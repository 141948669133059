import {
  CheckCircleOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
} from "@ant-design/icons";
import { Spin, Popover } from "antd";
const content = (src) => {
  return (
    <img
      alt="..."
      className="questionThumbnail"
      style={{
        height: 150,
        width: 150,
        objectFit: "contain",
        cursor: "pointer",
        border: "1px solid #707070",
        borderRadius: "5px",
        margin: "0 5px",
      }}
      src={src}
    />
  );
};
const TreeLeaf = ({
  name,
  status,
  color,
  hasStatus,
  isTitle,
  statusAfterUpdate,
  saveDisabled,
  src,
}) => {
  return (
    <Popover content={src && content(src)} trigger="hover">
      <div
        className={`treeLeafText ${isTitle && "treeLeafTitle"}`}
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          gap: 10,
        }}
      >
        <span>{name}</span>
        {hasStatus && (
          <span style={{ display: "flex", gap: 5 }}>
            {(!statusAfterUpdate ||
              status === "Not in Drive" ||
              statusAfterUpdate === "Failed") && (
              <span
                style={{
                  color: color,
                  minWidth: 120,
                  textAlign: "right",
                }}
              >
                {status}
              </span>
            )}
            {saveDisabled &&
              statusAfterUpdate !== "Fixed" &&
              status !== "Not in Drive" && (
                <Spin
                  indicator={
                    <LoadingOutlined
                      style={{
                        fontSize: 24,
                        margin: 3,
                      }}
                      spin
                    />
                  }
                />
              )}
            {statusAfterUpdate && status !== "Not in Drive" ? (
              statusAfterUpdate === "Fixed" ? (
                <CheckCircleOutlined style={{ color: "#71CF48" }} />
              ) : statusAfterUpdate === "Failed" ? (
                <CloseCircleOutlined style={{ color: "#FE4C4A" }} />
              ) : null
            ) : null}
          </span>
        )}
      </div>
    </Popover>
  );
};

export default TreeLeaf;

import { Card } from "antd";
import { Link } from "react-router-dom";
import { ColumnsType } from "antd/es/table";
import { AnyObject } from "antd/es/_util/type";

import { FleetInspectionType, WorkOrderType } from "../types";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { AvatarImage } from "../../../commonComponents";
import { formatNumber } from "src/components/SidebarPages/utils";

const { Meta } = Card;

export const fleetInspectionCols: ColumnsType<FleetInspectionType> = [
  {
    dataIndex: "fleetName",
    title: "Vehicle #",
  },
  {
    dataIndex: "inspectionDate",
    title: "Inspection Date",
    render(value) {
      return dayjsNY(value).format("MMM DD, YYYY");
    },
  },
  {
    dataIndex: "driver",
    title: "Driver",
  },
  {
    dataIndex: "inspectionStatus",
    title: "Status",
  },
  {
    dataIndex: "inspectionType",
    title: "Inspection Type",
  },
  {
    dataIndex: "mechanic",
    title: "Mechanic",
  },
];

export const workOrderCols: ColumnsType<WorkOrderType> = [
  {
    dataIndex: "workOrderId",
    title: "Work Order Id",
  },
  {
    dataIndex: "fleetName",
    title: "Vehicle #",
  },
  {
    title: "Mechanic",
    render(_, record) {
      return record.mechanicInfo.mechanic;
    },
  },
  {
    dataIndex: "workOrderStatus",
    title: "Status",
  },
  {
    dataIndex: "dueDate",
    title: "Due Date",
    render(value) {
      return dayjsNY(value).startOf("day").format("MMM DD, YYYY");
    },
  },
];

export const assignedMechanicsCols: ColumnsType<AnyObject> = [
  {
    dataIndex: "mechanic",
    title: "Mechanic",
    render(value, record) {
      return (
        <Meta
          {...{
            avatar: record?.googleDriveFileId ? (
              <AvatarImage
                {...{
                  size: 55,
                  nameOfUser: record?.nameOfUser,
                  googleDriveFileId: record?.googleDriveFileId,
                }}
              />
            ) : (
              <div
                className="InitialsContainer"
                style={{
                  backgroundColor: "#F8F8FA",
                }}
                onClick={() => {}}
              >
                <span
                  className="initialsStyle"
                  style={{
                    color: "#323338",
                  }}
                >
                  {value?.charAt(0)}
                </span>
              </div>
            ),
            title: value,
            description: record?.userName || "No user name",
          }}
        />
      );
    },
  },
  {
    dataIndex: "jobs",
    title: "Jobs",
    render(value) {
      return (
        <div className="dashboard-list-jobs">{value} work orders assigned</div>
      );
    },
  },
];

export const reminderColumns: ColumnsType<AnyObject> = [
  {
    title: "Program Name",
    dataIndex: "programName",
  },
  {
    title: "Vehicle #",
    dataIndex: "fleetName",
  },
  {
    title: "VIN",
    dataIndex: "vinNumber",
  },
  {
    title: "Plate",
    dataIndex: "licensePlate",
  },
  {
    title: "Mileage",
    dataIndex: "odometerReading",
    render(value) {
      return formatNumber(value, {
        unit: "mile",
      });
    },
  },
  {
    title: "Due Miles",
    dataIndex: "nextExecutionMiles",
    render(value) {
      if (!value) {
        return "N/A";
      }

      return formatNumber(value, { unit: "mile", fixed: 0 }) ?? "N/A";
    },
  },
  {
    title: "Due Date",
    dataIndex: "nextExecutionDate",
    render(value) {
      if (!value) {
        return "N/A";
      }

      return dayjsNY(value.nextExecutionDate).format("MMM DD, YYYY");
    },
  },
];

export const mismatchColumns: ColumnsType<AnyObject> = [
  {
    title: "Vehicle #",
    dataIndex: "fleetName",
  },
  {
    title: "Match Status",
    render(_, record) {
      if (record?.foundVin) {
        return "Vin mismatch";
      }

      return "Vehicle not matched";
    },
  },
  {
    title: "Tracker",
    dataIndex: "deviceSerialNumber",
  },
  {
    title: "VIN in System",
    dataIndex: "vinInSystem",
  },
  {
    title: "Found VIN",
    dataIndex: "foundVin",
  },
];

export const defectsCols: ColumnsType<AnyObject> = [
  {
    title: "Defect Id",
    dataIndex: "defectId",
  },
  {
    title: "Defect",
    dataIndex: "defectName",
  },
  {
    title: "Vehicle #",
    dataIndex: "fleetName",
  },
  {
    title: "Priority",
    dataIndex: "defectPriority",
  },
  {
    title: "Severity",
    dataIndex: "defectSeverity",
  },
  {
    title: "Work Order",
    dataIndex: "workOrderId",
    render(value) {
      if (value) {
        return <Link to={`/workOrders/${value}`}>{value}</Link>;
      }

      return null;
    },
  },
];

import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import {
  convertToAdvancedFilters,
  fetchSearchRecords,
  getFiltersObject,
  getPanelObject,
} from "../../../../utils";
import { apiRoutes, fetchData } from "../../Fleet/utils";
import "../DocumentationPage.scss";
import {
  documentationsPageColDef,
  excelColumnsConfig,
  formatCellValueHandler,
} from "../documentationPageData";
import { LoadableComp } from "../../XComponents";
import { uniq, min, uniqBy } from "lodash";
import { CategorySelectorCard } from "../components";
import dayjs from "dayjs";
import { useObserver, usePaginationControls } from "../../../../hooks";
import BasePage from "../../BasePage";
import { chartingOptions } from "../AgGridData/index";
import { useLocation, useSearchParams } from "react-router-dom";
import { filterDocumentationByAccess } from "./helper";
import { getExcelColumnKeys } from "../../../../utils/getExcelColumnKeys";
import { getChartingOptions } from "./utils/getChartingOptions";
import { DocumentationModal } from "../View/components";
import { message } from "antd";

const DocumentationPage = () => {
  let { state: locationState } = useLocation();
  const keysToUpdate = locationState?.chartFilter || {};

  const [searchParams, setSearchParams] = useSearchParams();
  const [singleDocId, setSingleDocId] = useState(searchParams.get("docId"));

  const { programFields = [] } = useSelector((state) => state.programFields);
  const { userConfiguration = [] } = useSelector((state) => state.userConfig);
  const { preferences } = useSelector((state) => state.preferences);

  const panelObject = getPanelObject(programFields, "Documentations");
  const filtersObject = getFiltersObject(
    programFields,
    "Documentations",
    keysToUpdate
  );

  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [gridApi, setGridApi] = useState();
  const [documentations, setDocumentations] = useState([]);
  const [docMapping, setDocMapping] = useState({});
  const [docConfig, setDocConfig] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState();
  const gridContainerRef = useRef();
  const [gridRef, setGridRef] = useState(null);
  const [loading, setLoading] = useState(true);
  const [documentationModalVisible, setDocumentationModalVisible] =
    useState(false);
  const [documentationModalProps, setDocumentationModalProps] = useState({});

  useObserver({
    element: gridContainerRef,
    callback: () => {
      !!gridApi && gridApi.sizeColumnsToFit();
    },
  });

  const statuses = uniqBy(
    docConfig
      .flatMap(({ documentationsAvailable }) => documentationsAvailable)
      .flatMap(({ docStatuses = [] }) => docStatuses),
    "statusName"
  );

  // Gets the category statuses for the selected Documentation
  let selectedCategoryStatuses = [];
  if (documentationModalProps && documentationModalProps.categoryName) {
    // Inspections gets categories from safety
    const categoryStatusesKey =
      documentationModalProps.categoryName === "Inspections"
        ? "Safety"
        : documentationModalProps?.categoryName;

    selectedCategoryStatuses = programFields
      ?.find((el) => el?.fieldName === "Statuses Of Categories")
      ?.fieldOptions?.[categoryStatusesKey].filter(({ status }) => status);
  }

  const switchPanels = getPanelObject(programFields, "Documentations 1");

  const populatedStatuses = statuses?.map((el) => ({
    ...el,
    value: documentations?.filter((item) => item?.docStatus === el?.statusName)
      ?.length,
  }));

  const populatedColDefs = useMemo(() => {
    return documentationsPageColDef({
      accessToken,
      docMapping,
      docConfig,
      statuses: (statuses || [])?.map((st) => ({
        status: st?.statusName || "",
        color: st?.statusColor || "",
      })),
      selectedCategory,
      isDarkMode,
      setDocumentationModalVisible,
      setDocumentationModalProps,
    });
  }, [docMapping, docConfig, statuses, selectedCategory]);
  const selectedPreferences = preferences?.basepages?.["Documentations"];
  const defaultFilters =
    selectedPreferences?.[selectedPreferences?.defaultFilter]
      ?.advancedFiltersState;

  const categoriesWithDocs = useMemo(
    () => uniq(documentations?.map(({ categoryName }) => categoryName)),
    [documentations]
  );

  const {
    rowData: hookData = [],
    statuses: hookStatuses,
    fetchAllHandler = () => {},
    onPaginationChanged = () => {},
    reInitializeFetch = () => {},
    filterSearchData: filterSearchDataNew = () => {},
  } = usePaginationControls(
    "documentation",
    "docId",
    setLoading,
    9,
    locationState?.tabPosition || locationState?.state?.tabPosition || "",
    defaultFilters
    // gridRef
  );

  const gridData = !selectedCategory
    ? documentations
    : documentations
        ?.filter(({ categoryName }) => categoryName === selectedCategory)
        ?.reduce((acc, curr = {}, _, arr) => {
          const {
            recordId,
            recordName,
            categoryName,
            docType,
            docId,
            teamsConfiguration,
          } = curr;

          const filteredArr = arr?.filter(
            ({ recordId: arrRecordId }) => arrRecordId === recordId
          );
          let missingFiles = filteredArr
            ?.filter(
              ({ googleDriveUploads = [] }) => !googleDriveUploads?.length
            )
            ?.map(({ docType }) => docType);
          return !!acc
            ?.map(({ recordId: accRecordId }) => accRecordId)
            .includes(recordId)
            ? acc
            : [
                ...acc,
                {
                  recordId,
                  recordName,
                  categoryName,
                  docId,
                  docType,
                  teamsConfiguration,
                  createdBy: filteredArr?.map(({ createdBy }) => createdBy),
                  allGoogleDriveUploads: filteredArr?.map(
                    ({ googleDriveUploads, docType }) => ({
                      docType,
                      googleDriveUploads,
                    })
                  ),
                  status: missingFiles?.length
                    ? "Missing Documentations"
                    : "Everything Up-to-date",
                  missingFiles,
                  nearestExpDate: min(
                    filteredArr
                      ?.filter(
                        ({ expirationDate }) =>
                          expirationDate > dayjs().valueOf()
                      )
                      ?.map(({ expirationDate }) => expirationDate)
                  ),
                },
              ];
        }, []);

  const fileStatuses = [
    {
      statusName: "Missing Documentations",
      statusColor: "#FE4C4A",
      value: gridData?.filter(({ missingFiles }) => !!missingFiles?.length)
        ?.length,
    },
    {
      statusName: "Everything Up-to-date",
      statusColor: "#71CF48",
      value: gridData?.filter(({ missingFiles }) => !missingFiles?.length)
        ?.length,
    },
  ];

  function getRowData() {
    Promise.allSettled([fetchData(apiRoutes.docConfiguration)]).then(
      ([{ value: docConfigRes }]) => {
        setDocConfig(docConfigRes);
        setLoading(false);
      }
    );
  }

  function getSingleDoc() {
    Promise.allSettled([
      fetchData("documentation", `documentation/${singleDocId}`),
    ])
      .then(([{ value }]) => {
        setDocumentationModalProps(value);
        setDocumentationModalVisible(true);
      })
      .catch(([{ error }]) => {
        message.error("Couldn't find the Documentation");
      });
  }

  function reloadGrid(gridApi) {
    gridApi.showLoadingOverlay();
    getRowData();
    reInitializeFetch();
  }

  useEffect(() => {
    getRowData();
    if (singleDocId) {
      getSingleDoc();
    }
  }, []);

  useEffect(() => {
    if (hookData) {
      // setDocumentations(hookData);
      setDocumentations(
        filterDocumentationByAccess(hookData, userConfiguration)
      );
    }
  }, [hookData]);

  useEffect(() => {
    const fieldObj = programFields.reduce(
      (acc, { fieldName, fieldOptions }) => ({
        ...acc,
        [fieldName]: fieldOptions,
      }),
      {}
    );
    setDocMapping(fieldObj["Documentation Mapping"]);
  }, [programFields]);

  // useEffect(() => {
  //   if (!!gridApi) {
  //     // gridApi.redrawRows();
  //     gridApi.sizeColumnsToFit();
  //   }
  // }, [gridData]);

  const filterSearchData = async (filters) => {
    gridApi?.showLoadingOverlay();
    const queryStringParameters =
      Array.isArray(filters) && filters.length > 0
        ? { filters: JSON.stringify(filters) }
        : undefined;

    await fetchData("documentation", "documentation", {
      queryStringParameters,
    })
      .then((result) => {
        let res = Array.isArray(result) ? result : result.documentation;
        setDocumentations(res);
      })
      .catch((error) => console.log({ error }));
  };

  const searchTableHandler = async (filters) => {
    if (Object.keys(filters || {}).length > 0) {
      filterSearchDataNew(convertToAdvancedFilters(filters));
    } else {
      setDocumentations(hookData);
    }
  };

  const onDebounceChange = async (e) => {
    gridApi.showLoadingOverlay();
    if (!!e && e !== "") {
      await fetchSearchRecords("documentation", "docType", e).then((res) => {
        setDocumentations(res);
        gridApi.hideOverlay();
        gridApi.showNoRowsOverlay();
      });
    } else {
      await fetchData("documentation").then((res) => {
        let allRes = Array.isArray(res) ? res : res.documentation;
        setDocumentations(allRes);
        if (allRes.length === 0) {
          gridApi.hideOverlay();
          gridApi.showNoRowsOverlay();
        } else {
          gridApi.hideOverlay();
        }
      });
    }
  };

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName === "Files Uploaded" || headerName === "Team") {
          return value?.length || "0";
        } else if (headerName === "Shown In") {
          return value?.length || "0";
        } else if (headerName === "Team") {
          return "My Team";
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  };

  return (
    <LoadableComp loading={loading}>
      <div
        className={`documentationPageBasePage ${
          isDarkMode && "documentationPageBasePageDark"
        }`}
      >
        <CategorySelectorCard
          {...{
            categoriesWithDocs,
            onClick: (catName) =>
              setSelectedCategory((prev) => (prev === catName ? "" : catName)),
            currentCategory: selectedCategory,
            isDarkMode,
          }}
        />
        <BasePage
          {...{
            title: "Documentations",
            rowData: gridData,
            panelObject: !selectedCategory
              ? switchPanels?.docStatus
              : switchPanels?.status,
            filtersObject,
            videoLinks: true,
            formatCellValueHandler,
            customStatusPanel: !selectedCategory
              ? populatedStatuses
              : fileStatuses,
            columnDefs: populatedColDefs,
            hasNew: false,
            chartingOptions,
            // hasStatus: !selectedCategory,
            exportGridToExcel,
            defaultExcelExportParams: {
              columnKeys: excelColumnsConfig,
              fileName: "Documentations",
            },
            getGridApi: (e) => setGridApi(e),
            reloadGrid,
            paginationAutoPageSize: false,
            filterSearchData: filterSearchDataNew,
            setRowData: setDocumentations,
            getGridRef: (ref) => {
              setGridRef(ref);
            },
            onPaginationChanged: () => {
              onPaginationChanged();
            },
            getChartingOptions: (data) => getChartingOptions(data),
            fetchAllHandler,
            fetchAllLogsButton: true,
            allowFetchSearch: true,
            hasFetchSearchFromDb: true,
            searchTableHandler,
            onDebounceChange,
          }}
        />
        {documentationModalVisible && (
          <DocumentationModal
            {...{
              visible: documentationModalVisible,
              setVisible: setDocumentationModalVisible,
              viewTitle: documentationModalProps?.recordName,
              categoryName: documentationModalProps?.categoryName,
              docType: documentationModalProps?.docType,
              docId: documentationModalProps?.docId,
              recordId: documentationModalProps?.recordId,
              googleDriveUploads: documentationModalProps?.googleDriveUploads,
              docStatus: documentationModalProps?.docStatus,
              docStatuses: selectedCategoryStatuses,
              accessToken,
              documentation: documentations,
              setDocumentation: setDocumentations,
              teamsConfiguration: documentationModalProps?.teamsConfiguration,
            }}
          />
        )}
      </div>
    </LoadableComp>
  );
};

export default DocumentationPage;

//@ts-check
import moment from "moment";
import {
  differenceInHours,
  formatDate,
  formatDateToMidnight,
} from "./formatUtils";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

const yards = [
  "2777 Arthur Kill Road, Staten Island, NY 10309, USA",
  "271 Industrial Loop, Staten Island, NY, USA",
];

export const getDispatchesByActivities = async () => {
  try {
    const activities = await fetchAllData(
      "fleetActivity",
      "fleetActivity",
      "activityId"
    );
    const groupedDispatches = groupAndSortActivitiesByDispatchId(activities);

    const dispatches = [];
    let countPerDay = {};
    Object.entries(groupedDispatches).forEach(([key, activities]) => {
      const {
        fleetName,
        fleetId,
        scheduleId,
        dispatchId,
        driverName,
        driverId,
        dispatchedBy,
        activityStatus,
      } = activities[0];

      const dispatchDate = formatDateToMidnight(
        formatDate(activities[0].startingTime)
      );

      const currentDispatchIndex = [...dispatches]
        .reverse()
        .findIndex(
          (activity) =>
            activity.fleetName === updateFleetName(fleetName) &&
            activity.dispatchDate === dispatchDate
        );

      // Since we searched in reverse, the actual index in the original array is:
      const originalDispatchIndex =
        currentDispatchIndex !== -1
          ? dispatches.length - 1 - currentDispatchIndex
          : -1;

      if (originalDispatchIndex !== -1) {
        const dispatchNumber = dispatches[
          originalDispatchIndex
        ].dispatchNumber?.replace("D", "");
        if (!countPerDay[dispatchDate]) {
          countPerDay[dispatchDate] = {
            ...countPerDay[dispatchDate],
            [fleetName]: 1,
          };
        } else if (dispatchNumber) {
          countPerDay[dispatchDate][fleetName] = Number(dispatchNumber);
          countPerDay[dispatchDate][fleetName]++;
        }
      } else {
      }
      const dispatchNumber =
        countPerDay[dispatchDate]?.[fleetName] > 1
          ? `D${countPerDay[dispatchDate][fleetName]}`
          : "D1";

      dispatches.push({
        fleetName: updateFleetName(fleetName),
        fleetId,
        scheduleId,
        driverName,
        dispatchId,
        dispatchDate,
        driverId,
        dispatchedBy,
        dispatchStatus: activityStatus,
        routes: activities.map((activity) => ({
          ...activity,
          dispatchNumber,
          dispatchDate,
          driverId,
          tripVariance: getTripVariance(activity),
          fleetName: updateFleetName(fleetName),
          departAt: formatDate(activity.startingTime),
          arriveBy: formatDate(activity.timeAtLocation),
          timeExitingTheLocation: activity.timeExitingTheLocation
            ? formatDate(activity.timeExitingTheLocation)
            : "",
          timeOnSite:
            activity.timeExitingTheLocation && activity.timeAtLocation
              ? differenceInHours(
                  formatDate(activity.timeAtLocation),
                  formatDate(activity.timeExitingTheLocation)
                )
              : "",
          timeScheduled:
            activity.timeAtLocation && activity.startingTime
              ? differenceInHours(
                  formatDate(activity.startingTime),
                  formatDate(activity.timeAtLocation)
                )
              : "",
          hoistRoute: activity.hoistRoute || false,
        })),
        dispatchNumber,
      });
    });

    const sortedDispatches = dispatches.sort((a, b) =>
      moment(b.dispatchDate).isBefore(a.dispatchDate) ? -1 : 1
    );
    return sortedDispatches;
  } catch (error) {
    console.log(
      "data source function getDispatchesByActivities error: ",
      error
    );
  }
};

const groupAndSortActivitiesByDispatchId = (activities) => {
  // Sort the array by dispatchId
  activities.sort((a, b) =>
    moment(a.startingTime).isBefore(b.startingTime) ? -1 : 1
  );

  // Group the objects by dispatchId
  let groups = {};
  activities.forEach((activity) => {
    let dispatchId = activity.dispatchId;
    if (!groups[dispatchId]) {
      groups[dispatchId] = [];
    }
    groups[dispatchId].push(activity);
  });
  return groups;
};

const updateFleetName = (fleetName) =>
  fleetName.toLowerCase().includes("truck") ||
  fleetName.toLowerCase().includes("trailer")
    ? fleetName.replace(/\D/g, "")
    : fleetName;

const getTripVariance = (activity) => {
  if (!!activity?.scheduleId) {
    return false;
  }

  if (!!activity?.projectId) {
    return true;
  }

  if (yards.includes(activity?.dropOffLocation)) {
    return false;
  }

  return true;
};

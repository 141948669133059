import { checkIfServiceIsHoist } from "../../../../../Estimations/DataEntryGrid/models/Service";
import { v4 as uuid } from "uuid";
import { max } from "lodash";
import dayjs from "dayjs";
import _ from "lodash";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { generateWeeks } from "../../../../../../../utils";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getDetailsRentDaily } from "../../RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalHeader/Components/HeaderDateInput/HeaderDateInputFunctions";
var duration = require("dayjs/plugin/duration");
dayjs.extend(duration);
export const selectedServiceHandler = ({ serviceList, service }) =>
  serviceList?.find(({ label }) => label === service);
//selected service data

//here we generates the information for the selected service
export const serviceDataHandler = ({ filteredData, service, estimation }) =>
  filteredData
    ?.find(({ estimationId }) => estimationId === estimation)
    ?.services?.find(({ label }) => label === service);

//with this we manipulate scheduling data
export const calculatedScheduleHandler = ({ allSchedules }) =>
  allSchedules?.reduce((acc, curr, index) => {
    const previousSchedule = acc?.at?.(-1);
    return index === 0
      ? [
          {
            ...curr,
            toBeScheduled: Object.keys(curr?.toBeScheduled || {})?.flatMap(
              (estimationId) => {
                return curr.toBeScheduled[estimationId]?.map((e) => ({
                  ...e,
                  estimationId,
                }));
              }
            ),
          },
        ]
      : [
          ...acc,
          {
            ...curr,
            toBeScheduled: Object.keys(curr?.toBeScheduled || {})
              ?.flatMap((estimationId) => {
                console.log("flatMapi", curr.toBeScheduled[estimationId]);
                return curr.toBeScheduled[estimationId]?.map((e) => ({
                  ...e,
                  estimationId,
                }));
              })
              ?.map((serviceData, serviceIndex) => {
                return checkIfServiceIsHoist(serviceData)
                  ? serviceData
                  : {
                      ...serviceData,
                      serviceOptions: [
                        serviceData?.serviceOptions[0]?.map(
                          (elevationData, elevationIndex) => {
                            return {
                              ...elevationData,
                              items: elevationData?.items?.map(
                                (itemsData, itemsIndex) => {
                                  const previousTotalProgress =
                                    previousSchedule?.toBeScheduled[
                                      serviceIndex
                                    ]?.serviceOptions[0][elevationIndex]?.items[
                                      itemsIndex
                                    ]?.totalProgress;

                                  return {
                                    ...itemsData,
                                    totalProgress:
                                      curr?.typeOfWork === "Removal"
                                        ? previousTotalProgress -
                                          itemsData?.totalProgress
                                        : itemsData?.totalProgress,
                                  };
                                }
                              ),
                            };
                          }
                        ),
                      ],
                    };
              }),
          },
        ];
  }, []);

export const autoFillRentals = ({
  rentals,
  currentElevation,
  currentService,
  quickbooksNumber = "",
  pliData,
  isHoist,
  estimationId,
}) => {
  console.log("autoFillRelatedRentals", {
    rentals,
    currentElevation,
    currentService,
    quickbooksNumber,
    pliData,
  });
  var duration = require("dayjs/plugin/duration");
  dayjs.extend(duration);
  let CURRENT_EXTREMITIES = {
    startDate: 0,
    endDate: 0,
  };

  console.log("ledjoRentals", rentals);

  let filteredRentals = rentals?.filter((el) => {
    return el?.services?.find(
      (el) =>
        el?.serviceId.toString() === currentService?.serviceId.toString() &&
        el?.estimationId?.toString() === estimationId?.toString()
      // el?.quickbooksNumber?.toString() === quickbooksNumber?.toString()
    );
  });
  console.log("ledjoFilteredRentals", filteredRentals);

  let filteredServices = filteredRentals
    ?.flatMap((el) => el?.services)
    .flatMap((el) => el?.serviceOptions);
  let currPli = {};
  if (filteredServices?.length > 0) {
    let filteredElevation = filteredServices?.filter((el) =>
      checkIfServiceIsHoist(currentService)
        ? el?.elevationLabel === "Hoist"
        : el?.elevationId === currentElevation?.elevationId
    );
    let filteredPlis = filteredElevation
      ?.flatMap((el) => el?.items)
      .filter((el) => {
        return checkIfServiceIsHoist(currentService)
          ? el?.elevationId === pliData?.elevationId
          : el?.id === pliData?.id;
      })
      ?.sort((a, b) => b?.rentalDetails?.endDate - a?.rentalDetails?.endDate);

    console.log("ledjotrack", {
      tesT: filteredElevation?.flatMap((el) => el?.items),
      pliData,
      filteredPlis,
    });
    if (filteredPlis?.length > 0 && !!filteredPlis[0]?.rentalDetails?.endDate) {
      let diff =
        dayjsNY(filteredPlis[0]?.rentalDetails?.endDate).diff(
          dayjsNY(filteredPlis[0]?.rentalDetails?.startDate),
          "day"
        ) + 1;
      CURRENT_EXTREMITIES = {
        startDate: dayjsNY(filteredPlis[0]?.rentalDetails?.endDate)
          .add(1, "day")
          .valueOf(),
        endDate: dayjsNY(filteredPlis[0]?.rentalDetails?.endDate)
          .add(diff, "day")
          .valueOf(),
      };
    } else return {};

    currPli = filteredPlis?.[0] || {};

    console.log("CURRENT_EXTREMITIES", filteredPlis);
    let details = generateWeeks(
      CURRENT_EXTREMITIES?.startDate,
      CURRENT_EXTREMITIES?.endDate
    );
    let startDate = CURRENT_EXTREMITIES?.startDate;
    let endDate = CURRENT_EXTREMITIES?.endDate;
    console.log("rentttttt", startDate, endDate);
    if (!startDate || !endDate) return {};
    const howManyDays =
      !!startDate && !!endDate
        ? +(
            dayjs
              ?.duration(
                dayjs(endDate)?.startOf("day")?.valueOf() -
                  dayjs(startDate)?.startOf("day")?.valueOf()
              )
              ?.asDays() + 1
          )?.toFixed(0)
        : 0;
    const dailyAmount = !checkIfServiceIsHoist(currentService)
      ? +(forceToNumber(pliData?.rent) / (4 * 7))?.toFixed(2)
      : +(forceToNumber(currentElevation?.rentalsTotal) / (4 * 7))?.toFixed(2);
    let tempDetails = getDetailsRentDaily({
      details,
      rent: !checkIfServiceIsHoist(currentService)
        ? forceToNumber(pliData?.rent)
        : forceToNumber(currentElevation?.rentalsTotal),
      dailyAmount,
      howManyDays,
      // lastOne: idx === lineItemsData?.items?.length - 1,
    });
    console.log("ledjotemppppp", {
      howManyDays,
      dailyAmount,
      currPliRent: currentElevation?.rent,
    });
    let tempApplied = Array.isArray(tempDetails)
      ? tempDetails?.reduce((acc, curr) => {
          return (
            acc +
            curr?.days?.reduce((acc2, curr2) => {
              return acc2 + forceToNumber(curr2?.dailyAmount);
            }, 0)
          );
        }, 0)
      : 0;
    if (Object.keys(currPli).length > 0) {
      currPli = {
        ...(currPli ?? {}),
        lock: false,
        applied: false,
        appliedAmount: forceToNumber(tempApplied?.toFixed(2) ?? 0),
        rentalDetails: {
          ...currPli?.rentalDetails,
          endDate,
          startDate,
          details: tempDetails,
          // details: details?.map((detailsData) => ({
          //   ...detailsData,
          //   charged: false,
          //   //here we set the weekly amount equal to the daily amount times the number of days in this week
          //   weeklyAmount:
          //     tempDaily * detailsData?.days?.length,
          //   lock: false,
          //   applied: false,
          //   days: detailsData?.days?.map((daysData) => ({
          //     ...daysData,
          //     applied: false,
          //     charged: false,
          //     lock: false,
          //     dailyAmount: tempDaily,
          //   })),
          // })),
        },
      };
    }
  }
  console.log("currPli", currPli);

  return currPli;
};

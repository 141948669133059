import moment from "moment";
import { useSelector } from "react-redux";
import { DeleteFilled } from "@ant-design/icons";
import { AutoComplete, Input, InputNumber, Space } from "antd";
import { FunctionPicker } from "../";
import { functionOptions } from "../../utils";
import { useAdvancedFilterContext } from "../../context";
import { fetchSearchRecords } from "../../../../../utils";
import DatePickerMoment from "../../../DatePickerMoment/DatePickerMoment";
import AutoCompleteFetch from "../../../AutoCompleteFetch/AutoCompleteFetch";
import { forceToNumber } from "../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { getRangePresets } from "src/components/SidebarPages/Fleet/components/InputComponent/utils";

const { RangePicker } = DatePickerMoment;

const selectedDataTypes = {
  projectManager: "nameOfUser",
  teamsConfiguration: "value",
  assignedTo: "nameOfUser",
  taskAssignedTo: "nameOfUser",
};

const AdvancedFilter = ({
  condition,
  filterIndex,
  conditionIndex,
  filtersObject,
  rowData,
  title,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const currentCategory = topicCategories?.find(
    (category) => category.categoryName === title
  );

  const endPoint = currentCategory?.apiName;
  const { handleInputChange, removeFilter } = useAdvancedFilterContext();

  const filterOptions = filtersObject.map((object) => ({
    value: object?.label,
    label: object?.label,
    type: object?.dataType ?? "string",
    key: object.key,
  }));

  const selectedFunction = functionOptions.find(
    (option) => option.value === condition.formula
  );

  const selectedLabel = condition.column
    ? filtersObject.find((filter) => filter.key === condition.column)?.label
    : "";

  const handleDateRangeChange = (value) => {
    const startDate = moment(value[0])
      .tz("America/New_York")
      .startOf("day")
      .valueOf();
    const endDate = moment(value[1])
      .tz("America/New_York")
      .endOf("day")
      .valueOf();

    handleInputChange(filterIndex, conditionIndex, "value", startDate, 0);
    handleInputChange(filterIndex, conditionIndex, "value", endDate, 1);
  };

  return (
    <div
      style={{
        display: "flex",
        gap: "20px",
        padding: "10px",
      }}
    >
      <AutoComplete
        placeholder="Select column to filter"
        popupClassName={isDarkMode && "darkDropDown"}
        options={filterOptions}
        value={selectedLabel}
        onChange={(value, option) => {
          handleInputChange(filterIndex, conditionIndex, "column", option.key);
          handleInputChange(filterIndex, conditionIndex, "value", null);
          handleInputChange(
            filterIndex,
            conditionIndex,
            "dataType",
            option.dataType
          );

          handleInputChange(
            filterIndex,
            conditionIndex,
            "columnType",
            option?.type || "string"
          );
          if (option.type === "object") {
            handleInputChange(
              filterIndex,
              conditionIndex,
              "formula",
              "contains"
            );
            return;
          }
          if (option.type === "date") {
            handleInputChange(
              filterIndex,
              conditionIndex,
              "formula",
              "is between"
            );
          } else if (option.type !== "date") {
            handleInputChange(filterIndex, conditionIndex, "formula", "is");
          }
        }}
        style={{ minWidth: 200 }}
      />
      <FunctionPicker
        {...{
          value: condition.formula,
          filterIndex,
          conditionIndex,
          selectedType: condition.columnType,
        }}
      />
      {selectedFunction.type === "double input" ? (
        <Space.Compact
          size="large"
          style={{
            visibility:
              condition.column && condition.formula.indexOf("null") === -1
                ? "visible"
                : "hidden",
            flex: 1,
          }}
        >
          {condition.columnType === "date" ? (
            <RangePicker
              format={"MM/DD/YYYY"}
              presets={getRangePresets()}
              defaultValue={
                condition.value
                  ? [
                      moment(condition.value?.[0]).tz("America/New_York"),
                      moment(condition.value?.[1]).tz("America/New_York"),
                    ]
                  : [undefined, undefined]
              }
              size="middle"
              onChange={handleDateRangeChange}
            />
          ) : (
            <>
              <InputNumber
                allowClear
                size="small"
                placeholder="from"
                defaultValue={condition.value?.[0] || 0}
                onChange={(value) =>
                  handleInputChange(
                    filterIndex,
                    conditionIndex,
                    "value",
                    value,
                    0
                  )
                }
                style={{ width: "100%" }}
              />
              <InputNumber
                allowClear
                size="small"
                placeholder="to"
                defaultValue={condition.value?.[1] || ""}
                onChange={(value) =>
                  handleInputChange(
                    filterIndex,
                    conditionIndex,
                    "value",
                    value,
                    1
                  )
                }
                style={{ width: "100%" }}
              />
            </>
          )}
        </Space.Compact>
      ) : selectedFunction.type === "autocomplete" ||
        condition.columnType === "object" ? (
        <AutoCompleteFetch
          type={selectedFunction.type}
          popupClassName={isDarkMode && "darkDropDown"}
          defaultValue={condition.value || ""}
          initialData={rowData}
          selectedDataType={condition.columnType}
          selectedDataTypeKey={selectedDataTypes[condition.column]}
          onSearch={(search) =>
            fetchSearchRecords(endPoint, condition.column, search)
          }
          selectedKey={condition.column}
          onSelect={(value, options) => {
            const newValue =
              condition.columnType === "object"
                ? options.content
                : condition.columnType === "number"
                ? forceToNumber(value)
                : value;

            handleInputChange(filterIndex, conditionIndex, "value", newValue);
          }}
          style={{
            flex: 1,
            visibility:
              condition.column && condition.formula.indexOf("null") === -1
                ? "visible"
                : "hidden",
          }}
        />
      ) : condition.dataType === "number" ? (
        <InputNumber
          allowClear
          size="small"
          placeholder="Input value..."
          defaultValue={condition.value || ""}
          onChange={(value) =>
            handleInputChange(filterIndex, conditionIndex, "value", value)
          }
          style={{ width: "100%" }}
        />
      ) : (
        <Input
          allowClear
          popupClassName={isDarkMode && "darkDropDown"}
          placeholder="Input value..."
          // value={condition.value || ""}
          defaultValue={condition.value || ""}
          onChange={(event) =>
            handleInputChange(
              filterIndex,
              conditionIndex,
              "value",
              event.target.value
            )
          }
          style={{
            flex: 1,
            visibility:
              condition.column && condition.formula.indexOf("null") === -1
                ? "visible"
                : "hidden",
          }}
        />
      )}
      <DeleteFilled
        style={{
          fontWeight: "bold",
          color: "white",
          backgroundColor: "red",
          fontSize: "20px",
          padding: "5px",
          borderRadius: "5px",
          visibility:
            conditionIndex > 0 || filterIndex > 0 ? "visible" : "hidden",
        }}
        onClick={() => removeFilter(filterIndex, conditionIndex)}
      />
    </div>
  );
};

export default AdvancedFilter;

import moment from "moment";
import { forceToNumber } from "../../components/SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getEstimationServices = async () => {
  let estimations = await fetchAllData(
    "estimations",
    "estimations",
    "estimationId"
  ).then((r) => r.filter((e) => e.estSTATUS === "Approved"));

  return estimations.map((est) => ({
    isChangeOrder: est.isChangeOrder,
    status: est.estSTATUS,
    accountName: est.accountName,
    jobSiteAddress: est.jobSiteAddress,
    createdAt: est.createdAt,
    estimationId: est.estimationId,
    calendarYear: moment(est.createdAt).year(),
    totalEstimationAmount: est.services
      .filter((service) => !service.isScope)
      .reduce((sum, service) => {
        const serviceOptions = Array.isArray(service.serviceOptions?.[0])
          ? service.serviceOptions[0]
          : service.serviceOptions;
        sum +
          serviceOptions?.reduce(
            (sum, option) =>
              sum +
              option.items.reduce(
                (subTotal, obj) => subTotal + forceToNumber(obj.totalPrice),
                0
              ),
            0
          );
      }, 0),
    serviceCount: est.services.filter((service) => !service.isScope).length,
    services: est.services
      .filter((service) => !service.isScope)
      .map((service) => {
        const serviceOptions = Array.isArray(service.serviceOptions?.[0])
          ? service.serviceOptions[0]
          : service.serviceOptions;
        return {
          accountName: est.accountName,
          jobSiteAddress: est.jobSiteAddress,
          createdAt: est.createdAt,
          estimationId: est.estimationId,
          serviceName: service.label,
          estimationNumber: est.estimationNumber,
          totalAmount: serviceOptions?.reduce(
            (sum, option) =>
              sum +
              option.items?.reduce(
                (subTotal, obj) => subTotal + obj.totalPrice,
                0
              ),
            0
          ),
          serviceOptions: serviceOptions?.map((option) => ({
            name: option.elevationLabel,
            totalAmount: option.items?.reduce(
              (subTotal, obj) => subTotal + obj.totalPrice,
              0
            ),
          })),
        };
      }),
  }));
};

import moment from "moment";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

import {
  sendNotification,
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../utils";
import {
  getChangedData,
  updateClientBalance,
  calculatePreviousInvoiceTotals,
  calculateCreditedAmountForService,
} from "../../utilities";
import {
  categories,
  categoriesName,
  categoriesServiceMap,
  requiredInvoiceFields,
} from "../utils/constants";
import { useInitialInvoiceContext } from "../context/InvoiceInitialContext";
import { useProgressContext } from "../../../../../commonComponents/ProgressComponent/context/ProgressContext";
import { useInvoiceFormDataContext } from "../context/InvoiceFormDataContext";
import { updateAllCategories } from "../utils/updateAllCategories";
import { useEditLogs } from "../../../../../../hooks";
import { updateBalance } from "../../../Tabs/Payments/components/BalanceReport/balanceFixer";

const useCreateInvoice = () => {
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const {
    setData,
    accountData,
    invoiceData,
    projectData,
    categoryData,
    setSavedRecord,
    setSelectedData,
    fetchAndSetInvoices,
    createdInvoiceRetriever,
    getBodyToSaveInAutomation,
  } = useInitialInvoiceContext();

  const { formData } = useInvoiceFormDataContext();

  const { setVisibleCreationProgress, updateProgressStatus } =
    useProgressContext();
  const { saveAddedLogs } = useEditLogs();

  const [products, setProducts] = useState([]);
  const [totalDue, setTotalDue] = useState(0.0);
  const [subTotal, setSubTotal] = useState(0.0);
  const [productsList, setProductsList] = useState([]); //Used to hold list of services to be displayed in the service auto complete cell

  //Calculates Total balance && subTotal(total without the tax) whenever rowData changes
  useEffect(() => {
    let totalDue = 0;
    let subTotal = 0;
    if (products !== undefined) {
      products?.forEach((el) => {
        subTotal += el.amount;
        totalDue += el.amount;
        if (el?.tax) totalDue += el.taxAmount;
      });
      setTotalDue(parseFloat(totalDue || 0).toFixed(2));
      setSubTotal(parseFloat(subTotal || 0).toFixed(2));
    }
  }, [products]);

  //Set suggested amount for product list and others
  useEffect(() => {
    if (invoiceData && categoryData) {
      //Creating a new categoryData object
      const prevProd = invoiceData?.invoiceItems;
      prevProd?.forEach((invoicedItem) => {
        const service = categoryData?.[invoicedItem?.category]
          ?.find(
            (catData) =>
              catData[categoriesName[invoicedItem?.category] + "Id"] ===
              invoicedItem?.categoryId
          )
          ?.[categoriesServiceMap[invoicedItem?.category]]?.flat(1)
          ?.find(({ serviceId }) => serviceId === invoicedItem?.serviceId);
        const { creditedAmount } = calculateCreditedAmountForService({
          service,
          categoryFrom: invoicedItem?.category,
        });

        const { previousInvoicedAmount } = calculatePreviousInvoiceTotals({
          service,
          exceptionInvoicesId: [invoiceData?.invoiceId],
        });
        //MaxSuggestedAmount is the maximum that a service can be invoiced
        return (invoicedItem.maxSuggestedAmount =
          invoicedItem?.scopeAmount -
          (creditedAmount || 0) -
          previousInvoicedAmount);
      });
      setProducts(prevProd);
    }
  }, [invoiceData, categoryData]);

  const handleSave = async () => {
    try {
      showLoadingMsg({ content: `Saving Invoice. Please wait.` });

      /*----- Validation Starts here -----*/
      //Checks if a field is empty or not
      const isEmpty = (field) => formData[field] === "" || !(field in formData);

      //Checks required fields if any of them are empty
      for (const { field, content } of requiredInvoiceFields) {
        if (isEmpty(field)) {
          return showErrorMsg({ content: `${content} field is empty` });
        }
      }

      if (products.length === 0)
        return showErrorMsg({ content: `You have not added any product.` });

      for (let i = 0; i < products.length; i++) {
        let product = products[i];
        for (let key in product) {
          if (
            product[key] === "" &&
            key !== "description" &&
            key !== "rowDescription"
          ) {
            return showErrorMsg({
              content: `Row with id "${i + 1}" has empty cell!`,
            });
          }
        }
      } //

      /* Validation end here */

      //Merge data of the form and rowData from table||Product/Services together
      const data = { ...formData, products };
      const convertedData = {
        subtotal: parseFloat(subTotal),
        invoiceItems: data.products,
        totalInvoiceAmount: parseFloat(totalDue),
        toDate: moment(data.dueDate).tz("America/New_York").valueOf(),
        fromDate: data.invoiceDate,
        quickBooksNumber: data.quickBooksNumber,
        invoiceDate: data.invoiceDate,
        memos: data.memos || [],
      };

      //Checks if modal is in edit mode so it saves update data in DB
      if (invoiceData) {
        /*Save changed invoice data in database*/
        const paidAmount =
          (invoiceData.totalInvoiceAmount || 0) - (invoiceData.amountDue || 0);
        const newDueAmount = parseFloat(totalDue) - paidAmount;

        /*** Getting new edit log ***/
        const newEditLogData = {
          recordId: invoiceData.invoiceId,
          recordName: `No. ${invoiceData?.invoiceNumber}`,
          actionType: "Edit",
          topic: invoiceData?.projectName,
          currentData: {},
          category: "Invoice",
          previousData: {},
          updatedKeys: [],
        };

        for (let key in convertedData) {
          let result;
          if (key in convertedData && key in invoiceData) {
            result = getChangedData(convertedData[key], invoiceData[key]);
          } else {
            continue;
          }
          if (result !== false) {
            newEditLogData.currentData[key] = result.curr;
            newEditLogData.previousData[key] = result.prev;
            newEditLogData.updatedKeys.push(key);
          }
        }
        /***                      ***/
        //Checking if any data has been updated before making a request in backend
        if (Object.keys(newEditLogData.currentData).length !== 0) {
          setVisibleCreationProgress(invoiceData);
          updateProgressStatus({ updatingRecord: "executing" });

          //Reflects changes in Invoices Ag Grid and in the selected invoice in Invoice Drawer
          setData((prev) => {
            const prevI = prev[invoiceData.rowIndex] || {};
            setSelectedData((prevS) => {
              for (let key in convertedData) {
                prevI[key] = convertedData[key];
                prevS[key] = convertedData[key];
              }
              return { ...prevS };
            });
            return [...prev];
          });

          let res = await API.put(
            "invoices",
            `/invoices/${invoiceData.invoiceId}`,
            {
              body: {
                ...convertedData,
                amountDue: newDueAmount,
                lastModifiedBy: {
                  ...{
                    date: moment().tz("America/New_York").valueOf(),
                    id: authUser.sub,
                    name: `${authUser.given_name} ${authUser.family_name}`,
                  },
                },
              },
            }
          ).catch((error) => {
            console.log({ error });
            updateProgressStatus({ updatingRecord: "hasError" });
          });

          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          const newInvoiceBalance = parseFloat(
            totalDue - invoiceData.totalInvoiceAmount
          );

          await updateClientBalance({
            accountId: accountData?.accountId,
            totalInvoiceAmount: -newInvoiceBalance,
          });

          //Updating all categories which are effected by this invoice
          const objsToUpdate = {};
          const estimationsServicesToUpdateInProject = {};
          data.products?.forEach(({ category, categoryId }) => {
            if (objsToUpdate?.[category]) {
              objsToUpdate?.[category]?.push(categoryId);
            } else objsToUpdate[category] = [categoryId];
          });
          Object.keys(objsToUpdate)?.forEach((table) =>
            Array?.from(new Set(objsToUpdate[table]))?.forEach((categoryId) => {
              const services = categoryData[table]?.find(
                (el) => el?.[`${categoriesName[table]}Id`] === categoryId
              )?.[categoriesServiceMap[table]];

              services?.forEach((service) => {
                const invoicedService = data?.products?.find(
                  ({ categoryId: cID, serviceId, name }) =>
                    cID === categoryId &&
                    serviceId === service?.serviceId &&
                    name === service?.label
                );
                if (invoicedService) {
                  service.invoices = [
                    ...(service?.invoices?.filter(
                      ({ invoiceId }) => invoiceId !== invoiceData?.invoiceId
                    ) || []),
                    {
                      invoiceId: invoiceData?.invoiceId,
                      total: invoicedService?.total,
                      amount: invoicedService?.amount,
                      amountPercent: invoicedService?.amountPercentage,
                    },
                  ];
                }
              });
              API.put(table, `/${table}/${categoryId}`, {
                body: {
                  invoices: [
                    ...(categoryData[table]
                      ?.find(
                        (el) =>
                          el?.[`${categoriesName[table]}Id`] === categoryId
                      )
                      ?.invoices?.filter(
                        ({ invoiceId }) => invoiceId !== invoiceData?.invoiceId
                      ) || []),
                    {
                      invoiceId: invoiceData?.invoiceId,
                      invoiceNumber: invoiceData?.invoiceNumber,
                      invoiceAmount:
                        convertedData?.invoiceItems
                          ?.filter(({ categoryId: cI }) => cI === categoryId)
                          ?.reduce(
                            (p, { amount = 0, tax = false, taxAmount = 0 }) =>
                              p + (amount + (tax ? taxAmount : 0)),
                            0
                          ) || 0,
                    },
                  ],
                  [categoriesServiceMap[table]]: services,
                },
              });
              if (table === "estimations") {
                estimationsServicesToUpdateInProject[categoryId] = services;
              }
            })
          );
          if (Object.keys(estimationsServicesToUpdateInProject)?.length > 0) {
            API.put("projects", `/projects/${projectData?.projectId}`, {
              body: {
                services: {
                  ...projectData?.services,
                  ...estimationsServicesToUpdateInProject,
                },
              },
            });
          }

          saveAddedLogs(newEditLogData);

          const priceChanged =
            invoiceData?.totalInvoiceAmount !==
            convertedData?.totalInvoiceAmount;

          // await sendNotification({
          //   id: "96",
          //   action: priceChanged ? "onInvoiceEditPriceChange" : "onInvoiceEdit",
          //   team: projectData?.teamsConfiguration || [],
          //   otherKeys: {
          //     topicName: projectData?.accountName,
          //     invoiceNumber: invoiceData?.invoiceNumber,
          //   },
          //   recordId: invoiceData?.invoiceId,
          //   authenticatedUser: authUser,
          //   userConfiguration,
          // }).then((notificationSent) => {
          //   updateProgressStatus({
          //     sendingNotification: !!notificationSent ? "finished" : "hasError",
          //   });
          // });

          if (res.status === false) {
            return showErrorMsg({
              content: `An error occurred when updating the invoice!`,
            });
          } else {
            showSuccessMsg({ content: `Invoice Saved Successfully` });
          }
        }
        // setFormChanged(false);
      }
      //If it is a new invoice
      else {
        /* ***************Extra validation start here***************** */

        //Validating invoice date
        const newYorkMoment = moment().tz("America/New_York");
        let date = newYorkMoment.format("YYYY-MM-DD");
        // let date = moment().tz("America/New_York").valueOf().format("YYYY-MM-DD");
        //TEMPORARY COMMENTED CHECK DATES
        // const todaysDateUnix = dayjs(date).valueOf();
        // if (todaysDateUnix > formData?.invoiceDate) {
        //   return showErrorMsg({ content: "Invoice date is in the past." });
        // }

        // //Validating invoice date
        // if (todaysDateUnix > formData?.dueDate) {
        //   return showErrorMsg({ content: "Due date is in the past." });
        // }

        //Validating invoice data is before the due date
        if (formData?.invoiceDate >= formData?.dueDate)
          return showErrorMsg({
            content:
              "Due date should not be in the same or before invoice date",
          });

        /* ***************Extra Validation Ends Here****************************** */

        //Getting all included categories that are included in this invoice
        const categoriesFrom = Array.from(
          new Set(data.products?.map(({ category }) => category))
        );

        const bodyToSave = {
          ...convertedData,
          // invoiceNumber: data.invoiceNumber,
          invoiceDate: data.invoiceDate,
          projectName: data.projectName,
          projectId: data.projectId,
          accountId: data.accountId,
          accountName: data.accountName,
          fromDate: data.invoiceDate,
          toDate: new Date(data.dueDate).getTime(),
          quickBooksNumber: data.quickBooksNumber,
          invoiceStatus: "Created",
          invoiceActivity: [
            {
              title: "Created",
              date: data.invoiceDate,
              status: true,
              hadData: false,
            },
            {
              title: "Paid",
              date: false,
              status: false,
              hadData: false,
            },
            {
              title: "Deposited",
              date: false,
              status: false,
              hadData: false,
            },
          ],
          editLogs: [],
          lastModifiedBy: {
            date: moment().tz("America/New_York").valueOf(),
            id: authUser.sub,
            name: `${authUser.given_name} ${authUser.family_name}`,
          },
          categoriesFrom,
        };

        if (!!getBodyToSaveInAutomation) {
          getBodyToSaveInAutomation({
            bodyForApi: bodyToSave,
            additionalKeys: {
              categories,
              categoriesName,
              categoriesServiceMap,
            },
          });
          // setConfirmData();
          // handleCancel();
        } else {
          setVisibleCreationProgress(true);
          updateProgressStatus({ updatingRecord: "executing" });

          //Post new invoice data into database
          let res = await API.post("invoices", `/invoices`, {
            //Save data into database
            body: bodyToSave,
          });

          setVisibleCreationProgress(res);
          const newInvoiceBalance =
            res?.totalInvoiceAmount + (accountData?.clientBalance || 0);

          await updateClientBalance({
            accountId: accountData?.accountId,
            totalInvoiceAmount: newInvoiceBalance,
          });

          await updateAllCategories({
            data,
            res,
            categoryData,
            invoiceData,
            convertedData,
            projectData,
          });

          /*******                          *******/

          if ("userId" in res) {
            showSuccessMsg({ content: `Invoice Submitted Successfully` });
            createdInvoiceRetriever(res);
            setSavedRecord(res);
            fetchAndSetInvoices();
            updateProgressStatus({
              updatingRecord: "finished",
              sendingNotification: "executing",
            });
          } else {
            showErrorMsg({
              content: `An error occurred when submitting the invoice!`,
            });
            updateProgressStatus({ updatingRecord: "hasError" });
            return;
          }

          saveAddedLogs({
            recordId: res.invoiceId,
            recordName: `No. ${res?.quickBooksNumber}`,
            topic: res?.projectName,
            category: "Invoice",
          });

          // await sendNotification({
          //   id: "96",
          //   action: "onInvoiceCreation",
          //   team: projectData?.teamsConfiguration || [],
          //   otherKeys: {
          //     topicName: projectData?.accountName,
          //   },
          //   recordId: res?.invoiceId,
          //   authenticatedUser: authUser,
          //   userConfiguration,
          // }).then((notificationSent) => {
          //   updateProgressStatus({
          //     sendingNotification: !!notificationSent ? "finished" : "hasError",
          //   });
          // });
        }
      }
    } catch (err) {
      console.error("error useCreateInvoice handleSave", err);
    } finally {
      await updateBalance({
        projectId: projectData?.projectId,
        accountId: accountData?.accountId,
      });
    }
  };

  return {
    products,
    totalDue,
    subTotal,
    setProducts,
    handleSave,
    productsList,
    setProductsList,
  };
};

export default useCreateInvoice;

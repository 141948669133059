import { message } from "antd";
import moment from "moment";
import { ExportPdfIcon } from "src/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { MondayButton } from "src/components/commonComponents";
import { generatePolygonImage } from "../../../../commonComponents/Map/Geofence/shapesImagesGeneratorForFences";

const GeofenceLogsModalFooter = ({
  setDifferenceLog,
  dynamicLog,
  previousData,
}) => {
  async function handleExportPdfClick() {
    try {
      // Display loading message
      message.loading("Generating PDF...");

      // Format the date
      const formattedDate = moment(dynamicLog?.createdAt)
        .tz("America/New_York")
        .format("MM/DD/YYYY h:mm:ss a");

      // Create a title with formatted details
      const title = {
        text: `Action Type: ${dynamicLog?.action} | User: ${dynamicLog?.createdBy} | Updated At: ${formattedDate}`,
        margin: [0, 20, 0, 20],
        style: {
          fontSize: 14,
          bold: true,
          alignment: "center",
          background: "#CCCCCC",
        },
      };

      // Create content for previous and updated log details
      const logContent = [
        {
          columns: [
            {
              stack: [
                {
                  text: `Type of shape: ${
                    dynamicLog?.action !== "Create"
                      ? dynamicLog?.type || "-||-"
                      : "-||-"
                  }`,
                  alignment: "left",
                },
                {
                  text: `Title of shape: ${
                    dynamicLog?.action !== "Create"
                      ? dynamicLog?.title || "-||-"
                      : "-||-"
                  }`,
                  alignment: "left",
                },
                {
                  text: `Description of shape: ${
                    dynamicLog?.action !== "Create"
                      ? dynamicLog?.description || "-||-"
                      : "-||-"
                  }`,
                  alignment: "left",
                },
              ],
            },
            { width: 10, text: "" },
            {
              stack: [
                {
                  text: `Type of shape: ${dynamicLog?.type || "-||-"}`,
                  alignment: "left",
                },
                {
                  text: `Title of shape: ${dynamicLog?.title || "-||-"}`,
                  alignment: "left",
                },
                {
                  text: `Description of shape: ${
                    dynamicLog?.description || "-||-"
                  }`,
                  alignment: "left",
                },
              ],
            },
          ],
          margin: [0, 5, 0, 0], // margin: [left, top, right, bottom]
        },
        {
          columns: [
            {
              stack: [
                { text: "Previous Values", alignment: "left", bold: true },
                {
                  image: await generatePolygonImage({
                    polygonCoordinates:
                      dynamicLog?.action === "Create"
                        ? [{ lat: undefined, lng: undefined }]
                        : dynamicLog?.action === "edit"
                        ? previousData?.geoFenceInfo
                        : dynamicLog?.geoFenceInfo,
                    // centerFromArgs:
                    //   dynamicLog?.action === "delete" ||
                    //   dynamicLog?.action === "Create"
                    //     ? { lng: -74.00892152484, lat: 40.71626141712765 }
                    //     : undefined,
                  }),
                  width: 250,
                  height: 200,
                },
              ],
            },
            { width: 10, text: "" },
            {
              stack: [
                { text: "Current Values", alignment: "left", bold: true },
                {
                  image: await generatePolygonImage({
                    polygonCoordinates:
                      dynamicLog?.action === "delete"
                        ? [dynamicLog?.geoFenceInfo]
                        : dynamicLog?.geoFenceInfo,
                    // centerFromArgs:
                    //   dynamicLog?.action === "delete" ||
                    //   dynamicLog?.action === "Create"
                    //     ? { lng: -74.00892152484, lat: 40.71626141712765 }
                    //     : undefined,
                  }),
                  width: 250,
                  height: 200,
                },
              ],
            },
          ],
          margin: [0, 20, 0, 20],
        },
      ];

      // Combine the title and log content
      const pdfContent = [title, ...logContent];

      // Define styles for the PDF
      const styles = {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
      };

      // Define the document structure
      const docDefinition = {
        content: pdfContent,
        styles: styles,
      };

      // Create and download the PDF
      pdfMake.createPdf(docDefinition).download("Logs.pdf");
    } catch (error) {
      console.error("Error generating PDF: ", error);
      message.error("Error generating PDF");
    }
  }
  return (
    <div style={{ display: "flex", justifyContent: "space-between" }}>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<ExportPdfIcon />}
        onClick={handleExportPdfClick}
      >
        Export PDF
      </MondayButton>
      <MondayButton
        className="mondayButtonRed"
        onClick={() => setDifferenceLog(false)}
        Icon={<XIcon />}
      >
        Close
      </MondayButton>
    </div>
  );
};

export default GeofenceLogsModalFooter;

import { Tooltip } from "antd";
import React, { useState } from "react";
import CustomDropdown from "../custom-dropdown/CustomDropdown";
import { DynamicFieldsIcon } from "../../../../assets";
import TableFieldModal from "../../../../../../pages/Settings/settingsComponents/EmailTemplate/TabelFieldModal/TableFieldModal";
import {
  getCategory,
  getSingleFields,
  getTableFields,
  showAddedTable,
} from "../footer/EmailBoxFooter/dynamic-fields/dynamic-fields-helpers";
import { useEmailBoxBody } from "../../providers/EmailBoxBodyProvider";
import { useSelector } from "react-redux";
import useDynamicFieldsData from "../../hooks/useDynamicFieldsData";

import "./dynamic-fields.scss";

// Responsible for returning the Dropdown with dynammic fields options
function DynamicFields({ rowData, recordDetails }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  // The  hook which fetches dynammic fields data
  const { dynamicFields, allDynamicFields } = useDynamicFieldsData(
    rowData,
    recordDetails
  );
  const { setEditorContent } = useEmailBoxBody();
  const [selectedTable, setSelectedTable] = useState({
    options: [],
    label: "",
    editor: {},
  });

  const category = getCategory(
    "permitdrawings",
    "Permit Drawings",
    recordDetails
  );

  const items = [
    {
      key: "1",
      label: "Single Fields",
      popupClassName: isDarkMode ? "custom-dropdown-menu-items-dark-mode" : "",
      children: getSingleFields(
        dynamicFields,
        category,
        null,
        rowData,
        setEditorContent,
        null
      ),
    },
    {
      key: "2",
      label: "Table Fields",
      popupClassName: isDarkMode ? "custom-dropdown-menu-items-dark-mode" : "",
      children: getTableFields(
        programFields,
        category,
        setSelectedTable,
        allDynamicFields,
        setEditorContent
      ),
    },
  ];

  return (
    <Tooltip title="Dynamic Fields">
      <CustomDropdown
        items={items}
        overlayClassName="dynamic-fields-anchor-dropdown"
        trigger="click"
      >
        <a
          onClick={(e) => {
            e.preventDefault();
          }}
          className="dynamic-fields-anchor-dropdown"
          data-testid="dynamic-fields-anchor-dropdown"
        >
          <DynamicFieldsIcon className="dynamic-fields-icon" />
        </a>
      </CustomDropdown>

      {selectedTable?.options?.length > 0 && (
        <TableFieldModal
          options={selectedTable}
          setSelectedTable={setSelectedTable}
          getContent={(template) =>
            showAddedTable(
              template,
              programFields,
              recordDetails,
              rowData,
              setEditorContent
            )
          }
        />
      )}
    </Tooltip>
  );
}

export default DynamicFields;

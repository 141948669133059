import { blobToBase64 } from "../../../../../../utils";

export const getImageSrcWithProgress = async (
  fileId,
  progressCallback,
  size,
  getDriveItem,
  currFile,
  onError = () => {}
) => {
  let src, blob, error;

  try {
    const response = await getDriveItem(fileId, { alt: "media" });
    if (!response.body) {
      throw new Error("No response body");
    }
    const reader = response.body.getReader();
    let receivedLength = 0;
    let chunks = [];

    while (true) {
      const { done, value } = await reader.read();
      if (done) {
        break;
      }

      receivedLength += value.length;
      chunks.push(value);

      if (progressCallback) {
        progressCallback(receivedLength, Number(size), currFile);
      }
    }

    const concatenatedChunks = new Uint8Array(receivedLength);
    let position = 0;
    for (let chunk of chunks) {
      concatenatedChunks.set(chunk, position);
      position += chunk.length;
    }

    const res = new Blob([concatenatedChunks], {
      type: response.headers.get("content-type"),
    });
    blob = URL.createObjectURL(res);
    src = await blobToBase64(res);
  } catch (err) {
    console.error("Error:", err);
    error = err;
    onError();
  }

  return { src, blob, error };
};

export const convertObjectUrlToBlob = async (url) => {
  const response = await fetch(url);
  const blob = await response.blob();
  const src = await blobToBase64(blob);
  return { src, blob: url };
};

export const mimeTypesConfig = {
  images: [
    "image/png",
    "image/jpeg",
    "image/bmp",
    "image/webp",
    "image/svg+xml",
  ],
  pdf: ["application/pdf"],
  video: ["video/mp4"],
  documents: [
    "application/pdf",
    "application/msword",
    "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    "text/plain",
    "application/vnd.openxmlformats-officedocument.presentationml.presentation",
  ],
  excel: [
    "application/vnd.ms-excel",
    "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
  ],
};

export const mimeTypeConfigText = {
  images: "image/jpeg,image/png,image/bmp,image/webp,image/svg+xml",
  pdf: "application/pdf",
  documents:
    "application/pdf,application/msword,application/vnd.openxmlformats-officedocument.wordprocessingml.document,application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet,text/plain,application/vnd.openxmlformats-officedocument.presentationml.presentation,application/vnd.ms-powerpoint",
  excel:
    "application/vnd.ms-excel,application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
};

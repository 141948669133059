import { useContext, useState } from "react";
import { useSelector } from "react-redux";
import { Modal, Select, Tooltip, Skeleton, message } from "antd";
import { FilterOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { ClearFilterIcon } from "../../../../../SidebarPages/DynamicView/src";
import {
  FetchAllIcon,
  FilterIcon,
} from "../../../../../SidebarPages/BasePage/src";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import { isDateBetween } from "../../utils/isDateBetween";
import { fetchAllData } from "../../../../../../utils";
import "./LogsCard.scss";

const LogsCard = ({ loading }) => {
  const { allLogs, setAllLogs, filteredLogs, setFilteredLogs } =
    useContext(VideoTutorialContext);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [allLogsFiltered, setAllLogsFiltered] = useState(false);
  const [filterModal, setFilterModal] = useState(false);
  const [filterData, setFilterData] = useState({
    user: "",
    startDate: "",
    endDate: "",
  });

  const userOptions = userConfiguration.allUsers.Items.map((user) => ({
    value: user.cognitoUserId,
    label: user.nameOfUser,
  }));

  const handleFilter = (e) => {
    e.stopPropagation();
    setFilterModal(true);
  };

  const onDateChange = (date, dateString) => {
    setFilterData((prev) => ({
      ...prev,
      startDate: dateString[0],
      endDate: dateString[1],
    }));
  };

  const handleFilterClick = () => {
    const filteredLogs = allLogs.filter(
      (log) =>
        (filterData.user ? log.cognitoUserId === filterData.user : true) &&
        (filterData.startDate
          ? isDateBetween(
              log.updatedAt,
              filterData.startDate,
              filterData.endDate
            )
          : true)
    );

    setFilteredLogs(filteredLogs.slice(0, 10));
    setFilterModal(false);
  };

  const handleClearFilter = () => {
    setFilteredLogs(allLogs.slice(0, 10));
    setFilterModal(false);
    setFilterData({
      user: "",
      startDate: "",
      endDate: "",
    });
  };

  const getAllLogs = async () => {
    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );
    const response = await fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            operator: "AND",
            conditions: [
              {
                column: "category",
                value: "Video Tutorials",
                formula: "is",
              },
            ],
          },
        ]),
      },
    });

    const sortedData = response.sort((a, b) => {
      return b.updatedAt - a.updatedAt;
    });

    setAllLogs(sortedData);
    setFilteredLogs(sortedData.slice(0, 10));
    setAllLogsFiltered(true);
    setFilterData({
      user: "",
      startDate: "",
      endDate: "",
    });
    hideLoading();
  };

  return (
    <div className="logs-container">
      {!loading && (
        <div className="logsCard">
          <p className="text">Last Logs</p>
          <Tooltip title="Get All Logs" placement="top">
            <FetchAllIcon
              data-testid="fetch-icon"
              className="fetch-icon"
              style={{
                cursor: allLogsFiltered ? "not-allowed" : "pointer",
                background: allLogsFiltered ? "#e0e0e0" : "#fff",
              }}
              onClick={() => !allLogsFiltered && getAllLogs()}
            />
          </Tooltip>
          <Tooltip title="Filter" placement="top">
            <FilterOutlined
              data-testid="filter-icon"
              className="filter-icon"
              onClick={(e) => handleFilter(e)}
            />
          </Tooltip>
        </div>
      )}
      <div className="logs-box">
        {loading ? (
          <Skeleton active paragraph={{ rows: 6 }} />
        ) : filteredLogs.length ? (
          filteredLogs.map((log) => (
            <div className="single-log">
              <span>{log.nameOfUser}</span>
              {` watched video `}
              <span>{log?.updatedKeys[0]?.label}</span>
              {` | ${dayjs(log.updatedAt).format("MM/DD/YYYY HH:mm")}`}
            </div>
          ))
        ) : (
          <div className="single-log-noData">
            <span>No Data</span>
          </div>
        )}
      </div>
      {filterModal && (
        <Modal
          className="videoTutorials-filterModal"
          title="Filters"
          open={filterModal}
          onCancel={() => setFilterModal(false)}
          closeIcon={<XIcon />}
          footer={
            <>
              <MondayButton
                className="mondayButtonRed"
                onClick={handleClearFilter}
                {...{ Icon: <ClearFilterIcon /> }}
              >
                Clear Filter
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={handleFilterClick}
                Icon={<FilterIcon height={17} width={17} />}
              >
                Filter
              </MondayButton>
            </>
          }
        >
          <div className="body">
            <label>User</label>
            <InputComponent
              type="select"
              allowClear={false}
              dropdownClassName={isDarkMode && "darkDropDown"}
              placeholder="Select User..."
              defaultValue={filterData.user}
              onChange={(val) =>
                setFilterData((prev) => ({ ...prev, user: val }))
              }
              options={userOptions?.map((option) => (
                <Select.Option value={option.value}>
                  {option.label}
                </Select.Option>
              ))}
            />
            <label>Date</label>
            <InputComponent
              {...{
                type: "rangepicker",
                format: "MM/DD/YYYY",
                style: { width: "100%" },

                ...(filterData?.startDate && {
                  defaultValue: [
                    dayjs(filterData.startDate),
                    dayjs(filterData.endDate),
                  ],
                }),
                onChange: onDateChange,
              }}
            />
          </div>
        </Modal>
      )}
    </div>
  );
};

export default LogsCard;

import { useContext, useEffect, useRef, useState } from "react";
import { getWs, setWs } from "../../sockets/customerWebsockets";
import { useSelector } from "react-redux";
import { WebsocketContext } from "./WebsocketProvider";

export const useCustomerWebsocket = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [wsConnection, setWsConnection] = useState();

  const websocketFunctions = useRef({});

  const finalWsConnection = wsConnection || getWs();

  const currentUser = {
    identityId: userConfiguration?.identityId,
    cognitoUserId: userConfiguration?.cognitoUserId,
    nameOfUser: userConfiguration?.nameOfUser,
  };

  //function to reconnect to ws after 60 seconds
  const reconnectWs = async () => {
    finalWsConnection.close();
    const newConnection = await setWs();

    setWsConnection(newConnection);

    if (!newConnection) {
      let timeout = null;
      timeout = setTimeout(() => {
        reconnectWs();
        clearTimeout(timeout);
      }, 10000);
    }
  };

  const createSession = async (ws) => {
    if (ws.readyState === WebSocket.OPEN) {
      ws.send(
        JSON.stringify({
          request: "createSession",
          body: { ...currentUser, nodeEnv: process.env.NODE_ENV },
        })
      );
    }
  };

  useEffect(() => {
    try {
      if (currentUser) {
        createSession(finalWsConnection);

        finalWsConnection.onmessage = (event) => {
          const msg = JSON.parse(event.data);

          // const splitUrl = finalWsConnection.url.split("/");
          const { type, request } = msg;

          switch (request) {
            case "createSession-res": {
              // console.log(
              //   `Session created in "${splitUrl[splitUrl.length - 1]}"`
              // );
              break;
            }
            default: {
              const callbackFn = websocketFunctions?.current?.[type];
              if (typeof callbackFn === "function") {
                websocketFunctions.current[type](msg);
              }
              break;
            }
          }
        };

        finalWsConnection.onclose = () => {
          reconnectWs();
        };
      }
    } catch (error) {
      console.log("useCustomerWebsocket useEffect error: ", error);
    }
  }, [finalWsConnection, currentUser.cognitoUserId]);

  const sendWsMessage = ({ request, body, type, notification }) => {
    if (request && type && finalWsConnection.readyState === WebSocket.OPEN) {
      finalWsConnection.send(
        JSON.stringify({ request, body, type, user: currentUser })
      );
    }
    if (notification) {
      finalWsConnection.send(
        JSON.stringify({
          type: "notifications",
          request: "new",
          body: notification,
          user: currentUser,
        })
      );
    }
  };

  return {
    websocketFunctions: websocketFunctions.current,
    sendWsMessage,
    finalWsConnection,
  };
};

export const useCustomerWebsocketContext = () => {
  return useContext(WebsocketContext);
};

import { PriceSchemesType } from "../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { pliTaxAmountCalculate } from "../controllers/CellEditingEvents";
import { getMaxNoFeet } from "./getMax";

const availableDimensions = ["width", "height", "length"];

export const priceFormatter = (params) => {
  let value = parseFloat(params.value);
  if (params.value?.toString() === "-") return "-";

  if (params.colDef?.field === "rent") {
    if (isNaN(value) || value === Infinity || value < 0) {
      //number 0 or higher are allowed
      // return "INVALID"
      return "-";
    }
  } else {
    //ppu price
    if (isNaN(value) || value === Infinity || value <= 0) {
      //0 is not allowed
      // return "INVALID"
      return "-";
    }
  }

  return new Intl.NumberFormat("en-US", { style: "currency", currency: "USD" })
    .format(value)
    .substring(1);
};

/**
 * Calculate price from PPU
 * @param serviceId {number}
 * @param rowData {SimplePLI} PLI data in a table row
 * */
export const calculatePrice = (
  serviceId,
  rowData,
  servicePricingConfig = {}
) => {
  console.log("service id row data", serviceId, rowData);
  const { priceSchemesType, priceScheme } = servicePricingConfig;
  const { dimension } = priceScheme || {};

  let height;
  let ppu = parseFloat(rowData.ppu);
  if (isNaN(ppu)) return "-"; //if ppu is not ready
  console.log("servceId", serviceId);
  switch (serviceId) {
    case 1: {
      // sidewalk shed
      let length = forceToNumber(rowData?.length);
      return Math.round(ppu * length);
    }
    case 2: {
      let sqft = parseFloat(rowData.height * rowData.length || 0);
      return Math.round(ppu * sqft);
    }
    // case 3: // hoist
    // case 4: // fence

    case 4: {
      // fence
      return Math.round(ppu * parseFloat(rowData?.length || 0));
    }

    case 5: {
      // roof protection
      return Math.round(
        ppu * parseFloat(rowData?.length) * parseFloat(rowData?.width)
      );
    }
    // case 6: // overhead protection
    case 6: {
      return Math.round(ppu * parseFloat(rowData?.length || 0));
    }
    case 7: {
      // needle beam
      return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
    }
    case 8: // horizontal netting
      return (
        // Math.round(
        //   ppu * getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.width) * 100
        // ) / 100
        Math.round(ppu * getMaxNoFeet(rowData.length))
      );
    case 9: {
      // barriers
      return Math.round(ppu * rowData?.length ?? 0);
    }
    // case 10: // working platform
    case 10: {
      let sqft = parseFloat(rowData.width * rowData.length || 0);
      return Math.round(ppu * sqft);
    }
    case 11: {
      // system scaffold
      return Math.round(ppu * parseFloat(rowData.sqft || 0));
    }
    // case 12: // stair tower
    case 12: {
      let sqft = Math.round(parseFloat(rowData.height * rowData.length || 0));
      return Math.round(ppu * sqft);
    }
    // System Stair
    case 80: {
      let sqft = Math.round(parseFloat(rowData.height * rowData.length || 0));
      return Math.round(ppu * sqft);
    }
    case 13: {
      // shoring
      return Math.round(ppu * parseFloat(rowData?.number_of_shoring || 0));
    }
    case 14: {
      // plywood enclosure
      return (
        Math.round(
          ppu *
            getMaxNoFeet(rowData.length) *
            getMaxNoFeet(rowData.height) *
            100
        ) / 100
      );
    }

    case 15: {
      // debris chute
      return Math.round(ppu * rowData?.length ?? 0);
    }
    // case 16: // turn stiles
    // case 17: // hanging scaffold
    case 17: {
      let sqft = parseFloat(rowData.height * rowData.length || 0);
      return Math.round(ppu * sqft);
    }

    case 18: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.height * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    case 81: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.height * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    case 85: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.height * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    case 19: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.height * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    // case 19: // Window Protection
    // case 20: // Platform Scaffold
    case 20: {
      let sqft = parseFloat(rowData.height * rowData.length || 0);
      return Math.round(ppu * sqft);
    }
    case 44: {
      // roof protection
      return Math.round(
        ppu * parseFloat(rowData?.length * rowData?.width || 0)
      );
    }
    case 69: {
      // Winter Tarp
      return (
        Math.round(
          ppu *
            getMaxNoFeet(rowData.length) *
            getMaxNoFeet(rowData.height) *
            100
        ) / 100
      );
    }
    case 70: {
      // Winter Tarp
      return (
        Math.round(
          ppu *
            getMaxNoFeet(rowData.length) *
            getMaxNoFeet(rowData.height) *
            100
        ) / 100
      );
    }
    case 71: {
      // Winter Tarp
      return (
        Math.round(
          ppu * getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.width) * 100
        ) / 100
      );
    }
    // Mash Netting
    case 72: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.height * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    // Heavy Dutty Netting
    case 73: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.height * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    // Road Plates
    case 79: {
      //vertical netting
      console.log("rowData", rowData);
      return Math.round(ppu * rowData?.width * rowData?.length);
      // if(rowData.length > 0 && rowData.width > 0 && rowData.height > 0)
      // return Math.round(ppu * getMaxNoFeet(rowData.length) * 100) / 100;
      // else return 0
    }
    default:
      if (priceSchemesType !== PriceSchemesType.TABLE) return;

      // returns an array containing the rest of the dimensions
      // e.g. if dimension = "width" the returned array will be [ "height", "length" ]
      const restOfDimensions = availableDimensions.filter(
        (d) => d !== dimension
      );

      //* Gets the dimensions value from the PLI and filters undefined ones (e.g. PLI has only 2 dimensions)
      //* and multiplies them with each other (if they are more than one)
      const dimensionValueToMultiply = restOfDimensions
        .map((dimension) => rowData?.[dimension])
        .filter((value) => value !== undefined)
        .reduce((acc, curr) => acc * curr, 1);

      return ppu * dimensionValueToMultiply;
  }
};
export const calculatePPU = (serviceId, rowData) => {
  let height;
  let price = parseFloat(rowData.price);
  if (isNaN(price)) return "-"; //if price is not ready

  switch (serviceId) {
    case 1:
    case 4:
    case 6:
    case 7:
    case 9:
    case 10:
      return (
        Math.round(
          (price / getMaxNoFeet(rowData.length)) *
            getMaxNoFeet(rowData.width) *
            100
        ) / 100
      );

    case 11:
    case 12: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 80: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 15:
    case 18: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 81: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 85: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 73: {
      return Math.round((price / getMaxNoFeet(rowData.length)) * 100) / 100;
    }
    case 79: {
      return (
        Math.round(
          (price / getMaxNoFeet(rowData.length)) *
            getMaxNoFeet(rowData.width) *
            100
        ) / 100
      );
    }
    case 2: {
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    }
    case 20: {
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    }
    case 5:
    case 44:
    case 8:
    case 14:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.width))) *
            100
        ) / 100
      );
    case 69:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
    case 70:
      return (
        Math.round(
          (price /
            (getMaxNoFeet(rowData.length) * getMaxNoFeet(rowData.height))) *
            100
        ) / 100
      );
      break;
    default:
      break;
  }
};
/**
 * @param service {Service}
 * @param pli {SidewalkShedPLI}
 * */
export const calculateRent = (service, pli, optionIndex) => {
  let total =
    optionIndex === 1
      ? Math.round(pli.price * service.alternateRent) / 100
      : Math.round(pli.price * service.rent) / 100;
  return !!total & !isNaN(total) ? total : 0;
};

export const calculateTaxAmount = (params) => {
  const { pli = {} } = params;
  const { price = 0, taxRate = 0 } = pli;
  return forceToNumber(price) * forceToNumber(taxRate);
};

export const calculateTotalPrice = (pli) => {
  let data = pli || {};
  const { pliAddonTotal = 0, price = 0 } = data;
  const taxAmount = pliTaxAmountCalculate(data);
  let temp1 = forceToNumber(pliAddonTotal);
  let temp2 = forceToNumber(price);
  let temp3 = forceToNumber(taxAmount);

  return temp1 + temp2 + temp3;
};

// const definers = {
//   1: sidewalkShed,
//   2: scaffold,
//   3: hoist,
//   4: fence,
//   5: roofProtection,
//   6: overheadProtection,
//   7: needleBeam,
//   8: horizontalNetting,
//   9: barriers,
//   10: workingPlatform,
//   11: systemScaffold,
//   12: stairTower,
//   13: shoring,
//   14: plywoodEnclosure,
//   15: debrisChute,
//   16: turnStiles,
//   17: hangingScaffold,
//   18: verticalNetting,
//   19: windowProtection,
//   20: platformScaffold,
// };

import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";

/**
 * @typedef LatLng
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef LatitudeLongitude
 * @property {number} latitude
 * @property {number} longitude
 */

/**
 * @typedef Props
 * @property {LatLng|LatitudeLongitude} point
 * @property {Array<LatLng|LatitudeLongitude>} geofence
 * @property {number} [tolerance=300]
 */

const ND_SIN = Math.sin(90);

/**
 *
 * @param {Props} props
 * @returns {{distanceInFeet: number; inRange: boolean}}
 */
function findDistanceFromGeofenceSide({ point, geofence, tolerance = 300 }) {
  /** @type {LatLng} */
  const initPoint = {
    lat: point?.lat || point?.latitude,
    lng: point?.lng || point?.longitude,
  };

  /** @type {LatLng[]} */
  const geofencePoints = geofence.map((e) => ({
    lat: e?.lat || e?.latitude,
    lng: e?.lng || e?.longitude,
  }));

  const pointDistances = geofencePoints.map((e) => ({
    lat: e.lat,
    lng: e.lng,
    ...withinRadius(initPoint, e, tolerance),
  }));
  pointDistances.sort((a, b) => a.distanceInFeet - b.distanceInFeet);

  const nearestPoint = pointDistances?.[0];

  if (!nearestPoint) {
    return {
      distanceInFeet: 0,
      inRange: false,
    };
  }

  let secondNearest = pointDistances?.[1];
  if (!secondNearest) {
    return {
      distanceInFeet: 0,
      inRange: false,
    };
  }

  if (
    secondNearest?.lat === nearestPoint?.lat &&
    secondNearest?.lng === nearestPoint?.lng
  ) {
    secondNearest = pointDistances?.[2];
  }

  const trSide = withinRadius(nearestPoint, secondNearest);

  const angleCos =
    (Math.pow(nearestPoint.distanceInFeet, 2) +
      Math.pow(trSide.distanceInFeet, 2) -
      Math.pow(secondNearest.distanceInFeet, 2)) /
    (2 * nearestPoint.distanceInFeet * trSide.distanceInFeet);

  if (Math.abs(angleCos) > 1) {
    return {
      distanceInFeet: nearestPoint.distanceInFeet,
      inRange: nearestPoint.withinRange,
    };
  }

  const angle = Math.acos(angleCos);

  if (angle >= 90 || angle <= 0) {
    return {
      distanceInFeet: nearestPoint.distanceInFeet,
      inRange: nearestPoint.withinRange,
    };
  }

  const distance = nearestPoint.distanceInFeet * (Math.sin(angle) / ND_SIN);

  return {
    distanceInFeet: distance,
    inRange: distance <= tolerance,
  };
}

export default findDistanceFromGeofenceSide;

import { useState, useContext, Fragment, useEffect } from "react";
import { useSelector } from "react-redux";
import { Form } from "antd";
import { Dayjs } from "dayjs";
import _ from "lodash";
import { Task, ViewMode } from "frappe-gantt-react";

import { DefectType, StoreType, WorkOrderType } from "../../types";
import { MaintenanceViewContext } from "../../data";
import { generateMaintenanceExcel } from "../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { MondayButton } from "src/components/commonComponents";
import CollapseInfoModal from "../CollapseInfoModal/CollapseInfoModal";
import ColorsLegendPopover from "../ColorsLegendPopover/ColorsLegendPopover";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import MaintenanceExportModal from "../MaintenanceExportModal/MaintenanceExportModal";
import { ExcelIconWhite, PdfWhiteIcon } from "src/assets";
import { WarningTriangle } from "src/components/SidebarPages/DynamicView/src";

import "./MaintenanceViewController.scss";

type RangeType = [number, number];

type MaintenanceControllerProps = {
  tasks: Task[];
  viewMode: ViewMode;
  dateRange: RangeType;
  selectedVehicle: string;
  updateViewMode: (mode: ViewMode) => any;
  filterHandler: (newRange: RangeType, vehicle: string) => any;
};

function MaintenanceViewController({
  tasks,
  viewMode,
  dateRange,
  filterHandler,
  updateViewMode,
  selectedVehicle,
}: MaintenanceControllerProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { data } = useContext(MaintenanceViewContext);

  const [warningModal, setWarningModal] = useState(false);
  const [exportModalOpen, setExportModalOpen] = useState(false);

  const [form] = Form.useForm();

  const { defects, inspections, workOrders, inventoryItems, fleet } = data;

  useEffect(() => {
    form.setFieldValue(
      "selectedVehicle",
      selectedVehicle ? selectedVehicle : null
    );
  }, [selectedVehicle]);

  function getFilterHandler() {
    setTimeout(() => {
      const formFields = form.getFieldsValue();
      const selectedRange = formFields["dateRange"] as Dayjs[];
      const newRange = selectedRange.map((e) => e.startOf("day").valueOf());

      filterHandler(newRange as RangeType, formFields["selectedVehicle"] || "");
    }, 0);
  }

  function getSections() {
    const sections = [];

    const allUnmatchedDefects: DefectType[] = tasks.flatMap(
      ({ custom_class, id }) =>
        custom_class.includes("unmatched")
          ? defects.find(({ defectId }) => defectId === id) || []
          : []
    );

    const allOverdueWorkOrders: WorkOrderType[] = tasks.flatMap(
      ({ custom_class, id }) =>
        custom_class.includes("overdue")
          ? workOrders.find(({ workOrderId }) => workOrderId === id) || []
          : []
    );

    const groupedDefects: Record<string, DefectType[]> = _.groupBy(
      allUnmatchedDefects,
      "inspectionId"
    );

    for (const inspectionId in groupedDefects) {
      sections.push({
        title: `Inspection ${inspectionId} - Failed (${groupedDefects[inspectionId][0]["fleetName"]})`,
        items: groupedDefects[inspectionId].map(
          (defect) => `[${defect.defectId}] - ${defect.defectName}`
        ),
      });
    }

    for (const workOrder of allOverdueWorkOrders) {
      sections.push({
        title: `Work Order ${workOrder.workOrderId} - Overdue (${workOrder["fleetName"]})`,
        items: workOrder.workOrderItems.flatMap(
          (defect) => `[${defect.defectId}] - ${defect.defectName}`
        ),
      });
    }

    return sections;
  }

  return (
    <Fragment>
      <Form
        form={form}
        className={`maintenance-controller ${
          isDarkMode ? "maintenance-controller-dark" : ""
        }`}
        initialValues={{
          viewMode,
          dateRange: dateRange.map((e) => dayjsNY(e)),
          selectedVehicle: selectedVehicle || undefined,
        }}
      >
        <div className="controller-left-form">
          {/** @ts-ignore */}
          <InputComponent
            {...{
              label: "",
              showTime: false,
              allowClear: false,
              type: "rangePicker",
              format: "MMM DD, YYYY",
              formItemName: "dateRange",
              onChange: getFilterHandler,
              dropdownClassName: isDarkMode ? "darkDateDropDown" : undefined,
            }}
          />
          {/**@ts-ignore */}
          <InputComponent
            {...{
              label: "",
              type: "select",
              onClear: getFilterHandler,
              onSelect: getFilterHandler,
              placeholder: "Select Vehicle...",
              formItemName: "selectedVehicle",
              dropdownClassName: isDarkMode ? "darkDropDown" : "",
              customOptions: fleet.map((vehicle) => ({
                label: vehicle.fleetName,
                value: vehicle.fleetId,
              })),
            }}
          />
        </div>
        <div className="controller-right-form">
          <ColorsLegendPopover
            sections={["Work Orders", "Inspections", "Defects"]}
            buttonSize={25}
            customSections={[
              {
                name: "Warning",
                statuses: [{ status: "Warning", color: "#fe4c4a" }],
              },
            ]}
          />
          {/** @ts-ignore */}
          <MondayButton
            {...{
              Icon: <WarningTriangle fill="#fff" width={19} />,
              className: "mondayButtonRed",
              onClick() {
                setWarningModal(true);
              },
            }}
          >
            {""}
          </MondayButton>
          {/** @ts-ignore */}
          <MondayButton
            Icon={<ExcelIconWhite />}
            className="mondayButtonBlue"
            onClick={() => {
              generateMaintenanceExcel({
                dateRange,
                defects,
                inspections,
                inventoryItems,
                tasks,
                workOrders,
              });
            }}
          >
            {""}
          </MondayButton>
          {/** @ts-ignore */}
          <MondayButton
            Icon={<PdfWhiteIcon />}
            className="mondayButtonBlue"
            onClick={() => {
              setExportModalOpen(true);
            }}
            data-testid="maintenance-pdf"
          >
            {""}
          </MondayButton>
          {/** @ts-ignore */}
          <InputComponent
            {...{
              label: "",
              type: "select",
              allowClear: false,
              formItemName: "viewMode",
              placeholder: "View mode...",
              onSelect(mode: ViewMode) {
                updateViewMode(mode);
              },
              showSearch: false,
              dropdownClassName: isDarkMode ? "darkDropDown" : "",
              customOptions: [
                { value: ViewMode.QuarterDay },
                { value: ViewMode.HalfDay },
                { value: ViewMode.Day },
                { value: ViewMode.Week },
                { value: ViewMode.Month },
              ],
            }}
          />
        </div>
      </Form>
      {exportModalOpen && (
        <MaintenanceExportModal
          {...{
            dateRange,
            selectedVehicle,
            open: exportModalOpen,
            onCancel() {
              setExportModalOpen(false);
            },
          }}
        />
      )}
      {warningModal && (
        <CollapseInfoModal
          {...{
            open: warningModal,
            sections: getSections(),
            title: "Maintenance Warnings",
            onCancel() {
              setWarningModal(false);
            },
          }}
        />
      )}
    </Fragment>
  );
}

export default MaintenanceViewController;

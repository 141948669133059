export const formatAutomationData = (
  automations,
  programFields = [],
  topicCategories = []
) => {
  const allAutomationActions = programFields.find(
    (prog) => prog.fieldName === "Automation Actions"
  )?.fieldOptions;

  return automations
    .map((automation) => ({
      ...automation,
      automationCategory: automation?.automationCategory?.map((catId) => {
        const category = topicCategories.find(
          ({ categoryId }) =>
            categoryId === catId?.categoryId || categoryId === catId
        );

        return (
          category && {
            label: category.categoryName,
            value: category.categoryName,
            ...category,
          }
        );
      }),
      automationAction: automation?.automationAction?.map((action) => {
        const findedAction = allAutomationActions.find(({ actionId }) =>
          action?.actionId ? action.actionId === actionId : action === actionId
        );
        return (
          findedAction && {
            label: findedAction.actionName,
            value: findedAction.actionName,
            ...findedAction,
            actionRules: [
              ...(findedAction?.actionRules || []),
              ...(findedAction?.defaultRules || []),
            ],
          }
        );
      }),
    }))
    .filter(Boolean);
};

import React, { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Image, Avatar, Spin } from "antd";
import { UserOutlined } from "@ant-design/icons";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { blobToBase64 } from "../../../../../utils";
import { UploadIcon } from "../../../../../Documentation/View/assets";
import styles from "./SingleFileUploaderPreviewer.module.scss";

const SingleFileUploaderPreviewer = ({
  fileId,
  label = "",
  enableUploadPreview = false,
  disabled = false,
}) => {
  const { accessToken } = useSelector((state) => state.accessToken);
  const [userImage, setUserImage] = useState("");
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [isHovered, setIsHovered] = useState(false);

  useEffect(() => {
    const getUserImage = async () => {
      if (!fileId || !accessToken) {
        setLoading(false);
        return;
      }

      let src, blob;
      const driveRequest = driveApi({ accessToken });

      if (!driveRequest) return;

      await driveRequest
        .getDriveItem(fileId, { alt: "media" })
        .then((r) => {
          if (r?.status === 404) {
            setError("File not found");
            setLoading(false);
            return;
          }
          return r.blob();
        })
        .then(async (data) => {
          blob = URL.createObjectURL(data);
          await blobToBase64(data).then((res) => (src = res));
          setUserImage(src);
          setLoading(false);
        })
        .catch((e) => {
          console.error("e", e);
          setError("There was an error on getting the File");
          setLoading(false);
        });
    };

    getUserImage();
  }, [fileId, accessToken]);

  if (loading) {
    return (
      <div className={styles.loadingContainer}>
        <Spin />
      </div>
    );
  }

  if (error || !fileId || !userImage) {
    return (
      <div
        className={styles.fileUploaderWrapper}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      >
        <Avatar
          size={128}
          shape="square"
          icon={<UserOutlined />}
          className={styles.defaultAvatar}
        />
        {enableUploadPreview && isHovered && (
          <div
            className={styles.uploadPreviewOverlay}
            style={{ cursor: disabled ? "not-allowed" : "pointer" }}
          >
            <UploadIcon style={{ fontSize: "24px", marginBottom: "8px" }} />
            <span>{`Upload ${label || "file"}`}</span>
          </div>
        )}
      </div>
    );
  }

  return (
    <div
      className={styles.fileUploaderWrapper}
      onMouseEnter={() => setIsHovered(true)}
      onMouseLeave={() => setIsHovered(false)}
    >
      <Image
        src={userImage}
        alt="Employee Profile Picture"
        preview={!enableUploadPreview}
        className={styles.imagePreview}
      />

      {enableUploadPreview && isHovered && (
        <div
          className={styles.uploadPreviewOverlay}
          style={{ cursor: disabled ? "not-allowed" : "pointer" }}
        >
          <UploadIcon style={{ fontSize: "24px", marginBottom: "8px" }} />
          <span>{`Upload ${label || "your file"}`}</span>
        </div>
      )}
    </div>
  );
};

export default SingleFileUploaderPreviewer;

import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { API } from "aws-amplify";
import { message } from "antd";

import { LoadableComp } from "../../XComponents";
import Report from "../../DynamicView/Pages/Overview/components/Report/Report";
import { queryParams } from "src/utils/queryParams";
import NewSafetyModal from "./NewSafetyModal";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";
import { openEmailBox } from "../../Communication/functions";
import useRecordData from "../../Communication/hooks/use-record-data";

import "./SafetyModal.scss";
/**
 * SafetyModal component displays a modal for managing safety data.
 *
 * @component
 * @param {Function} refreshTable - Function to refresh the table.
 * @param {Function} setEdit - Function to set the edit mode.
 * @param {string} category - The category of the safety data.
 * @param {Object} safetyData - The safety data object.
 * @param {Function} updateIncidentStatus - Function to update the incident status.
 * @param {boolean} isNextStep - Indicates if it's the next step.
 * @param {string} propProjId - The project ID.
 * @param {string} propAddress - The project address.
 * @param {Function} nextStepHandler - Function to handle the next step.
 * @param {Function} handleSavedRecord - Function to handle the saved record.
 * @returns {JSX.Element} The rendered SafetyModal component.
 */
function SafetyModal({
  refreshTable = () => {},
  setEdit = null,
  category = null,
  safetyData,
  updateIncidentStatus = () => {},
  isNextStep,
  propProjId,
  propAddress,
  nextStepHandler,
  handleSavedRecord,
}) {
  const navigate = useNavigate();
  const location = useLocation();
  const [rowObject, setRowObject] = useState(
    safetyData ? safetyData : location?.state?.rowObject || undefined
  );
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const recordName = topicCategories?.find(
    (c) => c.categoryName === "Safety"
  )?.apiName;
  const recordId = location.pathname.replace("/safety/", "");

  useRecordData(recordName, recordId, location?.state?.rowObject);

  const [cat, setCat] = useState(
    category || rowObject?.safetyApplicationCategory
  );

  // const [visibleReport, setVisibleReport] = useState(false);
  // const [attachments, setAttachments] = useState([]);
  // const [emailBoxes, setEmailBoxes] = useState([]);
  const [documentationModalFiles, setDocumentationModalFiles] = useState(null);

  async function getSingleSafetyData() {
    message.loading({
      content: "Loading Form...",
      key: "modalLoading",
      duration: 0,
    });
    if (!category && !rowObject) {
      let id = location.pathname.replace("/safety/", "");
      API.get("safety", `/safety/${id}`)
        .then((res) => {
          setRowObject(res);
          setCat(res?.safetyApplicationCategory);
          setDocumentationModalFiles(null);
          message.destroy("modalLoading");
        })
        .catch((err) => {
          console.error("Error opening safety form : ", err);
          message.destroy("modalLoading");
          message.error({
            content: "Something went wrong!",
          });
          !!!isNextStep && navigate("/safety");
        });
    }
  }

  useEffect(() => {
    if (!category && !rowObject) {
      getSingleSafetyData();
    }
  }, []);

  // const ModalType = modalType[cat];

  const setVisible = () => {
    if (!!!isNextStep)
      if (location?.length === 1) {
        navigate("/safety", { replace: true });
      } else if (!category && !location?.state?.rowObject) {
        navigate("/safety", {
          state: { search: queryParams({ tab: cat }) },
        });
      } else if (!!setEdit) {
        setEdit(false);
      } else {
        navigate(-1);
      }
    else setEdit(false);
  };

  return (
    <LoadableComp loading={!cat}>
      {/* <ModalType
        {...{
          visible: true,
          setVisible() {
            if (!!!isNextStep)
              if (location?.length === 1) {
                navigate("/safety", { replace: true });
              } else if (!category && !location?.state?.rowObject) {
                navigate("/safety", {
                  state: { search: queryParams({ tab: cat }) },
                });
              } else if (!!setEdit) {
                setEdit(false);
              } else {
                navigate(-1);
              }
            else setEdit(false);
          },
          refreshTable,
          rowObject,
          setVisibleReport,
          updateIncidentStatus,
          propProjId,
          propAddress,
          nextStepHandler,
          handleSavedRecord,
        }}
      /> */}

      <NewSafetyModal
        {...{
          visible: true,
          getSingleSafetyData,
          setVisible,
          refreshTable,
          rowObject,
          // setVisibleReport,
          updateIncidentStatus,
          propProjId,
          propAddress,
          nextStepHandler,
          handleSavedRecord,
          safetyCategory: cat,
          documentationModalFiles,
          setDocumentationModalFiles,
        }}
      />
    </LoadableComp>
  );
}
export default SafetyModal;

import parse from "html-react-parser";
import { formatFloorHeight, parseComparedHtmlWithKey } from "../../utils";

export const serviceColumnDefs = (objectToCompare) => [
  {
    headerName: "Approved",
    field: "approved",

    cellRendererFramework: (params) => {
      return <input type="checkbox" checked={params.value} disabled={true} />;
    },
  },
  {
    headerName: "L",
    field: "length",
  },
  {
    headerName: "H",
    field: "height",
  },
  {
    headerName: "Facade H",
    field: "height",
  },
  {
    headerName: "Addons",
    field: "addons",

    cellRendererFramework: (params) => {
      return <>{params.value?.length}</>;
    },
  },
  {
    headerName: "Description",
    field: "description",
    cellRendererFramework: (params) => {
      return <>{params?.value?.note ? parse(params?.value?.note) : ""}</>;
    },
    cellStyle: (params) => {
      let objectValue =
        objectToCompare?.[params.rowIndex]?.[params.colDef.field];

      return parseComparedHtmlWithKey(params.value, objectValue, "note");
    },
  },
  {
    headerName: "Internal Note",
    field: "note",
    cellRendererFramework: (params) => {
      return <>{params?.value?.note ? parse(params?.value?.note) : ""}</>;
    },
    cellStyle: (params) => {
      let objectValue =
        objectToCompare?.[params.rowIndex]?.[params.colDef.field];

      return parseComparedHtmlWithKey(params.value, objectValue, "note");
    },
  },
  {
    headerName: "Sqf.",
    field: "sqft",
  },
  {
    headerName: "PPU",
    field: "ppu",
  },
  {
    headerName: "Rent",
    field: "rent",
  },
  {
    headerName: "Price",
    field: "price",
  },
  {
    headerName: "Tax Rate",
    field: "taxRate",
    valueFormatter: ({ value = 0 }) => `${value * 100}%`,
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
  },
  {
    headerName: "Total Price",
    field: "totalPrice",
  },
];

export const serviceHoistColumnDefs = (objectToCompare) => [
  {
    headerName: "Floor",
    field: "floor",

    cellRendererFramework: (params) => {
      return (
        <>
          {params.value === -2
            ? "sub-cellar"
            : params.value === -1
            ? "cellar"
            : params.value}
        </>
      );
    },
  },
  {
    headerName: "Stop",
    field: "stop",
    cellRendererFramework: (params) => {
      return <input type="checkbox" checked={params.value} disabled={true} />;
    },
  },
  {
    headerName: "Dual Stop",
    field: "dual",
    cellRendererFramework: (params) => {
      return <input type="checkbox" checked={params.value} disabled={true} />;
    },
  },
  {
    headerName: "Floor Height",
    field: "floor_height",
    cellRendererFramework: (params) => {
      return <>{params.value} ft</>;
    },
  },
  {
    headerName: "Floor Height (converted)",
    field: "floor_height_converted",
    cellRendererFramework: (params) => {
      return (
        <>
          {params.data.floor_height_converted
            ? `${params.value} ft`
            : formatFloorHeight(params.data.floor_height)}
        </>
      );
    },
  },
  {
    headerName: "Locked",
    field: "locked",
    cellRendererFramework: (params) => {
      return <input type="checkbox" checked={params.value} disabled={true} />;
    },
  },
  {
    headerName: "Addons",
    field: "addons",
    cellRendererFramework: (params) => {
      return <>{params.value?.length}</>;
    },
  },
  {
    headerName: "Description",
    field: "description",
    cellRendererFramework: (params) => {
      return <>{params?.value?.note ? parse(params?.value?.note) : ""}</>;
    },
    cellStyle: (params) => {
      let objectValue =
        objectToCompare?.[params.rowIndex]?.[params.colDef.field];

      return parseComparedHtmlWithKey(params.value, objectValue, "note");
    },
  },
  {
    headerName: "Internal Note",
    field: "note",
    cellRendererFramework: (params) => {
      return <>{params?.value?.note ? parse(params?.value?.note) : ""}</>;
    },
    cellStyle: (params) => {
      let objectValue =
        objectToCompare?.[params.rowIndex]?.[params.colDef.field];

      return parseComparedHtmlWithKey(params.value, objectValue, "note");
    },
  },
];

export const includesColumnDefs = [
  {
    headerName: "ID",
    autoHeight: false,
    flex: 1,
    valueGetter: "node.rowIndex + 1",
    cellStyle: () => null,
  },
  {
    headerName: "Include",
    field: "name",
    width: 220,
  },
];

export const excludesColumnDefs = [
  {
    headerName: "ID",
    autoHeight: false,
    flex: 1,
    valueGetter: "node.rowIndex + 1",
    cellStyle: () => null,
  },
  {
    headerName: "Exclude",
    field: "name",
    width: 220,
  },
];

import { useState } from "react";
import { CircleF, OverlayViewF, PolygonF } from "@react-google-maps/api";

import { JobsiteInfoWindow } from "../";
import { Marker } from "src/components/commonComponents";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";

interface Props {
  data: JobsiteType;
  onClick?: () => void;
  key?: string | number;
}

const circleOptions = {
  strokeWeight: 2,
  fillOpacity: 0.2,
  fillColor: "#4e8afa",
  strokeColor: "#0059ff",
};

const polygonOptions = {
  strokeWeight: 2,
  fillOpacity: 0.4,
  fillColor: "#d99f00",
  strokeColor: "#d99f00",
};

function JobsiteMarker(props: Props) {
  const { key, data } = props;
  const [showInfo, setShowInfo] = useState<boolean>(false);

  function onToggleInfo() {
    setShowInfo((prev) => !prev);
  }

  return (
    <OverlayViewF
      zIndex={2}
      position={data.addressPosition}
      mapPaneName={"overlayMouseTarget"}
    >
      <Marker
        showIcon
        key={key}
        color={"#fe4c4a"}
        onClick={onToggleInfo}
        address={data.jobAddress}
        coordinates={data.addressPosition}
      />
      {!!data.locationRadius && (
        <CircleF
          options={circleOptions}
          center={data.addressPosition}
          radius={
            typeof data?.locationRadius === "string"
              ? parseFloat(data?.locationRadius) * 0.3048
              : data?.locationRadius * 0.3048
          }
        />
      )}
      {!!data?.geoFenceInfo?.length &&
        data?.geoFenceInfo?.map((geofence) => {
          return (
            <PolygonF
              options={{
                ...polygonOptions,
                editable: false,
                draggable: false,
              }}
              path={geofence?.geoFenceInfo}
            />
          );
        })}
      {showInfo ? (
        <JobsiteInfoWindow data={data} onClick={onToggleInfo} />
      ) : null}
    </OverlayViewF>
  );
}

export default JobsiteMarker;

import {
  useState,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { useLoadScript } from "@react-google-maps/api";

import {
  loadLivePreference,
  saveLocalPreferences,
  FleetsLiveContext,
} from "../../utils";
import { updateDocumentTitle } from "src/utils";
import { ACTIVE_VIEW_DATA, LIVE_LIBRARIES } from "../../data";

import "./FleetLiveActiveView.scss";

const FleetLiveActiveView = forwardRef((_, ref) => {
  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const { setMapLoaded } = useContext(FleetsLiveContext);

  const [activeView, setActiveView] = useState(
    loadLivePreference("pageLoaded")
  );

  const { isLoaded } = useLoadScript({
    id: "google-map-script",
    googleMapsApiKey: (hotCredentials ?? []).find(
      (e) => e.credentialKey === "GOOGLE MAPS KEY"
    )?.credentialValue,
    libraries: LIVE_LIBRARIES,
  });

  useEffect(() => {
    setMapLoaded(isLoaded);
  }, [isLoaded]);

  useImperativeHandle(
    ref,
    () => {
      return {
        changeMenuView(view) {
          setActiveView(view);
          saveLocalPreferences({ pageLoaded: view });

          let tabName = view.toLowerCase().split("_");
          let newSubTitle = ``;
          for (const part of tabName) {
            newSubTitle =
              newSubTitle + part.charAt(0).toUpperCase() + part.slice(1) + ` `;
          }
          newSubTitle = newSubTitle.trim();

          updateDocumentTitle({ newTitle: "Fleets Live", newSubTitle });
        },
      };
    },
    [activeView]
  );

  const View = ACTIVE_VIEW_DATA[activeView];

  return (
    <div className="live-active-view">
      <View />
    </div>
  );
});

export default FleetLiveActiveView;

import { parseInTz } from "./dateFunctions";
import { getAccessRights } from "./dispatchFunctions";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { MaxTagTooltip } from "../../../../../../commonComponents";

export function headerFields({
  routeLength,
  defaultData,
  rowObject,
  requestObject,
  trucks,
  onTruckSelect,
  filterTrucks,
  stepperParams,
  onStatusChange,
  form,
  allTeams,
  setSelectedTeam,
  accessRight,
  onDateChange,
  headerDisabled,
}) {
  const selectedDate = parseInTz(form.getFieldValue("date"))
    .startOf("day")
    .valueOf();

  return [
    {
      className: "dispatchDateSelect",
      label: "Date",
      formItemName: "date",
      dataTestid: "dispatch-date",
      disabled:
        routeLength > 1 ||
        !!defaultData ||
        !!rowObject?.dispatchId ||
        !!requestObject ||
        headerDisabled?.date,
      type: "datePicker",
      getPopupContainer: document.body,
      allowClear: false,
      initialValue:
        dayjsNY(defaultData?.schedule?.scheduleDays[0]?.startDate).startOf(
          "day"
        ) || dayjsNY(),
      onChange: onDateChange,
    },
    {
      label: "Truck",
      type: "select",
      formItemName: "truckNumber",
      className: "dispatchTruckNumberSelect",
      placeholder: "Select...",
      getPopupContainer: document.body,
      initialValue: defaultData?.fleetId || null,
      required: true,
      showSearch: true,
      dataTestid: "dispatch-vehicle",
      disabled:
        routeLength > 1 ||
        defaultData?.fleetId ||
        headerDisabled?.vehicle ||
        requestObject?.vehicle ||
        rowObject?.dispatchId,
      onSelect(val) {
        onTruckSelect(val);
      },
      customOptions: (filterTrucks || trucks)?.flatMap((truck, key) => {
        if (
          (truck?.inactivityTimeline || []).some(
            ({ start, end }) =>
              dayjsNY(start).startOf("D").valueOf() <= selectedDate &&
              dayjsNY(end || undefined)
                .startOf("D")
                .valueOf() > selectedDate
          )
        ) {
          return [];
        }

        if (
          truck?.fleetStatus === "Terminated" ||
          truck?.fleetStatus === "Out Of Service"
        ) {
          return [];
        }

        return {
          key,
          value: truck?.fleetId,
          label: truck?.fleetName,
        };
      }),
    },
    {
      className: "dispatchStatusSelect",
      label: "Status",
      type: "select",
      formItemName: "dispatchStatus",
      dataTestid: "dispatchStatus",
      showSearch: false,
      allowClear: false,
      getPopupContainer: document.body,
      customOptions: stepperParams.steps.map(({ title }) => ({ value: title })),
      onSelect(e) {
        onStatusChange(e);
      },
    },
    getAccessRights(accessRight, "Teams")?.write && {
      label: "Team",
      required: true,
      formItemName: "dispatchTeam",
      placeholder: "Select a team...",
      type: "select",
      mode: "multiple",
      dataTestid: "dispatch-team",
      showSearch: true,
      allowClear: false,
      getPopupContainer: document.body,
      customOptions: allTeams?.map((team) => ({
        label: team.teamName,
        value: team.teamName,
        members: team.members?.map(
          ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
            identityId,
            nameOfUser,
            cognitoUserId,
          })
        ),
      })),
      onSelect(_, data) {
        setSelectedTeam((prev) => [
          ...prev,
          {
            value: data?.value,
            members: data?.members?.map((e) => e),
          },
        ]);
      },
      onDeselect(val, data) {
        const teamLabel = data?.value || val;
        setSelectedTeam((prev) =>
          prev?.filter((el) => el?.value !== teamLabel)
        );
        if (form.getFieldValue("dispatchTeam")?.length < 1) {
          setSelectedTeam([]);
        }
      },
    },
  ].filter(Boolean);
}

import { ExportPreview } from "../../../../commonComponents";
import { safetyContactsStyles } from "../../../Projects/ProjectSafetyReport/helpers";
import AddSafetyContacts from "../../SafetyModal/components/SafetyExportPreview/components/AddSafetyContacts";
import SortReportFields from "../../SafetyModal/components/SafetyExportPreview/components/SortReportFields";
import {
  convertSafetyReport,
  initialExportData,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "../../SafetyModal/components/SafetyExportPreview/helpers";

function SafetyInspectionExportPreview({
  showPdfPreview,
  setShowPdfPreview,
  rowObject,
  userConfiguration,
  accessToken,
  inspectionCategory,
  fieldsJSON,
  selectedTeam,
  crewMembers,
  foremen,
  fleet,
  drivers,
  currentProject,
}) {
  return showPdfPreview ? (
    <ExportPreview
      {...{
        open: showPdfPreview,
        onCancel: () => setShowPdfPreview(false),
        modalTitle: "Incident Report",
        documentTitle: rowObject?.inspectionName,
        getInitialData: async () => {
          return await initialExportData({
            modalType: "safetyInspection",
            accessToken,
            userConfiguration,
            rowObject,
            safetyCategory: inspectionCategory,
            selectedTeam,
            safetyFormData: { crewMembers, foremen, fleet, drivers },
            formFields: fieldsJSON,
            currentProject,
          });
        },
        keyName: "safetyInspection " + inspectionCategory,
        asyncInitialData: true,
        applyFilters: safetyExportApplyFilters,
        convertData: convertSafetyReport,
        tabs: [
          {
            key: "sort",
            label: "Modify Fields",
            component: SortReportFields,
          },
          currentProject?.projectLatitude && {
            key: "safetyContacts",
            label: "Safety Contacts",
            component: AddSafetyContacts,
          },
        ].filter(Boolean),
        customStyles: { ...SAFETY_REPORT_STYLES, ...safetyContactsStyles },
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text: rowObject?.inspectionName + " Report",
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
          {
            text: {
              paragraphs: [
                {
                  text: "Date: " + new Date().toLocaleDateString(),
                  class: "safety-section-header no-margin",
                  bold: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default SafetyInspectionExportPreview;

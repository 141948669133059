import { Tooltip } from "antd";

import { EditIcon } from "../../../../../../icons";
import { TrashIcon } from "../../../../../SidebarPages/Communication/assets";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";

const columnDefs = ({ writeAccess, onEditClick, onDeleteTeam, isDarkMode }) => {
  const cellWidth = (window.innerWidth - 310) / 5;
  return [
    {
      headerName: "Team Name",
      field: "crewTeamName",
      cellClass: "cell-left-aligned",
      minWidth: cellWidth,
    },
    {
      headerName: "Foreman",
      field: "crewForeman",
      cellClass: "cell-left-aligned",
      minWidth: cellWidth,
      valueGetter: ({ data }) => {
        return data?.crewForeman?.crewName;
      },
    },
    {
      headerName: "Company",
      field: "company",
      cellClass: "cell-left-aligned",
      minWidth: cellWidth,
    },
    {
      headerName: "Members",
      field: "crewMembers",
      minWidth: cellWidth,
      cellRenderer: ({ value, data }) => {
        return (
          <DynamicAvatars
            {...{
              title: `${data?.crewTeamName} - Team`,
              isDarkMode: isDarkMode,
              members: data?.crewMembers,
              crew: { ...data?.crewForeman, createdBy: data?.createdBy },
              nameKey: "crewName",
              idKey: "crewId",
            }}
          />
        );
      },
    },
    writeAccess && {
      headerName: "Edit",
      minWidth: 130,
      cellClass: "cell-center-aligned",
      cellRenderer: ({ data }) => {
        return (
          // <MondayButton
          //   hasIcon={false}
          //   className={"mondayButtonBlue"}
          //   onClick={() => onEditClick(data)}
          // >
          //   Edit
          // </MondayButton>
          <Tooltip title="Edit">
            <div
              style={{
                width: 32,
                height: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => onEditClick(data)}
            >
              <EditIcon fill="#1264A3" />
            </div>
          </Tooltip>
        );
      },
    },
    writeAccess && {
      headerName: "Delete",
      enableRowGroup: false,
      cellClass: "cell-center-aligned",
      pdfExportOptions: {
        skipColumn: true,
      },
      cellRenderer: ({ data }) => {
        return (
          <Tooltip title="Delete">
            <div
              style={{
                width: 32,
                height: 32,
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
                cursor: "pointer",
              }}
              onClick={() => onDeleteTeam(data)}
            >
              <TrashIcon fill="#FE4C4A" />
            </div>
          </Tooltip>
        );
      },
    },
  ].filter(Boolean);
};

export default columnDefs;

import dayjs from "dayjs";

export function fieldsJSON({
  onChangeCargoValue,
  driversOptions,
  disabled,
  isDarkMode,
  fleet,
  setSelectedFleet,
  setSelectedTeam,
  form,
  users,
  cargoWeightValue,
  returningWeightValue,
  pickUpLocation,
  rowObject,
  setCorrectOptionsToPaperwork,
  setSoftwareOptions,
  collectOptions,
  softwareOptions,
  fleetActivityTeam,
  selectedTeam,
  onSelect,
  onDeselect,
  getAccessRights,
}) {
  return [
    {
      key: 0,
      label: "Cargo",
      formItemName: "cargo",
      placeholder: "Select...",
      type: "radio",
      required: true,
      buttonStyle: "solid",
      onChange: onChangeCargoValue,
      options: ["Empty", "Loaded"],
    },
    {
      label: "Driver",
      formItemName: "driver",
      disabled:
        disabled || window.location.pathname.includes("/fleet/drivers/"),
      placeholder: "Select...",
      type: disabled ? "input" : "select",
      dropdownClassName: isDarkMode && "darkDropDown",
      showSearch: true,
      required: true,
      options: driversOptions,
    },
    {
      label: "Truck",
      formItemName: "fleet",
      disabled:
        disabled ||
        (window.location.pathname.includes("/fleets/") &&
          !window.location.pathname.includes("/fleets/overview")),
      placeholder: "Select...",
      type: "select",
      dropdownClassName: isDarkMode && "darkDropDown",
      showSearch: true,
      required: true,
      onSelect: (value) => {
        let selectedFleet = fleet?.find((truck) => truck?.fleetName === value);
        setSelectedFleet(selectedFleet);
        if (selectedFleet) {
          setSelectedTeam(selectedFleet?.teamsConfiguration || []);
          form.setFieldValue(
            "activityTeam",
            (selectedFleet?.teamsConfiguration || [])?.map(
              (item) => item?.value
            )
          );
        }
      },
      options: fleet?.map(({ fleetName }) => fleetName),
    },
    {
      label: "Date",
      formItemName: "date",
      initialValue: dayjs(),
      type: "datepicker",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      disabled,
      required: true,
    },
    {
      label: "Dispatched by",
      formItemName: "dispatchedBy",
      disabled,
      type: disabled ? "input" : "select",
      dropdownClassName: isDarkMode && "darkDropDown",
      showSearch: true,
      required: true,
      options: users?.map(({ nameOfUser }) => nameOfUser),
      placeholder: "Select...",
    },
    {
      label: "Cargo Weight",
      formItemName: "cargoWeight",
      placeholder: "Cargo Weight",
      disabled: form.getFieldValue("cargo") === "Empty",
      type: "input",
      typeNumber: true,
      initialValue: cargoWeightValue,
    },
    {
      label: "Returning Weight",
      formItemName: "returningWeight",
      placeholder: "Returning Weight",
      disabled: form.getFieldValue("cargo") === "Empty",
      type: "input",
      typeNumber: true,
      initialValue: returningWeightValue,
    },
    {
      label: "Starting Time",
      formItemName: "startingTime",
      type: "timepicker",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      required: true,
    },
    {
      label: "Time Scheduled",
      formItemName: "timeScheduled",
      disabled: true,
    },
    {
      label: "Time at Location",
      formItemName: "timeAtLocation",
      type: "timepicker",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      onChange: (value) => {
        const duration = !!form.getFieldValue("timeExitingTheLocation")
          ? dayjs
              .utc(
                dayjs
                  .duration(
                    dayjs(form.getFieldValue("timeExitingTheLocation")).diff(
                      dayjs(value)
                    )
                  )
                  .asMilliseconds()
              )
              .format("HH:mm")
          : "";

        !!pickUpLocation &&
          !!form.getFieldValue("timeAtLocation") &&
          form.setFieldsValue({
            layover: duration,
          });

        !pickUpLocation &&
          !!form.getFieldValue("timeExitingTheLocation") &&
          form.setFieldsValue({
            layover: duration,
          });
      },
    },
    {
      label: "Time exiting Location",
      formItemName: "timeExitingTheLocation",
      onChange: (value) => {
        const duration = !!form.getFieldValue("timeAtLocation")
          ? dayjs
              .utc(
                dayjs
                  .duration(
                    dayjs(value).diff(
                      dayjs(form.getFieldValue("timeAtLocation"))
                    )
                  )
                  .asMilliseconds()
              )
              .format("HH:mm")
          : "";

        !!pickUpLocation &&
          !!form.getFieldValue("timeAtLocation") &&
          form.setFieldsValue({
            layover: duration,
          });

        !pickUpLocation &&
          !!form.getFieldValue("timeAtLocation") &&
          form.setFieldsValue({
            layover: duration,
          });
      },
      type: "timepicker",
      dropdownClassName: isDarkMode && "darkDateDropDown",
      required: false,
    },
    {
      label: "Layover",
      formItemName: "layover",
      placeholder: "Layover",
      disabled: false,
    },
    {
      label: "Paperwork type",
      placeholder: "Paperwork type",
      type: !!rowObject?.paperworkType ? "input" : "select",
      dropdownClassName: isDarkMode && "darkDropDown",
      formItemName: "paperworkType",
      required: true,
      customOptions: [{ value: "Inbound" }, { value: "Outbound" }],
      disabled: !!rowObject?.paperworkType,
      onSelect: (value) => {
        setCorrectOptionsToPaperwork(value, form.getFieldValue("cargo"));
      },
    },
    {
      label: "Paperwork collect status",
      placeholder: "Paperwork collect status",
      formItemName: "paperworkCollectStatus",
      dropdownClassName: isDarkMode && "darkDropDown",
      required: true,
      type: "select",
      initialValue: rowObject?.paperworkCollectStatus ?? "",
      customOptions: collectOptions,
      onSelect: (value) => {
        if (
          value === "Same Truckload" ||
          value === "Cancelled" ||
          value === "Not Required"
        ) {
          form.setFieldValue("paperworkSoftwareStatus", "Not Required");
          setSoftwareOptions([{ value: "Not Required" }]);
        } else {
          form.setFieldValue("paperworkSoftwareStatus", "Pending");
          setSoftwareOptions([{ value: "Pending" }, { value: "Entered" }]);
        }
      },
    },
    {
      label: "Paperwork software status",
      placeholder: "Paperwork software status",
      formItemName: "paperworkSoftwareStatus",
      dropdownClassName: isDarkMode && "darkDropDown",
      type: "select",
      initialValue: rowObject?.paperworkSoftwareStatus ?? "",
      required: true,
      customOptions: softwareOptions,
    },
    getAccessRights("Teams")?.write && {
      label: "Teams",
      placeholder: "Select team...",
      formItemName: "activityTeam",
      dropdownClassName: isDarkMode && "darkDropDown",
      type: "select",
      mode: "multiple",
      required: fleetActivityTeam?.length,
      customOptions: fleetActivityTeam,
      initialValue: selectedTeam?.map((team) => team?.value),
      onSelect: onSelect,
      onDeselect: onDeselect,
    },
  ].filter(Boolean);
}

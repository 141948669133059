import { groupBy } from "lodash";
import { v4 as uuid } from "uuid";

import { CrewTeamType, ExcelDataType } from "../payrollLiveTypes";
import { EmployeeType } from "src/components/SidebarPages/FleetMaintenanceView/types";

type ClientType = {
  configId: string;
  clientName: string;
  clientSource: string;
  clientKey: {
    encryptedData: string;
    iv: string;
  };
};

type Props = {
  selectedClient: ClientType;
  employees: Array<EmployeeType>;
  defaultExcelData: Array<ExcelDataType>;
};

export function extractTeamsFromExcel({
  employees,
  selectedClient,
  defaultExcelData,
}: Props): Array<CrewTeamType> {
  const crews = [];
  const groupedTeams = groupBy(defaultExcelData, "Crew");

  for (const crewName in groupedTeams) {
    const teamMembers = groupedTeams[crewName];
    const fIndex = employees.findIndex(
      (el) =>
        el?.employeeId ===
        `${selectedClient?.clientName}-${teamMembers?.[0]?.["ID Number"]}`
    );
    const foreman = employees?.[fIndex];
    const crewForeman = {
      crewId: foreman?.crewId,
      crewName: foreman?.crewName || teamMembers?.[0]?.Name,
      employeeId:
        foreman?.employeeId ||
        `${selectedClient?.clientName}-${teamMembers[0]["ID Number"]}`,
    };

    const crewMembers = teamMembers.slice(1).map((member) => {
      const memIndex = employees.findIndex(
        (el) =>
          el?.employeeId ===
          `${selectedClient?.clientName}-${member?.["ID Number"]}`
      );
      return {
        crewId: employees[memIndex]?.crewId,
        crewName: employees[memIndex]?.crewName || member?.Name,
        employeeId:
          employees[memIndex]?.employeeId ||
          `${selectedClient?.clientName}-${member["ID Number"]}`,
      };
    });

    const teamObject = {
      company: selectedClient?.clientName,
      crewForeman,
      crewMembers,
      crewTeamName: crewName,
      crewTeamId: uuid(),
    };
    crews.push(teamObject);
  }
  return crews;
}

import { MondayButton, WarningModal } from "../../../../../commonComponents";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { onSave } from "./helpers";
import { TickIcon } from "../../Roles/src";
import { WarningTriangle } from "../../../../../SidebarPages/DynamicView/src";
import "./NewNonUserModal.scss";
import { useEditLogs } from "../../../../../../hooks";

const WarningSection = ({
  warningModal,
  setWarningModal,
  userConfiguration,
  form,
  setEditLogs,
  setModalVisible,
  isDarkMode,
  setSelectedRow,
}) => {
  const { saveAddedLogs } = useEditLogs();

  return (
    <WarningModal
      {...{
        visible: warningModal?.visible,
        setVisible: () =>
          setWarningModal((prev) => {
            return {
              ...prev,
              visible: false,
            };
          }),
        title: "Warning Message",
        className: "logout-warning-modal eng-arch-warning",
        closable: true,
        darkMode: isDarkMode,
      }}
    >
      <div className="logout-modal-body">
        {/* <span> */}
        <WarningTriangle />
        <p>{warningModal?.message}</p>
        {/* </span> */}
      </div>
      <div className="buttons">
        <MondayButton
          {...{
            onClick: () => {
              setWarningModal((prev) => {
                return {
                  message: "",
                  type: "",
                  visible: false,
                };
              });
            },
            className: "mondayButtonRed",
            Icon: <XIcon />,
          }}
        >
          No
        </MondayButton>
        <MondayButton
          {...{
            onClick: () => {
              if (warningModal?.type === "save") {
                onSave({
                  form,
                  setModalVisible,
                  userConfiguration,
                  setEditLogs,
                  // setRowData,
                  saveAddedLogs,
                });
              } else {
                setModalVisible(false);
              }
              setSelectedRow();
              setWarningModal({
                message: "",
                type: "",
                visible: false,
              });
            },
            Icon: <TickIcon width={17} height={17} />,
          }}
        >
          Yes
        </MondayButton>
      </div>
    </WarningModal>
  );
};

export default WarningSection;

import { fetchData, lazyFetch } from "../../../../../utils";

const schedulingApiData = async () =>
  fetchData({
    endpoint: "scheduling",
    queryStringParams: {
      queryStringParameters: {
        keysToInclude: [
          JSON.stringify([
            "projectId",
            "scheduleId",
            "scheduleAddress",
            "scheduleName",
            "scheduleDays",
            "createdAt",
            "teamsConfiguration",
          ]),
        ],
      },
    },
  });
const projectApiData = async () =>
  fetchData({
    endpoint: "projects",
    queryStringParams: {
      queryStringParameters: {
        keysToInclude: [
          JSON.stringify([
            "projectId",
            "projectName",
            "accountId",
            "accountName",
            "createdAt",
            "teamsConfiguration",
            "projectManager",
            "projectLatitude",
            "projectLongitude",
            "projectExecutive",
          ]),
        ],
      },
    },
  });
const crewsApiData = async () =>
  fetchData({
    endpoint: "crews",
    queryStringParams: {
      queryStringParameters: {
        keysToInclude: [
          JSON.stringify([
            "crewId",
            "crewName",
            "foreman",
            "members",
            "createdAt",
          ]),
        ],
        filters: JSON.stringify([
          {
            conditions: [
              {
                columnType: "string",
                column: "crewStatus",
                formula: "is",
                value: "Active",
              },
            ],
          },
        ]),
      },
    },
  });

const incidentApiData = {
  "Property Damage": {
    crews: crewsApiData,

    projects: projectApiData,

    scheduling: schedulingApiData,
  },

  "Personal Injury": {
    crews: crewsApiData,

    projects: projectApiData,

    scheduling: schedulingApiData,
  },

  "Other Trade Incident": {
    crews: crewsApiData,

    projects: projectApiData,

    scheduling: schedulingApiData,
  },
};

export default incidentApiData;

import "./NotificationConfigs.scss";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Topics } from "../../../../settingsCommonComponents";
import Switcher from "../../../../../../commonComponents/Switcher/Switcher";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { SearchOutlined } from "@ant-design/icons";

// IS REAUSABLE COMPONENT
const NotificationConfigs = ({
  myNotifications,
  setMyNotifications = () => {},
  myAutomationPrefs,
  setPreferences,
}) => {
  const {
    preferences: { preferences: { notifications } = {} },
  } = useSelector((state) => state.preferences);
  const { notificationSettings } = useSelector(
    (state) => state.notificationSettings
  );
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [selectedTopic, setSelectedTopic] = useState({});
  const [searchVal, setSearchVal] = useState("");

  const actionFunctions = programFields?.find(
    (prog) => prog.fieldName === "Automation Configuration"
  )?.fieldOptions["Automation Functions"];

  const notificationConfigs = useMemo(() => {
    const hasTemplates = notificationSettings.filter(
      (notifSetting) => !!notifSetting?.templates?.length
    );
    return topicsByAccess(hasTemplates);
  }, [notificationSettings, userConfiguration]);
  const automationPreferencesObj = {
    topicId: -1,
    topicName: "Automation Preferences",
    templates: actionFunctions.map(({ value }) => ({ templateAction: value })),
  };
  const updatedTopics = [
    setPreferences && automationPreferencesObj,
    ...notificationConfigs,
  ].filter(Boolean);

  function topicsByAccess(notificationConfigs) {
    const { routeConfig } = userConfiguration;

    return notificationConfigs.filter(({ routeConfigTitle }) => {
      if (!routeConfigTitle || routeConfigTitle === "notification") return true;
      if (Array.isArray(routeConfigTitle)) {
        if (routeConfigTitle.length === 0) return true;
        if (routeConfigTitle.length === 1) {
          if (routeConfigTitle[0] === "/") return true;
          return routeConfig.some((item) => item.title === routeConfigTitle[0]);
        }
        let route = null;
        for (let i = 0; i < routeConfigTitle.length; i++) {
          if (i === 0) {
            route = routeConfig.find(
              (item) => item.title === routeConfigTitle[i]
            );
            if (!route) return false;
          } else {
            if (!route.children) return false;
            route = route.children.find(
              (item) => item.title === routeConfigTitle[i]
            );
            if (!route) return false;
          }
        }
        return true;
      } else {
        return routeConfig.some((item) => item.title === routeConfigTitle);
      }
    });
  }

  const notificationsTopics = myNotifications || notifications?.topics || {};
  const finalAutomationPreferences =
    myAutomationPrefs || notifications?.automationPreferences || {};

  const isAutomationTopic =
    selectedTopic.topicName === automationPreferencesObj.topicName;

  const handleAllTopicNotifications = (status) => {
    isAutomationTopic
      ? setPreferences &&
        setPreferences(
          Object.keys(finalAutomationPreferences).reduce(
            (acc, functionName) => ({ ...acc, [functionName]: status }),
            {}
          )
        )
      : setMyNotifications(
          Object.entries(notificationsTopics).reduce(
            (acc, [topicId, actions]) => ({
              ...acc,
              [topicId]:
                selectedTopic.topicId === topicId
                  ? Object.keys(actions).reduce(
                      (acc, actionName) => ({ ...acc, [actionName]: status }),
                      {}
                    )
                  : actions,
            }),
            {}
          )
        );
  };

  return (
    <div className={`notification-cards ${isDarkMode ? "dark-mode" : ""}`}>
      <Topics
        {...{
          hasCollapse: false,
          topics: updatedTopics.map(({ topicName }) => topicName),
          selectedTopic: selectedTopic?.topicName,
          setSelectedTopic: (topic) => {
            setSelectedTopic(
              updatedTopics.find(({ topicName }) => topicName === topic)
            );
          },
        }}
      />

      {selectedTopic?.topicId && (
        <div className="notification-actions">
          <InputComponent
            {...{
              onChange: (e) => {
                setSearchVal(e.target.value);
              },
              initialValue: searchVal,
              prefix: <SearchOutlined style={{ color: "#323338" }} />,
              placeholder: "Search...",
            }}
          />

          <div className="notification-actions-buttons">
            <div
              className="btn btn-disable"
              onClick={() => {
                handleAllTopicNotifications(false);
              }}
            >
              Disable all
            </div>
            <div
              className="btn btn-enable"
              onClick={() => {
                handleAllTopicNotifications(true);
              }}
            >
              Enable all
            </div>
          </div>

          {selectedTopic.templates.map(({ templateAction }, i) => {
            const status =
              (isAutomationTopic
                ? finalAutomationPreferences[templateAction]
                : notificationsTopics?.[selectedTopic.topicId]?.[
                    templateAction
                  ]) ?? true;

            return (
              <Switcher
                key={i}
                {...{
                  label: templateAction,
                  status,
                  onClick: () => {
                    isAutomationTopic
                      ? setPreferences &&
                        setPreferences({
                          ...finalAutomationPreferences,
                          [templateAction]: !status,
                        })
                      : setMyNotifications({
                          ...notificationsTopics,
                          [selectedTopic.topicId]: {
                            ...notificationsTopics[selectedTopic.topicId],
                            [templateAction]: !status,
                          },
                        });
                  },
                }}
              />
            );
          })}
        </div>
      )}
    </div>
  );
};

export default NotificationConfigs;

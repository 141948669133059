import { Popover } from "antd";
import { useSelector } from "react-redux";

import { InfoIcon } from "src/assets";
import { PersonLocationIcon } from "src/assets/icons";
import { LocationIcon } from "src/components/SidebarPages/Communication/assets";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./PopoverLegend.scss";

function PopoverLegend() {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  return (
    <Popover
      title="Legend"
      trigger="hover"
      overlayClassName={`legend-popover ${
        darkMode ? "legend-popover-dark" : ""
      }`}
      content={
        <div className="legend-content">
          <div className="legend-item">
            <LocationIcon height={30} width={30} fill="#1264a3" />
            Schedules
          </div>
          <div className="legend-item">
            <LocationIcon height={30} width={30} fill="#fe4c4a" />
            Jobsites
          </div>
          <div className="legend-item">
            <PersonLocationIcon width={30} height={30} fill="#1264a3" />
            Foreman Employees
          </div>
          <div className="legend-item">
            <PersonLocationIcon width={30} height={30} fill="#00a464" />
            Punch In Employees
          </div>
          <div className="legend-item">
            <PersonLocationIcon width={30} height={30} fill="#e9c466" />
            Punch In Employees that don't match any job
          </div>
          <div className="legend-item">
            <PersonLocationIcon width={30} height={30} fill="#787677" />
            Punch Out Employees
          </div>
        </div>
      }
    >
      <InfoIcon
        style={{
          cursor: "pointer",
        }}
      />
    </Popover>
  );
}

export default PopoverLegend;

import { isYard } from "./filterAlertsOnTruck";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { formatNumber } from "src/components/SidebarPages/utils";

/**
 * Function that gets the final audited data for the selected vehicle
 * @param {Object} data
 * @param {{}} data.audits - All the audits for the vehicle
 * @param {string} data.selectedVehicle - The selected vehicle
 * @param {Array} data.plannedTripsForDate - All the activities for the vehicle
 * @param {Boolean} [data.forDriveUpload=false] - Changes the data type depending on the use case
 * @param {Record<string, string>} [data.addressesMap] - Maps the activities locations to the locations in the drive sheets
 * @returns The formatted list of the approved times
 */
function generateExcelReport({
  audits,
  selectedVehicle,
  plannedTripsForDate,
  forDriveUpload = false,
  addressesMap = {},
}) {
  let exportData = [];

  for (const alertId in audits?.["changed"] || {}) {
    if (!audits?.["changed"]?.[alertId]?.["activityId"]) {
      continue;
    }

    let auditedAlert = audits?.["changed"]?.[alertId];
    let activity = plannedTripsForDate?.[selectedVehicle]?.find(
      ({ activityId }) => activityId === auditedAlert?.activityId
    );

    if (!activity) {
      continue;
    }

    let existingIndex = exportData?.findIndex(
      ({ activityId }) => activityId === activity?.activityId
    );

    let truckNo = activity?.fleetName || "";
    if (truckNo.startsWith("Truck")) {
      truckNo = truckNo.replace("Truck ", "");
    }

    if (existingIndex === -1) {
      exportData.push({
        Date: dayjsNY(auditedAlert?.alertDateTime).format("MM/DD/YY"),
        "Trip Type": activity?.routeName === "Return Route" ? "RETURN" : "TRIP",
        "Depart Time": dayjsNY(auditedAlert?.alertDateTime).format("HH:mm"),
        "From Location": isYard(activity?.pickUpLocation)
          ? "YARD"
          : !!addressesMap?.[activity?.pickUpLocation]
          ? addressesMap?.[activity?.pickUpLocation]
          : activity?.pickUpLocation,
        "Arrive Time": undefined,
        "To Location": isYard(activity?.dropOffLocation)
          ? "YARD"
          : !!addressesMap?.[activity?.dropOffLocation]
          ? addressesMap?.[activity?.dropOffLocation] +
            `${activity?.hoistRoute ? " (hoist)" : ""}`
          : `${activity?.dropOffLocation}${
              activity?.hoistRoute ? " (hoist)" : ""
            }`,
        "Truck #": truckNo,
        Driver: activity?.driverName,
        "Trip Variance":
          activity?.routeName === "Return Route" || activity?.scheduleId
            ? false
            : true,
        "Cargo Status": activity?.cargo,
        "Paperwork Type": (activity?.paperworkType || "").toUpperCase(),
        "Paperwork Collect Status": (
          activity?.paperworkCollectStatus || ""
        ).toUpperCase(),
        "Paperwork Software Status": (
          activity?.paperworkSoftwareStatus || ""
        ).toUpperCase(),
        "Cargo Weight": activity?.cargoWeight,
        "Returning Weight": activity?.returningWeight,
        "Paperwork Notes": activity?.notes,
        activityId: activity?.activityId,
        alertDateTime: auditedAlert?.alertDateTime,
      });
    } else {
      let existingDateTime = exportData[existingIndex]?.alertDateTime;
      if (existingDateTime < auditedAlert?.alertDateTime) {
        exportData[existingIndex] = {
          ...exportData[existingIndex],
          "Arrive Time": dayjsNY(auditedAlert?.alertDateTime).format("HH:mm"),
        };
      } else {
        exportData[existingIndex] = {
          ...exportData[existingIndex],
          Date: dayjsNY(auditedAlert?.alertDateTime).format("MM/DD/YY"),
          "Depart Time": dayjsNY(auditedAlert?.alertDateTime).format("HH:mm"),
          "Arrive Time": dayjsNY(
            exportData[existingIndex]?.alertDateTime
          ).format("HH:mm"),
        };
      }
    }
  }

  exportData.sort((a, b) => a?.alertDateTime - b?.alertDateTime);
  exportData.forEach((e) => {
    delete e["activityId"];
    delete e["alertDateTime"];
  });

  if (forDriveUpload) {
    return exportData.map((el) => {
      return [
        el["Date"],
        el["Trip Type"],
        el["Depart Time"],
        el["From Location"],
        el["Arrive Time"],
        el["To Location"],
        el["Truck #"],
        el["Driver"],
        "",
        "",
        el["Trip Variance"],
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        "",
        el["Cargo Status"],
        el["Paperwork Type"],
        el["Paperwork Collect Status"],
        el["Paperwork Software Status"],
        !+el["Cargo Weight"]
          ? ""
          : formatNumber(el["Cargo Weight"], { fallback: null, fixed: null }),
        el["Paperwork Notes"],
      ];
    });
  }

  return exportData;
}

export default generateExcelReport;

import { getParams } from "../../../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  const department = new Set();
  const role = new Set();

  data.forEach((item) => {
    const userName = item.departmentName || "No Data";
    const userRole = item.groupName || "No Data";

    department.add(userName);
    role.add(userRole);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total Users count based on Department",
      dataKey: "departmentName",
      params: getParams({
        data,
        chartData: department,
        chartKey: "departmentName",
        label: "Department",
        text: "Total Users count based on Department",
      }),
    },
    {
      optionTitle: "Show total Users count based on Role",
      dataKey: "groupName",
      params: getParams({
        data,
        chartData: role,
        chartKey: "groupName",
        label: "Role",
        text: "Total Users count based on Role",
      }),
    },
  ];

  return chartingOptions;
};

import { Tooltip } from "antd";
import React from "react";
import { RichTextIcon } from "../../../../../../assets";

import { useEmailBoxBody } from "../../../../providers/EmailBoxBodyProvider";

import "./formatting-options.scss";

function FormattingOptions() {
  // Access the setShowEditorToolbar function from the EmailBoxBodyProvider
  const { setShowEditorToolbar } = useEmailBoxBody();

  return (
    <div className="formatting-options-container">
      <Tooltip
        placement="top"
        {...{ title: "Formatting options" }}
        className="formatting-options-tooltip"
      >
        {/* Rich Text Icon that toggles the editor toolbar on click */}
        <RichTextIcon
          className="formatting-options-icon"
          onClick={() =>
            setShowEditorToolbar((showEditorToolbar) => !showEditorToolbar)
          }
          data-testid="richTextIcon"
        />
      </Tooltip>
    </div>
  );
}

export default FormattingOptions;

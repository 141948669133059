function getDegTotals({ employeeWeekTotals, generalOverheadTotal = 0 }) {
  let reg = 0,
    ot = 0,
    ovh = 0;
  let totalRegHours = 0,
    totalOtHours = 0,
    totalOvhHours = 0,
    totalReimbursement = 0;

  for (const employeeId in employeeWeekTotals) {
    for (const week in employeeWeekTotals[employeeId]) {
      let weekEntry = employeeWeekTotals[employeeId][week];

      reg = reg + weekEntry.totalRegAmount;
      ot = ot + weekEntry.otAmountWeekly;
      ovh = ovh + weekEntry.totalOvhAmount;

      totalRegHours = totalRegHours + weekEntry?.totalRegHours;
      totalOtHours = totalOtHours + weekEntry?.otHoursWeekly;
      totalOvhHours = totalOvhHours + weekEntry?.totalOvhHours;
      totalReimbursement =
        totalReimbursement + weekEntry?.reimbursementTotalAmount;
    }
  }

  return {
    totalReg: reg,
    totalOt: ot,
    totalRegHours,
    totalOtHours,
    totalOvhHours,
    totalReimbursement,
    totalWOvh: reg + ot + ovh,
    total: reg + ot + totalReimbursement,
    totalWorkHours: totalRegHours + totalOtHours,
    totalOvh: ovh + (Number(generalOverheadTotal) || 0),
    totalWorkHoursWOvh: totalRegHours + totalOtHours + totalOvhHours,
  };
}

export default getDegTotals;

import dayjs from "dayjs";

export const base64ToPdf = (pdf, title) => {
  const date = dayjs().format("YYYY_MM_DD-HH_mm");
  const pdfLink = `data:application/pdf;base64,${pdf}`;
  // eslint-disable-next-line no-restricted-globals
  const anchorElement = document.createElement("a");
  const fileName = `${title} - ${date}.pdf`;
  anchorElement.href = pdfLink;
  anchorElement.download = fileName;
  anchorElement.click();
};

import { API } from "aws-amplify";
import { splitTimelineCalls } from "./auditHelpers";

/**
 * Function that retrieves the data needed to create a route replay
 * @param {Object} config
 * @param {any} config.selectedLocation The selected location object
 * @param {number} config.minDate Start date in milliseconds
 * @param {number} config.maxDate End date in milliseconds
 * @param {"ALL"|"TRIPS"} config.call The data to request
 */
async function getReplayRoutes({ selectedLocation, minDate, maxDate, call }) {
  const timelineSplit = splitTimelineCalls({ minDate, maxDate });
  let allTrips = [];
  let allLocations = [];

  let ERROR = false;
  let iterator = 5;
  let step = iterator;

  while (true) {
    let timelineSlice = timelineSplit.slice(step - iterator, step);
    if (!timelineSlice.length) {
      break;
    }

    await Promise.all([
      Promise.all(
        timelineSlice.map(({ fromDate, toDate }) =>
          API.post("getVehicleTrips", "/getVehicleTrips", {
            body: {
              toDate,
              fromDate,
              searchBy: "SERIAL_NUMBER",
              searchByValue: selectedLocation?.deviceSerialNumber,
            },
          })
        )
      ),
      Promise.all(
        timelineSlice.map(({ fromDate, toDate }) =>
          call === "TRIPS"
            ? Promise.resolve()
            : API.post("locations", "/locations", {
                body: {
                  toDate,
                  fromDate,
                  uuid: selectedLocation?.deviceUUID,
                },
              })
        )
      ),
    ])
      .then(([tri, loc = []]) => {
        const tripsRes = tri.flatMap((e) => e.data.trips);
        const locationsRes = loc.flatMap((e) => e?.data?.locations || []);

        allLocations = allLocations.concat(locationsRes);
        allTrips = allTrips.concat(tripsRes);
      })
      .catch((err) => {
        console.log("Error getting reports data: ", err);
        ERROR = true;
      });

    if (ERROR) {
      break;
    }

    step += iterator;
  }

  if (ERROR) {
    throw ERROR;
  }

  return [allTrips, allLocations];
}

export default getReplayRoutes;

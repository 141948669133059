type FuncProps<T> = {
  key?: string;
  array: Array<T>;
  reverse?: boolean;
};

export function alphabeticalSorting<T>({ array, key, reverse }: FuncProps<T>) {
  return [...array].sort((a, b) => {
    let valA;
    let valB;

    if (key) {
      valA = a?.[key];
      valB = b?.[key];
    } else {
      valA = a;
      valB = b;
    }

    if (valA < valB) {
      return reverse ? 1 : -1;
    }
    if (valA > valB) {
      return reverse ? -1 : 1;
    }
    return 0;
  });
}

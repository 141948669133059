import { message } from "antd";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import dayjs from "dayjs";
import { fetchData } from "../components/SidebarPages/Fleet/utils";
import { totalCalculator } from "../components/SidebarPages/Projects/Accounting/Applications/ApplicationView/components/Header/Components/MainHeader/mainHeaderFunctions";
import types from "../types";
import { filterTables } from "../utils";
import { getRentals } from "./rentals";
import { totalCalculator as totalitiesCalc } from "../components/SidebarPages/Projects/Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/controlPanelFunctions";
import broadcastNotification from "../helpers/controllers/broadcastNotification";
import { thisRetainageCalculator } from "../components/SidebarPages/Projects/Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/utils";
import { getChangedData } from "../components/SidebarPages/Accounting/components/utilities";
import { checkIfServiceIsHoist } from "../components/SidebarPages/Estimations/DataEntryGrid/models/Service";
// Get all applications
export const getApplications = (projectId) => async (dispatch) => {
  try {
    // message.loading("Loading applications...", 0);
    dispatch({ type: types.LOADING_APPLICATION });

    // const applications = await filterTables("applications", "projectId", projectId);
    const applications = await filterTables(
      "applications",
      "projectId",
      projectId
    );
    console.log("getApplications", applications);

    dispatch({
      type: types.GET_APPLICATIONS,
      payload: applications,
    });
    message.destroy();
  } catch (err) {
    dispatch({
      type: types.ERROR_APPLICATION,
      payload: err,
    });
    console.error(err);
    message.destroy();
  }
};

// Get application
export const getApplication = (applicationId) => async (dispatch) => {
  try {
    // Clear previous loaded application
    dispatch({ type: types.CLEAR_APPLICATION });
    dispatch({ type: types.LOADING_APPLICATION });

    await API.get("applications", `/applications/${applicationId}`)
      .then(async (res) => {
        dispatch({
          type: types.GET_APPLICATION,
          payload: res,
        });
      })
      .catch((e) => {
        dispatch({
          type: types.ERROR_APPLICATION,
          payload: e?.response?.data,
        });
      });
  } catch (err) {
    dispatch({
      type: types.ERROR_APPLICATION,
      payload: err,
    });
  }
};

// Create application
export const createApplication =
  (application, broadNotifObj, isNextStep, getApplicationId) =>
  async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;
    try {
      // Clear previous created application
      dispatch({ type: types.CLEAR_NEWLY_CREATED_APPLICATION });
      dispatch({ type: types.LOADING_APPLICATION });
      const allProjects = await API.get(
        "projects",
        `/projects/${application?.projectId}`
      ).then((res) => res);
      const allApplications = await fetchData("accounts").then((res) =>
        res.find((el) => el.accountId === allProjects?.accountId)
      );
      console.log("allApplications", allApplications);

      let toSend = {
        ...application,
        accountName: allApplications?.accountName || "",
        accountId: allProjects?.accountId || "",
        accountNameAddress: allApplications?.billingAddress || "NO NAME",
      };

      console.log("toSend", toSend);

      // toSend?.services?.forEach((service) => {
      //   if (Array.isArray(service?.amounts)) {
      //     service.amounts.forEach((amount) => {
      //       if (!!amount?.serviceOptions) {
      //         amount.serviceOptions = [];
      //       }
      //     });
      //   }
      // });

      console.log("toSend", toSend);

      message.info("Creating application...");
      const newApplication = await API.post("applications", `/applications`, {
        body: toSend,
      }).then((res) => {
        dispatch({
          type: types.CREATE_APPLICATION,
          payload: res,
        });

        if (isNextStep) getApplicationId(res.applicationId);

        Swal.fire({
          icon: "success",
          title: "Successfully created",
          text: "Created new Requisition",
          showConfirmButton: false,
          timer: 1500,
        });
        // broadcastNotification(
        //   "15",
        //   "onRequisitionCreation",
        //   broadNotifObj?.action,
        //   res?.applicationId
        // );
        API.post("editLogs", "/editLogs", {
          body: {
            recordId: res?.applicationId,
            recordName: `Requisition ${res?.applicationNo}`,
            actionType: "Create",
            category: "Requisitions",
            topic: res?.projectName,
            label: "",
            currentData: {},
            nameOfUser: `${authUser?.given_name} ${authUser?.family_name}`,
            previousData: {},
            cognitoUserId: authUser?.sub,
            updatedAt: dayjs().valueOf(),
            updatedKeys: [],
          },
        }).catch((err) => console.log("Error Creating Log: ", err));
      });
      console.log("newApplication", newApplication);

      return newApplication;
    } catch (err) {
      console.log("erro", err);
      dispatch({
        type: types.ERROR_APPLICATION,
        payload: err,
      });
      Swal.fire({
        title: "Something went wrong!",
        text: "Cannot create new Application",
        icon: "error",
        showConfirmButton: false,
        timer: 1500,
      });

      // swal({
      //   title: "Something went wrong!",
      //   text: "Cannot create new Application",
      //   icon: "error",
      //   buttons: false,
      //   timer: 1500,
      // });
    }
  };

// Update application
export const updateApplication =
  (id, application, applications, saveAddedLogs = () => {}) =>
  async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;
    const { userId, lastModifiedBy, applicationId, ...rest } =
      application || {};
    const totalities = totalitiesCalc(application, applications);
    const applicationWithTotalPrice = {
      ...rest,
      // services: rest?.services?.map((el) => {
      //   if (checkIfServiceIsHoist(el)) {
      //     return {
      //       ...el,
      //       serviceOptions: el?.serviceOptions?.map((sOptions) => {
      //         return sOptions.map((sOption) => {
      //           return {
      //             ...sOption,
      //             // amounts: sOption?.amounts?.map((amount) => {
      //             //   const {
      //             //     thisPeriod = 0,
      //             //     taxAmount = 0,
      //             //     retainage = 0,
      //             //     reducedAmount = 0,
      //             //     reducedBalance = 0,
      //             //     keptRetainage = 0,
      //             //   } = amount || {};
      //             //   const paymentDue =
      //             //     Number(thisPeriod) -
      //             //     // Number(taxAmount) -
      //             //     Number(keptRetainage) +
      //             //     Number(reducedBalance);
      //             //   // console.log("myPaymentHoist", {
      //             //   //   paymentDue,
      //             //   //   thisPeriod,
      //             //   //   keptRetainage,
      //             //   //   reducedBalance,
      //             //   //   amount,
      //             //   // });
      //             //   return {
      //             //     ...amount,
      //             //     paymentDue,
      //             //     applicableRetainage:
      //             //       (thisPeriod * el?.retainage) / 100 || 0,
      //             //   };
      //             // }),
      //           };
      //         });
      //       }),
      //     };
      //   } else {
      //     return {
      //       ...el,
      //       // amounts: el?.amounts?.map((amount) => {
      //       //   ///
      //       //   const {
      //       //     thisPeriod = 0,
      //       //     taxAmount = 0,
      //       //     retainage = 0,
      //       //     reducedAmount = 0,
      //       //     totalCompleated = 0,
      //       //     retainagePercentage = 0,
      //       //     percentageCompleated = 0,
      //       //     previousRetainage = 0,
      //       //     keptRetainage = 0,
      //       //     reducedBalance = 0,
      //       //   } = amount || {};
      //       //   // const res =
      //       //   //   percentageCompleated > 0
      //       //   //     ? (thisPeriod * retainagePercentage) / 100 +
      //       //   //       (reducedAmount > 0 ? previousRetainage - reducedAmount : 0)
      //       //   //     : 0;
      //       //   // const finalRes =
      //       //   //   thisPeriod > 0 || reducedAmount > 0 ? res || 0 : 0;
      //       //   // const paymentDue =
      //       //   //   Number(thisPeriod) - Number(finalRes) > 0
      //       //   //     ? Number(thisPeriod) - Number(finalRes)
      //       //   //     : Number(finalRes);
      //       //   const paymentDue =
      //       //     Number(thisPeriod) -
      //       //     // Number(taxAmount) -
      //       //     Number(keptRetainage) +
      //       //     Number(reducedBalance);
      //       //   // console.log("this is finalRes", {
      //       //   //   finalRes,
      //       //   //   res,
      //       //   //   thisPeriod,
      //       //   //   paymentDue,
      //       //   //   reducedAmount,
      //       //   // });

      //       //   // Number(totalCompleated) -
      //       //   // // Number(taxAmount) -
      //       //   // Number(retainage) +
      //       //   // Number(reducedAmount);
      //       //   // const totalCompleted = (amount * percentageCompleated) / 100;
      //       //   // const fullAm = (totalCompleted * retainagePercentage) / 100;
      //       //   return {
      //       //     ...amount,
      //       //     paymentDue,
      //       //     applicableRetainage:
      //       //       (thisPeriod * amount?.retainagePercentage) / 100 || 0,
      //       //   };
      //       // }),
      //     };
      //   }
      // }),
      totalPrice: +totalCalculator({ fakeApplication: rest })?.toFixed(2),
    };
    applicationWithTotalPrice.totalities.thisRetainage =
      thisRetainageCalculator({
        fakeApplication: applicationWithTotalPrice,
        applications,
      });
    console.log("updateApplication", applicationWithTotalPrice);

    try {
      message.info("Updating application...", 0);
      dispatch({ type: types.LOADING_APPLICATION });

      let prevRequisition = applications.find(
        ({ applicationId }) => applicationId === application.applicationId
      );

      applicationWithTotalPrice.chainedRentalsIds = [];
      applicationWithTotalPrice.includedRentals = [];

      API.put("applications", `/applications/${id}`, {
        body: {
          ...applicationWithTotalPrice,
          lastModifiedBy: lastModifiedBy,
        },
      })
        .then((res) => {
          let newEditLog = {
            recordId: id,
            recordName: `Requisition ${applicationWithTotalPrice?.applicationNo}`,
            actionType: "Edit",
            category: "Requisitions",
            topic: applicationWithTotalPrice?.projectName,
            label: "",
            currentData: {},
            nameOfUser: `${authUser?.given_name} ${authUser?.family_name}`,
            previousData: {},
            cognitoUserId: authUser?.sub,
            updatedAt: dayjs().valueOf(),
            updatedKeys: [],
          };

          for (let key in applicationWithTotalPrice) {
            let result;
            if (
              !!applicationWithTotalPrice[key] &&
              !!prevRequisition[key] &&
              key !== "editLogs"
            ) {
              result = getChangedData(
                applicationWithTotalPrice[key],
                prevRequisition[key]
              );
            } else {
              continue;
            }

            if (result !== false) {
              newEditLog.currentData[key] = result.curr;
              newEditLog.previousData[key] = result.prev;
              newEditLog.updatedKeys.push(key);
            }
          }
          API.post("editLogs", "/editLogs", {
            body: {
              ...newEditLog,
            },
          });
        })
        .then(() => {
          message.destroy();
          message.success("Requisition updated successfully");
        })
        .catch((err) => {
          message.destroy();
          message.error("Error updating requisition");
          console.log("Error Updating Application: ", {
            err,
            applicationWithTotalPrice,
          });
        });

      dispatch({
        type: types.UPDATE_APPLICATION,
        payload: {
          applicationId: id,
          application: { ...applicationWithTotalPrice, applicationId },
        },
      });
    } catch (err) {
      dispatch({
        type: types.ERROR_APPLICATION,
        payload: err,
      });
      console.log("Error Updating: ", err);
      message.destroy();
    }
  };

// Delete application
export const deleteApplication =
  (
    applicationId,
    notifObj,
    { setVisibleCreationProgress, updateProgressStatus }
  ) =>
  async (dispatch, getState) => {
    const authUser = getState().authenticatedUser.authenticatedUser;
    const applicationToDelete = getState().applications.applications?.filter(
      (el) => el?.applicationId === applicationId
    );
    try {
      // message.info("Deleting Requisition...", 1);
      // await API.get("applications", `/applications/${applicationId}`).then(
      //   (res) => {}
      // );
      // dispatch({ type: types.LOADING_APPLICATION })
      await unchainAppRental(applicationId);
      setVisibleCreationProgress(true);
      updateProgressStatus({ updatingRecord: "executing" });
      await API.del("applications", `/applications/${applicationId}`)
        .then(async (res) => {
          message.destroy();
          message.success("Requisition deleted successfully");

          dispatch({
            type: types.DELETE_APPLICATION,
            payload: applicationId,
          });

          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });
          // await broadcastNotification(
          broadcastNotification(
            notifObj?.topicId,
            "onRequisitionDelete",
            notifObj?.action,
            applicationId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          API.post("editLogs", "/editLogs", {
            body: {
              recordId: applicationToDelete?.applicationId,
              recordName: `Requisition ${applicationToDelete?.applicationNo}`,
              actionType: "Delete",
              category: "Requisitions",
              topic: applicationToDelete?.projectName,
              label: "",
              currentData: {},
              nameOfUser: `${authUser?.given_name} ${authUser?.family_name}`,
              previousData: {},
              cognitoUserId: authUser?.sub,
              updatedAt: dayjs().valueOf(),
              updatedKeys: [],
            },
          }).catch((err) => console.log("Error Posting Logs: ", err));
        })
        .catch((err) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          message.destroy();
          message.error("Requisition cannot be deleted at this moment");
        })
        .finally(() => {
          // message.destroy();
        });
    } catch (err) {
      dispatch({
        type: types.ERROR_APPLICATION,
        payload: err,
      });
    }
  };

// Reset application
export const resetApplication = () => ({ type: types.RESET_APPLICATION });

export const unchainAppRental = async (applicationId) => {
  let projectId;
  await API.get("applications", `/applications/${applicationId}`)
    .then(async (application) => {
      let rentals = application?.includedRentals || [];
      projectId = application?.projectId;
      if (rentals.length > 0) {
        rentals.forEach(async (rental) => {
          // console.log("rental", rental);
          await API.put("rentals", `/rentals/${rental.rentalId}`, {
            body: {
              includedTo: "",
            },
          });
        });
      }
      let includedRentals = filterTables(
        "includedRentals",
        "applicationId",
        applicationId
      ).then(async (res) => {
        let temp = res;
        if (temp.length > 0) {
          let rentalsToUnchain = temp.map((el) => el?.rentalId);
          rentalsToUnchain.forEach(async (rental) => {
            // console.log("rental", rental);
            await API.put("rentals", `/rentals/${rental}`, {
              body: {
                includedTo: "",
              },
            });
          });
        }
        await API.del("includedRentals", `/includedRentals/1234`, {
          body: { includedRentals: temp?.map((el) => el?.rentalsReqId) },
        }).then((res) => {
          message.success("Rental unchained successfully");
        });
      });
    })
    .then(async () => {
      getRentals(projectId);
    })
    .catch((err) => {
      console.error("unchainAppRental", err);
      message.error(
        "There was an error while unchaining rental from application!"
      );
    });
};

/*
 API.del("includedRentals", "/includedRentals/1244", {
includedRentals: [...]
})
*/

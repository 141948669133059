import moment from "moment";
import { dateInputValidation } from "../../../../../utils/dateInputValidation";
import taskDaysLeft from "./components/taskDaysLeft";
import TaskTitleRedirect from "./components/TaskTitleRedirect";
import { sortDates } from "../../../ToDos/AgGridData/columnDefs";
import { AvatarImage, AvatarImages } from "../../../../commonComponents";

export const excelColumnsConfig = [
  "taskTitle",
  "taskTopic",
  "taskRelatedTo",
  "taskSubTopic",
  "taskSubTopicId",
  "taskStatus",
  "taskAssignedTo",
  "taskStartDate",
  "taskEndDate",
  "taskDeadline",
  "remainingDays",
  "createdBy",
  "createdAt",
];

export const formatCellValueHandler = (data) => {
  if (data.length < 1) {
    return "";
  } else {
    return data.map(({ nameOfUser }) => nameOfUser).join(", ");
  }
};

export const columnDefs = ({ handleTaskClick, clickableRecord }) => [
  {
    field: "taskTitle",
    headerName: "Title",
    resizable: true,
    cellRendererFramework: ({ data, value }) => {
      return (
        <a className="taskTitle" onClick={() => handleTaskClick(data)}>
          {value}
        </a>
      );
    },
  },
  {
    field: "taskTopic",
    headerName: "Topic",
  },
  {
    field: "taskRelatedTo",
    headerName: "Topic Related To",
    resizable: true,
    width: 200,
    cellRendererFramework: ({ data, value }) => {
      return <TaskTitleRedirect {...{ data, value, clickableRecord }} />;
    },
  },
  {
    field: "taskSubTopic",
    headerName: "SubTopic",
    cellRenderer: ({ value }) => {
      if (!!!value) {
        return "-|-";
      } else return value;
    },
  },
  {
    field: "taskSubTopicId",
    headerName: "SubTopic Related To",
    cellRenderer: (data) => {
      if (typeof data?.value === "string" && data?.value !== "") {
        return data?.value;
      } else if (
        typeof data?.value === "object" &&
        Object.keys(data?.value).length > 0
      ) {
        return data.value?.recordName || "-|-";
      } else return "-|-";
    },
  },
  {
    field: "taskStatus",
    headerName: "Status",
    resizable: true,
  },
  {
    field: "taskAssignedTo",
    headerName: "Assigned to",
    resizable: true,
    enableRowGroup: false,
    cellRenderer: ({ value = [] }) => {
      return (
        <AvatarImages
          {...{
            cognitoUserIds: value.map(({ cognitoUserId }) => cognitoUserId),
          }}
        />
      );
    },
    pdfExportOptions: {
      modifyCellValue: (value) => {
        return value;
      },
    },
  },
  {
    field: "taskStartDate",
    headerName: "Start Date",
    filter: "agDateColumnFilter",
    // filterParams: {
    //   comparator: (filterLocalDateAtMidnight, cellValue) =>
    //     gridFilterDate(filterLocalDateAtMidnight, cellValue),
    // },
    valueGetter: ({ data }) => {
      if (data?.taskStartDate !== null || undefined) {
        return moment(data?.taskStartDate).format("MM/DD/YYYY h:mm A");
      } else return "";
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY h:mm A"),
  },
  {
    field: "taskEndDate",
    headerName: "End Date",
    filter: "agDateColumnFilter",
    // filterParams: {
    //   comparator: (filterLocalDateAtMidnight, cellValue) =>
    //     gridFilterDate(filterLocalDateAtMidnight, cellValue),
    // },
    valueGetter: ({ data }) => {
      return !!data?.taskEndDate
        ? moment(data.taskEndDate).format("MM/DD/YYYY h:mm A")
        : "";
    },
    pdfExportOptions: {
      modifyCellValue: (date) =>
        date
          ? new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : "",
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY h:mm A"),
  },
  {
    field: "taskDeadline",
    headerName: "Deadline",
    filter: "agDateColumnFilter",
    // filterParams: {
    //   comparator: (filterLocalDateAtMidnight, cellValue) =>
    //     gridFilterDate(filterLocalDateAtMidnight, cellValue),
    // },

    valueGetter: ({ data }) => {
      return !!data?.taskDeadline
        ? moment(data.taskDeadline).format("MM/DD/YYYY h:mm A")
        : "";
    },
    pdfExportOptions: {
      modifyCellValue: (date) =>
        date
          ? new Date(date).toLocaleDateString("en-US", {
              year: "numeric",
              month: "2-digit",
              day: "2-digit",
            })
          : "",
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY h:mm A"),
  },
  {
    field: "remainingDays",
    headerName: "Days Left",
    minWidth: 140,
    cellRenderer: (data) => {
      const startDate = data?.data?.taskStartDate;
      const deadline = data?.data?.taskDeadline;
      const remainingDays = taskDaysLeft(startDate, deadline);
      return remainingDays;
    },
    valueGetter: ({ data }) => {
      const startDate = data?.taskStartDate;
      const deadline = data?.taskDeadline;
      const remainingDays = taskDaysLeft(startDate, deadline);
      return remainingDays;
    },
    cellStyle: function (data) {
      const startDate = data?.data?.taskStartDate;
      const deadline = data?.data?.taskDeadline;
      const remainingDays = taskDaysLeft(startDate, deadline);
      if (isNaN(remainingDays)) {
        return {
          color: "#fff",
          backgroundColor: "rgb(120, 118, 119)",
        };
      } else {
        if (remainingDays <= 0) {
          return {
            color: "#fff",
            backgroundColor: "rgb(240, 79, 78)",
          };
        }
      }
    },
  },
  {
    headerName: "Created At",
    field: "createdAt",
    // hide: false,
    sort: "desc",
    filterParams: { apply: true, newRowsAction: "keep" },
    getQuickFilterText: () => "",
    valueGetter: ({ data }) => {
      return moment(data?.createdAt).format("MM/DD/YYYY");
    },
    cellStyle: () => ({
      justifyContent: "center",
    }),
    minWidth: 120,
  },
  {
    headerName: "Created By",
    field: "createdBy",
    // hide: true,
    valueGetter: ({ data }) => {
      return !!data?.createdBy?.name
        ? data?.createdBy?.name
        : data?.createdBy?.nameOfUser || data?.createdBy;
    },
    minWidth: 150,
    cellRenderer({ value }) {
      return <AvatarImage {...{ nameOfUser: value }} />;
    },
  },
];

import createPDF from "../createPDF";
import getDocDefinition from "./docDefinition";
import { DOWNLOAD } from "../createPDF";
import getDocOrderDefinition from "./docOrderDefinition";

const agGridToPDF = ({
  action,
  params,
  gridApi,
  rowData,
  history,
  historyTab,
  gridColumnApi,
  tableColumn,
  base64,
  isOrder,
  formatCellValueHandler,
  dataIsRowData = false,
  masterDetail = false,
}) => {
  const docDefinition = !isOrder
    ? getDocDefinition({
        params,
        rowData,
        gridApi,
        gridColumnApi,
        tableColumn,
        history,
        historyTab,
        base64,
        masterDetail,
        formatCellValueHandler,
        ...(dataIsRowData ? { dataIsRowData: true } : {}),
      })
    : getDocOrderDefinition({
        rowData,
        params,
        gridApi,
        gridColumnApi,
        history,
        historyTab,
        base64,
      });

  createPDF({
    action,
    docDefinition,
    title: action.includes(DOWNLOAD) && `${params.PDF_DOCUMENT_TITLE}.pdf`,
  });
};

export default agGridToPDF;

export * from "../createPDF/pdfActions";

import { camelCaseToNormalText } from "../../../../../../commonComponents/RowDataGridModal/components/utils";
import { safetyPdfBody, separateWitnessForPdf } from "../../../../safetyPdf";

function safetyReportWordData({
  formFields = [],
  selectedTeam = [],
  safetyCategory,
  rowObject,
  witnessCount,
  witnessSignings,
  defaultId,
  formDataUsers,
  userConfiguration,
  rowObjectKey,
}) {
  const safetyTmp = formFields?.map((el) => {
    if (el?.title === "General Information") {
      return {
        ...el,
        fields: [
          ...el?.fields,
          ...(selectedTeam?.length
            ? [
                {
                  label: "Team Members",
                  value: selectedTeam
                    ?.flatMap((el) => el?.members?.map((el) => el?.nameOfUser))
                    ?.join(", "),
                },
              ]
            : []),
        ],
      };
    }
    return el;
  });

  const prepareWitnessArr = separateWitnessForPdf({
    defaultId,
    rowObject,
    witnessCount,
    witnessSignings,
    rowObjectKey,
  });

  const witnessList = prepareWitnessArr?.map((el, i) => ({
    title: `Witness ${i + 1}`,
    body: el.map((el) => ({
      ...el,
      key: camelCaseToNormalText(el?.key),
    })),
  }));

  const safetyDirectorSignatureSection = {
    title: "Safety Director Signature",
    body: [
      {
        key: "Safety Director",
        value:
          rowObject?.safetyApplicationObject?.safetyDirectorSignature
            ?.nameOfUser || "",
      },
      {
        key: "Signature",
        value:
          rowObject?.safetyApplicationObject?.safetyDirectorSignature?.src ||
          "N/A",
      },
    ],
  };

  const dynamicFields = formFields?.map((el) => {
    return {
      title: el?.title,
      body: safetyPdfBody?.[safetyCategory]({
        category: el?.title,
        fieldsJSON: safetyTmp,
        rowObject,
        ...formDataUsers,
        userConfiguration,
      }),
    };
  });

  return [...dynamicFields, ...witnessList, safetyDirectorSignatureSection];
}

export default safetyReportWordData;

export const mapWordImages = (images) => {
  if (!images.length) {
    return [];
  }
  const formattedImages = images.map((el) => ({
    key: el?.name,
    value: el?.src,
  }));
  let tmpArr = [];
  for (let i = 0; i < images.length / 6; i++) {
    let tmpIndex = i + 1;
    tmpArr.push({
      title: images.length > 6 ? "Images " + tmpIndex : "Images",
      body: formattedImages.slice(i * 6, i * 6 + 6),
    });
  }

  return tmpArr;
};

function safetyExportApplyFilters(filters = {}, fields) {
  if (Object.keys(filters)?.length === 0) {
    return fields;
  }
  let otherFields = [...fields]?.filter(
    (el) => el?.other || el?.additionalData
  );
  let tmpFields = [...fields].filter((el) => !el?.additionalData && !el?.other);
  if (!!filters?.categoriesSort) {
    let sortObj = filters?.categoriesSort;
    tmpFields =
      tmpFields?.sort((a, b) => {
        if (!sortObj?.[a?.title] || !sortObj?.[b?.title]) return 0;
        return sortObj[a?.title] - sortObj[b?.title];
      }) || [];
  }
  tmpFields = tmpFields
    ?.filter((el) => !(filters?.removedCategories || [])?.includes(el?.title))
    ?.map(({ title, body = [] }) => {
      let tmpBody = body;
      if (!!filters?.fieldsSort?.[title]) {
        let sortObj = filters?.fieldsSort?.[title];
        tmpBody =
          body?.sort((a, b) => {
            const aKey = a?.key || a?.title;
            const bKey = b?.key || b?.title;
            if (!sortObj?.[aKey] || !sortObj?.[bKey]) return 0;
            return sortObj[aKey] - sortObj[bKey];
          }) || [];
      }
      return {
        title,
        body: tmpBody?.filter(
          ({ key = "", title: fieldTitle = "" }) =>
            !(filters?.removedFields?.[title] || [])?.includes(
              key || fieldTitle
            )
        ),
      };
    });
  return tmpFields.concat(otherFields);
}

export default safetyExportApplyFilters;

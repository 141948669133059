import { useState, useEffect, useMemo, Fragment } from "react";
import { useSelector } from "react-redux";
import { message, Tooltip } from "antd";
import { driveApi } from "src/integrations/DriveRequest";
import { StoreType } from "../../types";
import { MondayButton, RedWarningModal } from "src/components/commonComponents";
import { FolderFilled } from "@ant-design/icons";
import { LoadingDots } from "src/components/commonComponents/3LoadingDots/LoadingDots";
import { API } from "aws-amplify";

import "../DynamicSideButtons/DynamicSideButtons.scss";

export type DriveButtonProps = {
  path: string;
  folderId: string;
  recordId: string;
  folderIdKey: string;
  recordName?: string;
  parentFolderId: string;
  onFolderCreate?: (folderId: string) => any;
};

const FOLDERS_URI = "https://drive.google.com/drive/folders/";

function DriveCheckButton(props: DriveButtonProps) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { accessToken } = useSelector((state: StoreType) => state.accessToken);
  const [driveFolderId, setDriveFolderId] = useState<string>(props.folderId);
  const [driveFolderFound, setDriveFolderFound] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [errorId, setErrorId] = useState<string>("");

  const drive = useMemo(() => {
    //#region DRIVE
    if (!accessToken) {
      return;
    }

    return driveApi({ accessToken });
  }, [accessToken]);

  useEffect(() => {
    //#region CHECK FOLDER
    if (!drive) {
      return;
    }

    if (!props.folderId) {
      setDriveFolderFound(false);
      setLoading(false);
      return;
    }

    drive
      .getDriveItem(props.folderId)
      .then(() => {
        setDriveFolderFound(true);
      })
      .catch((err) => {
        console.log("Error getting drive item: ", err);
        setDriveFolderFound(false);
        setDriveFolderId("");
      })
      .finally(() => {
        setLoading(false);
      });
  }, [drive, props.folderId]);

  function onClick() {
    //#region BUTTON CLICK
    if (loading) {
      return;
    }

    if (!driveFolderFound) {
      if (!props.parentFolderId) {
        message.warning({
          content: "Cannot create drive folder, parent folder was not found",
          key: "noParent",
        });
        return;
      }

      let name = props.recordName ?? location.pathname.split("/").at(-1);

      message.loading({
        content: "Creating folder...",
        key: "create",
        duration: 0,
      });

      setLoading(true);

      drive
        .getFolderIdOrCreate({
          name,
          parents: [props.parentFolderId],
        })
        .then(async (res: string) => {
          setDriveFolderFound(true);
          setDriveFolderId(res);

          message.loading({
            content: "Updating record...",
            key: "crate",
            duration: 0,
          });

          await updateRecord(res)
            .then(() => {
              message.success({
                content: "Folder created!",
                key: "create",
              });

              if (
                props.onFolderCreate &&
                "function" === typeof props.onFolderCreate
              ) {
                props.onFolderCreate(res);
              }
            })
            .catch((err) => {
              console.log("Error updating record: ", err);
              message.destroy("create");

              setErrorId(res);
            });
        })
        .catch((err: any) => {
          message.error({
            content: "Something went wrong while creating drive folder",
            key: "create",
          });

          console.log("Error creating drive folder: ", err);
        })
        .finally(() => {
          setLoading(false);
        });
    } else {
      window.open(
        `${FOLDERS_URI}${driveFolderId}`,
        "_blank",
        "noopener,noreferrer"
      );
    }
  }

  async function updateRecord(id: string) {
    //#region UPDATE RECORD
    return API.put(props.path, `/${props.path}/${props.recordId}`, {
      body: {
        [props.folderIdKey]: id,
      },
    });
  }

  async function deleteFolderHandler(id: string) {
    //#region DELETE FOLDER
    setLoading(true);
    message.loading({
      content: "Deleting folder...",
      key: "delete",
      duration: 0,
    });

    await drive
      .deleteDriveItemPromise(id)
      .then(() => {
        message.success({
          content: "Folder deleted",
          key: "delete",
        });

        setDriveFolderFound(false);
        setDriveFolderId("");
      })
      .catch(async (err: any) => {
        console.log("Error deleting file: ", err);
        await message
          .error({
            content:
              "Folder could not be deleted, you are being redirected to Google Drive...",
            key: "delete",
            duration: 3,
          })
          .then(() => {
            window.open(
              `${FOLDERS_URI}${driveFolderId}`,
              "_blank",
              "noopener,noreferrer"
            );
          });
      })
      .finally(() => {
        setErrorId("");
        setLoading(false);
      });
  }

  //#region JSX
  return (
    <Fragment>
      <Tooltip
        title={driveFolderFound ? "Go to folder" : "Create folder"}
        placement="left"
      >
        <MondayButton
          Icon={
            loading ? (
              <LoadingDots />
            ) : (
              <FolderFilled
                style={{
                  fontSize: 22,
                  color: "#ffffff",
                }}
              />
            )
          }
          data-testid="drive-button"
          className={!driveFolderFound ? "mondayButtonRed" : ""}
          containerClassName={`side-action-button ${
            isDarkMode ? "side-button-dark" : ""
          }`}
          onClick={onClick}
        >
          {null}
        </MondayButton>
      </Tooltip>
      {errorId ? (
        <RedWarningModal
          visible={!!errorId}
          onCancel={() => {
            setErrorId("");
          }}
          titleText="Create folder error"
          footerProps={{
            onConfirm: deleteFolderHandler,
            closeText: "No",
            saveText: "Yes",
          }}
        >
          <p>
            The drive folder was created (folder id:{" "}
            <a
              href={`${FOLDERS_URI}${errorId}`}
              style={{
                color: "#1065A4",
                fontWeight: "600",
                textDecoration: "none",
              }}
            >
              {errorId}
            </a>
            ), but the record could not be updated. Do you want to delete this
            new folder?
          </p>
        </RedWarningModal>
      ) : null}
    </Fragment>
  );
}

export default DriveCheckButton;

import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import {
  antdModalProps,
  filterTables,
  updateDocumentTitle,
} from "../../../../../../utils";
import { Modal, Form, Select, Radio } from "antd";
import { InputComponent, LabeledInput } from "../../../../Fleet/components";
import IndividualLoader from "../../../../../IndividualLoader";
import { MondayButton } from "../../../../../commonComponents";
import { DocumentationModal } from "..";
import { apiRoutes, fetchData } from "../../../../Fleet/utils";
import { uniqBy } from "lodash";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { DropDownArrow } from "src/components/SidebarPages/Fleet/components/InputComponent/assets";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

import "./NewDocumentationModal.scss";

const FORM_ITEM_NAME = "docType";
const SAVE_AS_TYPE = "saveAsType";

const NewDocumentationModal = ({ visible, setVisible, docType }) => {
  const { topicCategories = [] } = useSelector(
    (state) => state.topicCategories
  );
  const [selectedTopic, setSelectedTopic] = useState();
  const [topicRecords, setTopicRecords] = useState([]);
  const [selectedRecord, setSelectedRecord] = useState();

  const [showModal, setShowModal] = useState(false);
  const [documentToAdd, setDocumentToAdd] = useState();
  const [loading, setLoading] = useState(false);
  const [docConfiguration, setDocConfiguration] = useState([]);
  const [documentation, setDocumentation] = useState([]);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [thisType, setThisType] = useState();
  const [saveAsTypeShow, setSaveAsTypeShow] = useState(false);
  const [recordDocuments, setRecordDocuments] = useState([]);
  const [selectedOriginalDocument, setSelectedOriginalDocument] =
    useState(false);

  const [form] = Form.useForm();
  const radioValue = Form.useWatch(SAVE_AS_TYPE, form);

  const categoryConfiguration = useMemo(() => {
    return docConfiguration?.find(
      ({ categoryName: arrCateName }) =>
        arrCateName === selectedTopic?.categoryName ||
        arrCateName === selectedTopic?.categoryName.split(" ").join("-")
    );
  }, [docConfiguration, selectedTopic]);

  const options = useMemo(() => {
    return categoryConfiguration?.documentationsAvailable?.map(
      ({ docName }) => ({
        label: docName,
        value: docName,
      })
    );
  }, [categoryConfiguration]);

  const topics = useMemo(() => {
    return topicCategories?.filter((topic) => {
      return !!docConfiguration.find((el) => {
        return el?.categoryName?.replaceAll("-", " ") === topic?.categoryName;
      });
    });
  }, [docConfiguration, topicCategories]);

  // const docStatuses = useMemo(() => {
  //   return (
  //     categoryConfiguration?.documentationsAvailable.find(
  //       (el) => el?.docName === thisType
  //     )?.docStatuses || []
  //   );
  // }, [categoryConfiguration, thisType]);

  const { expirationDate: configExpirationDate } =
    categoryConfiguration?.documentationsAvailable?.find(
      ({ docName }) => docName === docType
    ) || {};

  const populatedStatuses = useMemo(() => {
    return uniqBy(
      categoryConfiguration?.documentationsAvailable?.flatMap(
        ({ docStatuses = [] }) => docStatuses
      ),
      "statusName"
    )?.map((el) => ({
      ...el,
      value: documentation?.filter(
        ({ docStatus }) => docStatus === el?.statusName
      )?.length,
    }));
  }, [categoryConfiguration]);

  const setTopic = (event) => {
    setTopicRecords([]);
    setSelectedRecord();
    const topicSelected = topics.find((top) => top.categoryName === event);
    console.log("topicSelected: ", topicSelected);
    fetchData(topicSelected.apiName)
      .then((results) => {
        const updatedResult = Array.isArray(results)
          ? results
          : results[Object.keys(results)[0]];
        setTopicRecords(updatedResult);
      })
      .catch((e) => console.error(e));
    setSelectedTopic(topicSelected);
  };

  const onNext = () => {
    form.validateFields().then(() => {
      setDocumentToAdd(selectedRecord);
      setShowModal(true);
    });
  };

  function onRecordSelect(value, option) {
    if (value) {
      setSelectedRecord({
        recordName: value,
        recordId: option?.objId,
        categoryName: selectedTopic?.categoryName,
        googleDriveFolderIds: option?.googleDriveFolderIds,
      });

      filterTables("documentation", "recordId", option?.objId).then((res) => {
        setRecordDocuments(
          res.filter((doc) => !doc?.renewalId && !doc?.revisedId)
        );
      });
    }
  }

  function onDocumentationTypeChange(e) {
    setThisType(e);
    const repeatedDocType = recordDocuments?.some((el) => el?.docType === e);
    setSaveAsTypeShow(repeatedDocType);
  }

  useEffect(() => {
    fetchData(apiRoutes.docConfiguration).then((r) => {
      if (Array.isArray(r) && r.length > 0) {
        setDocConfiguration(r);
      }
    });
  }, []);

  return (
    <>
      <Modal
        {...{
          ...antdModalProps,
          title: "New Documentation",
          afterOpenChange: (event) => {
            event && updateDocumentTitle({ newTitle: "New Documentation" });
          },
          open: visible,
          className: `newDocumentationModal ${
            isDarkMode && "newDocumentationModalDark"
          }`,
          footer: [
            <MondayButton
              {...{
                Icon: <TickIcon width={17} height={17} />,
                onClick: onNext,
                disabled: !selectedTopic || !selectedRecord || !thisType,
              }}
            >
              Next
            </MondayButton>,
          ],
          onCancel: () => {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            setVisible(false);
            setSelectedRecord();
            setTopicRecords([]);
            setLoading(false);
          },
        }}
      >
        <div className="documentation-modal-content">
          <InputComponent
            required
            showSearch
            label="Topic"
            dropdownClassName={isDarkMode && "darkDropDown"}
            placeholder="Select one topic"
            type="select"
            options={topics?.map(({ categoryName, categoryId }) => (
              <Option key={categoryId} value={categoryName}>
                {categoryName}
              </Option>
            ))}
            onSelect={(e) => setTopic(e)}
            onClear={() => {
              setLoading(false);
              setSelectedRecord();
              setTopicRecords([]);
            }}
          />
          {topicRecords.length > 0 ? (
            <InputComponent
              key={selectedTopic.categoryId}
              required
              showSearch
              label="Related Record"
              placeholder="Select one Record"
              type="select"
              disabled={false}
              options={topicRecords?.map((row, i) => {
                const body = "row?.";

                const rowName = selectedTopic?.otherName
                  ? `${row[selectedTopic.otherName]} | ${
                      selectedTopic?.prefix || ""
                    } ${row[selectedTopic?.rowName]}`
                  : eval(`${body}${selectedTopic?.rowName}`);
                return (
                  <Option
                    key={i}
                    value={rowName}
                    googleDriveFolderIds={row?.googleDriveFolderIds || {}}
                    objId={row[selectedTopic.primaryKey]}
                  >
                    {rowName}
                  </Option>
                );
              })}
              onChange={onRecordSelect}
              onClear={() => setSelectedRecord()}
            />
          ) : (
            loading && (
              <div className="loader">
                <IndividualLoader />
              </div>
            )
          )}
          {topicRecords?.length > 0 ? (
            <Form {...{ form, preserve: false }}>
              <div className="selectorContainer">
                <LabeledInput
                  {...{
                    label: "Documentation Type",
                    className: "docSelector",
                    dropdownClassName: isDarkMode && "darkDropDown",
                    formItemName: FORM_ITEM_NAME,
                    required: true,
                  }}
                >
                  <Select
                    {...{
                      options,
                      popupClassName: isDarkMode && "darkDropDown",
                      suffixIcon: <DropDownArrow />,
                      onChange: onDocumentationTypeChange,
                    }}
                  />
                </LabeledInput>
              </div>
              {!!saveAsTypeShow && (
                <InputComponent
                  type="radio"
                  label={"Create As"}
                  formItemName={SAVE_AS_TYPE}
                  required={true}
                  value={radioValue}
                  onChange={(e) => {
                    if (e?.target?.value === "original") {
                      setSelectedOriginalDocument(false);
                    }
                  }}
                  options={[
                    <Radio value={"original"} key={0}>
                      Original
                    </Radio>,
                    <Radio value={"revised"} key={1}>
                      Revised
                    </Radio>,
                    <Radio value={"renewal"} key={2}>
                      Renewal
                    </Radio>,
                  ]}
                />
              )}
              {saveAsTypeShow && radioValue && radioValue !== "original" && (
                <div
                  className="original-cards"
                  style={{
                    height: recordDocuments?.length >= 3 * 54,
                    maxHeight: 162,
                  }}
                >
                  {(recordDocuments || []).map((el) => {
                    const statusBg = populatedStatuses.find(
                      (status) => status.statusName === el.docStatus
                    )?.statusColor;

                    return (
                      <div
                        key={el?.docId}
                        className={`original-card-container ${
                          selectedOriginalDocument === el?.docId
                            ? "selected"
                            : ""
                        }`}
                        onClick={() => setSelectedOriginalDocument(el?.docId)}
                      >
                        <div className="card-name">
                          <div className="doc-title">{el?.docType}</div>
                          <div className="doc-created">
                            {dayjsNY(el?.createdAt).format("MM/DD/YYYY")}
                          </div>
                        </div>
                        <div
                          className="doc-status"
                          style={{ backgroundColor: statusBg || "#ecde7c" }}
                        >
                          {el?.docStatus}
                        </div>
                      </div>
                    );
                  })}
                </div>
              )}
            </Form>
          ) : (
            ""
          )}
        </div>
      </Modal>
      {!!showModal && documentToAdd && (
        <DocumentationModal
          {...{
            visible: showModal,
            setVisible: (e) => {
              setShowModal(e);
              setVisible(e);
            },
            recordId: selectedRecord?.recordId,
            recordName: selectedRecord?.recordName,
            viewTitle: selectedRecord?.recordName,
            saveAsType: radioValue,
            configExpirationDate,
            docId: "",
            docType: thisType,
            categoryName: selectedRecord?.categoryName,
            docObject:
              categoryConfiguration?.documentationsAvailable?.find(
                (el) => el?.docName === thisType
              )?.docObject ?? [],
            documentation,
            docStatuses:
              categoryConfiguration?.documentationsAvailable?.find(
                (el) => el?.docName === thisType
              )?.docStatuses ?? [],
            populatedStatuses,
            forceEnableWrite: true,
            recordFolderId: selectedRecord?.googleDriveFolderIds?.docObject,
            recordId: selectedRecord?.recordId,
            setDocumentation: () => {},
            docType: thisType,
            renewalId: radioValue !== "original" && selectedOriginalDocument,
            // recordValue: rowData?.opportunityAddress,
          }}
        />
      )}
    </>
  );
};
export default NewDocumentationModal;

import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { quickStatistics_Schedule_and_Project } from "../../components/SidebarPages/Scheduling/helpers/quickStatistics";

export const getSchedulingTotalProgressWithCharges = async () => {
  let [scheduling, chargesRes] = await Promise.all([
    fetchAllData("scheduling", "scheduling", "scheduleId"),
    fetchAllData("charges", "charges", "chargeId"),
  ]);

  let result = scheduling.map((schedule) => ({
    createdAt: schedule.createdAt,
    projectId: schedule.projectId,
    projectExecutive: schedule.projectExecutive,
    scheduleAddress: schedule.scheduleAddress,
    scheduleId: schedule.scheduleId,
    scheduleName: schedule.scheduleName,
    scheduleStatus: schedule.scheduleStatus,
    typeOfWork: schedule.typeOfWork,
    services: Object.keys(schedule.toBeScheduled)
      .map((key) => {
        return schedule.toBeScheduled[key].map((service) => {
          const charges = chargesRes.filter(
            (charge) =>
              charge.projectId === schedule.projectId &&
              charge.categoryFrom === "Estimation" &&
              charge.recordId === key
          );

          const { scheduleTotalProgressPercentage } =
            quickStatistics_Schedule_and_Project({
              project: [],
              toBeScheduled: schedule?.toBeScheduled,
              schedules: [],
              typeOfWork: schedule?.typeOfWork,
            });

          return {
            createdAt: schedule.createdAt,
            projectId: schedule.projectId,
            projectExecutive: schedule.projectExecutive,
            scheduleAddress: schedule.scheduleAddress,
            scheduleId: schedule.scheduleId,
            scheduleName: schedule.scheduleName,
            scheduleStatus: schedule.scheduleStatus,
            typeOfWork: schedule.typeOfWork,
            estimationNumber: service.estimationNumber,
            serviceName: service.label,
            totalProgress: scheduleTotalProgressPercentage,
            totalChargeAmount: charges.reduce(
              (acc, charge) =>
                (acc += charge.chargeItems
                  .filter((chargeItem) => chargeItem.label === service.label)
                  .reduce(
                    (sum, item) => (sum += item.totalities?.totalPrice || 0),
                    0
                  )),
              0
            ),
          };
        });
      })
      .flat(),
  }));
  return result;
};

import { Checkbox } from "antd";
import "./AlternativeAddressSelect.scss";
import { useSelector } from "react-redux";

function AlternativeAddressSelect({
  selectedProject = {},
  setSelectedAltAddresses,
  selectedAltAddresses,
}) {
  const alternativeAddresses = selectedProject?.alternativeAddresses || [];
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <div
      className={`alternative-addresses-select ${
        isDarkMode ? "alternative-addresses-select-dark" : ""
      }`}
    >
      <div className="alternative-addresses-label">
        <label className="add-label">Alternative Addresses</label>
        {alternativeAddresses?.length > 1 && (
          <Checkbox
            className="alternative-addresses-label-checkbox"
            indeterminate={
              selectedAltAddresses.length > 0 &&
              selectedAltAddresses.length < alternativeAddresses.length
            }
            checked={
              selectedAltAddresses.length === alternativeAddresses.length
            }
            onChange={(e) => {
              if (e.target.checked) {
                setSelectedAltAddresses(alternativeAddresses);
              } else {
                setSelectedAltAddresses([]);
              }
            }}
          >
            Select All
          </Checkbox>
        )}
      </div>
      <div className="alternative-addresses-checkbox">
        {alternativeAddresses?.length > 0 ? (
          alternativeAddresses.map((el, i) => (
            <Checkbox
              key={el?.address + i}
              className="alternative-addresses-checkbox-item"
              checked={selectedAltAddresses.some(
                (item) => item?.address === el?.address
              )}
              onChange={(e) => {
                if (e.target.checked) {
                  setSelectedAltAddresses((prev) => [...prev, el]);
                } else {
                  setSelectedAltAddresses((prev) =>
                    prev.filter((item) => item?.address !== el?.address)
                  );
                }
              }}
            >
              {el?.address}
            </Checkbox>
          ))
        ) : (
          <div className="alternative-addresses-checkbox-item">
            No alternative addresses. Go to the project to add some (Not
            required).
          </div>
        )}
      </div>
    </div>
  );
}

export default AlternativeAddressSelect;

import { useEffect, useState } from "react";

import { ServiceCard } from "../";
import { LoadableComp } from "../../../../../../../XComponents";

import { sortServices } from "../../utils";

import styles from "./VersionComparison.module.css";

const VersionComparison = ({
  services,
  serviceToCompare,
  serviceVersion,
  serviceVersionToCompare,
}) => {
  const sortedServices = sortServices(services, serviceToCompare);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const timer = setTimeout(() => {
      setLoading(false);
    }, 0);

    return () => clearTimeout(timer);
  }, []);

  return (
    <LoadableComp loading={loading}>
      <div className={styles.versionComparisonContainer}>
        <div className={styles.serviceVersion}>
          {sortedServices.services.map((service, serviceIndex) => (
            <ServiceCard
              key={service.serviceId}
              service={service}
              version={serviceVersion}
              serviceToCompare={sortedServices.servicesToCompare}
              serviceIndex={serviceIndex}
            />
          ))}
        </div>

        <div className={styles.serviceVersion}>
          {serviceToCompare.map((service, serviceIndex) => (
            <ServiceCard
              key={service.serviceId}
              service={service}
              version={serviceVersionToCompare}
              serviceToCompare={services}
              serviceIndex={serviceIndex}
            />
          ))}
        </div>
      </div>
    </LoadableComp>
  );
};

export default VersionComparison;

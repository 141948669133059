import { wsRefGetter } from "../../App";

/**
 * Function that handles the message received
 * @param {MessageEvent<{request: string, body:any}>} event
 * @param {WebSocket} socket
 */
function messageHandlerFunction(event, socket) {
  //#region MESSAGE HANDLER
  try {
    const message = JSON.parse(event?.data);

    if (message?.body?.originUrl === `/${socket.url.split("/").at(-1)}`) {
      if (message.request === "notificationSent") {
        const {
          body: { notificationObj },
        } = message;
        return notificationObj || "notificationSent";
      } else if (message.request === "error") {
        return false;
      }
    }
    return false;
  } catch (err) {
    console.error("Error parsing WebSocket message:", err);
    return false;
  }
}

/**
 * @typedef {Record<string, any>} TemplateParams
 *
 * @typedef UsersInfo
 * @property {string} userName - nameOfUser of the logged user
 * @property {string} currentUser - cognitoId of the logged user
 * @property {string[]} cognitos - Receiving users
 *
 * @param {string} topic
 * @param {string} action
 * @param {[TemplateParams, UsersInfo]} actionData
 * @param {string} id - id if it is included in template {{id}}
 */
const broadcastNotification = (topic, action, actionData, id) => {
  //#region BROADCAST
  return new Promise((resolve) => {
    const socket = wsRefGetter();

    // check ws is online
    if (!socket || socket.readyState !== WebSocket.OPEN) {
      resolve(false);
      return;
    }

    function socketHandler(event) {
      try {
        const message = JSON.parse(event.data);
        if (message?.request === "error") {
          resolve(false);
        } else {
          resolve(messageHandlerFunction(event, socket));
        }
      } catch (error) {
        console.error("Error processing WebSocket message:", error);
        resolve(false);
      } finally {
        socket.removeEventListener("message", socketHandler);
      }
    }

    try {
      // validate missing data
      if (!topic || !action || !actionData || actionData?.length < 2) {
        console.error("broadcastNotification missed necessary data!");
        console.error({
          topic,
          action,
          actionDataLength: actionData?.length,
        });
        resolve(false);
        return;
      }

      // send message
      socket.addEventListener("message", socketHandler);

      socket.send(
        JSON.stringify({
          request: "broadcast-notification",
          body: { topic, action, actionData, id },
        })
      );
    } catch (e) {
      socket.removeEventListener("message", socketHandler);
      console.error("broadcastNotification Error: ", e);
      resolve(false);
    }
  });
};

export default broadcastNotification;

import React, { useState, useRef, useEffect } from "react";
import { Form, Input } from "antd";
import "./PredictInput.scss";

const escapeRegExp = (string) => {
  return string.replace(/[.*+?^${}()|[\]\\]/g, "\\$&");
};

const PredictInput = ({
  value: propValue,
  prefix,
  suffix,
  onPressEnter,
  placeholder,
  onChange,
  maxLength,
  addonAfter,
  addonBefore,
  defaultValue,
  disabled,
  autoSuggestWords = [],
  onTabEnter = () => {},
  form,
  formItemName,
}) => {
  const ref = useRef(null);
  const [suggestion, setSuggestion] = useState("");
  const [value, setValue] = useState(propValue || "");

  const inputFormValue = Form.useWatch(formItemName, form);

  let words = autoSuggestWords?.sort();

  // * This function is used to check the case sensitivity of the input and the word
  const checkCaseSensitivity = (word, input) => {
    word = word.split("");
    let inp = input.split(" ").pop();
    for (let i in inp) {
      if (inp[i] == word[i]) {
        continue;
      } else if (inp[i].toUpperCase() == word[i]) {
        word.splice(i, 1, word[i].toLowerCase());
      } else {
        word.splice(i, 1, word[i].toUpperCase());
      }
    }
    return word.join("");
  };

  useEffect(() => {
    if (!!propValue) {
      form.setFieldsValue({ [formItemName]: propValue });
      // setValue(propValue);
    }
  }, []);

  //* This useEffect is used to update the onChange function of the parent component
  useEffect(() => {
    const e = {
      target: {
        value: inputFormValue,
      },
    };
    onChange(e);
  }, [inputFormValue]);

  // * This function is used to update the value of the input and the suggestion
  const onInputChange = (e) => {
    // setValue(e.target.value);
    form.setFieldsValue({ [formItemName]: e.target.value });
    const input = e.target.value;
    const lastWord = input.split(" ").pop();

    setSuggestion("");
    const escapedLastWord = escapeRegExp(lastWord);
    const regex = new RegExp("^" + escapedLastWord, "i");
    for (let i in words) {
      if (regex.test(words[i]) && !!lastWord) {
        words[i] = checkCaseSensitivity(words[i], lastWord);
        let temp = input?.split(" ");
        temp.pop();
        setSuggestion(temp.join(" ") + (temp.length > 0 ? " " : "") + words[i]);
        break;
      }
    }
  };
  return (
    <div className="predictInputContainer">
      <Input
        ref={ref}
        prefix={prefix}
        value={inputFormValue || ""}
        className={"predictInput"}
        style={{ zIndex: 5, backgroundColor: "transparent !important" }}
        // suffix={suffixIcon}
        // onPressEnter={onPressEnter}
        autoComplete="off"
        placeholder={placeholder}
        onKeyDown={(e) => {
          if ((e.key === "Tab" || e.key === "Enter") && !!suggestion) {
            e.preventDefault();
            form.setFieldsValue({ [formItemName]: suggestion });
            // setValue(suggestion);
            onTabEnter(suggestion);
            setSuggestion("");
          }
        }}
        onChange={onInputChange}
        maxLength={maxLength}
        type="text"
        addonAfter={addonAfter}
        addonBefore={addonBefore}
        disabled={disabled}
        // {...(!!noFormItem ? { defaultValue: initialValue } : {})}
        defaultValue={defaultValue}
      />
      <span className="suggestion" style={{ zIndex: 4 }}>
        {suggestion}
      </span>
    </div>
  );
};

export default PredictInput;

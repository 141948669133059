//@ts-check

import { API } from "aws-amplify";
import { updateProgramFieldById } from "../actions/programFields";

export const updateProgramFields = async (fieldId, fieldOptions, dispatch) => {
  return await API.patch("programFields", `/programFields/${fieldId}`, {
    body: { fieldOptions },
  }).then(dispatch(updateProgramFieldById({ fieldId, fieldOptions })));
};

export const geofenceCountByCategory = (rowData) => {
  if (!rowData) return null;
  return rowData.length
    ? rowData?.reduce((acc, curr) => {
        const category = curr.isDrawedIn;
        acc[category] = (acc[category] || 0) + curr.geofencesQuantity;
        return acc;
      }, {})
    : null;
};

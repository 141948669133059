export const chartJsBackgroundColor = [
  "rgba(255, 99, 132, 0.8)",
  "rgba(54, 162, 235, 0.8)",
  "rgba(255, 206, 86, 0.8)",
  "rgba(75, 192, 192, 0.8)",
  "rgba(153, 102, 255, 0.8)",
  "rgba(255, 159, 64, 0.8)",
  "rgba(255, 182, 193, 0.8)",
  "rgba(135, 206, 250, 0.8)",
  "rgba(0, 128, 128, 0.8)",
  "rgba(255, 20, 147, 0.8)",
  "rgba(173, 216, 230, 0.8)",
  "rgba(255, 140, 0, 0.8)",
  "rgba(240, 128, 128, 0.8)",
  "rgba(148, 0, 211, 0.8)",
  "rgba(0, 250, 154, 0.8)",
  "rgba(255, 69, 0, 0.8)",
  "rgba(0, 191, 255, 0.8)",
  "rgba(127, 255, 0, 0.8)",
  "rgba(255, 215, 0, 0.8)",
  "rgba(30, 144, 255, 0.8)",
  "rgba(138, 43, 226, 0.8)",
  "rgba(60, 179, 113, 0.8)",
  "rgba(70, 130, 180, 0.8)",
  "rgba(255, 0, 255, 0.8)",
  "rgba(0, 250, 255, 0.8)",
  "rgba(218, 165, 32, 0.8)",
  "rgba(219, 112, 147, 0.8)",
  "rgba(127, 0, 255, 0.8)",
  "rgba(34, 139, 34, 0.8)",
  "rgba(255, 160, 122, 0.8)",
  "rgba(178, 34, 34, 0.8)",
  "rgba(255, 105, 180, 0.8)",
  "rgba(135, 86, 130, 0.8)",
  "rgba(255, 140, 86, 0.8)",
  "rgba(240, 230, 140, 0.8)",
  "rgba(100, 149, 237, 0.8)",
  "rgba(0, 255, 127, 0.8)",
  "rgba(255, 99, 71, 0.8)",
  "rgba(0, 206, 209, 0.8)",
  "rgba(147, 112, 219, 0.8)",
  "rgba(255, 228, 225, 0.8)",
  "rgba(255, 246, 143, 0.8)",
  "rgba(255, 228, 181, 0.8)",
  "rgba(139, 69, 19, 0.8)",
  "rgba(65, 105, 225, 0.8)",
  "rgba(255, 127, 80, 0.8)",
  "rgba(255, 222, 173, 0.8)",
  "rgba(221, 160, 221, 0.8)",
  "rgba(205, 92, 92, 0.8)",
  "rgba(32, 178, 170, 0.8)",
];

export const chartJsBorderColor = [
  "rgba(255, 99, 132, 1)",
  "rgba(54, 162, 235, 1)",
  "rgba(255, 206, 86, 1)",
  "rgba(75, 192, 192, 1)",
  "rgba(153, 102, 255, 1)",
  "rgba(255, 159, 64, 1)",
  "rgba(255, 182, 193, 1)",
  "rgba(135, 206, 250, 1)",
  "rgba(0, 128, 128, 1)",
  "rgba(255, 20, 147, 1)",
  "rgba(173, 216, 230, 1)",
  "rgba(255, 140, 0, 1)",
  "rgba(240, 128, 128, 1)",
  "rgba(148, 0, 211, 1)",
  "rgba(0, 250, 154, 1)",
  "rgba(255, 69, 0, 1)",
  "rgba(0, 191, 255, 1)",
  "rgba(127, 255, 0, 1)",
  "rgba(255, 215, 0, 1)",
  "rgba(30, 144, 255, 1)",
  "rgba(138, 43, 226, 1)",
  "rgba(60, 179, 113, 1)",
  "rgba(70, 130, 180, 1)",
  "rgba(255, 0, 255, 1)",
  "rgba(0, 250, 255, 1)",
  "rgba(218, 165, 32, 1)",
  "rgba(219, 112, 147, 1)",
  "rgba(127, 0, 255, 1)",
  "rgba(34, 139, 34, 1)",
  "rgba(255, 160, 122, 1)",
  "rgba(178, 34, 34, 1)",
  "rgba(255, 105, 180, 1)",
  "rgba(135, 86, 130, 1)",
  "rgba(255, 140, 86, 1)",
  "rgba(240, 230, 140, 1)",
  "rgba(100, 149, 237, 1)",
  "rgba(0, 255, 127, 1)",
  "rgba(255, 99, 71, 1)",
  "rgba(0, 206, 209, 1)",
  "rgba(147, 112, 219, 1)",
  "rgba(255, 228, 225, 1)",
  "rgba(255, 246, 143, 1)",
  "rgba(255, 228, 181, 1)",
  "rgba(139, 69, 19, 1)",
  "rgba(65, 105, 225, 1)",
  "rgba(255, 127, 80, 1)",
  "rgba(255, 222, 173, 1)",
  "rgba(221, 160, 221, 1)",
  "rgba(205, 92, 92, 1)",
  "rgba(32, 178, 170, 1)",
];

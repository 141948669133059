import { Modal } from "antd";
import EventsCalculatorHeader from "./EventsCalculatorHeader";
import EventsCalculatorBody from "./EventsCalculatorBody";
import EventsCalculatorFooter from "./EventsCalculatorFooter";
import "./EventsCalculatorModal.scss";
import { ElevationEventsContext } from "./ElevationEventsContext";
import { useRedux } from "../../../../../../../../../../../../hooks";
import {
  customBalancePriceOfSOVHandler,
  totalPriceOfSOVHandler,
} from "../../../../../../../Header/Components/ControlPanel/controlPanelFunctions";
import Swal from "sweetalert2";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../../../../../Context/Context";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useEditLogs } from "../../../../../../../../../../../../../../hooks";
const EventsCalculatorModal = ({ isOpen, onClose, ...rest }) => {
  const {
    isWritable = false,
    isAutoSaving = false,
    fakeSOV = {},
    errors = {},
    darkMode = true,
    retracted = false,
  } = useStateContext();
  const dispatchContext = useDispatchContext();
  const dispatch = useDispatch();
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const [rowDatas] = useRedux("rowDatasEventsSov");
  const [distributedElevationData] = useRedux("distributedElevationData");
  // const totalPriceOfSOV = totalPriceOfSOVHandler({ fakeSOV });
  const { saveAddedLogs } = useEditLogs();

  const onConfirm = (data) => {
    // message.loading("We are performing the distribution", 0);
    console.log("onConfirm", { data, props, fakeSOV });
    const updateScheduleOfValueHandler = async (e) => {
      const balance = customBalancePriceOfSOVHandler({ fakeSOV });
      if (balance?.balanceAmount !== 0) {
        Swal.fire({
          title: "IMMEDITE ATTENTION!",
          text: "You have stll balance left! Please check your SOV!",
          icon: "warning",
        });
      }
      const sovEvents = serviceDefinitions?.map((item) => ({
        serviceId: item?.serviceId,
        serviceName: item?.serviceName,
        scheduleOfValues: item?.scheduleOfValues,
        listOfEvents: item?.listOfEvents,
      }));
      const whatIHaveToPost = {};
      console.log("sovEvents", sovEvents);
      e.preventDefault();
      if (Array.isArray(sovEvents) && Array.isArray(fakeSOV?.services)) {
        fakeSOV?.services?.forEach((item) => {
          if (!checkIfServiceIsHoist(item)) {
            if (Array.isArray(item?.amounts)) {
              item?.amounts?.forEach((amount) => {
                let tempList =
                  sovEvents?.find(
                    (el) =>
                      el?.serviceId?.toString() === item?.serviceId?.toString()
                  )?.listOfEvents || [];
                if (Array.isArray(tempList) && tempList.length > 0) {
                  if (!tempList.includes(amount?.name)) {
                    if (!Array.isArray(whatIHaveToPost[item?.serviceId])) {
                      whatIHaveToPost[item?.serviceId] = [amount?.name];
                    } else {
                      whatIHaveToPost[item?.serviceId].push(amount?.name);
                    }
                  }
                }
              });
            }
          } else {
            let sOptions = item?.serviceOptions;
            if (Array.isArray(sOptions) && Array.isArray(sOptions?.[0])) {
              sOptions?.[0]?.forEach((option) => {
                let tempAmounts = option?.amounts ?? [];
                tempAmounts.forEach((amount) => {
                  let tempList =
                    sovEvents?.find(
                      (el) =>
                        el?.serviceId?.toString() ===
                        item?.serviceId?.toString()
                    )?.listOfEvents || [];
                  if (Array.isArray(tempList) && tempList.length > 0) {
                    if (!tempList.includes(amount?.name)) {
                      if (!Array.isArray(whatIHaveToPost[item?.serviceId])) {
                        whatIHaveToPost[item?.serviceId] = [amount?.name];
                      } else {
                        whatIHaveToPost[item?.serviceId].push(amount?.name);
                      }
                    }
                  }
                });
              });
            }
          }
        });
      }
      console.log("what i have to post before Updating", {
        whatIHaveToPost,
        fakeSOV,
      });
      fakeSOV.services = cleanUpUnnecessaryData(fakeSOV.services);
      console.log("what i have to post after updating", {
        whatIHaveToPost,
        fakeSOV,
      });
      // create an object `updatedElementServices` with updated values for some properties
      const updatedElementServices = {
        sovStatus: fakeSOV.sovStatus,
        services: fakeSOV.services,
      };
      // get the data that has changed between `updatedElementServices` and a target object
      const changedData = getChangedData(updatedElementServices, {
        sovStatus: scheduleOfValue.sovStatus,
        services: scheduleOfValue.services,
        // totalPrice: scheduleOfValue?.totalPrice,
      });
      // create a new object `newEditLog` with information about the changes
      const newEditLog = {
        recordId: scheduleId,
        recordName: `SOV ${scheduleOfValue?.SOVNo}`,
        actionType: "Edit",
        topic: scheduleOfValue?.projectName,
        currentData: changedData.curr,
        nameOfUser: `${authUser.given_name} ${authUser.family_name}`,
        cognitoUserId: authUser?.sub,
        previousData: changedData.prev,
        updatedAt: dayjs().valueOf(),
        updatedKeys: Object.keys(changedData),
      };
      let tmp = editLogs?.concat(newEditLog);
      setEditLogs(tmp);

      saveAddedLogs(newEditLog);

      message.loading({ content: "Saving...", key: "saving" });
      let promises = [];
      // Object.entries(whatIHaveToPost)
      for (const [key, value] of Object.entries(whatIHaveToPost)) {
        if (value.length > 0) {
          let tempRequest = sovEvents?.find(
            (el) => el?.serviceId?.toString() === key?.toString()
          );
          let newEvents = tempRequest?.listOfEvents?.concat(value);
          // let promises = await
          promises.push(await updateNewEvents(key, newEvents));
        }
      }
      if (Array.isArray(promises) && promises.length > 0) {
        await Promise.all(promises).then((res) => {
          Swal.fire({
            title: "Success! You added NEW SOV Events",
          });
        });
      }
      // dispatch an action to update the schedule of value with the updated data
      dispatch(updateScheduleOfValue(scheduleId, fakeSOV));
    };
  };

  const onRestart = () => {
    onClose();
    // modalSwitcher();
  };

  return (
    <>
      {/* <ElevationEventsContext.Provider> */}
      {isOpen && (
        <Modal
          open={isOpen}
          centered={true}
          className="eventsCalculatorModalDark"
          title={"Events Calculator Modal"}
          width={1600}
          onCancel={onClose}
          destroyOnClose={true}
          footer={
            <EventsCalculatorFooter
              onConfirm={onConfirm}
              onClose={onClose}
              eventProps={rest}
              onRestart={onRestart}
            />
          }
        >
          <div className="eventsCalculatorModalWrapper">
            {/* <EventsCalculatorHeader /> */}
            <EventsCalculatorBody eventProps={rest} />
          </div>
        </Modal>
      )}
      {/* </ElevationEventsContext.Provider> */}
    </>
  );
};
export default EventsCalculatorModal;

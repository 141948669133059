import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { Segmented } from "antd";
import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../Fleet/components";
import { ReportCheck } from "../../../../../Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import { initialProjectSafetyReportData } from "../../../../../Projects/ProjectSafetyReport/helpers";
import EditSafetyContacts from "../../../../../Projects/ProjectSafetyReport/EditSafetyContacts";
import HospitalsNearby from "../../../../../Projects/ProjectSafetyReport/HospitalsNearby";
import { SmallLoadableComp } from "../../../../../../Sidebars/components";

function AddSafetyContacts({
  initialValues,
  setInitialValues,
  dynamicStyles,
  setDynamicStyles,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);

  const [showContacts, setShowContacts] = useState(false);
  const [additionalData, setAdditionalData] = useState([]);
  const [noProject, setNoProject] = useState(false);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    async function getHospitals() {
      const rowData = initialValues?.find(
        (el) => el.title === "projectData"
      )?.body;
      if (!rowData) {
        setNoProject(true);
        return;
      }
      if (!!additionalData?.length) {
        setInitialValues(
          initialValues.concat(
            additionalData.map((el) => ({ ...el, other: true }))
          )
        );
        return;
      }
      setLoading(true);
      const hospitals = await initialProjectSafetyReportData({
        rowData,
        userConfiguration,
        accessToken,
      });
      setLoading(false);
      setAdditionalData(hospitals);
      setInitialValues(
        initialValues.concat(hospitals.map((el) => ({ ...el, other: true })))
      );
    }
    if (!!initialValues) {
      if (showContacts) {
        getHospitals();
      } else {
        setInitialValues(initialValues?.filter((el) => !el?.other));
      }
    }
  }, [additionalData, showContacts]);

  function onGapChange(e) {
    let val = e.target.value;
    if (!val) {
      val = 0;
    }
    setDynamicStyles({
      ...dynamicStyles,
      projectName: { "margin-top": val + "px" },
    });
  }

  return (
    <div className="tab-container safety-contacts-tab">
      <ReportCheck
        {...{
          id: "showContacts",
          label: "Show Safety Contacts and nearby hospitals",
          checked: showContacts,
          onChange: (e) => setShowContacts(e),
        }}
      />
      {loading ? <SmallLoadableComp loading={loading} /> : null}
      {showContacts && additionalData?.length ? (
        <>
          <InputComponent
            {...{
              typeNumber: true,
              label: "Adjust gap between the 2 documents",
              noFormItem: true,
              initialValue:
                dynamicStyles?.["projectName"]?.["margin-top"]?.replace(
                  "px",
                  ""
                ) || 0,
              onChange: onGapChange,
              style: { maxWidth: "90%" },
            }}
          />
          <HospitalsNearby
            {...{
              initialValues,
              setInitialValues,
            }}
          />
          <EditSafetyContacts
            {...{
              initialValues,
              setInitialValues,
            }}
          />
        </>
      ) : null}
      {noProject ? (
        <div>This Safety does not have a project connected</div>
      ) : null}
    </div>
  );
}

export default AddSafetyContacts;

import { API } from "aws-amplify";
import { message } from "antd";
import { postToDoLogs, removeSearchParams } from ".";
import { upperCaseFirstLetter } from "../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getCognitosForNotification } from "../../../../../utils";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

/**
 *
 * @param {String} recordId - the id of the record we are creating a to do for
 * @param {String} createFolderId - the id of the google drive folder
 * @param {Object} form - the form instance object
 * @param {Function} setRowData - function to set the row data
 * @param {Object} userConfiguration - the user configuration object
 * @param {Function} setVisible - function to set the visibility of the modal
 * @param {String} todoId - the id of the to do
 * @param {Array} statuses - the statuses of the to do
 * @param {Number} currentStep - the current step of the to do
 * @param {Object} defaultObject - the default object
 * @param {Function} setRowObject - function to set the row object
 * @param {String} category - the category of the to do
 * @param {Function} onAddTodo - call back function that is executed when we add a to do
 * @param {Function} onEditTodo - call back function that is executed when we edit a to do
 * @param {String} recordName - the name of the record
 * @param {Array} records - the records
 * @param {Array} formattedFiles - the formatted files
 * @param {Object} authenticatedUser - the authenticated user object
 * @param {Function} setVisibleCreationProgress - function to set the visibility of the progress
 * @param {Function} updateProgressStatus - function to update the progress status
 * @param {Function} nextStepHandler - function to handle the next step
 * @param {Function} handleSavedRecord - function to handle the saved record
 * @param {Function} deleteDriveItem - function to delete the drive item
 * @param {Array} filesToBeDeleted - the files to be deleted
 * @param {Function} saveAddedLogs - function to save the added logs
 * @returns
 */
async function createToDo({
  type = "create",
  recordId = "",
  createFolderId = "",
  form = null,
  setRowData = () => {},
  userConfiguration = {},
  setVisible = () => {},
  todoId = null,
  statuses = [],
  currentStep = 0,
  defaultObject = null,
  setRowObject = () => {},
  category = null,
  customLinkTo = null,
  onAddTodo = () => {},
  onEditTodo = () => {},
  recordName = "",
  records = [],
  formattedFiles = [],
  authenticatedUser = {},
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  deleteDriveItem = async () => {},
  filesToBeDeleted = [],
  saveAddedLogs = async () => {},
  geoFenceInfo = [],
  relatedTo,
  geoCoords,
}) {
  // for geofence
  const latLngKeys =
    category === "projects"
      ? { lat: "lat", lng: "lng" }
      : category === "opportunities"
      ? { lat: "opportunityLatitude", lng: "opportunityLongitude" }
      : false;

  const { relatedRecord, ...formValues } = form.getFieldsValue();

  const todoDueDate = dayjs(
    `${formValues?.dueDate} ${formValues?.dueTime}`
  ).valueOf();

  let urlCategory = window.location.pathname.includes("drivers")
    ? "drivers"
    : window.location.pathname.split("/")[1];

  const formattedCategory = (
    !!category
      ? category
      : upperCaseFirstLetter(window.location.pathname.split("/")[1])
  ).toLowerCase();

  const fromSettings = window.location.pathname.split("/")[1] === "settings";

  const getLinkForSettings = () => {
    if (defaultObject) {
      return defaultObject.linkTo;
    }

    return customLinkTo.startsWith("/") ? customLinkTo : `/${customLinkTo}`;
  };

  let tmpBody = {
    // ...formValues,
    todoCategory: !!category ? category : upperCaseFirstLetter(urlCategory),
    todoRecordId: recordId || window.location.pathname.split("/")[2],
    todoStatus: statuses?.[currentStep]?.statusName,
    todoDetails: formValues?.description || formValues?.todoDetails,
    todoDueDate,
    todoUser: userConfiguration?.nameOfUser,
    lastUpdatedBy: type === "create" ? "" : userConfiguration?.nameOfUser,
    lastUpdatedAt: type === "create" ? "" : dayjs().valueOf(),
    cognitoUserId: userConfiguration?.cognitoUserId,
    relatedTo: formValues?.relatedTo || relatedTo,
    comments: formValues?.comments,
    todoName: formValues?.title || formValues?.todoName,
    googleDriveUploads: formattedFiles,
    recordName,
    subRecordName: {
      id: relatedRecord,
      name: records?.find((e) => e?.value === relatedRecord)?.label,
    },
    linkTo: `/${formattedCategory}${
      formattedCategory !== "reports" ? "/" : ""
    }${recordId || window.location.pathname.split("/")[2]}`,
    // [latLngKeys.lat]: geoCoords.lat,
    // [latLngKeys.lng]: geoCoords.lng,
    // geoFenceInfo,
    ...(latLngKeys && {
      [latLngKeys.lat]: geoCoords.lat,
      [latLngKeys.lng]: geoCoords.lng,
    }),
    ...(latLngKeys && geoFenceInfo && { geoFenceInfo }),
    fromSettings,
    linkTo: fromSettings
      ? getLinkForSettings()
      : `/${formattedCategory}${formattedCategory !== "reports" ? "/" : ""}${
          recordId || window.location.pathname.split("/")[2]
        }`,
  };

  setVisibleCreationProgress(true);

  updateProgressStatus({ updatingRecord: "executing" });
  /**
   * If there is no todoId we will create a new to do
   */
  if (!todoId) {
    message.loading({ content: "Saving...", key: "save" });
    return await API.post("todos", "/todos", {
      body: { ...tmpBody, googleDriveFolderIds: createFolderId },
    })
      ?.then(async (data) => {
        setVisibleCreationProgress(data); // save data to generate logs in case of any fail
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });
        message.success({ content: "Saved!", key: "save" });

        setRowData((prev) => {
          if (!!prev) {
            return [...prev, data];
          } else {
            return [data];
          }
        });
        onAddTodo(data);
        // setVisible(false);
        removeSearchParams();
        postToDoLogs({
          record: tmpBody,
          type: "save",
          prevRecord: null,
          recordId: data?.todoId,
          setRowObject,
          formattedFiles: [],
          saveAddedLogs,
        });

        // await broadcastNotification(
        broadcastNotification(
          "101",
          "onToDoCreate",
          [
            {
              common: userConfiguration?.nameOfUser,
              recordName,
              recordCategory: tmpBody?.todoCategory,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(userConfiguration),
            },
          ],
          data?.todoId
        )
          .then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          })
          .catch((error) => {
            console.log("error processing notification on create to do", error);
          });
        handleSavedRecord(data);
        nextStepHandler(true, "Todos");
        setVisible(false);
      })
      ?.catch((error) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        console.error("Error creating To Do", error);
        setVisible(false);
        removeSearchParams();
        message.error({ content: "Something went wrong", key: "save" });
      });
  } else {
    message.loading({ content: "Updating...", key: "save" });
    delete tmpBody.todoUser;
    delete tmpBody.todoRecordId;
    delete tmpBody.cognitoUserId;

    return await API.put("todos", `/todos/${todoId}`, {
      body: { ...tmpBody },
    })
      ?.then(async (data) => {
        setVisibleCreationProgress({ ...data, todoId });
        filesToBeDeleted.forEach(async (id) => await deleteDriveItem(id));

        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });
        message.success({ content: "Updated!", key: "save" });
        setRowData((prev) =>
          prev?.map((item) => (item.todoId === todoId ? data : item))
        );
        onEditTodo(data);
        postToDoLogs({
          record: tmpBody,
          type: "Edit",
          prevRecord: defaultObject,
          recordId: todoId,
          setRowObject,
          formattedFiles,
          saveAddedLogs,
        });
        removeSearchParams();

        const hasStatusChanged =
          tmpBody.todoStatus === defaultObject.todoStatus ? false : true;

        // await broadcastNotification(
        broadcastNotification(
          "101",
          hasStatusChanged ? "onToDoStatusEdit" : "onToDoEdit",
          [
            {
              common: userConfiguration?.nameOfUser,
              ...(!!hasStatusChanged && {
                common2: `Status from '${defaultObject.todoStatus}' to '${tmpBody.todoStatus}'`,
              }),
              recordName,
              recordCategory: tmpBody?.todoCategory,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(userConfiguration),
            },
          ],
          todoId
        )
          .then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          })
          .catch((error) => {
            console.log("error sending notification on todo edit", error);
          });
        setVisible(false);
      })
      ?.catch((error) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        message.error({ content: "Something went wrong", key: "save" });
        console.error("Error updating To Do", error);
        setVisible(false);
        removeSearchParams();
      });
  }
}

export default createToDo;

import { Alert } from "antd";
import React, { useCallback, useState } from "react";
import { useMemo } from "react";
import { filterTables } from "../../../../../../../../../../../utils";
import { dayjsNY } from "../../../../../../../../../../DateComponents/contants/DayjsNY";
import { useRedux } from "../../../../../../../../../hooks";

function RentalHelper() {
  const [selectedService] = useRedux("rentalsSelectedService");
  const [fakeRentals] = useRedux("fakeRentals");
  const [infoNotes, setInfoNotes] = useState(
    "Hold on, fetching information from Schedule..."
  );
  const fetchProgressItems = useCallback(async () => {
    try {
      if (!fakeRentals?.projectId) return;
      const allItems = await filterTables(
        "progressItems",
        "projectId",
        fakeRentals.projectId
      );

      const progressItems = allItems
        .filter(
          ({
            elevationId,
            serviceName,
            estimationId,
            pliProgressPercentage = 0,
          }) =>
            serviceName === selectedService &&
            estimationId === fakeRentals?.estimationId &&
            pliProgressPercentage > 0
        )
        ?.sort((a, b) => dayjsNY(a?.startDate) - dayjsNY(b?.startDate));
      return progressItems?.[0];
      console.log("Filtered Progress Items:", progressItems);
    } catch (error) {
      console.error("Error fetching progress items:", error);
    }
  }, [
    fakeRentals?.projectId,
    selectedService,
    // elevationData.elevationId,
    fakeRentals?.estimationId,
  ]);

  const selectedServiceData = useMemo(async () => {
    const service = fakeRentals?.services?.find(
      ({ label = "" }) => label === selectedService
    );
    let firstDay = await fetchProgressItems();
    let numberOfWeeksPastInst = service?.rentalPaymentTerms?.range || 0;

    console.log("First Day:", firstDay);
    let newInstallationDate = dayjsNY(firstDay?.startDate)
      .add(numberOfWeeksPastInst, "week")
      .valueOf();
    if (firstDay) {
      setInfoNotes(
        `The first day of ${selectedService} is ${dayjsNY(
          firstDay?.startDate
        ).format("MM/DD/YYYY")} and the installation date is ${dayjsNY(
          newInstallationDate
        ).format(
          "MM/DD/YYYY"
        )}, based on the progress items and rental payment terms. ${
          service?.rentalPaymentTerms?.range || "N/A"
        } weeks after the first day is ${dayjsNY(newInstallationDate).format(
          "MM/DD/YYYY"
        )}.`
      );
    } else {
      setInfoNotes("No progress items found for this service.");
    }
    return service;
  }, [selectedService, fakeRentals?.services, fetchProgressItems]);

  return (
    <div
      style={{
        padding: "0 0 10px 0",
      }}
    >
      {" "}
      <Alert message={infoNotes} type="info" showIcon />
    </div>
  );
}

export default RentalHelper;

import React from "react";
import {
  Button,
  message,
  Modal,
  Tooltip,
  Select,
  Switch,
  Input,
  AutoComplete,
} from "antd";
import _ from "lodash";
import { get_ProgressDimensionsByServiceId } from "../../../../DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import { AddonButton } from "../AddonsList/AddonButton";
import { completedItems, selectedItems } from "../../../helpers/PliInfoGetters";
import { SchedulingContext } from "../../../context";
import { MondayButton } from "../../../../../../commonComponents";
import { Tick } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import Swal from "sweetalert2";
import { Plus } from "../../../../../../SidebarPages/DynamicView/components/FilterView/FilterIcons";
import {
  breakdownsServiceId,
  breakInspProgress,
  calculateBreakdownsRate,
  columnDefsForBreakdowns,
  daysScheduledInServices,
  onlyAppliedBreakdowns,
  pliEditedFromNextSchedules,
  preventDuplicateStrings,
  prevPliCompleted,
  shareRates,
  sumProgressByDay,
  swalToast,
  upperCaseFirstLetter,
} from "../../../SchedulingFirstPage/helperData";
import "./Breakdown.scss";
import BreakdownsSelectedDays from "./BreakdownsSelectedDays";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { updateDocumentTitle } from "../../../../../../../utils";
import { AgGridReact } from "ag-grid-react";
import { PercentageIcon } from "../../../../../../SidebarPages/BasePage/src";
import { CrewsIcon } from "../../../../../../../assets";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";

/**
 * Modal to work with breakdowns. It is located in plis and in elevation
 * */
export class Breakdown extends React.Component {
  static contextType = SchedulingContext;
  state = {
    isModalVisible: false,
    breakdownValue: [],
    breakVisible: false,
    specificBreakdown: {},
    data: {
      name: null,
      rate: null,
    },
  };

  componentDidMount() {
    this.setInitialData();
  }

  componentDidUpdate(prevProps, prevState, snapshot) {
    if (
      JSON.stringify(prevProps.breakdownsInElevations) !==
      JSON.stringify(this.props.breakdownsInElevations)
    ) {
      this.setInitialData();
    }
  }

  get_Days_AND_breakdownValue = () => {
    const type =
      this?.props?.typeOfWork === "Inspection" ? "usage" : "inspections";
    const {
      origin,
      breakdownsInElevations,
      daysInElevations,
      serviceId,
      elevationIndex,
      serviceDefinitions,
      service,
    } = this.props;
    switch (
      origin //depending where it is open it will return breakdowns
    ) {
      case "pli":
        return {
          breakdownValue:
            this?.props?.node?.data?.[this?.props?.column?.colId] || [],
          days: this?.props?.data?.days,
        };
      case "elevation":
        return {
          breakdownValue:
            breakdownsInElevations?.[serviceId]?.[elevationIndex] || [],
          days: daysInElevations?.[serviceId]?.[elevationIndex],
        };
      default:
        throw new Error("Define breakdown component origin property");
    }
  };

  setInitialData = () => {
    //breakdownValue value is receiverd from props and set in state
    try {
      // const { serviceDefinitions, serviceId } = this.props
      let { breakdownValue } = this.get_Days_AND_breakdownValue();

      this.setState({ breakdownValue });
    } catch (e) {
      console.error(e);
    }
  };

  onCancel = () => {
    this.setInitialData(); //reset data from props
    updateDocumentTitle(); // reset document title to "Lead Manager";
    this.setState({ isModalVisible: false }); //close modal
  };

  onOk = () => {
    //callback to parent
    if (!this?.props?.isWritable && this?.props?.editMode) {
      message.error("Enable write mode to edit breakdowns!");
      return;
    }

    // if (calculateBreakdownsRate(this?.state?.breakdownValue) < 100) {
    //   message.error("Total rate cannot be less than 100!");
    //   return;
    // }

    const { optionIndex, elevationIndex, serviceId, origin } = this.props;
    if (origin === BREAKDOWN_RENDER_ORIGIN.PLI) {
      const haveWeSelectedDays = this?.state?.breakdownValue?.every?.(
        ({ progressByDay = [] }) => progressByDay?.length === 0
      );
      let pli = _.cloneDeep(this?.props?.data) || {};

      const breakdownsData = this?.state?.breakdownValue || [];
      breakdownsData?.forEach((breakdown) => {
        breakdown?.progressByDay?.forEach((progress) => {
          delete progress?.changed;
        });
      });

      pli[this.props.column.colId] = breakdownsData || [];

      const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);
      pli["totalProgress"] =
        onlyAppliedBreakdowns(pli?.breakdownValue || [])
          ?.map?.(({ progressByDay = [], rate = 0 }) =>
            breakInspProgress(this?.props?.typeOfWork, progressByDay, rate)
          )
          ?.reduce?.((a, b) => a + b, 0) *
        (d2 === undefined ? pli?.[d1] / 100 : (pli?.[d1] * pli?.[d2]) / 100);
      pli["typeOfProgress"] = haveWeSelectedDays ? "" : "breakdown";
      pli.days = [
        ...new Set(
          this?.state?.breakdownValue?.flatMap?.(({ days = [] }) => days)
        ),
      ];

      (pli.usedScheduleDays = daysScheduledInServices(
        {
          ...pli,
          days: preventDuplicateStrings([
            ...pli?.days,
            ...(pli?.isPliCompletedInThePreviousSchedule?.prevDays || []),
          ]),
        },
        this?.props?.scheduleDays,
        this?.props?.scheduleName,
        ""
      )),
        this.props.node.setDataValue("breakdownValue", breakdownsData);
      let items = [];
      this.props.api.forEachNode((n) => {
        if (n?.data?.id === pli?.id) {
          n.data = pli;
        }
        items.push(n?.data);
      });

      this.props.onBreakdownSetInPLI(
        items,
        serviceId,
        optionIndex,
        elevationIndex,
        breakdownsData || [],
        pli,
        { d1, d2 }
      );
      this.props.api.redrawRows();
      this.setState({
        breakdownValue: breakdownsData || [],
        specificBreakdown: {},
      });
    } else if (origin === BREAKDOWN_RENDER_ORIGIN.ELEVATION) {
      this.props.onSetBreakDownsInElevation(
        serviceId,
        elevationIndex,
        this?.state?.breakdownValue
      );
      this.setState({
        breakdownValue: this?.state?.breakdownValue,
        specificBreakdown: {},
      });
    }
    swalToast({
      icon: "success",
      position: "bottom-end",
      title: `Saved successfully!`,
      timer: 3500,
      showConfirmButton: false,
    });
    this.setInitialData();
    updateDocumentTitle(); // reset document title to "Lead Manager";
    this.setState({ isModalVisible: false }); //finally close modal
  };

  //when selecting days from dropdown
  selectDays = (name, days = []) => {
    let breakdownValue = _.cloneDeep(this?.state?.breakdownValue);
    const editingBreakdown = breakdownValue?.find((b) => b?.name === name);
    editingBreakdown.days = days; //set days
    editingBreakdown.progressByDay = [
      ...editingBreakdown?.progressByDay,
      ...days?.map((el) => ({
        day: el,
        breakdown_dimension: 0,
      })),
    ];
    // ?.filter((el) => days?.includes(el?.day));

    //add days in progressByDay
    this.setState({ breakdownValue });
  };

  onDayProgressChange = (x, e, dayId, dayIndex) => {
    let breakdownValue = _.cloneDeep(this?.state?.breakdownValue);
    let editingBreakdown = breakdownValue?.find((b) => b?.id === x?.id);

    let val = Number(e.target.value);
    if (isNaN(val)) {
      message.error("Progress must be a number!");
      return;
    }
    if (
      sumProgressByDay(
        x?.progressByDay?.map((el, i) => {
          if (el?.day === dayId?.day) {
            return {
              ...el,
              breakdown_dimension: Number(val),
            };
          }
          return el;
        })
      ) > 100
    ) {
      message.error("Progress cannot be more than 100%!");
      return;
    }

    editingBreakdown.progressByDay[dayIndex][breakdown_dimension] = val;
    this.setState({ breakdownValue });
  };

  render() {
    try {
      const {
        data,
        origin,
        scheduleDays,
        label,
        serviceId,
        serviceDefinitions,
      } = this.props;

      const { breakdownValue, isModalVisible } = this.state;
      const breakdownsWithDaysLength =
        selectedItems(breakdownValue)?.length || 0;
      const completedBreakdownsLength =
        completedItems(breakdownValue, {
          d1: breakdown_dimension,
        })?.length || 0;

      let title = label || "";

      const type =
        this?.props?.typeOfWork === "Inspection" ? "inspections" : "breakdowns";
      const numbers = [
        {
          number: completedBreakdownsLength,
          description: `Completed ${type}`,
        },
        {
          number: breakdownsWithDaysLength,
          description: `Selected ${type}`,
        },
        {
          number: breakdownValue?.length,
          description: `Total ${type} for this ${
            origin === "pli" ? "pli" : "elevation"
          }`,
        },
      ];

      const inspectionsType = {
        service: {
          label: "Service",
          value: true,
        },
        elevation: {
          label: "Elevation",
          value: origin === "elevation",
        },
        pli: {
          label: Number(serviceId) === 3 ? "Jump" : "Pli",
          value: true,
        },
      };

      const addBreakdown = () => {
        if (!this?.state?.data?.name) {
          message.error("Please fill all the fields!");
          return;
        } else {
          this.setState({
            breakdownValue: [
              ...breakdownValue,
              {
                id: new Date().getTime().toString(),
                ...this?.state?.data,
                [this?.props?.typeOfWork === "Inspection"
                  ? "usage"
                  : "inspections"]: inspectionsType,
                days: [],
                progressByDay: [],
                breakdown_dimension: 100,
                totalProgress: 0,
                isApplied: true,
              },
            ],
          });

          this.setState({
            data: {
              name: null,
              rate: null,
            },
          });
        }
      };

      const onDeleteBreakdown = (id) => {
        const breakDowns = [...breakdownValue];
        this.setState({
          breakdownValue: breakDowns?.filter?.((b) => b?.id !== id),
        });
        message.success("Breakdown deleted successfully!");
      };
      const allRate = calculateBreakdownsRate(breakdownValue);

      const onChangeInputs = (e, type) => {
        if (type === "name") {
          const findBreakdown = breakdownsServiceId(
            serviceDefinitions,
            "breakdowns",
            serviceId,
            "inspections",
            origin
          )?.find((a) => a?.name === e);
          if (!isNaN(findBreakdown?.name) || (!isNaN(e) && e !== "")) {
            message.error("Name shouldn't be a number!");
            return;
          }
          if (
            breakdownValue?.some((b) => b?.name === (findBreakdown?.name || e))
          ) {
            message.error(`${e} already exists!`);
            return;
          }

          this.setState({
            data: {
              rate:
                allRate + findBreakdown?.rate < 100
                  ? findBreakdown?.rate
                  : 100 - allRate || 0,
              name: findBreakdown?.name || e,
            },
          });
        } else {
          let val = Number(e.target.value);

          if (Number(val) > 100) {
            message.error("Rate cannot be more than 100%!");
            val = 0;
          } else if (isNaN(val)) {
            message.error("Rate must be a number!");
            return;
          } else if (Number(val) + allRate > 100) {
            message.warning(
              `The rate of all the breakdowns is ${allRate}%, so the maximum value you can insert is ${
                100 - allRate
              }%!`
            );
            val = 100 - allRate === 0 ? "" : 100 - allRate;
          }
          this.setState({
            data: {
              ...this?.state?.data,
              [type]: Number(val),
            },
          });
        }
      };

      const { d1, d2 } = get_ProgressDimensionsByServiceId(serviceId);

      const totalProgressPli =
        breakdownValue
          ?.map?.((breakdown) =>
            breakInspProgress(
              this?.props?.typeOfWork,
              breakdown?.progressByDay,
              breakdown?.rate
            )
          )
          ?.reduce?.((a, b) => a + b, 0) *
        (d2 === undefined
          ? this?.props?.node?.data?.[d1] / 100
          : this?.props?.node?.data?.["surface"] / 100);

      const dimensions =
        origin === "pli"
          ? d2 === undefined
            ? `${upperCaseFirstLetter(d1)}: ${
                Math.round(this?.props?.node?.data?.[d1] || 0) ??
                Math.round(data?.length || 0)
              }`
            : `${upperCaseFirstLetter(d1)}: ${
                Math.round(this?.props?.node?.data?.[d1] || 0) ??
                Math.round(data?.length || 0)
              } - ${upperCaseFirstLetter(d2)}: ${
                Math.round(this?.props?.node?.data?.[d2] || 0) ??
                Math.round(data?.height || 0)
              }`
          : "";

      const applieBreakdown = (checked, breakdown) => {
        let breakdownToChangeRate = breakdownValue?.filter(
          (b) => b?.id !== breakdown?.id && b?.isApplied
        );
        let rates = shareRates(breakdownToChangeRate, 100);
        let temp = structuredClone(breakdownValue);

        let tempIdx = 0;
        temp?.forEach?.((element, index) => {
          if (element?.id === breakdown?.id) {
            element.isApplied = checked;
            element.rate = 0;
          } else if (element?.id !== breakdown?.id && element?.isApplied) {
            element.rate = rates[tempIdx];
            tempIdx++;
          }
        });

        this.setState({
          breakdownValue: temp,
        });
      };

      const updateBreakdownsGrid = (newValue = "", e = {}, type = "") => {
        if (type === "name") {
          const breakdowns = [...breakdownValue];
          const checkDuplicateNames = breakdowns?.some(
            (b) =>
              b?.name?.toLowerCase()?.toString() ===
                newValue?.toLowerCase()?.toString() && b?.id !== e?.data?.id
          );

          if (checkDuplicateNames) {
            message.error(`${newValue} already exists!`);
            newValue = e.oldValue;
            e.data.name = newValue;
          }
          breakdowns[e.rowIndex][type] = newValue;
          this.setState({ breakdownValue: breakdowns });
        } else {
          let val = Number(newValue);
          if (isNaN(val)) {
            message.error("Rate must be a number!");
            val = e.oldValue;
            e.data.rate = val;
          }
          if (val > 100) {
            message.error("Rate cannot be more than 100%!");
            val = e.oldValue;
            e.data.rate = val;
          }
          const allTotalRate = Number(
            breakdownValue
              ?.filter((el) => !!el?.isApplied)
              ?.reduce?.(
                (acc, { rate = 0 }, i) =>
                  acc + (i === e?.rowIndex ? val : rate),
                0
              )
          );
          if (allTotalRate > 100) {
            message.error("Total rate cannot be more than 100%!");
            val = e.oldValue;
            e.data.rate = val;
          }
          const breakdowns = [...breakdownValue];
          breakdowns[e.rowIndex][type] = val;
          this.setState({ breakdownValue: breakdowns });
        }
      };

      const hasProgressFromForeman = breakdownValue?.some(
        (b) =>
          b?.progressByDay?.length > 0 &&
          b?.progressByDay?.some?.((b) => !!b?.foreman)
      );

      return (
        <span
          onClick={(e) => {
            e.stopPropagation();
          }}
        >
          {!this?.props?.node?.group ? (
            <div style={{ display: "flex", alignItems: "center", gap: 5 }}>
              <AddonButton
                {...{
                  numbers,
                  onClick: (e) => {
                    if (
                      prevPliCompleted(this?.props?.node?.data) &&
                      origin === "pli"
                    ) {
                      message.warning(
                        "This pli is completed in the previous schedule, you cannot set progress by breakdowns!"
                      );
                    } else if (
                      pliEditedFromNextSchedules(this?.props?.node?.data) &&
                      origin === "pli"
                    ) {
                      Swal.fire({
                        text: `This pli is edited in  ${this?.props?.node?.data?.editedFrom?.scheduleName}`,
                        icon: "warning",
                        showConfirmButton: false,
                        timer: 2500,
                      });
                    } else if (this?.props?.node?.data?.subPli?.length) {
                      message.warning(
                        "There are sub-pli(s) created, you cannot set progress by breakdowns!"
                      );
                    } else if (
                      this?.props?.node?.data?.typeOfProgress === "dimensions"
                    ) {
                      message.warning(
                        "Progress is set by dimensions, you cannot set progress by breakdowns!"
                      );
                    } else {
                      this.setState({ isModalVisible: true });
                    }
                  },
                }}
              />
              {hasProgressFromForeman && <CrewsIcon />}
            </div>
          ) : (
            ""
          )}
          {!!isModalVisible && (
            <Modal
              className={`breakdowns-modal ${
                (this?.props?.isDarkMode || this?.props?.context?.isDarkMode) &&
                "breakdowns-modal-dark"
              }`}
              title={
                "Breakdowns" +
                " - " +
                title +
                " " +
                (!!dimensions ? `- ${dimensions}` : "")
              }
              afterOpenChange={(event) => {
                event &&
                  updateDocumentTitle({ newTitle: title + " " + dimensions });
              }}
              centered
              open={isModalVisible}
              setVisible={this.onCancel}
              // closable={false}
              closeIcon={<XIcon />}
              width={1300}
              // height={319}
              onCancel={() => this.onCancel()}
              closable={false}
              maskClosable={false}
              destroyOnClose={true}
              footer={
                <div
                  style={{
                    display: "flex",
                    justifyContent: "space-between",
                  }}
                >
                  <MondayButton
                    onClick={() => this.onCancel()}
                    {...{ Icon: <XIcon />, className: "mondayButtonRed" }}
                  >
                    Cancel
                  </MondayButton>
                  <MondayButton
                    onClick={() => this.onOk()}
                    {...{ Icon: <Tick width={19} height={19} /> }}
                  >
                    Save Changes
                  </MondayButton>
                </div>
              }
            >
              <div>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "row",
                    alignItems: "center",
                    gap: 10,
                  }}
                >
                  <AutoComplete
                    placeholder="Add Breakdown..."
                    value={this?.state?.data?.name}
                    onChange={(e) => {
                      onChangeInputs(e, "name");
                    }}
                    variant="filled"
                    style={{
                      width: 200,
                      background:
                        this?.props?.isDarkMode ||
                        this?.props?.context?.isDarkMode
                          ? "#f5f5f7"
                          : "",
                      borderRadius: 5,
                    }}
                    options={breakdownsServiceId(
                      serviceDefinitions,
                      "breakdowns",
                      serviceId,
                      "inspections",
                      this?.props?.origin
                    )
                      ?.filter(
                        (el) =>
                          !breakdownValue?.some(
                            (element) => element?.name === el?.name
                          )
                      )
                      ?.map((s) => {
                        return { value: s?.name, label: s?.name };
                      })}
                  />

                  <Input
                    placeholder="Breakdown rate..."
                    value={this?.state?.data?.rate}
                    onChange={(e) => {
                      onChangeInputs(e, "rate");
                    }}
                    suffix={"%"}
                    style={{
                      width: 200,
                      backgroundColor: "#f5f5f7",
                      borderRadius: 5,
                      border: "none",
                    }}
                  />
                  <MondayButton onClick={addBreakdown} {...{ Icon: <Plus /> }}>
                    {`Add ${
                      this?.props?.typeOfWork === "Inspection"
                        ? "Inspection"
                        : "Breakdown"
                    }`}
                  </MondayButton>
                </div>
                {breakdownValue?.length > 0 && (
                  <div
                    className={`documentationsTable ${
                      this?.props?.isDarkMode ||
                      this?.props?.context?.isDarkMode
                        ? "dark-ag-theme ag-theme-alpine-dark"
                        : "light-ag-theme ag-theme-alpine"
                    }`}
                    style={{ width: "100%", height: "100%", marginTop: 22 }}
                  >
                    <AgGridReact
                      {...{
                        onCellValueChanged: (e) => {
                          const newValue = e?.newValue || "";
                          if (
                            e?.colDef?.field === "name" ||
                            e?.colDef?.field === "rate"
                          ) {
                            updateBreakdownsGrid(newValue, e, e?.colDef?.field);
                          }
                        },
                        columnDefs: columnDefsForBreakdowns({
                          applieBreakdown,
                          onDeleteBreakdown,
                          scheduleDays: this?.props?.scheduleDays || [],
                          daysForSelectedBreakdown: (breakdown = {}) => {
                            this.setState({
                              breakVisible: true,
                              specificBreakdown: breakdown,
                            });
                          },
                          isDarkMode: this?.props?.isDarkMode,
                        }),
                        rowData: breakdownValue,
                        animateRows: true,
                        suppressDragLeaveHidesColumns: true,
                        suppressRowClickSelection: true,
                        enableCellChangeFlash: true,
                        rowSelection: "multiple",
                        defaultColDef: {
                          resizable: true,
                          enablePivot: true,
                          enableColResize: true,
                          enableRowGroup: true,
                          editable: false,
                          sortable: true,
                          flex: 1,
                          minWidth: 100,
                          filter: true,
                        },
                      }}
                    />
                  </div>
                )}
              </div>
            </Modal>
          )}
          {!!this?.state?.breakVisible && (
            <>
              <BreakdownsSelectedDays
                {...{
                  breakVisible: this?.state?.breakVisible,
                  setBreakVisible: (val) =>
                    this.setState({ breakVisible: val }),
                  scheduleDays: this?.props?.scheduleDays?.sort(
                    (a, b) => a?.day - b?.day
                  ),
                  breakdownValue,
                  setBreakdownValue: (val) =>
                    this.setState({ breakdownValue: val }),
                  specificBreakdown: this?.state?.specificBreakdown,
                  onEmptySpecificBreakdown: () => {
                    this.setState({ specificBreakdown: {} });
                  },
                  context: this?.props?.context,
                  origin,
                  typeOfWork: this?.props?.typeOfWork,
                  totalProgressPli,
                  pli: this?.props?.node?.data,
                  d1,
                  d2,
                  onDayProgressChange: this.onDayProgressChange,
                  editMode: this?.props?.editMode,
                  isDarkMode: this?.props?.isDarkMode,
                  estimationId: this?.props?.estimationId,
                  service: this?.props?.service,
                  elevationLabel: this?.props?.elevationLabel,
                  elevationId: this?.props?.elevationId,
                  updateBreakdownLogs: this?.props?.updateBreakdownLogs,
                  ...this?.props,
                }}
              />
            </>
          )}
        </span>
      );
    } catch (e) {
      console.error(e);
    }
    // return "Something went wrong"
    return "";
  }
}

export const BREAKDOWN_RENDER_ORIGIN = { PLI: "pli", ELEVATION: "elevation" };

export const breakdown_dimension = "breakdown_dimension";

import { Collapse } from "antd";
import React, { useState } from "react";
import { currencyFormatter } from "../../../../../utils/currencyFormatter";
import RentalCalculator, { FooterAmountsRental } from "../RentalCalculator";
import { useRedux } from "../../../../../hooks";
import { CollapseIcon } from "../../../../../../../icons";
const { Panel } = Collapse;

function RentalServiceCollapse({
  service = {},
  updateStateAndSave,
  serviceIndex,
}) {
  const [gridData] = useRedux("takeOffTableData");
  const [visible, setVisible] = useState(false);
  console.log("visible", visible);
  return (
    <div className="addonsContainer" style={{ height: "100%" }}>
      <>
        <Collapse
          expandIcon={({ isActive }) => (
            <div
              className="dropdownIconContainer"
              style={{
                // color: "#fff",
                // fill: "#fff",
                // backgroundColor: isActive
                //   ? "#5b5b5b"
                //   : "#1264A3",
                // background: "red",
                // width: "32px",
                // height: "32px",
                display: "flex",
                height: "100%",
                justifyContent: "center",
                alignItems: "center",
                // borderRadius: "50%",
              }}
            >
              {!isActive ? (
                <CollapseIcon
                  style={{
                    transform: "rotate(-90deg)",
                  }}
                />
              ) : (
                <CollapseIcon />
              )}
            </div>
          )}
        >
          <Panel
            header={
              <div className="addonsHeader elevation-panel-header ag-theme-alpine-dark">
                Additional Rentals - (PRICE{" "}
                {`${currencyFormatter(
                  service?.additionalRentalTerms?.newPrice ?? 0
                )}`}
                )
              </div>
            }
          >
            <br />{" "}
            <RentalCalculator
              {...{
                service,
                updateStateAndSave,
                serviceIndex,
                gridData,
                noIcon: true,
                collapsedTrigger: true,
              }}
            />
            <br />
            <FooterAmountsRental
              {...{
                inputVals: service?.additionalRentalTerms,
                totalities: service?.additionalRentalTerms,
                onEditRental: () => setVisible(true),
              }}
            />{" "}
            <br />
          </Panel>
        </Collapse>{" "}
      </>{" "}
    </div>
  );
}

export default RentalServiceCollapse;

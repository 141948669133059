import React, { useState, useEffect } from "react";
import "./ResponseEvaluator.scss";

const ResponseEvaluator = ({ messageId, feedback, onClick }) => {
  const [showFeedback, setShowFeedback] = useState(false);

  const handleFeedback = (value) => {
    onClick(messageId, value);
    setShowFeedback(true);
  };

  useEffect(() => {
    if (showFeedback) {
      const timer = setTimeout(() => {
        setShowFeedback(false);
      }, 5000);

      return () => clearTimeout(timer);
    }
  }, [showFeedback]);

  if (feedback && showFeedback)
    return (
      <div className="responseFeedbackMessage">
        Thank you for your feedback!
      </div>
    );

  if (feedback && !showFeedback) return null;

  return (
    <div className="responseEvaluatorContainer">
      <span className="responseMessage">Was this answer helpful?</span>
      <div className="responseEvaluatorButtonContainer">
        <button
          onClick={() => handleFeedback("Yes")}
          className="button buttonYes"
        >
          Yes
        </button>
        <button
          onClick={() => handleFeedback("No")}
          className="button buttonNo"
        >
          No
        </button>
      </div>
    </div>
  );
};

export default ResponseEvaluator;

import { message } from "antd";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { filterTables } from "../../../../../../../utils";
import { forceToNumber } from "../NewPayment/utils/checkers";

export const updateBalance = async ({
  accountId = "",
  projectId = "",
  showMessage = false,
}) => {
  let secondsToDelay = showMessage ? 0 : 3;
  setTimeout(async () => {
    await fixBalance({
      accountId,
      projectId,
      showMessage,
    });
    // await API.post("openBalance", "/openBalance", {
    //   body: {
    //     projectId: projectId,
    //     accountId: accountId,
    //   },
    // })
    //   .then((res) => {
    //     if (!showMessage) {
    //       return;
    //     }
    //     if (res?.changes?.length > 0) {
    //       Swal.fire({
    //         title: "Balance Fixed",
    //         html: `The balance of the invoices has been fixed. <br> <br>`,
    //         icon: "success",
    //         showCancelButton: false,
    //         confirmButtonColor: "#3085d6",
    //         confirmButtonText: "Ok",
    //       }).then((result) => {
    //         if (result.isConfirmed) {
    //           window.location.reload();
    //         }
    //       });
    //     } else {
    //       message.success("No changes needed");
    //     }
    //   })
    //   .catch((err) => {
    //     console.error("error balance message:", err);
    //   });
  }, secondsToDelay);
};
const fixBalance = async (projectData) => {
  let { projectId = "", accountId = "", showMessage } = projectData;
  // message.success(id);
  let invoices = []; // invoices
  let charges = []; //credits type
  let payments = [];
  let changes = [];
  if (showMessage) {
    message.loading("Checking balance...", 0);
  }
  await filterTables("invoices", "projectId", projectId).then((data) => {
    invoices = data;
  });
  await filterTables("charges", "projectId", projectId).then((data) => {
    charges = data?.filter((charge) => charge?.chargeType === "Credit Memo");
  });
  await filterTables("payments", "accountId", accountId).then((data) => {
    payments = data?.filter((payment) =>
      payment.receivedFromProjects?.find(
        (project) => project.projectId === projectId
      )
    );
  });

  for (const invoice of invoices) {
    let currentAmount = invoice.amountDue;
    let newAmount = 0;
    let invoiceAmount = forceToNumber(invoice.totalInvoiceAmount);
    let accumulatedPayments = payments.reduce((acc, payment) => {
      let invAm = payment?.invoices?.find(
        (inv) => inv.invoiceNumber === invoice.invoiceNumber
      )?.invoicePaidAmount;
      // test.push(payment);
      return acc + forceToNumber(invAm);
    }, 0);
    console.log("accumulatedPayments", accumulatedPayments);
    let accumulatedCharges = charges.reduce((acc, charge) => {
      let temp = charge?.chargeItems
        ?.filter((item) => item.invoiceNumber === invoice.invoiceNumber)
        ?.map((item) => item.creditAmount);
      temp = temp.reduce((acc, item) => acc + forceToNumber(item), 0);
      return acc + forceToNumber(temp);
    }, 0);

    newAmount =
      invoiceAmount -
      accumulatedPayments?.toFixed(2) -
      accumulatedCharges?.toFixed(2);
    console.log("newAmount", {
      newAmount,
      invoiceAmount,
      accumulatedPayments,
      accumulatedCharges,
      no: invoice?.invoiceNumber,
    });
    if (newAmount !== currentAmount) {
      changes.push({
        invoiceNumber: invoice.invoiceNumber,
        amountDue: currentAmount,
        totalInvoiceAmount: invoiceAmount,
        newAmount: newAmount,
        qbNumber: invoice?.quickBooksNumber,
      });
      await API.put("invoices", `/invoices/${invoice?.invoiceId}`, {
        body: {
          amountDue: newAmount,
          invoiceStatus: newAmount === 0 ? "Paid" : "Paid",
        },
      });
    }
    if (newAmount === invoiceAmount) {
      await API.put("invoices", `/invoices/${invoice?.invoiceId}`, {
        body: {
          invoiceStatus: "Created",
          amountDue: newAmount,
        },
      });
    }
    invoice.newAmount = newAmount;
  }

  if (changes.length > 0) {
    if (showMessage) {
      Swal.fire({
        title: "Balance Fixed",
        html: `The balance of the invoices has been fixed. <br> <br>`,
        icon: "success",
        showCancelButton: false,
        confirmButtonColor: "#3085d6",
        confirmButtonText: "Ok",
      }).then((result) => {
        if (result.isConfirmed) {
          window.location.reload();
        }
      });
    }
  } else {
    // message.success("No changes needed");
    message.destroy();
    message.success("No changes needed");
  }
};

import {
  getScheduleOfValue,
  updateScheduleOfValue,
} from "../../../../../../../../../../actions/scheduleOfValues";
import { Pdf, SaveIcon, WordIcon } from "../../../../../../../../BasePage/src";
import {
  useDispatchContext,
  useStateContext,
} from "../../../../Context/Context";
import { MondayButton } from "../../../../../../../../../commonComponents";
import {
  customBalancePriceOfSOVHandler,
  retractChangerHandler,
  totalPriceOfSOVHandler,
} from "./controlPanelFunctions";
import ExportToExcel from "./Components/ExportToEcxel/ExportToExcel";
import StatusChanger from "./Components/StatusChanger/StatusChanger";
import {
  EditIcon,
  NotesIcon,
  UpIcon,
} from "../../../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../../../utils";
import RefreshGrid from "./Components/RefreshGrid/RefreshGrid";
import { Tasks } from "../../Components/Tasks/Tasks";
import "./ControlPanel.scss";

import { useDispatch, useSelector } from "react-redux";
import { useParams } from "react-router-dom";
import { Switch, Tooltip } from "antd";
import { TickIcon } from "../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { useState, useMemo, useEffect } from "react";
import { API } from "aws-amplify";
import EstimationPicker from "../../../../../ScheduleOfValueTab/NewScheduleOfValue/Components/EstimationPicker/EstimationPicker";
import AddEstimationModal from "../../../../../ScheduleOfValueTab/ListOfScheduleOfValues/GridList/Components/AddEstimationModal/AddEstimationModal";
import {
  fetchAllData,
  filterTables,
} from "../../../../../../../../../../utils";
import { EditableModeWrapper } from "../../../EditableModeWrapper";
import { message } from "antd";
import { filterTablesPagination } from "../../../../../../../../../../utils/paginationFetchData";
import { UndoRedoButtons } from "../../../../../../../../../commonComponents/UndoRedoButtons/UndoRedoButtons";
import { EditLogs } from "../../../../../../../../../commonComponents/EditLogs/EditLogs";
import { getChangedData } from "../../../../../../../../Accounting/components/utilities";
import dayjs from "dayjs";
import { Notes } from "../../../../../../../../../commonComponents/Notes/Notes";
import { CheckAccessRights } from "../../../../../../../../../../utils/CheckAccessRights";
import PreviewEngTakeOff from "../../../../../../../../Estimations/DataEntryGrid/subcomponents/previewTakeOff/PreviewEngTakeOff";
import { checkIfServiceIsHoist } from "../../../../../../../../Estimations/DataEntryGrid/models/Service";
import Swal from "sweetalert2";
import { TaskIcon } from "src/icons/NotificationIcons";
import ToDoModal from "src/components/SidebarPages/ToDos/ToDoModal";
import ToDoButton from "../../../../../../../../ToDos/components/ToDoButton/ToDoButton";
import { sovToChargeObj } from "../../../../../../Charges/components/NewChargeItem/utils/sovToCharge";
import DriveNavigate from "../../../../../../Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/DriveNavigate/DriveNavigate";
import { useEditLogs } from "../../../../../../../../../../hooks";

const ControlPanel = ({
  undoClickHandler,
  redoClickHandler,
  canUndo,
  canRedo,
}) => {
  const { isLoading, scheduleOfValue } = useSelector(
    (state) => state?.scheduleOfValues
  );
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const { saveAddedLogs } = useEditLogs();

  const { scheduleId } = useParams();
  const [visibleSov, setVisibleSov] = useState(false);
  const [editSov, setEditSov] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  // Context state & dispatch
  const { isWritable, isAutoSaving, fakeSOV, errors, darkMode, retracted } =
    useStateContext();
  const dispatchContext = useDispatchContext();
  const dispatch = useDispatch();
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const totalPriceOfSOV = totalPriceOfSOVHandler({ fakeSOV });
  const customBalancePriceOfSOV = customBalancePriceOfSOVHandler({ fakeSOV });

  const [estimations, setEstimations] = useState([]);
  const retractChanger = () =>
    retractChangerHandler({ dispatchContext, retracted });
  const getEstimations = async (projectId) =>
    await fetchAllData({
      endpoint: "estimations",
      resultPosition: "estimations",
      resultId: "estimationId",
      otherStringParams: {
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "projectId",
                value: projectId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    });
  const { userConfiguration } = useSelector((state) => state.userConfig);
  useEffect(() => {
    (async () => {
      if (!!fakeSOV) {
        setEstimations(
          await getEstimations(fakeSOV.projectId).then((res) => res)
        );
      }
    })();
  }, [fakeSOV]);

  useEffect(() => {
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: scheduleId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((r) => setEditLogs(r))
      .catch((err) => console.log("Error Fetching Logs: ", err));
  }, []);

  const onAdd = async () => {
    dispatch(getScheduleOfValue(scheduleId));
  };

  const openSov = () => {
    setVisibleSov(true);
    setEditSov(true);
  };

  const closeModal = () => {
    setVisibleSov(false);
  };

  const updateScheduleOfValueHandler = async (e) => {
    const balance = customBalancePriceOfSOVHandler({ fakeSOV });
    if (balance?.balanceAmount !== 0) {
      Swal.fire({
        title: "IMMEDITE ATTENTION!",
        text: "You have stll balance left! Please check your SOV!",
        icon: "warning",
      });
    }
    const sovEvents = serviceDefinitions?.map((item) => ({
      serviceId: item?.serviceId,
      serviceName: item?.serviceName,
      scheduleOfValues: item?.scheduleOfValues,
      listOfEvents: item?.listOfEvents,
    }));
    const whatIHaveToPost = {};
    console.log("sovEvents", sovEvents);
    e.preventDefault();

    if (Array.isArray(sovEvents) && Array.isArray(fakeSOV?.services)) {
      fakeSOV?.services?.forEach((item) => {
        if (!checkIfServiceIsHoist(item)) {
          if (Array.isArray(item?.amounts)) {
            item?.amounts?.forEach((amount) => {
              let tempList =
                sovEvents?.find(
                  (el) =>
                    el?.serviceId?.toString() === item?.serviceId?.toString()
                )?.listOfEvents || [];
              if (Array.isArray(tempList) && tempList.length > 0) {
                if (!tempList.includes(amount?.name)) {
                  if (!Array.isArray(whatIHaveToPost[item?.serviceId])) {
                    whatIHaveToPost[item?.serviceId] = [amount?.name];
                  } else {
                    whatIHaveToPost[item?.serviceId].push(amount?.name);
                  }
                }
              }
            });
          }
        } else {
          let sOptions = item?.serviceOptions;
          if (Array.isArray(sOptions) && Array.isArray(sOptions?.[0])) {
            sOptions?.[0]?.forEach((option) => {
              let tempAmounts = option?.amounts ?? [];
              tempAmounts.forEach((amount) => {
                let tempList =
                  sovEvents?.find(
                    (el) =>
                      el?.serviceId?.toString() === item?.serviceId?.toString()
                  )?.listOfEvents || [];
                if (Array.isArray(tempList) && tempList.length > 0) {
                  if (!tempList.includes(amount?.name)) {
                    if (!Array.isArray(whatIHaveToPost[item?.serviceId])) {
                      whatIHaveToPost[item?.serviceId] = [amount?.name];
                    } else {
                      whatIHaveToPost[item?.serviceId].push(amount?.name);
                    }
                  }
                }
              });
            });
          }
        }
      });
    }
    console.log("what i have to post before Updating", {
      whatIHaveToPost,
      fakeSOV,
    });

    fakeSOV.services = cleanUpUnnecessaryData(fakeSOV.services);
    console.log("what i have to post after updating", {
      whatIHaveToPost,
      fakeSOV,
    });
    // create an object `updatedElementServices` with updated values for some properties
    const updatedElementServices = {
      sovStatus: fakeSOV.sovStatus,
      services: fakeSOV.services,
    };

    // get the data that has changed between `updatedElementServices` and a target object
    const changedData = getChangedData(updatedElementServices, {
      sovStatus: scheduleOfValue.sovStatus,
      services: scheduleOfValue.services,
      // totalPrice: scheduleOfValue?.totalPrice,
    });

    // create a new object `newEditLog` with information about the changes
    const newEditLog = {
      recordId: scheduleId,
      recordName: `SOV ${scheduleOfValue?.SOVNo}`,
      actionType: "Edit",
      topic: scheduleOfValue?.projectName,
      currentData: changedData.curr,
      previousData: changedData.prev,
      updatedKeys: Object.keys(changedData),
    };

    let tmp = editLogs?.concat(newEditLog);
    setEditLogs(tmp);

    saveAddedLogs(newEditLog);

    message.loading({ content: "Saving...", key: "saving" });
    let promises = [];

    // Object.entries(whatIHaveToPost)

    for (const [key, value] of Object.entries(whatIHaveToPost)) {
      if (value.length > 0) {
        let tempRequest = sovEvents?.find(
          (el) => el?.serviceId?.toString() === key?.toString()
        );
        let newEvents = tempRequest?.listOfEvents?.concat(value);
        // let promises = await
        promises.push(await updateNewEvents(key, newEvents));
      }
    }

    if (Array.isArray(promises) && promises.length > 0) {
      await Promise.all(promises).then((res) => {
        Swal.fire({
          title: "Success! You added NEW SOV Events",
        });
      });
    }
    // dispatch an action to update the schedule of value with the updated data
    dispatch(updateScheduleOfValue(scheduleId, fakeSOV));
  };

  return (
    <div
      className={
        darkMode
          ? "sov-header-control-panel-dark"
          : "sov-header-control-panel-light"
      }
    >
      <div className="sov-header-options">
        <div className="leftSideContainer">
          <Tooltip
            title={retracted ? "Show service menu" : "Hide service menu"}
          >
            <button
              className={
                retracted
                  ? "serviceRetractButtonRetracted"
                  : "serviceRetractButton"
              }
              onClick={retractChanger}
            >
              <UpIcon />
            </button>
          </Tooltip>
          {/* <button onClick={retractChanger}>{"<<"}</button>
          <EditableModeWrapper {...{ isWritable }}>
            <StatusChanger />
          </EditableModeWrapper>{" "} */}
          <div
            style={{
              marginLeft: "-10px",
              marginLeft: "-10px",
            }}
          >
            <UndoRedoButtons
              canUndo={canUndo}
              canRedo={canRedo}
              undoClickHandler={undoClickHandler}
              redoClickHandler={redoClickHandler}
            />
          </div>
          <div className="total-price-details-div-sov">
            {totalPriceOfSOV > 0 && (
              <div className="sov-header-options-price-container">
                <div
                  className="sov-header-options-price-label"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  Total price for SOV:
                </div>
                <div
                  className="sov-header-options-price"
                  style={{
                    fontWeight: "600",
                  }}
                >
                  {formatCurrency(totalPriceOfSOV, "USD")}
                </div>
              </div>
            )}

            <div className="sov-header-options-price-container">
              <br />
              <div
                className="sov-header-options-price-label"
                style={{
                  paddingLeft: "20px",
                  fontWeight: "600",
                  // color: "red",
                }}
              >
                BALANCE LEFT:
              </div>
              <div
                className="sov-header-options-price"
                style={{
                  color: "#FE4C4A",
                  fontWeight: "600",
                }}
              >
                {formatCurrency(customBalancePriceOfSOV?.balanceAmount, "USD")}
              </div>
            </div>
            <div className="sov-header-options-price-container">
              <br />
              <div
                className="sov-header-options-price-label"
                style={{
                  paddingLeft: "20px",
                  fontWeight: "600",
                  // color: "red",
                }}
              >
                LEGACY PRICE:
              </div>
              <div
                className="sov-header-options-price"
                style={{
                  fontWeight: "600",
                }}
              >
                {formatCurrency(customBalancePriceOfSOV?.legacyPrice, "USD")}
              </div>
            </div>
            <div
              style={{
                paddingLeft: "20px",
              }}
            >
              {/* <MondayButton onClick={openSov} {...{ Icon: <EditIcon /> }}>
								Edit
							</MondayButton> */}
              <AddEstimationModal
                {...{
                  currentSOVData: fakeSOV,
                  estimations,
                  onAdd,
                  disabled: !isWritable,
                  darkMode,
                }}
              />
              {/* <NewScheduleOfValue visible={visibleSov} closeModal={closeModal} editSov={editSov} /> */}
            </div>
          </div>
        </div>

        <div className="rightSideContainer">
          <div className="buttonContainer">
            <div className="autoSaveSwitchContainer">
              {/* <PreviewEngTakeOff
                {...{
                  gridData: estimations[0]?.services || [],
                  projectAddress: fakeSOV?.projectAddress,
                  serviceDefs: fakeSOV?.serviceDefs,
                  accountName: "PREVIEW",
                }}
              /> */}
              <EditableModeWrapper {...{ isWritable }}>
                <Switch
                  checked={isAutoSaving}
                  onChange={(checked) =>
                    dispatchContext({
                      type: "SET_AUTOSAVING",
                      payload: checked,
                    })
                  }
                  checkedChildren={
                    <div style={{ width: 90 }}>Auto Save On</div>
                  }
                  unCheckedChildren={
                    <div style={{ width: 90 }}>Auto Save Off</div>
                  }
                  disabled={!isWritable || errors}
                />
              </EditableModeWrapper>
            </div>
            <div className="exportButtonContainer">
              <ToDoButton
                {...{
                  iconOnly: true,
                  recordId: fakeSOV?.scheduleId,
                  recordName: `${fakeSOV?.projectName} | SOV ${fakeSOV?.SOVNo}`,
                }}
              />
              {CheckAccessRights(
                userConfiguration?.routeConfig?.find(
                  ({ title }) => title === "Notes/View"
                )?.children,
                "Schedule Of Values"
              ) && (
                <Notes
                  noteModalName={`${fakeSOV?.projectName} | SOV ${fakeSOV?.SOVNo}`}
                  rowId={fakeSOV?.scheduleId}
                  topicCategory="Schedule Of Values"
                  customIcon={<NotesIcon />}
                />
              )}
              {/* <Notes {...{ darkMode }} /> */}
              <Tasks {...{ darkMode }} />
              <div style={{ paddingRight: "12px" }}>
                <EditLogs
                  editLogs={editLogs}
                  createdBy={scheduleOfValue?.createdBy}
                  createdAt={scheduleOfValue?.updatedAt}
                />
              </div>
              <div style={{ paddingLeft: "2px" }}>
                <ExportToExcel />
              </div>
              {/* <DriveNavigate folderId="1cEL2CPQW0yYI1JewfAnum0c6eGBy6Cnq" /> */}
              {/* <Tooltip title="Export to Pdf">
                <button className="pptButton">
                  <Pdf />
                </button>
              </Tooltip>
              <Tooltip title="Export to Word">
                <button className="wordButton">
                  <WordIcon />
                </button>
              </Tooltip> */}
              <RefreshGrid />
            </div>

            <div className="saveButtonContainer">
              <Tooltip
                title={
                  isWritable
                    ? "Save your work"
                    : "Enable WRITE mode to save work"
                }
              >
                <MondayButton
                  className="quickSaveButton"
                  onClick={(e) => updateScheduleOfValueHandler(e)}
                  Icon={<SaveIcon />}
                  disabled={!isWritable || errors || isLoading}
                >
                  Quick Save
                </MondayButton>
              </Tooltip>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ControlPanel;

const updateNewEvents = async (serviceId, events) => {
  return new Promise(async (resolve, reject) => {
    await API.put("serviceDefinitions", `/serviceDefinitions/${serviceId}`, {
      body: {
        listOfEvents: events,
      },
    });
    resolve();
  });
};

const cleanUpUnnecessaryData = (servicesArr = []) => {
  if (Array.isArray(servicesArr) && servicesArr.length > 0) {
    console.log("servicesArr", servicesArr);
    return servicesArr.map((service) => {
      let temp = service;
      if (!!temp) {
        delete temp.additionalRentalTerms;
        delete temp.PLIAddonScheme;
        delete temp.alternateRent;
        delete temp.isTaxable;
        delete temp.priceScheme;
        delete temp.rent;
        // delete temp.rentalPaymentTerms; I actually need this
        delete temp.taxRate;
        delete temp.totalities;
        // delete temp.serviceAddons; // I need this too now
        delete temp.excludes;
        delete temp.includes;
        delete temp.priceSheetDataSrc;
        delete temp.pricePerUnit;
        delete temp.documentationAddons;
      }
      return temp;
    });
  } else return [];
};

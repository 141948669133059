import { useContext } from "react";
import "./VideosContainer.scss";
import VideoTutorialContext from "../../contexts/VideoTutorialContext";
import VideoCard from "../VideoCard/VideoCard";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";

const VideosContainer = ({ setDisabled, subCatId }) => {
  const {
    primaryArray,
    setPrimaryArray,
    activePrimaryCat,
    subCatVideos,
    setSubCategoryName,
  } = useContext(VideoTutorialContext);

  setSubCategoryName(
    primaryArray
      ?.find((category) => category?.id === activePrimaryCat)
      ?.subCategories?.find(
        (subCategory) => subCategory?.videos[0]?.id === subCatVideos[0]?.id
      )?.subCategoryName
  );

  const dragEndHandler = (result) => {
    if (!result.destination) return;
    const updatedPrimaryArr = primaryArray.map((category) => {
      if (category?.id === activePrimaryCat) {
        const updatedSubCategories = category?.subCategories?.map((subCat) => {
          if (subCat?.id === subCatId) {
            // Update the videos array based on the drag and drop result
            const items = Array.from(subCat?.videos || []);
            const [reorderedItem] = items.splice(result.source.index, 1);
            items.splice(result.destination.index, 0, reorderedItem);

            return { ...subCat, videos: items };
          } else {
            return subCat;
          }
        });

        return { ...category, subCategories: updatedSubCategories };
      } else {
        return category;
      }
    });

    setPrimaryArray(updatedPrimaryArr);
    setDisabled(false);
  };

  return (
    <DragDropContext onDragEnd={dragEndHandler}>
      <Droppable droppableId="droppable">
        {(provided) => (
          <div
            className="videosContainer-card"
            {...provided?.droppableProps}
            ref={provided?.innerRef}
          >
            <div className="videos">
              {primaryArray
                .find((elem) => elem.id === activePrimaryCat)
                .subCategories.find((item) => item.id === subCatId)
                .videos.map((video, i) => (
                  <Draggable draggableId={`draggable-${i}`} index={i} key={i}>
                    {(provided) => (
                      <div
                        {...provided?.draggableProps}
                        {...provided?.dragHandleProps}
                        ref={provided?.innerRef}
                      >
                        <VideoCard key={video?.id} data={video} />
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided?.placeholder}
            </div>
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

export default VideosContainer;

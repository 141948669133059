import { message } from "antd";
import { v4 as uuid } from "uuid";
import { useSelector } from "react-redux";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import { entriesApiCrud } from "../../utils";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "src/components/commonComponents";
import {
  StoreType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import {
  EntryType,
  ScheduleType,
  EmployeeReportType,
} from "../../payrollLiveTypes";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./EmployeeSidebarItem.scss";

const statusColors = {
  In: "#00a464",
  Out: "#787677",
  ["No Punch"]: "#f04f4e",
  ["Don't Match"]: "#e9c466",
};

const reasonOptions = [
  { key: 3, value: "Sick", label: "Sick" },
  { key: 4, value: "Holiday", label: "Holiday" },
  { key: 7, value: "Regular", label: "Regular" },
  { key: 2, value: "Vacation", label: "Vacation" },
  { key: 6, value: "Personal", label: "Personal" },
  { key: 8, value: "Overtime", label: "Overtime" },
  { key: 5, value: "Bereavement", label: "Bereavement" },
  { key: 1, value: "Paid Time Of", label: "Paid Time Of" },
  { key: 0, value: "Unpaid Time Off", label: "Unpaid Time Off" },
  { key: 9, value: "NYC Covid Leave", label: "NYC Covid Leave" },
];

function EmployeeSidebarItem(
  props: EmployeeReportType & {
    isForeman?: boolean;
    selectedEmployees: Array<string>;
    setSelectedEmployees: Dispatch<SetStateAction<Array<string>>>;
  }
) {
  const {
    color,
    company,
    punchTime,
    isForeman,
    liveStatus,
    employeeId,
    employeeName,
    crewTeamName,
    employeeNumber,
    selectedEmployees,
    setSelectedEmployees,
  } = props;
  const {
    mapRef,
    jobsites,
    schedules,
    degEntries,
    accessRight,
    setDegEntries,
    employeesReport,
    selectedWeekDeg,
    setEmployeesInfo,
    setEmployeesReport,
  } = useContext(PayrollLiveContext);

  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const [approveDisabled, setApproveDisabled] = useState<boolean>(false);

  const matchedJobsite: JobsiteType | ScheduleType = useMemo(() => {
    if (props?.projectId) {
      let index = schedules.findIndex(
        (el) => el.projectId === props?.projectId
      );
      return schedules?.[index];
    } else if (props?.jobsiteId) {
      let index = jobsites.findIndex((el) => el.jobsiteId === props?.jobsiteId);
      return jobsites?.[index];
    }
  }, [jobsites, schedules, props?.jobsiteId, props?.projectId]);

  function onEmployeeFilter() {
    if (!props?.punchCoordinates?.lat) {
      message.error({
        key: "coordinatesError",
        content: "This employee does not have coordinates.",
        duration: 2,
      });
      return;
    }

    let filteredEmployees = selectedEmployees;

    if (filteredEmployees.includes(employeeNumber)) {
      filteredEmployees = filteredEmployees.filter(
        (emp) => emp !== employeeNumber
      );
      setEmployeesInfo(undefined);
    } else {
      filteredEmployees = [employeeNumber];
      setEmployeesInfo(employeeNumber);
    }

    let employeesCoordinates = employeesReport.flatMap((emp) =>
      emp.punchCoordinates?.lat &&
      filteredEmployees.includes(emp.employeeNumber)
        ? emp.punchCoordinates
        : []
    );

    if (mapRef.current && filteredEmployees.includes(employeeNumber)) {
      if (matchedJobsite?.addressPosition?.lat) {
        employeesCoordinates = employeesCoordinates.concat(
          matchedJobsite?.addressPosition
        );
      }
      mapRef.current.fitBoundsToMarkers(employeesCoordinates);
    } else {
      mapRef.current.fitBoundsToMarkers([]);
    }
    setSelectedEmployees(filteredEmployees);
  }

  function onJobSelect(e: string) {
    message.loading({
      key: "onJobUpdate",
      content: "Changing jobsite...",
      duration: 0,
    });
    const jobIndex = jobsites.findIndex((el) => el?.jobsiteId === e);
    const job = jobsites?.[jobIndex];
    const geoFence = job?.geofenceInfo?.length
      ? [{ geoFenceInfo: job?.geofenceInfo }]
      : job?.geoFenceInfo;
    let isMatch;
    
    if (geoFence?.length) {
      isMatch = findDistanceFromGeofenceSide({
        point: connectedEntry?.punchCoordinates,
        geofence: geoFence?.[0]?.geoFenceInfo,
        tolerance: +job?.locationRadius || 300,
      });
    } else {
      isMatch = withinRadius(
        job?.addressPosition,
        connectedEntry?.punchCoordinates,
        Number(job?.locationRadius) || 300
      );
    }

    const jobUpdateBody = {
      projectId: job?.projectId,
      jobsiteId: job?.jobsiteId,
      jobAddress: job?.jobAddress,
      activityStatus: (isMatch?.distanceInFeet >=
      Number(job?.locationRadius || 300)
        ? "Pending"
        : "Completed") as "Completed" | "Draft" | "Pending",
      jobsiteMatch: {
        services: job?.services || [],
        jobName: job?.jobName,
        jobsiteId: job?.jobsiteId,
        jobAddress: job?.jobAddress,
        reimbursement: job.reimbursement,
      },
    };
    // unstable_batchedUpdates(() => {
    entriesApiCrud({
      method: "put",
      body: jobUpdateBody,
      id: connectedEntry?.entryId,
    })
      .then(() => {
        setDegEntries((prev) =>
          prev.map((el) => {
            if (connectedEntry?.entryId === el?.entryId) {
              return {
                ...el,
                ...jobUpdateBody,
              };
            } else {
              return el;
            }
          })
        );
        message.success({
          key: "onJobUpdate",
          content: "Job changed successfully!",
          duration: 3,
        });
      })
      .catch((err) => {
        console.log("Error updating jobsite:: ", err);
        message.error({
          key: "onJobUpdate",
          content: "There was a problem updating jobsite",
          duration: 3,
        });
      });
    // setEmployeesReport((prev) => {
    //   // const index = prev.findIndex((el) => el.punchId === props.punchId);
    //   // if (index > -1) {
    //   //   prev.splice(index, 1, { ...prev[index], projectId: e });
    //   // }
    //   // return prev;
    //   return prev.map((el) =>
    //     el.punchId === props.punchId ? { ...el, projectId: e } : el
    //   );
    // });
    // });
  }

  function onReasonSelect(reason: string) {
    setEmployeesReport((prev) => {
      const index = prev.findIndex((el) => el?.punchId === props?.punchId);
      if (index > -1) {
        prev.splice(index, 1, { ...prev[index], reason });
      }
      return prev;
    });

    if (connectedEntry?.punchType === "HR") {
      setDegEntries((prev) => {
        const index = prev.findIndex(
          (el) => el.entryId === connectedEntry?.entryId
        );
        if (index > -1) {
          prev.splice(index, 1, { ...prev[index], reason });
        }
        return prev;
      });
    } else {
      const newEntry: EntryType = {
        reason,
        sow: [],
        totalOvh: 0,
        duration: 0,
        uploadId: "",
        jobsiteId: "",
        uploadName: "",
        entryId: uuid(),
        employeeRate: 0,
        distanceFromJob: 0,
        serviceOptions: [],
        salaryType: "Hourly",
        punchTime: undefined,
        punchType: "No Punch",
        payrollType: "Regular",
        activityStatus: "Draft",
        punchTimeStamp: undefined,
        crewId: props.crewId || "",
        companyName: props?.company,
        employeeId: props?.employeeId,
        company: props?.company || "",
        degId: selectedWeekDeg?.degId,
        createdAt: parseInTz().valueOf(),
        employeeRole: props?.crewPosition,
        punchDate: parseInTz().startOf("d"),
        employeeFullName: props?.employeeName,
        crewTeamName: props.crewTeamName || "",
        punchCoordinates: props?.punchCoordinates,
        punchLocation: props?.punchLocation || "",
        jobsiteMatch: {
          jobName: "",
          services: [],
          jobsiteId: "",
          jobAddress: "",
          reimbursement: false,
        },
      };
      setDegEntries((prev) => {
        prev.push(newEntry);
        return prev;
      });
    }
  }

  async function onApprovePunch() {
    setApproveDisabled(true);
    message.loading({
      key: "onApprovePunch",
      content: "Approving...",
      duration: 0,
    });
    try {
      await entriesApiCrud({
        method: "put",
        body: { activityStatus: "Completed" },
        id: connectedEntry?.entryId,
      });
      setDegEntries((prev) =>
        prev.map((el) =>
          el?.entryId === connectedEntry?.entryId
            ? { ...el, activityStatus: "Completed" }
            : el
        )
      );
      message.success({
        key: "onApprovePunch",
        content: "Punch Approved",
        duration: 2,
      });
    } catch (error) {
      console.log("Error updating entry: ", error);
      message.error({
        key: "onApprovePunch",
        content: "Error approving this punch",
        duration: 2,
      });
    } finally {
      setApproveDisabled(false);
    }
  }

  const connectedEntry = useMemo(() => {
    if (punchTime && employeeId) {
      const index = degEntries.findIndex((el) =>
        el.punchType === "HR"
          ? el.employeeId === employeeId ||
            el?.employeeId === `${company}-${employeeNumber}`
          : (el.employeeId === employeeId ||
              el?.employeeId === `${company}-${employeeNumber}`) &&
            el.punchTimeStamp === parseInTz(punchTime).valueOf()
      );
      const matchedEntry = degEntries?.[index];
      return matchedEntry;
    } else if (liveStatus === "No Punch") {
      const index = degEntries.findIndex(
        (el) => el.punchType === "HR" && el.employeeId === employeeId
      );
      const matchedEntry = degEntries?.[index];
      return matchedEntry;
    } else {
      return undefined;
    }
  }, [degEntries, employeeId, punchTime]);

  const customOptions = useMemo(() => {
    return [
      // {
      //   label: "Schedules",
      //   options: schedules.map((el) => ({
      //     key: el?.scheduleId,
      //     value: el?.projectId,
      //     label: el?.scheduleAddress,
      //   })),
      // },
      {
        label: "Jobsites",
        options: jobsites.map((el) => ({
          key: el?.jobsiteId,
          value: el?.jobsiteId,
          label: el?.jobAddress,
        })),
      },
    ];
  }, [jobsites, schedules]);

  return (
    <section
      key={employeeNumber}
      className={`emp-sidebar-item ${darkMode ? "emp-sidebar-item-dark" : ""}
      ${selectedEmployees.includes(employeeNumber) ? "active" : ""}
      `}
    >
      <div onClick={onEmployeeFilter} className="emp-data-wrapper">
        <div className="emp-data header-data">
          <span className="emp-name">{employeeName}</span>
          <div className="emp-status">
            <div
              className="status-pin"
              style={{
                backgroundColor: !!color ? color : statusColors?.[liveStatus],
              }}
            ></div>
            <span>{`${liveStatus}${
              connectedEntry?.punchType === "HR" ? " (HR)" : ""
            }`}</span>
          </div>
        </div>
        <div className="emp-data">
          <label>Crew Team</label>
          <span>
            {crewTeamName}{" "}
            {isForeman ? (
              <span style={{ color: "#acacac" }}>(Foreman)</span>
            ) : (
              ""
            )}
          </span>
          {punchTime ? (
            <span className="emp-punch-time">
              {parseInTz(punchTime).format("hh:mm A")}
            </span>
          ) : null}
        </div>
        <div className="emp-data">
          <label>Role</label>
          <span>{props.crewPosition}</span>
        </div>
        {connectedEntry ? (
          <div className="emp-data">
            <span>
              {connectedEntry?.jobAddress}
              {/* ||
                (matchedJobsite as ScheduleType)?.scheduleAddress} */}
            </span>
          </div>
        ) : (
          <div className="emp-data">
            <span>No Jobsite Match</span>
          </div>
        )}
      </div>
      {liveStatus !== "No Punch" &&
      accessRight?.write &&
      selectedWeekDeg?.degStatus !== "Completed" ? (
        <InputComponent
          allowClear
          type="select"
          label="Schedule"
          onSelect={onJobSelect}
          customOptions={customOptions}
          onClear={() => onJobSelect("")}
          placeholder="Select schedule match"
          initialValue={connectedEntry?.jobsiteId || props?.jobsiteId}
          // filterOption={(
          //   input: string,
          //   option: { label: React.ReactElement }
          // ) => {
          //   return (option?.label?.key || "")
          //     .toLowerCase()
          //     .includes((input || "").toLowerCase());
          // }}
        />
      ) : null}
      {liveStatus === "No Punch" &&
      accessRight?.write &&
      selectedWeekDeg?.degStatus !== "Completed" ? (
        <InputComponent
          allowClear
          type="select"
          label="Reason"
          onSelect={onReasonSelect}
          placeholder="Select reason"
          customOptions={reasonOptions}
          onClear={() => onReasonSelect("")}
          initialValue={connectedEntry?.reason}
        />
      ) : null}
      {accessRight?.write && liveStatus !== "No Punch" ? (
        <div className="approve-container">
          <MondayButton
            hasIcon={false}
            onClick={onApprovePunch}
            disabled={
              connectedEntry?.activityStatus === "Completed" || approveDisabled
            }
            className={
              connectedEntry?.activityStatus === "Completed"
                ? ""
                : "mondayButtonBlue"
            }
          >
            Approve Punch
          </MondayButton>
        </div>
      ) : null}
    </section>
  );
}

export default EmployeeSidebarItem;

import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getSOVComparisonWithAccounting = async () => {
  let [scheduleOfValuesRes, chargesRes, requisitionsRes] = await Promise.all([
    fetchAllData("scheduleOfValues", "schedules", "scheduleId"),
    fetchAllData("charges", "charges", "chargeId"),
    fetchAllData("applications", "applications", "applicationId"),
  ]);

  const result = scheduleOfValuesRes.map((schedule) => {
    const charges = chargesRes.filter(
      (charge) =>
        charge.projectId === schedule.projectId &&
        charge.categoryFrom === "Requisition"
    );
    const requisitions = requisitionsRes.filter(
      (requisition) => requisition.scheduleId === schedule.scheduleId
    );
    return {
      createdAt: schedule.createdAt,
      projectId: schedule.projectId,
      projectName: schedule.projectName,
      scheduleId: schedule.scheduleId,
      SOVNo: schedule.SOVNo,
      sovStatus: schedule.sovStatus,
      services: schedule.services.map((service) => ({
        SOVNo: schedule.SOVNo,
        sovStatus: schedule.sovStatus,
        serviceName: service.label,
        estimationId: service.estimationId,
        createdAt: schedule.createdAt,
        projectId: schedule.projectId,
        projectName: schedule.projectName,
        scheduleId: schedule.scheduleId,
        totalSovAmount: service.totalities?.totalPrice || 0,
        totalRequisitionsAmount: requisitions.reduce(
          (sum, requisition) =>
            (sum += requisition.services
              .filter((reqService) => reqService.label === service.label)
              .reduce(
                (acc, item) =>
                  (acc +=
                    item.amounts.reduce(
                      (itemSum, amount) => (itemSum += amount.paymentDue),
                      0
                    ) || 0),
                0
              )),
          0
        ),
        totalChargesAmount: charges.reduce(
          (acc, charge) =>
            (acc += charge.chargeItems
              .filter((chargeItem) => chargeItem.label === service.label)
              .reduce(
                (sum, item) => (sum += item.totalities?.totalPrice || 0),
                0
              )),
          0
        ),
      })),
    };
  });

  return result;
};

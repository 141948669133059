import { message } from "antd";
import React, { createContext, useState, useContext } from "react";
import EmailBox from "../EmailBox";

const EmailBoxContainerContext = createContext();
// the provider for the email boxes that handles opening, closing and updating email boxes states, attachemnts and selected drafts
export const EmailBoxContainerProvider = ({ children }) => {
  const [emailBoxes, setEmailBoxes] = useState([]);
  const [selectedDraft, setSelectedDraft] = useState(null);
  const [attachments, setAttachments] = useState([]);

  const openEmailBox = (id, mobile) => {
    if (emailBoxes.length === 2) {
      message.warning("You cannot open more than two mail boxes");
      return;
    }

    if (mobile && emailBoxes.length === 1) {
      message.warning("You cannot open more than one mail box in mobile");
      return;
    }

    setSelectedDraft(id);
    setEmailBoxes((prev) =>
      [
        ...prev,
        {
          Component: EmailBox,
          key: prev.length,
          id,
        },
      ].filter((box) => (emailBoxes.length > 1 ? box.id !== undefined : true))
    );
  };

  const closeEmailBox = (key) => {
    setEmailBoxes((prev) => prev.filter((e) => e.key !== key));
    setAttachments([]);
  };

  return (
    <EmailBoxContainerContext.Provider
      value={{
        emailBoxes,
        openEmailBox,
        closeEmailBox,
        selectedDraft,
        setAttachments,
        attachments,
      }}
    >
      {children}
    </EmailBoxContainerContext.Provider>
  );
};

export const useEmailBoxContainer = () => useContext(EmailBoxContainerContext);

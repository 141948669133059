import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../integrations/DriveRequest";
import {
  keys,
  state,
} from "../../../../pages/Settings/settingsComponents/Roles/RolesData";
import { FilePreviewModal } from "../../../Documentation/View/components";
import "./Upload.scss";
import { message } from "antd";
import { MondayButton, WarningModal } from "src/components/commonComponents";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { WarningTriangle } from "../../src";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import getFilesWarnings from "../../../Documentation/View/components/DocumentationListCard/components/DocWarningsModal/helpers/getFilesWarnings";
import DocWarningsModal from "../../../Documentation/View/components/DocumentationListCard/components/DocWarningsModal/DocWarningsModal";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import SingleFile from "./SingleFile";

const Upload = ({ params, edited, setNewValues }) => {
  const [values, setValues] = useState(params);
  const [showModal, setShowModal] = useState(false);
  const [currentFile, setCurrentFile] = useState();
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [deleteFile, setDeleteFile] = useState(false);
  const driveRequest = driveApi({ accessToken });
  const { getParents } = driveRequest;
  const [docWarningModalVisible, setDocWarningModalVisible] = useState(false);
  const [uploadsWarnings, setUploadsWarnings] = useState([]);

  useEffect(() => {
    if (!!values?.content?.parentId && accessToken) {
      (async () => {
        const warnings = await getFilesWarnings(
          values.content.value,
          values.content.parentId,
          getParents
        );
        setUploadsWarnings(warnings);
      })();
    }
  }, [values.content.parentId, accessToken]);

  useEffect(() => {
    if (edited) {
      keys(edited)?.map((el) => {
        values.content.value = edited[el];
      });
      setNewValues(state(values));
      setValues(state(values));
    }
  }, [edited]);
  const handleDelete = (id) => {
    function createLog(val) {
      return {
        currentStep: "",
        getEditedValue: () => {},
        id: "upload",
        isDarkMode,
        params: { ...values, content: { ...values.content, value: val } },
        setEditMode: () => {},
        setFormChanged: () => {},
        title: values.content.title,
      };
    }
    message.loading({ duration: 0, content: "Saving Changes..." });
    let prev = values?.content?.value;
    let curr = values?.content?.value?.filter((elem) => elem?.id !== id);
    driveRequest
      .deleteDriveItem(id)
      .then(() => {
        setValues((prev) => {
          const newValue = prev.content.value?.filter(
            ({ id: fileId }) => fileId !== id
          );
          return {
            ...prev,
            content: {
              ...prev.content,
              value: newValue,
            },
          };
        });
      })
      .then(() => {
        values.content.handleDeletefromView(
          createLog(prev),
          createLog(curr),
          curr
        );
        message.destroy();
        message.success({
          content: "Successfully saved changes",
          key: "onSave",
        });
      })
      .catch((err) => {
        console.log("Error: ", err);
        message.destroy();
        message.error("Failed saving changes");
      });
  };

  return (
    <div className="UploadCard">
      {uploadsWarnings?.length > 0 && (
        <div
          onClick={() => {
            setDocWarningModalVisible(true);
          }}
          className="warningMessage"
        >
          <WarningTriangle
            fill="#FE4C4A"
            height={15}
            width={17}
            style={{ cursor: "pointer" }}
          />
          <span style={{ color: isDarkMode ? "#fff" : " #323338" }}>
            {uploadsWarnings?.length} warning found (
            <span style={{ color: "#1264A3", fontWeight: 600 }}>
              click to view
            </span>
            )
          </span>
        </div>
      )}
      {Array.isArray(values?.content?.value) ? (
        values?.content?.value?.map((file, index) => {
          const fileExtension = file?.name?.split(".").pop();

          const fileType = Object.keys(fileIcons).includes(fileExtension)
            ? fileExtension
            : "image";

          return (
            <SingleFile
              key={file?.id}
              {...{
                driveRequest,
                index,
                isDarkMode,
                setCurrentFile,
                setShowModal,
                handleDelete: setDeleteFile,
                fileType,
                file,
              }}
            />
          );
        })
      ) : (
        <span className="descriptionValue">No files uploaded</span>
      )}
      {showModal && (
        <FilePreviewModal
          {...{
            visible: true,
            setVisible: () => {
              setShowModal(false);
              setCurrentFile("");
            },
            file: currentFile,
          }}
        />
      )}
      {docWarningModalVisible && (
        <DocWarningsModal
          {...{
            visible: docWarningModalVisible,
            setVisible: setDocWarningModalVisible,
            docWarnings: uploadsWarnings,
            accessToken,
            setUploadsWarnings,
            callbackFn: () => {},
            proppedCorrectFolderId: values.content.parentId,
          }}
        />
      )}
      {!!deleteFile && (
        <WarningModal
          title={"Warning"}
          darkMode={isDarkMode}
          visible={!!deleteFile}
          setVisible={setDeleteFile}
        >
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              flexDirection: "column",
            }}
          >
            <span>
              <WarningTriangle />
            </span>
            <p>{`Are you sure you want to continue?`}</p>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                gap: 10,
                width: "100%",
              }}
            >
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setDeleteFile(false)}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  handleDelete(deleteFile);
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
    </div>
  );
};
export default Upload;

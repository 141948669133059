import moment from "moment";
import { compareIncluding } from "../../../SidebarPages/utils";
import htmlParser from "../../../../utils/htmlParser";
import { parseContent } from "./parseContent";
import { docDefinition } from "../../../SidebarPages/Safety/safetyPdf";
import XLSX from "sheetjs-style";
import createPDF from "../../../../integrations/createPDF";
import { defaultExcelHeaderStyle } from "../../../../helpers/constants/defaultExcelHeaderStyle";
import { defaultExcelBodyStyle } from "../../../../helpers/constants/defaultExcelBodyStyle";
import { excelCellFitToColumn } from "../../../../utils/excelCellFitToColumn";
import { message } from "antd";
import { generatePolygonImage } from "../../Map/Geofence/shapesImagesGeneratorForFences";

const excelHeaderStyleWithoutBorder = {
  font: { bold: true },
};

export const generatePdf = (
  newLogData,
  title,
  generateLog,
  base64,
  action,
  getDocDefinition = false
) => {
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const dynamicPdfData = newLogData.map((elem, i) => {
    const headers = ["Previous Key", "Previous Value", "Updated Value"];
    const tableBody = [headers];

    const title = `Action Type: ${elem.actionType} | User: ${
      elem.nameOfUser
    } | Updated At: ${moment(elem.updatedAt)
      .tz("America/New_York")
      .format("MM/DD/YYYY h:mm:ss a")}`;

    const addRowToTable = (node, prefix = "") => {
      const dynamic = node.children ? node.children : node.logs;

      if (!dynamic.length) return true;

      const filteredData = dynamic.filter(
        (log) =>
          !log?.key?.toLowerCase().includes("id") &&
          log?.key?.toLowerCase() !== "id"
      );

      filteredData.forEach((log) => {
        if (log.key === "geoFenceInfo" && log.children) {
          // Handle the special case where key is "geoFenceInfo" and it has children
          log.children.forEach((gf) => {
            gf.map((coord) => {
              const logKey = coord.key || "";
              const updLogValue = coord.updatedValue || "";
              const prevLogValue = coord.previousValue || "";
              tableBody.push([
                `${prefix}${logKey}`,
                `${prevLogValue}`,
                `${updLogValue}`,
              ]);
            });
          });
        } else if (log.key === "description") {
          const logKey = log.key;
          const updLogValue = htmlParser(log.updatedValue) || "";
          const prevLogValue = htmlParser(log.previousValue) || "";

          tableBody.push([
            `${prefix}${logKey}`,
            `${prevLogValue}`,
            `${updLogValue}`,
          ]);
        } else if (log.key === "lastUpdated" || log.key === "createdAt") {
          const logKey = log.key;
          const updLogValue = parseContent(log?.updatedValue);
          const prevLogValue = parseContent(log?.previousValue);

          tableBody.push([
            `${prefix}${logKey}`,
            `${prevLogValue}`,
            `${updLogValue}`,
          ]);
        } else if (log.key === "image") {
          const base64Regex =
            /^(data:([a-zA-Z0-9]+\/[a-zA-Z0-9.-]+)?;base64,)?([A-Za-z0-9+/]{4})*([A-Za-z0-9+/]{2}==|[A-Za-z0-9+/]{3}=)?$/;

          const logKey = log.key;
          let updLogValue = log.updatedValue || "";
          let prevLogValue = log.previousValue || "";

          if (base64Regex.test(prevLogValue)) {
            prevLogValue = { image: log.previousValue, width: 100 };
          }
          if (base64Regex.test(log.updatedValue)) {
            updLogValue = { image: log.updatedValue, width: 100 };
          }

          tableBody.push([`${prefix}${logKey}`, prevLogValue, updLogValue]);
        } else {
          // Handle the general case
          const logKey = log.key || "";
          const updLogValue = log.updatedValue || "";
          const prevLogValue = log.previousValue || "";

          tableBody.push([
            `${prefix}${logKey}`,
            `${prevLogValue}`,
            `${updLogValue}`,
          ]);
        }
        if (log.children) {
          addRowToTable(log, `${prefix}  `);
        }
      });
    };

    const noData = addRowToTable(elem);

    if (!!noData) {
      return [
        {
          text: title,
          margin: [0, 20, 0, 20],

          style: {
            fontSize: 14,
            bold: true,
            alignment: "center",
            background: "#CCCCCC",
          },
        },
      ];
    }

    const filteredTableBody = tableBody.filter((row) =>
      row.some((cell) => cell.trim() !== "")
    );

    if (i < newLogData.length - 1) {
      // Check if there are non-empty rows in the filteredTableBody
      if (
        filteredTableBody.some((row) => row.some((cell) => cell.trim() !== ""))
      ) {
        filteredTableBody.push(["", "", ""]); // Empty row as separator
      }
    }

    return [
      {
        text: title,
        margin: [0, 20, 0, 20],

        style: {
          fontSize: 14,
          bold: true,
          alignment: "center",
          background: "#CCCCCC",
        },
      },
      {
        table: {
          headerRows: 1,
          body: filteredTableBody,
          widths: ["*", "*", "*"],
        },
        layout: {
          fillColor: function (rowIndex) {
            return rowIndex === 0
              ? "#B7C4CB"
              : rowIndex % 2 === 0
              ? "#f7f7f7"
              : "#fff";
          },
        },
      },
    ];
  });

  if (getDocDefinition) {
    return docDefinition(title, dynamicPdfData, companyLogo);
  }

  // The generated dynamicPdfData now contains the required structure for your PDF generation
  createPDF({
    action,
    docDefinition: docDefinition(title, dynamicPdfData, companyLogo),
    title: action === "print" ? false : title,
  });

  generateLog(
    action === "Download" ? "Download" : "Print",
    action === "Download" ? "Logs Download PDF" : "Logs Print PDF",
    newLogData[0]?.recordId || ""
  );
};

export async function handleExportPdfClick(
  newLogData,
  action,
  getDocDefinition = false
) {
  let pdfContent = [];
  message.loading("Generating PDF...");

  try {
    for (const logData of newLogData) {
      const title = `Action Type: ${logData.actionType} | User: ${
        logData.nameOfUser
      } | Updated At: ${moment(logData.updatedAt)
        .tz("America/New_York")
        .format("MM/DD/YYYY h:mm:ss a")}`;
      const { previousData, currentData } = logData;
      const prevData = previousData?.params?.rowData;
      const currData = currentData?.params?.rowData;

      const dynamicLog = !Array.isArray(currData) ? currData : prevData;

      const prevPolygonImage = await generatePolygonImage({
        polygonCoordinates:
          dynamicLog.action !== "Create" && dynamicLog.action !== "edit"
            ? dynamicLog.geoFenceInfo
            : dynamicLog.action === "edit"
            ? prevData.geoFenceInfo
            : [],
      });

      const currPolygonImage = await generatePolygonImage({
        polygonCoordinates:
          dynamicLog.action !== "delete" ? dynamicLog.geoFenceInfo : [],
      });

      const dynamicLink = `${logData.topic}/${logData.category.toLowerCase()}/${
        logData.recordId
      }`;

      const contentForLog = [
        {
          text: title,
          margin: [0, 20, 0, 20],
          style: {
            fontSize: 14,
            bold: true,
            alignment: "center",
            background: "#CCCCCC",
            padding: [10, 20], // Top and bottom padding of 10, left and right padding of 20
            borderRadius: 5, // Border radius of 5
          },
        },
        {
          columns: [
            {
              stack: [
                { text: "Previous Values", alignment: "left" },
                { image: prevPolygonImage, width: 250, height: 200 },
              ],
            },
            { width: 10, text: "" },
            {
              stack: [
                { text: "Updated Values", alignment: "left" },
                { image: currPolygonImage, width: 250, height: 200 },
              ],
            },
          ],
        },
        {
          text: "Go to record",
          alignment: "center",
          margin: [0, 10, 0, 10],
          decoration: "underline",
          color: "blue",
          link: dynamicLink,
        },
      ];

      pdfContent = pdfContent.concat(contentForLog);
    }

    const docDefinition = {
      content: pdfContent,
      styles: {
        header: {
          fontSize: 18,
          bold: true,
          margin: [0, 0, 0, 10],
        },
      },
    };

    if (getDocDefinition) {
      return docDefinition;
    }

    pdfMake.createPdf(docDefinition).download("Logs.pdf");
  } catch (error) {
    console.error("Error generating PDF: ", error);
  } finally {
    message.destroy();
  }
}

export const exportGridToExcel = (
  title,
  generateLog,
  newLogData,
  getDocDefinition = false
) => {
  const dynamicExcelData = newLogData.map((elem) => {
    const headers = [
      { v: "Previous Key", s: defaultExcelHeaderStyle },
      { v: "Previous Value", s: defaultExcelHeaderStyle },
      { v: "Updated Value", s: defaultExcelHeaderStyle },
    ];
    const rows = [headers];

    const addRowToTable = (node, prefix = "") => {
      const dynamic = node.children ? node.children : node.logs;

      if (!dynamic.length) return true;

      const filteredData = dynamic.filter(
        (log) =>
          !log?.key?.toLowerCase().includes("id") &&
          log?.key?.toLowerCase() !== "id"
      );

      filteredData.forEach((log) => {
        if (log.key === "description") {
          const logKey = log.key;
          const updLogValue = htmlParser(log.updatedValue) || "";
          const prevLogValue = htmlParser(log.previousValue) || "";

          rows.push([
            { v: `${prefix}${logKey}`, s: defaultExcelBodyStyle },
            { v: `${prevLogValue}`, s: defaultExcelBodyStyle },
            { v: `${updLogValue}`, s: defaultExcelBodyStyle },
          ]);
        } else if (log.key === "lastUpdated" || log.key === "createdAt") {
          const logKey = log.key;
          const updLogValue = parseContent(log?.updatedValue);
          const prevLogValue = parseContent(log?.previousValue);

          rows.push([
            { v: `${prefix}${logKey}`, s: defaultExcelBodyStyle },
            { v: `${prevLogValue}`, s: defaultExcelBodyStyle },
            { v: `${updLogValue}`, s: defaultExcelBodyStyle },
          ]);
        } else if (log.key === "image") {
          return;
        } else {
          // Handle the general case
          const logKey = log.key || "";
          const updLogValue = log.updatedValue || "";
          const prevLogValue = log.previousValue || "";

          rows.push([
            { v: `${prefix}${logKey}`, s: defaultExcelBodyStyle },
            { v: `${prevLogValue}`, s: defaultExcelBodyStyle },
            { v: `${updLogValue}`, s: defaultExcelBodyStyle },
          ]);
        }
        if (log.children) {
          addRowToTable(log, `${prefix}  `);
        }
      });
    };

    const noData = addRowToTable(elem);

    const filteredRows = rows.filter((row) =>
      row.some((cell) => cell.v.trim() !== "")
    );

    return {
      title: [
        [
          {
            v: `Action Type: ${elem.actionType}`,
            s: !!noData
              ? defaultExcelHeaderStyle
              : excelHeaderStyleWithoutBorder,
          },
          {
            v: `User: ${elem.nameOfUser}`,
            s: !!noData
              ? defaultExcelHeaderStyle
              : excelHeaderStyleWithoutBorder,
          },
          {
            v: `Updated At: ${moment(elem.updatedAt)
              .tz("America/New_York")
              .format("MM/DD/YYYY h:mm:ss a")}`,
            s: !!noData
              ? defaultExcelHeaderStyle
              : excelHeaderStyleWithoutBorder,
          },
        ],
        ["", "", ""],
      ],
      rows: !!noData ? ["", "", ""] : [...filteredRows, ["", "", ""]],
    };
  });

  const headers = ["", "", ""];

  if (getDocDefinition) {
    // return docDefinition
    return generatePdf("", true);
  }

  const worksheet = XLSX.utils.aoa_to_sheet([headers]);

  dynamicExcelData.forEach(({ title, rows }, i) => {
    if (i > 0) {
      XLSX.utils.sheet_add_aoa(worksheet, [["", "", ""]], { origin: -1 });
    }

    XLSX.utils.sheet_add_aoa(worksheet, title, { origin: -1 });
    XLSX.utils.sheet_add_aoa(worksheet, rows, { origin: -1 });

    worksheet["!cols"] = excelCellFitToColumn([...title, ...rows]);
  });

  const workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, `${title}.xlsx`);
  generateLog("Download", "Logs Download Excel", newLogData[0]?.recordId || "");
};

export const uploadExcelToDrive = async (driveRequest, driveFolderId) => {
  try {
    const dynamicExcelData = newLogData.map((elem) => {
      const headers = [
        { v: "Previous Key", s: defaultExcelHeaderStyle },
        { v: "Previous Value", s: defaultExcelHeaderStyle },
        { v: "Updated Value", s: defaultExcelHeaderStyle },
      ];
      const rows = [headers];

      const addRowToTable = (node, prefix = "") => {
        const dynamic = node.children ? node.children : node.logs;

        if (!dynamic.length) return true;

        const filteredData = dynamic.filter(
          (log) =>
            !log?.key?.toLowerCase().includes("id") &&
            log?.key?.toLowerCase() !== "id"
        );

        filteredData.forEach((log) => {
          if (log.key === "description") {
            const logKey = log.key;
            const updLogValue = htmlParser(log.updatedValue) || "";
            const prevLogValue = htmlParser(log.previousValue) || "";

            rows.push([
              { v: `${prefix}${logKey}`, s: defaultExcelBodyStyle },
              { v: `${prevLogValue}`, s: defaultExcelBodyStyle },
              { v: `${updLogValue}`, s: defaultExcelBodyStyle },
            ]);
          } else if (log.key === "lastUpdated" || log.key === "createdAt") {
            const logKey = log.key;
            const updLogValue = parseContent(log?.updatedValue);
            const prevLogValue = parseContent(log?.previousValue);

            rows.push([
              { v: `${prefix}${logKey}`, s: defaultExcelBodyStyle },
              { v: `${prevLogValue}`, s: defaultExcelBodyStyle },
              { v: `${updLogValue}`, s: defaultExcelBodyStyle },
            ]);
          } else {
            // Handle the general case
            const logKey = log.key || "";
            const updLogValue = log.updatedValue || "";
            const prevLogValue = log.previousValue || "";

            rows.push([
              { v: `${prefix}${logKey}`, s: defaultExcelBodyStyle },
              { v: `${prevLogValue}`, s: defaultExcelBodyStyle },
              { v: `${updLogValue}`, s: defaultExcelBodyStyle },
            ]);
          }
          if (log.children) {
            addRowToTable(log, `${prefix}  `);
          }
        });
      };

      const noData = addRowToTable(elem);

      const filteredRows = rows.filter((row) =>
        row.some((cell) => cell.v.trim() !== "")
      );

      return {
        title: [
          [
            {
              v: `Action Type: ${elem.actionType}`,
              s: !!noData
                ? defaultExcelHeaderStyle
                : excelHeaderStyleWithoutBorder,
            },
            {
              v: `User: ${elem.nameOfUser}`,
              s: !!noData
                ? defaultExcelHeaderStyle
                : excelHeaderStyleWithoutBorder,
            },
            {
              v: `Updated At: ${moment(elem.updatedAt)
                .tz("America/New_York")
                .format("MM/DD/YYYY h:mm:ss a")}`,
              s: !!noData
                ? defaultExcelHeaderStyle
                : excelHeaderStyleWithoutBorder,
            },
          ],
          ["", "", ""],
        ],
        rows: !!noData ? ["", "", ""] : [...filteredRows, ["", "", ""]],
      };
    });

    const headers = ["", "", ""];
    const worksheet = XLSX.utils.aoa_to_sheet([headers]);

    dynamicExcelData.forEach(({ title, rows }, i) => {
      if (i > 0) {
        XLSX.utils.sheet_add_aoa(worksheet, [["", "", ""]], { origin: -1 });
      }

      XLSX.utils.sheet_add_aoa(worksheet, title, { origin: -1 });
      XLSX.utils.sheet_add_aoa(worksheet, rows, { origin: -1 });

      worksheet["!cols"] = excelCellFitToColumn([...title, ...rows]);
    });

    const workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    // Convert the workbook to an Excel binary array
    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    // Convert the array buffer to a blob with the correct MIME type
    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    // Upload the Excel file to Google Drive
    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: "ExportedData.xlsx",
        parents: [driveFolderId],
      }
    );

    // Extract and log information about the uploaded file
    const { id, newName } = await uploadResponse.json();

    // Update state or perform any necessary actions with the file ID
    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};

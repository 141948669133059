import { fileIcons } from "../../../../../documentationViewData";
import { TrashIcon } from "../../../../../../../Communication/assets";
import {
  DownloadIcon,
  SaveIcon,
  Redirect,
} from "../../../../../../../BasePage/src";
import dayjs from "dayjs";
import { useRef } from "react";
import { useLocation } from "react-router-dom";

const DATE_FORMAT = "MM/DD/YYYY hh:mm a";

function DocUploaderFile({
  file = {},
  index,
  handleCurrentFile,
  setWarningModalVisible,
  disabled,
  fileType,
  isCreateDocumentEnabled = false,
  setDocumentationModalFiles,
  tempSrc,
}) {
  const {
    id,
    name = "",
    uploadedBy = "",
    uploadedAt,
    url,
    type,
    mimeType,
    downloadUrl,
    documented = {},
    size,
  } = file;
  const divRef = useRef(null);
  const location = useLocation();

  let userName = uploadedBy;
  if (location.pathname?.includes("foremanProject")) {
    userName = "";
  }
  function formatBytes(bytes) {
    if (bytes === 0) return "0 Bytes";

    const sizes = ["Bytes", "KB", "MB", "GB"];
    const i = Math.floor(Math.log(bytes) / Math.log(1024));

    return parseFloat((bytes / Math.pow(1024, i)).toFixed(2)) + " " + sizes[i];
  }

  const handleMouseOver = (colorClass) => {
    divRef.current.classList.remove(
      "uploadedFilesCardRed",
      "uploadedFilesCardBlue"
    );
    divRef.current.classList.add(colorClass);
  };

  const handleMouseLeave = () => {
    divRef.current.classList.remove(
      "uploadedFilesCardBlue",
      "uploadedFilesCardRed"
    );
  };

  return (
    <div
      data-testid="file-item"
      ref={divRef}
      key={id}
      className="uploadedFilesCard"
    >
      <span className="filesIcon">{fileIcons[fileType]}</span>

      <div
        onClick={() => {
          handleCurrentFile(id, { ...file, index });
        }}
        className="uploadedFile"
      >
        <span className="fileName">{name}</span>
        <div className="fileAuthor">
          {userName && <span>{userName}</span>}
          {uploadedAt && userName && <span>-</span>}
          {uploadedAt && <span>{dayjs(uploadedAt).format(DATE_FORMAT)}</span>}
          {size && <span>{` | ${formatBytes(size)}`}</span>}
        </div>
      </div>
      <div className="actionButtons">
        {isCreateDocumentEnabled &&
          !disabled &&
          (documented.isDocumented ? (
            <span
              data-testid="redirect-icon"
              onMouseOver={() => handleMouseOver("uploadedFilesCardBlue")}
              onMouseLeave={handleMouseLeave}
              onClick={() =>
                window.open(
                  `/documentation?tab=Documentation&docId=${documented?.docId}`,
                  "_blank"
                )
              }
              className="closeIconContainer"
            >
              <Redirect />
            </span>
          ) : (
            <span
              data-testid="save-icon"
              onMouseOver={() => handleMouseOver("uploadedFilesCardBlue")}
              onMouseLeave={handleMouseLeave}
              className="closeIconContainer"
              onClick={() => setDocumentationModalFiles([file])}
            >
              <SaveIcon />
            </span>
          ))}
        {!disabled ? (
          <>
            <DownloadButton
              {...{
                tempSrc,
                id,
                name,
                divRef,
              }}
            />
            <span
              onMouseOver={() => handleMouseOver("uploadedFilesCardRed")}
              onMouseLeave={handleMouseLeave}
              className="closeIconContainer closeIconContainerDelete"
              onClick={() => setWarningModalVisible(id)}
            >
              <TrashIcon />
            </span>
          </>
        ) : null}
      </div>
    </div>
  );
}

export default DocUploaderFile;

function DownloadButton({ id, tempSrc, name, divRef }) {
  const handleDownloadClick = async () => {
    if (tempSrc?.[id]) {
      try {
        const link = document.createElement("a");
        link.href = tempSrc[id];
        link.download = name;
        document.body.appendChild(link);
        link.click();
        document.body.removeChild(link);
      } catch (e) {
        console.error("Error:", e);
        window.open(
          `https://drive.google.com/u/1/uc?id=${id}&export=download&authuser=0`
        );
      }
    } else {
      window.open(
        `https://drive.google.com/u/1/uc?id=${id}&export=download&authuser=0`
      );
    }
  };

  return (
    <span
      onMouseOver={() => {
        divRef.current.classList.remove("uploadedFilesCardRed");
        divRef.current.classList.add("uploadedFilesCardBlue");
      }}
      onMouseLeave={() => {
        divRef.current.classList.remove("uploadedFilesCardBlue");
      }}
      className="closeIconContainer"
      onClick={handleDownloadClick}
    >
      <DownloadIcon />
    </span>
  );
}

import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getSchedulingServices = async () => {
  try {
    const scheduling = await fetchAllData(
      "scheduling",
      "scheduling",
      "scheduleId"
    );

    return scheduling.map((schedule) => ({
      scheduleAddress: schedule.scheduleAddress,
      projectExecutive: schedule.projectExecutive,
      scheduleName: schedule.scheduleName,
      scheduleId: schedule.scheduleId,
      scheduleStatus: schedule.scheduleStatus,
      typeOfWork: schedule.typeOfWork,
      items: Object.keys(schedule.toBeScheduled)
        .map((key, index) => {
          const estimationName = `Estimation ${index + 1}`;
          const items = schedule.toBeScheduled[key];

          return items.flatMap((item) => {
            const { label, serviceOptions } = item;
            const options = serviceOptions[0] || [];

            return options.map((option) => {
              if (option.items && option.items.length > 0) {
                return option.items.map((nestedOption) => ({
                  estimationName,
                  label,
                  scheduleName: schedule.scheduleName,
                  elevationLabel: option.elevationLabel,
                  totalProgress: nestedOption?.totalProgress || 0,
                  price: nestedOption?.originalPli?.totalPrice || 0,
                  approved: nestedOption?.originalPli?.approved || true,
                }));
              }
              return {
                estimationName,
                label,
                scheduleName: schedule.scheduleName,
                elevationLabel: option.elevationLabel,
                totalProgress: option?.totalProgress,
                price: option.price,
                approved: option.approved,
              };
            });
          });
        })
        .flat(2),
    }));
  } catch (error) {
    console.error("data source function getSchedulingServices error: " + error);
  }
};

import "./TeamsList.scss";
import { Popover } from "antd";
import { TickIcon, XIcon } from "../../../InternalChat/assets/icons";
import { MondayButton } from "../../../commonComponents";
import SimpleCheckbox from "./SimpleCheckbox/SimpleCheckbox";
import { teamPopoverConfirm, teamSelectHandler } from "./helpers";
import { InfoText } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";

function TeamsList({
  selectedTeamTmp = [],
  setSelectedTeamTmp = () => {},
  allTeams,
  comparator,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  function onTeamSelect(team) {
    teamSelectHandler({
      team,
      selectedTeamTmp,
      setSelectedTeamTmp,
      comparator,
    });
  }

  const customTeam = useMemo(() => {
    const found =
      selectedTeamTmp?.find((team) => team?.value === "Team") || null;
    if (found && (found?.members || [])?.length > 0) {
      return found;
    } else {
      return null;
    }
  }, [selectedTeamTmp]);

  return (
    <div
      className={`teams-list-transfer ${
        isDarkMode ? "teams-list-transfer-dark" : ""
      }`}
    >
      {customTeam && (
        <Popover
          key={"Custom Team"}
          content={() =>
            PopoverContent({
              teamName: "Custom Team",
              allTeamMembers: customTeam?.members || [],
              selectedTeamMembers: customTeam?.members || [],
              selectedTeamTmp,
              setSelectedTeamTmp,
              isSelectedIndex: selectedTeamTmp?.findIndex(
                (t) => t?.value === "Team"
              ),
              team: { ...customTeam, teamName: "Team" },
              customTeam: true,
              isDarkMode,
            })
          }
          title={null}
          trigger="hover"
          placement="bottom"
          overlayClassName={`teams-popover ${
            isDarkMode ? "teams-popover-dark" : ""
          }`}
        >
          <div
            // onClick={() => onTeamSelect(team)}
            className={`teams-list-transfer-item ${true ? "selected" : ""} `}
            key={"Custom Team"}
          >
            <span>
              {"Custom Team"} ({`${customTeam?.members?.length}`})
            </span>
            {true && <TickIcon fill="#1264a3" />}
          </div>
        </Popover>
      )}
      {allTeams.map((team) => {
        const { teamId = "", teamName = "", members = [] } = team || {};
        let isSelectedIndex = -1;
        if (comparator === "teamId") {
          isSelectedIndex = selectedTeamTmp?.findIndex((t) =>
            (t?.teamId || [])?.includes(teamId)
          );
        } else if (comparator === "teamName") {
          isSelectedIndex = selectedTeamTmp?.findIndex(
            (t) => t?.value === teamName
          );
        }
        const isSelected = isSelectedIndex > -1;

        const currTeam = isSelected ? selectedTeamTmp[isSelectedIndex] : [];
        const selectedTeamMembers = currTeam?.members || [];

        return (
          <Popover
            key={team.teamId}
            content={() =>
              PopoverContent({
                teamName,
                allTeamMembers: members,
                selectedTeamMembers,
                selectedTeamTmp,
                setSelectedTeamTmp,
                isSelectedIndex,
                team,
                isDarkMode,
              })
            }
            title={null}
            trigger="hover"
            placement="bottom"
            overlayClassName={`teams-popover ${
              isDarkMode ? "teams-popover-dark" : ""
            }`}
          >
            <div
              onClick={() => onTeamSelect(team)}
              className={`teams-list-transfer-item ${
                isSelected ? "selected" : ""
              }`}
              key={team.teamId}
            >
              <span>
                {teamName} (
                {`${selectedTeamMembers?.length}/${members?.length}`})
              </span>
              {isSelected && <TickIcon fill="#1264a3" />}
            </div>
          </Popover>
        );
      })}
    </div>
  );
}

export default TeamsList;

const PopoverContent = ({
  teamName,
  allTeamMembers = [],
  selectedTeamMembers,
  selectedTeamTmp = [],
  setSelectedTeamTmp = () => {},
  isSelectedIndex,
  customTeam = false,
  team,
}) => {
  const [membersToAdd, setMembersToAdd] = useState([]);
  const [membersToRemove, setMembersToRemove] = useState([]);

  function onConfirm() {
    teamPopoverConfirm({
      isSelectedIndex,
      team,
      membersToAdd,
      membersToRemove,
      selectedTeamTmp,
      setSelectedTeamTmp,
      setMembersToAdd,
      setMembersToRemove,
    });
  }
  const allMembersSelected = allTeamMembers.every((member) => {
    return selectedTeamMembers?.some((u) => u.identityId === member.identityId);
  });

  return (
    <div className={"teams-popover"}>
      <InfoText
        {...{
          text: customTeam
            ? `
          These users are part of the custom team. You can remove users from this team by unchecking them or add more users from the transfer list below.`
            : `You can add or remove users from ${team?.teamName}. Users not selected can belong to another team that is selected.`,
        }}
      />
      <div className="teams-popover-title">
        <span>{teamName}</span>
        {!allMembersSelected ? (
          <span
            className="selectAll"
            onClick={() => {
              setMembersToAdd(allTeamMembers);
            }}
          >
            Select Everyone
          </span>
        ) : null}
      </div>
      <div className="teams-popover-content">
        {allTeamMembers.map((member, i) => {
          const { nameOfUser, identityId } = member || {};
          const isUsersOnTeam =
            selectedTeamMembers?.length === 0
              ? false
              : selectedTeamMembers?.some(
                  (el) => el?.identityId === identityId
                );
          const isCurrentlySelected = membersToAdd.some(
            (u) => u.identityId === identityId
          );

          const removedMember = membersToRemove.some(
            (el) => el.identityId === identityId
          );

          return (
            <SimpleCheckbox
              key={i}
              {...{
                checked:
                  (isUsersOnTeam || isCurrentlySelected) && !removedMember,
                onChange: (e) => {
                  const value = e.target.checked;
                  if (value) {
                    if (!removedMember) {
                      setMembersToAdd((prev) => [
                        ...prev,
                        allTeamMembers.find(
                          (el) => el.identityId === identityId
                        ),
                      ]);
                    } else {
                      setMembersToRemove((prev) =>
                        prev.filter((el) => el.identityId !== identityId)
                      );
                    }
                  } else {
                    if (isUsersOnTeam) {
                      setMembersToRemove((prev) => [...prev, member]);
                    } else {
                      setMembersToAdd((prev) =>
                        prev.filter((el) => el.identityId !== identityId)
                      );
                    }
                  }
                },
              }}
            >
              {nameOfUser}
            </SimpleCheckbox>
          );
        })}
      </div>
      <div className="team-popover-footer">
        <MondayButton
          {...{
            className: "mondayButtonGrey",
            Icon: <XIcon />,
            disabled: !membersToAdd.length && !membersToRemove.length,
            onClick: () => {
              setMembersToAdd([]);
              setMembersToRemove([]);
            },
          }}
        >
          Cancel
        </MondayButton>
        {!membersToAdd.length && !membersToRemove.length ? null : (
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              Icon: <TickIcon />,
              disabled: !membersToAdd.length && !membersToRemove.length,
              onClick: onConfirm,
            }}
          >
            Save
          </MondayButton>
        )}
      </div>
    </div>
  );
};

import {
  useRef,
  Fragment,
  useState,
  useEffect,
  forwardRef,
  useContext,
  useCallback,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";

import {
  isEnteredAlert,
  saveAuditChanges,
  FleetsLiveContext,
  getStatsFromTrips,
  loadLivePreference,
  validateActualTrips,
  getAllAuditsForVehicle,
  findAuditDateExtremities,
  getTripsFromActualActivities,
} from "../../../../utils";
import LiveAuditContext from "../../LiveAuditContext";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import {
  AuditMapView,
  ActualPlanCard,
  InitialPlanCard,
  UpdatedPlanCard,
} from "./components";
import { AuditIcon } from "../../../../../../../../assets";

import "./AuditActions.scss";

/**
 * @typedef StopReason
 * @property {string} reason
 * @property {number} beginDate
 * @property {number} endDate
 * @property {boolean} isDefault
 */

const AuditActions = forwardRef((_, ref) => {
  //#region CONTEXT
  const {
    allStops,
    locations,
    isWritable,
    globalAudits,
    auditsForDate,
    getTripsCollection,
    changeGlobalAudits,
    actualTripsForDate,
    plannedTripsForDate,
    getOtherDayActivities,
  } = useContext(FleetsLiveContext);

  const {
    viewMode,
    auditStatus,
    onChangesMade,
    selectedVehicle,
    updateAuditStatus,
  } = useContext(LiveAuditContext);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  //#region STATES
  const [trips, setTrips] = useState([]);
  const [toggleCancel, setToggleCancel] = useState(true);
  const [allAuditsForVehicle, setAllAuditsForVehicle] = useState({});
  const [discardedStops, setDiscardedStops] = useState([]);
  const [linkedStopsData, setLinkedStopsData] = useState([]);
  const [dayStats, setDayStats] = useState(null);
  const [timeChanges, setTimeChanges] = useState({});
  const [createdAlerts, setCreatedAlerts] = useState({});
  const [selectedReasons, setSelectedReasons] = useState(
    /** @type {StopReason[]} */ ([])
  );
  const [dateBoundaries, setDateBoundaries] = useState({
    minDate: loadLivePreference("liveSelectedDate"),
    maxDate: loadLivePreference("liveSelectedDate"),
    vehicle: undefined,
    date: undefined,
  });

  const mapViewRef = useRef(null);
  const actualPlanRef = useRef(null);
  const initialPlanRef = useRef(null);
  const updatedPlanRef = useRef(null);

  const allCancel = useCallback(() => {
    //#region CANCEL FUNCTION
    setToggleCancel((prev) => !prev);
    setSelectedReasons((prev) => prev.filter(({ isDefault }) => isDefault));

    if (mapViewRef.current) {
      mapViewRef.current.clearCustomLocation();
    }

    setTimeout(() => {
      if (updatedPlanRef?.current) {
        updatedPlanRef?.current?.clearHighlights();
        updatedPlanRef?.current?.clearAudited();
      }

      if (actualPlanRef?.current) {
        actualPlanRef?.current?.resetChanges();
      }
    }, 0);
  }, []);

  useEffect(() => {
    //#region RESET EFFECT
    let newTrips = getTripsFromActualActivities(
      actualTripsForDate?.[selectedVehicle],
      { auditsForTruck: allAuditsForVehicle }
    );

    setTrips(newTrips);

    if (actualPlanRef?.current) {
      actualPlanRef?.current?.updateDateBoundaries(
        calcDateBoundaries(newTrips)
      );
    }

    setSelectedReasons(allAuditsForVehicle?.stopReasons || []);
    setTimeChanges(allAuditsForVehicle?.timeChanges || {});
    setCreatedAlerts(allAuditsForVehicle?.createdAlerts || {});
  }, [toggleCancel, selectedVehicle, actualTripsForDate, allAuditsForVehicle]);

  useEffect(() => {
    //#region AUDIT FOR VEHICLE
    setAllAuditsForVehicle(
      getAllAuditsForVehicle({ auditsForDate, globalAudits, selectedVehicle })
    );
  }, [globalAudits, auditsForDate, selectedVehicle]);

  useEffect(() => {
    //#region IS WRITABLE EFFECT
    if (!isWritable) {
      allCancel();
    }
  }, [isWritable]);

  useEffect(() => {
    //#region PAGINATED TRIPS
    let call = false;

    if (selectedVehicle) {
      if (!dateBoundaries.vehicle) {
        call = true;
      } else {
        if (selectedVehicle === dateBoundaries.vehicle) {
          call = true;
        }
      }
    }

    if (call) {
      getTripsCollection({
        minDate: dateBoundaries.minDate,
        maxDate: dateBoundaries.maxDate,
        fleetId: selectedVehicle,
        call: "TRIPS",
      }).then((res) => {
        const lowBound = dateBoundaries.minDate;
        const highBound = dayjsNY(dateBoundaries.maxDate)
          .endOf("day")
          .valueOf();

        setDayStats(
          getStatsFromTrips({
            allStops: allStops?.[selectedVehicle]?.filter(
              ({ beginDate, endDate }) =>
                lowBound <= endDate && highBound >= beginDate
            ),
            allTrips: res.allTrips || [],
          })
        );
      });
    }
  }, [
    viewMode,
    selectedVehicle,
    dateBoundaries.minDate,
    dateBoundaries.maxDate,
    dateBoundaries.vehicle,
  ]);

  const checkForOtherDay = useCallback(
    (newVehicle) => {
      //#region CHECK FOR OTHER DAY
      /**
       * If the user open a vehicle whose plans matched another day
       * and they've been audited, we need to fetch the other day's data
       */
      let fleetId = newVehicle || selectedVehicle;
      if (!fleetId) {
        return;
      }

      const { minDate: minAuditDate, maxDate: maxAuditDate } =
        findAuditDateExtremities(
          globalAudits?.["alertChanges"]?.[fleetId]?.["changed"] || {},
          true
        );

      let allActivitiesForTruck = {};
      for (const key in actualTripsForDate?.[fleetId] || {}) {
        for (const activity of actualTripsForDate[fleetId][key]) {
          for (const alert of activity) {
            allActivitiesForTruck[alert["alertUUID"]] = {
              ...alert,
            };
          }
        }
      }

      /**
       * This check is made because we need to know if the data for the
       * other days has been fetched or not. If the user open the vehicle
       * multiple times in the same session, we don't want to repeat fetches
       */
      const {
        minDate: minActivityDate = Infinity,
        maxDate: maxActivityDate = 0,
      } = loadLivePreference("vehicleDateBoundaries")?.[fleetId] || {};

      if (
        minAuditDate !== minActivityDate ||
        maxAuditDate !== maxActivityDate
      ) {
        getOtherDayActivities({
          minDate: minAuditDate,
          maxDate: maxAuditDate,
          fleetId,
        });
      }
    },
    [
      selectedVehicle,
      globalAudits,
      actualTripsForDate,
      auditsForDate,
      locations,
    ]
  );

  //#region REF HANDLES
  useImperativeHandle(
    ref,
    () => {
      return {
        changeSelectedVehicle() {
          setDayStats(null);
          allCancel();
        },
        changeInitialPlanMode() {
          changeInitialPlanHandler();
          changeActualMode();
          keepHighlightsOnPlanChange();
        },
        getSelectedVehicle() {
          return selectedVehicle;
        },
        onCancelChanges() {
          allCancel();
        },
        getAllAudits() {
          return allAuditsForVehicle;
        },
        renderCustomLocation(params) {
          if (mapViewRef.current) {
            mapViewRef.current.renderCustomLocation(params);
          }
        },
        clearCustomLocation() {
          if (mapViewRef.current) {
            mapViewRef.current.clearCustomLocation();
          }
        },
        onFiler(filterValues) {
          if (mapViewRef.current) {
            mapViewRef.current.onFilter(filterValues);
          }
        },
        onDrawNearFences(values) {
          if (mapViewRef.current) {
            mapViewRef.current.onDrawNearFences(values);
          }
        },
        onAddActualActivity(alert) {
          setCreatedAlerts((prev) => ({
            ...prev,
            [alert.alertUUID]: alert,
          }));

          setTrips((prev) => {
            const tmp = [...prev];
            tmp.push({
              alertCode: alert.alertCode,
              alertDateTime: alert?.["alertDateTime"],
              fenceName: alert?.fenceName,
              alertUUID: alert?.alertUUID,
              defaultAudited: false,
              activityId: undefined,
              isConflicting: undefined,
              isEnter: isEnteredAlert(alert?.alertCode)
                ? "enter-dot"
                : "exit-dot",
            });

            tmp.sort((a, b) => a?.alertDateTime - b?.alertDateTime);
            let validation = validateActualTrips(tmp);
            for (let i = 0; i < validation?.length; i++) {
              tmp[i]["isConflicting"] = validation[i]["isConflicting"];
            }

            return tmp;
          });

          onChangesMade();
        },
        onChangesSaved() {
          //#region SAVE CHANGES
          if (selectedVehicle) {
            let newAuditedActivities = [];
            let audited =
              globalAudits?.["alertChanges"]?.[selectedVehicle]?.[
                "auditedActivities"
              ] || [];

            if (updatedPlanRef?.current) {
              newAuditedActivities =
                updatedPlanRef?.current?.getActivitiesAudited();

              newAuditedActivities.forEach(({ id }) => {
                if (!audited.includes(id)) {
                  audited.push(id);
                }
              });
            }

            let auditObject = saveAuditChanges({
              fleetId: selectedVehicle,
              trips,
              actualActivities: actualTripsForDate?.[selectedVehicle],
              newAuditedActivities,
              linkedStops: linkedStopsData,
              discardedStops,
              auditsForTruck: {
                ...(globalAudits?.["alertChanges"]?.[selectedVehicle] || {}),
                createdAlerts: {
                  ...(globalAudits?.["alertChanges"]?.[selectedVehicle]?.[
                    "createdAlerts"
                  ] || {}),
                  ...createdAlerts,
                },
              },
            });

            auditObject[selectedVehicle]["createdAlerts"] = createdAlerts;
            auditObject[selectedVehicle]["timeChanges"] = timeChanges;
            auditObject[selectedVehicle]["auditedActivities"] = audited;
            auditObject[selectedVehicle]["auditStatus"] = auditStatus;
            auditObject[selectedVehicle]["stopReasons"] = selectedReasons.map(
              (e) => ({
                ...e,
                isDefault: true,
              })
            );

            changeGlobalAudits(
              auditObject,
              newAuditedActivities?.map(({ id }) => id)
            );
          }
        },
        onAuditRevert() {
          //#region AUDIT REVERT
          if (selectedVehicle) {
            const auditObject = {};

            auditObject["auditStatus"] = "PENDING";

            auditObject["changes"] = {};
            auditObject["timeChanges"] = {};
            auditObject["createdAlerts"] = {};
            auditObject["auditedActivities"] = [];
            auditObject["discarded"] = [];
            auditObject["discardedStops"] = [];
            auditObject["stopReasons"] = [];

            auditObject["afterHoursChanges"] =
              globalAudits?.["alertChanges"]?.[selectedVehicle]?.[
                "afterHoursChanges"
              ] || [];

            changeGlobalAudits(
              { [selectedVehicle]: auditObject },
              globalAudits?.["alertChanges"]?.[selectedVehicle]?.[
                "auditedActivities"
              ] || [],
              true,
              allCancel
            );
          }
        },
      };
    },
    [
      trips,
      timeChanges,
      auditStatus,
      globalAudits,
      createdAlerts,
      discardedStops,
      linkedStopsData,
      selectedReasons,
      selectedVehicle,
      actualTripsForDate,
      allAuditsForVehicle,
      plannedTripsForDate,
      updatedPlanRef?.current,
    ]
  );

  useEffect(() => {
    //#region AUDIT STATUS
    if (!selectedVehicle) {
      updateAuditStatus("");
      return;
    }

    let tmpNewStatus = "PENDING";
    let fleetId = selectedVehicle;
    let auditForVehicle = globalAudits?.["alertChanges"]?.[fleetId];

    if (!auditForVehicle) {
      tmpNewStatus = "PENDING";
    } else {
      if (auditForVehicle?.auditStatus === "UPLOADED") {
        tmpNewStatus = "UPLOADED";
      } else {
        let ids = [];
        for (const alertId in auditForVehicle?.["changed"]) {
          let id = auditForVehicle?.["changed"]?.[alertId]?.["activityId"];
          if (id) {
            if (!ids.includes(id)) {
              ids.push(id);
            }
          }
        }

        if (ids?.length && plannedTripsForDate?.[fleetId]?.length) {
          if (
            plannedTripsForDate[fleetId]?.every(({ activityId }) =>
              ids?.includes(activityId)
            )
          ) {
            tmpNewStatus = "COMPLETED";
          }
        }
      }
    }

    updateAuditStatus(tmpNewStatus);
  }, [globalAudits, selectedVehicle, plannedTripsForDate]);

  function calcDateBoundaries(newTrips) {
    //#region CALC BOUNDARIES
    let tripsDates = newTrips.map(({ alertDateTime }) =>
      dayjsNY(alertDateTime).startOf("D").valueOf()
    );
    let minDate = tripsDates?.length
      ? Math.min(...tripsDates)
      : loadLivePreference("liveSelectedDate");
    let maxDate = tripsDates?.length
      ? Math.max(...tripsDates)
      : loadLivePreference("liveSelectedDate");

    return { minDate, maxDate };
  }

  function changeInitialPlanHandler() {
    //#region CHANGE INITIAL PLACE
    if (initialPlanRef?.current) {
      initialPlanRef?.current?.changeMode();
    }
  }

  function changeActualMode() {
    //#region CHANGE MODE
    if (actualPlanRef?.current) {
      actualPlanRef?.current?.changeMode();
    }
  }

  function keepHighlightsOnPlanChange(param = {}) {
    //#region KEEP HIGHLIGHTS
    if (updatedPlanRef?.current) {
      updatedPlanRef?.current?.updateHighlights(param);
    }
  }

  function updateTripsHandler(newTrips, removeId) {
    //#region UPDATE TRIPS
    if (newTrips) {
      let validation = validateActualTrips(newTrips);
      for (let i = 0; i < validation?.length; i++) {
        newTrips[i]["isConflicting"] = validation[i]["isConflicting"];
      }
      setTrips(newTrips);
    }
    keepHighlightsOnPlanChange({ exclude: removeId });
  }

  function auditTripsHandler(tripIds) {
    //#region AUDIT TRIPS
    let tmpTrips = [...trips];
    for (const id of tripIds) {
      let i = tmpTrips?.findIndex(({ alertUUID }) => alertUUID === id);
      if (i > -1) {
        tmpTrips[i]["audited"] = !tmpTrips[i]["audited"];
      }
    }

    setTrips(tmpTrips);

    keepHighlightsOnPlanChange();
    onChangesMade();
  }

  /**
   * @param {Omit<StopReason, "isDefault">} config
   */
  function onReasonSelect(config) {
    //#region ON REASON SELECT
    const { reason } = config;

    if (!reason) {
      //we remove the reason if we passed an empty value
      let existingIndex = selectedReasons.findIndex(
        ({ beginDate, endDate }) =>
          beginDate === config.beginDate && endDate === config.endDate
      );
      if (existingIndex > -1) {
        let tmp = [...selectedReasons];
        tmp.splice(existingIndex, 1);
        setSelectedReasons(tmp);
      }
    } else {
      setSelectedReasons(
        selectedReasons.concat({ ...config, isDefault: false })
      );
    }
  }

  function onChangeActualTime(change) {
    //#region ON CHANGE ACTUAL TIME
    const changedIndex = trips?.findIndex(
      ({ alertUUID }) => alertUUID === change?.alertUUID
    );

    onChangesMade();

    if (changedIndex !== -1) {
      setTrips((prev) => {
        const tmp = [...prev];
        tmp[changedIndex] = {
          ...tmp[changedIndex],
          alertDateTime: change?.date,
        };

        tmp.sort((a, b) => a.alertDateTime - b.alertDateTime);

        return tmp;
      });
    }

    setTimeChanges((prev) => {
      return {
        ...(prev || {}),
        [change?.alertUUID]: {
          ...prev?.[change?.alertUUID],
          ...change,
          ...(prev?.[change?.alertUUID]
            ? {}
            : { originalDateTime: change.originalDateTime }),
        },
      };
    });
  }

  //#region JSX
  return (
    <div
      className={`audit-action-container ${
        isDarkMode ? "action-container-dark" : ""
      } ${!selectedVehicle ? "audit-full-height" : ""}`}
    >
      {selectedVehicle ? (
        viewMode === "OVERVIEW" ? (
          <Fragment>
            <InitialPlanCard ref={initialPlanRef} />
            <ActualPlanCard
              trips={trips}
              dayStats={dayStats}
              ref={actualPlanRef}
              timeChanges={timeChanges}
              createdAlerts={createdAlerts}
              onReasonSelect={onReasonSelect}
              discardedStops={discardedStops}
              dateBoundaries={dateBoundaries}
              selectedReasons={selectedReasons}
              linkedStopsData={linkedStopsData}
              checkForOtherDay={checkForOtherDay}
              setDiscardedStops={setDiscardedStops}
              setDateBoundaries={setDateBoundaries}
              onChangeActualTime={onChangeActualTime}
              updateTripsHandler={updateTripsHandler}
              setLinkedStopsData={setLinkedStopsData}
              allAuditsForVehicle={allAuditsForVehicle}
            />
            <UpdatedPlanCard
              trips={trips}
              ref={updatedPlanRef}
              auditTripsHandler={auditTripsHandler}
            />
          </Fragment>
        ) : (
          <AuditMapView
            trips={trips}
            ref={mapViewRef}
            dayStats={dayStats}
            timeChanges={timeChanges}
            setDayStats={setDayStats}
            createdAlerts={createdAlerts}
            actualPlanRef={actualPlanRef}
            onReasonSelect={onReasonSelect}
            dateBoundaries={dateBoundaries}
            discardedStops={discardedStops}
            linkedStopsData={linkedStopsData}
            selectedReasons={selectedReasons}
            checkForOtherDay={checkForOtherDay}
            setDiscardedStops={setDiscardedStops}
            setDateBoundaries={setDateBoundaries}
            onChangeActualTime={onChangeActualTime}
            setLinkedStopsData={setLinkedStopsData}
            updateTripsHandler={updateTripsHandler}
            allAuditsForVehicle={allAuditsForVehicle}
          />
        )
      ) : (
        <div className="audit-no-vehicle-selected">
          <div className="icon-no-selection">
            <AuditIcon fill="black" />
          </div>
          <span className="text-no-selection">
            No vehicle has been selected for audit
          </span>
        </div>
      )}
    </div>
  );
});

export default AuditActions;

/**
 * @param {string} str
 * @returns {string}
 */
function camelToTitle(str) {
  let res = str
    .replace(/(_)+/g, " ")
    .replace(/([a-z])([A-Z][a-z])/g, "$1 $2")
    .replace(/([A-Z][a-z])([A-Z])/g, "$1 $2")
    .replace(/([a-z])([A-Z]+[a-z])/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-z][a-z])/g, "$1 $2")
    .replace(/([a-z]+)([A-Z0-9]+)/g, "$1 $2")
    .replace(/([A-Z]+)([A-Z][a-rt-z][a-z]*)/g, "$1 $2")
    .replace(/([0-9])([A-Z][a-z]+)/g, "$1 $2")
    .replace(/([A-Z]{2,})([0-9]{2,})/g, "$1 $2")
    .replace(/([0-9]{2,})([A-Z]{2,})/g, "$1 $2")
    .trim();

  res = res.trim();
  res = res.charAt(0).toUpperCase() + res.slice(1);
  return res;
}

export default camelToTitle;

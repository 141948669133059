import React, { useEffect, useRef, useState } from "react";
import { MondayButton } from "../../../../../../commonComponents";
// import { Editor } from "@tinymce/tinymce-react";
import { NormalSizedModal } from "../../../../../Fleet/components";
import { processWordHtml } from "./process/process";
import { exportDOCX } from "../../../tools/exporters";
import { DownloadIcon, NewIcon } from "../../../../../BasePage/src";
import { useEstVersionsContext } from "../../../../components/EstVersionsTab/context";
import {
  SaveFilled,
  SortAscendingOutlined,
  WindowsFilled,
} from "@ant-design/icons";
import Swal from "sweetalert2";
import moment from "moment";
import { Alert } from "antd";
import { CircleLockIcon } from "../../../../../../pages/Settings/settingsComponents/AllUsers/assets";
import { CloseIconModal, WordIconWhite } from "../../../../../../../assets";
import { useSelector } from "react-redux";
import DraggableSort from "./DraggableSort/DraggableSort";
import CustomReorder from "./CustomReorder/CustomReorder";
import { EyeIcon, SwitchEyeIcon } from "../../../../../DynamicView/src";
import JoditEditor from "jodit-react";
import { Editor } from "@tinymce/tinymce-react";

function WordDocxFactory({
  gridData,
  projectAddress,
  serviceDefs,
  accountName,
  versionId,
  wordRef,
}) {
  const editorRef = useRef(null);
  const [editorData, setEditorData] = useState("");
  const [open, setOpen] = useState(false);
  const [newWindow, setNewWindow] = useState(false);

  const newWindowHandler = (value) => {
    setNewWindow(value);
  };

  return (
    <div>
      <div ref={wordRef}>
        <MondayButton
          hasIcon={false}
          // Icon={<WordIconWhite />}
          onClick={() => {
            setOpen(true);
            setNewWindow(false);
          }}
          tooltipCategory="Take Off"
          tooltipKey="wordDocBuilder"
          className="mondayButtonBlue"
        >
          <WordIconWhite />
        </MondayButton>
      </div>

      <TakeOffWordEditor
        {...{
          editorRef,
          open,
          setOpen,
          gridData,
          projectAddress,
          serviceDefs,
          accountName,
          versionId,
          setNewWindow: newWindowHandler,
          newWindow,
        }}
      />
    </div>
  );
}

export default WordDocxFactory;

const TakeOffWordEditor = ({
  editorRef,
  open,
  setOpen,
  setEditorData,
  gridData,
  projectAddress,
  serviceDefs,
  versionId,
  accountName,
  setNewWindow,
  newWindow,
}) => {
  const [editorData, setData] = useState("");
  const { estimation, updateEstimation } = useEstVersionsContext();
  const { nameOfUser = "" } = useSelector(
    (state) => state?.userConfig?.userConfiguration
  );
  const editoRef2 = useRef(null);
  const exportAsWord = () => {
    console.log("exporting as word", {
      projectAddress,
    });
    let temp = editorRef?.current?.value;
    exportDOCX({
      customFileName:
        projectAddress?.replace(/\W+/g, "-").toLowerCase() ?? "Document",
      html: temp,
    });
  };

  const wordDraft = estimation?.versionServices?.[versionId]?.wordDraft;
  const customOrder = estimation?.versionServices?.[versionId]?.customWordOrder;
  const windowRef = useRef(null);
  const [showIds, setShowIds] = useState(true);

  const onRegenerate = () => {
    setNewWindow(false);
    setData(
      processWordHtml({
        gridData,
        projectAddress,
        serviceDefs,
        accountName,
        estimation,
        versionId,
        showIds,
      })
    );
    Swal.fire(
      "Successfully Regenerated",
      "The current custom view has been regenerated!",
      "success"
    );
  };

  const onClose = () => {
    setOpen(false);
    // setEditorData("");
    // setData("");
  };

  const onSaveCurrentHtmlData = () => {
    Swal.fire(
      "Successfully Saved",
      "The current custom view has been saved!",
      "success"
    );

    if (!!estimation?.versionServices?.[versionId]) {
      let myData = estimation?.versionServices?.[versionId];
      let wordData = editorData;
      myData.wordDraft = {
        htmlData: wordData,
        createdAt: moment().valueOf(),
        createdBy: nameOfUser,
      };
      updateEstimation(
        {
          versionServices: {
            ...estimation?.versionServices,
            [versionId]: myData,
          },
        },
        {
          action: "saveWordData",
          versionName: myData?.versionName,
          versionId: versionId,
          actionType: "Edit",
        }
      );
    }
    console.log("currentHtmlData", {
      data: estimation?.versionServices?.[versionId],
      versionId,
    });
  };

  const newWindowExportWord = (html) => {
    exportDOCX({
      customFileName:
        projectAddress?.replace(/\W+/g, "-").toLowerCase() ?? "Document",
      html,
    });
  };

  useEffect(() => {
    let temp =
      estimation?.versionServices?.[versionId]?.wordDraft?.htmlData ??
      processWordHtml({
        gridData,
        projectAddress,
        serviceDefs,
        accountName,
        estimation,
        versionId,
        showIds,
      });
    setData(temp);
  }, [gridData, projectAddress, serviceDefs, accountName, estimation, showIds]);

  const onNewWindow = () => {
    // If the window is not open or has been closed, open a new one
    if (!windowRef.current || windowRef.current.closed) {
      setNewWindow(true);
      windowRef.current = window.open("", "_blank");
      const button = windowRef.current.document.createElement("button");
      button.innerHTML = "Export as Word";
      button.style.padding = "10px";
      button.style.backgroundColor = "#1264A3";
      button.style.color = "#ffffff";
      button.style.border = "none";
      button.style.borderRadius = "5px";
      button.style.cursor = "pointer";
      let temp = editorRef?.current?.currentContent;

      button.addEventListener("click", () => newWindowExportWord(temp));

      // Add the button to the body
      windowRef.current.document.body.appendChild(button);
      const myDiv = windowRef.current.document.createElement("div");
      myDiv.innerHTML = editorRef?.current?.currentContent;
      windowRef.current.document.body.appendChild(myDiv);
      // editorRef?.current?.currentContent;
    }

    // Set the content when triggering the new window
    // setContent(editorRef?.current?.currentContent);
  };

  useEffect(() => {
    // When content changes, update the existing window if it's open
    let tempData =
      estimation?.versionServices?.[versionId]?.wordDraft?.htmlData ??
      processWordHtml({
        gridData,
        projectAddress,
        serviceDefs,
        accountName,
        estimation,
        versionId,
        showIds,
      });
    setNewWindow(true);

    let temp = editoRef2?.current?.currentContent;

    if (
      editorData &&
      windowRef.current &&
      !windowRef.current.closed &&
      newWindow
    ) {
      setNewWindow(true);
      windowRef.current.document.body.innerHTML = "";
      const button = windowRef.current.document.createElement("button");
      button.innerHTML = "Export as Word";
      button.style.padding = "5px";
      button.style.backgroundColor = "#1264A3";
      button.style.color = "#ffffff";
      button.style.border = "none";
      button.style.borderRadius = "5px";
      button.style.cursor = "pointer";
      button.addEventListener("click", () => newWindowExportWord(temp));

      windowRef.current.document.body.appendChild(button);
      const myDiv = windowRef.current.document.createElement("div");
      myDiv.innerHTML = editoRef2?.current?.currentContent || "Loading...";
      windowRef.current.document.body.appendChild(myDiv);
    }
  }, [editorData, editoRef2?.current?.currentContent, gridData, showIds]);

  return (
    <>
      <NormalSizedModal
        centered={true}
        visible={open}
        maskClosable={false}
        closable={true}
        // closeIcon={<CloseIconModal />}
        title="Word Docx Builder"
        onCancel={onClose}
      >
        <div
          style={{
            height: "90vh",
            display: "flex",
            padding: "0 20px 10px 20px",
            flexDirection: "column",
            top: "0px",
          }}
        >
          <div
            style={{
              display: "flex",
              width: "100%",
              padding: "0 20px 10px 20px",
              justifyContent: "flex-end",
              gap: 10,
              // margin: "20px",
            }}
          >
            {" "}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<EyeIcon />}
              onClick={() => setShowIds(!showIds)}
            >
              {showIds ? "Hide" : "Show"} ID's
            </MondayButton>{" "}
            <CustomReorder versionId={versionId} />{" "}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<NewIcon />}
              onClick={onNewWindow}
            >
              New Window
            </MondayButton>{" "}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<DownloadIcon />}
              onClick={exportAsWord}
            >
              Export As Word
            </MondayButton>{" "}
            <MondayButton
              className="mondayButtonBlue"
              Icon={<CircleLockIcon />}
              onClick={onRegenerate}
            >
              Regenerate
            </MondayButton>
            <MondayButton
              className="mondayButtonGreen"
              Icon={<SaveFilled />}
              onClick={onSaveCurrentHtmlData}
            >
              Save Draft
            </MondayButton>
          </div>
          {!!wordDraft && (
            <Alert
              message={`For this take off, there is a saved Draft for Word - [Created by ${
                wordDraft?.createdBy
              } on ${moment(wordDraft?.createdAt).format(
                "DD/MM/YYYY hh:mm:ss"
              )}]`}
              showIcon
              type="success"
            />
          )}
          <br />{" "}
          {!!customOrder && (
            <>
              {" "}
              <Alert
                message={`For this take off, the arrangement of phases is customised, please double check your choices! `}
                showIcon
                type="warning"
              />
              <br />{" "}
            </>
          )}
          <JoditEditor
            ref={editorRef}
            value={editorData}
            onBlur={(newContent) => setData(newContent)}
            config={{
              // Jodit Editor configuration options
              readonly: false, // Ensure it's set to false if you want to insert content
              toolbarButtonSize: "large",
              height: 600,
              allowResizeY: false,
              allowResizeX: false,

              // Other configurations as needed
            }}
          />
          {/* <Editor
            ref={editorRef}
            apiKey="YOUR_API_KEY" // Replace with your TinyMCE API key
            // initialValue={initialValue}
            //   value={editorData}
            value={editorData}
            init={{
              // height: 500,
              height: "100%",
              menubar: true,
              content_style: `
          .page-break {
            page-break-before: always;
          }
        `,
              plugins: [
                "advlist autolink lists link image charmap print preview anchor",
                "searchreplace visualblocks code fullscreen",
                "insertdatetime media table paste code help wordcount",
              ],
              font_formats:
                "Calibri, Andale Mono=andale mono,times; Arial=arial,helvetica,sans-serif; Arial Black=arial black,avant garde; Book Antiqua=book antiqua,palatino; Comic Sans MS=comic sans ms,sans-serif; Courier New=courier new,courier; Georgia=georgia,palatino; Helvetica=helvetica; Impact=impact,chicago; Oswald=oswald; Symbol=symbol; Tahoma=tahoma,arial,helvetica,sans-serif; Terminal=terminal,monaco; Times New Roman=times new roman,times; Trebuchet MS=trebuchet ms,geneva; Verdana=verdana,geneva; Webdings=webdings; Wingdings=wingdings,zapf dingbats",
              toolbar:
                "undo redo | formatselect | fontsizeselect bold italic backcolor forecolor | \
        alignleft aligncenter alignright alignjustify | \
        bullist numlist outdent indent | removeformat | help",
              toolbar_location: "external", // Display the toolbar outside the editor
              pagebreak_separator: "<!-- Page Break -->",
              pagebreak_replace:
                '<div style="page-break-after: always;"></div>',
              pagebreak_html:
                '<header style="text-align: center;">My Custom Header</header>' +
                '<footer style="text-align: center;">Page <span class="mce-page-number"></span> of <span class="mce-total-pages"></span></footer>',
              content_css: "./ProposalTinyEditor.scss",
            }}
            onEditorChange={(content, editor) => {
              // setData(content);
            }}
          /> */}
        </div>
      </NormalSizedModal>

      {newWindow && (
        <div
          style={{
            display: "none",
            // width: "1px",
            // height: "1px",
          }}
        >
          <JoditEditor
            ref={editoRef2}
            value={editorData}
            // onBlur={(newContent) => setData(newContent)}
            config={{
              // Jodit Editor configuration options
              readonly: false, // Ensure it's set to false if you want to insert content
              toolbarButtonSize: "large",
              height: 600,
              allowResizeY: false,
              allowResizeX: false,

              // Other configurations as needed
            }}
          />
        </div>
      )}
    </>
  );
};

import { useState } from "react";
import { ChartIcon } from "../SidebarPages/BasePage/src";
import { MondayButton } from "../commonComponents";
import ChartComponent from "../SidebarPages/components/AgChart/ChartComponent";
import { dayjsNY } from "../DateComponents/contants/DayjsNY";
import ChartModal from "./ChartModal/ChartModal";

const ChartButton = ({
  getChartingOptions,
  handleChartButtonClick,
  data,
  getUpdatedChartData = false,
}) => {
  const [chartModalIsVisible, setChartModalIsVisible] = useState(false);
  const [chartOptions, setChartOptions] = useState({});
  const [chartVisible, setChartVisible] = useState(false);
  const [chartArr, setChartArr] = useState([]);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState();

  const currentDate = dayjsNY();

  const openChartModal = (chartData) => {
    const modifiedData = !!getUpdatedChartData
      ? getUpdatedChartData(chartData)
      : chartData;
    const chartOptions = getChartingOptions(!chartData ? data : modifiedData);

    setChartArr(chartOptions);
    setStartDate(currentDate.subtract(10, "year").format("YYYY-MM-DD"));
    setEndDate(currentDate.endOf("year").format("YYYY-MM-DD"));
    setChartModalIsVisible(true);
  };

  const closeChartModal = () => {
    setChartModalIsVisible(false);
  };

  const drawChart = (params) => {
    setChartOptions(params);
    setChartVisible(true);
    setChartModalIsVisible(false);
  };

  const handleChartDateChange = (start, end) => {
    setStartDate(start);
    setEndDate(end);
  };

  const handleChartButtonClicks = async () => {
    if (!!handleChartButtonClick) {
      const result = await handleChartButtonClick();
      openChartModal(result);
    } else {
      openChartModal();
    }
  };

  return (
    <>
      <MondayButton
        onClick={handleChartButtonClicks}
        Icon={<ChartIcon />}
        hasIcon={false}
        className="mondayButtonBlue"
        tooltipKey="showCharts"
      />
      {chartModalIsVisible && (
        <ChartModal
          {...{
            open: chartModalIsVisible,
            onCancel: closeChartModal,
            data,
            onDateChange: handleChartDateChange,
            chartingOptions: chartArr,
            startDate,
            endDate,
            onSubmit: drawChart,
          }}
        />
      )}
      {chartVisible && (
        <ChartComponent
          open={chartVisible}
          onCancel={() => setChartVisible(false)}
          options={chartOptions}
        />
      )}
    </>
  );
};

export default ChartButton;

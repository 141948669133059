import { message } from "antd";
import { getCognitosForNotification } from "../../../../../utils";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { getChangedData } from "../../../Accounting/components/utilities";
import { API } from "aws-amplify";

/**
 * Edits the safety information.
 *
 * @param {Object} options.tmpBody - The temporary body object.
 * @param {string} options.nameKey - The name key.
 * @param {string} options.category - The category.
 * @param {Object} options.rowObject - The row object.
 * @param {string} options.editAction - The edit action.
 * @param {Function} options.onClose - The function to close the modal.
 * @param {Object} options.authenticatedUser - The authenticated user.
 * @param {Object} options.userConfiguration - The user configuration.
 * @param {Function} options.customLogObj - The function to generate custom log object.
 * @param {Function} options.refreshTable - The function to refresh the table.
 * @param {Function} options.saveAddedLogs - The function to save added logs.
 * @param {Function} options.updateProgressStatus - The function to update progress status.
 * @returns {Promise<void>} - A promise that resolves when the safety information is edited successfully.
 */
async function editSafety({
  tmpBody = {},
  nameKey = "",
  category = "",
  rowObject = {},
  editAction = "",
  onClose = () => {},
  authenticatedUser = {},
  userConfiguration = {},
  customLogObj = () => {},
  refreshTable = () => {},
  saveAddedLogs = async () => {},
  updateProgressStatus = () => {},
}) {
  updateProgressStatus({ updatingRecord: "executing" });

  await API.put("safety", `/safety/${rowObject?.safetyApplicationId}`, {
    body: {
      ...tmpBody,
      lastModifiedBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    ?.then(async () => {
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      let { safetyApplicationObject: prevObj } = rowObject;
      let { safetyApplicationObject: currObj } = tmpBody;

      const newAssignedToIds = currObj?.assignedTo
        ?.filter(({ cognitoUserId: id }) => {
          return !prevObj?.assignedTo?.some(
            ({ cognitoUserId }) => cognitoUserId === id
          );
        })
        ?.map(({ cognitoUserId }) => cognitoUserId)
        ?.filter(Boolean);

      let tmpPrev = {
        ...prevObj,
        ...customLogObj(prevObj),
      };

      let tmpCurr = {
        ...currObj,
        ...customLogObj(currObj),
      };

      refreshTable({ ...rowObject, ...tmpBody }, "Edit");

      let changes = getChangedData(tmpPrev, tmpCurr);

      if (!!changes) {
        saveAddedLogs({
          recordId: rowObject?.safetyApplicationId,
          recordName: rowObject?.[nameKey],
          category,
          actionType: "Edit",
          topic: rowObject?.[nameKey],
          currentData: changes?.curr || {},
          previousData: changes?.prev || {},
          updatedKeys: ["See Details"],
        });
      }

      newAssignedToIds?.length > 0 &&
        broadcastNotification(
          "42",
          editAction,
          [
            {
              common: userConfiguration?.nameOfUser,
              category,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: newAssignedToIds || [],
            },
          ],
          rowObject?.safetyApplicationId
        );

      // await broadcastNotification(
      broadcastNotification(
        "42",
        editAction,
        [
          {
            common: userConfiguration?.nameOfUser,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: authenticatedUser?.sub,
            cognitos: getCognitosForNotification(
              userConfiguration,
              rowObject?.teamsConfiguration,
              "safety"
            ),
          },
        ],
        rowObject?.safetyApplicationId
      ).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });

      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
      console.error("Error editing safety", err);
    });
  onClose();
}

export default editSafety;

import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";
import "./CircleProgressBar.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { Progress, Result, Tooltip } from "antd";
import { GraphTooltipContent } from "../BarChart/GraphTooltipContent";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";

const CircleProgresBar = ({ params = {}, filterValue }) => {
  const {
    color,
    percent,
    showInfo,
    status,
    data,
    table,
    field,
    content,
    isChartDataLoading,
  } = params;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const history = useFilteredHistory(table, {
    [field === "leadAddress" ? "borough" : field]: [content],
  });
  const [displayData, setDisplayData] = useState({
    percent: percent,
    loading: true,
  });

  useEffect(() => {
    if (isChartDataLoading) return;

    const updateDisplay = () => {
      const displayPercent = percent === "NaN" ? "0.0" : percent;
      setDisplayData({ percent: displayPercent, loading: false });
    };

    if (filterValue !== "This Month") {
      updateDisplay();
    } else {
      const timeout = setTimeout(updateDisplay, 3000);
      return () => clearTimeout(timeout);
    }
  }, [params?.percent, filterValue, isChartDataLoading]);

  return (
    <div className="CircleProgressBarContent">
      {!params?.noFunction ? (
        <SmallLoadableComp loading={displayData?.loading}>
          <Tooltip
            overlayClassName={"dashToolTipStyleCustom"}
            placement="top"
            title={
              !!params?.custom && (
                <GraphTooltipContent
                  {...{
                    tooltipData: params?.custom?.[0],
                    title: `${status}: ${displayData?.percent}%`,
                    label: table,
                  }}
                />
              )
            }
          >
            <div
              className={`CircleProgressBarBodyStyle ${
                isDarkMode && "CircleProgressBarBodyStyleDark"
              }`}
            >
              <Progress
                className="CircleProgressComponent"
                type="dashboard"
                percent={displayData?.percent || 0}
                showInfo={showInfo}
                strokeColor={color}
                gapDegree={140}
                size={250}
                strokeWidth={11}
                onClick={() => history()}
              />
              <div className="CircleProgressBarStatus">{status}</div>
            </div>
          </Tooltip>
        </SmallLoadableComp>
      ) : (
        <Result
          status="warning"
          title={`There are no matching pattern for this chart `}
          subTitle={`Try removing and recreating this chart. If this reoccurs, please contact the Software Department.`}
        />
      )}
    </div>
  );
};
export default CircleProgresBar;

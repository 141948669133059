import moment from "moment";
import { fetchData } from "../../components/SidebarPages/Fleet/utils";

export const groupAndInspectionsByActivityId = async () => {
  try {
    const inspections = await fetchData("fleetInspections");

    inspections.sort((a, b) =>
      moment(b.createdAt).isBefore(a.createdAt) ? -1 : 1
    );
    // Group the objects by activityId
    let groups = {};
    inspections.forEach((inspection) => {
      let activityId = inspection.activityId;
      let driverName = inspection?.driverInfo?.driver || "";
      let inspectionDate = inspection.inspectionDate;

      if (!groups[activityId]) {
        groups[activityId] = {
          activityId: activityId,
          driverName: driverName,
          inspectionDate,
          inspections: [],
          type: "",
          completion: 0,
        };
      }
      groups[activityId].inspections.push(inspection);

      // Check the length of the inspections array and set the type and completion
      const len = groups[activityId].inspections.length;
      if (len === 0) {
        groups[activityId].type = "Neither";
      } else if (len === 1) {
        groups[activityId].type = "Either";
        groups[activityId].completion = 50;
      } else if (len > 1) {
        groups[activityId].type = "Both";
        groups[activityId].completion = 100;
      }
    });

    // Convert object to array
    //   return groupAndCountByDriverNameAndDate(Object.values(groups));
    return Object.values(groups);
  } catch (error) {
    console.log(
      "data source function groupAndInspectionsByActivityId error: ",
      error
    );
  }
};

import { validateForm } from "../Fleet/utils";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import utc from "dayjs/plugin/utc";
import timezone from "dayjs/plugin/timezone";
import { filterSchedulesOnDate } from "./SafetyModal/utils/filterSchedulesOnDate";
import { getNewLabelHTML } from "../Fleet/Dispatch/modals/NewDispatchModal/utils/routeFunctions";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import broadcastNotification from "../../../helpers/controllers/broadcastNotification";
import { API } from "aws-amplify";
import {
  deleteNotifications,
  getCognitosForNotification,
} from "../../../utils/notificationHelpers";
import { message } from "antd";
import { getChangedData } from "../Accounting/components/utilities";
import { filterTables, updateTeamsConfiguration } from "../../../utils";
import { getGridProjectInfo } from "./SafetyBasePage/getGridProjectInfo";
import createNewTask from "../Incidents/Modals/utils/createNewTask";
import {
  docDefinition,
  getRepliesRecursively,
  imageForPdf,
  mapImages,
  notePdfBody,
  savePdfToDrive,
} from "./safetyPdf";
import { compareIncluding } from "../utils";
import createPDF from "../../../integrations/createPDF";
import { driveApi } from "../../../integrations/DriveRequest";
import { companyDetails } from "../../../helpers/constants";
dayjs.extend(customParseFormat);
dayjs.extend(utc);
dayjs.extend(timezone);

const customOptionsData = {
  foremanOfCrewThatCausedIncident: {
    key: "crewId",
    label: "crewName",
    table: "crews",
  },

  crewMembersThatCausedDamage: {
    key: "crewId",
    label: "crewName",
    table: "crews",
    mode: "multiple",
  },

  fullNameOfForeman: {
    key: "crewId",
    label: "crewName",
    table: "crews",
  },

  nameOfDriver: {
    key: "driverId",
    label: "driverName",
    table: "drivers",
  },

  foremanOfCrewThatCausedTheDamage: {
    key: "crewId",
    label: "crewName",
    table: "crews",
  },
};

export function checkSaveDraft({
  changes = false,
  populatedInputFields,
  // descriptionValues,
  // requiredTextArea,
  form,
  saveFunction,
  setVisible,
  setCancelModalVisible,
  incidentId,
}) {
  if (!!changes || !!incidentId) {
    let fields = populatedInputFields
      ?.filter((el) => el?.required)
      ?.map((el) => el?.formItemName);

    let fieldValues = form.getFieldsValue(fields);
    // let findRequiredArea = [];
    // Object.keys(descriptionValues)?.filter((key) => {
    //   if (fieldValues?.hasOwnProperty(key)) {
    //     delete fieldValues[key];
    //   }
    //   if (requiredTextArea.includes(key) && !descriptionValues[key]) {
    //     findRequiredArea.push(key);
    //   }
    // });
    if (
      Object.values(fieldValues)?.some((value) => !value)
      // ||
      // !!findRequiredArea?.length
    ) {
      setCancelModalVisible("saveDraft");
    } else {
      validateForm(form, saveFunction);
    }
  } else {
    setVisible(false);
  }
}

export function checkDateInput({ fields, formItemName }) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return dynamicField?.type?.toLowerCase() === "dateinput";
  }
  return false;
}

export function checkTimeInput({ fields, formItemName }) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return dynamicField?.type?.toLowerCase() === "timeinput";
  }
  return false;
}

export function checkPicker(fields, formItemName) {
  let dynamicField = fields?.find(
    ({ formItemName: fN }) => fN === formItemName
  );
  if (dynamicField) {
    return (
      dynamicField?.type?.toLowerCase() === "datepicker" ||
      dynamicField?.type?.toLowerCase() === "timepicker"
    );
  }
  return false;
}

/**
 *
 * @param {String} date date or time value from formField,
 * @param {String} format format of the  input
 * @param {String} type to check if it is timeInput
 * @returns
 */
export function dateToSave(date, format, type) {
  if (type === "time") {
    let today = dayjs().format("MM/DD/YYYY");
    return dayjs
      .tz(`${today} ${date}`, `MM/DD/YYYY ${format}`, "America/New_York")
      .format();
  }
  return dayjs.tz(date, format, "America/New_York").format();
}

const keyData = {
  safety: {
    categoryKey: "safetyApplicationCategory",
    objectKey: "safetyApplicationObject",
    idKey: "safetyApplicationId",
    taskKey: "Safety",
    recordName: "safetyName",
    statusKey: "safetyApplicationStatus",
    defaultStatus: "Draft",
  },
  incidents: {
    categoryKey: "incidentCategory",
    objectKey: "incidentObject",
    idKey: "incidentId",
    taskKey: "Incidents",
    statusKey: "incidentStatus",
    recordName: "incidentName",
    defaultStatus: "Draft",
  },
};

export function changeDateOfIncident({
  form,
  date = null,
  schedules = [],
  addressField = "",
  addressSelectionInfo = {},
  setSchedulesForDate = () => {},
  setAddressSelectionInfo = () => {},
}) {
  const selectedSchedule = addressSelectionInfo?.scheduleId || null;

  const res = filterSchedulesOnDate(
    schedules,
    dayjs(date, "MM/DD/YYYY"),
    selectedSchedule
  );

  setSchedulesForDate(res?.schedulesForDay);

  if (res?.isCleared) {
    form.setFieldValue(addressField, "");
    setAddressSelectionInfo({
      projectId: "",
      scheduleId: "",
      accountId: "",
    });
  }
}

export function onChangeAddress({
  val,
  form,
  option,
  projects = [],
  schedulesForDate = [],
  setAddressSelectionInfo = () => {},
}) {
  let selectedAddress = val?.trim();
  let type = option?.type || "google";

  if (type === "schedule") {
    let selectedSchedule = schedulesForDate?.find(
      (el) => el?.scheduleAddress === selectedAddress
    );

    if (!!selectedSchedule) {
      setAddressSelectionInfo({
        scheduleId: selectedSchedule?.scheduleId,
        projectId: selectedSchedule?.projectId,
        accountId: "",
      });
    }
  } else if (type === "project") {
    let selectedProject = projects?.find(
      (el) => el?.projectName === selectedAddress
    );
    form.setFieldValue(
      "projectExecutive",
      selectedProject?.projectExecutive || ""
    );
    if (!!selectedProject) {
      setAddressSelectionInfo({
        projectId: selectedProject?.projectId,
        accountId: selectedProject?.accountId,
        scheduleId: "",
      });
    }
  } else {
    setAddressSelectionInfo({
      projectId: "",
      scheduleId: "",
      accountId: "",
    });
  }
}

export function populateSafetyForm({
  form,
  pdfId = "",
  statusKey = "",
  rowObject = {},
  fieldsObject = "",
  dynamicFields = [],
  setSign = () => {},
  statusesColors = {},
  setPdfId = () => {},
  setAssignedTo = () => {},
  setCurrentStep = () => {},
  setSelectedTeam = () => {},
  setWitnessCount = () => {},
  setUploadedFiles = () => {},
  setWitnessSignings = () => {},
  data = {},
  // setWitnessStatements = () => {},
  // setDescriptionValues = () => {},
}) {
  let toReturn = {};
  if (rowObject?.teamsConfiguration?.length > 0) {
    setSelectedTeam(
      rowObject?.teamsConfiguration?.map((team) => ({
        value: team?.value,
        members: team?.members,
      }))
    );
  }
  if (!pdfId) {
    setPdfId(rowObject?.[fieldsObject]?.gDrivePdfId || "");
  }

  setTimeout(() => {
    toReturn = structuredClone(rowObject?.[fieldsObject]);
    let witnessNo = 0;
    let rg = /witnessName-*[0-9]$/;

    Object.keys(toReturn || {}).forEach((key) => {
      if (!!checkPicker(dynamicFields, key)) {
        if (rowObject?.[fieldsObject]?.[key]) {
          toReturn[key] = dayjsNY(rowObject?.[fieldsObject]?.[key]);
        } else {
          delete toReturn?.[key];
        }
      }
      if (checkDateInput({ fields: dynamicFields, formItemName: key })) {
        if (!!rowObject?.[fieldsObject]?.[key]) {
          toReturn[key] = dayjsNY(rowObject?.[fieldsObject]?.[key]).format(
            "MM/DD/YYYY"
          );
          toReturn[`datePicker${key}`] = dayjsNY(
            rowObject?.[fieldsObject]?.[key]
          );
        } else {
          delete toReturn?.[key];
        }
      }
      if (checkTimeInput({ fields: dynamicFields, formItemName: key })) {
        if (!!rowObject?.[fieldsObject]?.[key]) {
          toReturn[key] = dayjsNY(rowObject?.[fieldsObject]?.[key]).format(
            "hh:mm A"
          );
        } else {
          delete toReturn?.[key];
        }
      }

      if (
        dynamicFields?.some((el) => el?.formItemName === key) &&
        customOptionsData?.[key]
      ) {
        const { table, key: tableKey, label } = customOptionsData[key];

        if (customOptionsData?.[key]?.mode === "multiple") {
          if (Array.isArray(rowObject?.[fieldsObject]?.[key])) {
            toReturn[key] = rowObject?.[fieldsObject]?.[key]?.map(
              ({ value, id }) => {
                const found = data?.[table]?.some(
                  (el) => el?.[tableKey] === id
                );

                if (found) {
                  return id;
                } else {
                  return value;
                }
              }
            );
          }
        } else if (
          toReturn?.[key]?.hasOwnProperty("value") &&
          toReturn?.[key]?.hasOwnProperty("id")
        ) {
          const found = (data?.[table] || [])?.some(
            (el) => el?.[tableKey] === toReturn?.[key]?.id
          );

          if (!found) {
            toReturn[key] = !!toReturn?.[key]?.value
              ? toReturn?.[key]?.value
              : null;
          } else {
            toReturn[key] = toReturn?.[key]?.id;
          }
        }
      }

      if (rg.test(key)) {
        witnessNo = witnessNo + 1;
      }
    });

    let tStatements = [];
    let tSignatures = [];

    for (let i = 0; i < witnessNo; i++) {
      // tStatements.push(toReturn?.[`witnessStatement-${i}`]);
      tSignatures.push(toReturn?.[`witnessSign-${i}`]);
    }

    setWitnessCount(witnessNo || 1);
    // setWitnessStatements(tStatements);

    setWitnessSignings(tSignatures);
    setSign(toReturn?.signature);

    // let tmpDescriptions = {};

    // Object.keys(toReturn || {}).forEach((key) => {
    //   tmpDescriptions[key] = toReturn[key];
    // });

    let stepIndex = statusesColors?.findIndex(
      ({ statusName }) => statusName === rowObject?.[statusKey]
    );

    setCurrentStep(stepIndex);

    form.setFieldValue("stepper", stepIndex);
    // setDescriptionValues(tmpDescriptions);
    setAssignedTo(toReturn?.assignedTo);

    form.setFieldsValue(toReturn);

    if (!Array.isArray(rowObject?.googleDriveUpload)) {
      setUploadedFiles([]);
    } else {
      setUploadedFiles(rowObject?.googleDriveUpload);
    }
  });
}

export async function sendSafetyCreateNotifs({
  table = "",
  userConfiguration,
  authenticatedUser,
  res,
  createAction = "",
  category,
  tmpBody = {},
}) {
  broadcastNotification(
    table === "safety" ? "42" : "43",
    table === "safety" ? "onSafetyAssignation" : "onIncidentAssignation",
    [
      {
        common: userConfiguration?.nameOfUser,
        category,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: userConfiguration?.cognitoUserId,
        cognitos:
          res.safetyApplicationObject?.assignedTo
            ?.map(({ cognitoUserId }) => cognitoUserId)
            ?.filter((el) => el !== userConfiguration?.cognitoUserId) || [],
      },
    ],
    res.safetyApplicationId
  );
  // return await broadcastNotification(
  return broadcastNotification(
    table === "safety" ? "42" : "43",
    createAction,
    [
      { common: userConfiguration?.nameOfUser },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: userConfiguration?.cognitoUserId,
        cognitos: getCognitosForNotification(
          userConfiguration,
          tmpBody?.teamsConfiguration
        ),
      },
    ],
    res?.safetyApplicationId
  );
}

export async function deleteSafety({
  dispatch,
  nameKey = "",
  category = "",
  rowObject = {},
  notifAction = "",
  notifications = [],
  authenticatedUser = {},
  userConfiguration = {},
  refreshTable = () => {},
  // onCloseWarningModal = () => {},
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  saveAddedLogs,
}) {
  message.loading({
    content: "Deleting...",
    key: "messageDeleting",
  });

  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });
  await API.del("safety", `/safety/${rowObject?.safetyApplicationId}`)
    ?.then(() => {
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      refreshTable(rowObject, "Delete");
      deleteNotifications(
        notifications,
        rowObject?.safetyApplicationId,
        dispatch
      );

      message.success({
        content: "Safety deleted successfully",
        key: "messageDeleting",
        duration: 3,
      });
      let deletePostLogsBody = {
        recordId: rowObject?.safetyApplicationId,
        recordName: rowObject?.safetyApplicationObject?.[nameKey],
        actionType: "Delete",
        category,
        currentData: {},
        nameOfUser: userConfiguration.nameOfUser,
        cognitoUserId: userConfiguration?.cognitoUserId,
        previousData: rowObject?.safetyApplicationObject,
        updatedAt: Date.now(),
      };
      setVisibleCreationProgress({ deletePostLogsBody });

      saveAddedLogs(deletePostLogsBody);

      broadcastNotification("42", notifAction, [
        { common: userConfiguration?.nameOfUser },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: userConfiguration?.cognitoUserId,
          cognitos: getCognitosForNotification(
            userConfiguration,
            rowObject?.teamsConfiguration
          ),
        },
      ]).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });

      // onCloseWarningModal({
      //   deleteAction: true,
      //   deletePostLogsBody,
      // });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error Deleting Safety: ", err);
    });
}

export function safetyBody({
  form,
  sign,
  assignedTo,
  currentStep,
  selectedTeam,
  witnessCount,
  nameKey = "",
  projects = [],
  schedules = [],
  crews = [],
  drivers = [],
  statusesColors,
  formattedFiles,
  table = "",
  incidentId = "",
  saveDraft = false,
  dynamicFields = [],
  witnessSignings = [],
  // requiredTextArea = {},
  // witnessStatements = [],
  userConfiguration = {},
  // descriptionValues = {},
  googleDriveFolderIds = [],
  addressSelectionInfo = {},
  safetyDirectorSignature,
  documentationModalFiles = null,
}) {
  const tables = {
    crews,
    drivers,
    schedules,
    projects,
  };

  const {
    defaultStatus = "",
    statusKey = "",
    objectKey = "",
    recordName = "",
  } = keyData[table];
  let formObject = {
    assignedTo: assignedTo,
    signature: sign,
    isDraft: saveDraft,
    ...(safetyDirectorSignature ? { safetyDirectorSignature } : {}),
    // ...descriptionValues,
    ...form.getFieldsValue(),
    ...getGridProjectInfo({
      projects,
      schedules,
      data: addressSelectionInfo,
    }),
  };

  const relatedDateFields = [
    "dateOfPropertyDamage",
    "dateOfInjury",
    "dateOfVehicleDamageOrIncident",
    "dateOfIncident",
    "dateOfPropertyDamage",
  ];

  for (let i = 0; i < witnessCount; i++) {
    // formObject[`witnessStatement-${i}`] = witnessStatements[i];
    formObject[`witnessSign-${i}`] = witnessSignings[i];
  }

  // At formattedFiles added e new Documented Object if file has been Documented
  if (!!documentationModalFiles) {
    const documentedIds = documentationModalFiles.map((d) => d.id);

    formattedFiles.forEach((file) => {
      if (documentedIds.includes(file.id)) {
        file.documented = {
          isDocumented: true,
          docId: documentationModalFiles.docId,
        };
      }
    });
  }

  let tmpBody = structuredClone({
    [objectKey]: formObject,
    incidentId,
    [recordName]: (form.getFieldValue(nameKey) || "")?.trim(),
    [statusKey]: saveDraft
      ? defaultStatus
      : statusesColors[currentStep]?.statusName || defaultStatus,
    ...addressSelectionInfo,
    googleDriveUpload: formattedFiles,
    googleDriveFolderIds,
    teamsConfiguration: [],
    // teamsConfiguration: updateTeamsConfiguration(
    //   userConfiguration,
    //   selectedTeam
    // ),
  });
  if (table === "incidents") {
    delete tmpBody.incidentId;
  }

  let dateInput;

  let timeFields = [];

  Object.keys(formObject).forEach((key) => {
    if (checkPicker(dynamicFields, key)) {
      if (!!formObject[key]) {
        tmpBody[objectKey][key] = dayjsNY(formObject[key]).format();
      }
    }
    if (checkDateInput({ fields: dynamicFields, formItemName: key })) {
      if (!!formObject[key]) {
        if (relatedDateFields?.includes(key)) {
          dateInput = formObject[key];
        }

        tmpBody[objectKey][key] = dateToSave(formObject[key], "MM/DD/YYYY");
      }
    }

    if (checkTimeInput({ fields: dynamicFields, formItemName: key })) {
      if (!!formObject[key]) {
        timeFields.push(key);
      }
    }
    if (key === nameKey && !!formObject[key]) {
      tmpBody[objectKey][key] = formObject[key].trim();
    }

    if (key?.toLowerCase()?.includes("datepicker")) {
      delete tmpBody[objectKey][key];
    }

    if (customOptionsData?.[key]) {
      const {
        table = "",
        key: tableKey = "",
        label = "",
      } = customOptionsData[key];

      // if (!!found) {
      if (customOptionsData?.[key]?.mode === "multiple") {
        tmpBody[objectKey][key] = formObject[key]?.map((id) => {
          const found =
            tables?.[table]?.find((el) => el?.[tableKey] === id) || null;

          return {
            value: found?.[label] || null,
            id: id || null,
          };
        });
      } else {
        const found =
          tables?.[table]?.find((el) => el?.[tableKey] === formObject[key]) ||
          null;

        if (!!found) {
          tmpBody[objectKey][key] = {
            value: found?.[label] || null,
            id: formObject[key] || null,
          };
        }
      }
      // }
    }
  });

  if (!!dateInput) {
    timeFields.forEach((key) => {
      if (!!formObject[key]) {
        tmpBody[objectKey][key] = dateToSave(
          `${dateInput} ${formObject[key]}`,
          "MM/DD/YYYY hh:mm A",
          "time"
        );
      }
    });

    dateInput = null;
  }

  return {
    tmpBody,
  };
}

export async function createSafety({
  dispatch,
  table = "",
  nameKey = "",
  tmpBody = {},
  loggedOnTasks,
  programFields,
  category = "",
  rowObject = {},
  incidentId = "",
  assignedTo = [],
  preferences = {},
  createAction = "",
  saveDraft = false,
  // onClose = () => {},
  authenticatedUser = {},
  userConfiguration = {},
  refreshTable = () => {},
  setAskTaskModal = () => {},
  updateIncidentStatus = () => {},
  handleSavedRecord,
  setVisibleCreationProgress = () => {},
  updateProgressStatus = () => {},
  hideProgress = () => {},
  saveAddedLogs,
}) {
  const postCreationFieldOptions = programFields?.find(
    (prog) => prog.fieldName === "Automated Tasks"
  )?.fieldOptions;

  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });

  (!postCreationFieldOptions?.Safety || saveDraft) &&
    hideProgress("creatingTask");

  const result = await API.post("safety", `/safety`, {
    body: {
      ...tmpBody,
      safetyApplicationCategory: category,
      createdBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    .then(async (res) => {
      updateProgressStatus({ updatingRecord: "finished" });
      setVisibleCreationProgress(res); // save datas to generate logs in case of any fail
      res?.safetyApplicationStatus !== "Draft" && handleSavedRecord(res);
      if (postCreationFieldOptions?.Safety && !saveDraft) {
        updateProgressStatus({ creatingTask: "executing" });
        await createNewTask({
          category: "Safety",
          taskAssignedTo: assignedTo?.map((user) => ({
            cognitoUserId: user.cognitoUserId,
            nameOfUser: user.label,
            userName: user?.userName,
          })),
          taskRelatedTo: res?.safetyName,
          taskTopicId: res?.safetyApplicationId,
          taskSubTopic: category,
          userConfiguration,
          calendarPreference: preferences?.calendarPreference,
          loggedOnTasks,
          dispatch,
        })
          .then(() => {
            updateProgressStatus({ creatingTask: "finished" });
          })
          .catch((err) => {
            console.error({ err });
            updateProgressStatus({ creatingTask: "hasError" });
          });
      }

      if (incidentId && table === "safety") {
        updateIncidentStatus([rowObject], "safety");
        await API.put("incidents", `/incidents/${incidentId}`, {
          body: {
            incidentStatus: "Proceeded to Safety",
          },
        }).catch((err) => console.error("Error", { err }));
      }
      refreshTable(res);

      if (!saveDraft) {
        setAskTaskModal(res);
      }
      // else {
      //   onClose();
      // }

      updateProgressStatus({ sendingNotification: "executing" });
      if (!saveDraft) {
        sendSafetyCreateNotifs({
          userConfiguration,
          authenticatedUser,
          res,
          createAction,
          category,
          tmpBody,
          table: "safety",
        }).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      } else {
        broadcastNotification(
          "42",
          "onSaveDraft",
          [
            { common: userConfiguration?.nameOfUser },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
            },
          ],
          res?.safetyApplicationId
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
      }

      await saveAddedLogs({
        recordId: res?.safetyApplicationId,
        recordName: res?.[nameKey],
        category,
        topic: res?.[nameKey],
      });

      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });
      return res;
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("Error creating safety", err);
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
    });
  return result;
}

export async function editSafety({
  tmpBody = {},
  nameKey = "",
  category = "",
  rowObject = {},
  editAction = "",
  onClose = () => {},
  authenticatedUser = {},
  userConfiguration = {},
  customLogObj = () => {},
  refreshTable = () => {},
  updateProgressStatus = () => {},
  saveAddedLogs,
}) {
  updateProgressStatus({ updatingRecord: "executing" });

  await API.put("safety", `/safety/${rowObject?.safetyApplicationId}`, {
    body: {
      ...tmpBody,
      lastModifiedBy: {
        name: userConfiguration?.nameOfUser,
        id: userConfiguration?.cognitoUserId,
      },
    },
  })
    ?.then(async () => {
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      let { safetyApplicationCategory: prevObj } = rowObject;
      let { safetyApplicationCategory: currObj } = tmpBody;

      const newAssignedToIds = currObj?.assignedTo
        ?.filter(({ cognitoUserId: id }) => {
          return !prevObj?.assignedTo?.some(
            ({ cognitoUserId }) => cognitoUserId === id
          );
        })
        ?.map(({ cognitoUserId }) => cognitoUserId)
        ?.filter(Boolean);

      let tmpPrev = {
        ...prevObj,
        ...customLogObj(prevObj),
      };

      let tmpCurr = {
        ...currObj,
        ...customLogObj(currObj),
      };

      refreshTable({ ...rowObject, ...tmpBody }, "Edit");

      let changes = getChangedData(tmpPrev, tmpCurr);

      if (!!changes) {
        saveAddedLogs({
          recordId: rowObject?.safetyApplicationId,
          recordName: rowObject?.[nameKey],
          category,
          actionType: "Edit",
          topic: rowObject?.[nameKey],
          currentData: changes?.curr || {},
          previousData: changes?.prev || {},
          updatedKeys: ["See Details"],
        });
      }

      newAssignedToIds?.length > 0 &&
        broadcastNotification(
          "42",
          editAction,
          [
            {
              common: userConfiguration?.nameOfUser,
              category,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: newAssignedToIds || [],
            },
          ],
          rowObject?.safetyApplicationId
        );

      // await broadcastNotification(
      broadcastNotification(
        "42",
        editAction,
        [
          {
            common: userConfiguration?.nameOfUser,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: authenticatedUser?.sub,
            cognitos: getCognitosForNotification(
              userConfiguration,
              rowObject?.teamsConfiguration
            ),
          },
        ],
        rowObject?.safetyApplicationId
      ).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });

      message.success({
        content: "Saved Successfully!",
        key: "safetyLoading",
        duration: 3,
      });
    })
    .catch((err) => {
      updateProgressStatus({ updatingRecord: "hasError" });
      message.error({
        content: "Something Went Wrong While Saving",
        key: "safetyLoading",
      });
      console.error("Error editing safety", err);
    });
  onClose();
}

export async function onGenerateSafetyPdf({
  base64,
  category,
  rowObject,
  pdfId = "",
  accessToken,
  driveFunctions,
  defaultObj = {},
  setPdfId = () => {},
  userConfiguration = {},
  folderDriveKeyName = "",
  createPdfBody = () => {},
}) {
  const notes = await filterTables(
    "notes",
    "recordId",
    rowObject?.safetyApplicationId
  )
    .then((res) => res)
    ?.catch((err) => console.error("Error Fetching Notes: ", err));

  let noteArray = [];

  notes?.map((note) => {
    let noteIndex = 0;

    getRepliesRecursively(note, noteArray, noteIndex);
  });

  const images = await imageForPdf({ rowObject, accessToken });

  const imagePdfData = mapImages(images);

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const notesBody = notePdfBody(noteArray, false);

  const body = createPdfBody()?.concat(imagePdfData).concat(notesBody);

  createPDF({
    action: "open",
    docDefinition: docDefinition(
      `${rowObject?.safetyName} ${category} Form`,
      body,
      companyLogo
    ),
  });

  await savePdfToDrive({
    docDefinition: docDefinition(
      `${rowObject?.safetyName} ${category} Form`,
      body,
      companyLogo
    ),
    accessToken,
    folderId:
      rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
      driveFunctions.parentFolderId,
    title: `${rowObject?.safetyName} ${category} Safety Form`,
  })
    .then((res) => {
      if (!!pdfId) {
        driveApi({ accessToken }).deleteDriveItem(pdfId);
      }
      setPdfId(res?.id);

      API.put("safety", `/safety/${rowObject?.safetyApplicationId}`, {
        body: {
          safetyApplicationObject: {
            ...defaultObj,
            gDrivePdfId: res?.id,
          },
        },
      }).catch((err) => console.error("Error Updating Safety: ", err));
    })
    .catch((err) => console.error("Error Saving PDF to Drive: ", err));
}

export async function onCloseModal({
  form,
  param = {},
  driveRequest,
  nameKey = "",
  rowObject = {},
  objectKey = "",
  driveFunctions = {},
  filesToBeDeleted = [],
  setVisible = () => {},
  folderDriveKeyName = "",
  setUploadedFiles = () => {},
  updateDocumentTitle = () => {},
  setFilesToBeDeleted = () => {},
  setCancelModalVisible = () => {},
  saveAddedLogs,
}) {
  const {
    cancel = false,
    deleteAction = false,
    deletePostLogsBody = {},
  } = param || {};

  if (deleteAction) {
    if (driveFunctions?.parentFolderId) {
      driveFunctions
        ?.delete()
        ?.then(() => {
          if (Object.keys(deletePostLogsBody).length > 0) {
            saveAddedLogs({
              ...deletePostLogsBody,
              recordName: rowObject?.[objectKey]?.[nameKey] + "Drive Folder",
            });
          }
        })
        .catch((err) => {
          console.error("Error Deleting Drive Folder: ", err);
        });
    } else {
      driveRequest
        ?.deleteDriveItem(rowObject?.googleDriveFolderIds?.[folderDriveKeyName])
        ?.then(() => {
          if (Object.keys(deletePostLogsBody).length > 0) {
            saveAddedLogs({
              ...deletePostLogsBody,
              recordName: rowObject?.[objectKey]?.[nameKey] + "Drive Folder",
            });
          }
        });
    }
  }
  if (cancel || deleteAction) {
    Promise.allSettled(
      (filesToBeDeleted || [])?.map(
        async (id) => await driveRequest.deleteDriveItem(id)
      )
    )?.then(() => setFilesToBeDeleted([]));
  }

  driveFunctions?.reset();
  form.resetFields();
  setCancelModalVisible(false);
  updateDocumentTitle();

  setVisible(false);
  setUploadedFiles([]);
}

export async function safetyEmailNotif({
  table = "",
  category = "",
  rowObject = {},
  defaultId = "",
  setEditLogs = () => {},
  authenticatedUser = {},
  userConfiguration = {},
  saveAddedLogs,
}) {
  const action = table === "safety" ? "onSafetyEmail" : "onSafetyIncidentEmail";
  const notifId = table === "safety" ? "42" : "43";
  const nameKey = table === "safety" ? "safetyName" : "incidentName";

  broadcastNotification(
    notifId,
    action,
    [
      {
        common: userConfiguration?.nameOfUser,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: authenticatedUser?.sub,
        cognitos: getCognitosForNotification(
          userConfiguration,
          rowObject?.teamsConfiguration
        ),
      },
    ],
    defaultId
  );

  await saveAddedLogs({
    recordId: defaultId,
    recordName: rowObject?.[nameKey],
    actionType: "Email",
    category,
    topic: rowObject?.[nameKey],
    currentData: {
      [userConfiguration.nameOfUser]: "Sent an email for this record",
    },
  })
    .then((res) => {
      setEditLogs((prev) => {
        if (!!prev?.length) {
          return [...res, ...prev];
        }
        return res;
      });
    })
    .catch((err) => console.error("Error Posting logs", err));
}

import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { quickStatistics_Schedule_and_Project } from "../../components/SidebarPages/Scheduling/helpers/quickStatistics";

export const getSchedulingTotalProgress = async () => {
  let [scheduling, invoicesRes, paymentsRes, requisitionsRes] =
    await Promise.all([
      fetchAllData("scheduling", "scheduling", "scheduleId"),
      fetchAllData("invoices", "invoices", "invoiceId"),
      fetchAllData("payments", "payments", "paymentId"),
      fetchAllData("applications", "applications", "applicationId"),
    ]);

  let result = scheduling.map((schedule) => ({
    createdAt: schedule.createdAt,
    projectId: schedule.projectId,
    projectExecutive: schedule.projectExecutive,
    scheduleAddress: schedule.scheduleAddress,
    scheduleId: schedule.scheduleId,
    scheduleName: schedule.scheduleName,
    scheduleStatus: schedule.scheduleStatus,
    typeOfWork: schedule.typeOfWork,
    services: Object.keys(schedule.toBeScheduled)
      .map((key) => {
        return schedule.toBeScheduled[key].map((service) => {
          const requisitions = requisitionsRes
            .filter((requisition) =>
              requisition.estimationsSelected.includes(key)
            )
            .map((req) => ({
              services: req.services.filter(
                (reqService) =>
                  reqService.estimationId === key &&
                  reqService.label === service.label
              ),
            }));

          const invoices = invoicesRes
            .filter(
              (invoice) =>
                invoice.projectId === schedule.projectId &&
                invoice.categoriesFrom.includes("estimations")
            )
            .map((invoice) => {
              return {
                ...invoice,
                invoiceItems: invoice.invoiceItems.filter(
                  (item) =>
                    item.category === "estimations" &&
                    item.name === service.label &&
                    item.categoryId === key
                ),
              };
            });
          const payments = paymentsRes.filter((payment) =>
            payment.payments.some((paymentDetail) =>
              invoices.some(
                (invoice) =>
                  invoice.invoiceNumber === paymentDetail.invoiceNumber
              )
            )
          );
          const { scheduleTotalProgressPercentage } =
            quickStatistics_Schedule_and_Project({
              project: [],
              toBeScheduled: schedule?.toBeScheduled,
              schedules: [],
              typeOfWork: schedule?.typeOfWork,
            });

          return {
            createdAt: schedule.createdAt,
            projectId: schedule.projectId,
            projectExecutive: schedule.projectExecutive,
            scheduleAddress: schedule.scheduleAddress,
            scheduleId: schedule.scheduleId,
            scheduleName: schedule.scheduleName,
            scheduleStatus: schedule.scheduleStatus,
            typeOfWork: schedule.typeOfWork,
            estimationNumber: service.estimationNumber,
            serviceName: service.label,
            totalProgress: scheduleTotalProgressPercentage,
            totalRequisitionAmount: requisitions.reduce(
              (sum, requisition) =>
                (sum += requisition.services.reduce(
                  (acc, item) =>
                    (acc +=
                      item.amounts.reduce(
                        (itemSum, amount) => (itemSum += amount.paymentDue),
                        0
                      ) || 0),
                  0
                )),
              0
            ),
            totalInvoiceAmount: invoices.reduce(
              (sum, invoice) =>
                sum +
                invoice.invoiceItems
                  .filter((item) => item.name === service.label)
                  .reduce((itemSum, item) => itemSum + item.total, 0),
              0
            ),
            totalPaymentAmount: payments?.reduce(
              (sum, payment) =>
                sum +
                payment.invoices.reduce(
                  (itemSum, item) =>
                    itemSum +
                    (service.totalities?.totalPrice || 0) *
                      ((item?.invoicePaidPercentage || 0) / 100),
                  0
                ),
              0
            ),
          };
        });
      })
      .flat(),
  }));
  return result;
};

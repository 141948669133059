import { Buffer } from "buffer";
import React from "react";
import { useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { markEmailsAsRead } from "../../../../../../../actions/notificationsActions";
import { useGoogleSignIn } from "../../../../../../../hooks/useGoogleSignIn";
import { callGmailAPI } from "../../../../../../SidebarPages/Communication/functions";
import { EmailIcon } from "../../../../Approvals/assets";

import { formatCommunicationTimestamp } from "../../../../../../SidebarPages/Communication/utils/formatCommunicationTimestamp";
import { useNotificationsDrawer } from "../../../providers/NotificationsDrawerProvider";

import "./communication-item-view.scss";

function CommunicationItemView({ email }) {
  // Destructure email properties for easier access
  const { id, from, to, subject, timestampDate, recordName, recordId, labels } =
    email;

  // Hook to programmatically navigate
  const navigate = useNavigate();
  // Hook to manage notifications drawer state
  const { toggleDrawer } = useNotificationsDrawer();
  // Hook to dispatch actions to Redux store
  const dispatch = useDispatch();
  // Hook to get Google sign-in state and access token
  const { googleAccessToken, userLoggedIn } = useGoogleSignIn();

  // Categories that do not redirect on click
  const noRedirectCategories = ["safety", "incidents", "allinspections"];
  // Determine if the current record name is in the no redirect categories
  const noRedirect = noRedirectCategories.includes(recordName.toLowerCase());

  // Parse the timestamp date to an integer
  const createdAt = parseInt(timestampDate);
  // Format the created timestamp for display
  const formattedCreatedAt = formatCommunicationTimestamp(createdAt);

  // Encode the Google access token if the user is logged in
  let authData = userLoggedIn
    ? Buffer.from(JSON.stringify(googleAccessToken)).toString("base64")
    : null;

  // Function to handle the click event on the communication item
  const handleOnClick = async () => {
    // If the item is in a noRedirect category, do nothing
    if (noRedirect) {
      return;
    }

    // Prepare the request payload to change the email label
    const req = {
      emailIds: [id], // ID of the email to modify
      moveTo: [], // Labels to move to (none in this case)
      removeFrom: [], // Labels to remove from (none in this case)
      markAs: ["READ"], // Mark the email as read
      authData, // Include auth data for Gmail API
    };

    // Call the Gmail API to change the label
    const { data } = await callGmailAPI("changeLabel", req);

    // If the API call was successful, navigate to the appropriate route
    if (data === "success") {
      // Determine the category based on the labels of the email
      const category = labels?.includes("INBOX")
        ? "inbox"
        : labels[0].toLowerCase();

      // Dispatch an action to mark the email as read in the Redux store
      dispatch(markEmailsAsRead(id, false));

      // Navigate to the specific communication thread
      navigate(
        `${recordName}/${recordId}?tab=Communication&category=${category}&threadId=${id}`
      );

      // Toggle the notifications drawer to close it
      toggleDrawer();
    }
  };

  return (
    <div
      className={`communication-item-view-container ${
        labels.includes("UNREAD") ? "unread" : ""
      }`}
      style={{ cursor: noRedirect ? "default" : "pointer" }} // Set cursor style based on redirect condition
      onClick={handleOnClick} // Set the click handler for the item
    >
      <div className="communication-item-view-content-container">
        <div className="email-icon-container">
          <EmailIcon /> {/* Render the email icon */}
        </div>
        <p>
          <strong>{from}</strong> has sent email to <strong>{to}</strong> with
          subject <strong>{subject}</strong> {/* Display email details */}
        </p>
      </div>
      <p className="created-at">{formattedCreatedAt}</p>
      {/* Display the formatted creation date */}
    </div>
  );
}

export default CommunicationItemView;

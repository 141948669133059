import axios from "axios";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

const linxupEndpoints = {
  location: "https://leadmanager-sockets.ue.r.appspot.com/api/location",
  geofences: "https://leadmanager-sockets.ue.r.appspot.com/api/geofences",
  alerts: "https://leadmanager-sockets.ue.r.appspot.com/api/alerts",
  stops: "https://leadmanager-sockets.ue.r.appspot.com/api/stops",
  vehicleReports:
    "https://leadmanager-sockets.ue.r.appspot.com/api/vehicleReports",
  locationsForVehicle:
    "https://leadmanager-sockets.ue.r.appspot.com/api/locationsForVehicle",
  newGeofence: "https://leadmanager-sockets.ue.r.appspot.com/api/newGeofence",
  vehicleTrips: "https://leadmanager-sockets.ue.r.appspot.com/api/vehicleTrips",
};

export const geoFencesActiveActivities = async () => {
  try {
    const [projects, locationsData] = await Promise.all([
      fetchAllData("projects", "projects", "projectId", () => {}, {
        keysToInclude: JSON.stringify([
          "projectName",
          "projectAddress",
          "projectStatus",
          "geoFenceInfo",
        ]),
      }),
      axios.get(linxupEndpoints.location),
    ]);

    let locations =
      locationsData.status === 200 ? locationsData.data.data.locations : [];

    const activities = [];
    projects.forEach((project) => {
      Array.isArray(project.geoFenceInfo) &&
        locations.forEach((location) => {
          const isInProject = isLocationInsideGeofences(
            location,
            project.geoFenceInfo
          );
          isInProject &&
            activities.push({
              ...location,
              projectName: project.projectName,
              projectAddress: project.projectAddress,
              projectStatus: project.projectStatus,
            });
        });

      return activities;
    });
  } catch (error) {
    console.log(
      "data source function geoFencesActiveActivities error: ",
      error
    );
  }
};

function isLocationInsideGeofences(location, geofences) {
  const { latitude, longitude } = location;
  for (const geofence of geofences) {
    if (Array.isArray(geofence)) {
      const coordinates = geofence.map((point) => [point.lng, point.lat]);
      let inside = false;

      for (
        let i = 0, j = coordinates.length - 1;
        i < coordinates.length;
        j = i++
      ) {
        const xi = coordinates[i][0];
        const yi = coordinates[i][1];
        const xj = coordinates[j][0];
        const yj = coordinates[j][1];
        const intersect =
          yi > latitude !== yj > latitude &&
          longitude < ((xj - xi) * (latitude - yi)) / (yj - yi) + xi;

        if (intersect) {
          inside = !inside;
        }
      }

      if (inside) {
        return true;
      }
    }
  }

  return false;
}

/**
 * External store class, used to preserve the same instance
 * of a data across all the listening components.
 *
 * Created to be used alongside useSyncExternalStore, groups
 * all the listeners and cleans up when all the components that were listening, unmount
 */
class ExternalStore {
  /** @type {Record<string, Function[]>} */
  #listeners = {};

  constructor() {}

  /** @param {string} group */
  storeUpdate(group) {
    const pathListeners = this.#listeners?.[group] || [];
    for (const listener of pathListeners) {
      listener();
    }
  }

  /**
   * @param {Function} listener
   * @param {string} group
   */
  storeListen(listener, group) {
    this.#listeners = {
      ...this.#listeners,
      [group]: [...(this.#listeners?.[group] || []), listener],
    };

    return () => {
      this.#listeners = {
        ...this.#listeners,
        [group]: (this.#listeners?.[group] || []).filter((l) => l !== listener),
      };

      if (!this.#listeners?.[group]?.length) {
        delete this.#listeners[group];
        this.cleanup(group);
      }
    };
  }

  /**
   * Cleanup is called when the listener component
   * is unmounted, custom implementation is left
   * to the store that inherits this property
   * @param {string} group
   */
  cleanup(group) {}

  /**
   * The store needs to implement this function
   * in order for the listener component to get
   * state updates
   */
  getSnapshot() {}
}

export default ExternalStore;

import React, { useState, useEffect } from "react";
import "./Chats.scss";
import UsersToChat from "./UsersToChat/UsersToChat";
import { useSelector } from "react-redux";
import NewConversation from "./NewConversation/NewConversation";
import { message } from "antd";
import { io } from "socket.io-client";
// const socket = io.connect("http://10.0.0.216:3000", {
//   reconnectionAttempts: 5,
// });
const socket = {};

const Chats = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { cognitoUserId = "", allUsers = {} } = userConfiguration || {};
  const { Items = [] } = allUsers || {};
  const [activeTab, setActiveTab] = useState("allChats");
  const [selectedUser, setSelectedUser] = useState({});
  const [roomId, setRoomId] = useState("");
  const [messageList, setMessageList] = useState({});
  const activeUsers = Items.filter(
    (user) => !user.isSuspended && user.cognitoUserId !== cognitoUserId
  );

  const tabs = [
    {
      name: "All my conversations",
      field: "allChats",
    },
    {
      name: "Unread",
      field: "unread",
    },
    {
      name: "Groups",
      field: "groups",
    },
    {
      name: "All users",
      field: "allUsers",
    },
  ];

  const generateRoomId = () => {
    if (!cognitoUserId || !selectedUser?.cognitoUserId) {
      console.warn("Missing user IDs to generate roomId");
      return null;
    }

    const sortedUuids = [cognitoUserId, selectedUser.cognitoUserId].sort();
    return `${sortedUuids[0]}_${sortedUuids[1]}`;
  };

  useEffect(() => {
    if (Object.keys(selectedUser).length > 0) {
      const roomId = generateRoomId();

      if (roomId) {
        setMessageList((prev) => ({
          ...prev,
          [roomId]: prev[roomId] || [],
        }));

        socket.emit("chatId", roomId);
        setRoomId(roomId);
      }
    }
  }, [selectedUser]);

  return (
    <div className="chatsView">
      <div className="internalChatsContent">
        <div className="internalChatsContentTabs">
          {tabs.map((tab) => (
            <div
              key={tab.field}
              className={`tab ${activeTab === tab.field ? "activeTab" : ""}`}
              onClick={() => setActiveTab(tab.field)}
            >
              {tab.name}
            </div>
          ))}
        </div>
        <UsersToChat
          {...{
            users: activeUsers || [],
            selectedUser,
            setSelectedUser,
            messageList: messageList || {},
            cognitoUserId,
          }}
        />
      </div>
      <NewConversation
        {...{
          users: activeUsers || [],
          selectedUser,
          setSelectedUser,
          socket,
          roomId,
          messageList: messageList || {},
          setMessageList,
        }}
      />
    </div>
  );
};

export default Chats;

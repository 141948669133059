import { useState } from "react";
import { Tree } from "antd";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../../../../../../../integrations/DriveRequest";
import { useEffect } from "react";
import { MondayButton } from "../../../../../../../../../../../../commonComponents";
import { DownOutlined } from "@ant-design/icons";
import { DownArrow } from "../../../../../../../../../../../../pages/Settings/settingsComponents/Management/EditUser/components/UserAccessWrites/components/FoldersAccess";

const updateTreeData = (list, key, children) => {
  return list.map((node) => {
    if (node.key === key) {
      return {
        ...node,
        children,
      };
    }
    if (node.children) {
      return {
        ...node,
        children: updateTreeData(node.children, key, children),
      };
    }
    return node;
  });
};

function TreeDriveNavigate({
  onPickFolder,
  folderId,
  loading,
  currentView,
  currentFolderId,
  parentFolderName,
}) {
  const [treeData, setTreeData] = useState(getInitTreeData(folderId));
  const [parentView, setParentView] = useState(false);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { getFilesByFolderId, ...rest } = driveApi({ accessToken });
  const [selectedKey, setSelectedKey] = useState(null);

  const onLoadData = ({ key, children, title = "" }) =>
    new Promise((resolve) => {
      if (children) {
        resolve();
        return;
      }
      let prev = treeData;
      console.log("prev", { key, children });
      onPickFolder({ id: key, name: title, save: true });
      getFilesByFolderId(key)
        .then((res) => res.json())
        .then((data) => {
          // console.log("dataaa2", data.files);
          let filesArr = data?.files || [];
          let tree = filesArr.map((file) => {
            return {
              title: file.name,
              key: file.id,
            };
          });
          console.log("treee2", { tree, origin, key });
          setTreeData((origin) => updateTreeData(origin, key, tree));
        });
      resolve();
    });
  const onSelect = (selectedKeys, info) => {
    setSelectedKey(selectedKeys[0]); // Always reset to selected key
    onPickFolder({ id: selectedKeys[0], name: info.node.title });
  };

  useEffect(() => {
    getFilesByFolderId(folderId)
      .then((res) => {
        return res.json();
      })
      .then((data) => {
        let filesArr = data.files;
        let tree =
          filesArr.length > 0
            ? filesArr.map((file) => ({
                title: file.name,
                key: file.id,
              }))
            : [{ title: "No files", key: `${key}-empty`, isLeaf: true }];
        console.log("treeeledjo", { data });
        setTreeData([
          { title: "CORE STORAGE", key: "0", children: tree, id: folderId },
        ]);
      })
      .finally(() => {
        // setLoading(false);
        // onPickFolder({ id: folderId, name: "CORE STORAGE" });
      });
  }, [accessToken, loading, folderId]);

  return (
    <div>
      <Tree
        selectedKeys={[selectedKey]} // Control the selected key
        loadData={(params) => {
          console.log("params", params);
          return onLoadData(params);
        }}
        // switcherIcon={<DownOutlined />}
        showLine
        onSelect={onSelect}
        defaultExpandAll
        treeData={treeData}
        defaultExpandParent={true}
        style={{
          backgroundColor: "#F8F8FA",
        }}
        switcherIcon={({ expanded }) => {
          return (
            <DownArrow
              style={{ transform: `rotate(${expanded ? "0deg" : "-90deg"})` }}
            />
          );
        }}
      />
    </div>
  );
}

export default TreeDriveNavigate;

const getInitTreeData = (folderId) => [
  { title: "CORE STORAGE", key: "0", id: folderId },
  //   { title: "Tree Node", key: "2", isLeaf: true },
];

import moment from "moment";
import { API } from "aws-amplify";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { v4 as uuidv4 } from "uuid";
import { getAdminAndTeamUsers } from "../../../../../../utils";
import dayjs from "dayjs";

// Function to get assigned users
export const getAssignedUsers = (
  assignToMe,
  taskAssignedTo = [],
  userConfiguration
) => {
  const updatedAssignedTo =
    taskAssignedTo.map(({ label, value, key, ...rest }) => rest) || [];

  return !!assignToMe
    ? [
        ...(updatedAssignedTo || []),
        {
          nameOfUser: userConfiguration.nameOfUser,
          userName: userConfiguration.userName,
          cognitoUserId: userConfiguration.cognitoUserId,
          identityId: userConfiguration.identityId,
        },
      ]
    : updatedAssignedTo;
};

// Function to get task body for api
export const getTaskBody = (
  taskFormFields,
  customTaskProps,
  updatedTaskAssignedTo,
  remindMe,
  currentStatus,
  rowData,
  userConfiguration,
  folders,
  formattedFiles,
  taskStatuses,
  isPrivate
) => {
  const {
    taskTitle,
    taskTopic,
    taskSubTopic,
    taskSubTopicId = {},
    fromSettings = false,
    taskRelatedToObj,
    taskLabels,
    taskThread,
    taskStartDate: startDate,
    taskEndDate: endDate,
    taskDeadline: deadline,
    taskStartTime,
    taskEndTime,
    taskDeadlineTime,
  } = taskFormFields;

  const taskStartDate = dayjs(`${startDate} ${taskStartTime}`).valueOf();
  const taskEndDate = dayjs(`${endDate} ${taskEndTime}`).valueOf();

  const taskDeadline = dayjs(`${deadline} ${taskDeadlineTime}`).valueOf();

  // for tasks used in dynamic views we get customTaskProps
  const defaultTaskKeys =
    customTaskProps && !customTaskProps?.fromSettings
      ? customTaskProps
      : {
          taskTopic:
            taskTopic?.categoryName || customTaskProps?.taskTopic || "General",
          taskTopicId:
            taskRelatedToObj?.recordId || customTaskProps?.taskTopicId || "",
          taskRelatedTo:
            taskRelatedToObj?.recordName ||
            customTaskProps?.taskRelatedTo ||
            "",
        };

  return {
    taskTitle,
    taskSubTopic,
    taskSubTopicId,
    remindMe,
    createdByCognito: userConfiguration?.cognitoUserId,
    taskCounter: rowData?.length,
    taskAssignedTo: updatedTaskAssignedTo,
    taskStatus: taskStatuses?.[currentStatus]?.title || "Pending",
    taskLabels,
    fromSettings,
    taskStartDate,
    taskDeadline,
    taskEndDate,
    googleDriveUploads: formattedFiles || [],
    googleDriveFolderIds: folders || {},
    taskDependency: {
      blockedBy: [],
      blocking: [],
    },
    categoryFrom: "documentationCategoryFrom",
    taskThread,
    ...(isPrivate && { privateOnlyTo: userConfiguration.cognitoUserId }),
    taskActivity: [
      {
        nameOfUser: userConfiguration.nameOfUser,
        cognitoUserId: userConfiguration?.cognitoUserId,
        updatedAt: moment.now(),
        updatedKeys: [
          {
            label: "Task creation",
            type: "",
            previousValue: "",
            key: uuidv4(),
            updatedValue: "",
          },
        ],
      },
    ],
    ...defaultTaskKeys,
  };
};

export const updateProjectTasksList = (
  taskRes,
  projectTask,
  projectSubTask
) => {
  API.get("projects", `/projects/${taskRes.taskTopicId}`).then((project) => {
    const tasksList = project.projectTaskList;

    const taskPhase = tasksList[projectTask.taskPhase];
    const referedTask = taskPhase[projectTask.value];
    const subtask = referedTask[projectSubTask];

    const updateObject = {
      value: true,
      taskAssignedTo: taskRes?.updatedTaskAssignedTo,
      taskStatus: taskRes.taskStatus,
      taskId: taskRes.taskId,
    };

    if (projectSubTask) {
      if (typeof subtask === "object") {
        Object.assign(subtask, {
          [taskRes.taskTitle]: updateObject,
        });
      } else {
        Object.assign(referedTask, {
          [projectSubTask]: {
            [taskRes.taskTitle]: updateObject,
          },
        });
      }
    } else {
      Object.assign(referedTask, {
        [taskRes.taskTitle]: updateObject,
      });
    }

    API.put("projects", `/projects/${project.projectId}`, {
      body: {
        projectTaskList: tasksList,
      },
    })
      .then((res) => console.log("res: ", res))
      .catch((err) => console.log("Projects Task List put Error: ", err));
  });
};

export const sendTaskCreateNotification = async (
  userConfiguration,
  taskRes,
  assignedUsers,
  mentionedUsers,
  updateProgressStatus
) => {
  const defaultNotObj = {
    userName: userConfiguration.nameOfUser,
    currentUser: userConfiguration?.cognitoUserId,
    tab: "Tasks",
    selectedId: taskRes?.taskId,
  };

  const topicLowerCase = taskRes.taskTopic.toLowerCase();
  const goToLink =
    taskRes.taskTopic.toLowerCase() === "general"
      ? window.location.pathname.substring(1)
      : `${topicLowerCase.replace(/\s/g, "")}/${taskRes.taskTopicId}`;

  if (!!mentionedUsers) {
    broadcastNotification(
      "10",
      "onCommentMention",
      [
        {
          common: userConfiguration.nameOfUser,
          commonNext: taskRes.taskTopic,
          commonName: taskRes.taskTitle,
          user: taskRes.taskRelatedTo,
        },
        {
          ...defaultNotObj,
          selectedRow: taskRes,
          cognitos: mentionedUsers.map(({ cognitoUserId }) => cognitoUserId),
          currentPath:
            taskRes?.taskTopic === "General"
              ? "Task Manager"
              : `${taskRes?.taskTopic}/View`,
        },
      ],
      goToLink
    );
  }

  // await broadcastNotification(
  broadcastNotification(
    "5",
    "onCreation",
    [
      { common: userConfiguration.nameOfUser },
      {
        ...defaultNotObj,
        cognitos: assignedUsers.map(({ cognitoUserId }) => cognitoUserId),
        rowSelected: taskRes,
      },
    ],
    goToLink
  )
    .then((notificationSent) => {
      updateProgressStatus({
        sendingNotification: !!notificationSent ? "finished" : "hasError",
      });
    })
    .catch((error) => {
      console.error("Error sending notification:", error);
    });
};

//Function used to get list of users that can be asssigned in a task
export const getUsersList = (
  userConfiguration,
  selectedDepartments = [],
  taskRelatedToObj
) => {
  const departmentUsers = userConfiguration?.allUsers?.Items?.filter((user) => {
    return (
      user.cognitoUserId !== userConfiguration.cognitoUserId &&
      selectedDepartments.includes(user.departmentName)
    );
  });

  return !!taskRelatedToObj &&
    !!Array.isArray(taskRelatedToObj?.teamsConfiguration)
    ? getAdminAndTeamUsers(
        { allUsers: { Items: departmentUsers } },
        taskRelatedToObj.teamsConfiguration
      )
    : departmentUsers;
};

//modify row name to show its prefix then its name
export const updateRecordLabels = ({
  records,
  topic: { primaryKey, rowName, otherName, prefix, fromSettings = false } = {},
  modifyOptionLabel = true,
}) => {
  return records?.map((row) => {
    const recordId = row[primaryKey];

    const recordName =
      modifyOptionLabel && otherName && !fromSettings
        ? `${row[otherName]} | ${prefix || ""}
          ${row[rowName]}`
        : row[rowName];

    return {
      key: recordId,
      label: recordName,
      value: recordName,
      recordName,
      recordId,
      teamsConfiguration: row?.teamsConfiguration,
      createdAt: row?.createdAt || null,
    };
  });
};

export const mockedUseSelector = {
  userConfiguration: {
    nameOfUser: "nameOfUser",
    cognitoUserId: "cognitoUserId",
    tasksConfig: { canAssignToOthers: true },
    routeConfig: [{ title: "Projects", path: "/", read: true, children: [{}] }],
    allUsers: {
      Items: [
        {
          nameOfUser: "nameOfUser",
          cognitoUserId: "cognitoUserId",
          isSuspended: false,
          departmentName: "Executive",
          routeConfig: [{ title: "Projects", path: "/", read: true }],
        },
        {
          nameOfUser: "user2",
          cognitoUserId: "cognito2",
          isSuspended: false,
          departmentName: "Executive",
          routeConfig: [{ title: "Projects", path: "/", read: true }],
        },
      ],
    },
  },
  programFields: [
    {
      fieldName: "Tutorials Steps",
      fieldOptions: [
        { categoryName: "taskModal", steps: [{ title: "title" }] },
      ],
    },
    {
      fieldName: "Portal Video Tutorials",
      fieldOptions: [
        {
          categoryName: "General Tutorials",
          subCategories: [
            { videos: [[{ videoName: "New Task", videoLink: "videoLink" }]] },
          ],
        },
      ],
    },
    {
      fieldName: "Progress Steps Configuration",
      fieldOptions: {
        Tasks: {
          Create: [
            {
              progressStatus: "notStarted",
              progressName: "Creating",
              progressKey: "updatingRecord",
            },
          ],
        },
      },
    },
    {
      fieldName: "Project Management Task List",
      fieldOptions: [{ categoryName: "taskModal", steps: [{}] }],
    },
    {
      fieldName: "Tasks for Subtopics",
      fieldOptions: [
        {
          topicName: "Projects",
          subTopics: [{ subTopicName: "subTopicName" }],
        },
      ],
    },
    {
      fieldName: "Tasks labels",
      fieldOptions: [{ labelName: "Projects" }],
    },
  ],
  userGroups: [{ departmentName: "Executive" }],
  preferences: {},
  darkMode: false,
  accessToken: "accessToken",
  hotCredentials: [{ credentialId: "21" }],
  topicCategories: [{ categoryName: "Projects" }],
};

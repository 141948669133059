import { updateTable } from "../../../functions";

// update table with the new drafts data
export const updateDraftsInTable = async (recordName, recordId, drafts) => {
  const tableName =
    recordName?.categoryType === "permitdrawings"
      ? "permitDrawings"
      : recordName;
  await updateTable(tableName, recordId, "drafts", drafts);
};

import { useContext, useMemo, useRef, useEffect, Fragment } from "react";
import dayjs from "dayjs";

import VirtualAssistantContext from "../../data/VirtualAssistantContext";
import MessageGroup from "../MessageGroup/MessageGroup";
import { RightArrow } from "../../../../SidebarPages/BasePage/src";

import "./AssistantChatBody.scss";

const DATE_FORMAT = "ddd, DD MMM";

function AssistantChatBody() {
  //#region HOOKS
  const {
    messages,
    status,
    connectedUser,
    commonQuestions,
    sendMessage,
    retryMessage,
    sendFeedback,
  } = useContext(VirtualAssistantContext);

  /** @type {import("react").MutableRefObject<HTMLDivElement>} */
  const containerRef = useRef(null);

  const messageGroups = useMemo(() => {
    //#region MESSAGE GROUPS
    const groups = [];

    for (let i = 0; i < messages.length; i++) {
      //this loop is entered only when creating a new group
      const groupSender = messages[i]["senderId"];
      const prevMaxDate = groups.length
        ? dayjs(
            groups[groups.length - 1]["messages"][
              groups[groups.length - 1]["messages"].length - 1
            ]["createdAt"]
          )
            .endOf("day")
            .valueOf()
        : 0;
      const maxDate = dayjs(messages[i]["createdAt"]).endOf("day").valueOf();

      for (let j = i + 1; j <= messages.length; j++) {
        if (j === messages.length) {
          groups.push({
            dateSeparator: messages[i]["createdAt"] > prevMaxDate,
            messages: messages.slice(i, j),
          });
          i = j;
          break;
        } else {
          if (
            groupSender !== messages[j]["senderId"] ||
            messages[j]["createdAt"] > maxDate
          ) {
            groups.push({
              dateSeparator: messages[i]["createdAt"] > prevMaxDate,
              messages: messages.slice(i, j),
            });

            i = j - 1;
            break;
          }
        }
      }
    }

    return groups;
  }, [messages]);

  useEffect(() => {
    //#region AUTOMATIC SCROLL HANDLER
    const container = containerRef.current;
    if (!container) {
      return;
    }

    if (
      container.scrollTop <=
      container.scrollHeight - container.offsetHeight - 100
    ) {
      setTimeout(() => {
        container.scroll({
          behavior: "instant",
          top: container.scrollHeight,
        });
      }, 50);
    }
  }, [messages]);

  /**
   * Temporary dummy function used to render the messages on screen (TO BE DELETED)
   */
  async function onAskDefault(message) {
    await sendMessage(message);
  }

  //#region SHOW DEFAULTS CONDITION
  const showDefaultMessagesCondition =
    !messages.length &&
    (status === "Online" || (status === "Offline" && !connectedUser));

  //#region JSX
  return (
    <div
      className={`assistant-chat-body ${
        showDefaultMessagesCondition ? "default-messages" : ""
      }`}
      ref={containerRef}
    >
      {showDefaultMessagesCondition
        ? commonQuestions.map((message, i) => {
            return (
              <div
                key={i}
                className="default-message-container"
                onClick={() => onAskDefault(message.question)}
              >
                <span className="default-message">{message.question}</span>
                <div className="arrow-container">
                  <RightArrow height={"8.16px"} width={"18.6px"} />
                </div>
              </div>
            );
          })
        : null}
      {messageGroups.map(({ dateSeparator, messages }, index) => (
        <Fragment key={`group-${index}`}>
          {dateSeparator ? (
            <div
              className="time-fragment-separator"
              key={`group-${index}-date`}
            >
              {dayjs(messages[0]["createdAt"]).startOf("day").valueOf() ===
              dayjs().startOf("day").valueOf()
                ? "Today"
                : dayjs(messages[0]["createdAt"])
                    .startOf("day")
                    .format(DATE_FORMAT)}
            </div>
          ) : null}
          <MessageGroup
            messages={messages}
            key={`group-${index}-inner`}
            onRetry={retryMessage}
            onSendFeedback={sendFeedback}
          />
        </Fragment>
      ))}
    </div>
  );
}

export default AssistantChatBody;

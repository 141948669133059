import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { Modal, Form, Button, Spin, Alert, message } from "antd";
import { API } from "aws-amplify";
import { useNavigate } from "react-router-dom";
import axios from "axios";

import { validateForm, fetchData } from "../../../../Fleet/utils";
import {
  lazyFetch,
  filterTables,
  getCognitosForNotification,
  updateTeamsConfiguration,
  updateDocumentTitle,
  batchUpdateItems,
} from "../../../../../../utils";
import { formatNumber } from "../../../../utils";
import { useEditLogs } from "../../../../../../hooks";
import findPreviousWorkOrder from "./utils/findPreviousWorkOrder";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import WorkOrderTable from "./components/WorkOrderTable";
import WorkOrderAssign from "./components/WorkOrderAssign";
import NewFleetInventory from "../../FleetInventory/Modals/NewFleetInventory";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import DynamicTeamModal from "../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { InputComponent } from "../../../../Fleet/components";
import { WarningInfoCard } from "../../../../FleetMaintenanceView/components";
import { FormTextEditor } from "../../../../../Header/forms/Components";
import { MondayButton } from "../../../../../commonComponents";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../../../Communication/assets";
import { InfoIcon } from "../../../../Inspections/assets";
import { WarningTruckIcon } from "../../../../../../assets";
import { AddIcon } from "../../../../../Header/components/GoogleEvents/assets";
import { reportEndpoints } from "../../../../FleetMaintenanceView/utils/getMaintenanceBaseData";

import "./NewWorkOrderModal.scss";

//#region TYPES
/**
 * @typedef {import("../../../../FleetMaintenanceView/types").WorkOrderType} WorkOrder
 */

/**
 * @typedef NewWorkOrderDefaultData
 * @property {string} defectId
 * @property {string} defectStatus
 * @property {import("../../../../FleetMaintenanceView/types").TeamsConfigurationType} teamsConfiguration
 * @property {string} fleetId
 * @property {string} [serviceId]
 * @property {string} [programId]
 */

/**
 * @typedef {WorkOrder | {workOrderId: string, itemsToUpdate: string[]}} RefreshTableParam
 */

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").ServiceProgramType} ServiceProgram
 */

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").VehicleReport} VehicleReport
 */

/**
 * @typedef {import("../../../../FleetMaintenanceView/types").VehicleReportError} VehicleReportError
 */

/**
 * @typedef {{vehicleReports: VehicleReport[], reportErrors: VehicleReportError[]}} Reports
 */

/**
 * @typedef NewWorkOrderProps
 * @property {(param: RefreshTableParam) => void} [refreshTable]
 * @property {boolean} visible
 * @property {(visible: boolean) => void} setVisible
 * @property {NewWorkOrderDefaultData} [defaultData]
 * @property {any} [requestObject]
 * @property {(param: {requestId: string, path: string}) => void} [afterSaveRequest]
 *
 * @param {NewWorkOrderProps} props
 * @returns {JSX.Element}
 */
function NewWorkOrderModal({
  refreshTable = () => {},
  visible: open,
  setVisible,
  defaultData = null,
  requestObject = null,
  afterSaveRequest = async () => {},
}) {
  //#region HOOKS
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Work Order View")
      ?.children?.find(({ title }) => title === "Overview")
  );
  const [fleets, setFleets] = useState([]);
  const [mechanics, setMechanics] = useState([]);
  const [inventoryItems, setInventoryItems] = useState([]);
  const [selectedItems, setSelectedItems] = useState(
    [defaultData?.defectId].filter(Boolean)
  );
  const [defectsForFleet, setDefectsForFleet] = useState([]);
  const [loading, setLoading] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [newInventoryOpen, setNewInventoryOpen] = useState(false);
  const [comments, setComments] = useState("");
  const [allTeams, setAllTeams] = useState([]);
  const [teamModalOpen, setTeamModalOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState(
    defaultData?.teamsConfiguration || []
  );
  const [selectedProgram, setSelectedProgram] = useState(
    /** @type {ServiceProgram} */ (undefined)
  );
  const [vehicleReports, setVehicleReports] = useState(
    /** @type {Reports} */ ({
      reportErrors: [],
      vehicleReports: [],
    })
  );
  const [lastInspectedItems, setLastInspectedItems] = useState(
    /** @type {string[]} */ ([])
  );
  const [form] = Form.useForm();

  const navigate = useNavigate();
  const { saveAddedLogs } = useEditLogs();

  function getAccessRights(childTitle) {
    //#region ACCESS RIGHT
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  async function getDefectsForFleet(fleetId) {
    //#region GET DEFECTS
    filterTables("fleetDefects", "fleetId", fleetId)
      .then((res) => {
        let tmpRes = res?.filter((el) => !el?.workOrderId);
        tmpRes?.sort((a) => {
          if (a?.defectPriority === "High") {
            return -1;
          } else {
            return 1;
          }
        });
        setDefectsForFleet(tmpRes);
        setLoading(false);
      })
      .catch((err) => {
        console.log("Error getting defects: ", err);
        setLoading(false);
      });
  }

  if (defaultData?.teamsConfiguration) {
    form.setFieldValue("workOrderTeam", defaultData?.teamsConfiguration);
  }

  useEffect(() => {
    //#region WO WARNINGS
    if (!defaultData?.serviceId) {
      return;
    }

    message.loading({
      content: "Looking for warnings...",
      key: "warningsLoad",
      duration: 0,
    });

    findPreviousWorkOrder({
      fleetId: defaultData.fleetId,
      serviceId: defaultData.serviceId,
    })
      .then((res) => {
        message.destroy("warningsLoad");

        if (!res) {
          return;
        }

        setLastInspectedItems(res);
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while checking for warnings",
          key: "warningsLoad",
        });
        console.log("could not get latest work order: ", err);
      });
  }, [defaultData?.serviceId]);

  useEffect(() => {
    //#region DATA EFFECT
    message.loading({
      content: "Opening work order form",
      key: "woData",
    });

    Promise.all(
      [
        lazyFetch({
          tableName: "fleet",
          listOfKeys: [
            "fleetName",
            "fleetId",
            "odometerReading",
            "teamsConfiguration",
          ],
        }),
        lazyFetch({
          tableName: "fleetInventory",
          listOfKeys: ["itemId", "itemName", "itemPartNo", "itemQuantity"],
        }),
        fetchData("teams"),
        defaultData?.programId &&
          API.get(
            "servicePrograms",
            `/servicePrograms/${defaultData?.programId}`,
            {}
          ),
        defaultData?.programId && axios.get(reportEndpoints.online),
      ].filter(Boolean)
    )
      .then((res) => {
        message.destroy("woData");

        setFleets(res[0]);
        setInventoryItems(res[1]);
        setAllTeams(res[2]);

        if (res[3]) {
          setSelectedProgram(res[3]);
        }

        if (res[4]) {
          setVehicleReports(res[4]["data"]);
        }
      })
      .catch((err) => {
        message.error({
          content: "Something went wrong while opening work order form",
          key: "woData",
        });
        console.log("Error getting work order data: ", err);
        closeModal();
      });

    setMechanics(
      userConfiguration.allUsers.Items?.filter(
        (el) => el?.groupName === "Mechanic"
      )?.reduce(
        (acc, val) => [
          ...acc,
          { name: val?.nameOfUser, id: val?.cognitoUserId },
        ],
        []
      )
    );
  }, []);

  async function saveHandler() {
    //#region SAVE HANDLER
    if (defaultData?.programId) {
      const fleetId = form.getFieldValue("selectedTruck");

      message.loading({
        content: "Updating service program...",
        key: "workOrderSave",
      });

      const { durationUnit, repetitionDuration, repetitionMiles } =
        selectedProgram;

      let vehicleOdometerReading = vehicleReports.vehicleReports.find(
        (e) => e.fleetId === fleetId
      )?.odometerReading;

      if (!vehicleOdometerReading) {
        vehicleOdometerReading = vehicleReports.reportErrors.find(
          (e) => e.fleetId === fleetId
        )?.odometerReading;
      }

      if (!vehicleOdometerReading) {
        vehicleOdometerReading = fleets.find(
          (e) => e.fleetId === fleetId
        )?.odometerReading;
      }

      const updateData = {
        [defaultData?.programId]: {
          ...(repetitionMiles
            ? {
                [`executionData.${fleetId}.lastMileScheduleService`]:
                  vehicleOdometerReading,
                [`executionData.${fleetId}.nextExecutionMiles`]:
                  vehicleOdometerReading + repetitionMiles,
                [`executionData.${fleetId}.workOrderCreatedMiles`]: true,
              }
            : {}),
          ...(durationUnit && repetitionDuration
            ? {
                [`executionData.${fleetId}.lastDateScheduleService`]: dayjsNY()
                  .startOf("day")
                  .valueOf(),
                [`executionData.${fleetId}.nextExecutionDate`]: dayjsNY()
                  .add(repetitionDuration, durationUnit)
                  .startOf("day")
                  .valueOf(),
                [`executionData.${fleetId}.workOrderCreatedDate`]: true,
              }
            : {}),
        },
      };

      try {
        await batchUpdateItems({
          tableName: "servicePrograms",
          conditionKey: "programId",
          updateData,
        });
      } catch (err) {
        message.error({
          content: "Something went wrong! Could not update service program",
          key: "workOrderSave",
        });
        console.log("Error updating service program: ", err);
        closeModal();
        return;
      }
    }

    let body = {
      fleetId: form.getFieldValue("selectedTruck"),
      fleetName: fleets?.find(
        (el) => el?.fleetId === form.getFieldValue("selectedTruck")
      )?.fleetName,
      workOrderItems: selectedItems?.reduce(
        (acc, val) => [
          ...acc,
          {
            defectId: val,
            defectName: defectsForFleet?.find((el) => el?.defectId === val)
              ?.defectName,
          },
        ],
        []
      ),
      inventoryItems: form
        .getFieldValue("inventoryItems")
        ?.map((itemId) => ({ itemId, quantity: 0 })),
      mechanicId: form.getFieldValue("assignee"),
      teamsConfiguration: updateTeamsConfiguration(
        userConfiguration,
        selectedTeam
      ),
      issueDate: form.getFieldValue("issueDate").valueOf(),
      dueDate: form.getFieldValue("dueDate").valueOf(),
      mechanicInfo: {
        mechanicId: form.getFieldValue("assignee"),
        mechanic: mechanics?.find(
          (el) => el?.id === form.getFieldValue("assignee")
        )?.name,
      },
      comments,
      serviceId: defaultData?.serviceId,
    };

    message.loading({
      content: "Saving...",
      key: "workOderSave",
    });

    API.post("workOrders", "/workOrders", {
      body: { ...body },
    })
      .then((res) => {
        if (!defaultData?.defectId) {
          refreshTable(res);
        }

        if (requestObject?.requestId) {
          let requestId = requestObject?.requestId;

          delete requestObject?.requestId;

          afterSaveRequest({ requestId, path: `workOrders` });
        }
        message.success({
          content: "Saved Successfully!",
          key: "workOderSave",
        });
        broadcastNotification(
          "11",
          "onWorkOrderCreation",
          [
            {
              common: userConfiguration?.nameOfUser,
              fleetName: body?.fleetName,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: authenticatedUser?.sub,
              cognitos: getCognitosForNotification(
                userConfiguration,
                res?.teamsConfiguration
              ),
            },
          ],
          res?.workOrderId
        );

        saveAddedLogs({
          recordId: res?.workOrderId,
          recordName: res?.workOrderId,
          category: "Work Orders",
        });

        if (defaultData?.defectId) {
          refreshTable({
            workOrderId: res?.workOrderId,
            itemsToUpdate: body?.workOrderItems?.map((el) => el?.defectId),
          });
        }
        body?.workOrderItems?.forEach((item) => {
          API.put("fleetDefects", `/fleetDefects/${item?.defectId}`, {
            body: {
              workOrderId: res?.workOrderId,
              defectStatus: "In Progress",
            },
          }).then((res) => {
            broadcastNotification(
              "11",
              "defectStatusChange",
              [
                {
                  common: userConfiguration.nameOfUser,
                  oldDefectStatus: defaultData?.defectStatus ?? "New",
                  defectStatus: res.defectStatus,
                  defectName: res.defectName,
                  fleetName: res.fleetName,
                },
                {
                  userName: userConfiguration.nameOfUser,
                  currentUser: userConfiguration?.cognitoUserId,
                  cognitos: getCognitosForNotification(
                    userConfiguration,
                    defaultData?.teamsConfiguration ?? []
                  ),
                },
              ],
              res.defectId
            );
          });
        });

        closeModal(res.workOrderId);
      })
      .catch((err) => {
        console.log("Error Creating Order: ", err);
        message.error({
          content: "Something went wrong while saving",
          key: "workOderSave",
        });
        closeModal();
      });
  }

  useEffect(() => {
    //#region DEFAULT DATA EFFECT
    if (defaultData?.fleetId) {
      form.setFieldValue("selectedTruck", defaultData?.fleetId);
      getDefectsForFleet(defaultData?.fleetId);
    }

    if (requestObject?.fleetName) {
      form.setFieldValue("selectedTruck", requestObject?.fleetName);
      getDefectsForFleet(requestObject?.fleetName);
    }
  }, [defaultData, requestObject]);

  let isTruckSelected = !!form.getFieldValue("selectedTruck");
  let mil = "";
  if (isTruckSelected) {
    mil = formatNumber(
      fleets?.find((el) => el?.fleetId === form.getFieldValue("selectedTruck"))
        ?.odometerReading,
      { unit: "mile", fallback: "--", fixed: 0 }
    );
  }

  function onTeamSelect(_, data) {
    //#region TEAM SELECT
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((e) => e),
      },
    ]);
  }

  function onTeamDeselect(val, data) {
    //#region TEAM DESELECT
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el?.value !== teamName));
    if (form.getFieldValue("dispatchTeam")?.length < 1) {
      setSelectedTeam([]);
    }
  }

  const closeModal = (id) => {
    //#region CLOSE MODAL
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);

    if (id) {
      navigate(`/workOrders/${id}`);
    }
  };

  //#region JSX
  return (
    <Modal
      {...{
        open,
        centered: true,
        onCancel() {
          setCancelModalVisible(true);
        },
        title: "New Work Order",
        afterOpenChange: (event) => {
          if (event) {
            updateDocumentTitle({ newTitle: "New Work Order" });
          }
        },
        closable: true,
        closeIcon: <XIcon />,
        className: `newWorkOrderModalContainer ${
          isDarkMode && "newWorkOrderModalContainerDark"
        }`,
        footer: [
          <MondayButton
            Icon={<XIcon />}
            onClick={() => setCancelModalVisible(true)}
            className="mondayButtonRed"
            key="cancel-btn"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            Icon={<TickIcon width={17} height={17} />}
            disabled={!isTruckSelected}
            key="save-btn"
            onClick={() => {
              if (!selectedItems?.length) {
                setCancelModalVisible("empty");
              } else {
                validateForm(form, saveHandler);
              }
            }}
          >
            Save
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        <div className="infoSection">
          <InfoIcon />
          <span>
            This Work Order will contain {selectedItems?.length} items
          </span>
        </div>
        {lastInspectedItems.length ? (
          <WarningInfoCard
            title="Warning"
            description={
              <span>
                Careful, some defects for the vehicle are included in other work
                orders outside the automatic programs:&nbsp;
                <span style={{ fontWeight: 600 }}>
                  {lastInspectedItems.join(", ")}
                </span>
              </span>
            }
          />
        ) : null}
        <div
          className={`${
            isTruckSelected
              ? "mainContainerSelected"
              : "mainContainerUnSelected"
          }`}
        >
          <div className="truckSelectSection">
            <InputComponent
              {...{
                label: "Fleet Name",
                type: "select",
                dropdownClassName: isDarkMode && "darkDropDown",
                showSearch: true,
                allowClear: false,
                getPopupContainer: document.body,
                onSelect(val) {
                  setSelectedItems([]);
                  setDefectsForFleet([]);
                  setLoading(true);
                  getDefectsForFleet(val);
                  form.setFieldValue("inventoryItems", []);
                  let truck = fleets?.find(({ fleetId }) => fleetId === val);
                  setSelectedTeam(truck?.teamsConfiguration || []);
                  form.setFieldValue(
                    "workOrderTeam",
                    (truck?.teamsConfiguration || [])?.map(
                      (item) => item?.value
                    )
                  );
                },
                onClear() {
                  setSelectedItems([]);
                  form.setFieldValue("inventoryItems", []);
                },
                required: true,
                placeholder: "Select a truck...",
                customOptions: fleets?.map((el) => ({
                  label: el?.fleetName,
                  value: el?.fleetId,
                })),
                disabled: !!defaultData?.fleetId,
                style: { minWidth: "300px", width: "100%" },
                formItemName: "selectedTruck",
              }}
            />
            {isTruckSelected && (
              <span className="truckMileage">
                <strong>Mileage:&nbsp;</strong>
                {mil}
              </span>
            )}
          </div>
          {!defectsForFleet?.length && isTruckSelected && (
            <div className="truckWarningSection">
              <WarningTruckIcon />
              <div className="warningInfo">
                <strong>No Defects Found</strong>
                <span>Please try selecting another fleet</span>
              </div>
            </div>
          )}
          {!!isTruckSelected && defectsForFleet?.length ? (
            <div className="inventorySelection">
              <InputComponent
                {...{
                  label: "Inventory Items",
                  type: "select",
                  mode: "multiple",
                  required: false,
                  formItemName: "inventoryItems",
                  dropdownClassName: isDarkMode && "darkDropDown",
                  customOptions: inventoryItems
                    ?.filter(({ itemQuantity }) => !!parseInt(itemQuantity))
                    ?.map((el) => ({
                      value: el?.itemId,
                      label: el?.itemName,
                    })),
                  dropdownRender: (menu) => (
                    <>
                      {menu}
                      <div className="openInventoryLink">
                        <Button
                          type="primary"
                          style={{ borderRadius: "5px" }}
                          onClick={() => setNewInventoryOpen(true)}
                        >
                          Create new Item
                        </Button>
                      </div>
                    </>
                  ),
                  style: {
                    width: "100%",
                  },
                }}
              />
            </div>
          ) : (
            <></>
          )}
        </div>
        <div
          className={`${
            isTruckSelected && defectsForFleet?.length
              ? "workOrderTableContainer"
              : "workOrderTableContainerVoid"
          }`}
        >
          <Spin spinning={loading}>
            {isTruckSelected ? (
              loading ? (
                <Alert
                  {...{
                    message: "Searching defects for fleet",
                    type: "info",
                  }}
                />
              ) : (
                defectsForFleet?.length !== 0 && (
                  <WorkOrderTable
                    {...{
                      dataSource: defectsForFleet,
                      form,
                      selectedItems,
                      setSelectedItems,
                      isDarkMode,
                    }}
                  />
                )
              )
            ) : defaultData?.fleetId ? (
              <WorkOrderTable
                {...{
                  dataSource: defectsForFleet,
                  form,
                  selectedItems,
                  setSelectedItems,
                }}
              />
            ) : (
              ""
            )}
          </Spin>
        </div>
        {isTruckSelected && (
          <WorkOrderAssign {...{ form, mechanics, isDarkMode }} />
        )}
        {isTruckSelected && getAccessRights("Teams") && (
          <div className="teams-section">
            {getAccessRights("Teams")?.write ? (
              <InputComponent
                {...{
                  label: "Team",
                  required: true,
                  allowClear: false,
                  formItemName: "workOrderTeam",
                  placeholder: "Select a team...",
                  type: "select",
                  dropdownClassName: isDarkMode && "darkDropDown",
                  mode: "multiple",
                  showSearch: true,
                  getPopupContainer: document.body,
                  onSelect: onTeamSelect,
                  onDeselect: onTeamDeselect,
                  style: { maxWidth: "45%" },
                  customOptions: allTeams?.map((team) => ({
                    label: team.teamName,
                    value: team.teamName,
                    members: team.members?.map(
                      ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                        identityId,
                        nameOfUser,
                        cognitoUserId,
                      })
                    ),
                  })),
                }}
              />
            ) : (
              <></>
            )}
            {!!selectedTeam?.length && getAccessRights("Teams")?.write ? (
              <MondayButton
                {...{
                  onClick() {
                    setTeamModalOpen(true);
                  },
                  Icon: <AddIcon />,
                }}
              >
                Members
              </MondayButton>
            ) : (
              <></>
            )}
            {getAccessRights("Teams") && (
              <DynamicAvatars
                title={"Work Order Team"}
                isDarkMode={isDarkMode}
                members={selectedTeam?.reduce(
                  (acc, val) => [...acc, ...(val?.members || [])],
                  []
                )}
              />
            )}
          </div>
        )}
        {isTruckSelected ? (
          <FormTextEditor
            {...{
              label: "",
              onChange(val) {
                setComments(val);
              },
              value: comments,
              style: {
                width: "100%",
              },
            }}
          />
        ) : (
          <></>
        )}
      </Form>
      {newInventoryOpen && (
        <NewFleetInventory
          {...{
            visible: newInventoryOpen,
            setVisible: setNewInventoryOpen,
            refreshTable(obj) {
              setInventoryItems(inventoryItems.concat(obj));
            },
          }}
        />
      )}
      {teamModalOpen && (
        <DynamicTeamModal
          {...{
            open: teamModalOpen,
            setOpen: setTeamModalOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions() {
              setTeamModalOpen(false);
            },
            onSave: (data) => {
              form.setFieldValue("workOrderTeam", data || []);
            },
            proppedAllTeams: allTeams,
          }}
        />
      )}
      <WarningModal
        visible={!!cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          {cancelModalVisible === "empty" ? (
            <>
              <p>
                <strong>
                  You are about to create a work order with no defective items
                </strong>
              </p>
              <p>Are you sure you want to continue?</p>
            </>
          ) : (
            <p>Are you sure you want to cancel?</p>
          )}
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={() => {
                if (cancelModalVisible === "empty") {
                  validateForm(form, saveHandler);
                } else {
                  closeModal();
                }
              }}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
    </Modal>
  );
}

export default NewWorkOrderModal;

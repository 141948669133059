function teamSelectHandler({
  team,
  selectedTeamTmp,
  setSelectedTeamTmp,
  comparator,
}) {
  const selectedTeamTmpCopy = [...selectedTeamTmp];
  let teamIndex = -1;
  if (comparator === "teamId") {
    teamIndex = selectedTeamTmpCopy.findIndex((t) =>
      (t?.teamId || [])?.includes(team.teamId)
    );
  } else if (comparator === "teamName") {
    teamIndex = selectedTeamTmpCopy?.findIndex(
      (t) => t?.value === team?.teamName
    );
  }

  if (teamIndex > -1) {
    if (selectedTeamTmpCopy[teamIndex]?.value !== "Team") {
      selectedTeamTmpCopy.splice(teamIndex, 1);
    } else {
      const oldMembers = selectedTeamTmpCopy[teamIndex].members;
      const newMembers = oldMembers.filter((member) => {
        let memberId = member?.identityId;
        return !team.members.some((el) => el.identityId === memberId);
      });
      selectedTeamTmpCopy[teamIndex].members = newMembers;
      if (comparator === "teamId") {
        selectedTeamTmpCopy[teamIndex].teamId = selectedTeamTmpCopy[
          teamIndex
        ].teamId.filter((el) => el !== team.teamId);
      }
    }
  } else {
    const allOtherMembers = selectedTeamTmpCopy.flatMap((t) => t.members);
    const newMembers = team.members.filter((member) => {
      let memberId = member?.identityId;
      return !allOtherMembers.some((el) => el.identityId === memberId);
    });
    selectedTeamTmpCopy.push({
      value: team.teamName,
      members: newMembers,
      ...(comparator === "teamId" ? { teamId: [team.teamId] } : {}),
    });
  }
  setSelectedTeamTmp(selectedTeamTmpCopy);
}

export default teamSelectHandler;

import { ExportPreview } from "../../../commonComponents";
import { safetyContactsStyles } from "../../Projects/ProjectSafetyReport/helpers";
import AddSafetyContacts from "../../Safety/SafetyModal/components/SafetyExportPreview/components/AddSafetyContacts";
import SortReportFields from "../../Safety/SafetyModal/components/SafetyExportPreview/components/SortReportFields";
import {
  convertSafetyReport,
  initialExportData,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "../../Safety/SafetyModal/components/SafetyExportPreview/helpers";
import { safetyPdfBody } from "../../Safety/safetyPdf";

function IncidentExportPreview({
  wordPreview,
  setWordPreview,
  rowObject,
  incidentFormData,
  selectedTeam,
  incidentCategory,
  witnessCount,
  witnessSignings,
  defaultId,
  userConfiguration,
  accessToken,
  rowObjectKey,
  currentProject,
}) {
  return wordPreview ? (
    <ExportPreview
      {...{
        open: wordPreview,
        onCancel: () => setWordPreview(false),
        modalTitle: "Incident Report",
        documentTitle: "Incident Report for" + rowObject?.["incidentName"],
        getInitialData: async () => {
          return await initialExportData({
            modalType: "incident",
            rowObject,
            formFields: incidentFormData?.dynamicFields,
            selectedTeam,
            safetyPdfBody,
            safetyCategory: incidentCategory,
            witnessCount,
            witnessSignings,
            defaultId,
            safetyFormData: { crews: incidentFormData?.crews },
            userConfiguration,
            accessToken,
            rowObjectKey,
            currentProject,
          });
        },
        asyncInitialData: true,
        keyName: "incident " + incidentCategory,
        applyFilters: (filters, data) =>
          safetyExportApplyFilters(filters, data),
        convertData: (data) => convertSafetyReport(data),
        tabs: [
          {
            key: "sort",
            label: "Modify Fields",
            component: SortReportFields,
          },
          {
            key: "contacts",
            label: "Safety Contacts",
            component: AddSafetyContacts,
          },
        ],
        customStyles: { ...SAFETY_REPORT_STYLES, ...safetyContactsStyles },
        defaultHeaderSection: [
          {
            text: {
              paragraphs: [
                {
                  text:
                    rowObject?.["incidentName"] +
                    " " +
                    incidentCategory +
                    " Report",
                  class: "safety-title",
                  bold: true,
                  header: true,
                },
              ],
            },
          },
          {
            text: {
              paragraphs: [
                {
                  text: "Date: " + new Date().toLocaleDateString(),
                  class: "safety-section-header no-margin",
                  bold: true,
                },
              ],
            },
          },
        ],
      }}
    />
  ) : null;
}

export default IncidentExportPreview;

import { API } from "aws-amplify";
import { useEffect } from "react";

import { useRedux } from "../../../../../hooks";
import DescriptionButton from "../../../../../Accounting/components/CreateInvoice/components/DescriptionButton";

const cellStyle = {
  backgroundColor: "#1F212D",
  color: "white",
  minWidth: 400,
  minHeight: 40,
};

const popoverStyle = { backgroundColor: "#1F212D", color: "white" };

const DescriptionCreditMemo = ({ onDescriptionChange, ...props }) => {
  const [isWritable] = useRedux("creditMemoIsWritable");
  const [darkMode] = useRedux("chargesDarkMode");

  const [accountingTemplates, setAccountingTemplates] = useRedux(
    "accountingTemplates",
    [],
    false
  );

  useEffect(() => {
    if (!accountingTemplates.length)
      API.get("accountingTemplates", "/accountingTemplates")?.then(
        setAccountingTemplates
      );
  }, []);

  return (
    <DescriptionButton
      {...props}
      darkMode={darkMode}
      className="chargeInvoiceDesc"
      cellStyle={cellStyle}
      popoverStyle={popoverStyle}
      disabled={!!props.data.invoiceData ? !isWritable : false}
      accountingTemplates={accountingTemplates}
      onDescriptionChange={(description) =>
        onDescriptionChange({ ...props, description })
      }
    />
  );
};

export default DescriptionCreditMemo;

import * as Types from "../types";

export const REDUCER_INIT: Types.MaintenanceReducerType = {
  fleet: [],
  drivers: [],
  defects: [],
  locations: [],
  workOrders: [],
  inspections: [],
  inventoryItems: [],
  servicePrograms: [],
  serviceMaintenance: [],
  reports: {
    reportErrors: [],
    vehicleReports: [],
  },
};

const ID_KEYS = {
  fleet: "fleetId",
  drivers: "driverId",
  defects: "defectId",
  inspections: "inspectionId",
  maintenances: "maintenanceId",
  inventoryItems: "itemId",
  servicePrograms: "programId",
  workOrders: "workOrderId",
  serviceMaintenance: "serviceId",
};

function getIdKey(key: string): string {
  return ID_KEYS[key];
}

export function DATA_REDUCER(
  state: typeof REDUCER_INIT,
  request: Types.MaintenanceRequestType
) {
  let tmpNewObj = {
    ...state,
  };

  switch (request.action) {
    case "SETUP":
      tmpNewObj = {
        ...tmpNewObj,
        ...request.payload,
      };
      break;
    case "ADD":
      for (const key in request.payload) {
        tmpNewObj[key] = [...tmpNewObj[key], ...request.payload[key]];
      }
      break;
    case "DELETE":
      for (const key in request.payload) {
        const idKey = getIdKey(key);
        if (!idKey) {
          return;
        }

        const idsSet = new Set<string>(
          request.payload[key].map((e) => e[idKey])
        );

        tmpNewObj[key] = tmpNewObj[key].filter((e) => !idsSet.has(e[idKey]));
      }
      break;
    case "EDIT":
      for (const key in request.payload) {
        let idKey = getIdKey(key);
        if (!idKey) {
          return;
        }

        for (const changeElement of request.payload[key]) {
          const index = tmpNewObj[key].findIndex(
            (e) => e[idKey] === changeElement[idKey]
          );

          if (index > -1) {
            tmpNewObj[key][index] = {
              ...tmpNewObj[key][index],
              ...changeElement,
            };
          }
        }
      }
      break;
  }

  return tmpNewObj;
}

import { Modal, Form, message, Tooltip, Collapse } from "antd";
import { XIcon } from "../../Communication/assets";
import {
  MultiLevelTreeLogs,
  NextSteps,
  ProgressComponent,
  RichTextEditor,
  Stepper,
} from "../../../commonComponents";
import { useEffect, useMemo, useState } from "react";
import "./NewToDo.scss";
import { RenderDynamicComponents } from "../../../Header/forms/Components";
import { inputFields, footerButtons } from "./toDoFormData";
import WarningSection from "./WarningSection";
import { InputComponent, LabeledInput } from "../../Fleet/components";
import { useMediaQuery } from "react-responsive";
import { showErrorMsg, showLoadingMsg, showSuccessMsg } from "src/utils";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import ToDoComment from "../components/ToDoComment/ToDoComment";
import { getRelatedToRecords } from "./helpers";
import { createTodoPdf, emailTodo } from "./todoPdf";
import EmailBox from "../../Communication/components/EmailBox/EmailBox";
import { driveApi } from "../../../../integrations/DriveRequest";
import { useProgressComponent, useUploadedFiles } from "src/hooks";
import { InspectionModal } from "../../XComponents";
import TasksBasePage from "../../TaskManager/Tasks/TasksBasePage";
import { DocUploader } from "../../Documentation/View/components/DocumentationModal/components";
import { useCreateDriveFolders } from "src/hooks/useCreateDriveFolders";
import { parseInTz } from "../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { API } from "aws-amplify";
import { useNextStep, useProgramFields } from "../../../../hooks";
import { SettingIcon } from "../../../Header/subcomponents";
import { getLatLng } from "../../../Header/forms/RequestFormModal/components/RequestFormSection/components/GeofenceMap/utils";
import GeofenceTodoModal from "./GeofenceTodoModal";
import { fetchAllData, lazyFetch } from "../../../../utils";
import ToDoExportPreview from "../components/ToDoExportPreview/ToDoExportPreview";
import { dayjsUserTimeZone } from "../../../DateComponents/contants/DayjsUserTimeZone";

/**
 * Represents the form for creating or editing a ToDo.
 *
 * @component
 * @param {boolean} visible - Determines if the form is visible.
 * @param {function} setVisible - Sets the visibility of the form.
 * @param {function} setRowData - Sets the row data.
 * @param {string|null} recordId - The ID of the record.
 * @param {Object|null} rowObject - The row object.
 * @param {function} setRowObject - Sets the row object.
 * @param {string|null} category - The category of the ToDo.
 * @param {string|null} recordName - The name of the record.
 * @param {function} onAddTodo - Handles the addition of a ToDo.
 * @param {function} onEditTodo - Handles the editing of a ToDo.
 * @param {function} onDeleteToDo - Handles the deletion of a ToDo.
 * @param {boolean} hideCreateTaskModal - Determines if the create task modal should be hidden.
 * @param {boolean} isCreate - Determines if the form is for creating a new ToDo.
 * @returns {JSX.Element} The NewToDo component.
 */
const NewToDo = ({
  cloneModal = false,
  visible,
  setVisible = () => {},
  setRowData = () => {},
  recordId = null,
  rowObject = null,
  setRowObject = () => {},
  category: propCategory = null,
  recordName = null,
  onAddTodo = () => {},
  onEditTodo = () => {},
  onDeleteToDo = () => {},
  hideCreateTaskModal = false,
  isCreate = false,
  customLinkTo = null,
  clonedTodo,
  setClonedTodo,
}) => {
  const { todoId = null } = rowObject || {};
  const [form] = Form.useForm();

  const category = propCategory
    ? propCategory
    : rowObject?.todoCategory || window.location.pathname.split("/")[1];

  const uidRegex =
    /^[0-9a-fA-F]{8}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{4}\b-[0-9a-fA-F]{12}$/;

  const [warningModal, setWarningModal] = useState({
    visible: false,
    message: "",
    type: "",
  });

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Todos",
    actionType: warningModal?.type === "edit" ? "Update" : "Create",
  });

  const [changes, setChanges] = useState(false);
  const [currentStep, setCurrentStep] = useState(0);

  const [logsVisible, setLogsVisible] = useState(false);
  const [editLogs, setEditLogs] = useState([]);
  const [records, setRecords] = useState([]);
  const [relatedTo, setRelatedTo] = useState(
    rowObject?.hasOwnProperty("relatedTo") ? rowObject.relatedTo : null
  );
  const [loadingRecords, setLoadingRecords] = useState(false);
  const [pdfId, setPdfId] = useState(null);
  const [emailBox, setEmailBox] = useState(false);
  const [tasksVisible, setTasksVisible] = useState(false);
  const [exportPreview, setExportPreview] = useState(false);
  const [
    { topicCategories = [] },
    { base64 },
    { accessToken },
    { userConfiguration },
    { programFields },
    { isDarkMode },
    { hotCredentials },
  ] = useSelector((state) => [
    state.topicCategories,
    state?.base64,
    state?.accessToken,
    state?.userConfig,
    state?.programFields,
    state?.darkMode,
    state?.hotCredentials,
  ]);

  // =================== Upload ===================
  const [uploadHandlers, deleteHandlers] = useUploadedFiles(
    rowObject?.googleDriveUploads || []
  );
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders("Todos");
  const todosHotCredential = hotCredentials?.find(
    (obj) => obj.credentialId === "22"
  );

  const { deleteDriveItem } = driveApi({ accessToken });
  // =================== Upload ===================

  async function deleteDriveFolder() {
    if (!!rowObject?.googleDriveFolderIds?.todoObject) {
      await deleteDriveItem(rowObject?.googleDriveFolderIds?.todoObject);
    } else if (driveFunctions?.parentFolderId) {
      await deleteDriveItem(driveFunctions?.parentFolderId);
    }
  }

  const {
    savedRecord,
    handleStepExit,
    isNextStepOpen,
    nextStepHandler,
    handleSavedRecord,
  } = useNextStep();

  const allFieldsDisabled =
    rowObject?.todoStatus === "Done" || rowObject?.todoStatus === "Cancelled";

  const {
    ["Statuses Of Categories"]: { ["To Do"]: statuses },
    ["To Do Subtopics"]: toDoSubtopics,
  } = useProgramFields();

  const steps = statuses?.map(({ statusName }) => {
    return { title: statusName, disabled: false };
  });

  /**
   * Creates a temporary folder with the given name.
   * If no name is provided, the default name "todos" will be used.
   *
   * @param {string} name - The name of the temporary folder (optional).
   * @returns {Promise<{ parentFolderId: string, allFolders: Array<string> }>} - An object containing the ID of the parent folder and an array of all folders.
   */
  async function createTmpFolder(name) {
    const folderName = name ? name : "todos";
    const { folders, parentFolder } = await driveFunctions.create({
      // Formats the date to the user's timezone.
      parentFolderName: `${folderName} ${dayjsUserTimeZone().format(
        "DD/MM/YYYY HH:mm:ss"
      )}`,
      parentId: todosHotCredential?.credentialValue,
    });
    return { parentFolderId: parentFolder, allFolders: folders };
  }

  useEffect(() => {
    if (!!clonedTodo) {
      const { recordName, relatedTo, subRecordName, todoDueDate, todoStatus } =
        clonedTodo;

      const topicObjects = topicCategories?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.categoryName]: {
            table: curr?.apiName,
            searchKey: curr?.rowName,
            idKey: curr?.primaryKey,
          },
        }),
        {}
      );

      if (relatedTo) {
        let relatedToOptions = (toDoSubtopics || [])
          ?.find((e) => {
            if (!!category) {
              return e?.topicName === category?.capitalize();
            } else {
              return (
                e?.topicName ===
                window.location.pathname.split("/")?.[1]?.capitalize()
              );
            }
          })
          ?.subTopics?.map(({ subTopicName = "", ...rest }) => {
            return {
              label: subTopicName,
              value: subTopicName,
              filterData: { ...rest },
            };
          });
        getRelatedToRecords({
          value: relatedTo,
          records,
          recordId: rowObject?.todoRecordId
            ? rowObject?.todoRecordId
            : recordId ||
              location.pathname.match(uidRegex)?.[0]?.replace("/", ""),
          topicObjects,
          relatedToOptions,
          setLoadingRecords,
          setRecords,
          userConfiguration,
        });
        setRelatedTo(relatedTo);
      }

      form.setFieldsValue({
        ...clonedTodo,
        status: todoStatus,
        toDoCategory: category || window.location.pathname.split("/")[1],
        categoryRelatedTo: recordName,
        relatedRecord:
          !!subRecordName && subRecordName.hasOwnProperty("id")
            ? subRecordName.id
            : "",
        dueDate: dayjsUserTimeZone(todoDueDate)?.format("MM/DD/YYYY"), // Formats the due date to the user's timezone.
        dueTime: dayjsUserTimeZone(todoDueDate)?.format("hh:mm A"), // Formats the due time to the user's timezone.
      });
      setCurrentStep(
        statuses?.findIndex(({ statusName }) => statusName === todoStatus)
      );
    }
  }, [clonedTodo]);

  useEffect(() => {
    if (rowObject && !clonedTodo) {
      const {
        todoDetails: description,
        todoDetails,
        todoDueDate: due,
        todoDueDate,
        todoName: title,
        todoName,
        todoStatus: status,
        relatedTo,
        subRecordName,
        recordName,
        comments,
      } = rowObject;

      const topicObjects = topicCategories?.reduce(
        (acc, curr) => ({
          ...acc,
          [curr?.categoryName]: {
            table: curr?.apiName,
            searchKey: curr?.rowName,
            idKey: curr?.primaryKey,
          },
        }),
        {}
      );

      if (relatedTo) {
        let relatedToOptions = (toDoSubtopics || [])
          ?.find((e) => {
            if (!!category) {
              return e?.topicName === category?.capitalize();
            } else {
              return (
                e?.topicName ===
                window.location.pathname.split("/")?.[1]?.capitalize()
              );
            }
          })
          ?.subTopics?.map(({ subTopicName = "", ...rest }) => {
            return {
              label: subTopicName,
              value: subTopicName,
              filterData: { ...rest },
            };
          });
        getRelatedToRecords({
          value: relatedTo,
          records,
          recordId: rowObject?.todoRecordId
            ? rowObject?.todoRecordId
            : recordId ||
              location.pathname.match(uidRegex)?.[0]?.replace("/", ""),
          topicObjects,
          relatedToOptions,
          setLoadingRecords,
          setRecords,
          userConfiguration,
        });
        setRelatedTo(relatedTo);
      }

      form.setFieldsValue({
        description,
        title,
        status,
        relatedTo,
        comments,
        dueDate: dayjsUserTimeZone(due)?.format("MM/DD/YYYY"), // Formats the due date to the user's timezone.
        toDoCategory: category || window.location.pathname.split("/")[1],
        categoryRelatedTo: recordName,
        datePickerdueDate: dayjsUserTimeZone(due), // Formats the date picker due date to the user's timezone.
        dueTime: dayjsUserTimeZone(due)?.format("hh:mm A"), // Formats the due time to the user's timezone.
        relatedRecord: subRecordName?.id,
        todoDetails,
        todoDueDate,
        todoName,
      });
      setCurrentStep(
        statuses?.findIndex(({ statusName }) => statusName === status)
      );
    }

    if (!rowObject && !cloneModal) {
      form.setFieldsValue({
        dueDate: dayjs().format("MM/DD/YYYY"),
        dueTime: dayjs().add(1, "hour").format("hh:mm A"),
        datePickerdueDate: dayjs(),
        toDoCategory: propCategory,
        categoryRelatedTo: recordName,
      });
    }
  }, [rowObject]);

  useEffect(() => {
    !!changes && form.setFieldValue("relatedRecord", null);
  }, [relatedTo]);

  useEffect(() => {
    (async () => {
      if (!!rowObject?.todoId) {
        const foldersEmpty = _.isEmpty(rowObject?.googleDriveFolderIds);
        let folders;
        if (foldersEmpty) {
          const { folders: allFolders } = await driveFunctions.create({
            parentFolderName: `${rowObject?.todoName} ${parseInTz(
              rowObject?.createdAt
            ).format("DD/MM/YYYY HH:mm:ss")}`,
            parentId: todosHotCredential?.credentialValue,
          });
          folders = allFolders;
          await API.put("todos", `/todos/${rowObject.todoId}`, {
            body: {
              googleDriveFolderIds: folders,
            },
          }).catch((err) => console.log("Error : ", err));
        }
      } else {
        await createTmpFolder();
      }
    })();
  }, []);

  const isMobileView = useMediaQuery({ query: "(max-width: 575px)" });

  const onLogButtonClickHandler = () => {
    if (editLogs?.length > 0) {
      setLogsVisible(true);
      return;
    }

    showLoadingMsg({ content: "Loading Logs. Please wait..." });

    if (!rowObject?.todoId) {
      showErrorMsg({ content: "Error Loading Logs." });
      return;
    }

    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: rowObject?.todoId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((result) => {
        setEditLogs(result);
        showSuccessMsg({ content: "Logs Loaded Successfully" });
        setLogsVisible(true);
      })
      .catch((error) => {
        showErrorMsg({ content: "Error Loading Logs" });
      });
  };

  const formFields = useMemo(() => {
    return inputFields({
      records,
      category: category || window.location.pathname.split("/")[1],
      recordId: !!rowObject?.todoRecordId
        ? rowObject?.todoRecordId
        : recordId ||
          window.location.pathname.match(uidRegex)?.[0]?.replace("/", ""),

      relatedTo,
      setRecords,
      setRelatedTo,
      programFields,
      loadingRecords,
      rowObject,
      topicCategories,
      disabled: allFieldsDisabled,
      form,
      isDarkMode,
      setLoadingRecords,
      userConfiguration,
      toDoSubtopics,
      isCreate,
      setChanges,
      clonedTodo,
    });
  }, [records, relatedTo, allFieldsDisabled]);

  function onCreatePdf() {
    createTodoPdf({
      base64,
      rowObject,
      relatedTo,
      topicCategories,
    });
  }

  async function onEmail({}) {
    message.loading({
      content: "Opening Email",
      key: "emailLoading",
      duration: 0,
    });

    await emailTodo({
      base64,
      relatedTo,
      rowObject,
      accessToken,
      topicCategories,
    })
      .then((res) => {
        setPdfId(res?.id);
        message.destroy("emailLoading");
        setEmailBox(true);
      })
      .catch((err) => {
        message.destroy("emailLoading");
        showErrorMsg({ content: "Something went wrong!" });
        console.error("Error saving tdo pdf to drive", err);
      });
  }

  const closeModal = () => {
    if (cloneModal) {
      setVisible(false);
      setClonedTodo();
    } else if (changes) {
      setWarningModal({
        visible: true,
        message: "Are you sure you want to cancel?",
        type: "cancel",
      });
    } else if (isCreate) {
      setRowObject(null);
      setVisible(false);
      deleteDriveFolder();
    } else {
      setRowObject(null);
      setVisible(false);
    }
  };

  const isAdmin =
    userConfiguration?.groupName === "Admin" ||
    userConfiguration?.groupName === "Super Admin";

  const openSettings = () => {
    window.open(`${window.location.origin}/settings/toDos`, "_blank");
  };

  // for geofence

  const [geoCoords, setGeoCoords] = useState();
  const [geoFenceInfo, setGeoFenceInfo] = useState([]);

  const onChangeSubCategoryRelatedTo =
    category === "projects" || category === "opportunities"
      ? Form.useWatch("relatedTo", form)
      : false;
  const onChangeRecordAddress = rowObject?.recordName || recordName || "";

  useEffect(() => {
    if (onChangeRecordAddress) {
      getLatLng(onChangeRecordAddress)
        .then((res) => setGeoCoords(res))
        .catch((err) => {
          console.log({ err });
          setGeoCoords();
        });
    }
  }, [onChangeRecordAddress]);

  const rowData = {
    projectAddress: onChangeRecordAddress || "",
    opportunityAddress: onChangeRecordAddress || "",
  };

  useEffect(() => {
    if (category === "projects" || category === "opportunities") {
      lazyFetch({
        tableName: category,
        filterKey:
          category === "projects"
            ? "projectId"
            : category === "opportunities"
            ? "opportunityId"
            : "",
        filterValue: recordId,
      }).then((res) => {
        if (res) {
          const proceedRes = Array.isArray(res)
            ? res?.[0]?.geoFenceInfo || ""
            : [res?.[0]?.geoFenceInfo];
          setGeoFenceInfo(proceedRes);
        }
      });
    }
  }, [recordId, category]);

  const handleCloneTodo = () => {
    setClonedTodo(rowObject);
  };

  return (
    <>
      <Modal
        {...{
          title: (
            <div className="new-todo-modal-header">
              <div className="title">
                {!todoId
                  ? `New To Do for ${rowObject?.recordName || recordName || ""}`
                  : `To Do for ${rowObject?.recordName || recordName || ""}`}
              </div>
              {isAdmin && (
                <Tooltip title="Open Configurations" placement="left">
                  <SettingIcon onClick={openSettings} />
                </Tooltip>
              )}
            </div>
          ),
          open: visible,
          onCancel: closeModal,
          closeIcon: <XIcon />,
          centered: true,
          className: `newToDoModal ${isDarkMode && "newToDoModalDark"}`,
          footer: footerButtons({
            userConfiguration,
            setWarningModal,
            closeModal,
            changes,
            todoId,
            allFieldsDisabled,
            setLogsVisible: onLogButtonClickHandler,
            changes,
            handleCloneTodo,
            onCreatePdf,
            onEmail,
            setTasksVisible,
            form,
            cloneModal,
            setExportPreview,
          }),
        }}
      >
        {!!isMobileView ? (
          <div className="stepper-section-mobile">
            <InputComponent
              {...{
                label: "Status",
                type: "select",
                dropdownClassName: isDarkMode && "darkDropDown",
                formItemName: "status",
                disabled: allFieldsDisabled,
                customOptions: statuses?.map(({ statusName, statusId }) => ({
                  value: statusId,
                  label: statusName,
                })),
                onSelect() {
                  setCurrentStep(e);
                },
                initialValue: currentStep,
              }}
            />
          </div>
        ) : (
          <Stepper
            {...{
              currentStep,
              setCurrentStep(e) {
                setCurrentStep(e);
                setChanges(true);
              },
              steps,
              statusesColors: statuses,
              stepRenderer: false,
              disabled: allFieldsDisabled,
            }}
          />
        )}
        <div className="todo-form-section">
          <div className="todo-form__leftSide">
            <DocUploader
              {...{
                folderId: !!rowObject?.googleDriveFolderIds
                  ? rowObject.googleDriveFolderIds?.todoObject
                  : !!driveFunctions.parentFolderId
                  ? driveFunctions.parentFolderId
                  : "",
                uploadedFiles,
                setUploadedFiles,
                onDelete: (e) => {
                  setChanges(true);
                  onDelete(e);
                },
                onChange: () => {
                  setChanges(true);
                },

                onPickerSuccess,
                responsiveWidth: 1400,
              }}
            />
          </div>
          <Form
            {...{
              form,
              onFieldsChange() {
                setChanges(true);
              },
              disabled: allFieldsDisabled,
            }}
          >
            {/* setUserTime to true for converting date to the user's local timezone */}
            {RenderDynamicComponents(formFields, { form, setUserTime: true })}
            {onChangeSubCategoryRelatedTo && (
              <Form.Item
                style={{
                  marginTop: "12px",
                  width: "100%",
                }}
                name="geoInfo"
              >
                <GeofenceTodoModal
                  {...{ geoCoords, setGeoFenceInfo, geoFenceInfo, rowData }}
                />
              </Form.Item>
            )}
            <LabeledInput
              {...{
                style: { width: "100%" },
                label: "Description",
                formItemName: "todoDetails",
              }}
            >
              <RichTextEditor
                {...{
                  form,
                  isDarkMode,
                  formItemName: "todoDetails",
                }}
              />
            </LabeledInput>
            <LabeledInput
              {...{
                form,
                formItemName: "comments",
                className: "todo-comment-section",
              }}
            >
              <ToDoComment
                {...{ form, setChanges, todoId: rowObject?.todoId, setRowData }}
              />
            </LabeledInput>
          </Form>
        </div>
        {!!warningModal?.visible && (
          <WarningSection
            {...{
              isCreate,
              deleteDriveFolder,
              createFolderId: driveFunctions.allFolders,
              warningModal,
              formattedFiles,
              setWarningModal,
              setRowData,
              userConfiguration,
              uploadedFiles,
              deleteDriveItem,
              filesToBeDeleted,
              form,
              setVisible,
              setClonedTodo,
              todoId: !!clonedTodo ? undefined : todoId,
              rowObject,
              setRowObject,
              relatedTo,
              recordId: rowObject?.todoRecordId
                ? rowObject?.todoRecordId
                : recordId ||
                  location.pathname.match(uidRegex)?.[0]?.replace("/", ""),
              statuses,
              currentStep,
              isDarkMode,
              onDeleteToDo,
              onEditTodo,
              onAddTodo,
              recordName: rowObject?.recordName || recordName || "",
              records,
              category,
              setVisibleCreationProgress,
              updateProgressStatus,
              nextStepHandler,
              handleSavedRecord,
              setChanges,
              closeModal,
              geoFenceInfo,
              geoCoords,
              customLinkTo,
            }}
          />
        )}
        {!!logsVisible && (
          <MultiLevelTreeLogs
            {...{
              visible: logsVisible,
              setVisible: setLogsVisible,
              logsData: editLogs,
              title: "Edit Logs for this To Do : " + rowObject?.todoName,
            }}
          />
        )}
        {!!emailBox && (
          <EmailBox
            {...{
              rowData: rowObject,
              isRedirected: [pdfId],
              onSave: async () => {
                if (!!pdfId) {
                  await driveApi({ accessToken }).deleteDriveItem(pdfId);
                  setPdfId(null);
                }
              },
              onClose: async () => {
                setEmailBox(false);
                if (!!pdfId) {
                  await driveApi({ accessToken }).deleteDriveItem(pdfId);
                  setPdfId(null);
                }
              },
              isFromCharge: true,
              setEmail() {},
              proppedTemplateCat: "To Do",
            }}
          />
        )}

        {visibleCreationProgress && creationProgresses && (
          <ProgressComponent
            {...{
              categoryName: "Todos",
              actionType: warningModal?.type === "edit" ? "Update" : "Create",
              visibleCreationProgress,
              creationProgresses,
              closeModal: () => {
                setWarningModal({
                  message: "",
                  type: "",
                  visible: false,
                });
                if (visibleCreationProgress?.todoId || hideCreateTaskModal) {
                  setVisibleCreationProgress(false);
                  // setVisible(false);
                  nextStepHandler(true, "Todos");
                  setVisible(false);
                } else {
                  setVisibleCreationProgress(false);
                  setVisible(false);
                }
              },
            }}
          />
        )}

        <ToDoExportPreview
          {...{
            exportPreview,
            setExportPreview,
            rowObject,
            topicCategories,
          }}
        />

        {tasksVisible && (
          <InspectionModal
            {...{
              visible: tasksVisible,
              onCancel: () => setTasksVisible(false),
              title: `Tasks for ${rowObject?.todoName || "this To Do"}`,
              width: 1600,
              footer: false,
              closable: true,
              close: <XIcon fill="#333238" />,
              className: `header-tasks-modal-light`,
            }}
          >
            <TasksBasePage
              {...{
                customTaskProps: {
                  rowData: rowObject,
                  taskTopic: "To Do",
                  taskTopicId: rowObject?.todoId,
                  taskRelatedTo: rowObject?.todoName,
                },
              }}
            />
          </InspectionModal>
        )}
      </Modal>
      {isNextStepOpen && (
        <NextSteps
          {...{
            isOpen: isNextStepOpen,
            title: "Todos",
            category: "Todos",
            handleStepExit,
            savedRecord,
            handleSavedRecord,
          }}
        />
      )}
    </>
  );
};

export default NewToDo;

import { message } from "antd";
import { filterTables } from "../../../../../../../../../utils";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../../../Estimations/DataEntryGrid/models/Service";

//this function generates application object that we save on db and we use on application tab
export const newApplicationGenerator = async ({
  previousApplication,
  estimationsSelected,
  selectedMonth,
  projectName,
  scheduleId,
  projectId,
  services,
  dueDate,
  previousApplications,
  MERGED_PREVIOUS_APPLICATION,
}) => {
  let tempPrevTotalLessRetainage =
    await getPreviousApplicationTotalLessRetainage(
      previousApplication,
      previousApplications?.filter(
        (el) => el?.applicationNo < previousApplication?.applicationNo
      )
    );
  return {
    projectId,
    projectName,
    scheduleId,
    applicationNo: previousApplication?.applicationNo + 1 || 1,
    periodTo: selectedMonth,
    prevRentalsRetainage: previousApplication?.prevRentalsRetainage || 0,
    prevTotalLessRetainage: tempPrevTotalLessRetainage,
    dueDate: dueDate,
    estimationsSelected,
    googleDriveLocationId: previousApplication?.googleDriveLocationId || "",
    accumulatedRentals: attachRentalsAccumulated(
      previousApplication,
      MERGED_PREVIOUS_APPLICATION
    ),
    rentalExcel: previousApplication?.rentalExcel || {},
    services: previousApplication // Get services from previous Application
      ? checkIfNewServices(
          previousApplication?.services?.filter(({ visible }) => !!visible),
          services?.filter(({ visible }) => !!visible)
        ) !== false
        ? retrieveUpdatedServices(
            previousApplication?.services?.filter(({ visible }) => !!visible),
            services?.filter(({ visible }) => !!visible)
          )?.map((service) => {
            //when service is hoist and we have previous application
            console.log("service", service);
            return checkIfServiceIsHoist(service) // If service is a hoist
              ? {
                  ...service, // Make a copy of service
                  serviceOptions: [
                    service?.serviceOptions?.[0]?.map((serviceOption) => ({
                      // Loop over serviceOptions
                      ...serviceOption, // Make a copy of serviceOption
                      amounts: serviceOption?.amounts
                        // ?.filter((amount) => amount?.balanceToFinish)
                        ?.map((amount) => ({
                          // Filter events that still have collectable amount
                          ...amount, // Make a copy of event
                          carriedRetainage:
                            getPreviousIncludedRetainage(amount),
                          // !!amount?.carriedRetainage
                          // 	? amount?.carriedRetainage || 0 + amount?.retainage || 0
                          // 	: amount?.retainage || 0,
                          previousRetainage:
                            (service?.retainage / 100) *
                            amount?.totalCompleated,
                          keptRetainage: 0,
                          isReduced: false,
                          collectable_amount:
                            amount?.collectable_amount - amount?.thisPeriod,
                          fromPrevious:
                            amount?.fromPrevious + amount?.thisPeriod,
                          thisPeriod: 0,
                          balanceToFinish:
                            amount?.amount -
                            (amount?.thisPeriod + amount?.fromPrevious),
                          totalCompleated:
                            amount?.thisPeriod + amount?.fromPrevious,
                          paymentDue: 0,
                          reducedBalance: 0,
                          charged: false, //the connection with charges
                          charges: [],
                          ...attachRetaiangeLogic(amount, false, true),
                        })),
                    })),
                  ],
                }
              : //when service is not hoist and we have previous application
                {
                  ...service,
                  serviceOptions: [],
                  amounts: service?.amounts?.map((amount) => ({
                    ...amount,
                    previousRetainage:
                      (amount?.retainagePercentage / 100) *
                      amount?.totalCompleated,
                    isReduced: false,
                    keptRetainage: 0,
                    paymentDue: 0,
                    carriedRetainage: getPreviousIncludedRetainage(amount),
                    collectable_amount:
                      amount?.collectable_amount - amount?.thisPeriod,
                    fromPrevious: amount?.fromPrevious + amount?.thisPeriod,
                    thisPeriod: 0,
                    balanceToFinish:
                      amount?.amount -
                      (amount?.thisPeriod + amount?.fromPrevious),
                    totalCompleated: amount?.thisPeriod + amount?.fromPrevious,
                    charged: false, //the connection with charges
                    charges: [],
                    reducedBalance: 0,
                    serviceOptions: [],
                    serviceAddons: [],
                    ...attachRetaiangeLogic(amount, false, false),

                    // // elevation data
                    // serviceOptions: amount?.serviceOptions?.map(
                    //   (elevationData) => ({
                    //     ...elevationData,
                    //     items: elevationData?.items?.map((pliData) => ({
                    //       ...pliData,
                    //       fromPrevious:
                    //         pliData?.fromPrevious + pliData?.thisPeriod,
                    //       lock: false,
                    //       thisPeriod: 0,
                    //       collectableAmount:
                    //         pliData?.balanceToFinish - pliData?.retainage,
                    //     })),
                    //   })
                    // ),
                    // // addons data
                    // serviceAddons: amount?.serviceAddons?.map((addonsData) => ({
                    //   ...addonsData,
                    //   fromPrevious:
                    //     addonsData?.fromPrevious + addonsData?.thisPeriod,
                    //   lock: false,
                    //   thisPeriod: 0,
                    //   collectableAmount:
                    //     addonsData?.balanceToFinish - addonsData?.retainage,
                    // })),
                  })),
                };
          })
        : previousApplication?.services
            ?.filter(({ visible }) => !!visible)
            ?.map((service) => {
              //when service is hoist and we have previous application
              return checkIfServiceIsHoist(service) // If service is a hoist
                ? {
                    ...service, // Make a copy of service
                    serviceOptions: [
                      service?.serviceOptions?.[0]?.map((serviceOption) => ({
                        // Loop over serviceOptions
                        ...serviceOption, // Make a copy of serviceOption
                        amounts: serviceOption?.amounts
                          // ?.filter((amount) => amount?.balanceToFinish)
                          ?.map((amount) => ({
                            // Filter events that still have collectable amount
                            ...amount, // Make a copy of event
                            carriedRetainage:
                              getPreviousIncludedRetainage(amount),
                            // !!amount?.carriedRetainage
                            // 	? amount?.carriedRetainage || 0 + amount?.retainage || 0
                            // 	: amount?.retainage || 0,
                            previousRetainage:
                              (service?.retainage / 100) *
                              amount?.totalCompleated,
                            isReduced: false,
                            keptRetainage: 0,
                            collectable_amount:
                              amount?.collectable_amount - amount?.thisPeriod,
                            fromPrevious:
                              amount?.fromPrevious + amount?.thisPeriod,
                            thisPeriod: 0,
                            paymentDue: 0,
                            balanceToFinish:
                              amount?.amount -
                              (amount?.thisPeriod + amount?.fromPrevious),
                            totalCompleated:
                              amount?.thisPeriod + amount?.fromPrevious,
                            charged: false,
                            reducedBalance: 0,
                            ...attachRetaiangeLogic(amount, false, true),
                            charges: [],
                            //the connection with charges
                          })),
                      })),
                    ],
                  }
                : //when service is not hoist and we have previous application
                  {
                    ...service,
                    amounts: service?.amounts?.map((amount) => ({
                      ...amount,
                      previousRetainage:
                        (amount?.retainagePercentage / 100) *
                        amount?.totalCompleated,
                      isReduced: false,
                      carriedRetainage: getPreviousIncludedRetainage(amount),
                      collectable_amount:
                        amount?.collectable_amount - amount?.thisPeriod,
                      fromPrevious: amount?.fromPrevious + amount?.thisPeriod,
                      thisPeriod: 0,
                      paymentDue: 0,
                      keptRetainage: 0,

                      balanceToFinish:
                        amount?.amount -
                        (amount?.thisPeriod + amount?.fromPrevious),
                      totalCompleated:
                        amount?.thisPeriod + amount?.fromPrevious,
                      charged: false, //the connection with charges
                      charges: [],
                      reducedBalance: 0,
                      ...attachRetaiangeLogic(amount, false, false),

                      // // elevation data
                      // serviceOptions: amount?.serviceOptions?.map(
                      //   (elevationData) => ({
                      //     ...elevationData,
                      //     items: elevationData?.items?.map((pliData) => ({
                      //       ...pliData,
                      //       fromPrevious:
                      //         pliData?.fromPrevious + pliData?.thisPeriod,
                      //       lock: false,
                      //       thisPeriod: 0,
                      //       collectableAmount:
                      //         pliData?.balanceToFinish - pliData?.retainage,
                      //     })),
                      //   })
                      // ),
                      // // addons data
                      // serviceAddons: amount?.serviceAddons?.map((addonsData) => ({
                      //   ...addonsData,
                      //   fromPrevious:
                      //     addonsData?.fromPrevious + addonsData?.thisPeriod,
                      //   lock: false,
                      //   thisPeriod: 0,
                      //   collectableAmount:
                      //     addonsData?.balanceToFinish - addonsData?.retainage,
                      // })),
                    })),
                  };
            })
      : //=================================== if we dont have previous application===========================================================
        //when service is hoist and we dont have previous application
        services
          ?.filter(({ visible }) => !!visible)
          ?.map((service) => {
            return checkIfServiceIsHoist(service) // Get services from Schedule of Value
              ? {
                  ...service, // Make a copy of service
                  serviceOptions: [
                    service?.serviceOptions?.[0]?.map((serviceOption) => ({
                      // Loop over serviceOptions
                      ...serviceOption, // Make a copy of serviceOption
                      amounts: serviceOption?.amounts?.map((amount) => ({
                        // Filter events that have collectable amount
                        ...amount, // Make a copy of event from Schedule of Value
                        carriedRetainage: 0,
                        previousRetainage: 0,
                        isReduced: false,
                        fromPrevious: 0,
                        thisPeriod: 0,
                        paymentDue: 0,
                        taxAmount: service?.isTaxable
                          ? amount?.amount * service?.taxRate
                          : 0,
                        balanceToFinish: amount?.amount,
                        keptRetainage: 0,

                        percentageCompleated: 0,
                        totalCompleated: 0,
                        charged: false,
                        charges: [],
                        reducedBalance: 0,
                        ...attachRetaiangeLogic(amount, true, true),

                        note: `<div contenteditable="false" user-select: "none" ><h3>Description:</h3> Service: ${
                          service?.label
                        }, Elevation: ${
                          serviceOption?.elevationLabel
                        }, Event: ${amount?.name}<h3 >Notes:</h3></div> ${
                          amount?.note || ""
                        }`,
                      })),
                    })),
                  ],
                }
              : //when service is hoist and we dont have previous application
                {
                  ...service,
                  amounts: service?.amounts?.map((amount) => ({
                    ...amount,
                    carriedRetainage: 0,
                    previousRetainage: 0,
                    paymentDue: 0,
                    isReduced: false,
                    fromPrevious: 0,
                    thisPeriod: 0,
                    keptRetainage: 0,

                    balanceToFinish: amount?.amount,
                    percentageCompleated: 0,
                    totalCompleated: 0,
                    retainage: (amount?.amount * service?.retainage) / 100,
                    charged: false, //the connection with charges
                    charges: [],
                    reducedBalance: 0,

                    taxAmount: service?.isTaxable
                      ? amount?.amount * service?.taxRate
                      : 0,
                    note: `<div contenteditable="false" user-select: "none" ><h3>Description:</h3> Service: ${
                      service?.label
                    }, Event: ${amount?.name}<h3 >Notes:</h3></div> ${
                      amount?.note || ""
                    }`,
                    serviceOptions: [],
                    serviceAddons: [],
                    ...attachRetaiangeLogic(amount, true, false),

                    // // elevation data
                    // serviceOptions: service?.serviceOptions[0]?.map(
                    //   (elevationData) => ({
                    //     ...elevationData,
                    //     items: elevationData?.items?.map((pliData) => {
                    //       const calculatedAmount =
                    //         (pliData?.totalPrice * amount?.rate) / 100;
                    //       const retainage =
                    //         (calculatedAmount * service?.retainage) / 100;

                    //       return {
                    //         ...pliData,
                    //         event: amount?.event,
                    //         isReduced: false,
                    //         reducedBalance: 0,

                    //         amount: calculatedAmount,
                    //         taxAmount: service?.isTaxable
                    //           ? calculatedAmount * service?.taxRate
                    //           : 0,
                    //         retainage,
                    //         balanceToFinish: calculatedAmount,
                    //         fromPrevious: 0,
                    //         thisPeriod: 0,
                    //         percentageCompleated: 0,
                    //         lock: false,
                    //         collectableAmount: calculatedAmount - retainage,
                    //         charged: false, //the connection with charges
                    //         charges: [],
                    //       };
                    //     }),
                    //   })
                    // ),
                    // // addons data
                    // serviceAddons: service?.serviceAddons?.map((addonsData) => {
                    //   const calculatedAmount =
                    //     (addonsData?.totalPrice * amount?.rate) / 100;
                    //   const retainage =
                    //     (calculatedAmount * service?.retainage) / 100;

                    //   return {
                    //     ...addonsData,
                    //     reducedBalance: 0,

                    //     isReduced: false,
                    //     event: amount?.event,
                    //     amount: calculatedAmount,
                    //     taxAmount: service?.isTaxable
                    //       ? calculatedAmount * service?.taxRate
                    //       : 0,
                    //     retainage,
                    //     balanceToFinish: calculatedAmount,
                    //     fromPrevious: 0,
                    //     thisPeriod: 0,
                    //     percentageCompleated: 0,
                    //     lock: false,
                    //     collectableAmount: calculatedAmount - retainage,
                    //     charged: false,
                    //     charges: [],
                    //     //the connection with charges
                    //   };
                    // }),
                  })),
                  serviceOptions: [],
                };
          }),
  };
};

export const getPreviousApplicationTotalLessRetainage = async (
  application,
  previousApplications
) => {
  console.log("getPreviousApplicationTotalLessRetainage", {
    application,
    previousApplications,
  });
  let result = 0;
  let totalCompletedAndStored = 0;
  let retainageAmountTotal = 0;
  let services = application?.services || [];

  const THIS_APPLICATION = application;
  // const PREV_APPLICATIONS = previousApplications?.filter(
  //   (el) => el?.applicationNo < THIS_APPLICATION?.applicationNo
  // );

  // const BEFORE_APPLICATION =
  //   PREV_APPLICATIONS?.find(
  //     (a) => a?.applicationNo === THIS_APPLICATION?.applicationNo - 1
  //   ) || {};

  // Calculate the total earned (This Period) - Retainage for only those that have this Period.
  for (const service of services) {
    if (checkIfServiceIsHoist(service)) {
      let totalities = service?.serviceOptions[0] || [];
      console.log("hoist totalities", totalities);
      for (const total of totalities) {
        let currAmounts = total?.amounts || [];
        if (Array.isArray(currAmounts)) {
          for (const amount of currAmounts) {
            totalCompletedAndStored += forceToNumber(amount?.totalCompleated);
            retainageAmountTotal += forceToNumber(amount?.accRetainageBalance);
          }
        }
      }
    } else {
      let totalities = service?.amounts || [];
      for (const total of totalities) {
        totalCompletedAndStored += forceToNumber(total?.totalCompleated);
        retainageAmountTotal += forceToNumber(total?.accRetainageBalance);
      }
      // for (const total of totalities) {
      // }
    }
  }

  // // Do the same thing for Included Rentals in Previous Application
  let rentals = application?.includedRentals || [];
  if (application?.applicationId) {
    await filterTables(
      "includedRentals",
      "applicationId",
      application?.applicationId
    ).then((res) => {
      rentals = [...rentals, ...res];
    });
  }
  for (const rental of rentals) {
    let rentalServices = rental?.services || [];
    for (const rentalService of rentalServices) {
      const {
        appliedAmount = 0,
        retainage = 0,
        taxRate = 0,
        serviceLevelCredit = 0,
        accRetainageBalance = 0,
      } = rentalService;
      const amountPlusTax = appliedAmount + appliedAmount * taxRate;
      const positiveCredit =
        Math.abs(serviceLevelCredit) + Math.abs(serviceLevelCredit * taxRate);
      const creditAndRetainage = positiveCredit;
      const retainageAmount = accRetainageBalance;
      const temp = amountPlusTax - retainageAmount - creditAndRetainage;
      result += temp;
      totalCompletedAndStored += amountPlusTax - creditAndRetainage;
      retainageAmountTotal += validateNumber(retainageAmount);
      retainageAmountTotal -= validateNumber(
        (positiveCredit * retainage) / 100
      );
    }
  }

  let previousRentals = application?.accumulatedRentals;
  if (!!previousRentals) {
    Object.keys(previousRentals).forEach((appNo) => {
      Object.keys(previousRentals[appNo]).forEach((rentalNo) => {
        Object.keys(previousRentals[appNo][rentalNo]).forEach(
          (rentalService) => {
            const {
              appliedAmount = 0,
              retainage = 0,
              taxRate = 0,
              serviceLevelCredit = 0,
              accRetainageBalance = 0,
            } = previousRentals[appNo][rentalNo][rentalService];
            const amountPlusTax = appliedAmount + appliedAmount * taxRate;
            const positiveCredit =
              Math.abs(serviceLevelCredit) +
              Math.abs(serviceLevelCredit * taxRate);
            const creditAndRetainage = positiveCredit;
            const retainageAmount = accRetainageBalance;
            const temp = amountPlusTax - retainageAmount - creditAndRetainage;
            result += temp;
            totalCompletedAndStored += amountPlusTax - creditAndRetainage;
            retainageAmountTotal += validateNumber(retainageAmount);
            retainageAmountTotal -= validateNumber(
              (positiveCredit * retainage) / 100
            );
          }
        );
      });
    });
  }
  // for (const prevRentals of previousApplications) {
  //   let tempRental = prevRentals?.includedRentals || [];
  //   for (const rent of tempRental) {
  //     let services = rent?.services || [];
  //     for (const rentalService of services) {
  //       const {
  //         appliedAmount = 0,
  //         taxRate = 0,
  //         serviceLevelCredit = 0,
  //       } = rentalService;

  //       const CURRENT_RENTAL_RET =
  //         application?.totalities?.prevRentalsRetainage?.[
  //           `Rental${rent?.rentalNumber}`
  //         ] ?? rentalService?.retainage;
  //       const retainage = CURRENT_RENTAL_RET;
  //       const amountPlusTax = appliedAmount + appliedAmount * taxRate;
  //       const positiveCredit =
  //         Math.abs(serviceLevelCredit) + Math.abs(serviceLevelCredit * taxRate);
  //       const creditAndRetainage = positiveCredit;
  //       console.log("creditAndRetaianage", creditAndRetainage);
  //       const retainageAmount = (amountPlusTax * retainage) / 100;
  //       const temp = amountPlusTax - retainageAmount - creditAndRetainage;
  //       result += temp;
  //       totalCompletedAndStored += amountPlusTax - creditAndRetainage;
  //       retainageAmountTotal += validateNumber(retainageAmount);
  //       retainageAmountTotal -= validateNumber(
  //         (positiveCredit * retainage) / 100
  //       );
  //     }
  //   }
  // }

  // result = totalCompletedAndStored - retainageAmountTotal;
  console.log("totalCompletedAndStored2", {
    totalCompletedAndStored,
    retainageAmountTotal,
    result,
    previousApplications,
  });

  return forceToNumber(totalCompletedAndStored - retainageAmountTotal);
};

export const getPreviousIncludedRetainage = (amountObj = {}) => {
  // !!amount?.carriedRetainage
  // 	? amount?.carriedRetainage || 0 + amount?.retainage || 0
  // 	: amount?.retainage || 0,
  let previousCarry = amountObj?.carriedRetainage || 0;
  let currentRetainage = amountObj?.retainage || 0;
  let thisPeriod = amountObj?.thisPeriod || 0;
  let result = 0;
  console.log("getPreviousIncludedRetainage", amountObj);
  if (thisPeriod !== 0 && isNaN(thisPeriod) === false) {
    result = previousCarry + currentRetainage;
  } else {
    result = previousCarry;
  }
  return forceToNumber(result);
};

// Case where we include new services included in SOV!
const checkIfNewServices = (previousServices = [], newServices = []) => {
  const hasChanged = previousServices?.length !== newServices?.length;
  console.log("checkIfNewServices", {
    hasChanged,
    previousServices,
    newServices,
  });
  if (hasChanged) {
    return true;
  }
  return false;
};

// if (hasChanged) {
//   const tempAllServices = [...previousServices, ...newServices];
//   return tempAllServices;
// }
// return false;

const retrieveUpdatedServices = (previousServices = [], newServices = []) => {
  const prev = previousServices?.map(
    ({ isChangeOrder = false, label = "" }) => ({
      isChangeOrder,
      label,
    })
  );
  const newS = newServices?.map(({ isChangeOrder = false, label = "" }) => ({
    isChangeOrder,
    label,
  }));
  const allServices = [
    ...previousServices,
    ...newServices?.filter((el) => {
      let findPrev = previousServices?.find(
        ({ label = "" }) => label === el?.label
      );
      if (
        findPrev === undefined ||
        findPrev?.isChangeOrder !== el?.isChangeOrder ||
        findPrev?.estimationId !== el?.estimationId
      ) {
        return true;
      }
      return false;
    }),
  ];
  console.log("retrieveUpdatedServices", allServices);
  return filterAndRemoveDuplicates(allServices);
};

const validateNumber = (value) => {
  if (value === "") {
    return 0;
  }
  if (isNaN(value)) {
    return 0;
  }
  return value;
};
const filterAndRemoveDuplicates = (arr) => {
  if (!Array.isArray(arr)) {
    return [];
  }
  const seen = {}; // To keep track of seen combinations of estimationId and serviceId
  const result = [];

  for (const obj of arr) {
    const key = `${obj?.estimationId}-${obj?.serviceId}`;

    if (!seen[key]) {
      seen[key] = true;
      result.push(obj);
    }
  }

  return result;
};

export const attachRetaiangeLogic = (
  amount,
  isFirst,
  isHoist,
  lastApplication
) => {
  // Here we will have two categories and different cases

  // CATEGORY 1: HOIST -- CATEGORY 2: REGULAR SERVICE
  // Cases

  // CASE 1:
  // Upon first creation
  // CASE 2:
  // Next application
  // CASE 3:
  // When SOV is updated
  // message.info("attachRetaiangeLogic");
  // amount.thisKeptRetainage = 0;
  // amount.accRetainageBalance = 0;
  // amount.accRetaiangeDist = 0;
  // amount.thisDistRetainage = 0;
  // amount.retainagePercentage = 0;
  // amount.fromPreviousRetainageBalance = 0;

  if (isFirst) {
    return {
      thisKeptRetainage: 0,
      accRetainageBalance: 0,
      accRetaiangeDist: 0,
      thisDistRetainage: 0,
      retainagePercentage: forceToNumber(amount?.retainagePercentage || 0),
      fromPreviousRetainageBalance: 0,
    };
  } else {
    return {
      thisKeptRetainage: 0,
      accRetainageBalance: forceToNumber(amount?.accRetainageBalance || 0),
      accRetaiangeDist: forceToNumber(amount?.accRetaiangeDist || 0),
      thisDistRetainage: 0,
      retainagePercentage: forceToNumber(amount?.retainagePercentage || 0),
      fromPreviousRetainageBalance: forceToNumber(
        amount?.accRetainageBalance || 0
      ),
    };
  }

  return amount;
};

const attachRentalsAccumulated = (
  previousApplication,
  MERGED_PREVIOUS_APPLICATION
) => {
  console.log("MERGED_PREVIOUS_APPLICATION22", MERGED_PREVIOUS_APPLICATION);
  console.log("previous application", previousApplication);
  let temp = previousApplication?.previousRentals || {};
  let currentRentals = previousApplication?.includedRentals || []; // previous current's rentals
  if (
    Array.isArray(MERGED_PREVIOUS_APPLICATION) &&
    MERGED_PREVIOUS_APPLICATION.length > 0
  ) {
    MERGED_PREVIOUS_APPLICATION.forEach((rental) => {
      let currServices = rental?.services || [];
      if (Array.isArray(currServices) && currServices?.length > 0) {
        temp[previousApplication?.applicationNo] = {
          ...(temp[previousApplication?.applicationNo] || {}),
        };
        currServices.forEach((service) => {
          const { serviceOptions = [], ...rest } = service || {};
          const appNo = previousApplication?.applicationNo;
          const rentalNumber = rental?.rentalNumber;
          if (!temp[appNo]) {
            temp[appNo] = {};
          }
          temp[appNo][rentalNumber] = {
            ...(temp[appNo][rentalNumber] || {}),
            [service?.label]: rest,
          };
        });

        // temp[previousApplication?.applicationNo][rental?.rentalNumber] = {

        // };
      }
    });
  }
  if (Array.isArray(currentRentals) && currentRentals?.length > 0) {
    currentRentals.forEach((rental) => {
      let currServices = rental?.services || [];
      if (Array.isArray(currServices) && currServices?.length > 0) {
        temp[previousApplication?.applicationNo] = {
          ...(temp[previousApplication?.applicationNo] || {}),
        };
        currServices.forEach((service) => {
          const { serviceOptions = [], ...rest } = service || {};
          const appNo = previousApplication?.applicationNo;
          const rentalNumber = rental?.rentalNumber;
          if (!temp[appNo]) {
            temp[appNo] = {};
          }
          temp[appNo][rentalNumber] = {
            ...(temp[appNo][rentalNumber] || {}),
            [service?.label]: rest,
          };
        });

        // temp[previousApplication?.applicationNo][rental?.rentalNumber] = {

        // };
      }
    });
  } else {
    temp = { ...(previousApplication?.accumulatedRentals || {}), ...temp };
  }
  console.log("attachRentalsAccumulated", {
    acc: previousApplication?.accumulatedRentals,
    temp,
    previousApplication,
    currentRentals,
  });
  // Before moving further we have to empty current balance distributed.
  let toReturn = {
    ...(previousApplication?.accumulatedRentals || {}),
    ...(temp || {}),
  };
  if (Object.keys(toReturn).length !== 0) {
    for (const appNo in toReturn) {
      if (Object.keys(toReturn[appNo]).length !== 0) {
        for (const rentalNo in toReturn[appNo]) {
          if (Object.keys(toReturn[appNo][rentalNo]).length !== 0) {
            for (const service in toReturn[appNo][rentalNo]) {
              toReturn[appNo][rentalNo][service].thisDistRetainage = 0;
              toReturn[appNo][rentalNo][service].paymentDue = 0;
              toReturn[appNo][rentalNo][service].thisPeriod = 0;
            }
          }
        }
      }
    }
  }

  console.log("toReturn", toReturn);
  return toReturn;
};

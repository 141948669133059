import React, { useEffect, useMemo, useState } from "react";
import { Button, Form, Select } from "antd";
import { useSelector } from "react-redux";
import { generateUsersEmailsOptions } from "../../../../functions";
import { getRoles } from "../../utils/emailbox-helpers";

import "./EmailBoxHeaderInputs.scss";

function EmailBoxHeaderInputs({ form = { setFieldValue: () => {} }, rowData }) {
  const [isBccVisible, setIsBccVisible] = useState(false);
  const [isCcVisible, setIsccVisible] = useState(false);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [roles, setRoles] = useState([]);

  // Effect to fetch roles whenever rowData changes
  useEffect(() => {
    getRoles(rowData).then((res) => {
      // Map over roles to ensure that only the first role is used if there are multiple
      const filteredRoles = res.map((item) => {
        if (Array.isArray(item.role) && item.role.length > 0) {
          return { ...item, role: item.role[0] };
        } else {
          return item;
        }
      });
      // Set roles where both role and email are available
      setRoles(filteredRoles.filter((role) => role.role && role.email));
    });
  }, [rowData]);

  // Memoized email options generated from userConfiguration and roles
  const options = useMemo(() => {
    return generateUsersEmailsOptions(userConfiguration, roles);
  }, [userConfiguration.allUsers.Items, roles]);

  // Inline style for customizing the dropdown width
  const selectDropdownStyles = {
    maxWidth: "500px",
  };

  return (
    <div
      className={`email-box-header-inputs-container ${
        isDarkMode ? "dark-mode" : ""
      }`}
    >
      {/* "To" Field */}
      <div className="to-container" data-testid="to-container">
        <Form.Item {...{ name: "to" }} className="email-box-form-item">
          <Select
            {...{
              showSearch: true,
              mode: "tags",
              popupClassName: `${isDarkMode ? "darkDropDown" : ""}`,
              placeholder: "To",
            }}
            data-testid="to-dropdown"
            dropdownStyle={selectDropdownStyles}
            options={options}
            allowClear
            suffixIcon={false}
            maxTagCount="responsive"
          />
        </Form.Item>

        {/* Triggers for showing Cc and Bcc fields */}
        <div className="cc-bcc-triggers-container">
          {!isCcVisible && (
            <span data-testid="cc-trigger" onClick={() => setIsccVisible(true)}>
              Cc
            </span>
          )}
          {!isBccVisible && (
            <span
              data-testid="bcc-trigger"
              onClick={() => setIsBccVisible(true)}
            >
              Bcc
            </span>
          )}
        </div>
      </div>

      {/* Conditional rendering of the Cc and Bcc fields */}
      <div className="cc-bcc-container">
        {isCcVisible && (
          <div className="cc-container">
            {/* "Cc" Field */}
            <Form.Item {...{ name: "cc" }} className="email-box-form-item ">
              <Select
                {...{
                  showSearch: true,
                  mode: "tags",
                  className: "cc-input",
                  popupClassName: isDarkMode && "darkDropDown",
                  placeholder: "Cc",
                }}
                options={options}
                dropdownStyle={selectDropdownStyles}
                suffixIcon={false}
                data-testid="cc-dropdown"
              />
            </Form.Item>

            {/* Remove Cc button */}
            <div
              {...{
                className: "remove-icon-container",
                onClick: () => {
                  setIsccVisible(false); // Hide Cc field
                  form.setFieldValue("cc", []); // Clear Cc field value
                },
              }}
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                data-testid="remove-icon"
              >
                -
              </Button>
            </div>
          </div>
        )}

        {isBccVisible && (
          <div className="bcc-container">
            {/* "Bcc" Field */}
            <Form.Item {...{ name: "bcc" }} className="email-box-form-item ">
              <Select
                {...{
                  showSearch: true,
                  mode: "tags",
                  className: "cc-input",
                  popupClassName: isDarkMode && "darkDropDown",
                  placeholder: "Bcc",
                }}
                options={options}
                dropdownStyle={selectDropdownStyles}
                suffixIcon={false}
                data-testid="bcc-dropdown"
              />
            </Form.Item>

            {/* Remove Bcc button */}
            <div
              {...{
                className: "remove-icon-container",
                onClick: () => {
                  setIsBccVisible(false);
                  form.setFieldValue("bcc", []);
                },
              }}
              data-testid="remove-bcc-icon-container"
            >
              <Button
                type="default"
                shape="circle"
                size="small"
                data-testid="remove-bcc-icon"
              >
                -
              </Button>
            </div>
          </div>
        )}
      </div>
    </div>
  );
}

export default EmailBoxHeaderInputs;

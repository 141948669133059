const logColumnNames = ({ onLogClick }) => [
  {
    fieldName: "label",
    fieldTitle: "Label",
  },
  {
    fieldName: "details",
    fieldTitle: "Details",
    onClick: onLogClick,
  },
  {
    fieldName: "nameOfUser",
    fieldTitle: "User",
  },
  {
    fieldName: "updatedAt",
    fieldTitle: "Date & Time",
  },
];
export default logColumnNames;

import { resetNestedValues } from "../../NewDegVersionModal/utils/resetServicesData";

export const checkPriceAccess = (services, canViewPrice) => {
  if (canViewPrice) {
    return services;
  }

  return services.map((service) => {
    return resetNestedValues(service);
  });
};

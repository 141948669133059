import { useEffect, useState } from "react";

import { QuestionCategoryCard } from "..";
import { getBinNumber } from "../../../Projects/DobTab/utils";
import { useInspection } from "../../view/InspectionProvider";

const QuestionCardWHeader = ({
  type,
  serviceQuestions,
  parentId,
  accessToken,
  form,
  setVisible,
  images,
  setShownImages,
  isDraft,
  onPickerSuccess,
  inspectionType,
  style = {},
  isEditable = true,
  setChanges,
  isDarkMode,
  setNewImagesCount,
  setIsAnswerChanged,
  onSaveLogs,
}) => {
  const [inspectionApplicantLicense, setInspectionApplicantLicense] = useState(
    []
  );

  const { inspection } = useInspection();

  const getGeneralInformationFirst = (array) => {
    let generalInformationObj = null;
    const filteredArray = [];

    for (const obj of array) {
      if (obj.categoryName.includes("General Information")) {
        generalInformationObj = obj;
      } else {
        filteredArray.push(obj);
      }
    }

    if (generalInformationObj) {
      filteredArray.unshift(generalInformationObj);
    }

    return filteredArray;
  };

  useEffect(() => {
    const generalContractor = "617232";

    const fetchApplicantLicense = async () => {
      const binNumber = await getBinNumber(inspection?.projectName);
      const response = await fetch(
        `https://data.cityofnewyork.us/resource/rbx6-tga4.json?bin=${binNumber}&applicant_license=${generalContractor}`
      );

      if (response) {
        const data = await response.json();
        setInspectionApplicantLicense(data);
      }
    };

    fetchApplicantLicense();
  }, []);

  return (
    <div className="serviceCard">
      <div className="questionaryServiceName" {...{ style }}>
        {typeof type === "object" ? type?.serviceType : type}
      </div>
      {getGeneralInformationFirst(serviceQuestions)?.map(
        ({ categoryName, categoryId, questions }, i) => (
          <QuestionCategoryCard
            key={i}
            {...{
              accessToken,
              parentId,
              categoryId,
              categoryName,
              questions,
              form,
              setVisible,
              images,
              setShownImages,
              isDraft,
              onPickerSuccess,
              inspectionTypeProp: inspectionType,
              style,
              isEditable,
              type,
              inspectionApplicantLicense,
              setChanges,
              isDarkMode,
              setNewImagesCount,
              setIsAnswerChanged,
              onSaveLogs,
            }}
          />
        )
      )}
    </div>
  );
};

export default QuestionCardWHeader;

import { useContext, useMemo } from "react";
import { useSelector } from "react-redux";
import { Modal, Form } from "antd";
import { v4 as uuid } from "uuid";

import LiveAuditContext from "../../../../LiveAuditContext";
import { FleetsLiveContext, getFence } from "../../../../../../utils";
import { dayjsNY } from "../../../../../../../../../DateComponents/contants/DayjsNY";
import {
  parseInTz,
  setHourMinute,
} from "../../../../../../../Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { MondayButton } from "src/components/commonComponents";
import {
  InputComponent,
  SimplePlacesInput,
} from "../../../../../../../components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";

import "./AddActualActivityModal.scss";

function AddActualActivityModal({ open, onCancel }) {
  //#region HOOKS
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { geofences, selectedDate, locations } = useContext(FleetsLiveContext);
  const { selectedVehicle, onAddActualActivity } = useContext(LiveAuditContext);

  const [form] = Form.useForm();

  const geofenceOptions = useMemo(() => {
    //#region GEOFENCE OPTIONS
    return Object.values(geofences).map(({ name }) => ({
      value: name,
      label: name,
    }));
  }, [geofences]);

  async function onConfirm() {
    //#region ON CONFIRM
    try {
      var { eventAddress, eventDate, eventTime, eventType, creationReason } =
        await form.validateFields();
    } catch {
      return;
    }

    const vehicle = locations?.find(
      ({ fleetId }) => fleetId === selectedVehicle
    );
    if (!vehicle) {
      return;
    }

    const geofenceUUID = geofences?.[getFence(eventAddress)]?.["geofenceUUID"];
    const fenceGroup = geofences?.[getFence(eventAddress)]?.["fenceGroup"];
    const dateTime = setHourMinute(eventDate, eventTime);

    const createBody = {
      alertDateTime: dateTime.valueOf(),
      alertUUID: uuid(),
      fenceName: eventAddress,
      deviceSerialNumber: vehicle?.deviceSerialNumber,
      vin: vehicle?.vinNumber || vehicle?.vin,
      alertCode:
        eventType === "Departure" ? "GEOFENCE_EXITED" : "GEOFENCE_ENTERED",
      geofenceUUID,
      creationReason,
      createdBy: userConfiguration?.nameOfUser,
      cognitoUserId: userConfiguration?.cognitoUserId,
      createdAt: parseInTz().valueOf(),
      fenceGroup,
      fenceName: eventAddress,
      formattedAlertDateTime: dateTime.format(),
    };

    onAddActualActivity(createBody);
    onCancel();
  }

  //#region JSX
  return (
    <Modal
      {...{
        open,
        onCancel,
        title: "Create Event",
        centered: true,
        className: `add-actual-activity-modal ${
          isDarkMode ? "add-actual-activity-modal-dark" : ""
        }`,
        closeIcon: <XIcon />,
        footer: [
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
            }}
            key="cancel"
          >
            Cancel
          </MondayButton>,
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onConfirm,
            }}
            key="confirm"
          >
            Confirm
          </MondayButton>,
        ],
      }}
    >
      <Form form={form}>
        <SimplePlacesInput
          form={form}
          label="Address"
          formItemName="eventAddress"
          placeholder="Event Address..."
          groupedOptions={[
            {
              label: "Geofences",
              options: geofenceOptions,
            },
          ]}
          isCustomValue
          required
          className="address-field"
          popupClassName={isDarkMode ? "darkDropDown" : undefined}
        />
        <InputComponent
          {...{
            formItemName: "eventDate",
            type: "datePicker",
            label: "Event Date",
            required: true,
            placeholder: "Event date...",
            defaultValue: dayjsNY(selectedDate),
            initialValue: dayjsNY(selectedDate),
            allowClear: false,
          }}
        />
        <InputComponent
          {...{
            formItemName: "eventTime",
            type: "timePicker",
            label: "Event Time",
            required: true,
            placeholder: "Event time...",
            dropdownClassName: isDarkMode ? "darkDateDropDown" : "",
          }}
        />
        <InputComponent
          {...{
            formItemName: "eventType",
            type: "select",
            required: true,
            placeholder: "Event type",
            allowClear: false,
            showSearch: false,
            label: "Event Type",
            customOptions: [{ value: "Departure" }, { value: "Arrival" }],
            defaultValue: "Arrival",
            initialValue: "Arrival",
          }}
        />
        <InputComponent
          {...{
            formItemName: "creationReason",
            type: "textarea",
            label: "Creation Reason",
            required: true,
            placeholder: "Creation reason...",
            style: {
              minHeight: "100px",
            },
          }}
        />
      </Form>
    </Modal>
  );
}

export default AddActualActivityModal;

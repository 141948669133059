import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Avatar, Form, Tooltip, Tour, message } from "antd";
import {
  footerButtons,
  fieldsJSON,
  PERSONAL_INFORMATION,
  body,
  BUSINESS_ADDRESS,
} from "./leadModalData";
import "./LeadModal.scss";
import dayjs from "dayjs";
import {
  getCognitosForNotification,
  lazyFetch,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../utils";
import {
  BorderedTextCard,
  NormalSizedModal,
} from "../../../SidebarPages/Fleet/components";
import { compareIncluding } from "../../../SidebarPages/utils";
import {
  apiRoutes,
  fetchData,
  validateForm,
} from "../../../SidebarPages/Fleet/utils";
import {
  MondayButton,
  ProgressComponent,
  SortModalFields,
} from "../../../commonComponents";
import { API } from "aws-amplify";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import { BillingAndShippingAdd, RenderDynamicComponents } from "../Components";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import broadcastNotification from "../../../../helpers/controllers/broadcastNotification";
import { filterPeopleList } from "../../../SidebarPages/utils/filterPeopleList";
import { ReactComponent as Tick } from "../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../icons/warningTriangle.svg";
import WarningModal from "../../../commonComponents/WarningModal/WarningModal";
import { DownCaretWhite } from "../../../../assets/icons";
import { useCreateDriveFolders } from "../../../../hooks/useCreateDriveFolders";
import { AnimatedCheckBox } from "../../../SidebarPages/Fleet/components/InputComponent/components";
import { VideoTutorialIcon } from "../../components/ModalVideoTutorials/assets/svg";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { XIcon } from "../../../SidebarPages/Communication/assets";
import DynamicTeamModal from "../DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { TransferIconNew } from "src/components/SidebarPages/DynamicView/src";
import { useEditLogs, useProgressComponent } from "../../../../hooks";

const LeadModal = ({
  visible,
  setVisible,
  refreshTable = () => {},
  lead,
  leadCompanyNames = [],
  saveOnly = false,
  DOBprop,
  preventContinue = false,
  isNextStep,
  nextStepHandler,
  handleSavedRecord = () => {},
}) => {
  const navigate = useNavigate();
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Leads", actionType: "Create" });
  const { saveAddedLogs } = useEditLogs();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [form] = Form.useForm();
  const [leadNames, setLeadNames] = useState(leadCompanyNames);
  const [sources, setSources] = useState([]);
  const [coreDriveFolderId, setCoreDriveFolderId] = useState();
  // const [centralDriveFolderId, setCentralDriveFolderId] = useState();
  const [salesPerson, setSalesPerson] = useState([]);
  const [leadTeamSelect, setLeadTeamSelect] = useState([]);
  const [recordTypes, setRecordTypes] = useState([]);
  const [statusTypes, setStatusTypes] = useState([]);
  const [industryTypes, setIndustryTypes] = useState([]);
  const [roles, setRoles] = useState([]);
  const [saving, setSaving] = useState(false);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [addressesCompKey, setAddressesCompKey] = useState(uuidv4());
  const [users, setUsers] = useState([]);
  const [projectExecutives, setProjectExecutives] = useState([]);

  const { hotCredentials } = useSelector((state) => state.hotCredentials);
  const { programFields } = useSelector((state) => state.programFields);
  const [initialValues, setInitialValues] = useState({});
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [formChanged, setFormChanged] = useState(false);
  const [preQualificationDisabled, setPreQualificationDisabled] =
    useState(false);
  const [prequalificationStages, setPrequalificationStages] = useState([]);
  const [mandatoryPreQualificationStages, setMandatoryPreQualificationStages] =
    useState(false);
  const [checkShabbat, setCheckShabbat] = useState(false);
  const [existingLeadsOptions, setExistingLeadsOptions] = useState([]);
  const [sortOpen, setSortOpen] = useState(false);
  const [detailsFields, setDetailsFields] = useState([]);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.leadModal || {}
  );

  const [fileNames, setFileNames] = useState([]);

  const driveParentId = coreDriveFolderId;

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Sales")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Lead"
    )?.videoLink;

  const driveFunctions = useCreateDriveFolders("Leads");

  const user = `${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`;

  const bodyObject = () =>
    body({
      form,
      authenticatedUser,
      checkShabbat,
    });

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        teamId: data?.teamId,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("leadTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const ClearOptions = () => {
    setOpen(false);
    // form.resetFields(["leadTeam"]);
  };

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];
    for (const item of uploadedFiles) {
      const fileNameObj = { ...item, uploadedAt: dayjs() };
      uploadedFileNames.push(fileNameObj);
    }
    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );
  };

  const preQualificationOnChange = (value) => {
    if (value === "Completed") {
      setMandatoryPreQualificationStages(true);
      setPreQualificationDisabled(false);
    } else if (value === "Not Required") {
      setMandatoryPreQualificationStages(false);
      setPreQualificationDisabled(true);
    } else {
      setMandatoryPreQualificationStages(false);
      setPreQualificationDisabled(false);
    }
  };

  const inputFields = fieldsJSON({
    checkShabbat,
    setCheckShabbat,
    accounts,
    salesPerson,
    recordTypes,
    // onChange,
    onSelect,
    onDeselect,
    statusTypes,
    industryTypes,
    roles,
    sources,
    leadNames,
    accessToken,
    preQualificationDisabled,
    form,
    fileNames,
    onPickerSuccess,
    prequalificationStages,
    existingLeadsOptions,
    setExistingLeadsOptions,
    parentId: driveFunctions?.parentFolderId,
    preQualificationOnChange,
    mandatoryPreQualificationStages,
    projectExecutives: projectExecutives?.map(
      ({ firstName, lastName }) => `${firstName} ${lastName}`
    ),
    leadTeamSelect: leadTeamSelect?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      teamId: [team.teamId],
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
  });

  const validateLeadForm = (onSuccessFunc) => {
    const leadCompanyVal = form.getFieldValue("leadCompany") || "";
    const doesExistLead = leadNames?.some(
      ({ leadCompany }) => leadCompany === leadCompanyVal
    );
    if (doesExistLead) {
      message.error("Lead already exists");
    } else {
      if (leadCompanyVal?.replace(/\s/g, "").length >= 3) {
        validateForm(form, onSuccessFunc, () => {}, [
          "leadCompany",
          "leadTeam",
        ]);
      } else {
        message.error("Company Name must be at least 3 characters long");
      }
    }
  };

  const resetForm = () => {
    form.resetFields();
    setCancelModalVisible(false);
    setAddressesCompKey(uuidv4());
    driveFunctions?.reset();
  };

  const onCancel = () => {
    setCancelModalVisible(false);
    resetForm();
  };

  const onCancelModal = () => {
    driveFunctions.delete().catch((e) => {
      console.error(e);
    });
    setVisible(false);
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  const saveLead = async (action) => {
    const key = "onSaveMsg";
    showLoadingMsg({ key });
    setSaving(true);

    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam,
      [],
      true
    );
    setSelectedTeam([]);

    let allFolders = null;

    try {
      setVisibleCreationProgress({ action });

      if (!mandatoryPreQualificationStages && !driveFunctions?.parentFolderId) {
        const { folders } = await driveFunctions.create({
          parentId: driveParentId,
          parentFolderName: form.getFieldValue("leadCompany").replace("&", ""),
        });
        allFolders = folders;
      }

      const commonLeadBody = {
        leadStatus: "New",
        teamsConfiguration,
        googleDriveUploads: fileNames[0]?.files || [],
      };

      const leadBody = {
        ...bodyObject(),
        ...commonLeadBody,
        googleDriveFolderIds: {
          ...(allFolders || driveFunctions.allFolders || {}),
        },
      };

      updateProgressStatus({ updatingRecord: "executing" });
      const result = await API.post(apiRoutes.leads, `/${apiRoutes.leads}`, {
        body: leadBody,
      }).catch((error) => {
        console.log({ error });
        updateProgressStatus({ updatingRecord: "hasError" });
      });
      setVisibleCreationProgress({ ...result, action });
      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      handleSavedRecord(result);
      saveAddedLogs({
        actionType: "Create",
        category: "Leads",
        recordId: result?.leadId,
        recordName: result?.leadCompany,
      });

      showSuccessMsg({ key });
      setSaving(false);
      resetForm();
      setTimeout(() => refreshTable([result]), 0);
      // await broadcastNotification(
      broadcastNotification(
        "1",
        "onCreateLead",
        [
          { common: user },
          {
            userName: user,
            currentUser: authenticatedUser?.sub,
            cognitos: getCognitosForNotification(
              userConfiguration,
              teamsConfiguration
            ),
          },
        ],
        result.leadId
      ).then((notificationSent) => {
        updateProgressStatus({
          sendingNotification: !!notificationSent ? "finished" : "hasError",
        });
      });
      return result;
    } catch (error) {
      console.error(error);
      throw error; // Re-throw the error for further handling if needed
    } finally {
      message.destroy("projectDriveFolders");
      message.destroy("windowMessages");
    }
  };

  const onFinish = async () => {
    await saveLead("onFinish").then(() => {});
  };

  const onSaveAndContinue = async () => {
    await saveLead("onSaveAndContinue").then((res) => {});
  };

  const onOptionClick = ({ key }) => {
    let tmpKey = preventContinue ? "Finish" : "Save & Close";
    compareIncluding(key, tmpKey)
      ? validateLeadForm(() => {
          preventContinue ? onSaveAndContinue() : onFinish();
        })
      : validateLeadForm(saveLead);
    // : validateLeadForm(saveRecord);
  };

  useEffect(() => {
    fetchData(apiRoutes?.projectExecutives).then((r) => {
      setProjectExecutives(r);
    });
    fetchData("accounts").then((r) =>
      setAccounts(() => {
        return r;
      })
    );
    API.get("teams", "/teams").then((r) => {
      setLeadTeamSelect(r);
    });
  }, []);

  const openMemberModal = () => {
    setOpen(true);
  };

  useEffect(() => {
    (async () => {
      if (!!leadNames.length) return;
      else if (!!leadCompanyNames.length) {
        setLeadNames(leadCompanyNames);
        return;
      }

      const fetchedNames = await lazyFetch({
        tableName: apiRoutes.leads,
        listOfKeys: ["leadCompany", "leadId"],
      });

      setLeadNames(fetchedNames);
      setExistingLeadsOptions(
        fetchedNames?.map(({ leadCompany, leadId }) => ({
          label: (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
              }}
            >
              {leadCompany}
              <a href={`/leads/${leadId}`} target="_blank">
                Go To
              </a>
            </div>
          ),
          value: leadCompany,
          disabled: true,
        }))
      );
    })();
  }, [
    // leadNames,
    leadCompanyNames,
  ]);

  useEffect(() => {
    const fieldsObj = programFields.reduce(
      (acc, { fieldName, fieldOptions }) => ({
        ...acc,
        [fieldName]: fieldOptions,
      }),
      {}
    );
    setSources(fieldsObj["Opportunity Source"]);
    setStatusTypes(fieldsObj["Statuses Of Categories"]["Leads"]);
    setIndustryTypes(fieldsObj["Lead Industry Types"]);
    setRecordTypes(fieldsObj["Lead Record Type"]?.map(({ type }) => type));
    setRoles(fieldsObj["Lead Roles"]);
    setSalesPerson(fieldsObj["Sales person"]);
    setPrequalificationStages(fieldsObj["Prequalification Stages"]);
    setUsers(filterPeopleList(userConfiguration.allUsers?.Items));
  }, [userConfiguration, programFields]);

  const personalInfoFields = inputFields[0].fields;
  function filterFields(allFields) {
    const tmp = [...allFields.slice(0, -2)].sort((a, b) => {
      if (Object.keys(sortFieldsObj).length === 0) {
        return 0;
      }
      const aIndex = sortFieldsObj[a.formItemName];
      const bIndex = sortFieldsObj[b.formItemName];
      return aIndex - bIndex;
    });
    return [...tmp, ...allFields.slice(-2)];
  }

  const sortedFields = filterFields(personalInfoFields);

  useEffect(() => {
    const credentialsObj = hotCredentials?.reduce(
      (acc, { credentialValue, credentialKey }) => ({
        ...acc,
        [credentialKey]: credentialValue,
      }),
      {}
    );
    setCoreDriveFolderId(credentialsObj?.["CORE/LEAD PARENT FOLDER"]);
    // setCentralDriveFolderId(credentialsObj["CENTRAL/LEAD PARENT FOLDER"]);
  }, [hotCredentials]);

  useEffect(() => {
    if (!lead) return;
    form.setFieldsValue(lead);
    form.validateFields();
  }, [lead]);

  useEffect(() => {
    setInitialValues(form.getFieldsValue());
  }, []);

  useEffect(() => {
    const compName = form.getFieldValue("leadCompany");
    !!compName &&
    mandatoryPreQualificationStages &&
    !!driveParentId &&
    compName?.replace(/\s+/g, "").length > 3 &&
    !driveFunctions?.parentFolderId
      ? driveFunctions
          .create({
            parentId: driveParentId,
            parentFolderName: form
              .getFieldValue("leadCompany")
              .replace("&", ""),
          })
          .then(({ folders }) => {
            // setFolderDriveId(folderId);
            // setSubFolderDriveId(subFolderId);
          })
      : mandatoryPreQualificationStages &&
        message.error(
          "Enter a valid company name and select completed stage again",
          3
        );
  }, [mandatoryPreQualificationStages]);

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(({ categoryName }) => categoryName === "leadModal")
      ?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Required Fields": document.querySelector(".leadCompanyName"),
      "Company Name": document.querySelector(".leadCompanyName"),
      "Pre-qualification Stages": document.querySelector(
        ".lead-pre-qualification-stages"
      ),
      "Add more information": document.querySelector(".leadBorderedTextCard"),
      "Add Billing and Shipping Address": document.querySelector(
        ".billingBorderedTextCard"
      ),
      "Copy Billing Address": document.querySelector(".copyIconDiv"),
      "Street View Address": document.querySelector(".billingAddIcon"),
      "Create Lead": document.querySelector(".saveButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  return (
    <>
      <NormalSizedModal
        visible={visible}
        onCancel={() => {
          if (formChanged) {
            setCancelModalVisible(true);
          } else {
            onCancel();
          }
        }}
        title={
          <CustomModalHeader
            title={"New Lead"}
            onClick={() => {
              tourSteps.length > 0
                ? setTourOpen(true)
                : message.info("No guide available for this form");
            }}
            headerInfo={() => {
              return (
                userConfiguration.groupName === "Admin" && (
                  <Tooltip title="Sort Fields">
                    <TransferIconNew
                      {...{
                        className: "sortFieldsIcon",
                        onClick: () => {
                          setSortOpen(true);
                        },
                      }}
                    />
                  </Tooltip>
                )
              );
            }}
            noX={true}
          />
        }
        className={`leadModalContainer ${
          isDarkMode && "leadModalContainerDark"
        }`}
        newFooterDesign={footerButtons({
          DOBprop,
          saving,
          onCancel: () => {
            if (formChanged) {
              setCancelModalVisible(true);
            } else {
              setVisible(false);
            }
          },
          onSave: () =>
            validateLeadForm(() => {
              preventContinue ? onFinish() : onSaveAndContinue();
            }),
          onOptionClick,
          suffix: <DownCaretWhite />,
          preventContinue,
          saveOnly,
        })}
        centered
      >
        {/* <div className='teamBtn'></div> */}

        <Form
          form={form}
          onFieldsChange={() => {
            setFormChanged(true);
          }}
        >
          <div className="leadModalBodyContainer">
            {/* <div className="leadModalBodyContainer-splash bodyTypeRadio">
              <span className="radioTitle">Select a building type</span>
              {RenderDynamicComponents([inputFields[0]])}
            </div> */}
            <BorderedTextCard
              className="leadBorderedTextCard"
              title={PERSONAL_INFORMATION}
            >
              {RenderDynamicComponents(sortedFields)}
              <div className="leadAvatarsDiv">
                {!!selectedTeam?.length && (
                  <MondayButton
                    onClick={openMemberModal}
                    Icon={<PlusOutlined />}
                    className="membersButton"
                  >
                    Members
                  </MondayButton>
                )}
                <div className="leadAvatars">
                  <DynamicAvatars
                    {...{
                      title: "Team Selected",
                      isDarkMode: isDarkMode,
                      members: selectedTeam?.reduce(
                        (acc, { members }) => [...acc, ...members],
                        []
                      ),
                    }}
                  />
                </div>
              </div>
            </BorderedTextCard>
            <BillingAndShippingAdd
              key={addressesCompKey}
              {...{
                form,
                title: BUSINESS_ADDRESS,
                mapClassName: "leadModalBodyContainer-splash",
                billingAddress: lead?.leadAddress,
              }}
            />
          </div>
        </Form>
      </NormalSizedModal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancelModal}
              Icon={<Tick width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <div>
        {open && (
          <DynamicTeamModal
            {...{
              open,
              setOpen,
              selectedTeam,
              setSelectedTeam,
              ClearOptions,
              proppedAllTeams: leadTeamSelect,
              onSave: (newTeam) => {
                form.setFieldValue("leadTeam", newTeam || []);
              },
            }}
          />
        )}
      </div>
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Lead Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Lead",
            rowData: sortedFields?.slice(0, -2),
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "leadModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Leads",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              const { action, leadId } = visibleCreationProgress;
              if (!!action) {
                setVisible(false);
                if (!!nextStepHandler) {
                  nextStepHandler();
                  return;
                }
                action === "onSaveAndContinue" &&
                  !!!isNextStep &&
                  navigate(`/leads/${leadId}`);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default LeadModal;

import { getParams } from "../../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  let name = {};
  let category = {};
  let status = {};
  let project = {};
  let cilent = {};
  let createdBy = {};

  data.forEach((item) => {
    const injuredPerson = item?.inspectionName || "No Data";
    const vehicleDriver = item?.inspectionCategory || "No Data";
    const injuredStatus = item?.safetyApplicationStatus || "No Data";
    const chargetype = item?.projectName || "No Data";
    const accName = item?.accountName.replace(".", "") || "No Data";
    const user = item?.createdBy?.name || "No Data";

    name.hasOwnProperty(injuredPerson)
      ? (name[injuredPerson] += 1)
      : (name[injuredPerson] = 1);

    category.hasOwnProperty(vehicleDriver)
      ? (category[vehicleDriver] += 1)
      : (category[vehicleDriver] = 1);

    status.hasOwnProperty(injuredStatus)
      ? (status[injuredStatus] += 1)
      : (status[injuredStatus] = 1);

    project.hasOwnProperty(chargetype)
      ? (project[chargetype] += 1)
      : (project[chargetype] = 1);

    cilent.hasOwnProperty(accName)
      ? (cilent[accName] += 1)
      : (cilent[accName] = 1);

    createdBy.hasOwnProperty(user)
      ? (createdBy[user] += 1)
      : (createdBy[user] = 1);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total Inspection count based on Name",
      dataKey: "inspectionName",
      params: getParams({
        data,
        chartData: name,
        chartKey: "inspectionName",
        label: "Name",
        text: "Total Inspection count based on Name",
      }),
    },
    {
      optionTitle: "Show total Inspection count based on Category",
      dataKey: "inspectionCategory",
      params: getParams({
        data,
        chartData: category,
        chartKey: "inspectionCategory",
        label: "Category",
        text: "Total Inspection count based on Category",
      }),
    },
    {
      optionTitle: "Show total Inspection count based on Status",
      dataKey: "safetyApplicationStatus",
      params: getParams({
        data,
        chartData: status,
        chartKey: "safetyApplicationStatus",
        label: "Status",
        text: "Total Inspection count based on Status",
      }),
    },
    {
      optionTitle: "Show total Inspection count based on Project",
      dataKey: "projectName",
      params: getParams({
        data,
        chartData: project,
        chartKey: "projectName",
        label: "Project",
        text: "Total Inspection count based on Project",
      }),
    },
    {
      optionTitle: "Show total Inspection count based on Client Name",
      dataKey: "accountName",
      params: getParams({
        data,
        chartData: cilent,
        chartKey: "accountName",
        label: "Client Name",
        text: "Total Inspection count based on Client Name",
      }),
    },
    {
      optionTitle: "Show total Inspection count based on Created By",
      dataKey: "createdBy.name",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy.name",
        label: "Created By",
        text: "Total Inspection count based on Created By",
      }),
    },
  ];

  return chartingOptions;
};

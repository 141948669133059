import { useEffect, useState } from "react";
import { isStepDisabled, Stepper } from "../../../../../../commonComponents";
import "./NewUserModal.scss";
import { Form, Modal } from "antd";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { SignupFormData } from "../../../../../../../containers/Signup/SignUpCompData";
import { useSelector } from "react-redux";
import { userModalSteps } from "./helpers";
import { CreateUserInfoText, NewUserFooter } from "./components";
import { API } from "aws-amplify";

function NewUserModal({ visible, setVisible, userData }) {
  const [form] = Form.useForm();
  const [currentStep, setCurrentStep] = useState(0);
  const [user, setUser] = useState(false);
  const [roles, setRoles] = useState([]);
  const [permissions, setPermissions] = useState({});
  const [customAccess, setCustomAccess] = useState({});
  const [allTeams, setAllTeams] = useState([]);
  const [completedSteps, setCompletedSteps] = useState({});
  const [{ validateStep }, setValidateStep] = useState({});
  const [stepsFormDisabled, setStepsFormDisabled] = useState({
    steps: {},
    otp: false,
  });

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userGroups } = useSelector((state) => state.userGroups);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const allUsers = userConfiguration?.allUsers?.Items || [];

  const departments = Array.from(
    new Set(userGroups.map((group) => group.departmentName))
  );

  const setRoleOptions = (value) => {
    if (!value) {
      setRoles([]);
      return;
    }
    setRoles(
      departmentFields.find(({ departmentName }) => departmentName === value)
        .roles
    );
  };

  const departmentFields = departments.map((department) => ({
    departmentName: department,
    roles: userGroups
      .filter((item) => item.departmentName === department)
      .map((item) => item.groupName),
  }));

  const populatedFields = SignupFormData(
    departments,
    roles,
    setRoleOptions,
    form,
    isDarkMode,
    allUsers,
    departmentFields,
    stepsFormDisabled.steps?.[0]
  );

  const onAccessChange = (value) => {
    console.log("onAccessChange ~ value", value);
  };

  function getRecordsToBePartOf(records) {
    console.log("getRecordsToBePartOf ~ records", records);
  }

  const setValidateStepMethod = (func) => {
    setValidateStep({ validateStep: func });
  };

  function onCancel() {
    setVisible(false);
  }

  useEffect(() => {
    if (userData && form) {
      form.setFieldsValue(userData);
    }
  }, [form, JSON.stringify(userData)]);

  useEffect(() => {
    (async () =>
      await API.get("teams", "/teams").then((res) => setAllTeams(res)))();
  }, []);

  async function finalConfirm() {
    // permissions
    const accessConfig = customAccess?.accessConfig || {};
    const driveAccess = customAccess?.driveAccess || {};
    console.log({ accessConfig, driveAccess, permissions });
    // onCancel();
  }

  const populatedSteps = userModalSteps({
    form,
    populatedFields,
    user,
    stepsFormDisabled,
    setStepsFormDisabled,
    setUser,
    permissions,
    setPermissions,
    allTeams,
    finalConfirm,
    customAccess,
    setCustomAccess,
  }).map((step, index) => ({
    ...step,
    index,
    disabled: isStepDisabled(completedSteps, currentStep, index),
    onCancel,
    setValidateStepMethod,
  }));

  const updateSteps = () => {
    setCompletedSteps((curr) => ({ ...curr, [currentStep]: true }));
    !(currentStep > populatedSteps.length - 2) &&
      setCurrentStep((curr) => curr + 1);
  };

  const onNext = () =>
    !!validateStep ? validateStep(updateSteps) : updateSteps();

  return (
    <Modal
      {...{
        title: "New User",
        open: visible,
        maskClosable: false,
        centered: true,
        destroyOnClose: true,
        className: `createNewUserModal ${
          isDarkMode ? "createNewUserModalDark" : ""
        }`,
        onCancel,
        footer: (
          <NewUserFooter
            {...{
              currentStep,
              onNext,
              onCancel,
              onBack: () => setCurrentStep((curr) => curr - 1),
              length: populatedSteps.length,
            }}
          />
        ),
        closeIcon: <XIcon />,
      }}
    >
      <CreateUserInfoText {...{ currentStep, user }} />

      <Stepper
        {...{
          className: "new-user-stepper",
          currentStep,
          setCurrentStep,
          steps: populatedSteps,
          componentContainerClassName: "new-user-step",
        }}
      />
    </Modal>
  );
}

export default NewUserModal;

import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";
import { ScrollIcon } from "../../src";
import "./Progress.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { useNavigate } from "react-router-dom";
import { useState, useEffect } from "react";
import { GraphTooltipContent } from "../BarChart/GraphTooltipContent";
import { Result, Tooltip } from "antd";
import { queryParams } from "../../../../../utils/queryParams";
import { useSelector } from "react-redux";
import { routeData } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/HistoryData";
const Progress = ({ params, id, filterValue = [] }) => {
  const { statusData, table, filterType, isChartDataLoading } = params;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [scrollable, setScrollable] = useState();
  const [bottom, setBottom] = useState(false);

  useEffect(() => {
    document.getElementById(id)?.scrollHeight &&
      setScrollable(document.getElementById(id).scrollHeight > 300);
  }, [document.getElementById(id)?.scrollHeight]);

  // useEffect(() => {

  // }, [statusData])

  const loading = isChartDataLoading || statusData === undefined;
  const noData = !isChartDataLoading && statusData?.length === 0;

  const history = useFilteredHistory(table);

  const location = useNavigate();

  const goTo = (path, rowStatusData) => {
    const accountingTab =
      table === "Requisitions"
        ? "Applications"
        : table === "Invoices"
        ? "Invoicing"
        : table;

    if (!!path && filterType?.type !== "dashboardId") {
      location.push(
        `projects/${path}?${queryParams({
          tab: "Accounting",
          subTab: accountingTab,
        })}`,
        {
          tabPosition: "Accounting",
          accountingTab,
          fromAccounting: "Accounting",
        }
      );
    } else if (filterType?.type === "status") {
      location.push({
        state: {
          tabPosition: "Inspections",
          chartFilter: {
            inspectionStatus: [rowStatusData?.label],
            inspectedBy: filterType?.filters?.inspectedBy,
            periodFilter: filterType?.filters?.periodFilter,
          },
        },
      });
    } else if (filterType?.type === "id") {
      location.push("/projects/" + rowStatusData?.projectId, {
        tabPosition: "Inspections",
      });
    } else if (filterType?.type === "dashboardId") {
      if (!params?.noTab && !params?.pathname) {
        location.push(routeData[table] + "/" + rowStatusData?.id, {
          tabPosition: "Overview",
        });
      } else if (!!params?.noTab && !params?.pathname) {
        location.push(routeData[table] + "/" + rowStatusData?.id);
      } else if (params?.pathname) {
        location.push(params?.pathname + "/" + rowStatusData?.id);
      }
    }
  };

  // const historyDataAdapter = progressAdapter[table][secondaryField]

  const resultText = () => {
    if (params?.noFunction) {
      return "There are no matching pattern for this chart";
    } else if (noData && !!filterValue?.length) {
      return "No data matches the applied filter";
    } else return `No data found for ${table}!`;
  };

  const resultSubText = () => {
    if (params?.noFunction) {
      return "Try removing and recreating this chart. If this reoccurs, please contact the Software Department.";
    } else if (noData && !!filterValue?.length) {
      return "Try another filter.";
    } else return `You may not have access to any records!`;
  };

  // console.log("Rerendered Progress");

  const progressChart = document.getElementById(`${id}`);
  // const progressChart = document.querySelector(`#${id}`);
  let height = 310;

  if (progressChart) {
    let headerHeight = document.getElementsByClassName(
      "CardViewComponentHeader"
    )[0]?.clientHeight;
    let containerHeight = progressChart?.clientHeight;

    if (containerHeight > 310) {
      height = containerHeight - headerHeight - 40;
    }
  }
  return (
    <>
      <div
        id={id}
        data-testid={id}
        className={`progressBodyContainer ${
          isDarkMode && "progressBodyContainerDark"
        }`}
        style={{ height }}
        onScroll={({ target: { scrollHeight, scrollTop, clientHeight } }) => {
          if (scrollHeight - scrollTop === clientHeight) {
            if (bottom !== true) {
              setBottom(true);
            }
          } else {
            if (bottom !== false) {
              setBottom(false);
            }
          }
        }}
      >
        {!noData ? (
          <SmallLoadableComp
            loading={loading}
            data-testid="small-loadable-comp"
          >
            {statusData?.map((rowStatusData, index) => (
              <Tooltip
                key={index}
                overlayClassName={"dashToolTipStyleCustom"}
                title={
                  !!rowStatusData.custom && (
                    <GraphTooltipContent
                      {...{ tooltipData: rowStatusData?.custom }}
                    />
                  )
                }
              >
                <div
                  key={index}
                  className="statusContainer"
                  style={{
                    background: `transparent linear-gradient(270deg,${
                      rowStatusData?.color
                    } 0%, ${isDarkMode ? "#12131b" : "#ffffff"} 100%)`,
                    cursor:
                      rowStatusData?.id || !!filterType?.type
                        ? "pointer"
                        : "auto",
                  }}
                  onClick={() =>
                    rowStatusData?.id || !!filterType?.type
                      ? goTo(rowStatusData?.id, rowStatusData)
                      : filterType?.type === "dashboardId" &&
                        goTo(_, rowStatusData)
                  }
                >
                  <p className="statusLabelStyle">{rowStatusData?.label}</p>
                  <span
                    className="statusValueStyle"
                    style={{
                      color: rowStatusData?.labelColor || "white",
                    }}
                  >
                    {rowStatusData?.value}
                  </span>
                </div>
              </Tooltip>
            ))}
          </SmallLoadableComp>
        ) : (
          <Result
            status="warning"
            title={resultText()}
            subTitle={resultSubText()}
          />
        )}
      </div>
      {scrollable && (
        <span
          className={!bottom ? "scrollBottomContainer" : "scrollTopContainer"}
        >
          <ScrollIcon />
        </span>
      )}
    </>
  );
};
export default Progress;

import ExportConfigTab from "./ExportConfigTab";
import SaveExportPreview from "./SaveExportPreview";
import DynamicTab from "../DynamicTab/DynamicTab";

function ExportPreviewTabs({
  modifiedValues,
  filters,
  setFilters,
  initialValues,
  landscape,
  setLandscape,
  setPageSize,
  pageSize,
  tabs,
  templates,
  setTemplates,
  keyName,
  selectedTemplate,
  setSelectedTemplate,
  setInitialValues,
  pagination,
  setPagination,
  dynamicStyles,
  setDynamicStyles,
}) {
  const props = {
    initialValues,
    modifiedValues,
    filters,
    setFilters,
    setInitialValues,
    dynamicStyles,
    setDynamicStyles,
  };

  const items = [
    {
      key: "1",
      label: "Configuration",
      children: (
        <ExportConfigTab
          {...{
            landscape,
            setLandscape,
            setPageSize,
            pageSize,
            pagination,
            setPagination,
          }}
        />
      ),
    },
    {
      key: "2",
      label: "Templates",
      children: (
        <SaveExportPreview
          {...{
            filters,
            templates,
            setTemplates,
            keyName,
            selectedTemplate,
            setSelectedTemplate,
          }}
        />
      ),
    },
    ...tabs.map((tab) => {
      const { component: Component, key, label } = tab;
      return {
        key: key,
        label: label,
        children: <Component {...props} />,
      };
    }),
  ];
  return (
    <DynamicTab
      {...{
        className: "configuration-tabs",
        defaultActiveKey: "1",
        items,
      }}
    />
  );
}

export default ExportPreviewTabs;

export function removeEmptyConditionsAndFilters(filters) {
  if (!filters || filters.length === 0) return [];

  const filteredFilters = filters
    .map((filter) => {
      const filteredConditions = filter.conditions?.filter(
        (condition) => condition.value !== ""
      );

      if (filteredConditions && filteredConditions.length > 0) {
        return { ...filter, conditions: filteredConditions };
      }
      return null;
    })
    .filter(Boolean);

  return filteredFilters;
}

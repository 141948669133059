import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

const applyFormulaFromArrayHoist = (
  formulaInArr,
  objWithValues,
  keyToAssign
) => {
  console.log("test1", {
    formulaInArr,
    objWithValues,
    keyToAssign,
  });
  let formulaToEval = "";
  const objToExplore = structuredClone(objWithValues);
  if (Array.isArray(formulaInArr)) {
    formulaInArr?.forEach((item) => {
      if (item !== "+" && item !== "-" && item !== "*" && item !== "/") {
        const findItem = objToExplore?.[item?.toString()] || false;
        if (!!findItem) {
          formulaToEval += forceToNumber(findItem);
        } else {
          if (typeof parseFloat(item) === "number") formulaToEval += item;
        }
      } else {
        formulaToEval += item;
      }
    });
  }
  console.log("formulaToEval", formulaToEval);
  if (formulaToEval.length > 0) {
    try {
      let res = forceToNumber(eval(formulaToEval));
      if (keyToAssign) objWithValues[keyToAssign] = res;
      return res;
    } catch (e) {
      console.log("Error in formula eval", e);
      return 0;
    }
    return res;
  } else {
    return 0;
  }
};

export default applyFormulaFromArrayHoist;

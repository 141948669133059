import React, { useState } from "react";
import { Form, message } from "antd";
import { useSelector } from "react-redux";
import { handleSendEmail, saveEmail } from "../../../utils/send-button-helpers";
import { useDispatch } from "react-redux";
import {
  MondayButton,
  WarningModal,
} from "../../../../../../../commonComponents";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { useEmailBox } from "../../../providers/EmailBoxProvider";
import { useEmailBoxBody } from "../../../providers/EmailBoxBodyProvider";
import useLogManagement from "../../../../../../../../hooks/useLogManagement";
import { addEmails } from "../../../../../../../../actions/communicationActions";

import { useInitialEmailBoxProps } from "../../../providers/InitialEmailBoxProvider";
import { WarningIcon } from "../../../../../../../../assets";
import { XIcon } from "../../../../../assets";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";

import { useAttachments } from "../../../providers/AttachmentsProvider";
import { useDriveDocuments } from "../../../providers/DriveDocumentsProvider";

import "./send-email-button.scss";

function SendEmailButton() {
  const form = Form.useFormInstance();
  const dispatch = useDispatch();
  const [loading, setLoading] = useState(false);
  const {
    signature,
    coreStorageSignature,
    hotCredentials,
    withSignature,
    selectedDocTypes,
    email,
    draftId,
    isFromCharge,
  } = useEmailBox();
  const { driveDocuments } = useDriveDocuments();
  const { attachments, driveLinkFiles } = useAttachments();
  const { onClose, sendEmailNotification, authData } =
    useInitialEmailBoxProps();
  const { editorContent } = useEmailBoxBody();
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const {
    drafts = [],
    recordData: { recordName, recordId, ...rest },
  } = useSelector((state) => state.communication);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { handleGenerateNewLog } = useLogManagement();
  const [warningModal, setWarningModal] = useState(false);

  const handleOnClick = async () => {
    if (!form.getFieldsValue().to) {
      message.warning("Please fill section To");
      return;
    }

    try {
      setLoading(true);
      const response = await handleSendEmail(
        attachments,
        driveDocuments,
        selectedDocTypes,
        driveRequest,
        hotCredentials,
        authData,
        withSignature,
        signature,
        coreStorageSignature,
        userConfiguration,
        onClose,
        form,
        email,
        editorContent,
        isDarkMode
      );

      if (
        response &&
        Object.keys(response.data).length > 0 &&
        response.status === 200
      ) {
        response.data.attachments = [...attachments, ...driveLinkFiles];
        const saveEmailResponse = await saveEmail(
          response,
          draftId,
          recordName,
          recordId,
          rest,
          authData,
          isFromCharge,
          drafts,
          dispatch,
          onClose,
          handleGenerateNewLog
        );

        if (saveEmailResponse) {
          sendEmailNotification();
        }
      } else {
        throw new Error("Failed to send email or invalid response received.");
      }
    } catch (error) {
      console.error("Error sending email:", error.message || error);
    }
  };

  return (
    <div className="send-email-buttton-container">
      <MondayButton
        className="send-email-button"
        loading={loading}
        disabled={loading}
        onClick={() => {
          if (!form.getFieldsValue().subject || editorContent === "") {
            setWarningModal(true);
          } else {
            handleOnClick();
          }
        }}
        data-testid="send-button"
      >
        Send
      </MondayButton>

      {warningModal && (
        <WarningModal
          visible={warningModal}
          setVisible={setWarningModal}
          title="Warning"
          children={
            <div style={{ textAlign: "center" }} className="logout-modal-body">
              <span>
                <WarningIcon />
              </span>
              <p>Send this email without a subject or text in the body?</p>
            </div>
          }
          // darkMode={isDarkMode}
          footer={[
            {
              text: "No",
              onClick: () => setWarningModal(false),
              icon: <XIcon />,
              className: "mondayButtonRed",
            },
            {
              text: "Yes",
              onClick: () => {
                setWarningModal(false);
                handleOnClick();
              },
              icon: <TickIcon />,
            },
          ]}
        />
      )}
    </div>
  );
}

export default SendEmailButton;

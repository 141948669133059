import { Tooltip } from "antd";
import { MaxTagTooltip } from "../../../../commonComponents";

/**
 * Creates a configuration object for the team select component.
 *
 * @param {Object} options - The options for configuring the team select component.
 * @param {Array} options.teams - The array of teams.
 * @param {boolean} options.isDarkMode - Indicates whether the dark mode is enabled.
 * @param {Function} options.onTeamSelect - The callback function to be called when a team is selected.
 * @returns {Object} - The configuration object for the team select component.
 */
const teamSelect = ({
  teams = [],
  isDarkMode = false,
  onTeamSelect = () => {},
}) => ({
  mode: "multiple",
  allowClear: true,
  dropdownClassName: isDarkMode && "darkDropDown",
  customOptions: teams?.map((team) => ({
    value: team?.teamName,
    label: team?.teamName,
    members: team?.members?.map(
      ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
        identityId,
        nameOfUser,
        cognitoUserId,
      })
    ),
  })),

  onSelect: (_, data) => onTeamSelect(_, data, false),

  onDeselect: (val, data) => onTeamSelect(val, data, true),

  onClear: () => onTeamSelect(null, null, true, "clearing"),

  showSearch: true,

  maxTagPlaceholder: (e) => {
    return (
      <MaxTagTooltip
        {...{
          array: e,
          onDeselect: (val, data) => onTeamSelect(val, data, true),
        }}
      />
    );
  },
  maxTagCount: 2,
});

export default teamSelect;

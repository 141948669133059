import { formatCurrency } from "../components/SidebarPages/utils/formatCurrency";

const axesTooltip = (params) => {
  return {
    title: params.datum[params.xKey],
    content: `Count: ${params.datum[params.yKey].toFixed(0)}`,
  };
};

export const axesTooltipCustom = (params, key) => {
  return {
    title: params.yKey,
    content: `${key}: ${params.datum[params.yKey].toFixed(0)}`,
  };
};

export const axesTooltipWithCurrency = (params, key) => {
  return {
    title: params.title,
    content: `${key || params.yName}: ${formatCurrency(
      params.datum[params.yKey]
    )}`,
  };
};

export const axesTooltipWithPercentage = (params) => {
  return {
    title: params.datum[params.xKey],
    content: `${params.yName}%: ${params.datum[params.yKey]}`,
  };
};

export const pieTooltip = (params) => {
  let tmp = params["calloutLabelKey"];
  return {
    title: params.datum[tmp],
    content: `Count: ${params.datum[params.angleKey].toFixed(0)}`,
  };
};

export const pieTooltipWithCurrency = (params) => {
  let tmp = params["calloutLabelKey"];
  return {
    title: params.datum[tmp],
    content: `${params.angleName}: ${formatCurrency(
      params.datum[params.angleName.toLowerCase()]
    )}`,
  };
};

export const axesCount = (params) => {
  return {
    title: params.datum[params.xKey],
    content: `Count: ${params.datum[params.yKey].toFixed(0)}`,
  };
};

export const pieCount = (params) => {
  let tmp = params["calloutLabelKey"];
  return {
    title: params.datum[tmp],
    content: `Count: ${params.datum[params.angleName.toLowerCase()].toFixed(
      0
    )}`,
  };
};

export default axesTooltip;

import { Modal, message } from "antd";
import "./DrawOnPhotoModal.scss";
import { XIcon } from "../../../../../../../Communication/assets";
import { GDriveIcon } from "../../../../../assets";
import { DownloadIcon } from "../../../../../../../BasePage/src";
import { openInNewTab } from "../../../../../../../../../utils";
import {
  MondayButton,
  ProgressBar,
} from "../../../../../../../../commonComponents";
import "src/components/SidebarPages/Documentation/View/components/FilePreviewModal/FilePreviewModal.scss";
import { SmallLoadableComp } from "../../../../../../../../Sidebars/components";
import useTranslate from "../../../../../../../../pages/ScheduleProgress/Language/useTranslate";
import CanvasDrawImage from "../CanvasDrawImage/CanvasDrawImage";
import base64ImageToDrive from "../base64ToDrive";

function DrawOnPhotoModal({
  visible,
  setVisible,
  currentFile,
  downloadProgress,
  progressVisible,
  folderId,
  isDarkMode,
  driveRequest,
  onPickerSuccess,
  onDelete,
  onChange,
  disabled,
}) {
  const { t } = useTranslate();
  const { id, name = "", src, url } = currentFile;

  async function onDrawSave(e) {
    try {
      // const newName = `${name.split(".")[0]}_edit.png`;
      const newName = name;
      const newMimeType = "image/png";

      const newId = await base64ImageToDrive({
        base64: e,
        fileName: newName,
        mimeType: newMimeType,
        folderId,
        driveRequest,
      });

      if (newId) {
        const newFileObj = {
          id: newId,
          name: newName,
          mimeType: newMimeType,
          lastEditedUtc: new Date().toISOString(),
          url: `https://drive.google.com/file/d/${newId}/view?usp=sharing`,
          downloadUrl: `https://drive.google.com/u/1/uc?id=${newId}&export=download&authuser=0`,
          type: "photo",
        };
        onDelete(id);
        onChange();
        onPickerSuccess({
          uploadedFiles: [newFileObj],
        });
        setVisible(false);
      } else {
        message.error("Error saving changes!");
      }
    } catch (error) {
      console.log("error in onFileSave:", error);
    }
  }

  function loading() {
    if (progressVisible === undefined) {
      return !src;
    } else if (progressVisible === false && !src) {
      return true;
    }
    return false;
  }

  return (
    <Modal
      {...{
        open: visible,
        onCancel: () => setVisible(false),
        className: `DrawOnPhotoModal ${isDarkMode && "DrawOnPhotoModalDark"} `,
        destroyOnClose: true,
        centered: true,
        maskClosable: true,
        title: name || t("File Preview"),
        closeIcon: <XIcon />,
        // transitionName: "linear",
        footer: disabled ? null : (
          <div className="gDriveButtons">
            {!!id && (
              <MondayButton
                {...{
                  className: "mondayButtonBlue",
                  Icon: <DownloadIcon />,
                  onClick: () => {
                    window.open(
                      `https://drive.google.com/u/1/uc?id=${id}&export=download&authuser=0`
                    );
                  },
                }}
              >
                {t("Download")}
              </MondayButton>
            )}

            {/* {!!url && (
              <MondayButton
                {...{
                  className: "mondayButtonBlue driveIcon",
                  Icon: <GDriveIcon />,
                  onClick: () => openInNewTab(url),
                }}
              >
                {t("Open In Drive")}
              </MondayButton>
            )} */}
          </div>
        ),
      }}
    >
      <div id="file-preview-carousel" className="filePreviewCarousel">
        <SmallLoadableComp loading={loading()}>
          {progressVisible === true && (
            <ProgressBar
              {...{
                percent: downloadProgress,
                style: { minWidth: 300 },
                loading: true,
              }}
            />
          )}
          {!!src &&
            (progressVisible === false || progressVisible === undefined) && (
              <CanvasDrawImage
                {...{
                  src,
                  onDrawSave,
                }}
              />
            )}
        </SmallLoadableComp>
      </div>
    </Modal>
  );
}

export default DrawOnPhotoModal;

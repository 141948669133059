import React, { lazy, Suspense } from "react";
import { useRedux } from "../../../../../../../hooks";
import { checkCheckboxStatus } from "../../utils/checkCheckboxStatus";
import { useCreateChargeContext } from "../../context/NewChargeItemContext";
import { groupServicesByEstimationId } from "../../../ChooseChargeFromModal/utils/groupServicesByEstimationId";

const ElevationAgGrid = lazy(() =>
  import("../ElevationAgGrid/ElevationAgGrid")
);
const RequisitionCharge = lazy(() => import("../../RequisitionCharge"));

const ChargeTables = () => {
  const [objectData] = useRedux("chargeItemsModal");
  const categoryFrom = objectData?.categoryFrom;

  const isRequisition = categoryFrom === "Requisition";

  const { chargeToSave, selectedServices } = useCreateChargeContext();

  const allCharges = groupServicesByEstimationId(chargeToSave?.chargeItems);

  return (
    <div className="PLISContainer">
      {!isRequisition ? (
        allCharges?.map((savedCharge, index) => {
          const isSelected = checkCheckboxStatus(
            selectedServices?.find(
              (estimation) =>
                estimation.estimationId === savedCharge.estimationId
            )
          );

          return (
            <div key={index} style={{ display: isSelected ? "block" : "none" }}>
              {allCharges.length > 1 && (
                <h3 className="estimation-name-charge">{savedCharge.name}</h3>
              )}
              <Suspense
                fallback={<div style={{ color: "white" }}>Loading...</div>}
              >
                {savedCharge.services.map((charge, index) => (
                  <ElevationAgGrid
                    key={index}
                    charge={charge}
                    chargeIndex={index + 1}
                  />
                ))}
              </Suspense>
            </div>
          );
        })
      ) : (
        <Suspense fallback={<div style={{ color: "white" }}>Loading...</div>}>
          <RequisitionCharge />
        </Suspense>
      )}
    </div>
  );
};

export default ChargeTables;

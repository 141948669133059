import React, { useMemo } from "react";
import {
  getRawOptionTotal,
  getRawOptionTotalUnits,
} from "../../tools/formatters/totals";
import { Tooltip } from "antd";
import { wrapWithErrorCalculating } from "../other/ErrorCalculating";
import { formatCurrency } from "../../../../utils";
import { checkIfServiceIsHoist } from "../../models/Service";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { WithTooltip } from "../../../../../commonComponents";
import { getElevationAddonsPrice } from "../ServiceHeader/ServicePrices/ServicePrices";

export default function OptionHeader(props) {
  const { serviceId, option, priceView, service, optionIndex = 0 } = props;

  const totalPriceMapper = (el) =>
    !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
  const totalTaxMapper = (el) => (!isNaN(+el?.taxAmount) ? +el?.taxAmount : 0);
  const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;
  const totalHoistTaxMapper = ({ taxAmount }) => taxAmount;
  console.log("option header", { props });

  const priceTotal = useMemo(() => {
    let elevationAddonsPrice = 0;
    let elevationAddonsTax = 0;
    service?.serviceOptions?.[optionIndex]?.forEach((el) => {
      const { elevationAddonPrice, elevationAddonTax } =
        getElevationAddonsPrice(el);
      elevationAddonsPrice += forceToNumber(elevationAddonPrice);
      // taxes += forceToNumber(elevationAddonTax);
    });
    let price = !checkIfServiceIsHoist(service)
      ? [
          ...service?.serviceOptions?.[optionIndex]?.flatMap?.(({ items }) =>
            items
              ?.filter?.(({ approved }) => !!approved)
              ?.map?.(totalPriceMapper)
          ),
          // ...service?.serviceAddons?.map?.(totalPriceMapper),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0)
          ?.toFixed?.(2)
      : [
          ...service?.serviceOptions?.[optionIndex]?.map?.(
            hoistTotalPriceMapper
          ),
          // ...service?.serviceAddons?.map?.(totalPriceMapper),
        ]
          ?.filter?.(Boolean)
          ?.reduce?.((acc, curr) => (acc += curr), 0)
          ?.toFixed?.(2);
    let taxAmount = !checkIfServiceIsHoist(service)
      ? [
          ...service?.serviceOptions?.[optionIndex]?.flatMap?.(({ items }) =>
            items?.filter?.(({ approved }) => !!approved)?.map?.(totalTaxMapper)
          ),
          // ...service?.serviceAddons?.map?.(totalTaxMapper),
        ]
          ?.filter(Boolean)
          ?.reduce((acc, curr) => (acc += curr), 0)
          ?.toFixed?.(2)
      : [
          ...service?.serviceOptions?.[optionIndex]?.map?.(totalHoistTaxMapper),
          // ...service?.serviceAddons?.map?.(totalHoistTaxMapper),
        ]
          ?.filter?.(Boolean)
          ?.reduce?.((acc, curr) => (acc += curr), 0)
          ?.toFixed?.(2);
    price = forceToNumber(price) + forceToNumber(elevationAddonsPrice);
    taxAmount = forceToNumber(taxAmount) + forceToNumber(elevationAddonsTax);
    return { price, taxAmount };
  }, [service]);

  let notHoistRent = 0;

  option?.forEach?.((el) => {
    el?.items?.forEach?.((item) => {
      if (item?.approved) {
        notHoistRent += forceToNumber(item?.rent);
      }
    });
  });

  const totalAmountOfRent = !checkIfServiceIsHoist(service)
    ? notHoistRent
    : [
        ...service?.serviceOptions?.[optionIndex]?.map?.(
          ({ rentalsTotal = 0 }) => rentalsTotal
        ),
      ]
        ?.filter(Boolean)
        ?.reduce?.((acc, curr) => (acc += curr), 0);
  console.log("option header", {
    priceTotal,
    totalAmountOfRent,
    option,
    service,
  });

  return (
    <StatisticView
      {...{
        service,
        serviceId,
        option,
        priceView,
        optionIndex,
        priceTotal,
        totalAmountOfRent,
      }}
    />
  );
}

const StatisticView = ({
  service,
  serviceId,
  option,
  priceView,
  optionIndex,
  priceTotal,
  totalAmountOfRent,
}) => {
  const {
    unitTotal,
    suffix,
    priceSubtotal,
    averagePPU,
    rentTotal,
    averageTotal,
    averageRent,
  } = getRawOptionTotal(serviceId, option);
  return (
    <div>
      {priceView ? (
        getPriceView(serviceId, service, option, priceTotal, totalAmountOfRent)
      ) : (
        <>{getNonPriceView(serviceId, option)}</>
      )}
    </div>
  );
};

const getPriceView = (
  serviceId,
  service,
  option,
  priceTotal,
  totalAmountOfRent
) => {
  const {
    unitTotal,
    suffix,
    priceSubtotal,
    averagePPU,
    rentTotal,
    averageTotal,
    averageRent,
  } = getRawOptionTotal(serviceId, option);
  console.log("getPriceView", {
    unitTotal,
    suffix,
    priceSubtotal,
    averagePPU,
    rentTotal,
    averageTotal,
    averageRent,
  });
  let rent_higher_than_price = "";
  if (rentTotal >= priceSubtotal && rentTotal !== 0)
    rent_higher_than_price = "rent-higher-than-price";

  return (
    <div
      className="option_header_wrapper"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
      }}
    >
      <div
        className="option_header_wrapper totalUnit"
        style={{
          // width: "100%",
          display: "flex",
          flex: "1 1 20%",
          flexDirection: "row",
          alignItems: "center",
          background: "#20212d",
          fontWeight: "bold",
          padding: "5px 10px",
          borderRadius: "5px 0 0 5px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          gap: "5px",
        }}
      >
        {wrapWithErrorCalculating(unitTotal)} {suffix}
      </div>
      <div
        className="option_header_wrapper otherPrices"
        style={{
          // width: "100%",
          display: "flex",
          flex: "1 1 80%",
          flexDirection: "row",
          justifyContent: "flex-end",
          background: "#00897b",
          background: "linear-gradient(to right,#20212d, #71cf48)",
          borderRadius: "0 5px 5px 0",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          gap: "20px",
          fontWeight: "600",
          padding: "5px 10px",
        }}
      >
        {service?.isScope !== true ? (
          <>
            <div
              className="price_holder"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Price total:{" "}
              <WithTooltip tooltipCategory="Take Off" tooltipKey="totalPrice">
                <span>{wrapWithErrorCalculating(priceTotal.price, true)}</span>
              </WithTooltip>
            </div>
            <div
              className="price_holder"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Tax total:{" "}
              <span className={"elevation-total-priceSubtotal"}>
                <WithTooltip tooltipCategory="Take Off" tooltipKey="totalTax">
                  {wrapWithErrorCalculating(priceTotal.taxAmount, true)}
                </WithTooltip>
              </span>
            </div>
            <div
              className="price_holder"
              style={{
                display: "flex",
                flexDirection: "row",
                justifyContent: "center",
                alignItems: "center",
                gap: "5px",
              }}
            >
              Rent total:{" "}
              <span>
                <WithTooltip tooltipCategory="Take Off" tooltipKey="totalRent">
                  {wrapWithErrorCalculating(
                    totalAmountOfRent > 0 ? totalAmountOfRent : null,
                    true
                  )}
                </WithTooltip>
              </span>
              {/* {rentTotal === undefined
            ? ""
            : !service.isScope && (
                <span
                  className={
                    "option-header-element rent " + rent_higher_than_price
                  }
                >
                  Rent total:{" "}
                  <span className={"elevation-total-priceSubtotal"}>
                    {wrapWithErrorCalculating(
                      totalAmountOfRent > 0 ? totalAmountOfRent : null,
                      true
                    )}
                  </span>
                </span>
              )} */}
            </div>
          </>
        ) : (
          <strong>
            Be aware, this is not a regular Service, but rather OTHER SCOPE.{" "}
          </strong>
        )}
      </div>
    </div>
    // <span className="option-header">
    //   {unitTotal === undefined && suffix === undefined ? (
    //     ""
    //   ) : (
    //     <div>
    //       {wrapWithErrorCalculating(unitTotal)} {suffix}
    //     </div>
    //   )}
    //   <Tooltip
    //     title={
    //       averagePPU && averageTotal && averageRent !== undefined ? (
    //         <div className={"option-header-tooltip"}>
    //           {
    //             <div className={"option-header-element"}>
    //               Average PPU:{" "}
    //               <span className={"elevation-total-priceSubtotal "}>
    //                 {" "}
    //                 {wrapWithErrorCalculating(averagePPU, true)}
    //               </span>
    //             </div>
    //           }
    //           {
    //             <div className={"option-header-element"}>
    //               Average Total:{" "}
    //               <span className={"elevation-total-priceSubtotal "}>
    //                 {wrapWithErrorCalculating(averageTotal, true)}
    //               </span>
    //             </div>
    //           }
    //           {
    //             <div className={"option-header-element"}>
    //               Average Rent:{" "}
    //               <span className={"elevation-total-priceSubtotal "}>
    //                 {wrapWithErrorCalculating(averageRent, true)}
    //               </span>
    //             </div>
    //           }
    //         </div>
    //       ) : null
    //     }
    //   >
    //     <span className={"prices"}>
    //       {priceSubtotal === undefined ? (
    //         ""
    //       ) : (
    //         <div
    //           className={
    //             "option-header-element price " + rent_higher_than_price
    //           }
    //         >
    //           Price total:{" "}
    //           <span className={"elevation-total-priceSubtotal"}>
    //             {wrapWithErrorCalculating(priceTotal.price, true)}
    //           </span>
    //         </div>
    //       )}{" "}
    //       {
    //         <span
    //           className={"option-header-element rent " + rent_higher_than_price}
    //         >
    //           Tax total:{" "}
    //           <span className={"elevation-total-priceSubtotal"}>
    //             {wrapWithErrorCalculating(priceTotal.taxAmount, true)}
    //           </span>
    //         </span>
    //       }
    //       {rentTotal === undefined
    //         ? ""
    //         : !service.isScope && (
    //             <span
    //               className={
    //                 "option-header-element rent " + rent_higher_than_price
    //               }
    //             >
    //               Rent total:{" "}
    //               <span className={"elevation-total-priceSubtotal"}>
    //                 {wrapWithErrorCalculating(
    //                   totalAmountOfRent > 0 ? totalAmountOfRent : null,
    //                   true
    //                 )}
    //               </span>
    //             </span>
    //           )}
    //     </span>
    //   </Tooltip>
    // </span>
  );
};

const getNonPriceView = (serviceId, option) => {
  const { unitTotal, suffix } = getRawOptionTotalUnits(serviceId, option);
  return (
    <div
      className="option_header_wrapper"
      style={{
        width: "100%",
        display: "flex",
        flexDirection: "row",
        background: "#282828",
        justifyContent: "space-between",
        boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
        flexWrap: "wrap",
        padding: "10px",
        borderRadius: "5px",
      }}
    >
      <div
        className="option_header_wrapper totalUnit"
        style={{
          // width: "100%",
          display: "flex",
          flex: "1 1 20%",
          flexDirection: "row",
          alignItems: "center",
          background: "#1a1a1a",
          fontWeight: "bold",
          padding: "5px 10px",
          borderRadius: "5px 0 0 5px",
          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          gap: "5px",
        }}
      >
        {wrapWithErrorCalculating(unitTotal)} {suffix}
      </div>
      <div
        className="option_header_wrapper otherPrices"
        style={{
          // width: "100%",
          display: "flex",
          flex: "1 1 80%",
          flexDirection: "row",
          justifyContent: "flex-end",
          background: "#00897b",
          borderRadius: "0 5px 5px 0",

          boxShadow: "0 2px 4px rgba(0, 0, 0, 0.2)",
          gap: "5px",
          fontWeight: "bold",
          padding: "5px 10px",
        }}
      >
        {/* <strong>Prices are Hidden $$</strong> */}
      </div>
    </div>
  );
};

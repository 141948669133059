import { Badge } from "antd";
import { NewAutomationIcon } from "../../../../assets";
import { WithTooltip } from "../../../commonComponents";
import { useRedux } from "../../../SidebarPages/hooks";
import { useSelector } from "react-redux";
import { useMemo } from "react";

function ChatBotHeader({ toggleDrawer }) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [openChatbot, setOpenChatbot] = useRedux("chatbot", false);
  const hasAccess = useMemo(
    () => userConfiguration?.virtualAssistant?.enabled,
    [userConfiguration?.virtualAssistant?.enabled]
  );
  return hasAccess ? (
    <div className="icon-container">
      <WithTooltip tooltipCategory="HEADER" tooltipKey="virtualAssistant">
        <Badge overflowCount={99}>
          <NewAutomationIcon
            className="header-icon"
            onClick={() => {
              if (openChatbot) {
                setOpenChatbot(false);
              } else {
                setOpenChatbot(true);
                if (toggleDrawer) {
                  toggleDrawer();
                }
              }
            }}
          />
        </Badge>
      </WithTooltip>
    </div>
  ) : null;
}

export default ChatBotHeader;

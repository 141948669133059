import { useContext } from "react";
import { useSelector } from "react-redux";
import { Avatar, Tooltip } from "antd";

import { getInitials } from "../../../../../utils";
import { useMediaQuery } from "../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/use-media-query/use-media-query";
import { StoreType } from "../../../../SidebarPages/FleetMaintenanceView/types";
import VirtualAssistantContext from "../../data/VirtualAssistantContext";
import { AutomationIconFilled } from "../../../../OnBoarding/assets/icons";
import {
  EditIcon,
  WarningTriangle,
} from "../../../../SidebarPages/DynamicView/src";
import { ResizeIcon } from "../../../../../assets";

import "./AssistantChatHeader.scss";
import { greetingMessage } from "../../utils/greetingMessage";
import { NewConversationIcon } from "../../../../InternalChat/assets/icons";

const CHAT_STATUSES = {
  Online: "You're talking to our robot...",
  Connecting: "We're connecting with you, please wait...",
  BotConnecting: "We're connecting with you, please wait...",
  Connected: "You're talking to ",
  Offline: "We're trying to reconnect, please wait...",
  Error: "Error connecting to assistant!",
};

const MOBILE_STATUSES = {
  Online: "You're talking to our robot...",
  Connecting: "We're connecting with you, please wait...",
  BotConnecting: "We're connecting with you, please wait...",
  Connected: "You're talking to ",
  Offline: "We're trying to reconnect, please wait...",
  Error: "Error connecting to assistant!",
};

function AssistantChatHeader() {
  const { userConfiguration } = useSelector(
    /** @param {StoreType} state */
    (state) => state.userConfig
  );
  const { userImages } = useSelector(
    /** @param {StoreType} state*/
    (state) => state.userImages
  );

  const { status, connectedUser, toggleResize, newChatSession } = useContext(
    VirtualAssistantContext
  );

  const mobile = useMediaQuery("(max-width: 767px)");
  const chatFullHeight = useMediaQuery("(max-height: 767px)");

  let statusSuffix = "";
  if (status === "Connected") {
    if (connectedUser?.nameOfUser) {
      statusSuffix = connectedUser.nameOfUser;
    } else {
      statusSuffix = "an employee";
    }
  }

  const isError = status === "Error";
  const isOffline = status === "Offline";

  return (
    <div className={`assistant-chat-header`}>
      {isOffline || isError ? (
        <div className="robot-icon-container">
          <WarningTriangle height={60} width={60} />
        </div>
      ) : connectedUser ? (
        <Avatar
          size={56}
          src={userImages?.[connectedUser?.cognitoUserId]}
          className="header-avatar"
        >
          <span className="avatar-initials">
            {getInitials(connectedUser.nameOfUser)}
          </span>
        </Avatar>
      ) : (
        <div className="robot-icon-container">
          <AutomationIconFilled height={62} width={62} />
        </div>
      )}
      <div className="chat-title">
        <div className="header-user-greeting">
          {greetingMessage(userConfiguration, status)}
        </div>
        <div className="chat-status">
          {(mobile ? MOBILE_STATUSES : CHAT_STATUSES)[status] + statusSuffix}
        </div>
      </div>
      {!chatFullHeight ? (
        <Tooltip title="Resize Window" placement="bottom">
          <div
            className="chat-resize"
            data-testid="chat-resize"
            onClick={toggleResize}
          >
            <ResizeIcon />
          </div>
        </Tooltip>
      ) : null}
      <Tooltip title="New Chat" placement="bottom">
        <div
          className="new-chat-button"
          data-testid="new-chat-button"
          onClick={newChatSession}
        >
          <NewConversationIcon />
        </div>
      </Tooltip>
    </div>
  );
}

export default AssistantChatHeader;

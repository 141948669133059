import axios from "axios";
import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { useSelector, useDispatch } from "react-redux";

import {
  SESSION_KEY,
  VIRTUAL_ASSISTANT_API_URL,
  VIRTUAL_ASSISTANT_ERROR_FIELD_ID,
  VIRTUAL_ASSISTANT_ERROR_FIELD_NAME,
} from "../data/constants";
import { useLocalStorage } from "../../../../utils";
import { useLoading, useProgramFields } from "../../../../hooks";
import { useVirtualAssistantConfig } from "./useVirtualAssistantConfig";
import { trimDataIfLargerThan5mb } from "../utils/trimDataIfLargerThan5mb";
import { updateProgramFields } from "../../../../utils/updateProgramFields";
import { showSwalErrorMessage } from "../../../../utils/swalWindowsMessages";

export const useOpenAiAssistant = ({ setStatus }) => {
  const [threadId, setThreadId] = useLocalStorage("chatThreadId", null);
  const [isError, setIsError] = useState(false);
  const [chatSession, setChatSession] = useLocalStorage(SESSION_KEY);

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { [VIRTUAL_ASSISTANT_ERROR_FIELD_NAME]: assistantErrors } =
    useProgramFields();

  const { saveVirtualAssistantConfig } = useVirtualAssistantConfig("2");

  const [messages, setMessages] = useState(
    chatSession?.messages ? chatSession.messages : []
  );

  const { isLoading, startLoading, stopLoading } = useLoading();

  const dispatch = useDispatch();

  const createThread = async () => {
    // if (!threadId)
    //   try {
    //     const apiUrl = `${VIRTUAL_ASSISTANT_API_URL}/thread`;
    //     const { data: response } = await axios.get(apiUrl);
    //     setThreadId(response.data.threadId);
    //     setStatus("Online");
    //     return response.data.threadId;
    //   } catch (error) {
    //     setIsError(true);
    //     console.error("Error creating thread:", error);
    //     setStatus("Error");
    //   }
    // return;
  };

  const sendMessage = async (message, retry = false) => {
    if (!message.trim()) return;
    startLoading();

    const userMessage = {
      messageId: uuidv4(),
      attachments: [],
      botMessage: false,
      createdAt: Date.now(),
      messageRetried: false,
      messageStatus: "sent",
      parentFolderId: "",
      roomId: threadId,
      roomName: "",
      senderId: userConfiguration.identityId,
      senderName: userConfiguration.nameOfUser,
      text: message,
      questionId: "",
    };

    const botMessage = {
      messageId: uuidv4(),
      attachments: [],
      botMessage: false,
      createdAt: Date.now(),
      messageRetried: false,
      messageStatus: "typing",
      parentFolderId: "",
      roomId: threadId,
      roomName: "",
      senderId: "chatbot",
      senderName: "Core Scaffold Systems Inc.",
      text: "",
      questionId: userMessage.messageId,
    };

    if (!retry) {
      setMessages((prevMessages) => [...prevMessages, userMessage, botMessage]);
    }

    try {
      // const newThreadId = await createThread();

      const apiUrl = `${VIRTUAL_ASSISTANT_API_URL}/messageAi`;

      const requestBody = {
        userId: userConfiguration?.identityId,
        message,
      };

      const { data: response } = await axios.post(apiUrl, requestBody);

      const botResponse = response.data.message;

      setMessages((prevMessages) =>
        prevMessages.map((oldMessage, index) =>
          index === prevMessages.length - 1
            ? {
                ...oldMessage,
                text: botResponse || "",
                messageStatus: "completed",
              }
            : oldMessage
        )
      );

      const trimmedMessages = trimDataIfLargerThan5mb([
        ...messages,
        userMessage,
        { ...botMessage, text: botResponse || "", messageStatus: "completed" },
      ]);

      return trimmedMessages;
    } catch (error) {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.slice(0, -1);

        if (updatedMessages.length > 0) {
          updatedMessages[updatedMessages.length - 1] = {
            ...updatedMessages[updatedMessages.length - 1],
            messageStatus: "error",
            errorMessage:
              error?.response?.data?.error?.status || error?.code || "",
          };
        }

        const newFieldOptions = [
          ...assistantErrors,
          {
            id: uuidv4(),
            ...error?.response?.data?.error,
            nameOfUser: userConfiguration?.nameOfUser,
            identityId: userConfiguration?.identityId,
            retry,
          },
        ];
        updateProgramFields(
          VIRTUAL_ASSISTANT_ERROR_FIELD_ID,
          newFieldOptions,
          dispatch
        );

        return updatedMessages;
      });
    } finally {
      stopLoading();
    }
  };

  const retryMessage = async (messageId) => {
    const messageToRetry = messages.find(
      (message) => message.messageId === messageId
    );

    if (messageToRetry) {
      setMessages((prevMessages) => {
        const updatedMessages = prevMessages.map((message) => {
          if (message.messageId === messageId) {
            return {
              ...message,
              messageStatus: "sent",
              messageRetried: true,
              errorMessage: "",
            };
          }
          return message;
        });

        return [
          ...updatedMessages,
          {
            messageId: uuidv4(),
            attachments: [],
            botMessage: false,
            createdAt: Date.now(),
            messageRetried: false,
            messageStatus: "typing",
            parentFolderId: "",
            roomId: threadId,
            roomName: "",
            senderId: "chatbot",
            senderName: "Core Scaffold Systems Inc.",
            text: "",
          },
        ];
      });

      await sendMessage(messageToRetry.text, true);
    }
  };

  const closeSession = async () => {
    try {
      const apiUrl = `${VIRTUAL_ASSISTANT_API_URL}/closeAiSession`;

      const requestBody = {
        userId: userConfiguration?.identityId,
      };

      await axios.post(apiUrl, requestBody);

      setThreadId(null);
      localStorage.removeItem("chatThreadId");
      localStorage.removeItem("chatSession");
      setMessages([]);
      setIsError(false);
    } catch (error) {
      showSwalErrorMessage({ text: "Failed to close session" });
    }
  };

  const sendFeedback = (messageId, feedback) => {
    if (!messageId) return;

    const message = messages.find((message) => message.messageId === messageId);
    const userMessage = messages.find(
      (userMsg) => userMsg.messageId === message.questionId
    );

    saveVirtualAssistantConfig({
      question: userMessage?.text || "",
      answer: message?.text || "",
      helpful: feedback,
    });

    const messagesToUpdate = messages.map((message) =>
      messageId === message.messageId
        ? {
            ...message,
            helpful: feedback,
          }
        : message
    );

    setMessages(messagesToUpdate);

    if (chatSession) {
      const updatedSession = {
        ...chatSession,
        messages: messagesToUpdate,
        lastAccess: new Date().toISOString(),
      };
      setChatSession(updatedSession);
    }
  };

  return {
    messages,
    setMessages,
    sendMessage,
    isLoading,
    isError,
    createThread,
    retryMessage,
    closeSession,
    sendFeedback,
  };
};

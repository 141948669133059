import { ClearOutlined } from "@ant-design/icons";
import { Drawer, Tooltip, Tour, message } from "antd";
import { API } from "aws-amplify";
import moment from "moment";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { RequestsCategoryCard } from "../../components";
import ActionLogTable from "../../components/ActionLogs/ActionLogTable";
import { approvalInstantDecline } from "../../utils/approvalInstantDecline";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
// import { wsFormRequests } from "../../../../../../AppData";
import { useCreateDriveFolders } from "../../../../../../hooks/useCreateDriveFolders";
import { filterTables } from "../../../../../../utils";
import {
  SearchIcon,
  XIcon,
} from "../../../../../SidebarPages/Communication/assets";
import { WarningTriangle } from "../../../../../SidebarPages/DynamicView/src";
import { NewDispatchModal } from "../../../../../SidebarPages/Fleet/Dispatch/modals";
import { apiRoutes, fetchData } from "../../../../../SidebarPages/Fleet/utils";
import IncidentModal from "../../../../../SidebarPages/Incidents/Modals/IncidentModal";
import NewSafetyInspection from "../../../../../SidebarPages/Safety/Inspections/Modal/NewSafetyInspection";
import { useRedux } from "../../../../../SidebarPages/hooks";
import { compareIncluding } from "../../../../../SidebarPages/utils";
import NewInspectionShortcut from "../../../../../Sidebars/components/NewShortcutContent/modals/Inspections/NewInspectionShortcut";
import {
  ConfirmationModal,
  ExportOrEmailDropdown,
  MondayButton,
  SwitchComponent,
  WarningModal,
} from "../../../../../commonComponents";
import { findTutorialSteps } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import {
  ClaimsModal,
  EstimationModal,
  OpportunityModal,
  PermitDrawingModal,
  ProjectModal,
} from "../../../../forms";
import NewSchedule from "../../../../forms/Scheduling/NewSchedule/NewSchedule";
import {
  allRequests,
  approveActions,
  declineActions,
  requestCards,
} from "../../approvalsData";
import { orderRecords } from "../../../ToDos/helpers";
import { companyDetails } from "../../../../../../helpers/constants";
import { uniqBy } from "lodash";
import "./ApprovalsDrawer.scss";
import { DocumentationModal } from "../../../../../SidebarPages/Documentation/View/components";
import useExportHeadersModal from "../../../../../../hooks/useExportHeadersModal";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "../../utils/exportPdfExcel";
import PdfHeadersModal from "../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import { tableHeaders, tableHeadersRequest } from "../../constants/tableHeadrs";
import MultiStepModal from "../../../../../SidebarPages/Projects/Tabs/Estimations/EstimationChangeOrder/MultiStepModal";
import NewRequest from "../../../../forms/RequestFormModal/NewRequest";
import NewWorkOrderModal from "../../../../../SidebarPages/FleetsMaintenances/Tabs/WorkOrder/Modals/NewWorkOrderModal";
import NewFleetInventory from "../../../../../SidebarPages/FleetsMaintenances/Tabs/FleetInventory/Modals/NewFleetInventory";
import {
  addNewRecordToRequest,
  REQUEST_FORM_TYPES,
} from "../../../../forms/RequestFormModal/helpers";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useEditLogs } from "../../../../../../hooks";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { InfoIcon } from "../../../GoogleEvents/assets";
import { useCustomerWebsocketContext } from "../../../../../../contexts/WebsocketProvider/useCustomerWebsocket";
import ResponsiveHeaderButtons from "../../../../../commonComponents/ResponsiveHeaderButtons/ResponsiveHeaderButtons";

const {
  PERMIT_DRAWING,
  ESTIMATION,
  SCHEDULING,
  DISPATCH,
  SAFETY_INSPECTION,
  PROJECT,
  CLAIM,
  INCIDENT,
  SERVICE_INSPECTION,
  SCHEDULE_CHANGE,
  DOCUMENTATION,
  CHANGE_ORDER,
  OPPORTUNITY,
  WORK_ORDER,
  FLEET_INVENTORY,
} = REQUEST_FORM_TYPES;
// const requestFormWs = new WebSocket(wsFormRequests.online);

const ApprovalsDrawer = ({
  visible,
  setVisible,
  setRowData,
  wsFRequests,
  notRespondedFormRequests = [],
}) => {
  const navigate = useNavigate();
  const driveFunctions = useCreateDriveFolders("Scheduling");
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { companyName } = companyDetails;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [requestToBeCreated, setRequestToBeCreated] = useState();
  const [rowDataSchedule, setRowDataSchedule] = useState();
  const [services, setServices] = useState();
  const [docConfiguration, setDocConfiguration] = useState();
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const [clearPrefs, setClearPrefs] = useState(false);

  const [isLoading, setIsLoading] = useState({
    new: false,
    edit: false,
  });

  const [requestToBeReviewed, setRequestToBeReviewed] = useState();
  const [newDispatchVisible, setNewDispatchVisible] = useState(false);
  const [searchValue, setSearchValue] = useState();
  const [requestToView, setRequestToView] = useState();
  const [warningVisible, setWarningVisible] = useState(false);
  const [newSafetyInspectionVisible, setNewSafetyInspectionVisible] =
    useState(false);
  const serviceDefinitions = useSelector((state) => state.serviceDefinitions);
  const { base64 } = useSelector((state) => state.base64);
  const [tourOpen, setTourOpen] = useState(false);
  const [schedules, setSchedules] = useState([]);
  const [isWritable, setIsWritable] = useRedux("isWritableScheduling");
  const [logsRowData, setLogsRowData] = useState([]);
  const [draggableApprovals, setDraggableApprovals] = useState([]);
  const [disabled, setDisabled] = useState(true);
  const [preferences, setPreferences] = useState([]);
  const [showUnsavedChangesModal, setShowUnsavedChangesModal] = useState(false);
  const [step, setStep] = useState(0);
  const [changeOrderVisible, setChangeOrderVisible] = useState(false);

  const prevDataRef = useRef();
  const { saveAddedLogs } = useEditLogs();

  const { sendWsMessage } = useCustomerWebsocketContext();

  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const { accessToken } = useSelector((state) => state.accessToken);
  const [panel, setPanel] = useState({
    status: "Requests",
    checked: false,
  });

  const {
    requestId,
    requestType: requestToBeCreatedType,
    recordId,
    projectManager,
    requestObject = {},
  } = requestToBeCreated || {};

  const {
    jobsiteAddress,
    projectExecutive,
    dueDate,
    proposedTypeOfWork,
    // recordId,
  } = requestObject;

  const actionLogsAccess = userConfiguration?.routeConfig?.find(
    (e) => e?.title === "Approvals"
  )?.write;

  function incidentAddress(category, requestObject) {
    switch (category) {
      case "Property Damage":
        return { addressOfDamagedProperty: requestObject?.jobsiteAddress };
        break;
      case "Personal Injury":
        return { addressOfInjury: requestObject?.jobsiteAddress };
        break;
      case "Other Trade Incident":
        return { addressOfIncident: requestObject?.jobsiteAddress };
        break;
      case "Vehicle Damage":
        return { incidentAddress: requestObject?.jobsiteAddress };
        break;
    }
  }

  function closeApprovals() {
    setVisible(false);
    setPanel({
      status: "Requests",
      checked: false,
    });
  }

  const goToPreviousSchedule = (scheduleId) => {
    openInNewTab(`/scheduling/${scheduleId}`);
  };

  /**
   * Filtered list of form requests based on the search value.
   *
   * @type {Array}
   */
  const requestsToRender = useMemo(
    () =>
      !searchValue
        ? notRespondedFormRequests
        : notRespondedFormRequests.filter((request) => {
            const {
              userRequesting,
              requestType,
              requestObject = {},
            } = request || {};
            const { jobsiteAddress } = requestObject;

            return [userRequesting, requestType, jobsiteAddress].some((value) =>
              compareIncluding(value, searchValue)
            );
          }),
    [notRespondedFormRequests, searchValue]
  );

  const emptyApprovals = Object?.values(allRequests(requestsToRender))?.every(
    (el) => el?.length === 0
  );

  const onAddressClick = (category, recordId) => {
    navigate(`${category}/${recordId}`);
    closeApprovals();
  };

  /**
   * Handles the approval of a request form.
   *
   * @param {Object} requestForm - The request form to be approved.
   */
  const onApprove = (requestForm) => {
    const status = requestForm?.requestObject?.status;
    const { requestId } = requestForm;
    switch (status) {
      case "Needs Review": {
        setRequestToBeReviewed({
          action: approveActions[status],
          requestForm,
        });
        break;
      }

      default:
        if (requestForm?.requestType === DISPATCH) {
          setNewDispatchVisible(true);
        } else if (requestForm?.requestType === SAFETY_INSPECTION) {
          setNewSafetyInspectionVisible(true);
        } else if (requestForm?.requestType === CHANGE_ORDER) {
          setChangeOrderVisible(true);
        }
        setRequestToBeCreated(requestForm);

        break;
    }

    // closeApprovals();
  };

  useEffect(() => {
    API.get("preferences", "/preferences").then((res) => {
      setPreferences(res.preferences);
      if (res.preferences.approvalsData) {
        setDraggableApprovals(
          orderRecords(
            requestCards,
            res?.preferences?.approvalsData?.map((el) => {
              if (!Array.isArray(el) && typeof el === "object") {
                return el?.category;
              } else {
                return el;
              }
            }),
            "category"
          )
        );
      } else {
        setDraggableApprovals(requestCards);
      }
    });
  }, [clearPrefs]);

  const onDecline = (requestForm) => {
    setRequestToBeReviewed({
      action: declineActions[requestForm?.requestObject?.status],
      requestForm,
    });
    // closeApprovals();
  };

  function onView(requestForm) {
    setRequestToView({
      action: "Draft",
      requestForm,
    });
  }

  function onInstantDecline(requestForm) {
    setWarningVisible(requestForm);
  }

  let actionRequest = requestToBeReviewed || requestToView;

  const getRightDate = (prjId, tpw) =>
    rowDataSchedule
      ?.filter((el) => el?.projectId === prjId && el?.typeOfWork === tpw)
      ?.sort((a, b) => moment(b?.createdAt - moment(a?.createdAt)))[0]
      ?.scheduleDays?.sort(
        (a, b) => moment(b?.startDate) - moment(a?.startDate)
      )[0];

  useEffect(() => {
    if (recordId) {
      if (
        requestToBeCreatedType === SCHEDULING ||
        requestToBeCreatedType === SCHEDULE_CHANGE
      ) {
        message.loading({
          key: "schedule",
          content: "Opening Schedule Form ...",
          duration: 0,
        });

        if (requestToBeCreatedType === SCHEDULING) {
          Promise.allSettled([
            filterTables("scheduling", "projectId", recordId),
            API.get("projects", `/projects/${recordId}`),
          ])
            .then(([{ value: schedule }, { value: project }]) => {
              setRowDataSchedule(schedule[0]);

              if (project?.services) {
                setServices(project.services);
              }
              // setTimeout(() => {
              setIsLoading({ ...isLoading, new: true });

              message.destroy("schedule");
              // }, 1000);
            })
            .catch((err) => {
              console.error(err);
              message.destroy("schedule");
              message.error("Something went wrong");
            });
        } else {
          setIsLoading({ ...isLoading, edit: true });
          message.destroy("schedule");
        }
      } else if (requestToBeCreatedType === DOCUMENTATION) {
        message.loading({
          key: "documentation",
          content: "Opening Documentation Form ...",
          duration: 0,
        });
        fetchData(apiRoutes.docConfiguration)
          .then((res) => {
            setDocConfiguration(res);
            message.destroy("documentation");
          })
          .catch((err) => {
            console.error(err);
            message.destroy("documentation");
            message.error("Something went wrong");
          });
      }
    }
  }, [recordId]);

  function stepsMapHelper(title) {
    const stepsMap = {
      "Search Approvals": document.querySelector(".requestsSearchField"),
      "Approvals Categories": document.querySelector(".firstRequestCategory"),
      Request: document.querySelector(".formRequestCard"),
      "View Request": document.querySelector(".actionIcon"),
      "Request Address": document.querySelector(".jobsiteAddress"),
      "Request Actions": document.querySelector(".btnContainer"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    emptyApprovals ? newSteps.splice(2, 4) : newSteps;
    return newSteps;
  }

  const tourSteps = mapRefs(
    findTutorialSteps("assignedApprovalsModal", programFields)
  );

  function changeView(e) {
    if (e) {
      setPanel({
        status: "Logs",
        checked: true,
      });
    } else {
      setPanel({
        status: "Requests",
        checked: false,
      });
    }
  }

  /**
   * Handles the save operation for the approvals drawer.
   */
  const handleSave = () => {
    message.loading({ content: "Saving changes...", duration: 0 });
    if (preferences.length != 0) {
      API.patch("preferences", "/preferences", {
        body: {
          preferences: {
            ...preferences,
            approvalsData: draggableApprovals,
          },
        },
      })
        .then(() => {
          message.destroy();
          setDisabled(true);
          setClearPrefs((prev) => !prev);
          message.success("Changes saved successfuly!");
        })
        .catch((err) => {
          message.destroy();
          message.error("Thee was an error while saving this!");
        });
    } else {
      message.error("There was an error while saving this");
    }
  };

  /**
   * Approves the log and saves the added logs.
   */
  const approveLog = () => {
    saveAddedLogs({
      recordName: requestObject?.recordName,
      category: requestToBeCreated?.category,
      topic: requestToBeCreatedType,
      actionType: "Created",
      category: "Approvals Action",
      nameOfUser: authUser?.nameOfUser,
      currentData: {
        requestedBy: requestObject?.userRequesting,
        category: requestObject?.category,
        recordId: requestObject?.recordId,
        requestId,
      },
    });
  };

  /**
   * Performs actions after saving a request.
   *
   * @param {Object} options - The options for afterSaveRequest.
   * @param {string} options.newRecordId - The ID of the new record.
   * @param {string} options.path - The path of the new record.
   * @param {Object|null} options.moveFilesParams - The parameters for moving files (optional).
   * @returns {Promise<void>} - A promise that resolves when the actions are completed.
   */
  async function afterSaveRequest({
    newRecordId,
    path,
    moveFilesParams = null,
  }) {
    await addNewRecordToRequest({
      ws: wsFRequests,
      newRecordId,
      path,
      // cognitoUserId: userConfiguration?.cognitoUserId,
      requestId,
      moveFilesParams,
      requestType: requestToBeCreatedType,
      approveLog,
      setVisible,
      sendWsMessage,
    });
  }

  const handleClearOrderPreference = () => {
    if (!preferences?.approvalsData) {
      message.info("There is no preference saved before...");
      return;
    }
    setOpenConfirmModal(true);
  };

  const handleOnConfirmModal = () => {
    message.loading("Saving...");
    const { approvalsData, ...updatedPreferences } = preferences;

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        setDraggableApprovals(requestCards);
        setClearPrefs((prev) => !prev);
        message.destroy();
        message.success("Order preference has been deleted!");
      })
      ?.catch((err) => console.error(err));
  };

  const additionalData = {
    data:
      panel.status === "Requests"
        ? draggableApprovals?.map((el) => ({
            request: allRequests(requestsToRender)?.[el?.category],
            category: el.category,
          }))
        : logsRowData,
    status: panel.status === "Requests" ? "Requests" : "Approvals",
    base64,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({ additionalData, onGeneratePDF, exportToExcel });

  const headerButtons = (setOpen) => (
    <>
      <ExportOrEmailDropdown
        {...{
          rowData: draggableApprovals,
          onGeneratePDF: (action, getDocDefinition) =>
            getDocDefinition
              ? onGeneratePDF(action, additionalData, getDocDefinition)
              : getHeadersModal(action, getDocDefinition),
          exportGridToExcel: (getDocDefinition) =>
            getDocDefinition
              ? exportToExcel(additionalData, getDocDefinition)
              : getHeadersModal(false, getDocDefinition, "excel"),
          uploadExcelToDrive: (driveRequest, driveFolderId) =>
            uploadExcelToDrive(additionalData, driveRequest, driveFolderId),
          defaultDocDefinition: true,
          title: panel.status === "Requests" ? "Approvals" : "Requests",
        }}
      />
      {!!actionLogsAccess && (
        <div onClick={() => setOpen && setOpen(false)}>
          <SwitchComponent
            {...{
              label: panel?.status,
              checked: panel?.checked,
              onChange: (e) => {
                changeView(e);
              },
            }}
          />
        </div>
      )}

      <Tooltip title="Clear order preference">
        <div onClick={() => setOpen(false)}>
          <MondayButton
            data-testid="clear-order"
            className="mondayButtonBlue"
            Icon={<ClearOutlined />}
            onClick={handleClearOrderPreference}
          />
        </div>
      </Tooltip>

      <Tooltip title="Guide">
        <div onClick={() => setOpen(false)}>
          <InfoIcon
            className="guide-icon"
            onClick={() => {
              tourSteps.length > 0
                ? setTourOpen(true)
                : message.info("No guide available for this form");
            }}
          />
        </div>
      </Tooltip>
      <InputComponent
        type="input"
        value={searchValue}
        prefix={<SearchIcon />}
        placeholder="Search approvals..."
        onChange={({ target: { value } }) => setSearchValue(value)}
        className="requestsSearchField"
        allowClear={true}
      />
    </>
  );

  const headerComponents = () => {
    return (
      <div className="approvalsPopOverHeader">
        <ResponsiveHeaderButtons headerButtons={headerButtons} />
      </div>
    );
  };

  const dragEndHandler = (result) => {
    if (!result?.destination) return;
    const items = Array.from(draggableApprovals);
    const [reorderedItem] = items?.splice(result?.source?.index, 1);
    items?.splice(result?.destination?.index, 0, reorderedItem);
    setDraggableApprovals(items);
    setDisabled(false);
  };

  const categoryConfiguration = () => {
    return docConfiguration?.find(
      (el) => el?.categoryName === requestObject?.documentationCategory
    );
  };

  return (
    <>
      {panel?.status === "Requests" ? (
        <Drawer
          {...{
            title: "Approvals",
            extra: headerComponents(),
            size: "large",
            open: visible,
            closeIcon: <XIcon />,
            onClose: () => {
              setVisible(false);
            },
            className: `approvalsPopOver ${
              isDarkMode && "approvalsPopOverDark"
            }`,
            contentWrapperStyle: { width: "850px" },
            onClose: () => {
              if (disabled) {
                closeApprovals();
              } else {
                setShowUnsavedChangesModal(true);
              }
            },
          }}
        >
          <div className="approvalsPopOverContent">
            <DragDropContext onDragEnd={dragEndHandler}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="requestsSection"
                    {...provided?.droppableProps}
                    ref={provided?.innerRef}
                  >
                    {draggableApprovals?.map((el, i) => (
                      <Draggable
                        draggableId={`droppable-${i}`}
                        index={i}
                        key={i}
                      >
                        {(provided) => (
                          <div
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            ref={provided.innerRef}
                          >
                            <RequestsCategoryCard
                              {...{
                                createdId: requestToBeCreated?.requestId,
                                category: el?.category,
                                requests:
                                  allRequests(requestsToRender)?.[el?.category],
                                onAddressClick: el?.addressClick
                                  ? onAddressClick
                                  : () => {},
                                onApprove,
                                onDecline,
                                onView,
                                onInstantDecline,
                                i,
                              }}
                            />
                          </div>
                        )}
                      </Draggable>
                    ))}
                    {provided?.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          </div>
        </Drawer>
      ) : (
        <Drawer
          {...{
            title: "Approvals",
            extra: headerComponents(),
            size: "large",
            open: visible,
            onClose: () => setVisible(false),
            className: `approvalsPopOver ${
              isDarkMode && "approvalsPopOverDark"
            }`,
            contentWrapperStyle: { width: "850px" },
            onClose: () => closeApprovals(),
          }}
        >
          <div className="approvalsPopOverContent">
            <div className="requestsSection">
              <ActionLogTable
                logsRowData={logsRowData}
                setLogsRowData={setLogsRowData}
                {...{ searchValue, closeDrawer: setVisible }}
              />
            </div>
          </div>
        </Drawer>
      )}
      {requestToBeCreatedType === PERMIT_DRAWING && (
        <PermitDrawingModal
          visible
          {...{
            requestId,
            setVisible: () => setRequestToBeCreated(""),
            permitDrawing: {
              projectId: recordId,
              jobSiteAddress: requestObject?.recordName,
              projExecutive: projectExecutive,
              services: serviceDefinitions
                .filter(({ serviceId }) =>
                  proposedTypeOfWork?.includes(serviceId)
                )
                .map(({ serviceName }) => serviceName),
              requestFiles: requestObject?.gDriveUploads,
              projectManager,
              drawingType: requestObject?.drawingType,
            },
            requestObject,
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === ESTIMATION && (
        <EstimationModal
          visible
          {...{
            setVisible: () => {
              setRequestToBeCreated("");
              setVisible(false);
            },
            requestId,
            requestObject,
            requestFiles: requestObject?.gDriveUploads,
            estimation: {
              jobSiteAddress: requestObject?.recordName,
              dueDate,
              accountName: requestObject?.selectedMainField?.accountName,
              accountId: requestObject?.selectedMainField?.accountId,
              leadId: requestObject?.selectedMainField?.leadId,
              projectExecutive,
              projectIdData:
                requestToBeCreated?.category === "opportunities"
                  ? requestObject?.selectedMainField?.projectId
                  : recordId,
              opportunityId:
                requestToBeCreated?.category === "opportunities"
                  ? recordId
                  : "",
            },
            afterSaveRequest,
          }}
        />
      )}
      {!!isLoading?.new && (
        <NewSchedule
          {...{
            isLoading: isLoading?.new,
            onChange: () => {
              setIsLoading({ ...isLoading, new: false });
              setRequestToBeCreated(null);
            },
            scheduleFormOpen: /*!schedules?.length ? [{}] : schedules*/ [{}],
            refreshTable: () => {},
            goToPreviousSchedule,
            userConfiguration,
            projectExecutive: projectExecutive,
            // requestTypeOfWork: typeOfWork,
            projectAddress: requestObject?.recordName,
            projectManagers: projectManager,
            projectId: recordId,
            requestId,
            projectObj: {
              projectId: recordId,
              projectName: requestObject?.recordName,
              services,
            },
            // typeOfWork: typeOfWork,
            driveFunctions,
            getRightDate,
            editMode: false,
            requestFiles: requestObject?.gDriveUploads,
            requestObject,
            accessToken,
            afterSaveRequest,
            // project,
          }}
        />
      )}
      {!!isLoading?.edit && (
        <NewSchedule
          {...{
            isLoading: isLoading.edit,
            onChange: () => {
              setIsLoading({ ...isLoading, edit: false });
              setRequestToBeCreated(null);
            },
            scheduleFormOpen: /*!schedules?.length ? [{}] : schedules*/ [{}],
            refreshTable: () => {},
            updateRowData: () => {},
            editMode: true,
            goToPreviousSchedule,
            userConfiguration,
            projectExecutive: projectExecutive,
            // requestTypeOfWork: typeOfWork,
            projectAddress: jobsiteAddress,
            projectManagers: projectManager,
            projectId: requestObject?.selectedMainField?.projectId,
            scheduleId: requestObject?.selectedMainField?.scheduleId,
            toBeScheduled:
              requestObject?.selectedMainField?.toBeScheduled || {},
            schedule: requestObject?.selectedMainField,
            requestObject,
            driveFunctions,
            getRightDate,
            requestId,
            // state: estimationsForSchedule,
            schedules: [{}],
            isWritable,
            setIsWritable,
            ableToSave: true,
            // fromApprovals: true,
            projectAddress: jobsiteAddress,
            pricing: [],
            getRightDate() {},
            units: [],
            proposedTypeOfWork: [],
            unchangedRowData: {},
            serviceDefinitions: [],
            setScheduleLogsVisibility() {},
            authenticatedUser: {},
            companyName: "",
            userConfiguration: {},
            handleChange() {},
            setEditSchedule() {},
            driveRequest: driveApi({ accessToken }),
            project: {},
            notesAccessRights() {},
            darkMode: false,
            setRowData() {},
            dispatchForSchedule: [],
            ableToSave: true,
            userConfiguration,
            afterSaveRequest,
          }}
        />
      )}
      {!!newDispatchVisible && (
        <NewDispatchModal
          {...{
            visible: !!newDispatchVisible,
            setVisible: setNewDispatchVisible,
            requestForm: requestToBeCreated,
            afterSaveRequest,
            // requestObject,
          }}
        />
      )}

      {!!newSafetyInspectionVisible && (
        <NewSafetyInspection
          {...{
            visible: !!newSafetyInspectionVisible,
            setVisible: setNewSafetyInspectionVisible,
            rowObject: requestObject,
            inspectionCategory: requestObject?.inspectionCategory,
            requestId,
            afterSaveRequest,
          }}
        />
      )}

      {requestToBeCreatedType === PROJECT && (
        <ProjectModal
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            defaultData: {
              ...requestObject,
              projectName: requestObject?.jobsiteAddress,
              accountName: requestObject?.accountName,
            },
            fromApprovals: true,
            requestId,
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === CLAIM && (
        <ClaimsModal
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            category: requestObject?.claimCategory,
            incident: { id: recordId, name: requestObject?.recordName },
            requestFiles: requestObject?.gDriveUploads,
            requestId,
            requestObject,
            isNextStep: false,
            nextStepHandler: () => {},
            handleSavedRecord: () => {},
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === INCIDENT && (
        <IncidentModal
          {...{
            setEdit: () => setRequestToBeCreated(""),
            requestObject: {
              ...requestObject,
              incidentObject: incidentAddress(
                requestObject?.incidentCategory,
                requestObject
              ),
              incidentAddress: requestObject?.jobsiteAddress,
            },
            category: requestObject?.incidentCategory,
            requestId,
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === SERVICE_INSPECTION && (
        <NewInspectionShortcut
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            companyName,
            propedRowData: { ...requestObject, projectId: recordId },
            requestId,
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === DOCUMENTATION && docConfiguration?.length && (
        <DocumentationModal
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            recordId,
            recordValue: requestObject?.recordName,
            recordName: requestObject?.recordName,
            viewTitle: requestObject?.recordName,
            configExpirationDate:
              (categoryConfiguration()?.documentationsAvailable || [])?.find(
                (el) => el?.docName === requestObject?.documentationType
              ) || {},
            accessToken,
            docId: null,
            docType: requestObject?.documentationType,
            categoryName: requestObject?.documentationCategory,
            docObject:
              (categoryConfiguration()?.documentationsAvailable || [])?.find(
                (el) => el?.docName === requestObject?.documentationType
              )?.docObject || [],
            populatedStatuses: uniqBy(
              (categoryConfiguration()?.documentationsAvailable || [])?.flatMap(
                ({ docStatuses = [] }) => docStatuses
              ),
              "statusName"
            ),
            docStatuses:
              (categoryConfiguration()?.documentationsAvailable || [])?.find(
                (el) => el?.docName === requestObject?.documentationType
              )?.docStatuses || [],
            forceEnableWrite: true,
            recordFolderId: requestObject?.docObject || "",
            googleDriveUploads: requestObject?.gDriveUploads,
            setDocumentation: () => {},
            requestId,
            afterSaveRequest,
          }}
        />
      )}
      {changeOrderVisible && (
        <MultiStepModal
          {...{
            visible: !!changeOrderVisible,
            setVisible: () => setRequestToBeCreated(""),
            step,
            setStep,
            modals: {
              Opportunity: {
                Component: OpportunityModal,
                data: {
                  rowData: [],
                  changeOrderData: {
                    ...requestObject,
                    jobsiteAddress: requestObject?.recordName,
                    projectName: requestObject?.recordName,
                    isChangeOrder: true,
                  },
                  refreshTable(add) {
                    Array.isArray(add)
                      ? (prevDataRef.current = add[0])
                      : (prevDataRef.current = add);

                    setStep((prev) => prev + 1);

                    setChangeOrderVisible((prev) =>
                      prev === true ? prev : false
                    );
                  },
                  // requestId,
                  estimationChangeOrder: true,
                  setVisible: setChangeOrderVisible,
                },
                message:
                  "You are creating a new Opportunity that is Change Order",
              },

              Estimation: {
                Component: EstimationModal,
                data: {
                  estimation: {
                    projectIdData: requestObject?.projectId,
                    jobSiteAddress: prevDataRef.current?.opportunityAddress,
                    projectExecutive: prevDataRef.current?.projectExecutive,
                    accountName: prevDataRef.current?.accountName,
                    opportunityId: prevDataRef.current?.opportunityId,
                  },
                  isChangeOrder: true,
                  authenticatedUser: authUser,
                  accessToken,
                  requestId,
                  requestObject,
                  refreshTable: () => {
                    setChangeOrderVisible(false);
                  },
                  afterSaveRequest,
                },
                message:
                  "You are creating a new Estimation that is Change Order for this Opportunity",
              },
            },
          }}
        />
      )}
      {requestToBeCreatedType === OPPORTUNITY && (
        <OpportunityModal
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            changeOrderData: {
              ...requestObject,
              alternativeAddresses: [],
              projectName: jobsiteAddress,
              accountName: requestObject?.recordName,
              projectExecutive,
              isChangeOrder: false,
            },
            rowData: [],
            requestId,
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === WORK_ORDER && (
        <NewWorkOrderModal
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            requestObject: {
              ...requestObject,
              requestId,
            },
            afterSaveRequest,
          }}
        />
      )}
      {requestToBeCreatedType === FLEET_INVENTORY && (
        <NewFleetInventory
          {...{
            visible: !!requestToBeCreated,
            setVisible: () => setRequestToBeCreated(""),
            requestObject: {
              ...requestObject,
              requestId,
            },
            afterSaveRequest,
          }}
        />
      )}

      {!!actionRequest && (
        <NewRequest
          {...{
            action: requestToBeReviewed?.action,
            visible: !!actionRequest,
            setVisible: () => {
              setRequestToBeReviewed("");
              setRequestToView();
              setVisible(false);
            },
            requestForm: actionRequest?.requestForm,
            requestType: actionRequest?.requestForm?.requestType,
            globalDisabled: !!requestToView,
            from: "header",
          }}
        />
      )}
      {!!warningVisible && (
        <WarningModal
          {...{
            visible: !!warningVisible,
            setVisible: setWarningVisible,
            title: "Warning Message",
            closable: true,
            className: "logout-warning-modal",
            darkMode: isDarkMode,
          }}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>
              Are you sure you want to decline this request without reviewing
              it?
            </p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => {
                  setWarningVisible(false);
                }}
              >
                No
              </MondayButton>
              <MondayButton
                Icon={<TickIcon />}
                onClick={async () => {
                  approvalInstantDecline(
                    warningVisible,
                    wsFRequests,
                    authUser,
                    saveAddedLogs,
                    sendWsMessage
                  ).then(() => {
                    setWarningVisible(false);
                  });
                }}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {!!showUnsavedChangesModal && (
        <WarningModal
          {...{
            visible: !!showUnsavedChangesModal,
            setVisible: setShowUnsavedChangesModal,
            title: "Warning Message",
            closable: true,
            className: "logout-warning-modal",
          }}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>You have unsaved changes.</p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => {
                  setShowUnsavedChangesModal(false);
                }}
              >
                Cancel Changes
              </MondayButton>
              <MondayButton
                Icon={<TickIcon />}
                onClick={() => {
                  setShowUnsavedChangesModal(false);
                  handleSave();
                  setVisible(false);
                }}
              >
                Save Changes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {openConfirmModal && (
        <ConfirmationModal
          onConfirm={handleOnConfirmModal}
          visible={openConfirmModal}
          setVisible={setOpenConfirmModal}
          title="Confirm"
          text="Are you sure you want to clear order preference"
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers:
              panel.status === "Requests" ? tableHeadersRequest : tableHeaders,
          }}
        />
      )}
    </>
  );
};

export default ApprovalsDrawer;

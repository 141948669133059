import "./ActiveAutomations.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import {
  ClockCircleOutlined,
  PauseOutlined,
  ThunderboltOutlined,
} from "@ant-design/icons";
import { Avatar, Button, Divider, Tag, Tooltip } from "antd";
import {
  automationRecurrence,
  sendAutomationNotification,
  stopAutomation,
} from "../../../../../pages/Settings/settingsComponents/Automation/AllAutomations/utils";
import { RedWarningModal } from "../../../../../commonComponents";
import AutomationResponses from "../AutomationResponses/AutomationResponses";
import { formatAutomationResponses } from "../../utils";
import Execution from "../../../../../pages/Settings/settingsComponents/PostEventsAutomationsConfig/components/Execution";

const ActiveAutomations = ({
  responses = {},
  setAllAutomations,
  fetchedLogs,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { topicCategories } = useSelector((state) => state.topicCategories);

  const [showLogs, setShowLogs] = useState(false);

  const postEventsAutomations = responses["Post Events Automations"];

  return (
    <div
      className={`active-automations-container${
        isDarkMode ? " dark-mode" : ""
      }`}
    >
      <ActiveAutomationsList
        responses={responses.Automation?.map(
          ({ automationCategory, automationTime, ...automation }) => ({
            ...automation,
            categories: automationCategory.map(
              (category) =>
                topicCategories?.find((cat) => {
                  const currCategoryId =
                    typeof category === "object"
                      ? category.categoryId
                      : category;

                  return cat.categoryId === currCategoryId;
                })?.categoryName
            ),
            automationTime: automationRecurrence(automationTime),
          })
        )}
        setAllAutomations={setAllAutomations}
        setShowLogs={setShowLogs}
      />

      {!!postEventsAutomations?.length && (
        <>
          <Divider>Post Events Automations</Divider>
          <ActiveAutomationsList
            isPostEventAutomation={true}
            responses={postEventsAutomations.map((automation) => {
              const { automationName, categoryName } = automation;

              return {
                ...automation,
                automationId: automationName,
                categories: [categoryName],
                automationTime: "Executes after event",
              };
            })}
            setAllAutomations={setAllAutomations}
            setShowLogs={setShowLogs}
          />
        </>
      )}

      {showLogs && (
        <AutomationResponses
          {...{
            title: `Responses for ${showLogs?.automationName}`,
            responses: formatAutomationResponses(
              fetchedLogs.filter(
                ({ recordId }) => recordId === showLogs.automationId
              )
            ),
          }}
        />
      )}
    </div>
  );
};

export default ActiveAutomations;

const ActiveAutomationsList = ({
  isPostEventAutomation,
  responses,
  setAllAutomations,
  setShowLogs,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [warningModal, setWarningModal] = useState(false);

  const stopAutomatedJob = (automation) => {
    stopAutomation(automation).then(() => {
      sendAutomationNotification(
        "34",
        "onAutomationStopped",
        automation.automationId,
        automation.automationName,
        userConfiguration
      );

      API.put("automations", `/automations/${automation.automationId}`, {
        body: { automationStatus: "Stopped" },
      }).then(() => {
        setAllAutomations((prev) =>
          prev.map((item) =>
            item.automationId === automation.automationId
              ? { ...item, automationStatus: "Stopped" }
              : item
          )
        );
      });
    });

    setWarningModal(false);
  };

  return (
    <div className="active-automations">
      {responses.map((automation) => {
        const { automationId, automationName, categories, automationTime } =
          automation;

        return (
          <div
            className="active-automation"
            key={automationId}
            onClick={() => {
              setShowLogs(
                (prev) => prev?.automationId !== automationId && automation
              );
            }}
          >
            <div style={{ display: "flex", alignItems: "center", gap: "10px" }}>
              <div className="icon">
                <Avatar icon={<ThunderboltOutlined />} />
              </div>
              <div className="description">
                <div className="name">{automationName}</div>

                <div className="infos">
                  {categories &&
                    categories.map((category) => {
                      return (
                        <Tag style={{ textAlign: "center" }}>{category}</Tag>
                      );
                    })}
                  <Tag icon={<ClockCircleOutlined />}>{automationTime}</Tag>
                </div>
              </div>
            </div>
            <div className="list-item-meta-unglobal">
              <div className="stop">
                {isPostEventAutomation ? (
                  <Execution data={automation} />
                ) : (
                  <Tooltip title="Stop this automation">
                    <Button
                      danger
                      icon={<PauseOutlined />}
                      onClick={(e) => {
                        e.stopPropagation();
                        setWarningModal(automation);
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            </div>
          </div>
        );
      })}
      <RedWarningModal
        visible={warningModal}
        onCancel={() => setWarningModal(false)}
        footerProps={{
          onConfirm: () => {
            stopAutomatedJob(warningModal);
          },
        }}
      >
        <p>Do you want to stop this Automation?</p>
      </RedWarningModal>
      ;
    </div>
  );
};

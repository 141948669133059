import axios from "axios";
import { driveApi } from "../../../../../integrations/DriveRequest";
import { filterTables } from "../../../../../utils";
import { getBase64 } from "../../../Incidents/Modals/utils/getBase64Pdf";
import { compareIncluding } from "../../../utils";
import {
  docDefinition,
  getRepliesRecursively,
  imageForPdf,
  mapImages,
  notePdfBody,
} from "../../safetyPdf";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { b64toBlob } from "../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
import { savePdfToDrive } from "../../../Incidents/Modals/utils/helpers";
import createPDF from "../../../../../integrations/createPDF";

/**
 * Generates a PDF document for safety purposes.
 *
 * @param {string} base64 - The base64 string.
 * @param {string} category - The category of the safety form.
 * @param {Object} rowObject - The row object containing safety application data.
 * @param {string} [pdfId=""] - The ID of the PDF.
 * @param {string} accessToken - The access token for authentication.
 * @param {Object} driveFunctions - The drive functions.
 * @param {Object} [defaultObj={}] - The default object.
 * @param {Function} [setPdfId=()=>{}] - The function to set the PDF ID.
 * @param {Object} [userConfiguration={}] - The user configuration.
 * @param {string} [folderDriveKeyName=""] - The folder drive key name.
 * @param {Function} [createPdfBody=()=>{}] - The function to create the PDF body.
 * @returns {Promise<void>} - A promise that resolves when the PDF is generated.
 */
async function onGenerateSafetyPdf({
  base64,
  category,
  rowObject,
  pdfId = "",
  accessToken,
  driveFunctions,
  defaultObj = {},
  setPdfId = () => {},
  userConfiguration = {},
  folderDriveKeyName = "",
  createPdfBody = () => {},
  includeImages = true,
}) {
  Swal.fire({
    title: "<strong>Generating PDF</strong>",
    icon: "info",
    html: "Please wait to generate PDF...!",
    didOpen: () => {
      Swal.showLoading();
    },
  });

  const notes = await filterTables(
    "notes",
    "recordId",
    rowObject?.safetyApplicationId
  )
    .then((res) => res)
    ?.catch((err) => console.error("Error Fetching Notes: ", err));

  let noteArray = [];

  notes?.map((note) => {
    let noteIndex = 0;

    getRepliesRecursively(note, noteArray, noteIndex);
  });

  let images = [];
  if (includeImages) {
    images = await imageForPdf({
      rowObject,
      driveRequest: driveApi({ accessToken }),
    });
  }

  const imagePdfData = mapImages(images);

  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const notesBody = notePdfBody(noteArray, false);

  const body = createPdfBody()?.concat(imagePdfData).concat(notesBody);
  const createDocDef = docDefinition(
    `${rowObject?.safetyName} ${category} Form`,
    body,
    companyLogo
  );

  const pdfDocGenerator = pdfMake.createPdf(createDocDef);

  let fileList = [];

  try {
    createPDF({
      action: "open",
      docDefinition: docDefinition(
        `${rowObject?.safetyName} ${category} Form`,
        body,
        companyLogo
      ),
    });
    Swal.close();
  } catch (error) {
    Swal.fire({
      title: "<strong>Error</strong>",
      icon: "error",
      html: "There was an error while generating PDFs! ",
    });
  }

  return;

  const existedObject = await getBase64({
    pdfDocGenerator,
    title: "Safety",
    name: rowObject?.projectName,
  });

  fileList.push(existedObject);

  await Promise.allSettled(
    rowObject?.googleDriveUpload?.map(async (element) => {
      let pdfCondition = element.mimeType?.includes("application/pdf");
      let blob;
      let size;
      let file;

      if (pdfCondition) {
        await driveApi({ accessToken })
          .getImageSrc(element?.id)
          .then((r) => r)
          .then((res) => (blob = res?.src));

        const fileSrc = new Promise((resolve, reject) => {
          resolve(blob);
        });

        file = {
          fileSrc: fileSrc,
          name: element?.name,
          originFileObj: {
            uid: element?.id,
          },
          percent: 0,
          size: size,
          status: "uploading",
          type: "local",
          uid: element?.id,
        };

        fileList.push(file);
      }
    })
  );

  await axios
    .post(process.env.NODE_ENV === "production" ? prodUrl : devUrl, {
      originalPdf: [
        ...(await Promise.all(fileList?.map((el) => el?.fileSrc))),
      ]?.filter((el) => typeof el === "string"),
      configuration: {
        fileList: fileList,
        pagesConfig: {},
      },
    })
    .then(async ({ data: mergedPdf }) => {
      Swal.close();

      var blob = b64toBlob(mergedPdf, "application/pdf");
      const blobURL = URL.createObjectURL(blob);

      window.open(blobURL);

      await savePdfToDrive({
        blobData: mergedPdf,
        title: `Property Damage ${rowObject?.projectName}`,
        driveRequest: driveApi({ accessToken }),
        folderId:
          rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
          driveFunctions.parentFolderId,
      }).then((res) => {
        if (!!pdfId) {
          driveApi({ accessToken }).deleteDriveItem(pdfId);
        }
        setPdfId(res?.id);

        API.put("safety", `/safety/${rowObject?.safetyApplicationId}`, {
          body: {
            safetyApplicationObject: {
              ...defaultObj,
              gDrivePdfId: res?.id,
            },
          },
        }).catch((err) => console.error("Error Updating Safety: ", err));
      });
    })
    .catch(() => {
      Swal.fire({
        title: "<strong>Error</strong>",
        icon: "error",
        html: "There was an error while generating PDFs! ",
      });
    });
}

export default onGenerateSafetyPdf;

import moment from "moment";

export function formatDateToMidnight(dateStr) {
  let date = new Date(dateStr);

  date.setHours(0, 0, 0, 0);

  return date.toISOString();
}

export function getYear(dateStr) {
  const date = new Date(dateStr);
  const year = date.getFullYear();
  return year;
}

export function differenceInHours(startingTimeStr, timeAtLocationStr) {
  let startingTime = moment(startingTimeStr);
  let timeAtLocation = moment(timeAtLocationStr);

  let diffHours = moment.duration(timeAtLocation.diff(startingTime)).asHours();

  let hours = Math.floor(diffHours);
  let minutes = Math.round((diffHours - hours) * 60);

  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")}`;
}

export function formatDate(dateInput) {
  let dateStr;
  if (typeof dateInput === "number") {
    // If the input is a number, assume it's a timestamp and convert it to a date string
    dateStr = moment(dateInput).format("YYYY-MM-DDTHH:mm:ssZ");
  } else {
    dateStr = dateInput;
  }

  const format = "YYYY-MM-DDTHH:mm:ssZ";
  const momentObj = moment(dateStr, format, true); // true for strict parsing

  if (momentObj.isValid()) {
    // If dateStr is in the correct format, return it as-is
    return dateStr;
  } else {
    // If dateStr is not in the correct format, parse it without strict parsing, and format it in the correct format
    return moment(dateStr).format(format);
  }
}

import { Input } from "antd";
import { useState } from "react";
import RouteCard from "./RouteCard";

const RolePermissions = ({
  selectedTab,
  accessConfiguration,
  setAccessConfiguration,
  searchRouteConfigs = "",
  disabled = false,
}) => {
  const roleData = accessConfiguration[selectedTab];

  const routeConfigs = (roleData || []).map((route, key) => ({
    title: route.title,
    key: key.toString(),
    read: route?.read,
    write: route?.write,
    delete: route?.delete,
    checked: false,
    ...(route.children && { children: route.children }),
  }));

  const filteredRouteConfigs = routeConfigs.filter((route) =>
    findChildren(route, searchRouteConfigs)
  );
  function findChildren(route, searchRouteConfigs) {
    if (route.title.toLowerCase().includes(searchRouteConfigs.toLowerCase())) {
      return true;
    }
    if (route.children) {
      return route.children.some((child) =>
        findChildren(child, searchRouteConfigs)
      );
    }
    return false;
  }

  return (
    <div className="collapsedContainer-body">
      <div className="configBody" id="configBody">
        {filteredRouteConfigs.map((route) => (
          <RouteCard
            key={route.key}
            {...{
              route,
              selectedTab,
              accessConfiguration,
              setAccessConfiguration,
              searchRouteConfigs,
              disabled,
            }}
          />
        ))}
      </div>
    </div>
  );
};

export default RolePermissions;

const groupDocument = (document, groupedDocs, idKey) => {
  const docIndex = groupedDocs?.findIndex(
    (el) => el?.docId === document[idKey]
  );

  const doc = groupedDocs?.[docIndex];
  const groupParentIndex = groupedDocs?.findIndex(
    (el) => el?.[idKey] === document[idKey] && !!el?.docChildren
  );

  if (doc) {
    findLatestAndUpdate(doc, document, groupedDocs, docIndex);
  } else if (groupParentIndex !== -1) {
    const prevDoc = groupedDocs[groupParentIndex];
    findLatestAndUpdate(prevDoc, document, groupedDocs, groupParentIndex);
  } else {
    groupedDocs.push(document);
  }
};

function findLatestAndUpdate(doc1, doc2, allDocs, index) {
  if (doc1?.expirationDate && doc2?.expirationDate) {
    if (doc1.expirationDate > doc2.expirationDate) {
      allDocs[index] = {
        ...doc1,
        docChildren: [...(doc1?.docChildren || []), doc2].sort(sortDocsByDate),
      };
    } else {
      allDocs[index] = {
        ...doc2,
        docChildren: [...(doc2?.docChildren || []), doc1].sort(sortDocsByDate),
      };
    }
  } else if (doc1?.createdAt && doc2?.createdAt) {
    if (doc1.createdAt > doc2.createdAt) {
      allDocs[index] = {
        ...doc1,
        docChildren: [...(doc1?.docChildren || []), doc2].sort(sortDocsByDate),
      };
    } else {
      allDocs[index] = {
        ...doc2,
        docChildren: [...(doc2?.docChildren || []), doc1].sort(sortDocsByDate),
      };
    }
  }
}

function sortDocsByDate(a, b) {
  if (a?.expirationDate && b?.expirationDate) {
    return new Date(b.expirationDate) - new Date(a.expirationDate);
  } else if (a?.createdAt && b?.createdAt) {
    return new Date(b.createdAt) - new Date(a.createdAt);
  } else {
    return 0;
  }
}

function groupAndFilterDocs({ documentation = [], activeFilter = "" }) {
  let groupedDocs = (documentation || []).filter(
    (el) => !el?.renewalId && !el?.revisedId
  );
  documentation.forEach((document) => {
    if (document?.renewalId) {
      groupDocument(document, groupedDocs, "renewalId");
    }

    if (document?.revisedId) {
      groupDocument(document, groupedDocs, "revisedId");
    }
  });

  if (activeFilter) {
    groupedDocs = groupedDocs.flatMap((doc) => {
      const hasActiveChildren = doc?.docChildren?.some(
        (childDoc) => childDoc.docStatus === activeFilter
      );

      if (hasActiveChildren || doc?.docStatus === activeFilter) {
        return {
          ...doc,
          docChildren: (doc?.docChildren || []).filter(
            (childDoc) => childDoc.docStatus === activeFilter
          ),
        };
      } else {
        return [];
      }
    });
  }

  return groupedDocs;
}

export default groupAndFilterDocs;

import dayjs from "dayjs";
import { v4 } from "uuid";
import { Flex } from "antd";
import { Link } from "react-router-dom";

import {
  ActionButton,
  ServicesInspected,
} from "../AgGridDatas/page/components";
import { queryParams } from "../../../../utils/queryParams";
import { Notes } from "../../../commonComponents/Notes/Notes";
import { DynamicAvatars } from "../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { compareIncluding } from "../../utils";
import { sortDates } from "../../ToDos/AgGridData/columnDefs";
import RedirectApprovals from "../../Approvals/components/RedirectApprovals/RedirectApprovals";
import Text from "src/components/commonComponents/Base/Text";
import React from "react";

export const excelColumnsConfig = [
  "inspectionType",
  "projectName",
  "projectExecutive",
  "projectManager",
  "inspectionStatus",
  "inspectionDate",
  "inspectedBy",
  "servicesInspected",
  "createdAt",
  "createdBy",
];

export const formatCellValueHandler = (data) => {
  if (data.length < 1) {
    return "";
  } else {
    return data.map((item) => item).join(", ");
  }
};

export const columnDefs = ({
  setDispatchModalVisible,
  setForceToInspectModalVisible,
  setCurrentProject,
  view,
  inspectionView,
  changeCompany,
  navigate,
  dontShowActions = false,
  estimations,
}) => {
  const centerStyle = {
    height: "100%",
    width: "100%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  };
  return [
    {
      headerName: "Inspection Type",
      field: "inspectionType",
      resizable: true,
      cellRendererFrameworkParams: { inspectionView },
      link: (data) => `/inspectionsView/${data.inspectionId}`,
      cellRendererFramework: ({ value, data }) => {
        try {
          return (
            <a
              style={{ color: "#1065A4" }}
              onClick={async () =>
                await changeCompany({
                  data: {
                    inspectionId: data?.inspectionId,
                  },
                  openProject: false,
                })
              }
            >
              {value}
            </a>
          );
        } catch (err) {
          if (value) {
            return value;
          } else {
            return null;
          }
        }
      },
    },
    {
      headerName: "Project Name",
      field: "projectName",
      resizable: true,
      cellRendererFrameworkParams: { view },
      link: (data) => `/projects/${data.projectId}?tab=Inspections`,
      cellRendererFramework: ({ value, data }) => {
        try {
          return (
            <a
              style={{ color: "#1065A4" }}
              onClick={async () =>
                await changeCompany({
                  data: { inspectionId: data?.inspectionId },
                  openProject: {
                    pathname: `/projects/${data?.projectId}?tab=Inspections`,
                    state: { tabPosition: "Inspections" },
                    search: queryParams({ tab: "Inspections" }),
                  },
                })
              }
            >
              {value}
            </a>
          );
        } catch (err) {
          if (value) {
            return value;
          } else {
            return null;
          }
        }
      },
    },
    {
      headerName: "P. Executive",
      field: "projectExecutive",
      resizable: true,
    },
    {
      headerName: "P. Manager",
      field: "projectManager",
      enableRowGroup: false,
      resizable: true,
      pdfExportOptions: {
        modifyCellValue: (pms) => {
          if (Array.isArray(pms)) {
            return pms.map(({ nameOfUser }) => nameOfUser).join(", ");
          } else {
            return pms;
          }
        },
      },
      cellRendererFramework: ({ data }) => {
        return (
          <DynamicAvatars
            {...{
              members: Array.isArray(data?.projectManager)
                ? data?.projectManager?.map((el) => {
                    return { nameOfUser: el, identityId: v4() };
                  })
                : [],
              nameKey: "nameOfUser",
              idKey: "identityId",
            }}
          />
        );
      },
    },
    {
      headerName: "Status",
      field: "inspectionStatus",
      resizable: true,
    },
    {
      headerName: "Inspection Date",
      field: "inspectionDate",
      filter: "agDateColumnFilter",
      resizable: true,
      cellRenderer: ({ value }) => {
        return dayjs(value).format("MM/DD/YYYY") !== "Invalid date"
          ? `${dayjs(value).format("MM/DD/YYYY")}`
          : "--";
      },
      cellStyle: () => ({
        justifyContent: "center",
      }),
      getQuickFilterText: ({ value }) => value,
      comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
    },
    {
      headerName: "Inspected By",
      field: "inspectedBy",
      resizable: true,
      cellRenderer({ value }) {
        return (
          <DynamicAvatars
            {...{
              members: [value],
            }}
          />
        );
      },
    },
    {
      headerName: "Estimations",
      field: "inspectionReport",
      resizable: true,
      cellRendererFramework: ({ value, data }) => {
        const reports = data?.inspectionReport;

        let groupedEstimations = estimations
          ?.map((estimation) => {
            const services = reports
              ?.filter(
                (item) => item?.estimationId === estimation?.estimationId
              )
              ?.map(() => estimation);

            if (!!services?.length) {
              return estimation;
            }
            return;
          })
          .filter(Boolean);

        groupedEstimations = groupedEstimations?.filter(
          (item) => !!item.estimationNumber
        );

        return (
          <Flex gap="small" style={{ width: "100%" }}>
            {groupedEstimations?.map((estimation, index) => (
              <div key={index}>
                {!!estimation?.estimationId ? (
                  <Link to={`/estimations/${estimation?.estimationId}`}>
                    #{estimation?.estimationNumber}
                  </Link>
                ) : (
                  `#${estimation?.estimationNumber}`
                )}
                {index !== groupedEstimations?.length - 1 && ","}
              </div>
            ))}
          </Flex>
        );
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          const result = value.split(", ");
          return result.length;
        },
      },
    },
    {
      headerName: "Services Inspected",
      field: "servicesInspected",
      resizable: false,
      sortable: false,
      cellRendererFramework: ({ value, data }) => {
        const reports = data?.inspectionReport;

        const groupedEstimations = estimations
          ?.map((estimation) => {
            const services =
              reports
                ?.filter(
                  (item) => item?.estimationId === estimation?.estimationId
                )
                ?.map((report) => {
                  const failedCount = report?.questions?.filter(
                    ({ answer, correctAnswer, decisive }) =>
                      (decisive && answer && answer?.toLowerCase() === "no") ||
                      (answer?.toLowerCase() === "yes" &&
                        correctAnswer &&
                        !compareIncluding(answer, correctAnswer))
                  );
                  const passedCount = report?.questions?.filter(
                    ({ answer, correctAnswer, decisive }) =>
                      decisive &&
                      answer &&
                      answer?.toLowerCase() === "yes" &&
                      correctAnswer &&
                      correctAnswer?.toLowerCase() === "yes" &&
                      compareIncluding(answer, correctAnswer)
                  );
                  const allChecked = [...passedCount, ...failedCount].map(
                    (el) => el.questionId
                  );
                  const inspectionReportImages = report.questions?.flatMap(
                    ({ question, fileIds }) =>
                      fileIds.map((fileId) => ({ question, fileId }))
                  );

                  return {
                    serviceType: report?.serviceType,
                    passed: passedCount?.length,
                    failed: failedCount?.length,
                    allFilesIds: inspectionReportImages,
                    notIns: report.questions?.filter(
                      (el) => !allChecked.includes(el.questionId)
                    ).length,
                  };
                }) ?? [];

            if (!!services?.length) {
              return {
                estimationId: estimation?.estimationId,
                estimationNumber: estimation?.estimationNumber,
                services,
              };
            }

            return;
          })
          .filter(Boolean);

        return (
          <div style={centerStyle}>
            <ServicesInspected value={groupedEstimations} />
          </div>
        );
      },
    },
    {
      headerName: "Inspection Notes",
      field: "inspectionNotes",
      filter: "agMultiColumnFilter",
      enableRowGroup: false,
      cellRendererFramework: (params) => {
        return (
          <div style={centerStyle}>
            <Notes
              {...{
                noteModalName: params?.data?.projectName,
                rowId: params?.data?.inspectionId,
                topicCategory: "Inspections",
                teamsConfiguration: params?.data?.teamsConfiguration,
              }}
            />
          </div>
        );
      },
      valueGetter: ({ data }) => {
        try {
          return data?.inspectionNotes?.map(({ comment }) =>
            comment?.replace(/<\/?[^>]+(>|$)/g, "")
          );
        } catch {}
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Actions",
      field: "actions",
      cellRendererFramework: ({ data }) => {
        return (
          <div style={centerStyle}>
            <ActionButton
              {...{
                data,
                setDispatchModalVisible,
                setForceToInspectModalVisible,
                setCurrentProject,
              }}
            />
          </div>
        );
      },
      enableRowGroup: false,
      pdfExportOptions: {
        skipColumn: true,
      },
      hide: dontShowActions,
    },
    // {
    //   headerName: "Gallery",
    //   field: "gallery",
    //   sortable: false,
    //   cellRendererFramework: ({ data }) => {
    //     return (
    //       <div style={centerStyle}>
    //         <InspectionGallery {...{ data }} />
    //       </div>
    //     );
    //   },
    //   enableRowGroup: false,
    //   pdfExportOptions: {
    //     skipColumn: true,
    //   },
    // },
    {
      headerName: "From Request",
      minWidth: 100,
      valueGetter: ({ data }) => {
        return !!data?.requestId ? "Yes" : "No";
      },
      hide: true,
      cellStyle: { display: "flex", justifyContent: "center" },
      cellRendererFramework: ({ value, data }) => (
        <RedirectApprovals
          {...{
            value,
            requestId: data?.requestId,
          }}
        />
      ),
    },
    {
      headerName: "Created At",
      field: "createdAt",
      // hide: true,
      sort: "desc",
      filter: "agDateColumnFilter",
      // filterParams: {
      //   comparator: (filterLocalDateAtMidnight, cellValue) =>
      //     gridFilterDate(filterLocalDateAtMidnight, cellValue),
      // },
      valueGetter: ({ data }) => {
        try {
          return data?.createdAt
            ? dayjs(data?.createdAt).isValid()
              ? dayjs(data?.createdAt).format("MM/DD/YYYY")
              : ""
            : "";
        } catch (err) {
          return data?.createdAt;
        }
      },
      getQuickFilterText: () => "",

      pdfExportOptions: {
        modifyCellValue: (date) =>
          date
            ? new Date(date).toLocaleDateString("en-US", {
                year: "numeric",
                month: "2-digit",
                day: "2-digit",
              })
            : "",
      },
      comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY"),
    },
    {
      headerName: "Created By",
      field: "createdBy",
      hide: true,
      filter: "agTextColumnFilter",
      valueGetter: ({ data }) =>
        !!data?.createdBy?.name
          ? data?.createdBy?.name
          : data?.createdBy?.nameOfUser || data?.createdBy,
      minWidth: 150,
      cellRenderer({ value }) {
        return (
          <DynamicAvatars
            {...{
              members: [value],
            }}
          />
        );
      },
    },
  ];
};

import _ from "lodash";
import { API } from "aws-amplify";
import { Modal, message } from "antd";
import { useSelector } from "react-redux";
import { useEffect, useState, useMemo, useRef } from "react";

import {
  ControlPanel,
  Footer,
  Header,
  PaymentDetails,
  PaymentForm,
  PaymentTable,
  ProjectsTable,
  TableFooter,
} from "./components";
import { updateEditLogs, updateExistingPayments } from "./utils/actions";
import { useRedux } from "../../../../../hooks/useRedux";
import { validateThenSavePayment } from "./utils/checkers";
import { fetchData } from "../../../../../Fleet/utils/fetchData";
import LoadableComp from "../../../../../XComponents/LoadableComp";
import { filterInvoicesByKeyId, paymentInitialState } from "./utils";
import { filterTables } from "../../../../../../../utils/filterTables";
import { fetchAllData } from "../../../../../Fleet/utils/fetchAllData";
import { useUndoRedoState } from "../../../../../../../hooks/useUndoRedoState";
import {
  sendNotification,
  showErrorMsg,
  updateDocumentTitle,
} from "../../../../../../../utils";
import "./NewPayment.scss";
import { useProgressComponent } from "../../../../../../../hooks";
import { ProgressComponent } from "../../../../../../commonComponents";
import { updateBalance } from "../BalanceReport/balanceFixer";
import Swal from "sweetalert2";

const NewPayment = ({
  showCreatePaymentModal,
  setShowCreatePaymentModal,
  paymentData,
  projectData,
  fetchPayments = () => {},
  invoiceData,
  getBodyToSaveInAutomation, //this is a function used in Automations to get body for api, to save it later automatically
  nextStepHandler = () => {}, //Opens Next Step Modal
}) => {
  const [invoices, setInvoices] = useRedux("paymentInvoices", [], false);
  const [invoiceRecords, setInvoiceRecords] = useRedux(
    "invoiceRecords",
    [],
    true
  );
  const [loading, setLoading] = useState(true);
  const [selectedClientProjects, setSelectedClientProjects] = useState([]);
  const [isWritable] = useRedux("paymentIsWritable");
  const [trackOfActions] = useRedux(
    "paymentsTrackOfActions",
    {
      updateCredits: [],
    },
    true
  );
  const [fakePayment, setFakePayment] = useRedux(
    "fakePayment",
    {
      ...paymentInitialState,
      accountName: projectData?.accountName || "",
      accountId: projectData?.accountId || "",
    },
    true
  );
  const [clientInfos, setClientInfos] = useRedux(
    "clientInfos",
    {
      accounts: [],
      projects: [],
    },
    true
  );

  const { accounts = [], projects = [] } = clientInfos;

  let selectedClientData = accounts?.find(
    (el) => el?.accountId === fakePayment?.accountId
  );
  const { programFields } = useSelector((state) => state.programFields);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  /******** START UNDO/REDO functions ***********/
  //Undo Redo Limit for Payments
  const undoRedoLimit = programFields.find(
    (item) => item.fieldName === "Statuses Of Categories"
  ).fieldOptions?.["Receive Payments"];

  //Undo,Redo function
  const {
    itemToSaveActiveState: newFakePayment,
    changeStateHandler,
    index: docStateIndex,
    lastIndex: docStateLastIndex,
    undoClickHandler,
    redoClickHandler,
  } = useUndoRedoState(undoRedoLimit);

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Payments",
    actionType: !!paymentData ? "Update" : "Create",
  });

  const canUndo = docStateIndex > 0;
  const canRedo = docStateIndex < docStateLastIndex;
  const isSettingFakePayment = useRef(false);

  // useEffect(() => {
  //   if (!isSettingFakePayment.current)
  //     changeStateHandler(
  //       JSON.parse(JSON.stringify({ fakePayment, selectedClientProjects }))
  //     );
  // }, [fakePayment, selectedClientProjects]);

  useEffect(() => {
    if (newFakePayment && !loading) {
      isSettingFakePayment.current = true;
      setFakePayment(JSON.parse(JSON.stringify(newFakePayment?.fakePayment)));
      setSelectedClientProjects(
        JSON.parse(JSON.stringify(newFakePayment?.selectedClientProjects ?? []))
      );
      isSettingFakePayment.current = false;
    }
  }, [newFakePayment]);

  /******** END UNDO/REDO functions ***********/

  const updateMemos = async (memos) => {
    const promises = memos.map((memo) => {
      return API.put("charges", `/charges/${memo.chargeId}`, {
        body: {
          chargeItems: memo.chargeItems,
        },
      });
    });
    const results = await Promise.all(promises);
    return results;
  };

  const handleSave = async (type) => {
    //this is the part that updates the client balance on payment made
    let newClientBalance = parseFloat(fakePayment?.newCustomerBalance || 0);

    const payments = fakePayment.invoices
      .filter((invoice) => invoice.invoicePaidAmount > 0)
      .map((invoice) => ({
        invoiceNumber: invoice.invoiceNumber,
        invoicePayment: invoice.invoicePaidAmount,
      }));

    const filteredProjects = _.uniqBy(
      fakePayment.invoices
        .filter((project) => {
          return !!fakePayment?.invoices?.find(
            (invoice) => invoice.projectId === project.projectId
          );
        })
        .map((invoice) => ({
          projectName: invoice.projectAddress,
          projectId: invoice.projectId,
        })),
      "projectId"
    );
    // console.log("filteredProjects", {
    //   filteredProjects,
    //   selectedClientProjects,
    //   test: selectedClientProjects.filter((project) => {
    //     return !!fakePayment?.invoices?.find(
    //       (invoice) => invoice.projectId === project.projectId
    //     );
    //   }),
    // });
    // return;
    const newPayment = {
      ...fakePayment,
      paymentAmount: fakePayment.appliedAmount,
      invoices: _.uniqBy(
        fakePayment.invoices.filter((invoice) => invoice.invoicePaidAmount > 0),
        "invoiceId"
      ),
      projects: filteredProjects,
      payments: payments, // Add payments array here
    };
    if (paymentData) {
      //Removes userId and paymentId before put data to API
      delete newPayment.userId;
      delete newPayment.paymentId;
    }
    if (!!getBodyToSaveInAutomation) {
      getBodyToSaveInAutomation({
        bodyForApi: {
          receivedFromProjects: newPayment.projects,
          ...newPayment,
        },
      });
    } else {
      // setLoading(true);
      setVisibleCreationProgress({ ...(paymentData || {}), type });
      updateProgressStatus({ updatingRecord: "executing" });

      //Save payment into database
      API[paymentData ? "put" : "post"](
        "payments",
        `/payments${paymentData ? `/${fakePayment?.paymentId}` : ""}`,
        { body: { ...newPayment } }
      )
        .then(async (result) => {
          setVisibleCreationProgress({ ...(paymentData || result), type });
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          const hasPriceChanged =
            fakePayment?.appliedAmount !== paymentData?.appliedAmount;

          // await sendNotification({
          //   id: "95",
          //   action: paymentData
          //     ? hasPriceChanged
          //       ? "onPaymentEditPriceChange"
          //       : "onPaymentEdit"
          //     : "onPaymentCreation",
          //   team: selectedClientData?.teamsConfiguration || [],
          //   otherKeys: {
          //     topicName: selectedClientData?.accountName,
          //     ...(paymentData && { paymentNumber: fakePayment.paymentNumber }),
          //   },
          //   recordId: paymentData ? fakePayment.paymentId : result.paymentId,
          //   authenticatedUser,
          //   userConfiguration,
          // }).then((notificationSent) => {
          //   updateProgressStatus({
          //     sendingNotification: !!notificationSent ? "finished" : "hasError",
          //   });
          // });
          await API.put(
            "accounts",
            `/accounts/${selectedClientData?.accountId}`,
            {
              body: {
                clientBalance: paymentData
                  ? Number(selectedClientData.clientBalance) +
                    Number(newClientBalance)
                  : newClientBalance,
              },
            }
          ).catch((err) =>
            showErrorMsg({
              content: "There was a problem updating clientBalance",
              err,
            })
          );
          let updatedMemos = structuredClone(trackOfActions.memos || []);

          updatedMemos.forEach((memo) => {
            if (!!fakePayment.credits[memo?.chargeId]) {
              memo.chargeItems.forEach((item) => {
                let creditedItem =
                  fakePayment.credits[memo?.chargeId][item?.name] ?? null;
                if (!!creditedItem) {
                  item.payments = {
                    ...(item.payments || {}),
                    [`${
                      paymentData ? paymentData?.paymentId : result?.paymentId
                    }`]: creditedItem?.appliedCredit || 0,
                  };
                }
              });
            }
          });
          await updateMemos(updatedMemos);
          // await updateInvoicesAmountDue(fakePayment.invoices); //REMOVED: This is already handled in backend.
          await updateExistingPayments(paymentData ? fakePayment : result);
          await updateEditLogs(
            result,
            paymentData,
            newPayment,
            userConfiguration
          );

          setFakePayment({
            ...paymentInitialState,
            accountName: projectData?.accountName || "",
            accountId: projectData?.accountId || "",
          });

          if (projectData && selectedClientData && projects) getInvoices();
          else setInvoices([]);
          // setLoading(false);
        })
        .then(() => {
          if (!!!type) {
            updateDocumentTitle(); // reset document title to "Lead Manager";
            fetchPayments();
          } else {
            setInvoiceRecords([]);
            setInvoices([]);
          }
          // setVisibleCreationProgress(false);
        })
        .catch((err) => {
          console.log("error while saving payment", err);
          updateProgressStatus({ updatingRecord: "hasError" });
          Swal.fire({
            title: "Possible DUPLICATE PAYMENT",
            text: "Please check, there might be a duplicate payment or an error occurred while saving the payment.",
            icon: "error",
            confirmButtonText: "Ok",
          });
        })
        .finally(async () => {
          for (const project of filteredProjects) {
            await updateBalance({
              projectId: project.projectId,
              showMessage: false,
              accountId: selectedClientData?.accountId,
            });
          }
        });
      // .finally(async () => {
      //   //TODO: Send Notification TEMPORARILY REMOVED - FAULTY
      //   await sendNotification({
      //     id: "95",
      //     action: paymentData
      //       ? hasPriceChanged
      //         ? "onPaymentEditPriceChange"
      //         : "onPaymentEdit"
      //       : "onPaymentCreation",
      //     team: selectedClientData?.teamsConfiguration || [],
      //     otherKeys: {
      //       topicName: selectedClientData?.accountName,
      //       ...(paymentData && { paymentNumber: fakePayment.paymentNumber }),
      //     },
      //     recordId: paymentData ? fakePayment.paymentId : result.paymentId,
      //     authenticatedUser,
      //     userConfiguration,
      //   }).then((notificationSent) => {
      //     updateProgressStatus({
      //       sendingNotification: !!notificationSent ? "finished" : "hasError",
      //     });
      //   });
      // });
    }
  };

  const handleClear = () => {
    setFakePayment({
      ...paymentInitialState,
      accountName: projectData?.accountName || "",
      accountId: projectData?.accountId || "",
    });
    if (!paymentData && !projectData) setSelectedClientProjects([]);
  };
  const onProjectSelection = (project, checked) => {
    checked
      ? setFakePayment(
          validateThenSavePayment({
            ...fakePayment,
            clientBalance: paymentData
              ? fakePayment.clientBalance
              : selectedClientData?.clientBalance || 0,
            invoices: _.uniqBy(
              filterInvoicesByKeyId(
                fakePayment,
                invoices,
                projects,
                project.projectId,
                "projectId"
              ),
              "invoiceId"
            ),
            projects: _.uniqBy(
              [
                ...fakePayment?.projects,
                {
                  projectName: project.projectName,
                  projectId: project.projectId,
                },
              ],
              "projectId"
            ),
          })
        )
      : setFakePayment(
          validateThenSavePayment({
            ...fakePayment,
            invoices: fakePayment?.invoices.filter(
              (invoice) => invoice.projectId !== project.projectId
            ),
          })
        );
  };

  const onToggleAllProjects = (checked) => {
    const updatedProjects = checked
      ? selectedClientProjects.map((project) => ({
          projectName: project.projectName,
          projectId: project.projectId,
        }))
      : [];

    const updatedInvoices = checked
      ? _.uniqBy(
          selectedClientProjects.flatMap((project) =>
            filterInvoicesByKeyId(
              fakePayment,
              invoices,
              selectedClientProjects,
              project.projectId,
              "projectId"
            )
          ),
          "invoiceId"
        )
      : [];

    setFakePayment(
      validateThenSavePayment({
        ...fakePayment,
        clientBalance: paymentData
          ? fakePayment.clientBalance
          : selectedClientData?.clientBalance || 0,
        projects: updatedProjects,
        invoices: updatedInvoices,
      })
    );
  };

  const retrieveNeededData = async () => {
    try {
      const accountId = invoiceData?.accountId || projectData?.accountId;

      const accountsPromise = accountId
        ? filterTables("accounts", "accountId", accountId)
        : fetchData("accounts");

      const projectsPromise = accountId
        ? filterTables("projects", "accountId", accountId)
        : fetchAllData("projects", "projects", "projectId");

      const [accounts, projects] = await Promise.all([
        accountsPromise,
        projectsPromise,
      ]);

      setClientInfos({
        accounts: accounts || [],
        projects: projects.filter(
          ({ projectStatus = "" }) => projectStatus !== "Canceled"
        ),
      });
    } catch (error) {
      console.log("error while retrieving data", error);
    } finally {
      setLoading(false);
    }
  };

  const getInvoices = async () => {
    filterTables("invoices", "accountId", selectedClientData?.accountId).then(
      (result) => {
        if (result.length > 0) {
          setInvoices(
            result.filter(
              (invoice) =>
                projects.some(
                  (project) => project.projectId === invoice.projectId //Removes invoices from Canceled Project
                ) && invoice.amountDue > 0
            )
          );
          setInvoiceRecords(
            result.filter((invoice) =>
              projects.some(
                (project) => project.projectId === invoice.projectId //Removes invoices from Canceled Project
              )
            )
          );
        }
      }
    );
  };

  useEffect(() => {
    if (projectData && !!!invoiceData) onProjectSelection(projectData, true);
  }, [projectData]);

  useEffect(() => {
    if (paymentData) {
      setFakePayment(
        validateThenSavePayment({
          ...paymentData[0],
          clientBalance: paymentData[0].clientBalance || 0,
          // appliedAmount: paymentData[0].paymentAmount,
          invoices: _.uniqBy(paymentData[0].invoices || [], "invoiceId"),
          projects: paymentData[0].receivedFromProjects,
        })
      );
    }
  }, [paymentData]);

  useEffect(() => {
    if (selectedClientData && projects) getInvoices();
  }, [selectedClientData, projects]);

  useMemo(() => {
    if (!paymentData)
      setFakePayment(
        validateThenSavePayment({
          ...fakePayment,
          clientBalance: selectedClientData?.clientBalance || 0,
          invoices: _.uniqBy(
            invoiceData
              ? filterInvoicesByKeyId(
                  fakePayment,
                  invoices,
                  projects,
                  invoiceData?.invoiceId,
                  "invoiceId"
                )
              : filterInvoicesByKeyId(
                  fakePayment,
                  invoices,
                  projects,
                  selectedClientData?.accountId,
                  "accountId"
                ),
            "invoiceId"
          ),
          projects: _.uniqBy(
            [
              ...fakePayment?.projects,
              ...invoices.filter((invoice) => ({
                projectName: invoice.projectName,
                projectId: invoice.projectId,
              })),
            ],
            "projectId"
          ),
        })
      );
  }, [invoices, paymentData]);

  useEffect(() => {
    if (showCreatePaymentModal) {
      setLoading(true);
      retrieveNeededData();
    }
  }, [showCreatePaymentModal]);

  return (
    <Modal
      title={
        <Header
          {...{
            closePaymentModal: () => {
              updateDocumentTitle(); // reset document title to "Lead Manager";
              setShowCreatePaymentModal(false);
            },
            fakePayment,
            paymentData,
            projectData,
            handleSave,
            fetchPayments,
            isDarkMode,
          }}
        />
      }
      afterOpenChange={(event) => {
        event &&
          updateDocumentTitle({
            newTitle: paymentData
              ? `Edit Payment # ${paymentData[0]?.paymentNumber}`
              : "New Payment",
          });
      }}
      className={`create-payment-container ${
        isDarkMode && "create-payment-container-dark"
      }`}
      wrapClassName="wrapper-create-payment-container"
      open={showCreatePaymentModal}
      closable={false}
      footer={
        <Footer {...{ handleSave, handleClear, paymentData, isDarkMode }} />
      }
    >
      <LoadableComp loading={loading}>
        <div
          className="payment-modal-content"
          onClick={() =>
            !isWritable &&
            paymentData &&
            message.error("Please enable Write Mode")
          }
        >
          <ControlPanel
            {...{
              canUndo,
              canRedo,
              fakePayment,
              paymentData,
              undoClickHandler,
              redoClickHandler,
              selectedClientProjects,
            }}
          />
          <div className="payment-up-container">
            <PaymentForm
              {...{
                setSelectedClientProjects,
                projectData,
                paymentData,
                clientBalance: selectedClientData?.clientBalance,
              }}
            />
            {selectedClientProjects.length > 0 && (
              <ProjectsTable
                {...{
                  selectedClientProjects,
                  onToggleAllProjects,
                  onProjectSelection,
                  paymentData,
                  fakePayment,
                  invoices,
                }}
              />
            )}

            {visibleCreationProgress && creationProgresses && (
              <ProgressComponent
                {...{
                  categoryName: "Payments",
                  actionType: !!paymentData ? "Update" : "Create",
                  visibleCreationProgress,
                  creationProgresses,
                  closeModal: () => {
                    const { type } = visibleCreationProgress;
                    setVisibleCreationProgress(false);
                    if (!!!type) {
                      setShowCreatePaymentModal(false);
                      nextStepHandler();
                    }
                  },
                }}
              />
            )}
          </div>
          <PaymentTable {...{ paymentData, invoices, isDarkMode }} />
          <TableFooter {...{ rowData: fakePayment?.invoices }} />
          <PaymentDetails {...{ paymentData }} />
        </div>
      </LoadableComp>
    </Modal>
  );
};

export default NewPayment;

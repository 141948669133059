import { fetchData } from "./fetchData";

export const fetchAllData = async (
  endpoint,
  query,
  idObject,
  customSetter = () => {},
  otherStringParams = {}
) => {
  const items = [];

  let currentPageKey = undefined;

  const newQuery = query || endpoint;

  while (true) {
    const result = await fetchData(endpoint, endpoint, {
      queryStringParameters: {
        ExclusiveStartKey: currentPageKey,
        withPagination: "true",
        getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
        ...otherStringParams,
      },
    });

    if (result[newQuery]) {
      items.push(...result[newQuery]);
      customSetter(result[newQuery]);
    } else {
      items.push(...result);
      customSetter(result);
    }

    if (!result.LastEvaluatedKey) {
      break;
    }

    if (!idObject) {
      throw new Error("idObject is required");
    }

    currentPageKey = result.LastEvaluatedKey[idObject];
  }
  return items;
};

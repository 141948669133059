import axios from "axios";
import * as FileSaver from "file-saver";
export const pdfExportFunc = async (
  htmlClassName = "",
  anyThingToReplace = "",
  fileName = "",
  endPoint = ""
) => {
  let whatWePrinting = document
    .getElementById(htmlClassName)
    .innerHTML.replaceAll(anyThingToReplace, "");
  const doctype =
    `<!DOCTYPE html>
  <html>
  <head>
  <title>Title of the document</title>
  </head><body>` +
    whatWePrinting +
    `</body>
  </html>`;
  const generatedPdf = await axios.post(
    // `http://10.0.0.85:8080/api/${endPoint}`,
    `https://leadmanager-express-backend.ue.r.appspot.com/api/${endPoint}`,
    {
      html: doctype,
    }
  );

  FileSaver.saveAs(
    `data:application/pdf;base64,${generatedPdf.data}`,
    `${fileName}.pdf`
  );
};

export const SESSION_KEY = "chatSession";
export const INACTIVITY_LIMIT_MS = 12 * 60 * 60 * 1000;

export const VIRTUAL_ASSISTANT_ERROR_FIELD_NAME = "Virtual Assistant Errors";
export const VIRTUAL_ASSISTANT_ERROR_FIELD_ID = "34dfd-564gh--76fghfnvbsh";

const VIRTUAL_ASSISTANT_API_URL_PROD =
  "https://leadmanager-sockets.ue.r.appspot.com/api";

const VIRTUAL_ASSISTANT_API_URL_DEV = "http://localhost:8080/api";

const VIRTUAL_ASSISTANT_API_MAP = {
  dev: VIRTUAL_ASSISTANT_API_URL_DEV,
  prod: VIRTUAL_ASSISTANT_API_URL_PROD,
};

export const VIRTUAL_ASSISTANT_API_URL = VIRTUAL_ASSISTANT_API_MAP.prod;

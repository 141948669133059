import { useState } from "react";
import { useSelector } from "react-redux";
import { message, Modal, Radio } from "antd";
import { XIcon } from "../../SidebarPages/Communication/assets";
import {
  BarChart,
  DoughnutChart,
  PieChart,
} from "../../SidebarPages/BasePage/src";
import { InputComponent } from "../../SidebarPages/Fleet/components";
import { generateDatePeriods } from "../../../utils";
import { InfoText } from "../../commonComponents/CustomModalHeader/CustomModalHeader";
import { MondayButton } from "../../commonComponents";
import { isDateBetween } from "../../Header/components/ModalVideoTutorials/utils/isDateBetween";
import dayjs from "dayjs";

const chartTypes = [
  {
    title: "Bar Chart",
    def: "bar",
    icon: <BarChart height={13} width={13} />,
  },
  {
    title: "Pie Chart",
    def: "pie",
    icon: <PieChart height={13} width={13} />,
  },
  {
    title: "Doughnut Chart",
    def: "doughnut",
    icon: <DoughnutChart height={13} width={13} />,
  },
];

const ChartModal = ({
  open,
  onCancel,
  data,
  onDateChange,
  chartingOptions = [],
  startDate,
  endDate,
  onSubmit,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [selectedChartType, setSelectedChartType] = useState("bar");
  const [selectedChartParam, setSelectedChartParam] = useState(
    chartingOptions[0]?.optionTitle || ""
  );

  const dateOptions = generateDatePeriods();

  const getRadioGroups = () => {
    return chartingOptions.map((option, i) => (
      <Radio key={i} value={option.optionTitle}>
        {option.optionTitle}
      </Radio>
    ));
  };

  const changeChartOption = (value) => {
    setSelectedChartParam(value);
  };

  const changeDate = (value, options) => {
    const newStartDate = options.startDate.format("YYYY-MM-DD");
    const newEndDate = options.endDate.format("YYYY-MM-DD");
    onDateChange(newStartDate, newEndDate);
  };

  const handleOk = () => {
    if (chartingOptions.length < 1) {
      return onCancel();
    }

    const nestedKeys = [];
    const finalData = {};

    const selectedOption = chartingOptions.find(
      ({ optionTitle }) => optionTitle === selectedChartParam
    );

    const filteredData = data.filter((item) =>
      isDateBetween(item.createdAt, startDate, endDate)
    );

    if (selectedOption.dataKey.includes(".")) {
      const splitKeys = selectedOption.dataKey.split(".");
      nestedKeys.push(...splitKeys);
    } else {
      nestedKeys.push(selectedOption.dataKey);
    }

    const getValueByNestedKeys = (obj, keys) => {
      return keys.reduce(
        (o, key) =>
          o && o[key] !== undefined && o[key] !== "" ? o[key] : "No Data",
        obj
      );
    };

    filteredData.forEach((item) => {
      const keyToCheck = getValueByNestedKeys(item, nestedKeys);

      const updatedKey =
        selectedOption?.timestamp === true
          ? dayjs(keyToCheck).format("DD/MM/YYYY")
          : keyToCheck;

      if (finalData.hasOwnProperty(updatedKey)) {
        if (selectedOption.hasOwnProperty("calcKey")) {
          finalData[updatedKey] += Number(item[selectedOption.calcKey]);
        } else {
          finalData[updatedKey] += 1;
        }
      } else if (
        selectedOption.hasOwnProperty("customKey") &&
        selectedOption.customKey === "YesNo"
      ) {
        // Custom Key
        const customKey =
          keyToCheck === "No Data" || keyToCheck === "" ? "No" : "Yes";
        finalData[customKey] = finalData.hasOwnProperty(customKey)
          ? finalData[customKey] + 1
          : 1;
      } else {
        // Calculate Amount
        finalData[updatedKey] = selectedOption.hasOwnProperty("calcKey")
          ? Number(item[selectedOption.calcKey])
          : 1;
      }
    });

    if (Object.keys(finalData).length === 0)
      return message.error("No Data to display!");

    if (selectedOption) {
      const result = {
        ...selectedOption.params,
        data: {
          labels: Object.keys(finalData),
          datasets: [
            {
              ...selectedOption.params.data.datasets[0],
              data: Object.values(finalData),
            },
          ],
        },
        chartType: selectedChartType,
      };

      onSubmit(result);
    }
  };

  return (
    <Modal
      title="Chart Options"
      open={open}
      onCancel={onCancel}
      onOk={handleOk}
      closeIcon={<XIcon />}
      className={`chartModal ${isDarkMode ? "chartModalDark" : ""}`}
    >
      {chartingOptions.length > 0 ? (
        <>
          <InfoText
            {...{
              isDarkMode,
              text: "Select a chart appearance and one of the options below them. This includes choosing the chart type and the time frame for data such as total number of users by department, role, and device type used for logging in.",
            }}
          />
          <div className="chartType">
            {chartTypes.map(({ title, def, icon }) => (
              <MondayButton
                key={title}
                className={
                  selectedChartType === def ? "activeButton" : "unactiveButton"
                }
                onClick={() => setSelectedChartType(def)}
                Icon={icon}
              >
                {title}
              </MondayButton>
            ))}
          </div>
          <Radio.Group
            className="chartOption"
            onChange={(e) => changeChartOption(e.target.value)}
            defaultValue={chartingOptions[0]?.optionTitle}
          >
            {getRadioGroups()}
          </Radio.Group>
          <InputComponent
            style={{ textAlign: "center" }}
            allowClear={false}
            type="select"
            showSearch={true}
            dropdownClassName={isDarkMode && "darkDropDown"}
            placeholder={`Select...`}
            defaultValue={"All Time"}
            customOptions={dateOptions}
            onChange={(value, options) => changeDate(value, options)}
          />
        </>
      ) : (
        <span style={{ color: isDarkMode ? "#fff" : "#000" }}>
          No charting options available.
        </span>
      )}
    </Modal>
  );
};

export default ChartModal;

import { message } from "antd";
import { API } from "aws-amplify";
import axios from "axios";
import * as FileSaver from "file-saver";
import { b64toBlob } from "../../../../../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
export async function exportPaymentsExcel({
  projectName = "",
  payments = {},
  paymentDetails,
}) {
  console.log("paymentDetails", paymentDetails);
  let fileName = "Payments Received" + ".xlsx";
  const url =
    "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // const url = "http://10.0.0.85:8080/api/export/xlsx";

  const hideLoad = message.loading("Exporting to Excel...", 0);
  await API.post("exportPayment", "/exportPayment", {
    body: {
      projectName,
      paymentData: payments,
      paymentDetails,
    },
  }).then((response) => {
    console.log("response", response);
    let blob = b64toBlob(
      response.body,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
    );
    FileSaver.saveAs(blob, fileName);
    hideLoad();
    message.success("Export successful.");
  });
  return;
  axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      projectName,
      apiKey: "499c87f0-ca33-11ed-afa1-0242ac120002",
      payments,
      paymentDetails,
      // ...result,
    }),
  })
    .then((response) => {
      hideLoading();
      console.log("response", response);
      FileSaver.saveAs(response.data, fileName);
      message.success("Export successful.");
    })
    .catch((err) => {
      console.log("my error", err);
      hideLoading();
      console.error(err);
      message.error("Something went wrong...");
    });
}

import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Modal } from "antd";

import { WorkOrderType, StoreType } from "../../types";
import { formatNumber } from "src/components/SidebarPages/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import SmallInfoCard from "../SmallInfoCard/SmallInfoCard";
import { MondayButton } from "src/components/commonComponents";
import WarningInfoCard from "../WarningInfoCard/WarningInfoCard";
import SmallVehicleInformation from "../SmallVehicleInformation/SmallVehicleInformation";
import { GoTasks } from "src/icons";
import { XIcon } from "src/components/SidebarPages/Communication/assets";

import "./WorkOrderInfoViewModal.scss";

type InfoProps = {
  open: boolean;
  onCancel: () => any;
  workOrder: WorkOrderType;
};

function WorkOrderInfoViewModal({ open, onCancel, workOrder }: InfoProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const navigate = useNavigate();

  let isOverdue = false;
  if (
    Date.now() > dayjsNY(workOrder.dueDate).endOf("day").valueOf() &&
    workOrder.workOrderStatus !== "Completed"
  ) {
    isOverdue = true;
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        "data-testid": "work-order-info-modal",
        footer: (
          <>
            {/** @ts-ignore */}
            <MondayButton
              {...{
                Icon: <GoTasks />,
                className: "mondayButtonBlue",
                onClick() {
                  navigate(`/workOrders/${workOrder.workOrderId}`);
                },
              }}
            >
              Open Work Order
            </MondayButton>
          </>
        ),
        closeIcon: <XIcon />,
        title: `Work Order - ${workOrder.workOrderId}`,
        className: `maintenance-view-info-modal work-order-info ${
          isDarkMode ? "maintenance-info-dark" : ""
        }`,
      }}
    >
      {isOverdue ? (
        <WarningInfoCard
          title="Warning"
          description={`This work order is overdue. Due date was ${dayjsNY(
            workOrder.dueDate
          ).format("MMM DD, YYYY")}`}
        />
      ) : null}
      <SmallVehicleInformation fleetId={workOrder.fleetId} />
      <SmallInfoCard
        {...{
          isDarkMode,
          title: "Work Order Information",
          data: [
            {
              title: "Mechanic",
              value: workOrder.mechanicInfo.mechanic,
            },
            {
              title: "Issue Date",
              value: dayjsNY(workOrder?.issueDate || workOrder.dueDate).format(
                "MMM DD, YYYY"
              ),
            },
            {
              title: "Due Date",
              value: dayjsNY(workOrder.dueDate).format("MMM DD, YYYY"),
            },
            {
              title: "Completion Date",
              value: workOrder.completionDate
                ? dayjsNY(workOrder.completionDate).format("MMM DD, YYYY")
                : "N/A",
            },
            {
              title: "Status",
              value: workOrder.workOrderStatus,
            },
            {
              title: "Total Labor Cost",
              value: formatNumber(
                workOrder.laborInformation.reduce(
                  (acc, val) => acc + Number(val.total),
                  0
                ),
                { currency: "USD" }
              ),
            },
            {
              title: "Defects",
              value: workOrder.workOrderItems
                .map((e) => e.defectName)
                .join(", "),
            },
          ],
        }}
      />
    </Modal>
  );
}

export default WorkOrderInfoViewModal;

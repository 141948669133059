import PropTypes from "prop-types";
import React, { useState, useEffect, useContext, useCallback } from "react";

import PayrollContext from "../../PayrollContext";
import PayrollLayout from "../../Layout/PayrollLayout";
import { MondayButton } from "../../../../commonComponents";
import { LiveIcon } from "../../../../SidebarPages/DynamicView/src";
import { LoadableComp } from "../../../../SidebarPages/XComponents";
import { DownloadIcon } from "../../../../SidebarPages/BasePage/src";
import { chartingOptions, columnDefs, gridFilters } from "./AgGridData";
import GetPayrollReportModal from "./components/modalComponents/GetPayrollReportModal/GetPayrollReportModal";

const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

function DegTab({ accessRight }) {
  const { degPayrolls: rowData } = useContext(PayrollContext);

  const [editData, setEditData] = useState();
  const [loading, setLoading] = useState(true);
  const [modalVisible, setModalVisible] = useState(false);
  const [degStepFilters, setDegStepFilters] = useState({});
  const [payrollReport, setPayrollReport] = useState(false);

  const postSortRows = useCallback(
    (props) => {
      let rowNodes = [];
      let completedNodes = [];
      let uncompletedNodes = [];

      // eslint-disable-next-line react/prop-types
      for (let i = 0; i < props.nodes.length; i++) {
        // eslint-disable-next-line react/prop-types
        const node = props.nodes[i];
        // eslint-disable-next-line react/prop-types
        if (node?.data?.degStatus === "Completed") {
          completedNodes.push(node);
        } else {
          uncompletedNodes.push(node);
        }
      }

      completedNodes = completedNodes.sort((rowA, rowB) => {
        return rowB?.data?.createdAt - rowA?.data?.createdAt;
      });

      uncompletedNodes = uncompletedNodes.sort((rowA, rowB) => {
        return rowB?.data?.createdAt - rowA?.data?.createdAt;
      });

      rowNodes = uncompletedNodes.concat(completedNodes);

      return rowNodes;
    },
    [rowData]
  );

  function onEditClick(data, otNames = []) {
    setEditData(data);
    setModalVisible(true);
    if (otNames?.length) {
      setDegStepFilters({ crewName: otNames });
    }
  }

  function navigateToLive() {
    window.open(`${window.location.pathname}/live`, "_blank");
  }

  useEffect(() => {
    if (rowData) {
      setLoading(false);
    }
  }, [rowData]);

  return (
    <>
      <LoadableComp loading={loading} componentTestId={"deg-tab-loading"}>
        <></>
      </LoadableComp>
      <PayrollLayout
        {...{
          title: "DEG",
          rowData,
          chartingOptions,
          columnDefs: columnDefs({
            writeAccess: accessRight?.write,
            onEditClick,
            editData,
          }),
          // getGridApi(param) {
          //   setGridApi(param);
          // },
          hasNew: accessRight?.write,
          gridFilters,
          suppressDoubleClickEdit: true,
          dataIdKey: "degId",
          rowToEdit: editData,
          setRowToEdit: setEditData,
          additionalActionsButtons: [
            <MondayButton
              key={"payroll-live-btn"}
              className="liveButton"
              Icon={<LiveIcon />}
              onClick={navigateToLive}
            >
              Live
            </MondayButton>,
            <MondayButton
              Icon={<DownloadIcon />}
              className="mondayButtonBlue"
              key={"get-payroll-report-btn"}
              onClick={() => setPayrollReport(true)}
            >
              Get Payroll Report
            </MondayButton>,
          ],
          postSortRows,
          modalVisible,
          degStepFilters,
          setModalVisible,
          setDegStepFilters,
          paginationPageSize,
        }}
      />
      {payrollReport ? (
        <GetPayrollReportModal
          open={payrollReport}
          onCancel={() => setPayrollReport(false)}
        />
      ) : null}
    </>
  );
}

DegTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
};

export default DegTab;

import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const modifyAndSortApplications = async () => {
  try {
    const applications = await fetchAllData(
      "applications",
      "applications",
      "applicationId"
    );

    const applicationsWithRetainage = applications.map((application) => {
      return {
        ...application,
        currentPaymentDue: application?.totalities?.currentPaymentDue || 0,
        currentRetainage: application?.totalities?.currentRetainage || 0,
        totalAmount: application?.totalities?.totalAmount || 0,
        totalRetainage: application?.totalities?.totalRetainage || 0,
        periodStart: application?.periodTo?.start,
      };
    });

    // Filter out falsy values, sort by application number desc and return the resulting array
    const newApplications = applicationsWithRetainage
      .filter(Boolean)
      .sort((a, b) => b?.applicationNo - a?.applicationNo);

    return newApplications;
  } catch (error) {
    console.log(
      "data source function modifyAndSortApplications error: ",
      error
    );
  }
};

import { Button } from "antd";
import { filterTables } from "../../../../../../../../../utils";

export const getCategory = (
  categoryToCheck,
  categoryToReturn,
  recordDetails
) => {
  if (recordDetails?.categoryType === categoryToCheck) {
    return categoryToReturn;
  } else {
    return recordDetails?.categoryType;
  }
};

export const addToCursor = (newText, clickedElement, form) => {
  const start = clickedElement.selectionStart;
  const end = clickedElement.selectionEnd;
  const text = clickedElement.value || "";
  const before = text?.substring(0, start);
  const after = text?.substring(end, text.length);
  clickedElement.value = before + newText + after;

  clickedElement.selectionStart = clickedElement.selectionEnd =
    start + newText.length;
  clickedElement.focus();
  form.setFieldsValue({ subject: clickedElement.value });
};

export const getSingleFields = (
  dynamicFields,
  category,
  clickedElement,
  rowData,
  setEditorContent,
  form
) => {
  // Filter dynamic fields based on category and render type
  const filteredDynamicFields = dynamicFields?.filter(
    ({ dynamicFieldCategory, dynamicFieldRenderFor }) =>
      dynamicFieldCategory.toLowerCase() === category.toLowerCase() &&
      dynamicFieldRenderFor === "single" // Only include fields meant for single rendering
  );

  // Further filter the fields to ensure they have a valid value in rowData
  const mappedDynamicFieldsFiltered = filteredDynamicFields?.filter(
    ({ dynamicFieldValue }) => {
      const value = rowData[dynamicFieldValue];
      // Ensure value is not empty, not an object, and is defined
      return value !== "" && typeof value !== "object" && value !== undefined;
    }
  );

  // Map the filtered fields to create button elements for the dropdown
  const mappedDynamicFields = mappedDynamicFieldsFiltered?.map(
    ({ dynamicFieldLabel, dynamicFieldValue }) => ({
      key: dynamicFieldLabel,
      label: (
        <Button
          className="dropdown-item-button"
          type="link"
          key={dynamicFieldValue}
          onClick={() => {
            // If an element is clicked, add its value to the cursor position
            clickedElement
              ? addToCursor(
                  rowData[dynamicFieldValue] || "", // Get the value or empty string
                  clickedElement, // The clicked element
                  form // The form object
                )
              : setEditorContent((prevData) => {
                  // Otherwise, append the value to the editor content
                  const newContent =
                    prevData +
                    (prevData ? "<br />" : "") + // Add a line break if prevData exists
                    (rowData[dynamicFieldValue] || ""); // Add the field value or empty string
                  return newContent; // Return the updated content
                });
          }}
        >
          {dynamicFieldLabel} // Display the dynamic field label as button text
        </Button>
      ),
    })
  );

  return mappedDynamicFields; // Return the mapped dynamic fields for rendering
};

export const getTableFields = (
  programFields,
  category,
  setSelectedTable,
  allDynamicFields,
  setEditorContent
) => {
  // Find dynamic field options corresponding to the specified category
  const foundProgamFields = programFields?.find(
    ({ fieldName }) => fieldName === "Dynamic Fields Subcategories" // Look for the specific field name
  )?.fieldOptions[category?.toLowerCase()]?.options; // Access the category options

  // If no program fields are found, return an empty array
  if (!foundProgamFields) {
    return [];
  }

  // Map the found program fields to create button elements for the dropdown
  const mappedTableFields = foundProgamFields?.map((tableField) => ({
    key: tableField, // Use the table field as the key
    label: (
      <Button
        className="dropdown-item-button"
        type="link"
        onClick={() => {
          // Set the selected table with options filtered from all dynamic fields
          setSelectedTable({
            options: allDynamicFields.filter(
              ({ dynamicFieldCategory }) => dynamicFieldCategory === tableField // Filter by dynamic field category
            ),
            label: tableField, // Set the label to the current table field
            editor: setEditorContent, // Pass the editor content setter function
          });
        }}
      >
        {tableField} // Display the table field as button text
      </Button>
    ),
  }));

  return mappedTableFields; // Return the mapped table fields for rendering
};

/**
 * Processes a template containing HTML tables and populates them with data based on provided record details.
 *
 * @param {string} template - The HTML template containing table structure.
 * @param {Array} programFields - Array of program fields to find dynamic field options.
 * @param {Object} recordDetails - Details of the current record, used to filter data.
 * @param {Object} rowData - Data associated with the row, used to populate table cells.
 * @param {function} setEditorContent - Function to update the content in the editor.
 */
export const showAddedTable = async (
  template,
  programFields,
  recordDetails,
  rowData,
  setEditorContent
) => {
  // Regular expression to match table cell contents
  var regex = /<td[^>]*>(.*?)<\/td>/g;

  // Extract matched items from the template using the regex
  const matchedItems = Array.from(template?.matchAll(regex), (x) => x);

  // Create a new div element to manipulate the HTML template
  let newDiv = document.createElement("div");
  newDiv.innerHTML = template; // Set the inner HTML to the template
  let tableDiv = newDiv?.getElementsByTagName("table"); // Get all table elements
  let tables = []; // Array to store updated table HTML strings

  // Iterate over each table found in the template
  for await (let table of tableDiv) {
    // Extract keys from the table cells
    let tableKeys = Array.from(table.innerHTML?.matchAll(regex), (x) => x[1]);

    // Retrieve the relation key based on the category type from recordDetails
    const relationKey = programFields?.find(
      ({ fieldName }) => fieldName === "Dynamic Fields Subcategories"
    )?.fieldOptions[recordDetails?.categoryType?.toLowerCase()].relationKey;

    // Fetch filtered data for the current table based on the relation key
    await filterTables(
      table.id.toLowerCase(), // Convert table ID to lowercase for consistency
      relationKey,
      rowData[relationKey] // Pass the relevant data for filtering
    ).then((record) => {
      // Create a new row for the table
      var newRow = document.createElement("tr");
      newRow.innerHTML = `${tableKeys
        .map((e) => {
          const cleanedKey = e.replace("!", ""); // Clean the key for processing

          // Check if the fetched record contains data
          if (Array.isArray(record) && record.length > 0) {
            const value = record[0][cleanedKey]; // Get the value for the cleaned key

            if (Array.isArray(value)) {
              // If the value is an array, map its items to table cells
              return value
                .map((item) => {
                  if (typeof item === "object") {
                    // If the item is an object, format its entries
                    return `<td style="border: 1px solid #000">${
                      Object.entries(item)
                        .map(([key, val]) => `${key}: ${val}`)
                        .join(", ") || "N/A"
                    }</td>`;
                  } else {
                    // Otherwise, return the item value or "N/A"
                    return `<td style="border: 1px solid #000">${
                      item || "N/A"
                    }</td>`;
                  }
                })
                .join(""); // Join the cells into a single string
            } else if (typeof value === "object") {
              // If the value is an object, format its entries
              return `<td style="border: 1px solid #000">${
                Object.entries(value)
                  .map(([key, val]) => `${key}: ${val}`)
                  .join(", ") || "N/A"
              }</td>`;
            } else {
              // Otherwise, return the value or "N/A"
              return `<td style="border: 1px solid #000">${
                value || "N/A"
              }</td>`;
            }
          } else {
            // If no record data is found, return "N/A"
            return `<td style="border: 1px solid #000">N/A</td>`;
          }
        })
        .join("")}`; // Join all table cell contents into a single row

      // Append the new row to the table body
      table.getElementsByTagName("tbody")[0].appendChild(newRow);

      // Remove the initial row (presumably a placeholder or header)
      table.getElementsByTagName("tr")[1].remove();

      // Replace matched items in the table with data from rowData
      matchedItems.forEach(
        (e) =>
          (table.innerHTML = table.innerHTML.replace(
            e[0],
            rowData[e[1]] ? rowData[e[1]] : e[0] // Replace with corresponding value or keep original
          ))
      );

      // Push the updated table HTML to the tables array
      tables.push(`<table>${table.innerHTML}</table>`);
    });
  }

  // Update the editor content with the newly created tables
  setEditorContent((prevData) => {
    if (prevData === "<p><br></p>") {
      // If the previous content is empty, replace it with the new tables
      return tables.join("");
    }

    // Otherwise, append the new tables to the existing content
    return prevData + tables.join("");
  });
};

import axios from "axios";
import { socketsApi } from "../../../../../../../AppData";

export const getActiveAutomations = async () => {
  try {
    const res = await axios.get(`${socketsApi}/api/automation/activeCronJobs`);
    return res.data;
  } catch (err) {
    console.log({ err });
    throw err;
  }
};

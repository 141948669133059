import { Button, Divider, Input, Select, Space } from "antd";
import React, { useState, forwardRef, useImperativeHandle } from "react";
import { PlusOutlined } from "@ant-design/icons";

import { useRef } from "react";
import { MondayButton } from "../../../../../../../commonComponents";
import { TABLE_DATA_HOIST_CALCULATOR } from "../tableData";
let index = 0;

export const CustomRichSelectEditor = forwardRef((props, ref) => {
  const [items, setItems] = useState(TABLE_DATA_HOIST_CALCULATOR || []);
  const [name, setName] = useState(props?.value);
  const inputRef = useRef(null);

  // Expose methods required by ag-Grid
  useImperativeHandle(ref, () => ({
    getValue: () => name,
  }));

  const handleChange = (event) => {
    setName(event);
    // console.log("handleChange", { event, props });
    // props.parseValue(event);
    // props.data.name = event;
  };

  const onNameChange = (event) => {
    setName(event.target.value);
  };

  const addItem = (e) => {
    e.preventDefault();
    setItems([...items, name || `Custom ${index++}`]);
    setName("");
    setTimeout(() => {
      inputRef.current?.focus();
    }, 0);
  };
  return (
    <Select
      style={{ width: 260 }}
      placeholder="Customize items"
      dropdownRender={(menu) => (
        <>
          {menu}
          <Divider style={{ margin: "8px 0" }} />
          <Space style={{ padding: "0 8px 4px" }}>
            <MondayButton className="mondayButtonBlue" hasIcon={false}>
              Custom Option:
            </MondayButton>
            <Input
              placeholder="Please enter item"
              ref={inputRef}
              value={name}
              onChange={onNameChange}
              onKeyDown={(e) => e.stopPropagation()}
            />
            {/* <Button type="text" icon={<PlusOutlined />} onClick={addItem}>
              Add item
            </Button> */}
          </Space>
        </>
      )}
      onChange={handleChange}
      options={items.map((item) => ({ label: item?.name, value: item?.name }))}
    />
  );
});

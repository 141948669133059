import { useEffect, useState } from "react";
import { message } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import { updateDocumentTitle, filterTables } from "src/utils";
import NotesDrawer from "./NotesDrawer/NotesDrawer";
import NotesIconContent from "./components/NotesIconContent/NotesIconContent";

/**
 @param {String} rowId - Id of current row 
@param {String} noteModalName - Name of current row 
@param {String} topicCategory - Current topic NOTE: topicCategory is used also to check accessRights in routeConfig & is used also at fieldOptions 
@param {String} tooltipCategory - category used to show dynamic tooltips
@param {String} customLinkTo - OPTIONAL - If we want to navigate user in a custom link when note is saved in NotesBasePage
@param {Array} teamsConfiguration - OPTIONAL - used to save curr team in curr note
@param {Boolean} notesVisibility - OPTIONAL - We get visibility of overview notes
@param {Function} setNotesVisibility - OPTIONAL - We set visibility of overview notes
@param {Function} setNotesVisibility - OPTIONAL - if you have custom array with notes state
@param {Function} setNotesVisibility - OPTIONAL - if you have set state for custom array with notes 
@param {Boolean} isNextStep - OPTIONAL - to not navigate if is next step
@param {HTMLElement} customIcon - OPTIONAL - If we have custom icon from anywhere
@param {Boolean} showBadge - OPTIONAL - if need to show notes length
@param {Boolean} showBadge - OPTIONAL - if need to disable note opening
*/

export const Notes = ({
  rowId,
  noteModalName,
  topicCategory,
  tooltipCategory,
  customLinkTo = "",
  teamsConfiguration,
  notesVisibility,
  setNotesVisibility = () => {},
  propNotes,
  setPropNotes,
  isNextStep,
  customIcon,
  showBadge = false,
  disableNotes = false,
}) => {
  const location = useLocation();
  const navigate = useNavigate();

  const [visible, setVisible] = useState(
    (!!location?.state?.notesOpen && location?.state?.recordId === rowId) ||
      notesVisibility
  );
  const [rowNotes, setRowNotes] = useState();

  const finalVisibility = visible || notesVisibility;

  //if there is no propNotes && we have an rowId and notes visibility is true fetch current notes
  useEffect(() => {
    if (!propNotes && rowId && finalVisibility) {
      updateDocumentTitle({ newSubTitle: "Notes" }); // set Title of Tab
      filterTables("notes", "recordId", rowId)
        .then((res) => {
          message.destroy();
          setRowNotes(res);
        })
        .catch((err) => {
          message.warning("Error fetching notes!");
          console.log("Error fetching notes", err);
        });
    }
  }, [rowId, finalVisibility]);

  const openModal = (event) => {
    if (event) {
      setVisible(true);
    } else {
      updateDocumentTitle(); // reset document title to "Lead Manager"

      !!!isNextStep &&
        navigate(
          location.pathname + location.search,
          { ...location, state: {} },
          { replace: true }
        );
      notesVisibility && setNotesVisibility(false); //from overview pages
      visible && setVisible(false); //from notesProps of history state, visible still its true so we set it to false
    }
  };

  return (
    <>
      {typeof notesVisibility !== "boolean" && (
        <NotesIconContent
          {...{
            rowNotes: propNotes || rowNotes,
            topicCategory,
            tooltipCategory,
            openModal,
            customIcon,
            showBadge,
            disableNotes,
          }}
        />
      )}

      {finalVisibility && (
        <NotesDrawer
          {...{
            visible: finalVisibility,
            openModal,
            noteModalName,
            topicCategory,
            rowId,
            rowNotes: propNotes || rowNotes,
            setRowNotes: setPropNotes || setRowNotes,
            customLinkTo,
            teamsConfiguration,
          }}
        />
      )}
    </>
  );
};

import { TickIcon } from "../../../Roles/src";
import { LogsButton, MondayButton } from "../../../../../../commonComponents";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";

export const footerButtons = ({
  onCancel,
  handleUpdate,
  cancelRef,
  logsRef,
  saveRef,
  recordSelected,
}) => {
  return [
    <div className="footerButtons">
      <div ref={cancelRef}>
        <MondayButton
          className="mondayButtonRed"
          onClick={onCancel}
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>
      </div>
      <div ref={logsRef} className="crewLogsDiv">
        <LogsButton
          {...{
            buttonLabel: "Logs",
            logsTitle: "Employee Logs",
            filtersObj: { recordId: recordSelected?.data?.crewId },
          }}
        />
      </div>
      <div ref={saveRef}>
        <MondayButton
          Icon={<TickIcon height={17} width={17} />}
          onClick={handleUpdate}
        >
          Save Changes
        </MondayButton>
      </div>
    </div>,
  ];
};

export function employeeFields(isDarkMode, role, status) {
  return [
    {
      label: "Full Name",
      type: "input",
      placeholder: "Name...",
      formItemName: "crewName",
      className: "crewNameUpdate",
      required: true,
      dataTestid: "input-name",
    },
    {
      label: "Role",
      type: "select",
      placeholder: "Select role...",
      formItemName: "crewPosition",
      popupClassName: isDarkMode && "darkDropDown",
      className: "crewPositionSelect",
      customOptions: (role || [])?.map(({ statusName }, key) => ({
        key,
        label: statusName,
        value: statusName,
      })),
      required: true,
      getPopUpContainer: document.body,
      dataTestid: "select-role",
    },
    {
      label: "Employee Type",
      type: "select",
      placeholder: "Select employee type...",
      formItemName: "foreman",
      customOptions: [
        { key: 0, label: "Member", value: false },
        { key: 1, label: "Foreman", value: true },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "Status",
      type: "select",
      placeholder: "Select employee status...",
      formItemName: "crewStatus",
      customOptions: (status || []).map(({ statusName }, key) => {
        return {
          key,
          label: statusName,
          value: statusName,
        };
      }),
      required: true,
      getPopUpContainer: document.body,
    },

    {
      label: "Pay Rate",
      type: "input",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      addonBefore: "$",
      required: true,
    },
    {
      label: "Salary Type",
      type: "select",
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "Employee ID",
      type: "input",
      placeholder: "Employee Id...",
      formItemName: "employeeId",
      className: "employeeIdEdit",
      required: true,
    },
    {
      label: "Dynamic Selection",
      type: "select",
      placeholder: "Dynamic Selection...",
      formItemName: "dynamicSelection",
      className: "employeeIdInput",
      customOptions: [
        {
          key: 0,
          label: "Yes",
          value: true,
        },
        {
          key: 1,
          label: "No",
          value: false,
        },
      ],
      dataTestid: "employeeIdField",
      required: true,
    },
    {
      type: "input",
      formItemName: "fingerCheckId",
    },
    {
      type: "input",
      formItemName: "employeeNumber",
    },
  ];
}

export function employeeEmergencyContactFields() {
  return {
    formItemName: "emergencyContact",
    label: "Emergency Contact",
    type: "MultipleInputs",
    disabled: false,
    dynamicFields: [
      {
        formItemName: "fullName",
        label: "Full Name",
        type: "Input",
        disabled: false,
        rules: [{ required: true, message: "Please input the Full Name" }],
      },
      {
        formItemName: "email",
        label: "Email",
        type: "Email",
        disabled: false,
        rules: [
          {
            required: true,
            type: "email",
            message: "The input is not valid e-mail!",
          },
        ],
      },
      {
        formItemName: "phoneNumber",
        label: "Phone Number",
        type: "PhoneNumber",
        rules: [
          {
            required: true,
            message: "Please input your phone number!",
          },
          {
            pattern:
              /^1?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            message: "Please enter a valid phone number",
          },
        ],
        disabled: false,
      },
      {
        formItemName: "relationship",
        label: "Relationship",
        type: "ComboBox",
        options: ["Parent", "Spouse", "Sibling", "Child", "Friend"],
        disabled: false,
        rules: [
          {
            required: true,
            message: "Please select or enter a Relationship",
          },
        ],
      },
    ],
  };
}

import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { formatDate } from "./formatUtils";

export const getFleetRepairWorkOrders = async () => {
  try {
    const workOrders = await fetchData("workOrders");

    return workOrders.flatMap((order) => {
      return order.workOrderItems.map((item) => ({
        ...order,
        ...item,
        fleetName:
          order.fleetName.toLowerCase().includes("truck") ||
          order.fleetName.toLowerCase().includes("trailer")
            ? order.fleetName.replace(/\D/g, "")
            : order.fleetName,
        createdAt: formatDate(order.createdAt),
        dueDate: formatDate(order.dueDate),
        odometer: parseInt(item?.odometer) || "-",
        mechanic: order.mechanicInfo.mechanic,
        workOrderItems: undefined,
        schedule: "Corrective",
        location: "In-House",
      }));
    });
  } catch (error) {
    console.error(
      "data source function getFleetRepairWorkOrders error: " + error
    );
  }
};

import { useEffect } from "react";
import { useDispatch } from "react-redux";

import {
  resetRecordData,
  setRecordData,
} from "../../../../actions/communicationActions";

/**
 * 
@param useRecordData - adds the record/row data to the record data object inside communication reducer state and this data will be used to send the email
*/

const useRecordData = (recordName, recordId, prevRecordData) => {
  const dispatch = useDispatch();

  useEffect(() => {
    if (recordName && recordId && prevRecordData) {
      dispatch(
        setRecordData({
          recordName,
          recordId,
          ...prevRecordData,
        })
      );
    }

    return () => {
      dispatch(resetRecordData());
    };
  }, [recordName, recordId, prevRecordData]);
};

export default useRecordData;

import { API } from "aws-amplify";
import { saveNotification } from "../utils/save";
import { showErrorMsg, showInfoMsg } from "../../../../../utils";
import { deleteNotification } from "../../../../../actions/notificationsActions";

export const onDeleteConfirmation = async (
  setAnimatedClassName,
  id,
  cognitoUserId,
  authenticatedUser,
  notificationObject,
  apiName,
  path,
  dispatch,
  type
) => {
  try {
    // Trigger animation for UI feedback on delete action
    setAnimatedClassName(["moveRight", id]);

    // Function to call the API and delete the notification
    const deleteNotificationAPI = async () => {
      // Call the API to delete the notification
      const response = await API.del(apiName, `/${path}/${id}`);
      // Check if the deletion was successful
      if (response.status === true) {
        // Show success message
        showInfoMsg({ content: "Notification was successfully deleted." });
        // Reset animation class
        setAnimatedClassName([]);
        // Dispatch action to remove the notification from the state
        dispatch(deleteNotification(id));
      } else {
        // Show error message if deletion failed
        showErrorMsg({ content: `Error deleting ${type}` });
      }
    };

    // Check if there are multiple Cognito User IDs associated with the notification
    if (Object.keys(cognitoUserId).length > 1) {
      // Destructure to separate the authenticated user's ID from the others
      const { [authenticatedUser.sub]: myCognito, ...otherCognitos } =
        cognitoUserId;

      // Save the notification with remaining Cognito User IDs
      const saveResponse = await saveNotification({
        id,
        body: {
          cognitoUserId: otherCognitos,
          notificationObject,
        },
      });

      // Check if saving the notification was successful
      if (saveResponse.status === true) {
        // If saving succeeded, proceed to delete the notification
        await deleteNotificationAPI();
      } else {
        // Show error message if saving failed
        showErrorMsg({ content: `Error deleting ${type}` });
      }
    } else {
      // If there are no additional Cognito User IDs, delete the notification directly
      await deleteNotificationAPI();
    }
  } catch (error) {
    // Log the error to the console
    console.error(error);
    // Show error message if an exception occurs
    showErrorMsg({ content: `Error deleting ${type}` });
  }
};

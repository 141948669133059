import { message } from "antd";
import dispatchExportTemplates from "./dispatchExportTemplates";

export function createTemplateActionHandler({
  templates,
  exportTemplateField,
  keyName,
  dispatch,
  setTemplates,
  setSelectedTemplate,
  selectedTemplate,
  setTemplateName,
  setClicked,
}) {
  return async function handleAction(
    action,
    templateName,
    filters = {},
    isDefault = false
  ) {
    const fieldId = exportTemplateField?.fieldId;

    if (action === "create") {
      if (Object.keys(filters).length === 0) {
        setTemplateName("");
        return message.error("No filters to save");
      }
      if (templates.find((template) => template.name === templateName)) {
        return message.error("Template name already exists");
      }
      const newTemplate = {
        name: templateName,
        filters: filters,
        isDefault: false,
      };
      const newTemplates = [...templates, newTemplate];
      const newFieldOptions = {
        ...exportTemplateField?.fieldOptions,
        [keyName]: newTemplates,
      };
      await dispatchExportTemplates(fieldId, newFieldOptions, dispatch);
      setTemplates(newTemplates);
      setSelectedTemplate(newTemplate);
      setTemplateName("");
    } else if (action === "delete") {
      const newTemplates = templates.filter(
        ({ name }) => name !== templateName
      );
      const newFieldOptions = {
        ...exportTemplateField?.fieldOptions,
        [keyName]: newTemplates,
      };
      await dispatchExportTemplates(fieldId, newFieldOptions, dispatch);
      setTemplates(newTemplates);
      if (selectedTemplate && selectedTemplate.name === templateName) {
        setSelectedTemplate("");
      }
      setClicked("");
    } else if (action === "makeDefault") {
      const newTemplates = templates.map((template) => {
        if (template.name === templateName) {
          return { ...template, isDefault };
        }
        return { ...template, isDefault: false };
      });
      const newFieldOptions = {
        ...exportTemplateField?.fieldOptions,
        [keyName]: newTemplates,
      };
      await dispatchExportTemplates(fieldId, newFieldOptions, dispatch);
      setTemplates(newTemplates);
      setClicked("");
    }
  };
}

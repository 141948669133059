import React, { useState } from "react";
import { LabeledInput } from "..";
import { GPicker } from "../../../../../integrations/GPicker";
import { Button, Modal, message } from "antd";
import { compareIncluding, generateDocumentViewLink } from "../../../utils";
import { CloseOutlined } from "@ant-design/icons";
import "./GPickerButton.scss";
import { driveApi } from "../../../../../integrations/DriveRequest";
import TooltipMonday from "../../../../commonComponents/ToolTip/TooltipMonday";
import { fileIcons } from "../../../Documentation/View/documentationViewData";
import dayjs from "dayjs";
import { updateDocumentTitle } from "../../../../../utils";
import { DEVELOPER_KEY, DATE_FORMAT } from "./GPickerButtonData";
import { UploadIcon } from "../../../Documentation/View/assets";
import { MondayButton } from "../../../../commonComponents";

const GPickerButton = ({
  accessToken,
  uploaderId,
  onPickerSuccess,
  fileNames = [],
  parentId,
  required = false,
  showFiles = false,
  onDelete,
  label = "Upload",
  buttonText = "Choose Files",
  style = {},
  disabled = false,
  onlyTypes,
}) => {
  const driveRequest = driveApi({ accessToken });
  const [isModalOpen, setIsModalOpen] = useState(false);

  const filteredFileNames = fileNames?.find((item) =>
    compareIncluding(item.uploaderId, uploaderId)
  )?.files;

  const onFileNameClick = async ({ id, name, fileName }) => {
    let imageSrc = await driveRequest.getImageSrc(id);
    const value = fileName
      ? generateDocumentViewLink({
          filename: fileName,
          src: imageSrc.src,
        })
      : generateDocumentViewLink({
          filename: name,
          src: imageSrc.src,
        });

    window
      .open(value)
      .document.write(
        `<iframe width='100%' height='100%' src=${value}></iframe>`
      );
  };

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setIsModalOpen(false);
  };

  return (
    <LabeledInput
      label={label}
      noFormItem
      style={{ alignSelf: "baseline", ...style }}
      required={required}
    >
      <div className="googlePickerButtonContainer">
        <GPicker
          token={accessToken}
          onAuthenticate={() => {}}
          createPicker={(google, oauthToken) => {
            const uploadView = new google.picker.DocsUploadView()
              .setIncludeFolders(false)
              .setParent(parentId);
            const picker = new window.google.picker.PickerBuilder()
              .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
              .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
              .enableFeature(google.picker.Feature.MINE_ONLY)
              .enableFeature(google.picker.Feature.NAV_HIDDEN)
              .addView(uploadView)
              .setOAuthToken(oauthToken)
              .setDeveloperKey(DEVELOPER_KEY)
              .setCallback(async (data, event) => {
                if (
                  data.action === google.picker.Action.PICKED &&
                  !!data.docs.length
                ) {
                  if (
                    !onlyTypes ||
                    data.docs.every(({ mimeType }) =>
                      onlyTypes?.includes(mimeType)
                    )
                  ) {
                    onPickerSuccess({
                      uploaderId,
                      uploadedFiles: data.docs,
                    });
                  } else {
                    driveRequest
                      .deleteDriveItem(uploaderId)
                      .then(() => {
                        message.error(
                          `Invalid type! Please select another type!`
                        );
                      })
                      .catch((error) => {
                        console.error(error);
                      });
                  }
                }
              });
            picker.build().setVisible(true);
          }}
          disabled={!parentId || !accessToken}
        >
          <MondayButton
            disabled={!parentId || !accessToken || disabled}
            className="mondayButtonBlue uploadButton"
            Icon={<UploadIcon />}
          >
            {buttonText}
          </MondayButton>
        </GPicker>

        <div className="uploadedFileNamesContainer">
          {!showFiles ? (
            uploaderId ? (
              !!filteredFileNames ? (
                filteredFileNames.map(({ name, id, fileName }, i) => (
                  <div className="fileNameContainer" key={i}>
                    {!!onDelete && (
                      <CloseOutlined
                        className="deleteIcon"
                        onClick={() =>
                          !disabled
                            ? onDelete({ uploaderId, id })
                            : message.error(
                                "You can't delete this file because fields are disabled"
                              )
                        }
                      />
                    )}
                    <span
                      onClick={() => onFileNameClick({ name, id, fileName })}
                      className="fileName"
                    >
                      {fileName ? fileName : name}
                    </span>
                  </div>
                ))
              ) : (
                <span style={{ color: "#7B7B7B" }}>
                  {" "}
                  {!parentId ? "" : "No file uploaded"}
                </span>
              )
            ) : (
              !!fileNames.length && "Uploaded"
            )
          ) : !!filteredFileNames ? (
            <Button
              className="showFiles-btn"
              onClick={() => setIsModalOpen(true)}
            >
              Show Files
            </Button>
          ) : null}
        </div>
      </div>

      <Modal
        className="GPicker-modal"
        title="Uploaded Files"
        afterOpenChange={(event) => {
          event && updateDocumentTitle({ newTitle: "Uploaded Files" });
        }}
        open={isModalOpen}
        onOk={closeModal}
        onCancel={closeModal}
        footer={null}
      >
        {!!filteredFileNames ? (
          filteredFileNames?.map(({ id, name, uploadedAt }) => {
            const fileExtension = name.split(".").pop();

            const fileType = Object.keys(fileIcons).includes(fileExtension)
              ? fileExtension
              : "image";

            return (
              <div className="gPicker-container">
                <div className="first-row">
                  <span className="filesIcon">{fileIcons[fileType]}</span>
                  <span
                    className="title"
                    onClick={() => onFileNameClick({ name, id })}
                  >
                    {name}
                  </span>
                </div>
                <span className="date">
                  {dayjs(uploadedAt).format(DATE_FORMAT)}
                </span>
              </div>
            );
          })
        ) : (
          <span className="noFiles">No File Uploaded</span>
        )}
      </Modal>
    </LabeledInput>
  );
};

export default GPickerButton;

//@ts-check

import { filterTables } from "../../../../../../utils";
import { currencyFormater } from "../../../../../../utils/currencyFormater";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../Estimations/DataEntryGrid/models/Service";
import { getApprovedRentTotal } from "../../../../Estimations/DataEntryGrid/subcomponents/ServiceHeader/ServicePrices/ServicePrices";
import { getRawServiceTotals } from "../../../../Estimations/DataEntryGrid/tools/formatters/totals";
import { fetchAllData } from "../../../../Fleet/utils/fetchAllData";
// import { fetchAllData } from "../../../../../../utils";
import { rentalDataExtractor } from "../../../../Projects/Accounting/Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/IncludeRentals/includeRentalsFunctions";
import { getTotalServicesPrice } from "../../../../Projects/Accounting/calculations/servicePrices";

const calculateEstimationsTotal = (estimations) => {
  let totals = estimations.reduce((acc, estimation) => {
    acc += getTotalServicesPrice(estimation.services);
    return acc;
  }, 0);

  return totals;
};

const calculateIncludedRentalsAmount = (includedRentals) => {
  return includedRentals.reduce((acc, rental) => {
    acc += rental.services.reduce((accService, service) => {
      accService += service.currentPayment;
      return accService;
    }, 0);

    return acc;
  }, 0);
};

const sumCurrentPaymentsRentals = (rentals) => {
  return rentals
    .flat()
    .reduce((total, rental) => total + rental.currentPayment, 0);
};

const getServiceRent = (service) => {
  const isHoist = checkIfServiceIsHoist(service);
  const rentalAmount = isHoist
    ? getRawServiceTotals(service)
    : getApprovedRentTotal(service);
  return isHoist ? rentalAmount?.rentTotal : rentalAmount?.approvedRent;
};

const accumulateRentByLabel = (services) => {
  return services.reduce((acc, service) => {
    const { label } = service;
    if (label) {
      const rent = getServiceRent(service) || 0;
      acc[label] = (acc[label] || 0) + rent;
    }
    return acc;
  }, {});
};

const calculateRentalPerServiceFromEstimations = (estimations) => {
  const flattenedServices = estimations.flatMap(
    (estimation) => estimation?.services || []
  );
  const rentByLabel = accumulateRentByLabel(flattenedServices);

  const services = Object.entries(rentByLabel).map(([label, rent]) => ({
    label,
    rent,
  }));

  return services;
};

export const estimateTotalCalculation = async (id, filterBy) => {
  let TOTAL_INVOICE_AMOUNT = 0;
  let AMOUNT_TO_REDUCE = 0;
  let RETAINAGE_AMOUNT = 0;
  let estimations = [];
  let invoices = [];
  let charges = [];
  let applications = [];
  let includedRentals = [];
  let rentals = [];
  let payments = [];

  if (id)
    [
      estimations,
      invoices,
      charges,
      applications,
      includedRentals,
      rentals,
      payments,
    ] = await Promise.all([
      fetchAllData("estimations", "estimations", "estimationId", () => {}, {
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: filterBy,
                value: id,
                formula: "is",
              },
            ],
          },
        ]),
      }),
      filterTables("invoices", filterBy, id),
      filterTables("charges", filterBy, id),
      filterTables("applications", filterBy, id),
      filterTables("includedRentals", filterBy, id),
      filterTables("rentals", filterBy, id),
      fetchAllData("payments", "payments", "paymentId"),
    ]);
  else
    [
      estimations,
      invoices,
      charges,
      applications,
      includedRentals,
      rentals,
      payments,
    ] = await Promise.all([
      fetchAllData("estimations", "estimations", "estimationId"),
      fetchAllData("invoices", "invoices", "invoiceId"),
      fetchAllData("charges", "charges", "chargeId"),
      fetchAllData("applications", "applications", "applicationId"),
      fetchAllData("includedRentals", "includedRentals", "rentalsReqId"),
      fetchAllData("rentals", "rentals", "rentalId"),
      fetchAllData("payments", "payments", "paymentId"),
    ]);

  const approvedEstimations = estimations.filter(
    (estimation) => estimation.estSTATUS === "Approved"
  );

  const servicesRentalsPerEstimation =
    calculateRentalPerServiceFromEstimations(approvedEstimations);

  const estimationsTotal = calculateEstimationsTotal(approvedEstimations);
  const invoicesWithoutRentals = invoices.map((invoice) => ({
    ...invoice,
    invoiceItems: (invoice.invoiceItems || []).filter(
      (item) => item.category !== "rentals"
    ),
  }));

  // const notIncludedRentals = rentals.filter(
  //   (includedRental) =>
  //     !includedRentals.find(
  //       (rental) => rental.rentalId === includedRental.rentalId
  //     )
  // );

  // const rentalAmounts = notIncludedRentals.map((rental) =>
  //   rentalDataExtractor({ rental })
  // );

  // const totalRentalsWithoutRetainage = sumCurrentPaymentsRentals(rentalAmounts);

  const CHARGES_IDS = new Set();
  const { appliedCredit, unAppliedCredit } = charges.reduce(
    (acc, charge) => {
      if (charge.chargeType === "Credit Memo") {
        acc.appliedCredit += forceToNumber(charge?.appliedAmount);
        acc.unAppliedCredit += forceToNumber(charge?.remainingAmount);
      }
      return acc;
    },
    { appliedCredit: 0, unAppliedCredit: 0 }
  );

  const totalCredit = appliedCredit + unAppliedCredit;

  invoicesWithoutRentals.map((invoice) => {
    invoice.invoiceItems.map((item) => {
      TOTAL_INVOICE_AMOUNT += item.total;

      if (item.category === "charges") {
        CHARGES_IDS.add(item.categoryId);
      }
    });
  });
  const uniqueChargesIdsArray = Array.from(CHARGES_IDS);

  uniqueChargesIdsArray.map((charge_id) => {
    const foundCharge = charges.find((charge) => charge.chargeId === charge_id);
    console.log("foundCharge", foundCharge);

    if (foundCharge.categoryFrom === "Requisition") {
      const application = applications.find(
        (application) => application.applicationId === foundCharge.recordId
      );

      RETAINAGE_AMOUNT += application?.totalities?.currentRetainage || 0;

      const includedRentalsInApplication = includedRentals.filter(
        (rental) => rental.applicationId === application.applicationId
      );

      AMOUNT_TO_REDUCE += calculateIncludedRentalsAmount(
        includedRentalsInApplication
      );
    }
  });

  const filteredPayments = payments
    .map((payment) => {
      console.log("payment", { payment, filterBy, id });
      const filteredInvoices = payment.invoices.filter((invoice) => {
        const appInvoice = invoicesWithoutRentals.find(
          (_invoice) => _invoice.invoiceId === invoice.invoiceId
        );
        return appInvoice !== undefined;
      });

      return {
        ...payment,
        invoices: filteredInvoices,
      };
    })
    .filter((_payment) => _payment.invoices.length > 0);

  console.log({
    estimationsTotal,
    TOTAL_INVOICE_AMOUNT,
    AMOUNT_TO_REDUCE,
    RETAINAGE_AMOUNT,
  });
  // console.log("ledjorentals", rentals);
  return {
    totalEstimates:
      estimationsTotal - (TOTAL_INVOICE_AMOUNT - AMOUNT_TO_REDUCE),
    //  - RETAINAGE_AMOUNT
    //  -      totalRentalsWithoutRetainage,
    invoices: invoicesWithoutRentals,
    payments: filteredPayments,
    rentals,
    servicesRentalsPerEstimation,
    unAppliedCredit,
    totalCredit,
    appliedCredit,
    allPayments: payments?.filter((payment) => {
      if (filterBy === "projectId") {
        console.log("paymentFilter", { payment, id, filterBy });
        return !!payment.receivedFromProjects?.find((p) => p.projectId === id);
      } else return payment?.[`${filterBy}`] === id;
    }),
  };
};

import { useSelector } from "react-redux";
import { message, Switch, Tooltip } from "antd";
import { InfoWindowF } from "@react-google-maps/api";
import { Dispatch, SetStateAction, useContext, useMemo, useState } from "react";

import {
  EntryType,
  ScheduleType,
  EmployeeReportType,
} from "../../payrollLiveTypes";
import { InfoIcon } from "src/assets";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "src/components/commonComponents";
import {
  StoreType,
  JobsiteType,
  CoordinateType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { convertSecondsToTime, entriesApiCrud } from "../../utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { withinRadius } from "../../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../../Payroll/Tabs/DEG/components/modalComponents/utils";
// import { DEG_TIME_FORMAT } from "../../../Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./EmployeeInfo.scss";
interface Props {
  color: string;
  onClick: () => void;
  isHistoryPunch?: boolean;
  data: EmployeeReportType & { isForeman?: boolean };
  setEmployeesHistory?: Dispatch<
    SetStateAction<Array<EntryType & { color?: string }>>
  >;
}

function EmployeeInfo(props: Props) {
  const { data, color, onClick, setEmployeesHistory, isHistoryPunch } = props;
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const {
    mapRef,
    jobsites,
    schedules,
    degEntries,
    accessRight,
    setDegEntries,
    setHistoryInfo,
    selectedWeekDeg,
    setEmployeesInfo,
    // setEmployeesReport,
  } = useContext(PayrollLiveContext);

  const [distanceTime, setDistanceTime] = useState<string | null>(null);

  function onScheduleSelect(jobId: string) {
    message.loading({
      key: "onJobUpdate",
      content: "Changing jobsite...",
      duration: 0,
    });

    const updatedEntryReport = {
      ...data,
      color: "#e9c466",
    };
    let scheduleIndex = schedules.findIndex((el) => el.scheduleId === jobId);
    let jobIndex = jobsites.findIndex((el) => el.jobsiteId === jobId);

    if (scheduleIndex > -1) {
      const matchedSchedule = schedules[scheduleIndex];
      let isMatch;

      if (matchedSchedule?.geoFenceInfo?.length) {
        isMatch = findDistanceFromGeofenceSide({
          point: data?.punchCoordinates,
          geofence: matchedSchedule?.geoFenceInfo[0]?.geoFenceInfo,
          tolerance: +matchedSchedule?.radius || 300,
        });
      } else {
        isMatch = withinRadius(
          matchedSchedule.addressPosition,
          data.punchCoordinates,
          300
        );
      }

      Object.assign(updatedEntryReport, {
        projectId: schedules?.[scheduleIndex]?.projectId,
        color: isMatch?.withinRange || isMatch?.inRange ? "#00a464" : "#e9c466",
        distance: isMatch?.distanceInFeet,
      });
    }

    if (jobIndex > -1 && scheduleIndex === -1) {
      let isMatch;
      const matchedJobsite = jobsites?.[jobIndex];
      if (
        matchedJobsite?.geoFenceInfo?.length ||
        matchedJobsite?.geofenceInfo?.length
      ) {
        const geofence = matchedJobsite?.geofenceInfo?.length
          ? matchedJobsite?.geofenceInfo
          : matchedJobsite?.geoFenceInfo;

        isMatch = findDistanceFromGeofenceSide({
          point: data?.punchCoordinates,
          geofence: geofence?.[0]?.geoFenceInfo,
          tolerance: +matchedJobsite?.locationRadius || 300,
        });
      } else {
        isMatch = withinRadius(
          matchedJobsite.addressPosition,
          data.punchCoordinates,
          Number(matchedJobsite.locationRadius) || 300
        );
      }

      Object.assign(updatedEntryReport, {
        projectId: matchedJobsite?.projectId,
        jobsiteId: matchedJobsite?.jobsiteId,
        jobAddress: matchedJobsite?.jobAddress,
        color: isMatch?.withinRange || isMatch?.inRange ? "#00a464" : "#e9c466",
        distance: isMatch?.distanceInFeet,
      });
    } else if (scheduleIndex > -1) {
      jobIndex = jobsites.findIndex(
        (el) => el?.projectId === schedules?.[scheduleIndex]?.projectId
      );
    }

    const job = jobsites?.[jobIndex];
    if (job) {
      const jobUpdateBody = {
        projectId: job?.projectId,
        jobsiteId: job?.jobsiteId,
        jobAddress: job?.jobAddress,
        activityStatus: (updatedEntryReport?.distance > 300
          ? "Pending"
          : "Completed") as "Completed" | "Draft" | "Pending",
        jobsiteMatch: {
          services: job?.services || [],
          jobName: job?.jobName,
          jobsiteId: job?.jobsiteId,
          jobAddress: job?.jobAddress,
          reimbursement: job.reimbursement,
        },
      };
      entriesApiCrud({
        method: "put",
        body: jobUpdateBody,
        id: connectedEntry?.entryId,
      })
        .then(() => {
          setDegEntries((prev) => {
            return prev.map((el) => {
              const reportEntry = connectedEntry?.entryId === el?.entryId;
              if (reportEntry) {
                return {
                  ...el,
                  ...jobUpdateBody,
                };
              } else {
                return el;
              }
            });
          });
          message.success({
            key: "onJobUpdate",
            content: "Job changed successfully!",
            duration: 3,
          });
        })
        .catch((err) => {
          console.log("Error updating jobsite:: ", err);
          message.error({
            key: "onJobUpdate",
            content: "There was a problem updating jobsite",
            duration: 3,
          });
        });
    }
  }

  function onShowRoute() {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );
    if (jobIndex > -1) {
      mapRef.current.getDirections({
        origin: data.punchCoordinates,
        destination: jobsites[jobIndex].addressPosition,
        travelMode: google.maps.TravelMode.WALKING,
      });
    }
  }

  async function directionModeToggle(e: boolean) {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );

    const directions = await mapRef.current.getDirections({
      origin: data.punchCoordinates,
      destination: jobsites?.[jobIndex]?.addressPosition,
      travelMode: e
        ? google.maps.TravelMode.DRIVING
        : google.maps.TravelMode.WALKING,
    });
    setDistanceTime(directions?.routes?.[0]?.legs?.[0]?.duration?.text);
  }

  function showPunchHistory() {
    let historyEntries = degEntries.flatMap((el) => {
      return Number(el.employeeId.split("-")[1]) ===
        Number(data?.employeeNumber)
        ? { ...el, color: "#1264a38b" }
        : [];
    });
    setEmployeesHistory(historyEntries);
    setHistoryInfo(
      `${historyEntries?.[0]?.employeeId}${historyEntries?.[0]?.punchType}`
    );
    setEmployeesInfo(undefined);
  }

  async function onApprovePunch() {
    message.loading({
      key: "onApprovePunch",
      content: "Approving...",
      duration: 0,
    });
    try {
      await entriesApiCrud({
        method: "put",
        body: { activityStatus: "Completed" },
        id: connectedEntry?.entryId,
      });
      setDegEntries((prev) =>
        prev.map((el) =>
          el?.entryId === connectedEntry?.entryId
            ? { ...el, activityStatus: "Completed" }
            : el
        )
      );
      message.success({
        key: "onApprovePunch",
        content: "Punch Approved",
        duration: 2,
      });
    } catch (error) {
      console.log("Error updating entry: ", error);
      message.error({
        key: "onApprovePunch",
        content: "Error approving this punch",
        duration: 2,
      });
    }
  }

  function getJobOptions({
    data,
    empCoordinates,
  }: {
    type: "jobsites" | "schedules";
    empCoordinates: CoordinateType;
    data: Array<JobsiteType | ScheduleType>;
  }) {
    const options = data
      .sort((a, b) => {
        const positionA = a?.addressPosition?.lat
          ? a?.addressPosition
          : { lat: 0, lng: 0 };
        const positionB = b?.addressPosition?.lat
          ? b?.addressPosition
          : { lat: 0, lng: 0 };

        const distanceA = withinRadius(
          positionA,
          empCoordinates
        )?.distanceInMile;
        const distanceB = withinRadius(
          positionB,
          empCoordinates
        )?.distanceInMile;

        return distanceA - distanceB;
      })
      .map(
        ({
          // projectId,
          scheduleId,
          jobsiteId,
          jobAddress,
          scheduleAddress,
          addressPosition,
        }: ScheduleType & JobsiteType) => {
          let distance = withinRadius(
            addressPosition,
            empCoordinates
          )?.distanceInFeet;

          let lengthName = "Feet";

          if (distance >= 5280) {
            distance = Math.round(distance / 5280);
            lengthName = "Miles";
          }

          return {
            key: scheduleId || jobsiteId,
            value: scheduleId || jobsiteId,
            label: (
              <div
                style={{
                  display: "flex",
                  alignItems: "center",
                  justifyContent: "space-between",
                  gap: 10,
                }}
                key={scheduleAddress || jobAddress} // key used for search options
              >
                <div>{scheduleAddress || jobAddress}</div>
                {empCoordinates?.lat && (
                  <div
                    style={{
                      color: "grey",
                    }}
                  >
                    ({distance}) {lengthName}
                  </div>
                )}
              </div>
            ),
          };
        }
      );
    return options;
  }

  const connectedEntry = useMemo(() => {
    const index = degEntries.findIndex(
      (el) =>
        (el?.employeeId === data?.employeeId &&
          el?.punchTimeStamp === parseInTz(data?.punchTime).valueOf()) ||
        (el?.employeeId ===
          `${data?.company}-${Number(data?.employeeNumber)}` &&
          el?.punchTimeStamp === parseInTz(data?.punchTime).valueOf()) ||
        (el?.employeeId === `${data?.company}-${data?.employeeNumber}` &&
          el?.punchTimeStamp === parseInTz(data?.punchTime).valueOf())
    );
    const matchedEntry = degEntries?.[index];
    return matchedEntry;
  }, [JSON.stringify(degEntries), data]);

  const customOptions = useMemo(() => {
    const empCoordinates = data.punchCoordinates;
    const scheduleOptions = getJobOptions({
      data: schedules.filter(
        (el: ScheduleType & JobsiteType) => !el?.jobsiteId
      ),
      type: "schedules",
      empCoordinates,
    });
    const jobOptions = getJobOptions({
      data: jobsites,
      type: "jobsites",
      empCoordinates,
    });

    return [
      {
        label: "Schedules",
        options: scheduleOptions,
      },
      {
        label: "Jobsites",
        options: jobOptions,
      },
    ];
  }, [schedules, data]);

  const distance = useMemo(() => {
    const jobIndex = jobsites.findIndex(
      (el) => el?.projectId === data?.projectId
    );
    let lengthName = "ft";
    let duration = 0;

    let dst = withinRadius(
      data.punchCoordinates,
      jobsites?.[jobIndex]?.addressPosition
    ).distanceInFeet;

    duration = Math.round(dst / 4.7);

    if (dst >= 5280) {
      dst = Math.round(dst / 5280);
      lengthName = "Miles";
    }

    return { dst, lengthName, duration };
  }, [data?.punchCoordinates, data?.projectId, jobsites]);

  return (
    <InfoWindowF
      zIndex={3}
      position={data.punchCoordinates}
      key={data.employeeNumber + "info"}
      options={{ pixelOffset: new google.maps.Size(0, -36) }}
    >
      <section
        className={`employee-info-window ${
          darkMode ? "employee-info-window-dark" : ""
        }`}
        style={{ boxShadow: `0px 0px 4px 0px ${color || "#e9c466"}` }}
      >
        <div className="info-header">
          <span className="info-title">Employee</span>
          <XIcon onClick={onClick} width={12} height={12} />
        </div>
        <div className="info-body">
          <div className="location-information">
            <Tooltip title="Employee's Punch location">
              <InfoIcon width={14} height={14} />
            </Tooltip>
            <span>{data?.punchLocation}</span>
          </div>
          <div className="info-data">
            <label>
              {data.employeeName}{" "}
              {data?.isForeman ? (
                <span style={{ color: "#acacac" }}>(Foreman)</span>
              ) : (
                ""
              )}
            </label>
            <div className="employee-status">
              <div
                className="status-pin"
                style={{ backgroundColor: color || "#e9c466" }}
              ></div>
              <span>{data.liveStatus}</span>
            </div>
          </div>
          <div className="info-data">
            <div>{data?.crewTeamName}</div>
            <div>
              {data.punchTime.length > 19
                ? dayjsNY(data.punchTime).format("MM/DD/YYYY, hh:mm A")
                : parseInTz(data.punchTime).format("MM/DD/YYYY, hh:mm A")}
            </div>
          </div>
          {data?.projectId && data?.punchCoordinates?.lat ? (
            <div className="info-data">
              <div className="distance-data">
                <span>
                  Distance: {distance?.dst + " " + distance?.lengthName}
                </span>
                <div className="duration">
                  ({distanceTime ?? convertSecondsToTime(distance.duration)} )
                </div>
              </div>
              <span>
                <Switch
                  checkedChildren="Driving"
                  unCheckedChildren="Walking"
                  onChange={directionModeToggle}
                />
              </span>
              <span onClick={onShowRoute} className="show-route">
                Show Route
              </span>
            </div>
          ) : null}
          {setEmployeesHistory ? (
            <div onClick={showPunchHistory} className="show-route">
              Show Punch History
            </div>
          ) : null}

          {Object.hasOwn(data, "company") &&
          isHistoryPunch &&
          accessRight?.write &&
          selectedWeekDeg?.degStatus !== "Completed" ? (
            <InputComponent
              allowClear
              type="select"
              label="Schedule"
              onSelect={onScheduleSelect}
              customOptions={customOptions}
              placeholder="Select schedule match"
              onClear={() => onScheduleSelect("")}
              initialValue={connectedEntry?.jobsiteId || data?.jobsiteId}
              filterOption={(
                input: string,
                option: { label: React.ReactElement }
              ) => {
                return (option?.label?.key || "")
                  .toLowerCase()
                  .includes((input || "").toLowerCase());
              }}
            />
          ) : null}
          {accessRight?.write ? (
            <div className="approve-container">
              <MondayButton
                hasIcon={false}
                onClick={onApprovePunch}
                disabled={connectedEntry?.activityStatus === "Completed"}
                className={
                  connectedEntry?.activityStatus === "Completed"
                    ? ""
                    : "mondayButtonBlue"
                }
              >
                Approve Punch
              </MondayButton>
            </div>
          ) : null}
        </div>
      </section>
    </InfoWindowF>
  );
}

export default EmployeeInfo;

import { useSelector } from "react-redux";
import { Avatar } from "antd";

import { getInitials } from "../../../../../utils";
import { StoreType } from "../../../../SidebarPages/FleetMaintenanceView/types";
import MessageBubble from "../MessageBubble/MessageBubble";
import { AutomationIconFilled } from "../../../../OnBoarding/assets/icons";

import "./MessageGroup.scss";

/**
 * @typedef MessageGroupProps
 * @property {Array} messages
 *
 * @param {MessageGroupProps} props
 */
function MessageGroup(props) {
  const { userConfiguration } = useSelector(
    /** @param {StoreType} state */
    (state) => state.userConfig
  );
  const { userImages } = useSelector(
    /** @param {StoreType} state */
    (state) => state.userImages
  );

  const { senderId, messageStatus, botMessage, senderName } =
    props?.messages?.[0] || {};

  const isCurrentUser = userConfiguration.identityId === senderId;

  const src = userImages?.[senderId];

  return (
    <div
      className={`assistant-message-group ${
        isCurrentUser ? "message-sent" : ""
      }`}
    >
      <Avatar
        size={40}
        src={src}
        icon={botMessage ? <AutomationIconFilled /> : undefined}
        className="group-avatar "
      >
        <span className="avatar-initials">{getInitials(senderName)}</span>
      </Avatar>
      <div className="triangle-wrap">
        <div
          className={`sender-triangle ${
            messageStatus === "error"
              ? "arrow-error"
              : messageStatus === "pending"
              ? "arrow-pending"
              : ""
          }`}
        />
      </div>
      <div className="messages-container">
        {props.messages.map((message, i) => {
          return (
            <MessageBubble
              message={message}
              isFirstMessage={!i}
              key={message?.messageId}
              onRetry={props.onRetry}
              onSendFeedback={props.onSendFeedback}
            />
          );
        })}
      </div>
    </div>
  );
}

export default MessageGroup;

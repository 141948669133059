import { useMemo } from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import { useLocation, useNavigate } from "react-router-dom";
import { useRedux } from "../../../../../../../hooks";
import { getColumnDefs, getRowData, parseComparedHtml } from "../../utils";

import "./DynamicComparisonTable.scss";

const DynamicComparisonTable = ({
  originalObject,
  objectToCompare,
  customColumnDefs,
  completeServiceName,
}) => {
  const [tableDataArray, setTableDataArray] = useRedux(
    "CompareTakeOffVersions",
    []
  );
  const location = useLocation();
  const { base64 } = useSelector((state) => state.base64);

  const rowData = getRowData(originalObject, objectToCompare);
  const columnDefs = customColumnDefs
    ? customColumnDefs
    : getColumnDefs(originalObject, objectToCompare);

  const defaultColDef = useMemo(() => {
    return {
      editable: false,
      sortable: false,
      resizable: true,
      filter: false,
      flex: 1,
      minWidth: 100,
      cellStyle: (params) => {
        let objectValue =
          objectToCompare?.[params.rowIndex]?.[params.colDef.field];

        if (Array.isArray(params.value) && Array.isArray(objectValue)) {
          return params.value.length !== objectValue.length
            ? { backgroundColor: "#4ee54e" }
            : null;
        }
        return parseComparedHtml(params.value, objectValue);
      },
    };
  }, []);

  const onGridReady = (params) => {
    const { api, columnApi } = params;
    setTableDataArray([
      ...tableDataArray,
      {
        gridApi: api,
        rowData: rowData,
        history: location?.pathname?.split("/")?.[1],
        historyTab: location?.pathname,
        gridColumnApi: columnApi,
        base64,
        completeServiceName,
      },
    ]);
  };

  return (
    <div className="ag-theme-alpine" style={{ height: "500px", width: "100%" }}>
      <AgGridReact
        rowData={rowData}
        columnDefs={columnDefs}
        defaultColDef={defaultColDef}
        onGridReady={onGridReady}
        rowClassRules={{
          "highlight-green": (params) =>
            params.data.hasOwnProperty("matched") && !params.data.matched,
        }}
      />
    </div>
  );
};

export default DynamicComparisonTable;

import { API } from "aws-amplify";

import {
  additionalContactBodyCreation,
  chargeBodyCreation,
  claimBodyCreation,
  contactBodyCreation,
  documentationBodyCreation,
  hearingBodyCreation,
  invoiceBodyCreation,
  opportunityBodyCreation,
  paymentBodyCreation,
  requisitionBodyCreation,
  safetyInspectionBodyCreation,
  taskBodyCreation,
  vehicleIncidentBodyCreation,
  vehicleViolationBodyCreation,
} from "./autoCreationHelpers";
import { filterTables, showErrorMsg } from "../../../../utils";
import {
  updateEditLogs,
  updateExistingPayments,
  updateInvoicesAmountDue,
} from "../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/actions";
import { updateClientBalance } from "../../../SidebarPages/Accounting/components/utilities";
import { keys } from "src/components/pages/Settings/settingsComponents/Roles/RolesData";
import {
  getAssignedUsers,
  sendTaskCreateNotification,
  updateProjectTasksList,
} from "../../../SidebarPages/TaskManager/Tasks/TasksModals/NewTask/helpers";
import { sendTaskAssignmentEmail } from "../../../SidebarPages/TaskManager/utils";
import { createEventFromTask } from "../../../Header/components/GoogleEvents/googleClientAPI/execute";
import { getRecord } from ".";
import { updatePLIsFromCategory } from "../../../SidebarPages/Projects/Accounting/Charges/components/NewChargeItem/utils";
import { fetchData } from "../../../SidebarPages/Fleet/utils";
import { updateAllCategories } from "../../../SidebarPages/Accounting/components/CreateInvoice/utils/updateAllCategories";

class OperationHandler {
  constructor() {
    this.savedItems = [];
    this.lastCreatedStep = "";
    this.createAllFolders = this.createAllFolders.bind(this);
    this.createFolders = this.createFolders.bind(this);
  }

  clearItems() {
    this.lastCreatedStep = "";
    this.savedItems = [];
  }

  updateLastCreatedStep(newStep) {
    this.lastCreatedStep = newStep;
  }

  async createAllFolders({
    getFolderIdOrCreate = async () => {},
    updateDriveItem = async () => {},
    oldFolders = {},
    parentFolderName,
    parentId,
    listOfFolders,
  }) {
    try {
      const parentFolderId = await getFolderIdOrCreate({
        name: parentFolderName,
        parents: [parentId],
      });

      let allFolders = {
        [listOfFolders?.folderKeyName]: parentFolderId,
      };

      const createFolder = async (name, parentFolderId, folderKeyName) => {
        let temp;
        //* If folder exists in all folders move it to the new folder if not create a new one and add it to allFolders
        if (!!oldFolders?.[folderKeyName]) {
          await updateDriveItem(oldFolders?.[folderKeyName], {
            addParents: parentFolderId,
          }).then(async (res) => {
            allFolders[folderKeyName] = oldFolders?.[folderKeyName];
            temp = { [folderKeyName]: oldFolders?.[folderKeyName] };
          });
        } else {
          await getFolderIdOrCreate({
            name,
            parents: [parentFolderId],
          }).then((res) => {
            allFolders[folderKeyName] = res;
            temp = { [folderKeyName]: res };
          });
        }
        return temp;
      };

      const isObject = (value) => {
        return !!(
          value &&
          typeof value === "object" &&
          !Array.isArray(value) &&
          keys(value).length > 0
        );
      };

      const promiseAllCreate = async (subFolders, parentId) => {
        return await Promise.all(
          Object.entries(subFolders).map(([key, value]) =>
            createFolder(key, parentId, value.folderKeyName)
          )
        );
      };

      const dynamicallyCreateFolders = async (listOfFolders, parent) => {
        if (isObject(listOfFolders)) {
          const allIds = await promiseAllCreate(listOfFolders, parent);
          for (let key in listOfFolders) {
            if (typeof listOfFolders[key] === "string") continue;

            if (isObject(listOfFolders[key])) {
              let folder = listOfFolders[key];
              if (isObject(folder?.subFolders))
                await dynamicallyCreateFolders(
                  folder?.subFolders,
                  allIds?.find((id) => !!id?.[folder?.folderKeyName])?.[
                    folder?.folderKeyName
                  ]
                );
            }
          }
        }
      };

      await dynamicallyCreateFolders(listOfFolders?.subFolders, parentFolderId);

      return allFolders;
    } catch (error) {
      console.error("google drive", error);
      return Promise.reject(error);
    }
  }

  async createFolders({
    name,
    programFields,
    parentFolderName,
    parentId,
    driveRequest,
  }) {
    const listOfFolders = programFields?.find(
      ({ fieldName }) => fieldName === "List of Folders"
    )?.fieldOptions?.[name];

    const { getFolderIdOrCreate } = driveRequest;

    let folders = await this.createAllFolders({
      parentFolderName,
      parentId,
      getFolderIdOrCreate,
      listOfFolders,
    });

    return folders;
  }

  async createInvoice({
    data: record,
    inputData,
    title: category,
    userConfiguration,
    dependantName,
    projectData,
  }) {
    try {
      const { invoiceDate, invoiceDueDate } = inputData;

      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      if (!!!invoiceDate || !!!invoiceDueDate) {
        showErrorMsg({
          content: "Please ensure all mandatory fields are filled out.",
        });
        return;
      }

      const invoice = await invoiceBodyCreation({
        record: data,
        inputData,
        userConfiguration,
        category,
        lastStep: this.lastCreatedStep,
      });

      const result = await API.post("invoices", `/invoices`, {
        body: invoice,
      });

      if (!result) throw new Error("Invoice was not created successfully.");

      this.savedItems.push({ name: "Invoice", item: { ...result } });
      this.updateLastCreatedStep("Invoice");

      const convertedData = {
        subtotal: result.subtotal,
        invoiceItems: result.invoiceItems,
        totalInvoiceAmount: result.totalDue,
        toDate: result.dueDate,
        fromDate: result.invoiceDate,
        quickBooksNumber: result.quickBooksNumber,
        invoiceDate: result.invoiceDate,
        memos: result.memos || [],
      };

      await updateClientBalance({
        accountId: result?.accountId,
        totalInvoiceAmount: -result.totalInvoiceAmount,
      });

      let invoiceData;

      const table = category.toLowerCase();
      const currentCategoryData = await filterTables(
        table,
        "projectId",
        projectData.projectId
      );

      let categoryData = { [table]: currentCategoryData };

      const updatedInvoice = { ...result, products: result.invoiceItems };

      await updateAllCategories({
        data: updatedInvoice,
        res: result,
        categoryData,
        invoiceData,
        convertedData,
        projectData,
      });
    } catch (error) {
      showErrorMsg({
        content: `Error creating invoice: ${error.message}`,
      });
      throw error;
    }
  }

  async createPayment({
    data: record,
    inputData,
    dependantName,
    userConfiguration,
  }) {
    try {
      const { paymentDate, paymentAmount, paymentMethod } = inputData;

      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      if (!!!paymentDate || !!!paymentAmount || !!!paymentMethod) {
        showErrorMsg({
          content: "Please ensure all mandatory fields are filled out.",
        });
        return;
      }

      const paymentBody = await paymentBodyCreation({
        invoice: data,
        inputData,
      });

      const result = await API.post("payments", `/payments`, {
        body: {
          ...paymentBody,
        },
      });

      if (!result) throw new Error("Payment was not created successfully.");

      await API.put("accounts", `/accounts/${result?.accountId}`, {
        body: {
          clientBalance: result.newCustomerBalance,
        },
      })
        .then(() => {
          this.savedItems.push({ name: "Payment", item: { ...result } });
          this.updateLastCreatedStep("Payment");
        })
        .catch((err) =>
          showErrorMsg({
            content: "There was a problem updating clientBalance",
            err,
          })
        );
      const paymentData = undefined;
      // await updateInvoicesAmountDue(paymentBody.invoices);
      await updateExistingPayments(result);
      await updateEditLogs(result, paymentData, paymentBody, userConfiguration);
    } catch (error) {
      showErrorMsg({
        content: `Error creating payment: ${error.message}`,
      });
      throw error;
    }
  }

  async createCharge({
    data: record,
    inputData,
    title: category,
    applications,
    dependantName,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      const newCategory =
        category === "Applications" ? "Requisitions" : category;

      const charge = await chargeBodyCreation({
        record,
        inputData,
        category: newCategory,
        lastStep: this.lastCreatedStep,
        currentRecord: data,
        applications,
      });

      const whereToFind = await fetchData(
        "projects",
        `projects/${charge?.projectId}`
      );

      if (!whereToFind) throw new Error("Could not create charge");

      await API.post("charges", `/charges`, {
        body: charge,
      }).then(async (result) => {
        console.log("result", charge);
        this.savedItems.push({ name: "Charge", item: { ...result } });
        this.updateLastCreatedStep("Charge");

        const categoryToTableRel = {
          Requisition: "applications",
          Rental: "rentals",
          Estimation: "estimations",
          "Schedule Of Value": "scheduleOfValues",
        };

        const categoryFrom = charge.categoryFrom;
        const selectedGroup = charge.recordId;
        const defaultParams = {
          recordId: selectedGroup,
          tableFrom: categoryToTableRel[categoryFrom],
          categoryFrom,
          chargeItems: charge.chargeItems,
          whereToFind,
          selectedGroup,
        };
        if (categoryFrom === "Requisition") {
          updatePLIsFromCategory({
            ...result,
            ...defaultParams,
          });
        } else if (categoryFrom === "Estimation" || categoryFrom === "Rental") {
          updatePLIsFromCategory({
            ...result,
            ...defaultParams,
            services: whereToFind.services[selectedGroup],
          });
        } else {
          updatePLIsFromCategory({
            ...result,
            ...defaultParams,
            ...whereToFind,
          });
        }
      });
    } catch (error) {
      showErrorMsg({
        content: `Error creating charge: ${error.message}`,
      });
      throw error;
    }
  }

  async createRental({
    data: record,
    inputData,
    title: category,
    projectData,
    userConfiguration,
  }) {
    try {
      this.updateLastCreatedStep("Rental");
    } catch (error) {
      showErrorMsg({
        content: `Error creating rental: ${error.message}`,
      });
      throw error;
    }
  }

  async createScheduleOfValue({
    data: record,
    inputData,
    title: category,
    projectData,
    userConfiguration,
  }) {
    try {
      this.updateLastCreatedStep("Schedule Of Value");
    } catch (error) {
      showErrorMsg({
        content: `Error creating Schedule Of Value: ${error.message}`,
      });
      throw error;
    }
  }

  async createRequisition({
    data: record,
    inputData,
    title: category,
    projectData,
    dependantName,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      const requisition = await requisitionBodyCreation({
        record: data,
        inputData,
        category,
        projectData,
      });

      const result = await API.post("applications", `/applications`, {
        body: requisition,
      });

      this.savedItems.push({ name: "Application", item: { ...result } });
      this.updateLastCreatedStep("Application");
    } catch (error) {
      showErrorMsg({
        content: `Error creating requisition: ${error.message}`,
      });
      throw error;
    }
  }

  async createSafetyInspection({
    data: record,
    inputData,
    title: category,
    dependantName,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      const safetyInspection = await safetyInspectionBodyCreation({
        record: data,
        inputData,
        category,
      });

      const result = await API.post("safety", `/safety`, {
        body: safetyInspection,
      });

      this.savedItems.push({
        name: "Safety Inspection",
        item: result,
      });
      this.updateLastCreatedStep("Safety Inspection");
    } catch (error) {
      showErrorMsg({
        content: `Error creating safety: ${error.message}`,
      });
      throw error;
    }
  }

  async createClaim({
    data: record,
    inputData,
    title: category,
    dependantName,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);
      if (!data) {
        throw new Error("Safety is not found");
      }

      const claim = await claimBodyCreation({
        record: data,
        inputData,
        category,
      });

      const result = await API.post("claims", "/claims", { body: claim });

      this.savedItems.push({
        name: "Claims",
        item: result,
      });
      this.updateLastCreatedStep("Claims");
    } catch (error) {
      showErrorMsg({
        content: `Error creating claim: ${error.message}`,
      });
      throw error;
    }
  }

  async createHearing({
    data: record,
    inputData,
    title: category,
    dependantName,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Claim is not found");
      }

      const hearing = await hearingBodyCreation({
        record: data,
        inputData,
        category,
      });

      const result = await API.post("hearings", "/hearings", { body: hearing });

      this.savedItems.push({
        name: "Hearings",
        item: result,
      });
      this.updateLastCreatedStep("Hearings");
    } catch (error) {
      showErrorMsg({
        content: `Error creating hearing: ${error.message}`,
      });
      throw error;
    }
  }

  async createContact({
    data: record,
    inputData,
    title: category,
    dependantName,
  }) {
    const data = getRecord(dependantName, record, this.savedItems);

    if (!data) {
      throw new Error("Record is not found");
    }

    const isContactCreated = !!data?.leadEmail;

    const contact = await contactBodyCreation({ data, inputData, category });

    const additionalContact = await additionalContactBodyCreation({
      data,
      inputData,
      category,
    });

    try {
      const result = !isContactCreated
        ? await API.post("contacts", "/contacts", { body: contact })
        : (await filterTables("contacts", "leadId", data.leadId)?.[0]) ||
          contact;

      const additionalResult =
        additionalContact &&
        (await API.post("contacts", "/contacts", { body: additionalContact }));

      this.savedItems.push({
        name: "Contact",
        item: result,
      });
      this.savedItems.push({
        name: "Additional Contact",
        item: additionalResult,
      });
      this.updateLastCreatedStep("Contact");
    } catch (error) {
      showErrorMsg({
        content: `Error creating contact: ${error.message}`,
      });
      throw error;
    }
  }

  async createOpportunity({
    data: record,
    inputData,
    title: category,
    programFields,
    driveRequest,
    dependantName,
  }) {
    const data = getRecord(dependantName, record, this.savedItems);

    if (!data) {
      throw new Error("Record is not found");
    }

    const contact = this.savedItems.find((item) => item.name === "Contact");
    const additionalContact = this.savedItems.find(
      (item) => item.name === "Additional Contact"
    )?.item;

    const parentFolderName = inputData?.jobSiteAddress;
    const parentId = data?.googleDriveFolderIds?.opportunitiesObject;

    const folders =
      (parentFolderName &&
        parentId &&
        (await this.createFolders({
          name: "Opportunities",
          programFields,
          parentFolderName,
          parentId,
          driveRequest,
        }))) ||
      {};

    const opportunity = await opportunityBodyCreation({
      contact: contact.item,
      record: data,
      inputData,
      category,
      folders,
      additionalContact,
    });

    try {
      const result = await API.post("opportunities", "/opportunities", {
        body: opportunity,
      });

      this.savedItems.push({
        name: "Opportunity",
        item: result,
      });
      this.updateLastCreatedStep("Opportunity");
    } catch (error) {
      showErrorMsg({
        content: `Error creating opportunity: ${error.message}`,
      });
      throw error;
    }
  }
  async createDocumentation({
    data: record,
    inputData,
    title,
    driveRequest,
    dependantName,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      const recordFolderId = data?.googleDriveFolderIds?.docObject;

      const documentTypes = await filterTables(
        "docConfiguration",
        "categoryName",
        title
      );

      for (const type of inputData.documentTypes) {
        const folderId = await driveRequest.getFolderIdOrCreate({
          name: type,
          parents: [recordFolderId],
        });

        const inputDocTypes = documentTypes?.[0]?.documentationsAvailable?.find(
          (document) => document.docName === type
        )?.docObject || [
          {
            type: "datepicker",
            formItemName: "requestedDate",
            label: "Requested Date",
          },
        ];

        const documentation = await documentationBodyCreation({
          record: data,
          title,
          type,
          folderId,
          inputDocTypes,
        });

        const result = await API.post("documentation", "/documentation", {
          body: {
            ...documentation,
            // relatedDocId: Object.keys(categoriesToIncludeIds).filter(Boolean),
          },
        });

        this.savedItems.push({
          name: "Documentation",
          item: result,
        });
        this.updateLastCreatedStep("Documentation");
      }
    } catch (error) {
      showErrorMsg({
        content: `Error creating documentation: ${error.message}`,
      });
    }

    // !!extraFolders?.[docType?.toLowerCase?.()] &&
    //   (await driveRequest
    //     .createFolders({
    //       folderNames: extraFolders[docType?.toLowerCase?.()],
    //       parents: [folderId],
    //     })
    //     .then((r) => r));
  }
  async createPermitDrawing({
    data: record,
    inputData,
    title: category,
    dependantName,
  }) {}

  async createTask({
    data: record,
    formValues,
    title: category,
    preferences,
    userConfiguration,
    dependantName,
    saveAddedLogs,
  }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }
      const { taskAssignedTo, projectTask, projectSubTask, assignToMe } =
        formValues;

      const updatedTaskAssignedTo = getAssignedUsers(
        assignToMe,
        taskAssignedTo,
        userConfiguration
      );

      const task = await taskBodyCreation({
        record: data,
        formValues,
        category,
        userConfiguration,
        updatedTaskAssignedTo,
      });
      const result = await API.post("tasksManagement", "/tasksManagement", {
        body: task,
      });

      if (!result) return;

      await createEventFromTask(
        { ...task, taskId: result.taskId },
        preferences?.calendarPreference
      );

      sendTaskAssignmentEmail(
        updatedTaskAssignedTo,
        result?.taskTitle,
        result?.taskTopic,
        result?.taskRelatedTo,
        result?.taskDeadline,
        result?.taskDescription,
        result.taskId
      );

      if (result.taskTopic.toLowerCase() === "projects" && projectTask) {
        updateProjectTasksList(result, projectTask, projectSubTask);
      }

      saveAddedLogs({
        recordId: result.taskId,
        recordName: result.taskTitle,
        category: "Tasks",
        topic: result.taskRelatedTo,
      });

      sendTaskCreateNotification(
        userConfiguration,
        result,
        updatedTaskAssignedTo,
        [], //mentionedUsers,
        () => {}
      );

      this.savedItems.push({
        name: "Task",
        item: result,
      });

      this.updateLastCreatedStep("Task");
    } catch (error) {
      showErrorMsg({
        content: `Error creating task: ${error.message}`,
      });
      throw error;
    }
  }

  async createVehicleIncident({ data: record, inputData, dependantName }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      const incident = await vehicleIncidentBodyCreation({
        record: data,
        inputData,
      });

      const result = await API.post("incidents", "/incidents", {
        body: incident,
      });

      if (!result) return;

      this.savedItems.push({
        name: "Incident",
        item: result,
      });

      this.updateLastCreatedStep("Incident");
    } catch (error) {
      showErrorMsg({
        content: `Error creating incident: ${error.message}`,
      });
      throw error;
    }
  }

  async createVehicleViolation({ data: record, inputData, dependantName }) {
    try {
      const data = getRecord(dependantName, record, this.savedItems);

      if (!data) {
        throw new Error("Record is not found");
      }

      const violation = await vehicleViolationBodyCreation({
        record: data,
        inputData,
      });

      const result = await API.post("fleetViolations", "/fleetViolations", {
        body: violation,
      });

      if (!result) return;

      this.savedItems.push({
        name: "Violation",
        item: result,
      });

      this.updateLastCreatedStep("Violation");
    } catch (error) {
      showErrorMsg({
        content: `Error creating violation: ${error.message}`,
      });
      throw error;
    }
  }
}

const operationHandler = new OperationHandler();

export const operations = {
  Clear: operationHandler.clearItems.bind(operationHandler),
  "Create Invoice": operationHandler.createInvoice.bind(operationHandler),
  "Create Payment": operationHandler.createPayment.bind(operationHandler),
  "Create Charge": operationHandler.createCharge.bind(operationHandler),
  // "Create Rental": operationHandler.createRental.bind(operationHandler),
  // "Create Schedule Of Value":
  //   operationHandler.createScheduleOfValue.bind(operationHandler),
  "Create Application":
    operationHandler.createRequisition.bind(operationHandler),
  "Create Safety Inspection":
    operationHandler.createSafetyInspection.bind(operationHandler),
  "Create Claims": operationHandler.createClaim.bind(operationHandler),
  "Create Hearings": operationHandler.createHearing.bind(operationHandler),
  "Create Contact": operationHandler.createContact.bind(operationHandler),
  "Create Opportunity":
    operationHandler.createOpportunity.bind(operationHandler),
  "Create New Documentation":
    operationHandler.createDocumentation.bind(operationHandler),
  "Create New Task": operationHandler.createTask.bind(operationHandler),
  // "Create Permit Drawing":
  //   operationHandler.createPermitDrawing.bind(operationHandler),
  "Create Incident":
    operationHandler.createVehicleIncident.bind(operationHandler),
  "Create New Violation":
    operationHandler.createVehicleViolation.bind(operationHandler),
};

import { API } from "aws-amplify";

export const getSalesTax = async ({ zipCode = "" }) => {
  try {
    const response = await API.post("salesTax", "/salesTax", {
      body: {
        postalCode: zipCode, // Use the provided zipCode or default to 10001
      },
    });

    let result = response?.results;
    if (Array.isArray(result) && result.length > 0) {
      return result[0]?.taxSales || 0;
    }
    return 0;
  } catch (error) {
    console.error("Error fetching sales tax:", error);
    return 0;
  }
};

import htmlParser from "../../../../utils/htmlParser";
import { parseText } from "../../Accounting/components/utilities";
import { hasSettingsAccess } from "../../TaskManager/Tasks/utils/hasSettingsAccess";
import { message } from "antd";
import { dayjsUserTimeZone } from "../../../DateComponents/contants/DayjsUserTimeZone";

const getLink = (data) => {
  if (data.todoCategory === "Pricing Config") {
    return `${data.linkTo}?service=${data.recordName.replace(/ /g, "_")}`;
  } else if (data.todoCategory === "Services Config") {
    return `${data.linkTo}?serviceId=${data.todoRecordId}`;
  } else if (data.todoCategory === "Scheduling Inspections") {
    return `${data.linkTo}?serviceId=${data.recordName.replace(/ /g, "_")}`;
  } else if (data.todoCategory === "Breakdowns") {
    return `${data.linkTo}?serviceId=${data.recordName.replace(/ /g, "_")}`;
  } else if (data.todoCategory === "Other Scopes") {
    return `${data.linkTo}?serviceId=${data.recordName.replace(/ /g, "_")}`;
  }

  return `${data.linkTo}`;
};

const handleClick = (
  userConfiguration,
  programFields,
  navigate,
  data,
  destination
) => {
  const fromSettings =
    data.hasOwnProperty("fromSettings") && data.fromSettings === true;

  if (!fromSettings)
    return window.open(`${window.location.origin}${destination}`, "_blank");

  const result = programFields
    .find(({ fieldName }) => fieldName === "Settings Tasks")
    .fieldOptions.filter((item) => item.included.includes("todo"))
    .find((topic) => topic.categoryName === data.todoCategory);

  const hasAccess = hasSettingsAccess(userConfiguration, result.access);

  return hasAccess
    ? navigate(getLink(data), {
        state: {
          tab: "ToDo",
          selectedId: data.todoId,
          rowSelected: data,
        },
      })
    : message.error({
        content: "You don't have access to this record or it doesn't exist.",
        key: "taskAccess",
        duration: 2,
      });
};

export const columnDefs = (userConfiguration, programFields, navigate) => [
  {
    headerName: "For",
    field: "recordName",
    cellRenderer: ({ data }) => {
      const destination = data?.linkTo?.includes("null")
        ? `/${data?.linkTo?.split("/")[1]}`
        : data?.linkTo;

      return data?.linkTo &&
        !window.location.pathname?.includes(destination) ? (
        <div
          style={{
            color: "#1677ff",
            textDecoration: "none",
            backgroundColor: "transparent",
            outline: "none",
            cursor: "pointer",
            transition: "color 0.3s,",
          }}
          onClick={() =>
            handleClick(
              userConfiguration,
              programFields,
              navigate,
              data,
              destination
            )
          }
        >
          {data?.recordName}
        </div>
      ) : (
        data?.recordName
      );
    },
  },
  {
    headerName: "Title",
    field: "todoName",
    maxWidth: 300,
  },
  {
    headerName: "Due",
    field: "todoDueDate",
    filter: "agDateColumnFilter",
    valueGetter: ({ data }) => {
      return dayjsUserTimeZone(data?.todoDueDate).format("MM/DD/YYYY hh:mm A");
    },
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY hh:mm A"),
  },
  {
    headerName: "Status",
    field: "todoStatus",
    minWidth: 120,
    cellStyle: { justifyContent: "center" },
  },
  {
    headerName: "Category",
    field: "todoCategory",
  },
  {
    headerName: "Related To",
    field: "relatedTo",
    valueGetter: ({ data }) => {
      if (!data?.relatedTo) return "N/A";
      if (!data?.subRecordName?.name) return data?.relatedTo || "N/A";

      return `${data?.relatedTo?.nameOfRelatedTo || data?.relatedTo} - ${
        data?.subRecordName?.name
      }`;
    },
    cellRenderer: ({ data, value }) => {
      let relatedTo = data?.relatedTo?.nameOfRelatedTo || data?.relatedTo;
      if (
        data?.subRecordName?.id &&
        relatedTo &&
        relatedTo?.toLowerCase() !== "documents"
      ) {
        return (
          <div
            onClick={() => {
              window.open(
                `/${relatedTo?.toLowerCase()}/${data?.subRecordName?.id}`,
                "_blank"
              );
            }}
            style={{
              color: "#1677ff",
              fontWeight: "600",
              fontSize: "14px",
              cursor: "pointer",
            }}
          >
            {value}
          </div>
        );
      } else {
        return <>{value}</>;
      }
    },
  },
  {
    headerName: "Description",
    field: "todoDetails",
    enableRowGroup: false,
    width: 300,
    valueGetter: ({ data }) => {
      return parseText(htmlParser(data?.todoDetails));
    },
  },
  {
    headerName: "Created By",
    field: "todoUser",
    valueGetter: ({ data }) => {
      return data?.todoUser?.nameOfUser
        ? data?.todoUser?.nameOfUser
        : data?.todoUser || "N/A";
    },
  },
  {
    headerName: "Last Updated By",
    field: "lastUpdatedBy",
    valueGetter: ({ data }) => {
      return data?.lastUpdatedBy ? data?.lastUpdatedBy : "";
    },
  },
  {
    headerName: "Last Updated At",
    field: "lastUpdatedAt",
    filter: "agDateColumnFilter",
    valueGetter: ({ data }) =>
      !data?.lastUpdatedAt
        ? ""
        : dayjsUserTimeZone(data?.lastUpdatedAt).format("MM/DD/YYYY hh:mm A"), // Formats the date to the user's timezone.
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY hh:mm A"),
  },

  {
    headerName: "Created At",
    field: "createdAt",
    filter: "agDateColumnFilter",
    valueGetter: ({ data }) =>
      dayjsUserTimeZone(data?.createdAt).format("MM/DD/YYYY hh:mm A"), // Formats the date to the user's timezone.
    hidden: true,
    comparator: (date1, date2) => sortDates(date1, date2, "MM/DD/YYYY hh:mm A"),
  },
];

/**
 *
 * @param {String || Timestamp || Moment} date1 - Date 1
 * @param {String || Timestamp || Moment} date2 - Date 2
 * @param {String} format - Format of the date if null then the date is either in timestamp or moment
 * @returns {Number} - Returns -1 if date1 is before date2, 0 if date1 is equal to date2, 1 if date1 is after date2
 */
export function sortDates(date1, date2, format = null) {
  if (!format) {
    if (!date1 || !dayjsUserTimeZone(date1).isValid()) return -1;
    if (!date2 || !dayjsUserTimeZone(date2).isValid()) return 1;
    if (date1 === date2) return 0;
    return dayjsUserTimeZone(date1).isBefore(dayjsUserTimeZone(date2)) ? -1 : 1;
  } else {
    if (!date1 || !dayjsUserTimeZone(date1, format).isValid()) return -1;
    if (!date2 || !dayjsUserTimeZone(date2, format).isValid()) return 1;
    if (date1 === date2) return 0;
    return dayjsUserTimeZone(date1, format).isBefore(
      dayjsUserTimeZone(date2, format)
    )
      ? -1
      : 1;
  }
}

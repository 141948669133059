import { isEmpty } from "lodash";
import getFilesWarnings, { singleFileWarning } from "./getFilesWarnings";

async function getDocWarnings(records, getParents, recordFolderId) {
  const processedRecords = await Promise.all(
    records?.map(async (record, i) => {
      if (isEmpty(record)) return null;
      let driveStatus = {};
      const { folderId, googleDriveUploads = [] } = record || {};
      const docTypeIndex = records.findIndex(
        (el) => el?.docType === record?.docType
      );
      if (docTypeIndex !== -1 && docTypeIndex < i) {
        driveStatus = { status: "Ok" };
      } else {
        driveStatus = await singleFileWarning(
          record,
          recordFolderId,
          getParents,
          "folderId"
        );
      }
      if (googleDriveUploads?.length === 0) {
        return !!folderId
          ? driveStatus.status !== "Ok"
            ? { ...record, driveStatus }
            : null
          : { ...record, driveStatus: { status: "Folder Missing" } };
      }
      const updatedUploads = await getFilesWarnings(
        googleDriveUploads,
        folderId,
        getParents
      );
      const allOk = updatedUploads.every((upload) => upload.status === "Ok");
      if (allOk && driveStatus.status === "Ok") return null;
      return {
        ...record,
        googleDriveUploads: updatedUploads.filter(
          (upload) => upload.status !== "Ok"
        ),
        ...(driveStatus.status !== "Ok" ? { driveStatus } : {}),
      };
    })
  );
  return processedRecords.filter(Boolean);
}

export default getDocWarnings;

import { message } from "antd";
import { API } from "aws-amplify";
import { lazyFetch } from "../../../../../../../utils";
import { findProp } from "../../../../../../SidebarPages/DynamicView/Pages/Overview/components/DrivePermission/utils/utils";
import Swal from "sweetalert2";
import { uniqBy } from "lodash";

//region Unique Items
export function getUniqueItems(oldArr, newArr) {
  //? get newArr elements that are not in oldArr comparing by identityId
  const filtered = newArr?.filter(
    (objN) => !oldArr?.some((objO) => objO?.identityId === objN?.identityId)
  );
  return filtered;
}

//region Prepare API Put Data
function prepareApiPutData(recordsToUpdate, topic, promises, group) {
  recordsToUpdate?.forEach(
    ({ teamIndex, recordId, teamsConfiguration, team, newUsers }) => {
      let usersToAdd = newUsers.map((user) => ({
        cognitoUserId: user?.cognitoUserId || "",
        identityId: user?.identityId || "",
        nameOfUser: user?.nameOfUser || "",
        userName: user?.userName || "",
        groupName: user?.groupName || "",
        googleDriveFileId: user?.googleDriveFileId || "",
      }));
      if (!group) {
        teamsConfiguration[teamIndex].members = [
          ...team.members,
          ...usersToAdd,
        ];
        promises.push({ teamsConfiguration, recordId, topic });
      } else {
        const allMembers = uniqBy(
          [...teamsConfiguration?.flatMap((t) => t?.members), ...usersToAdd],
          "identityId"
        );
        const teamIds =
          teamsConfiguration?.flatMap((t) => t?.teamId)?.filter(Boolean) || [];
        const newTeam = [
          {
            value: "Team",
            teamId: teamIds,
            members: allMembers,
          },
        ];
        promises.push({ teamsConfiguration: newTeam, recordId, topic });
      }
    }
  );
}

//region Get Drive Promises
function getGoogleDrivePromises(recordsToUpdate, topic, promises) {
  const driveFolderKeys = {
    leads: "leadObject",
    opportunities: "oppObject",
    estimations: "estimationObject",
    permitDrawings: "permitDrawings",
    scheduling: "scheduleObject",
    projects: "projectId",
    accounts: "clientObject",
  };
  recordsToUpdate?.forEach(({ newUsers, googleDriveFolderIds }) => {
    newUsers.forEach((user) => {
      const folderId = findProp(
        googleDriveFolderIds,
        driveFolderKeys[topic]
      )[0];
      promises.push({ folderId, userName: user.userName });
    });
  });
}

//region Wait
async function wait() {
  return new Promise((resolve, reject) => {
    setTimeout(() => {
      resolve();
    }, 1500);
  });
}

//region Update Team Handler
export async function onUpdateTeamHandler(
  selected,
  setVisible,
  driveRequest,
  setWarningModalVisible,
  group,
  giveDriveAccess
) {
  let apiPromises = [];
  let googleDrivePromises = [];
  Object.keys(selected).forEach((topic) => {
    if (selected[topic].length > 0) {
      prepareApiPutData(selected[topic], topic, apiPromises, group);
      giveDriveAccess &&
        getGoogleDrivePromises(selected[topic], topic, googleDrivePromises);
    }
  });

  let promiseLength = apiPromises?.length;
  let googleDrivePromiseLength = googleDrivePromises?.length;
  message.loading({
    content: "Updating records. Please wait...",
    key: "updateRecords",
    duration: 0,
  });
  if (giveDriveAccess) {
    for (let i = 0; i < googleDrivePromiseLength; i += 20) {
      await Promise.allSettled(
        googleDrivePromises.slice(i, i + 20).map((promise) => {
          driveRequest.share(
            promise.folderId,
            "reader",
            "user",
            promise.userName,
            true
          );
        })
      ).catch((err) => {
        console.error("err", err);
        message.error({
          content: "Error updating Google Drive permissions",
          key: "updateRecords",
        });
      });
      await wait();
    }
  }
  for (let i = 0; i < promiseLength; i += 20) {
    await Promise.allSettled(
      apiPromises.slice(i, i + 20).map((promise) => {
        return API.put(promise.topic, `/${promise.topic}/${promise.recordId}`, {
          body: {
            teamsConfiguration: promise.teamsConfiguration,
          },
        });
      })
    ).catch((err) => {
      console.error("err", err);
      message.error({
        content: "Error updating records",
        key: "updateRecords",
      });
    });
    await wait();
  }
  message.success({
    content: "Records updated successfully",
    key: "updateRecords",
  });
  Swal.fire({
    title: "Success!",
    text: "Records updated successfully!",
    icon: "success",
  });
  setWarningModalVisible(false);
  setVisible(false);
}

//region Filter Records To Update
export function filterRecordsToUpdate(
  id,
  teamsConfiguration,
  label,
  newUsers,
  arr,
  selectedTeam,
  googleDriveFolderIds,
  all,
  isNewUser,
  status,
  multipleTeams
) {
  if (!teamsConfiguration) return;
  if (all) {
    const allMembers = teamsConfiguration.flatMap((t) => t.members);
    const filterNewUsers = getUniqueItems(allMembers, newUsers);
    if (filterNewUsers.length > 0) {
      arr.push({
        recordId: id,
        teamsConfiguration,
        newUsers: filterNewUsers,
        label,
        googleDriveFolderIds,
        teamId: "",
        teamIndex: "",
        status,
      });
    }
  } else {
    teamsConfiguration.forEach((team, i) => {
      if (!!isNewUser) {
        if (
          team?.value === selectedTeam?.teamName ||
          team?.teamId?.includes(selectedTeam.teamId)
        ) {
          arr.push({
            teamIndex: i,
            recordId: id,
            teamsConfiguration,
            team,
            newUsers: [],
            teamId: team.value,
            label: label,
            status,
          });
        }
      } else if (multipleTeams) {
        if (
          selectedTeam?.some((tId) => teamsConfiguration?.teamId?.includes(tId))
        ) {
          let allTM = teamsConfiguration.flatMap((t) => t.members);
          const filterNewUsers = getUniqueItems(allTM, newUsers);
          if (filterNewUsers.length > 0) {
            arr.push({
              teamIndex: i,
              recordId: id,
              teamsConfiguration,
              team,
              newUsers: filterNewUsers,
              teamId: id,
              label: label,
              googleDriveFolderIds,
              status,
            });
          }
        }
      } else if (!!team?.teamId) {
        //? If you want to update all teams comparing by teamName (!team.teamId instead of !!team.teamId)
        //   if (team.value === selectedTeam.teamName) {
        //     let allTM = teamsConfiguration.flatMap((t) => t.members);
        //     const filterNewUsers = getUniqueItems(allTM, newUsers);
        //     if (filterNewUsers.length > 0) {
        //       arr.push({
        //         teamIndex: i,
        //         recordId: id,
        //         teamsConfiguration,
        //         team,
        //         newUsers: filterNewUsers,
        //         teamId: team.value,
        //         label: label,
        //       });
        //     }
        //   }
        // } else {
        team.teamId.forEach((teamId) => {
          if (teamId === selectedTeam.teamId) {
            let allTM = teamsConfiguration.flatMap((t) => t.members);
            const filterNewUsers = getUniqueItems(allTM, newUsers);
            if (filterNewUsers.length > 0) {
              arr.push({
                teamIndex: i,
                recordId: id,
                teamsConfiguration,
                team,
                newUsers: filterNewUsers,
                teamId,
                label: label,
                googleDriveFolderIds,
                status,
              });
            }
          }
        });
      }
    });
  }
}

//region Get Fields To Update
export async function getFieldsToUpdate(
  newUsers = [],
  selectedTeam,
  checkAll = false,
  isNewUser = false
) {
  let leadsToUpdate = [];
  let opportunitiesToUpdate = [];
  let estimationsToUpdate = [];
  let permitDrawingsToUpdate = [];
  let schedulingToUpdate = [];
  let projectsToUpdate = [];
  let accountsToUpdate = [];
  await Promise.allSettled([
    lazyFetch({
      tableName: "accounts",
      listOfKeys: [
        "accountId",
        "teamsConfiguration",
        "accountName",
        "googleDriveFolderIds",
      ],
    }),
    lazyFetch({
      tableName: "leads",
      listOfKeys: [
        "leadId",
        "teamsConfiguration",
        "leadCompany",
        "googleDriveFolderIds",
        "leadStatus",
      ],
    }),
    lazyFetch({
      tableName: "opportunities",
      listOfKeys: [
        "opportunityId",
        "teamsConfiguration",
        "opportunityAddress",
        "googleDriveFolderIds",
        "opportunityStatus",
      ],
    }),
    lazyFetch({
      tableName: "estimations",
      listOfKeys: [
        "estimationId",
        "teamsConfiguration",
        "jobSiteAddress",
        "googleDriveFolderIds",
        "estSTATUS",
      ],
    }),
    lazyFetch({
      tableName: "permitDrawings",
      listOfKeys: [
        "permitId",
        "teamsConfiguration",
        "sow",
        "googleDriveFolderIds",
        "jobSiteAddress",
        "permitStatus",
      ],
    }),
    lazyFetch({
      tableName: "scheduling",
      listOfKeys: [
        "scheduleId",
        "teamsConfiguration",
        "scheduleAddress",
        "googleDriveFolderIds",
        "scheduleStatus",
      ],
    }),
    lazyFetch({
      tableName: "projects",
      listOfKeys: [
        "projectId",
        "teamsConfiguration",
        "projectName",
        "googleDriveFolderIds",
        "projectStatus",
      ],
    }),
  ])
    .then(
      ([
        { value: accounts },
        { value: leads },
        { value: opportunities },
        { value: estimations },
        { value: permitDrawings },
        { value: scheduling },
        { value: projects },
      ]) => {
        if (!!accounts && accounts.length > 0) {
          accounts.forEach(
            ({
              accountId,
              teamsConfiguration,
              accountName,
              googleDriveFolderIds,
            }) => {
              filterRecordsToUpdate(
                accountId,
                teamsConfiguration,
                accountName,
                newUsers,
                accountsToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                ""
              );
            }
          );
        }
        if (!!leads && leads.length > 0) {
          leads.forEach(
            ({
              leadId,
              teamsConfiguration,
              leadCompany,
              googleDriveFolderIds,
              leadStatus,
            }) => {
              filterRecordsToUpdate(
                leadId,
                teamsConfiguration,
                leadCompany,
                newUsers,
                leadsToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                leadStatus
              );
            }
          );
        }
        if (!!opportunities && opportunities.length > 0) {
          opportunities.forEach(
            ({
              opportunityId,
              teamsConfiguration,
              opportunityAddress,
              googleDriveFolderIds,
              opportunityStatus,
            }) => {
              filterRecordsToUpdate(
                opportunityId,
                teamsConfiguration,
                opportunityAddress,
                newUsers,
                opportunitiesToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                opportunityStatus
              );
            }
          );
        }
        if (!!estimations && estimations.length > 0) {
          estimations.forEach(
            ({
              estimationId,
              teamsConfiguration,
              jobSiteAddress,
              googleDriveFolderIds,
              estSTATUS,
            }) => {
              filterRecordsToUpdate(
                estimationId,
                teamsConfiguration,
                jobSiteAddress,
                newUsers,
                estimationsToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                estSTATUS
              );
            }
          );
        }
        if (!!permitDrawings && permitDrawings.length > 0) {
          permitDrawings.forEach(
            ({
              permitId,
              teamsConfiguration,
              jobSiteAddress,
              sow,
              googleDriveFolderIds,
              permitStatus,
            }) => {
              filterRecordsToUpdate(
                permitId,
                teamsConfiguration,
                jobSiteAddress + "-" + sow,
                newUsers,
                permitDrawingsToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                permitStatus
              );
            }
          );
        }
        if (!!scheduling && scheduling.length > 0) {
          scheduling.forEach(
            ({
              scheduleId,
              teamsConfiguration,
              scheduleAddress,
              googleDriveFolderIds,
              scheduleStatus,
            }) => {
              filterRecordsToUpdate(
                scheduleId,
                teamsConfiguration,
                scheduleAddress,
                newUsers,
                schedulingToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                scheduleStatus
              );
            }
          );
        }
        if (!!projects && projects.length > 0) {
          projects.forEach(
            ({
              projectId,
              teamsConfiguration,
              projectName,
              googleDriveFolderIds,
              projectStatus,
            }) => {
              filterRecordsToUpdate(
                projectId,
                teamsConfiguration,
                projectName,
                newUsers,
                projectsToUpdate,
                selectedTeam,
                googleDriveFolderIds,
                checkAll,
                isNewUser,
                projectStatus
              );
            }
          );
        }
      }
    )
    .catch((err) => {
      console.log("Error finding fields to update: ", err);
      message.error("Something went wrong finding fields to update");
    });
  let allFields = [
    { topic: "accounts", fields: accountsToUpdate },
    { topic: "opportunities", fields: opportunitiesToUpdate },
    {
      topic: "estimations",
      fields: estimationsToUpdate,
    },
    { topic: "leads", fields: leadsToUpdate },
    {
      topic: "permitDrawings",
      fields: permitDrawingsToUpdate,
    },
    {
      topic: "scheduling",
      fields: schedulingToUpdate,
    },
    {
      topic: "projects",
      fields: projectsToUpdate,
    },
  ];
  return allFields;
}

import { useEffect, useMemo, useRef, useState } from "react";
import "./ExportPreview.scss";
import { useSelector } from "react-redux";
import { Modal } from "antd";
import {
  BASE_REPORT_STYLES,
  PRINT_PAGE_CONFIGS,
} from "../../SidebarPages/Fleet/fleetsLive/data";
import { MAINTENANCE_REPORT_STYLES } from "../../SidebarPages/FleetMaintenanceView/data";
import { SAFETY_REPORT_STYLES } from "../../SidebarPages/Safety/SafetyModal/components/SafetyExportPreview/helpers";
import { generateHTMLTemplate } from "../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/utils";
import { exportDOCX } from "../../SidebarPages/Estimations/DataEntryGrid/tools/exporters";
import { XIcon } from "../../SidebarPages/Communication/assets";
import ExportPreviewFooter from "./ExportPreviewFooter";
import ExportPreviewTabs from "./ExportPreviewTabs";
import coreIcon from "./coreIcon.svg";

/**
 * @typedef {Object} tabsType
 * @property {number} label - The label of the tab.
 * @property {string} key - The key of the tab.
 * @property {JSX} component - The component of the tab.
 */

/**
 * Renders a modal component for previewing and exporting safety data.
 *
 * @component
 * @param {Object} props - The component props.
 * @param {boolean} props.open - Determines if the modal is open.
 * @param {function} props.onCancel - The callback function to handle canceling the modal.
 * @param {string} [props.modalTitle="Safety Export"] - The title of the modal.
 * @param {tabsType[]} [props.tabs=[]] - The array of tabs to display in the export configuration section.
 * @param {Array} [props.defaultHeaderSection=[]] - The array of default header sections to include in the export.
 * @param {Object} [props.customStyles={}] - The custom styles to apply to the export.
 * @param {function} [props.convertData=(data) => data] - The function to convert the initial data.
 * @param {function} [props.applyFilters=(filters, fields) => fields] - The function to apply filters to the data.
 * @param {function} [props.getInitialData=() => []] - The function to get the initial data.
 * @param {boolean} [props.asyncInitialData=false] - Determines if the initial data is fetched asynchronously.
 * @param {string} [props.documentTitle="Document"] - The title of the exported document.
 * @param {string} [props.keyName=""] - The key name to save the templates.
 * @param {boolean} [props.hasPagination=false] - Determines if the export has pagination.
 * @returns {JSX.Element} The rendered component.
 */
function ExportPreview({
  open,
  onCancel,
  modalTitle = "Safety Export",
  tabs = [],
  defaultHeaderSection = [],
  customStyles = {},
  convertData = async (data) => [],
  applyFilters = (filters, fields) => fields,
  getInitialData = () => [],
  asyncInitialData = false,
  documentTitle = "Document",
  keyName = "",
  hasPagination = false,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);

  const exportTemplateField = useMemo(
    () =>
      programFields.find(
        (el) => el?.fieldName === "Pdf Export Filter Templates"
      ),
    [programFields]
  );

  const previewRef = useRef(null);

  const [initialFetchDone, setInitialFetchDone] = useState(false);

  const [landscape, setLandscape] = useState(false);
  const [pagination, setPagination] = useState(hasPagination);
  const [pageSize, setPageSize] = useState("Letter");
  const [initialValues, setInitialValues] = useState([]);
  const [modifiedValues, setModifiedValues] = useState([]);
  const [filters, setFilters] = useState({});
  const [dataSections, setDataSections] = useState([]);
  const [templates, setTemplates] = useState([]);
  const [selectedTemplate, setSelectedTemplate] = useState("");
  //use other classnames for dynamic styles bc it will overwrite other styles
  const [dynamicStyles, setDynamicStyles] = useState({});

  const baseConfig = useMemo(() => {
    return [
      {
        image: {
          src: coreIcon,
          class: "baseLogo",
        },
        class: "headerSection export-preview-header",
      },
      ...defaultHeaderSection,
    ];
  }, []);

  const reportStyles = useMemo(() => {
    return {
      ...BASE_REPORT_STYLES,
      ...MAINTENANCE_REPORT_STYLES,
      ...SAFETY_REPORT_STYLES,
      ...customStyles,
      ...dynamicStyles,
    };
  }, [dynamicStyles]);

  const fetchDataAndSet = async () => {
    let res = [];

    if (asyncInitialData) {
      try {
        const result = await getInitialData();
        res = result;
      } catch (e) {
        res = [];
      }
    } else {
      res = getInitialData();
    }

    const convertedData = await convertData(res, pageSize);

    setDataSections([...baseConfig, ...(convertedData || [])]);
    setInitialValues(res);
    setModifiedValues(res);
    setInitialFetchDone(true);
  };

  useEffect(() => {
    fetchDataAndSet();

    if (keyName) {
      const currentTemplates = exportTemplateField?.fieldOptions?.[keyName];
      if (!!currentTemplates && Array.isArray(currentTemplates)) {
        setTemplates(currentTemplates);
        if (currentTemplates.length > 0) {
          const foundDefault = currentTemplates.find((el) => el?.isDefault);
          if (!!foundDefault) {
            setSelectedTemplate(foundDefault);
          }
        }
      }
    }
  }, []);

  useEffect(() => {
    if (!previewRef.current) {
      return;
    }

    const w = previewRef.current.contentWindow;
    if (!w) {
      return;
    }

    const html = w.document.querySelector("html");

    if (html) {
      html.innerHTML = generateHTMLTemplate({
        dataSections,
        styles: reportStyles,
        landscape,
        pageSize,
        title: documentTitle,
        pagination,
      });
    }
  }, [pageSize, landscape, dataSections, pagination, reportStyles]);

  function onPrint() {
    if (!previewRef.current) {
      return;
    }

    const w = previewRef.current.contentWindow;
    if (w) {
      if (!pagination) {
        w.print();
      } else {
        const html = w.document.querySelector("html");

        const newWindow = window.open("", "_blank");

        newWindow.document.write(html.innerHTML);

        const timeOut = setTimeout(() => {
          newWindow.print();
        }, 1000);

        newWindow.onafterprint = () => {
          clearTimeout(timeOut);
          newWindow.close();
        };
      }
    }
  }

  useEffect(() => {
    if (initialFetchDone) {
      const func = async () => {
        const newModifiedValues = applyFilters(filters, initialValues);
        setModifiedValues(newModifiedValues);
        setDataSections([
          ...baseConfig,
          ...(await convertData(newModifiedValues, pageSize)),
        ]);
      };

      func();
    }
  }, [JSON.stringify(filters), initialValues, initialFetchDone, pageSize]);

  useEffect(() => {
    if (!selectedTemplate) {
      return;
    }

    setFilters(selectedTemplate.filters);
  }, [JSON.stringify(selectedTemplate)]);

  async function resetFilters() {
    await fetchDataAndSet();
    setFilters({});
    setSelectedTemplate("");
  }

  function exportWord() {
    const w = previewRef.current.contentWindow;
    if (!w) {
      return;
    }
    const html = w.document.querySelector("html");
    const stringHtml = html.innerHTML.toString();

    const body = `<html>${stringHtml}</html>`;

    exportDOCX({
      html: body,
      customFileName: documentTitle,
      others: { orientation: landscape ? "landscape" : "portrait" },
      replacePadding: false,
    });
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        closeIcon: <XIcon />,
        title: modalTitle,
        className: `safety-export-container ${
          isDarkMode ? "safety-export-dark" : ""
        }`,
        footer: (
          <ExportPreviewFooter
            {...{
              onCancel,
              onPrint,
              resetFilters,
              filters,
              exportWord,
            }}
          />
        ),
      }}
    >
      <div className={`configuration-section ${landscape ? "landscape" : ""}`}>
        <ExportPreviewTabs
          {...{
            modifiedValues,
            filters,
            setFilters,
            initialValues,
            setInitialValues,
            tabs,

            landscape,
            setLandscape,
            setPageSize,
            pageSize,
            templates,
            setTemplates,
            keyName,
            selectedTemplate,
            setSelectedTemplate,
            pagination,
            setPagination,
            dynamicStyles,
            setDynamicStyles,
          }}
        />
      </div>
      <div
        className={`preview-section ${landscape ? "landscape" : ""}`}
        style={{
          aspectRatio: landscape
            ? PRINT_PAGE_CONFIGS[pageSize]["landscapeRatio"]
            : PRINT_PAGE_CONFIGS[pageSize]["aspectRatio"],
        }}
      >
        <iframe
          ref={previewRef}
          className="preview-frame"
          name="preview-frame"
          height={"100%"}
          width={"100%"}
        />
      </div>
    </Modal>
  );
}

export default ExportPreview;

import { AutoComplete, Form, Tooltip, message } from "antd";
import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useOutsideClick, useResponsive } from "../../../../../hooks";
import { FilePreviewModal } from "../../../Documentation/View/components";
import { DeleteIcon } from "../../../DynamicView/src";
import { MaximazeIcon, MinimizeIcon, XIcon } from "../../assets";
import EmailBoxHeaderInputs from "./components/emailbox-header-inputs-view/EmailBoxHeaderInputs";
import EmailBoxFooter from "./components/footer/EmailBoxFooter/EmailBoxFooter";
import {
  EmailBoxBodyProvider,
  useEmailBoxBody,
} from "./providers/EmailBoxBodyProvider";
import { EmailBoxProvider, useEmailBox } from "./providers/EmailBoxProvider";
import {
  EmailTemplatesProvider,
  useEmailTemplates,
} from "./providers/EmailTemplatesProvider";
import {
  InitialEmailBoxPropsProvider,
  useInitialEmailBoxProps,
} from "./providers/InitialEmailBoxProvider";
import {
  fetchSignature,
  getDraft,
  getDraftToSave,
  handleEditorContent,
  handleSetFormFields,
  onHeaderClick,
  removeDraft,
  saveAsDraft,
  saveDraftsToRecord,
} from "./utils/emailbox-helpers";

import "./EmailBox.scss";
import {
  AttachmentsProvider,
  useAttachments,
} from "./providers/AttachmentsProvider";
import CommunicationRichEditor from "../rich-editor/CommunicationRichEditor";
import { DriveDocumentsProvider } from "./providers/DriveDocumentsProvider";
import {
  FilePreviewModalProvider,
  useFilePreviewModal,
} from "./providers/FilePreviewModalProvider";
// import getRecordDetails from "./utils/getRecordDetails";
import { useNavigate } from "react-router-dom";
import { useMemo } from "react";

const EmailBox = (props) => {
  const { recordID, proppedRecordId } = props || {};
  const recordDetails = useMemo(() => {
    const pathSegments = window.location.pathname.split("/");
    const isDeviceManagement = pathSegments[2] === "deviceManagement";
    const isInspections = pathSegments[1] === "inspections";

    return {
      categoryType: isDeviceManagement ? pathSegments[2] : pathSegments[1],

      recordId:
        recordID || !proppedRecordId
          ? isInspections
            ? window.location.href.split("?id=")[1]?.split("&&")[0] || ""
            : pathSegments[2]
          : proppedRecordId,
    };
  }, [recordID, proppedRecordId]);

  return (
    <InitialEmailBoxPropsProvider {...props}>
      <EmailBoxProvider recordDetails={recordDetails}>
        <EmailBoxBodyProvider>
          <EmailTemplatesProvider {...props} recordDetails={recordDetails}>
            <AttachmentsProvider>
              <DriveDocumentsProvider>
                <FilePreviewModalProvider>
                  <NewEmailBox />
                </FilePreviewModalProvider>
              </DriveDocumentsProvider>
            </AttachmentsProvider>
          </EmailTemplatesProvider>
        </EmailBoxBodyProvider>
      </EmailBoxProvider>
    </InitialEmailBoxPropsProvider>
  );
};

const NewEmailBox = () => {
  const {
    setSignatureState,
    signature,
    draftId,
    setDraftId,
    recordDetails,
    setClickedElement,
    form,
  } = useEmailBox();

  const navigate = useNavigate();

  const { selectedFile, filePreview, setFilePreview } = useFilePreviewModal();

  const { attachments, setAttachments } = useAttachments();

  const {
    onClose,
    rowData,
    authToken,
    selectedDraft,
    isFull,
    isFromCharge,
    chargePdf,
    recordID,
    boxKey,
    authData,
    attachments: propsAttachments,
  } = useInitialEmailBoxProps();

  const { setSelectedEmailTemplate, selectedEmailTemplate } =
    useEmailTemplates();
  const { editorContent, setEditorContent } = useEmailBoxBody();
  const {
    drafts = [],
    recordData: { recordName, recordId, ...rest },
  } = useSelector((state) => state.communication);
  const dispatch = useDispatch();
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [documentsActive, setDocumentsActive] = useState(true);
  const [maximize, setMaximize] = useState(true);
  const [fullSize, setFullSize] = useState(isFull);
  const { mobile } = useResponsive();
  const [draftToSave, setDraftToSave] = useState();
  const [draftSavedMessage, setDraftSavedMessage] = useState("");

  const [
    {
      authenticatedUser: { email },
    },
  ] = useSelector(({ authenticatedUser }) => [authenticatedUser]);

  useEffect(() => {
    // in case props are passed from another route to the email box
    if (propsAttachments) {
      setAttachments((attachments) => {
        const combined = [
          ...attachments.filter(Boolean),
          ...propsAttachments.filter(Boolean),
        ];

        return Array.from(new Set(combined));
      });
    }

    if (chargePdf) {
      setAttachments((attachments) => {
        const combined = [
          ...attachments.filter(Boolean),
          ...chargePdf.filter(Boolean),
        ];

        return Array.from(new Set(combined));
      });
    }
  }, [propsAttachments, chargePdf]);

  // it fetches a draft, sets form fields, updates editor content, and manages state based on the fetched data.
  useEffect(() => {
    if (!selectedDraft) {
      handleSavedDraft();
      return;
    }

    // if there is a draft it will update the state with the draft id
    setDraftId(selectedDraft);

    getDraft(selectedDraft, authData).then((res) => {
      if (res.data) {
        const { data: draft } = res;
        const {
          template,
          body,
          draftId,
          signature,
          attachments: draftAttachments,
        } = draft;

        if (draftAttachments) {
          setAttachments((prevState) => [...prevState, ...draftAttachments]);
        }

        handleSetFormFields(form, draft);

        handleEditorContent(
          setEditorContent,
          setSelectedEmailTemplate,
          body,
          template
        );

        setDraftId(draftId);
        setSignatureState(signature);
      } else {
        message.warning("An error occurred during communication");
      }
    });
  }, [selectedDraft]);

  //get signature & undoTime when selected user changed
  useEffect(() => {
    fetchSignature(authData, setSignatureState);
  }, [authToken]);

  const handleSavedDraft = async () => {
    return await saveAsDraft(
      form,
      draftId,
      email,
      editorContent,
      selectedEmailTemplate,
      authData,
      signature,
      attachments,
      setDraftId,
      dispatch,
      drafts
    ).then(() => {
      setDraftSavedMessage("Draft saved!");

      setTimeout(() => {
        setDraftSavedMessage("");
      }, 3000);
    });
  };

  const handleDraftChanges = async () => {
    const updatedDraft = getDraftToSave(
      form,
      draftId,
      email,
      editorContent,
      selectedEmailTemplate,
      authData,
      signature,
      attachments
    );

    //if draft body has changed, save it
    if (
      !draftToSave ||
      JSON.stringify(draftToSave) !== JSON.stringify(updatedDraft)
    ) {
      setDraftToSave(updatedDraft);
      return await handleSavedDraft();
    }
  };

  const handleCloseEmailBox = () => {
    handleDraftChanges().then((newDraft) => {
      if (newDraft?.data.id) {
        saveDraftsToRecord(drafts, recordName, recordId);
      }
    });
    onClose(true);
    navigate(`${location.pathname}?tab=Communication`, { replace: true });
  };

  const outsideClickRef = useOutsideClick(handleDraftChanges);

  return (
    <div className={fullSize ? "ModalMode" : ""}>
      <div
        ref={outsideClickRef}
        {...{
          className: maximize
            ? `EmailBoxContainer ${isDarkMode && "EmailBoxContainerDark"} ${
                boxKey === 1 ? "second" : ""
              } ${maximize ? "maximize-width" : ""}`
            : `EmailBoxContainerMinimaze
            ${boxKey === 1 ? "second" : ""}
            ${isDarkMode && "EmailBoxContainerMinimazeDark"}`,
        }}
      >
        <div
          {...{
            className: "emailBoxHeader",
            onClick: (e) => {
              handleDraftChanges();

              if (!isFromCharge) {
                onHeaderClick(e, setMaximize, setFullSize);
              } else {
                return null;
              }
            },
          }}
        >
          <div className="titleContainer">
            <span {...{ className: "emailBoxHeaderTitle" }}>
              {draftSavedMessage ||
                form.getFieldValue("subject") ||
                "New Message"}
            </span>
          </div>

          <div>
            {!isFromCharge && (
              <span
                {...{
                  onClick: (e) => {
                    e.stopPropagation();
                    setFullSize(fullSize ? false : true);
                    setMaximize(true);
                  },
                  className: "emailBoxHeaderMaximaze",
                }}
              >
                {!mobile ? (
                  fullSize ? (
                    <MinimizeIcon />
                  ) : (
                    <MaximazeIcon />
                  )
                ) : null}
              </span>
            )}
            <span
              onClick={(e) => {
                e.stopPropagation();
                handleCloseEmailBox();
              }}
              className="emailBoxHeaderClose"
            >
              <XIcon />
            </span>
          </div>
        </div>

        <div
          {...{
            className: "emailBoxContent",
          }}
        >
          <Form {...{ form }}>
            <EmailBoxHeaderInputs
              setClickedElement={setClickedElement}
              form={form}
              rowData={rowData}
            />

            <div className="subject-container">
              <Form.Item {...{ name: "subject" }}>
                <AutoComplete
                  {...{
                    className: "",
                    placeholder: "Subject",
                    onClick: () => setClickedElement(document.activeElement),
                  }}
                />
              </Form.Item>
            </div>

            <CommunicationRichEditor />

            <div {...{ className: "emailBoxFooter" }}>
              <div {...{ className: "footerLeftPart" }}>
                <EmailBoxFooter
                  rowData={rowData}
                  recordDetails={recordDetails}
                />
              </div>

              <div {...{ className: "footerRightPart" }}>
                {!mobile && attachments?.length !== 0 && (
                  <span
                    {...{
                      onClick: () => setDocumentsActive(!documentsActive),
                      className: "documentsStyle",
                    }}
                  >{`${attachments?.length} document${
                    attachments?.length === 1 ? "" : "s"
                  }`}</span>
                )}
                {draftId && (
                  <Tooltip {...{ title: "Remove draft" }}>
                    <span
                      {...{
                        className: "removeIcon",
                        onClick: () =>
                          removeDraft(
                            draftId,
                            authData,
                            rowData,
                            recordDetails,
                            recordID,
                            dispatch,
                            onClose
                          ),
                      }}
                    >
                      <DeleteIcon />
                    </span>
                  </Tooltip>
                )}
              </div>
            </div>
          </Form>
        </div>
      </div>

      {filePreview && (
        <FilePreviewModal
          {...{
            visible: filePreview,
            setVisible: () => setFilePreview(false),
            file: selectedFile,
            allowEmail: false,
          }}
        />
      )}
    </div>
  );
};
export default EmailBox;

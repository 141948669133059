import { getParams } from "../../../../ChartButton/utils/getParams";

export const chartConfigs = [
  {
    chartName: "documentType",
    groupKey: "docType",
    xAxisName: "Type",
  },
  {
    chartName: "docStatus",
    groupKey: "docStatus",
    xAxisName: "Status",
  },
  {
    chartName: "category",
    groupKey: "categoryName",
    xAxisName: "Category",
  },
  {
    chartName: "createdBy",
    groupKey: "createdBy",
    xAxisName: "Documents",
  },
];

export const getChartingOptions = (data) => {
  let documentType = new Set();
  let docStatus = new Set();
  let category = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const dType = item.docType || "No Data";
    const oStatus = item.docStatus || "No Data";
    const cat = item.categoryName || "No Data";
    const user = item.createdBy || "No Data";

    documentType.add(dType);
    docStatus.add(oStatus);
    category.add(cat);
    createdBy.add(user);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total Documents count based on Type",
      dataKey: "docType",
      params: getParams({
        data,
        chartData: documentType,
        chartKey: "docType",
        label: "Type",
        text: "Total Documents count based on Type",
      }),
    },
    {
      optionTitle: "Show total Documents count based on Status",
      dataKey: "docStatus",
      params: getParams({
        data,
        chartData: docStatus,
        chartKey: "docStatus",
        label: "Status",
        text: "Total Documents count based on Status",
      }),
    },
    {
      optionTitle: "Show total Documents count based on Category",
      dataKey: "categoryName",
      params: getParams({
        data,
        chartData: category,
        chartKey: "categoryName",
        label: "Category",
        text: "Total Documents count based on Category",
      }),
    },
    {
      optionTitle: "Show total Documents count based on Created By",
      dataKey: "createdBy",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy",
        label: "Created By",
        text: "Total Documents count based on Created By",
      }),
    },
  ];

  return chartingOptions;
};

import { Modal, message } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useState, useCallback, useContext } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import DegModalContext from "../DegModalContext";
import { teamShiftColumns } from "./teamShiftModalData";
import ShiftEditModal from "../ShiftEditModal/ShiftEditModal";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";

import "./TeamShiftsModal.scss";

function TeamShiftsModal({ open, onCancel = () => {} }) {
  const { isDarkMode, crewTeams } = useContext(DegModalContext);

  const [gridApi, setGridApi] = useState();
  const [detailsModal, setDetailsModal] = useState(false);
  const [gridSearchInput, setGridSearchInput] = useState("");

  const onGridReady = useCallback((param) => {
    setGridApi(param.api);
  }, []);

  function addShiftHandler() {
    if (gridApi) {
      let selectedTeams = gridApi.getSelectedNodes().map(({ data }) => data);
      if (!selectedTeams.length) {
        message.warning("Please select a team to add shifts");
        return;
      }
      setDetailsModal(
        selectedTeams.flatMap(({ crewForeman, crewMembers }) => {
          let tmpArray = [];
          if (crewForeman?.crewId) {
            // const foreman = crews.find(({crewId}) => crewId === crewForeman?.crewId);
            // if (!!foreman?.employeeId) {
            //   tmpArray?.push(foreman?.employeeId);
            // }
            tmpArray?.push(crewForeman?.crewId);
          }
          for (const member of crewMembers) {
            const { crewId, employeeId } = member;
            if (!!crewId) {
              tmpArray.push(crewId);
            }
          }
          return tmpArray;
        })
      );
    }
  }

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setGridSearchInput(e);
  }

  return (
    <Modal
      {...{
        open,
        centered: true,
        onCancel: () => {
          onCancel();
          updateDocumentTitle(); // reset document title to "Lead Manager";
        },
        closeIcon: <XIcon />,
        wrapClassName: "teamShiftModalWrap",
        ["data-testid"]: "team-shift-modal",
        title: "Add Shifts For Teams",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "Add Shifts For Teams" });
        },
        className: `teamShiftModal ${isDarkMode && "teamShiftModalDark"}`,
        footer: (
          <MondayButton Icon={<AddIcon />} onClick={addShiftHandler}>
            Add Shift
          </MondayButton>
        ),
      }}
    >
      <InputComponent
        showSearch
        type="input"
        value={gridSearchInput}
        placeholder="Search Team..."
        style={{ maxWidth: 300 }}
        prefix={<SearchOutlined />}
        onChange={(e) => onFilterTextChange(e?.target?.value)}
      />
      <div
        className={`teamsGridContainer ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            pagination: true,
            rowData: crewTeams,
            paginationPageSize: 8,
            rowSelection: "multiple",
            columnDefs: teamShiftColumns,
            suppressRowClickSelection: false,
            onGridReady,
            defaultColDef: {
              flex: 1,
              filter: true,
              minWidth: 130,
              sortable: true,
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              suppressSizeToFit: true,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {detailsModal && (
        <ShiftEditModal
          {...{
            mode: "NEW",
            open: !!detailsModal,
            onCancel() {
              setDetailsModal(false);
              onCancel();
            },
            selectedMembers: detailsModal,
          }}
        />
      )}
    </Modal>
  );
}

export default TeamShiftsModal;

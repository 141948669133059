import { useMemo } from "react";
import { cellValueChanged } from "./DetailsAgGrid/DetailsGridUtils/cellValueChanger";
import { useStateContext } from "../../../../../../../../../../Context/Context";
import { cellEditingStarted } from "../../rentalDetailsFunctions";
import { defaultColDef } from "./DetailsAgGrid/defaultColumnDef";
import { columnDefs } from "./DetailsAgGrid/columnDefs";
import { RentalAgGrid } from "../../../../../..";
import { useSelector } from "react-redux";
import { cloneDeep } from "lodash";
import { useRedux } from "../../../../../../../../../../../../../../hooks";
import "./WeekTable.scss";
import { forceToNumber } from "../../../../../../../../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { formatCurrency } from "../../../../../../../../../../../../../../utils";

const WeekTable = ({ elevationData, allRow, setSelectedWeek }) => {
  // const { isWritable, unchangingFakeRentals, darkMode, selectedService } = useStateContext()

  const [isWritable] = useRedux("rentalsIsWritable");
  const [unchangingFakeRentals, setUnchangingFakeRentals] = useRedux(
    "unchangingFakeRentals"
  );
  const [darkMode] = useRedux("rentalsDarkMode");
  const [selectedService] = useRedux("rentalsSelectedService");

  const { isLoading } = useSelector((state) => state.rentals);

  const rowSpan = (params) => {
    var monthSpanning = params?.data?.rowSpan;
    // if rowspan key is true find the length that we are spanning else return 1
    return !!monthSpanning
      ? rowData?.filter(({ month }) => month === params?.data?.month)?.length
      : 1;
  };

  const selectedPli = useMemo(() => {
    let myPLI = unchangingFakeRentals?.services
      ?.find(({ label }) => label === selectedService)
      ?.serviceOptions?.find(
        (lineItemsData) =>
          lineItemsData?.elevationId === elevationData?.elevationId
      )
      ?.items?.find((itemsData) => itemsData?.id === allRow?.id);
    let myWeek = myPLI?.rentalDetails?.details;
    myWeek.forEach((week) => {
      let weekAmount = week?.days?.reduce((a, b) => {
        return a + forceToNumber(b?.dailyAmount);
      }, 0);
      week.currentWeeklyAmount = `${formatCurrency(
        weekAmount || 0
      )} out of ${formatCurrency(week?.weeklyAmount)}`;
      return week;
    });

    return myPLI;
  }, [unchangingFakeRentals?.services, selectedService, elevationData, allRow]);

  const initialRowData = selectedPli?.rentalDetails?.details || [];

  const rowData = cloneDeep(initialRowData);
  console.log("rowData", rowData);

  return (
    <div className="weekTableContainer">
      <RentalAgGrid
        {...{
          darkMode,
          onCellEditingStarted: (params) =>
            cellEditingStarted({ params, isLoading, isWritable }),
          onCellValueChanged: (params) => {
            cellValueChanged({
              selectedElevation: elevationData,
              selectedItem: selectedPli,
              setUnchangingFakeRentals,
              unchangingFakeRentals,
              selectedService,
              initialRowData,
              params,
            });
          },
          columnDefs: columnDefs({
            selectedElevation: elevationData,
            selectedItem: allRow,
            setSelectedWeek,
            rowSpan,
          }),
          rowData,
          defaultColDef,
          suppressRowTransform: true,
        }}
      />
    </div>
  );
};

export default WeekTable;

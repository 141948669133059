import { useState } from "react";
import { useEffect } from "react";
import { RenderDynamicComponents } from "../../../Components";
import { fieldsJSON } from "./dobStepData";
import StepHeader from "../StepHeader/StepHeader";
import { Button, message } from "antd";
import "./DobStep.scss";
import { ViolationsTable } from "./components";
import { SearchOutlined } from "@ant-design/icons";
import { openInNewTab } from "../../../../../../utils";
import isEqual from "lodash/isEqual";
import { LoadableComp } from "../../../../../SidebarPages/XComponents";
import { LoadingDiamonds, MondayButton } from "../../../../../commonComponents";
import { validateForm } from "../../../../../SidebarPages/Fleet/utils";
import { DobInfoModal } from "../../../OpportunityModal/components";
import Swal from "sweetalert2";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { TransferIcon } from "../../../../../../assets";
import { InfoIconWhite } from "../../../Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

const DobStep = ({
  index,
  form,
  completedSteps = {},
  projectAddressInfo = {},
  altAddressesInfo = [],
  dobViolations = [],
  setDobViolations,
  setValidateStepMethod,
  projectAddress,
  setProjectAddressInfo,
}) => {
  const [loading, setLoading] = useState(false);
  const [rowData, setRowData] = useState([]);
  const [dobInfoModalVisible, setDobInfoModalVisible] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);
  const [binOptions, setBinOptions] = useState([]);
  const [dobInfo, setDobInfo] = useState({});

  const populatedFields = fieldsJSON(binOptions);

  const onAddViolations = () => {
    setDobViolations(selectedRows);
    message.success("Successfully added violations!");
  };

  const verifyViolations = () => {
    const binNr = form.getFieldValue("binNumber");
    binNr.forEach((bin) =>
      openInNewTab(
        `http://a810-bisweb.nyc.gov/bisweb/PropertyProfileOverviewServlet?bin=${bin}`
      )
    );
  };

  const validateDobStep = async (onSuccessFunc) =>
    validateForm(form, onSuccessFunc);

  const searchWithBin = async (bins = []) => {
    if (!bins.length) {
      setLoading(false);
      return;
    }

    const initQuery = `https://data.cityofnewyork.us/resource/3h2n-5cm9.json?$query=SELECT * WHERE`;

    const searchQuery = bins.reduce((acc, curr, idx) => {
      const param = ` bin='${curr}' OR`;
      return idx === bins.length - 1
        ? acc + param.replace(" OR", "")
        : acc + param;
    }, initQuery);

    const results = await (await fetch(searchQuery)).json();

    const { house_number, street } = results[0] || {};
    !!house_number &&
      !!street &&
      form.setFieldsValue({ dobAddress: `${house_number} ${street}` });

    setRowData(results);
    setLoading(false);
  };

  useEffect(() => {
    !completedSteps[index] && setValidateStepMethod(validateDobStep);
    setSelectedRows(dobViolations);
  }, []);

  //fetches bin nr of the project address and alternative addresses
  useEffect(() => {
    (async () => {
      const bins = (
        await Promise.all(
          [projectAddressInfo, ...altAddressesInfo].map(
            async ({ address_components: altAddDetails }) => {
              const { street_number, route = "" } = altAddDetails?.reduce(
                (acc, item) => {
                  const { long_name = "", types = [] } = item || {};
                  return {
                    ...acc,
                    [types[0]]: long_name,
                  };
                },
                {}
              );

              return (
                await (
                  await fetch(
                    `https://data.cityofnewyork.us/resource/ipu4-2q9a.json?house__=${street_number}&street_name=${route.toUpperCase()}`
                  )
                ).json()
              )?.[0]?.bin__;
            }
          )
        )
      ).filter(Boolean);
      if (bins.length === 0) {
        Swal.fire({
          title: "No dob information was found!",
          icon: "warning",
          showConfirmButton: false,
          timer: 2000,
        });
      }
      form.setFieldValue("binNumber", bins);
      searchWithBin(bins);
      setBinOptions(bins || []);
    })();
  }, [altAddressesInfo]);

  useEffect(() => {
    const { binNumber = [] } = form.getFieldsValue();
    if (!!dobInfo?.binNumber) {
      if (!binNumber.includes(dobInfo.binNumber)) {
        form.setFieldValue("binNumber", [...binNumber, dobInfo.binNumber]);
      }
      searchWithBin([...new Set([...binOptions, dobInfo.binNumber])]);
      setBinOptions((prev) => [...new Set([...prev, dobInfo.binNumber])]);
    }
  }, [dobInfo]);

  return (
    <LoadableComp {...{ loading, LoaderComp: LoadingDiamonds }}>
      <div className="dobStepContainer">
        <StepHeader
          title="NYC Department of Buildings (DOB)"
          subtitle="Make sure you have the Building Identification Number (BIN)"
        />
        <div className="controlPanel">
          <div className="inputsContainer">
            {RenderDynamicComponents(populatedFields)}
          </div>
          <div className="buttonsContainer">
            <MondayButton
              {...{
                className: "mondayButtonBlue dobInfoButton",
                onClick: () => setDobInfoModalVisible(true),
                Icon: <InfoIconWhite />,
              }}
            >
              DOB Information
            </MondayButton>
            <MondayButton
              className="mondayButtonBlue"
              Icon={<SearchOutlined />}
              onClick={() => searchWithBin(form.getFieldValue("binNumber"))}
            >
              Search
            </MondayButton>
            <MondayButton
              className="verifyButton"
              onClick={verifyViolations}
              Icon={<TickIcon />}
            >
              Verify
            </MondayButton>
            {!!selectedRows.length && !isEqual(selectedRows, dobViolations) && (
              <MondayButton onClick={onAddViolations} Icon={<TransferIcon />}>
                Pair Violations
              </MondayButton>
            )}
          </div>
        </div>

        <ViolationsTable {...{ rowData, dobViolations, setSelectedRows }} />
      </div>
      <DobInfoModal
        {...{
          visible: dobInfoModalVisible,
          setVisible: setDobInfoModalVisible,
          setDobInformation: setDobInfo,
          address: projectAddress,
        }}
      />
    </LoadableComp>
  );
};
export default DobStep;

import { message } from "antd";

async function onPickerSuccessAdvanced({
  driveRequest = {},
  docs = [],
  includeSize = false,
  maxFileSize = null, // size in MB
}) {
  if (!includeSize && maxFileSize === null) {
    return docs;
  }
  try {
    let toReturnFiles = docs;
    const { deleteDriveItem, getFileSize } = driveRequest;

    if (includeSize || !!maxFileSize) {
      toReturnFiles = await Promise.all(
        toReturnFiles.map(async (doc) => {
          const size = await getFileSize(doc.id);
          return { ...doc, size: Number(size) };
        })
      );
    }

    if (!!maxFileSize) {
      const maxFileSizeBytes = maxFileSize * 1024 * 1024;
      const sizeExcluded = toReturnFiles.filter(
        (doc) => doc?.size > maxFileSizeBytes
      );

      if (sizeExcluded.length) {
        toReturnFiles = toReturnFiles.filter(
          (doc) => doc.size <= maxFileSizeBytes
        );

        message.error({
          content: `Files larger than ${maxFileSize} MB are not allowed.`,
          duration: 5,
        });

        await Promise.all(
          sizeExcluded.map(async (doc) => {
            await deleteDriveItem(doc.id);
          })
        );
      }
    }

    return Array.isArray(toReturnFiles) ? toReturnFiles : [];
  } catch (error) {
    console.error("Error:", error);
    return docs;
  }
}

export default onPickerSuccessAdvanced;

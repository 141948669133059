import { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { Image, message, Modal } from "antd";
import Canvas from "../../../../Documentation/View/components/DocumentationModal/components/DocUploader/CanvasDrawImage/Canvas";
import "./SafetyVisualization.scss";
import { TrashIcon, XIcon } from "../../../../Communication/assets";
import "src/components/SidebarPages/Documentation/View/components/DocumentationModal/components/DocUploader/DrawOnPhotoModal/DrawOnPhotoModal.scss";

function SafetyVisualization({
  safetyCategory = "Property Damage",
  setVisualizationImages = () => {},
  setChanges = () => {},
  disabledAllFields = false,
  rowObject = {},
  divClickable = false,
  onContinueToUpload = () => {},
  onDelete = () => {},
  visualizationImages = [],
}) {
  const { programFields } = useSelector((state) => state.programFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const driveRequest = driveApi({ accessToken });
  const { getImageSrc } = driveRequest;

  const [images, setImages] = useState([]);
  const [hiddenImages, setHiddenImages] = useState([]);
  const [openImage, setOpenImage] = useState(false);

  const savedVisualizationImages = useMemo(
    () =>
      programFields?.find((item) => item?.fieldName === "Safety Dynamic Fields")
        ?.fieldOptions?.damageVisualization?.[safetyCategory] || []
  );

  const oldImages =
    rowObject?.safetyApplicationObject?.visualizationImages || [];

  async function fetchImages(imagesToGet, imagesIdsToShow) {
    await Promise.all(
      imagesToGet.map(async (image) => {
        const imageSrc = await getImageSrc(image.id);
        return { ...image, ...imageSrc };
      })
    )
      .then((images) => {
        const newImages = images.map((el) => {
          if (!el?.parentId) {
            return {
              ...el,
              original: true,
            };
          } else {
            return el;
          }
        });
        const toShow = newImages.filter((image) =>
          imagesIdsToShow.includes(image.id)
        );
        const hidden = newImages.filter(
          (image) => !imagesIdsToShow.includes(image.id)
        );
        setImages(toShow);
        setHiddenImages(hidden);
      })
      .catch((error) => {
        console.log("error", error);
        message.error("Error fetching visualization images");
      });
  }
  useEffect(() => {
    if (
      !!accessToken &&
      (!!savedVisualizationImages?.length || oldImages?.length)
    ) {
      const oldNotInSettings =
        oldImages
          .filter(
            (item) =>
              !savedVisualizationImages.find((el) => el.id === item.parentId)
          )
          .map((e) => e.id) || [];
      const connectImages =
        savedVisualizationImages
          .map((image) => {
            const replaceImage = oldImages.find(
              (item) => item.parentId === image.id
            );
            return replaceImage ? replaceImage : image;
          })
          .map((e) => e.id) || [];
      fetchImages(
        [...oldImages, ...savedVisualizationImages],
        [...oldNotInSettings, ...connectImages]
      );
    }
  }, [accessToken, savedVisualizationImages]);

  function onDrawSave({ base64, image }) {
    setChanges(true);
    setVisualizationImages((prev) => {
      const oldIndx = prev.findIndex((item) => item.parentId === image.id);
      if (oldIndx > -1) {
        prev[oldIndx].src = base64;
        return [...prev];
      } else {
        return [
          ...prev,
          { ...image, parentId: image?.parentId || image?.id, src: base64 },
        ];
      }
    });
    setImages((prev) => {
      const oldIndx = prev.findIndex((item) => item.id === image.id);
      if (oldIndx > -1) {
        prev[oldIndx].src = base64;
        if (!image.parentId) {
          prev[oldIndx].parentId = image.id;
        }
        return [...prev];
      } else {
        return [...prev, { ...image, src: base64 }];
      }
    });
  }

  return (
    <div className="safetyVisualization">
      {images.map((image, i) => {
        const { id, src, parentId = "", original = false } = image;
        return (
          <div className={`uploadedImage`}>
            {parentId && (
              <div
                className="imageInfoDelete"
                onClick={() => {
                  setChanges(true);
                  if (!original) {
                    onDelete(id);
                  }
                  if (
                    visualizationImages.some(
                      (item) => item.parentId === parentId
                    )
                  ) {
                    const toReturn = visualizationImages.filter(
                      (item) => item.parentId !== parentId
                    );
                    setVisualizationImages(toReturn);
                  }
                  setImages((prev) => {
                    const tmp = [...prev];
                    tmp.splice(i, 1);
                    if (
                      hiddenImages?.length &&
                      hiddenImages.some((e) => e.id === parentId)
                    ) {
                      const toPush = hiddenImages.find(
                        (e) => e.id === parentId
                      );
                      tmp.push({ ...toPush });
                    }
                    return tmp;
                  });
                }}
              >
                <TrashIcon />
              </div>
            )}
            <Image
              key={id + i}
              {...{
                src: src,
                width: 250,
                height: 350,
                className: `uploadedImage`,
                onClick: () => {
                  if (disabledAllFields) return;
                  if (divClickable) {
                    onContinueToUpload(true, () => {
                      setOpenImage(image);
                    });
                  } else {
                    setOpenImage(image);
                  }
                },
                preview: false,
              }}
            />
          </div>
        );
      })}
      {openImage && (
        <SafetyCanvas
          image={openImage}
          onCancel={() => {
            setOpenImage(false);
          }}
          onSave={(e) => {
            onDrawSave(e);
            setOpenImage(false);
          }}
        />
      )}
    </div>
  );
}

export default SafetyVisualization;

function SafetyCanvas({ image, onSave = () => {}, onCancel }) {
  const { src, height, width, name, id } = image;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [loading, setLoading] = useState(false);
  const [canvasSize, setCanvasSize] = useState({ width: "", height: "" });

  useEffect(() => {
    setLoading(true);
    let timer = null;
    const deviceHeight = window.innerHeight - 200;
    const deviceWidth = window.innerWidth - 100;
    if (height && width) {
      setCanvasSize({
        width: width > deviceWidth ? deviceWidth : width,
        height: height > deviceHeight ? deviceHeight : height,
      });
      timer = setTimeout(() => {
        setLoading(false);
      }, 50);
    } else {
      timer = setTimeout(() => {
        setLoading(false);
      }, 50);
    }
    return () => clearTimeout(timer);
  }, []);

  return (
    <Modal
      {...{
        open: !!image,
        onCancel,
        title: name,
        centered: true,
        closeIcon: <XIcon />,
        destroyOnClose: true,
        footer: null,
        maskClosable: false,
        className: `DrawOnPhotoModal ${isDarkMode && "DrawOnPhotoModalDark"} `,
      }}
    >
      <div className="filePreviewCarousel">
        {loading ? null : (
          <Canvas
            {...{
              initialImageSrc: src,
              width: canvasSize?.width || 300,
              height: canvasSize?.height || 300,
              markerColor: "#FF5733",
              onChange: (e) => {},
              onSave: (e) => onSave({ base64: e, image }),
            }}
          />
        )}
      </div>
    </Modal>
  );
}

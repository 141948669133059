import { getLiveColor } from "../../../../utils";
import { formatNumber } from "src/components/SidebarPages/utils";

function DistancesTable({
  distanceArr = [],
  renderDirections = () => {},
  onClose = () => {},
}) {
  return (
    <div
      className="vehicle-distances-container"
      data-testid="vehicles-distance-table"
    >
      <div className="distance-container-header">
        <b>Vehicle</b>
        <b>Distance</b>
        <b>Status</b>
        <div
          className="distances-info-close"
          onClick={() => {
            onClose();
          }}
        >
          x
        </div>
      </div>
      {distanceArr?.map(
        (
          { fleetName, distanceInMiles, locationStatus, vehiclePosition },
          key
        ) => {
          return (
            <div
              className="vehicle-distance"
              key={key}
              onClick={() => {
                renderDirections(vehiclePosition);
              }}
            >
              <div className="vehicle-name">{fleetName}</div>
              <div className="vehicle-distance-number">
                {formatNumber(distanceInMiles, { unit: "mile" })}
              </div>
              <div className="vehicle-status">
                <span style={{ color: getLiveColor(locationStatus) }}>
                  {locationStatus}
                </span>
                <div
                  className="status-dot"
                  style={{
                    background: getLiveColor(locationStatus),
                  }}
                ></div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
}

export default DistancesTable;

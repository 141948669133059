export const formatAutomationResponses = (logs = []) => {
  return logs
    .map(
      ({
        topic,
        recordName,
        updatedAt,
        label,
        currentData: { status, toUsers = [] },
      }) => ({
        automationName: recordName,
        responseDate: updatedAt,
        toUsers,
        error: status,
        responseTitle: topic,
        description: label,
      })
    )
    .sort((a, b) => b.responseDate - a.responseDate);
};

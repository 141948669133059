import { useSelector } from "react-redux";

import { StoreType } from "../../types";
import { LeftArrow } from "src/components/SidebarPages/BasePage/src";

import "./ContainerScrollArrow.scss";

type ScrollArrowProps = {
  direction: "LEFT" | "RIGHT";
  offset?: number;
  smooth?: boolean;
  className?: string;
  dataTestid?: string;
  containerRef: React.MutableRefObject<HTMLElement>;
};

function ContainerScrollArrow(props: ScrollArrowProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const { direction, offset, smooth = true, className, containerRef } = props;

  function onClickHandler() {
    const container = containerRef.current;
    if (!container) {
      return;
    }

    let leftBy: number = 0;
    if (offset) {
      leftBy = offset;
    } else {
      leftBy = container.clientHeight / 2;
    }

    container?.scrollBy?.({
      behavior: smooth ? "smooth" : "auto",
      left: direction === "LEFT" ? leftBy * -1 : leftBy,
    });
  }

  return (
    <div
      className={`container-scroll-arrow-container ${
        isDarkMode ? "arrow-container-dark" : ""
      } ${className ?? ""} ${direction}`}
      onClick={onClickHandler}
      data-testid={direction === "LEFT" ? "left-arrow" : "right-arrow"}
    >
      <LeftArrow />
    </div>
  );
}

export default ContainerScrollArrow;

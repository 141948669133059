import { Auth } from "aws-amplify";
import { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";

import { useLoading } from "../../../../../../../../../hooks";
import { useAuth } from "../../../../../../../../../hooks/useAuth";

import {
  showSwalErrorMessage,
  showSwalSuccessMessage,
} from "../../../../../../../../../utils/swalWindowsMessages";
import { apiPut } from "../../../../../../../../../utils";
import { updateUserKeysUser } from "../../../../../../../../../actions";
import { useLocation, useNavigate } from "react-router-dom";

export const useTwoFactorAuthentication = () => {
  const [setupUri, setSetupUri] = useState(null);
  const [currentStep, setCurrentStep] = useState(0);
  const [qrErrorMessage, setQrErrorMessage] = useState(null);
  const { user, checkMFAStatus, is2FaActive } = useAuth();
  const { isLoading, startLoading, stopLoading } = useLoading();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const identityId = userConfiguration?.identityId;

  const dispatch = useDispatch();
  const navigate = useNavigate();
  const location = useLocation();

  const setUpTotpHandler = async () => {
    if (qrErrorMessage) setQrErrorMessage(null);
    try {
      startLoading();
      const totpSetupDetails = await Auth.setupTOTP(user);
      const appName = "Core Portal";
      const setupUri = `otpauth://totp/${appName}:${user.username}?secret=${totpSetupDetails}&issuer=${appName}`;
      setSetupUri(setupUri);
    } catch (error) {
      console.error("Error setting up TOTP", error);
      setQrErrorMessage("Error generating QR code.");
      throw error;
    } finally {
      stopLoading();
    }
  };

  const verifyCode = async (code) => {
    try {
      startLoading();
      await Auth.verifyTotpToken(user, code);
      await Auth.setPreferredMFA(user, "TOTP");
      await apiPut("userConfiguration", identityId, {
        is2FaEnabled: true,
      });
      dispatch(updateUserKeysUser({ identityId, is2FaEnabled: false }));
      showSwalSuccessMessage({
        text: "Success! 2FA setup was successful.",
      });
      checkMFAStatus(user);
      if (location.pathname === "/two-factor-authentication") {
        navigate("/");
      }
    } catch (error) {
      console.error("Error verifying TOTP setup", error);
      showSwalErrorMessage({
        text: error.message,
      });
      throw error;
    } finally {
      stopLoading();
    }
  };

  const disableTotp = async (code) => {
    if (code) {
      startLoading();
      try {
        await Auth.verifyTotpToken(user, code);
        await Auth.setPreferredMFA(user, "NOMFA");
        await apiPut("userConfiguration", identityId, {
          is2FaEnabled: false,
        });
        dispatch(updateUserKeysUser({ identityId, is2FaEnabled: false }));
        showSwalSuccessMessage({
          text: "Success! 2FA was disabled.",
        });
        checkMFAStatus(user);
        setCurrentStep(0);
        return true;
      } catch (error) {
        console.error("Error disabling 2FA", error);
        showSwalErrorMessage({
          text: "Error disabling 2FA. Please try again.",
        });
        throw error;
      } finally {
        stopLoading();
      }
    }
    throw new Error({ message: "Code is required." });
  };

  const handleStepChange = (direction) => {
    setCurrentStep((prev) => prev + direction);
  };

  return {
    isLoading,
    setupUri,
    currentStep,
    isEnabled: is2FaActive,
    verifyCode,
    disableTotp,
    handleStepChange,
    qrErrorMessage,
    setUpTotpHandler,
  };
};

import {
  useRef,
  Fragment,
  useState,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import {
  CircleF,
  Polygon,
  PolylineF,
  InfoWindowF,
  OverlayViewF,
  DirectionsRenderer,
} from "@react-google-maps/api";
import { message } from "antd";
import { PlayCircleFilled } from "@ant-design/icons";

import LiveMapContext from "../../LiveMapContext";
import { MARKER_ICON_URL } from "../../../../data";
import { FleetsLiveContext } from "../../../../utils";
import { getRandomColor } from "../../../../../../utils";
import { withinRadius } from "../../../../../../../pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import { DistancesTable, InfoTripDetails } from "..";
import { Marker } from "src/components/commonComponents";
import { PauseIconFilled } from "src/assets";

import "./LocationOverlay.scss";

const LocationOverlay = forwardRef((_, ref) => {
  const { locations, todayLocations } = useContext(FleetsLiveContext);
  const { mapInstance } = useContext(LiveMapContext);

  const [directions, setDirections] = useState(null);
  const [infoVisible, setInfoVisible] = useState(false);
  const [renderInstance, setRenderInstance] = useState();
  const [startEndMarkers, setStartEndMarkers] = useState([]);
  const [polyline, setPolyline] = useState(
    /** @type {google.maps.Polyline} */ (null)
  );

  const markerColor = useRef(null);

  useImperativeHandle(
    ref,
    () => {
      return {
        /**
         * @param {{
         *  address: String,
         *  position: {lat, lng},
         *  points?: {lat, lng}[]
         * }} [info]
         */
        renderInstance(info) {
          if (info && mapInstance) {
            setInfoVisible(false);
            setDirections();
            if (info?.points) {
              //draw a polygon
              const bounds = new window.google.maps.LatLngBounds();
              for (const point of info?.points) {
                const { lat, lng } = point;
                if (lat && lng) {
                  bounds.extend({ lat, lng });
                }
              }

              if (bounds) {
                mapInstance.fitBounds(bounds);
                mapInstance.setZoom(16);
              }

              setRenderInstance(info);
            } else {
              //place a marker
              mapInstance.panTo(info?.position);
              mapInstance.setZoom(16);

              markerColor.current = getRandomColor();
              setRenderInstance(info);
            }
          }
        },
        clearInstance() {
          setRenderInstance();
          setInfoVisible(false);
          setDirections();
        },
        clearDirections() {
          setDirections();
        },
      };
    },
    [mapInstance, renderInstance, polyline, todayLocations]
  );

  async function renderDirections(address, selectedLocation) {
    if (!mapInstance || !address) {
      return;
    }

    message.loading({
      content: "Loading directions...",
      key: "directionService",
    });

    const direction = new google.maps.DirectionsService();
    await direction
      .route(
        {
          origin: address,
          destination: selectedLocation || renderInstance?.position,
          travelMode: window.google.maps.TravelMode.DRIVING,
          avoidFerries: true,
          avoidHighways: true,
          avoidTolls: true,
        },
        (directionRes) => {
          message.destroy("directionService");
          setDirections(directionRes);
        }
      )
      .catch((err) => {
        message.error({
          content: "Something went wrong while getting directions",
          key: "directionService",
        });
        console.log("Error getting directions: ", err);
      });
  }

  let distanceArr = [];
  if (renderInstance?.position) {
    for (const location of locations) {
      let rad = withinRadius(location.position, renderInstance?.position);
      distanceArr.push({
        fleetName: location?.fleetName,
        fleetId: location?.fleetId,
        vehiclePosition: location?.position,
        distanceInMiles: rad?.distanceInMile,
        distanceInFeet: rad?.distanceInFeet,
        locationAddress: renderInstance?.address,
        locationPosition: renderInstance?.position,
        locationStatus: location?.locationStatus,
      });
    }
    distanceArr.sort((a, b) => a?.distanceInFeet - b?.distanceInFeet);
  }

  return (
    <OverlayViewF
      position={renderInstance ? renderInstance?.position : { lat: 0, lng: 0 }}
      mapPaneName="overlayMouseTarget"
    >
      <Fragment>
        {!!infoVisible || directions ? (
          <InfoWindowF
            {...{
              visible: infoVisible,
              position: renderInstance?.position,
              zIndex: 1001,
            }}
          >
            {!directions ? (
              <DistancesTable
                distanceArr={distanceArr}
                renderDirections={renderDirections}
                onClose={() => {
                  setInfoVisible(false);
                }}
              />
            ) : (
              <InfoTripDetails
                directions={directions}
                setDirections={setDirections}
              />
            )}
          </InfoWindowF>
        ) : (
          <></>
        )}
        <PolylineF
          {...{
            onLoad(e) {
              setPolyline(e);
            },
            options: {
              visible: true,
              strokeColor: "#9d9dff",
              strokeWeight: 4,
              strokeOpacity: 1,
            },
          }}
        />
        {renderInstance?.points?.length === 1 ? (
          <CircleF
            center={renderInstance?.points?.at(0)}
            radius={renderInstance?.radius}
            options={{
              fillColor: "#1264a3",
              fillOpacity: 0.35,
              strokeWeight: 2,
              strokeOpacity: 1,
              strokeColor: "#1264a3",
              visible: true,
            }}
            onClick={() => {
              setInfoVisible(!infoVisible);
            }}
          />
        ) : (
          <Polygon
            options={{
              editable: false,
              draggable: false,
              strokeColor: "#d99f00",
              strokeOpacity: 1,
              strokeWeight: 2,
              fillColor: "#d99f00",
              fillOpacity: 0.35,
              map: mapInstance,
            }}
            dataTestid="overlay-polygon"
            visible={!!renderInstance?.points}
            paths={renderInstance?.points || []}
            onClick={() => {
              setInfoVisible(!infoVisible);
            }}
          />
        )}
        {startEndMarkers.map((e, i) => {
          return (
            <OverlayViewF
              position={e}
              mapPaneName="overlayMouseTarget"
              key={`marker-overlay-${i}`}
            >
              <div className="live-marker">
                {i ? (
                  <span className="marker-status-icon Stopped">
                    <PauseIconFilled height={24} width={24} />
                  </span>
                ) : (
                  <span className="marker-status-icon Play">
                    <PlayCircleFilled height={24} width={24} />
                  </span>
                )}
              </div>
            </OverlayViewF>
          );
        })}
        {!renderInstance?.points && (
          <Marker
            {...{
              onClick() {
                if (!renderInstance?.points && !renderInstance?.position) {
                  return;
                }

                setInfoVisible(!infoVisible);
              },
              color: markerColor?.current,
              ...(!renderInstance?.points
                ? renderInstance?.position
                : { lat: 0, lng: 0 }),
            }}
          />
        )}
        {directions && (
          <DirectionsRenderer
            options={{
              directions,
              map: mapInstance,
              infoWindow: null,
              markerOptions: {
                icon: MARKER_ICON_URL,
              },
            }}
          />
        )}
      </Fragment>
    </OverlayViewF>
  );
});

export default LocationOverlay;

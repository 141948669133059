//?check if there are any failed records remaining to make the save button disabled
export function checkIfFailedRecords(rowData, fromDoc) {
  if (fromDoc) {
    return rowData.some(
      (record) =>
        !record?.folderId ||
        (!!record?.driveStatus?.status &&
          record?.driveStatus?.status !== "Fixed") ||
        record?.googleDriveUploads?.some((el) =>
          !el?.statusAfterUpdate
            ? el?.status === "Wrong Folder"
            : el?.statusAfterUpdate === "Failed"
        )
    );
  } else {
    return rowData.some((el) =>
      !el?.statusAfterUpdate
        ? el?.status === "Wrong Folder"
        : el?.statusAfterUpdate === "Failed"
    );
  }
}

import {
  useRef,
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
} from "react";
import dayjs from "dayjs";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import DegHeader from "../DegHeader/DegHeader";
import DegModalContext from "../DegModalContext";
import { overviewColumnDefs } from "./overviewStepData";
import FooterTotals from "../FooterTotals/FooterTotals";
import { getEmployeeAnalytics, groupEntriesInShifts } from "../utils";
import OverviewFilterModal from "../OverviewFilter/OverviewFilterModal";
import { formatCurrency } from "../../../../../../../SidebarPages/utils";
import createPDF from "../../../../../../../../integrations/AgGridToPdf";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { compareIncluding } from "../../../../../../../SidebarPages/utils";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

import "./OverviewStep.scss";

let overviewExternalFilter = {
  selectedEmployees: [],
  selectedJobsites: [],
  payrollType: [],
  employeeRole: [],
  timeFrame: undefined,
};

function OverviewStep() {
  const {
    crews,
    degName,
    jobsites,
    analytics,
    degGridApi,
    isDarkMode,
    rowData: degRows,
  } = useContext(DegModalContext);
  const { base64 } = useSelector((state) => state.base64);

  const [filterOpen, setFilterOpen] = useState(false);
  const [overviewGridApi, setOverviewGridApi] = useState();
  const [overviewColumnApi, setOverviewColumnApi] = useState();
  const [analyticsState, setAnalyticsState] = useState(analytics);
  const [rowData] = useState(
    groupEntriesInShifts({ analytics, jobsites, crews, rowData: degRows })
  );

  const timeoutRef = useRef();

  const { employeeWeekTotals } = analyticsState;

  useEffect(() => {
    return () => {
      overviewExternalFilter = {
        selectedEmployees: [],
        selectedJobsites: [],
        payrollType: [],
        employeeRole: [],
        timeFrame: undefined,
      };
    };
  }, []);

  const onGridReady = useCallback((param) => {
    setOverviewGridApi(param.api);
    setOverviewColumnApi(param.columnApi);
  }, []);

  const onFirstDataRendered = useCallback((param) => {
    param.columnApi.autoSizeAllColumns(false);
  }, []);

  const statusBar = useMemo(() => {
    return {
      statusPanels: [
        {
          align: "left",
          key: "footerTotals",
          statusPanel: FooterTotals,
        },
      ],
    };
  }, []);

  function getColumnState(event) {
    if (event?.finished) {
      const columnOrder = event.columnApi.getColumnState();
      sessionStorage.setItem("degOverviewColumns", JSON.stringify(columnOrder));
    }
  }

  const isExternalFilterPresent = useCallback(() => {
    return (
      !!overviewExternalFilter.selectedEmployees?.length ||
      !!overviewExternalFilter.selectedJobsites?.length ||
      !!overviewExternalFilter.payrollType?.length ||
      !!overviewExternalFilter.employeeRole?.length ||
      !!overviewExternalFilter.timeFrame
    );
  }, [overviewExternalFilter]);

  const doesExternalFilterPass = useCallback(
    ({ data }) => {
      let employeeCondition = true;
      let jobsiteCondition = true;
      let payrollCondition = true;
      let roleCondition = true;
      let timeCondition = true;

      if (!!overviewExternalFilter.selectedEmployees?.length) {
        employeeCondition = overviewExternalFilter.selectedEmployees?.includes(
          data?.employeeId
        );
      }

      if (!!overviewExternalFilter.selectedJobsites?.length) {
        jobsiteCondition = overviewExternalFilter.selectedJobsites.includes(
          data?.jobsiteMatch?.jobsiteId
        );
      }

      if (!!overviewExternalFilter.payrollType?.length) {
        payrollCondition = overviewExternalFilter.payrollType.includes(
          data?.payrollType
        );
      }

      if (!!overviewExternalFilter.employeeRole?.length) {
        roleCondition = overviewExternalFilter.employeeRole.includes(
          data?.employeeRole
        );
      }

      if (!!overviewExternalFilter.timeFrame) {
        let start = dayjsNY(overviewExternalFilter.timeFrame[0])
          .startOf("D")
          .valueOf();
        let end = dayjsNY(overviewExternalFilter.timeFrame[1])
          .startOf("D")
          .valueOf();
        timeCondition = data?.punchDate
          ? dayjsNY(data?.punchDate).startOf("D").valueOf() >= start &&
            dayjsNY(data?.punchDate).startOf("D").valueOf() <= end
          : data?.firstClockIn >= start && data?.firstClockIn <= end;
      }

      return (
        employeeCondition &&
        jobsiteCondition &&
        timeCondition &&
        payrollCondition &&
        roleCondition
      );
    },
    [overviewExternalFilter]
  );

  const onFilterChanged = useCallback(() => {
    let tmpRows = [];

    if (overviewGridApi) {
      overviewGridApi.forEachNode(({ data }) => {
        if (doesExternalFilterPass({ data })) {
          tmpRows.push(data);
        }
      });
    }
    let selectedEmployeesSet = new Set(
      tmpRows?.map(({ employeeId }) => employeeId)
    );
    let selectedEmployees = [];

    selectedEmployeesSet.forEach((e) => {
      selectedEmployees.push(e);
    });

    getEmployeeAnalytics({
      employeeList: selectedEmployees?.length
        ? selectedEmployees
        : crews.flatMap((emp) => (!!emp?.employeeId ? emp.employeeId : [])),
      degGridApi,
      analyticsUpdate: setAnalyticsState,
      externalFiltersPass: doesExternalFilterPass,
      degRows,
    });
  }, [overviewGridApi, overviewExternalFilter]);

  function exportOverviewToExcel() {
    overviewGridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        const data = params.node.beans.data;
        if (
          headerName === "Work Hours" ||
          headerName === "Break Hours" ||
          headerName === "Overtime Hours"
        ) {
          return `${value || 0} h`;
        } else if (headerName === "Google Sheet Link") {
          return value?.googleSheetLink;
        } else if (headerName === "Clock In" || headerName === "Clock Out") {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        } else if (headerName === "Punch Time") {
          return dayjs(value).format(DEG_TIME_FORMAT);
        } else if (headerName === "Total Reg. Amount") {
          return formatCurrency(value || 0);
        } else if (headerName === "Total OT. Amount") {
          if (
            data?.payrollType === "Open Shop" ||
            // data?.payrollType === "Prevailing Wage" ||
            data?.payrollType === "Certified Payroll"
          ) {
            return formatCurrency(0);
          } else {
            return formatCurrency(data?.otAmount || 0);
          }
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  function exportOverviewToPdf() {
    let rows = [];
    overviewGridApi.forEachNode(({ data }) => {
      rows.push(data);
    });

    return createPDF({
      action: "download",
      gridApi: overviewGridApi,
      rowData: rows,
      history: location?.pathname?.split("/")?.[1],
      historyTab: location?.pathname,
      gridColumnApi: overviewColumnApi,
      base64,
      masterDetail: true,
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: degName || "New DEG",
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  return (
    <div className="deg-step" data-testid="deg-overview-step">
      <DegHeader
        {...{
          setFilterOpen,
          exportOverviewToExcel,
          exportOverviewToPdf,
        }}
      />
      <div className="overview-data-container">
        <div className="overview-dynamic-data">
          <div
            className={`overview-grid-container ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
          >
            <AgGridReact
              {...{
                rowData,
                statusBar,
                onGridReady,
                onFilterChanged,
                pagination: true,
                animateRows: true,
                onFirstDataRendered,
                doesExternalFilterPass,
                paginationPageSize: 13,
                isExternalFilterPresent,
                rowGroupPanelShow: "always",
                onColumnMoved: getColumnState,
                columnDefs: overviewColumnDefs,
                onComponentStateChanged: ({ columnApi }) => {
                  clearTimeout(timeoutRef.current);
                  timeoutRef.current = setTimeout(() => {
                    const degColumnsState = JSON.parse(
                      sessionStorage.getItem("degOverviewColumns")
                    );
                    columnApi.applyColumnState({
                      state: degColumnsState,
                      applyOrder: true,
                    });
                    columnApi.autoSizeAllColumns();
                  }, 600);
                },
                context: {
                  employeeWeekTotals,
                },
                defaultColDef: {
                  flex: 1,
                  filter: true,
                  minWidth: 130,
                  sortable: true,
                  resizable: true,
                  enableRowGroup: true,
                  enableColResize: true,
                  suppressSizeToFit: true,
                  suppressDragLeaveHidesColumns: true,
                },
              }}
            />
          </div>
        </div>
      </div>
      <OverviewFilterModal
        {...{
          rowData,
          isDarkMode,
          open: filterOpen,
          filter: overviewExternalFilter,
          onCancel() {
            setFilterOpen(false);
          },
          onFilter(param) {
            overviewExternalFilter = { ...param };
            overviewGridApi?.onFilterChanged?.();
          },
        }}
      />
    </div>
  );
}

export default OverviewStep;

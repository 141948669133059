import {
  useRef,
  useState,
  useEffect,
  useContext,
  forwardRef,
  useCallback,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Select, message, Tooltip } from "antd";
import {
  MenuOutlined,
  BgColorsOutlined,
  BorderOuterOutlined,
} from "@ant-design/icons";

import {
  isYard,
  getFence,
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
  findBoundExtremities,
  findGeofenceCenterCoordinate,
} from "../../../../utils";
import LiveMapContext from "../../LiveMapContext";
import { YARD_LOCATIONS } from "../../../../data";
import { MAP_TYPES, MAP_THEME } from "../../../../data";
import { getCoordinatesAndZip } from "../../../../../utils";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { GPlacesAutocompleteInput } from "../../../../../components";
import { MondayButton } from "../../../../../../../commonComponents";
import { MapsIcon, CommuteIcon } from "../../../../../../../../assets";
import { DropDownArrow } from "../../../../../components/InputComponent/assets";

import "./MapController.scss";

const MapController = forwardRef((_, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { yards } = useSelector((state) => state.yards);

  const { locations = [], geofences } = useContext(FleetsLiveContext);
  const {
    mapInstance,
    toggleMapSidebar,
    sidebarFilterByVin,
    clearCustomLocation,
    renderCustomLocation,
  } = useContext(LiveMapContext);

  const [trafficLayer, setTrafficLayer] = useState();
  const vehicleFilterRef = useRef(loadLivePreference("mapVinFilters"));

  useImperativeHandle(
    ref,
    () => {
      return {
        getVehicleFilter() {
          return vehicleFilterRef?.current;
        },
      };
    },
    []
  );

  function changeStyleHandler(style) {
    if (mapInstance) {
      mapInstance.setOptions({ styles: MAP_THEME[style] });
      saveLocalPreferences({ mapStyle: style });
      localStorage.setItem("mapStyle", style);
    }
  }

  function changeTypeHandler(type) {
    if (mapInstance) {
      mapInstance.setOptions({ mapTypeId: type });
      saveLocalPreferences({ mapType: type });
      localStorage.setItem("mapType", type);
    }
  }

  function resetBounds() {
    if (locations?.length) {
      const bounds = new window.google.maps.LatLngBounds();
      let extremities = findBoundExtremities(
        locations?.map((e) => e?.position)
      );
      for (const location of extremities) {
        bounds.extend(location);
      }
      mapInstance.fitBounds(bounds);
    } else {
      mapInstance.panTo(YARD_LOCATIONS()?.[0] || NYC_CENTER);
    }
  }

  function toggleCommuteClass(add = true) {
    const el = document.getElementById("icon-commute-toggle");
    if (!el) {
      return;
    }

    if (add) {
      el.classList.remove("mondayButtonBlue");
      el.classList.add("mondayButtonGrey");
    } else {
      el.classList.remove("mondayButtonGrey");
      el.classList.add("mondayButtonBlue");
    }
  }

  const toggleTraffic = useCallback(() => {
    if (!mapInstance) {
      return;
    }

    if (!trafficLayer) {
      let layer = new google.maps.TrafficLayer({ map: mapInstance });
      setTrafficLayer(layer);
      toggleCommuteClass();
    } else {
      if (trafficLayer.getMap()) {
        trafficLayer.setMap(null);
        toggleCommuteClass(false);
      } else {
        trafficLayer.setMap(mapInstance);
        toggleCommuteClass();
      }
    }
  }, [trafficLayer, mapInstance]);

  useEffect(() => {
    let siderWidth = loadLivePreference("liveSidebar") ? 65 : 200;
    let sideWidth =
      loadLivePreference("mapSidebar") === "expanded" ? "18vw" : "0dvw";
    let tripWidth = loadLivePreference("sidebarTripLocation") ? "18vw" : "0dvw";

    let e = document.getElementById("live-map-controller");
    if (e) {
      e.style.width = `calc(100dvw - ${siderWidth}px - ${sideWidth} - ${tripWidth})`;
    }
  }, []);

  return (
    <div
      className={`live-map-controller ${
        isDarkMode ? "map-controller-dark" : ""
      }`}
      id="live-map-controller"
    >
      <div className="list-controls">
        <div
          className="map-side-toggle-icon"
          onClick={toggleMapSidebar}
          data-testid="map-toggle-sidebar"
        >
          <MenuOutlined fill={"black"} />
        </div>
        <Select
          {...{
            allowClear: true,
            popupMatchSelectWidth: false,
            onChange(e) {
              sidebarFilterByVin(e);
              saveLocalPreferences({ mapVinFilters: e });
              vehicleFilterRef.current = e;
            },
            "data-testid": "vin-filter-select",
            defaultValue: loadLivePreference("mapVinFilters"),
            popupClassName: isDarkMode ? "darkDropDown" : undefined,
            suffixIcon: <DropDownArrow fill="currentColor" />,
            mode: "multiple",
            placeholder: "Filter by vehicle...",
            showSearch: true,
            style: { outline: "none" },
            filterOption: (input, option) =>
              option?.label?.toLowerCase().includes(input.toLocaleLowerCase()),
            options: locations?.map(({ fleetName, deviceSerialNumber }) => ({
              label: fleetName,
              value: deviceSerialNumber,
            })),
          }}
        />
        <GPlacesAutocompleteInput
          {...{
            label: "",
            noFormItem: true,
            required: false,
            isCustomValue: true,
            dataTestid: "locations-search-autocomplete",
            className: "locations-input",
            popupClassName: isDarkMode ? "darkDropDown" : undefined,
            placeholder: "Search geofence or location...",
            groupedOptions: [
              {
                label: "Geofences",
                options: Object.values(geofences || {})?.map(
                  ({ name, points, radius }) => ({
                    label: name,
                    value: name,
                    points,
                    radius: (radius ?? 0) * 1609,
                  })
                ),
              },
            ],
            onClear() {
              clearCustomLocation();
            },
            async onSelect(value, option) {
              if (mapInstance) {
                let destination = value;
                if (option?.points) {
                  if (!isYard(destination)) {
                    destination = findGeofenceCenterCoordinate(option?.points);
                  } else {
                    destination =
                      yards?.find(
                        ({ address }) => getFence(address) === getFence(value)
                      )?.position || yards?.[0]?.["position"];
                  }
                  renderCustomLocation({
                    address: value,
                    points: option?.points?.map(({ latitude, longitude }) => ({
                      lat: latitude,
                      lng: longitude,
                    })),
                    position: destination,
                    radius: option?.radius,
                  });
                } else {
                  message.loading({
                    content: "Loading location...",
                    key: "locationLoad",
                  });
                  try {
                    let location = await getCoordinatesAndZip(value);

                    message.destroy("locationLoad");
                    const { coordinates: position } = location;
                    renderCustomLocation({
                      address: value,
                      position,
                    });
                  } catch (err) {
                    message.error({
                      content: "Something went wrong while loading location",
                      key: "locationLoad",
                    });
                    console.log("Error loading location: ", err);
                  }
                }
              }
            },
          }}
        />
      </div>
      <div className="map-controls">
        <Tooltip title="Resize Map">
          <MondayButton
            {...{
              Icon: <BorderOuterOutlined />,
              className: "mondayButtonBlue",
              onClick: resetBounds,
              "data-testid": "resize-map-button",
            }}
          >
            {null}
          </MondayButton>
        </Tooltip>
        <Tooltip title="Toggle Traffic">
          <MondayButton
            {...{
              Icon: <CommuteIcon height={20} width={20} fill="#fff" />,
              className: "mondayButtonBlue",
              id: "icon-commute-toggle",
              onClick: toggleTraffic,
              "data-testid": "toggle-traffic-button",
            }}
          >
            {null}
          </MondayButton>
        </Tooltip>
        <Select
          {...{
            allowClear: false,
            defaultValue: loadLivePreference("mapStyle"),
            popupMatchSelectWidth: false,
            showSearch: false,
            onSelect: changeStyleHandler,
            style: { width: 150, outline: "none" },
            popupClassName: isDarkMode ? "darkDropDown" : undefined,
            suffixIcon: (
              <BgColorsOutlined fill="currentColor" height={16} width={16} />
            ),
            "data-testid": "change-map-theme-select",
            options: Object.keys(MAP_THEME).map((key) => ({
              label: key.charAt(0).toUpperCase() + key.slice(1),
              value: key,
            })),
          }}
        />
        <Select
          {...{
            allowClear: false,
            defaultValue: loadLivePreference("mapType"),
            popupMatchSelectWidth: false,
            showSearch: false,
            onSelect: changeTypeHandler,
            style: { width: 150, outline: "none" },
            "data-testid": "change-map-type-select",
            popupClassName: isDarkMode ? "darkDropDown" : undefined,
            suffixIcon: <MapsIcon fill="currentColor" height={16} width={16} />,
            options: MAP_TYPES.map((type) => ({
              label:
                type === "hybrid"
                  ? "Satellite"
                  : type.charAt(0).toUpperCase() + type.slice(1),
              value: type,
            })),
          }}
        />
      </div>
    </div>
  );
});

export default MapController;

import { useEffect, useState } from "react";
import { DocumentationModal, DocumentationSelectorModal } from "../../..";
import { useAccessRights } from "../../../../../../../../hooks";
import {
  MondayButton,
  WithTooltip,
} from "../../../../../../../commonComponents";
import "./CardHeader.scss";
import { useLocation } from "react-router-dom";
import { PlusIcon } from "../../../../../../../../assets";
import DocWarningsModal from "../DocWarningsModal/DocWarningsModal";
import { WarningTriangle } from "../../../../../../DynamicView/src";
import { API } from "aws-amplify";
import {
  moveToCorrectFolder,
  moveToCorrectFolderSingle,
} from "../DocWarningsModal/helpers/moveToCorrectFolder";
import getDocWarnings from "../DocWarningsModal/helpers/getDocWarnings";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { apiRoutes } from "../../../../../../Fleet/utils";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";

const CardHeader = ({
  recordValue,
  categoryConfiguration,
  // getMaxByDocumentation,
  accessToken,
  recordFolderId,
  viewTitle,
  categoryName,
  recordId,
  setDocumentation,
  isEditable,
  record,
  documentation,
  filteredDocs,
  saveCardsLayout,
  layoutChanged,
}) => {
  const location = useLocation();
  const [documentationModalVisible, setDocumentationModalVisible] = useState();
  const driveRequest = driveApi({ accessToken });
  const { getParents } = driveRequest;
  const [_, writeRight] = useAccessRights({ title: "documentations" });
  const [docType, setDocType] = useState();
  const [docWarningModalVisible, setDocWarningModalVisible] = useState(false);
  const [uploadsWarnings, setUploadsWarnings] = useState([]);

  useEffect(() => {
    if (location?.state?.data) {
      setDocType(
        location?.state?.data?.docType ||
          location?.state?.data?.allGoogleDriveUploads?.[0]?.docType
      );
    }
  }, [location?.state?.data]);

  useEffect(() => {
    (async () => {
      try {
        if (!accessToken || uploadsWarnings.length > 0) return;
        const records = await getDocWarnings(
          documentation,
          getParents,
          recordFolderId
        );

        setUploadsWarnings(records);
      } catch (e) {
        console.log("Error getting documentation: ", e);
      }
    })();
  }, [documentation, accessToken]);

  const {
    docObject,
    docStatuses,
    expirationDate: configExpirationDate,
  } = categoryConfiguration?.documentationsAvailable?.find(
    ({ docName }) => docName === docType?.docType
  ) || {};

  const { folderId, docId = "" } =
    documentation.find((el) => el.docId === docType?.docId) || {};
  // getMaxByDocumentation(docType?.docType);

  const warningsCallback = (rowData) => {
    if (rowData?.some((el) => el?.statusAfterUpdate === "Fixed")) {
      setDocumentation((prev) => {
        return prev.map((doc) => {
          const found = rowData?.find((el) => el?.docId === doc?.docId);
          if (found) {
            if (!doc?.folderId && found?.folderId) {
              return { ...doc, folderId: found?.folderId };
            } else return doc;
          }
          return doc;
        });
      });
    }
  };

  async function onFixWarnings(records, fixedRecords, failedRecords) {
    let newCreatedId = {};

    const fixed = await Promise.all(
      records.map(async (record) => {
        let docTypeNewStatus = "";
        const failedUploads = [];
        try {
          const parentId = record?.folderId;
          const recordStatus = record?.driveStatus?.status;
          if (recordStatus === "Folder Missing") {
            let newId = "";

            newId = await driveRequest.getFolderIdOrCreate({
              name: record?.docType,
              parents: [recordFolderId],
            });
            if (newId) {
              newCreatedId[record?.docType] = {
                id: newId,
                from: "new",
              };
              await API.patch(
                apiRoutes.documentation,
                `/${apiRoutes.documentation}/${record?.docId}`,
                {
                  body: {
                    folderId: newCreatedId[record?.docType]?.id || null,
                  },
                }
              );
              docTypeNewStatus = "Fixed";
              await moveToCorrectFolder(
                record?.googleDriveUploads,
                newCreatedId[record?.docType]?.id,
                driveRequest,
                failedUploads
              );
            } else {
              throw new Error("Failed to create folder");
            }
          } else {
            if (recordStatus === "Wrong Folder") {
              const moveDocType = await moveToCorrectFolderSingle(
                record,
                recordFolderId,
                driveRequest
              );

              docTypeNewStatus = moveDocType;
            }
            await moveToCorrectFolder(
              record?.googleDriveUploads,
              parentId,
              driveRequest,
              failedUploads
            );
          }
          return {
            ...record,
            failedUploads,
            createdFolderId: newCreatedId[record?.docType]?.id || null,
            statusAfterUpdate: docTypeNewStatus || "Fixed",
            ...(record?.driveStatus
              ? {
                  driveStatus: {
                    status: docTypeNewStatus,
                  },
                }
              : {}),
          };
        } catch (error) {
          failedRecords.push({
            ...record,
            createdFolderId: newCreatedId[record?.docType]?.id || null,
            statusAfterUpdate: "Failed",
          });
          if (
            newCreatedId[record?.docType]?.id &&
            newCreatedId[record?.docType]?.from === "new"
          ) {
            await driveRequest
              .deleteDriveItem(newCreatedId[record?.docType]?.id)
              .catch((e) => console.error(e));
          }
          console.error(`Error processing record ${record.docId}: ${error}`, {
            record,
          });
          return null;
        }
      })
    );
    fixedRecords.push(...fixed.filter((el) => el));
  }

  return (
    <>
      <div className="documentationListCardHeader" data-testid="new-doc-button">
        <span className="documentationListCardHeaderTitle">Documentation</span>
        <div className="leftSection">
          {/* <span className="addressTitle">{recordValue}</span> */}
          {uploadsWarnings?.length > 0 && (
            <WithTooltip
              tooltipCategory="Documentations"
              tooltipKey="viewWarnings"
            >
              <div
                onClick={() => setDocWarningModalVisible(true)}
                style={{
                  display: "flex",
                  alignItems: "center",
                  gap: 5,
                  cursor: "pointer",
                }}
              >
                <WarningTriangle fill="#FE4C4A" height={15} width={17} />
                <span style={{ color: "#FE4C4A" }}>Warnings</span>
              </div>
            </WithTooltip>
          )}
          {/* <Switch
            checkedChildren="Draggable mode"
            unCheckedChildren="Edit mode"
            value={draggableMode}
            onChange={(e) => setDraggableMode(e)}
          /> */}
          <MondayButton
            onClick={saveCardsLayout}
            Icon={<TickIcon width={17} height={17} />}
            disabled={!layoutChanged}
          >
            Save Layout
          </MondayButton>
          {!!writeRight && (
            <MondayButton
              disabled={!isEditable}
              Icon={<PlusIcon />}
              className="mondayButtonGreen"
              tooltipCategory="Documentations"
              tooltipKey="onNew"
              {...{ onClick: () => setDocumentationModalVisible(true) }}
            >
              <span> New Documentation </span>
            </MondayButton>
          )}
        </div>
      </div>
      {!!docType?.docType && (
        <DocumentationModal
          {...{
            visible: !!docType?.docType,
            docType: docType?.docType,
            saveAsType: docType?.saveAsType,
            accessToken,
            recordValue,
            setVisible: () => setDocType(""),
            configExpirationDate,
            recordFolderId,
            categoryName,
            folderId,
            recordId,
            renewalId: docType?.docId,
            docObject,
            docStatuses,
            setDocumentation,
            viewTitle,
            categoryConfiguration,
            isOpenedByButton: true,
            record,
          }}
        />
      )}
      {!!documentationModalVisible && (
        <DocumentationSelectorModal
          {...{
            visible: documentationModalVisible,
            setVisible: setDocumentationModalVisible,
            setDocType,
            categoryConfiguration,
            filteredDocs,
            documentation,
          }}
        />
      )}
      {docWarningModalVisible && (
        <DocWarningsModal
          {...{
            visible: docWarningModalVisible,
            setVisible: setDocWarningModalVisible,
            docWarnings: uploadsWarnings,
            accessToken,
            setUploadsWarnings,
            setDocumentation,
            fromDoc: true,
            callbackFn: warningsCallback,
            onSave: onFixWarnings,
            proppedCorrectFolderId: recordFolderId,
          }}
        />
      )}
    </>
  );
};

export default CardHeader;

import { API } from "aws-amplify";

export const getApiMethod = async (tableName) => {
  const data = {
    queryStringParameters: {
      tableName: tableName,
    },
  };
  return await API.get("dynamicFunc", "/dynamicFunc", data);
};

export const getRecordApiMethod = async (tableName, tableKey, id) => {
  const data = {
    queryStringParameters: {
      tableName: tableName,
      tableKey: tableKey,
    },
  };
  return await API.get("dynamicFunc", `/dynamicFunc/${id}`, data);
};

export const postApiMethod = async (tableName, tableKey, body) => {
  const data = {
    body: body,
    queryStringParameters: {
      tableName: tableName,
      tableKey: tableKey,
    },
  };
  return await API.post("dynamicFunc", "/dynamicFunc", data);
};

export const putApiMethod = async (tableName, tableKey, body, id) => {
  const data = {
    body: body,
    queryStringParameters: {
      tableName: tableName,
      tableKey: tableKey,
    },
  };
  return await API.patch("dynamicFunc", `/dynamicFunc/${id}`, data);
};

export const lazyFetch = async ({
  tableName,
  listOfKeys = [],
  filterKey,
  filterValue,
}) =>
  await API.get("lazyFetching", "/lazyFetching", {
    queryStringParameters: { tableName, listOfKeys, filterKey, filterValue },
  });

export const apiPost = async (endpoint, body) =>
  await API.post(endpoint, `/${endpoint}`, { body });

export const apiDelete = async (endpoint, id) =>
  await API.del(endpoint, `/${endpoint}/${id}`);

export const apiPut = async (endpoint, id, body) =>
  await API.put(endpoint, `/${endpoint}/${id}`, { body });

export const deleteApiMethod = async (tableName, tableKey, id) => {
  const data = {
    queryStringParameters: {
      tableName: tableName,
      tableKey: tableKey,
    },
  };
  return await API.del("dynamicFunc", `/dynamicFunc/${id}`, data);
};

export const fetchData = async ({
  endpoint,
  query,
  queryStringParams = undefined,
}) => {
  return await API.get(endpoint, `/${query || endpoint}`, queryStringParams);
};

export const fetchAllData = async ({
  endpoint,
  pathName,
  resultPosition,
  resultId,
  customSetter = () => {},
  otherStringParams = {},
}) => {
  const items = [];

  let currentPageKey = undefined;
  while (true) {
    const result = await fetchData({
      endpoint,
      query: pathName || endpoint,
      queryStringParams: {
        queryStringParameters: {
          ExclusiveStartKey: currentPageKey,
          withPagination: "true",
          getMaxLimit: "true", // When this key is set to "true", data retrieval is based on the total size limit, not restricted to just 24 records.
          ...otherStringParams,
        },
      },
    });

    const updatedResults = result?.[resultPosition] || result;

    items.push(...updatedResults);
    customSetter(updatedResults);

    if (!result.LastEvaluatedKey) {
      break;
    }

    if (!resultId) {
      throw new Error("resultId is required");
    }

    currentPageKey = result.LastEvaluatedKey[resultId];
  }

  return items;
};

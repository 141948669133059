import { useState, useEffect } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { useSocket } from "../../hooks";
import { wsEndpoints } from "../../AppData";
import { startNotificationSession, messageHandlers } from "./utils";
import NotificationsContext from "./NotificationsContext";

//#region TYPES

/**
 * @typedef {import("../../components/SidebarPages/FleetMaintenanceView/types").StoreType} Store
 */

/**
 * @typedef MessageBody
 * @property {string} id
 * @property {string} topic
 * @property {string} action
 * @property {any} actionData
 * @property {string} [error]
 * @property {string} [status]
 * @property {string} originUrl
 * @property {any} notificationObject
 */

/**
 * @typedef NotificationMessage
 * @property {string} request
 * @property {MessageBody} body
 */

/**
 * Wraps the application with notification handlers
 */
function NotificationsProvider(props = {}) {
  //#region HOOKS
  const { preferences } = useSelector(
    /** @param {Store} state  */
    (state) => state.preferences
  );
  const { userConfiguration } = useSelector(
    /** @param {Store} state  */
    (state) => state.userConfig
  );
  const { notificationSettings } = useSelector(
    /** @param {Store} state  */
    (state) => state.notificationSettings
  );

  const [startSession, setStartSession] = useState(false);

  const navigate = useNavigate();

  const { socket, online } = useSocket({
    path: wsEndpoints.online,
    retryTimeout: 15000,
  });

  useEffect(() => {
    //#region WAIT DATA
    // wait for all the required data
    if (preferences && userConfiguration && notificationSettings) {
      setStartSession(true);
    }
  }, [preferences, userConfiguration, notificationSettings]);

  useEffect(() => {
    //#region SESSION START
    // when the socket reconnects, a new session is started
    if (!startSession || !socket || !online) {
      return;
    }

    startNotificationSession(socket);
  }, [startSession, socket, online]);

  useEffect(() => {
    //#region MESSAGES
    if (!socket) {
      return;
    }
    const allowNotifications =
      preferences?.notifications?.allowNotifications ?? true;

    const socketUrl = `/${socket.url.split("/").at(-1)}`;

    if (!allowNotifications || !online) {
      socket.onmessage = function () {};
      return;
    }

    socket.onmessage = function (msg) {
      /** @type {NotificationMessage} */

      const message = JSON.parse(msg.data ?? "{}");
      if (!message || Object(message).constructor.name !== "Object") {
        return;
      }

      if (socketUrl !== message.body.originUrl) {
        return;
      }

      const { request, body } = message;
      switch (request) {
        case "pong": {
          messageHandlers.pongHandler(socket, +body.id || 0);
          break;
        }
        case "broadcast": {
          messageHandlers.broadcastHandler(body, navigate);
          break;
        }
        case "confirmSessionCreation": {
          break;
        }
        case "notification-preferences": {
          messageHandlers.notificationPreferenceHandler(body);
          break;
        }
        case "error": {
          console.error("Broadcast notification error: ", body?.error);
          break;
        }
        default: {
          break;
        }
      }
    };
  }, [socket, preferences, navigate, online]);

  //#region JSX
  return (
    <NotificationsContext.Provider value={{}}>
      {props.children}
    </NotificationsContext.Provider>
  );
}

export default NotificationsProvider;

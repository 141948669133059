import {
  forwardRef,
  useState,
  useRef,
  useEffect,
  useCallback,
  ReactNode,
  useImperativeHandle,
} from "react";
import { API } from "aws-amplify";
import { Popover, message, Tooltip, Button } from "antd";
import { GoogleMap } from "@react-google-maps/api";

import { statusPaths } from "./statusPaths";
import { MAP_THEME } from "../../fleetsLive/data";
import { loadLivePreference } from "../../fleetsLive/utils";
import UploadProgress from "src/components/pages/Payroll/Tabs/DEG/components/UploadProgress/UploadProgress";
import { withinRadius } from "src/components/pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import { NYC_CENTER } from "src/components/commonComponents/Map/mapData";
import { MondayButton } from "src/components/commonComponents";
import { FleetWhite } from "src/icons";
import { CommuteIcon } from "../../../../../assets";

import "./VehicleLocationWidget.scss";

/** @typedef {{lat: number, lng: number}} LatLng */

/**
 * @typedef LocationWidgetProps
 * @property {string} deviceSerialNumber
 * @property {string} [popoverClassName]
 * @property {ReactNode} [buttonComponent]
 * @property {string} [buttonClassName]
 * @property {ReactNode} [icon]
 * @property {string|null} [tooltipTitle]
 * @property {boolean} [buttonDisabled]
 */

/**
 * @typedef WidgetHandle
 * @property {() => LatLng} getVehicleLocation
 */

/**
 * @type {React.ForwardRefExoticComponent<LocationWidgetProps>}
 */
const VehicleLocationWidget = forwardRef(function VehicleLocationWidget(
  props,
  ref
) {
  //#region STATES
  const [open, setOpen] = useState(false);
  const [execCount, setExecCount] = useState(0);
  const [lastLocation, setLastLocation] = useState();
  const [trafficLayer, setTrafficLayer] = useState();
  const [vehicleFound, setVehicleFound] = useState(true);
  const [vehicleLocation, setVehicleLocation] = useState();
  const [map, setMap] = useState(/** @type {google.maps.Map} */ (undefined));

  const {
    popoverClassName = "",
    buttonComponent = null,
    buttonClassName = "mondayButtonBlue",
    icon = <FleetWhite />,
    deviceSerialNumber,
    tooltipTitle = "Vehicle Live Location",
    buttonDisabled = false,
  } = props;

  //#region REFS
  const timeoutRef = useRef();
  const animationRef = useRef();
  const progressRef = useRef(null);
  const progressInterval = useRef();
  const controllerRef = useRef(new AbortController());
  const directionsService = useRef(new google.maps.DirectionsService());
  const routePolyline = useRef(
    new google.maps.Polyline({
      clickable: false,
      draggable: false,
      editable: false,
      strokeOpacity: 0,
      strokeWeight: 0,
      strokeColor: "#000",
    })
  );

  const startAnimation = useCallback(() => {
    //#region START ANIMATION
    if (!routePolyline.current || !map) {
      return;
    }

    let count = 0;
    animationRef.current = setInterval(() => {
      count = (count + 1) % 200;

      const icons = routePolyline.current.get("icons");

      icons[0].offset = count / 2 + "%";

      routePolyline.current.set("icons", [...icons]);

      if (count === 199) {
        let path = routePolyline.current.getPath().getArray();
        map.panTo(path[path.length - 1]);
        map.setZoom(15);

        routePolyline.current.setPath(regulatePath([path[path.length - 1]]));

        clearInterval(animationRef.current);
      }
    }, 10);
  }, [map]);

  //#region REF HANDLE
  useImperativeHandle(
    ref,
    () => {
      return {
        getVehicleLocation() {
          return {
            lat: lastLocation?.["latitude"] || vehicleLocation?.["latitude"],
            lng: lastLocation?.["longitude"] || vehicleLocation?.["longitude"],
          };
        },
      };
    },
    [lastLocation, vehicleLocation]
  );

  useEffect(() => {
    //#region GENERAL EFFECT
    setLastLocation(undefined);
    setVehicleFound(true);
    setVehicleLocation(undefined);
    clearAllTimers();
    routePolyline.current.setPath([]);
    setExecCount(0);
  }, [deviceSerialNumber]);

  useEffect(() => {
    //#region GET LOCATION EFFECT
    if (!map) {
      return;
    }

    if (open) {
      if (!deviceSerialNumber) {
        message.warning({
          content: "Vehicle does not have a valid tracker number",
          key: "noTracker",
        });
      } else {
        message.destroy("noTracker");
      }
    }

    if (!open || !vehicleFound || !deviceSerialNumber) {
      controllerRef.current.abort();
      setExecCount(0);
      clearAllTimers();

      if (vehicleLocation) {
        setLastLocation(JSON.parse(JSON.stringify(vehicleLocation)));
      }

      setVehicleLocation(undefined);
      routePolyline.current.setPath([]);
      return;
    }

    async function getLocation() {
      clearInterval(progressInterval.current);

      controllerRef.current = new AbortController();
      progressRef.current.setPercentage(0);
      progressInterval.current = setInterval(() => {
        const rand = Math.floor(Math.random() * 10 + 7);
        const currentPercent = progressRef.current.getPercentage();
        let newPercent = currentPercent + rand;

        if (newPercent >= 80) {
          newPercent = Math.min(
            currentPercent +
              (currentPercent >= 90 ? 2 : currentPercent >= 80 ? 5 : 7),
            90
          );
        }

        progressRef.current.setPercentage(newPercent);
      }, 150);

      await API.get("locations", "/locations", {
        signal: controllerRef.current.signal,
      })
        .then((res) => {
          clearInterval(progressInterval.current);
          progressRef.current.setPercentage(100);

          const allLocations = res?.data?.locations || [];
          const vehicleLocationObject = allLocations.find(
            ({ deviceSerialNumber: nr }) => nr === deviceSerialNumber
          );

          if (!!vehicleLocationObject !== vehicleFound) {
            setVehicleFound(!!vehicleLocationObject);

            if (!vehicleLocationObject) {
              message.warning({
                content: "Device was not found in Linxup records",
                key: "noDevice",
              });
            }
          }

          if (!!vehicleLocationObject) {
            setVehicleLocation(vehicleLocationObject);
          }

          setExecCount((prev) => prev + 1);
          changeVehiclePosition(vehicleLocationObject);
        })
        .catch((err) => {
          clearInterval(progressInterval.current);
          progressRef.current.setPercentage(0);
          console.log("Error getting location: ", err);
        });
    }

    timeoutRef.current = setTimeout(
      () => {
        void getLocation();
      },
      !!execCount ? 10000 : 0
    );

    return () => {
      controllerRef.current.abort();
      setExecCount(0);
      clearAllTimers();
    };
  }, [open, vehicleLocation, map, deviceSerialNumber, vehicleFound]);

  async function changeVehiclePosition(location) {
    //#region CHANGE POSITION
    if (!location) {
      return;
    }

    const status = findActualStatus(location.status, location.speed);

    if (!vehicleLocation) {
      routePolyline.current.set(
        "icons",
        assignIcon(status, location.direction)
      );

      routePolyline.current.setPath(
        regulatePath([{ lat: location.latitude, lng: location.longitude }])
      );
      map.panTo({
        lat: location.latitude,
        lng: location.longitude,
      });
      map.setZoom(15);
      routePolyline.current.setVisible(true);

      return;
    }

    const prevStatus = findActualStatus(
      vehicleLocation.status,
      vehicleLocation.speed
    );

    const wasStopped = isStopped(prevStatus);
    const hasStopped = isStopped(status);

    if (wasStopped && hasStopped) {
      if (prevStatus !== status) {
        routePolyline.current.set("icons", assignIcon(status));
      }

      return;
    }

    let routePath = [];

    if (
      !withinRadius(
        { lat: location["latitude"], lng: location["longitude"] },
        {
          lat: vehicleLocation["latitude"],
          lng: vehicleLocation["longitude"],
        },
        30
      ).withinRange
    ) {
      routePath = await directionsService.current
        .route({
          origin: {
            lat: vehicleLocation["latitude"],
            lng: vehicleLocation["longitude"],
          },
          destination: {
            lat: location["latitude"],
            lng: location["longitude"],
          },
          travelMode: google.maps.TravelMode.DRIVING,
        })
        .then((r) => regulatePath(r?.routes?.[0]?.overview_path ?? []))
        .catch((err) => {
          message.warning({
            content: "Could not load directions",
            key: "noDirections",
          });

          console.log("No direction error: ", err);
          return regulatePath();
        });

      routePolyline.current.setPath(routePath);
    }

    if (routePath.length > 2) {
      startAnimation();
      setTimeout(() => {
        routePolyline.current.set(
          "icons",
          assignIcon(status, location.direction)
        );
      }, 2000);
    } else {
      routePolyline.current.set(
        "icons",
        assignIcon(status, location.direction)
      );
    }
  }

  function findActualStatus(defaultStatus, speed) {
    //#region STATUS
    let status = defaultStatus;
    if (!speed) {
      status = "Stopped";
    } else {
      if (status === "Stopped") {
        status = "In Motion";
      }
    }
    return status;
  }

  /**
   * @param {string} status
   * @param {number} rotation
   * @returns {google.maps.IconSequence[]}
   */
  function assignIcon(status, rotation = 0) {
    //#region ASSIGN ICON
    const statusKey = status.replaceAll(" ", "");
    const iconConfig =
      statusKey in statusPaths
        ? statusPaths[statusKey]
        : statusPaths["NotTracking"];

    return [
      {
        icon: {
          path: iconConfig["path"],
          fillColor: iconConfig["color"],
          fillOpacity: 1,
          scale: 1,
          rotation: isStopped(status) ? 0 : rotation,
          strokeColor: "#fff",
          strokeOpacity: 1,
          strokeWeight: 1.5,
          anchor: new google.maps.Point(11, 11),
        },
        offset: "0%",
        repeat: "0",
      },
    ];
  }

  /**
   * @param {LatLng[]|google.maps.LatLng[]} [path]
   * @returns {LatLng[]|google.maps.LatLng[]}
   */
  function regulatePath(path = []) {
    //#region REGULATE PATH

    /** @type {LatLng[]|google.maps.LatLng[]} */
    const newPath = [...path];

    if (!vehicleFound) {
      return newPath;
    }

    if (!newPath.length) {
      if (vehicleLocation) {
        const newLatLng = new google.maps.LatLng({
          lat: vehicleLocation.latitude,
          lng: vehicleLocation.longitude,
        });

        newPath.push(newLatLng);
      }
    }

    if (newPath.length === 1) {
      const newLatLng = new google.maps.LatLng({
        lat:
          (typeof newPath[0]["lat"] === "function"
            ? newPath[0]["lat"]()
            : newPath[0]["lat"]) + 0.00001,
        lng:
          typeof newPath[0]["lng"] === "function"
            ? newPath[0]["lng"]()
            : newPath[0]["lng"],
      });

      newPath.push(newLatLng);

      newPath[0] = new google.maps.LatLng({
        lat: newPath[0].lat,
        lng: newPath[0].lng,
      });
    }

    return newPath;
  }

  function clearAllTimers() {
    //#region CLEAR TIMERS
    clearInterval(animationRef.current);
    clearTimeout(timeoutRef.current);
    clearInterval(progressInterval.current);
  }

  /** @param {string} status  */
  function isStopped(status) {
    //#region IS STOPPED
    return !["Moving", "InMotion", "In Motion"].includes(status);
  }

  function toggleTraffic() {
    //#region TOGGLE TRAFFIC
    if (!map) {
      return;
    }

    if (!trafficLayer) {
      setTrafficLayer(new google.maps.TrafficLayer({ map }));
    } else {
      trafficLayer.setMap(null);
      setTrafficLayer(undefined);
    }
  }

  //#region JSX
  return (
    <Popover
      {...{
        trigger: "click",
        onOpenChange(o) {
          setOpen(o);
        },
        overlayClassName: `popover-widget-map-container ${
          popoverClassName ?? ""
        }`,
        content: (
          <div className="popover-widget-map" id="popover-widget-map">
            <UploadProgress showPercent={false} ref={progressRef} />
            <GoogleMap
              {...{
                zoom: 15,
                id: "widget-map",
                mapContainerClassName: "widget-map",
                onLoad(m) {
                  setMap(m);
                  routePolyline.current.setMap(m);
                  m.setCenter(NYC_CENTER);
                },
                options: {
                  disableDefaultUI: true,
                  styles: MAP_THEME[loadLivePreference("mapStyle")],
                  mapTypeId: loadLivePreference("mapType"),
                  fullscreenControl: true,
                  clickableIcons: false,
                  rotateControl: true,
                  fullscreenControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                  streetViewControl: true,
                  streetViewControlOptions: {
                    position: google.maps.ControlPosition.RIGHT_BOTTOM,
                  },
                },
              }}
            >
              <div className="location-widget-controller-container">
                <Button
                  type="default"
                  onClick={() => {
                    window.open(
                      "https://app02.linxup.com/ng/portal/index.html#mappage",
                      "_blank",
                      "noopener noreferrer"
                    );
                  }}
                >
                  Linxup
                </Button>
                <Tooltip title="Toggle Traffic">
                  <MondayButton
                    {...{
                      Icon: <CommuteIcon height={20} width={20} fill="#fff" />,
                      className: !trafficLayer
                        ? "mondayButtonBlue"
                        : "mondayButtonGrey",
                      onClick() {
                        toggleTraffic();
                      },
                    }}
                  >
                    {null}
                  </MondayButton>
                </Tooltip>
              </div>
            </GoogleMap>
          </div>
        ),
      }}
    >
      <Tooltip title={tooltipTitle}>
        {buttonComponent ? (
          buttonComponent
        ) : (
          <MondayButton
            {...{
              Icon: icon,
              className: buttonClassName,
              disabled: buttonDisabled,
            }}
          >
            {""}
          </MondayButton>
        )}
      </Tooltip>
    </Popover>
  );
});

export default VehicleLocationWidget;

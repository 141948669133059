//@ts-check

import moment from "moment";
import { generatePolygonImage } from "../../components/commonComponents/Map/Geofence/shapesImagesGeneratorForFences";
import { calculateZoomLevel } from "../../components/commonComponents/Map/Geofence/utils";
import { filterTables } from "../filterTables";

export const opportunityDetails = (recordId) => {
  return filterTables("opportunities", "opportunityId", recordId)
    .then(async (r) => {
      const res = !!r?.["opportunities"] ? r["opportunities"] : r;
      const proceededOpportunities = await Promise.all(
        res.map(async (opportunity) => {
          const geoFenceInfo = await Promise.all(
            opportunity.geoFenceInfo.map(async (geo) => {
              const image = await generatePolygonImage({
                polygonCoordinates: geo.geoFenceInfo,
                zoomOfMap: calculateZoomLevel(geo.geoFenceInfo),
              });
              return {
                ...geo,
                image: `data:image/png;base64,${image}`,
                createdAt: moment(geo.createdAt).format("MM/DD/YYYY"),
              };
            })
          );

          return {
            ...opportunity,
            geoFenceInfo,
            createdAt:
              typeof opportunity.createdAt === "number"
                ? moment(opportunity.createdAt).format("MM/DD/YYYY")
                : opportunity.createdAt,
          };
        })
      );
      return proceededOpportunities;
    })
    .catch((err) => console.log({ err }));
};

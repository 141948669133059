const pinnedBottomRowData = (rowData) => {
  const crewStatusCounts = rowData.reduce(
    (counts, row) => {
      if (row.crewStatus === "Active") {
        counts.active++;
      } else if (row.crewStatus === "Suspended") {
        counts.suspended++;
      }
      return counts;
    },
    { active: 0, suspended: 0 }
  );

  const employeeStatusCounts = rowData.reduce(
    (counts, row) => {
      if (row.employeeStatus === "Active") {
        counts.active++;
      } else if (row.employeeStatus === "Suspended") {
        counts.suspended++;
      }
      return counts;
    },
    { active: 0, suspended: 0 }
  );

  const roleCounts = rowData.reduce(
    (counts, row) => {
      if (row.role && row.role.includes("Crew")) {
        counts.crews++;
      }
      if (row.engineerId) {
        counts.engineers++;
      }
      if (row.architectId) {
        counts.architects++;
      }
      if (row.driverId) {
        counts.drivers++;
      }
      return counts;
    },
    { crews: 0, engineers: 0, architects: 0, drivers: 0 }
  );

  return [
    {
      crewName: null,
      employeeId: null,
      foreman: null,
      role: `Total: ${roleCounts.engineers} Engineer | ${roleCounts.architects} Architects | ${roleCounts.crews} Crews | ${roleCounts.drivers} Drivers`,
      crewPosition: null,
      accountName: null,
      employeeRate: "",
      salaryType: null,
      crewStatus: `Total: ${crewStatusCounts.active} Active | ${crewStatusCounts.suspended} Suspended`,
      employeeStatus: `Total: ${employeeStatusCounts.active} Active | ${employeeStatusCounts.suspended} Suspended`,
      crewTeam: null,
      edit: null,
      delete: null,
    },
  ];
};

export default pinnedBottomRowData;

import { Segmented } from "antd";
import { InfoText } from "../CustomModalHeader/CustomModalHeader";
import { ReportCheck } from "../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

function ExportConfigTab({
  landscape,
  setLandscape,
  setPageSize,
  pageSize,
  pagination,
  setPagination,
}) {
  const options = ["A4", "Letter", "Legal", "Tabloid"];

  const items = [
    {
      key: "1",
      label: "Orientation",
      options: [
        {
          label: "Portrait",
          value: false,
        },
        {
          label: "Landscape",
          value: true,
        },
      ],
      onChange: (e) => {
        setLandscape(e);
      },
      defaultValue: landscape,
    },
    {
      key: "2",
      label: "Page Size",
      options: options.map((key) => {
        return {
          label: key,
          value: key,
        };
      }),
      onChange: (e) => {
        setPageSize(e);
      },
      defaultValue: pageSize,
    },
  ];
  return (
    <div className="tab-container">
      <InfoText
        {...{
          text: "Select the orientation and page size for your export.",
        }}
      />
      {items.map((item, i) => {
        const { key, ...rest } = item;
        return (
          <Segmented
            key={key}
            {...{
              ...rest,
              style: { width: "fit-content" },
            }}
          />
        );
      })}
      <ReportCheck
        {...{
          id: "pagination",
          label: "Pagination",
          className: "pagination-checkbox",
          checked: pagination,
          onChange: (e) => {
            setPagination(e);
          },
        }}
      />
    </div>
  );
}

export default ExportConfigTab;

import React from "react";
import "./InternalChat.scss";
// import { ChatProvider } from "./ChatContext";
import InternalChatWrapper from "./components/InternalChatWrapper/InternalChatWrapper.jsx";
const InternalChat = () => {
  document.title = "Internal Chat";
  return <InternalChatWrapper />;
};

export default InternalChat;

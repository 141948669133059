import {
  AlternateSidewalkShedPLI,
  SidewalkShedPLI,
} from "../../models/SidewalkShedModels";
import {
  AlternateNeedleBeamPLI,
  NeedleBeamPLI,
} from "../../models/NeedleBeamModels";
import { AlternateScaffoldPLI, ScaffoldPLI } from "../../models/ScaffoldModels";
import {
  AlternateRoofProtectionPLI,
  RoofProtectionPLI,
} from "../../models/RoofProtectionModels";
import {
  AlternateOverheadProtectionPLI,
  OverheadProtectionPLI,
} from "../../models/OverheadProtectionModels";
import {
  AlternateHorizontalNettingPLI,
  HorizontalNettingPLI,
} from "../../models/HorizontalNettingModels";
import {
  AlternatePlywoodEnclosurePLI,
  PlywoodEnclosurePLI,
} from "../../models/PlywoodEnclosureModels";
import {
  AlternateDebrisChutePLI,
  DebrisChutePLI,
} from "../../models/DebrisChuteModels";
import { HoistPLI } from "../../models/HoistModels";
import { AlternateFencePLI, FencePLI } from "../../models/FenceModels";
import { AlternateBarriersPLI, BarriersPLI } from "../../models/BarriersModels";
import {
  AlternateWorkingPlatformPLI,
  WorkingPlatformPLI,
} from "../../models/WorkingPlatformModels";
import {
  AlternateSystemScaffoldPLI,
  SystemScaffoldPLI,
} from "../../models/SystemScaffoldModels";
import {
  AlternateStairTowerPLI,
  StairTowerPLI,
} from "../../models/StairTowerModels";
import { ShoringPLI } from "../../models/ShoringModels";
import { TurnStilesPLI } from "../../models/TurnStilesModels";
import { HangingScaffoldPLI } from "../../models/HangingScaffoldModels";
import {
  AlternateVerticalNettingPLI,
  VerticalNettingPLI,
} from "../../models/VerticalNettingModels";
import { WindowProtectionPLI } from "../../models/WindowProtectionModels";
import { PlatformScaffoldPLI } from "../../models/PlatformScaffoldModels";
import { WinterTarpPli } from "../../models/WinterTarp";
import { CrashDeckPli } from "../../models/CrashDeckPli";
import { conEdVaultProtectionPli } from "../../models/conEdVaultProtectionPli";
import { HeavyDuttyNettingPli } from "../../models/heavyDuttyNetting";
import { MeshNettingPli } from "../../models/MeshNettingModels";
import { CustomServicePLI } from "../../models/CustomServiceModel";
import {
  AlternateRoadPlatesPLI,
  RoadPlatesPLI,
} from "../../models/roadPlatesPLI";
import {
  AlternateSystemStairPli,
  SystemStairPli,
} from "../../models/SystemStairPli";
import {
  AlternateEngEnclosureSystemPli,
  EngEnclosureSystemPli,
} from "../../models/engEnclosureSystemPli";
import {
  AlternateTreeProtectionPLI,
  TreeProtectionPLI,
} from "../../models/treeProtection";

/**
 * This function will return a new PLI based on serviceId.
 * @example serviceId=1 and id=5, will return a new SidewalkShedPLI(5) (a SidewalkShedPLI with id=5)
 *
 * @param serviceId {number} Service id which can be from from 1-20
 * @param id {number} Can be 1 or higher, as PLI id's starts from 1.
 * */
export function createEmptyPLI(serviceId, id, service, optionIndex) {
  if (service?.isScope === true) {
    console.log("PLIModelsMap[serviceId] is undefined");
    return;
  } else if (!PLIModelsMap[serviceId]) {
    //TODO: Option Index = Alternate Elevation/PLI
    return new CustomServicePLI(id, {
      serviceId: serviceId,
      width: 0,
      height: 0,
      length: 0,
    });
  }
  return optionIndex === 0
    ? new PLIModelsMap[serviceId](id, service?.isTaxable ? service?.taxRate : 0)
    : new PLIModelsMapAlternate[serviceId](
        id,
        service?.isTaxable ? service?.taxRate : 0
      );
}

export const PLIModelsMap = {
  1: SidewalkShedPLI,
  2: ScaffoldPLI,

  3: HoistPLI,
  4: FencePLI,

  5: RoofProtectionPLI,
  6: OverheadProtectionPLI,
  7: NeedleBeamPLI,
  8: HorizontalNettingPLI,

  9: BarriersPLI,
  10: WorkingPlatformPLI,
  11: SystemScaffoldPLI,
  12: StairTowerPLI,
  13: ShoringPLI,

  14: PlywoodEnclosurePLI,
  15: DebrisChutePLI,

  16: TurnStilesPLI,
  17: HangingScaffoldPLI,
  18: VerticalNettingPLI,
  19: WindowProtectionPLI,
  20: PlatformScaffoldPLI,
  38: HoistPLI,
  44: RoofProtectionPLI,
  69: WinterTarpPli,
  70: CrashDeckPli,
  71: conEdVaultProtectionPli,
  72: MeshNettingPli,
  73: HeavyDuttyNettingPli,
  79: RoadPlatesPLI,
  80: SystemStairPli,
  81: EngEnclosureSystemPli,
  85: TreeProtectionPLI,
};

export const PLIModelsMapAlternate = {
  1: AlternateSidewalkShedPLI,
  2: AlternateScaffoldPLI,

  3: HoistPLI,
  4: AlternateFencePLI,

  5: AlternateRoofProtectionPLI,
  6: AlternateOverheadProtectionPLI,
  7: AlternateNeedleBeamPLI,
  8: AlternateHorizontalNettingPLI,

  9: AlternateBarriersPLI,
  10: AlternateWorkingPlatformPLI,
  11: AlternateSystemScaffoldPLI,
  12: AlternateStairTowerPLI,
  13: ShoringPLI,

  14: AlternatePlywoodEnclosurePLI,
  15: AlternateDebrisChutePLI,

  16: TurnStilesPLI,
  17: HangingScaffoldPLI,
  18: AlternateVerticalNettingPLI,
  19: WindowProtectionPLI,
  20: PlatformScaffoldPLI,
  38: HoistPLI,
  44: AlternateRoofProtectionPLI,
  69: WinterTarpPli,
  70: CrashDeckPli,
  71: conEdVaultProtectionPli,
  72: MeshNettingPli,
  73: HeavyDuttyNettingPli,
  79: AlternateRoadPlatesPLI,
  80: AlternateSystemStairPli,
  81: AlternateEngEnclosureSystemPli,
  85: AlternateTreeProtectionPLI,
};

import { API } from "aws-amplify";
import {
  fetchAllData,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../utils";
import { swalToast } from "src/components/Header/forms/Scheduling/SchedulingFirstPage/helperData";
import Swal from "sweetalert2";
import { addNewRecordToRequest } from "src/components/Header/forms/RequestFormModal/helperData";
import { message } from "antd";
import { checkIfServiceIsHoist } from "../../Estimations/DataEntryGrid/models/Service";
import {
  addonDidNotChange,
  calculateIsBreakdownCompleted,
  calculateIsJumpCompleted,
  calculateIsSubPliCompleted,
  compareAddons,
  compareJumps,
  comparePlis,
  getStateSizeInKB,
  jumpDidNotChange,
  pliDidNotChange,
  pliEditedFromNextSchedules,
  plisDimensionMisMatch,
  processPli,
  scheduleProgress,
  transformOption,
  updateNextSchedules,
  updatePrevSchedules,
} from "../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { get_ProgressDimensionsByServiceId } from "../../../Header/forms/DataEntryGrid/tools/columnDefinitions/ProgressColumnDefinition";
import dayjs from "dayjs";
import { filter, groupBy } from "lodash";
import { breakdown_dimension } from "../../../Header/forms/Scheduling/PLIPerService/componentsForPli/Breakdown/breakdown";
export function getSchedule(scheduleId) {
  return API.get("scheduling", `/scheduling/${scheduleId}`);
}

export function getProject(projectId) {
  return API.get("projects", `/projects/${projectId}`);
}

export function saveSchedule(schedule) {
  showLoadingMsg();
  const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};
  return API.put("scheduling", `/scheduling/${scheduleId}`, {
    body: saveableScheduleObject,
  }).then((r) => {
    showSuccessMsg();
  });
}

// export async function saveMultipleSchedules(
//   schedules = [],
//   notificationForProgressChange = () => {},
//   updateRowData = () => {}
// ) {
//   return new Promise(() => {
//     schedules?.forEach((schedule) => {
//       const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};
//       API.put("scheduling", `/scheduling/${scheduleId}`, {
//         body: saveableScheduleObject,
//       })
//         .then((r) => {
//           // showSuccessMsg();
//           notificationForProgressChange();
//           updateRowData();
//           swalToast({
//             icon: "success",
//             title: "Schedule saved successfully!",
//             timer: 3000,
//             position: "bottom-end",
//             showConfirmButton: false,
//           });
//         })
//         .catch((e) => {
//           swalToast({
//             icon: "error",
//             title: "Error saving schedule!",
//             timer: 3000,
//             position: "bottom-end",
//             showConfirmButton: false,
//           });
//           console.log("eeeeeeeee", { e });
//         });
//     });
//   });
// }

function delayUpdate(ms) {
  return new Promise((resolve) => setTimeout(resolve, ms));
}

async function updateSchedule(
  schedule,
  toBeScheduled,
  getToBeScheduled,
  createdAt,
  schId,
  scheduleName
) {
  const groupByEstimationId = groupBy(getToBeScheduled, "estimationId");
  const isAfter =
    dayjs(schedule?.createdAt).isAfter(dayjs(createdAt)) &&
    schId !== schedule?.scheduleId;

  if (isAfter) {
    updateNextSchedules(schedule, toBeScheduled, groupByEstimationId);
  } else if (schId === schedule?.scheduleId) {
    schedule.toBeScheduled = toBeScheduled;
  } else if (!isAfter && schId !== schedule?.scheduleId) {
    updatePrevSchedules(
      schedule,
      toBeScheduled,
      groupByEstimationId,
      schId,
      scheduleName
    );
  }

  const { userId, scheduleId, ...saveableScheduleObject } = schedule || {};

  try {
    await API.put("scheduling", `/scheduling/${scheduleId}`, {
      body: {
        ...saveableScheduleObject,
        toBeScheduled: {},
        totalScheduleProgress: scheduleProgress(schedule?.toBeScheduled || {}),
        scheduleEstimates: Object.keys(schedule?.toBeScheduled || {}).length,
      },
    });
  } catch (error) {
    console.error("Error updating schedule:", error);
    throw new Error(`Failed to save schedule for ${scheduleId}`);
  }
}

async function updateToBeScheduledItems(schedule, getToBeScheduled) {
  const batchSize = 3;
  const delayBetweenBatches = 4000;

  try {
    for (let i = 0; i < getToBeScheduled?.length; i += batchSize) {
      const batch = getToBeScheduled?.slice(i, i + batchSize);
      const updateEstimatesPromises = batch?.map(async (toBeScheduledItem) => {
        const findEsti = schedule?.toBeScheduled?.[
          toBeScheduledItem?.estimationId
        ]?.find((esti) => esti?.serviceId === toBeScheduledItem?.serviceId);

        try {
          if (findEsti) {
            await API.put(
              `toBeScheduled`,
              `/toBeScheduled/${toBeScheduledItem?.toBeScheduledId}`,
              {
                body: {
                  serviceOptions: findEsti?.serviceOptions || [],
                  serviceAddons: findEsti?.serviceAddons || [],
                  isNotAppliedForProgress:
                    findEsti?.isNotAppliedForProgress || false,
                  isSelected: findEsti?.isSelected || false,
                },
              }
            );
            message.destroy("savingEstimates");
          } else {
            await API.del(
              `toBeScheduled`,
              `/toBeScheduled/${toBeScheduledItem?.toBeScheduledId}`
            );
            message.destroy("savingEstimates");
            message.success(
              `${toBeScheduledItem?.label} deleted successfully for ${schedule?.scheduleName}!`
            );
          }
        } catch (e) {
          const action = findEsti ? "saving" : "deleting";
          message.error(
            `Error ${action} estimates for ${schedule?.scheduleName}!`
          );
          console.log("findEsti", { findEsti });
          console.error(`Error ${action} estimates:`, e);
          throw new Error(
            `Error ${action} estimates for ${schedule?.scheduleName}`
          );
        }
      });

      await Promise.all(updateEstimatesPromises);
      await delayUpdate(delayBetweenBatches);
    }
  } catch (error) {
    console.error("Error updating estimates:", error);
    throw new Error(`Failed to update estimates for ${schedule?.scheduleName}`);
  }
}

export async function saveMultipleSchedules(
  schedules = [],
  toBeScheduled,
  createdAt,
  schId,
  scheduleName,
  progressLogs = [],
  notificationForProgressChange = () => {},
  updateRowData = () => {},
  afterSaveRequest = async () => {},
  requestId
) {
  try {
    Swal.fire({
      title: "Please wait...",
      text: "We are saving the schedule for you!",
      allowOutsideClick: false,
      showConfirmButton: false,
      willOpen: () => {
        Swal.showLoading();
      },
    });

    const promises = schedules?.map(async (schedule) => {
      try {
        const getToBeScheduled = await fetchAllDataForSchedule(schedule);
        await updateSchedule(
          schedule,
          toBeScheduled,
          getToBeScheduled?.filter(
            (item) => item?.scheduleId === schedule?.scheduleId
          ),
          createdAt,
          schId,
          scheduleName
        );

        await updateToBeScheduledItems(
          schedule,
          getToBeScheduled?.filter(
            (item) => item?.scheduleId === schedule?.scheduleId
          )
        );
      } catch (error) {
        console.error("Error processing schedule:", error);
        throw new Error(
          `Failed to save ${scheduleName} for ${schedule?.scheduleId}`
        );
      }
    });

    await Promise.all(promises);
    if (progressLogs?.length > 0) {
      progressLogs?.forEach(async (log = {}) => {
        await API.post("progressItems", "/progressItems", {
          body: log || {},
        })
          .then((res) => {})
          .catch((e) => {
            message.error("Error saving progress!");
            console.error("Error saving progress:", e);
          });
      });
    }

    if (requestId) {
      await afterSaveRequest();
    }

    notificationForProgressChange();
    updateRowData();

    Swal.fire({
      icon: "success",
      title: "Schedule saved successfully!",
      timer: 3000,
      showConfirmButton: false,
    });
  } catch (error) {
    Swal.fire({
      icon: "error",
      title: "Error saving schedules!",
      timer: 3000,
      showConfirmButton: false,
    });
    console.error("Error saving schedules:", error);
  }
}

async function fetchAllDataForSchedule(schedule) {
  return fetchAllData({
    endpoint: "toBeScheduled",
    resultPosition: "toBeScheduled",
    resultId: "toBeScheduledId",
    otherStringParams: {
      filters: JSON.stringify([
        {
          conditions: [
            {
              column: "scheduleId",
              value: schedule?.scheduleId,
              formula: "is",
            },
          ],
        },
      ]),
      withPagination: "true",
      getMaxLimit: "true",
      // listOfKeys: [
      //   "estimationId",
      //   "scheduleId",
      //   "serviceId",
      //   "serviceOptions",
      //   "toBeScheduledId",
      //   "isNotAppliedForProgress",
      //   "isSelected",
      //   "serviceAddons",
      //   "label",
      // ],
    },
  });
}

export function createSchedule(schedule) {
  return API.post("scheduling", "/scheduling", {
    body: schedule,
  });
}

export function deleteSchedule(scheduleId) {
  return API.del("scheduling", `/scheduling/${scheduleId}`);
}

import { Form, Modal } from "antd";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import {
  useMemo,
  useState,
  useEffect,
  useContext,
  useCallback,
  useLayoutEffect,
} from "react";

import {
  MondayButton,
  MultiLevelTreeLogs,
} from "../../../../../../commonComponents";
import { PlusIcon } from "../../../../../../../assets";
import { fieldsList } from "./cashEntryShiftModalData";
import DegModalContext from "../modalComponents/DegModalContext";
import { createOverheadEntries } from "../modalComponents/utils";
import { LogsIcon } from "../../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { teamShiftColumns } from "../modalComponents/TeamShiftsModal/teamShiftModalData";

import "./CashEntryShiftModal.scss";

function CashEntryShiftModal({
  open,
  onCancel,
  selectedRow,
  selectedNodes,
  shiftView = false,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  // const { programFields } = useSelector((state) => state.programFields);
  const {
    crews,
    rowData,
    crewTeams,
    setRowData,
    // degGridApi,
    entriesLogs,
    // currentStep,
    addEntryAction,
    addActionToRegister,
  } = useContext(DegModalContext);

  const [gridApi, setGridApi] = useState(null);
  const [logsVisible, setLogsVisible] = useState(false);
  // const [crewTeams] = useState(
  //   programFields?.find(({ fieldName }) => fieldName === "Crew Teams")
  //     .fieldOptions?.[process.env.NODE_ENV === "production" ? "prod" : "dev"] ||
  //     []
  // );

  const [form] = Form.useForm();
  const punchType = Form.useWatch("punchType", form);

  const onGridReady = useCallback(({ api }) => {
    setGridApi(api);
  }, []);

  const formFieldsList = useMemo(() => {
    return fieldsList({
      form,
      crews,
      punchType,
      selectedRow,
      selectedNodes,
      onAmountChange,
      calculateAmount,
    });
  }, [crews, punchType, selectedRow, selectedNodes]);

  function calculateAmount() {
    const otHours = parseFloat(form.getFieldValue("otHours"));
    const rate = parseFloat(form.getFieldValue("employeeRate"));
    const workHours = parseFloat(form.getFieldValue("workHours"));

    if (!!rate && (!!workHours || !!otHours)) {
      let total = workHours * rate + otHours * (rate * 1.5);
      form.setFieldValue("totalOvh", total.toFixed(2));
    }
  }

  function onAmountChange(e) {
    form.setFieldValue("otHours", null);
    form.setFieldValue("workHours", null);
    form.setFieldValue("employeeRate", null);
  }

  function onSave() {
    if (selectedNodes?.length) {
      const formData = form.getFieldsValue();

      if (formData?.shiftStatus) {
        Object.assign(formData, { activityStatus: formData?.shiftStatus });
      }

      Object.keys(formData).forEach((key) => {
        if (!formData?.[key] && formData[key] !== 0) {
          delete formData?.[key];
        }
      });

      if (shiftView) {
        const entriesIncluded = selectedNodes.map(({ entries }) => entries[0]);
        const selectedEntries = rowData.filter(({ entryId }) =>
          entriesIncluded.includes(entryId)
        );
        let updatedSelectedEntries = [];
        let editedActions = [];
        for (const entry of selectedEntries) {
          const editedEntry = {
            ...entry,
            ...formData,
            totalOvh: Number(formData?.totalOvh) || entry.totalOvh,
          };
          updatedSelectedEntries.push(editedEntry);
          editedActions.push({ curr: editedEntry, prev: entry });
          // Object.assign(entry, formData);
        }
        const tmpRowData = rowData.map((data) => {
          const editedEntryIndex = updatedSelectedEntries.findIndex(
            ({ entryId }) => data?.entryId === entryId
          );
          if (editedEntryIndex > -1) {
            return updatedSelectedEntries[editedEntryIndex];
          } else {
            return data;
          }
        });
        addEntryAction({ type: "edit", entry: updatedSelectedEntries });
        addActionToRegister({ type: "edit", editActions: editedActions });
        setRowData(tmpRowData);
      } else {
        let updatedSelectedEntries = [];
        let editedActions = [];
        for (const entry of selectedNodes) {
          const editedEntry = {
            ...entry,
            ...formData,
            totalOvh: Number(formData?.totalOvh) || entry.totalOvh,
          };
          updatedSelectedEntries.push(editedEntry);
          editedActions.push({ curr: editedEntry, prev: entry });
          // Object.assign(entry, formData);
        }
        // if (currentStep === 1) {
        //   degGridApi.applyTransaction({ update: updatedSelectedEntries });
        //   addActionToRegister({ type: "edit", editActions: editedActions });
        //   addEntryAction({ type: "edit", entry: updatedSelectedEntries });
        // } else {
        const tmpRowData = rowData.map((data) => {
          const editedEntryIndex = updatedSelectedEntries.findIndex(
            ({ entryId }) => data?.entryId === entryId
          );
          if (editedEntryIndex > -1) {
            return updatedSelectedEntries[editedEntryIndex];
          } else {
            return data;
          }
        });
        addEntryAction({ type: "edit", entry: updatedSelectedEntries });
        addActionToRegister({ type: "edit", editActions: editedActions });
        setRowData(tmpRowData);
        // }
      }
      onCancel();
    } else {
      form.validateFields().then((formValue) => {
        const workHours = parseFloat(formValue.workHours);
        const otHours = parseFloat(formValue.otHours);
        const employeeRate = parseFloat(formValue.employeeRate);

        // const otAmount = otHours * 1.5 * employeeRate;
        // const ovhAmount = workHours * employeeRate;

        const formData = {
          ...formValue,
          workHours,
          otHours,
          totalOvh: parseFloat(formValue?.totalOvh) || 0,
          employeeRate,
        };

        // if (!formData?.punchDate) {
        //   Object.assign(formData, { dateRangeIncluded: analytics?.dateRange });
        // } else {
        //   Object.assign(formData, { dateRangeIncluded: false });
        // }

        if (selectedRow?.entryId) {
          const newSelectedRow = {
            ...selectedRow,
            punchType: formData?.punchType,
            activityStatus: formData?.shiftStatus,
            punchDate: formData?.selectedDate || null,
            totalOvh: formData?.totalOvh || null,
            reason: formData?.reason || null,
            employeeRate: formData?.employeeRate,
            salaryType: formData?.salaryType,
          };

          // if (currentStep === 1) {
          //   degGridApi.applyTransaction({ update: [newSelectedRow] });
          //   addEntryAction({
          //     type: "edit",
          //     entry: [newSelectedRow],
          //   });
          //   addActionToRegister({
          //     type: "edit",
          //     editActions: [{ curr: newSelectedRow, prev: selectedRow }],
          //   });
          // } else {
          const entryIndex = rowData.findIndex(
            (entry) => entry?.entryId === selectedRow?.entryId
          );
          setRowData((prev) => {
            return prev.splice(entryIndex, 1, newSelectedRow);
          });
          addEntryAction({
            type: "edit",
            entry: [newSelectedRow],
          });
          addActionToRegister({
            type: "edit",
            editActions: [{ curr: newSelectedRow, prev: selectedRow }],
          });
          // }
        } else {
          const newEntries = createOverheadEntries({ crews, formData });
          // if (currentStep === 1) {
          //   degGridApi.applyTransaction({ addIndex: 0, add: newEntries });
          //   addEntryAction({
          //     type: "new",
          //     entry: newEntries,
          //   });
          //   addActionToRegister({
          //     type: "new",
          //     newActions: newEntries,
          //   });
          // } else {
          setRowData((prev) => {
            return newEntries.concat(prev);
          });
          addEntryAction({
            type: "new",
            entry: newEntries,
          });
          addActionToRegister({
            type: "new",
            newActions: newEntries,
          });
          // }
        }
        onCancel();
      });
    }
  }

  useEffect(() => {
    function handleSelectionToggle(param) {
      if ((param?.source || "").includes("checkbox")) {
        let isSelected = param.node.isSelected();
        let selectedEmployees = form.getFieldValue("selectedMembers") || [];

        let employeesToChange = [];

        if (param.data?.["crewForeman"]?.["crewId"]) {
          const foreman = crews.find(
            ({ crewId }) => crewId === param.data?.crewForeman?.crewId
          );
          if (!!foreman?.crewId) {
            employeesToChange.push(foreman?.crewId);
          }
        }
        for (const crew of param.data.crewMembers) {
          if (crew?.["crewId"]) {
            employeesToChange.push(crew?.["crewId"]);
          }
        }

        if (isSelected) {
          for (const crewId of employeesToChange) {
            if (!selectedEmployees.includes(crewId)) {
              selectedEmployees = [...selectedEmployees, crewId];
            }
          }

          form.setFieldValue("selectedMembers", selectedEmployees);
        } else {
          form.setFieldValue(
            "selectedMembers",
            selectedEmployees.filter(
              (crewId) => !employeesToChange.includes(crewId)
            )
          );
        }
      }
    }

    if (gridApi) {
      gridApi.addEventListener("rowSelected", handleSelectionToggle);
    }

    return () => {
      if (gridApi) {
        gridApi.removeEventListener("rowSelected", handleSelectionToggle);
      }
    };
  }, [gridApi, form]);

  useLayoutEffect(() => {
    if (selectedNodes?.length) {
      form.setFieldValue("punchType", null);
      form.setFieldValue("shiftStatus", null);
    }
    if (!!selectedRow) {
      const formObj = {
        otHours: null,
        workHours: null,
        punchType: selectedRow?.punchType,
        reason: selectedRow?.reason || null,
        salaryType: selectedRow?.salaryType,
        selectedMembers: [selectedRow?.crewId],
        totalOvh: selectedRow?.totalOvh || null,
        employeeRate: selectedRow?.employeeRate,
        shiftStatus: selectedRow?.activityStatus,
        selectedDate: selectedRow?.punchDate || null,
      };
      form.setFieldsValue(formObj);
    }
  }, [selectedRow, selectedNodes]);

  return (
    <Modal
      open={open}
      closable={true}
      centered={true}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      destroyOnClose={true}
      title="Cash Entry Shift Modal"
      data-testid="cash-entries-modal"
      className={`cashEntryModal ${isDarkMode && "cashEntryModalDark"}`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          onClick={onCancel}
          className="mondayButtonRed"
          data-testid="cash-entries-close-btn"
        >
          Cancel
        </MondayButton>,
        !!selectedRow && (
          <MondayButton
            Icon={<LogsIcon />}
            className="mondayButtonBlue"
            onClick={() => setLogsVisible(selectedRow?.entryId)}
          >
            Show Logs
          </MondayButton>
        ),
        <MondayButton Icon={<PlusIcon />} onClick={onSave}>
          Add Entry
        </MondayButton>,
      ]}
    >
      <Form form={form}>
        <section className="employeeSection">
          {RenderDynamicComponents(formFieldsList["Employees"], form)}
        </section>
        <section className="detailsSection">
          {RenderDynamicComponents(formFieldsList["Shift Details"], form)}
          {RenderDynamicComponents(formFieldsList["Amount Details"], form)}
        </section>
      </Form>
      <div
        className={`selection-grid-container ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            gridApi,
            rowData: crewTeams,
            columnDefs: teamShiftColumns
              .slice(0, 2)
              .concat(teamShiftColumns.slice(3)),
            onGridReady,
            pagination: false,
            suppressRowClickSelection: true,
            rowSelection: "multiple",
            defaultColDef: {
              resizable: true,
              enableColResize: true,
              enableRowGroup: false,
              sortable: true,
              filter: true,
              flex: 1,
              suppressSizeToFit: true,
              minWidth: 130,
              suppressDragLeaveHidesColumns: true,
            },
          }}
        />
      </div>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            title: `Entry Logs`,
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: entriesLogs[logsVisible],
          }}
        />
      )}
    </Modal>
  );
}

export default CashEntryShiftModal;

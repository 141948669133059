import {
  ClaimsIcon,
  FolderFileIcon,
  IncidentsIcon,
  SafetyIcon,
} from "../../../../../assets";
import {
  ContactsWhite,
  ApprovalsWhite,
  ClientsWhite,
  EstimationsWhite,
  FleetWhite,
  FleetsInventoryIcon,
  InspectionsWhite,
  LeadsWhite,
  MaintenanceIcon,
  MathActionsIcon,
  NotesIcon,
  OpportunitiesWhite,
  PermitDrawingsWhite,
  ProjectsWhite,
  SchedulingWhite,
  SubcontractorsWhite,
  TasksWhite,
  PaymentsIcon,
  ScheduleOfValuesIcon,
  RentalsIcon,
  FleetsViolationsIcon,
  EstimationsIcon,
  TodosIcon,
  ReportsBlackIcon,
  VendorsSmallIcon,
  ApplicationsIcon,
} from "../../../../../icons";
import { TaskIcon } from "../../../../../icons/NotificationIcons";
import {
  SearchIcon,
  XIcon,
} from "../../../../SidebarPages/Communication/assets";

export const getIcon = (key) => {
  const Icons = {
    Applications: ApplicationsIcon,
    Contacts: ContactsWhite,
    Clients: ClientsWhite,
    estimations: EstimationsWhite,
    Leads: LeadsWhite,
    Opportunities: OpportunitiesWhite,
    Projects: ProjectsWhite,
    "Permit Drawings": PermitDrawingsWhite,
    "Task Manager": TasksWhite,
    Mention: NotesIcon,
    Fleets: FleetWhite,
    "Fleet Inspections": FleetWhite,
    Documentations: PermitDrawingsWhite,
    Approvals: ApprovalsWhite,
    Scheduling: SchedulingWhite,
    Inspections: InspectionsWhite,
    Subcontractors: SubcontractorsWhite,
    "Fleet Maintenance": MaintenanceIcon,
    "Fleets Inventory": FleetsInventoryIcon,
    "Fleet/Incidents": FleetWhite,
    "Fleets Drivers": FleetWhite,
    "Fleet Activity": FleetWhite,
    "Fleets Dispatching": FleetWhite,
    Charges: MathActionsIcon,
    Invoices: MathActionsIcon,
    Claims: ClaimsIcon,
    Hearings: ClaimsIcon,
    Safety: SafetyIcon,
    "Safety Inspection": SafetyIcon,
    "Work Orders": MaintenanceIcon,
    "Fleet Defects": MaintenanceIcon,
    XIcon: XIcon,
    Payments: PaymentsIcon,
    "Schedule Of Values": ScheduleOfValuesIcon,
    Rentals: RentalsIcon,
    "Fleets Violations": FleetsViolationsIcon,
    Estimations: EstimationsIcon,
    Incidents: IncidentsIcon,
    Todos: TodosIcon,
    Reports: ReportsBlackIcon,
    Tasks: TaskIcon,
    Vendors: VendorsSmallIcon,
    Notes: NotesIcon,
    "Last Searches": SearchIcon,
  };

  if (!!key && !!Icons[key]) {
    return Icons[key];
  } else {
    return null;
  }
};

export const addedMissingCategories = [
  "Vendors",
  "Bills",
  "Approvals",
  "Tasks",
  "Notes",
  "Fleets",
  "Reports",
  "Scheduling",
  "Clients",
  "Todos",
  "Work Orders",
  "Fleet Inspections",
  "Schedule Maintenance",
  "Applications",
  "Fleet Activity",
];
export const addedMissingTopicCategories = [
  {
    categoryName: "Bills",
    rowName: "billNumber",
    apiName: "bills",
    primaryKey: "billId",
  },
  {
    categoryName: "Tasks",
    rowName: "taskTitle",
    apiName: "tasksManagement",
    primaryKey: "taskId",
    lazyFetchKeys: [
      "taskId",
      "taskTitle",
      "taskAssignedTo",
      "createdAt",
      "taskTopic",
      "taskStatus",
      "createdBy",
    ],
    newRowName: true,
  },
  {
    categoryName: "Notes",
    rowName: "relatedTo",
    apiName: "notes",
    primaryKey: "noteId",
    lazyFetchKeys: [
      "noteId",
      "topicCategory",
      "mentions",
      "createdAt",
      "category",
      "createdBy",
      "linkTo",
    ],
    newRowName: true,
  },
  {
    categoryName: "Reports",
    rowName: "reportName",
    apiName: "reportsAvailable",
    primaryKey: "reportId",
    lazyFetchKeys: ["categoryName", "reportId"],
    newRowName: true,
  },
  {
    categoryName: "Todos",
    rowName: "todoName",
    apiName: "todos",
    primaryKey: "todoId",
    lazyFetchKeys: [
      "todoId",
      "todoName",
      "todoUser",
      "createdAt",
      "todoStatus",
      "createdBy",
      "recordName",
      "linkTo",
    ],
    newRowName: true,
  },
  {
    categoryName: "Fleet Defects",
    rowName: "defectName",
    apiName: "fleetDefects",
    primaryKey: "defectId",
    lazyFetchKeys: ["defectName", "defectId"],
    newRowName: true,
  },
  {
    categoryName: "Fleet Inspections",
    rowName: "fleetName",
    apiName: "fleetInspections",
    primaryKey: "inspectionId",
    lazyFetchKeys: ["defectName", "inspectionId"],
    newRowName: true,
  },
  {
    categoryName: "Work Orders",
    rowName: "fleetName",
    apiName: "workOrders",
    primaryKey: "workOrderId",
    lazyFetchKeys: ["workOrderId", "fleetName"],
    newRowName: true,
  },
  {
    categoryName: "Schedule Maintenance",
    rowName: "fleetName",
    apiName: "serviceMaintenance",
    primaryKey: "serviceId",
    lazyFetchKeys: ["serviceId", "fleetName"],
    newRowName: true,
  },
  {
    categoryName: "Subcontractors",
    rowName: "accountName",
    apiName: "accounts",
    primaryKey: "accountId",
    lazyFetchKeys: ["accountName", "teamsConfiguration", "accountRecordType"],
    newRowName: true,
    filterKey: "accountRecordType",
    filterValue: "Subcontractors",
  },
  {
    categoryName: "Clients",
    rowName: "accountName",
    apiName: "accounts",
    primaryKey: "accountId",
    lazyFetchKeys: ["accountName", "teamsConfiguration", "accountRecordType"],
    newRowName: true,
    newRowName: true,
    filterKey: "accountRecordType",
    filterValue: "Client",
  },
  {
    categoryName: "HR Management",
    rowName: "crewName",
    apiName: "crews",
    primaryKey: "employeeId",
    lazyFetchKeys: ["crewName", "crewId", "crewPosition"],
  },
];

export function generateLinkTo(curr, element) {
  switch (curr.apiName) {
    case "documentation":
      return `/${
        element.categoryName ? element.categoryName.toLowerCase() : ""
      }/${element.recordId}?tab=Documentation&docId=${element.docId}`;
    case "notes":
      return element.linkTo || "";
    case "fleetDefects":
      return `/defects/${element.defectId}`;
    case "fleetActivity":
      return `/fleets/${element.fleetId}?tab=Activity`;
    case "serviceMaintenance":
      return `/scheduleMaintenance/${element.serviceId}`;
    case "todos":
      return element.linkTo || `/${curr.apiName}/${element.todoId}`;
    case "fleet":
      return `/fleets/${element.fleetId}`;
    case "crews":
      return `/hrManagement/crews/${element.crewId}`;
    case "claims":
      return `/claims/${element.claimId}`;
    default:
      return curr.pathName
        ? curr.pathName.replace(":id", element[curr.primaryKey])
        : `/${curr.apiName}/${element[curr.primaryKey]}`;
  }
}

export function generateLabel(curr, element) {
  switch (true) {
    case Boolean(curr?.otherName):
      return `${element[curr.otherName]} ${curr?.prefix ? "|" : ""} ${
        curr?.prefix || ""
      } ${element[curr?.rowName]}`;
    default:
      return `${element[curr?.rowName]} ${
        element.scheduleName || element.recordName || element.crewPosition
          ? `${
              element.scheduleName || element.recordName || element.crewPosition
            } |`
          : ""
      }`;
  }
}

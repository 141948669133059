import { filterTables } from "../filterTables";

export const getCreditMemoServices = async () => {
  const creditMemos = await filterTables(
    "charges",
    "chargeType",
    "Credit Memo"
  );

  return creditMemos.map((credit) => ({
    creditAmount: credit.chargeAmount,
    categoryFrom: credit.categoryFrom,
    chargeId: credit.chargeId,
    chargeNumber: credit.chargeNumber,
    chargeStatus: credit.chargeStatus,
    chargeType: credit.chargeType,
    createdAt: credit.createdAt,
    projectAddress: credit.projectAddress,
    taxAmount: credit.taxAmount,
    totalPrice: credit.chargeItems?.reduce(
      (acc, item) => acc + (item?.totalities?.totalPrice || item.total),
      0
    ),
    totalTax: credit.chargeItems?.reduce(
      (acc, item) => acc + (item?.totalities?.includedTaxes || item.taxAmount),
      0
    ),
    items: credit.chargeItems?.map((item) => ({
      categoryFrom: credit.categoryFrom,
      chargeId: credit.chargeId,
      chargeNumber: credit.chargeNumber,
      chargeStatus: credit.chargeStatus,
      chargeType: credit.chargeType,
      createdAt: credit.createdAt,
      projectAddress: credit.projectAddress,

      serviceName: item?.name || item.label,
      category: item?.category || credit.categoryFrom,
      amount: item?.amount || item?.totalities?.totalPrice,
      creditAmount: item?.creditAmount || item.price,
      creditPercentage:
        (item?.creditPercentage ||
          (item.price + item.taxAmount) / item?.totalities?.totalPrice) * 100,
      taxAmount: item.taxAmount,
    })),
  }));
};

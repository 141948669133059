import axios from "axios";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import moment from "moment";
import { linxupEndpoints } from "../../components/SidebarPages/Fleet/fleetsLive/utils";

export const getGeoFencesActivities = async () => {
  try {
    const [fleetDispatch, geoActivities] = await Promise.all([
      fetchAllData("fleetDispatching", "fleetDispatching", "dispatchId"),

      axios({
        method: "post",
        url: linxupEndpoints.alerts,
        data: {
          fromDate: moment().subtract(2, "days").valueOf(),
          toDate: moment().subtract(1, "hour").valueOf(),
        },
      }),
    ]);

    let allGeoActivities =
      geoActivities && geoActivities.status === 200
        ? geoActivities.data.data.alerts
        : [];

    const allActivities = [];

    fleetDispatch.forEach((dispatch) => {
      allGeoActivities.forEach((activity) => {
        const fleetName = activity.firstName + activity.lastName;

        const isFleet =
          dispatch.fleetName.replace(/\s/g, "") ===
          fleetName.replace(/\s/g, "");

        isFleet &&
          allActivities.push({
            ...activity,
            fleetName: dispatch.fleetName,
            projectName:
              dispatch.routes.length > 0
                ? dispatch.routes[0].dropOffLocation
                : "",
          });
      });
    });

    return allActivities;
  } catch (error) {
    console.error(
      "data source function getGeoFencesActivities error: " + error
    );
  }
};

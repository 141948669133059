import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { filterTables } from "../filterTables";

export const fleetDocuments = async () => {
  try {
    const fleetDocs = await filterTables(
      `documentation`,
      "categoryName",
      "Fleet"
    );

    const allFleets = await fetchAllData(`fleet`, "fleet", "fleetId");

    return fleetDocs
      .map((doc) => {
        const fleet = allFleets.find(({ fleetId }) => fleetId === doc.recordId);

        if (!!fleet?.fleetName) {
          return { ...doc, ...fleet };
        }
      })
      .filter(Boolean);
  } catch (error) {
    console.error("data source function fleetDocuments error: " + error);
  }
};

import { useEffect, useContext } from "react";
import { useSelector } from "react-redux";

import { columnDefs } from "./data";
import { InputComponent } from "../../../components";
import {
  FleetsLiveContext,
  findPaginationSize,
  saveLocalPreferences,
} from "../../utils";
import PayrollLayout from "../../../../../pages/Payroll/Layout/PayrollLayout";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

import "./LiveAuditOverview.scss";
import "../LiveLocationsView/LiveLocationsView.scss";

/**
 * @typedef AuditStatistic
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string} auditDate
 * @property {string} auditStatus
 * @property {number} activitiesToAudit
 * @property {number} manualEventsCreated
 */

function LiveAuditOverview() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    fleet,
    selectedDate,
    changeMenuKey,
    updateAuditRange,
    auditOverviewData,
    changeDateHandler,
  } = useContext(FleetsLiveContext);

  useEffect(() => {
    if (auditOverviewData["called"]) {
      return;
    }

    updateAuditRange({
      dateRange: auditOverviewData?.["dateRange"] ?? [],
    });
  }, [auditOverviewData, fleet]);

  async function onLoadAudit(data) {
    if (
      selectedDate.valueOf() === dayjsNY(data.auditDate).startOf("D").valueOf()
    ) {
      saveLocalPreferences({
        auditExternalRedirect: {
          fleetId: data.fleetId,
          fleetName: data.fleetName,
        },
      });
      changeMenuKey("AUDIT");
    } else {
      await changeDateHandler(dayjsNY(data.auditDate).startOf("D")).then(
        (res) => {
          if (res) {
            saveLocalPreferences({
              auditExternalRedirect: {
                fleetId: data.fleetId,
                fleetName: data.fleetName,
              },
            });
            changeMenuKey("AUDIT");
          }
        }
      );
    }
  }

  return (
    <div
      className={`live-locations-view ${
        isDarkMode ? "live-locations-dark" : ""
      }`}
      id="audit-overview-grid"
    >
      <div
        className={`main-payroll-view ${
          isDarkMode ? "main-payroll-view-dark" : ""
        }`}
      >
        <PayrollLayout
          {...{
            title: "",
            columnDefs,
            hasNew: false,
            rowHeight: 38,
            headerHeight: 40,
            suppressDoubleClickEdit: true,
            rowData: auditOverviewData?.["data"] ?? [],
            paginationPageSize: findPaginationSize("audit-overview-grid", 38),
            context: {
              onLoadAudit,
            },
            additionalActionsButtons: [
              <InputComponent
                key="range-picker"
                {...{
                  showTime: false,
                  onChange(dateRange) {
                    updateAuditRange({ dateRange });
                  },
                  type: "rangePicker",
                  noFormItem: true,
                  defaultValue: auditOverviewData.dateRange,
                  allowClear: false,
                }}
              />,
            ],
          }}
        />
      </div>
    </div>
  );
}

export default LiveAuditOverview;

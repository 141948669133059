import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { processInvoices } from "./helpers";

export const retrieveInvoices = async () => {
  try {
    return await fetchAllData("invoices", "invoices", "invoiceId", () => {}, {
      keysToInclude: JSON.stringify([
        "invoiceId",
        "invoiceNumber",
        "invoiceDate",
        "invoiceStatus",
        "toDate",
        "amountDue",
        "accountName",
        "totalInvoiceAmount",
        "quickBooksNumber",
        "projectName",
        "memos",
      ]),
    }).then(async (result) => {
      let processedInvoices = processInvoices(result);
      return processedInvoices;
    });
  } catch (error) {
    console.log("error", error);
  }
};

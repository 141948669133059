import _ from "lodash";
import { message } from "antd";
import { fetchByDate } from "../../../../../utils";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import { fetchAllData } from "../../../../../utils";

/**
 * @typedef {import("../../../FleetMaintenanceView/types").FleetActivityType} Activities
 */

/**
 * @typedef {import("../../../FleetMaintenanceView/types").DispatchType} Dispatches
 */

/**
 * @typedef {import("../../../FleetMaintenanceView/types").FleetType} Fleet
 */

/**
 * @typedef CreatedAlert
 * @property {number} alertDateTime
 * @property {string} alertUUID
 * @property {string} fenceName
 * @property {string} deviceSerialNumber
 * @property {string} vin
 * @property {string} alertCode
 * @property {string} geofenceUUID
 * @property {string} creationReason
 * @property {string} createdBy
 * @property {string} cognitoUserId
 * @property {number} createdAt
 * @property {string} fenceGroup
 * @property {string} formattedAlertDateTime
 */

/**
 * @typedef ChangedAlert
 * @property {number} alertDateTime
 * @property {boolean} audited
 * @property {string} [activityId]
 */

/**
 * @typedef LinkedStop
 * @property {number} beginAlert
 * @property {number} endAlert
 * @property {string} beginUUID
 * @property {string} endUUID
 * @property {string} fenceName
 */

/**
 * @typedef VehicleAudit
 * @property {string} auditStatus
 * @property {string[]} auditedActivities
 * @property {Record<string, ChangedAlert>} changed
 * @property {Record<string, CreatedAlert>} createdAlerts
 * @property {string[]} discarded
 * @property {LinkedStop[]} linkedStops
 */

/**
 * @typedef Audit
 * @property {string} auditDate
 * @property {Record<string, VehicleAudit>} alertChanges
 */

/**
 * @typedef AuditOverviewParam
 * @property {[ReturnType<dayjsNY>, ReturnType<dayjsNY>]} dateRange
 * @property {Fleet[]} fleet
 */

/**
 * @typedef AuditStatistic
 * @property {string} fleetName
 * @property {string} fleetId
 * @property {string} auditDate
 * @property {string} auditStatus
 * @property {number} activitiesToAudit
 * @property {number} manualEventsCreated
 */

/**
 * @param {AuditOverviewParam} param
 */
async function getAuditOverview(param) {
  const { dateRange, fleet } = param;
  let err = false;

  message.loading({
    content: "Loading audits data...",
    key: "auditsLoad",
    duration: 0,
  });

  /** @type {[Activities[], Dispatches[], any[]]} */
  const [activities, dispatches, audits] = await Promise.all([
    fetchAllData({
      endpoint: "fleetActivity",
      pathName: "",
      resultId: "activityId",
      resultPosition: "fleetActivity",
      otherStringParams: {
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "activityDate",
                formula: "is_between",
                value: dateRange.map((e) => e.startOf("D").format()),
                operator: "AND",
              },
              {
                column: "activityStatus",
                formula: "is_not",
                value: "Cancelled",
                operator: "AND",
              },
            ],
          },
        ]),
      },
    }),
    fetchByDate(
      "fleetDispatching",
      dateRange.map((e) => e.startOf("D").format()),
      "dispatchDate"
    ),
    fetchByDate(
      "fleetAudits",
      dateRange.map((e) => e.startOf("D").format()),
      "auditDate"
    ),
  ]).catch((err) => {
    message.error({
      content: "Something went wrong while loading audits data",
      key: "auditsLoad",
    });
    console.log("Error getting data: ", err);
    err = err;
    return [];
  });

  if (err) {
    return;
  }

  message.destroy("auditsLoad");

  /** @type {AuditStatistic[]} */
  const stats = [];

  const activitiesGroup = _.groupBy(activities, "activityDate");
  const dispatchesGroup = _.groupBy(dispatches, "fleetId");
  const auditGroup = _.keyBy(audits, "auditDate");
  const fleetGroup = _.keyBy(fleet, "fleetId");

  for (const fleetId in dispatchesGroup) {
    const groupedFleetDispatches = _.groupBy(
      dispatchesGroup[fleetId],
      "dispatchDate"
    );

    for (const date in groupedFleetDispatches) {
      const auditForVehicle =
        auditGroup?.[date]?.["alertChanges"]?.[fleetId] || {};

      const activities = (activitiesGroup[date] || []).filter(
        (e) => e.fleetId === fleetId
      );

      let activitiesToAudit = activities.length;
      let auditStatus = "PENDING";

      if (auditForVehicle) {
        if (auditForVehicle?.["auditStatus"] === "UPLOADED") {
          auditStatus = "UPLOADED";
        } else {
          /** @type {Set<string>} */
          const auditedActivities = new Set(
            Object.values(auditForVehicle["changed"] || {}).flatMap(
              (e) => e["activityId"] ?? []
            )
          );

          if (!activities.length) {
            auditStatus = "PENDING";
          } else if (auditedActivities.size === activities.length) {
            auditStatus = "COMPLETED";
            activitiesToAudit = 0;
          } else if (auditedActivities.size) {
            auditStatus = "PENDING";
            for (const activity of activities) {
              if (auditedActivities.has(activity.activityId)) {
                --activitiesToAudit;
              }
            }
          }
        }
      }

      stats.push({
        fleetId,
        fleetName: fleetGroup[fleetId]["fleetName"],
        auditDate: date,
        manualEventsCreated: Object.values(
          auditForVehicle["createdAlerts"] || {}
        ).length,
        activitiesToAudit,
        auditStatus,
      });
    }
  }

  return stats;
}

export default getAuditOverview;

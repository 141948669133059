const getGridFilters = (isFromHrManagement = false) => {
  return [
    {
      globalKeyId: "8fdejjjjjj8a-400f-b352-6354cb0f22ec",
      label: "Name",
      type: "Dropdown",
      key: "crewName",
    },
    !isFromHrManagement && {
      globalKeyId: "8f454548a-400f-b352-6354cb0f22ec",
      label: "Status",
      type: "Dropdown",
      key: "crewStatus",
    },
    isFromHrManagement && {
      globalKeyId: "8f454548a-400f-b352-6354cb0f22ec",
      label: "Status",
      type: "Dropdown",
      key: "employeeStatus",
    },
    {
      globalKeyId: "ace23714-e23c-45fe-83ec-0d0ddcdf0b24",
      label: "Employee ID",
      type: "Dropdown",
      key: "employeeId",
    },
    !isFromHrManagement && {
      globalKeyId: "8fdedbc3-0b8a-3433f-b352-6354cb0f22ec",
      label: "Role",
      type: "Dropdown",
      key: "crewPosition",
    },
    isFromHrManagement && {
      globalKeyId: "8fdedbc3-0b8a-3433f-b352-6354cb0f22ec",
      label: "Role",
      type: "Dropdown",
      key: "role",
    },
    isFromHrManagement && {
      globalKeyId: "8fdedbc3-0b8a-3433f-b159-6354cb0f22ec",
      label: "Salary Type",
      type: "Dropdown",
      key: "salaryType",
    },
    isFromHrManagement && {
      globalKeyId: "ace23714-400f-3433f-b352-0d0ddcdf0b24",
      label: "Rate",
      type: "NumberRange",
      key: "employeeRate",
    },
  ].filter(Boolean);
};

export default getGridFilters;

import { v4 as uuidv4 } from "uuid";
import dayjs from "dayjs";
import customParseFormat from "dayjs/plugin/customParseFormat";
import { toCamelCase } from "../components/pages/Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";
import { employeeSpreadsheetHeaders } from "../components/SidebarPages/HrManagement/utils/employeeSpreadsheetHeaders";

import {
  getNextColumn,
  parsedCell,
  getCellName,
  getIndexFromCell,
  getColumnFromCell,
} from "../components/pages/Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { message } from "antd";

dayjs.extend(customParseFormat);

export const EMPTY_ROW = {
  crewName: "",
  employeeId: "",
  crewPosition: "",
  crewStatus: "",
  employeeRate: "",
  foreman: false,
  salaryType: "hourly",
};

const defaultDegColumns = [
  "employee number",
  "employee list",
  "rate",
  "department",
  "role",
  "crew",
  "data 1",
];

// Helper function to check if a row is empty
function isRowEmpty(rowData) {
  return Object.values(rowData).every(
    (value) => value === "" || value === undefined || value === null
  );
}

/**
 * Function that parses the DEG sheet of an uploaded Excel
 * @param {Object} deg The DEG sheet object
 * @param {File} fileData Additional file data to be put in the row data
 */
function employeeUploadParser(
  deg = {},
  fileData,
  accountName,
  employeeType = "crews"
) {
  /**
   * The idea is to search for the index of the header by finding
   * a cell that has the content of a known header.
   *
   * We find the total number of columns by iterating the header index until
   * we have an empty cell
   *
   * We can find the total number of rows by looking at how many
   * cells have the same column name as the previously found header.
   *
   * Then we simply iterate all the data and turn the header names
   * in camel case to form the new row data
   */
  let headerRowIndex = -1;
  let numOfValidColumns = 0;
  let totalNumOfRows = 0;
  let headerStartColumn = "";
  let headerEndColumn = "";
  let nameCol = "";
  let parsedData = [];

  // Define expected headers for each employee type
  const headerConfig = employeeSpreadsheetHeaders();
  // const headerConfig = {
  //   crews: [
  //     "Employee Number",
  //     "Employee List",
  //     "Role",
  //     "Rate",
  //     "Crew",
  //     "Foreman",
  //   ],
  //   engineers: ["First Name", "Last Name", "Email", "Pay Rate", "Salary Type"],
  //   architects: ["First Name", "Last Name", "Email", "Pay Rate", "Salary Type"],
  //   drivers: ["Full Name", "Driver Type"],
  // };

  // Find header rows
  for (const cell in deg) {
    let cellContent = parsedCell(deg[cell]?.w);

    if (
      headerConfig[employeeType].some((header) =>
        cellContent?.toLowerCase()?.includes(header.toLowerCase())
      )
    ) {
      headerRowIndex = getIndexFromCell(cell);
      nameCol = getColumnFromCell(cell);
      break;
    }
  }

  // if the data is invalid and there is no header
  if (headerRowIndex === -1) {
    message.error(
      "You aren't using the right format for " + employeeType + " spreadsheet"
    );
    return parsedData;
  }

  // Validate all required columns are present
  let foundHeaders = new Set();
  let currentColumn = headerStartColumn || "A";
  while (true) {
    let cellName = getCellName(currentColumn, headerRowIndex);
    let cellContent = parsedCell(deg[cellName]?.w);
    if (!cellContent) break;

    headerConfig[employeeType].forEach((header) => {
      if (cellContent.toLowerCase().includes(header.toLowerCase())) {
        foundHeaders.add(header);
      }
    });

    currentColumn = getNextColumn(currentColumn);
  }

  if (foundHeaders.size !== headerConfig[employeeType].length) {
    const missingHeaders = headerConfig[employeeType].filter(
      (header) => !foundHeaders.has(header)
    );
    message.error(
      `You aren't using the right format for " 
        ${employeeType} 
        " spreadsheet. You Missing required columns: ${missingHeaders.join(
          ", "
        )}`
    );
    return parsedData;
  }

  // we get the start and end columns to create a range
  let lastCheckedColumn = "";
  while (!headerStartColumn) {
    let col = getNextColumn(lastCheckedColumn);
    let cellName = getCellName(col, headerRowIndex);
    if (headerConfig[employeeType].includes(parsedCell(deg[cellName]?.w))) {
      headerStartColumn = col;
      break;
    } else {
      lastCheckedColumn = col;
    }
  }

  while (!headerEndColumn) {
    let col = getNextColumn(lastCheckedColumn);
    let cellName = getCellName(col, headerRowIndex);
    if (!parsedCell(deg[cellName]?.w)) {
      headerEndColumn = lastCheckedColumn;
      break;
    } else {
      ++numOfValidColumns;
      lastCheckedColumn = col;
    }
  }

  //we get the total number of rows by getting the maximum number of row that have a name
  //we check for the name field since the other ones may not be yet completed
  for (const cell in deg) {
    if (getColumnFromCell(cell) === nameCol) {
      ++totalNumOfRows;
    }
  }

  for (let i = 1; i <= totalNumOfRows; i++) {
    let tmpData = {};
    let rowIndexToCheck = headerRowIndex + i;
    let dataColumn = headerStartColumn;

    for (let j = 0; j < numOfValidColumns; j++) {
      let cellName = getCellName(dataColumn, rowIndexToCheck);
      let key = toCamelCase(
        parsedCell(
          deg[getCellName(dataColumn, headerRowIndex)]?.w
        )?.toLowerCase()
      );
      tmpData[key] = parsedCell(deg[cellName]?.w);
      dataColumn = getNextColumn(dataColumn);
    }

    // Skip if row is empty
    if (isRowEmpty(tmpData)) {
      continue;
    }

    let employeeFields = {};

    switch (employeeType) {
      case "crews":
        employeeFields = {
          accountName,
          crewId: uuidv4(),
          crewName: `${tmpData["employeeList"]
            .split(",")[1]
            ?.replaceAll(" ", "")} ${tmpData["employeeList"]
            .split(",")[0]
            .replace(",", "")}`,
          crewPosition: tmpData["role"],
          crewStatus: "Active",
          employeeId: `${accountName}-${tmpData["employeeNumber"]}`,
          // fingerCheckId: Number,
          salaryType: "Hourly",
          employeeNumber: tmpData?.employeeNumber,
          employeeRate:
            parseFloat(
              tmpData["rate"].replaceAll("$", "").replaceAll(" ", "")
            ) || 0,
          foreman:
            tmpData?.["role"]?.toLowerCase() === "foreman" ||
            !!tmpData?.foreman,
          members: [],
          teamsConfiguration: [],
          crewTeam: tmpData["crew"],
          uploadId: fileData?.uid,
          uploadName: fileData?.name.replace(".xlsx", "")?.trim(),
        };
        break;
      case "engineers":
      case "architects":
        employeeFields = {
          firstName: tmpData["firstName"],
          lastName: tmpData["lastName"],
          email: tmpData["email"],
          employeeRate: tmpData["payRate"],
          salaryType: tmpData["salaryType"],
        };
        break;
      // case "drivers":
      //   employeeFields = {
      //     crewName: tmpData["fullName"],
      //     crewPosition: "Driver",
      //     driverType: tmpData["driverType"],
      //     salaryType: "Hourly",
      //   };
      // break;
    }

    parsedData.push({ ...employeeFields });
  }

  return {
    parsedData,
    // totalHoursPerEmployee,
  };
}

export default employeeUploadParser;

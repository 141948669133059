import { createContext, useContext, useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";

import { driveApi } from "../../../../integrations/DriveRequest";
import { compareIncluding } from "../../utils";
import { companyDetails } from "../../../../helpers/constants";
import { dayjsNY } from "../../../DateComponents/contants/DayjsNY";

export const InspectionContext = createContext({
  inspection: undefined,
  setInspection: () => {},
  images: [],
  setImages: () => {},
  getImage: async (id) => {},
  structuredQuestions: [],
  structuredEstimations: [],
  changes: false,
  setChanges: () => {},
  activeService: undefined,
  setActiveService: () => {},
  sign: null,
  setSign: () => {},
  isDraft: false,
  completedServices: [],
  setCompletedServices: () => {},
  activeServiceObj: {},
  visible: false,
  setVisible: () => {},
  newImagesCount: 0,
  setNewImagesCount: () => {},
  logsData: [],
  setLogsData: () => {},
  inspectionAddress: "",
  setInspectionAddress: () => {},
  locations: undefined,
  setLocations: () => {},
});

export const useInspection = () => useContext(InspectionContext);

export const InspectionProvider = ({ inspectionId, children }) => {
  const [inspection, setInspection] = useState();
  const [images, setImages] = useState([]);
  const [changes, setChanges] = useState(false);
  const [activeService, setActiveService] = useState();
  const [sign, setSign] = useState(null);
  const [completedServices, setCompletedServices] = useState([]);
  const [visible, setVisible] = useState(false);
  const [newImagesCount, setNewImagesCount] = useState(0);
  const [locations, setLocations] = useState([]);
  const [logsData, setLogsData] = useState([]);

  // temporary
  const [inspectionAddress, setInspectionAddress] = useState("");

  const { accessToken } = useSelector((state) => state.accessToken);
  const { proposedTypeOfWork } = useSelector(
    (state) => state.proposedTypeOfWork
  );

  const driveRequest = driveApi({ accessToken });

  const isDraft = compareIncluding(inspection?.inspectionStatus, "draft");

  const structuredQuestions = inspection?.inspectionReport?.map((item) => {
    const {
      foreGroundColor: color = "#fff",
      colorCode: backgroundColor = "#1264A3",
    } =
      proposedTypeOfWork.find(
        ({ workName }) => workName === item?.serviceType
      ) || {};

    return {
      estimationId: item?.estimationId ?? "",
      estimationNumber: item?.estimationNumber ?? 0,
      type: item?.serviceType,
      color,
      backgroundColor,
      elevationsInspected: item?.elevationsInspected ?? [],
      serviceQuestions: item?.questions?.reduce(
        (acc, cur, i) =>
          !![...acc]?.filter((it) =>
            compareIncluding(it?.categoryName, cur?.categoryName)
          ).length
            ? [...acc]?.map((it) =>
                compareIncluding(it?.categoryName, cur?.categoryName)
                  ? { ...it, questions: [...it.questions, { ...cur }] }
                  : { ...it }
              )
            : [
                ...acc,
                {
                  categoryName: cur?.categoryName,
                  categoryId: i + 1,
                  questions: [{ ...cur }],
                },
              ],
        []
      ),
    };
  });

  const uniqueEstimations = structuredQuestions?.reduce((acc, current) => {
    if (!acc.some((item) => item.estimationId === current.estimationId)) {
      acc.push({
        estimationId: current?.estimationId,
        estimationNumber: current?.estimationNumber,
      });
    }
    return acc;
  }, []);

  const structuredEstimations = uniqueEstimations?.map((estimation) => {
    const services =
      structuredQuestions
        ?.filter(
          (item) =>
            item?.estimationId === estimation?.estimationId &&
            item?.serviceQuestions.length > 0
        )
        .map((item) => ({
          ...item,
          elevationsInspected: item?.elevationsInspected?.filter(
            (elevItem) => elevItem?.estimationId === item?.estimationId
          ),
        })) || [];

    if (!!services.length) {
      return {
        estimationId: estimation?.estimationId,
        estimationNumber: estimation?.estimationNumber,
        services,
      };
    }

    return;
  });

  const activeServiceObj =
    structuredEstimations
      ?.find(({ estimationId }) => estimationId === activeService?.estimationId)
      ?.services?.find(({ type }) => type === activeService?.type) ?? {};

  const getImage = async (fileId) => {
    const { src, blob } = await driveRequest.getImageSrc(fileId);
    return {
      fileId,
      src,
      blob,
    };
  };

  useEffect(() => {
    if (inspectionId) {
      const fetchInspectionData = async () => {
        try {
          const [companiesRes, inspectionsRes] = await Promise.all([
            API.get("companies", "/companies"),
            API.get("inspections", `/inspections/${inspectionId}`),
          ]);
          const projectRes = await API.get(
            "filterProjects",
            `/filterProjects/${inspectionsRes.projectId}`
          );
          const folderForInspection =
            projectRes?.googleDriveFolderIds?.inspectionsObject;

          const companyNumber = companiesRes?.filter(
            ({ companyName: company }) =>
              compareIncluding(company, companyDetails.companyName)
          )[0]?.phoneNumber;

          const inspectionReportImages =
            inspectionsRes?.inspectionReport?.flatMap(({ questions }) =>
              questions?.flatMap(({ questionId, fileIds }) => ({
                id: questionId,
                files: fileIds,
              }))
            );

          let structuredImages = [];

          for (const element of inspectionReportImages) {
            const { id, files } = element;
            let sourcedImages = [];
            for (const fileId of files) {
              sourcedImages.push(await getImage(fileId));
            }
            structuredImages.push({ id, images: [...sourcedImages] });
          }
          let parentId = inspectionsRes?.inspectionFolderId;
          if (folderForInspection === parentId || parentId === null) {
            parentId = await driveRequest.getFolderIdOrCreate({
              name: `${inspectionsRes.inspectionType} ${dayjsNY(
                inspectionsRes?.createdAt
              ).format("DD/MM/YYYY HH:mm:ss")}`,
              parents: [inspectionsRes?.inspectionFolderId],
            });
          }

          setImages(
            [...structuredImages]?.filter(({ images }) => !!images?.length)
          );
          const accRes = await API.get(
            "accounts",
            `/accounts/${projectRes.accountId}`
          );
          setInspection({
            ...inspectionsRes,
            inspectionFolderId: parentId,
            parentId,
            companyNumber,
            projectInspectedStatus: projectRes.inspectionStatus,
            renter: {
              name: accRes?.accountName,
              phone: accRes?.accountPhone,
            },
          });
          setSign(inspectionsRes?.inspectionSignature?.sign ?? null);
          setInspectionAddress(inspectionsRes?.inspectionAddress ?? "");
        } catch (e) {
          console.error(e);
        }
      };
      fetchInspectionData();
    }
  }, [accessToken]);

  const value = {
    inspection,
    setInspection,
    images,
    setImages,
    getImage,
    structuredQuestions,
    structuredEstimations,
    changes,
    setChanges,
    activeService,
    setActiveService,
    sign,
    setSign,
    isDraft,
    completedServices,
    setCompletedServices,
    activeServiceObj,
    visible,
    setVisible,
    newImagesCount,
    setNewImagesCount,
    logsData,
    setLogsData,
    inspectionAddress,
    setInspectionAddress,
    locations,
    setLocations,
  };

  return (
    <InspectionContext.Provider value={value}>
      {children}
    </InspectionContext.Provider>
  );
};

import { useState, useImperativeHandle, useContext, forwardRef } from "react";
import { useSelector } from "react-redux";
import { Menu } from "antd";

import { SIDER_ITEMS } from "../../data";
import {
  FleetsLiveContext,
  loadLivePreference,
  saveLocalPreferences,
} from "../../utils";

import "./FleetLiveSider.scss";

const FleetLiveSider = forwardRef((_, ref) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { changeMenuView } = useContext(FleetsLiveContext);

  const [collapsed, setCollapsed] = useState(loadLivePreference("liveSidebar"));
  const [selectedKeys, setSelectedKeys] = useState([
    loadLivePreference("pageLoaded"),
  ]);

  useImperativeHandle(
    ref,
    () => {
      return {
        toggleCollapsed() {
          let mapView = document.getElementById("live-map-view");
          if (mapView) {
            if (!collapsed) {
              mapView.style.width = "calc(100dvw - 65px)";
            } else {
              mapView.style.width = "calc(100dvw - 200px)";
            }
          }

          let mapController = document.getElementById("live-map-controller");
          if (mapController) {
            let sideWidth =
              loadLivePreference("mapSidebar") === "expanded" ? "18vw" : "0px";
            let tripWidth = loadLivePreference("sidebarTripLocation")
              ? "18vw"
              : "0px";
            if (!collapsed) {
              mapController.style.width = `calc(100dvw - 65px - ${sideWidth} - ${tripWidth})`;
            } else {
              mapController.style.width = `calc(100dvw - 200px - ${sideWidth} - ${tripWidth})`;
            }
          }

          let reportController = document.getElementById(
            "reports-view-controller"
          );
          if (reportController) {
            if (!collapsed) {
              reportController.style.width = "calc(100dvw - 65px)";
            } else {
              reportController.style.width = "calc(100dvw - 200px)";
            }
          }

          saveLocalPreferences({ liveSidebar: !collapsed });
          setCollapsed(!collapsed);
        },
        getCollapsedState() {
          return collapsed;
        },
        changeMenuKey(key) {
          changeMenuView(key);
          setSelectedKeys([key]);
        },
      };
    },
    [collapsed]
  );

  return (
    <div className="fleet-live-sider">
      <Menu
        {...{
          items: SIDER_ITEMS(),
          "data-testid": "fleet-live-sider",
          defaultSelectedKeys: [loadLivePreference("pageLoaded")],
          mode: "inline",
          selectedKeys,
          theme: isDarkMode ? "dark" : "light",
          inlineCollapsed: collapsed,
          onSelect(e) {
            changeMenuView(e.key);
            setSelectedKeys([e.key]);
          },
        }}
      />
    </div>
  );
});

export default FleetLiveSider;

import React, {
  useRef,
  useState,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import dayjs from "dayjs";
import { Table } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";

import {
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../../../commonComponents";
import DegModalContext from "../DegModalContext";
import { columnDefs } from "../DegStep/degStepColumns";
import ShiftHeader from "./HeaderComponents/ShiftHeader";
import { OVERHEAD_ENTRY_TYPES } from "../../degModalData";
import CrewAnalytics from "../CrewAnalytics/CrewAnalytics";
import DegNotFound from "../NotFoundModal.jsx/DegNotFound";
import ControlHeader from "./HeaderComponents/ControlHeader";
import ShiftEditModal from "../ShiftEditModal/ShiftEditModal";
import DegDetailsModal from "../DetailsModal/DegDetailsModal";
import LastStepHeader from "./HeaderComponents/LastStepHeader";
import TeamShiftsModal from "../TeamShiftsModal/TeamShiftsModal";
import DegWarningsModal from "../WarningsModal/DegWarningsModal";
import DegLocationsModal from "../LocationsModal/DegLocationsModal";
import OvertimeNamesModal from "../OvertimeModal/OvertimeNamesModal";
import CategoryCostModal from "../CategoryCostModal/CategoryCostModal";
import createPDF from "../../../../../../../../integrations/AgGridToPdf";
import TeamSelectionModal from "../TeamSelectionModal/TeamSelectionModal";
import ShiftsToSplitModal from "../ShiftsToSplitModal/ShiftsToSplitModal";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import UploadEntriesModal from "../UploadEntriesModal/UploadEntriesModal";
import { compareIncluding } from "../../../../../../../SidebarPages/utils";
import JobsiteMatchDetails from "../JobsiteMatchDetails/JobsiteMatchModal";
import CostDispersionModal from "../CostDispersionModal/CostDispersionModal";
import { existingShiftsColumns } from "../TeamShiftsModal/teamShiftModalData";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import CashEntryShiftModal from "../../CashEntryShiftModal/CashEntryShiftModal";
import RadiusToleranceModal from "../RadiusToleranceModal/RadiusToleranceModal";
import { TickIcon } from "../../../../../../Settings/settingsComponents/Roles/src";
import { WarningTriangle } from "../../../../../../../SidebarPages/DynamicView/src";
import PayrollActivityModal from "../../../../Activity/components/PayrollActivityModal";
import MultipleHrShiftsModal from "../../AddMultipleHrShiftsModal/MultipleHrShiftsModal";
import { CrewsHeader } from "../../../../../../Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";

const DegHeader = forwardRef(function (
  {
    addData = () => {},
    onSearch = () => {},
    shiftsColumnDefs = [],
    removeData = () => {},
    actionsDisabled = true,
    degExternalFilter = {},
    searchInput = undefined,
    setFilterOpen = () => {},
    duplicateRows = () => {},
    onShiftRemove = () => {},
    showHasFilters = () => {},
    setFieldSelected = () => {},
    exportShiftToPdf = () => {},
    onFilterTextChange = () => {},
    exportShiftToExcel = () => {},
    exportOverviewToPdf = () => {},
    setShiftEditVisible = () => {},
    openSplitShiftModal = () => {},
    openRecommendedModal = () => {},
    updateExternalFilter = () => {},
    exportOverviewToExcel = () => {},
    setShiftVisibleFromShift = () => {},
  },
  ref
) {
  const {
    form,
    crews,
    degName,
    jobsites,
    accounts,
    degStatus,
    analytics,
    rowToEdit,
    degGridApi,
    isLastStep,
    isDarkMode,
    updateData,
    degPayrolls,
    currentStep,
    entriesLogs,
    degColumnApi,
    shiftsGridApi,
    massUpdateHandler,
    updateCrewHandler,
    setFiltersFromSelection,
    updateShiftExternalFilter,
  } = useContext(DegModalContext);

  const { base64 } = useSelector((state) => state.base64);

  const [gridActions, setGridActions] = useState(false);
  const [detailsOpen, setDetailsOpen] = useState(false);
  const [categoryCost, setCategoryCost] = useState(false);
  const [otNamesModal, setOtNamesModal] = useState(false);
  const [detailsModal, setDetailsModal] = useState(false);
  const [editEntryData, setEditEntryData] = useState(false);
  const [notFoundModal, setNotFoundModal] = useState(false);
  const [locationsModal, setLocationsModal] = useState(false);
  const [teamShiftsModal, setTeamShiftsModal] = useState(false);
  const [entryLogVisible, setEntryLogVisible] = useState(false);
  const [teamsCostVisible, setTeamsCostVisible] = useState(false);
  const [massEntryVisible, setMassEntryVisible] = useState(false);
  const [addEmployeeModal, setAddEmployeeModal] = useState(false);
  const [massEntryConflict, setMassEntryConflict] = useState(false);
  const [rcmdShiftsToSplit, setRcmdShiftsToSplit] = useState(false);
  const [shiftBreakWarning, setShiftBreakWarning] = useState(false);
  const [degWarningsVisible, setDegWarningsVisible] = useState(false);
  const [uploadModalVisible, setUploadModalVisible] = useState(false);
  const [massEntryShiftType, setMassEntryShiftType] = useState(false);
  const [costDispersionModal, setCostDispersionModal] = useState(false);
  const [jobsiteMatchDetails, setJobsiteMatchDetails] = useState(false);
  const [shiftDetailsVisible, setShiftDetailsVisible] = useState(false);
  const [deleteWarningVisible, setDeleteWarningVisible] = useState(false);
  const [radiusToleranceModal, setRadiusToleranceModal] = useState(false);
  const [selectionModalVisible, setSelectionModalVisible] = useState(false);
  const [cashEntryModalVisible, setCashEntryModalVisible] = useState(false);
  const [multipleHrShiftVisible, setMultipleHrShiftVisible] = useState(false);

  const location = useLocation();

  const serqInputRef = useRef();

  function openGridActions() {
    setGridActions(true);
    setTimeout(() => {
      closeGridActions();
    }, 4500);
  }

  function openSelectionModal() {
    setSelectionModalVisible(true);
  }

  function openDetailsView() {
    setDetailsModal(true);
  }

  function openTeamsShiftsModal() {
    setTeamShiftsModal(true);
  }

  function openWarningsModal() {
    setDegWarningsVisible(true);
  }

  function closeGridActions() {
    const actionsDiv = document.getElementsByClassName("action-buttons")[0];
    actionsDiv?.classList.add("hide-buttons");
    setTimeout(() => {
      actionsDiv?.classList.remove("hide-buttons");
      setGridActions(false);
    }, 300);
  }

  function exportGridToExcel() {
    if (currentStep === 2) {
      exportShiftToExcel();
      return;
    }

    degGridApi.exportDataAsExcel({
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName === "Jobsite Match") {
          return value?.jobName;
        } else if (headerName === "Google Sheet Link") {
          return value?.googleSheetLink;
        } else if (headerName === "Punch Date") {
          return dayjsNY(value).format(DEG_DATE_FORMAT);
        } else if (headerName === "Punch Time") {
          return dayjs(value).format(DEG_TIME_FORMAT);
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  }

  function onGeneratePDF() {
    if (currentStep === 2) {
      exportShiftToPdf();
      return;
    }

    let rows = [];
    degGridApi.forEachNode(({ data }) => {
      rows.push(data);
    });

    return createPDF({
      action: "download",
      gridApi: degGridApi,
      rowData: rows,
      history: location?.pathname?.split("/")?.[1],
      historyTab: location?.pathname,
      gridColumnApi: degColumnApi,
      base64,
      masterDetail: true,
      params: {
        PDF_SELECTED_ROWS_ONLY: false,
        PDF_DOCUMENT_TITLE: analytics?.dateRange?.length
          ? `Week(${dayjsNY(analytics?.dateRange?.[0]).week()}) (${dayjsNY(
            analytics?.dateRange?.[0]
          ).format(DEG_DATE_FORMAT)} - ${dayjsNY(
            analytics?.dateRange?.[1]
          ).format(DEG_DATE_FORMAT)}) Entries`
          : degName,
        PDF_LOGO: base64?.find(({ fileName }) =>
          compareIncluding(fileName, "Core Logo Black")
        )?.base64,
      },
    });
  }

  function addShift(e) {
    const openModal = {
      addEmployeeShift: () =>
        currentStep === 2
          ? setShiftEditVisible(true)
          : setShiftDetailsVisible(true),
      addTeamShift: () => openTeamsShiftsModal(),
      addMultipleHR: () => setMultipleHrShiftVisible(true),
      addCashShift: () => setCashEntryModalVisible(true),
    };
    if (e?.key) {
      openModal?.[e?.key]?.();
    }
  }

  function onMassEntry() {
    const allSelectedNodes = degGridApi.getSelectedNodes();
    const hasOverheadEntries = allSelectedNodes.some(({ data }) =>
      ["CASH", "1099"].includes(data?.punchType)
    );
    const hasNormalEntries = allSelectedNodes.some(({ data }) =>
      ["ID", "OL", "IL", "OD", "HR"].includes(data?.punchType)
    );

    if (hasOverheadEntries && hasNormalEntries) {
      setMassEntryConflict(true);
    } else if (hasOverheadEntries && !hasNormalEntries) {
      setMassEntryVisible("overhead");
    } else if (hasNormalEntries && !hasOverheadEntries) {
      setMassEntryVisible("normal");
    }
  }

  useImperativeHandle(
    ref,
    function () {
      return {
        setEditEntry(data) {
          setEditEntryData(data);
        },
        getEditEntry() {
          return editEntryData;
        },
        setAddEmployee(employee) {
          setAddEmployeeModal(employee);
        },
        setShiftBreakWarning(visible) {
          setShiftBreakWarning(visible);
        },
        setEntryLogVisible(entryId) {
          setEntryLogVisible(entryId);
        },
      };
    },
    [editEntryData]
  );

  return (
    <>
      {isLastStep ? (
        <LastStepHeader
          {...{
            form,
            degName,
            degStatus,
            rowToEdit,
            degPayrolls,
            setFilterOpen,
            setCategoryCost,
            setTeamsCostVisible,
            exportOverviewToPdf,
            exportOverviewToExcel,
            setCostDispersionModal,
          }}
        />
      ) : currentStep === 2 ? (
        <ShiftHeader
          {...{
            addShift,
            onSearch,
            degStatus,
            isDarkMode,
            searchInput,
            detailsOpen,
            gridActions,
            serqInputRef,
            setFilterOpen,
            shiftsGridApi,
            onGeneratePDF,
            onShiftRemove,
            setDetailsOpen,
            openDetailsView,
            setCategoryCost,
            openGridActions,
            closeGridActions,
            setFieldSelected,
            shiftsColumnDefs,
            exportGridToExcel,
            openSelectionModal,
            onFilterTextChange,
            openSplitShiftModal,
            setShiftEditVisible,
            setTeamsCostVisible,
            openTeamsShiftsModal,
            openRecommendedModal,
            setMassEntryShiftType,
            setShiftDetailsVisible,
            setCostDispersionModal,
            setJobsiteMatchDetails,
            setFiltersFromSelection,
            setShiftVisibleFromShift,
            updateShiftExternalFilter,
          }}
        />
      ) : (
        <ControlHeader
          {...{
            addData,
            addShift,
            onSearch,
            degStatus,
            isDarkMode,
            isLastStep,
            columnDefs,
            searchInput,
            detailsOpen,
            gridActions,
            serqInputRef,
            onGeneratePDF,
            setFilterOpen,
            duplicateRows,
            setDetailsOpen,
            setGridActions,
            showHasFilters,
            openDetailsView,
            openGridActions,
            setCategoryCost,
            actionsDisabled,
            setFieldSelected,
            closeGridActions,
            exportGridToExcel,
            openWarningsModal,
            onFilterTextChange,
            openSelectionModal,
            setTeamsCostVisible,
            openTeamsShiftsModal,
            setUploadModalVisible,
            setCostDispersionModal,
            setJobsiteMatchDetails,
            setShiftDetailsVisible,
            setDeleteWarningVisible,
            setRadiusToleranceModal,
            setMassEntryVisible: onMassEntry,
          }}
        />
      )}
      {editEntryData &&
        OVERHEAD_ENTRY_TYPES.includes(editEntryData?.punchType) && (
        <CashEntryShiftModal
          {...{
            open: !!editEntryData,
            selectedRow: editEntryData,
            onCancel() {
              setEditEntryData();
            },
          }}
        />
      )}
      {!!editEntryData &&
        !OVERHEAD_ENTRY_TYPES.includes(editEntryData?.punchType) && (
        <PayrollActivityModal
          {...{
            open: !!editEntryData,
            selectedRow: editEntryData,
            crews,
            accounts,
            jobsites,
            nameEditAllow: !editEntryData?.employeeFullName,
            allowEdit: !radiusToleranceModal,
            onCancel() {
              setEditEntryData();
              degGridApi.deselectAll();
            },
            onSave: updateData,
          }}
        />
      )}
      {selectionModalVisible && (
        <TeamSelectionModal
          {...{
            open: selectionModalVisible,
            onCancel() {
              setSelectionModalVisible(false);
            },
          }}
        />
      )}
      {teamsCostVisible && (
        <CrewAnalytics
          {...{
            open: teamsCostVisible,
            onCancel() {
              setTeamsCostVisible(false);
            },
          }}
        />
      )}
      {categoryCost && (
        <CategoryCostModal
          open={categoryCost}
          onCancel={() => setCategoryCost(false)}
        />
      )}
      {costDispersionModal && (
        <CostDispersionModal
          {...{
            open: costDispersionModal,
            onCancel: () => setCostDispersionModal(false),
          }}
        />
      )}
      {detailsModal && (
        <DegDetailsModal
          {...{
            open: detailsModal,
            onCancel() {
              setDetailsModal(false);
            },
          }}
        />
      )}
      {jobsiteMatchDetails && (
        <JobsiteMatchDetails
          open={jobsiteMatchDetails}
          onCancel={() => {
            setJobsiteMatchDetails(false);
          }}
        />
      )}
      {cashEntryModalVisible && (
        <CashEntryShiftModal
          open={cashEntryModalVisible}
          onCancel={() => setCashEntryModalVisible(false)}
        />
      )}
      {multipleHrShiftVisible && (
        <MultipleHrShiftsModal
          open={multipleHrShiftVisible}
          onCancel={() => setMultipleHrShiftVisible(false)}
        />
      )}
      {shiftDetailsVisible === true && (
        <ShiftEditModal
          {...{
            open: shiftDetailsVisible,
            onCancel() {
              setShiftDetailsVisible(false);
            },
            mode: "NEW",
          }}
        />
      )}
      {teamShiftsModal && (
        <TeamShiftsModal
          {...{
            open: teamShiftsModal,
            onCancel() {
              setTeamShiftsModal(false);
            },
          }}
        />
      )}
      {massEntryVisible === "normal" && (
        <PayrollActivityModal
          {...{
            onCancel() {
              setMassEntryVisible(false);
            },
            crews,
            jobsites,
            onDelete() {},
            massEntry: true,
            allowDelete: false,
            nameEditAllow: true,
            open: massEntryVisible,
            onSave: massUpdateHandler,
            selectedNodes: degGridApi
              .getSelectedNodes()
              .map(({ data }) => data),
          }}
        />
      )}
      {massEntryVisible === "overhead" && (
        <CashEntryShiftModal
          {...{
            massEntry: true,
            open: !!massEntryVisible,
            onCancel() {
              setMassEntryVisible(false);
            },
            selectedNodes: degGridApi
              .getSelectedNodes()
              .map(({ data }) => data),
          }}
        />
      )}
      {!!massEntryConflict && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={massEntryConflict}
          setVisible={setMassEntryConflict}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              There is a mixed selection. If you want to mass update normal
              entries please chose only entires with type <b>ID</b>, <b>OL</b>,{" "}
              <b>IL</b>, <b>OD</b> or <b>HR</b>. If you want to mass update
              overhead entries please chose entries with type <b>CASH</b> or{" "}
              <b>1099</b>.
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  const allSelectedNodes = degGridApi.getSelectedNodes();
                  for (const node of allSelectedNodes) {
                    if (["CASH", "1099"].includes(node?.data?.punchType)) {
                      node.setSelected(false);
                    }
                  }
                  setMassEntryConflict(false);
                  setMassEntryVisible("normal");
                }}
                Icon={<TickIcon width={17} height={17} />}
                className="mondayButtonBlue"
              >
                Mass update Normal entries
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => {
                  const allSelectedNodes = degGridApi.getSelectedNodes();
                  for (const node of allSelectedNodes) {
                    if (!["CASH", "1099"].includes(node?.data?.punchType)) {
                      node.setSelected(false);
                    }
                  }
                  setMassEntryConflict(false);
                  setMassEntryVisible("overhead");
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Mass update Overhead entries
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {!!massEntryShiftType && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={massEntryShiftType}
          setVisible={setMassEntryShiftType}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              It appears that you have selected rows with different shift types.
              In order to proceed with the mass update please select which shift
              type you want to update.
            </p>
            <div className="buttons">
              <MondayButton
                onClick={() => {
                  const allSelectedNodes = shiftsGridApi.getSelectedNodes();
                  for (const node of allSelectedNodes) {
                    if ("Regular Shift" === node?.data?.shiftType) {
                      node.setSelected(false);
                    }
                  }
                  setMassEntryShiftType(false);
                  setShiftVisibleFromShift("normal");
                }}
                Icon={<TickIcon width={17} height={17} />}
                className="mondayButtonBlue"
              >
                Mass update <b>HR Shifts</b>
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => {
                  const allSelectedNodes = shiftsGridApi.getSelectedNodes();
                  for (const node of allSelectedNodes) {
                    if ("HR Shift" === node?.data?.shiftType) {
                      node.setSelected(false);
                    }
                  }
                  setMassEntryShiftType(false);
                  setShiftVisibleFromShift("normal");
                }}
                Icon={<TickIcon width={17} height={17} />}
              >
                Mass update <b>Regular Shifts</b>
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {degWarningsVisible && (
        <DegWarningsModal
          {...{
            setOtNamesModal,
            setNotFoundModal,
            degExternalFilter,
            setLocationsModal,
            updateExternalFilter,
            setRcmdShiftsToSplit,
            open: degWarningsVisible,
            onCancel() {
              setDegWarningsVisible(false);
            },
          }}
        />
      )}
      {otNamesModal && (
        <OvertimeNamesModal
          open={otNamesModal}
          onCancel={() => setOtNamesModal(false)}
        />
      )}
      {locationsModal && (
        <DegLocationsModal
          {...{
            open: locationsModal,
            onCancel() {
              setLocationsModal(false);
            },
          }}
        />
      )}
      {rcmdShiftsToSplit && (
        <ShiftsToSplitModal
          open={rcmdShiftsToSplit}
          onCancel={() => setRcmdShiftsToSplit(false)}
        />
      )}
      {notFoundModal && (
        <DegNotFound
          {...{
            open: notFoundModal,
            onCancel() {
              setNotFoundModal(false);
            },
          }}
        />
      )}
      {!!addEmployeeModal && (
        <CrewsHeader
          {...{
            title: "Employees",
            visible: !!addEmployeeModal,
            defaultData: addEmployeeModal,
            setVisible: setAddEmployeeModal,
            refreshTable: (data) => {
              updateCrewHandler(data, addEmployeeModal);
            },
          }}
        />
      )}
      {uploadModalVisible && (
        <UploadEntriesModal
          open={uploadModalVisible}
          onCancel={() => setUploadModalVisible(false)}
        />
      )}
      {!!shiftBreakWarning && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={shiftBreakWarning}
          setVisible={setShiftBreakWarning}
          className="logout-warning-modal shiftsBreakWarning"
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p style={{ textAlign: "center" }}>
              Deleting this activities will ruin a shift. Are you sure you want
              to continue?
            </p>
            <Table
              dataSource={shiftBreakWarning}
              columns={existingShiftsColumns}
              pagination={{ pageSize: 8 }}
            />
            <div className="buttons">
              <MondayButton
                onClick={() => setShiftBreakWarning(false)}
                Icon={<XIcon />}
                className="mondayButtonRed"
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  setShiftBreakWarning(false);
                  removeData(false);
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {deleteWarningVisible && (
        <WarningModal
          closable={true}
          title="Warning Message"
          visible={deleteWarningVisible}
          className="logout-warning-modal"
          setVisible={setDeleteWarningVisible}
        >
          <div className="logout-modal-body">
            <span>
              <WarningTriangle />
            </span>
            <p>Are you sure you want to delete the selected rows?</p>
            <div className="buttons">
              <MondayButton
                Icon={<XIcon />}
                className="mondayButtonRed"
                onClick={() => setDeleteWarningVisible(false)}
              >
                No
              </MondayButton>
              <MondayButton
                onClick={() => {
                  setDeleteWarningVisible(false);
                  removeData();
                  closeGridActions();
                }}
                Icon={<TickIcon />}
              >
                Yes
              </MondayButton>
            </div>
          </div>
        </WarningModal>
      )}
      {entryLogVisible && (
        <MultiLevelTreeLogs
          {...{
            title: `Entry Logs`,
            visible: entryLogVisible,
            setVisible: setEntryLogVisible,
            logsData: entriesLogs[entryLogVisible],
          }}
        />
      )}
      {radiusToleranceModal ? (
        <RadiusToleranceModal
          open={radiusToleranceModal}
          setEditEntryData={setEditEntryData}
          onCancel={() => setRadiusToleranceModal(false)}
        />
      ) : null}
    </>
  );
});

export default DegHeader;

import { cloneElement, forwardRef, ReactNode } from "react";
import { useSelector } from "react-redux";

import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { AddIcon } from "src/components/Header/components/GoogleEvents/assets";

import "./HoverButton.scss";
import { Spin } from "antd";
import { LoadingOutlined } from "@ant-design/icons";

/**
 * @typedef {"action"|"new"|"decline"|"save"} ButtonTypes
 *
 * @typedef HoverButtonProps
 * @property {ReactNode} text
 * @property {boolean} [disabled=false]
 * @property {ReactNode} [icon]
 * @property {string} [id]
 * @property {ButtonTypes} [type="new"] - "new" for green button, "action" for blue and "decline" for red
 * @property {string} [className]
 * @property {boolean} [alwaysShowIcon=false]
 * @property {(ev: React.MouseEvent<HTMLButtonElement, MouseEvent>)=>any} [onClick]
 * @property {boolean} [hasIcon=true]
 * @property {string} [dataTestId]
 * @property {{height: number, width: number}} [iconSize={height: 10, width:10}]
 * @property {boolean} [loading=false]
 */

/** @type {Record<ButtonTypes, ReactNode>} */
const defaultTypeMap = {
  action: <TickIcon />,
  decline: <XIcon />,
  new: <AddIcon />,
  save: <TickIcon />,
};

const HoverButton = forwardRef(
  /**
   * @param {HoverButtonProps} props
   * @param {React.MutableRefObject<HTMLButtonElement>} [ref]
   * @returns {JSX.Element}
   */
  (props, ref) => {
    const { isDarkMode } = useSelector((state) => state.darkMode);

    const {
      id,
      text,
      dataTestId = undefined,
      disabled = false,
      onClick = () => {},
      icon = undefined,
      alwaysShowIcon = false,
      iconSize = { height: 10, width: 10 },
      type = "new",
      className = "",
      hasIcon = true,
      loading = false,
    } = props;

    const iconToShow = hasIcon ? (
      loading ? (
        <Spin indicator={<LoadingOutlined spin />} />
      ) : icon === undefined ? (
        defaultTypeMap?.[type] || null
      ) : (
        icon
      )
    ) : null;

    return (
      <button
        type="button"
        className={`hover-button-container ${
          isDarkMode ? "hover-button-dark" : ""
        } ${className}`}
        id={id}
        ref={ref}
        data-testid={dataTestId}
        disabled={disabled}
        onClick={onClick}
      >
        <div
          className={`button-inner-content ${
            !iconToShow ? "content-no-icon" : ""
          } ${disabled ? "content-disabled" : ""} ${
            alwaysShowIcon ? "icon-show" : "icon-hover"
          }`}
        >
          <span className={`button-inner-text type-${type}`}>{text}</span>
          {iconToShow ? (
            <span className={`button-inner-icon type-${type}`}>
              {cloneElement(iconToShow, iconSize)}
            </span>
          ) : null}
        </div>
      </button>
    );
  }
);

export default HoverButton;

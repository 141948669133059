import { useSelector } from "react-redux";

import dayjs from "dayjs";
import XLSX from "sheetjs-style";

import createPDF from "../../../../../../../../integrations/createPDF";

import { getAccessRights } from "../../../../../../../../utils";
import { compareIncluding } from "../../../../../../../SidebarPages/utils";
import { companyDetails } from "../../../../../../../../helpers/constants";

import { defaultExcelHeaderStyle } from "../../../../../../../../helpers/constants/defaultExcelHeaderStyle";
import { defaultExcelBodyStyle } from "../../../../../../../../helpers/constants/defaultExcelBodyStyle";
import { excelCellFitToColumn } from "../../../../../../../../utils/excelCellFitToColumn";

const { logoName } = companyDetails;

export const returnAccessForDocumentations = (checkAccesIn) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const accessRight = getAccessRights({
    userConfiguration: userConfiguration,
    title: checkAccesIn,
  });

  const { routeConfig } = accessRight;

  let updatedAccess = routeConfig.read && routeConfig.write ? true : false;

  return updatedAccess;
};

// pdf functions
export const onGeneratePDF = ({
  action,
  getDocDefinition = false,
  dataToExport = [],
  columnHeader = [],
  base64 = null,
  hasMoreThanOneTable = false,
  titleOfPdf = "",
}) => {
  const docDefinition = buildDocDefinition({
    dataToExport,
    columnHeader,
    base64,
    hasMoreThanOneTable,
    titleOfPdf,
  });

  if (getDocDefinition) {
    return docDefinition;
  }

  createPDF({
    action,
    docDefinition,
    title: titleOfPdf,
  });
};

const buildDocDefinition = ({
  dataToExport,
  columnHeader,
  base64,
  hasMoreThanOneTable,
  titleOfPdf,
}) => {
  const pageMargins = [10, 80, 30, 40]; // Adjust margins for better layout
  const logo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, logoName)
  )?.base64;

  const header = buildHeader(logo, titleOfPdf);
  const content = hasMoreThanOneTable
    ? buildMultipleTablesContent(dataToExport)
    : buildSingleTableContent(dataToExport, columnHeader);

  return {
    pageOrientation: "landscape",
    pageMargins,
    header,
    content,
    footer: buildFooter,
    styles: {
      header: {
        fontSize: 18,
        bold: true,
        margin: [0, 0, 0, 10],
      },
      table: {
        fontSize: 10,
        padding: [5, 5, 5, 5], // Add padding to cells
      },
      tableHeader: {
        fontSize: 12,
        bold: true,
        fillColor: "#B7C4CB",
      },
    },
    defaultStyle: {
      fontSize: 10,
    },
  };
};

const buildHeader = (logo, titleOfPdf) => ({
  columns: [
    {
      text: titleOfPdf,
      fontSize: 14,
      alignment: "left",
      margin: [20, 10],
      width: "*",
      color: "#323338",
    },
    {
      image: logo,
      fit: [150, 150],
      alignment: "center",
      width: "*",
    },
    {
      text: new Date().toLocaleString("en-US", {
        month: "long",
        day: "numeric",
        year: "numeric",
        hour: "numeric",
        minute: "numeric",
        hour12: true,
      }),
      fontSize: 10,
      alignment: "right",
      margin: [0, 10, 20, 0],
      width: "*",
      color: "#323338",
    },
  ],
  margin: [0, 0, 0, 20],
});

const buildSingleTableContent = (dataToExport, columnHeader) => {
  return {
    table: {
      headerRows: 1,
      widths: columnHeader.map(() => "*"),
      body: [
        columnHeader.map((header) => ({ text: header, style: "tableHeader" })),
        ...dataToExport.map((value) =>
          Object.values(value).map((item) => {
            if (item) {
              if (typeof item === "number" && item?.toString()?.length === 13) {
                return dayjs(item).format("MM/DD/YYYY");
              } else if (item?.startsWith("data:image/png;")) {
                return {
                  image: item,
                  fit: [128, 100],
                  alignment: "center",
                };
              } else if (item.startsWith("#") && item.length === 7) {
                return {
                  text: item,
                  background: item,
                  noWrap: false,
                };
              } else {
                return {
                  text: item?.toString(),
                  noWrap: false,
                };
              }
            } else {
              return item;
            }
            return { text: "", noWrap: false };
          })
        ),
      ],
    },
    layout: {
      fillColor: function (rowIndex, node, columnIndex) {
        return rowIndex === 0 ? "#B7C4CB" : null;
      },
      hLineColor: "#DDE3E5",
      vLineColor: "#DDE3E5",
    },
    style: "table",
  };
};

const buildMultipleTablesContent = (dataToExport) => {
  const tables = [];
  dataToExport.forEach((tableData, index) => {
    const body = buildTableBody(tableData);
    const table = {
      table: {
        headerRows: 1,
        widths: tableData.headers.map(() => "*"),
        body: [
          tableData.headers.map((header) => ({
            text: header,
            style: "tableHeader",
          })),
          ...body,
        ],
      },
      layout: {
        fillColor: function (rowIndex) {
          return rowIndex === 0 ? "#B7C4CB" : null;
        },
        hLineColor: "#DDE3E5",
        vLineColor: "#DDE3E5",
      },
      style: "table",
    };
    tables.push(table);
    if (index < dataToExport.length - 1) {
      tables.push({ text: "", margin: [0, 20] });
    }
  });

  return tables;
};

const buildTableBody = (tableData) => {
  let body = [];
  if (Array.isArray(tableData.data) && tableData.data.length > 0) {
    if (Array.isArray(tableData.data[0])) {
      body = tableData.data.map((row) => row.map((cell) => cell || ""));
    } else {
      body = tableData.data.map((cell) => [cell || ""]);
    }
  }
  return body;
};

const buildFooter = function (currentPage, pageCount) {
  return {
    text: `Page ${currentPage} of ${pageCount}`,
    alignment: "right",
    margin: [0, 0, 20, 0],
    fontSize: 10,
  };
};

// pdf functions end

export const exportToExcel = ({
  dataToExport = [],
  columnHeader = [],
  getDocDefinition = false,
  title = "ListOfDocumentations",
}) => {
  let workbook;
  if (getDocDefinition)
    return onGeneratePDF({ action: "download", getDocDefinition });

  const rows = [];

  const headerRow = columnHeader.map((el) => ({
    v: el || "",
    s: defaultExcelHeaderStyle,
  }));
  rows.push(headerRow);

  dataToExport.forEach((item) => {
    const rowData = Object.values(item).map((value) => {
      const convertDate =
        typeof value === "number" && value.toString().length === 13
          ? dayjs(value).format("MM/DD/YYYY")
          : value;
      return {
        v: convertDate || "",
        s: defaultExcelBodyStyle,
      };
    });
    rows.push(rowData);
  });

  const worksheet = XLSX.utils.aoa_to_sheet([...rows], { styles: true });
  worksheet["!cols"] = excelCellFitToColumn([...rows]);

  workbook = XLSX.utils.book_new();
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  XLSX.writeFile(workbook, `${title}.xlsx`);
};

export const uploadExcelToDrive = async ({
  columnHeader,
  dataToExport,
  driveRequest,
  driveFolderId,
  title = "ListOfDocumentations.",
}) => {
  try {
    let workbook;

    const rows = [];

    const headerRow = columnHeader.map((el) => ({
      v: el || "",
      s: defaultExcelHeaderStyle,
    }));
    rows.push(headerRow);

    dataToExport.forEach((item) => {
      const rowData = Object.values(item).map((value) => {
        const convertDate =
          typeof value === "number" && value?.toString().length === 13
            ? dayjs(value).format("MM/DD/YYYY")
            : value;
        return {
          v: convertDate || "",
          s: defaultExcelBodyStyle,
        };
      });
      rows.push(rowData);
    });

    const worksheet = XLSX.utils.aoa_to_sheet([...rows], { styles: true });
    worksheet["!cols"] = excelCellFitToColumn([...rows]);

    workbook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

    const excelArrayBuffer = XLSX.write(workbook, {
      bookType: "xlsx",
      type: "array",
    });

    const excelBlob = new Blob([excelArrayBuffer], {
      type: "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
    });

    const uploadResponse = await driveRequest.uploadExcelFile(
      excelBlob,
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      {
        name: `${title}.xlsx`,
        parents: [driveFolderId],
      }
    );

    const { id, newName } = await uploadResponse.json();

    return { id, name: newName };
  } catch (error) {
    console.error("Error uploading Excel file to Google Drive:", error);
  }
};

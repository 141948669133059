import { dateInputValidation } from "../../../../../../../utils/dateInputValidation";
import { useState } from "react";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import moment from "moment";
import { CalendarIcon } from "../../../../../BasePage/src";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { MondayButton } from "../../../../../../commonComponents";
import { DatePicker, message } from "antd";
import { generateReminderDays } from "./utils";
import dayjs from "dayjs";

export const TaskDatesInEdit = ({
  title,
  dateKey,
  compareWith: { date, comparator, errorMsg, dateFrom, dateTo },
  dates,
  updatedTask,
  setUpdatedTask,
}) => {
  const [dateValue, setDateValue] = useState(
    dayjsNY(updatedTask[dateKey] || undefined)
  );
  const [datePickerModal, setDatePickerModal] = useState(false);

  const updateDateValue = () => {
    setDatePickerModal(false);
    const updatedDate = dayjsNY(dateValue).valueOf();
    if (
      !dateValue.toString().includes("Invalid Date") ||
      updatedDate !== updatedTask?.taskEndDate
    ) {
      generateReminderDays(
        { ...updatedTask, [dateKey]: updatedDate },
        setUpdatedTask
      );
    }
  };

  return (
    <div className="detailsModal__collaborators">
      <span>
        {title}
        <span
          style={{
            cursor: "pointer",
            marginLeft: 10,
            color: "#1264A3",
            fontWeight: 600,
          }}
          onClick={() => setDatePickerModal((prevState) => !prevState)}
          data-testid="date-input-testid"
        >
          {dateInputValidation("text", dateValue, true)}
        </span>
      </span>
      {datePickerModal && (
        <div className="detailsModal__collaborators__modal">
          <DatePicker
            defaultValue={dayjsNY(dateValue)}
            value={dayjsNY(dateValue)}
            onChange={(event) => {
              setDateValue(dayjsNY(event));
              return;
              let result = false;

              // if (comparator === ">") {
              //   result = moment(event).valueOf() > moment(date).valueOf();
              // } else if (comparator === "<") {
              //   result = moment(event).valueOf() < moment(date).valueOf();
              // }

              if (result) {
                message.error(errorMsg);
                return;
              }
              setDateValue(moment(event?.valueOf()));
              //   setFormChanged(true);
            }}
            disabledDate={(current) => {
              return (
                dayjsNY(current).valueOf() >= dayjs(dateTo).valueOf() ||
                dayjsNY(current).valueOf() <=
                  dayjs(dateFrom.subtract(1, "day")).valueOf()
              );
            }}
            disabledTime={(date) => {
              if (date.isBefore(dateFrom)) {
                return {
                  disabledHours: () =>
                    Array.from({ length: dateFrom.hour() }, (_, i) => i),
                  disabledMinutes: () =>
                    Array.from({ length: dateFrom.minute() }, (_, i) => i),
                };
              }
            }}
            inputReadOnly={true}
            format="MM/DD/YYYY h:mm a"
            suffixIcon={<CalendarIcon />}
            showTime={{ format: "h:mm a" }}
            allowClear={false}
          />
          <MondayButton Icon={<TickIcon />} onClick={updateDateValue}>
            Done
          </MondayButton>
        </div>
      )}
    </div>
  );
};

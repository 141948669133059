import { EditIcon } from "../../../../../../../DynamicView/src";
import { formatCurrency } from "../../../../../../../utils";
import { CompleatedCheckBox } from "./GridComponents";
import "./columnDefs.scss";
import ChargedIndicator from "./GridComponents/ChargedIndicator/ChargedIndicator";
import DescriptionModalHoist from "./GridComponents/DescriptionModalHoist/DescriptionModalHoist";
import { Button } from "antd";
import DeleteService from "../Service/ServiceCredit/DeleteService/DeleteService";
import { forceToNumber } from "../../../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";

export const columnDefsHoist = ({ service, elevation }) => {
  return [
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>A</div><div class='textContainerStretched'>#</div></div>",
      },
      headerTooltip: "Id",
      field: "id",
      editable: false,
      minWidth: 70,
      cellRenderer: ({ value }) => value + 1,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Action</div></div>",
      },
      headerTooltip: "Total Completed",
      field: "totalCompleated",
      editable: false,
      minWidth: 130,
      cellRendererFramework: (gridParams) => (
        <div className="descriptionOfWorkCellContainer">
          <CompleatedCheckBox
            {...{ gridParams, service, type: "hoist", elevation }}
          />{" "}
          Completed
        </div>
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>B</div><div class='textContainerStretched'>Description of work</div></div>",
      },
      headerName: "Description of work",
      headerTooltip: "Events",
      field: "name",
      minWidth: 550,
      cellStyle: {
        whiteSpace: "normal",
        wordBreak: "break-word",
        maxWidth: "400px",
      },
      editable: false,
      cellRendererFramework: (gridParams) => (
        <div className="descriptionOfWorkCellContainer">
          {gridParams?.data?.name}{" "}
          {gridParams?.data?.isCredit && (
            <DeleteService
              {...{ type: "Hoist", service, grid: gridParams, elevation }}
            />
          )}
        </div>
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>C</div><div class='textContainerStretched'>Schedule of value</div></div>",
      },
      headerName: "Schedule of value",
      headerTooltip: "Amount",
      field: "amount",
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      minWidth: 200,
      editable: false,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>D</div><div class='textContainer'>From previous requisition</div><div class='actionsContainer'>(D+E)</div></div>",
      },
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      headerTooltip: "From Previous Requisition",
      headerName: "From previous requisition",
      field: "fromPrevious",
      minWidth: 240,
      editable: false,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>Payment Due</div></div>",
      },
      headerName: "Payment Due",
      headerTooltip: "Payment Due",
      field: "paymentDue",
      minWidth: 200,
      editable: false,
      cellStyle: {
        // backgroundColor: "white",
        color: "#68C142",
      },
      // valueGetter: (params) => {
      //   // const {
      //   //   thisPeriod = 0,
      //   //   taxAmount = 0,
      //   //   retainage = 0,
      //   //   reducedAmount = 0,
      //   // } = params?.data || {};
      //   // const res =
      //   //   Number(thisPeriod) +
      //   //   Number(taxAmount) -
      //   //   (Number(retainage) + Number(reducedAmount));
      //   // // params.data.paymentDue = res;
      //   // return res < 0 && reducedAmount > 0 ? Math.abs(res) : res;

      //   return paymentDuePLICalculator(params, service);
      // },
      valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>E</div><div class='textContainerStretched'>This period</div></div>",
      },
      headerName: "This period",
      headerTooltip: "This Period",
      field: "thisPeriod",
      minWidth: 150,
      cellEditor: "PeriodCellEditor",
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      cellRendererFramework: (params) => (
        <div className="editableCellContainer">
          <div>{formatCurrency(params?.value, "USD")}</div>
          <div className="editableCellIcon">
            <EditIcon />
          </div>
        </div>
      ),
    },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>This Retainage</div></div>",
    //   },
    //   headerName: "This Retainage",
    //   headerTooltip: "This  Retainage",
    //   field: "keptRetainage",
    //   minWidth: 200,
    //   editable: false,
    //   valueGetter: (params) => {
    //     const { reducedBalance = 0, keptRetainage = 0 } = params?.data || {};
    //     return formatCurrency(reducedBalance + keptRetainage, "USD");
    //   },
    //   // valueGetter: (params) => {
    //   //   return formatCurrency(params?.value || 0, "USD");
    //   // },
    // },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>G</div><div class='textContainerStretched'>Total completed & stored to date</div></div>",
      },
      headerName: "Total completed & stored to date",
      headerTooltip: "Total Completed",
      field: "totalCompleated",
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
      minWidth: 250,
      editable: false,
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>%</div><div class='actionsContainer'>(G/C)</div></div>",
      },
      headerName: "%",
      headerTooltip: "Percentage Completed",
      cellEditor: "PeriodCellEditor",
      field: "percentageCompleated",
      minWidth: 130,
      editable: true,
      cellStyle: {
        color: "white",
        backgroundColor: "#68C142",
      },
      cellRendererFramework: ({ value }) => {
        return (
          <div className="editableCellContainer">
            <div>{!!value ? +(+value)?.toFixed(2) + "%" : "0.00%"}</div>
            <div className="editableCellIcon">
              <EditIcon />
            </div>
          </div>
        );
      },
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Charged</div></div>",
      },
      headerName: "Charged",
      headerTooltip: "charged",
      field: "charged",
      minWidth: 130,
      editable: false,
      cellRendererFramework: (params) => (
        <ChargedIndicator
          {...{
            id: elevation?.elevationId,
            eventId: params?.data?.id,
            thisFunction: "hoist",
            service,
          }}
        />
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Balance to finish</div><div class='actionsContainer'>(C-G)</div></div>",
      },
      headerName: "Balance to finish",
      headerTooltip: "Balance To Finish",
      field: "balanceToFinish",
      minWidth: 200,
      editable: false,
      valueFormatter: (params) => formatCurrency(params?.value, "USD"),
    },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>Included Retainage</div></div>",
    //   },
    //   headerName: "Included Retainage",
    //   headerTooltip: "Included Retainage",
    //   field: "carriedRetainage",
    //   minWidth: 200,
    //   editable: false,
    //   valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    // },

    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'></div><div class='actionsContainer'>This Retainage</div></div>",
    //   },
    //   headerName: "This Retainage",
    //   headerTooltip: "This Retainage",
    //   // field: "carriedRetainage",
    //   field: "thisRetainage",
    //   minWidth: 200,
    //   editable: false,
    //   valueGetter: (params) => {
    //     console.log("hoist service", params);
    //     const {
    //       retainage = 0,
    //       carriedRetainage = 0,
    //       reducedAmount = 0,
    //       thisPeriod = 0,
    //       previousRetainage = 0,
    //     } = params?.data || {};
    //     const { retainage: retainagePercentage } = service || {};
    //     const res =
    //       (thisPeriod * retainagePercentage) / 100 +
    //       (reducedAmount > 0 ? previousRetainage - reducedAmount : 0);
    //     const finalRes = thisPeriod > 0 || reducedAmount > 0 ? res || 0 : 0;
    //     return formatCurrency(finalRes > 0 ? finalRes : 0, "USD");
    //   },
    // },

    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>I</div><div class='textContainer'>Retainage</div><div class='actionsContainer'>(Balance)</div></div>",
    //   },
    //   headerName: "Retainage",
    //   headerTooltip: "Retainage",
    //   field: "retainage",
    //   minWidth: 140,
    //   editable: false,
    //   valueFormatter: (params) => {
    //     let curr =
    //       params?.data?.thisPeriod !== 0
    //         ? formatCurrency(params?.value, "USD")
    //         : formatCurrency(0, "USD");
    //     // console.log("curr:", params);
    //     return formatCurrency(params?.value || 0, "USD");
    //   },
    // },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>This Retaiange</div></div>",
      },
      headerName: "This Retaiange",
      headerTooltip: "This Retaiange",
      field: "thisKeptRetainage",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Acc Ret Balance</div></div>",
      },
      headerName: "Accumulated Retainage Balance",
      headerTooltip: "Accumulated Retainage Balance",
      field: "accRetainageBalance",
      enableFillHandle: true,
      fillHandleDirection: "y",

      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>This Distributed Retaiange</div></div>",
      },
      headerName: "This Distributed Retaiange",
      headerTooltip: "This Distributed Retaiange",
      field: "thisDistRetainage",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Acc. Distributed Ret</div></div>",
      },
      headerName: "Accumulated Distributed Retainage",
      headerTooltip: "Accumulated Distributed Retainage",
      field: "accRetaiangeDist",
      valueFormatter: (params) =>
        formatCurrency(forceToNumber(params?.value), "USD"),
      minWidth: 250,
      editable: true,
      getQuickFilterText: () => "",
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Collectable amount</div><div class='actionsContainer'>(C-D-I)</div></div>",
      },
      headerName: "Collectable amount",
      headerTooltip: "Collectable Amount",
      field: "collectable_amount",
      // minWidth: 260,
      editable: false,
      hide: true,
      valueFormatter: (params) =>
        formatCurrency(
          params?.value < 0 || !params.value ? 0 : params?.value,
          "USD"
        ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainer'>Collectable amount</div><div class='actionsContainer'>(C-I)</div></div>",
      },
      headerName: "Collectable amount",
      headerTooltip: "Collectable Amount",
      // field: "collectable_amount",
      minWidth: 260,

      editable: false,
      valueGetter: (params) => {
        const {
          amount = 0,
          retainagePercentage = 0,
          accRetainageBalance = 0,
          thisKeptRetainage = 0,
          totalCompleated = 0,
        } = params?.data || {};
        const C_AMOUNT =
          forceToNumber(amount) -
          forceToNumber(totalCompleated) -
          forceToNumber(accRetainageBalance);
        return C_AMOUNT < 0 ? 0 : C_AMOUNT;
      },
      valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Tax Amount</div></div>",
      },
      headerName: "Tax Amount",
      headerTooltip: "Tax Amount",
      field: "taxAmount",
      minWidth: 130,
      editable: false,
      valueFormatter: ({ value }) => formatCurrency(value, "USD"),
      valueGetter: (params) => {
        const { custom_tax_amount = 0, paymentDue = 0 } = params?.data || {};
        if (custom_tax_amount === 0) return custom_tax_amount;
        console.log("custom_tax_amount:", custom_tax_amount);
        let taxAmount =
          (paymentDue * custom_tax_amount) / 1 + custom_tax_amount;
        return forceToNumber(taxAmount);
      },
    },
    // {
    //   headerComponentParams: {
    //     template:
    //       "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Tax Rate</div></div>",
    //   },
    //   headerName: "Tax Rate",
    //   headerTooltip: "Tax Rate",
    //   field: "custom_tax_amount",
    //   minWidth: 130,
    //   editable: true,
    //   // valueFormatter: ({ value }) => formatCurrency(value, "USD"),
    //   // valueGetter: (params) => {
    //   //   const { custom_tax_amount = 0, paymentDue = 0 } = params?.data || {};

    //   //   return forceToNumber(taxAmount);
    //   // },
    // },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Description</div></div>",
      },
      headerName: "Description",
      headerTooltip: "Description",
      field: "description",
      minWidth: 230,
      editable: false,
      cellRendererFramework: (params) => (
        <DescriptionModalHoist
          {...{
            event: params?.data,
            elevation,
            service,
          }}
        />
      ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Initial Ret %</div></div>",
      },
      headerName: "Event Req %",
      // headerTooltip: "Description",
      field: "retainagePercentage",
      minWidth: 240,
      editable: true,
      valueFormatter: (params) => {
        return params?.value ? +(+params?.value)?.toFixed(2) + "%" : "0.00%";
      },
      // cellRendererFramework: (params) => (
      //   <EventReqChanger {...{ service, event: params?.data, params }} />
      // ),
    },
    {
      headerComponentParams: {
        template:
          "<div class='headerDivContainer'><div class='letterContainer'>-</div><div class='textContainerStretched'>Ret +/- in %</div></div>",
      },
      headerName: "Event Req %",
      // headerTooltip: "Description",
      // field: "retainagePercentage",
      field: "retainageDndInd",
      minWidth: 240,
      editable: true,
      // cellRendererFramework: (params) => (
      //   <EventReqChanger {...{ service, event: params?.data, params }} />
      // ),
    },
  ];
};

const paymentDuePLICalculator = (valueParamsObj = {}, service) => {
  console.log("valueParamsObj:", valueParamsObj?.data);
  const { retainage: retainagePercentage } = service || {};
  const {
    thisPeriod = 0,
    taxAmount = 0,
    retainage = 0,
    reducedAmount = 0,
    previousRetainage = 0,
    carriedRetainage = 0,
  } = valueParamsObj?.data || {};
  let tempthisRetainage =
    (thisPeriod * retainagePercentage) / 100 +
    (reducedAmount > 0 ? previousRetainage - reducedAmount : 0);
  let thisRetainage =
    thisPeriod > 0 || reducedAmount > 0 ? tempthisRetainage || 0 : 0;
  const res = Number(thisPeriod) - Number(thisRetainage);
  //   Number(taxAmount) -
  //   (Number(carriedRetainage > 0 ? carriedRetainage : retainage) -
  //     Number(reducedAmount));
  // // params.data.paymentDue = res;
  return Math.abs(res);
  if (thisPeriod === 0 && reducedAmount === 0) return 0;
  else if (reducedAmount !== 0) return Math.abs(res);
  else return Math.abs(res);
};

import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";
import { findGeofenceCenterCoordinate } from "../../../../../../../SidebarPages/Fleet/fleetsLive/utils";

/**
 * @typedef LatLng
 * @property {number} lat
 * @property {number} lng
 */

/**
 * @typedef IntersectionParams
 * @property {LatLng[]} points
 * @property {LatLng} position
 * @property {number} [radius=300]
 */

/**
 * @typedef IntersectionReturn
 * @property {boolean} inRange
 * @property {number} distanceInFeet
 */

/**
 * @param {IntersectionParams} config
 * @return {IntersectionReturn}
 */
function findPolygonRange(config) {
  const { points, position, radius = 300 } = config;

  if (!Array.isArray(points) || points?.length < 3) {
    return null;
  }

  // let north = null,
  //   south = null,
  //   east = null,
  //   west = null;

  // let latArr = points
  //   ?.map(({ latitude = null, lat = null }) => lat || latitude)
  //   .filter(Boolean);
  // let lngArr = points
  //   ?.map(({ longitude = null, lng = null }) => lng || longitude)
  //   .filter(Boolean);

  // north = Math.max(...latArr);
  // south = Math.min(...latArr);
  // east = Math.max(...lngArr);
  // west = Math.min(...lngArr);

  /**
   * The idea is to create a square with the maximum
   * of the coordinates
   */
  // const coordsArray = [
  //   { lat: north, lng: east },
  //   { lat: north, lng: west },
  //   { lat: south, lng: east },
  //   { lat: south, lng: west },
  // ]
  //   .map((e) => {
  //     const within = withinRadius(e, position, radius);

  //     return {
  //       ...e,
  //       distance: within.distanceInFeet,
  //       withinRange: within.withinRange,
  //     };
  //   })
  //   .sort((a, b) => a.distance - b.distance);

  // const polygonCenter = {
  //   lat: (Math.abs(north) - Math.abs(south)) / 2 + south,
  //   lng: (Math.abs(east) - Math.abs(west)) / 2 + west,
  // };

  /**
   * We firstly check if the position is inside the
   * main square. In that case we don't need to perform any calculation
   */
  // if (
  //   pointsInsideAPolygon(
  //     [position.lat, position.lng],
  //     coordsArray.map((e) => [e.lat, e.lng])
  //   )
  // ) {
  //   return {
  //     inRange: true,
  //     distanceInFeet: withinRadius(polygonCenter, position).distanceInFeet,
  //   };
  // }

  /**
   * This is the square's closest side to the position
   */
  // const l1 = [
  //   { y: coordsArray[0]["lat"], x: coordsArray[0]["lng"] },
  //   { y: coordsArray[1]["lat"], x: coordsArray[1]["lng"] },
  // ];

  /**
   * This is the line from the center of the square to the position
   */
  // const l2 = [
  //   { y: polygonCenter["lat"], x: polygonCenter["lng"] },
  //   { y: position["lat"], x: position["lng"] },
  // ];

  // let xPoint, yPoint;
  
  // if(l1[0]["x"] === l1[1]["x"]) {
  //   // parallel with the y axis
  //   xPoint = l1[0]["x"];

  //   if(l2[0]["y"] === l2[1]["y"]) {
  //     yPoint = l2[0]["y"]
  //   } else {
  //     const k = (l2[1]["y"] - l2[0]["y"]) / (l2[1]["X"] - l2[0]["X"])
  //     yPoint = k*xPoint - k*l2[0]["x"] + l2[0]["y"]
  //   }
  // } else if(l1[0]["y"] === l1[1]["y"]) {
  //   // parallel with the x axis
  //   yPoint = l1[0]["y"];

  //   if(l2[0]["x"] === l2[1]["x"]) {
  //     xPoint = l2[0]["x"]
  //   } else {
  //     const k = (l2[1]["x"] - l2[0]["x"]) / (l2[1]["y"] - l2[0]["y"]) 
  //     xPoint = k*yPoint - k*l2[0]["y"] + l2[0]["x"]
  //   }
  // }
  

  // xPoint =
  //   (l1[0]["x"] * (l1[1]["y"] - l1[0]["y"]) * (l2[1]["x"] - l2[0]["x"]) -
  //     l2[0]["x"] * (l2[1]["y"] - l2[0]["y"]) * (l1[1]["x"] - l1[0]["x"]) +
  //     (l2[1]["x"] - l2[0]["x"]) *
  //       (l1[1]["x"] - l1[0]["x"]) *
  //       (l2[0]["y"] - l1[0]["y"])) /
  //   ((l1[1]["y"] - l1[0]["y"]) * (l2[1]["x"] - l2[0]["x"]) -
  //     (l2[1]["y"] - l2[0]["y"]) * (l1[1]["x"] - l1[0]["x"]));

  // yPoint =
  //   ((l1[1]["y"] - l1[0]["y"]) / (l1[1]["x"] - l1[0]["x"])) * xPoint -
  //   l1[0]["x"] * ((l1[1]["y"] - l1[0]["y"]) / (l1[1]["x"] - l1[0]["x"])) +
  //   l1[0]["y"];

  /**
   * This is the intersection point between the lines
   */
  // const intersectionPoint = { lat: yPoint, lng: xPoint };

  // if (
  //   position?.lat === 40.81432331357227 &&
  //   position?.lng === -73.88803909539607
  // ) {
  // }

  // const within = withinRadius(intersectionPoint, position, radius);

  const polygonCenter = findGeofenceCenterCoordinate(points);

  let sortedPoints = points.map((e) => withinRadius(e, polygonCenter)).sort((a, b) => b.distanceInFeet - a.distanceInFeet);
  const furthestPointFromCenter = sortedPoints[0];

  const within = withinRadius(polygonCenter, position, furthestPointFromCenter.distanceInFeet + radius);
  
  return {
    distanceInFeet: within.distanceInFeet,
    inRange: within.withinRange,
  };
}

export default findPolygonRange;

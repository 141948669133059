import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { getDispatchesByActivities } from "./getDispatchesByActivities";

export const getDriverStatistics = async () => {
  try {
    let drivers = await fetchAllData("drivers", "drivers", "driverId");
    let activities = await getDispatchesByActivities();

    // Iterate over each activity
    return activities.map((activity) => {
      // Find the corresponding driver
      const driver = drivers.find((d) => d.driverId === activity.driverId);
      if (driver) {
        // If a matching driver is found, add driverStatus and driverRating to the activity
        activity.driverStatus = driver.driverStatus;
        activity.driverRating = driver?.driverRating || "";
        // Also, add driverStatus to each route if the driverId matches
        activity.routes = activity.routes.map((route) => {
          if (route.driverId === driver.driverId) {
            route.driverStatus = driver.driverStatus;
          }
          return route;
        });
      }
      return activity;
    });
  } catch (error) {
    console.error("data source function getDriverStatistics error: " + error);
  }
};

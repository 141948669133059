import { API } from "aws-amplify";
import { Form, Modal, Tour, message, Divider } from "antd";
import { useState, useEffect, useMemo } from "react";
import { InfoCircleFilled } from "@ant-design/icons";
import { useSelector, useDispatch } from "react-redux";

// import { MultiLevelTreeLogs } from "../../../../../../commonComponents/index";
import { useEditLogs } from "../../../../../../../hooks";
import {
  employeeFields,
  employeeEmergencyContactFields,
  footerButtons,
} from "./updateCrewData";
// import { fetchAllData } from "../../../../../../../utils";
import { fetchData } from "../../../../../../SidebarPages/Fleet/utils";
import { compareIncluding } from "../../../../../../SidebarPages/utils";
import { getCognitosForNotification } from "../../../../../../../utils";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import {
  getActiveEmployeesData,
  getEmployeesRateByDateRange,
} from "../../../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { programFields as setProgramFields } from "../../../../../../../actions";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components/index";
import { getChangedData } from "../../../../../../SidebarPages/Accounting/components/utilities";
// import DynamicTeamModal from "../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";
import { useModalOnceObject } from "../../../../../../../hooks/useModalOnce";
import { useMultipleRefs } from "../../../../../../../hooks/useMultipleRefs";
import CustomModalHeader, {
  findTutorialSteps,
} from "../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VectorIcon } from "../../../../../../../assets";
import { broadcastArrayToString } from "../../../../../../../utils/broadcastArrayToString";
import MultipleInputs from "../../../../../../SidebarPages/DynamicView/FormItemComponents/MultipleInputs/MultipleInputs";
import "./UpdateCrew.scss";

const map = {
  employeeId: "ID",
  crewName: "Name",
  crewStatus: "Status",
  crewPosition: "Role",
  employeeRate: "Pay Rate",
  salaryType: "Salary Type",
  accountName: "Subcontractor",
};

const updatedKeys = (key) => {
  return map[key] || "";
  //  === "crewName"
  //   ? "Name"
  //   : key === "crewStatus"
  //   ? "Status"
  //   : key === "crewPosition"
  //   ? "Role"
  //   : key === "accountName"
  //   ? "Subcontractor"
  //   : key === "employeeRate"
  //   ? "Pay Rate"
  //   : key === "employeeId"
  //   ? "ID"
  //   : key === "salaryType"
  //   ? "Salary Type"
  //   : "";
};

export const UpdateCrew = ({
  crews,
  // title,
  refreshData,
  refreshTable,
  recordSelected,
  updateModalVisible,
  // setUpdateModalVisible,
  handleUpdateModalVisible,
}) => {
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  // const [confirmLoading, setConfirmLoading] = useState(false);
  const [status, setStatus] = useState();
  const [role, setRole] = useState();
  const [subcontractor, setSubcontractor] = useState([]);

  // const [selectForeman, setSelectForeman] = useState(null);
  // const [existingLogs, setExistingLogs] = useState([]);
  const [form] = Form.useForm();

  // const [editCrewTeam, setEditCrewTeam] = useState([]);
  // const [open, setOpen] = useState(false);
  // const [selectedTeam, setSelectedTeam] = useState([]);

  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );

  const dispatch = useDispatch();
  const { saveAddedLogs } = useEditLogs();

  const [tourOpen, setTourOpen] = useModalOnceObject("Tour");
  const [cancelRef, logsRef, saveRef] = useMultipleRefs(3);

  function stepsMapHelper(title) {
    if (title?.includes("Cancel")) {
      return cancelRef.current;
    } else if (title?.includes("Crew Logs")) {
      return logsRef.current;
    } else if (title?.includes("Save")) {
      return saveRef.current;
    }
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    return newSteps;
  }

  const dbSteps = mapRefs(findTutorialSteps("Update Crew", programFields));

  // const splitedName = recordSelected?.data?.crewName.split(" ");

  // const firstName = splitedName?.[0];
  // const middleName = splitedName?.length === 3 ? splitedName?.[1] : null;
  // const lastName =
  //   splitedName?.length === 3 ? splitedName?.[2] : splitedName?.[1];

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field?.fieldName === "Payroll Configuration"
      );
      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  const formFieldsJSON = useMemo(() => {
    return employeeFields(isDarkMode, role, status);
  }, [isDarkMode, role, status]);

  const formEmergencyContactJSON = useMemo(() => {
    return employeeEmergencyContactFields();
  }, [isDarkMode, role]);

  async function onFetchRate() {
    message.loading({
      key: "onFetchRate",
      content: "Fetching Rate...",
      duration: 0,
    });
    const dateRange = [dayjsNY().subtract(1, "m"), dayjsNY()];
    const employeeData = recordSelected?.api
      ? recordSelected.data
      : recordSelected;
    let employeeNumber = employeeData?.employeeNumber;
    if (!employeeData?.employeeNumber) {
      employeeNumber = (employeeData?.employeeId || "")?.replace(
        `${employeeData?.accountName}-`,
        ""
      );
    }

    const clientKey = clientConfigs.find(
      (el) => el.clientName === employeeData?.accountName
    )?.clientKey;

    let rateData = await getEmployeesRateByDateRange({
      dateRange,
      clientKey,
      employeeNumber,
    });

    if (rateData?.status === 200) {
      const data = rateData?.data?.[0];
      form.setFieldValue("employeeRate", data?.Rate);
      form.setFieldValue("fingerCheckId", data?.EmployeeID);
      form.setFieldValue("employeeNumber", employeeNumber);
      message.success({
        key: "onFetchRate",
        content: "Rate updated!",
        duration: 1.5,
      });
      return;
    }

    if (rateData?.status !== 200) {
      const codeLength = 6;
      const zerosToAdd = codeLength - employeeNumber.length;
      for (let i = 0; i < zerosToAdd; i++) {
        employeeNumber = "0" + employeeNumber;
      }

      getEmployeesRateByDateRange({
        dateRange,
        clientKey,
        employeeNumber,
      })
        .then((res) => {
          const data = res?.data?.[0];
          form.setFieldValue("employeeRate", data?.Rate);
          form.setFieldValue("fingerCheckId", data?.EmployeeID);
          form.setFieldValue("employeeNumber", employeeNumber);
          message.success({
            key: "onFetchRate",
            content: "Rate updated!",
            duration: 1.5,
          });
        })
        .catch((err) => {
          console.log("Error: ", err);
          message.error({
            key: "onFetchRate",
            content: "Could not get rate for this employee!",
            duration: 1.5,
          });
        });
    } else {
      message.error({
        key: "onFetchRate",
        content: "Could not get rate for this employee!",
        duration: 1.5,
      });
    }
  }

  async function onUpdateEmployee() {
    message.loading({
      key: "onUpdateEmployee",
      content: "Fetching Employee data...",
      duration: 0,
    });
    const employeeData = recordSelected?.api
      ? recordSelected.data
      : recordSelected;
    let employeeNumber = employeeData?.employeeNumber;
    if (!employeeData?.employeeNumber) {
      employeeNumber = (employeeData?.employeeId || "")?.replace(
        `${employeeData?.accountName}-`,
        ""
      );
    }

    const clientKey = clientConfigs.find(
      (el) => el.clientName === employeeData?.accountName
    )?.clientKey;

    let result = await getActiveEmployeesData(clientKey, employeeNumber)
      .then((res) => res)
      .catch((err) => {
        console.log("error: ", err);
      });

    if (result?.status !== 200) {
      const codeLength = 6;
      const zerosToAdd = codeLength - employeeNumber?.length;
      for (let i = 0; i < zerosToAdd; i++) {
        employeeNumber = "0" + employeeNumber;
      }
      result = await getActiveEmployeesData(clientKey, employeeNumber);
    }

    if (result?.status !== 200) {
      message.error({
        key: "onUpdateEmployee",
        content: "Could not get employee data from fingerCheck!",
        duration: 1.5,
      });
    } else {
      const data = result?.data?.[0];
      form.setFieldsValue({
        employeeNumber,
        foreman: data?.foreman,
        crewName: data?.crewName,
        crewStatus: data?.crewStatus,
        salaryType: data?.salaryType,
        crewPosition: data?.crewPosition,
        employeeRate: data?.employeeRate,
        fingerCheckId: data?.fingerCheckId,
        dynamicSelection: data?.dynamicSelection,
      });
      form.setFieldValue("crewStatus", data?.crewStatus);
      message.success({
        key: "onUpdateEmployee",
        content: "Employee Data Updated!",
        duration: 1.5,
      });
    }
  }

  const handleUpdate = async () => {
    form
      .validateFields()
      .then((val) => {
        // setConfirmLoading(true);
        // let crewName = `${val.name}${
        //   val?.middleName ? " " + val.middleName : ""
        // } ${val.lastName}`;
        const body = {
          crewName: val?.crewName,
          foreman:
            val.foreman === undefined
              ? recordSelected?.data?.foreman
              : val.foreman,
          // members: getMembersId(val.members) || [],
          crewStatus: val?.crewStatus,
          crewPosition: val?.crewPosition,
          accountName: val?.accountName,
          employeeRate: val.employeeRate,
          employeeNumber: val?.employeeNumber || "",
          employeeId: val?.employeeId,
          fingerCheckId: val?.fingerCheckId || "",
          salaryType: val?.salaryType,
          dynamicSelection: val?.dynamicSelection,
          absentCount: recordSelected?.absentCount ?? 0,
          emergencyContact: val?.emergencyContact,
          // teamsConfiguration,
        };

        let newEditLog = {
          recordId: recordSelected?.data?.crewId,
          recordName: recordSelected?.data?.crewName,
          category: "Crew Logs",
          actionType: "Edit",
          topic: "",
          currentData: {},
          label: "",
          previousData: {},
          updatedAt: dayjsNY().valueOf(),
          nameOfUser: `${authUser.given_name} ${authUser.family_name}`,
          cognitoUserId: userConfiguration?.cognitoUserId,
          updatedKeys: [],
        };

        const crewTeamsConfiguration = programFields.find(
          ({ fieldName }) => fieldName === "Crew Teams"
        );

        let crewTeams =
          crewTeamsConfiguration?.fieldOptions?.[
            process.env.NODE_ENV === "production" ? "prod" : "dev"
          ];

        if (typeof body?.foreman === "string") {
          const teamOfMember = crewTeams.find(
            ({ crewForeman }) => crewForeman?.crewId === body?.foreman
          );

          if (teamOfMember) {
            Object.assign(teamOfMember, {
              crewMembers: teamOfMember?.crewMembers.map((crew) =>
                crew?.crewId === recordSelected?.data?.crewId
                  ? {
                      crewId: recordSelected?.data?.crewId,
                      crewName: body?.crewName,
                      employeeId: body?.employeeId,
                    }
                  : crew
              ),
            });
            crewTeams = crewTeams.map((team) =>
              team?.crewTeamId === teamOfMember?.crewTeamId
                ? teamOfMember
                : team
            );
          }
        } else if (body?.foreman === true) {
          const teamOfMember = crewTeams.find(
            ({ crewForeman }) =>
              crewForeman?.crewId === recordSelected?.data?.crewId
          );
          if (teamOfMember) {
            Object.assign(teamOfMember, {
              crewForeman: {
                crewId: recordSelected?.data?.crewId,
                crewName: body?.crewName,
                employeeId: body?.employeeId,
              },
            });
            crewTeams = crewTeams.map((team) =>
              team?.crewTeamId === teamOfMember?.crewTeamId
                ? teamOfMember
                : team
            );
          }
        }

        const updatedProgramFields = programFields.map((field) =>
          field.fieldName === "Crew Teams"
            ? {
                ...field,
                fieldOptions: {
                  ...field.fieldOptions,
                  [process.env.NODE_ENV === "production" ? "prod" : "dev"]:
                    crewTeams,
                },
              }
            : field
        );

        const duplicateEmployeeId = crews
          .filter((crew) => crew?.crewId !== recordSelected?.data?.crewId)
          .some(
            (crewEl) =>
              (crewEl?.employeeId || "")?.trim() === val?.employeeId?.trim()
          );
        if (duplicateEmployeeId) {
          message.error("Employee with this Employee Id already exists!");
        } else {
          const broadCastChanges = [];

          for (let key in body) {
            let result;
            if (
              !!body[key] &&
              key !== "editLogs" &&
              recordSelected?.data[key]
            ) {
              result = getChangedData(body[key], recordSelected.data[key]);

              if (result !== false) {
                newEditLog.currentData[key] = result.curr;
                newEditLog.previousData[key] = result.prev;
                newEditLog.updatedKeys.push(key);

                newEditLog.currentData[key] !== newEditLog.previousData[key] &&
                  broadCastChanges.push(updatedKeys(key));
              }
            } else {
              continue;
            }
          }

          Promise.allSettled([
            API.patch("crews", `/crews/${recordSelected?.data?.crewId}`, {
              body: {
                ...body,
                // teamsConfiguration,
              },
            }),
            saveAddedLogs(newEditLog),
            API.patch(
              "programFields",
              `/programFields/${crewTeamsConfiguration.fieldId}`,
              {
                body: {
                  fieldOptions: {
                    ...crewTeamsConfiguration?.fieldOptions,
                    [process.env.NODE_ENV === "production" ? "prod" : "dev"]:
                      crewTeams,
                  },
                },
              }
            ),
          ])
            .then(([{ value: crewRes }]) => {
              if (!!refreshTable) {
                refreshTable(crewRes, "edit");
              }
              // setConfirmLoading(false);
              handleUpdateModalVisible();
              dispatch(setProgramFields(updatedProgramFields));
              refreshData().then((e) => {
                form.resetFields();
                message.success("Updated successfully");
              });

              broadcastNotification(
                "20",
                "onCrewEdit",
                [
                  {
                    common: userConfiguration?.nameOfUser,
                    common2: broadcastArrayToString(broadCastChanges),
                    commonNext: body.crewName,
                  },
                  {
                    userName: userConfiguration?.nameOfUser,
                    currentUser: authUser?.sub,
                    cognitos: getCognitosForNotification(
                      userConfiguration
                      // teamsConfiguration
                    ),
                    recordId: recordSelected?.data?.crewId,
                  },
                ],
                ""
              );
            })
            .catch((e) => {
              console.error(e);
              // setConfirmLoading(false);
              message.error("Error while updating");
            });
        }
      })
      .catch((e) => console.error(e));
  };

  useEffect(() => {
    if (!!programFields?.length) {
      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      );
      setStatus(
        fieldObj?.["Crew Status"]?.filter(
          ({ statusName }) => statusName !== "s" && statusName !== "test"
        )
      );
      setRole(fieldObj?.["Crew Position"]);
    }
  }, [userConfiguration, programFields]);

  useEffect(() => {
    if (recordSelected) {
      fetchData("accounts").then((r) => {
        setSubcontractor(() =>
          r?.filter((item) =>
            compareIncluding(item?.accountRecordType, "subcontractors")
          )
        );
      });
      // API.get("teams", "/teams").then((teams) => {
      //   setEditCrewTeam(
      //     teams?.map((team) => ({
      //       label: team.teamName,
      //       value: team.teamName,
      //       members: team.members?.map(
      //         ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
      //           identityId,
      //           nameOfUser,
      //           cognitoUserId,
      //         })
      //       ),
      //     }))
      //   );
      // });

      form.setFieldsValue(recordSelected?.data);
      form.setFieldValue(
        "foreman",
        typeof recordSelected?.data?.foreman === "string"
          ? false
          : recordSelected?.data?.foreman
      );
      form.setFieldValue("employeeId", recordSelected?.data?.employeeId);
      form.setFieldValue("employeeRate", recordSelected?.data?.employeeRate);
    }
  }, [recordSelected]);

  // const ClearOptions = () => {
  //   setOpen(false);
  //   setSelectedTeam([]);
  // };

  // const openMemberModal = () => {
  //   setOpen(true);
  // };

  return (
    <>
      {recordSelected && (
        <Modal
          className={`editCrewModal ${isDarkMode && "editCrewModalDark"} `}
          title={
            <CustomModalHeader
              title={`Edit ${recordSelected?.data?.crewName}`}
              onClick={() => {
                setTourOpen(true);
              }}
            />
          }
          open={updateModalVisible}
          closable={true}
          closeIcon={<XIcon />}
          onCancel={handleUpdateModalVisible}
          centered={true}
          destroyOnClose={true}
          data-testid="edit-crew-modal"
          footer={footerButtons({
            logsRef,
            saveRef,
            cancelRef,
            handleUpdate,
            recordSelected,
            onCancel: () => handleUpdateModalVisible(),
          })}
        >
          <div className="updateCrewInfo">
            <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
            <p className="updateCrewText">
              Edit crew member details such as name,role,pay rate,and more.You
              can also retrieve the latest rate and sync data with Fingercheck.
            </p>
          </div>
          <Form form={form}>
            <section className="fingerCheckFetch">
              <InputComponent
                {...{
                  label: "Subcontractor",
                  type: "select",
                  required: true,
                  placeholder: "Select subcontractor...",
                  formItemName: "accountName",
                  className: "editCrewSubcontractor",
                  dropdownClassName: `${isDarkMode && "darkDropDown"}`,
                  customOptions: (subcontractor || [])?.map(
                    ({ accountName }, key) => ({
                      key,
                      label: accountName,
                      value: accountName,
                    })
                  ),
                  getPopUpContainer: document.body,
                }}
              />
              <div className="employeeNumberTextDiv">
                <div
                  onClick={onFetchRate}
                  style={{ cursor: "pointer" }}
                  className="employeeLatestRate"
                >
                  <span className="employeeNumberText">Get latest rate</span>
                  <VectorIcon />
                </div>
                <div
                  onClick={onUpdateEmployee}
                  style={{ cursor: "pointer" }}
                  className="updateEmployeeData"
                >
                  <span className="employeeNumberText">
                    Update employee data
                  </span>
                  <VectorIcon />
                </div>
              </div>
            </section>
            <section className="formSection">
              {RenderDynamicComponents(formFieldsJSON.slice(0, 3), form)}
            </section>
            <section className="firstFormSection">
              {RenderDynamicComponents(formFieldsJSON.slice(3, 8), form)}
            </section>
            {/* <section className="formSection">
              {RenderDynamicComponents(formFieldsJSON.slice(4, 7), form)}
            </section> */}
            <section style={{ display: "none" }}>
              {RenderDynamicComponents(formFieldsJSON.slice(7, 10), form)}
            </section>
            {/* <div className="avatarEditDiv">
              {RenderDynamicComponents(formFieldsJSON.slice(8, 9))}
              {!!selectedTeam?.length && (
                <MondayButton
                  onClick={openMemberModal}
                  Icon={<PlusOutlined />}
                  className="membersButton"
                >
                  Members
                </MondayButton>
              )}
            </div> */}
            <Divider
              style={{
                ...(isDarkMode && {
                  color: "white",
                  borderColor: "white",
                }),
              }}
            >
              Emergency Contact Fields
            </Divider>
            <MultipleInputs
              name={formEmergencyContactJSON.formItemName || ""}
              label={""}
              value={recordSelected?.data?.emergencyContact || []}
              dynamicFields={formEmergencyContactJSON.dynamicFields || []}
              form={form}
              isDarkMode={isDarkMode}
            />
          </Form>
        </Modal>
      )}
      {/* {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions,
          }}
        />
      )} */}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => {
            setTourOpen(false);
          }}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
    </>
  );
};

import { API } from "aws-amplify";

export const fetchData = async (
  endpoint,
  query,
  queryStringParameters = undefined
) => (
  (query = query || endpoint),
  await API.get(endpoint, `/${query}`, queryStringParameters)
);

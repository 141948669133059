import { Checkbox } from "antd";
import AccessTree from "../AccessTree/AccessTree";
import { DownArrow } from "../UserAccessWrites/components/FoldersAccess";
import "../AccessRadioGroup/AccessRadioGroup.scss";

function AccessCheckboxGroup({
  optionsProps = {},
  selectedRoute = {},
  onCollapseClick = () => {},
  collapsedOpen = false,
  className = "",
  setAccessConfiguration = () => {},
  disabled = false,
  isDarkMode = false,

  ////////
  setExpandedKeys,
  setAutoExpandParent,
  expandedKeys,
  autoExpandParent,

  searchRouteConfigs,
}) {
  const onExpand = (newExpandedKeys) => {
    setExpandedKeys((prev) => ({
      ...prev,
      [title]: newExpandedKeys || [],
    }));
    setAutoExpandParent(false);
  };

  const { children = null, title } = selectedRoute;

  function doesIncludes(word, searchWord) {
    if (!searchWord) return true;
    if (!word) return false;
    return word?.toLowerCase().includes(searchWord.toLowerCase());
  }

  const childrenWithSearch =
    children && searchRouteConfigs
      ? children.filter((child) => findChildren(child, searchRouteConfigs))
      : children;

  function findChildren(route, searchRouteConfigs) {
    if (doesIncludes(route.title, searchRouteConfigs)) {
      return true;
    }
    if (route.children) {
      return route.children.some((child) =>
        findChildren(child, searchRouteConfigs)
      );
    }
    return false;
  }

  return (
    <>
      <div
        id="AccessCheckboxGroup"
        key={title}
        className={`radio-group-wrapper ${className} ${
          isDarkMode && "radio-group-wrapper-dark"
        } `}
        style={collapsedOpen ? { borderBottom: "none" } : {}}
      >
        <div className={`radio-group-label`}>
          {children && (
            <DownArrow
              onClick={onCollapseClick}
              style={{
                cursor: "pointer",
                transform: `rotate(${collapsedOpen ? "0deg" : "-90deg"})`,
                fill: isDarkMode ? "white" : "#323338",
              }}
            />
          )}
          <HighlightedText text={title} searchValue={searchRouteConfigs} />
        </div>
        <div className="checkbox-group-options">
          {renderCheckbox({ ...optionsProps, disabled })}
        </div>
      </div>
      {childrenWithSearch && collapsedOpen && (
        <AccessTree
          {...{
            children: childrenWithSearch,
            setAccessConfiguration,
            parent: selectedRoute,
            disabled,
            isDarkMode,

            /////////
            autoExpandParent,
            onExpand,
            expandedKeys: expandedKeys?.[title] || [],

            searchRouteConfigs,
          }}
        />
      )}
    </>
  );
}

export default AccessCheckboxGroup;

const renderCheckbox = ({
  read,
  write,
  onReadClick,
  onWriteClick,
  showWrite,
  disabled = false,
}) => {
  const options = [
    {
      value: "read",
      label: "Read",
      checked: read,
      onChange: onReadClick,
      disabled,
    },
    showWrite && {
      value: "write",
      label: "Write",
      checked: write,
      onChange: onWriteClick,
      disabled,
    },
  ].filter(Boolean);
  return options.map(({ label, checked, value, onChange, disabled }) => (
    <Checkbox
      key={label}
      {...{
        checked,
        value,
        onChange,
        disabled,
      }}
    >
      {label}
    </Checkbox>
  ));
};

const HighlightedText = ({
  text,
  searchValue,
  type = "mark",
  color = "yellow",
}) => {
  const highlightMatches = (text, searchValue) => {
    if (!searchValue) return text;

    const regex = new RegExp(`(${searchValue})`, "gi"); // Case-insensitive matching
    const parts = text.split(regex);

    return parts.map((part, index) =>
      regex.test(part) ? (
        type === "mark" ? (
          <mark style={{ backgroundColor: color }} key={index}>
            {part}
          </mark>
        ) : type === "char" ? (
          <span key={index} style={{ color: color, fontWeight: "bold" }}>
            {part}
          </span>
        ) : (
          part
        )
      ) : (
        part
      )
    );
  };

  return <span>{highlightMatches(text, searchValue)}</span>;
};

import { gapi } from "gapi-script";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../utils";
import {
  AllFolders,
  CreateFolderProps,
  Folders,
  UploadObject,
} from "../helpers";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import loadScript from "load-script";
import Upload from "../../../../../../SidebarPages/Projects/Upload";
import { scheduleDay } from "../../../../../../SidebarPages/Scheduling/ScheduleChangesModal/Helpers/Types";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

interface Props {
  folderName: string;
  serviceFolders: Folders;
  getFolderIdOrCreate: (folder: CreateFolderProps) => Promise<string>;
}

export const createFolder = async (
  folderName: string,
  serviceFolders: Folders,
  getFolderIdOrCreate: (folder: CreateFolderProps) => Promise<string>
): Promise<string> => {
  showLoadingMsg({ content: "Creating folder...", key: folderName });
  return new Promise((resolve, reject) => {
    getFolderIdOrCreate({
      name: folderName,
      parents: [serviceFolders?.id],
    })
      .then((folderId) => {
        showSuccessMsg({ content: "Folder created", key: folderName });
        resolve(folderId);
      })
      .catch((err) => {
        console.error({ err });
        showErrorMsg({ content: "Error creating folder", key: folderName });
        reject(err);
      });
  });
};

interface State {
  accessToken: {
    accessToken: string;
  };
  [key: string]: any;
}

// type DocType = {
//   [key: string]: UploadObject[];
// };

type PickerResponse = {
  action: string;
  docs: UploadObject[];
};

let scriptLoadingStarted = false;
const GOOGLE_SDK_URL = "https://apis.google.com/js/api.js";

export const useGooglePicker = () => {
  const { accessToken } = useSelector((state: State) => state.accessToken);
  const [docs, setDocs] = useState({});

  function isGoogleReady() {
    return !!gapi;
  }

  function onApiLoad() {
    gapi.load("picker");
  }

  function isGooglePickerReady() {
    // @ts-ignore
    const google = window.google as any;
    return !!google.picker;
  }

  function createPicker({
    accessToken,
    parentId,
  }: {
    accessToken: string;
    parentId: string;
  }): Promise<UploadObject[]> {
    return new Promise((resolve, reject) => {
      // @ts-ignore
      const google = window.google as any;
      const uploadView = new google.picker.DocsUploadView()
        .setIncludeFolders(false)
        .setParent(parentId);

      let uploadedDocs = [];

      const picker = new google.picker.PickerBuilder()
        .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
        .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
        .enableFeature(google.picker.Feature.MINE_ONLY)
        .enableFeature(google.picker.Feature.NAV_HIDDEN)
        .addView(uploadView)
        .setOAuthToken(accessToken)
        .setDeveloperKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo")
        .setCallback((data: PickerResponse) => {
          if (
            data.action === google.picker.Action.PICKED &&
            !!data.docs.length
          ) {
            uploadedDocs = data.docs;
            setDocs((prev) => ({
              ...prev,
              [parentId]: [...(docs?.[parentId] || []), uploadedDocs],
            }));
            resolve(data.docs);
          }
        });
      picker.build().setVisible(true);
    });
  }

  useEffect(() => {
    if (isGoogleReady()) {
      // google api is already exists
      // init immediately
      onApiLoad();
    } else if (!scriptLoadingStarted) {
      // load google api and the init
      scriptLoadingStarted = true;
      loadScript(GOOGLE_SDK_URL, onApiLoad);
    } else {
      // is loading
    }

    return () => {
      setDocs([]);
    };
  }, []);

  return {
    openGooglePicker: ({ parentId }: { parentId: string }) => {
      if (isGooglePickerReady() && accessToken && !!isGoogleReady()) {
        return createPicker({ parentId, accessToken });
      }
    },
    docs,
  };
};

type DriveQueue = {
  action: string;
  day: string | string[];
};

type DeleteFunctionProps = {
  action: string;
  driveRequest: any;
  googleDriveFolderIds: AllFolders;
  oldGoogleDriveFolderIds?: AllFolders;
  day: string | string[];
  updateGoogleDriveFolders: (folders: AllFolders, label?: string) => void;
  addGoogleDriveFolderToQueue?: (folderQueue: DriveQueue) => void;
  googleDriveFolderQueue?: DriveQueue[];
  parentFolderId?: string;
};

type FolderFunctionsProps = DeleteFunctionProps;

export function driveFolderFunction(props: FolderFunctionsProps) {
  const { googleDriveFolderQueue = [], googleDriveFolderIds } = props;
  if (googleDriveFolderQueue.length > 0) {
    googleDriveFolderQueue.forEach((folder, index) => {
      driveFolderFunction({
        ...props,
        googleDriveFolderQueue: [],
        day: folder.day,
        action: folder.action,
      });
      delete googleDriveFolderQueue[index];
    });
  } else if (props?.action === "delete") {
    const { driveRequest, day, updateGoogleDriveFolders } = props;

    if (typeof day === "string") {
      Object.values(googleDriveFolderIds).forEach((folder) => {
        if (typeof folder === "object") {
          if (folder?.[day]) {
            driveRequest.deleteDriveItem(folder?.[day]);
            delete folder[day];
          }
        }
      });
    } else if (Array.isArray(day)) {
      const { oldGoogleDriveFolderIds } = props;
      Object.entries(googleDriveFolderIds).forEach(([key, folder]) => {
        if (typeof folder === "object") {
          Object.keys(folder).forEach((day) => {
            if (!oldGoogleDriveFolderIds?.[key]?.[day]) {
              driveRequest.deleteDriveItem(folder?.[day]);
              delete folder[day];
            }
          });
        }
      });
    }

    updateGoogleDriveFolders(googleDriveFolderIds);
  } else if (props?.action === "delete-queue") {
    const { addGoogleDriveFolderToQueue, day } = props;
    addGoogleDriveFolderToQueue({ action: "delete", day });
  } else if (props?.action === "create-folder") {
    const {
      addGoogleDriveFolderToQueue,
      day,
      driveRequest,
      updateGoogleDriveFolders,
      parentFolderId,
    } = props;

    if (typeof day !== "string") return;

    driveRequest
      ?.getFolderIdOrCreate({
        name: day,
        parents: [parentFolderId],
      })
      .then((folderId) => {
        updateGoogleDriveFolders(
          {
            id: folderId,
          },
          day
        );

        addGoogleDriveFolderToQueue({ action: "delete", day });
      });
  }

  return googleDriveFolderIds;
}

import dayjs from "dayjs";
import weekOfYear from "dayjs/plugin/weekOfYear";
import { v4 as uuidv4 } from "uuid";

import { COST_ROLES } from "./cellFunctions";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import { withinRadius } from "../../../../Activity/components/payrollActivityModalData";

dayjs.extend(weekOfYear);

/**
 * @typedef jobsiteMatch
 * @property {string} googleSheetLink
 * @property {string} jobAddress
 * @property {string} jobsiteId
 * @property {string} jobName
 * @property {object | undefined} rates
 */

/**
 * @typedef shiftObjectType
 * @property {number} breakHours
 * @property {string} company
 * @property {string | undefined} companyName
 * @property {string} crewId
 * @property {number} distanceFromJob
 * @property {number} duration
 * @property {string} employeeFullName
 * @property {string} employeeId
 * @property {number} employeeRate
 * @property {string} employeeRole
 * @property {object | undefined} employeeType
 * @property {string[]} entries
 * @property {number} firstClockIn
 * @property {string | undefined} geofence
 * @property {boolean} hrShift
 * @property {string} jobsiteAddress
 * @property {string} jobsiteId
 * @property {jobsiteMatch} jobsiteMatch
 * @property {number} lunchStart
 * @property {number} otAmount
 * @property {number} overtimeHours
 * @property {number} ovhAmount
 * @property {number} ovhOvertime
 * @property {number} ovhRegular
 * @property {string} payrollType
 * @property {{lat: number, lng: number}} punchCoordinates
 * @property {dayjs.Dayjs} punchDate
 * @property {dayjs.Dayjs} punchTime
 * @property {string | undefined} reason
 * @property {number} regAmount
 * @property {number | undefined} reimbursement
 * @property {string} salaryType
 * @property {string} shiftId
 * @property {string} shiftStatus
 * @property {string} shiftType
 * @property {string[]} sow
 * @property {number} total
 * @property {number} totalOvh
 * @property {number} workHours
 * @property {string} uploadId
 * @property {string} uploadName
 */

function groupEntriesInShifts({
  analytics,
  crews,
  jobsites,
  filterOvertime = false,
  rowData = [],
}) {
  let tmpOverheadRows = [];

  for (const entry of rowData) {
    if (["CASH", "1099"].includes(entry?.punchType)) {
      let newShiftEntry = {
        degId: entry?.degId,
        breakHours: 0,
        clockOut: null,
        company: entry?.company || entry?.companyName,
        companyName: entry?.company,
        crewId: entry?.crewId,
        distanceFromJob: undefined,
        duration: undefined,
        employeeFullName: entry?.employeeFullName,
        employeeId: entry?.employeeId,
        employeeRate: entry?.employeeRate,
        employeeType: undefined,
        entries: [entry?.entryId],
        firstClockIn: entry?.punchTimeStamp,
        geofence: undefined,
        hrShift: false,
        jobsiteAddress: "",
        jobsiteId: "",
        jobsiteMatch: {
          jobsiteId: "",
          jobAddress: "",
          jobName: "",
          googleSheetLink: "",
        },
        lunchStart: undefined,
        otAmount: 0,
        ovhAmount: Number(entry?.totalOvh),
        ovhOvertime: 0,
        ovhRegular: 0,
        payrollType: "",
        punchCoordinates: undefined,
        punchDate: entry?.punchDate,
        punchTime: entry?.punchDate,
        reason: entry?.reason,
        regAmount: 0,
        reimbursement: 0,
        salaryType: entry?.salaryType,
        // shiftId: uuidv4(),
        shiftId: entry?.entryId,
        shiftStatus: entry?.activityStatus,
        shiftType: `${entry?.punchType} Shift`,
        sow: [],
        total: 0,
        totalOvh: Number(entry?.totalOvh),
        uploadId: entry?.uploadId,
        uploadName: entry?.uploadName,
        workHours: 0,
      };
      tmpOverheadRows.push(newShiftEntry);
    }
  }

  /**@type {shiftObjectType[]} */
  const result = Object.keys(analytics?.employeesHoursPerDay || {})
    .flatMap((employeeId) => {
      let entriesForEmployee = [];

      for (const date in analytics.employeesHoursPerDay[employeeId]) {
        let dayShift = analytics.employeesHoursPerDay[employeeId][date];
        const {
          company = dayShift?.company || dayShift?.companyName,
          companyName,
          crewId,
          geofence,
          salaryType,
          uploadId,
          uploadName,
          punchDate,
          punchTime,
          employeeType,
          lunchStart,
          reason,
          duration,
          distanceFromJob,
          scheduleId = null,
          scheduleAddress = null,
          scheduleDayId = null,
          crewTeamName = null,
          crewTeamId = null,
        } = dayShift;

        for (const shift of dayShift["jobsitesInfo"]) {
          let otToAdd = shift?.otAmount;
          let reimbursement = 0;

          let shiftStatus = (shift["activityStatusesArray"] || []).every(
            (status) => status === "Completed"
          )
            ? "Completed"
            : (shift["activityStatusesArray"] || []).every(
              (status) => status === "Draft"
            )
              ? "Draft"
              : "Pending";

          let tmpEmployee =
            crews?.find(({ employeeId: e }) => e === employeeId) ||
            rowData.find(({ employeeId: e }) => e === employeeId);

          let overheadCondition =
            shift?.hrShift ||
            (!shift?.inJobsiteId && !shift?.outJobsiteId) ||
            !COST_ROLES.includes(shift?.employeeRole);

          //calculations for overtime
          if (tmpEmployee?.salaryType === "Salary") {
            otToAdd = 0;
          } else {
            if (
              shift?.payrollType === "Open Shop" ||
              shift?.payrollType === "Certified Payroll"
              // shift?.payrollType === "Prevailing Wage"
            ) {
              if (!overheadCondition) {
                let weekWorked = dayjs(shift?.firstClockIn).week();
                let weekTotal =
                  analytics?.employeeWeekTotals?.[employeeId]?.[weekWorked];
                let weeklyPercentage = 0;
                if (weekTotal?.weeksTypeOvertimeHours) {
                  weeklyPercentage =
                    (shift?.overtimeHours + shift?.workHours) /
                    (weekTotal.weeksTypeOvertimeHours + 40);
                }

                otToAdd = weeklyPercentage * weekTotal?.weekTypesOvertime;
              }
            }
          }

          /**
           * Check if employee have worked on 2 reimbursement jobsites
           * that are close to each other on the same day and get reimbursement
           * only from the first jobsite
           */
          if (shift?.reimbursement) {
            const reimbursementShiftJobs = dayShift?.["jobsitesInfo"].filter(
              ({ reimbursement }) => reimbursement
            );

            const earliestJobWork = reimbursementShiftJobs.sort(
              (a, b) => a?.firstClockIn - b?.firstClockIn
            )?.[0];

            if (earliestJobWork?.entries?.[0] === shift?.entries?.[0]) {
              reimbursement = shift?.employeeRate * 3;
            } else if (
              withinRadius(
                shift.punchCoordinates,
                earliestJobWork.punchCoordinates,
                5280
              ).withinRange
            ) {
              reimbursement = shift?.employeeRate * 3;
            } else {
              reimbursement = 0;
            }
          }

          let newEntry = {
            degId: shift?.degId,
            employeeFullName:
              tmpEmployee?.crewName || tmpEmployee?.employeeFullName || "",
            employeeId,
            employeeRole: tmpEmployee?.crewPosition,
            employeeRate: shift?.employeeRate,
            firstClockIn: shift?.firstClockIn,
            clockOut: dayjsNY(shift?.firstClockIn)
              .add(
                shift?.workHours + shift?.breakHours + shift?.overtimeHours,
                "hours"
              )
              .valueOf(),
            punchCoordinates: shift?.punchCoordinates,
            hrShift: shift?.hrShift,
            // shiftId: shift?.shiftId ? shift?.shiftId : uuidv4(),
            shiftId: shift?.entries?.[0] || uuidv4(),
            scheduleId,
            scheduleAddress,
            scheduleDayId,
            crewTeamName,
            crewTeamId,
            company: company || companyName,
            companyName,
            crewId,
            geofence,
            salaryType,
            uploadId,
            uploadName,
            punchDate,
            punchTime,
            employeeType,
            entries: shift?.entries || [],
            lunchStart,
            shiftStatus,
            reason,
            payrollType: shift?.payrollType,
            shiftType: shift?.hrShift ? "HR Shift" : "Regular Shift",
            workHours: shift?.workHours,
            breakHours: shift?.breakHours,
            overtimeHours: shift?.overtimeHours,
            ovhRegular: shift?.regAmount,
            ovhOvertime: otToAdd,
            reimbursement,
            regAmount: overheadCondition ? 0 : shift?.regAmount,
            ovhAmount: overheadCondition ? shift?.regAmount + otToAdd : 0,
            otAmount: overheadCondition ? 0 : otToAdd,
            total: overheadCondition
              ? 0
              : shift?.regAmount + otToAdd + reimbursement,
            totalOvh: overheadCondition ? shift?.regAmount + otToAdd : 0,
            jobsiteAddress: "",
            sow: shift?.sow || [],
            jobsiteId: "",
            duration,
            distanceFromJob,
            jobsiteMatch: {
              jobName: "",
              jobsiteId: "",
              jobAddress: "",
              googleSheetLink: "",
            },
          };

          if (shift?.inJobsiteId || shift?.outJobsiteId) {
            let selectedJobsite = jobsites?.find(({ jobsiteId }) =>
              shift?.inJobsiteId
                ? jobsiteId === shift?.inJobsiteId
                : jobsiteId === shift?.outJobsiteId
            );
            if (selectedJobsite) {
              newEntry["jobsiteAddress"] = selectedJobsite?.jobAddress;
              newEntry["jobsiteId"] = selectedJobsite?.jobsiteId;
              newEntry["jobsiteMatch"] = {
                jobName: selectedJobsite?.jobName,
                jobsiteId: selectedJobsite?.jobsiteId,
                jobAddress: selectedJobsite?.jobAddress,
                googleSheetLink: selectedJobsite?.googleSheetLink,
                rates: selectedJobsite?.rates,
              };
            }
          }

          if (filterOvertime) {
            if (newEntry?.otAmount > 0) {
              entriesForEmployee.push(newEntry);
            }
          } else {
            entriesForEmployee.push(newEntry);
          }
        }
      }

      return entriesForEmployee;
    })
    .concat(tmpOverheadRows);

  return result;
}

export default groupEntriesInShifts;

import { GridApi } from "ag-grid-community";
import { Dispatch, SetStateAction } from "react";

import { EntryType } from "../payrollLiveTypes";
import { MondayButton } from "src/components/commonComponents";
import { formatCurrency } from "src/components/SidebarPages/utils";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import {
  DEG_DATE_FORMAT,
  DEG_TIME_FORMAT,
} from "../../Payroll/Tabs/DEG/components/modalComponents/utils/cellFunctions";
import { RouteConfig } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { formatDurationDeg } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils";

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

type EntryColumnsProps = {
  accessRight: RouteConfig;
  onEditClick: (data: EntryType) => void;
  onDelete: (data: EntryType, api: GridApi) => void;
  setNewEmpModal: Dispatch<SetStateAction<boolean | NewEmpModalType>>;
};

const punchStatusColor = {
  ID: "#00a4648b",
  OD: "#7876778b",
  "No Punch": "#f04f4e8b",
};

const statusColors = {
  Completed: "#7ac14d",
  Pending: "#ecde7c",
  Draft: "#787677",
};

export function entryColumnDefs({
  onDelete,
  onEditClick,
  accessRight,
  setNewEmpModal,
}: EntryColumnsProps) {
  const colAccesses = {};
  for (let i = 0; i < accessRight?.children.length; i++) {
    const childAccess = accessRight?.children[i];
    Object.assign(colAccesses, { [childAccess?.title]: childAccess?.write });
  }
  return [
    {
      headerName: "Nr",
      colId: "3",
      minWidth: 80,
      isRowSelectable: false,
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      },
    },
    {
      headerName: "Origin",
      field: "uploadName",
      minWidth: 130,
      cellClass: "cell-left-aligned",
    },
    accessRight?.write && {
      headerName: "Edit",
      colId: "1",
      skipColumnGroupHeaders: true,
      skipColumnHeaders: true,
      minWidth: 100,
      cellClass: "cell-center-aligned",
      cellRenderer: ({ data, context }) => {
        return (
          <span
            onClick={() => onEditClick(data)}
            style={{ color: "#1264a3", cursor: "pointer", fontWeight: 600 }}
          >
            Edit
          </span>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Last Report",
      field: "lastFetch",
      enableRowGroup: true,
      // rowGroup: true,
      minWidth: 150,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ value }) => {
        if (!!value) {
          return dayjsNY(value).format(DEG_DATE_FORMAT + " " + DEG_TIME_FORMAT);
        } else {
          return "- || -";
        }
      },
    },
    {
      headerName: "Status",
      field: "activityStatus",
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
      minWidth: 120,
      cellStyle: ({ value }) => ({
        color: statusColors?.[value],
        fontWeight: 600,
      }),
      cellRenderer({ value }) {
        return value || "";
      },
    },
    {
      headerName: "Employee Id",
      field: "employeeId",
      enableRowGroup: true,
      minWidth: 160,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
    },
    {
      headerName: "Employee Full Name",
      field: "employeeFullName",
      enableRowGroup: true,
      minWidth: 170,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
      valueGetter: ({ data }) => {
        if (!!data?.crewName?.length) {
          return data?.crewName || "";
        } else {
          return data?.employeeFullName || "";
        }
      },
    },
    {
      headerName: "Punch Time",
      field: "punchTime",
      enableRowGroup: true,
      minWidth: 120,
      filter: "agTimeColumnFilter",
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value && "HR" !== data?.punchType
          ? { background: "#f54c4a99" }
          : {};
      },
      cellRenderer: ({ value }) => {
        if (!value) {
          return "-||-";
        }
        const punchTime = dayjsNY(value).format(DEG_TIME_FORMAT);
        return punchTime === "Invalid Date" ? "-||-" : punchTime;
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (!value) {
            return "-||-";
          }
          const punchTime = dayjsNY(value).format(DEG_TIME_FORMAT);
          return punchTime === "Invalid Date" ? "-||-" : punchTime;
        },
      },
    },
    {
      headerName: "Punch Type",
      field: "punchType",
      minWidth: 120,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, context }) => {
        return !value?.length
          ? {
              background: "#f54c4a99",
            }
          : {
              background: punchStatusColor?.[value],
              color:
                context?.darkMode || !!punchStatusColor?.[value]
                  ? "#fff"
                  : "#231f20",
            };
      },
      enableRowGroup: true,
    },
    {
      headerName: "Team",
      field: "crewTeamName",
      cellClass: "cell-left-aligned",
      minWidth: 130,
      enableRowGroup: true,
      cellRenderer: ({ value, data, context }) => {
        if (value) {
          return value;
        }

        let teamName = "";

        for (let i = 0; i < context?.crewTeams?.length; i++) {
          const team = context?.crewTeams?.[i];
          const isForeman =
            team?.crewForeman?.employeeId === data?.employeeId ||
            team?.crewForeman?.crewId === data?.crewId;
          const isMember =
            team?.crewMembers?.findIndex(
              (member) =>
                member?.employeeId === data?.employeeId ||
                member?.crewId === data?.crewId
            ) > -1;
          if (isForeman || isMember) {
            teamName = team?.crewTeamName;
          }
        }

        return teamName || "";
      },
    },
    {
      headerName: "Jobsite Address",
      field: "jobAddress",
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      minWidth: 200,
      // getQuickFilterText: ({ value }) => {
      //   return typeof value === "string" ? value : value;
      // },
      cellRenderer: ({ value, data }) => {
        return value;
      },
      filterParams: {
        valueGetter: ({ data }) => {
          return data?.jobsiteMatch?.jobAddress || "";
        },
      },
    },
    {
      headerName: "Distance",
      field: "distanceFromJob",
      enableRowGroup: true,
      filter: "agNumberColumnFilter",
      minWidth: 120,
      valueGetter: ({ data }) => {
        const distance = Number(data?.distanceFromJob) || 0;
        return distance >= 5280
          ? `${Number(distance / 5280).toFixed(2)} miles`
          : (distance ?? "0") + ` ft`;
      },
      filterParams: {
        valueGetter: ({ data }) => {
          return data?.distanceFromJob || 0;
        },
      },
    },
    {
      headerName: "Duration",
      field: "duration",
      minWidth: 120,
      enableRowGroup: true,
      valueGetter: ({ data }) => {
        return formatDurationDeg(data?.duration, "seconds")?.text;
      },
    },
    colAccesses?.["Rate"] && {
      headerName: "Rate",
      field: "employeeRate",
      enableRowGroup: true,
      minWidth: 100,
      cellClass: "cell-left-aligned",
      cellRenderer({ value }) {
        if (isNaN(parseFloat(value))) {
          return formatCurrency(0);
        } else {
          return formatCurrency(value);
        }
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (isNaN(parseFloat(value))) {
            return formatCurrency(0);
          } else {
            return formatCurrency(value);
          }
        },
      },
    },
    {
      headerName: "Role",
      field: "employeeRole",
      minWidth: 120,
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
    },
    {
      headerName: "Type",
      field: "type",
      minWidth: 120,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
    },
    {
      headerName: "Payroll Type",
      field: "payrollType",
      enableRowGroup: true,
      hide: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
    },
    {
      headerName: "SOW",
      field: "sow",
      minWidth: 100,
      cellClass: "cell-center-aligned",
      cellRenderer: ({ value }) => {
        return (
          <div
            style={{
              height: 32,
              width: 32,
              color: "#fff",
              borderRadius: 5,
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
              backgroundColor: value?.length ? "#1264a3" : "#fe4c4ab1",
              border: `1px solid ${value?.length ? "#1264a3" : "#fe4c4a"}`,
            }}
          >
            {value?.length || 0}
          </div>
        );
      },
    },
    {
      headerName: "Punch Date",
      field: "punchDate",
      minWidth: 120,
      enableRowGroup: true,
      filter: "agDateColumnFilter",
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value ? { background: "#f54c4a99" } : {};
      },
      cellRenderer: ({ value, data }) => {
        if (!value) {
          return "-||-";
        }
        // if (value.includes('+02:00')) {
        //   console.log('value: ', {value, data});
        // }
        const punchDate = dayjsNY(value).format(DEG_DATE_FORMAT);
        return punchDate === "Invalid Date" ? "-||-" : punchDate;
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (!value) {
            return "-||-";
          }
          const punchDate = dayjsNY(value).format(DEG_DATE_FORMAT);
          return punchDate === "Invalid Date" ? "-||-" : punchDate;
        },
      },
    },
    {
      headerName: "Punch Location",
      field: "punchLocation",
      enableRowGroup: true,
      minWidth: 200,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value?.length && "HR" !== data?.punchType
          ? { background: "#f54c4a99" }
          : {};
      },
    },
    {
      headerName: "Reason",
      field: "reason",
      enableRowGroup: true,
      minWidth: 130,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value?.length && data?.punchType === "HR"
          ? { background: "#f54c4a99" }
          : {};
      },
    },
    {
      headerName: "Punch Time Stamp",
      field: "punchTimeStamp",
      filter: "agNumberColumnFilter",
      hide: true,
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    accessRight?.write && {
      headerName: "Remove",
      skipColumnGroupHeaders: true,
      skipColumnHeaders: true,
      cellClass: "cell-center-aligned",
      minWidth: 150,
      cellRenderer: ({ data, api }) => {
        return (
          <span
            onClick={() => onDelete(data, api)}
            style={{ cursor: "pointer", fontWeight: 600, color: "#fe4c4a" }}
          >
            Remove
          </span>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    accessRight?.write && {
      headerName: "Action",
      field: "employeeId",
      colId: "employeeId_1",
      skipColumnGroupHeaders: true,
      skipColumnHeaders: true,
      minWidth: 150,
      cellStyle: {
        display: "grid",
        placeContent: "center",
      },
      cellRenderer: ({ data }) => {
        return (
          <MondayButton
            tooltipKey={" "}
            disabled={data?.crewId}
            hasIcon={false}
            onClick={() =>
              setNewEmpModal({
                employeeFullName: data?.employeeFullName,
                employeeId: data?.employeeId,
                accountName: data?.company,
                employeeNumber: !!data?.employeeId
                  ? (data?.employeeId || "")?.split("-")?.[1]
                  : null,
              })
            }
          >
            Add Employee
          </MondayButton>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
  ].filter(Boolean);
}

export function shiftColumnDefs({ accessRight }: { accessRight: RouteConfig }) {
  const colAccesses = {};
  for (let i = 0; i < accessRight?.children.length; i++) {
    const childAccess = accessRight?.children[i];
    Object.assign(colAccesses, { [childAccess?.title]: childAccess?.write });
  }
  return [
    {
      headerName: "Nr",
      colId: "3",
      minWidth: 80,
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      },
    },
    {
      headerName: "Origin",
      field: "uploadName",
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      minWidth: 100,
    },
    {
      headerName: "Status",
      field: "shiftStatus",
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
      minWidth: 120,
      cellStyle: ({ value }) => ({
        color: statusColors?.[value],
        fontWeight: 600,
      }),
      cellRenderer({ value }) {
        return value || "";
      },
    },
    {
      headerName: "Employee Id",
      field: "employeeId",
      minWidth: 150,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
    },
    {
      headerName: "Employee Full Name",
      field: "employeeFullName",
      minWidth: 160,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
      cellRenderer: ({ data }) => {
        if (!!data?.crewName?.length) {
          return data?.crewName || "";
        } else {
          return data?.employeeFullName || "";
        }
      },
    },
    {
      headerName: "ID",
      field: "firstClockIn",
      filter: "agDateColumnFilter",
      enableRowGroup: true,
      minWidth: 120,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ value }) => {
        if (!value) {
          return "-||-";
        }

        const punchTime = dayjsNY(value).format(DEG_TIME_FORMAT);
        return punchTime === "Invalid Date" ? "-||-" : punchTime;
      },
    },
    {
      headerName: "OD",
      field: "firstClockIn",
      filter: "agDateColumnFilter",
      enableRowGroup: true,
      minWidth: 120,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ data, context }) => {
        const entryId = data?.entries?.[data?.entries?.length - 1];
        let odEntry = (context?.degEntries || [])?.findIndex(
          (el) => el?.entryId === entryId
        );
        const value = context?.degEntries?.[odEntry];
        if (!value) {
          return "-||-";
        }

        const punchTime = dayjsNY(value.punchTime).format(DEG_TIME_FORMAT);
        return punchTime === "Invalid Date" ? "-||-" : punchTime;
      },
    },
    {
      headerName: "Punch Date",
      field: "punchDate",
      minWidth: 120,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      filter: "agDateColumnFilter",
      cellStyle: ({ value }) => {
        return !value ? { background: "#f54c4a99" } : {};
      },
      cellRenderer: ({ value }) => {
        if (!value) {
          return "-||-";
        }
        const punchDate = dayjsNY(value).format(DEG_DATE_FORMAT);
        return punchDate === "Invalid Date" ? "-||-" : punchDate;
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (!value) {
            return "-||-";
          }
          const punchDate = dayjsNY(value).format(DEG_DATE_FORMAT);
          return punchDate === "Invalid Date" ? "-||-" : punchDate;
        },
      },
    },
    {
      headerName: "Shift Type",
      field: "shiftType",
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
      minWidth: 120,
    },
    {
      headerName: "Team",
      field: "crewTeamName",
      minWidth: 130,
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
    },
    {
      headerName: "Jobsite Match",
      field: "jobsiteAddress",
      minWidth: 200,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value }) => {
        return !value?.length ? { background: "#f54c4a99" } : {};
      },
    },
    colAccesses?.["Rate"] && {
      headerName: "Rate",
      field: "employeeRate",
      minWidth: 100,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellRenderer({ value }) {
        if (isNaN(parseFloat(value))) {
          return formatCurrency(0);
        } else {
          return formatCurrency(value);
        }
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (isNaN(parseFloat(value))) {
            return formatCurrency(0);
          } else {
            return formatCurrency(value);
          }
        },
      },
    },
    {
      headerName: "Role",
      field: "employeeRole",
      minWidth: 120,
      cellClass: "cell-left-aligned",
      enableRowGroup: true,
    },
    {
      headerName: "Reason",
      field: "reason",
      minWidth: 120,
      enableRowGroup: true,
      cellClass: "cell-left-aligned",
      cellStyle: ({ value, data }) => {
        return !value?.length && data?.punchType === "HR"
          ? { background: "#f54c4a99" }
          : {};
      },
    },
  ];
}

import { useSelector } from "react-redux";
import dayjs from "dayjs";
import { useCallback, useState } from "react";

/**
 * Custom hook to manage uploaded files.
 *
 * @param {Array} initialFiles - Initial list of uploaded files.
 * @returns {Array} An array containing:
 *   - uploadedFiles: Array of uploaded files.
 *   - setUploadedFilesCb: Callback to set uploaded files.
 *   - formattedFiles: Array of formatted files without the newDocFile property.
 *   - onPickerSuccessCb: Callback to handle successful file uploads or updates.
 *   - filesToBeDeleted: Array of files to be deleted.
 *   - setFilesToBeDeleted: Callback to set files to be deleted.
 *   - onDeleteCb: Callback to handle file deletion.
 *   - getLogs: Function to get the logs from changes inside the hook.
 *   - googleDriveUploadsLogs: Function to get the logs from 2 arrays of files.
 */
export const useUploadedFiles = (initialFiles) => {
  const [uploadedFiles, setUploadedFiles] = useState(initialFiles);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
  const [firstSet, setFirstSet] = useState(initialFiles || []);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const formattedFiles = uploadedFiles.map(({ newDocFile, ...file }) => file);

  const onPickerSuccessCb = useCallback(
    ({ uploadedFiles = [], updatedFiles = [] }) => {
      if (updatedFiles.length) {
        setUploadedFiles((prev) =>
          prev.map((item) => {
            const found = updatedFiles.find(({ id }) => id === item.id);
            if (found) {
              return {
                ...item,
                ...found,
              };
            }
            return item;
          })
        );
      } else {
        const uploadedFileNames = uploadedFiles.map((item) => ({
          ...item,
          newDocFile: true,
          uploadedAt: dayjs().valueOf(),
          uploadedBy: `${userConfiguration?.nameOfUser || ""}`,
        }));

        setUploadedFiles((prev) => [...prev, ...uploadedFileNames]);
      }
    },
    [userConfiguration]
  );

  const onDeleteCb = useCallback((fileId) => {
    setUploadedFiles((prev) => prev.filter(({ id }) => id !== fileId));
    setFilesToBeDeleted((prev) => [...prev, fileId]);
  }, []);

  const setUploadedFilesCb = useCallback((files, initial = false) => {
    if (initial) {
      setUploadedFiles(files);
      setFirstSet(files);
    } else {
      setUploadedFiles(files);
    }
  }, []);

  const getLogs = useCallback(() => {
    return googleDriveUploadsLogs(firstSet, uploadedFiles);
  }, [firstSet, uploadedFiles]);

  return [
    [uploadedFiles, setUploadedFilesCb, formattedFiles, onPickerSuccessCb],
    [filesToBeDeleted, setFilesToBeDeleted, onDeleteCb],
    [getLogs, googleDriveUploadsLogs],
  ];
};

export const googleDriveUploadsLogs = (prevArray = [], currArray = []) => {
  const logs = {};
  let cIndex = 1;
  let pIndex = 1;
  let added = [];
  let removed = [];

  prevArray.forEach((prevItem, prevIndex) => {
    const found = currArray.find((currItem) => currItem.id === prevItem.id);
    if (!found) {
      logs[prevItem.id] = {
        curr: "does not exist",
        prev: prevItem.name,
        // index: `removed(${prevIndex + 1})`,
        index: `removed(${pIndex})`,
      };
      pIndex++;
      removed.push(prevItem?.name);
    }
  });

  currArray.forEach((currItem, currIndex) => {
    const found = prevArray.find((prevItem) => prevItem.id === currItem.id);
    if (!found) {
      logs[currItem.id] = {
        curr: currItem.name,
        prev: "does not exist",
        // index: `added(${currIndex + 1})`,
        index: `added(${cIndex})`,
      };
      cIndex++;
      added.push(currItem?.name);
    }
  });

  if (Object.keys(logs).length === 0) {
    return null;
  }

  const currObj = Object.keys(logs).reduce((acc, key) => {
    if (logs[key].curr !== "does not exist") {
      acc[logs[key].index] = logs[key].curr;
    } else if (logs[key].curr === "does not exist") {
      acc[logs[key].index] = logs[key].curr;
    }
    return acc;
  }, {});

  const prevObj = Object.keys(logs).reduce((acc, key) => {
    if (logs[key].prev !== "does not exist") {
      acc[logs[key].index] = logs[key].prev;
    } else if (logs[key].prev === "does not exist") {
      acc[logs[key].index] = logs[key].prev;
    }
    return acc;
  }, {});

  return {
    prev: { googleDriveUploads: prevObj },
    curr: { googleDriveUploads: currObj },
    changes: {
      added,
      removed,
    },
  };
};

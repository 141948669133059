import React, { useState, useEffect, useMemo } from "react";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import { Select, Button, Badge, Tooltip, message, Spin } from "antd";
import "./FormSection.scss";

import { typeOfWorkColors } from "../DataAgGrid/columnDefs";
import { useNavigate } from "react-router-dom";
import { useSelector } from "react-redux";
import moment from "moment";
import PreventCreationOfSchedule from "../Subcomponents/FirstPage/PreventCreationOfSchedule";
import { WeatherFirstPage } from "../Subcomponents/FirstPage/WeatherFirstPage";
import FilterSchedules from "./FilterSchedules";
import { availableDays, prevSchedulesDataHeader } from "./helperData";
import { EmptyBox, WeatherSchedule } from "../../../../../assets";
import { fetchData } from "../../../../SidebarPages/Fleet/utils/fetchData";
import { API } from "aws-amplify";
import { checkIfServiceIsHoist } from "../../../../SidebarPages/Estimations/DataEntryGrid/models/Service";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import ScheduleDaysPreview from "../Subcomponents/ScheduleDaysPreview";
import DetailsForDayModal from "../PLIPerService/componentsForPli/ScheduledDaysTime/DetailsForDayModal";
import { AgGridReact } from "ag-grid-react";

const FormSection = ({
  schedules,
  setFirstPageVariables,
  firstPage_InformativeVariables: { scheduleAddress, typeOfWork, scheduleName },
  weatherNotFound,
  fullWeatherArray,
  handleWeather,
  projectAddress,
  projectManagers,
  projectId,
  projectExecutive,
  project,
  selectTypeOfRemoval,
  toBeScheduled,
  selectedTeams,
  setSelectedTeams,
  isDarkMode,
  allProjects,
}) => {
  const navigate = useNavigate();
  const { programFields } = useSelector((state) => state.programFields);
  // all projects
  const pr = allProjects || [];
  const [specificTypeOfWork, setSpecificTypeOfWork] = useState(false);
  const { Option } = Select;
  const [showWeather, setShowWeather] = useState(false);
  const [teams, setTeams] = useState([]);
  const [preview, setPreview] = useState({});

  const [prevent, setPrevent] = useState(false);
  const [id, setId] = useState(null);
  const [pmOptions, setPmOptions] = useState([]);
  const [pmIndex, setPmIndex] = useState(projectManagers || []);
  const [objKeys, setObjKeys] = useState({
    scheduleAddress: projectAddress ? projectAddress : null,
    typeOfWork: typeOfWork ? typeOfWork : null,
    scheduleStatus: null,
    scheduleName: null,
  });

  const [openDetails, setOpenDetails] = useState({
    open: false,
    details: {},
  });

  const typeOfWorkTypes = programFields
    .find((item) => item.fieldName === "Scheduling Types")
    .fieldOptions.filter(({ status }) => status)
    .map(({ statusName }) => statusName);

  const otherSchedules =
    schedules
      ?.filter((s) => specificTypeOfWork || s?.typeOfWork === typeOfWork)
      ?.sort(
        (a, b) =>
          dayjsNY(b?.createdAt).valueOf() - dayjsNY(a?.createdAt).valueOf()
      ) || [];

  const allPrevSchNames =
    schedules?.map(({ scheduleName = "" }) => scheduleName?.trim()) || [];

  const onSelect = (e) => {
    const findProject = pr?.find((p) => p?.projectId === e) || {};
    if (Object.values(findProject?.services || {})?.flat()?.length === 0) {
      setPrevent(true);
    } else {
      setFirstPageVariables("projectId", e);
      setPmIndex(findProject?.projectManager?.map((s) => s?.nameOfUser));

      setObjKeys({
        ...objKeys,
        scheduleAddress: e,
        typeOfWork: null,
      });
    }

    setId(e);
  };

  function onSelectPm(e, type) {
    const dataChecked =
      e?.filter?.((el) => typeof el === "string" && Boolean(el)) || [];
    setFirstPageVariables("projectManagers", dataChecked);
    setPmIndex(dataChecked);
  }
  useEffect(() => {
    if (!!project?.teamsConfiguration) {
      function getTeamId(teamName) {
        let teamId = teams?.find((e) => e?.teamName === teamName)?.teamId;
        return !!teamId ? [teamId] : [];
      }
      let newProjectTeam = project?.teamsConfiguration?.map((el) => ({
        ...el,
        teamId: !!el.teamId ? el.teamId : getTeamId(el.value),
      }));
      setSelectedTeams([...newProjectTeam]);
    }
    if (Object.keys(project || {})?.length > 0) {
      const pms =
        project?.projectManager?.map((s) =>
          typeof s === "object" ? s?.nameOfUser : s
        ) || [];
      setPmIndex(pms);
      setFirstPageVariables("projectManagers", pms);
    }
  }, [project]);

  useEffect(() => {
    API.get("teams", "/teams").then((r) => {
      setTeams(r);
    });
  }, []);

  useEffect(() => {
    const fetchExecutives = async () => {
      await fetchData("projectExecutives").then((pe) => {
        setPmOptions(
          pe?.find?.(
            (p) => `${p?.firstName} ${p?.lastName}` === projectExecutive
          )?.projectManagers || []
        );
      });
    };
    if (projectExecutive) {
      fetchExecutives();
    }
  }, [projectExecutive, projectId]);

  useMemo(() => {
    if (!!scheduleAddress) {
      setFirstPageVariables("projectManagers", pmIndex);
    }
  }, [scheduleAddress, pmIndex]);

  // useEffect(() => {
  //   message.info("108");
  //   if (
  //     Object.values(toBeScheduled || {})
  //       ?.flat(1)
  //       ?.some((el) => checkIfServiceIsHoist(el))
  //   ) {
  //     message.info("109");
  //     setTwork(typeOfWorkTypes);
  //   } else {
  //     message.info("110");
  //     setTwork(typeOfWorkTypes?.filter((el) => el !== "Inspection"));
  //   }
  // }, [toBeScheduled, objKeys?.scheduleAddress]);

  //**** TEAMS FUNCTIONS START ****//
  const onSelectTeam = (_, data) => {
    setSelectedTeams([
      ...selectedTeams,
      {
        value: data?.value,
        teamId: data?.teamId,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onDeselectTeam = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeams(
      selectedTeams
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
  };
  //**** TEAMS FUNCTIONS END ****//

  const scheduleStatuses = programFields
    .find((item) => item.fieldName === "Statuses Of Categories")
    .fieldOptions["Scheduling"].filter(({ status }) => status)
    .map(({ statusName }) => statusName);

  const rowDataAgGrid = otherSchedules?.flatMap((schedule) => {
    return availableDays(schedule?.scheduleDays)?.map((day) => {
      return {
        scheduleName: schedule?.scheduleName,
        typeOfWork: schedule?.typeOfWork,
        scheduleId: schedule?.scheduleId,
        date: dayjsNY(day?.startDate).format("MM/DD/YYYY"),
        startTime: dayjsNY(day?.startDate).format("h:mm a"),
        pePm: schedule?.projectManagers?.map((q) => q)?.join(", ") || "-",
        clientName: !!project ? project?.accountName : "-",
        notes: day?.notes,
        toBeScheduled: schedule?.toBeScheduled || {},
        id: day?.id,
        startDate: day?.startDate,
        endDate: day?.endDate,
      };
    });
  });

  return (
    <div
      style={{
        display: "flex",
        gap: "0.7rem",
        justifyContent: "space-between",
        height: "calc(100% - 80px)",
        // display: "flex",
        // flexDirection: "column",
      }}
    >
      <div style={{ display: "flex", flexDirection: "column" }}>
        <div className="form-section">
          <div>
            <span className="formSectionLabel">Project:</span>
            <InputComponent
              type={"select"}
              initialValue={
                projectAddress ? projectAddress : objKeys?.scheduleAddress
              }
              onClear={() => {
                setObjKeys({
                  ...objKeys,
                  scheduleAddress: null,
                });
                setFirstPageVariables("projectId", "");
                setPmIndex([]);
                setSelectedTeams([]);
              }}
              defaultValue={projectAddress ? projectAddress : ""}
              dropdownClassName={isDarkMode && "darkDropDown"}
              showSearch={true}
              disabled={!!projectAddress}
              onSelect={(e) => onSelect(e)}
              notFoundContent={
                pr?.length > 0 ? (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      flexDirection: "column",
                      gap: 5,
                      height: "100%",
                    }}
                  >
                    <EmptyBox width={40} height={40} />
                    <span style={{ fontSize: 12, fontWeight: 600 }}>
                      No project found!
                    </span>
                  </div>
                ) : (
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      height: "100%",
                    }}
                  >
                    <Spin size="small" />
                  </div>
                )
              }
              options={pr?.map((el) => {
                return <Option value={el?.projectId}>{el?.projectName}</Option>;
              })}
            />
          </div>
          <div>
            <span className="formSectionLabel">Type of Work:</span>
            <InputComponent
              type="select"
              initialValue={objKeys?.typeOfWork}
              dropdownClassName={isDarkMode && "darkDropDown"}
              onSelect={(e) => {
                setObjKeys({
                  ...objKeys,
                  typeOfWork: e,
                });
                setFirstPageVariables("typeOfWork", e);
              }}
              options={typeOfWorkTypes?.map((el) => {
                return <Option value={el}>{el}</Option>;
              })}
            />
          </div>
          {typeOfWork === "Removal" && (
            <div>
              <span className="formSectionLabel" style={{ textAlign: "right" }}>
                Type of Removal:
              </span>
              <InputComponent
                type={"select"}
                placeholder="Select type of removal"
                dropdownClassName={isDarkMode && "darkDropDown"}
                showSearch={true}
                onChange={(e) => {
                  selectTypeOfRemoval(e);
                }}
                options={["Partial Removal", "Full Removal"]?.map?.((el) => {
                  return <Option value={el}>{el}</Option>;
                })}
                allowClear={true}
              />
            </div>
          )}
          <div>
            <span className="formSectionLabel" style={{ textAlign: "right" }}>
              Project Manager:
            </span>
            <InputComponent
              type={"select"}
              placeholder={
                !projectExecutive
                  ? "Select a project..."
                  : "Select project managers"
              }
              initialValue={pmIndex}
              dropdownClassName={isDarkMode && "darkDropDown"}
              showSearch={true}
              onChange={(e) => {
                onSelectPm(e, "select");
              }}
              mode={"multiple"}
              options={pmOptions?.map?.((el) => {
                return <Option value={el}>{el}</Option>;
              })}
              allowClear={true}
            />
          </div>
          <div>
            <span className="formSectionLabel">Status:</span>
            <InputComponent
              initialValue={objKeys?.scheduleStatus}
              dropdownClassName={isDarkMode && "darkDropDown"}
              onSelect={(e) => {
                setObjKeys({
                  ...objKeys,
                  scheduleStatus: e,
                });
                setFirstPageVariables("scheduleStatus", e);
              }}
              type="select"
              options={scheduleStatuses?.map((el) => {
                return <Option value={el}>{el}</Option>;
              })}
            />
          </div>
          <div>
            <span className="formSectionLabel">Schedule Name:</span>
            <InputComponent
              type="input"
              value={scheduleName}
              // disabled={!!projectAddress}
              onChange={(e) => {
                if (!objKeys?.scheduleAddress) {
                  message.warning("Please select a project first!");
                  return;
                } else if (allPrevSchNames?.includes(e.target.value?.trim())) {
                  message.warning(
                    `There is already a schedule of this project with this name!`
                  );
                } else if (e?.target?.value?.length > 100) {
                  message.error(
                    "Schedule name should be less than 100 characters!"
                  );
                  return;
                } else
                  setObjKeys({
                    ...objKeys,
                    scheduleName: e.target.value,
                  });
                setFirstPageVariables("scheduleName", e.target.value);
              }}
              placeholder="Schedule Name"
            />
          </div>
          <div>
            <span className="formSectionLabel">Teams:</span>
            <InputComponent
              type="select"
              mode="multiple"
              placeholder="Select team"
              dropdownClassName={isDarkMode && "darkDropDown"}
              initialValue={selectedTeams?.map((team) => team?.value)}
              customOptions={teams?.map((team) => ({
                label: team?.teamName,
                value: team?.teamName,
                teamId: [team.teamId],
                members: team?.members?.map(
                  ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                    identityId,
                    nameOfUser,
                    cognitoUserId,
                  })
                ),
              }))}
              onSelect={onSelectTeam}
              onDeselect={onDeselectTeam}
            />
          </div>
        </div>
        {/* <FilterSchedules {...{ otherSchedules }} /> */}
      </div>
      <div
        className="form-section-table-Data"
        style={{
          height:
            // !!projectAddress || !!scheduleAddress
            // ? "calc(100% - 77px)"
            // : "100%",
            "100%",
        }}
      >
        <div className="table-data-container">
          <div className="table-data-card">
            {otherSchedules?.length === 0 ? (
              <div className="card-content">
                <EmptyBox width={200} height={200} />

                <span className="noDataMessage">
                  {!typeOfWork && !projectAddress
                    ? "No project and no type of work selected!"
                    : // : getSpecificId(otherSchedules)?.length === 0
                      // ? "No schedule days for this project!"
                      "No schedule days for this project!"}
                </span>
              </div>
            ) : (
              <div className="schedule-data-details-container">
                <div className="schedule-details-header">
                  <span className="addressName">
                    {projectAddress ? projectAddress : scheduleAddress}
                    <span
                      onClick={() => setSpecificTypeOfWork(!specificTypeOfWork)}
                      style={{
                        color: "#1264A3",
                        cursor: "pointer",
                        fontSize: 14,
                      }}
                    >
                      {!specificTypeOfWork
                        ? `Show all schedules of ${
                            projectAddress ? projectAddress : scheduleAddress
                          }`
                        : `Show only ${typeOfWork
                            ?.charAt(0)
                            ?.toLowerCase()}${typeOfWork?.slice(
                            1
                          )} schedules of ${
                            projectAddress ? projectAddress : scheduleAddress
                          }`}
                    </span>
                  </span>
                </div>
                <div
                  className={`documentationsTable ${
                    isDarkMode
                      ? "dark-ag-theme ag-theme-alpine-dark"
                      : "light-ag-theme ag-theme-alpine"
                  }`}
                  style={{ width: "100%", height: "100%", marginTop: 10 }}
                >
                  <AgGridReact
                    {...{
                      columnDefs: prevSchedulesDataHeader(
                        setPreview,
                        setOpenDetails
                      ),
                      rowData: rowDataAgGrid,
                      animateRows: true,
                      suppressDragLeaveHidesColumns: true,
                      suppressRowClickSelection: true,
                      masterDetail: true,
                      rowHeight: 32,
                      headerHeight: 32,
                      defaultColDef: {
                        resizable: true,
                        enablePivot: true,
                        enableColResize: true,
                        enableRowGroup: true,
                        editable: false,
                        sortable: true,
                        flex: 1,
                        filter: true,
                        pivot: true,
                        enableRowGroup: true,
                        enableValue: true,
                        enableCellChangeFlash: true,
                      },
                    }}
                  />
                </div>
                {/* <div
                  className="schedule-details-table"
                  style={{
                    maxHeight: 725,
                    // maxHeight: `calc(100vh - ${
                    //   !!projectAddress || !!scheduleAddress ? "313px" : "250px"
                    // })`,
                  }}
                >
                  <table style={{ width: "100%" }}>
                    <tr
                      style={{
                        width: "100%",
                        textAlign: "left",
                      }}
                      className="schedule-data-details-header"
                    >
                      {prevSchedulesDataHeader?.map?.((el) => (
                        <th style={{ border: "1px solid #e8e8e8" }}>{el}</th>
                      ))}
                    </tr>
                    {otherSchedules?.flatMap((schedule) => {
                      return availableDays(schedule?.scheduleDays)?.map(
                        (day, i) => {
                          const servicesForThisDay =
                            dayDetail(schedule?.toBeScheduled || {}, [
                              day?.id,
                            ])?.[day?.id]?.flatMap?.(({ services = [] }) =>
                              services?.map?.(
                                ({ serviceName = "" }) => serviceName
                              )
                            ) || [];
                          const notesForThisDay = day?.notes?.length > 0;
                          return (
                            <tr>
                              <td style={styleOfTable}>
                                <Badge
                                  text={schedule?.scheduleName}
                                  color={
                                    repeatelem(
                                      colors,
                                      otherSchedules?.flatMap(
                                        ({ scheduleDays = [] }) => scheduleDays
                                      )?.length - 1
                                    )?.[i]
                                  }
                                />
                              </td>
                              <td
                                style={{
                                  ...styleOfTable,
                                  background:
                                    typeOfWorkColors?.[schedule?.typeOfWork],
                                  color: "#ffffff",
                                }}
                              >
                                {schedule?.typeOfWork}
                              </td>
                              <td style={styleOfTable}>
                                {dayjsNY(day?.startDate).format("MM/DD/YYYY")}
                              </td>
                              <td style={styleOfTable}>
                                {dayjsNY(day?.startDate).format("h:mm a")}
                              </td>
                              <td style={styleOfTable}>{`${day?.day} / ${
                                availableDays(schedule?.scheduleDays)?.length
                              }`}</td>
                              <td style={styleOfTable}>
                                {schedule?.projectManagers
                                  ?.map((q) => q)
                                  ?.join(", ") || "-"}
                              </td>
                              <td style={styleOfTable}>
                                {!!project ? project?.accountName : "-"}
                              </td>
                              <td style={styleOfTable}>
                                {!servicesForThisDay?.length ? (
                                  <b style={{ color: "red" }}>Not Scheduled!</b>
                                ) : (
                                  <b
                                    onClick={() =>
                                      setPreview({
                                        preview: true,
                                        scheduleDays: [day],
                                        toBeScheduled:
                                          schedule?.toBeScheduled || {},
                                        typeOfWork: schedule?.typeOfWork || "",
                                      })
                                    }
                                    style={{
                                      color: "#1065A4",
                                      cursor: "pointer",
                                    }}
                                  >
                                    {servicesForThisDay
                                      ?.map((el) => el)
                                      ?.join?.(",")}
                                  </b>
                                )}
                              </td>
                              <td style={styleOfTable}>
                                {notesForThisDay ? (
                                  <b
                                    onClick={() => {
                                      setOpenDetails({
                                        open: true,
                                        details: {
                                          description:
                                            day?.notes
                                              ?.map((text) => text?.text)
                                              ?.join(",") || "",
                                          startDate: day?.startDate,
                                        },
                                      });
                                    }}
                                    style={{
                                      color: "#1065A4",
                                      cursor: "pointer",
                                    }}
                                  >
                                    See note
                                  </b>
                                ) : (
                                  <span>-</span>
                                )}
                              </td>
                            </tr>
                          );
                        }
                      );
                    })}
                  </table>
                </div> */}
              </div>
            )}
          </div>
        </div>
        {/* {(projectAddress !== "" || scheduleAddress !== "") && (
          <WeatherFirstPage
            {...{
              weatherNotFound,
              fullWeatherArray,
              handleWeather,
              showWeather,
            }}
          />
        )} */}
      </div>
      <>
        {preview?.preview && (
          <ScheduleDaysPreview
            {...{
              preview: preview?.preview,
              setPreview: (e) => setPreview({ ...preview, preview: e }),
              scheduleDays: preview?.scheduleDays || [],
              toBeScheduled: preview?.toBeScheduled || {},
              typeOfWork: preview?.typeOfWork || "",
              infoForOneDay: true,
              scheduleId: preview?.scheduleId,
            }}
          />
        )}
        {openDetails?.open && (
          <DetailsForDayModal
            {...{
              openDetails,
              setOpenDetails,
              disabled: true,
              onChange: () => {},
            }}
          />
        )}
        {prevent && (
          <PreventCreationOfSchedule
            {...{
              objKeys,
              prevent,
              isDarkMode,
              pr,
              id,
              onClose: () => {
                setPrevent(false);
                setFirstPageVariables("projectId", "");
                setPmIndex([]);
                setSelectedTeams([]);
                setObjKeys({
                  scheduleAddress: null,
                  typeOfWork: null,
                  scheduleName: null,
                  scheduleStatus: null,
                });
                setFirstPageVariables("scheduleAddress", "");
              },
              redirectToProject: () =>
                navigate(`/projects/${id}`, {
                  tabPosition: "Estimations",
                }),
            }}
          />
        )}
      </>
      {/* {(scheduleAddress || projectAddress) && typeOfWork ? (
        <div
          onClick={() => {
            if (weatherNotFound) {
              Swal.fire({
                text: "Weather not found",
                icon: "error",
                showConfirmButton: false,
                timer: 3000,
              });
            } else {
              setShowWeather(!showWeather);
            }
          }}
          style={{
            height: "fit-content",
            position: "fixed",
            right: "1%",
            bottom: showWeather ? "17%" : "7%",
            cursor: "pointer",
            transition: "all 0.5s ease-in-out",
          }}
        >
          <WeatherSchedule />
        </div>
      ) : null} */}
    </div>
  );
};

export default FormSection;

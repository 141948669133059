import { useEffect, useMemo, useRef } from "react";
import { useLocation } from "react-router-dom";
import { Button, Form, message, Tooltip, Tour } from "antd";
import {
  BorderedTextCard,
  GPickerButton,
  InputComponent,
  NormalSizedModal,
} from "../../../../../../components";
import {
  inputFields,
  questionAnswers,
  getIncidentPdfLayout,
} from "./incidentModalData";
import { CloseOutlined, MinusCircleOutlined } from "@ant-design/icons";
import {
  fetchAllData,
  filterTables,
  updateTeamsConfiguration,
} from "../../../../../../../../../utils";
import { fleetFields as updateFleetFields } from "../../../../../../../../../actions";
import "./IncidentModal.scss";
import { useDispatch } from "react-redux";
import { fetchData } from "../../../../../../utils";
import { useState } from "react";
import { useSelector } from "react-redux";
import { compareIncluding } from "../../../../../../../utils";
import { API } from "aws-amplify";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { SignatureContainer } from "./components";
import dayjs from "dayjs";
import {
  ExportPreview,
  LinkButton,
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
  Stepper,
} from "../../../../../../../../commonComponents";
import { lazyFetch } from "../../../../../../../../../utils";
import { filterSchedulesOnDate } from "../../../../../../../Safety/SafetyModal/utils/filterSchedulesOnDate";
import { getNewLabelHTML } from "../../../../../../Dispatch/modals/NewDispatchModal/utils/routeFunctions";
import { RenderDynamicComponents } from "../../../../../../../../Header/forms/Components";
import { assignDisabledDate } from "../../../../../../../../Header/forms/utils";
import { showErrorMsg, showSuccessMsg } from "../../../../../../../../../utils";
import axios from "axios";
import { executeCalendarList } from "../../../../../../../../Header/components/GoogleEvents/googleClientAPI/execute";
import AskForTaskModal from "./AskForTaskModal";
import WarningModal from "../../../../../../../../commonComponents/WarningModal/WarningModal";
import {
  DeleteIcon,
  LogsIcon,
  WarningTriangle,
} from "../../../../../../../DynamicView/src";
import createPDF from "../../../../../../../../../integrations/createPDF";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { getChangedData } from "../../../../../../../Accounting/components/utilities";
import {
  isLoggedIn,
  loadClient,
} from "../../../../../../../../Header/components/GoogleEvents/googleClientAPI/authenticate";
import {
  EmailIcon,
  ExportPdfIcon,
  FilledEyeIcon,
} from "../../../../../../../../../assets";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";
import { useCreateDriveFolders } from "../../../../../../../../../hooks/useCreateDriveFolders";
import { useMediaQuery } from "react-responsive";
import SafetyModal from "../../../../../../../Safety/SafetyModal/SafetyModal";
import { dayjsNY } from "../../../../../../../../DateComponents/contants/DayjsNY";
import { getGridProjectInfo } from "../../../../../../../Safety/SafetyBasePage/getGridProjectInfo";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { PlusIcon } from "../../../../../../../../../assets";
import DynamicTeamModal from "../../../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { savePdfToDrive } from "../../../../../../../Safety/safetyPdf";
import EmailBox from "../../../../../../../Communication/components/EmailBox/EmailBox";
import { viewSafetyModal } from "../../../../../../../Incidents/Modals/NewPropertyDamageIncidentModal";
import { TasksIconNew } from "../../../../../../../../../icons";
import InspectionModal from "../../../../../../../components/AgGridComponents/InspectionModal";
import { XIcon } from "../../../../../../../Communication/assets";
import TasksBasePage from "../../../../../../../TaskManager/Tasks/TasksBasePage";
import { DynamicAvatars } from "../../../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { useNavigate } from "react-router-dom";
import {
  deleteNotifications,
  getCognitosForNotification,
} from "../../../../../../../../../utils/notificationHelpers";
import { TaskIcon } from "src/icons/NotificationIcons";
import createNewTask from "../../../../../../../Incidents/Modals/utils/createNewTask";
import {
  useEditLogs,
  useProgressComponent,
} from "../../../../../../../../../hooks";
import { deleteAllRelatedToDos } from "../../../../../../../ToDos/forms/helpers";
import Signature from "../../../../../../../../commonComponents/Signature/Signature";
import {
  convertSafetyReport,
  initialExportData,
  SAFETY_REPORT_STYLES,
  safetyExportApplyFilters,
} from "../../../../../../../Safety/SafetyModal/components/SafetyExportPreview/helpers";
import AddSafetyContacts from "../../../../../../../Safety/SafetyModal/components/SafetyExportPreview/components/AddSafetyContacts";
import SortReportFields from "../../../../../../../Safety/SafetyModal/components/SafetyExportPreview/components/SortReportFields";
import { safetyContactsStyles } from "../../../../../../../Projects/ProjectSafetyReport/helpers";

const IncidentModal = ({
  setVisible,
  refreshTable = () => {},
  rowObject = {},
  deleteRow,
  showNextStep = true,
  setVisibleReport,
  requestId = "",
  setToDoVisible = () => {},
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  afterSaveRequest = async () => {},
}) => {
  const {
    incidentId,
    fleetId: existingFleetId,
    createdBy,
    createdAt,
    incidentStatus,
    incidentObject: defaultObject,
    gDriveUploads: requestUploads = [],
  } = rowObject;
  const navigate = useNavigate();
  const { programFields } = useSelector((state) => state.programFields);
  const { notifications } = useSelector((state) => state.notifications);
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { loggedOnTasks } = useSelector((state) => state.loggedOnTasks);
  const { preferences } = useSelector((state) => state.preferences);

  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [form] = Form.useForm();
  const [deleteWarningModalVisible, setDeleteWarningModalVisible] =
    useState(false);
  const [vehicles, setVehicles] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [incidentStatuses, setIncidentStatuses] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [picturesUploaded, setPicturesUploaded] = useState();
  const [signature, setSignature] = useState("");
  const [booleans, setBooleans] = useState([]);
  const [details, setDetails] = useState([]);
  const [assignToSome, setAssignToSome] = useState([]);
  const [calendarList, setCalendarList] = useState([]);
  const [projects, setProjects] = useState([]);
  const [schedules, setSchedules] = useState([]);
  const [schedulesForDate, setSchedulesForDate] = useState([]);
  const dispatch = useDispatch();
  const [labels, setLabels] = useState([]);

  const [wordPreview, setWordPreview] = useState(false);

  const [personInjured, setPersonInjured] = useState();
  const [gDriveFolderId, setGDriveFolderId] = useState();
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [saving, setSaving] = useState();
  const { fleetFields } = useSelector((state) => state.fleetFields);
  const { accessToken } = useSelector((state) => state.accessToken);
  const [incidentVisible, setIncidentVisible] = useState(true);
  const [formFieldsChange, setFormFieldsChange] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [existingLogs, setExistingLogs] = useState([]);
  const [noOfInjuredPeople, setNoOfInjuredPeople] = useState();
  const [dynamicDetails, setDynamicDetails] = useState();
  const [injuredPersonDetails, setInjuredPersonDetails] = useState([]);
  const [statusesColors, setStatusesColors] = useState([]);
  //holds the maximum of injured people fields ever added to the modal
  //we do this so the form item names don't get mixed up when adding/removing fields
  const [maxNoInjured, setMaxNoInjured] = useState();
  const [incidentDetailsFields, setIncidentDetailsFields] = useState([]);
  const [selectedVehicle, setSelectedVehicle] = useState(false);
  const [selectedIncidentDate, setSelectedIncidentDate] = useState(false);
  const [reminderMessage, setReminderMessage] = useState({
    set: undefined,
    value: "",
  });
  const [currentStep, setCurrentStep] = useState(0);
  const [steps, setSteps] = useState([]);
  const [safetyModalVisible, setSafetyModalVisible] = useState(false);
  const [safetyData, setSafetyData] = useState();
  const [addressSelectionInfo, setAddressSelectionInfo] = useState({
    projectId: "",
    accountId: "",
    scheduleId: "",
  });
  const [displayViewLogs, setDisplayViewLogs] = useState();
  const [confirmationModal, setConfirmationModal] = useState(false);
  const oldFolderId = useRef();
  const authUser = useSelector(
    (state) => state.authenticatedUser.authenticatedUser
  );
  const [tasksVisible, setTasksVisible] = useState(false);
  const [tasksData, setTasksData] = useState([]);
  const [actionType, setActionType] = useState("Update");

  const currentProject = useMemo(() => {
    const projectId = rowObject?.projectId;
    if (!projectId && !(projects || [])?.length) return null;

    return projects?.find(({ projectId: pId }) => pId === projectId);
  }, [rowObject, projects]);

  // set props for Link Component
  const popoverProps = {
    accountId: rowObject?.accountId,
    projectId: rowObject?.projectId,
    scheduleId: rowObject?.scheduleId,
    fleetId: rowObject?.incidentObject?.fleetId,
  };

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
    hideProgress,
  } = useProgressComponent({
    categoryName: "Incidents",
    actionType:
      actionType === "Delete" ? "Delete" : !!incidentId ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  //Gets the fleetId by URL and then gets the vehicle by FleetId
  let location = useLocation();
  let fleetId = "";
  if (location.pathname === "/fleets") {
    fleetId =
      location.pathname.split("/")[location.pathname.split("/").length - 1];
  }
  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Incident"
    )?.videoLink;
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [teams, setTeams] = useState([]);
  const [teamModalVisible, setTeamModalVisible] = useState(false);
  const [pdfId, setPdfId] = useState("");
  const [emailBox, setEmailBox] = useState(false);

  function changeDateOfIncident(date) {
    let selectedSchedule = addressSelectionInfo?.scheduleId || null;
    let res = filterSchedulesOnDate(schedules, dayjsNY(date), selectedSchedule);
    setSchedulesForDate(res?.schedulesForDay);
    if (res?.isCleared) {
      form.setFieldValue("incidentAddress", "");
      setAddressSelectionInfo({
        projectId: "",
        scheduleId: "",
        accountId: "",
      });
    }
  }
  const [safetyUsingIncident, setSafetyUsingIncident] = useState([]);

  const disableAllFields =
    safetyUsingIncident?.length > 0 &&
    safetyUsingIncident?.length > 0 &&
    Array.isArray(safetyUsingIncident) &&
    !!incidentId;

  function onChangeAddress(val, option) {
    let selectedAddress = val?.trim();
    let type = getNewLabelHTML({ option })?.type;
    if (type === "schedule") {
      let selectedSchedule = schedulesForDate?.find(
        (el) => el?.scheduleAddress === selectedAddress
      );
      if (!!selectedSchedule) {
        setAddressSelectionInfo({
          scheduleId: selectedSchedule?.scheduleId,
          projectId: "",
          accountId: "",
        });
      }
    } else if (type === "project") {
      let selectedProject = projects?.find(
        (el) => el?.projectName === selectedAddress
      );
      if (!!selectedProject) {
        setAddressSelectionInfo({
          projectId: selectedProject?.projectId,
          accountId: selectedProject?.accountId,
          scheduleId: "",
        });
      }
    } else {
      setAddressSelectionInfo({
        projectId: "",
        scheduleId: "",
        accountId: "",
      });
    }
  }

  const incidentModalInputFields = inputFields({
    driverName: defaultObject?.driverName,
    createdAt,
    createdBy: createdBy?.name ?? createdBy,
    lastModifiedBy: defaultObject?.lastModifiedBy,
    fleetId: fleetId || existingFleetId,
    drivers: drivers?.map(({ driverName }) => driverName),
    vehicles: vehicles?.map(({ fleetName }) => fleetName),
    projects,
    schedulesForDate,
    changeDateOfIncident,
    onChangeAddress,
    // incidentStatuses: incidentStatuses?.map(({ statusName }) => statusName),
    disableAllFields,
    addressSelectionInfo,
    navigate,
    defaultId: existingFleetId,
  });

  const driveFunctions = useCreateDriveFolders("Incidents");
  const driveRequest = driveApi({ accessToken });

  //deletes the array of file ids from the google drive
  const deleteFilesFromGDrive = (arr = []) => {
    for (const { id } of arr) driveRequest.deleteDriveItem(id);
  };

  function formatDataIntoArrays(label, type) {
    const valueArray = [];

    injuredPersonDetails?.forEach((el) => {
      if (el?.label !== label) return;

      const formItemValue = form.getFieldValue(el?.formItemName);

      if (
        compareIncluding(type, "datepicker") ||
        compareIncluding(type, "timepicker")
      ) {
        valueArray.push(
          formItemValue ? dayjsNY(formItemValue).unix() * 1000 : ""
        );
      } else {
        valueArray.push(formItemValue ?? "");
      }
    });
    return valueArray;
  }

  //creates an array of objects of the input fields values ready to be posted to the database
  const formatQuestions = (arr = []) =>
    arr.reduce((acc, { formItemName, label, type, id }) => {
      let tmpValue = "";
      let injuredRg = /^\d{1,}-\d{1,}$/;
      if (typeof formItemName === "string" && injuredRg.test(formItemName)) {
        //this is the case of multiple injured people
        tmpValue = formatDataIntoArrays(label, type);
      }

      if (acc?.length < 11) {
        return [
          ...acc,
          {
            type,
            label,
            id,
            value: !!tmpValue
              ? tmpValue
              : compareIncluding(type, "datepicker") ||
                compareIncluding(type, "timepicker")
              ? dayjsNY(form.getFieldValue(id)).valueOf()
              : form.getFieldValue(id),
          },
        ];
      } else return [...acc];
    }, []);

  const incidentObject = () => ({
    incidentObject: {
      ...incidentModalInputFields.concat(incidentDetailsFields)?.reduce(
        (acc, { type, formItemName }) => ({
          ...acc,
          [formItemName]:
            compareIncluding(type, "datepicker") ||
            compareIncluding(type, "timepicker")
              ? dayjsNY(form.getFieldValue(formItemName)).valueOf()
              : typeof form.getFieldValue(formItemName) === "string"
              ? form.getFieldValue(formItemName)?.trim()
              : form.getFieldValue(formItemName),
        }),
        {}
      ),
      fleetId: vehicles?.find(({ fleetName }) =>
        compareIncluding(fleetName, form.getFieldValue("fleetName"))
      )?.fleetId,
      driverId: drivers?.find(({ driverName }) =>
        compareIncluding(driverName, form.getFieldValue("driverName"))
      )?.driverId,

      incidentReport: [
        compareIncluding(personInjured, "yes") && {
          category: "Injured Person Details",
          values: formatQuestions(injuredPersonDetails),
        },
        compareIncluding(personInjured, "yes") && {
          category: "Details",
          values: formatQuestions(details),
        },
        { category: "Booleans", values: formatQuestions(booleans) },
      ].filter(Boolean),
      incidentNotes: form.getFieldValue("incidentNotes"),
      incidentSignature:
        !!signature && signature.includes("data:image/png;base64,")
          ? signature?.replace("data:image/png;base64,", "")
          : signature || "",
      lastModifiedBy: {
        name: userConfiguration?.nameOfUser,
        date: dayjsNY().valueOf(),
      },
      requestId,
    },
    ...addressSelectionInfo,
    incidentName: form.getFieldValue("fleetName"),
    googleDriveFolderIds: gDriveFolderId,
    googleDriveUpload: [...fileNames],
    incidentStatus: statusesColors[currentStep]?.statusName,
    incidentCategory: "Vehicle Damage",
    teamsConfiguration: [],
    // teamsConfiguration: updateTeamsConfiguration(
    //   userConfiguration,
    //   selectedTeam
    // ),
    createdBy: {
      name: userConfiguration?.nameOfUser,
      id: userConfiguration?.cognitoUserId,
    },
  });

  const safetyObject = (incidentId) => ({
    ...addressSelectionInfo,
    safetyApplicationCategory: "Vehicle Damage",
    safetyApplicationObject: {
      dateOfVehicleDamageOrIncident: dayjsNY(
        form.getFieldValue("incidentDate")
      ).format(),
      timeOfVehicleDamageOrIncident: dayjsNY(
        form.getFieldValue("incidentTime")
      ).format(),
      addressOfIncident: form.getFieldValue("incidentAddress")?.trim(),
      truckNumber: vehicles?.find(({ fleetName }) =>
        compareIncluding(fleetName, form.getFieldValue("fleetName"))
      )?.fleetId,
      nameOfDriver: drivers?.find(({ driverName }) =>
        compareIncluding(driverName, form.getFieldValue("driverName"))
      )?.driverId,
      licensePlate: vehicles?.find(({ fleetName }) =>
        compareIncluding(fleetName, form.getFieldValue("fleetName"))
      )?.licensePlate,
      witnessRequired:
        form.getFieldValue("wasIncidentWitnessed") === "yes" ? true : false,
      werePoliceInvolved: form.getFieldValue(details[0]?.id),
      wereTheFireDepartmentInvolved: form.getFieldValue(details[1]?.id),
      wereTheAmbulanceInvolved: form.getFieldValue(details[2]?.id),
    },
    safetyApplicationStatus: "Draft",
    teamsConfiguration: [],
    // teamsConfiguration: updateTeamsConfiguration(
    //   userConfiguration,
    //   selectedTeam
    // ),
    incidentId,
  });

  const resetForm = (onCreation = false) => {
    setReminderMessage({
      set: undefined,
      value: "",
    });
    resetFields();
    updateDocsUploadStatus({ status: false });
    setSignature("");
    setPersonInjured("");
    setFileNames([]);
    setFilesToBeDeleted([]);
    if (!onCreation) {
      setVisible(false);
      setIncidentVisible(false);
    }
    setNoOfInjuredPeople();
  };

  const addIncident = async () => {
    const key = "savingMessage";
    message.loading({ content: "Saving...", key });
    setSaving(true);
    // setVisible(false);

    // check if is allowed in automations configuration to create task for
    const postCreationFieldOptions = programFields?.find(
      (prog) => prog.fieldName === "Automated Tasks"
    )?.fieldOptions;

    !postCreationFieldOptions.Incidents && hideProgress("creatingTask");
    setVisibleCreationProgress(rowObject);

    let currentData = incidentObject();
    for (let key in currentData) {
      if (key === "undefined") {
        delete currentData[key];
      }
    }

    updateProgressStatus({ updatingRecord: "executing" });
    if (!incidentId) {
      await API.post("incidents", `/incidents`, {
        body: { ...currentData },
      })
        .then(async (r) => {
          handleSavedRecord(r);
          updateProgressStatus({ updatingRecord: "finished" });
          setVisibleCreationProgress(r); // save datas to generate logs in case of any fail

          if (postCreationFieldOptions.Incidents) {
            updateProgressStatus({ creatingTask: "executing" });

            await createNewTask({
              category: "Incidents",
              taskAssignedTo: [],
              taskRelatedTo: rowObject?.incidentName,
              taskTopicId: r?.incidentId,
              taskSubTopic: "Vehicle Damage",
              userConfiguration,
              calendarPreference: preferences?.calendarPreference,
              loggedOnTasks,
              dispatch,
            })
              .then(() => {
                updateProgressStatus({ creatingTask: "finished" });
              })
              .catch((err) => {
                updateProgressStatus({ creatingTask: "hasError" });
              });
          }

          setSafetyData(safetyObject(r?.incidentId));

          saveAddedLogs({
            recordId: r?.incidentId,
            recordName: rowObject?.incidentName || "Not set",
            topic: r?.incidentObject?.driverName,
            category: "Fleet Incidents",
          });

          refreshTable([
            {
              ...incidentObject(),
              ...getGridProjectInfo({
                projects,
                schedules,
                data: incidentObject(),
              }),
            },
          ]);
          if (!!requestId) {
            let requestObject = rowObject;
            delete requestObject?.incidentObject;
            delete requestObject?.incidentAddress;

            await afterSaveRequest({
              newRecordId: r?.incidentId,
              path: "incidents",
              moveFilesParams: {
                filesToMove: requestUploads || [],
                newParentId: gDriveFolderId,
                accessToken,
              },
            });
          }
          // setSafetyModalVisible(true);
          // if (showNextStep) {
          //   setConfirmationModal(true);
          // } else {
          //   setVisible(false);
          // }

          updateProgressStatus({ sendingNotification: "executing" });
          // await broadcastNotification(
          broadcastNotification(
            "11",
            "onIncidentCreation",
            [
              {
                common: userConfiguration?.nameOfUser,
                commonNext: form.getFieldValue("fleetName") || "Draft Fleet",
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "incidents",
                currentUser: userConfiguration?.cognitoUserId,
              },
            ],
            vehicles?.find(({ fleetName }) =>
              compareIncluding(fleetName, form.getFieldValue("fleetName"))
            )?.fleetId
          )
            .then((notificationSent) => {
              updateProgressStatus({
                sendingNotification: !!notificationSent
                  ? "finished"
                  : "hasError",
              });
            })
            .catch((error) => {
              console.error("Error sending notification:", error);
            });

          // resetForm(true);
        })
        .catch((error) => {
          console.error({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    } else {
      let newEditLog = {
        recordId: incidentId,
        recordName: rowObject?.incidentName,
        actionType: "Edit",
        topic: defaultObject?.driverName,
        category: "Fleet Incidents",
        currentData: {},
        label: "",
        nameOfUser: `${authUser.given_name} ${authUser.family_name}`,
        cognitoUserId: authUser?.sub,
        previousData: {},
        updatedAt: dayjsNY().valueOf(),
        updatedKeys: [],
      };

      let tmpNewObj = structuredClone(currentData),
        tmpOldObj = structuredClone(rowObject);

      let tmpNew = {},
        tmpOld = {};

      //this part saves the booleans of the incident reports
      let oB = tmpOldObj?.incidentObject?.incidentReport?.find(
        (el) => el?.category === "Booleans"
      )?.values;
      let nB = tmpNewObj?.incidentObject?.incidentReport?.find(
        (el) => el?.category === "Booleans"
      )?.values;

      oB?.forEach((b) => {
        tmpOld[b?.label] = b?.value;
      });
      nB?.forEach((b) => {
        tmpNew[b?.label] = b?.value;
      });

      //this part saves the departments who showed up
      for (let i = 0; i < 5; i++) {
        if (
          tmpNewObj?.incidentObject?.incidentReport?.[0]?.category === "Details"
        ) {
          let n = tmpNewObj?.incidentObject?.incidentReport?.[0]?.values[i];
          tmpNew[n?.label] = n?.value;
        }
        if (
          tmpOldObj?.incidentObject?.incidentReport?.[0]?.category === "Details"
        ) {
          let o = tmpOldObj?.incidentObject?.incidentReport?.[0]?.values[i];
          tmpOld[o?.label] = o?.value;
        }
      }

      //this will hold the updated info about the injured people
      let newInjuredPeople = {};
      //this will hold the previous info about the injured people
      let oldInjuredPeople = {};

      dynamicDetails?.forEach(({ label }) => {
        let oldValues = [],
          newValues = [];
        //will have to read the value data array to get the number of injured people
        let oldNr = 0,
          newNr = 0;
        //in this case there were people registered
        if (
          tmpOldObj?.incidentObject?.incidentReport?.[0]?.category === "Details"
        ) {
          //we get the element that holds the individual data under the label
          oldValues =
            tmpOldObj?.incidentObject?.incidentReport?.[0]?.values?.find(
              (el) => el.label === label
            );
          oldNr = oldValues?.value?.length ?? 0;
        }
        if (
          tmpNewObj?.incidentObject?.incidentReport?.[0]?.category === "Details"
        ) {
          newValues =
            tmpNewObj?.incidentObject?.incidentReport?.[0]?.values?.find(
              (el) => el.label === label
            );
          newNr = newValues?.value?.length ?? 0;
        }

        let i = 0,
          j = 0;
        while (i < oldNr) {
          //in this case we switch the key of the person's object with their name
          if (label === "Name") {
            let t = {
              ...oldInjuredPeople[i],
              Name: oldValues?.value?.[i],
            };
            delete oldInjuredPeople[i];
            oldInjuredPeople[oldValues?.value?.[i]] = { ...t };
          } else {
            let identifier = i;
            if (!oldInjuredPeople?.hasOwnProperty(i)) {
              identifier = Object.keys(oldInjuredPeople)?.[i];
            }
            oldInjuredPeople[identifier] = {
              ...oldInjuredPeople[identifier],
              [label]: oldValues?.value?.[i],
            };
          }
          ++i;
        }

        while (j < newNr) {
          //in this case we switch the key of the person's object with their name
          if (label === "Name") {
            let t = {
              ...newInjuredPeople[j],
              Name: newValues?.value?.[j],
            };
            delete newInjuredPeople[j];
            newInjuredPeople[newValues?.value?.[j]] = { ...t };
          } else {
            let identifier = j;
            if (!oldInjuredPeople?.hasOwnProperty(j)) {
              identifier = Object.keys(newInjuredPeople)?.[j];
            }
            newInjuredPeople[identifier] = {
              ...newInjuredPeople[identifier],
              [label]: newValues?.value?.[j],
            };
          }
          ++j;
        }
      });

      let oldKeys = Object.keys(oldInjuredPeople) ?? 0;
      let newKeys = Object.keys(newInjuredPeople) ?? 0;

      oldKeys?.forEach((el) => {
        if (!newKeys?.includes(el)) {
          newInjuredPeople[el] = "Does not exist";
        }
      });
      newKeys?.forEach((el) => {
        if (!oldKeys?.includes(el)) {
          oldInjuredPeople[el] = "Does not exist";
        }
      });

      //if there are injured people we replace the data in the objects
      if (Object.keys(oldInjuredPeople)?.length > 0) {
        tmpOldObj["incidentObject"]["incidentReport"] = {
          injuredPeople: oldInjuredPeople,
          ...tmpOld,
        };
      }
      if (Object.keys(newInjuredPeople)?.length > 0) {
        tmpNewObj["incidentObject"]["incidentReport"] = {
          injuredPeople: newInjuredPeople,
          ...tmpNew["incidentObject"],
        };
      }

      for (let key in tmpNewObj["incidentObject"]) {
        let result;
        if (
          !!tmpNewObj["incidentObject"][key] &&
          !!tmpOldObj["incidentObject"][key]
        ) {
          result = getChangedData(
            tmpNewObj["incidentObject"][key],
            tmpOldObj["incidentObject"][key]
          );
        } else {
          continue;
        }
        if (result !== false) {
          newEditLog.currentData[key] = result.curr;
          newEditLog.previousData[key] = result.prev;
          newEditLog.updatedKeys.push(key);
        }
      }

      //this makes sure the logs will have an appropriate formatting regarding the injured people
      if (
        newEditLog?.currentData?.incidentReport?.hasOwnProperty("injuredPeople")
      ) {
        Object.keys(
          newEditLog?.currentData?.incidentReport?.injuredPeople
        )?.forEach((key) => {
          newEditLog.currentData.incidentReport.injuredPeople[key] = {
            ...newEditLog?.currentData?.incidentReport?.injuredPeople[key],
            dummy: 0,
          };
        });
      }
      if (
        newEditLog?.previousData?.incidentReport?.hasOwnProperty(
          "injuredPeople"
        )
      ) {
        Object.keys(
          newEditLog?.previousData?.incidentReport?.injuredPeople
        )?.forEach((key) => {
          newEditLog.previousData.incidentReport.injuredPeople[key] = {
            ...newEditLog?.previousData?.incidentReport?.injuredPeople[key],
            dummy: 0,
          };
        });
      }

      saveAddedLogs(newEditLog);

      API.put("incidents", `/incidents/${incidentId}`, { body: currentData })
        .then(async (r) => {
          //set progress of updating record as 100%
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          if (
            !!selectedIncidentDate &&
            defaultObject?.incidentDate !== selectedIncidentDate?.unix()
          ) {
            const files = fileNames
              .flatMap((item) => item.files)
              .map(async (file) => {
                return await driveRequest
                  .getImageSrc(file.id)
                  .then(async (res) => {
                    return {
                      src: res.src,
                      blob: res.blob,
                      mimeType: file?.mimeType,
                      id: file.id,
                      name: file.name,
                    };
                  });
              });

            Promise.all(files).then(async (res) =>
              res.map((file) => {
                return driveRequest.uploadFile(
                  file?.src?.split(",")[1],
                  file.mimeType,
                  {
                    name: file.name,
                    mimeType: file.mimeType,
                    writersCanShare: true,
                    parents: [gDriveFolderId],
                  },
                  true
                );
              })
            );

            driveRequest.deleteDriveItem(oldFolderId.current);
          }

          // await broadcastNotification(
          broadcastNotification(
            "11",
            "onIncidentEdit",
            [
              {
                common: userConfiguration?.nameOfUser,
                commonNext: form.getFieldValue("fleetName"),
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "Incidents",
                currentUser: userConfiguration?.cognitoUserId,
              },
            ],
            vehicles?.find(({ fleetName }) =>
              compareIncluding(fleetName, form.getFieldValue("fleetName"))
            )?.fleetId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          Object.keys(rowObject).forEach((e) => (rowObject[e] = r?.[e]));
          refreshTable({ update: [rowObject] });
          resetForm();
        })
        .catch((error) => {
          console.error({ error });
          updateProgressStatus({ updatingRecord: "hasError" });
        });
    }

    deleteFilesFromGDrive(filesToBeDeleted);
    message.success({ content: "Saved", key, duration: 3 });
    setSaving(false);
  };

  const onTeamSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onTeamDeselect = (val, data) => {
    const teamName = data.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("team").length < 1) {
      setSelectedTeam([]);
    }
  };

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];

    for (const { name, id, mimeType } of uploadedFiles) {
      const fileNameObj = {
        name,
        id,
        mimeType,
        uploadedAt: dayjsNY().valueOf(),
      };
      uploadedFileNames.push(fileNameObj);
    }

    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );

    updateDocsUploadStatus({ uploaderId, status: true });
  };

  const updateDocsUploadStatus = ({ uploaderId, status }) => {
    if (!!uploaderId) {
      // compareIncluding(uploaderId, "Police Report") &&
      //   setPoliceReportUploaded(status)
      compareIncluding(uploaderId, "Pictures and other proofs") &&
        setPicturesUploaded(status);
    } else {
      // setPoliceReportUploaded(status)
      setPicturesUploaded(status);
    }
  };

  //handles the deletion of files from the google drive when editing the vehicle
  const onFileDelete = async ({ uploaderId, id }) => {
    const newFileNames = fileNames?.map((item) => {
      const { uploaderId: filesUploaderId, files = [] } = item;

      return compareIncluding(filesUploaderId, uploaderId)
        ? {
            ...item,
            files: files?.filter(({ id: existingId }) => existingId !== id),
          }
        : item;
    });

    const updatedObj = newFileNames?.find(({ uploaderId: filesUploaderId }) =>
      compareIncluding(filesUploaderId, uploaderId)
    );
    const { files } = updatedObj;
    if (!files?.length) {
      updateDocsUploadStatus({ uploaderId, status: false });
    }

    driveRequest.deleteDriveItem(id);
    setFileNames(newFileNames);
    setFilesToBeDeleted([...filesToBeDeleted, id]);
  };

  const onCancelModal = () => {
    setVisible(false);
    driveFunctions.delete();
    driveFunctions.reset();
  };

  const onSave = () => {
    !!signature
      ? !!picturesUploaded
        ? form
            .validateFields()
            .then(() => addIncident())
            .catch((e) => {
              console.error(e);
              let el = document.getElementById(e?.errorFields[0]?.name[0]);
              el.scrollIntoView(true);
            })
        : message.warning("Please upload pictures and other proofs!")
      : message.warning("Please add your signature!");
  };

  const onDelete = () => {
    setActionType("Delete");

    let allFiles = filesToBeDeleted || [];
    for (const { files } of fileNames) {
      allFiles.push(...(files || []));
    }
    setDeleteWarningModalVisible(false);

    setVisibleCreationProgress(true);
    updateProgressStatus({ updatingRecord: "executing" });

    API.del("incidents", `/incidents/${incidentId}`)
      .then((res) => {
        updateProgressStatus({
          updatingRecord: "finished",
          sendingNotification: "executing",
        });
        deleteNotifications(notifications, incidentId, dispatch);
        deleteAllRelatedToDos({ recordId: incidentId });

        saveAddedLogs({
          recordId: rowObject?.incidentId,
          recordName: rowObject?.incidentName,
          actionType: "Delete",
          topic: defaultObject?.driverName,
          category: "Fleet Incidents",
        });

        broadcastNotification(
          "11",
          "onIncidentDelete",
          [
            {
              common: userConfiguration?.nameOfUser,
              commonNext: form.getFieldValue("fleetName"),
            },
            {
              userName: userConfiguration?.nameOfUser,
              tab: "Incidents",
              currentUser: userConfiguration?.cognitoUserId,
            },
          ],
          null
        ).then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        });
        driveRequest.deleteDriveItem(gDriveFolderId);
        deleteFilesFromGDrive(allFiles);
        showSuccessMsg({ content: "Deleted!", key: "deleteMsg", duration: 3 });
      })
      .catch((err) => {
        updateProgressStatus({ updatingRecord: "hasError" });
        showErrorMsg({ content: "Error!", key: "deleteMsg", duration: 3 });
        console.error(err);
      });
  };

  const resetFields = () => {
    form.resetFields([
      ...booleans?.map(({ id }) => id),
      ...details?.map(({ formItemName, id }) => formItemName ?? id),
      ...incidentModalInputFields
        ?.filter(
          ({ formItemName }) => !compareIncluding(formItemName, "fleetName")
        )
        .map(({ formItemName }) => formItemName),
      "incidentNotes",
    ]);
  };
  //formats the incident report category values, so they can populate the input fields
  const formatExistingIncidentCategory = (arr = []) =>
    arr?.reduce(
      (acc, { id, type, value, formItemName }) => ({
        ...acc,
        [formItemName ?? id]:
          compareIncluding(type, "datepicker") ||
          compareIncluding(type, "timepicker")
            ? !!!value
              ? ""
              : dayjs.unix(value / 1000)
            : value,
      }),
      {}
    );

  useEffect(() => {
    if (fleetFields?.length) {
      const fleetFieldsObj = fleetFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        }),
        {}
      );
    }
    const fieldsObj = programFields.reduce(
      (acc, { fieldName, fieldOptions }) => ({
        ...acc,
        [fieldName]: fieldOptions,
      }),
      {}
    );
    setIncidentStatuses(fieldsObj["Statuses Of Categories"]["Incidents"]);
    setSteps(
      fieldsObj["Statuses Of Categories"]["Incidents"]
        ?.map(({ statusName }) => ({ title: statusName }))
        ?.filter((el) => {
          if (rowObject?.incidentStatus !== "Proceeded to Safety") {
            return el.title !== "Proceeded to Safety";
          } else {
            return el;
          }
        })
    );
    let tColors = fieldsObj["Statuses Of Categories"]["Incidents"];
    setStatusesColors(tColors);

    if (incidentId) {
      setCurrentStep(
        tColors?.findIndex(({ statusName }) => statusName === incidentStatus)
      );
    }
  }, [fleetFields]);

  useEffect(() => {
    if (!incidentId) {
      // on Change of Date or Vehicle reCreates the drive folder to match the incidentDate
      if (!!selectedVehicle && !!selectedIncidentDate) {
        // try {
        //   driveFunctions.delete();
        // } catch {}
        const vehicle = vehicles.find(
          ({ fleetName }) => fleetName === selectedVehicle
        );
        const fleetFolderId = vehicle?.googleDriveFolderIds;
        const folderName = dayjsNY(selectedIncidentDate).format(
          "MM/DD/YYYY, hh:mm:ss"
        );
        driveFunctions
          .create({
            parentFolderName: folderName,
            parentId: fleetFolderId?.incidentObject,
          })
          .then((res) => console.error("drive res: ", res))
          ?.catch((err) => console.error("Error creating drive Folders", err));
      }
    } else {
      // When editing the incident
      setGDriveFolderId(rowObject.googleDriveFolderIds);
      if (
        !!selectedIncidentDate &&
        defaultObject?.incidentDate !== selectedIncidentDate?.unix()
      ) {
        // Change folder name based on incident date
        try {
          driveFunctions.delete();
        } catch {}
        const vehicle = vehicles.find(
          ({ fleetName }) => fleetName === selectedVehicle
        );
        const fleetFolderId = vehicle?.googleDriveFolderIds;
        const folderName = dayjsNY(selectedIncidentDate).format(
          "MM/DD/YYYY, hh:mm:ss"
        );
        driveFunctions
          .create({
            parentFolderName: folderName,
            parentId: fleetFolderId?.incidentObject,
          })
          .then((res) => console.error("drive res: ", res))
          ?.catch((err) => console.error("Error creating drive Folders", err));
      }
      message.destroy("projectDriveFolders");
      message.destroy("windowMessages");
    }
  }, [selectedVehicle, selectedIncidentDate]);

  useEffect(() => {
    // Get folderId for the selected Incident
    if (!!incidentId) {
      setGDriveFolderId(rowObject.googleDriveFolderIds);
      // Get folderId for the created Incident
      if (
        !!selectedIncidentDate &&
        defaultObject?.incidentDate !== selectedIncidentDate?.unix()
      ) {
        setGDriveFolderId(driveFunctions.parentFolderId);
        oldFolderId.current = rowObject?.googleDriveFolderIds;
      }
    } else if (!!driveFunctions?.parentFolderId) {
      setGDriveFolderId(driveFunctions?.parentFolderId);
    }
  }, [driveFunctions?.parentFolderId]);

  function getDefaultNoOfInjuredPeople() {
    let n = 1;
    if (!!incidentId) {
      let tmpReportDetails = defaultObject?.incidentReport?.find(
        (el) => el?.category === "Injured Person Details"
      );

      //we take a sample value to check the presence of multiple records
      let tmpValue = tmpReportDetails?.values?.find(
        (el) => el?.label === "Name"
      )?.value;

      if (Array.isArray(tmpValue)) {
        //if the value is an array we manipulate the form item names and map the values accordingly
        n = tmpValue?.length;
      }
    }

    return n;
  }

  useEffect(() => {
    fetchData("fleet").then(async (r) => {
      // the code below have been commented because it sets fleetName to undefined when is opened in edit mode
      // let idToConsider = !!fleetId ? fleetId : existingFleetId;
      // let fleetName = await r.find(
      //   ({ fleetId: arrFleetId }) => arrFleetId === idToConsider
      // )?.fleetName;
      // form.setFieldsValue({
      //   fleetName: r.find(
      //     ({ fleetId: arrFleetId }) => arrFleetId === idToConsider
      //   )?.fleetName,
      // });
      setVehicles(r);
      try {
        await API.get("locations", "/locations").then((res) => {
          let realTimeLoc = res?.liveMapResponseVO?.locations?.find(
            ({ vehicleName }) =>
              compareIncluding(vehicleName, form.getFieldValue("fleetName"))
          )?.realLKL_address;
          !incidentId &&
            !!realTimeLoc &&
            form.setFieldsValue({
              incidentAddress: realTimeLoc,
            });
        });
      } catch (error) {
        console.error({ error });
      }
    });
    fetchData("drivers").then((r) =>
      setDrivers(() => {
        return r;
      })
    );

    Promise.allSettled([
      lazyFetch({
        tableName: "projects",
        listOfKeys: [
          "projectName",
          "projectId",
          "projectManager",
          "accountId",
          "teamsConfiguration",
          "projectLatitude",
          "projectLongitude",
        ],
      }),
      lazyFetch({
        tableName: "scheduling",
        listOfKeys: ["scheduleAddress", "scheduleId", "scheduleDays"],
      }),
      API.get("teams", "/teams"),
      filterTables("safety", "incidentId", incidentId),
    ])
      .then(
        ([{ value: pr }, { value: sch }, { value: teams }, { value: sf }]) => {
          setTeams(teams);
          setProjects(pr);
          setSchedules(sch);
          // const filterSafety = sf?.filter(
          //   ({ safetyApplicationObject }) =>
          //     safetyApplicationObject?.hasOwnProperty("incidentId") &&
          //     safetyApplicationObject.incidentId === incidentId
          // );
          setSafetyUsingIncident(sf);

          setSchedulesForDate(
            filterSchedulesOnDate(sch, defaultObject?.incidentDate || dayjsNY())
              ?.schedulesForDay || []
          );
        }
      )
      .catch((err) => {
        console.error("Error Getting Project Data: ", err);
      });

    if (!!incidentId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: incidentId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      }).then((r) => {
        setExistingLogs(r);
      });
    }
    if (
      window.location.pathname.includes("fleet") &&
      !window.location.pathname.includes("overview")
    ) {
      let fleetId = window.location.pathname.split("/")[2];
      API.get("fleet", `/fleet/${fleetId}`)
        .then((res) => {
          let fleetName = res?.fleetName;
          form.setFieldsValue({ fleetName: fleetName });
        })
        .catch((err) => {
          console.error("Error getting truck: ", err);
        });
    }
  }, []);
  useEffect(() => {
    fetchData("dynamicQuestions").then((r) => {
      const dynamicQuestionsObj = r?.reduce(
        (acc, { nameOfQuestions, serviceName, questions }) => ({
          ...acc,
          [nameOfQuestions || serviceName]: questions,
        })
      )["Incidents Report"];
      //we check the row object if any to see if we have more that one injured person
      let n = getDefaultNoOfInjuredPeople();

      //we get all the static elements of the details, we'll concat the mapped data at the end
      let tmpDetails = [];
      let tmpInjuredPersonDetails = [];
      dynamicQuestionsObj?.details?.forEach((item, key) => {
        const { id, rules = [] } = item;
        if (item?.type === "radio") {
          tmpDetails.push({
            ...item,
            key,
            formItemName: id,
            disabledDate: assignDisabledDate(item, "Date of Birth"),
            rules: [...rules],
            disabled: disableAllFields,
          });
        }
      });

      let tmpDyn = [];

      for (let i = 0; i < n; i++) {
        dynamicQuestionsObj?.injuredPersonDetails?.forEach((item, key) => {
          // if (item?.type !== "radio" && !!item) {
          const { id, type, rules, label } = item;
          let tmpType = type,
            tmpRules = rules ?? [];
          if (type === "custom") {
            tmpType = "phone";
            // tmpRules[0]["pattern"] = phoneValidator;
            delete tmpRules[0]["pattern"];
          } else if (label === "Telephone") {
            tmpType = "phone";
          }
          // add to the tmpDyn all form Fields
          if (!tmpDyn.find((el) => el?.label === label)) {
            tmpDyn.push({
              ...item,
              key,
              type: tmpType,
              formItemName: `${id}-${i}`,
              disabledDate: assignDisabledDate(item, "Date of Birth"),
              rules: [...tmpRules],
              disabled: disableAllFields,
            });
          }
          // add to tmpInjuredPersonDetails all injured Peoples details
          tmpInjuredPersonDetails?.push({
            ...item,
            key,
            type: tmpType,
            formItemName: `${id}-${i}`,
            disabledDate: assignDisabledDate(item, "Date of Birth"),
            rules: [...tmpRules],
            disabled: disableAllFields,
          });
        });
      }
      setDynamicDetails(tmpDyn);
      setDetails(tmpDetails);
      setInjuredPersonDetails(tmpInjuredPersonDetails);
      setIncidentDetailsFields(
        dynamicQuestionsObj.incidentDetails.filter(
          (field) => !field?.defaultField
        )
      );
      setNoOfInjuredPeople(n);
      setMaxNoInjured(n);

      const dynamicBooleans = dynamicQuestionsObj?.booleans;
      if (dynamicBooleans.some((el) => !!el?.index)) {
        dynamicBooleans.sort((a, b) => a?.index - b?.index);
      }
      setBooleans(
        dynamicBooleans?.map((boo, key) => ({
          ...boo,
          onChange: (e) => {
            boo?.id === 2 && setPersonInjured(e.target.value);
          },
          options: questionAnswers,
          formItemName: boo.id,
          key,
          buttonStyle: "solid",
          optionType: "Button",
          style: { minWidth: "fit-content" },
          disabled: disableAllFields,
        }))
      );
    });
    setLabels(
      programFields.find((el) => el.fieldName === "Tasks labels").fieldOptions
    );
    setAssignToSome([userConfiguration?.nameOfUser]);
    if (isLoggedIn()) {
      setTimeout(() => {
        try {
          loadClient().then(
            executeCalendarList().then((res) =>
              setCalendarList(res?.result.items)
            )
          );
        } catch (err) {
          console.error("CALENDAR ERROR: ", err);
        }
      }, 5000);
    }

    disableAllFields &&
      message.warning(
        "This form is disabled because this Incident is being used in one or more Safety Reports and cannot be edited.",
        [5]
      );
  }, [safetyUsingIncident]);

  useEffect(() => {
    if (!!incidentId) {
      filterTables("tasksManagement", "taskTopicId", rowObject?.incidentId)
        .then((res) => {
          setTasksData(res);
        })
        .catch((err) => console.error(err));

      const incidentReportObj = defaultObject?.incidentReport?.reduce(
        (acc, { category, values }) => ({ ...acc, [category]: values }),
        {}
      );
      let n = getDefaultNoOfInjuredPeople();
      let tmpDetails = incidentReportObj["Details"]?.filter(
        (el) => el?.type === "radio"
      );

      let tmpInjuredPersonDetails = [];

      let arrayToLoop = incidentReportObj["Injured Person Details"]
        ? incidentReportObj["Injured Person Details"]
        : incidentReportObj["Details"]?.filter((el) => el?.type !== "radio");

      for (let i = 0; i < n; i++) {
        arrayToLoop?.forEach((item) => {
          let itemValue = item?.value;
          if (Array.isArray(itemValue)) {
            itemValue = itemValue?.[i];
          }

          tmpInjuredPersonDetails.push({
            ...item,
            formItemName: `${item?.id}-${i}`,
            value: itemValue,
          });
        });
      }

      tmpDetails = tmpDetails?.concat(tmpInjuredPersonDetails);

      form.setFieldsValue({
        ...rowObject,
        ...defaultObject,
        incidentDate: dayjs(defaultObject?.incidentDate),
        incidentTime: dayjs(defaultObject?.incidentTime),
        ...formatExistingIncidentCategory(incidentReportObj["Booleans"]),
        ...formatExistingIncidentCategory(tmpDetails),
      });
      if (!pdfId) {
        setPdfId(rowObject?.incidentObject?.gDrivePdfId || "");
      }
      if (rowObject?.teamsConfiguration?.length > 0) {
        setSelectedTeam(
          rowObject?.teamsConfiguration?.map((e) => ({
            value: e?.value,
            members: e?.members,
          }))
        );
        form.setFieldValue(
          "team",
          rowObject?.teamsConfiguration?.map((e) => e?.value)
        );
      }
      compareIncluding(form.getFieldValue(1), "yes") && setPersonInjured("Yes");
      if (defaultObject?.incidentSignature) {
        setSignature(
          "data:image/png;base64," + defaultObject?.incidentSignature
        );
      }
      setFileNames(rowObject?.googleDriveUpload || []);
      (rowObject?.googleDriveUpload || []).map(({ uploaderId }) =>
        updateDocsUploadStatus({ uploaderId, status: true })
      );
      let { viewLogs = {} } = rowObject;
      viewLogs = {
        ...viewLogs,
        [authenticatedUser?.sub]: {
          lastSeen: Date.now(),
          name: userConfiguration?.nameOfUser,
        },
      };

      API.put("incidents", `/incidents/${incidentId}`, {
        body: {
          viewLogs,
        },
      })
        .then(() => {
          setDisplayViewLogs(viewLogs);
        })
        .catch((err) => console.error("Error Updating View Logs: ", err));
    } else if (!!rowObject && !incidentId) {
      form.setFieldsValue({
        ...rowObject,
        ...defaultObject,
      });
    }

    if (requestUploads) {
      setFileNames((prev) => {
        if (prev?.length) {
          let tmp = [...prev];
          return tmp?.map((el) => {
            if (el?.uploaderId === "Pictures and other proofs") {
              return {
                ...el,
                files: [...el?.files, ...(requestUploads || [])],
              };
            } else {
              return el;
            }
          });
        } else {
          return [
            {
              files: requestUploads || [],
              uploaderId: "Pictures and other proofs",
            },
          ];
        }
      });
    }
  }, [JSON.stringify(rowObject)]);

  useEffect(() => {
    if (!fleetFields) {
      API.get("fleetFields", `/fleetFields`).then((res) =>
        dispatch(updateFleetFields(res))
      );
    }
  }, []);

  const addInjuredPersonHandler = () => {
    let tmpField = [];
    dynamicDetails?.forEach((item) => {
      tmpField.push({
        ...item,
        formItemName: `${item?.id}-${maxNoInjured}`,
      });
    });
    tmpField?.forEach((el) => {
      form.setFieldValue(el?.formItemName, "");
    });

    setInjuredPersonDetails(injuredPersonDetails?.concat(tmpField));
    setMaxNoInjured(maxNoInjured + 1);
    setNoOfInjuredPeople(noOfInjuredPeople + 1);
  };

  const removeInjuredPersonHandler = (index = 0) => {
    if (injuredPersonDetails?.length > dynamicDetails?.length) {
      let tmp = [...injuredPersonDetails];
      tmp.splice(index * dynamicDetails?.length, dynamicDetails?.length);
      setInjuredPersonDetails(tmp);
      setNoOfInjuredPeople(noOfInjuredPeople - 1);
    }
  };

  async function onPdfCreate() {
    message.loading({
      content: "Generating PDF...",
      key: "loadingGenerating",
    });

    let uploads = structuredClone(defaultObject?.googleDriveUpload);
    let files = [];
    uploads?.forEach(({ files: f, uploaderId: title }) => {
      f?.forEach((file) => {
        if (file?.mimeType === "image/png" || file?.mimeType === "image/jpeg") {
          files.push({
            ...file,
            title,
          });
        }
      });
    });

    await Promise.allSettled(
      files?.map(async (file) => {
        return await driveRequest.getImageSrc(file.id).then((r) => ({
          ...r,
          title: file?.title,
          label: file?.name,
          link: `https://drive.google.com/file/d/${file?.id}/view`,
        }));
      })
    )
      .then((res) => {
        message.destroy("loadingGenerating");
        let uploadedResult = res
          ?.filter((e) => e?.status === "fulfilled")
          ?.map(({ value }) => {
            if (!value?.src?.startsWith("data:application")) {
              return {
                ...value,
              };
            }
          })
          ?.filter(Boolean);

        createPDF({
          action: "open",
          docDefinition: getIncidentPdfLayout({
            rowObject,
            base64,
            dynamicDetails,
            uploads: uploadedResult,
          }),
        });

        saveAddedLogs({
          recordId: rowObject?.incidentId,
          recordName: rowObject?.incidentName,
          actionType: "Generate PDF",
          category: "Fleet Incidents",
          topic: defaultObject?.fleetName,
          currentData: { User: "Created a PDF for this record" },
        });
      })
      .catch((err) => {
        message.destroy("loadingGenerating");
        console.error("Error getting uploaded files: ", err);
        createPDF({
          action: "open",
          docDefinition: getIncidentPdfLayout({
            rowObject: defaultObject,
            base64,
            dynamicDetails,
            uploads: undefined,
          }),
        });
      });
    await savePdfToDrive({
      docDefinition: getIncidentPdfLayout({
        rowObject: defaultObject,
        base64,
        dynamicDetails,
        uploads: undefined,
      }),
      accessToken,
      folderId: gDriveFolderId || driveFunctions?.parentFolderId,
      title: `${rowObject?.incidentName} Vehicle Damage Incident Report`,
    })
      .then((res) => {
        if (!!pdfId) {
          driveRequest.deleteDriveItem(pdfId);
        }
        setPdfId(res?.id);

        API.put("incidents", `/incidents/${incidentId}`, {
          body: {
            incidentObject: {
              ...defaultObject,
              gDrivePdfId: res?.id,
            },
          },
        }).catch((err) => console.error("Error Updating Incident: ", err));
      })
      .catch((err) => console.error("Error Saving PDF to Drive: ", err));
  }
  function onEmailSave({ e, key, type, setLoading }) {
    if (type.toUpperCase() === "EMAIL") {
      message.success("Email sent");
      setLoading(false);
      setEmailBox(false);
    }
  }
  function sendEmailNotification() {
    broadcastNotification(
      "11",
      "onIncidentEmail",
      [
        {
          common: userConfiguration?.nameOfUser,
        },
        {
          userName: userConfiguration?.nameOfUser,
          currentUser: authenticatedUser?.sub,
          cognitos: getCognitosForNotification(
            userConfiguration,
            rowObject?.teamsConfiguration,
            "safety"
          ),
        },
      ],
      vehicles?.find(({ fleetName }) =>
        compareIncluding(fleetName, form.getFieldValue("fleetName"))
      )?.fleetId
    );

    saveAddedLogs({
      recordId: rowObject?.incidentId,
      recordName: rowObject?.incidentName,
      actionType: "Generate PDF",
      category: "Fleet Incidents",
      topic: defaultObject?.fleetName,
      currentData: { User: "Created a PDF for this record" },
    });
  }
  const tourStepsDb =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "vehicleIncidentModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Incident Statuses": document.querySelector(".stepper-component"),
      "Incident Details": document.querySelector(".incidentDetails"),
      "Injured Persons": document.querySelector(
        ".questionsContainer > :nth-child(2)"
      ),
      "Police Report": document.querySelector(".policeReport"),
      "Pictures and other evidences": document.querySelector(".picturesCard"),
      Notes: document.querySelector(".incidentNotes"),
      Signature: document.querySelector(".incidentSignature"),
      Save: document.querySelector(".saveButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(tourStepsDb);

  const isMobileView = useMediaQuery({
    query: "(max-width: 575px",
  });

  return (
    <>
      <NormalSizedModal
        visible={incidentVisible}
        // title="New Incident"
        title={
          <CustomModalHeader
            title={"New Incident"}
            onClick={() => {
              tourSteps?.length
                ? setTourOpen(true)
                : message.info("No guide available for this form");
            }}
          />
        }
        className={`incidentModalContainer ${
          isDarkMode && "incidentModalContainerDark"
        }`}
        closable={true}
        onCancel={() => {
          if (formFieldsChange) {
            setCancelModalVisible(true);
          } else {
            setVisible(false);
          }
        }}
        centered
        destroyOnClose={true}
      >
        {isMobileView ? (
          <div className="stepper-section-mobile">
            <InputComponent
              {...{
                label: "Status",
                type: "select",
                dropdownClassName: isDarkMode && "darkDropDown",
                formItemName: "stepper",
                customOptions: incidentStatuses?.map(
                  ({ statusId, statusName }) => ({
                    value: statusId,
                    label: statusName,
                  })
                ),

                onSelect(e) {
                  setCurrentStep(e);
                },
                defaultValue: currentStep,
              }}
              disabled={disableAllFields}
            />
          </div>
        ) : (
          <div className="stepper-section">
            <Stepper
              {...{
                currentStep,
                setCurrentStep(e) {
                  form.setFieldValue("stepper", e);
                  setCurrentStep(e);
                },
                steps,
                incidentStatuses,
                statusesColors,
                stepperClassName: "stepper-component",
                stepRenderer: false,
                disabled: disableAllFields,
              }}
            />
          </div>
        )}
        <Form
          form={form}
          onFieldsChange={(e) => {
            setFormFieldsChange(true);
            if (!rowObject?.incidentId) {
              setSelectedIncidentDate(form.getFieldValue("incidentDate"));
              setSelectedVehicle(form.getFieldValue("fleetName"));
            }
          }}
        >
          <div className="container">
            <BorderedTextCard
              title="Incident Details"
              className="inputContainer incidentDetails"
              isDarkMode={isDarkMode}
            >
              {RenderDynamicComponents(
                incidentModalInputFields.concat(incidentDetailsFields),
                {
                  form,
                  accessToken,
                }
              )}
              {/* <div style={{ display: "flex", gap: "20px" }}>
                <InputComponent
                  mode="multiple"
                  type="select"
                  dropdownClassName={isDarkMode && "darkDropDown"}
                  label="Team"
                  required={true}
                  formItemName="team"
                  allowClear={false}
                  customOptions={teams?.map((team) => ({
                    value: team?.teamName,
                    label: team?.teamName,
                    members: team.members?.map(
                      ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
                        identityId,
                        nameOfUser,
                        cognitoUserId,
                      })
                    ),
                  }))}
                  onSelect={onTeamSelect}
                  onDeselect={onTeamDeselect}
                  showSearch={true}
                  disabled={disableAllFields}
                />
                {!!selectedTeam?.length && (
                  <MondayButton
                    {...{
                      onClick: () => {
                        setTeamModalVisible(true);
                      },
                      Icon: <PlusIcon />,
                      style: { marginTop: "25px" },
                    }}
                    disabled={disableAllFields}
                  >
                    Team Members
                  </MondayButton>
                )}
              </div> */}
            </BorderedTextCard>

            <div className="innerCards">
              <div className="incidentConditionals">
                <div className="questionsContainer">
                  {RenderDynamicComponents(booleans)}
                </div>
                {personInjured === "Yes" && (
                  <div className="options">
                    <div className="didShowUp">
                      <div className="title">
                        <span className="titleReq">*</span>
                        <span className="titleText">
                          Did the following show up?
                        </span>
                      </div>

                      <div className="innerRadioList">
                        {RenderDynamicComponents(
                          details?.filter((el) => el?.type === "radio"),
                          { form, accessToken }
                        )}
                        <div className="peopleButtons">
                          {!disableAllFields && (
                            <Button
                              className="add btn"
                              onClick={addInjuredPersonHandler}
                            >
                              Add Person
                            </Button>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>

              {compareIncluding(personInjured, "yes") &&
                [...new Array(noOfInjuredPeople)].map((_, i) => {
                  const fieldsOfIndex = injuredPersonDetails
                    ?.filter((el) => el?.formItemName?.split("-")[1] === `${i}`)
                    ?.sort((a, b) => a?.index - b?.index);

                  return (
                    <BorderedTextCard
                      title={"Injured Person's Details"}
                      className="injuredDetails"
                      key={`bordered${i}`}
                      isDarkMode={isDarkMode}
                    >
                      <div className="injuredMainDetails">
                        {RenderDynamicComponents(fieldsOfIndex, {
                          form,
                          accessToken,
                        })}
                      </div>
                      {!disableAllFields && (
                        <div className="removeCardButton">
                          <Tooltip trigger="hover" title="Remove Person">
                            <MinusCircleOutlined
                              onClick={() => {
                                removeInjuredPersonHandler(i);
                              }}
                            />
                          </Tooltip>
                        </div>
                      )}
                    </BorderedTextCard>
                  );
                })}
            </div>

            <div className="uploaderContainer">
              <BorderedTextCard
                title="Police Report"
                className="uploaderCard policeReport"
                isDarkMode={isDarkMode}
              >
                <GPickerButton
                  onDelete={!!incidentId && onFileDelete}
                  parentId={gDriveFolderId}
                  accessToken={accessToken}
                  uploaderId="Police Report"
                  fileNames={fileNames}
                  onPickerSuccess={onPickerSuccess}
                  disabled={disableAllFields}
                />
              </BorderedTextCard>

              <BorderedTextCard
                title="Pictures and other evidences"
                className="picturesCard"
                isDarkMode={isDarkMode}
              >
                <GPickerButton
                  onDelete={!!incidentId && onFileDelete}
                  required
                  parentId={gDriveFolderId}
                  accessToken={accessToken}
                  uploaderId="Pictures and other proofs"
                  fileNames={fileNames}
                  onPickerSuccess={onPickerSuccess}
                  disabled={disableAllFields}
                />
              </BorderedTextCard>
            </div>
            <InputComponent
              label="Notes"
              placeholder="Notes"
              formItemName="incidentNotes"
              type="textarea"
              rows={4}
              style={{ minWidth: "100%" }}
              required
              className="incidentNotes"
              disabled={disableAllFields}
            />
            <div className="incidentSignature">
              <Signature
                {...{
                  signature,
                  setSignature,
                  signDisabled: disableAllFields,
                  removeDisabled: disableAllFields,
                }}
              />
            </div>
          </div>
          <div
            className={`${!!incidentId ? "allFooterButtons" : "footerButtons"}`}
          >
            {!!incidentId && (
              <div className="deleteAndLogs">
                <MondayButton
                  className="deleteButton mondayButtonRed"
                  Icon={<DeleteIcon />}
                  tooltipCategory={["Incidents", "Vehicle Damage"]}
                  tooltipKey="delete"
                  onClick={() =>
                    !!disableAllFields
                      ? message.error({
                          content:
                            "This Incident is linked to a Safety Report. Please delete the Safety first.",
                          key: "messageDeleting",
                        })
                      : setDeleteWarningModalVisible(true)
                  }
                >
                  Delete
                </MondayButton>
                <MondayButton
                  className="logsButton mondayButtonBlue"
                  Icon={<LogsIcon />}
                  onClick={() => setLogsVisible(true)}
                  tooltipCategory={["Incidents", "Vehicle Damage"]}
                  tooltipKey="showLogs"
                >
                  Show Logs
                </MondayButton>
                {/* <MondayButton
                  className="logsButton mondayButtonBlue"
                  Icon={<ExportPdfIcon />}
                  onClick={() => onPdfCreate()}
                  tooltipCategory={["Incidents", "Vehicle Damage"]}
                  >
                  Export to PDF
                </MondayButton> */}
                <MondayButton
                  className="logsButton mondayButtonBlue"
                  onClick={() => setWordPreview(true)}
                  tooltipCategory={["Incidents", "Vehicle Damage"]}
                  tooltipKey="exportPdf"
                  Icon={<ExportPdfIcon />}
                >
                  PDF Preview
                </MondayButton>
                <MondayButton
                  className="logsButton mondayButtonBlue"
                  Icon={<ExportPdfIcon />}
                  onClick={() => setVisibleReport(true)}
                  tooltipCategory={["Incidents", "Vehicle Damage"]}
                  tooltipKey="showReport"
                >
                  View Report
                </MondayButton>
                <MondayButton
                  className="logsButton mondayButtonBlue"
                  Icon={<TaskIcon />}
                  onClick={() => setToDoVisible(true)}
                  tooltipCategory={["Incidents", "Vehicle Damage"]}
                  tooltipKey="showTodos"
                >
                  To Do
                </MondayButton>
                <MondayButton
                  {...{
                    Icon: <EmailIcon />,
                    className: "logsButton mondayButtonBlue",
                    tooltipCategory: ["Incidents", "Vehicle Damage"],
                    tooltipKey: "openEmail",
                    onClick() {
                      setEmailBox(true);
                      !pdfId &&
                        message.info("There is no PDF file for this report");
                    },
                  }}
                >
                  Email
                </MondayButton>

                <MondayButton
                  {...{
                    Icon: <TasksIconNew />,
                    className: "logsButton mondayButtonBlue",
                    tooltipCategory: ["Incidents", "Vehicle Damage"],
                    tooltipKey: "showTasks",
                    onClick() {
                      setTasksVisible(true);
                    },
                  }}
                >
                  Tasks
                </MondayButton>

                <LinkButton
                  popoverProps={popoverProps}
                  title="Go To"
                  className="logsButton"
                />

                {disableAllFields && (
                  <Tooltip title={"View Safety Reports using this Incident"}>
                    <MondayButton
                      {...{
                        Icon: (
                          <FilledEyeIcon style={{ height: 23, width: 23 }} />
                        ),
                        className: "mondayButtonBlue previewButton",
                        onClick: () => viewSafetyModal(safetyUsingIncident),
                      }}
                    />
                  </Tooltip>
                )}
              </div>
            )}
            <div className="cancelAndSave">
              {!!selectedTeam?.length && !!incidentId && (
                <DynamicAvatars
                  {...{
                    title: "Team",
                    isDarkMode: isDarkMode,
                    members: selectedTeam.reduce(
                      (acc, { members }) => [...acc, ...members],
                      []
                    ),
                  }}
                />
              )}
              <MondayButton
                className={`cancelButton ${
                  !!incidentId ? "mondayButtonGrey" : "mondayButtonRed"
                }`}
                Icon={<CloseOutlined style={{ height: 25, width: 25 }} />}
                tooltipCategory={["Incidents", "Vehicle Damage"]}
                tooltipKey="cancel"
                onClick={() => {
                  if (formFieldsChange) {
                    setCancelModalVisible(true);
                  } else {
                    setVisible(false);
                  }
                }}
              >
                Cancel
              </MondayButton>
              <MondayButton
                disabled={saving}
                className="saveButton"
                Icon={<TickIcon />}
                onClick={onSave}
                tooltipCategory={["Incidents", "Vehicle Damage"]}
                tooltipKey="save"
              >
                Save
              </MondayButton>
            </div>
          </div>
          {emailBox && (
            <div className="safetyEmailBox">
              <EmailBox
                {...{
                  // incident: true,
                  // isFull: true,
                  onClose: () => setEmailBox(false),
                  setEmails: () => {},
                  rowData: rowObject,
                  onSave: (e, type, setLoading) => {
                    onEmailSave({ e, type, setLoading });
                    // setEmailBox(false);
                  },
                  isRedirected: pdfId,
                  isFromCharge: true,
                  sendEmailNotification,
                  proppedTemplateCat: "Incidents",
                }}
              />
            </div>
          )}
        </Form>
      </NormalSizedModal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              Icon={<CloseOutlined />}
              onClick={() => setCancelModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onCancelModal} Icon={<TickIcon />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      <WarningModal
        visible={deleteWarningModalVisible}
        setVisible={setDeleteWarningModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to delete?</p>
          <div className="buttons">
            <MondayButton
              Icon={<CloseOutlined />}
              onClick={() => setDeleteWarningModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton onClick={onDelete} Icon={<TickIcon />}>
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: existingLogs || [],
            title: "Incidents Logs",
            viewLogs: displayViewLogs,
          }}
        />
      )}

      {teamModalVisible && (
        <DynamicTeamModal
          {...{
            open: teamModalVisible,
            setOpen: setTeamModalVisible,
            selectedTeam,
            setSelectedTeam,
            ClearOptions: () => {
              setTeamModalVisible(false);
            },
            onSave: (e) => {
              form.setFieldValue("team", e || []);
            },
            proppedAllTeams: teams,
          }}
        />
      )}

      {wordPreview && (
        <ExportPreview
          {...{
            open: wordPreview,
            onCancel: () => setWordPreview(false),
            modalTitle: "Incident Report",
            documentTitle: "Incident Report for " + rowObject?.incidentName,
            getInitialData: async () => {
              return await initialExportData({
                modalType: "vehicleDamage",
                rowObject,
                formFields: dynamicDetails,
                accessToken,
                currentProject,
              });
            },
            asyncInitialData: true,
            keyName: "incident Vehicle Damage",
            applyFilters: safetyExportApplyFilters,
            convertData: convertSafetyReport,
            tabs: [
              {
                key: "sort",
                label: "Modify Fields",
                component: SortReportFields,
              },
              currentProject?.projectLatitude && {
                key: "safetyContacts",
                label: "Safety Contacts",
                component: AddSafetyContacts,
              },
            ].filter(Boolean),
            customStyles: { ...SAFETY_REPORT_STYLES, ...safetyContactsStyles },
            defaultHeaderSection: [
              {
                text: {
                  paragraphs: [
                    {
                      text: "Incident Report for " + rowObject?.incidentName,
                      class: "safety-title",
                      bold: true,
                      header: true,
                    },
                  ],
                },
              },
              {
                text: {
                  paragraphs: [
                    {
                      text: "Date: " + new Date().toLocaleDateString(),
                      class: "safety-section-header no-margin",
                      bold: true,
                    },
                  ],
                },
              },
            ],
          }}
        />
      )}

      {confirmationModal && (
        <AskForTaskModal
          {...{
            title: "Safety Request",
            span: "The next step will be creating a Safety Application for this incident, do you wish to continue?",
            askTaskModal: confirmationModal,
            isDarkMode,
            createTask() {
              setSafetyModalVisible(true);
              setConfirmationModal(false);
              // setVisible(false);
            },
            cancelTaskModal() {
              setConfirmationModal(false);
              setVisible(false);
            },
          }}
        />
      )}
      {safetyModalVisible && (
        <SafetyModal
          {...{
            setEdit() {
              resetForm();
              setVisible(false);
              setIncidentVisible(false);
              setSafetyModalVisible(false);
            },
            category: "Vehicle Damage",
            safetyData,
            updateIncidentStatus: refreshTable,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Vehicle Damage Tutorial",
          }}
        />
      )}
      {!!tasksVisible && (
        <InspectionModal
          {...{
            visible: tasksVisible,
            onCancel() {
              setTasksVisible(false);
            },
            title: `Tasks for ${rowObject?.incidentName}`,
            width: 1600,
            footer: false,
            closable: true,
            closeIcon: <XIcon fill="#333238" />,
            className: "header-tasks-modal-light",
            isDarkMode,
          }}
        >
          <TasksBasePage
            {...{
              customTaskProps: {
                rowData: rowObject,
                taskTopic: "Incidents",
                taskTopicId: rowObject?.incidentId,
                taskRelatedTo: rowObject?.incidentName,
              },
            }}
          />
        </InspectionModal>
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Incidents",
            actionType:
              actionType === "Delete"
                ? "Delete"
                : !!incidentId
                ? "Update"
                : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
              !incidentId && nextStepHandler();
              showNextStep ? setConfirmationModal(true) : resetForm();
              if (actionType === "Delete") {
                setVisible(false);
                deleteRow(rowObject);
              }
            },
          }}
        />
      )}
    </>
  );
};

export default IncidentModal;

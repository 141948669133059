import moment from "moment";
import { generatePolygonImage } from "../../components/commonComponents/Map/Geofence/shapesImagesGeneratorForFences";
import { calculateZoomLevel } from "../../components/commonComponents/Map/Geofence/utils";
import { filterTables } from "../filterTables";

export const projectDetails = async (recordId) => {
  return await filterTables("projects", "projectId", recordId)
    .then(async (r) => {
      const res = !!r?.[dataSourceName] ? r[dataSourceName] : r;
      const proceededProjects = await Promise.all(
        res.map(async (project) => {
          const geoFenceInfo = await Promise.all(
            project.geoFenceInfo.map(async (geo) => {
              const image = await generatePolygonImage({
                polygonCoordinates: geo.geoFenceInfo,
                zoomOfMap: calculateZoomLevel(geo.geoFenceInfo),
              });
              return {
                ...geo,
                image: `data:image/png;base64,${image}`,
                createdAt: moment(geo.createdAt).format("MM/DD/YYYY"),
              };
            })
          );

          return {
            ...project,
            geoFenceInfo,
            createdAt:
              typeof project.createdAt === "number"
                ? moment(project.createdAt).format("MM/DD/YYYY")
                : project.createdAt,
          };
        })
      );
      return proceededProjects;
    })
    .catch((err) => console.log({ err }));
};

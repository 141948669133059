import { useEffect, useRef, useState } from "react";
import { InspectionModal } from "../../../../XComponents";
import { Carousel, Image } from "antd";
import "../../../../../../integrations/AgGridDatas/components/inspectionGallery.scss";
import { LeftOutlined, RightOutlined } from "@ant-design/icons";
import { driveApi } from "src/integrations/DriveRequest";
import { Document, Page, pdfjs } from "react-pdf";
import { ExcelIcon } from "src/assets";
import { useSelector } from "react-redux";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { useResponsive } from "../../../../../../hooks";
import { SmallLoadableComp } from "../../../../../Sidebars/components";

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

const fallback =
  "https://via.placeholder.com/800x500?text=No+Preview+Available";

const CarouselModal = ({
  visible,
  setVisible,
  shownImages = [],
  onDelete,
  isDraft,
  imageIndex,
  loading = false,
}) => {
  const carouselRef = useRef(null);

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);

  const { mobile } = useResponsive();

  const [currentImage, setCurrentImage] = useState(shownImages[0]);
  const [currentImageIndex, setCurrentImageIndex] = useState(0);
  const [filesObj, setFilesObj] = useState({});

  const driveRequest = driveApi({ accessToken });

  async function getFileSize(fileId) {
    await driveRequest?.getDriveItem(fileId)?.then((res) => {
      res.blob().then((blobRes) => {
        setFilesObj((prev) => ({ ...prev, [fileId]: blobRes }));
      });
    });
  }

  const handleThumbnailClick = (index) => {
    setCurrentImageIndex(index);
    setCurrentImage(shownImages[index]);
    carouselRef.current.goTo(index);
    if (!filesObj?.[shownImages[index]?.fileId]) {
      getFileSize(shownImages[index]?.fileId);
    }
  };

  useEffect(() => {
    setCurrentImage(shownImages[0]);
    setCurrentImageIndex(imageIndex ? imageIndex : 0);
    if (imageIndex) {
      carouselRef.current.goTo(imageIndex);
    }
    if (!filesObj?.[shownImages[0]?.fileId]) {
      getFileSize(shownImages[0]?.fileId);
    }
  }, [visible, shownImages]);

  const getSize = () => {
    let fileContent = Object.values(filesObj);

    let myBlob = new Blob([fileContent[currentImageIndex]], {
      type: "application/json",
    });

    return myBlob.size;
  };

  return (
    <InspectionModal
      // className="carouselModal"
      className={`galleryInspection ${isDarkMode && "galleryInspectionDark"}`}
      visible={visible}
      setVisible={setVisible}
      centered={false}
      footer={[
        {
          text: "close",
          onClick: () => setVisible(false),
          primary: false,
        },
        isDraft && {
          text: "delete",
          onClick: () => {
            onDelete(currentImage);
            setCurrentImage(shownImages[0]);
          },
          danger: true,
        },
        !!currentImage?.fileId && {
          text: "Download",
          className: "mondayButtonBlue",
          Icon: <DownloadIcon />,
          onClick: () => {
            window.open(
              `https://drive.google.com/u/1/uc?id=${currentImage.fileId}&export=download`
            );
          },
        },
      ].filter(Boolean)}
    >
      <SmallLoadableComp {...{ loading }}>
        {!!shownImages?.length ? (
          <>
            <Carousel
              className="galleryCarousel"
              ref={carouselRef}
              arrows
              draggable
              dotPosition="bottom"
              closeIcon={null}
              prevArrow={<LeftOutlined />}
              nextArrow={<RightOutlined />}
              afterChange={(currentSlide) => {
                setCurrentImage(shownImages[currentSlide]);
                setCurrentImageIndex(currentSlide);
              }}
            >
              {shownImages.map((image, index) => {
                return (
                  <div
                    key={index}
                    style={{ width: "100%" }}
                    className={`fileContainer ${
                      mobile && "fileContainer-mobile"
                    }`}
                  >
                    {image.src.includes("data:image/") ? (
                      <Image
                        fallback={fallback}
                        src={image?.src}
                        className="imageFile"
                      />
                    ) : image.src.includes("data:application/pdf;") ? (
                      <Document key={index} file={image?.blob}>
                        <Page
                          key={`page_${1}`}
                          pageNumber={1}
                          // width={200}
                          // height={200}
                          className="iframeFile"
                        />
                      </Document>
                    ) : image.src.includes(
                        "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
                      ) ? (
                      <iframe
                        src={`https://drive.google.com/file/d/${image?.fileId}/preview`}
                        className="iframeFile"
                      />
                    ) : (
                      <>Unknown Type!</>
                    )}
                  </div>
                );
              })}
            </Carousel>
            <div className="fileInfo">
              <p>
                <b>Size: </b>
                {getSize()}KB
              </p>
              <a
                target="_blank"
                href={`https://docs.google.com/file/d/${currentImage?.fileId}`}
              >
                Go To File
              </a>
            </div>

            <div className="thumbnailContainer">
              {shownImages.map((image, index) => (
                <div
                  key={index}
                  className={`thumbnail ${
                    currentImageIndex === index ? "active" : ""
                  }`}
                  onClick={() => handleThumbnailClick(index)}
                >
                  {image.src.includes("data:image/") ? (
                    <Image
                      src={image?.src}
                      alt={`Thumbnail ${index}`}
                      className="thumbnailImage"
                      fallback={fallback}
                      preview={false}
                    />
                  ) : image.src.includes("data:application/pdf;") ? (
                    <Document file={image?.blob}>
                      <Page key={`page_${1}`} pageNumber={1} width={100} />
                    </Document>
                  ) : image.src.includes(
                      "data:application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;"
                    ) ? (
                    <div className="excel-thumbnail">
                      <ExcelIcon />
                    </div>
                  ) : (
                    <span>No Thumbnail</span>
                  )}
                </div>
              ))}
            </div>
          </>
        ) : (
          <span className="noImages">No Images to show</span>
        )}
      </SmallLoadableComp>
    </InspectionModal>
  );
};

export default CarouselModal;

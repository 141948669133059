import _ from "lodash";
import { useState, useEffect } from "react";
import { AutoComplete, Select, Spin, message } from "antd";

import { debounceSearch } from "../../../utils";
import { findNestedObject, getNestedValues } from "./utils";

const AutoCompleteFetch = ({
  selectedDataTypeKey,
  selectedDataType,
  popupClassName,
  defaultValue,
  selectedKey,
  initialData,
  onSearch,
  onSelect,
  style,
  type,
}) => {
  const [options, setOptions] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    if (initialData?.length > 0 && selectedKey)
      setOptions(
        _.uniqBy(initialData, selectedKey)
          .map((item) => ({
            label: item[selectedKey],
            value: item[selectedKey],
          }))
          .filter(
            (data) =>
              data.value !== null &&
              data.value !== undefined &&
              data.value !== ""
          )
      );
  }, [selectedKey, selectedDataType]);

  const uniqueValuesSet = new Set();

  const finalOptions =
    selectedDataType !== "object"
      ? options
      : options
          .filter((item) => item.value.length > 0)
          .flatMap((item) =>
            item.value.map((item2) => {
              const value = item2[selectedDataTypeKey];
              if (!uniqueValuesSet.has(value)) {
                uniqueValuesSet.add(value);
                return {
                  value,
                  content: item.value.find(
                    (manager) => manager[selectedDataTypeKey] === value
                  ),
                };
              }
              return null; // Skip duplicate values
            })
          )
          .filter((item) => item !== null);

  const onSearchChangeHandler = (search) => {
    if (search) {
      setOptions([]);
      setIsLoading(true);
      onSearch(search)
        .then((result) =>
          setOptions(
            _.uniqBy(result, selectedKey).map((item) => ({
              label: item[selectedKey],
              value: item[selectedKey],
            }))
          )
        )
        .catch((error) => {
          message.error(
            "Sorry, we couldn't retrieve data at the moment. Please try again later."
          );
          console.error("Error Fetching Data", error);
        })
        .finally(() => setIsLoading(false));
    }
  };

  const onSearchHandler = debounceSearch(onSearchChangeHandler, 300);

  return type === "autocomplete" || selectedDataType === "object" ? (
    <AutoComplete
      allowClear
      popupClassName={popupClassName}
      placeholder="Search here..."
      options={finalOptions}
      defaultValue={defaultValue || ""}
      onSearch={onSearchHandler}
      onSelect={onSelect}
      notFoundContent={
        isLoading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
            size="small"
          />
        ) : null
      }
      style={style}
    />
  ) : (
    <Select
      allowClear
      mode="multiple"
      popupClassName={popupClassName}
      placeholder="Search here..."
      options={finalOptions}
      defaultValue={defaultValue || ""}
      onSearch={onSearchHandler}
      onSelect={onSelect}
      notFoundContent={
        isLoading ? (
          <Spin
            style={{
              display: "flex",
              justifyContent: "center",
              margin: "20px",
            }}
            size="small"
          />
        ) : null
      }
      style={style}
    />
  );
};

export default AutoCompleteFetch;

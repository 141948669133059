import { Popover, Radio } from "antd";
import { useState } from "react";
import { PlusIcon } from "src/assets";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { MondayButton } from "src/components/commonComponents";

export function AddServicePopover({
  isDarkMode,
  serviceOptions,
  services,
  setServices,
}) {
  const [filterValue, setFilterValue] = useState("");

  return (
    <Popover
      trigger={"click"}
      title="Add Service"
      overlayClassName={`addServicePopover ${
        isDarkMode && "addServicePopoverDark"
      }`}
      style={{ marginLeft: "10px" }}
      content={
        <div className="popoverServicesContainer">
          <InputComponent
            type="input"
            placeholder="Search service..."
            value={filterValue}
            onChange={({ target: { value } }) => setFilterValue(value)}
          />
          {serviceOptions?.flatMap((service) =>
            service.toLowerCase().includes(filterValue) ? (
              <Radio
                key={service}
                checked={!!services.includes(service)}
                onClick={(e) => {
                  if (!!services.includes(service)) {
                    setServices((prev) =>
                      prev.filter((srv) => srv !== service)
                    );
                  } else {
                    setServices((prev) => prev.concat(service));
                  }
                }}
              >
                {service}
              </Radio>
            ) : (
              []
            )
          )}
        </div>
      }
    >
      <MondayButton className="addServiceBtn" Icon={<PlusIcon />}>
        {""}
      </MondayButton>
    </Popover>
  );
}

import { useStateContext } from "../../Context/Context";
import HoistService from "./HoistService/HoistService";
import Service from "./Service/Service";
import Rentals from "./Rentals/Rentals";
import "./service-list.scss";
import { useSelector } from "react-redux";
import Preview from "../Header/Components/ControlPanel/ControlPanelComponents/Preview/Preview";
import { checkIfServiceIsHoist } from "../../../../../../Estimations/DataEntryGrid/models/Service";
import { HistoryOutlined } from "@ant-design/icons";
import { MondayButton } from "../../../../../../../commonComponents";
import { useCallback, useMemo, useState } from "react";
import Swal from "sweetalert2";
import ChainedRentals from "./ChainedRentals/ChainedRentals";
import PreviousRentals from "./PreviousRentals/PreviousRentals";
import { Select } from "antd";
import { currencyFormater } from "../../../../../../../../utils/currencyFormater";
import { useRedux } from "../../../../../../hooks";

const ServiceList = () => {
  const { fakeApplication, darkMode } = useStateContext();
  const { applications } = useSelector((state) => state.applications);
  const [viewType, setViewType] = useState({
    service: true,
    rental: false,
    prevRental: false,
    choosenService: null,
  });
  const [applicableEstimations] = useRedux("applicableEstimations");

  const getQuickbooksNumber = useCallback(
    (service) => {
      let currService = service;
      let applicableEstimation = applicableEstimations.find(
        (e) => e.estimationId === currService?.estimationId
      );
      let quickbooksNumber =
        applicableEstimation?.quickbooksNumber ||
        applicableEstimation?.estimationNumber ||
        "";
      return quickbooksNumber;
    },
    [fakeApplication?.services, applicableEstimations]
  );

  const options = useMemo(() => {
    return [
      ...(fakeApplication?.services?.map((service) => ({
        label:
          service.label +
          `${
            !!service.estimationNumber
              ? ` - #${getQuickbooksNumber(service)}`
              : ""
          }` +
          ` ||   ${currencyFormater(service?.totalPrice)}`,
        value: `${service.label}_${service.estimationId}_${service?.serviceId}`,
      })) ?? []),
      {
        label: "All Services",
        value: "all",
      },
    ];
  }, [fakeApplication?.services, applicableEstimations]);

  return (
    <div className="applications-service-list">
      {" "}
      <div className="applications-service-list__toolbar">
        {" "}
        <Select
          showSearch
          placeholder="Pick a Service"
          optionFilterProp="label"
          allowClear
          onChange={(value) => {
            setViewType((prev) => ({ ...prev, choosenService: value }));
          }}
          // onSearch={onSearch}
          style={{ width: "300px" }}
          options={options}
        />
        <MondayButton
          className={!viewType.service ? "mondayButtonBlue" : "mondayButtonRed"}
          hasIcon={false}
          onClick={() => {
            setViewType((prev) => ({ ...prev, service: !prev.service }));
          }}
        >
          {viewType?.service ? "Hide" : "Show"} Scopes of Work
        </MondayButton>{" "}
        <MondayButton
          className={!viewType.rental ? "mondayButtonBlue" : "mondayButtonRed"}
          hasIcon={false}
          onClick={() => {
            setViewType((prev) => ({ ...prev, rental: !prev.rental }));
          }}
        >
          {viewType?.rental ? "Hide" : "Show"} Current Rentals
        </MondayButton>
        <MondayButton
          hasIcon={false}
          className={
            !viewType.prevRental ? "mondayButtonBlue" : "mondayButtonRed"
          }
          onClick={() => {
            setViewType((prev) => ({ ...prev, prevRental: !prev.prevRental }));
          }}
        >
          {!viewType?.prevRental ? "Show" : "Hide"} Previous Rentals
        </MondayButton>
      </div>
      {!!fakeApplication &&
        viewType.service &&
        (!viewType.choosenService || viewType.choosenService === "all") &&
        (fakeApplication?.services?.length < 8 ||
          viewType.choosenService === "all") &&
        fakeApplication?.services?.map((service) =>
          checkIfServiceIsHoist(service) ? (
            <HoistService
              {...{
                childKey: `${service.label}/${service.estimationId}`,
                service,
              }}
            />
          ) : (
            !!service?.amounts?.length && (
              <Service
                {...{
                  childKey: `${service.label}/${service.estimationId}`,
                  service,
                }}
              />
            )
          )
        )}
      {!!fakeApplication &&
        viewType.choosenService &&
        fakeApplication?.services
          ?.filter(
            (service) =>
              `${service.label}_${service.estimationId}_${service?.serviceId}` ===
              viewType.choosenService
          )
          .map((service) =>
            checkIfServiceIsHoist(service) ? (
              <HoistService
                {...{
                  childKey: `${service.label}/${service.estimationId}`,
                  service,
                }}
              />
            ) : (
              !!service?.amounts?.length && (
                <Service
                  {...{
                    childKey: `${service.label}/${service.estimationId}`,
                    service,
                  }}
                />
              )
            )
          )}
      {viewType.rental &&
        !!fakeApplication &&
        !!fakeApplication?.chainedRentalsIds?.length && (
          <>
            <div
              className={
                darkMode
                  ? "includedRentalsMainHeaderDark"
                  : "includedRentalsMainHeaderLight"
              }
            >
              These are the rentals included in this Requisition <Preview />
            </div>
            {fakeApplication?.chainedRentalsIds?.map((rental) => (
              <Rentals {...{ rental, type: "this" }} />
            ))}
          </>
        )}{" "}
      {/* {viewType.rental &&
        !!fakeApplication &&
        !!fakeApplication?.includedRentals?.length && (
          <>
            <div
              className={
                darkMode
                  ? "includedRentalsMainHeaderDark"
                  : "includedRentalsMainHeaderLight"
              }
            >
              These are the rentals included in this Requisition <Preview />
            </div>
            {fakeApplication?.includedRentals?.map((rental) => (
              <Rentals {...{ rental, type: "this" }} />
            ))}
          </>
        )} */}
      {/* {viewType.prevRental && !!applications?.length && (
        <>
          {applications
            .sort((a, b) => b?.applicationNo - a?.applicationNo)
            ?.filter(
              (el) =>
                el?.applicationNo < fakeApplication?.applicationNo &&
                el?.scheduleId === fakeApplication?.scheduleId
            )
            .map((application, idx) => {
              if (application?.includedRentals?.length > 0)
                return (
                  <>
                    <div
                      className={
                        darkMode
                          ? "includedRentalsMainHeaderDark"
                          : "includedRentalsMainHeaderLight"
                      }
                    >
                      <HistoryOutlined />
                      <strong style={{ marginLeft: "10px", color: "#71CF48" }}>
                        These are the rentals included in Requisition{" "}
                        {application?.applicationNo} <Preview />
                      </strong>
                    </div>
                    {application?.includedRentals?.map((rental) => (
                      <Rentals
                        {...{
                          rental,
                          type: "prev",
                          accumulatedRentals:
                            fakeApplication?.accumulatedRentals ?? false,
                          prevRentalsRetainage:
                            fakeApplication?.totalities?.prevRentalsRetainage?.[
                              `Rental${rental?.rentalNumber}`
                            ],
                        }}
                      />
                    ))}{" "}
                  </>
                );
            })}
        </>
      )} */}
      {/* <ChainedRentals /> */}{" "}
      {viewType.prevRental && (
        <PreviousRentals
          {...{
            fakeApplication,
          }}
        />
      )}
    </div>
  );
};

export default ServiceList;

import { useState } from "react";
import { API } from "aws-amplify";
import { useDispatch, useSelector } from "react-redux";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { EyeFilled } from "@ant-design/icons";
import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../utils";
import { Tick } from "../../../src";
import { TourGuide } from "../../../common";
import RoleWarningModal from "./RoleWarningModal";
import { ReactComponent as Back } from "../../../src/back.svg";
import { userRolesStepData } from "../utils";
import { userGroupsConfig } from "../../../../../../../../actions";
import {
  ExportOrEmailDropdown,
  LogsButton,
  MondayButton,
} from "../../../../../../../commonComponents";
import PreviewAccessModal from "./PreviewAccessModal/PreviewAccessModal";
import { removeDescription } from "../../../../Management/EditUser/components/UserAccessWrites/utils/filterRoutes";
import {
  exportToExcel,
  onGeneratePDF,
  uploadExcelToDrive,
} from "../utils/exportToPdfHandler";
import { useEditLogs, useResponsive } from "../../../../../../../../hooks";
import PdfHeadersModal from "../../../../../../../commonComponents/PdfHeadersModal/PdfHeadersModal";
import useExportHeadersModal from "../../../../../../../../hooks/useExportHeadersModal";
import { tableHeaders } from "../constants/tableHeaders";
import { isEmpty } from "lodash";

const RoleFooter = ({
  accessConfiguration,
  unchangedData,
  setUnchangedData,
  departmentGroups,
  notificationSettings,
}) => {
  const [warningModalVisible, setWarningModalVisible] = useState(false);
  const [previewAccessVisible, setPreviewAccessVisible] = useState(false);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const location = useLocation();

  const { departmentName, role } = useParams();

  const { mobile } = useResponsive();

  const { base64 } = useSelector((state) => state.base64);
  const { userGroups } = useSelector((state) => state.userGroups);
  const { saveAddedLogs } = useEditLogs();

  let logs = {
    actionType: "Edit",
    category: `Role Access ${role}`,
    recordId: "",
    updatedAt: Date.now(),
    currentData: {
      route: {},
      sidebar: {},
      notificationPref: {},
    },
    previousData: {
      route: {},
      sidebar: {},
      notificationPref: {},
    },
    recordName: "Route",
    label: "",
    updatedKeys: [],
  };

  const currentRole = role || "Engineer/Architect";

  const userGroup = userGroups
    .filter(
      (group) =>
        group.departmentName.toLowerCase() === departmentName.toLowerCase()
    )
    .find((role) => role.groupName.toLowerCase() === currentRole.toLowerCase());

  const previewAccessData = departmentGroups.find(
    (el) => el[currentRole] !== undefined
  )?.[currentRole];

  const generateLogForRoute = (route1, route2) => {
    Object.keys(route1).forEach((key) => {
      if (
        key === "children" &&
        Array.isArray(route1[key]) &&
        Array.isArray(route2[key])
      ) {
        route1[key].forEach((child, index) => {
          generateLogForRoute(child, route2[key][index]);
        });
      } else if (route1[key] instanceof Object) {
        generateLogForRoute(route1[key], route2[key]);
      } else if (route1[key] !== route2[key]) {
        logs.currentData.route[route1.title] = {
          ...(logs.currentData.route[route1.title] || {}),
          [key]: route2[key] ? "Yes" : "No",
        };
        logs.previousData.route[route1.title] = {
          ...(logs.previousData.route[route1.title] || {}),
          [key]: route1[key] ? "Yes" : "No",
        };
      }
    });
  };

  const generateLogForSidebar = (unchangedData) => {
    unchangedData.Sidebar.map((route, index) => {
      if (route.read !== accessConfiguration.Sidebar[index].read) {
        logs.currentData.sidebar[route.title] = accessConfiguration.Sidebar[
          index
        ].read
          ? "Yes"
          : "No";
        logs.previousData.sidebar[route.title] = route.read ? "Yes" : "No";
      }
    });
  };

  const generateLogForNotificationPref = (unchangedData) => {
    const prevNotificationPref = Object.values(
      unchangedData["Notification Preferences"].notifications.topics
    );
    const newNotificationPref = Object.values(
      accessConfiguration["Notification Preferences"].notifications.topics
    );

    prevNotificationPref.map((item, index) => {
      for (const [key, value] of Object.entries(item)) {
        if (value !== newNotificationPref[index][key]) {
          logs.currentData.notificationPref[key] = newNotificationPref[index][
            key
          ]
            ? "Yes"
            : "No";
          logs.previousData.notificationPref[key] = value ? "Yes" : "No";
        }
      }
    });
  };

  const removeEmptyKeys = (obj) => {
    Object.keys(obj).forEach((key) => {
      if (isEmpty(obj[key])) {
        delete obj[key];
      }
    });
    return obj;
  };

  const saveChanges = () => {
    let tmpUnchangedData = { ...unchangedData };
    setUnchangedData(accessConfiguration);
    const {
      Route,
      Sidebar,
      ["Notification Preferences"]: preferences,
    } = accessConfiguration;

    const routeConfig = Route.filter((route) => route.read);
    const sidebarConfig = Sidebar.filter((sidebar) => sidebar.read);

    let filteredRouteConfig = [...routeConfig];

    if (role !== "Super Admin") {
      filteredRouteConfig = removeDescription(routeConfig);
    }

    showLoadingMsg({ content: "Saving changes. Please wait..." });

    API.put("userGroups", `/userGroups/${userGroup.groupId}`, {
      body: {
        groupConfigs: {
          preferences,
          routeConfig: filteredRouteConfig,
          sidebarConfig,
        },
      },
    })
      .then(() => {
        dispatch(
          userGroupsConfig({
            groupId: userGroup.groupId,
            groupConfigs: {
              preferences,
              routeConfig: filteredRouteConfig,
              sidebarConfig,
            },
          })
        );
        showSuccessMsg({ content: "Changes saved successfully." });
        generateLogForRoute(tmpUnchangedData.Route, accessConfiguration.Route);
        generateLogForSidebar(tmpUnchangedData);
        generateLogForNotificationPref(tmpUnchangedData);
        removeEmptyKeys(logs.currentData);
        removeEmptyKeys(logs.previousData);
        saveAddedLogs(logs);
      })
      .catch(() => {
        showErrorMsg({ content: "Error saving changes." });
      })
      .finally(() => {
        setWarningModalVisible(false);
      });
  };

  const additionalData = {
    accessConfiguration,
    notificationSettings,
    currentRole,
    logs,
    base64,
    includeNotificationPref: true,
  };

  const { openModal, getHeadersModal, handleCloseModal, handleFinishModal } =
    useExportHeadersModal({
      additionalData,
      onGeneratePDF,
      exportToExcel,
    });

  return (
    <>
      <div
        className={`submitButtonContainer ${
          mobile && "submitButtonContainer-mobile"
        }`}
      >
        <div className="left">
          <MondayButton
            className="backButton"
            Icon={<Back />}
            // onClick={() => navigate(`/settings/rolesAccess/${departmentName}`)}
            onClick={() =>
              location.key === "default"
                ? navigate(`/settings/rolesAccess`)
                : navigate(-1)
            }
          >
            Back
          </MondayButton>
          <TourGuide
            {...{
              tab: 7,
              category: "rolesAccessRightsChanges",
              stepsData: userRolesStepData,
            }}
          />
        </div>
        <div className="right">
          <LogsButton
            {...{
              logsTitle: `Role Access ${role} Logs`,
              filtersObj: { category: `Role Access ${role}` },
            }}
          />
          <MondayButton
            id={"previewButton"}
            className="mondayButtonBlue iconButtons"
            Icon={<EyeFilled style={{ fontSize: "22px", color: "#fff" }} />}
            onClick={() => setPreviewAccessVisible(true)}
          ></MondayButton>
          <ExportOrEmailDropdown
            {...{
              rowData: true,
              onGeneratePDF: (action, getDocDefinition) =>
                getDocDefinition
                  ? onGeneratePDF(
                      action,
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs
                    )
                  : getHeadersModal(action, getDocDefinition),
              exportGridToExcel: (getDocDefinition) =>
                getDocDefinition
                  ? exportToExcel(
                      additionalData,
                      getDocDefinition,
                      null,
                      saveAddedLogs
                    )
                  : getHeadersModal(false, getDocDefinition, "excel"),
              uploadExcelToDrive: (driveRequest, driveFolderId) =>
                uploadExcelToDrive(additionalData, driveRequest, driveFolderId),
              defaultDocDefinition: true,
              title: `RolesAccess_${currentRole}`,
            }}
          />

          <MondayButton
            id={"submitButton"}
            className="mondayButton"
            Icon={<Tick />}
            onClick={() => setWarningModalVisible(true)}
          >
            Submit
          </MondayButton>
        </div>
      </div>
      {previewAccessVisible && (
        <PreviewAccessModal
          {...{
            previewAccessData,
            previewAccessVisible,
            setPreviewAccessVisible,
            additionalData,
            saveAddedLogs,
          }}
        />
      )}
      {warningModalVisible && (
        <RoleWarningModal
          {...{
            saveChanges,
            warningModalVisible,
            setWarningModalVisible,
            roles: true,
          }}
        />
      )}
      {openModal && (
        <PdfHeadersModal
          {...{
            isOpen: openModal,
            onClose: () => handleCloseModal(),
            onFinish: (data) => handleFinishModal(data),
            headers: tableHeaders,
            title: "Role Access",
            nested: true,
          }}
        />
      )}
    </>
  );
};

export default RoleFooter;

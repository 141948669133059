import { useState } from "react";
import { message } from "antd";
import { HistoryOutlined } from "@ant-design/icons";
import { useEditLogs } from "../../../hooks";
import MondayButton from "../MondayButton/MondayButton";
import MultiLevelTreeLogs from "../MultiLevelTreeLogs/MultiLevelTreeLogs";

const LogsButton = ({
  buttonLabel,
  logsTitle = "Logs",
  filtersObj = {},
  tooltipCategory,
  tooltipKey,
  onFetchedLogs,
}) => {
  const [showLogsModal, setShowLogsModal] = useState(false);

  const { fetchedLogs, setFetchedLogs, handleFetchLogs } = useEditLogs();

  const handleShowLogs = () => {
    if (!!fetchedLogs?.length) {
      setShowLogsModal(true);
    } else {
      message.loading("Fetching logs...", 30);
    }

    const filters = [
      {
        conditions: Object.entries(filtersObj).map(([column, value]) => ({
          column,
          value,
          formula: "is",
          operator: "AND",
        })),
      },
    ];

    handleFetchLogs({ filters }).then((response) => {
      !fetchedLogs?.length && setShowLogsModal(true);

      if (onFetchedLogs) {
        onFetchedLogs(response ?? []);
      }
    });
  };

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<HistoryOutlined />}
        onClick={handleShowLogs}
        tooltipCategory={tooltipCategory}
        tooltipKey={tooltipKey}
      >
        {buttonLabel}
      </MondayButton>

      <MultiLevelTreeLogs
        visible={showLogsModal}
        setVisible={setShowLogsModal}
        logsData={fetchedLogs?.sort((a, b) => a.updatedAt - b.updatedAt)}
        title={logsTitle}
        onLogsErase={() => setFetchedLogs([])}
      />
    </>
  );
};

export default LogsButton;

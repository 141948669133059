import React, { useEffect, useState, Suspense } from "react";
import { useSelector } from "react-redux";

import lazyRetry from "../../../utils/LazyRetry";
import { keys } from "../../pages/Settings/settingsComponents/Roles/RolesData";
import { formatCurrency, onDateFilter, onFilter } from "../utils";
import { LoadableComp } from "../XComponents";
import { dateFilterOptions } from "../XComponents/ControlPanel/datas";
import { useDeviceType } from "../../../hooks";
import { updateDocumentTitle } from "../../../utils";
import { getStatus } from "./utilities/functions";
import { useDispatch } from "react-redux";
// import { statusKeys } from "./BasePageData";
// import DynamicBasePage from "./DynamicBasePage";
const DynamicBasePage = React.lazy(() =>
  lazyRetry(
    () => import(/* webpackChunkName: "DynamicBasePage" */ "./DynamicBasePage"),
    "DynamicBasePage"
  )
);

const BasePage = ({
  title,
  rowData = [],
  exportData = false,
  filtersObject = [],
  onPaginationChanged = () => {},
  columnDefs = [],
  panelObject = {},
  rowSelection = "single",
  videoCategory,
  fleetName = false,
  driverName = false,
  defaultExcelExportParams = false,
  getSelected = () => {},
  agGridDefs = {},
  isTab = false,
  collapseKeys = [],
  onRowClicked = () => {},
  onRowDoubleClicked = () => {},
  setConflicts = () => {},
  setOpenProgressLogs = () => {},
  getChartingOptions = false,
  customNew = false,
  hasFilter = true,
  hasNew = true,
  hasStatus = true,
  detailsCell = false,
  exportGridToExcel = false,
  formatCellValueHandler,
  getGridApi = () => {},
  customstyle = "",
  filterContainClassName = "",
  sumKey = false,
  onSelectionChanged = () => {},
  getRowStyle = () => {},
  optionButton,
  getGridApiForParent = () => {},
  paginationPageSize = 10,
  changesModalVisible = false,
  setChangesModalVisible = () => {},
  setDaysOverview = () => {},
  setViewCrews = () => {},
  isEditable = true,
  videoLinks,
  customFooterData = null,
  customHeaderData = null,
  context = {},
  fetchAllLogsButton = false,
  getFiltersData = () => {},
  deleteSelectedRowsParams = {
    buttonVisibility: false,
    onDeleteSelectedRows: () => {},
  },
  suppressRowClickSelection = false,
  suppressPaginationPanel = false,
  panelFilter = false, //if we have two types of status panels ex.NotesBasePage
  setPanelFilter = () => {},
  customStatusPanel = false,
  fullScreenOptions = true,
  hasLive = true,
  customSort = false,
  hasGalleryItems = false,
  Conflicts = {},
  statuses = {},
  searchTableHandler,
  allowFetchSearch,
  filterSearchData = () => {},
  getGridRef = () => {},
  onDebounceChange,
  paginationAutoPageSize = true,
  reloadGrid = () => {},
  reloadButton,
  setRowData = () => {},
  customToggle = false,
  duplicateStatusColorKey = false,
  warningObj = {},
  rowId,
  additionalStatusPanels = [],
  hasFetchSearchFromDb = false,
  fetchAllHandler,
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
  setCustomFiltersData = () => {},
  tooltipCategory,
}) => {
  const dispatch = useDispatch();
  const [clickedValue, setClickedValue] = useState();
  const [gridApi, setGridApi] = useState();
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [newColumnDefs, setNewColumnDefs] = useState();
  const [statusPanel, setStatusPanel] = useState({});

  // for example in notes we have two types of status panels so we get from programfields only the selected one
  function getStatusPanelData() {
    let tmpTitle = !duplicateStatusColorKey ? title : duplicateStatusColorKey;

    if (!customStatusPanel) {
      if (panelFilter?.visible || panelFilter?.visibleOne) {
        //visible is to display switch visibleOne is to get statuses when we have default switch example Leads/TaskBasePage

        return programFields?.find(
          ({ fieldName }) => fieldName === "Status colors"
        )?.fieldOptions[tmpTitle][panelObject?.key];
      }
      return programFields?.find(
        ({ fieldName }) => fieldName === "Status colors"
      )?.fieldOptions[
        tmpTitle === "Violations" ? "Fleets Violations" : tmpTitle
      ];
    } else {
      return customStatusPanel;
    }
  }

  // for example in notes we have two types of status panels so we get from programfields only the selected one
  const statusPanelData = getStatusPanelData();

  // added these variables to handle tabs columnDefs(to dont be included in device management)
  const pathName = window.location.pathname;
  const pattern = /^(\/\w+\/[^\/\d]*|\/?\w+)$/;
  const matches = pattern.test(pathName); // .test tests for a match in a string

  // title's converter
  pathName === "/fleetInspections" ? (title = "Fleets Inspections") : title;

  // Define a function to calculate the value based on your conditions
  function calculateValue(statusName) {
    return (
      rowData?.filter((e) => {
        if (panelObject?.key === "createdAt" && !customSort) {
          return (
            parseInt(e[panelObject?.key]) >=
              dateFilterOptions[statusName][0].valueOf() &&
            parseInt(e[panelObject?.key]) <=
              dateFilterOptions[statusName][1].valueOf()
          );
        } else {
          if (typeof e?.[panelObject?.key] === "string") {
            return (
              e?.[panelObject?.key]?.toLowerCase() === statusName?.toLowerCase()
            );
          } else {
            return getStatus({ title, statusName, record: e });
          }
        }
      })?.length || 0
    );
  }

  // Define a function to format the value based on sumKey
  function formatValue(statusName) {
    const filteredData =
      rowData?.filter((e) => e[panelObject?.key] === statusName) || [];
    const sum = filteredData.reduce(
      (acc, curr) => acc + (curr[sumKey.key] || 0),
      0
    );

    return sumKey.type === "currency" ? formatCurrency(sum) : sum;
  }

  let data = statusPanelData?.map(({ statusName, statusColor }) => {
    const hasStatus = statusName in statuses;
    const value = hasStatus ? (
      <span style={{ fontSize: 40 }}>{statuses[statusName]}</span>
    ) : sumKey ? (
      <div
        style={{
          display: "flex",
          flexDirection: "column",
          alignItems: "center",
        }}
      >
        <span style={{ fontSize: 40 }}>{calculateValue(statusName)}</span>
        <span style={{ fontSize: 16 }}>{formatValue(statusName)}</span>
      </div>
    ) : (
      calculateValue(statusName)
    );

    return {
      statusName,
      statusColor,
      value,
    };
  });

  // This section of code, from line 166 to line 256, handles the configuration and filtering of column definitions for the current device based on settings defined in the Device Management section.
  const currentDevice = useDeviceType(); // First, it retrieves the current device type using useDeviceType().
  const currentDepartment = userConfiguration?.departmentName;
  const currentRoleByDepartment = userConfiguration?.groupName;

  const findFieldOptions =
    programFields &&
    programFields.find(
      ({ fieldId }) => fieldId === "adb571d9-caee-44f3-a8d3-e66ed6568278"
    ).fieldOptions;

  const findFieldOptions2 = findFieldOptions.configurations;

  // Then, it extracts the field options from programFields based on a specific fieldId.
  const dynamicColumnDefs =
    findFieldOptions2 && findFieldOptions2[currentDevice] // Next, it determines the  (dynamicColumnDefs) based on the current device type , title and findFieldOptions2.
      ? (findFieldOptions2 &&
          currentDevice &&
          currentDepartment &&
          currentRoleByDepartment &&
          title &&
          findFieldOptions2[currentDevice]?.[currentDepartment]?.[
            currentRoleByDepartment
          ]?.[title]) ||
        []
      : [];

  useEffect(() => {
    title &&
      updateDocumentTitle({ [isTab ? "newSubTitle" : "newTitle"]: title }); // set Title of Tab

    return () => {
      title && updateDocumentTitle(); // reset document title to "Lead Manager";
    };
  }, []);

  useEffect(() => {
    // const dynamicColumnDefsFields = dynamicColumnDefs.map((colDef) =>
    //   colDef?.headerName.trim().toLocaleLowerCase()
    // );

    // if dynamicColumnDefs comes duplicated for example we have the status field 2 times field:status and another field:status this variable handle this and remove duplicated fields
    // const uniqueDynamicColumnDefs = [
    //   ...new Map(
    //     dynamicColumnDefs.map((item) => [item.headerName, item])
    //   ).values(),
    // ];

    const sourceColumnDefs =
      typeof columnDefs === "function" ? columnDefs() : columnDefs;

    // const updatedColumnDefs = matches // Finally, the resulting column definitions are set in 'newColumnDefs', showing final structure based of configuration made in device management.
    //   ? uniqueDynamicColumnDefs.length
    //     ? uniqueDynamicColumnDefs
    //         .map((dynamicColDef) => {
    //           const colDef = sourceColumnDefs.find((col) => {
    //             return (
    //               col?.headerName?.trim()?.toLocaleLowerCase() ===
    //               dynamicColDef?.headerName?.trim()?.toLocaleLowerCase()
    //             );
    //           });

    //           if (colDef) {
    //             return {
    //               ...colDef,
    //               hide: dynamicColDef?.hide,
    //             };
    //           }

    //           return dynamicColDef;
    //         })
    //         .filter(Boolean)
    //     : sourceColumnDefs
    //   : sourceColumnDefs;

    // console.log({ currentDevice });
    // console.log({ currentDepartment });
    // console.log({ currentRoleByDepartment });
    // console.log({ title });
    // console.log({ findFieldOptions2 });
    // console.log({ dynamicColumnDefs });
    // console.log({ uniqueDynamicColumnDefs });
    // console.log({ sourceColumnDefs });
    // console.log({ updatedColumnDefs });

    // if (dynamicColumnDefsFields.length !== 0) {
    //   columnDefs.forEach((colDef) => {
    //     if (
    //       !dynamicColumnDefsFields?.includes(
    //         colDef?.headerName.trim().toLocaleLowerCase()
    //       ) &&
    //       !updatedColumnDefs?.some(
    //         (el) =>
    //           el?.headerName.trim().toLowerCase() ===
    //           colDef?.headerName.trim().toLowerCase()
    //       )
    //     ) {
    //       updatedColumnDefs.push(colDef);
    //     }
    //   });
    // }

    if (sourceColumnDefs.length !== 0 && filtersObject?.length !== 0) {
      if (keys(panelObject)?.length !== 0 && sourceColumnDefs?.length !== 0) {
        // updatedColumnDefs.filter((column) => !!column?.children).length > 0
        //   ? (updatedColumnDefs.find(
        //       (column) =>
        //         !!column?.children &&
        //         column?.children?.find(
        //           ({ field }) => field === panelObject?.key
        //         )
        //     ).filter =
        //       panelObject?.key === "createdAt"
        //         ? "agDateColumnFilter"
        //         : "agTextColumnFilter")
        //   : ((
        //       updatedColumnDefs.find(
        //         ({ field }) => field === panelObject?.key
        //       ) || {}
        //     ).filter =
        //       panelObject?.key === "createdAt"
        //         ? "agDateColumnFilter"
        //         : "agTextColumnFilter");

        const filterType =
          panelObject?.key === "createdAt"
            ? "agDateColumnFilter"
            : "agTextColumnFilter";

        setNewColumnDefs(
          sourceColumnDefs?.filter((column) => !!column?.children)?.length > 0
            ? sourceColumnDefs?.map((column) => {
                const hasChildren = column?.children?.length > 0;
                const findedChildrenCol = column?.children?.find(
                  ({ field }) => field === panelObject?.key
                );
                return hasChildren && findedChildrenCol
                  ? { ...column, filter: filterType }
                  : column;
              })
            : sourceColumnDefs?.map((col) =>
                col?.field === panelObject?.key
                  ? { ...col, filter: filterType }
                  : col
              )
        );
      }
    } else if (filtersObject?.length === 0) {
      // setNewColumnDefs(updatedColumnDefs);
    }
    setNewColumnDefs(sourceColumnDefs);
  }, [
    columnDefs,
    currentDevice,
    currentDepartment,
    currentRoleByDepartment,
    title,
  ]);

  let onClick = (e) => {
    if (Object.keys(statuses).length > 0) {
      gridRef.showLoadingOverlay();
      searchTableHandler(e, true);
      setClickedValue(e);
    } else {
      if (panelObject?.key === "createdAt") {
        setClickedValue(dateFilterOptions[e]);
        if (filtersObject?.length === 0 || filtersObject === undefined) {
          onDateFilter({
            gridApi,
            column: panelObject?.key,
            dateArray: dateFilterOptions[e],
          });
        }
      } else {
        setClickedValue(e);
        if (filtersObject?.length === 0 || filtersObject === undefined) {
          onFilter({
            gridApi,
            column: panelObject?.key,
            current: e,
            type: "equal",
          });
          setCustomFiltersData((prev) => ({
            ...prev,
            [panelObject?.key]: [e],
          }));
        }
      }
    }
  };

  useEffect(() => {
    setStatusPanel({ data, onClick });
  }, [programFields, JSON.stringify(panelFilter)]);

  const onRowDataUpdated = (e) => {
    let newList = [];
    e.api.forEachNode((node) => !!node.data && newList.push(node.data));
    newList = newList.filter(Boolean);
    setStatusPanel({
      data: statusPanelData?.map(({ statusName, statusColor }) => {
        return {
          statusName,
          statusColor,
          value: sumKey ? (
            <div
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <span style={{ fontSize: 40 }}>
                {newList?.filter((e) => {
                  if (panelObject?.key === "createdAt") {
                    return (
                      parseInt(e[panelObject?.key]) >=
                        dateFilterOptions[statusName][0].valueOf() &&
                      parseInt(e[panelObject?.key]) <=
                        dateFilterOptions[statusName][1].valueOf()
                    );
                  } else if (title === "Vendors") {
                    return (
                      statusName?.toLowerCase() ===
                      e?.vendorStatus?.toLowerCase()
                    );
                  } else {
                    return (
                      e[panelObject?.key]?.toLowerCase() ===
                      statusName?.toLowerCase()
                    );
                  }
                })?.length || 0}
              </span>
              <span style={{ fontSize: 16 }}>
                {sumKey.type === "currency"
                  ? formatCurrency(
                      newList
                        ?.filter((e) => e[panelObject?.key] === statusName)
                        ?.reduce((acc, curr) => {
                          return (acc = acc + curr[sumKey.key] || 0);
                        }, 0) || 0
                    )
                  : newList
                      ?.filter((e) => e[panelObject?.key] === statusName)
                      ?.reduce((acc, curr) => {
                        return (acc = acc + curr[sumKey.key] || 0);
                      }, 0) || 0}
              </span>
            </div>
          ) : (
            newList?.filter((e) => {
              if (panelObject?.key === "createdAt") {
                return (
                  parseInt(e[panelObject?.key]) >=
                    dateFilterOptions[statusName][0].valueOf() &&
                  parseInt(e[panelObject?.key]) <=
                    dateFilterOptions[statusName][1].valueOf()
                );
              } else {
                if (typeof e?.[panelObject?.key] === "string") {
                  return !!panelFilter?.visible
                    ? e[panelObject?.key]?.toLowerCase() ===
                        statusName?.toLowerCase()
                    : e[panelObject?.key]?.toLowerCase() ===
                        statusName?.toLowerCase();
                } else {
                  return getStatus({ title, statusName, record: e });
                }
              }
            })?.length || 0
          ),
        };
      }),
      onClick,
    });
  };

  return (
    <LoadableComp loading={newColumnDefs === undefined}>
      <Suspense fallback={<LoadableComp loading={true} />}>
        <DynamicBasePage
          key={newColumnDefs}
          {...{
            Conflicts,
            onRowDataUpdated,
            title,
            customSort,
            statusPanel,
            filtersObject,
            rowData,
            videoCategory,
            exportData,
            fleetName,
            driverName,
            formatCellValueHandler,
            suppressRowClickSelection,
            columnDefs: newColumnDefs,
            clickedValue,
            setClickedValue,
            panelObject,
            exportGridToExcel,
            getRowStyle,
            rowSelection,
            getSelected,
            agGridDefs,
            isTab,
            onRowClicked,
            defaultExcelExportParams,
            fetchAllLogsButton,
            onPaginationChanged,
            onRowDoubleClicked,
            customNew,
            hasFilter,
            hasNew,
            additionalStatusPanels,
            videoLinks,
            hasStatus,
            detailsCell,
            collapseKeys,
            getGridApi: (grid) => {
              getGridApiForParent(grid);
              setGridApi(grid);
              getGridApi(grid);
            },
            getGridRef,
            customstyle,
            filterContainClassName,
            onSelectionChangedOption: onSelectionChanged,
            optionButton,
            getFiltersData,
            paginationPageSize,
            changesModalVisible,
            setChangesModalVisible,
            setDaysOverview,
            setViewCrews,
            isEditable,
            customFooterData,
            customHeaderData,
            context,
            deleteSelectedRowsParams,
            suppressPaginationPanel,
            panelFilter,
            setPanelFilter,
            customStatusPanel,
            fullScreenOptions,
            hasLive,
            hasGalleryItems,
            setConflicts,
            setOpenProgressLogs,
            getChartingOptions,
            searchTableHandler,
            allowFetchSearch,
            filterSearchData,
            onDebounceChange,
            paginationAutoPageSize,
            reloadGrid,
            reloadButton,
            setRowData,
            customToggle,
            warningObj,
            rowId,
            hasFetchSearchFromDb,
            fetchAllHandler,
            nextStepHandler,
            handleSavedRecord,
            tooltipCategory,
          }}
        />
      </Suspense>
    </LoadableComp>
  );
};
export default BasePage;

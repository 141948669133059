import "./AutomationResponses.scss";
import { useState } from "react";
import moment from "moment";
import { Alert } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";

const AutomationResponses = ({ title, responses }) => {
  const [itemsLength, setItemsLength] = useState(100);
  const [searchedResponse, setSearchedResponse] = useState("");
  const [clickedIndex, setClickedIndex] = useState();

  const handleScroll = (e) => {
    const { scrollTop, scrollHeight, clientHeight } = e.target;
    if (scrollHeight - scrollTop === clientHeight) {
      setItemsLength((prev) => (prev += 50));
    }
  };

  const finalResponses = responses
    ?.slice(0, itemsLength)
    .filter(
      ({ responseTitle = "", description = "" }) =>
        responseTitle.toLowerCase().includes(searchedResponse.toLowerCase()) ||
        description.toLowerCase().includes(searchedResponse.toLowerCase())
    )
    ?.sort((a, b) => b.responseDate - a.responseDate);

  return (
    <div className="automation-responses-container">
      <div className="header">
        {title && <span style={{ fontWeight: 600 }}>{title}</span>}
        <InputComponent
          prefix={<SearchOutlined />}
          noFormItem={true}
          inputAllowClear={true}
          placeholder="Search responses..."
          onChange={(e) => {
            const value = e.target.value;
            setItemsLength(!!value ? responses?.length : 100);
            setSearchedResponse(value);
          }}
        />
      </div>
      <div className="automation-responses" onScroll={handleScroll}>
        {finalResponses?.length ? (
          finalResponses.map((response, index) => {
            const {
              automationName,
              responseDate,
              toUsers = [],
              error,
              responseTitle,
              description,
            } = response;

            return (
              <Alert
                key={index}
                data-testid="automation-response-testid"
                onClick={() => {
                  setClickedIndex((prev) => prev !== index && index);
                }}
                style={{ cursor: "pointer" }}
                message={
                  <div>
                    <>
                      {`"${automationName}" executed at `}
                      <b>
                        {`${moment(responseDate).format(
                          "MMMM Do YYYY, h:mm:ss a"
                        )} `}
                      </b>
                      with <b>{error}</b>
                    </>
                    {clickedIndex === index && (
                      <div style={{ display: "flex", flexDirection: "column" }}>
                        <i>
                          <b>Response Title:</b> {responseTitle}
                        </i>
                        <i>
                          <b>Description:</b> {description}
                        </i>
                        <i>
                          <b>To Users:</b> {toUsers?.join(", ")}
                        </i>
                      </div>
                    )}
                  </div>
                }
                type={error === "Success" ? "success" : "error"}
                showIcon
              />
            );
          })
        ) : (
          <Alert type="warning" message={"No responses to show!"} />
        )}
      </div>
    </div>
  );
};

export default AutomationResponses;

// import { FileIcon, defaultStyles } from "react-file-icon";
import { Tooltip } from "antd";
import {
  ExcelIcon,
  PdfDocIcon,
  WordDocIcon,
} from "../../../../../../../../../../../../assets";
import { useAttachments } from "../../../../../../../providers/AttachmentsProvider";

const ItemContainer = ({
  file,
  isSelected,
  files,
  setFiles,
  setShowUpload,
}) => {
  const { attachments } = useAttachments();
  const type = file?.name?.split(".")[1]?.toLowerCase();
  const isDisabled = attachments.some(
    (uploadedFile) => uploadedFile.id === file.id
  );

  const FileIcon = () => {
    switch (type) {
      case "pdf":
        return <PdfDocIcon style={{ height: "20px", width: "16px" }} />;
      case "docx":
        return <WordDocIcon style={{ height: "20px", width: "16px" }} />;
      case "xlsx":
        return <ExcelIcon style={{ height: "20px", width: "16px" }} />;
    }
  };

  return (
    <>
      <Tooltip title={file.name}>
        <div
          className={`${
            isSelected ? "FolderItemContainer-selected" : "FolderItemContainer"
          } ${isDisabled ? " disabled" : ""}`}
          onClick={() => {
            // Check if the button is disabled; if it is, exit the function without doing anything
            if (isDisabled) return false;

            // If the file is currently selected
            if (isSelected) {
              // Filter through the files to find the one matching the current file's ID
              files
                .filter(({ id }) => id === file.id) // Filter for the file with the same ID
                .forEach((e) => {
                  e.selected = false; // Set its selected property to false
                });

              // Update the files state with the modified files array
              setFiles(JSON.parse(JSON.stringify(files)));

              // Decrement the number of files to be uploaded (showUpload)
              setShowUpload((prev) => prev - 1);
            } else {
              // If the file is not selected
              files
                .filter(({ id }) => id === file.id) // Filter for the file with the same ID
                .forEach((e) => {
                  e.selected = true; // Set its selected property to true
                });

              // Update the files state with the modified files array
              setFiles(JSON.parse(JSON.stringify(files)));

              // Increment the number of files to be uploaded (showUpload)
              setShowUpload((prev) => prev + 1);
            }
          }}
          data-testid="item-container"
        >
          <span className="CategoryIcon">{FileIcon()}</span>
          <span className="ItemTitle">{file.name}</span>
        </div>
      </Tooltip>
    </>
  );
};
export default ItemContainer;

import { Badge, Button, Dropdown, Tooltip } from "antd";
import { useEffect, useState } from "react";
import { PermitDrawingsWhite } from "../../../../../../../../../icons";
import { filterTables } from "../../../../../../../../../utils";
import DocTypesFilesModal from "./doc-types-modal/DocTypesFilesModal";
import { useEmailBox } from "../../../../providers/EmailBoxProvider";
import _ from "lodash";
import { useDocumentationTypes } from "../../../../providers/DocumentationTypesProvider";

import { useEmailTemplates } from "../../../../providers/EmailTemplatesProvider";

import "./documentation-types.scss";
import CustomDropdown from "../../../custom-dropdown/CustomDropdown";
import { useFilePreviewModal } from "../../../../providers/FilePreviewModalProvider";

function DocumentationTypes({ recordDetails }) {
  const { setSelectedFile, setFilePreview } = useFilePreviewModal();
  const [isDocTypesModalOpen, setIsDocTypesModalOpen] = useState(false);
  const [files, setFiles] = useState([]);
  const { documentationTypes, setDocumentationTypes, documentationTypesFiles } =
    useDocumentationTypes();
  const { loading } = useEmailTemplates();

  const count = documentationTypesFiles?.length;

  useEffect(() => {
    // Call the filterTables function with specific parameters to filter documentation types
    filterTables(
      "documentation", // The table to filter from
      "categoryName", // The column to filter by
      // Determine the value for filtering based on the categoryType from recordDetails
      recordDetails?.categoryType === "permitdrawings"
        ? "Permit-Drawings" // If categoryType is "permitdrawings", use "Permit-Drawings"
        : recordDetails?.categoryType === "accounts"
        ? "Clients" // If categoryType is "accounts", use "Clients"
        : recordDetails?.categoryType && // If categoryType exists,
          recordDetails?.categoryType?.charAt(0).toUpperCase() + // Capitalize the first letter of categoryType
            recordDetails?.categoryType?.slice(1) // Add the rest of the string
    )
      .then((res) => {
        return res
          .filter(({ recordId }) => recordId === recordDetails.recordId) // Filter to keep only records matching the current recordId
          .map(({ docType, folderId, googleDriveUploads }) => ({
            docType, // Keep only the necessary fields: docType, folderId, and googleDriveUploads
            folderId,
            googleDriveUploads,
          }));
      })
      .then(async (final) => {
        // Set the final filtered documentation types in the state
        setDocumentationTypes(final);
      });
  }, []); // The empty dependency array ensures this effect runs only once after the initial render

  const items = _.uniqBy(documentationTypes, "folderId").map(
    // Map over unique documentation types based on folderId
    ({ docType, folderId, googleDriveUploads }) => ({
      key: folderId, // Use folderId as the key for each item
      label: (
        <Button
          type="link"
          className="dropdown-item-button" // Assign a CSS class to style the button
          onClick={() => {
            setIsDocTypesModalOpen(true); // Open the document types modal when the button is clicked
            setFiles(googleDriveUploads); // Set the files for the modal using googleDriveUploads
          }}
          data-testid="dropdown-item-label" // Add a data-testid attribute for testing purposes
        >
          {docType} // Display the docType as the button label
        </Button>
      ),
    })
  );

  const toolTipData =
    documentationTypesFiles?.length > 0
      ? documentationTypesFiles?.map(({ name, blob, type, id }) => (
          <p
            key={id}
            style={{ cursor: "pointer" }}
            onClick={() => {
              setSelectedFile({
                src: blob,
                mimeType: type,
              });
              setFilePreview(true);
            }}
            data-testid={`drive-document-item-${id}`}
          >
            {name}
          </p>
        ))
      : "Documentation Types";

  if (loading)
    return (
      <div className="documentation-types-loading-container documentation-types-container">
        <Badge
          status="processing"
          className="documentation-types-loading-badge"
        />
        <PermitDrawingsWhite className="documentation-types-icon" />
      </div>
    );

  return (
    <div className="documentation-types-container">
      <Tooltip placement="left" title={toolTipData}>
        <Badge count={count}>
          <CustomDropdown items={items} placement="top" trigger={["click"]}>
            <a
              onClick={(e) => e.preventDefault()}
              data-testid="documentation-types-dropdown-trigger"
            >
              <PermitDrawingsWhite className="documentation-types-icon" />
            </a>
          </CustomDropdown>
        </Badge>
      </Tooltip>

      {isDocTypesModalOpen && (
        <DocTypesFilesModal
          isDocTypesModalOpen={isDocTypesModalOpen}
          setIsDocTypesModalOpen={setIsDocTypesModalOpen}
          files={files}
        />
      )}
    </div>
  );
}

export default DocumentationTypes;

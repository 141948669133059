import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { API } from "aws-amplify";

import { fetchData } from "../../../SidebarPages/Fleet/utils";

export const useVirtualAssistantConfig = (configId = "") => {
  const [virtualAssistantData, setVirtualAssistantData] = useState([]);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const configData =
    virtualAssistantData.find((config) => config.configId === configId)
      ?.configData || [];

  useEffect(() => {
    fetchData("virtual-assistant")
      .then((result) => {
        setVirtualAssistantData(result);
      })
      .catch((err) => {
        console.log("Error fetching virtual assistant config:", err);
      });
  }, []);

  const saveVirtualAssistantConfig = async (newData) => {
    const updatedData = {
      ...newData,
      id: uuidv4(),
      nameOfUser: userConfiguration?.nameOfUser,
      identityId: userConfiguration?.identityId,
      app: "Lead Manager",
    };

    await API.put("virtual-assistant", `/virtual-assistant/${configId}`, {
      body: { configData: [...configData, updatedData] },
    })
      .then((result) => {
        setVirtualAssistantData(
          virtualAssistantData.map((item) =>
            item.configId === configId
              ? { ...item, configData: result.configData }
              : item
          )
        );
      })
      .catch((err) => {
        console.log({ err });
      });
  };

  return {
    configData,
    virtualAssistantData,
    saveVirtualAssistantConfig,
  };
};

import { API } from "aws-amplify";
import { apiRoutes } from "../../../../SidebarPages/Fleet/utils";
import dayjs from "dayjs";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { requestNotifRedirect } from ".";

/**
 * Creates a request.
 *
 * @param {WebSocket} ws - The WebSocket connection.
 * @param {Object} tmpBody - The temporary body for the request.
 * @param {string} requestType - The type of the request.
 * @param {Function} setVisible - The function to set the visibility of the request form modal.
 * @param {Object} userConfiguration - The user configuration.
 * @param {Function} fileHandler - The function to handle files.
 * @param {Function} updateProgressStatus - The function to update the progress status.
 * @param {Function} setVisibleCreationProgress - The function to set the visibility of the creation progress.
 * @param {Function} saveAddedLogs - The function to save added logs.
 * @returns {Promise<void>} - A promise that resolves when the request is created.
 */
const createRequest = async ({
  ws = null,
  tmpBody = null,
  requestType = "",
  setVisible = () => {},
  userConfiguration = {},
  fileHandler = () => {},
  updateProgressStatus = () => {},
  setVisibleCreationProgress = () => {},
  saveAddedLogs,
}) => {
  setVisibleCreationProgress(true);
  updateProgressStatus({ updatingRecord: "executing" });

  await API.post(apiRoutes.formRequests, `/${apiRoutes.formRequests}`, {
    body: tmpBody,
  })
    .then(async (res) => {
      setVisibleCreationProgress(res);

      updateProgressStatus({
        updatingRecord: "finished",
        sendingNotification: "executing",
      });

      fileHandler();

      /**
       * Redirect logic for notification redirect any special cases should be handled in this function
       */
      const notifRedirect = requestNotifRedirect(res?.category, res?.recordId);

      ws.send(JSON.stringify({ request: "new", body: res }));
      await saveAddedLogs({
        recordId: res?.requestId,
        recordName: tmpBody?.requestObject?.recordName,
        category: "Approvals",
        topic: requestType,
      });

      // await broadcastNotification(
      broadcastNotification(
        "13",
        "onCreation",
        [
          {
            user: userConfiguration?.nameOfUser,
            common: requestType,
            commonNext: userConfiguration?.allUsers?.Items?.find(
              (el) => el?.cognitoUserId === res?.assignedTo
            )?.nameOfUser,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
            selectedId: res?.requestedId,
          },
        ],
        notifRedirect
      )
        .then((notifSent) =>
          updateProgressStatus({
            sendingNotification: !!notifSent ? "finished" : "hasError",
          })
        )
        .catch((err) => console.error("ERROR SENDING NOTIFICATION : ", err));

      setVisible(false);
    })
    .catch((err) => {
      setVisible(true);
      updateProgressStatus({ updatingRecord: "hasError" });
      console.error("ERROR CREATING REQUEST : ", err);
    });
};

export default createRequest;

import { filterTables } from "../filterTables";

export const programFieldsParameters = async () => {
  try {
    const data = (
      await filterTables(
        "programFields",
        "fieldName",
        "Report Parameters Config"
      )
    )[0].fieldOptions;

    return (
      data?.map((obj) => {
        return {
          id: obj.id,
          name: obj.name,
          dateFrom: eval(obj.dateFrom),
          dateTo: eval(obj.dateTo),
        };
      }) || []
    );
  } catch (error) {
    console.error(
      "data source function programFieldsParameters error: ",
      error
    );
  }
};

import { useSelector } from "react-redux";
import { debounce, uniqBy } from "lodash";
import { MenuOutlined } from "@ant-design/icons";
import { ChangeEvent, useContext, useMemo, useRef, useState } from "react";

import {
  TeamSidebarItem,
  JobsiteSidebarItem,
  EmployeeSidebarItem,
  ScheduleSidebarItem,
} from "..";
import {
  CrewTeamType,
  ScheduleType,
  EmployeeReportType,
} from "../../payrollLiveTypes";
import { alphabeticalSorting } from "../../utils";
import { DownArrowIcon, UpArrowIcon } from "src/assets";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "src/components/commonComponents";
import {
  StoreType,
  JobsiteType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";

import "./PayrollLiveMapSidebar.scss";

const azSortStates: Array<boolean | "reverse"> = [true, "reverse", false];
const empStatusSort: Array<string | false> = [false, "In", "Out", "No Punch"];
const statusColors = {
  In: "#00a464",
  Out: "#787677",
  ["No Punch"]: "#f04f4e",
};

function PayrollLiveMapSidebar() {
  const {
    schedules,
    setFilters,
    filteredCrews,
    employeesReport,
    filteredEmployees,
    filteredSchedules,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);

  const selectedSidebarData = sessionStorage.getItem("sidebarSelectedData");

  const [toggleOpen, setToggleOpen] = useState<boolean>(true);
  const [inputValue, setInputValue] = useState<string | null>(null);
  const [azSorting, setAzSorting] = useState<boolean | "reverse">(false);
  const [statusSorting, setStatusSorting] = useState<string | false>(false);
  const [selectedEmployees, setSelectedEmployees] = useState<Array<string>>([]);
  const [selectedData, setSelectedData] = useState<
    "employees" | "schedules" | "crews" | string
  >(selectedSidebarData || "schedules");

  const inputRef = useRef(null);
  const dataHolderRef = useRef(null);
  const controlPanelRef = useRef(null);

  const sidebarData = useMemo(() => {
    const teamsIncluded = uniqBy(employeesReport, (el) => el?.crewTeamId).map(
      (el) => el.crewTeamId
    );
    let crews = [];
    for (let i = 0; i < filteredCrews.length; i++) {
      const team = filteredCrews[i];
      if (teamsIncluded.includes(team?.crewTeamId)) {
        let teamMembers = team.crewMembers.map((el) =>
          Number(el?.employeeId?.replace("GMNY Construction-", ""))
        );
        const teamObj = {
          crewTeamId: team?.crewTeamId,
          crewForeman: team?.crewForeman,
          crewMembers: team?.crewMembers,
          crewTeamName: team?.crewTeamName,
          members: filteredEmployees.flatMap((el) =>
            teamMembers.includes(Number(el?.employeeNumber)) ? el : []
          ),
        };
        crews.push(teamObj);
      }
    }

    return {
      crews,
      employees: filteredEmployees,
      schedules: filteredSchedules,
    };
  }, [filteredCrews, employeesReport, filteredSchedules, filteredEmployees]);

  function onSearch(inputVal: string) {
    if (selectedData === "schedules") {
      const selectedSchedules = schedules.flatMap(
        (sch: ScheduleType & { jobAddress: string }) =>
          !!inputVal &&
          (sch?.scheduleAddress || sch?.jobAddress)
            .toLowerCase()
            .includes(inputVal.toLowerCase())
            ? sch.projectId
            : []
      );
      const empMatchOfSchedules = employeesReport.flatMap((emp) =>
        selectedSchedules.includes(emp?.projectId) ? emp.employeeNumber : []
      );

      setFilters((prev) => ({
        ...prev,
        scheduleAddress: inputVal,
        employeeNumber: empMatchOfSchedules,
      }));
    } else if (selectedData === "employees") {
      setFilters((prev) => ({ ...prev, employeeSearch: inputVal }));
    } else if (selectedData === "crews") {
      setFilters((prev) => ({ ...prev, crewSearch: inputVal }));
    }
  }

  function onClearSearch() {
    setFilters((prev) => ({
      ...prev,
      scheduleAddress: "",
      employeeSearch: "",
      employeeNumber: [],
    }));
    setInputValue("");
  }

  function changeSelectedData(
    selectedData: "schedules" | "employees" | "crews"
  ) {
    setSelectedData(selectedData);
    sessionStorage.setItem("sidebarSelectedData", selectedData);
    onClearSearch();
  }

  function toggleSidebar() {
    setToggleOpen((prev) => {
      if (!prev) {
        const animation = [
          {
            opacity: 0,
          },
          {
            opacity: 1,
          },
        ];
        const timing = {
          duration: 300,
          rangeStart: "0%",
          rangeEnd: "100%",
          transition: "ease-put",
        };
        controlPanelRef.current?.animate?.(animation, timing);
        dataHolderRef.current?.animate?.(animation, timing);
      }
      return !prev;
    });
  }

  function azSortTrigger() {
    setAzSorting((prev) => {
      return azSortStates[
        (azSortStates.indexOf(prev) + 1) % azSortStates?.length
      ];
    });
  }

  function statusSortTrigger() {
    setStatusSorting(
      (prev) =>
        empStatusSort[(empStatusSort.indexOf(prev) + 1) % empStatusSort?.length]
    );
  }

  const totalCounts = useMemo(() => {
    return {
      crews: `Total Crews on Site ${sidebarData?.crews?.length || 0}`,
      schedules: `Total Working Jobs ${sidebarData?.schedules?.length || 0}`,
      employees: `Total Employees on Site ${
        sidebarData?.employees.filter((el) => el.liveStatus !== "No Punch")
          ?.length || 0
      }`,
    };
  }, [JSON.stringify(sidebarData)]);

  const renderedItems = useMemo(() => {
    const uniqueItems = new Set();

    const sortKeys = {
      crews: "crewTeamName",
      employees: "employeeName",
      schedules: "scheduleAddress",
    };

    const dataList = sidebarData?.[selectedData] || [];
    let sortedData = [...dataList];

    if (azSorting) {
      sortedData = alphabeticalSorting({
        array: dataList,
        key: sortKeys[selectedData],
        reverse: azSorting === "reverse",
      });
    }

    if (statusSorting) {
      sortedData = sortedData.sort((a) =>
        a?.liveStatus === statusSorting ? -1 : 0
      );
    }

    return sortedData.flatMap(
      (
        data: ScheduleType &
          JobsiteType &
          EmployeeReportType &
          CrewTeamType & { members: Array<EmployeeReportType> }
      ) => {
        let item = null;

        if (selectedData === "schedules") {
          if (data?.jobsiteId && !uniqueItems.has(data.jobsiteId)) {
            uniqueItems.add(data.jobsiteId);
            item = <JobsiteSidebarItem key={data.jobsiteId} {...data} />;
          } else if (data?.scheduleId && !uniqueItems.has(data.scheduleId)) {
            uniqueItems.add(data.scheduleId);
            item = <ScheduleSidebarItem key={data.scheduleId} {...data} />;
          }
        } else if (selectedData === "crews") {
          if (data?.crewTeamId && !uniqueItems.has(data.crewTeamId)) {
            uniqueItems.add(data.crewTeamId);
            item = (
              <TeamSidebarItem
                key={data.crewTeamId}
                {...{ ...data, selectedEmployees, setSelectedEmployees }}
              />
            );
          }
        } else if (data?.employeeId && !uniqueItems.has(data.employeeId)) {
          uniqueItems.add(data.employeeId);
          item = (
            <EmployeeSidebarItem
              key={data.employeeId}
              {...{ ...data, selectedEmployees, setSelectedEmployees }}
            />
          );
        }

        return item ? [item] : [];
      }
    );
  }, [
    azSorting,
    sidebarData,
    selectedData,
    statusSorting,
    selectedEmployees,
    setSelectedEmployees,
  ]);

  return (
    <div
      className={`map-sidebar ${darkMode ? "map-sidebar-dark" : ""} ${
        toggleOpen ? "open-sidebar" : "closed-sidebar"
      }`}
    >
      <div className="sidebar-control-panel" ref={controlPanelRef}>
        <div className="data-type-select">
          <div className="menu-icon" onClick={toggleSidebar}>
            <MenuOutlined />
          </div>
          {toggleOpen ? (
            <div className="sidebar-tabs">
              <div
                className={`tab-item ${
                  selectedData === "schedules" ? "active" : ""
                }`}
                onClick={() => changeSelectedData("schedules")}
              >
                Schedules
              </div>
              <div
                className={`tab-item ${
                  selectedData === "crews" ? "active" : ""
                }`}
                onClick={() => changeSelectedData("crews")}
              >
                Crews
              </div>
              <div
                className={`tab-item ${
                  selectedData === "employees" ? "active" : ""
                }`}
                onClick={() => changeSelectedData("employees")}
              >
                Employees
              </div>
            </div>
          ) : null}
        </div>
        <div className="search-field">
          <div className="sorting-buttons">
            <MondayButton
              onClick={azSortTrigger}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonGrey"}
              hasIcon={!!azSorting}
              Icon={
                azSorting ? (
                  azSorting === "reverse" ? (
                    <DownArrowIcon />
                  ) : (
                    <UpArrowIcon />
                  )
                ) : null
              }
            >
              A-Z
            </MondayButton>
            <MondayButton
              onClick={statusSortTrigger}
              className={darkMode ? "mondayButtonBlue" : "mondayButtonGrey"}
              hasIcon={false}
            >
              {statusSorting ? (
                <span
                  style={{
                    width: 8,
                    height: 8,
                    display: "block",
                    borderRadius: "50%",
                    backgroundColor: statusColors?.[statusSorting as string],
                  }}
                />
              ) : null}
              {statusSorting || "Status"}
            </MondayButton>
          </div>
          {toggleOpen ? (
            <InputComponent
              allowClear
              type="input"
              value={inputValue}
              inputRef={inputRef}
              placeholder="Search..."
              onChange={(e: ChangeEvent<HTMLInputElement>) => {
                setInputValue(e.target.value);
                debounce(onSearch, 600)(e.target.value);
              }}
            />
          ) : null}
          {toggleOpen ? (
            <div className="data-total-count">
              {totalCounts?.[selectedData] || ""}
            </div>
          ) : null}
        </div>
      </div>
      <div className="data-holder-container" ref={dataHolderRef}>
        {toggleOpen ? renderedItems : null}
      </div>
    </div>
  );
}

export default PayrollLiveMapSidebar;

export function convertToTitleCase(str) {
  return str.replace(/([A-Z])/g, " $1").replace(/^./, function (s) {
    return s.toUpperCase();
  });
}

export function convertKeyToTitleCase(key) {
  const words = key.split(/(?=[A-Z])/);
  const formattedWords = words.map((word) =>
    convertToTitleCase(word.toLowerCase())
  );
  return formattedWords.join(" ");
}

export function flattenObject(obj) {
  let result = {};

  function recurse(curr, prop) {
    if (Object(curr) !== curr || Array.isArray(curr)) {
      result[prop] = curr;
    } else {
      for (const p in curr) {
        if (curr.hasOwnProperty(p)) {
          recurse(curr[p], prop ? p + "." + prop : p);
        }
      }
    }
  }

  recurse(obj, "");
  return result;
}

export const convertMultiLevelLogsToOneLevelLogs = (logs) => {
  const finalObject = logs.flatMap((log) => {
    const { previousData, currentData } = log;
    const keys = Object.keys(previousData);

    return keys.flatMap((key) => {
      const previousValue = flattenObject(previousData[key]);
      const currentValue = flattenObject(currentData[key]);

      return Object.keys(previousValue).map((prevKey) => {
        return {
          previousData: previousValue[prevKey],
          currentData: currentValue[prevKey],
          logId: log?.logId,
          nameOfUser: log?.nameOfUser,
          topic: log?.topic,
          label: log?.label,
          recordId: log?.recordId,
          recordName: log?.recordName,
          userId: log?.userId,
          cognitoUserId: log?.cognitoUserId,
          updatedAt: log?.updatedAt,
          actionType: log?.actionType,
          category: log?.category,
          updatedKey: convertKeyToTitleCase(prevKey === "" ? key : prevKey),
        };
      });
    });
  });

  return finalObject;
};

export const logsColumnDefs = [
  { fieldName: "member", fieldTitle: "User" },
  { fieldName: "details", fieldTitle: "Action" },
  { fieldName: "updatedKey", fieldTitle: "Updated Key" },
  { fieldName: "previousData", fieldTitle: "Previous Data" },
  { fieldName: "currentData", fieldTitle: "Current Data" },
  { fieldName: "updatedAt", fieldTitle: "Date & Time" },
].filter(Boolean);

import axios from "axios";
import { base64ToPdf } from "./base64ToPdf";
import { message } from "antd";

const apiUrl = `https://leadmanager-sockets.ue.r.appspot.com/api`;
// const apiUrl = `http://localhost:8080/api`;

export const recordInfoToPdf = async ({ title, data }) => {
  try {
    if (Object.keys(data).length < 1) {
      message.warning("Data to export does not contain any key!");
      return;
    }

    message.loading("Exporting...");

    return await axios
      .post(`${apiUrl}/recordInfoViewToPdf`, { title, data })
      .then(({ data: base64 }) => {
        base64ToPdf(base64, title);
        message.destroy();
        message.success("Exported successfully!");
      });
  } catch (error) {
    console.error({ error });
  }
};

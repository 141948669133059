import { useEffect, useMemo, useRef, useState } from "react";
import NearbyPlaces from "./NearbyPlaces";
import "./HospitalsNearby.scss";
import { InputComponent } from "../../Fleet/components";
import { MondayButton } from "../../../commonComponents";
import { ReportCheck } from "../../Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import { message, Segmented } from "antd";
import { TickIcon } from "../../../InternalChat/assets/icons";
import { useSelector } from "react-redux";
import { getNearbyPlacesWithDetails } from "./helpers";

function convertToMiles(meters) {
  const miles = meters * 0.000621371;
  return Math.round(parseFloat(miles));
}
function convertToMeters(miles) {
  const meters = miles * 1609.34;
  const round = Math.round(parseFloat(meters));
  return round;
}

function HospitalsNearby({
  initialValues,
  modifiedValues,
  filters,
  setFilters,
  setInitialValues,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [urgentCares, setUrgentCares] = useState([]);
  const [radius, setRadius] = useState(convertToMiles(1609));
  const [radiusChanged, setRadiusChanged] = useState(false);
  const [showMap, setShowMap] = useState(false);
  const [loading, setLoading] = useState(false);
  const [filteredBy, setFilteredBy] = useState("Distance");

  const initialHospitals = useMemo(() => {
    const sectionIndex = initialValues?.findIndex(
      (item) => item?.title === "Hospitals Nearby"
    );
    const hospitals = initialValues[sectionIndex]?.body;
    return {
      hospitals,
      index: sectionIndex,
    };
  }, [initialValues]);

  useEffect(() => {
    if (
      !!initialHospitals?.hospitals &&
      Array.isArray(initialHospitals?.hospitals)
    ) {
      setUrgentCares(initialHospitals?.hospitals || []);
    }
  }, [initialHospitals?.hospitals]);

  const rowData = useMemo(
    () => initialValues?.find((item) => item?.title === "rowData")?.body,
    [initialValues]
  );
  const coordinates = {
    lat: rowData?.projectLatitude,
    lng: rowData?.projectLongitude,
  };

  async function newRadiusPlaces() {
    const newRadius = convertToMeters(radius);
    const numberRadius = Number(newRadius);

    setLoading(true);
    const newPlaces = await getNearbyPlacesWithDetails(
      coordinates,
      numberRadius,
      "hospital"
    );
    if (!newPlaces || newPlaces?.length === 0) {
      message.error("No places found within the radius");
      setLoading(false);
      return;
    }
    const sortedPlaces = newPlaces?.sort((a, b) =>
      sortHandler(a, b, filteredBy)
    );
    setLoading(false);
    setInitialValues((prev) => {
      const newModifiedValues = [...prev];
      newModifiedValues[initialHospitals.index].body = sortedPlaces;
      return newModifiedValues;
    });
    setRadiusChanged(false);
  }

  function onLocationClick(placeId) {
    setInitialValues((prev) => {
      const newModifiedValues = [...prev];
      const index = initialHospitals.index;
      const removedAddresses = newModifiedValues[index].removedAddresses;
      if (removedAddresses.includes(placeId)) {
        newModifiedValues[index].removedAddresses = removedAddresses.filter(
          (el) => el !== placeId
        );
        if (newModifiedValues[index].removedAddresses?.length === 0) {
          newModifiedValues[index].showAll = true;
        }
        return newModifiedValues;
      } else {
        newModifiedValues[index].removedAddresses = [
          ...removedAddresses,
          placeId,
        ];
        if (
          newModifiedValues[index].removedAddresses.length ===
          urgentCares?.length
        ) {
          newModifiedValues[index].showAll = true;
        }
      }
      return newModifiedValues;
    });
  }

  function sortHandler(a, b, filteredBy) {
    if (filteredBy === "Distance") {
      return (
        a.distance.driving.distance.value - b.distance.driving.distance.value
      );
    } else if (filteredBy === "Duration") {
      return (
        a.distance.driving.duration.value - b.distance.driving.duration.value
      );
    }
  }

  return (
    <div className={`nearby-places ${isDarkMode ? "nearby-places-dark" : ""}`}>
      <div style={{ display: "flex", gap: 20, alignItems: "flex-end" }}>
        <InputComponent
          {...{
            typeNumber: true,
            label: "Enter Radius (in miles)",
            noFormItem: true,
            initialValue: radius,
            onChange: (e) => {
              let value = e.target.value;
              if (value !== radius) {
                setRadius(value);
                setRadiusChanged(true);
              }
            },
            style: { width: "200px" },
          }}
        />
        <MondayButton
          {...{
            loading,
            disabled: !radiusChanged,
            onClick: newRadiusPlaces,
            Icon: <TickIcon />,
          }}
        >
          Change Radius
        </MondayButton>
      </div>
      <ReportCheck
        {...{
          label: "Show Map",
          checked: showMap,
          onChange: (e) => setShowMap(e),
        }}
      />
      <ReportCheck
        {...{
          label: "Select All Locations",
          checked: initialValues[initialHospitals.index]?.showAll,
          onChange: (e) =>
            setInitialValues((prev) => {
              const index = initialHospitals.index;
              prev[index].showAll = e;
              if (e) {
                prev[index].removedAddresses = [];
              } else {
                prev[index].removedAddresses = [
                  ...urgentCares.map((place) => place.place_id),
                ];
              }
              return [...prev];
            }),
          indeterminate:
            initialValues[initialHospitals.index]?.removedAddresses?.length >
              0 &&
            initialValues[initialHospitals.index]?.removedAddresses?.length <
              urgentCares.length,
        }}
      />

      <NearbyPlaces
        {...{
          coordinates,
          addressName: rowData?.projectName,
          urgentCares,
          showMap,
        }}
      />

      {urgentCares.length > 0 && (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          <div>Sort By Driving: </div>
          <Segmented
            {...{
              style: { width: "fit-content" },
              options: ["Distance", "Duration"],
              value: filteredBy,
              onChange: (e) => {
                setFilteredBy(e);
                setInitialValues((prev) => {
                  const index = initialHospitals.index;
                  prev[index].body = prev[index].body.sort((a, b) =>
                    sortHandler(a, b, e)
                  );
                  return [...prev];
                });
              },
            }}
          />
          {urgentCares.length === 20 ? (
            <div>
              These are the first 20 hospitals found within the specified
              radius.
            </div>
          ) : (
            <div>
              {" "}
              {urgentCares.length} Hospitals found within the specified radius
            </div>
          )}
        </div>
      )}
      <div className="places-container">
        {urgentCares?.length > 0 ? (
          urgentCares.map((place) => {
            return (
              <div className={`place-card`} key={place.place_id}>
                <ReportCheck
                  {...{
                    label: <h4>{place.name}</h4>,
                    checked: !initialValues[
                      initialHospitals.index
                    ]?.removedAddresses?.includes(place.place_id),
                    onChange: (e) => onLocationClick(place.place_id),
                  }}
                />
                {/* 
                <div>{place?.address || "Address Not Found"}</div>
                <div>
                  Driving: Distance:{" "}
                  {place.distance?.driving?.distance?.text || "Not Available"},{" "}
                  Duration:{" "}
                  {place.distance?.driving?.duration?.text || "Not Available"}
                </div>
                <div>
                  Walking: Distance:{" "}
                  {place.distance?.walking?.distance?.text || "Not Available"},{" "}
                  Duration:{" "}
                  {place.distance?.walking?.duration?.text || "Not Available"}
                </div> */}
              </div>
            );
          })
        ) : (
          <div>No Hospitals Within the Radius. Try increasing the radius</div>
        )}
      </div>
    </div>
  );
}

export default HospitalsNearby;

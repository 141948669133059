export const employeeSpreadsheetHeaders = (employeeType = null) => {
  const columnHeaders = {
    crews: [
      "Employee Number",
      "Employee List",
      "Role",
      "Rate",
      "Crew",
      "Foreman",
    ],
    engineers: ["First Name", "Last Name", "Email", "Pay Rate", "Salary Type"],
    architects: ["First Name", "Last Name", "Email", "Pay Rate", "Salary Type"],
    drivers: ["Full Name", "Driver Type"],
  };

  if (!employeeType) {
    return columnHeaders;
  }

  return columnHeaders[employeeType] || [];
};

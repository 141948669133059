import { Tooltip } from "antd";
import { LogsButton, MondayButton } from "../../../../../commonComponents";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";

// this simple function is made with for loop for performance reasons
export function formatMembersOptions(allMembers, busyMembers, rowToEdit) {
  const onlyMembers = [];

  for (let i = 0; i < allMembers?.length; i++) {
    const member = allMembers[i];

    if (typeof member?.foreman === "string" || !member?.foreman) {
      let foundBusyMember = null;
      for (let j = 0; j < busyMembers.length; j++) {
        if (member?.crewId === busyMembers[j]?.crewId) {
          foundBusyMember = busyMembers[j];
          break;
        }
      }

      let foundInRowToEdit = null;
      if (rowToEdit && rowToEdit?.crewMembers) {
        for (let k = 0; k < rowToEdit?.crewMembers?.length; k++) {
          if (member?.crewId === rowToEdit?.crewMembers[k]?.crewId) {
            foundInRowToEdit = rowToEdit?.crewMembers[k];
            break;
          }
        }
      }

      if (foundBusyMember && !foundInRowToEdit) {
        onlyMembers.push(Object.assign(member, { disabled: true }));
      } else {
        onlyMembers.push(member);
      }
    }
  }
  return onlyMembers;
}

export function footerButtons({
  onCancel = () => {},
  onSave = () => {},
  editMode = false,
  cancelRef,
  saveRef,
  rowToEdit,
}) {
  return [
    <>
      <div className="createCrewFooter">
        <div ref={cancelRef}>
          <MondayButton
            {...{
              Icon: <XIcon />,
              className: "mondayButtonRed",
              onClick: onCancel,
            }}
          >
            Cancel
          </MondayButton>
        </div>
        <div ref={saveRef} className="editCrewFooter">
          {editMode && (
            <Tooltip title="Show logs">
              <LogsButton
                {...{
                  buttonLabel: "Logs",
                  logsTitle: `${rowToEdit?.crewTeamName} Logs`,
                  filtersObj: { recordId: rowToEdit?.crewTeamId },
                }}
              />
            </Tooltip>
          )}
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onSave,
            }}
          >
            Save
          </MondayButton>
        </div>
      </div>
    </>,
  ];
}

export function crewTeamModalFields({
  foremanMembers = [],
  members = [],
  companies = [],
  selectedForeman = false,
  setSelectedForeman = () => {},
}) {
  return [
    {
      label: "Crew Name",
      type: "input",
      placeholder: "Enter here...",
      formItemName: "crewTeamName",
      className: "crewTeamInput",
      required: true,
    },
    {
      label: "Subcontractor",
      type: "select",
      placeholder: "Select company...",
      formItemName: "company",
      className: "crewTeamInput",
      required: true,
      getPopupContainer: document.body,
      customOptions: companies.map(({ accountName = "" }, key) => ({
        key,
        value: accountName,
        label: accountName,
      })),
    },
    {
      label: "Foreman",
      type: "select",
      onSelect: (e) => setSelectedForeman(JSON.parse(e)),
      placeholder: "Select foreman...",
      formItemName: "crewForeman",
      className: "crewTeamInput",
      required: true,
      getPopupContainer: document.body,
      filterOption: (input, option) => {
        return (option?.label?.key || "")
          .toLowerCase()
          .includes((input || "").toLowerCase());
      },
      customOptions: foremanMembers.map(
        ({ crewId = "", crewName = "", employeeId = "" }, key) => ({
          key,
          value: JSON.stringify({ crewId, crewName, employeeId }),
          label: (
            <div
              key={crewName}
              style={{
                width: "100%",
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
              }}
            >
              <div>{crewName}</div>
              {!!employeeId?.length && <div>({employeeId})</div>}
            </div>
          ),
        })
      ),
    },
    // {
    //   label: "Members",
    //   type: "styledmultiselect",
    //   mode: "multiple",
    //   disabled: !selectedForeman,
    //   formItemName: "crewMembers",
    //   required: true,
    //   placeholder: "Chose members...",
    //   getPopupContainer: document.body,
    //   filterOption(input, option) {
    //     return (option?.name || "")
    //       ?.toLowerCase()
    //       .includes((input || "")?.toLowerCase());
    //   },
    //   customOptions: members.map(
    //     (
    //       { crewId = "", crewName = "", disabled = false, employeeId = "" },
    //       key
    //     ) => ({
    //       key,
    //       value: JSON.stringify({ crewId, crewName, employeeId }),
    //       name: crewName,
    //       label: (
    //         <div
    //           style={{
    //             width: "100%",
    //             display: "flex",
    //             alignItems: "center",
    //             justifyContent: "space-between",
    //           }}
    //         >
    //           <div>{crewName}</div>
    //           {!!employeeId?.length && <div>({employeeId})</div>}
    //         </div>
    //       ),
    //       disabled: disabled,
    //     })
    //   ),
    // },
  ];
}

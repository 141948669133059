import { Modal, Switch } from "antd";
import React, { useState, useContext } from "react";

import DegModalContext from "../DegModalContext";
import { OVERHEAD_ENTRY_TYPES } from "../../degModalData";
import { updateDocumentTitle } from "../../../../../../../../utils";
import { MondayButton } from "../../../../../../../commonComponents";
import { EyeIcon } from "../../../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";

import "./DegWarningsModal.scss";

function DegWarningsModal({
  open,
  onCancel,
  setOtNamesModal,
  setNotFoundModal,
  setLocationsModal,
  degExternalFilter,
  setRcmdShiftsToSplit,
  updateExternalFilter,
}) {
  const {
    notFound,
    emptyData,
    analytics,
    degGridApi,
    isDarkMode,
    incorrectData,
    warningFilters,
    updateWarningFilters,
  } = useContext(DegModalContext);

  const [shiftsFiltersDisabled, setShiftsFiltersDisabled] = useState(false);

  const {
    hrHighlighted,
    incorrectHighlighted,
    incompleteHighlighted,
    missingLunchHighlighted,
    overheadEntriesHighlighted,
  } = warningFilters;

  const {
    missingLunchEntries,
    // employeeOver24HShift,
    employeeNotFoundLocations,
  } = analytics;

  let noOfNotFound = 0;
  const notFoundIds = Object.keys(notFound).filter(
    (key) => key !== "noEmployeeId"
  );
  noOfNotFound = notFoundIds?.length + notFound["noEmployeeId"]?.length;
  const singleNotFnd = noOfNotFound === 1;

  const singleLoc = employeeNotFoundLocations.length === 1;

  // for (const key in employeeOver24HShift) {
  //   if (employeeOver24HShift[key].length) {
  //     over24H = true;
  //   }
  // }

  let hrRows = 0;
  let overheadRows = 0;
  if (degGridApi) {
    degGridApi.forEachNode(({ data }) => {
      if (data?.punchType === "HR") {
        hrRows++;
      }
      if (OVERHEAD_ENTRY_TYPES.includes(data?.punchType)) {
        overheadRows++;
      }
    });
  }

  let otCondition = false;
  for (const employeeId in analytics.employeeWeekTotals) {
    let found = false;
    for (const week in analytics.employeeWeekTotals[employeeId]) {
      let entry = analytics.employeeWeekTotals[employeeId][week];
      if (entry.otAmountWeekly > 0) {
        otCondition = true;
        found = true;
        break;
      }
    }
    if (found) break;
  }

  return (
    <Modal
      {...{
        open,
        closable: true,
        onCancel: () => {
          onCancel();
          updateDocumentTitle(); // reset document title to "Lead Manager";
        },
        centered: true,
        closeIcon: <XIcon />,
        footer: null,
        className: `degWarningsModal ${isDarkMode && "degWarningsModalDark"}`,
        wrapClassName: "degWarningsModalWrap",
        ["data-testid"]: "deg-warnings-modal",
        title: "DEG Warnings",
        afterOpenChange: (event) => {
          event && updateDocumentTitle({ newTitle: "DEG Warnings" });
        },
      }}
    >
      <div className="sectionTitle">
        <strong>Warnings</strong>
      </div>
      <div className="checkEntry">
        <span>{`There ${
          singleNotFnd ? "is" : "are"
        } ${noOfNotFound} unregistered employee${
          singleNotFnd ? "" : "s"
        } `}</span>
        <MondayButton
          {...{
            Icon: <EyeIcon />,
            className: "mondayButtonRed",
            disabled: !noOfNotFound,
            onClick() {
              setNotFoundModal(true);
            },
          }}
        >
          {`See employee${singleNotFnd ? "" : "s"}`}
        </MondayButton>
      </div>
      <div className="checkEntry">
        <span>{`There ${singleLoc ? "is" : "are"} ${
          analytics.employeeNotFoundLocations.length
        } not found location${singleLoc ? "" : "s"}`}</span>
        <MondayButton
          {...{
            Icon: <EyeIcon />,
            className: "mondayButtonRed",
            disabled: !analytics.employeeNotFoundLocations.length,
            onClick() {
              setLocationsModal(true);
            },
          }}
        >
          {`See location${singleNotFnd ? "" : "s"}`}
        </MondayButton>
      </div>
      <div className="checkEntry">
        <span>
          {otCondition
            ? "Some of the employees made overtime"
            : "No employees made overtime"}
        </span>
        <MondayButton
          {...{
            Icon: <EyeIcon />,
            disabled: !otCondition,
            className: "mondayButtonBlue",
            onClick() {
              setOtNamesModal(true);
            },
          }}
        >
          See overtime data
        </MondayButton>
      </div>
      <div className="checkEntry">
        <span>
          See recommended shifts to split (
          {analytics?.shiftsWithMultipleJobsites?.length})
        </span>
        <MondayButton
          {...{
            Icon: <EyeIcon />,
            className: "mondayButtonBlue",
            onClick() {
              setRcmdShiftsToSplit(true);
            },
          }}
        >
          Shifts To Split
        </MondayButton>
      </div>
      <div className="sectionTitle">
        <strong>Highlight</strong>
      </div>
      <div className="checkEntry">
        <span>{`Highlight Incomplete Entries (${emptyData?.length} entries) `}</span>
        <Switch
          defaultChecked={incompleteHighlighted}
          onChange={() => {
            updateWarningFilters("INCOMPLETE");
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Highlight Incorrect Shifts (${incorrectData?.length} entries) `}</span>
        <Switch
          defaultChecked={incorrectHighlighted}
          onChange={() => {
            updateWarningFilters("INCORRECT");
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Highlight HR Shifts (${hrRows} entries) `}</span>
        <Switch
          defaultChecked={hrHighlighted}
          onChange={() => {
            updateWarningFilters("HR");
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Highlight CASH/1099 Shifts (${overheadRows} entries) `}</span>
        <Switch
          defaultChecked={overheadEntriesHighlighted}
          onChange={() => {
            updateWarningFilters("OVERHEAD");
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Highlight Missing Lunch Entries (${missingLunchEntries.length} entries) `}</span>
        <Switch
          defaultChecked={missingLunchHighlighted}
          onChange={() => {
            updateWarningFilters("MISSINGLUNCH");
          }}
        />
      </div>
      <div className="sectionTitle">
        <strong>Filters</strong>
      </div>
      <div className="checkEntry">
        <span>{`See Incomplete Entries `}</span>
        <Switch
          defaultChecked={degExternalFilter.showIncomplete}
          disabled={shiftsFiltersDisabled}
          onChange={(e) => {
            updateExternalFilter({
              showIncomplete: e,
            });
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`See Incorrect Shifts `}</span>
        <Switch
          defaultChecked={degExternalFilter.showIncorrect}
          disabled={shiftsFiltersDisabled}
          onChange={(e) => {
            updateExternalFilter({
              showIncorrect: e,
            });
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`See HR Entries `}</span>
        <Switch
          defaultChecked={degExternalFilter.showHr}
          disabled={shiftsFiltersDisabled}
          onChange={(e) => {
            updateExternalFilter({
              showHr: e,
            });
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Show Only Incomplete Entries `}</span>
        <Switch
          defaultChecked={degExternalFilter.showOnlyIncomplete}
          disabled={
            shiftsFiltersDisabled !== "INCOMPLETE" && !!shiftsFiltersDisabled
          }
          onChange={(e) => {
            updateExternalFilter({
              showOnlyIncomplete: e,
              showOnlyIncorrect: false,
            });

            if (e) {
              setShiftsFiltersDisabled("INCOMPLETE");
            } else {
              setShiftsFiltersDisabled(false);
            }
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Show Only Incorrect Shifts `}</span>
        <Switch
          defaultChecked={degExternalFilter.showOnlyIncorrect}
          disabled={
            shiftsFiltersDisabled !== "INCORRECT" && !!shiftsFiltersDisabled
          }
          onChange={(e) => {
            updateExternalFilter({
              showOnlyIncorrect: e,
              showOnlyIncomplete: false,
            });

            if (e) {
              setShiftsFiltersDisabled("INCORRECT");
            } else {
              setShiftsFiltersDisabled(false);
            }
          }}
        />
      </div>
      <div className="checkEntry">
        <span>{`Show Only HR Shifts `}</span>
        <Switch
          defaultChecked={degExternalFilter.showOnlyHr}
          disabled={shiftsFiltersDisabled !== "HR" && !!shiftsFiltersDisabled}
          onChange={(e) => {
            updateExternalFilter({
              showOnlyHr: e,
              showOnlyIncomplete: false,
              showOnlyIncorrect: false,
            });

            if (e) {
              setShiftsFiltersDisabled("HR");
            } else {
              setShiftsFiltersDisabled(false);
            }
          }}
        />
      </div>
    </Modal>
  );
}

export default DegWarningsModal;

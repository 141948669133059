import { Avatar } from "antd";
import { useEffect, useState } from "react";
import { UserSessionModal } from "..";
import { loginStatusColors } from "../../allUsersData";
import "./UsernameRenderer.scss";
import { useNavigate } from "react-router-dom";
import { SuspendedUserIcon } from "../../../../../../../assets";
import { useSelector } from "react-redux";
import { getInitials } from "../../../TeamManagement/components/TeamPreview/PreviewAvatar";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { RedWarningModal } from "../../../../../../commonComponents";
import { useDispatch } from "react-redux";
import { updateUserImages } from "../../../../../../../actions";

const avatarStyle = {
  width: "30px",
  height: "30px",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  gap: "5px",
  fontWeight: 600,
  fontSize: "12px",
};

const UsernameRenderer = ({
  data = {},
  value,
  userList = false,
  className = "",
  onlyName = false,
}) => {
  const {
    nameOfUser,
    departmentName,
    groupName,
    identityId,
    activeSessions,
    googleDriveFileId,
    isSuspended,
    lastSeen,
    cognitoUserId,
  } = data;
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [userSessionModalVisible, setUserSessionModalVisible] = useState(false);
  const [currentFile, setCurrentFile] = useState({});

  const { preferences } = useSelector((state) => state.preferences);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userImages } = useSelector((state) => state.userImages);

  const { getImageSrc } = driveApi({ accessToken });

  const [openWarning, setOpenWarning] = useState(false);

  const statusColor = isSuspended
    ? loginStatusColors.suspended
    : lastSeen === "now"
    ? loginStatusColors.loggedIn
    : loginStatusColors.loggedOut;

  const statusName = isSuspended ? (
    <span className="suspended-user-status">
      Suspended <SuspendedUserIcon fill="#F04F4E" />
    </span>
  ) : lastSeen === "now" ? (
    "Online"
  ) : (
    "Offline"
  );

  useEffect(() => {
    //setCurrentFile only if we have googleDriveFileId
    if (googleDriveFileId && accessToken) {
      if (userImages?.[cognitoUserId]) {
        setCurrentFile({ src: userImages[cognitoUserId] });
      } else {
        getImageSrc(googleDriveFileId).then((image) => {
          setCurrentFile({ ...image });
          dispatch(
            updateUserImages({
              [cognitoUserId]: image.src,
            })
          );
        });
      }
    } else {
      setCurrentFile(null);
    }
  }, [googleDriveFileId, accessToken]);

  return (
    <>
      <div
        onClick={() => {
          userList && setOpenWarning(true);
        }}
        className={`usernameRenderer ${className}`}
      >
        <span className="usernameRendererLeft">
          <Avatar
            shape="circle"
            src={currentFile?.src}
            gap={4}
            style={{
              backgroundColor: `${
                userConfiguration?.nameOfUser === nameOfUser
                  ? preferences.preferences?.profileColor || "#4895ef"
                  : isDarkMode
                  ? "#2a2b3a"
                  : "#F8F8FA"
              }`,
              color: isDarkMode ? "#fff" : "#323338",
              ...avatarStyle,
            }}
          >
            {getInitials(nameOfUser)}
          </Avatar>
          {!onlyName && (
            <div
              className="statusDot"
              style={{ backgroundColor: statusColor }}
            />
          )}
          <div
            onClick={() => {
              if (!userList) {
                navigate(
                  `/settings/allUsers/${departmentName}/${groupName}/${identityId}`
                );
              }
            }}
            className="username"
          >
            {value}
          </div>
        </span>
        {onlyName ? null : userList ? (
          <div
            className="user-status"
            style={{
              color:
                statusName === "Offline"
                  ? isDarkMode
                    ? "#acacca"
                    : "#323338"
                  : statusColor,
            }}
          >
            {statusName}
          </div>
        ) : (
          <div
            className="actionBtn"
            onClick={() => !!identityId && setUserSessionModalVisible(true)}
          >
            Actions
          </div>
        )}
      </div>
      {!!userSessionModalVisible && (
        <UserSessionModal
          {...{
            visible: userSessionModalVisible,
            setVisible: setUserSessionModalVisible,
            user: data,
          }}
        />
      )}
      {openWarning && (
        <RedWarningModal
          {...{
            visible: openWarning,
            onCancel: () => {
              setOpenWarning(false);
            },
            titleText: "Caution!",
            footerProps: {
              onConfirm: () => {
                navigate(
                  `/settings/allUsers/${departmentName}/${groupName}/${identityId}`
                );
                setOpenWarning(false);
              },
            },
          }}
        >
          <p>
            Are you sure you want to change user? The changes you made will be
            lost.
          </p>
        </RedWarningModal>
      )}
    </>
  );
};

export default UsernameRenderer;

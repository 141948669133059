import moment from "moment";
import { formatCurrency } from "../../../components/SidebarPages/utils";
import { dayjsNYDate } from "../../../components/DateComponents/contants/DayjsNY";
import { message } from "antd";

export const pdfHelpers = ({
  gridApi,
  gridColumnApi,
  params,
  companyLogo,
  base64,
  history,
  rowData = false,
  tableColumn,
  formatCellValueHandler,
  forExcelAndPdfAttachment,
}) => {
  const {
    PDF_WITH_CELL_FORMATTING = true,
    PDF_WITH_COLUMNS_AS_LINKS = true,
    PDF_SELECTED_ROWS_ONLY,
  } = params;

  function removeHTMLTags(htmlString) {
    const doc = new DOMParser().parseFromString(htmlString, "text/html");
    return doc.body.textContent || "";
  }

  function hasHTMLTags(htmlString) {
    const regex = /<\/?[a-z][\s\S]*>/i;
    return regex.test(htmlString);
  }

  function getPdfExportOptions(colId) {
    let col = gridColumnApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }

  function getColumnGroupsToExport() {
    let displayedColumnGroups = gridColumnApi.getAllDisplayedColumnGroups();

    let isColumnGrouping = displayedColumnGroups.some((col) =>
      col.hasOwnProperty("children")
    );

    if (!isColumnGrouping) {
      return null;
    }

    let columnGroupsToExport = [];

    displayedColumnGroups.forEach((colGroup) => {
      let isColSpanning = colGroup.children.length > 1;
      let numberOfEmptyHeaderCellsToAdd = 0;

      if (isColSpanning) {
        let headerCell = createHeaderCell(colGroup);
        columnGroupsToExport.push(headerCell);
        numberOfEmptyHeaderCellsToAdd--;
      }

      colGroup.displayedChildren.forEach((childCol) => {
        let pdfExportOptions = getPdfExportOptions(childCol.getColId());
        if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
          numberOfEmptyHeaderCellsToAdd++;
        }
      });

      for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
        columnGroupsToExport.push({});
      }
    });

    return columnGroupsToExport;
  }

  function getColumnsToExport() {
    let columnsToExport = [];

    if (
      gridColumnApi.getAllDisplayedColumns().length > 10 &&
      !forExcelAndPdfAttachment &&
      columnsToExport > 10
    ) {
      message.info(
        `Displayed 10 out of ${
          gridColumnApi.getAllDisplayedColumns().length
        } columns for better visibility.`
      );
    }

    gridColumnApi.getAllDisplayedColumns().forEach((col, index) => {
      let pdfExportOptions = getPdfExportOptions(col.getColId());
      if (
        (pdfExportOptions && pdfExportOptions.skipColumn) ||
        (tableColumn && !tableColumn.includes(col.colId))
      ) {
        return;
      }

      let headerCell = createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    if (tableColumn) {
      columnsToExport = columnsToExport.sort((a, b) => {
        return tableColumn.indexOf(a.colId) - tableColumn.indexOf(b.colId);
      });
    }

    return columnsToExport?.filter(
      ({ colId }) =>
        colId !== "viewApprovals" && colId !== "statusListOfScheduling"
    );
  }

  const getValueType = (columnId, val) => {
    if (
      columnId === "departAt" ||
      columnId === "inspectionDate" ||
      columnId === "createdAt" ||
      columnId === "createdBy" ||
      columnId === "arriveBy" ||
      columnId === "dlIssuedDate" ||
      columnId === "dlExpirationDate" ||
      columnId === "mcExpirationDate" ||
      columnId === "mcIssuedDate" ||
      columnId === "dispatchDate" ||
      columnId === "issue_date" ||
      columnId === "violation_time" ||
      columnId === "maintenanceDate" ||
      columnId === "incidentDate" ||
      columnId === "updatedAt"
    ) {
      return typeof val === "number" ? moment(val).format("MM/DD/YYYY") : val;
    } else if (
      columnId === "activityDate" ||
      columnId === "startingTime" ||
      columnId === "timeAtLocation" ||
      columnId === "timeExitingTheLocation"
    ) {
      return typeof val === "number" ? moment(val).format("hh:mm") : val;
    } else if (columnId === "hearingObject" || columnId === "claimObject") {
      if (val?.hasOwnProperty("assignedTo")) {
        if (Array.isArray(val.assignedTo)) {
          val.assignedTo.hasOwnProperty("label")
            ? val.assignedTo?.map(({ label }) => label).join(", ")
            : val.assignedTo?.map((item) => item);
        } else {
          val.assignedTo;
        }
      } else {
        return "";
      }
    } else if (columnId === "taskAssignedTo") {
      return Array.isArray(val) ? val?.map((el) => el)?.join(", ") : "";
    }
    //this was returning without modifying other column value
    // else if (val !== undefined && typeof val !== "object") {
    //   return val;
    // }
    else if (columnId === "totalHours") {
      return val?.totalHours?.hours;
    } else if (columnId === "lastModifiedBy") {
      return val?.name;
    }
    // else if (columnId === "estimationsSelected") {
    //   return val;
    // }
    else if (columnId === "noteComment" && val !== undefined) {
      return val?.replace(/<\/?[^>]+(>|$)/g, "");
    } else if (columnId === "mentions") {
      return Array.isArray(val) ? val?.map((el) => el)?.join(", ") : "";
    } else if (columnId === "automationAction") {
      return Array.isArray(val)
        ? val?.map(({ actionName }) => actionName)?.join(", ")
        : "";
    } else if (columnId === "safetyExpirationDate") {
      const formatedDate = dayjsNYDate(val).format("MM-DD-YYYY").toString();
      return formatedDate;
    } else if (columnId === "cronLogs") {
      const result = val?.responses?.length
        ? !!val?.responses?.[val?.responses?.length - 1]?.error
          ? "Error"
          : "Success"
        : "No Response";
      return result;
    } else if (columnId === "automationTime") {
      let result = `${
        (val.dayOfMonth && `Day of month: ${val.dayOfMonth}`) ||
        (val.dayOfWeek && `Day of week: ${val.dayOfWeek}`)
      }
      Time (NY-TimeZone): ${val.hour}:${val.minute}`;
      return result.trimStart();
    } else if (
      columnId === "description" ||
      columnId === "unitDescription" ||
      columnId === "inspectionNotes" ||
      columnId === "comments" ||
      columnId === "itemDescription" ||
      columnId === "automationDescription"
    ) {
      let text = val;
      if (hasHTMLTags(val)) {
        text = removeHTMLTags(val);
      }
      return text;
    } else if (columnId === "googleDriveUploads") {
      //this condition is for DocumentationBasePage
      return val ? val?.length : "unknown";
    } else if (columnId === "allGoogleDriveUploads") {
      return val ? val?.[0]?.googleDriveUploads?.length : "unknown";
    }
    // else if (columnId === "notes" || columnId === "projectNotes" && typeof val === 'object') {
    //   return Array.isArray(val) ? val?.map(({ comment }) => comment?.replace(/<\/?[^>]+(>|$)/g, "")) : ''
    // }
    else if (columnId === "services") {
      return Array.isArray(val) ? `${val?.map((el) => el)?.join(", ")} \n` : "";
    } else if (columnId === "periodTo") {
      return `${moment(val?.start).format("MM/DD/YYYY")} - ${moment(
        val?.end
      ).format("MM/DD/YYYY")}`;
    } else return val;
  };

  function createTableCell(cellValue, colId, node, gridApi, base64) {
    const column = gridApi.columnModel.columnDefs.find(
      (col) => col.field === colId
    );
    const updatedCellValue = cellValue;

    let tableCell = {};

    if (column?.hasOwnProperty("linkArray")) {
      const links = column.linkArray(node.data);

      // Stack to hold the links
      const linkStack = [];

      // Loop through each link and create clickable text
      links.forEach((link, index) => {
        const linkTo = window?.location.origin + link;
        linkStack.push({ text: `Est. ${index + 1}`, link: linkTo });
      });

      // Create a stack of clickable links
      tableCell = {
        stack: linkStack,
        color: "blue",
        style: ["tableCell"],
      };
    } else if (column?.hasOwnProperty("link")) {
      const linkTo =
        window?.location.origin + column.link(node?.data ? node.data : node);
      tableCell.link = linkTo;

      tableCell = {
        text: getValueType(colId, updatedCellValue),
        link: linkTo,
        color: "blue",
        style: ["tableCell"],
      };
    } else {
      tableCell = {
        text: getValueType(colId, updatedCellValue),
        style: ["tableCell"],
      };
    }

    const pdfExportOptions = getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL, modifyCellValue } = pdfExportOptions;

      if (PDF_WITH_CELL_FORMATTING && styles) {
        Object.entries(styles(updatedCellValue))?.forEach(
          ([key, value]) => (tableCell[key] = value)
        );
      }

      if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
        tableCell["link"] = createURL(updatedCellValue);
        tableCell["color"] = "blue";
        tableCell["decoration"] = "underline";
      }

      if (modifyCellValue) {
        tableCell["text"] = modifyCellValue(updatedCellValue);
      }
    }

    return tableCell;
  }

  function createHeaderCell(col) {
    let headerCell = {};

    // console.log("🚀 ~ file: index.js:235 ~ createHeaderCell ~ col:", col);
    let isColGroup = col.hasOwnProperty("children");

    if (isColGroup) {
      headerCell.text = col?.originalColumnGroup?.colGroupDef?.headerName;
      headerCell.colSpan = col?.children?.length;
      headerCell.colId = col?.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.colId = col.getColId();
    }

    headerCell["style"] = "tableHeader";

    return headerCell;
  }

  function getExportedColumnsWidths(columnsToExport) {
    return columnsToExport.map(() => 100 / columnsToExport.length + "%");
  }

  function getRowsToExport(columnsToExport) {
    let rowsToExport = [];

    if (!rowData) {
      gridApi?.forEachNodeAfterFilterAndSort?.((node) => {
        if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
          return;
        }
        let rowToExport = columnsToExport.map(({ colId }) => {
          let cellValues = gridApi.getValue(colId, node);
          let cellValue = Array.isArray(cellValues)
            ? formatCellValueHandler
              ? formatCellValueHandler(cellValues, node.data)
              : cellValues.length
            : cellValues;

          //Below fixes date for all dates in the table but it is not the best solution because dates may be without milliseconds and in that case it will be not formatted
          //It also formats amount into $100,000.00 for all columns that contain amount or price
          let formatCellValue =
            !isNaN(cellValue) &&
            (colId.toLowerCase().includes("amount") ||
              colId.toLowerCase().includes("price") ||
              colId.toLowerCase().includes("credits"))
              ? formatCurrency(cellValue)
              : cellValue?.hasOwnProperty("toString")
              ? cellValue?.toString?.()
              : cellValue;

          let tableCell = createTableCell(
            formatCellValue,
            colId,
            node,
            gridApi,
            base64
          );
          return tableCell;
        });
        rowsToExport.push(rowToExport);
      });
    } else {
      rowData.forEach((node) => {
        if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
          return;
        }

        let rowToExport = columnsToExport.map(({ colId }) => {
          let cellValues = node[colId];
          let cellValue = Array.isArray(cellValues)
            ? cellValues.length
            : cellValues;

          // Below fixes date for all dates in the table but it is not the best solution because dates may be without milliseconds and in that case it will be not formatted
          // It also formats amount into $100,000.00 for all columns that contain amount or price
          let formatCellValue =
            !isNaN(cellValue) &&
            (colId.toLowerCase().includes("amount") ||
              colId.toLowerCase().includes("price"))
              ? formatCurrency(cellValue)
              : cellValue?.hasOwnProperty("toString")
              ? cellValue?.toString?.()
              : cellValue;

          let tableCell = createTableCell(
            formatCellValue,
            colId,
            node,
            gridApi, // Remove this line since we're not using gridApi
            base64
          );
          return tableCell;
        });

        rowsToExport.push(rowToExport);
      });
    }

    return rowsToExport;
  }
  return {
    getColumnGroupsToExport,
    getRowsToExport,
    getColumnsToExport,
    getExportedColumnsWidths,
  };
};

export const pdfOrderHelpers = ({ gridApi, gridColumnApi, params }) => {
  const {
    PDF_WITH_CELL_FORMATTING = true,
    PDF_WITH_COLUMNS_AS_LINKS = true,
    PDF_SELECTED_ROWS_ONLY,
  } = params;

  function getPdfExportOptions(colId) {
    let col = gridColumnApi.getColumn(colId);
    return col.colDef.pdfExportOptions;
  }

  function getColumnGroupsToExport() {
    let displayedColumnGroups = gridColumnApi.getAllDisplayedColumnGroups();

    let isColumnGrouping = displayedColumnGroups.some((col) =>
      col.hasOwnProperty("children")
    );

    if (!isColumnGrouping) {
      return null;
    }

    let columnGroupsToExport = [];

    displayedColumnGroups.forEach((colGroup) => {
      let isColSpanning = colGroup.children.length > 1;
      let numberOfEmptyHeaderCellsToAdd = 0;

      if (isColSpanning) {
        let headerCell = createHeaderCell(colGroup);
        columnGroupsToExport.push(headerCell);
        numberOfEmptyHeaderCellsToAdd--;
      }

      colGroup.displayedChildren.forEach((childCol) => {
        let pdfExportOptions = getPdfExportOptions(childCol.getColId());
        if (!pdfExportOptions || !pdfExportOptions.skipColumn) {
          numberOfEmptyHeaderCellsToAdd++;
        }
      });

      for (let i = 0; i < numberOfEmptyHeaderCellsToAdd; i++) {
        columnGroupsToExport.push({});
      }
    });

    return columnGroupsToExport;
  }

  function getColumnsToExport() {
    let columnsToExport = [];

    gridColumnApi.getAllDisplayedColumns().forEach((col) => {
      let pdfExportOptions = getPdfExportOptions(col.getColId());
      if (pdfExportOptions && pdfExportOptions.skipColumn) {
        return;
      }
      let headerCell = createHeaderCell(col);
      columnsToExport.push(headerCell);
    });

    return columnsToExport;
  }
  function createTableCell(cellValue, colId) {
    const tableCell = {
      text: cellValue !== undefined ? cellValue : "",
      style: "tableCell",
    };

    const pdfExportOptions = getPdfExportOptions(colId);

    if (pdfExportOptions) {
      const { styles, createURL, modifyCellValue } = pdfExportOptions;

      if (PDF_WITH_CELL_FORMATTING && styles) {
        Object.entries(styles(cellValue)).forEach(
          ([key, value]) => (tableCell[key] = value)
        );
      }

      if (PDF_WITH_COLUMNS_AS_LINKS && createURL) {
        tableCell["link"] = createURL(cellValue);
        tableCell["color"] = "blue";
        tableCell["decoration"] = "underline";
      }

      if (modifyCellValue) {
        tableCell["text"] = modifyCellValue(cellValue);
      }
    }

    return tableCell;
  }

  function createHeaderCell(col) {
    let headerCell = {};

    let isColGroup = col.hasOwnProperty("children");

    if (isColGroup) {
      headerCell.text = col.originalColumnGroup.colGroupDef.headerName;
      headerCell.colSpan = col.children.length;
      headerCell.colId = col.groupId;
    } else {
      let headerName = col.colDef.headerName;

      if (col.sort) {
        headerName += ` (${col.sort})`;
      }
      if (col.filterActive) {
        headerName += ` [FILTERING]`;
      }

      headerCell.text = headerName;
      headerCell.colId = col.getColId();
    }

    headerCell["style"] = "tableHeader";

    return headerCell;
  }

  function getExportedColumnsWidths(columnsToExport) {
    return columnsToExport.map(() => 100 / columnsToExport.length + "%");
  }

  function getRowsToExport(columnsToExport) {
    let rowsToExport = [];

    gridApi.forEachNodeAfterFilterAndSort((node) => {
      if (PDF_SELECTED_ROWS_ONLY && !node.isSelected()) {
        return;
      }
      let rowToExport = columnsToExport.map(({ colId }) => {
        let cellValue = gridApi.getValue(colId, node);
        let tableCell = createTableCell(cellValue, colId);
        return tableCell;
      });
      rowsToExport.push(rowToExport);
    });

    return rowsToExport;
  }
  return {
    getColumnGroupsToExport,
    getRowsToExport,
    getColumnsToExport,
    getExportedColumnsWidths,
  };
};

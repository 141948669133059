export const lazyFetchingData = [
  {
    tableName: "leads",
    type: "lazyFetch",
    listOfKeys: [
      "leadStatus",
      "leadAddress",
      "borough",
      "leadCompany",
      "createdAt",
      "teamsConfiguration",
      "projectExecutive",
    ],
    idObject: "leadId",
    dataStateKey: "leadsData",
  },
  {
    tableName: "opportunities",
    type: "lazyFetch",
    listOfKeys: [
      "opportunityStatus",
      "opportunityStage",
      "opportunityAddress",
      "createdAt",
      "opportunityId",
      "teamsConfiguration",
      "projectExecutive",
      "projectManager",
      "estimations",
      "geoFenceInfo",
    ],
    idObject: "opportunityId",
    dataStateKey: "opportunitiesData",
  },
  {
    tableName: "projects",
    type: "fetchAllData",
    listOfKeys: [
      "projectStatus",
      "projectName",
      "attorney",
      "expeditor",
      "createdAt",
      "projectId",
      "teamsConfiguration",
      "projectExecutive",
      "projectManager",
      "services",
      "geoFenceInfo",
    ],
    idObject: "projectId",
    dataStateKey: "projectsData",
  },
  {
    tableName: "documentation",
    type: "lazyFetch",
    listOfKeys: ["docType", "createdAt", "teamsConfiguration"],
    dataStateKey: "documentationData",
    idObject: "docId",
  },
  {
    tableName: "estimations",
    type: "fetchAllData",
    listOfKeys: [
      "engProgress",
      "archProgress",
      "estSTATUS",
      "archAssignedTo",
      "engAssignedTo",
      "statusByArch",
      "statusByEng",
      "jobSiteAddress",
      "createdAt",
      "services",
      "estimationId",
      "teamsConfiguration",
      "projectExecutive",
      "projectManager",
    ],
    idObject: "estimationId",
    dataStateKey: "estimationsData",
  },
  {
    tableName: "scheduling",
    type: "fetchAllData",
    listOfKeys: [
      "typeOfWork",
      "scheduleAddress",
      "createdAt",
      "teamsConfiguration",
      "projectManagers",
      "projectExecutive",
      "scheduleId",
      "scheduleStatus",
      "toBeScheduled",
    ],
    idObject: "scheduleId",
    dataStateKey: "schedulingData",
  },
  {
    tableName: "fleet",
    type: "lazyFetch",
    listOfKeys: [
      "fleetYear",
      "registrationExpDate",
      "inspectionExpDate",
      "createdAt",
      "vinNumber",
      "licensePlate",
      "teamsConfiguration",
    ],
    idObject: "fleetId",
    dataStateKey: "fleetData",
  },
  {
    tableName: "fleetViolations",
    type: "lazyFetch",
    listOfKeys: [
      "violationLocation",
      "amount_due",
      "issue_date",
      "fine_amount",
      "createdAt",
      "teamsConfiguration",
    ],
    dataStateKey: "violationsData",
  },
  {
    tableName: "fleetDispatching",
    type: "fetchAllData",
    listOfKeys: [
      "fleetName",
      "fleetId",
      "dispatchedBy",
      "createdAt",
      "routes",
      "teamsConfiguration",
    ],
    idObject: "dispatchId",
    dataStateKey: "dispatchData",
  },
  {
    tableName: "fleetInspections",
    type: "lazyFetch",
    listOfKeys: [
      "inspectedBy",
      "driver",
      "createdAt",
      "fleetName",
      "teamsConfiguration",
    ],
    idObject: "inspectionId",
    dataStateKey: "fleetInspectionsData",
  },
  {
    tableName: "fleetIncidents",
    type: "lazyFetch",
    listOfKeys: [
      "driverName",
      "incidentStatus",
      "fleetName",
      "incidentReport",
      "incidentAddress",
      "createdAt",
      "teamsConfiguration",
    ],
    dataStateKey: "incidentsData",
  },
  {
    tableName: "fleetDefects",
    type: "lazyFetch",
    listOfKeys: [
      "defectStatus",
      "driverName",
      "fleetName",
      "inspectionName",
      "defectPriority",
      "defectName",
      "createdAt",
      "teamsConfiguration",
    ],
    idObject: "fleetId",
    dataStateKey: "defectsData",
  },
  {
    tableName: "engineers",
    type: "lazyFetch",
    listOfKeys: [
      "lastName",
      "nameOfEngineer",
      "createdAt",
      "teamsConfiguration",
    ],
    dataStateKey: "engData",
  },
  {
    tableName: "architects",
    type: "lazyFetch",
    listOfKeys: [
      "nameOfArchitect",
      "lastName",
      "createdAt",
      "teamsConfiguration",
    ],
    dataStateKey: "archData",
  },
  {
    tableName: "applications",
    type: "fetchAllData",
    listOfKeys: [
      "applicationStatus",
      "createdBy",
      "services",
      "applicationNo",
      "periodTo",
      "includedRentals",
      "applicationId",
      "estimationsSelected",
      "prevTotalLessRetainage",
      "totalPrice",
      "scheduleId",
      "fullyCharged",
      "dueDate",
      "createdAt",
      "accountName",
      "lastModifiedBy",
      "accountNameAddress",
      "projectId",
      "teamsConfiguration",
    ],
    idObject: "applicationId",
    dataStateKey: "applicationsData",
  },
  {
    tableName: "rentals",
    type: "lazyFetch",
    listOfKeys: [
      "createdAt",
      "isProRated",
      "rentalStatus",
      "projectAddress",
      "projectId",
      "rentalId",
      "services",
      "rentalNumber",
      "taxRate",
      "totalPrice",
      "teamsConfiguration",
    ],
    idObject: "rentalId",
    dataStateKey: "rentalsData",
  },
  {
    tableName: "workOrders",
    type: "lazyFetch",
    listOfKeys: [
      "workOrderStatus",
      "fleetName",
      "dueDate",
      "mechanicInfo",
      "createdAt",
      "teamsConfiguration",
    ],
    idObject: "workOrderId",
    dataStateKey: "workOrdersData",
  },
  {
    tableName: "fleetMaintenance",
    type: "lazyFetch",
    listOfKeys: ["createdAt", "teamsConfiguration"],
    dataStateKey: "maintenanceData",
  },
  {
    tableName: "claims",
    type: "lazyFetch",
    listOfKeys: [
      "claimStatus",
      "claimObject",
      "claimCategory",
      "createdAt",
      "teamsConfiguration",
      "projectExecutive",
      "projectManager",
    ],
    idObject: "claimId",
    dataStateKey: "claimsData",
  },
  {
    tableName: "safety",
    type: "lazyFetch",
    listOfKeys: [
      "safetyApplicationStatus",
      "safetyName",
      "safetyApplicationObject",
      "accountId",
      "projectId",
      "safetyApplicationCategory",
      "scheduleId",
      "safetyApplicationId",
      "createdAt",
      "teamsConfiguration",
      "projectExecutive",
      "projectManager",
    ],
    idObject: "safetyApplicationId",
    dataStateKey: "safetyData",
  },
  {
    tableName: "incidents",
    type: "lazyFetch",
    listOfKeys: [
      "incidentStatus",
      "incidentObject",
      "incidentCategory",
      "createdAt",
      "teamsConfiguration",
      "accountId",
      "projectId",
      "scheduleId",
      "projectExecutive",
      "projectManager",
      "incidentName",
      "incidentId",
    ],
    idObject: "incidentId",
    dataStateKey: "incidentsData",
  },
  {
    tableName: "todos",
    type: "filterTables",
    filterKey: "cognitoUserId",
    idObject: "todoId",
    adminAllRecords: true,
    dataStateKey: "todosData",
    idObject: "todoId",
    listOfKeys: [
      "createdAt",
      "todoId",
      "teamsConfiguration",
      "cognitoUserId",
      "todoStatus",
      "todoCategory",
      "todoUser",
      "relatedTo",
      "todoName",
    ],
  },
  {
    tableName: "degEntries",
    filterKey: "activityStatus",
    filterValue: "Completed",
    dateKey: "punchTimeStamp",
    // listOfKeys: [
    //   "entryId",
    //   "employeeId",
    //   "company",
    //   "jobsiteAddress",
    //   "jobsiteId",
    //   "jobsiteMatch",
    //   "punchTimeStamp",
    //   "punchType",
    //   "role",
    //   "jobsiteTotalCost",
    //   "total",
    //   "totalWorkHours",
    // ],
    idObject: "entryId",
  },
  {
    tableName: "formRequests",
    listOfKeys: [
      "requestId",
      "requestType",
      "assignedTo",
      "category",
      "userRequesting",
      "createdAt",
      "responseFrom",
    ],
    idObject: "requestId",
  },
  {
    tableName: "inspections",
    listOfKeys: [
      "inspectionId",
      "inspectionStatus",
      "inspectionType",
      "inspectionAddress",
      "projectId",
      "teamsConfiguration",
      "createdAt",
    ],
    idObject: "inspectionId",
  },
]?.filter(Boolean);

export const allTableData = {
  projects: {
    table: "projects",
    id: "projectId",
  },
  estimations: {
    table: "estimations",
    id: "estimationId",
  },
  scheduling: {
    table: "scheduling",
    id: "scheduleId",
  },
  applications: {
    table: "applications",
    id: "applicationId",
  },
};
export const tableMapping = {
  archData: "architects",
  engData: "engineers",
  dispatching: "fleetDispatching",
  violations: "fleetviolations",
  "fleet inspections": "fleetInspections",
  // inspections: "fleetInspections",
  // incidents: "fleetIncidents",
  maintenance: "fleetMaintenance",
  defects: "fleetDefects",
  "work orders": "workOrders",
  "add-ons": "estimations",
  "to do": "todos",
  "project cost": "degEntries",
  approvals: "formRequests",
};

export const tableKeyMapping = {
  architects: "archData",
  engineers: "engData",
  fleetDispatching: "Dispatching",
  fleetViolations: "Violations",
  // fleetInspections: "Inspections",
  fleetMaintenance: "Maintenance",
  fleetDefects: "Defects",
  // fleetIncidents: "Incidents",
  workOrders: "Work Orders",
  todos: "To Do",
  degEntries: "Project Cost",
  formRequests: "Approvals",
  fleetInspections: "Fleet Inspections",
};

export const keyToTable = {
  archData: "architects",
  engData: "engineers",
  Dispatching: "fleetDispatching",
  Violations: "fleetViolations",
  // Inspections: "fleetInspections",
  Maintenance: "fleetMaintenance",
  Defects: "fleetDefects",
  "Work Orders": "workOrders",
  "To Do": "todos",
};

export const tableIdKeys = {
  architects: "architectId",
  engineers: "engineerId",
  fleetDispatching: "dispatchId",
  fleetViolations: "violationId",
  fleetInspections: "inspectionId",
  fleetMaintenance: "maintenanceId",
  fleetDefects: "defectId",
  // fleetIncidents: "incidentId",
  workOrders: "workOrderId",
  todos: "todoId",
  projects: "projectId",
  estimations: "estimationId",
  scheduling: "scheduleId",
  leads: "leadId",
  opportunities: "opportunityId",
  applications: "applicationId",
  rentals: "rentalId",
  fleet: "fleetId",
  documentation: "docId",
  claims: "claimId",
  fleetDispatching: "dispatchId",
  fleetInspections: "inspectionId",
  fleetMaintenance: "maintenanceId",
  fleetDefects: "defectId",
  incidents: "incidentId",
  safety: "safetyApplicationId",
  todos: "todoId",
  costAnalytics: "entryId",
  formRequests: "requestId",
  inspections: "inspectionId",
};

export const intervalData = [
  { value: 0, field: "Disable" },
  { value: 60000, field: "1 Min" },
  { value: 300000, field: "5 Min" },
  { value: 600000, field: "10 Min" },
];

const MAX_ATTACHMENT_SIZE_MB = 25;
export const MAX_ATTACHMENT_SIZE_BYTES = MAX_ATTACHMENT_SIZE_MB * 1024 * 1024;

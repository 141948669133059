import { assignServiceColor } from "../../../../../../../../../helpers/constants/forProject";
import {
  excludesColumnDefs,
  includesColumnDefs,
  serviceColumnDefs,
  serviceHoistColumnDefs,
} from "./columnDefs";
import {
  ServiceAddons,
  CollapsePanel,
  HoistHeader,
  DynamicComparisonTable,
} from "../";

import styles from "./ServiceCard.module.css";
import { checkIfServiceIsHoist } from "../../../../../../DataEntryGrid/models/Service";

const ServiceCard = ({ service, serviceToCompare, serviceIndex, version }) => {
  const serviceOptions = Array.isArray(service.serviceOptions?.[0])
    ? service.serviceOptions[0]
    : service?.serviceOptions ?? [];

  return (
    <div className={styles.serviceCardContainer}>
      <span className={styles.serviceTitle}>
        <div
          className={styles.circleTitleShape}
          style={{ background: assignServiceColor[serviceIndex + 1] }}
        />
        {service.label}
      </span>
      <hr />
      {serviceOptions.map((option) => {
        const objectToCompare =
          serviceToCompare.find((item) => item.label === service.label) ?? {};
        const servicesFromObjectToCompare = Array.isArray(
          objectToCompare?.serviceOptions?.[0]
        )
          ? objectToCompare?.serviceOptions[0]
          : objectToCompare?.serviceOptions ?? [];

        const itemsFromObjectToCompare =
          servicesFromObjectToCompare?.find(
            (item) => item.elevationId === option.elevationId
          ) ?? {};

        const completeServiceName = `${service.label} ${option.elevationId} ${option.elevationLabel} ${version}`;

        return (
          <CollapsePanel
            key={option.elevationId}
            header={`${option.elevationId} ${option.elevationLabel}`}
          >
            {checkIfServiceIsHoist(service) && (
              <HoistHeader
                elevation={option}
                elevationToCompare={itemsFromObjectToCompare}
              />
            )}

            <DynamicComparisonTable
              completeServiceName={completeServiceName}
              originalObject={option.items}
              objectToCompare={itemsFromObjectToCompare?.items ?? []}
              customColumnDefs={
                checkIfServiceIsHoist(service)
                  ? serviceHoistColumnDefs(
                      itemsFromObjectToCompare?.items ?? []
                    )
                  : service.isScope
                  ? undefined
                  : serviceColumnDefs(itemsFromObjectToCompare?.items ?? [])
              }
            />
          </CollapsePanel>
        );
      })}

      <CollapsePanel header="Addons">
        <ServiceAddons
          addons={service.serviceAddons}
          addonsToCompare={serviceToCompare[serviceIndex]?.serviceAddons}
        />
      </CollapsePanel>

      <CollapsePanel header="Includes">
        <DynamicComparisonTable
          completeServiceName={service.label}
          originalObject={service.includes ?? []}
          objectToCompare={serviceToCompare[serviceIndex]?.includes ?? []}
          customColumnDefs={includesColumnDefs}
        />
      </CollapsePanel>

      <CollapsePanel header="Excludes">
        <DynamicComparisonTable
          completeServiceName={service.label}
          originalObject={service.excludes ?? []}
          objectToCompare={serviceToCompare[serviceIndex]?.excludes ?? []}
          customColumnDefs={excludesColumnDefs}
        />
      </CollapsePanel>
    </div>
  );
};

export default ServiceCard;

import {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { Tooltip, message, Popover } from "antd";
import { MenuOutlined, SearchOutlined } from "@ant-design/icons";

import {
  isYard,
  FleetsLiveContext,
  checkDriveLastRow,
  loadLivePreference,
  areUploadDatesLinked,
  saveLocalPreferences,
  findGeofenceCenterCoordinate,
} from "../../../../utils";
import { getFence } from "../../../../utils";
import LiveAuditContext from "../../LiveAuditContext";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { getCoordinatesAndZip } from "src/components/SidebarPages/Fleet/utils";
import {
  TripsPicker,
  RadiusPicker,
  AuditReportModal,
  AddActualActivityModal,
} from "./components";
import {
  MondayButton,
  WarningModal,
} from "../../../../../../../commonComponents";
import { EditIcon } from "../../../../../../DynamicView/src";
import { SimplePlacesInput } from "../../../../../components";
import { Documentation } from "src/icons";
import { FilterIcon } from "src/components/SidebarPages/BasePage/src";
import { TrashIcon, XIcon } from "../../../../../../Communication/assets";
import { ManageAlternatesIcon, ManageAlternatesIconBlack } from "src/assets";
import { AddIcon } from "../../../../../../../Header/components/GoogleEvents/assets";
import { TickIcon } from "../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { ReactComponent as WarningIcon } from "../../../../../../../../icons/warningTriangle.svg";

import "./AuditController.scss";

const AuditController = forwardRef((_, ref) => {
  //#region HOOKS
  const { yards } = useSelector((state) => state.yards);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    fleet,
    lastRows,
    geofences,
    isWritable,
    uploadFields,
    selectedDate,
    googleAccessToken,
    uploadToDriveHandler,
  } = useContext(FleetsLiveContext);

  const {
    onFiler,
    viewMode,
    auditStatus,
    openDispatch,
    onAuditRevert,
    changeAuditView,
    selectedVehicle,
    onDrawNearFences,
    onChangeInitialPlan,
    clearCustomLocation,
    renderCustomLocation,
  } = useContext(LiveAuditContext);

  const [planViewMode, setPlanViewMode] = useState(true);
  const [warningVisible, setWarningVisible] = useState(false);
  const [popoverFilter, setPopoverFilter] = useState(false);
  const [addActivityModalOpen, setAddActivityModalOpen] = useState(false);
  const [auditReportModal, setAuditReportModal] = useState(false);
  const [changePlanButton, setChangePlanButton] = useState(
    !!loadLivePreference("auditSelectedVehicle")
  );

  useEffect(() => {
    //#region IS WRITABLE EFFECT
    if (!isWritable) {
      setPlanViewMode(true);
    }
  }, [isWritable]);

  //#region REF HANDLES
  useImperativeHandle(
    ref,
    () => {
      return {
        changeFleetName(fleetId) {
          let selectedTruck = fleet?.find(({ fleetId: i }) => i === fleetId);
          if (selectedTruck) {
            let titleEl = document.getElementById("selected-vehicle-name");
            if (titleEl) {
              titleEl.innerText = selectedTruck?.fleetName;
            }
          }
          if (!planViewMode) {
            onChangeInitialPlan();
            setPlanViewMode(!planViewMode);
          }
          setChangePlanButton(true);
        },
        resetPlanMode() {
          if (!planViewMode) {
            onChangeInitialPlan();
            setPlanViewMode(!planViewMode);
          }
        },
      };
    },
    [planViewMode, fleet]
  );

  //#region VEHICLE UPLOAD CONFIG
  const key = process.env.NODE_ENV === "production" ? "prod" : "dev";
  const vehicleUploadConfig =
    uploadFields?.fieldOptions?.[key]?.vehicleConfigs?.[selectedVehicle];
  const lastVehicleRow = lastRows?.[vehicleUploadConfig?.sheetName];

  function toggleAuditSidebar() {
    //#region TOGGLE AUDIT SIDEBAR
    let sidebar = document.getElementById("audit-sidebar");
    if (sidebar) {
      if (sidebar.classList.contains("audit-side-expanded")) {
        sidebar.classList.remove("audit-side-expanded");
        sidebar.classList.add("audit-side-collapsed");
        saveLocalPreferences({ auditVehicleSide: "collapsed" });
      } else {
        sidebar.classList.remove("audit-side-collapsed");
        sidebar.classList.add("audit-side-expanded");

        const searchInput = sidebar.querySelector("input");
        if (searchInput) {
          searchInput.focus();
        }

        saveLocalPreferences({ auditVehicleSide: "expanded" });
      }
    }
  }

  async function updateDriveHandler(confirmed = false) {
    //#region UPDATE DRIVE HANDLER
    let vehicle = fleet?.find(({ fleetId }) => fleetId === selectedVehicle);
    if (!vehicle) {
      return;
    }

    message.loading({
      content: "Please wait for the data to be uploaded...",
      key: "dataUpload",
      duration: 0,
    });

    if (!vehicleUploadConfig) {
      message.warning({
        content: "Sheet name and id are missing for the vehicle",
        key: "dataUpload",
      });
      return;
    }

    if (!lastVehicleRow) {
      message.loading({
        content: "Last row information is missing for the vehicle",
        key: "dataUpload",
      });
      return;
    }

    if (!confirmed) {
      await checkDriveLastRow({
        spreadsheetId: vehicleUploadConfig.sheetId,
        sheetName: vehicleUploadConfig?.sheetName,
        lastRow: lastVehicleRow,
        googleAccessToken,
      })
        .then((res) => {
          let condition = true;
          if (res.length) {
            condition = areUploadDatesLinked({
              auditDate: loadLivePreference("liveSelectedDate"),
              lastDate: res?.[0]?.[0],
            });
          }

          if (!condition) {
            message.destroy("dataUpload");
            setWarningVisible(`UPLOAD CONFIRM-${res?.[0]?.[0]}`);
          } else {
            uploadToDriveHandler({ fleetId: selectedVehicle });
          }
        })
        .catch((err) => {
          message.error({
            content: "Something went wrong while uploading!",
            key: "dataUpload",
          });
          console.log("Error updating data: ", err);
        });
    } else {
      uploadToDriveHandler({ fleetId: selectedVehicle });
    }
  }

  function switchClickHandler() {
    //#region SWITCH CLICK HANDLER
    changeAuditView();
    if (!planViewMode) {
      setPlanViewMode(true);
      onChangeInitialPlan();
    }
  }

  function filterHandler(values) {
    //#region FILTER HANDLER
    setPopoverFilter(false);
    onFiler(values);
  }

  function drawNearFences(values) {
    //#region DRAW NEAR FENCES
    onDrawNearFences(values);
  }

  function revertAuditHandler(confirmed = false) {
    //#region REVERT AUDIT
    if (!confirmed) {
      setWarningVisible("REVERT");
      return;
    }

    onAuditRevert();
  }

  return (
    <div
      className={`audit-controller ${
        isDarkMode ? "audit-controller-dark" : ""
      }`}
    >
      <div className="list-controls">
        <div className="side-toggle-icon" onClick={toggleAuditSidebar}>
          <MenuOutlined fill={"black"} />
        </div>
        <span
          className="selected-vehicle-name"
          id="selected-vehicle-name"
        ></span>
        <Tooltip
          title={`Go to ${viewMode === "OVERVIEW" ? "map view" : "overview"}`}
        >
          {/** 
            //#region MAP/OVERVIEW
          */}
          {selectedVehicle ? (
            isDarkMode ? (
              <ManageAlternatesIcon
                className={"alternate-icon"}
                style={{
                  transform: `rotate(${
                    viewMode === "MAP" ? "0deg" : "180deg"
                  })`,
                }}
                onClick={() => {
                  switchClickHandler();
                }}
              />
            ) : (
              <ManageAlternatesIconBlack
                className={"alternate-icon"}
                style={{
                  transform: `rotate(${
                    viewMode === "MAP" ? "0deg" : "180deg"
                  })`,
                }}
                onClick={() => {
                  switchClickHandler();
                }}
              />
            )
          ) : (
            <></>
          )}
        </Tooltip>
        {selectedVehicle && auditStatus && (
          <span className={`audit-status ${auditStatus}`}>
            {auditStatus?.charAt(0) + auditStatus?.toLowerCase()?.slice(1)}
          </span>
        )}
      </div>
      <div className="audit-controls">
        {/** 
          //#region SEARCH LOCATION
        */}
        {viewMode === "MAP" && selectedVehicle && (
          <SimplePlacesInput
            {...{
              label: "",
              noFormItem: true,
              required: false,
              isCustomValue: true,
              className: "locations-input",
              popupClassName: isDarkMode ? "darkDropDown" : undefined,
              placeholder: "Search geofence or location...",
              groupedOptions: [
                {
                  label: "Geofences",
                  options: Object.values(geofences || {})?.map(
                    ({ name, points, radius }) => ({
                      label: name,
                      value: name,
                      points,
                      radius: (radius ?? 0) * 1609,
                    })
                  ),
                },
              ],
              onClear() {
                clearCustomLocation();
              },
              async onSelect(value, option) {
                let destination = value;
                if (option?.points) {
                  if (!isYard(destination)) {
                    destination = findGeofenceCenterCoordinate(option?.points);
                  } else {
                    destination =
                      yards?.find(
                        ({ address }) => getFence(address) === getFence(value)
                      )?.position || yards?.[0]?.["position"];
                  }
                  renderCustomLocation({
                    address: value,
                    points: option?.points?.map(({ latitude, longitude }) => ({
                      lat: latitude,
                      lng: longitude,
                    })),
                    position: destination,
                    radius: option?.radius,
                  });
                } else {
                  message.loading({
                    content: "Loading location...",
                    key: "locationLoad",
                  });
                  try {
                    let location = await getCoordinatesAndZip(value);

                    message.destroy("locationLoad");
                    const { coordinates: position } = location;
                    renderCustomLocation({
                      address: value,
                      position,
                    });
                  } catch (err) {
                    message.error({
                      content: "Something went wrong while loading location",
                      key: "locationLoad",
                    });
                    console.log("Error loading location: ", err);
                  }
                }
              },
            }}
          />
        )}
        {viewMode === "MAP" && selectedVehicle && (
          <Tooltip title="Search Geofences" placement="bottom">
            {/** 
              //#region SEARCH GEOFENCES POPOVER
            */}
            <Popover
              placement="bottom"
              trigger={"click"}
              overlayClassName={`stop-modal-popover-content${
                isDarkMode ? " popover-dark" : ""
              }`}
              content={<RadiusPicker onConfirm={drawNearFences} />}
            >
              <MondayButton
                Icon={<SearchOutlined />}
                className="mondayButtonBlue"
              >
                {null}
              </MondayButton>
            </Popover>
          </Tooltip>
        )}
        {viewMode === "MAP" && selectedVehicle && (
          <Tooltip title="Date Filter" placement="bottom">
            {/** 
              //#region DATE FILTER POPOVER
            */}
            <Popover
              placement="bottom"
              content={<TripsPicker onConfirm={filterHandler} />}
              open={popoverFilter}
              trigger={"click"}
              overlayClassName={`stop-modal-popover-content${
                isDarkMode ? " popover-dark" : ""
              }`}
              onOpenChange={(open) => {
                setPopoverFilter(open.valueOf());
              }}
            >
              <MondayButton Icon={<FilterIcon />} className="mondayButtonBlue">
                {null}
              </MondayButton>
            </Popover>
          </Tooltip>
        )}
        {/** 
          //#region CHANGE PLAN
        */}
        {changePlanButton &&
          isWritable &&
          auditStatus !== "UPLOADED" &&
          selectedVehicle && (
            <MondayButton
              {...{
                Icon: planViewMode ? <EditIcon /> : <XIcon />,
                className: planViewMode
                  ? "mondayButtonBlue"
                  : "mondayButtonRed",
                onClick() {
                  onChangeInitialPlan();
                  setPlanViewMode(!planViewMode);
                  if (planViewMode) {
                    message.info({
                      content:
                        "Click on a dispatch timeline to change it or create a new dispatch",
                      key: "dispatchInfoMessage",
                    });
                  }
                },
                "data-testid": "change-plan-btn",
              }}
            >
              {planViewMode ? "Change Initial Plan" : "Close Plan Change"}
            </MondayButton>
          )}
        {/** 
            //#region CREATE DISPATCH
          */}
        {!planViewMode && (
          <MondayButton
            Icon={<AddIcon />}
            onClick={() => {
              openDispatch();
            }}
            data-testid="create-dispatch-btn"
          >
            Create Dispatch
          </MondayButton>
        )}
        {selectedVehicle && isWritable && auditStatus !== "UPLOADED" ? (
          <MondayButton
            {...{
              Icon: <AddIcon />,
              "data-testid": "create-event-btn",
              onClick() {
                setAddActivityModalOpen(true);
              },
            }}
          >
            Create Event
          </MondayButton>
        ) : null}
        <Tooltip title="Audit Report" placement="bottom">
          {/** 
            //#region AUDIT REPORT
          */}
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              "data-testid": "audit-report-modal",
              Icon: <Documentation />,
              onClick() {
                setAuditReportModal(true);
              },
            }}
          >
            {null}
          </MondayButton>
        </Tooltip>
        {/** 
          //#region REPORT MODAL
        */}
        <AuditReportModal
          {...{
            open: auditReportModal,
            onCancel() {
              setAuditReportModal(false);
            },
          }}
        />
        {/** 
          //#region REVERT AUDIT
        */}
        {selectedVehicle && isWritable && auditStatus !== "UPLOADED" ? (
          <Tooltip title="Revert audit changes" placement="bottom">
            <MondayButton
              {...{
                className: "mondayButtonRed",
                Icon: <TrashIcon />,
                "data-testid": "revert-audit-btn",
                onClick() {
                  revertAuditHandler();
                },
              }}
            >
              {null}
            </MondayButton>
          </Tooltip>
        ) : null}
        {/** 
          //#region ADD ACTUAL ACTIVITY
        */}
        {addActivityModalOpen ? (
          <AddActualActivityModal
            {...{
              open: addActivityModalOpen,
              onCancel() {
                setAddActivityModalOpen(false);
              },
            }}
          />
        ) : null}
        {/** 
          //#region WARNING MODAL
        */}
        {warningVisible && (
          <WarningModal
            visible={!!warningVisible}
            setVisible={setWarningVisible}
            darkMode={isDarkMode}
            title="Warning Message"
            closable={true}
            className="logout-warning-modal"
          >
            <div className="logout-modal-body">
              <span>
                <WarningIcon />
              </span>
              <p style={{ textAlign: "center" }}>
                {warningVisible === "UPLOAD"
                  ? `Are you sure you want to upload the audit? Once you upload you will not be able to make any more changes to the audit of ${dayjsNY(
                      selectedDate
                    ).format("MM DD, YYYY")} for ${
                      document.getElementById("selected-vehicle-name")
                        ?.innerText
                    }`
                  : warningVisible === "REVERT"
                  ? "Are you sure you want to revert the audit changes? All the changes you made until now will be lost."
                  : `There are missing dates from the last audit. The last uploaded record is of the date ${warningVisible?.replace(
                      "UPLOAD CONFIRM-",
                      ""
                    )}. Are you sure you want to proceed with the upload?`}
              </p>
              <div className="buttons">
                <MondayButton
                  onClick={() => setWarningVisible(false)}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={() => {
                    if (warningVisible === "UPLOAD") {
                      updateDriveHandler();
                    } else if (warningVisible.includes("UPLOAD CONFIRM")) {
                      updateDriveHandler(true);
                    } else if (warningVisible === "REVERT") {
                      revertAuditHandler(true);
                    }
                    setWarningVisible(false);
                  }}
                  Icon={<TickIcon />}
                  data-testid="warning-confirm-btn"
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}
      </div>
    </div>
  );
});

export default AuditController;

import dayjs from "dayjs";

const inputFormat = "hhmmA";

// Checks if the given due date and time are in the past, returning an object indicating the status of both.
export const isDueTimeInPast = (
  timeStr,
  dueDate,
  today = dayjs().valueOf()
) => {
  const dueDateObj = dayjs(dueDate, "MM/DD/YYYY").startOf("day");

  const normalizedTimeStr = timeStr.toUpperCase();

  const updatedTimeStr = dayjs(
    normalizedTimeStr,
    inputFormat.slice(0, normalizedTimeStr.length)
  ).valueOf();

  const isBefore = dueDateObj.isBefore(dayjs().startOf("day"), "day");
  const isAfter = dueDateObj.isAfter(dayjs().startOf("day"), "day");

  if (!!isBefore) {
    return { isDateInPast: true, isTimeInPast: true };
  } else if (!!isAfter) {
    return { isDateInPast: false, isTimeInPast: false };
  } else {
    return { isDateInPast: false, isTimeInPast: updatedTimeStr < today };
  }
};

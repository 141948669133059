import React, { useCallback, useMemo, useState } from "react";
import {
  Button,
  Checkbox,
  Input,
  message,
  Popover,
  Radio,
  Alert,
  Select,
  Tooltip,
  InputNumber,
} from "antd";
import { AgGridReact } from "ag-grid-react";
import columnDefinitions from "../../tools/columnDefiners";
import ReactQuill from "react-quill";
import { quillComponents } from "../../tools/constants";
import _ from "lodash";
import { rtfEditor } from "../index";
import { rtfEditorAddon } from "../index";
import CheckboxRenderer from "../cellRenderers/CheckboxRenderer";
// import SidewalkShedAddonsRenderer from "../SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRenderer";
import SidewalkShedAddonsDetail from "../SidewalkShed/SidewalkShedAddonsDetail";
import { createEmptyPLI } from "../../tools/polyfillers/PLICreator";
import { darkModeRowStyleRules } from "../../tools/formatters/GridStyles";
import Force from "../../../../../../icons/Data_Grid_Force 32px.webp";
import { generateRedColorByAmount } from "../../../../../pages/Settings/settingsComponents/Pricing/utils/colorGenerator";
import HoistCheckBox from "../cellRenderers/CheckboxRenderer/HoistCheckBox";
import SidewalkShedAddonsRenderer from "../SidewalkShed/SidewalkShedAddonsDetail/SidewalkShedAddonsRendererFunc";
import { useRedux } from "../../../../hooks";
import "./HoistFormFunc.scss";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { MondayButton } from "../../../../../commonComponents";
import AddServiceNote from "../ServiceHeader/ServiceActions/AddServiceNote/AddServiceNote";
import SelfCheck from "./subcomponents/SelfCheck/SelfCheck";
import GridData from "./subcomponents/GridData/GridData";
import { LockFilled, LockOutlined, ProductFilled } from "@ant-design/icons";
const { Option } = Select;

function HoistFormFunc(props) {
  const [darkMode, setDarkMode] = useRedux("estimationsDarkMode", true, false);
  let key = 0;
  const [gridApi, setGridApi] = useState(null);
  const [tempCommands, setTempCommands] = useState({
    selectSingleStop: false,
    selectDualStops: false,
    autoDistribution: true,
  });
  const [gridData, setTakeOffTableData] = useRedux("takeOffTableData");
  const [currentFocusedInitialValue, setCurrentFocusedInitialValue] =
    useState("");
  const { serviceId = 0 } = props;
  const isWritable = true;
  const frameworkComponents = {
    rtfEditor: rtfEditor,
    rtfEditorAddon: rtfEditorAddon,
    CheckboxRenderer: (params) => (
      <HoistCheckBox
        {...{
          ...params,
          handleCheckboxElementChange: handleCheckboxElementChange,
          indexes: props.indexes,
        }}
      />
    ),
    SidewalkShedAddonsRenderer: SidewalkShedAddonsRenderer,
    SidewalkShedAddonsDetail: SidewalkShedAddonsDetail,
  };
  const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
  const elevation =
    gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
  const { access } = elevation;

  const rowData = useMemo(() => {
    let temp =
      gridData[serviceIndex]?.serviceOptions[optionIndex][elevationIndex]
        ?.items || [];
    setTempCommands({
      ...tempCommands,
      selectSingleStop: !temp?.some((item) => item?.stop !== true),
      selectDualStops: !temp?.some((item) => item?.dual !== true),
    });
    return temp;
  }, [gridData]);

  console.log("rowData", elevation);
  const defaultColDef = {
    // minWidth: 150,
    // width: "auto",
    editable: true,
    filter: true,
    sortable: true,
    resizable: true,
    autoHeight: true,
    detailRowAutoHeight: true,
    enablePivot: true,
    enableRowGroup: true,
    cellClass: "cell-wrap-text",
  };

  const onGridReady = (params, data) => {
    setGridApi(params.api);

    // params.api.setRowData(data);
    // params.api.sizeColumnsToFit();
    const defaultSortModel = [{ colId: "id", sort: "asc" }];
    // columnDefinitions[this.props.serviceId]["test"].find(c => c.field === "stop").cellRendererParams =
    params.columnApi.applyColumnState({ state: defaultSortModel }); //sort by default by id (which is the floor number)

    params.api.closeToolPanel();
  };
  const sizeColumnsToFit = () => {
    gridApi.sizeColumnsToFit();
  };
  const cellEditingStarted = () => {
    if (!props.isWritable) {
      gridApi.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  console.log("gridData", gridData);
  const getGridData = useCallback(() => {
    return structuredClone(gridData);
  }, [gridData]);

  const cellValueChanged = (params) => {
    if (isWritable) {
      let field = params.colDef.field;
      if (field === "floor_height" || field === "floor_height_converted") {
        console.log("cellValueHoist", params);
        //if floor height is modified, set floor to locked status. its height can be only reset by "force calculate button"
        // get ag grid row data
        let refVal =
          field === "floor_height"
            ? params.newValue
            : convertToFt(params.newValue);

        console.log("refVal", { refVal, field });
        // if (!params?.newValue > 0) {
        //   message.error(
        //     "Floor height must be greater than 0. Otherwise, delete the floor."
        //   );
        //   return;
        // } else {
        const items = [];

        gridApi.forEachNode((node) => {
          items.push(node.data);
        });

        const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
        const newGridData = getGridData();
        const elevation =
          newGridData?.[serviceIndex].serviceOptions[optionIndex][
            elevationIndex
          ];
        const currentFloor = params?.data?.floor || 0;

        let FLOOR_HEIGHT_SUM = 0;

        //TODO: New Logic
        let lockedHeightSum = forceToNumber(
          items?.reduce((acc, item) => {
            if (item.locked) {
              acc += item?.floor_height;
            }
            return acc;
          }, 0)
        );
        let BUILDING_HEIGHT = Math.round(elevation.building_height * 100) / 100;

        for (const floor of items) {
          if (floor.floor === currentFloor) {
            floor.floor_height = Number(Number(refVal).toFixed(2));
            lockedHeightSum += floor.floor_height;
            floor.locked = true;
            break;
            // FLOOR_HEIGHT_SUM += Number(params.newValue || 0);
          }
          //  else {
          //   FLOOR_HEIGHT_SUM += Number(floor?.floor_height || 0);
          // }
        }

        FLOOR_HEIGHT_SUM = Math.round(FLOOR_HEIGHT_SUM * 100) / 100;

        // let difference =
        //   Math.round((BUILDING_HEIGHT - FLOOR_HEIGHT_SUM) * 100) / 100;

        // let countNum = items?.filter(
        //   (floor) =>
        //     floor.floor > currentFloor &&
        //     floor.floor_height > 0 &&
        //     !floor.locked
        // ).length;

        // const tempAm = difference / countNum;
        // console.log("checking", {
        //   currentFloor,
        //   floorHeightSum: FLOOR_HEIGHT_SUM,
        //   building_height: BUILDING_HEIGHT,
        //   difference,
        //   countNum,
        //   tempAm,
        //   elevation,
        // });
        // let balance = difference;

        // items?.forEach((floor) => {
        //   if (floor.floor > currentFloor && floor?.locked !== true) {
        //     const temp = Number(floor.floor_height) + Number(tempAm);
        //     floor.floor_height = Number(temp > 0 ? temp?.toFixed(2) || 0 : 0);
        //   }
        // });
        // elevation.items = items;
        let unlocked =
          items?.filter((floor) => floor?.locked !== true)?.length || 1;
        //TODO: New Logic
        let distributedAmount =
          (forceToNumber(BUILDING_HEIGHT) - forceToNumber(lockedHeightSum)) /
          unlocked;
        distributedAmount = Math.round(distributedAmount * 100) / 100;

        items?.forEach((floor) => {
          if (floor?.locked !== true && tempCommands.autoDistribution) {
            // const temp = Number(floor.floor_height) + Number(tempAm);
            // floor.floor_height = Number(temp > 0 ? temp?.toFixed(2) || 0 : 0);
            floor.floor_height = Number(distributedAmount);
          }
        });
        elevation.items = items;

        // console.log("cell value changed", {
        //   params,
        //   tempAm,
        //   elevation,
        //   difference,
        //   newGridData,
        // });
        newGridData[serviceIndex].serviceOptions[optionIndex][
          elevationIndex
        ].items = items;
        // params.data.lock = true;
        gridApi.applyTransaction({ update: items }); //reset rows
        gridApi.refreshCells({
          columns: ["floor_height"],
        });
        // gridApi.redrawRows({ rowNodes: [params.api.getRowNode(params.data.id)] });
        // setTakeOffTableData(newGridData);
        // props.updateStateAndSave(newGridData);
        // this.props.updateStateAndSave(newGridData);
        // this.gridApi.redrawRows({
        //   rowNodes: [params.api.getRowNode(params.data.id)],
        // });
      }
    }
    // props.handleSave(false);
    // }
  };

  // called after input element is selected
  const handleInputElementFocus = (e) => {
    setCurrentFocusedInitialValue(e.target.value);
  };

  console.log("hoistttt", props);

  const calculateFloorHeightsByType = (elevation) => {
    if (!tempCommands.autoDistribution) return;
    const { items, undergroundHeight, building_height } = elevation;

    const lockedFloors = items.filter(({ locked = false }) => !locked);

    const [
      [unlockedUndergroundFloors, unlockedAboveGroundFloors],
      [lockedUndergroundFloors, lockedAboveGroundFloors],
    ] = items.reduce(
      (acc, curr) => (acc[+!!curr.lock][+(curr.floor > 0)].push(curr), acc),
      [
        [[], []],
        [[], []],
      ]
    );

    const [lockedUndergroundFloorsHeight, lockedAboveGroundFloorsHeight] =
      lockedFloors.reduce(
        (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
        [0, 0]
      );

    let remainingUndergroundHeight =
      undergroundHeight - lockedUndergroundFloorsHeight;
    remainingUndergroundHeight = forceToNumber(remainingUndergroundHeight || 0);
    let remainingBuildingHeight =
      building_height - lockedAboveGroundFloorsHeight;
    remainingBuildingHeight = forceToNumber(remainingBuildingHeight || 0);

    const calculatedFloors = [
      ...lockedUndergroundFloors,
      ...lockedAboveGroundFloors,
      ...unlockedUndergroundFloors.map((floor) => ({
        ...floor,
        floor_height: +(
          remainingUndergroundHeight / unlockedUndergroundFloors.length
        ).toFixed(2),
      })),

      ...unlockedAboveGroundFloors.map((floor) => ({
        ...floor,
        floor_height: +(
          remainingBuildingHeight / unlockedAboveGroundFloors.length
        ).toFixed(2),
      })),
    ];

    elevation.items = calculatedFloors;

    // const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    // let newGridData = _.cloneDeep(gridData);

    // newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex] =
    //   elevation;
    // console.log("newGridData", newGridData);
    // setTakeOffTableData(newGridData);

    return calculatedFloors;
  };

  // called after input element is deselected
  const handleInputElementBlur = (e) => {
    if (e.target.value !== currentFocusedInitialValue && isWritable) {
      props.handleSave(false);
    }
  };

  // handles input change
  const handleInputElementChange = (
    e,
    itemReference,
    biggerThanZeo,
    saveInDatabase,
    skipInitialize = false
  ) => {
    if (!isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }

    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    let newGridData = _.cloneDeep(gridData);
    const elevation =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    let value = e.target.value;

    //check what type of value we are getting
    if (biggerThanZeo && !skipInitialize)
      value = parseFloat(value) > 0 ? value : 1;

    elevation[itemReference] = value;
    console.log("itemReference", itemReference);
    switch (itemReference) {
      case "undergroundHeight":
      case "building_height":
        elevation.totalHeight =
          forceToNumber(elevation.undergroundHeight) +
          forceToNumber(elevation.building_height);
        elevation.liftHeight = elevation.mastHeight + 20;

        // const affectedFloors = elevation.items.filter(({ floor }) =>
        //   itemReference === "undergroundHeight" ? floor < 0 : floor > 0
        // )

        // elevation.items = elevation.items.map((item) =>
        //   affectedFloors.some(({ id }) => item.id === id)
        //     ? {
        //         ...item,
        //         floor_height: +(
        //           +elevation[itemReference] / affectedFloors.length
        //         ).toFixed(2),
        //       }
        //     : item
        // )

        // this.calculateFloorHeights(elevation.items, elevation.totalHeight)

        calculateFloorHeightsByType(elevation);

        break;
      case "mastHeight":
        // const initialTotalHeight = elevation.totalHeight;
        // elevation.totalHeight = +elevation.mastHeight - 20;
        // const difference = elevation.totalHeight - initialTotalHeight;

        // elevation.building_height = (
        //   (+elevation.building_height / +elevation.totalHeight) * difference +
        //   +elevation.building_height
        // ).toFixed(2);
        // elevation.undergroundHeight = (
        //   (+elevation.undergroundHeight / +elevation.totalHeight) * difference +
        //   +elevation.undergroundHeight
        // ).toFixed(2);

        // // this.calculateFloorHeights(elevation.items, elevation.totalHeight)
        // // ;[elevation.undergroundHeight, elevation.building_height] =
        // //   elevation.items.reduce(
        // //     (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
        // //     [0, 0]
        // //   )
        // calculateFloorHeightsByType(elevation);

        break;
      case "totalHeight":
        const diff = +elevation.totalHeight - (elevation.mastHeight - 20);
        elevation.mastHeight = +elevation.liftHeight + 20;
        // this.calculateFloorHeights(elevation.items, elevation.totalHeight)
        // ;[elevation.undergroundHeight, elevation.building_height] =
        //   elevation.items.reduce(
        //     (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
        //     [0, 0]
        //   )

        elevation.building_height = (
          (+elevation.building_height / +elevation.totalHeight) * diff +
          +elevation.building_height
        ).toFixed(2);

        elevation.undergroundHeight = (
          ((+elevation.undergroundHeight || 0) / +elevation.totalHeight) *
            diff +
          (+elevation.undergroundHeight || 0)
        ).toFixed(2);

        calculateFloorHeightsByType(elevation);

        break;
      case "lift_height":
        elevation.mastHeight = forceToNumber(elevation.lift_height) + 20;
        break;
      default:
        break;
    }
    // elevation.lift_height = Number(elevation.mastHeight) + 20;

    gridApi.applyTransaction({ update: elevation.items }); //reset rows
    gridApi.redrawRows();

    // if (itemReference === "building_height") {
    //   this.calculateFloorHeights(elevation.items, elevation.building_height)
    //   elevation.lift_height = this.get_lift_height_FROM_building_height(
    //     elevation.building_height
    //   )

    //   this.gridApi.applyTransaction({ update: elevation.items }) //reset rows
    //   this.gridApi.redrawRows()
    // }
    console.log("we change it here", elevation);
    setTakeOffTableData(newGridData);
    if (saveInDatabase) props.updateStateAndSave(newGridData);
    // else this.props.setState({ gridData });

    // const {serviceIndex, optionIndex, elevationIndex} = this.props.indexes;
    // this.props.handleInputElementChange(e, serviceIndex, optionIndex, elevationIndex, itemReference);
  };

  // handles radio button change
  const handleRadioButtonsGroupElementChange = (e, itemReference) => {
    console.log("itemReference", itemReference, e);
    if (!isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    let newGridData = _.cloneDeep(gridData);
    _.update(
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
      itemReference,
      () => e.target.value
    );
    if (e?.target?.value === "single") {
      newGridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.forEach((item) => {
        item.dual = false;
      });
    } else if (e?.target?.value === "dual") {
      newGridData[serviceIndex].serviceOptions[optionIndex][
        elevationIndex
      ].items.forEach((item) => {
        item.dual = true;
      });
    }
    setTakeOffTableData(newGridData);
    // this.props.updateStateAndSave(gridData);
  };

  // handles checkbox change for all floors selections
  const handleSelectAllFloors = (e, itemReference) => {
    console.log("item ref", itemReference);
    if (!isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }
    setTempCommands({
      ...tempCommands,
      [itemReference === "dual" ? "selectDualStops" : "selectSingleStop"]:
        e.target.checked,
    });
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    let newGridData = _.cloneDeep(gridData);
    newGridData[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items.forEach((item) => {
      // item.stop = e.target.checked;
      // item.dual = e.target.checked;
      item[itemReference] = e.target.checked;
    });
    const elevation =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    const floors = elevation.items;
    console.log("elevation floor", elevation);
    // const floors = this.generateFloors(typedValue, elevation.to, elevation.items || []); //receive floors object (some data are taken from state)

    const { building_height, undergroundHeight } = elevation;
    const [undergroundFloors, aboveGroundFloors] = floors?.reduce(
      (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
      [[], []]
    );

    const calculatedFloors = [
      ...undergroundFloors.map((floor) => ({
        ...floor,
        stop: itemReference === "stop" ? e.target.checked : floor.stop,
        dual: itemReference === "dual" ? e.target.checked : floor.dual,

        floor_height: +(undergroundHeight / undergroundFloors.length).toFixed(
          2
        ),
      })),
      ...aboveGroundFloors.map((floor) => ({
        ...floor,
        stop: itemReference === "stop" ? e.target.checked : floor.stop,
        dual: itemReference === "dual" ? e.target.checked : floor.dual,

        floor_height: +(building_height / aboveGroundFloors.length).toFixed(2),
      })),
    ];

    gridApi.setRowData(calculatedFloors); //reset rows
    gridApi.redrawRows();
    // console.log("grid data", gridData);
    // _.update(
    // 	gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
    // 	itemReference,
    // 	() => e.target.value
    // );
    // this.gridApi = params.api;

    // this.gridApi.setRowData(test);
    // this.gridApi.sizeColumnsToFit();
    // console.log("grid", this.gridApi);
    // console.log("floor dta", test);
    // props.updateStateAndSave(newGridData);
    // props.setState({ gridData: newGridData });
    setTakeOffTableData(newGridData);

    // this.gridApi.redrawRows();
  };

  // handles with shoring checkbox change in hoist ramps
  const handleCheckboxElementChange = (e, itemReference) => {
    // if (!this.props.isWritable) {
    //   //if not in write mode just warn user and don't update anything
    //   message.error("Please enable write mode to start making changes");
    //   return;
    // }
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    let newGridData = _.cloneDeep(gridData);
    _.update(
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex],
      itemReference,
      () => e.target.checked
    );
    setTakeOffTableData(newGridData);
    // this.props.updateStateAndSave(gridData);
    // this.props.handleSave(false);

    // if (this.props.isWritable) {
    //   this.props.handleSave(false);
    // }
  };

  /**
   * This function will handle changes of "From" and "To". From will determine the lowest floor and To the highest.
   * This will show rows in ag-grid table in the selected range. Existing rows will be removed if the lower boundary is higher than the old lower boundary.
   * This function can update in state from, to and floors.
   * @example having from -2 and to 6, will render 7 rows, one for each intermediate floors.
   * @param e {SyncEvent}
   * @param e.target.value {String}
   * @param FromTo {'from' | 'to'}
   * */
  const handleBoundariesChange = (e, FromTo) => {
    if (!isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    const typedValue = parseInt(e); //the number typed in from or to input
    const newGridData = _.cloneDeep(gridData);
    const elevation =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    if (FromTo === "from") {
      //if user has typed in from input
      if (typedValue > 130) {
        message.error("Abnormal height");
        return;
      }
      if (typedValue < -4) {
        //the lowest floor this software is supporting is -4
        message.warning("The lowest floor is -4 (sub-sub-sub-cellar)");
        return;
      }
      if (elevation.to !== undefined) {
        //if there is a defined upper boundary from before
        const floors = generateFloors(
          typedValue,
          elevation.to,
          elevation.items || []
        ); //receive floors object (some data are taken from state)

        // this.calculateFloorHeights(floors, elevation.building_height)
        const { building_height, undergroundHeight } = elevation;
        const [undergroundFloors, aboveGroundFloors] = floors?.reduce(
          (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
          [[], []]
        );
        const calculatedFloors = [
          ...undergroundFloors.map((floor) => ({
            ...floor,
            floor_height: tempCommands?.autoDistribution
              ? +(undergroundHeight / undergroundFloors.length).toFixed(2)
              : floor.floor_height,
          })),
          ...aboveGroundFloors.map((floor) => ({
            ...floor,
            floor_height: tempCommands?.autoDistribution
              ? +(building_height / aboveGroundFloors.length).toFixed(2)
              : floor.floor_height,
          })),
        ];

        gridApi.setRowData(calculatedFloors); //reset rows
        gridApi.redrawRows();

        // this.gridApi.refreshCells({columns: ["floor_height", "description", "note"]})
        // this.setState({ floors: calculatedFloors }); //set the typed value and floors in state

        elevation.items = calculatedFloors;
        elevation.from = typedValue;
        setTakeOffTableData(newGridData);
        props.setState({ gridData: gridData });
      } else {
        //if the upper boundary is not defined before
        elevation.from = typedValue;
        setTakeOffTableData(newGridData);
        // this.props.setState({ gridData: gridData }); //just update in state from. There is no row to set
      }
    } else if (FromTo === "to") {
      //if user has typed in to input
      if (elevation.from !== undefined) {
        //if there is a defined upper boundary from before
        const floors = generateFloors(
          elevation.from,
          typedValue,
          elevation.items || []
        ); //receive floors object (some data are taken from state)

        // this.calculateFloorHeights(floors, elevation.building_height)

        const { building_height, undergroundHeight } = elevation;
        const [undergroundFloors, aboveGroundFloors] = floors.reduce(
          (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
          [[], []]
        );
        const calculatedFloors = [
          ...undergroundFloors.map((floor) => ({
            ...floor,
            floor_height: tempCommands?.autoDistribution
              ? Number(undergroundHeight / undergroundFloors.length).toFixed(2)
              : floor.floor_height,
          })),
          ...aboveGroundFloors.map((floor) => ({
            ...floor,
            floor_height: tempCommands?.autoDistribution
              ? Number(building_height / aboveGroundFloors.length)?.toFixed(2)
              : floor.floor_height,
          })),
        ];

        gridApi.setRowData(calculatedFloors); //reset rows
        gridApi.redrawRows();
        // this.gridApi.refreshCells({columns: ["floor_height", "description", "note"]})

        // this.setState({ floors: calculatedFloors }); //set the typed value and floors in state

        elevation.items = calculatedFloors;
        elevation.to = typedValue;
        setTakeOffTableData(newGridData);
        // this.props.setState({ gridData: gridData });
        // this.key++
      } else {
        //if the upper boundary is not defined before
        elevation.to = typedValue;
        setTakeOffTableData(newGridData);
        // this.props.setState({ gridData: gridData }); //just update in state from. There is no row to set
      }
    }
  };

  /**
   * This function will generate floor, to then be used by ag-grid table rows. This function takes existing floors from this.state.floors (but does not modify values).
   * New floors will be generated with floor number as id and other data will be empty.
   * @param from {number} The lowest floor
   * @param to {number} the highest floor
   * @param existingFloors {{id: number, floor: number, stop: boolean, addons: []}[]}
   * @return {Object} A map like {1: {floor data}, 2: {floor data}}
   * */
  const generateFloors = (from, to, existingFloors) => {
    let floors = []; //create a temporary floors object, it will be same as the one in state
    console.log("existed", floors);

    if (from === 0 || to === 0) return existingFloors;

    for (let floorNumber = from; floorNumber <= to; floorNumber++) {
      if (floorNumber === 0) continue; //floor 0 does not exist

      let floor = existingFloors.find((f) => f.floor === floorNumber);
      // let id = floorNumber - from + 1
      let id = (_.max(floors.map(({ id }) => id)) || 0) + 1;
      if (floor) {
        //if value exist
        floor.id = id;
        // floors[i] = this.state.floors[i]
      } else {
        //if value does not exist
        floor = createEmptyPLI(3, id);
        floor.floor = floorNumber;
        floor.dual = tempCommands.selectDualStops;
        floor.stop = tempCommands.selectSingleStop;
      }
      floors.push(floor);
    }

    return floors;
  };

  const calculateFloorHeights = (
    floors,
    building_height,
    forced,
    reference
  ) => {
    if (!tempCommands?.autoDistribution) return;
    let floor_height;
    if (forced) {
      //if it is a forced calculation does not count which floor is locked or not
      floor_height = Math.round((building_height / floors.length) * 100) / 100;
    } else {
      const unlockedFloorsCount = floors.filter((f) => f.lock !== true).length;
      let remainingBuildingHeight = building_height; //subtract floors height of locked floors from total height
      for (const floor of floors) {
        if (floor.lock) remainingBuildingHeight -= floor.floor_height;
      }
      floor_height = Number(
        Math.round((remainingBuildingHeight / unlockedFloorsCount) * 100) / 100
      ).toFixed(2);
    }

    // floor_height = floor_height > 0 ? floor_height : "INVALID";
    floor_height = floor_height > 0 ? floor_height : "-";

    if (forced) {
      for (const floor of floors) {
        floor.floor_height = floor_height;
      }
    } else {
      for (const floor of floors) {
        if (floor.lock !== true) floor.floor_height = floor_height;
      }
    }

    const calculateFloorHeightsByType = (elevation) => {
      const { items, undergroundHeight, building_height } = elevation;

      const lockedFloors = items.filter(({ lock }) => !!lock);

      const [
        [unlockedUndergroundFloors, unlockedAboveGroundFloors],
        [lockedUndergroundFloors, lockedAboveGroundFloors],
      ] = items.reduce(
        (acc, curr) => (acc[+!!curr.lock][+(curr.floor > 0)].push(curr), acc),
        [
          [[], []],
          [[], []],
        ]
      );

      const [lockedUndergroundFloorsHeight, lockedAboveGroundFloorsHeight] =
        lockedFloors.reduce(
          (acc, curr) => ((acc[+(curr.floor > 0)] += curr.floor_height), acc),
          [0, 0]
        );

      const remainingUndergroundHeight =
        undergroundHeight - lockedUndergroundFloorsHeight;
      const remainingBuildingHeight =
        building_height - lockedAboveGroundFloorsHeight;

      const calculatedFloors = [
        ...lockedUndergroundFloors,
        ...lockedAboveGroundFloors,
        ...unlockedUndergroundFloors.map((floor) => ({
          ...floor,
          floor_height: +(
            remainingUndergroundHeight / unlockedUndergroundFloors.length
          ).toFixed(2),
        })),

        ...unlockedAboveGroundFloors.map((floor) => ({
          ...floor,
          floor_height: +(
            remainingBuildingHeight / unlockedAboveGroundFloors.length
          ).toFixed(2),
        })),
      ];

      elevation.items = calculatedFloors;
      return calculatedFloors;
    };

    return floors;
  };

  /**
   * @param style {'unchanged'|'all'}
   * */
  const setFloorHeights = (style) => {
    if (!isWritable) {
      //if not in write mode just warn user and don't update anything
      message.error("Please enable write mode to start making changes");
      return;
    }

    const newGridData = _.cloneDeep(gridData);
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    const elevation =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    const { undergroundHeight, building_height, items } = elevation;

    // if (style === "unchanged")
    //   this.calculateFloorHeights(
    //     elevation.items,
    //     elevation.building_height,
    //     false
    //   )
    // else
    //   this.calculateFloorHeights(
    //     elevation.items,
    //     elevation.building_height,
    //     true
    //   )

    if (style === "unchanged") {
      calculateFloorHeights(elevation.items, elevation.building_height, false);
    } else {
      const sortedHeights = [undergroundHeight, building_height];
      const sortedFloors = items.reduce(
        (acc, curr) => (acc[+(curr.floor > 0)].push(curr), acc),
        [[], []]
      );

      elevation.items = sortedFloors.flatMap((floorGroup, idx) =>
        floorGroup.map((floor) => ({
          ...floor,
          floor_height: +(sortedHeights[idx] / floorGroup.length).toFixed(2),
          lock: false,
        }))
      );
    }

    gridApi.applyTransaction({ update: elevation.items }); //reset rows
    // this.gridApi.refreshCells({columns: ["floor_height", "description", "note"]})
    gridApi.redrawRows();
    // this.setState({
    //   floors: elevation.items,
    //   floorHeightPopoverVisible: false,
    // }); //set the typed value and floors in state
    setTakeOffTableData(newGridData);
    //  props.updateStateAndSave(gridData);
  };

  const lockAllFloors = () => {
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    const newGridData = _.cloneDeep(gridData);
    const elevation =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    console.log("lock elevations", elevation);
    elevation.items.forEach((floor) => {
      floor.locked = true;
    });
    let data = [];
    gridApi.forEachNode((node) => {
      data.push({ ...node.data, locked: true });
    });
    // gridApi.setRowData(data); //reset rows
    // gridApi.redrawRows();
    gridApi.applyTransaction({ update: data }); //reset rows
    gridApi.refreshCells({
      columns: ["locked"],
    });

    setTakeOffTableData(newGridData);
    props.handleSave(true, true);
    // // this.setState({ floors: calculatedFloors }); //set the typed value and floors in state

    // elevation.items = calculatedFloors;
    // elevation.from = typedValue;
    // props.setState({ gridData: gridData });
  };

  const get_lift_height_FROM_building_height = (building_height) => {
    const remainder = building_height % 5;
    if (remainder === 0) {
      return building_height;
    } else {
      return parseFloat(building_height) + (5 - remainder);
    }
  };

  const saveDataFromRTDEditor = (
    items,
    serviceIndex,
    optionIndex,
    elevationIndex
  ) => {
    message.info("update", 10);
    console.log(items, "items");
    console.log(serviceIndex, "serviceIndex");
    console.log(optionIndex, "optionIndex");
    console.log(elevationIndex, "elevationIndex");
    // const { gridData, updateStateAndSave } = this.props;

    const gridDataCopy = getGridData();
    console.log("got darta", gridDataCopy);
    gridDataCopy[serviceIndex].serviceOptions[optionIndex][
      elevationIndex
    ].items = items;
    setTakeOffTableData(gridDataCopy);

    // updateStateAndSave(gridDataCopy);
  };

  const getColumnDefs = () => {
    const {
      // gridData, isWritable,
      agGridTheme,
      saveSidewalkShedAddonsData,
    } = props;
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;

    const elevation =
      gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];

    console.log("elevation", elevation);

    const columnDefs = columnDefinitions[props.serviceId];

    columnDefs.find((d) => d.field === "stop").cellRendererParams = {
      isWritable,
    };

    columnDefs.find((d) => d.field === "dual").cellRendererParams = {
      isWritable,
    };

    columnDefs.find((d) => d.field === "id").cellClass = "id-column";

    const rtfEditorColumns = columnDefs.filter(
      (cd) => cd.cellRenderer === "rtfEditor"
    );
    for (const rtfEditorColumn of rtfEditorColumns) {
      rtfEditorColumn.editable = false;
      rtfEditorColumn.cellRendererParams = {
        ...rtfEditorColumn.cellRendererParams,
        gridData,
        isWritable,
        serviceIndex,
        optionIndex,
        elevationIndex,
        saveDataFromRTDEditor: saveDataFromRTDEditor,
        from: "pli",
        agGridTheme,
      };
    }

    const addonsField = columnDefs.find((d) => d.field === "addons");
    addonsField.cellRendererParams = {
      ...addonsField.cellRendererParams,
      ...{
        gridData,
        isWritable,
        serviceIndex,
        optionIndex,
        elevationIndex,
        saveSidewalkShedAddonsData,
      },
    };

    const floor_height = columnDefs.find((d) => d.field === "floor_height");
    floor_height.cellStyle = (params) => {
      //find min and max from floor_height
      let min = params.api.getRowNode(1)?.data?.floor_height || 0,
        max = 0;

      params.api.forEachNode((node) => {
        const v = parseFloat(node.data.floor_height);
        if (v < min) min = v;
        if (v > max) max = v;
      });

      if (params.data.lock === true)
        return { color: generateRedColorByAmount(min, max, params.value) };
      return null;
    };
    return elevation.type === "single"
      ? columnDefs.flatMap((columnDef) =>
          columnDef.field === "dual"
            ? {
                ...columnDef,
                hide: true,
              }
            : columnDef
        )
      : columnDefs;
  };

  const switchAutoDistribution = () => {
    const isActive = tempCommands?.autoDistribution;
    setTempCommands((prev) => ({
      ...prev,
      autoDistribution: !isActive,
    }));
  };

  const onBetaMax = () => {
    const newGridData = _.cloneDeep(gridData);
    const { serviceIndex, optionIndex, elevationIndex } = props.indexes;
    const elevation =
      newGridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
    if (elevation?.hoistServiceType === "betaMax") {
      elevation.hoistServiceType = "normal";
    } else {
      elevation.hoistServiceType = "betaMax";
    }
    setTakeOffTableData(newGridData);
    console.log("myElevation", elevation);
  };

  const getStopsCount = (items) => {
    let count = 0;
    for (let i = 0; i < items.length; i++) {
      if (items[i]?.stop === true) ++count;
    }
    return count;
  };

  return (
    <div className="hoistForm">
      <div className="hoistFormSectionParent firstHeader">
        {checkAccess(serviceId, "type") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Type:</div> */}
            <Radio.Group
              defaultValue="dual"
              buttonStyle="solid"
              value={elevation.type}
              style={{ border: "none" }}
              onChange={(e) => {
                handleRadioButtonsGroupElementChange(e, "type");
              }}
            >
              <Radio.Button style={{ border: "none" }} value="single">
                Single Hoist
              </Radio.Button>
              <Radio.Button style={{ border: "none" }} value="dual">
                Dual Hoist
              </Radio.Button>
            </Radio.Group>
          </div>
        )}
        {checkAccess(serviceId, "dimensions") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Dimensions:</div> */}
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="L"
                // type="number"
                // min={1}
                // defaultValue={elevation.length}
                value={elevation.length}
                // onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "length", true, false, true);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (regex.test(val)) {
                    handleInputElementChange(e, "length", true, false, true);
                  } else {
                    message.info(
                      `Please enter the length in the format e.g 10'11"`
                    );
                    handleInputElementChange(
                      {
                        target: { value: "" },
                      },
                      "length",
                      true,
                      false,
                      true
                    );
                  }
                }}
                // suffix={"' ''"}
              />
            </div>
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="W"
                // type="number"
                // min={1}
                value={elevation.width}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "width", true, false, true);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (regex.test(val)) {
                    handleInputElementChange(e, "width", true, false, true);
                  } else {
                    message.info(
                      "Please enter the width in the format e.g 10'11 "
                    );
                    handleInputElementChange(
                      {
                        target: { value: "" },
                      },
                      "width",
                      true,
                      false,
                      true
                    );
                  }
                }}
              />
            </div>
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="H"
                // type="number"
                // min={1}
                value={elevation?.height}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  console.log("e", e);
                  handleInputElementChange(e, "height", true, false, true);
                }}
                onBlur={(e) => {
                  const val = e.target.value;
                  if (regex.test(val)) {
                    handleInputElementChange(e, "height", true, false, true);
                  } else {
                    message.info(
                      "Please enter the height in the format e.g 10'11 "
                    );
                    handleInputElementChange(
                      {
                        target: { value: "" },
                      },
                      "height",
                      true,
                      false,
                      true
                    );
                  }
                }}
              />
            </div>
          </div>
        )}
        {checkAccess(serviceId, "selectAllFloors") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Select All Floors:</div> */}
            <div style={{ display: "flex", marginTop: "10px" }}>
              <Checkbox
                className="hoistFormLabel"
                style={
                  tempCommands?.selectSingleStop
                    ? {}
                    : {
                        background: "red",
                        padding: "0 5px",
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "center",
                        borderRadius: "5px",
                      }
                }
                checked={tempCommands?.selectSingleStop}
                onChange={(e) => handleSelectAllFloors(e, "stop")}
              >
                Car 1
              </Checkbox>
              {elevation?.type === "dual" && (
                <Checkbox
                  className="hoistFormLabel"
                  style={
                    tempCommands?.selectDualStops
                      ? {}
                      : {
                          background: "red",
                          padding: "0 5px",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          borderRadius: "5px",
                        }
                  }
                  checked={tempCommands?.selectDualStops}
                  // checked={elevation?.items?.every((item) => item.dual)}
                  onChange={(e) => handleSelectAllFloors(e, "dual")}
                >
                  Car 2
                </Checkbox>
              )}
            </div>

            {/* <Radio.Group
        defaultValue="single"
        buttonStyle="solid"
        value={elevation.type}
        onChange={(e) => {
          this.handleRadioButtonsGroupElementChange(e, "selectAllFloors");
        }}
      >
        <Radio.Button value="single">Single Hoist</Radio.Button>
        <Radio.Button value="dual">Dual Hoist</Radio.Button>
      </Radio.Group> */}
          </div>
        )}
        {checkAccess(serviceId, "location") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Location:</div> */}
            <Select
              defaultValue="front"
              className="hoistFormSelect"
              value={elevation.location}
              onChange={(value) => {
                handleRadioButtonsGroupElementChange(
                  { target: { value } },
                  "location"
                );
              }}
            >
              <Option value="front">Front</Option>
              <Option value="rear">Rear</Option>
              <Option value="side">Side</Option>
              <Option value="courtyard">Courtyard</Option>
            </Select>
          </div>
        )}
        {checkAccess(serviceId, "access") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Access:</div> */}
            <Radio.Group
              defaultValue="direct"
              buttonStyle="solid"
              value={elevation.access}
              onChange={(e) => {
                handleRadioButtonsGroupElementChange(e, "access");
              }}
            >
              <Radio.Button value="direct">Direct</Radio.Button>
              <Radio.Button value="nonDirect">Non-direct</Radio.Button>
            </Radio.Group>
          </div>
        )}
        {access === "nonDirect" && (
          <div
            className="hoistFormSection"
            style={{
              height: "80px",
              display: "flex",
              alignItems: "center",
            }}
          >
            <AddServiceNote
              {...{
                isWritable: isWritable,
                themeType: "dark",
                customNoteLabel: "NDA Note",
                addNoteForService: (note) => {
                  handleInputElementChange(
                    { target: { value: note } },
                    "accessNote"
                  );
                },
                service: {
                  label: "Non-direct Access Note",
                  note: elevation?.accessNote || "",
                },
              }}
            />
            {/* <ReactQuill
                className={"darkModeQuill "}
                readOnly={!isWritable}
                theme="snow"
                {...quillComponents}
                defaultValue={elevation.accessNote}
                // onFocus={(range, source, editor) => {
                //   handleInputElementFocus({
                //     target: { value: editor.getHTML() },
                //   });
                // }}
                // onChange={(content, delta, source, editor) => {
                //   handleInputElementChange(
                //     { target: { value: editor.getHTML() } },
                //     "accessNote"
                //   );
                // }}
                onBlur={(range, source, editor) => {
                  handleInputElementBlur({
                    target: { value: editor.getHTML() },
                  });
                }}
              /> */}
          </div>
        )}
      </div>

      <div className="hoistFormSectionParent secondHeader">
        <div className="hoistFormSection">
          {/* <div className="hoistFormLabel">Heights</div> */}
          {checkAccess(serviceId, "underground_height") && (
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "200px" }}
                addonBefore="Underground"
                defaultValue={
                  elevation.undergroundHeight === "" ||
                  elevation.undergroundHeight === undefined
                    ? 0
                    : elevation.undergroundHeight
                }
                type="number"
                value={elevation.undergroundHeight}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "undergroundHeight");
                }}
                onBlur={handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
          )}
          {checkAccess(serviceId, "building_height") && (
            <div className="hoistFormSectionChild">
              <Input
                style={{ width: "150px" }}
                addonBefore="Building"
                type="number"
                value={elevation.building_height}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "building_height", true);
                }}
                onBlur={handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
          )}
          {checkAccess(serviceId, "total_height") && (
            <div className="hoistFormSectionChild">
              <Input
                addonBefore="Total"
                disabled={true}
                type="number"
                style={{ width: "150px" }}
                value={elevation.totalHeight}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "totalHeight", true);
                }}
                onBlur={handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
          )}
          {checkAccess(serviceId, "lift_height") && (
            <div className="hoistFormSectionChild">
              <Input
                addonBefore="Lift"
                type="number"
                value={elevation.lift_height}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "lift_height", true);
                }}
                onBlur={handleInputElementBlur}
                suffix={"ft"}
                style={{ width: "150px" }}
                min={0}
              />{" "}
              {/* {message2} */}
            </div>
          )}
          {checkAccess(serviceId, "mast_height") && (
            <div className="hoistFormSectionChild">
              <Input
                addonBefore="Mast"
                style={{ width: "150px" }}
                type="number"
                value={elevation.mastHeight}
                onFocus={handleInputElementFocus}
                onChange={(e) => {
                  handleInputElementChange(e, "mastHeight", true);
                }}
                onBlur={handleInputElementBlur}
                suffix={"ft"}
                min={0}
              />
            </div>
          )}
          {checkAccess(serviceId, "lockFloors") && (
            <div
              className="hoistFormSectionChild"
              style={{
                marginLeft: "20px",
              }}
            >
              <Tooltip title="Lock All Floors">
                <MondayButton
                  className="mondayButtonBlue"
                  onClick={lockAllFloors}
                  hasIcon={false}
                >
                  <LockFilled />
                </MondayButton>
              </Tooltip>
              {/* <Button type="primary" danger ghost onClick={lockAllFloors}>
                Lock Fl.
              </Button>{" "} */}
            </div>
          )}
          <SelfCheck
            {...{
              elevation,
              title: "Self Check!",
            }}
          />

          {checkAccess(serviceId, "turnOffAuto") && (
            <div
              className="hoistFormSectionChild"
              style={{
                marginLeft: "10px",
              }}
            >
              <Tooltip title="Turn on/off auto Distribution">
                <MondayButton
                  hasIcon={false}
                  className={
                    !tempCommands?.autoDistribution
                      ? "mondayButtonRed"
                      : "mondayButtonBlue"
                  }
                  // type={tempCommands?.autoDistribution ? "primary" : "default"}
                  onClick={() => switchAutoDistribution()}
                >
                  <ProductFilled />
                </MondayButton>
              </Tooltip>
            </div>
          )}
        </div>
      </div>

      <div className="hoistFormSectionParent thirdHeader">
        {checkAccess(serviceId, "stops") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Stops:</div> */}
            <div className="hoistFormSectionChild">
              <InputNumber
                style={{ width: "120px" }}
                addonBefore="From:"
                type="number"
                max={130}
                min={-4}
                value={elevation.from}
                onFocus={handleInputElementFocus}
                onBlur={(p) => {
                  console.log("onChange p", p);
                  handleBoundariesChange(
                    forceToNumber(p?.target?.value),
                    "from"
                  );
                }}
                // onBlur={handleInputElementBlur}
              />
            </div>
            <div className="hoistFormSectionChild">
              <InputNumber
                style={{ width: "120px" }}
                addonBefore="To:"
                type="number"
                max={130}
                value={elevation.to}
                onFocus={handleInputElementFocus}
                onBlur={(e) => {
                  handleBoundariesChange(forceToNumber(e?.target?.value), "to");
                }}
                // onBlur={handleInputElementBlur}
              />
            </div>
          </div>
        )}

        {checkAccess(serviceId, "stopsInfo") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Stops Info</div> */}
            <div className="hoistFormSectionChild tagSelect leftPartial stopsInfo">
              <div className="addonBefore">Including:</div>
              <Select
                mode="tags"
                className="selectComponent"
                value={elevation.stops_including}
                onChange={(e) => {
                  handleInputElementChange(
                    { target: { value: e } },
                    "stops_including",
                    false,
                    true
                  );
                }}
                onBlur={() => {
                  isWritable && props.handleSave(false);
                }}
              >
                <Option value="Ground Stop">Ground Stop</Option>
                <Option value="Roof Stop">Roof Stop</Option>
              </Select>
              <span className="addonBefore">Excluding:</span>
              <Select
                mode="tags"
                className="selectComponent excludingSelect"
                value={elevation.stops_excluding}
                onChange={(e) => {
                  handleInputElementChange(
                    { target: { value: e } },
                    "stops_excluding",
                    false,
                    true
                  );
                }}
                onBlur={() => {
                  isWritable && props.handleSave(false);
                }}
              />
            </div>
          </div>
        )}

        {checkAccess(serviceId, "hoistInfo") && (
          <div className="hoistFormSection">
            {/* <div className="hoistFormLabel">Hoist Info</div> */}
            <div className="hoistFormSectionChild tagSelect leftPartial hoistInfo">
              <div className="addonBefore">Serial Number:</div>
              <Select
                mode="tags"
                className="selectComponent"
                value={elevation.serialNumbers}
                placeholder="Type it here..."
                onChange={(e) => {
                  handleInputElementChange(
                    { target: { value: e } },
                    "serialNumbers",
                    false,
                    true
                  );
                }}
                onBlur={() => {
                  props.isWritable && props.handleSave(false);
                }}
              />{" "}
              <div className="hoistFormSectionChild">
                {/* <div className="hoistFormLabel">Type:</div> */}
                <Input
                  className="speedComp"
                  style={{ width: 200 }}
                  addonBefore="Speed:"
                  type="number"
                  min={1}
                  defaultValue={178}
                  placeholder="Speed"
                  value={!!elevation.speed ? elevation.speed : 178}
                  onFocus={handleInputElementFocus}
                  onChange={(e) => {
                    handleInputElementChange(e, "speed", true);
                  }}
                  onBlur={handleInputElementBlur}
                  suffix="ft/min"
                />
              </div>
            </div>
          </div>
        )}
      </div>

      {elevation?.hoistServiceType === "betaMax" && (
        <div
          style={{
            padding: "5px 0px",
          }}
        >
          <Alert
            showIcon
            type="info"
            className="service-header-alert"
            message="This is Beta Max Hoist, therefore it has some changes. Please be mindful and fill the following data carefully."
          />
        </div>
      )}
      <GridData
        {...{
          serviceId,
          key,
          gridApi,
          setGridApi,
          isWritable,
          handleSave: props.handleSave,
          serviceIndex,
          setTempCommands,
          tempCommands,
          darkMode,
          getColumnDefs,
          prevProps: props,
          optionIndex,
          cellValueChanged,
          elevationIndex,
          frameworkComponents,
          elevation,
        }}
      />
      {checkAccess(serviceId, "note") && (
        <div className="hoistFormSectionParent" style={{ marginBottom: 0 }}>
          <div className="hoistFormSection" style={{ width: "100%" }}>
            <div className="hoistFormLabel">Note:</div>
            <ReactQuill
              className={"darkModeQuill " + props.agGridTheme}
              readOnly={!isWritable}
              theme="snow"
              {...quillComponents}
              defaultValue={elevation.note}
              // onFocus={(range, source, editor) => {
              //   handleInputElementFocus({
              //     target: { value: editor.getHTML() },
              //   });
              // }}
              // onChange={(content, delta, source, editor) => {
              //   handleInputElementChange(
              //     { target: { value: editor.getHTML() } },
              //     "note"
              //   );
              // }}
              onBlur={(range, source, editor) => {
                handleInputElementChange(
                  {
                    target: { value: editor.getHTML() },
                  },
                  "note"
                );
              }}
            />
          </div>
        </div>
      )}
    </div>
  );
}

export default HoistFormFunc;
const regex = /^[1-9]\d*'\d+"$/;

export const StopCounts = ({ indexes = {} }) => {
  const [gridData] = useRedux("takeOffTableData");
  const { serviceIndex = "", optionIndex = "", elevationIndex = "" } = indexes;
  const elevation =
    gridData[serviceIndex].serviceOptions[optionIndex][elevationIndex];
  const items = elevation?.items || [];
  const cabins = items?.reduce;

  console.log("el items", gridData);
  return (
    <div>
      Number of stops: Cabin A:{" "}
      {items?.filter((r) => r?.stop === true)?.length || 0} Cabin B:{" "}
      {items?.filter((r) => r?.dual === true)?.length || 0}
    </div>
  );
};
const convertToFt = (ftt) => {
  let val = forceToNumber(ftt);
  if (typeof val !== "number") {
    throw new Error("Invalid input. Please provide a numeric value.");
  }

  const [ft, inch] = val.toString().split(".");

  // if (!!!ft || !!!inch) {
  //   throw new Error(
  //     "Invalid input format. Please provide a value in the ft.inch format."
  //   );
  // }

  const parsedFt = forceToNumber(parseInt(ft));
  const parsedInch = forceToNumber(parseInt(inch));

  if (isNaN(parsedFt) || isNaN(parsedInch)) {
    // throw new Error(
    //   "Invalid input. Please provide numeric values for feet and inches."
    // );
    console.error(
      "Invalid input. Please provide numeric values for feet and inches."
    );
  }

  const totalInches = parsedFt * 12 + parsedInch;
  const totalFeet = totalInches / 12;

  return totalFeet;
};

// 3: Legacy Hoist, 38: Beta Max
export const HoistVisualsForm = {
  3: {
    type: true,
    selectAllFloors: true,
    hasFloors: true,
    location: true,
    access: true,
    hoistInfo: true,
    underground_height: true,
    building_height: true,
    total_height: true,
    lift_height: true,
    mast_height: true,
    lockFloors: true,
    turnOffAuto: true,
    stops: true,
    stopsInfo: true,
    dimensions: true,
    note: true,
    hasJumps: true,
  },
  38: {
    lift_height: true,
    note: true,
  },
};

const checkAccess = (hoistId, key) => {
  const access = !!HoistVisualsForm?.[hoistId]?.[key] ?? false;
  return access;
};

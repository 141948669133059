import { Tooltip, Tour } from "antd";
import { useSelector } from "react-redux";
import { useState, useEffect } from "react";
import { QuestionCircleFilled } from "@ant-design/icons";
import { MondayButton } from "../../../../../commonComponents";

const TourGuide = ({ tab, category, stepsData }) => {
  const [showTour, setShowTour] = useState(false);

  const [{ isDarkMode }, { programFields }] = useSelector((state) => [
    state.darkMode,
    state.programFields,
  ]);

  const steps = programFields
    ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
    ?.fieldOptions?.find(
      ({ categoryName }) => categoryName === category
    )?.steps;

  const tourName = tab === 7 ? "RolesAccessTour" : "UserAccessTour";

  useEffect(() => {
    const timeout = setTimeout(() => {
      const modalShown = localStorage.getItem(tourName);
      setShowTour(!modalShown);
      if (!modalShown) {
        localStorage.setItem(tourName, true);
      }
    }, 1000);
    return () => clearTimeout(timeout);
  }, [tab]);

  function stepsMapHelper(title) {
    const step = steps.find((step) => step.title === title);
    const stepData = stepsData.find((step) => step.title === title);
    return step ? document.querySelector(`${stepData.id}`) : null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        ...step,
        target: () => stepsMapHelper(step?.title),
        className: isDarkMode ? `custom-tour-dark` : `custom-tour-light`,
      };
    });
    if (tab === 7) {
      newSteps.splice(1, 1);
    } else {
      newSteps.splice(3, 1);
    }
    return newSteps;
  }

  const dbSteps = mapRefs(steps);
  return (
    <>
      <Tooltip title={"Guide"}>
        <MondayButton
          {...{
            className: "mondayButtonBlue iconButton",
            Icon: <QuestionCircleFilled width={18} />,
            onClick: () => {
              setShowTour(true);
            },
          }}
        ></MondayButton>
      </Tooltip>
      {showTour && (
        <Tour
          open={showTour}
          onClose={() => setShowTour(false)}
          steps={dbSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
    </>
  );
};

export default TourGuide;

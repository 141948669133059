import { Bar } from "react-chartjs-2";
import {
  chartJsBackgroundColor,
  chartJsBorderColor,
} from "../../../../ChartButton/utils/constants";

export const ReportsPerCategoryChart = ({ data, onClick }) => {
  const chartData = {
    labels: data.map((item) => item.category),
    datasets: [
      {
        label: "Category",
        data: data.map((item) => item.count),
        backgroundColor: chartJsBackgroundColor,
        borderColor: chartJsBorderColor,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Bar
      data={chartData}
      options={{
        plugins: {
          title: {
            display: true,
            text: "Reports Per Category",
          },
        },
      }}
    />
  );
};

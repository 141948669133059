import { v4 as uuidv4 } from "uuid";

export const localStorageHistory = "global_search_last_searches";

export const handleLastSearches = (
  categoryName,
  rest,
  label,
  linkTo,
  setCategoriesData
) => {
  const data = {
    categoryName,
    ...rest,
    label,
    linkTo,
    isLastSearch: true,
  };

  data.key = uuidv4();

  let isUpdate = JSON.parse(localStorage.getItem(localStorageHistory)) || [];

  const dataExists = isUpdate.some((data) => data.linkTo === linkTo);

  if (dataExists) {
    return;
  }

  if (isUpdate.length === 5) {
    const storedHistory =
      JSON.parse(localStorage.getItem(localStorageHistory)) || [];

    const updatedHistory = [...storedHistory.slice(1), data];
    localStorage.setItem(localStorageHistory, JSON.stringify(updatedHistory));

    setCategoriesData((prev) => {
      const lastSearchesIndex = prev.findIndex((item) => item["Last Searches"]);

      if (lastSearchesIndex !== -1) {
        return prev.map((item, index) => {
          if (index === lastSearchesIndex) {
            const lastSearches = item["Last Searches"];
            const updatedSearches = [...lastSearches.slice(1), data];
            return { "Last Searches": updatedSearches };
          }
          return item;
        });
      }

      return [{ "Last Searches": [data] }, ...prev];
    });

    return;
  }

  isUpdate.push(data);

  localStorage.setItem(localStorageHistory, JSON.stringify(isUpdate));

  setCategoriesData((prev) => {
    const lastSearchesObj = prev.find((item) => item["Last Searches"]);

    if (!lastSearchesObj) {
      return [
        {
          "Last Searches": [data],
        },
        ...prev,
      ];
    }

    return prev.map((item) =>
      item === lastSearchesObj
        ? { ...item, "Last Searches": [...item["Last Searches"], data] }
        : item
    );
  });
};

import React, { useEffect, useMemo, useState } from "react";
import { useSelector } from "react-redux";
import CommunicationItemView from "./communication-item-view/CommunicationItemView";
import { Empty } from "antd";
import { getThisWeekTimestamps, getTodayTimestamps } from "../../utils/helpers";

import "./communication-tab-view.scss";

function CommunicationTabView({
  currentPage,
  searchValue,
  filteredEmails,
  setFilteredEmails,
}) {
  // Accessing the communication state and the filter criteria from Redux store
  const { communication, filterBy } = useSelector(
    (state) => state.notifications
  );
  const { emails } = communication; // Destructure emails from communication state

  // Effect to filter emails based on search value and filter criteria
  useEffect(() => {
    // Filter emails based on the search value
    const filteredBySearch = emails?.filter((email) => {
      const { from, to, subject } = email; // Destructure relevant email fields
      const searchLower = searchValue.toLowerCase(); // Convert search value to lowercase

      // Return true if any of the fields include the search term
      return (
        from?.toLowerCase().includes(searchLower) ||
        to?.toLowerCase().includes(searchLower) ||
        subject?.toLowerCase().includes(searchLower)
      );
    });

    let filteredByEmails = filteredBySearch; // Initialize filtered emails

    // Further filter emails based on the selected filter criteria
    if (filterBy) {
      filteredByEmails = filteredBySearch.filter((email) => {
        const { labels, timestampDate } = email; // Destructure labels and timestamp
        const timestamp = timestampDate;

        // Apply filtering based on the selected criteria
        switch (filterBy) {
          case "today": {
            const { startOfToday, endOfToday } = getTodayTimestamps();
            return timestamp >= startOfToday && timestamp <= endOfToday; // Filter emails from today
          }
          case "thisWeek": {
            const { startOfWeek, endOfWeek } = getThisWeekTimestamps();
            return timestamp >= startOfWeek && timestamp <= endOfWeek; // Filter emails from this week
          }
          case "important": {
            return labels.includes("IMPORTANT"); // Filter emails labeled as important
          }
          default:
            return labels.includes("UNREAD"); // Default to filtering unread emails
        }
      });
    }

    // Update the state with the filtered emails
    setFilteredEmails(filteredByEmails);
  }, [emails, filterBy, searchValue]); // Dependencies for the effect

  // Memoized value for paginated emails based on the current page
  const paginatedEmails = useMemo(() => {
    const startIndex = (currentPage - 1) * 24; // Calculate starting index for pagination
    const endIndex = Math.min(startIndex + 24, filteredEmails.length); // Calculate ending index
    return filteredEmails.slice(startIndex, endIndex); // Return the sliced array for pagination
  }, [currentPage, filteredEmails]);

  // Render an empty state if there are no filtered emails
  if (filteredEmails.length === 0) {
    return (
      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} className="no-data-view" />
    );
  }

  // Render the list of paginated emails
  return (
    <div className="communication-tab-view-container">
      {paginatedEmails.map((email, key) => (
        <CommunicationItemView key={key} email={email} /> // Render each email using CommunicationItemView component
      ))}
    </div>
  );
}
export default CommunicationTabView;

import React from "react";
import { useEmailBoxContainer } from "./providers/EmailBoxContainerProvider";
import "./EmailBox.scss";

// This will handle returning the email box component

const EmailBoxContainer = ({
  data = {},
  rowData = {},
  isRedirected = [],
  authData = null,
  ...rest
}) => {
  // the hook which holds the state of the email box and email boxes state
  const { emailBoxes, closeEmailBox, selectedDraft, attachments } =
    useEmailBoxContainer();

  return (
    <div className="emailBoxContainer">
      {emailBoxes.map(({ Component, key }) => (
        <Component
          key={key}
          data={data}
          onClose={() => closeEmailBox(key)}
          rowData={rowData}
          isRedirected={isRedirected}
          selectedDraft={selectedDraft}
          boxKey={key}
          authData={authData}
          attachments={attachments}
          {...rest}
        />
      ))}
    </div>
  );
};

export default EmailBoxContainer;

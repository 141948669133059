import React, { useState, useEffect } from "react";

const SecureValueDisplay = ({ value = "" }) => {
  const [showValue, setShowValue] = useState(false);

  useEffect(() => {
    let timer;
    if (showValue) {
      timer = setTimeout(() => {
        setShowValue(false);
      }, 5000);
    }
    return () => clearTimeout(timer);
  }, [showValue]);

  return (
    <span style={{ cursor: "pointer" }} onClick={() => setShowValue(true)}>
      {showValue ? value : "******"}
    </span>
  );
};

export default SecureValueDisplay;

import { Checkbox } from "antd";
import "./SimpleCheckbox.scss";
import { useSelector } from "react-redux";

function SimpleCheckbox({ children, ...checkBoxProps }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <Checkbox
      {...{
        ...checkBoxProps,
        className: `simple-checkbox ${checkBoxProps.className || ""} ${
          isDarkMode ? "simple-checkbox-dark" : ""
        }`,
      }}
    >
      {children}
    </Checkbox>
  );
}

export default SimpleCheckbox;

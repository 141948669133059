import React, {
  useMemo,
  useState,
  useEffect,
  useContext,
  useReducer,
  useCallback,
} from "react";
import { API } from "aws-amplify";
import PropTypes from "prop-types";
import { useSelector } from "react-redux";
import { Dropdown, Form, Switch } from "antd";

import {
  DownloadIcon,
  DropdownIcon,
} from "../../../../SidebarPages/BasePage/src";
import {
  ANALYTICS_REDUCER,
  getEmployeeAnalytics,
  groupEntriesInShifts,
} from "../DEG/components/modalComponents/utils";
import PayrollContext from "../../PayrollContext";
import PayrollLayout from "../../Layout/PayrollLayout";
import { MondayButton } from "../../../../commonComponents";
import { columnDefs, chartingOptions, gridFilters } from "./AgGridData";
import { getPinnedBottomRowData } from "./AgGridData/pinnedBottomRowData";
import ExportDataModal from "./components/ExportDataModal/ExportDataModal";
import { InputComponent } from "../../../../SidebarPages/Fleet/components";
import AnalyticsCostDistribution from "./components/AnalyticsCostDistribution";
import AnalyticShiftView from "./components/AnalyticShiftView/AnalyticShiftView";
import { REDUCER_INIT } from "../DEG/components/modalComponents/utils/degReducer";
import AnalyticsDeleteModal from "./components/AnalyticsDeleteModal/AnalyticsDeleteModal";
import { calculateColumnsTotalValue } from "./AgGridData/utils/calculateColumnsTotalValue";
import RemovedAnalyticsModal from "./components/RemovedAnalyticsModal/RemovedAnalyticsModal";

import "../../Payroll.scss";

function AnalyticsTab({ accessRight, isSingleEmployee = false }) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const {
    form,
    analytics,
    degPayrolls,
    employeesList,
    fetchAnalytics,
    removedAnalytics,
  } = useContext(PayrollContext);

  const [analyticsData, analyticsUpdate] = useReducer(
    ANALYTICS_REDUCER,
    REDUCER_INIT
  );

  const [gridApi, setGridApi] = useState();
  const [rowData, setRowData] = useState([]);
  const [jobsites, setJobsites] = useState([]);
  const [crewTeams, setCrewTeams] = useState([]);
  const [exportModal, setExportModal] = useState(false);
  const [analyticView, setAnalyticView] = useState(false);
  const [analyticsShifts, setAnalyticsShifts] = useState([]);
  const [distributionModal, setDistributionModal] = useState(false);
  const [deleteAnalyticsModal, setDeleteAnalyticsModal] = useState(false);
  const [removedAnalyticsModal, setRemovedAnalyticsModal] = useState(false);

  const paginationPageSize = Math.floor((window.innerHeight - 357) / 38);

  function onDropdownClick({ key }) {
    const keyFunctions = {
      exportData: () => setExportModal(true),
      costDistribution: () => setDistributionModal(true),
      removedAnalytics: () => setRemovedAnalyticsModal(true),
    };

    return keyFunctions[key]();
  }

  function onSwitch(checked) {
    if (checked) {
      setRowData(analyticsShifts);
    } else {
      setRowData(analytics);
    }
  }

  const onRowDoubleClicked = useCallback((event) => {
    setAnalyticView(event.node.data);
  }, []);

  const columns = useMemo(() => {
    return columnDefs({
      columnAccess: accessRight?.children || [],
      setDeleteAnalyticsModal,
      isSingleEmployee,
    });
  }, [accessRight, rowData, crewTeams]);

  const [columnsTotalValue, setColumnsTotalValue] = useState({});
  const sumColumns = ["employeeRate", "regAmount", "otAmount", "total"];

  function onSelectionChanged() {
    calculateColumnsTotalValue({
      gridApi,
      rowData,
      sumColumns,
      setColumnsTotalValue,
    });
  }

  useEffect(() => {
    onSelectionChanged();
  }, [rowData]);

  const pinnedBottomRowData = useMemo(
    () => getPinnedBottomRowData(columnsTotalValue),
    [columnsTotalValue]
  );

  useEffect(() => {
    if (!jobsites.length) {
      API.get("jobsites", "/jobsites")
        .then((jb) => {
          setJobsites(jb);
        })
        .catch((err) => {
          console.log("Error getting data: ", err);
        });
    }

    if (!crewTeams?.length) {
      API.get("crewTeams", "/crewTeams")
        .then((res) => {
          setCrewTeams(res);
        })
        .catch((error) => {
          console.log("ERror getting teams: ", error);
        });
    }

    if (analytics?.length) {
      getEmployeeAnalytics({
        employeeList: undefined,
        degGridApi: {},
        analyticsUpdate,
        degRows: analytics,
      });
    }
  }, [analytics]);

  useEffect(() => {
    if (analyticsData && jobsites?.length && employeesList?.length) {
      const groupedAnalytics = groupEntriesInShifts({
        analytics: analyticsData,
        jobsites,
        crews: employeesList,
        rowData: analytics,
      });
      setAnalyticsShifts(groupedAnalytics);
      setRowData(groupedAnalytics);
    }
  }, [analyticsData, jobsites, employeesList]);

  return (
    <>
      <PayrollLayout
        {...{
          title: "Analytics",
          rowData,
          chartingOptions,
          columnDefs: columns,
          pinnedBottomRowData: isSingleEmployee && pinnedBottomRowData,
          hasNew: !accessRight?.write,
          suppressDoubleClickEdit: true,
          gridFilters,
          dataIdKey: "shiftId",
          paginationPageSize,
          getGridApi: setGridApi,
          excelExport: "Analytics",
          onRowDoubleClicked,
          onSelectionChanged,
          context: {
            crewTeams,
            degPayrolls,
          },
          customActionButtons: [
            <Switch
              key={"table-type-switch"}
              defaultChecked
              onChange={onSwitch}
              checkedChildren="Shift View"
              unCheckedChildren="Entries View"
            />,
            <Form
              key={"form-container"}
              form={form}
              style={{ display: "flex", flexDirection: "row", gap: 10 }}
            >
              <InputComponent
                type="rangepicker"
                placeholder="Set Date Range..."
                formItemName={"analyticsDateRange"}
                dataTestid={"analyticsDateRange"}
                dropdownClassName={isDarkMode && "darkDateDropDown"}
              />
              <InputComponent
                type="select"
                placeholder="Select company..."
                formItemName={"companySelected"}
                dataTestid={"companySelected"}
                customOptions={[
                  {
                    key: 0,
                    label: "GMNY Construction",
                    value: "GMNY Construction",
                  },
                  {
                    key: 1,
                    label: "Yard Management Services",
                    value: "Yard Management Services",
                  },
                ]}
                dropdownClassName={isDarkMode && "darkDropDown"}
              />
            </Form>,
            <MondayButton
              key={"fetch-analytics-btn"}
              className="mondayButtonBlue"
              onClick={fetchAnalytics}
              Icon={<DownloadIcon />}
            >
              Fetch Analytics
            </MondayButton>,
            <Dropdown
              key={"actions-btn"}
              overlayClassName={`layout-card-dropdown${
                isDarkMode ? " layout-dropdown-dark" : ""
              }`}
              menu={{
                items: [
                  accessRight.children?.find(
                    ({ title }) => title === "Delete"
                  ) && { label: "Removed Analytics", key: "removedAnalytics" },
                  { label: "Cost Distribution", key: "costDistribution" },
                  { label: "Export Data", key: "exportData" },
                ].filter(Boolean),
                onClick: onDropdownClick,
              }}
            >
              <MondayButton
                className="mondayButtonBlue"
                Icon={<DropdownIcon />}
              >
                Actions
              </MondayButton>
            </Dropdown>,
          ],
        }}
      />
      {distributionModal && (
        <AnalyticsCostDistribution
          {...{
            open: distributionModal,
            onCancel() {
              setDistributionModal(false);
            },
            jobsites,
            gridApi,
          }}
        />
      )}
      {exportModal && (
        <ExportDataModal
          open={exportModal}
          onCancel={() => setExportModal(false)}
          rowData={analytics}
          columnDefs={columnDefs({
            columnAccess: accessRight?.children || [],
            setDeleteAnalyticsModal,
            isSingleEmployee,
          })}
        />
      )}
      {deleteAnalyticsModal &&
        accessRight.children?.find(({ title }) => title === "Delete") && (
        <AnalyticsDeleteModal
          open={deleteAnalyticsModal}
          onCancel={() => setDeleteAnalyticsModal(false)}
          entry={deleteAnalyticsModal}
          gridApi={gridApi}
          setRemovedRowData={setRemovedAnalyticsModal}
        />
      )}
      {removedAnalyticsModal &&
        accessRight.children?.find(({ title }) => title === "Delete") && (
        <RemovedAnalyticsModal
          open={removedAnalyticsModal}
          onCancel={() => setRemovedAnalyticsModal(false)}
          removedAnalytics={removedAnalytics}
        />
      )}
      {analyticView && (
        <AnalyticShiftView
          open={!!analyticView}
          onCancel={() => setAnalyticView(false)}
          selectedShift={analyticView}
          jobsites={jobsites}
        />
      )}
    </>
  );
}

AnalyticsTab.propTypes = {
  accessRight: {
    write: PropTypes.bool,
    title: PropTypes.string,
    children: PropTypes.array,
  },
  isSingleEmployee: PropTypes.bool,
};

export default AnalyticsTab;

import { API } from "aws-amplify";
import { useEffect, useMemo, useRef, useState } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";

import { useQuery } from "../../../../hooks";
import { queryParams } from "../../../../utils/queryParams";
import CustomModal from "../../../commonComponents/CustomModal/CustomModal";
import { ReportViewer } from "../components";
import { attachTemplate, populateReport, uploadToDrive } from "../utils";

import { useGetDriveFoldersAndParentNameByParentId } from "../../../../hooks/useGetDriveParentFolders";
import { driveApi } from "../../../../integrations/DriveRequest";
import { getCustomToolbarBtnConfig } from "../../../../utils/ActiveReportJsUtils/getCustomToolbarBtnConfig";
import ReportProgress from "./ReportProgress";
import UploadPdfToDriveModal from "./UploadPdfToDrive/modal/UploadPdfToDriveModal";

import "./ReportView.scss";

const CATEGORY = "category";
const REPORT_ID = "reportId";
const FIELD_ID = "354gfdgd-5345bcbf-fgjfhjgfh";

const ReportView = () => {
  let query = useQuery();
  const navigate = useNavigate();
  const location = useLocation();

  const { accessToken } = useSelector((state) => state.accessToken);
  const { programFields } = useSelector((state) => state.programFields);

  const viewerRef = useRef(null);
  const [fetchedCategory, setFetchedCategory] = useState();
  const [fetchedReport, setFetchedReport] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [progress, setProgress] = useState(0);
  const [isOpen, setIsOpen] = useState(false);
  const [reportAsFile, setReportAsFile] = useState();
  const { pathname, state } = location;

  const { driveChildrenFolders, parentName } =
    useGetDriveFoldersAndParentNameByParentId({
      parentId: "1TdZ926tznIrLj2W1n3kf6IX_iHyhzbl6",
    });

  const {
    report: historyPassedReport,
    category: historyPassedCategory,
    activeFilters,
    recordId,
    partitionKeys,
    exportsSettings: historyExportsSettings,
  } = state || {};

  const reportTemplates = programFields
    .find((field) => field.fieldId === FIELD_ID)
    .fieldOptions.filter((template) => template.defaultTemplate);

  // Search query params
  const queryReportId = query.get(REPORT_ID);
  const queryCategoryName = query.get(CATEGORY);
  const queryTab = query.get("tab");

  // The report object we are gonna use
  const report = useMemo(
    () => {
      const newReport = historyPassedReport || fetchedReport;
      return attachTemplate(newReport, reportTemplates);
    },
    // return !!historyPassedReport ? historyPassedReport : fetchedReport;
    [historyPassedReport, fetchedReport]
  );

  // The category that we are gonna use
  const category = useMemo(() => {
    return historyPassedCategory || fetchedCategory;
    // return !!historyPassedCategory ? historyPassedCategory : fetchedCategory;
  }, [historyPassedCategory, fetchedCategory]);

  // for the modal to be visible we should have a categoryName and a reportId in the url search query
  let isModalVisible = !!queryReportId && !!queryCategoryName;

  const { categoryParameters } = category || {};

  const exportsSettings = useMemo(
    () =>
      historyExportsSettings || {
        title: report?.reportName,
        filename: report?.reportName,
        author: report?.createdBy,
        printing: "highResolution",
        pdfVersion: "1.5",
      },
    [report, viewerRef]
  );

  const tabPosition =
    queryTab ||
    location?.state?.tabPosition ||
    location?.state?.data?.tab ||
    "";

  // Clears the search query, as a result modal hides
  const onCancel = () => {
    setProgress(0);
    if (tabPosition)
      navigate(
        `${pathname}?${queryParams({
          tab: tabPosition,
        })}`,
        {
          tabPosition,
          tab: tabPosition,
        }
      );
    else navigate(`${pathname}`);
  };

  // Opens the report
  useEffect(() => {
    (async () => {
      if (!report || !accessToken) return;

      if (!!accessToken && !!report) {
        setIsLoading(true);
        window.progressInterval = (completed, total) => {
          setProgress(Math.floor((completed / total) * 100));
        };

        const { definition } = await populateReport(
          report,
          categoryParameters,
          accessToken,
          activeFilters,
          recordId,
          partitionKeys
        );

        const uploadToDriveBtn = getCustomToolbarBtnConfig(
          "upload",
          "mdi mdi-cloud-upload",
          "Upload to Drive",
          true,
          () =>
            uploadToDrive(
              viewerRef,
              report,
              setReportAsFile,
              setIsOpen,
              reportAsFile
            )
        );

        viewerRef.current.Viewer.toolbar.addItem(uploadToDriveBtn);
        viewerRef.current.Viewer.open(definition);

        window.progressInterval = null;
        setProgress(100);
        setIsLoading(false);
      }
    })();
    return () => {
      window.progressInterval = null;
    };
  }, [report, accessToken, viewerRef]);

  useEffect(() => {
    if (!!historyPassedReport || !isModalVisible) {
      return;
    } else {
      if (!!queryReportId) {
        API.get("reportsAvailable", `/reportsAvailable/${queryReportId}`).then(
          (reportRes) => {
            setFetchedReport(reportRes);
          }
        );
      }
    }
  }, [queryReportId]);

  useEffect(() => {
    if (!!historyPassedCategory || !isModalVisible) {
      return;
    } else {
      if (!!queryCategoryName) {
        API.get(
          "reportConfiguration",
          `/reportConfiguration/${queryCategoryName}`
        ).then((categoryRes) => {
          setFetchedCategory(categoryRes);
        });
      }
    }
  }, [queryCategoryName]);

  return (
    <div className={`${isOpen ? "sub-modal" : ""}`}>
      <CustomModal
        {...{
          open: isModalVisible,
          setOpen: () => (isModalVisible = false),
          onClose: onCancel,
          title: report?.reportName || "Report Viewer",
          customFooter: false,
        }}
      >
        {/* <SmallLoadableComp loading={isLoading} /> */}
        <div className="reportViewerWrapper">
          {progress < 100 && (
            <ReportProgress type="circle" progress={progress} />
          )}
          {!!exportsSettings && (
            <ReportViewer ref={viewerRef} exportsSettings={exportsSettings} />
          )}
        </div>
      </CustomModal>

      {isOpen && (
        <UploadPdfToDriveModal
          isOpen={isOpen}
          setIsOpen={setIsOpen}
          driveFolders={driveChildrenFolders}
          driveFolderParentName={parentName}
          reportAsFile={reportAsFile}
        />
      )}
    </div>
  );
};

export default ReportView;

import { OneTimePasswordConfirmation } from "../../../../../../../../containers/Signup/components";
import {
  TeamAndPermissions,
  UserInformation,
  EditNewUserAccess,
} from "../components";

function userModalSteps({
  form,
  populatedFields,
  user,
  setUser,
  stepsFormDisabled,
  setStepsFormDisabled,
  permissions,
  setPermissions,
  allTeams,
  finalConfirm,
  customAccess,
  setCustomAccess,
}) {
  return [
    {
      title: "User Information",
      Comp: UserInformation,
      form,
      populatedFields,
      setUser,
      setStepsFormDisabled,
      stepsFormDisabled,
    },
    {
      title: "Confirmation Code",
      Comp: OneTimePasswordConfirmation,
      stepsFormDisabled,
      setStepsFormDisabled,
      form,
    },
    // {
    //   title: "Team & Permissions",
    //   Comp: TeamAndPermissions,
    //   permissions,
    //   setPermissions,
    //   allTeams,
    // },
    // {
    //   title: "Configure Access Rights",
    //   Comp: EditNewUserAccess,
    //   form,
    //   customAccess,
    //   setCustomAccess,
    //   permissions,
    // },
    // {
    //   title: "Review & Create",
    //   Comp: EditNewUserAccess,
    //   form,
    //   accessDisabled: true,
    //   finalConfirm,
    //   customAccess,
    //   setCustomAccess,
    //   permissions,
    // },
  ];
}

export default userModalSteps;

import _ from 'lodash';

import groupEntriesInShifts from "./groupEntriesInShifts";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

const OVERHEAD_TYPES = ["CASH Shift", "1099 Shift"];
/**
 * This function distributes the overhead amount to project
 * amount based on day and hours worked
 * @typedef analytics
 * @property {Number[]} dateRange
 * @property {Object} employeesHoursPerDay
 * @property {Object} employeeIncorrectShifts
 * @property {Object} employeeNotFoundLocations
 * @property {Object} employeeOverheadShifts
 * @property {Object} employeeWeekTotals
 * @property {Object} generalOverheadTotal
 *
 * @param {Object} props
 * @param {Object[]} props.rowData
 * @param {analytics} props.analytics
 * @param {Object[]} props.jobsites
 * @param {Object[]} props.crews
 */
function overheadDistribution({ rowData, analytics, jobsites, crews }) {
  const shifts = groupEntriesInShifts({ analytics, jobsites, crews, rowData });

  const resultTotals = {
    employeeTotals: {},
    jobsiteTotals: {},
  };

  const groupedByEmployee = _.groupBy(shifts, ({ employeeId }) => employeeId);
  let shiftsWithJobsite = [];
  let shiftsNoJobsite = [];
  for (const shift of shifts) {
    if (shift?.jobsiteMatch?.jobsiteId) {
      shiftsWithJobsite.push(shift);
    } else {
      shiftsNoJobsite.push(shift);
    }
  }
  const groupedByJobsite = _.groupBy(shiftsWithJobsite, ({ jobsiteMatch }) =>
    jobsiteMatch?.jobName ? jobsiteMatch?.jobName : ""
  );

  for (const employeeId in groupedByEmployee) {
    const employeeEntries = groupedByEmployee[employeeId];
    const employeeData = { [employeeId]: {} };

    const rowDataByWeek = _.groupBy(employeeEntries, ({ punchTime }) =>
      punchTime ? dayjsNY(punchTime).week() : null
    );

    for (const week in rowDataByWeek) {
      const weekEntries = rowDataByWeek[week];
      const weekEmployeeData = { [week]: {} };
      let weekHours = 0;
      let generalWeekOverhead = 0;

      const entriesPerDay = _.groupBy(weekEntries, ({ punchDate }) =>
        punchDate ? dayjsNY(punchDate).format("MM/DD/YYYY") : null
      );

      for (const day in entriesPerDay) {
        const entries = entriesPerDay[day];
        let totalWorkHours = 0;
        let totalDayAmount = 0;

        for (const dayEntry of entries) {
          totalWorkHours +=
            (dayEntry?.workHours || 0) + (dayEntry?.overtimeHours || 0);
          weekHours +=
            (dayEntry?.workHours || 0) + (dayEntry?.overtimeHours || 0);

          if (OVERHEAD_TYPES.includes(dayEntry?.shiftType)) {
            generalWeekOverhead +=
              (dayEntry?.total || 0) + (dayEntry?.totalOvh || 0);
          } else {
            totalDayAmount +=
              (dayEntry?.total || 0) + (dayEntry?.totalOvh || 0);
          }
        }

        Object.assign(weekEmployeeData[week], {
          [day]: { totalWorkHours, totalDayAmount },
        });
      }

      if (generalWeekOverhead) {
        for (const day in weekEmployeeData[week]) {
          const dayData = weekEmployeeData[week][day];

          const rate = dayData.totalWorkHours / weekHours;

          Object.assign(dayData, {
            totalDayAmount:
              dayData.totalDayAmount + (rate || 1) * generalWeekOverhead,
          });
        }
      }
      Object.assign(employeeData[employeeId], weekEmployeeData);
    }

    Object.assign(resultTotals["employeeTotals"], employeeData);
  }

  // let totalJobsiteAmount = 0;
  let totalJobsiteHours = 0;
  let allAddresses = [];
  const hoursPerAddress = {};

  for (const address in groupedByJobsite) {
    const jobsiteEntries = groupedByJobsite[address];
    const jobsiteData = { [address]: {} };
    allAddresses.push(address);

    const entriesByWeek = _.groupBy(jobsiteEntries, ({ punchDate }) =>
      punchDate ? dayjsNY(punchDate).week() : null
    );

    for (const week in entriesByWeek) {
      const weekEntries = entriesByWeek[week];
      const weekJobData = { [week]: {} };
      let weekHours = 0;
      let generalWeekOverhead = 0;

      const entriesPerDay = _.groupBy(weekEntries, ({ punchDate }) =>
        punchDate ? dayjsNY(punchDate).format("MM/DD/YYYY") : null
      );

      for (const day in entriesPerDay) {
        const entries = entriesPerDay[day];
        let totalWorkHours = 0;
        let totalDayAmount = 0;

        for (const dayEntry of entries) {
          totalWorkHours +=
            (dayEntry?.workHours || 0) + (dayEntry?.overtimeHours || 0);
          weekHours +=
            (dayEntry?.workHours || 0) + (dayEntry?.overtimeHours || 0);

          totalJobsiteHours +=
            (dayEntry?.workHours || 0) + (dayEntry?.overtimeHours || 0);
          // totalJobsiteAmount +=
          //   (dayEntry?.total || 0) + (dayEntry?.totalOvh || 0);

          if (OVERHEAD_TYPES.includes(dayEntry?.shiftType)) {
            generalWeekOverhead +=
              (dayEntry?.total || 0) + (dayEntry?.totalOvh || 0);
          } else {
            totalDayAmount +=
              (dayEntry?.total || 0) + (dayEntry?.totalOvh || 0);
          }
        }
        Object.assign(weekJobData[week], {
          [day]: { totalWorkHours, totalDayAmount },
        });
        hoursPerAddress[address] =
          (hoursPerAddress?.[address] || 0) + totalWorkHours;
      }

      if (generalWeekOverhead) {
        for (const day in weekJobData[week]) {
          const dayData = weekJobData[week][day];

          const rate = dayData.totalWorkHours / weekHours;

          Object.assign(dayData, {
            totalDayAmount:
              dayData.totalDayAmount + (rate || 1) * generalWeekOverhead,
          });
        }
      }
      Object.assign(jobsiteData[address], weekJobData);
    }
    Object.assign(resultTotals["jobsiteTotals"], jobsiteData);
  }

  if (shiftsNoJobsite?.length) {
    const groupNoJobByWeek = _.groupBy(shiftsNoJobsite, ({ punchDate }) =>
      punchDate ? dayjsNY(punchDate).week() : null
    );
    const jobsiteMatchTotals = resultTotals.jobsiteTotals;
    const totalDayJobsiteHours = {};

    let daysToDistributeInWeek = {};
    let daysToDistributeInAll = {};

    for (const address in jobsiteMatchTotals) {
      for (const week in jobsiteMatchTotals[address]) {
        for (const day in jobsiteMatchTotals[address][week]) {
          const dayData = jobsiteMatchTotals[address][week][day];

          Object.assign(totalDayJobsiteHours, {
            [day]:
              (totalDayJobsiteHours?.[day] || 0) + dayData?.totalWorkHours || 0,
            totalHours:
              (totalDayJobsiteHours?.totalHours || 0) +
                dayData?.totalWorkHours || 0,
          });
        }
      }
    }

    for (const noJobWeek in groupNoJobByWeek) {
      const groupNoJobByDay = _.groupBy(
        groupNoJobByWeek[noJobWeek],
        ({ punchDate }) =>
          punchDate ? dayjsNY(punchDate).format("MM/DD/YYYY") : null
      );

      for (const noJobDay in groupNoJobByDay) {
        const noJobDayTotals = groupNoJobByDay[noJobDay];
        let noJobTotalAmount = 0;
        let noJobTotalHours = 0;
        for (const entry of noJobDayTotals) {
          noJobTotalAmount += (entry?.total || 0) + (entry?.totalOvh || 0);
          noJobTotalHours +=
            (entry?.workHours || 0) + (entry?.overtimeHours || 0);
        }

        for (const address of allAddresses) {
          const existingJobMatchDay =
            jobsiteMatchTotals?.[address]?.[noJobWeek]?.[noJobDay];

          if (existingJobMatchDay) {
            const rate =
              existingJobMatchDay?.totalWorkHours /
                totalDayJobsiteHours?.[noJobDay] || 0;

            Object.assign(existingJobMatchDay, {
              totalDayAmount:
                existingJobMatchDay?.totalDayAmount + rate * noJobTotalAmount,
            });
          } else if (jobsiteMatchTotals?.[address]?.[noJobWeek]) {
            daysToDistributeInWeek[noJobWeek] =
              (daysToDistributeInWeek?.[noJobWeek] || 0) + noJobTotalAmount;
          } else {
            Object.assign(daysToDistributeInAll, {
              totalDayAmount:
                (daysToDistributeInAll?.totalDayAmount || 0) + noJobTotalAmount,
              totalWorkHours:
                (daysToDistributeInAll?.totalWorkHours || 0) + noJobTotalHours,
            });
          }
        }
      }
    }

    for (const address of allAddresses) {
      let totalHoursPerAddress = hoursPerAddress[address];
      const addressPartition = totalHoursPerAddress / totalJobsiteHours;

      const daysToDistributeAllHours =
        (daysToDistributeInAll?.totalWorkHours || 0) * addressPartition;

      for (const week in jobsiteMatchTotals[address]) {
        let weekTotalHours = 0;

        const amountToDistribute =
          (daysToDistributeInWeek?.[week] || 0) * addressPartition;

        for (const day in jobsiteMatchTotals[address][week]) {
          const dayData = jobsiteMatchTotals[address][week][day];
          weekTotalHours += dayData?.totalWorkHours || 0;
        }

        const hoursToDistributeInWeek =
          (weekTotalHours / totalHoursPerAddress) * daysToDistributeAllHours;

        const amountToDistributeAllPerWeek =
          daysToDistributeInAll.totalDayAmount *
          (hoursToDistributeInWeek / daysToDistributeInAll.totalWorkHours);

        for (const day in jobsiteMatchTotals[address][week]) {
          const dayData = jobsiteMatchTotals[address][week][day];
          const rate = dayData?.totalWorkHours / weekTotalHours;

          const distributedAmountToAdd =
            amountToDistributeAllPerWeek * rate || 0;

          Object.assign(dayData, {
            totalDayAmount:
              dayData.totalDayAmount +
              distributedAmountToAdd +
              (rate || 1) * amountToDistribute,
          });
        }
      }
      totalHoursPerAddress = 0;
    }
  }

  return resultTotals;
}

export default overheadDistribution;

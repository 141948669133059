import { Tooltip } from "antd";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { MondayButton } from "../../../../../../commonComponents";
import { OpenLinkIcon } from "../../../../../../../assets";

const colors = {
  COMPLETED: "#7cca00",
  PENDING: "#e9c400",
  UPLOADED: "#0a8700",
};

export default [
  {
    headerName: "Vehicle #",
    field: "fleetName",
  },
  {
    headerName: "Date",
    field: "auditDate",
    cellRenderer({ value }) {
      if (!value) {
        return "";
      }

      return dayjsNY(value).format("MMM DD, YYYY");
    },
  },
  {
    headerName: "Audit Status",
    field: "auditStatus",
    cellClass: "cell-center-aligned",
    cellRenderer({ value }) {
      if (!value) {
        return null;
      }

      return (
        <div
          className="audit-grid-status"
          style={{ backgroundColor: colors?.[value] }}
        >
          {value[0] + value.toLowerCase().slice(1)}
        </div>
      );
    },
  },
  {
    headerName: "Activities to Audit",
    field: "activitiesToAudit",
  },
  {
    headerName: "Manual Events Creates",
    field: "manualEventsCreated",
  },
  {
    headerName: "Action",
    cellRenderer({ context, data, node }) {
      if (node?.group) {
        return null;
      }

      return (
        <Tooltip title="Go to Audit">
          <MondayButton
            Icon={<OpenLinkIcon />}
            className="mondayButtonBlue"
            onClick={() => {
              if (
                context?.onLoadAudit &&
                typeof context?.onLoadAudit === "function"
              ) {
                context?.onLoadAudit(data);
              }
            }}
          >
            {null}
          </MondayButton>
        </Tooltip>
      );
    },
  },
];

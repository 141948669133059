import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getRentalServices = async () => {
  let rentals = await fetchAllData("rentals", "rentals", "rentalId");

  return rentals.map((rental) => ({
    createdAt: rental.createdAt,
    fullyCharge: rental.fullyCharge,
    rentalId: rental.rentalId,
    projectAddress: rental.projectAddress,
    projectTaxRate: rental.projectTaxRate,
    rentalNumber: rental.rentalNumber,
    rentalStatus: rental.rentalStatus,
    taxRate: rental.taxRate,
    totalPrice: rental.totalPrice,
    services: rental.services.map((service) => {
      const serviceOptions = Array.isArray(service.serviceOptions?.[0])
        ? service.serviceOptions[0]
        : service.serviceOptions;
      return {
        createdAt: rental.createdAt,
        rentalId: rental.rentalId,
        projectAddress: rental.projectAddress,
        projectTaxRate: rental.projectTaxRate,
        rentalNumber: rental.rentalNumber,
        rentalStatus: rental.rentalStatus,
        serviceName: service.label,
        rentPercentage: service.rentPercentage,
        retainage: service.retainage,
        totalPrice: serviceOptions?.reduce(
          (acc, option) =>
            acc +
            (option.items?.reduce(
              (subTotal, obj) => subTotal + (obj.appliedAmount || 0),
              0
            ) || 0),
          0
        ),
        rentalDays: serviceOptions
          .map((option) =>
            option.items?.map((item) =>
              item.rentalDetails.details.map((detail) =>
                detail.days.map((day) => ({
                  applied: day.applied,
                  charged: day.charged,
                  dailyAmount: day.dailyAmount,
                  date: day.day,
                  id: day.id,
                  lock: day.lock,
                  rentalId: rental.rentalId,
                  projectAddress: rental.projectAddress,
                  projectTaxRate: rental.projectTaxRate,
                  rentalNumber: rental.rentalNumber,
                  rentalStatus: rental.rentalStatus,
                  serviceName: service.label,
                  rentPercentage: service.rentPercentage,
                  retainage: service.retainage,
                }))
              )
            )
          )
          .flat(4),
      };
    }),
  }));
};

import { getDispatchLocationOptions } from "../../../../Header/forms/RequestFormModal/requestFormModalData";
import { searchSafetyAddress } from "../helpers";

/**
 * Adds conditional fields to an array of fields based on certain conditions.
 *
 * @param {Object} field - The field object.
 * @param {Array} [fields=[]] - The array of fields.
 * @param {number} fieldCategoryIndex - The index of the field category.
 * @param {string} [otherPersonnelAffected=""] - The value of the other personnel affected.
 * @param {string} [immediateActions=""] - The value of the immediate actions.
 * @param {string} [otherTypeOfEquipment=""] - The value of the other type of equipment.
 */
function addConditionalFields({
  field,
  fields = [],
  fieldCategoryIndex,
  otherPersonnelAffected = "",
  immediateActions = "",
  otherTypeOfEquipment = "",
}) {
  if (
    field.formItemName === "wasAnyoneElseAffectedByThisInjury" &&
    otherPersonnelAffected === "Yes"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      ...field,
      type: "input",
      formItemName: "otherPersonnelAffected",
      // style: { width: 200 },
      label: "Other Personnel Affected",
    });
  }

  if (
    field.type === "select" &&
    field.formItemName ===
      "describeTheImmediateActionsTakenFollowingTheIncident"
  ) {
    if (immediateActions === "Other") {
      fields[fieldCategoryIndex]["fields"].push({
        ...field,
        type: "input",
        formItemName: "otherImmediateActionsTaken",
        label: "Other",
        style: { width: 200 },
      });
    } else if (immediateActions === "Escorted by") {
      fields[fieldCategoryIndex]["fields"].push({
        ...field,
        type: "input",
        formItemName: "escortedBy",
        label: "Escorted By",
        style: { width: 200 },
      });
    }
  }

  if (
    field.formItemName === "typeOfEquipmentInvolved" &&
    otherTypeOfEquipment === "Other"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      ...field,
      type: "input",
      formItemName: "otherTypeOfEquipment",
      label: "Other Type Of Equipment",
    });
  }
}

/**
 * Generates an array of fields for the Other Trade Incident Safety form.
 *
 * @param {Object} form - The form object.
 * @param {Array} [projects=[]] - The array of projects.
 * @param {Array} [scheduling=[]] - The array of scheduling.
 * @param {string} [propAddress=""] - The address of the property.
 * @param {null|number} [defaultId=null] - The default ID.
 * @param {Array} [dynamicFields=[]] - The array of dynamic fields.
 * @param {boolean} [mainDisabledField=false] - Indicates if the main disabled field is enabled or disabled.
 * @param {string} [immediateActions=""] - The immediate actions.
 * @param {Array} [schedulesForDate=[]] - The array of schedules for the date.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {boolean} [disabledAllFields=false] - Indicates if all fields are disabled or not.
 * @param {string} [otherTypeOfEquipment=""] - The other type of equipment.
 * @param {Function} [onChangeAddress=()=>{}] - The function to handle address change.
 * @param {string} [otherPersonnelAffected=""] - The other personnel affected.
 * @param {Function} [changeDateOfIncident=()=>{}] - The function to handle date of incident change.
 * @param {Function} [dispatchSafetyFormData=()=>{}] - The function to dispatch safety form data.
 * @returns {Array} - The array of fields for the Other Trade Incident Safety form.
 */
const otherTradeIncidentSafetyFields = ({
  form,
  projects = [],
  scheduling = [],
  propAddress = "",
  defaultId = null,
  dynamicFields = [],
  mainDisabledField = false,
  immediateActions = "",
  schedulesForDate = [],
  projectExecutives = [],
  userConfiguration = {},
  disabledAllFields = false,
  otherTypeOfEquipment = "",
  onChangeAddress = () => {},
  otherPersonnelAffected = "",
  changeDateOfIncident = () => {},
  dispatchSafetyFormData = () => {},
}) => {
  let fields = [];

  const projectExecutivesOpt =
    projectExecutives?.map((executive) => ({
      id: executive.peId,
      label: `${executive.firstName} ${executive.lastName}`,
      value: `${executive.firstName} ${executive.lastName}`,
    })) || [];

  for (let i = 0; i < dynamicFields.length; i++) {
    let field = structuredClone(dynamicFields[i]);
    delete field["placeholder"];

    if (field.type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        ...(field.label.includes("Project Executive")
          ? { customOptions: projectExecutivesOpt }
          : {}),
      };
    }

    if (field.type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (field.type === "radio") {
      field = {
        ...field,
        style: { borderRadius: 10, backgroundColor: "#f5f5f7", padding: 10 },
      };
    }

    if (
      field.formItemName === "dateOfIncident" ||
      field.formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "dateOfIncident"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
      };
    }

    if (field.formItemName === "timeOfIncident") {
      field = {
        ...field,
        dateField: "dateOfIncident",
        validTime: "past",
        form,
      };
    }

    if (field.formItemName === "incidentAddress") {
      field = {
        ...field,
        onSelect: onChangeAddress,
        initialValue: propAddress,
        groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchSafetyAddress({
            searchValue,
            dispatchSafetyFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfIncident",
            form,
          }),
      };
    }

    if (field.formItemName === "reportPreparedBy") {
      delete field?.options;
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration?.allUsers?.Items?.map((user) => {
          return { value: user?.identityId, label: user?.nameOfUser };
        }),
        style: { width: 200 },
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (field.formItemName === "fullNameOfInjuredIndividual") {
      field = {
        ...field,
        disabled: mainDisabledField || disabledAllFields,
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );

    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);
      addConditionalFields({
        fields,
        fieldCategoryIndex,
        otherPersonnelAffected,
        immediateActions,
        otherTypeOfEquipment,
        field,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        fieldCategoryIndex: fields.length - 1 || 0,
        otherPersonnelAffected,
        immediateActions,
        otherTypeOfEquipment,
        field,
      });
    }

    if (disabledAllFields) {
      if (!field.disabled) {
        field.disabled = disabledAllFields;
      }
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );
  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default otherTradeIncidentSafetyFields;

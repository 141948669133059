import { MondayButton } from "../../../../../../../commonComponents";
import { LeftArrow } from "../../../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../Roles/src";

function NewUserFooter({
  currentStep,
  onNext = () => {},
  onBack = () => {},
  onCancel = () => {},
  length,
}) {
  return (
    <div>
      <MondayButton
        {...{
          Icon: <XIcon />,
          onClick: onCancel,
          className: "mondayButtonRed",
        }}
      >
        Cancel
      </MondayButton>
      <div className="NewUserFooterRight">
        {currentStep !== 0 ? (
          <MondayButton
            onClick={onBack}
            Icon={<LeftArrow />}
            className="mondayButtonGrey"
          >
            Back
          </MondayButton>
        ) : null}
        <MondayButton
          className={
            currentStep === length - 1
              ? "mondayButtonGreen"
              : "mondayButtonBlue"
          }
          onClick={onNext}
          Icon={
            currentStep === length - 1 ? (
              <TickIcon />
            ) : (
              <LeftArrow style={{ transform: "rotate(180deg)" }} />
            )
          }
        >
          {currentStep === length - 1 ? "Confirm" : "Next"}
        </MondayButton>
      </div>
    </div>
  );
}

export default NewUserFooter;

import { useState, useRef } from "react";
import { useSelector } from "react-redux";
import { Popover, Select, Button } from "antd";

import { RichText } from "../../../../DynamicView/FormItemComponents/index";
import { getProcessedTakeOf } from "../../../../Estimations/components/ProposalBuilder/ProposalModal/dataset/takeOffProcess";

import "./DescriptionButton.scss";

export default function DescriptionButton({
  data,
  cellStyle = {},
  popoverStyle = {},
  className = "",
  disabled,
  accountingTemplates = [],
  categoryData,
  showGenerate = true,
  onDescriptionChange,
  darkMode = false,
}) {
  console.log({ data });
  const inputRef = useRef();
  const textareaRef = useRef();
  const [selectedTempId, setSelectedTempId] = useState();
  const [description, setDescription] = useState(data?.description);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  const postProcess = ({ estimation, templateData }) => {
    const processedTakeOff = !!estimation
      ? getProcessedTakeOf({ estimation, version: {}, type: "preview" })
      : null;

    const str = templateData?.replace(/\{\{(.*?)\}\}/g, function (i, match) {
      return processedTakeOff?.[match] || match;
    });
    return str;
  };

  const handleChange = (id) => {
    const accountingTemplate = accountingTemplates?.find(
      ({ templateId: ID }) => id === ID
    );
    const selCategoryData = categoryData["estimations"]?.find(
      ({ estimationId }) => estimationId === data?.categoryId
    );
    const description = postProcess({
      estimation: selCategoryData,
      templateData: accountingTemplate?.templateContent,
    });
    setDescription(description);
  };

  const currentDescription =
    data?.description
      ?.split("\n")
      ?.join(" ")
      ?.replace(/(<([^>]+)>)/gi, "")
      ?.replace("&nbsp; ", "") || "";

  return (
    <>
      <Popover
        ref={textareaRef}
        style={{ ...popoverStyle, border: "1px solid blue" }}
        overlayClassName={className}
        content={
          <div className="descriptionButtonInvoiceContainer">
            {showGenerate && (
              <div className="descriptionButtonInvoiceBody">
                <Select
                  rootClassName="selectInput"
                  onChange={setSelectedTempId}
                  popupClassName={(isDarkMode || darkMode) && "darkDropDown"}
                  options={accountingTemplates
                    ?.filter(
                      ({ templateType }) => templateType === data?.category
                    )
                    ?.map(({ templateTitle, templateId }) => ({
                      value: templateId,
                      label: templateTitle,
                    }))}
                />
                <Button
                  type="primary"
                  disabled={!!!selectedTempId}
                  className="generateButton"
                  style={{
                    cursor: !!!selectedTempId ? "not-allowed" : "pointer",
                  }}
                  onClick={() => handleChange(selectedTempId)}
                >
                  Generate
                </Button>
              </div>
            )}

            <RichText
              color="white"
              formItemName={"description"}
              initialValue={description || ""}
              value={data?.description||''}
              setValue={(value) => {
                data.description = value;
              }}
              disabled={disabled}
            />
          </div>
        }
        onOpenChange={(e) => {
          if (!e) {
            onDescriptionChange(data?.description);
          }
        }}
        trigger="click"
        placement="bottom"
      >
        <div
          className={`descriptionContainer descriptionContainerInside`}
          ref={inputRef}
          style={cellStyle}
        >
          {currentDescription}
        </div>
      </Popover>
    </>
  );
}

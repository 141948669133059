import { message } from "antd";
import dayjs from "dayjs";
import { generateWeeks } from "../../../../../../../../../../../../../../../utils";
import { headerDateInputHandler } from "../../../../../RentalHeader/Components/HeaderDateInput/HeaderDateInputFunctions";

export const handleNumberOfWeeksChange = ({
  selectedService,
  thisElevation,
  fakeRentals,
  thisPli,
  params,
  setUnchangingFakeRentals,
  elevationData,
  setFakeRentals,
  fromSchedule = true,
}) => {
  // let changingValue = undefined;

  // try {
  // 	changingValue = eval(params.newValue);
  // } catch (e) {
  // 	changingValue = 0;
  // 	console.error(e);
  // }

  //if we have selected the start and end day we take the moment object and turn it to timestamp
  const startDate = dayjs().startOf("year").valueOf();
  const endDate = dayjs().endOf("end").valueOf();
  const timeLine = [startDate, endDate];

  const handleDateInput = (date) =>
    headerDateInputHandler({
      setUnchangingFakeRentals,
      selectedService,
      setFakeRentals,
      elevationData,
      fakeRentals,
      date,
    });
  handleDateInput(timeLine);
};

//this function tell the user why the check all is disabled
export const getDisabledTooltipContent = ({
  selectedService,
  fakeRentals,
  isWritable,
  elevationData,
  allRow,
}) => {
  //here we find the pli in witch the date input is on
  const data = fakeRentals?.services
    ?.find(({ label }) => label === selectedService)
    ?.serviceOptions?.find(
      ({ elevationId }) => elevationId === elevationData?.elevationId
    )
    ?.items?.find(({ id }) => id === allRow?.id);

  //the case where write mode is off
  return !isWritable
    ? "Enable write mode to edit!"
    : //the case where this pli is charged
    !!data?.charged
    ? "This pli is charged you cant change period!!"
    : //the case where non of those above are true
      "";
};

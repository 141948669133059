import "./styles/TaskFormFields.scss";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { API } from "aws-amplify";
import { Form } from "antd";
import { taskFieldsJSON } from "./data/taskFieldsJSON";
import { filterTables } from "../../../../../../../utils";
import { getUsersList, updateRecordLabels } from "../helpers";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { getTopics } from "../../../utils/getTopics";

export const TaskFormFields = ({
  form,
  customTaskProps,
  fromSettings = false,
  isPrivate = false,
}) => {
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { userGroups } = useSelector((state) => state.userGroups);

  const [userDepartments, setUserDepartments] = useState([]); //save departments of progra
  const [assignToUsers, setAssignToUsers] = useState([]); // save users that can assign task to
  const [subTopicRecords, setSubTopicRecords] = useState([]); //save Sub topic records of current selected sub topic

  // added states
  const [projectTaskOptions, setProjectTaskOptions] = useState([]);

  const taskSubTopic = Form.useWatch("taskSubTopic", form);
  const taskRelatedToObj = Form.useWatch("taskRelatedToObj", form);

  useEffect(() => {
    const departments = [
      ...new Set(userGroups.map((curr) => curr.departmentName)),
    ];

    setAssignToUsers(
      getUsersList(
        userConfiguration,
        departments,
        taskRelatedToObj || {
          recordId: customTaskProps?.taskTopicId,
          teamsConfiguration: customTaskProps?.rowData?.teamsConfiguration,
        }
      )
    );

    form.setFieldValue("department", departments);
    setUserDepartments(departments);
  }, [userGroups.length]);

  useEffect(() => {
    if (!!customTaskProps) {
      customTaskProps.taskTopic.toLowerCase() === "projects" &&
        getProjectTaskReferences(customTaskProps?.taskTopicId);
    }
  }, [customTaskProps]);

  const topics = getTopics(topicCategories, userConfiguration, programFields);

  //function to get records of current subtopic
  const getSubtopicRecords = (selectedTopic, taskTopicId, subCat) => {
    form.setFieldValue("taskSubTopicId", ""); //empty last selected val

    const topicCategory = topics.find(
      (top) => top.categoryName === selectedTopic?.categoryName
    );

    const subTopicCategory = topics.find(
      ({ categoryName }) => categoryName === subCat
    );

    if (!subTopicCategory) {
      return setSubTopicRecords([]);
    }

    filterTables(
      subTopicCategory?.apiName,
      topicCategory.primaryKey,
      taskTopicId
    )
      .then((results) => {
        //check for key recordId
        if (results?.length) {
          setSubTopicRecords(
            updateRecordLabels({ records: results, topic: subTopicCategory })
          );
        } else {
          filterTables(subTopicCategory?.apiName, "recordId", taskTopicId)
            .then((newRes) =>
              setSubTopicRecords(
                updateRecordLabels({ records: newRes, topic: subTopicCategory })
              )
            )
            .catch((err) => console.error({ err }));
        }
      })
      .catch((err) => console.error({ err }));
  };

  // Get options for Project Task Reference based on project selected or get the default ones from programFields
  const getProjectTaskReferences = (topicId) => {
    const taskOptions = programFields.find(
      ({ fieldName }) => fieldName === "Project Management Task List"
    ).fieldOptions;

    const getProjectTaskList = (taskOptions) => {
      return Object.keys(taskOptions)
        .sort()
        .map((taskPhase, i) => ({
          key: `${taskPhase}${i}`,
          label: taskPhase,
          options: Object.keys(taskOptions[taskPhase]).map(
            (referedTask, i) => ({
              key: `${referedTask}${i}`,
              label: referedTask,
              value: referedTask,
              taskPhase,
              ...(Object.keys(taskOptions[taskPhase]?.[referedTask])
                ?.length && {
                subTasks: Object.keys(taskOptions[taskPhase][referedTask]),
              }),
            })
          ),
        }));
    };

    if (!!topicId) {
      API.get("projects", `/projects/${topicId}`).then((res) => {
        const projectTaskFields = res[0]?.projectTaskList || taskOptions;
        const listOfTaskOptions = getProjectTaskList(projectTaskFields);
        setProjectTaskOptions(listOfTaskOptions);
      });
    } else {
      const listOfTaskOptions = getProjectTaskList(taskOptions);
      setProjectTaskOptions(listOfTaskOptions);
    }
  };

  return (
    <div className="task-form-fields-section">
      {RenderDynamicComponents(
        taskFieldsJSON({
          form,
          userConfiguration,
          programFields,
          customTaskProps,
          taskSubTopic,
          topics,
          getSubtopicRecords,
          userDepartments,
          assignToUsers,
          setAssignToUsers,
          getProjectTaskReferences,
          projectTaskOptions,
          subTopicRecords,
          fromSettings,
          isPrivate,
        })
      )}
    </div>
  );
};

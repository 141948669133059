import {
  useEffect,
  useState,
  useMemo,
  useRef,
  useCallback,
  Fragment,
} from "react";
import { useSelector } from "react-redux";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";
import { createPortal } from "react-dom";
import { SearchOutlined } from "@ant-design/icons";

import dataFilterHandler from "./dataFilterHandler";
import Filter from "src/components/SidebarPages/BasePage/components/Filter/Filter";
import { findPaginationSize } from "src/components/SidebarPages/Fleet/fleetsLive/utils";
import { gridCustomOverlayLoading } from "src/components/pages/Payroll/Tabs/DEG/components/modalComponents/utils";
import { MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { FilterIcon } from "src/components/SidebarPages/BasePage/src";

import "./GridTemplateCard.scss";

/**
 * @typedef {import("react").ReactNode} ReactNode
 */

/**
 * @typedef {import("ag-grid-enterprise").ColDef} ColDef
 */

/**
 * @typedef {import("ag-grid-react/lib/shared/interfaces").AgGridReactProps} AgGridReactProps
 */

/**
 * @typedef {import("ag-grid-react/lib/shared/interfaces").AgReactUiProps} AgReactUiProps
 */

/**
 * @template [T=Record<string, any>]
 * @typedef GridCardProps
 * @property {string} title
 * @property {ReactNode[]|ReactNode} [headerActions]
 * @property {ColDef<T>[]} columnDefs
 * @property {T[]} rowData
 * @property {boolean} [loading=false]
 * @property {boolean} [accessRight=true]
 * @property {any} [filters]
 * @property {boolean} [hideFilters=false]
 * @property {ReactNode|ReactNode[]} [additionalActions]
 * @property {string} [className]
 * @property {boolean} [hideHeader=false]
 * @property {Record<string, any>} [context]
 * @property {Omit<AgGridReactProps<T>|AgReactUiProps<T>, "rowData"|"columnDefs"|"ref"|"context">} [gridProps]
 */

/**
 * @template [T=Record<string, any>]
 * @param {GridCardProps<T>} props
 * @returns {JSX.Element}
 */
function GridTemplateCard(props) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [filterOpen, setFilterOpen] = useState(false);
  const [filtersData, setFiltersData] = useState();

  /** @type {React.MutableRefObject<AgGridReact>} */
  const gridRef = useRef(null);
  /** @type {React.MutableRefObject<HTMLDivElement>} */
  const gridContainerRef = useRef(null);

  const {
    title,
    context,
    rowData = [],
    className = "",
    filters = null,
    gridProps = {},
    columnDefs = [],
    loading = false,
    hideHeader = false,
    accessRight = true,
    hideFilters = false,
    headerActions = null,
    additionalActions = null,
  } = props;

  const sideBar = useMemo(() => {
    return {
      toolPanels: [
        {
          id: "columns",
          labelDefault: "Columns",
          labelKey: "columns",
          iconKey: "columns",
          toolPanel: "agColumnsToolPanel",
        },
        {
          id: "filters",
          labelDefault: "Filters",
          labelKey: "filters",
          iconKey: "filter",
          toolPanel: "agFiltersToolPanel",
        },
      ],
    };
  }, []);

  const onFirstDataRendered = useCallback(() => {
    const allColumnIds = [];
    gridRef?.current?.columnApi?.getColumns().forEach((column) => {
      allColumnIds.push(column.getId());
    });
    gridRef?.current?.columnApi?.autoSizeColumns(allColumnIds, false);
    const widths = {
      rowWidth: parseInt(
        gridContainerRef.current.querySelector(".ag-row")?.offsetWidth
      ),
      parentWidth: parseInt(
        gridContainerRef.current.querySelector(".ag-body-viewport")?.offsetWidth
      ),
    };

    if (widths.rowWidth < widths.parentWidth) {
      gridRef?.current?.api?.sizeColumnsToFit();
    }
  }, []);

  const defaultColDef = useMemo(() => {
    return {
      resizable: true,
      enablePivot: true,
      enableRowGroup: true,
      editable: false,
      sortable: true,
      flex: 1,
      filter: true,
    };
  }, []);

  useEffect(() => {
    if (!gridRef?.current) {
      return;
    }

    dataFilterHandler({
      filtersData,
      gridApi: gridRef.current.api,
      gridFilters: filters,
    });
  }, [filtersData]);

  useEffect(() => {
    if (!gridRef.current?.api) {
      return;
    }

    if (loading) {
      gridRef.current.api.showLoadingOverlay();
    } else {
      gridRef.current.api.hideOverlay();
    }
  }, [loading]);

  function onFilterTextChange(event) {
    if (!gridRef.current) {
      return;
    }
    gridRef.current.api.setQuickFilter(event.target.value);
  }

  function clearFilters() {
    setFiltersData({});
  }

  return (
    <Fragment>
      <div
        className={`grid-template-card ${
          isDarkMode ? "grid-template-card-dark" : ""
        } ${className}`}
      >
        {!hideHeader ? (
          <div className="card-title">
            <span>{title}</span>
            {accessRight ? (
              <div className="actions-container">{headerActions}</div>
            ) : null}
          </div>
        ) : null}
        <div
          className={`card-body ${hideHeader ? "no-title" : "with-title"} ${
            hideFilters ? "no-filters" : "with-filters"
          }`}
        >
          {!hideFilters ? (
            <div className="filters-container">
              <div className="inner-filter-section">
                <InputComponent
                  {...{
                    className: "search-comp",
                    placeholder: "Search...",
                    prefix: <SearchOutlined className="SearchLogoIcon" />,
                    onChange: onFilterTextChange,
                  }}
                />
                {filters ? (
                  <MondayButton
                    {...{
                      className: "mondayButtonBlue",
                      Icon: <FilterIcon />,
                      tooltipKey: "openFilters",
                      onClick() {
                        setFilterOpen(true);
                      },
                    }}
                  >
                    Open Filter
                  </MondayButton>
                ) : null}
              </div>
              {additionalActions ? (
                <div className="inner-filter-section">{additionalActions}</div>
              ) : null}
            </div>
          ) : null}
          <div
            className={`grid-container ${
              isDarkMode
                ? "dark-ag-theme ag-theme-alpine-dark"
                : "light-ag-theme ag-theme-alpine"
            }`}
            ref={gridContainerRef}
          >
            <AgGridReact
              {...{
                rowData,
                sideBar,
                context,
                columnDefs,
                ref: gridRef,
                defaultColDef,
                rowHeight: 39,
                pagination: true,
                headerHeight: 47,
                paginationAutoPageSize: false,
                paginationPageSize: findPaginationSize(
                  gridContainerRef,
                  gridProps?.rowHeight ?? 39
                ),
                overlayLoadingTemplate: gridCustomOverlayLoading(),
                animateRows: true,
                onFirstDataRendered,
                rowSelection: "multiple",
                rowGroupPanelShow: "always",
                groupDisplayType: "groupRows",
                ...gridProps,
                onGridReady(api) {
                  if (loading) {
                    api.api.showLoadingOverlay();
                  }

                  if (
                    gridProps?.onGridReady &&
                    typeof gridProps?.onGridReady === "function"
                  ) {
                    gridProps.onGridReady(api);
                  }
                },
              }}
            />
          </div>
        </div>
      </div>
      {filterOpen
        ? createPortal(
            <Filter
              getFilters={(val) => {
                setFiltersData(structuredClone(val));
              }}
              setOpen={setFilterOpen}
              filters={filters}
              filtersData={filtersData}
              rowData={rowData}
              title={title}
              clearFilters={clearFilters}
            />,
            document.body
          )
        : null}
    </Fragment>
  );
}

export default GridTemplateCard;

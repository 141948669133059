import {
  AlertFilled,
  WarningOutlined,
  SnippetsOutlined,
} from "@ant-design/icons";

import {
  MapsIcon,
  AuditIcon,
  CalendarIcon,
  WirelessIcon,
} from "../../../../../assets";
import { ReportsBlank } from "src/icons";
import { LogsIconTransparent } from "../../../DynamicView/src";
import { ClaimsIcon } from "../../../../../assets";

const SIDER_ITEMS = () => [
  {
    label: "Map",
    key: "MAP",
    icon: <MapsIcon height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Live",
    key: "LOCATIONS",
    icon: <WirelessIcon height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Trips",
    key: "TRIPS",
    icon: <LogsIconTransparent height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Planned Trips",
    key: "PLANNED_TRIPS",
    icon: <CalendarIcon height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Stop Reasons",
    key: "STOP_REASONS",
    icon: <AlertFilled />,
    children: null,
    type: undefined,
  },
  {
    label: "Idle Events",
    key: "IDLE_EVENTS",
    icon: <WarningOutlined />,
    children: null,
    type: undefined,
  },
  {
    label: "After Hours Events",
    key: "AFTER_HOURS",
    icon: <ClaimsIcon height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Audit",
    key: "AUDIT",
    icon: <AuditIcon height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Audit Overview",
    key: "AUDIT_OVERVIEW",
    icon: <SnippetsOutlined height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
  {
    label: "Reports",
    key: "REPORTS",
    icon: <ReportsBlank height={16} width={16} fill="currentColor" />,
    children: null,
    type: undefined,
  },
];

export default SIDER_ITEMS;

import { useState, Fragment } from "react";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "antd";
import { ReloadOutlined } from "@ant-design/icons";

import { TeamsConfigurationType, StoreType } from "../../types";
import { MondayButton, Notes } from "src/components/commonComponents";
import DriveCheckButton, {
  DriveButtonProps,
} from "../DriveCheckButton/DriveCheckButton";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets";
import { NotesIcon } from "src/components/SidebarPages/DynamicView/src";
import { OpenLinkIcon } from "src/assets";

import "./DynamicSideButtons.scss";

type NotesPropsType = {
  noteModalName: string;
  rowId: string;
  topicCategory: string;
  teamsConfiguration: TeamsConfigurationType[];
};

type SideButtonsProps = {
  notesProps?: NotesPropsType;
  notesAccess: boolean;
  driveProps: DriveButtonProps;
  onRefresh?: () => any;
  onDelete?: () => any;
  deleteAccess: boolean;
  redirectProps: {
    title: string;
    pathname: string;
  };
};

function DynamicSideButtons(props: SideButtonsProps) {
  const { userConfiguration } = useSelector(
    (state: StoreType) => state.userConfig
  );
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);

  const [notesOpen, setNotesOpen] = useState(false);

  const navigate = useNavigate();

  return (
    <Fragment>
      <div className="maintenance-side-buttons">
        {props.notesAccess ? (
          <Tooltip title="Show Notes" placement="left">
            <MondayButton
              Icon={<NotesIcon height={25} width={25} />}
              onClick={() => {
                setNotesOpen(true);
              }}
              containerClassName={`side-action-button ${
                isDarkMode ? "side-button-dark" : ""
              }`}
            >
              {null}
            </MondayButton>
          </Tooltip>
        ) : null}
        {userConfiguration.departmentName === "Executive" &&
          userConfiguration.groupName === "Admin" && (
            <DriveCheckButton {...props.driveProps} />
          )}
        {props.deleteAccess ? (
          <Tooltip title="Delete" placement="left">
            <MondayButton
              Icon={<TrashIcon height={22} width={22} />}
              onClick={props.onDelete}
              containerClassName={`side-action-button ${
                isDarkMode ? "side-button-dark" : ""
              }`}
            >
              {null}
            </MondayButton>
          </Tooltip>
        ) : (
          false
        )}
        <Tooltip title="Refresh Page" placement="left">
          <MondayButton
            Icon={
              <ReloadOutlined style={{ fontSize: "22px", color: "#ffffff" }} />
            }
            onClick={props.onRefresh}
            containerClassName={`side-action-button ${
              isDarkMode ? "side-button-dark" : ""
            }`}
          >
            {null}
          </MondayButton>
        </Tooltip>
        {props.redirectProps ? (
          <Tooltip title={props.redirectProps.title} placement="left">
            <MondayButton
              Icon={<OpenLinkIcon height={22} width={22} />}
              onClick={() => {
                navigate(props.redirectProps.pathname);
              }}
              containerClassName={`side-action-button ${
                isDarkMode ? "side-button-dark" : ""
              }`}
            >
              {null}
            </MondayButton>
          </Tooltip>
        ) : null}
      </div>
      {notesOpen ? (
        <Notes
          {...(props.notesProps ?? {})}
          notesVisibility={notesOpen}
          setNotesVisibility={setNotesOpen}
        />
      ) : null}
    </Fragment>
  );
}

export default DynamicSideButtons;

import { RenderDynamicComponents } from "../../../components/Header/forms/Components";
import { useWatch } from "antd/es/form/Form";
import { message } from "antd";
import { ThreeAsteriskIcon } from "../icons";
import { InfoText } from "../../../components/commonComponents/CustomModalHeader/CustomModalHeader";

const PasswordConfirmation = ({ form, populatedFields, disabled }) => {
  function generateNineDigitPassword() {
    //required: 1 uppercase, 1 number, 1 symbol, 9 characters
    const password = [];
    const symbols = "!@#$%^&*";
    const numbers = "0123456789";
    const uppercase = "ABCDEFGHIJKLMNOPQRSTUVWXYZ";
    const lowercase = "abcdefghijklmnopqrstuvwxyz";
    const allChars = symbols + numbers + uppercase + lowercase;

    password.push(
      symbols[Math.floor(Math.random() * symbols.length)],
      numbers[Math.floor(Math.random() * numbers.length)],
      uppercase[Math.floor(Math.random() * uppercase.length)],
      lowercase[Math.floor(Math.random() * lowercase.length)]
    );

    for (let i = 0; i < 5; i++) {
      password.push(allChars[Math.floor(Math.random() * allChars.length)]);
    }
    const finalPass = password.sort(() => Math.random() - 0.5).join("");
    form.resetFields(["password", "confirmPassword"]);
    form.setFieldsValue({
      password: finalPass,
      confirmPassword: finalPass,
    });
    //coy to clipboard
    navigator.clipboard.writeText(finalPass);
    message.success("Password copied to clipboard", 5);
  }

  return (
    <>
      <div className="create-user-details">
        <div className="create-user-form-header">
          <ThreeAsteriskIcon width={24} />
          <h2>Password </h2>
        </div>
        <InfoText
          {...{
            text: (
              <span>
                Password must have an uppercase letter, a number and a symbol.{" "}
                {!disabled ? (
                  <span
                    className="roles-access-link"
                    onClick={generateNineDigitPassword}
                  >
                    Generate Password
                  </span>
                ) : null}
              </span>
            ),
          }}
        />

        <div className="formInputs">
          {RenderDynamicComponents(populatedFields).filter(
            (item) => Number(item.key) > 5
          )}
        </div>
      </div>
    </>
  );
};

export default PasswordConfirmation;

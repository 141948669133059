import moment from "moment";
import { useSelector } from "react-redux";
import { useEffect, useMemo, useState } from "react";
import { ListManager } from "react-beautiful-dnd-grid";

import {
  deleteReport,
  removeFavoriteReport,
  setFavoriteReport,
  updateReport,
} from "../../actions/wrapperFunctions";
import { ReportView } from "../../..";
import { useReportOpener } from "../../../hooks";
import { ConfigurationPanel } from "./components";
import { compareIncluding } from "../../../../utils";
import { useEditLogs, useResponsive } from "../../../../../../hooks";
import { useReportsConfigContext } from "../../ReportsConfigContext";
import { categoriesIcons, views } from "../../reportsConfigurationData";
import { NewReportModal, ReportPreviewCard } from "../../../components";
import { getChangedData } from "../../../../Accounting/components/utilities";

import "./CategoryConfig.scss";
import { debounceSearch } from "../../../../../../utils";
import { MondayButton } from "../../../../../commonComponents";
import { CheckOutlined, CloseOutlined } from "@ant-design/icons";
import { message } from "antd";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { preferences as preferencesDispatch } from "../../../../../../actions/preferences";
import CustomHeader from "../../../../Projects/Accounting/Applications/ApplicationsTab/ListOfApplications/GridList/CustomHeader";

const CREATING = "creating";
const CLONING = "cloning";

const CategoryConfig = () => {
  const {
    allCategories,
    selectedCategory = {},
    setActiveView,
    setSelectedReport,
    setSelectedCategoryName,
    curryDispatch,
  } = useReportsConfigContext();

  const { desktop, tablet } = useResponsive();
  //the current search term, set from the search field
  const [searchKeyword, setSearchKeyword] = useState("");
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const dispatch = useDispatch();

  const [playDrag, setPlayDrag] = useState(false);
  //action of the modal that creates a new report (creating | cloning)
  const [reportModalVisible, setReportModalVisible] = useState();

  //hook for previewing a report
  const reportOpener = useReportOpener("settings/reports");
  const { saveAddedLogs } = useEditLogs();

  const [{ userConfiguration = {} }, { isDarkMode }] = useSelector((state) => [
    state.userConfig,
    state.darkMode,
  ]);
  const { identityId } = userConfiguration;

  const { categoryName, reportsAvailable: reports = [] } = selectedCategory;

  const reportsAvailable =
    allPreferences?.preferences?.ReportsPageSettings?.map((id) => {
      return reports?.find(({ reportId }) => id === reportId);
    }) ?? reports;

  const [dragItems, setDragItems] = useState([]);

  const CategoryIcon = categoriesIcons[categoryName] || categoriesIcons.default;
  const [activeFilters, setActiveFilters] = useState(null);

  const reportsToRender = useMemo(() => {
    if (!!searchKeyword) {
      const filteredReports = reportsAvailable.filter(({ reportName }) =>
        compareIncluding(reportName, searchKeyword)
      );
      return filteredReports;
    } else {
      if (!!activeFilters) {
        if (!!activeFilters?.createdAt) {
          const secondFilteredReports = reportsAvailable.filter(
            ({ createdAt }) =>
              moment(createdAt).format("MM/DD/YYYY") ===
              moment(activeFilters?.createdAt).format("MM/DD/YYYY")
          );

          return secondFilteredReports;
        } else {
          return reportsAvailable;
        }
      } else {
        return reportsAvailable;
      }
    }
  }, [searchKeyword, activeFilters, allCategories, reportsAvailable]);

  useEffect(() => {
    setDragItems(reportsToRender);
  }, [reportsToRender]);

  // new log object to store the changes
  let newEditLog = {
    recordId: "",
    recordName: "",
    topic: `ReportsConfiguration/${categoryName}`,
    actionType: "",
    category: "Reports Configuration",
    currentData: {},
    label: "",
    nameOfUser: userConfiguration.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    previousData: {},
    updatedKeys: [],
  };

  // Returns us to the previous view and resets the selected category
  const onBack = () => {
    setActiveView(views.CATEGORIES_VIEW);
    setSelectedCategoryName("");
  };

  /**
   * Opens the report configuration view
   *
   * @param {Event} e onClick event
   * @param {Object} report Object of the report that we want to configure
   */
  const openReport = (e, report) => {
    /** Default is prevented when we are not opening the report
     *  e.g. setting the report as favorite, opening the description, deleting etc.
     */
    if (e.defaultPrevented) return;

    setSelectedReport(report);
    setActiveView(views.REPORT_CONFIG);
  };

  /**
   * Marks a report as favorite, or removes it from favorites
   *
   * @param {Boolean} favorite
   * @param {Object} report
   */
  const onFavoriteClick = (favorite, report) => {
    const { reportId } = report;

    const dispatchArgs = [selectedCategory, reportId, identityId];

    curryDispatch(
      favorite
        ? setFavoriteReport(...dispatchArgs)
        : removeFavoriteReport(...dispatchArgs)
    );
  };

  /**
   * Deletes a report
   *
   * @param {String} reportId
   */
  const onDeleteReport = (reportId) => {
    // Deletes report from DB
    curryDispatch(deleteReport(selectedCategory, reportId));
    // get report obj to delete
    const reportToDelete = reportsAvailable.find(
      (report) => report.reportId === reportId
    );
    // update new log obj with current changes data
    newEditLog.recordId = reportId;
    newEditLog.recordName = reportToDelete.reportName;
    newEditLog.actionType = "Delete";
    newEditLog.currentData = {
      [reportToDelete.reportName]: "'Does not exist'",
    };
    newEditLog.previousData = {
      [reportToDelete.reportName]: reportToDelete.reportName,
    };
    newEditLog.updatedKeys.push(reportToDelete.reportName);
    // update Db with the new log
    saveAddedLogs(newEditLog);
  };

  /**
   * Updates the description of a report
   *
   * @param {Object} report The updated object of the report
   */
  const onDescriptionEdited = (report) => {
    // update DB with edited report
    curryDispatch(updateReport(selectedCategory, report));
    // find report object to edit
    const reportToEdit = reportsAvailable.find(
      (rep) => rep.reportId === report.reportId
    );
    // get changes
    let result = getChangedData(report, reportToEdit);
    // update new log object with current changes
    newEditLog.recordId = report.reportId;
    newEditLog.recordName = report.reportName;
    newEditLog.actionType = "Edit";
    newEditLog.currentData = {
      [report.reportName]: {
        dummyKey: undefined, // key that will be ignored but needed to keep the necessary nesting of logs modal
        Description: result.curr.description,
      },
    };
    newEditLog.previousData = {
      [report.reportName]: {
        dummyKey: undefined, // key that will be ignored but needed to keep the necessary nesting of logs modal
        Description: result.prev.description,
      },
    };
    newEditLog.updatedKeys.push(report.reportName);
    // update DB with the new log
    saveAddedLogs(newEditLog);
  };

  // Previews a report
  const onPreviewReport = (report) => {
    const { reportId, categoryName } = report || {};
    const { reportsAvailable, ...category } = selectedCategory;

    reportOpener(categoryName, reportId, { report, category });
  };

  const retrieveFilter = (temp) => {
    setActiveFilters(temp);
  };

  const onDragEnd = (sourceIndex, destinationIndex) => {
    if (sourceIndex === destinationIndex) {
      setPlayDrag(false);
      return;
    }
    const newItems = Array.from(dragItems);
    const [reorderedItem] = newItems.splice(sourceIndex, 1);
    newItems.splice(destinationIndex, 0, reorderedItem);
    setPlayDrag(true);
    setDragItems(newItems);
  };

  const itemsToRender = useMemo(
    () =>
      dragItems.map((dragReport, index) => ({
        reportId: dragReport.reportId,
        index,
      })),
    [dragItems]
  );

  const handleSaveReportsOrder = () => {
    const updatedPreferences = {
      ...(allPreferences?.preferences || {}),
      ReportsPageSettings: dragItems.map(({ reportId }) => reportId),
    };

    message.loading("Saving...");
    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Changes Saved In Your Preferences!");
        setPlayDrag(false);
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => {
        console.error("Error saving to preferences", err);
        setDragItems(reportsAvailable);
        setPlayDrag(false);
      });
  };

  const onSearchChangeHandler = ({ target }) => setSearchKeyword(target.value);

  const onSearchHandler = debounceSearch(onSearchChangeHandler, 300);

  return (
    <>
      <div
        className={`reportsCategoryConfig ${
          isDarkMode && "reportsCategoryConfigDark"
        }`}
      >
        <ConfigurationPanel
          {...{
            onBack,
            CategoryIcon,
            searchKeyword,
            selectedCategory,
            playDrag,
            setPlayDrag,
            activeFilters,
            dragItems,
            setDragItems,
            setActiveFilters,
            onCreateNewReport: () => setReportModalVisible(CREATING),
            onCloneExistingReport: () => setReportModalVisible(CLONING),
            onSearchChange: (event) => onSearchHandler(event),
            retrieveFilter,
          }}
        />

        <div className="reportsContainer">
          <div className="reportsContainer-header">
            <CategoryIcon className="reportsContainer-icon" />
            <span className="reportsContainer-title">
              {selectedCategory?.categoryName}
            </span>
          </div>
          <div className="reportsContainer-items">
            <ListManager
              key={1}
              items={itemsToRender}
              direction="horizontal"
              maxItems={desktop ? 5 : tablet ? 2 : 1}
              render={(report) => {
                const foundedReport = dragItems.find(
                  (dragReport) => dragReport.reportId === report.reportId
                );

                return (
                  <div
                    style={{
                      padding: desktop ? 5 : 5,
                    }}
                  >
                    <ReportPreviewCard
                      key={report?.reportId}
                      {...{
                        report: foundedReport,
                        onClick: openReport,
                        onDeleteReport,
                        onFavoriteClick,
                        configuring: true,
                        onDescriptionEdited,
                        onPreviewReport,
                        i: report?.index,
                      }}
                    />
                  </div>
                );
              }}
              onDragEnd={onDragEnd}
            />
          </div>
        </div>

        <div className="footerSection">
          <MondayButton
            {...{
              className: "mondayButtonRed",
              Icon: <CloseOutlined />,
              onClick: () => {
                setDragItems(reportsAvailable);
                setPlayDrag(false);
              },
            }}
          >
            Cancel Changes
          </MondayButton>
          <MondayButton
            data-testid="save-changes"
            {...{
              className: "mondayButtonGreen",
              Icon: <CheckOutlined />,
              onClick: handleSaveReportsOrder,
              tooltipKey: "saveCategoryChanges",
              disabled:
                !playDrag ||
                searchKeyword.length !== 0 ||
                !isNaN(activeFilters?.createdAt),
            }}
          >
            Save Changes
          </MondayButton>
        </div>
      </div>
      {reportModalVisible && (
        <NewReportModal
          {...{
            visible: !!reportModalVisible,
            setVisible: setReportModalVisible,
            cloning: reportModalVisible === CLONING,
            newEditLog,
            isDarkMode,
            categoryName,
          }}
        />
      )}
      <ReportView />
    </>
  );
};

export default CategoryConfig;

import axios from "axios";

const BASE_URL =
  process.env.NODE_ENV === "production"
    ? " https://leadmanager-express-backend.ue.r.appspot.com/api/notifications"
    : "http://localhost:8080/api/notifications";

const axiosInstance = axios.create({
  baseURL: BASE_URL,
  headers: {
    "Content-Type": "application/json",
  },
});

const isProd = process.env.NODE_ENV === "production" ? true : false;

const apiService = {
  // Method to modify the selection of notifications based on the provided payload
  async modifySelection(payload) {
    try {
      // Send a POST request to the "/modify-selection" endpoint with the payload and environment flag
      const response = await axiosInstance.post("/modify-selection", {
        ...payload, // Spread the payload to include all necessary data for the request
        isProd, // Include the production environment flag to indicate the environment (production or development)
      });
      return response.data; // Return the response data on successful request
    } catch (error) {
      // Handle any errors that occur during the request
      // If the error response contains a message, throw that; otherwise, throw a generic error message
      throw new Error(error?.response?.data?.message || "An error occurred");
    }
  },

  // Method to modify all notifications based on the provided payload
  async modifyAll(payload) {
    try {
      // Send a POST request to the "/modify-all" endpoint with the payload and environment flag
      const response = await axiosInstance.post("/modify-all", {
        ...payload, // Spread the payload to include all necessary data for the request
        isProd, // Include the production environment flag to indicate the environment (production or development)
      });
      return response.data; // Return the response data on successful request
    } catch (error) {
      console.log("error", error); // Log the error to the console for debugging purposes
      // Handle any errors that occur during the request
      // If the error response contains a message, throw that; otherwise, throw a generic error message
      throw new Error(error.response.message || "An error occurred");
    }
  },

  // Method to update the pinned status of a notification based on the provided payload
  async updateNotificationPinnedStatus(payload) {
    try {
      // Send a POST request to the "/update-notification-pinned-status" endpoint with the payload and environment flag
      const response = await axiosInstance.post(
        "/update-notification-pinned-status",
        {
          ...payload, // Spread the payload to include all necessary data for the request
          isProd, // Include the production environment flag to indicate the environment (production or development)
        }
      );

      return response; // Return the response on successful request
    } catch (error) {
      // Log the error message related to updating the pinned status for debugging purposes
      console.error(
        "error while updating notification isPinned status: ",
        error.response.data.error // Log the specific error returned from the server
      );
      // Handle any errors that occur during the request
      // Throw the specific error message returned from the server
      throw new Error(error.response.data.error);
    }
  },
};

export default apiService;

import { API } from "aws-amplify";
import { AnyAction, Dispatch } from "redux";
import { SetStateAction, Dispatch as ReactDispatch } from "react";

import { updateProgramFieldByName } from "src/actions/programFields";
import { EditIcon } from "src/components/SidebarPages/DynamicView/src";
import { ConfigDataType } from "src/components/pages/PayrollLive/payrollLiveTypes";
import { TrashIcon } from "src/components/SidebarPages/Communication/assets/index";

type UpdateProgramFieldType = {
  fieldId: string;
  fieldName: string;
  fieldOptions: Array<any>;
  dispatch: Dispatch<AnyAction>;
};

type ColumnsProps = {
  setDeleteWarning: ReactDispatch<SetStateAction<string | undefined>>;
  setClientConfigModal: ReactDispatch<SetStateAction<ConfigDataType | boolean>>;
};

const removeStyle = {
  height: 38,
  width: 38,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  cursor: "pointer",
};

export function columnDefs({
  setDeleteWarning,
  setClientConfigModal,
}: ColumnsProps) {
  return [
    {
      headerName: "Client Name",
      field: "clientName",
      cellClass: "cell-left-aligned",
    },
    {
      headerName: "Client Source",
      field: "clientSource",
      cellClass: "cell-left-aligned",
    },
    {
      headerName: "Edit",
      maxWidth: 130,
      cellClass: "cell-center-aligned",
      cellRenderer: ({ data }) => {
        return (
          <div style={removeStyle} onClick={() => setClientConfigModal(data)}>
            <EditIcon fill="#1264a3" />
          </div>
        );
      },
    },
    {
      headerName: "Remove",
      maxWidth: 130,
      cellClass: "cell-center-aligned",
      cellRenderer: ({ data }) => {
        return (
          <div
            style={removeStyle}
            onClick={() => setDeleteWarning(data.configId)}
          >
            <TrashIcon fill="#fe4c4a" />
          </div>
        );
      },
    },
  ];
}

export const updateProgramField = async ({
  fieldId,
  dispatch,
  fieldName,
  fieldOptions,
}: UpdateProgramFieldType) => {
  return await API.patch("programFields", `/programFields/${fieldId}`, {
    body: { fieldOptions },
  })
    .then(() => {
      dispatch(
        updateProgramFieldByName({
          fieldName: fieldName,
          fieldOptions,
        })
      );
    })
    .catch((error) => {
      console.log({ error });
    });
};

export const gridFilters = [
  {
    globalKeyId: "bcf43303-5249-4d90-a35a-ec7f5ac16cf1",
    label: "Client Name",
    type: "Dropdown",
    key: "clientName",
  },
  {
    globalKeyId: "2438abcf-6728-4668-906c-9d2aaf43a897",
    label: "Source",
    type: "Dropdown",
    key: "clientSource",
  },
];

import { NavLink } from "react-router-dom";
import UserAvatar from "../user-avatar/UserAvatar";
import { SettingIcon } from "../../subcomponents";
import Approvals from "../Approvals/Approvals";
import Events from "../GoogleEvents/components/previewEvent/Events";
import { ModalVideoTutorials } from "../ModalVideoTutorials/ModalVideoTutorials";
import Notifications from "../Notifications/Notifications";
import Tasks from "../LoggedOnTasks/LoggedOnTasks";
// import Todos from "../ToDos/ToDos";
import "./HeaderActions.scss";
import Logout from "../Logout/Logout";
import { useSelector } from "react-redux";
import SessionCheck from "../SessionCheck/SessionCheck";
import { WithTooltip } from "../../../commonComponents";
import { devUsers } from "../../utils/devUsers";
import HeaderAutomations from "../HeaderAutomations/HeaderAutomations";
import { receiveSessionNotification } from "../../utils/devUsers";
import { getAccessRights } from "../../../../utils";
import NotificationsDrawerProvider from "../Notifications/providers/NotificationsDrawerProvider";
import ChatBotHeader from "../ChatBotHeader/ChatBotHeader";

const HeaderActions = () => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const hasSettingsAccess = getAccessRights({
    userConfiguration,
    title: "Settings",
  })?.routeConfig?.read;

  return (
    <div className={`right-actions-container ${isDarkMode ? "dark-mode" : ""}`}>
      {receiveSessionNotification?.includes(userConfiguration?.nameOfUser) ? (
        <SessionCheck {...{ isDarkMode }} />
      ) : null}
      <ChatBotHeader />
      <ModalVideoTutorials />
      <HeaderAutomations />
      <Tasks />
      <Approvals />
      <Events />
      {/* <Todos /> */}
      <NotificationsDrawerProvider>
        <Notifications />
      </NotificationsDrawerProvider>

      {hasSettingsAccess && (
        <WithTooltip tooltipCategory="HEADER" tooltipKey="settings">
          <NavLink to="/settings" style={{ display: "flex" }}>
            <SettingIcon className="header-icon" />
          </NavLink>
        </WithTooltip>
      )}

      {hasSettingsAccess ? (
        <NavLink
          to={{ pathname: "/settings", state: { selectedSetting: "1" } }}
        >
          <UserAvatar />
        </NavLink>
      ) : (
        <UserAvatar />
      )}

      <Logout />
    </div>
  );
};

export default HeaderActions;

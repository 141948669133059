import { getParams } from "../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  let subType = new Set();
  let recordType = new Set();
  let borough = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const subcontractType = item.subcontractorType || "No Data";
    const accRecordType = item.accountRecordType || "No Data";
    const oStage = item.borough || "No Data";
    const user = item.createdBy || "No Data";

    subType.add(subcontractType);
    recordType.add(accRecordType);
    borough.add(oStage);
    createdBy.add(user);
  });

  const chartingOptions = [
    {
      optionTitle:
        "Show total Subcontractors count based on Subcontractor Type",
      dataKey: "subcontractorType",
      params: getParams({
        data,
        chartData: subType,
        chartKey: "subcontractorType",
        label: "Subcontractor Type",
        text: "Total Subcontractors count based on Subcontractor Type",
      }),
    },
    {
      optionTitle:
        "Show total Subcontractors count based on Account Record Type",
      dataKey: "accountRecordType",
      params: getParams({
        data,
        chartData: recordType,
        chartKey: "accountRecordType",
        label: "Record Type",
        text: "Total Subcontractors count based on Record Type",
      }),
    },
    {
      optionTitle: "Show total Subcontractors count based on Borough",
      dataKey: "borough",
      params: getParams({
        data,
        chartData: borough,
        chartKey: "borough",
        label: "Borough",
        text: "Total Subcontractors count based on Borough",
      }),
    },
    {
      optionTitle: "Show total Subcontractors count based on Created By",
      dataKey: "createdBy",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy",
        label: "Created By",
        text: "Total Subcontractors count based on Created By",
      }),
    },
  ];

  return chartingOptions;
};

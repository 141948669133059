import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { formatDate, getYear } from "./formatUtils";

export const getFleetExpenses = async () => {
  try {
    const workOrders = await fetchData("workOrders");

    return workOrders.flatMap((order) => {
      return order.laborInformation.map((labor) => {
        return {
          fleetName:
            order.fleetName.toLowerCase().includes("truck") ||
            order.fleetName.toLowerCase().includes("trailer")
              ? order.fleetName.replace(/\D/g, "")
              : order.fleetName,
          createdAt: formatDate(order.createdAt),
          dueDate: formatDate(order.dueDate),
          year: getYear(order.createdAt),
          workOrdersAmount: labor.rate * labor.hoursWorked,
          workOrderStatus: order.workOrderStatus,
          laborName: labor.laborName,
        };
      });
    });
  } catch (error) {
    console.error("data source function getFleetExpenses error: " + error);
  }
};

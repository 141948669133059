import { useState, useEffect, useContext, useMemo, useRef } from "react";
import { useSelector } from "react-redux";
import { Modal, Radio } from "antd";

import { StoreType } from "../../types";
import {
  BASE_REPORT_STYLES,
  PRINT_PAGE_CONFIGS,
} from "src/components/SidebarPages/Fleet/fleetsLive/data";
import { compareIncluding } from "src/components/SidebarPages/utils";
import { MaintenanceViewContext, MAINTENANCE_REPORT_STYLES } from "../../data";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { generateHTMLTemplate } from "src/components/SidebarPages/Fleet/fleetsLive/components/LiveReportsView/utils";
import ExportFooter from "./ExportFooter";
import { ReportCheck } from "src/components/SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { generateMaintenancePdfTemplate } from "../../utils";

import "./MaintenanceExportModal.scss";

type ExportProps = {
  open: boolean;
  onCancel: () => any;
  selectedVehicle: string;
  dateRange: [number, number];
};

type PaseSize = keyof typeof PRINT_PAGE_CONFIGS;

function MaintenanceExportModal({
  open,
  onCancel,
  dateRange,
  selectedVehicle,
}: ExportProps) {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { base64 } = useSelector((state: StoreType) => state.base64);

  const { data } = useContext(MaintenanceViewContext);

  const { defects, inspections, workOrders, inventoryItems, fleet } = data;

  const [landscape, setLandscape] = useState(false);
  const [dataSections, setDataSections] = useState([]);
  const [pageSize, setPageSize] = useState<PaseSize>("A4");
  const [precedence, setPrecedence] = useState<"DATE" | "VEHICLE">("DATE");
  const [include, setInclude] = useState<Array<"INSPECTION" | "WORK_ORDER">>(
    []
  );

  const previewRef = useRef<HTMLIFrameElement>(null);

  const baseConfig = useMemo(() => {
    return [
      {
        image: {
          src: base64?.find(({ fileName }) =>
            compareIncluding(fileName, "Core Logo Black")
          )?.base64,
          class: "baseLogo",
        },
        class: "headerSection",
      },
    ];
  }, [base64]);

  const reportStyles = useMemo(() => {
    return {
      ...BASE_REPORT_STYLES,
      ...MAINTENANCE_REPORT_STYLES,
    };
  }, []);

  useEffect(() => {
    setDataSections([...baseConfig]);
  }, []);

  useEffect(() => {
    setDataSections([
      ...baseConfig,
      ...generateMaintenancePdfTemplate({
        defects,
        inspections,
        precedence,
        include,
        workOrders,
        inventoryItems,
        dateRange,
        selectedVehicle,
      }),
    ]);
  }, [
    baseConfig,
    defects,
    inspections,
    precedence,
    include,
    workOrders,
    inventoryItems,
    dateRange,
    selectedVehicle,
  ]);

  useEffect(() => {
    if (!previewRef.current) {
      return;
    }

    const w = previewRef.current.contentWindow;
    if (!w) {
      return;
    }

    const html = w.document.querySelector("html");
    if (html) {
      html.innerHTML = generateHTMLTemplate({
        dataSections,
        styles: reportStyles,
        landscape,
        pageSize,
        title: `Maintenance Report - ${parseInTz(dateRange[0]).format(
          "MMM DD, YYYY"
        )} to ${parseInTz(dateRange[1]).format("MMM DD, YYYY")}${
          selectedVehicle
            ? ` for ${
                fleet.find(({ fleetId }) => fleetId === selectedVehicle)
                  ?.fleetName
              }`
            : ""
        }`,
      });
    }
  }, [dataSections, landscape, pageSize]);

  function onPrint() {
    if (!previewRef.current) {
      return;
    }

    const w = previewRef.current.contentWindow;
    if (w) {
      w.print();
    }
  }

  return (
    <Modal
      {...{
        open,
        onCancel,
        centered: true,
        closeIcon: <XIcon />,
        title: "Maintenance Export",
        "data-testid": "maintenance-export-modal",
        className: `maintenance-export-container ${
          isDarkMode ? "maintenance-export-dark" : ""
        }`,
        footer: (
          <ExportFooter
            {...{
              onPrint,
              onCancel,
            }}
          />
        ),
      }}
    >
      <div className={`configuration-section ${landscape ? "landscape" : ""}`}>
        <div className="choose-size-section">
          <span className="preview-label">Preview for:</span>
          <Radio.Group
            buttonStyle="solid"
            defaultValue={"A4"}
            onChange={(ev) => {
              setPageSize(ev.target.value);
            }}
          >
            {Object.keys(PRINT_PAGE_CONFIGS).map((e) => (
              <Radio.Button key={e} value={e}>
                {e}
              </Radio.Button>
            ))}
          </Radio.Group>
        </div>
        <ReportCheck
          {...{
            label: "Landscape",
            style: { marginBottom: "2rem" },
            onChange(checked) {
              setLandscape(checked);
            },
          }}
        />
        <ReportCheck
          label="Group By Vehicle #"
          checked={precedence === "VEHICLE"}
          defaultChecked={precedence === "VEHICLE"}
          onChange={(checked) => {
            if (checked) {
              setPrecedence("VEHICLE");
            } else {
              setPrecedence("DATE");
            }
          }}
        />
        <ReportCheck
          label="Group By Date"
          checked={precedence === "DATE"}
          style={{ marginBottom: "2rem" }}
          defaultChecked={precedence === "DATE"}
          onChange={(checked) => {
            if (checked) {
              setPrecedence("DATE");
            } else {
              setPrecedence("VEHICLE");
            }
          }}
        />
        <ReportCheck
          label="Include Inspections"
          checked={include.includes("INSPECTION")}
          defaultChecked={include.includes("INSPECTION")}
          onChange={(checked) => {
            if (checked) {
              setInclude([...include, "INSPECTION"]);
            } else {
              setInclude(include.filter((e) => e !== "INSPECTION"));
            }
          }}
        />
        <ReportCheck
          label="Include Work Orders"
          checked={include.includes("WORK_ORDER")}
          defaultChecked={include.includes("WORK_ORDER")}
          onChange={(checked) => {
            if (checked) {
              setInclude([...include, "WORK_ORDER"]);
            } else {
              setInclude(include.filter((e) => e !== "WORK_ORDER"));
            }
          }}
        />
      </div>
      <div
        className={`preview-section ${landscape ? "landscape" : ""}`}
        style={{
          aspectRatio: landscape
            ? PRINT_PAGE_CONFIGS[pageSize]["landscapeRatio"]
            : PRINT_PAGE_CONFIGS[pageSize]["aspectRatio"],
        }}
      >
        <iframe
          ref={previewRef}
          className="preview-frame"
          name="preview-frame"
          height={"100%"}
          width={"100%"}
        />
      </div>
    </Modal>
  );
}

export default MaintenanceExportModal;

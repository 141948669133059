import getWeeksTotal from "./getWeeksTotal";
import getOverheadEntriesAnalytics from "./getOverheadEntriesAnalytics";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";

/**
 * Function that gets analytics on a shift-based manner,
 * we use the "getEmployeeAnalytics" function when dealing with
 * individual punches. We use this function when dealing with grouped shifts
 */
function getShiftAnalytics({
  gridApi,
  degRows,
  employeeList,
  externalFiltersPass,
  analyticsUpdate = () => {},
}) {
  if (!gridApi) {
    return;
  }

  let tmpRows = [];
  let hoursPerDay = {};
  let weeksForWorker = {};
  let overheadShiftRows = [];
  let shiftsWithMultipleJobsites = [];

  gridApi.forEachNode(({ data }) => {
    if (dataValidator({ data, externalFiltersPass, employeeList })) {
      if (
        data?.shiftType === "CASH Shift" ||
        data?.shiftType === "1099 Shift"
      ) {
        overheadShiftRows.push(data);
      } else {
        tmpRows.push(data);
      }
    }
  });

  if (tmpRows?.length === 0 && !!degRows?.length) {
    for (let i = 0; i < degRows?.length; i++) {
      const data = degRows[i];
      if (dataValidator({ data, externalFiltersPass, employeeList })) {
        if (
          data?.shiftType === "CASH Shift" ||
          data?.shiftType === "1099 Shift"
        ) {
          overheadShiftRows.push(data);
        } else {
          tmpRows.push(data);
        }
        // tmpRows.push(data);
      }
    }
  }

  tmpRows.sort((a, b) => a.firstClockIn - b.firstClockIn);

  for (const entry of tmpRows) {
    const {
      crewId,
      hrShift,
      company,
      geofence,
      uploadId,
      punchDate,
      punchTime,
      workHours,
      employeeId,
      salaryType,
      uploadName,
      breakHours,
      lunchStart,
      companyName,
      employeeRate,
      overtimeHours,
      reimbursement = false,
    } = entry;

    if (!hoursPerDay[employeeId]) {
      hoursPerDay[employeeId] = {};
    }

    let dateKey = dayjsNY(punchDate).format("MM/DD/YYYY");
    let firstEntryCondition = false;

    if (!hoursPerDay[employeeId][dateKey]) {
      hoursPerDay[employeeId][dateKey] = {
        workHours,
        breakHours,
        overtimeHours,
        company: company || companyName,
        companyName,
        crewId,
        geofence,
        salaryType,
        uploadId,
        uploadName,
        punchDate,
        punchTime,
        lunchStart,
        firstClockIn: entry["firstClockIn"],
        clockOut: entry["clockOut"],
        // role: entry["role"],
        employeeRole: entry["employeeRole"],
        // type: entry["type"],
        employeeType: entry["employeeType"],
        jobsitesInfo: [],
        hrShift,
      };
      firstEntryCondition = true;
    } else {
      let tmpHorus = { ...hoursPerDay[employeeId][dateKey] };
      hoursPerDay[employeeId][dateKey] = {
        ...tmpHorus,
        workHours: tmpHorus["workHours"] + workHours,
        breakHours: tmpHorus["breakHours"] + breakHours,
        overtimeHours: tmpHorus["overtimeHours"] + overtimeHours,
        clockOut: entry["clockOut"],
      };
    }

    let jobsitesInfo = [...hoursPerDay[employeeId][dateKey]["jobsitesInfo"]];
    let matchedJobId = entry?.jobsiteMatch?.jobsiteId;
    let jobIndex = jobsitesInfo?.findIndex(
      ({ jobsiteId }) => jobsiteId === matchedJobId
    );

    if (jobIndex === -1) {
      hoursPerDay[employeeId][dateKey]["jobsitesInfo"] = [
        ...jobsitesInfo,
        {
          workHours,
          breakHours,
          overtimeHours,
          inJobsiteId: entry["jobsiteMatch"]["jobsiteId"],
          firstClockIn: entry["firstClockIn"],
          punchCoordinates: entry["punchCoordinates"],
          payrollType: entry["payrollType"],
          sow: entry["sow"] || [],
          employeeRate,
          employeeRole: entry["employeeRole"],
          reimbursement,
          hrShift: false,
          regAmount: workHours * employeeRate,
          otAmount: overtimeHours * employeeRate * 1.5,
        },
      ];
      if (firstEntryCondition) {
        hoursPerDay[employeeId][dateKey]["inJobsiteId"] =
          entry["jobsiteMatch"]["jobsiteId"];
        hoursPerDay[employeeId][dateKey]["outJobsiteId"] =
          entry["jobsiteMatch"]["jobsiteId"];
      }
    } else {
      let tmpInfo = { ...jobsitesInfo[jobIndex] };
      jobsitesInfo[jobIndex] = {
        ...tmpInfo,
        workHours: tmpInfo["workHours"] + workHours,
        breakHours: tmpInfo["breakHours"] + breakHours,
        overtimeHours: tmpInfo["overtimeHours"] + overtimeHours,
        regAmount: tmpInfo["regAmount"] + workHours * employeeRate,
        otAmount: tmpInfo["otAmount"] + overtimeHours * employeeRate * 1.5,
        sow: [...tmpInfo["sow"], ...(entry?.sow || [])],
      };
      hoursPerDay[employeeId][dateKey]["jobsitesInfo"] = [...jobsitesInfo];
      hoursPerDay[employeeId][dateKey]["outJobsiteId"] =
        entry["jobsiteMatch"]["jobsiteId"];
    }
  }

  const employeeOverheadShifts = getOverheadEntriesAnalytics({
    degRows: overheadShiftRows,
  });

  const workerList = _.uniq(
    Object.keys(hoursPerDay).concat(Object.keys(employeeOverheadShifts))
  );

  let generalOverheadTotal = 0;
  overheadShiftRows.forEach((data) => {
    if (!data?.punchDate) {
      generalOverheadTotal += Number(data?.totalOvh);
    }
  });

  weeksForWorker = getWeeksTotal({
    workerList,
    allEntries: hoursPerDay,
    employeeOverheadShifts,
  });

  for (const employeeId in hoursPerDay) {
    for (const date in hoursPerDay[employeeId]) {
      const { inJobsiteId = "", outJobsiteId = "" } =
        hoursPerDay[employeeId][date];
      if (inJobsiteId !== outJobsiteId) {
        shiftsWithMultipleJobsites.push({
          ...hoursPerDay[employeeId][date],
          employeeId,
        });
      }
    }
  }

  analyticsUpdate({
    employeesHoursPerDay: hoursPerDay,
    employeeWeekTotals: weeksForWorker,
    shiftsWithMultipleJobsites,
    generalOverheadTotal,
    employeeOverheadShifts,
  });
}

function dataValidator({ data, externalFiltersPass, employeeList }) {
  let pushCondition = false;

  if (["CASH Shift", "1099 Shift"].includes(data?.shiftType)) {
    return true;
  }
  if (!!data?.punchDate && !!data?.punchTime && !!data?.employeeId) {
    if (employeeList) {
      if (employeeList?.includes(data?.employeeId)) {
        if (externalFiltersPass) {
          pushCondition = externalFiltersPass({ data });
        } else {
          pushCondition = true;
        }
      }
    } else {
      if (externalFiltersPass) {
        pushCondition = externalFiltersPass({ data });
      } else {
        pushCondition = true;
      }
    }
  }

  return pushCondition;
}

export default getShiftAnalytics;

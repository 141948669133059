import { CloseOutlined } from "@ant-design/icons";
import { Drawer } from "antd";
import React from "react";
import { useSelector } from "react-redux";
import { NavLink } from "react-router-dom";
import { getAccessRights } from "../../utils";
import Approvals from "../Header/components/Approvals/Approvals";
import Events from "../Header/components/GoogleEvents/components/previewEvent/Events";
import Tasks from "../Header/components/LoggedOnTasks/LoggedOnTasks";
import Logout from "../Header/components/Logout/Logout";
import { ModalVideoTutorials } from "../Header/components/ModalVideoTutorials/ModalVideoTutorials";
import Notifications from "../Header/components/Notifications/Notifications";
// import Todos from "../Header/components/ToDos/ToDos";
import UserAvatar from "../Header/components/user-avatar/UserAvatar";
import { SettingIcon } from "../Header/subcomponents/index";
import "./HeaderRightActions.scss";
import { useDrawer } from "./RightActionsMobileDrawerProvider";
import HeaderAutomations from "../Header/components/HeaderAutomations/HeaderAutomations";
import NotificationsDrawerProvider from "../Header/components/Notifications/providers/NotificationsDrawerProvider";
import ChatBotHeader from "../Header/components/ChatBotHeader/ChatBotHeader";

const RightActionsMobile = () => {
  const { isDrawerOpen, toggleDrawer } = useDrawer();
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const hasSettingsAccess = getAccessRights({
    userConfiguration,
    title: "Settings",
  })?.routeConfig?.read;
  const handleOpenMenu = () => {
    toggleDrawer();
  };

  return (
    <>
      <div className="user-sidebar-container" onClick={() => handleOpenMenu()}>
        <UserAvatar />
      </div>

      <Drawer
        title={`${authenticatedUser?.given_name} ${authenticatedUser?.family_name}`}
        placement="right"
        onClose={() => toggleDrawer()}
        open={isDrawerOpen}
        closeIcon={<CloseOutlined style={{ color: "#fff" }} />}
        rootClassName="drawer-right-tablet"
        closable={true}
        destroyOnClose={true}
      >
        <ul className="drawer-right-tablet-content">
          {[
            {
              title: "Virtual Assistant",
              component: <ChatBotHeader toggleDrawer={toggleDrawer} />,
              accessRight: userConfiguration?.virtualAssistant?.enabled,
            },
            {
              title: "Video Tutorials",
              component: <ModalVideoTutorials />,
              accessRight: true,
            },
            {
              title: "Active Automations",
              component: <HeaderAutomations />,
              accessRight: getAccessRights({
                userConfiguration,
                title: "Settings",
              })?.routeConfig?.children?.find(
                ({ title }) => title && title === "Automation"
              ),
            },
            {
              title: "Tasks",
              component: <Tasks />,

              accessRight: getAccessRights({
                userConfiguration,
                title: "Task Manager",
              })?.routeConfig.read,
            },
            {
              title: "Approvals",
              component: <Approvals />,
              accessRight: getAccessRights({
                userConfiguration,
                title: "approvals",
              })?.routeConfig.read,
            },
            {
              title: "Events",
              component: <Events />,
              accessRight: getAccessRights({
                userConfiguration,
                title: "approvals",
              })?.routeConfig.read,
            },
            // {
            //   title: "To do",
            //   component: <Todos />,
            //   accessRight: getAccessRights({
            //     userConfiguration,
            //     title: "todos",
            //   })?.routeConfig.read,
            // },
            {
              title: "Notifications",
              component: (
                <NotificationsDrawerProvider>
                  <Notifications />
                </NotificationsDrawerProvider>
              ),
              accessRight: true,
            },

            hasSettingsAccess && {
              title: "Settings",
              component: (
                <NavLink to={"/settings"}>
                  <SettingIcon className="header-icon" />
                </NavLink>
              ),
              accessRight: true,
            },
            {
              title: "Log out",
              component: <Logout />,
              accessRight: true,
            },
          ]
            .filter(Boolean)
            .map((item, index) => {
              if (!item.accessRight) return null;
              return (
                <li key={index} className="drawer-right-tablet-content-item">
                  {item.component}
                  <label>{item.title}</label>
                </li>
              );
            })}
        </ul>
      </Drawer>
    </>
  );
};

export default RightActionsMobile;

import { useEffect, useMemo, useState } from "react";
import Transfer from "../Transfer/Transfer";
import _ from "lodash";
import { Input, message } from "antd";
import { SearchIcon } from "../../../Communication/assets";
import { useSelector } from "react-redux";
import TeamsList from "../../../../Header/forms/DynamicTeamModal/TeamsList";
import { InfoText } from "../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { InputComponent } from "../../../Fleet/components";
import { API } from "aws-amplify";
import { onTransferChange } from "../../../../Header/forms/DynamicTeamModal/helpers";
import { SmallLoadableComp } from "../../../../Sidebars/components";

function filterUniqueMembers(teams) {
  const uniqueMembers = new Set();
  const tmp = [...teams];

  return tmp.map((team) => {
    const filteredMembers = team.members.filter((member) => {
      if (uniqueMembers.has(member.identityId)) {
        return false;
      }
      uniqueMembers.add(member.identityId);
      return true;
    });

    return {
      ...team,
      members: filteredMembers,
    };
  });
}

//? EDIT CARD
function TeamsCard(props) {
  const { params, setEditMode, getEditedValue, form } = props;
  const { teamsConfiguration } = params;
  const [searchTeamMembers, setSearchTeamMembers] = useState([]);
  const [selectedTeamTmp, setSelectedTeamTmp] = useState(
    filterUniqueMembers(teamsConfiguration)
  );

  const [allTeams, setAllTeams] = useState([]);
  const [loading, setLoading] = useState(false);

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const getUsers = useMemo(
    () =>
      userConfiguration?.allUsers?.Items?.filter(
        ({ isSuspended }) => !isSuspended
      ).map((user) => ({
        identityId: user?.identityId,
        nameOfUser: user?.nameOfUser,
        cognitoUserId: user?.cognitoUserId,
        groupName: user?.groupName,
        userName: user?.userName,
        googleDriveFieId: user?.googleDriveFieId,
      })),
    [userConfiguration]
  );

  const allUsersObj = useMemo(() => {
    return getUsers.reduce((acc, user) => {
      acc[user.identityId] = user;
      return acc;
    }, {});
  }, [getUsers]);

  const [activeFilter, setActiveFilter] = useState(false);

  const comparator = useMemo(() => {
    return teamsConfiguration?.every((team) => team.hasOwnProperty("teamId"))
      ? "teamId"
      : "teamName";
  }, []);

  const teamSelectedValues = useMemo(() => {
    if (Array.isArray(selectedTeamTmp) && selectedTeamTmp?.length > 0) {
      let funcArr = [];
      selectedTeamTmp?.map((el) => {
        el?.members?.map((x) => {
          funcArr.push(x?.identityId);
        });
      });
      return funcArr;
    } else {
      return [];
    }
  }, [selectedTeamTmp]);

  const ClearOptions = () => {
    setEditMode(false);
  };

  const getEditedValues = async (team) => {
    function getPrevCurr(teamArr) {
      return {
        id: props?.id,
        Card: "TeamsCard",
        title: props?.title,
        params: {
          id: params?.id,
          formItemName: "teamsConfiguration",
          teamsConfiguration: teamArr,
          logs: true,
        },
      };
    }

    let body = {
      teamsConfiguration: selectedTeamTmp,
    };

    getEditedValue(
      body,
      {},
      {},
      getPrevCurr(params?.teamsConfiguration),
      getPrevCurr(selectedTeamTmp)
    );
    ClearOptions();
  };

  //* search filter at Teams card
  const onSearchTeam = (e) => {
    let substring = e?.target?.value;
    let filteredData = getUsers?.filter((item) =>
      item?.nameOfUser?.toLowerCase()?.includes(substring?.toLowerCase())
    );
    setSearchTeamMembers(filteredData);
    !!substring ? setActiveFilter(true) : setActiveFilter(false);
  };

  function debounceSearch(func, timeout = 150) {
    let timer;
    return (...args) => {
      clearTimeout(timer);
      timer = setTimeout(() => {
        func.apply(this, args);
      }, timeout);
    };
  }

  const getContent = useMemo(() => {
    const userList = activeFilter ? searchTeamMembers : getUsers;

    return userList?.map((users) => ({
      title: users?.nameOfUser,
      id: users?.identityId,
      role: users?.groupName,
      email: users?.userName,
    }));
  }, [activeFilter, searchTeamMembers, getUsers]);

  useEffect(() => {
    (async () => {
      setLoading(true);
      API.get("teams", "/teams")
        .then((res) => {
          setAllTeams(res);
          setLoading(false);
        })
        .catch((e) => {
          console.log(e);
          setLoading(false);
        });
    })();
  }, []);

  return (
    <div className="teamsCardContainer">
      <InfoText
        {...{
          text: "If you're making changes, please do not forget to save them by clicking the button below.",
        }}
      />

      {!loading ? (
        <TeamsList
          {...{ selectedTeamTmp, setSelectedTeamTmp, allTeams, comparator }}
        />
      ) : (
        <div style={{ position: "relative", height: 100 }}>
          <SmallLoadableComp {...{ loading }} />
        </div>
      )}

      <InputComponent
        {...{
          className: "searchEditMembers",
          placeholder: "Search members...",
          allowClear: true,
          prefix: <SearchIcon width={15} height={15} />,
          onChange: debounceSearch((e) => onSearchTeam(e)),
          noFormItem: true,
        }}
      />
      <div className="teamsInnerContainer">
        {!!teamSelectedValues && (
          <Transfer
            params={{
              onTransferChange: (type, userId) => {
                onTransferChange({
                  type,
                  userId,
                  selectedTeamTmp,
                  setSelectedTeamTmp,
                  allUsersObj,
                  allTeams,
                  comparator,
                });
              },
              content: {
                value: teamSelectedValues,
                data: getContent,
                formItemName: "members",
              },
            }}
            getEditedValue={getEditedValues}
            setEditMode={() => {}}
            setCloseTeamModalsOnCancel={() => {
              onSearchTeam({ target: { value: "" } });
              ClearOptions();
            }}
          />
        )}
      </div>
    </div>
  );
}
export default TeamsCard;

import { getParams } from "../../../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  let client = new Set();
  let clientAmount = new Set();
  let status = new Set();
  let statusAmount = new Set();
  let project = new Set();
  let projectAmount = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const chargeProject = item?.customer || "No Data";
    const invoiceStatus = item?.invoiceStatus || "No Data";
    const chargetype = item?.projectName || "No Data";
    const user = item?.createdBy?.name || "No Data";

    client.add(chargeProject);
    clientAmount.add(chargeProject);
    status.add(invoiceStatus);
    statusAmount.add(invoiceStatus);
    project.add(chargetype);
    projectAmount.add(chargetype);
    createdBy.add(user);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total Invoice count based on Client",
      dataKey: "customer",
      params: getParams({
        data,
        chartData: client,
        chartKey: "customer",
        label: "Client",
        text: "Total Invoice count based on Client",
      }),
    },
    {
      optionTitle: "Show total Invoice count based on Status",
      dataKey: "invoiceStatus",
      params: getParams({
        data,
        chartData: status,
        chartKey: "invoiceStatus",
        label: "Status",
        text: "Total Invoice count based on Status",
      }),
    },
    {
      optionTitle: "Show total Invoice count based on Project",
      dataKey: "projectName",
      params: getParams({
        data,
        chartData: project,
        chartKey: "projectName",
        label: "Project",
        text: "Total Invoice count based on Project",
      }),
    },
    {
      optionTitle: "Show total Invoice count based on Created By",
      dataKey: "createdBy.name",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy.name",
        label: "Created By",
        text: "Total Invoice count based on Created By",
      }),
    },
    {
      optionTitle: "Show total Invoice amount based on Client",
      dataKey: "customer",
      calcKey: "amount",
      params: getParams({
        data,
        chartData: clientAmount,
        chartKey: "customer",
        label: "Client",
        text: "Total Invoice count based on Client",
        type: "Amount",
      }),
    },
    {
      optionTitle: "Show total Invoice amount based on Status",
      dataKey: "invoiceStatus",
      calcKey: "amount",
      params: getParams({
        data,
        chartData: statusAmount,
        chartKey: "invoiceStatus",
        label: "Status",
        text: "Total Invoice count based on Status",
        type: "Amount",
      }),
    },
    {
      optionTitle: "Show total Invoice amount based on Project",
      dataKey: "projectName",
      calcKey: "amount",
      params: getParams({
        data,
        chartData: projectAmount,
        chartKey: "projectName",
        label: "Project",
        text: "Total Invoice count based on Project",
        type: "Amount",
      }),
    },
  ];

  return chartingOptions;
};

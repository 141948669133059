import { useEffect, useLayoutEffect } from "react";
import { CardView } from "..";
import { MondayButton } from "../../../../commonComponents";
import _ from "lodash";
import "./DifferenceModal.scss";
import { XIcon } from "../../../Communication/assets/index";
import { CloseOutlined } from "@ant-design/icons";
import { useSelector } from "react-redux";
import GeofenceLogsModal from "./GeofenceLogsModal";

const DifferenceModal = ({ params, setDifferenceLog }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { previus, next } = params;

  const isGeoFence = previus?.cardParams?.params?.id === "geofence";
  const oldOrNewStructureOfGeoFencePrevious = isGeoFence ? "new" : "old";

  return isGeoFence ? (
    <GeofenceLogsModal
      {...{
        params,
        setDifferenceLog,
        open: true,
        fromWhichPeriod: oldOrNewStructureOfGeoFencePrevious,
      }}
    />
  ) : (
    <div
      className={`DifferenceModalContainer ${
        isDarkMode && "DifferenceModalContainerDark"
      }`}
      id="DifferenceModalContainer"
    >
      <div className="DifferenceModalHeader">
        <div className="DifferenceModalTitle">Difference Modal</div>
        <div className="DifferenceModalCloseIcon">
          <XIcon onClick={() => setDifferenceLog(false)} />
        </div>
      </div>
      <div className="DifferenceModal">
        <div className="differenceModalContent" id="prev">
          <span className="differenceModalContentTitle">Previous Card</span>
          <div>
            {!!CardView?.[previus?.Card] &&
              CardView?.[previus?.Card]({
                ...previus,
                params: previus?.cardParams?.params,
                headerLabel: previus?.headerLabel,
              })}
          </div>
        </div>
        <div className="differenceModalContent" id="curr">
          <span className="differenceModalContentTitle">Current Card</span>
          <div>
            {!!CardView?.[previus?.Card] &&
              CardView?.[previus?.Card]({
                ...next,
                params: next?.cardParams?.params,
                headerLabel: next?.headerLabel,
              })}
          </div>
        </div>
      </div>
      <div className="ButtonContainer">
        <MondayButton
          {...{
            className: "actionButton",
            Icon: <CloseOutlined />,
            onClick: () => setDifferenceLog(false),
          }}
        >
          Close
        </MondayButton>
      </div>
    </div>
  );
};
export default DifferenceModal;

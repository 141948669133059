import { groupBy } from "lodash";

import { EntryType } from "src/components/pages/PayrollLive/payrollLiveTypes";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

type Props = {
  entries: Array<EntryType>;
};

type GroupedEntries = { [index: string]: Array<EntryType> };

// const groupStatuses = ["noLunch", "incorrect", "hr"];
const shiftFormat = ["ID", "OL", "IL", "OD"];
const shiftNoLunch = ["ID", "OD"];

export function getShiftOrder(props: Props) {
  const { entries } = props;
  let reportsToReturn = [];
  const dateGroup: GroupedEntries = groupBy(entries, (emp: EntryType) =>
    parseInTz(emp?.punchDate).format("YYYY-MM-DD")
  );

  for (const date in dateGroup) {
    const dateEntries = dateGroup?.[date] || [];
    const groupedEmployees: GroupedEntries = groupBy(
      dateEntries,
      (emp: EntryType) => emp?.employeeId
    );
    for (const employeeId in groupedEmployees) {
      if (Object.prototype.hasOwnProperty.call(groupedEmployees, employeeId)) {
        const employeeEntries = groupedEmployees[employeeId].sort(
          (a, b) => a?.punchTimeStamp - b?.punchTimeStamp
        );
        let status = "";
        const entriesType = groupBy(employeeEntries, (el) => el?.punchType);
        const shiftPunchTypes = Object.keys(entriesType);
        let regularShift = false;
        let noLunch = false;
        for (const punchType in entriesType) {
          const typeEntires = entriesType[punchType];
          if (typeEntires?.length > 1) {
            status = "incorrect";
            break;
          }
          if (shiftFormat.includes(punchType)) {
            regularShift = true;
          } else {
            regularShift = false;
          }

          if (shiftNoLunch.includes(punchType)) {
            noLunch = true;
          } else {
            noLunch = false;
          }
        }

        if (noLunch && shiftPunchTypes?.length === 2) {
          status = "noLunch";
        }

        if (shiftPunchTypes?.length === 1 && shiftPunchTypes?.[0] === "HR") {
          status = "hr";
        } else if (shiftPunchTypes?.length == 1) {
          status = "incorrect";
        }

        if (shiftPunchTypes?.length === 4 && !regularShift) {
          status = "incorrect";
        }

        if (shiftPunchTypes?.length === 3) {
          status = "incorrect";
        }

        if (
          shiftPunchTypes?.length === 2 &&
          (shiftPunchTypes.includes("IL") || shiftPunchTypes.includes("OL"))
        ) {
          status = "incorrect";
        }

        const empReport = {
          ...entriesType,
          date,
          status,
          employeeId,
          crewId: employeeEntries?.[0]?.crewId,
          employeeName: employeeEntries?.[0]?.employeeFullName || "",
        };
        reportsToReturn.push(empReport);
      }
    }
  }

  return reportsToReturn;
}

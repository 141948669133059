import {
  exportToExcelData,
  rentalDataExtractor,
} from "./exportToExcelFunctions";
import { useStateContext } from "../../../../../../Context/Context";
import { Excel } from "../../../../../../../../../../BasePage/src";
import { exportToXLSX } from "../../../../../exports/exportToXLSX";
import "./ExportToExcel.scss";

import { Tooltip, message } from "antd";
import moment from "moment-timezone";
import { useSelector } from "react-redux";
import { getNYDate } from "../../../../../../utils/dateFunctions";
import Swal from "sweetalert2";
import { MondayButton } from "../../../../../../../../../../../commonComponents";
import { driveApi } from "../../../../../../../../../../../../integrations/DriveRequest";
import { swalToast } from "../../../../../../../../../../../Header/forms/Scheduling/SchedulingFirstPage/helperData";
import { DriveIcon } from "../../../../../../../../../../Communication/assets";
import DriveNavigate from "../DriveNavigate/DriveNavigate";
import { filterTables } from "../../../../../../../../../../../../utils";

const ExportToExcel = () => {
  moment.tz.setDefault("America/New_York");
  const { fakeApplication } = useStateContext();
  const { applications } = useSelector((state) => state.applications);

  const { hotCredentials = [] } = useSelector((state) => state.hotCredentials);
  let otherReqs = applications?.filter(
    (el) =>
      el?.applicationNo < fakeApplication?.applicationNo &&
      el?.scheduleId === fakeApplication?.scheduleId
  );
  const { accessToken } = useSelector((state) => state.accessToken);
  const { getFilesByFolderId, ...rest } = driveApi({ accessToken });

  const folderId =
    hotCredentials?.find((el) => el?.credentialKey === "Accounting")
      ?.credentialValue || "";

  // console.log("workSheet2RentalsDataGrouped", workSheet2RentalsDataGrouped);
  const handleExport = async () => {
    let NEW_RENTALS_DATASET = [];
    await filterTables(
      "includedRentals",
      "projectId",
      fakeApplication?.projectId
    ).then((res) => {
      NEW_RENTALS_DATASET = res || [];
    });
    let othersRentalData = otherReqs
      ?.map((el) => {
        let NEW_APPLICATION = NEW_RENTALS_DATASET?.filter(
          (e) => e?.applicationId === el?.applicationId
        );
        let toSend = structuredClone(el);
        toSend.includedRentals = [
          ...(toSend?.includedRentals || []),
          ...NEW_APPLICATION,
        ];
        console.log("NEW_APPLICATION", {
          NEW_RENTALS_DATASET,
          el,
          NEW_APPLICATION,
        });
        return rentalDataExtractor({
          fakeApplication: toSend,
          applicableRental: fakeApplication,
        })
          ?.filter(Boolean)
          ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
          ?.map((e) => {
            let CURRENT_RENTAL_RET = 0;
            if (!!fakeApplication?.accumulatedRentals) {
              Object.keys(fakeApplication?.accumulatedRentals).forEach(
                (reqNo) => {
                  Object.keys(
                    fakeApplication?.accumulatedRentals[reqNo]
                  ).forEach((rentNo) => {
                    console.log("consoleeee", {
                      e,
                      rentNo,
                    });
                    if (rentNo === e?.rentalNumber?.toString()) {
                      CURRENT_RENTAL_RET +=
                        fakeApplication.accumulatedRentals[reqNo][rentNo][
                          e?.label
                        ].retainagePercentage;
                    }
                  });
                }
              );
            }
            fakeApplication?.totalities?.prevRentalsRetainage?.[
              `Rental${e?.rentalNumber}`
            ] ?? e?.retainage;
            // console.log("CURRENT RENTALS RET", CURRENT_RENTAL_RET);
            return {
              ...e,
              isPrevious: true,
              retainage: CURRENT_RENTAL_RET,
            };
          });
      })
      .flatMap((e) => e);

    console.log("othersRentalData", othersRentalData);

    const { workSheet1DataExcel, workSheet2DataExcel, workSheet2ChangeOrder } =
      exportToExcelData({
        fakeApplication,
        othersRentalData,
        lastRequisition:
          otherReqs?.length > 0
            ? otherReqs.sort((a, b) => b?.createdAt - a?.createdAt)[0]
            : null,
      }) || {};

    let workSheet2RentalsData = rentalDataExtractor({
      fakeApplication,
    })
      ?.filter(Boolean)
      ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1));

    // group array of objects by key
    // position objects in an array after grouped by key

    let temp =
      groupBy(
        "label",
        othersRentalData?.length > 0
          ? [...othersRentalData, ...workSheet2RentalsData]
          : workSheet2RentalsData
      ) || {};
    // console.log("temp", temp);
    Object.values(temp).forEach(
      (arr) =>
        (arr = arr.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1)))
    );

    let workSheet2RentalsDataGrouped = Object.values(temp)
      ?.flatMap((el) => el)
      .map((el) => ({
        ...el,
        monthStart: getNYDate(moment(el?.monthStart)).formatted,
        monthEnd: getNYDate(el?.monthEnd).formatted,
        totalPercentage: Math.round(
          el?.days?.reduce((a, b) => a + b?.percentage, 0) / el?.days.length
        ),
        // monthStart: moment(el?.monthStart).format("MM/DD/YYYY"),
        // monthEnd: moment(el?.monthEnd).format("MM/DD/YYYY"),
      }));

    // Swal.fire({
    //   title: "UNDER CONSTRUCTION",
    //   text: "This feature is currently under construction. Please check back later. Good things are coming!",
    // });
    // console.log("fakeApplication", fakeApplication);
    exportToXLSX(
      fakeApplication.projectName,
      fakeApplication.accountName,
      {
        applicationData: {
          workSheet1DataExcel,
          workSheet2DataExcel,
          workSheet2ChangeOrder,
          customRentals: fakeApplication.rentalExcel || {},
          workSheet2RentalsData: Array.isArray(workSheet2RentalsDataGrouped)
            ? workSheet2RentalsDataGrouped
            : workSheet2RentalsData,
        },
      }
      // accessToken
    );
  };
  const saveExport = async (folderId) => {
    let fileName =
      fakeApplication.projectName.replace(/\W+/g, " ") +
      ` Req#${fakeApplication?.applicationNo} ${
        getNYDate(fakeApplication?.periodTo?.end).formatted
      }.xlsx`;
    console.log("saveExport", fileName);
    let othersRentalData = otherReqs
      ?.map((el) => {
        return rentalDataExtractor({
          fakeApplication: el,
          applicableRental: fakeApplication,
        })
          ?.filter(Boolean)
          ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
          ?.map((e) => {
            let CURRENT_RENTAL_RET = 0;
            if (!!fakeApplication?.accumulatedRentals) {
              Object.keys(fakeApplication?.accumulatedRentals).forEach(
                (reqNo) => {
                  Object.keys(
                    fakeApplication?.accumulatedRentals[reqNo]
                  ).forEach((rentNo) => {
                    console.log("consoleeee", {
                      e,
                      rentNo,
                    });
                    if (rentNo === e?.rentalNumber?.toString()) {
                      CURRENT_RENTAL_RET +=
                        fakeApplication.accumulatedRentals[reqNo][rentNo][
                          e?.label
                        ].retainagePercentage;
                    }
                  });
                }
              );
            }
            fakeApplication?.totalities?.prevRentalsRetainage?.[
              `Rental${e?.rentalNumber}`
            ] ?? e?.retainage;
            // console.log("CURRENT RENTALS RET", CURRENT_RENTAL_RET);
            return {
              ...e,
              isPrevious: true,
              retainage: CURRENT_RENTAL_RET,
            };
          });
      })
      .flatMap((e) => e);
    const { workSheet1DataExcel, workSheet2DataExcel, workSheet2ChangeOrder } =
      exportToExcelData({
        fakeApplication,
        othersRentalData,
        lastRequisition:
          otherReqs?.length > 0
            ? otherReqs.sort((a, b) => b?.createdAt - a?.createdAt)[0]
            : null,
      }) || {};

    let workSheet2RentalsData = rentalDataExtractor({
      fakeApplication,
    })
      ?.filter(Boolean)
      ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1));

    // group array of objects by key
    // position objects in an array after grouped by key

    let temp =
      groupBy(
        "label",
        othersRentalData?.length > 0
          ? [...othersRentalData, ...workSheet2RentalsData]
          : workSheet2RentalsData
      ) || {};
    // console.log("temp", temp);
    Object.values(temp).forEach(
      (arr) =>
        (arr = arr.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1)))
    );

    let workSheet2RentalsDataGrouped = Object.values(temp)
      ?.flatMap((el) => el)
      .map((el) => ({
        ...el,
        monthStart: getNYDate(moment(el?.monthStart)).formatted,
        monthEnd: getNYDate(el?.monthEnd).formatted,
        totalPercentage: Math.round(
          el?.days?.reduce((a, b) => a + b?.percentage, 0) / el?.days.length
        ),

        // monthStart: moment(el?.monthStart).format("MM/DD/YYYY"),
        // monthEnd: moment(el?.monthEnd).format("MM/DD/YYYY"),
      }));

    // Swal.fire({
    //   title: "UNDER CONSTRUCTION",
    //   text: "This feature is currently under construction. Please check back later. Good things are coming!",
    // });
    // console.log("fakeApplication", fakeApplication);
    return await exportToXLSX(
      fakeApplication?.projectName,
      fakeApplication?.accountName,
      {
        applicationData: {
          workSheet1DataExcel,
          workSheet2DataExcel,
          workSheet2ChangeOrder,
          customRentals: fakeApplication.rentalExcel || {},
          workSheet2RentalsData: Array.isArray(workSheet2RentalsDataGrouped)
            ? workSheet2RentalsDataGrouped
            : workSheet2RentalsData,
        },
      },
      accessToken,
      folderId,
      fileName,
      fakeApplication
    );
  };

  const saveToDRIVE = () => {
    console.log("saveToDRIVE");
    getFilesByFolderId("17uFbHliPDF_oJXjZO74iDscyiKa-s6jJ")
      .then((res) => res.json())
      .then(({ files }) => {
        // here i have everything incl files and folders
        // i need to check if folder exists
        let applicableFolder = files?.find(
          (folder) => folder?.name === fakeApplication?.accountName
        ); // TODO: Replace with client name
        if (!applicableFolder) {
          applicableFolder = files?.find((folder) => folder?.name === "TEST");
        }
        if (!!applicableFolder) {
          getFilesByFolderId(applicableFolder?.id)
            .then((res) => res.json())
            .then(({ files = [] }) => {
              console.log("test files", { files, fakeApplication });
              // Now we find the folder for specific project
              swalToast({
                icon: "info",
                title: "Finding Project folder!",
                position: "top-end",
                showConfirmButton: false,
                // timer: 4000,
              });
              let projectFolder = files?.find(
                (folder) => folder?.name === fakeApplication?.projectName
              );
              if (projectFolder) {
                swalToast({
                  icon: "success",
                  title: "Project Folder found!",
                  position: "top-end",
                  showConfirmButton: false,
                  // timer: 4000,
                });
                // now we find requisition folder
                let toPostId;
                swalToast({
                  icon: "info",
                  title: "Finding Requisition folder!",
                  position: "top-end",
                  showConfirmButton: false,
                  // timer: 4000,
                });
                getFilesByFolderId(projectFolder?.id)
                  .then((res) => res.json())
                  .then(({ files = [] }) => {
                    let reqsFolder = files?.find(
                      (folder) => folder?.name === "Reqs"
                    );
                    if (!!reqsFolder) {
                      toPostId = reqsFolder?.id;
                      // we find if current requisition exists
                      getFilesByFolderId(reqsFolder?.id)
                        .then((res) => res.json())
                        .then(({ files = [] }) => {
                          let fileName =
                            fakeApplication.projectName.replace(/\W+/g, " ") +
                            ` Req#${fakeApplication?.applicationNo} ${
                              getNYDate(fakeApplication?.periodTo?.end)
                                .formatted
                            }`;

                          let currentReqs = files?.find(
                            (file) => file?.name === fileName
                          );
                          if (!!currentReqs) {
                            Swal.fire({
                              title: "Requisition already exists!",
                              text: "Would you like to still save it?",
                              icon: "warning",
                              showCancelButton: true,
                              confirmButtonText: "Yes",
                              cancelButtonText: "No",
                            }).then((result) => {
                              if (result.isConfirmed) {
                                message.loading(
                                  "Attaching Requisition to specific folder..."
                                );
                                saveExport(toPostId, fileName);
                              } else {
                                message.destroy();
                                swalToast({
                                  icon: "info",
                                  title: "Requisition did NOT save!",
                                  position: "top-end",
                                  showConfirmButton: false,
                                  timer: 4000,
                                });
                              }
                            });
                          } else {
                            message.loading(
                              "Attaching Requisition to specific folder..."
                            );
                            saveExport(toPostId, fileName);
                          }
                        });
                    }
                  });
              } else {
                message.destroy();
                message.error("Project folder not found!");
              }
            });
        }
      });
  };

  return (
    <div
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        gap: 10,
        paddingRight: 10,
      }}
    >
      <Tooltip title="Export to Excel">
        <MondayButton
          className="mondayButtonBlue"
          hasIcon={false}
          onClick={handleExport}
        >
          <Excel />
        </MondayButton>
      </Tooltip>
      {/* <Tooltip title="Save to Drive">
        <MondayButton
          onClick={saveToDRIVE}
          hasIcon={false}
          className="mondayButtonBlue"
        >
          <span
            style={{
              // backgroundColor: "white",
              color: "white",
              fill: "white",
            }}
          >
            <DriveIcon />
          </span>
        </MondayButton>{" "}
      </Tooltip> */}
      <DriveNavigate
        {...{
          folderId: "1uvltkcIjXlIub0PkxZV8L1necu9jqb2o",
          currentFolderId: !!fakeApplication?.googleDriveLocationId
            ? fakeApplication?.googleDriveLocationId
            : "1uvltkcIjXlIub0PkxZV8L1necu9jqb2o",
          saveExportFunc: saveExport,
          buttonContent: <DriveIcon />,
          buttonContent: "",
          fileName:
            fakeApplication?.projectName?.replace(/\W+/g, " ") +
            ` Req#${fakeApplication?.applicationNo} ${
              getNYDate(fakeApplication?.periodTo?.end).formatted
            }`,
        }}
      />
    </div>
  );
};

export default ExportToExcel;

export const groupBy = (key, array) => {
  return array?.reduce((objectsByKeyValue, obj) => {
    const value = obj[key];
    objectsByKeyValue[value] = (objectsByKeyValue[value] || []).concat(obj);
    return objectsByKeyValue;
  }, {});
};

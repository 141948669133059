import { filterTables } from "../filterTables";

export const codeInvitations = async () => {
  try {
    const data = (
      await filterTables("programFields", "fieldName", "User Invitations")
    )[0].fieldOptions;
    return data;
  } catch (error) {
    console.error("Data source User Invitations error: ", error);
  }
};

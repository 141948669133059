import moment from "moment";

function forceToNumber(param) {
  if (typeof param === "number" && !isNaN(param)) {
    // Check if it's a valid number
    return param;
  } else if (
    typeof param === "string" &&
    param.trim() !== "" &&
    !isNaN(Number(param))
  ) {
    // Check if it's a string that can be converted to a number
    return Number(param);
  } else {
    return 0;
  }
}

const processInvoices = (invoices) => {
  if (Array.isArray(invoices)) {
    return invoices
      .map((invoice, idx) => {
        const {
          invoiceId = "",
          invoiceNumber = "",
          invoiceDate = "",
          invoiceStatus = "",
          toDate = "",
          amountDue = 0,
          accountName = "",
          totalInvoiceAmount = 0,
          quickBooksNumber = "",
          projectName = "",
          memos = [],
        } = invoice;

        return {
          key: idx + 1,
          type: "Invoice",
          num: invoiceNumber,
          accountName,
          invoiceStatus,
          invoiceDate: new Date(invoiceDate).getTime(),
          toDate: new Date(invoiceDate).getTime(),
          invoiceId,
          aging:
            forceToNumber(amountDue) !== 0
              ? moment().diff(
                  moment(moment(toDate).format("MM/DD/YYYY"), "MM/DD/YYYY"),
                  "days"
                )
              : "—",
          openBalance: forceToNumber(amountDue),
          totalInvoiceAmount: forceToNumber(totalInvoiceAmount),
          receivedPayment:
            forceToNumber(totalInvoiceAmount) - forceToNumber(amountDue),
          quickBooksNumber,
          memos: memos,
          projectName,
        };
      })
      .sort((a, b) => {
        /// sort with invoice date
        return moment(a.invoiceDate).isAfter(b.invoiceDate) ? 1 : -1;
      });
  }
};

const getFirstScheduleDay = (schedules = []) => {
  const date = schedules
    ?.sort((a, b) => moment(a?.createdAt) - moment(b?.createdAt))[0]
    ?.scheduleDays?.sort(
      (a, b) => moment(a?.startDate) - moment(b?.startDate)
    )[0]?.startDate;
  return !!date ? moment(date).format("MM/DD/YYYY") : "";
};

const countScheduleDays = (schedules = []) => {
  return schedules.reduce((acc, curr) => {
    return acc + curr?.scheduleDays?.length;
  }, 0);
};

const additionalRentalServices = (services) => {
  let additionalRentCost = 0;
  if (Array.isArray(services)) {
    services?.forEach((service) => {
      additionalRentCost += forceToNumber(
        service?.additionalRentalTerms?.newPrice
      );
    });
  }
  return additionalRentCost;
};

const getPaymentTotal = (invoices) => {
  return invoices?.reduce((acc, curr) => {
    return (
      acc +
      (forceToNumber(curr?.totalInvoiceAmount) - forceToNumber(curr?.amountDue))
    );
  }, 0);
};

const getProgressBySchType = (schedules = []) => {
  const sortedSch = schedules?.sort((a, b) => {
    return moment(a?.createdAt) - moment(b?.createdAt);
  });

  const temp = {};
  getAllSchedulesTypes(schedules)?.map((type) => {
    const foundSch = sortedSch?.findLast(
      ({ typeOfWork = "" }) => typeOfWork === type
    );

    // const progressFound =
    //   ProgressStatistics.estimationTotal(
    //     Object?.values(foundSch?.toBeScheduled || {})?.flat(1)
    //   )?.progressPercentage || 0;

    // temp[type] = progressFound;
  });
  return temp;
};

const getEveryPaymentInfo = (payments = [], projectId = "") => {
  const projectPayments = payments?.filter(({ receivedFromProjects }) =>
    receivedFromProjects.find(
      (paymentPrj) => paymentPrj?.projectId === projectId
    )
  );

  return projectPayments?.map((payment) => {
    const {
      paymentNumber,
      paymentDate,
      paymentStatus,
      invoices,
      paymentMethod,
      createdAt,
      paymentId,
    } = payment;
    const totalAmount = invoices?.reduce((acc, curr) => {
      if (curr?.projectId === projectId) {
        return acc + forceToNumber(curr?.invoicePaidAmount);
      }
    }, 0);

    return {
      paymentNumber,
      paymentDate,
      totalAmount,
      paymentStatus,
      paymentMethod,
      createdAt,
      paymentId,
    };
  });
};

const getLastScheduleStatus = (schedules = []) =>
  schedules?.sort((a, b) => b?.createdAt - a?.createdAt)?.[0]?.typeOfWork;

const otherScopeServiceTotalPriceMapper = (paramService) => {
  let temp = 0;
  let taxRate = paramService?.isTaxable ? paramService?.taxRate || 0 : 0;
  temp =
    [
      ...(paramService?.serviceOptions?.[0]?.flatMap?.(({ items }) => items) ||
        []),
    ]
      ?.map((el) => {
        if (el?.price || el?.Price) {
          return Number(el?.price || el?.Price || 0);
        }
        return 0;
      })
      ?.filter(Boolean)
      ?.reduce((acc, curr) => (acc += curr), 0)
      ?.toFixed?.(2) || 0;
  const taxAmount = temp * taxRate || 0;
  temp = +temp + +taxAmount;
  return temp;
};

const calculateServicesTotal = (services) => {
  let servicesTotal = 0;
  servicesTotal += additionalRentalServices(services);
  if (services?.length > 0) {
    if (!services?.some((service) => typeof service === "string")) {
      servicesTotal += services
        ?.flatMap((service) => {
          const {
            serviceOptions,
            serviceAddons = [],
            label,
            isScope = false,
          } = service;
          const newServiceOptions = Array.isArray(serviceOptions?.[0])
            ? serviceOptions[0]
            : serviceOptions;

          const isHoist = label === "Hoist" || service.isHoist;
          return isScope === true
            ? [otherScopeServiceTotalPriceMapper(service)]
            : !isHoist
            ? [
                ...newServiceOptions.flatMap?.(({ items }) =>
                  items
                    ?.filter(({ approved }) => !!approved)
                    ?.flatMap(({ totalPrice }) => +totalPrice)
                ),
                ...serviceAddons?.map(({ totalPrice }) => +totalPrice),
              ]
            : [
                ...newServiceOptions.flatMap?.(({ totalPrice }) => +totalPrice),
                ...serviceAddons?.map(({ totalPrice }) => +totalPrice),
              ];
        })
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);
    }
  }
  return servicesTotal;
};

const getAllSchedulesTypes = (schedules = []) => {
  return Array?.from(new Set(schedules?.map(({ typeOfWork }) => typeOfWork)));
};

export {
  forceToNumber,
  processInvoices,
  getFirstScheduleDay,
  countScheduleDays,
  additionalRentalServices,
  getPaymentTotal,
  getProgressBySchType,
  getEveryPaymentInfo,
  getLastScheduleStatus,
  calculateServicesTotal,
  getAllSchedulesTypes,
};

import { useState } from "react";
import "./ConfigCard.scss";
import { Content, Header } from "./components";
import { useHoistPricingContext } from "../../context";
import {
  replaceArrayElement,
  updateArrayElement,
} from "../../../../../../../../../utils";
import {
  MondayButton,
  WarningModal,
} from "../../../../../../../../commonComponents";
import HoistPriceItemModal from "../HoistPriceItemModal/HoistPriceItemModal";
import { WarningTriangle } from "../../../../../../../../SidebarPages/DynamicView/src";
import { XIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { TickIcon } from "../../../../../Roles/src";
import { message } from "antd";
import { checkIfUsed } from "../../../PricingComponent/utils/checkIfUsed";
import useProgress from "../../../../../../../../../hooks/useProgress";
import { v4 as uuidv4 } from "uuid";
import { usePricingContext } from "../../../../../PricingV2/context";

const ConfigCard = ({ config, isDarkMode, selectedScheme }) => {
  const { label, formItemName, createdBy, createdAt, type } = config;
  const {
    configurationByType,
    hoistPricingData,
    hoistTypeSwitchChecked,
    selectedPriceSchemeID,
    label: mainLabel,
  } = useHoistPricingContext();
  const { generateNewLog, setPricingObject, estimations, setEstimations } =
    usePricingContext();
  const [deletionModalVisible, setDeletionModalVisible] = useState(false);
  const [editModalVisible, setEditModalVisible] = useState(false);
  const [selectedCard, setSelectedCard] = useState();

  const { setProgress } = useProgress();

  const onDelete = async () => {
    const hide = message.loading("Checking if Price Scheme is used...", 0);
    const isUsed = await checkIfUsed(
      estimations,
      setEstimations,
      selectedPriceSchemeID,
      mainLabel,
      setProgress
    );
    hide();

    if (isUsed) {
      return message.info("Can't delete used Price Sheet");
    }

    const { priceSchemes } = hoistPricingData;
    const hoistType = hoistTypeSwitchChecked ? "dual" : "single";

    generateNewLog(
      "Delete",
      `Schema ${selectedScheme.name}`,
      selectedScheme.configurationByType[hoistType].find(
        (item) => item.placeholder === selectedCard
      ),
      {},
      uuidv4()
    );

    const filterOutCurrentConfig = (configurationArray) => {
      return configurationArray.filter(
        ({ formItemName: itemName }) => formItemName !== itemName
      );
    };

    const updatedPriceScheme = {
      id: selectedPriceSchemeID,
      configurationByType: {
        single: filterOutCurrentConfig(configurationByType.single),
        dual: filterOutCurrentConfig(configurationByType.dual),
      },
    };

    const updatedHoistPricingData = {
      ...hoistPricingData,
      priceSchemes: updateArrayElement(priceSchemes, updatedPriceScheme, "id"),
    };

    setPricingObject(updatedHoistPricingData);
  };

  return (
    <>
      <div className="hoist-config-card">
        <Header
          label={label}
          onEdit={() => setEditModalVisible(true)}
          onDelete={() => {
            setDeletionModalVisible(true);
            setSelectedCard(label);
          }}
        />
        <Content type={type} createdBy={createdBy} createdAt={createdAt} />
      </div>

      <WarningModal
        visible={deletionModalVisible}
        setVisible={setDeletionModalVisible}
        title={label}
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to delete this record?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => {
                setDeletionModalVisible(false);
              }}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onDelete}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>

      <HoistPriceItemModal
        visible={editModalVisible}
        setVisible={setEditModalVisible}
        editingItem={config}
        isDarkMode={isDarkMode}
        selectedCard={selectedCard}
      />
    </>
  );
};

export default ConfigCard;

import { fetchAllData } from "../ApiMethods";

const data = {
  projects: {
    recordId: "projectId",
    keysToInclude: ["projectId", "projectName"],
  },
  opportunities: {
    recordId: "opportunityId",
    keysToInclude: ["opportunityId", "opportunityName"],
  },
  accounts: {
    recordId: "accountId",
    keysToInclude: ["accountId", "accountName"],
  },
  estimations: {
    recordId: "estimationId",
    keysToInclude: ["estimationId", "estimationName"],
  },
};

export const getIds = (index) => {
  const indexKeysToInclude = data[index]?.keysToInclude;

  const queryStringParameters = {
    ...(indexKeysToInclude
      ? { keysToInclude: JSON.stringify(indexKeysToInclude) }
      : {}),
  };

  return fetchAllData({
    endpoint: index,
    pathName: index,
    resultPosition: index,
    resultId: data[index].recordId,
    otherStringParams: queryStringParameters,
  });
};

import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { Tabs, Form, message } from "antd";
import { useNavigate } from "react-router-dom";
import React, { useMemo, useEffect, useState } from "react";

import {
  DegTab,
  CrewsTab,
  JobsiteTab,
  EmployeesTab,
  AnalyticsTab,
  AnalyticsCharts,
} from "./Tabs";
import { lazyFetch } from "../../../utils";
import { findTabFromSearch } from "./utils";
import { NotFound } from "../../SidebarPages";
import PayrollContext from "./PayrollContext";
import { GeneralReports } from "../../SidebarPages/Reports";
import { queryParams } from "../../../utils/queryParams";
import { LoadableComp } from "../../SidebarPages/XComponents";
import { dayjsNY } from "../../DateComponents/contants/DayjsNY";
import { InputComponent } from "../../SidebarPages/Fleet/components";
import { getCostAnalytics } from "./Tabs/DEG/components/modalComponents/utils";
import { parseInTz } from "../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./Payroll.scss";

function Payroll() {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [analytics, setAnalytics] = useState([]);
  const [crewTeams, setCrewTeams] = useState([]);
  const [allProjects, setAllProjects] = useState([]);
  const [degPayrolls, setDegPayrolls] = useState(null);
  const [dataFetched, setDataFetched] = useState(false);
  const [employeesList, setEmployeesList] = useState([]);
  const [removedAnalytics, setRemovedAnalytics] = useState([]);

  const activeKey = findTabFromSearch({ defaultTab: "Employees" });

  const [form] = Form.useForm();
  const navigate = useNavigate();
  const dateRange = Form.useWatch("analyticsDateRange", form);
  const company = Form.useWatch("companySelected", form);

  const accessRight = useMemo(() => {
    if (!userConfiguration) {
      return { children: [] };
    }
    return userConfiguration?.routeConfig?.find(
      ({ title }) => title === "Project Cost"
    );
  }, [userConfiguration]);

  function tabAccess(title) {
    let tabs = accessRight?.children;
    const analyticsTab = tabs?.find(({ title: t }) => t === "Activity");
    if (analyticsTab) {
      tabs = tabs?.concat([{ title: "AnalyticsCharts" }]);
    }
    return tabs?.find(({ title: t }) => t === title);
  }

  async function fetchAnalytics() {
    message.loading({
      key: "zh",
      content: "Getting Analytics...",
      duration: 0,
    });

    const defaultDateRange = dateRange?.map?.((d, i) => {
      const date = parseInTz(d.format("MM/DD/YYYY"), "MM/DD/YYYY");

      if (date.valueOf()) {
        return i ? date.endOf("D").valueOf() : date.startOf("d").valueOf();
      } else {
        return d;
      }
    }) || [
      parseInTz().subtract(7, "day").startOf("D").valueOf(),
      parseInTz().add(1, "d").endOf("D").valueOf(),
    ];

    try {
      const degEntries = await getCostAnalytics({
        dateRange: defaultDateRange,
        listOfPayrolls: degPayrolls?.length ? degPayrolls : [],
        company,
      });

      let tmpAnalytics = [];
      let tmpRemovedAnalytics = [];

      for (const entry of degEntries) {
        if (entry?.removed) {
          tmpRemovedAnalytics.push(entry);
        } else {
          let team = undefined;
          if (!entry?.crewTeamId) {
            const teamIndex = crewTeams.findIndex((el) => {
              const isForeman = el?.crewForeman?.crewId === entry?.crewId;
              const isMember =
                (el?.crewMembers || [])?.findIndex(
                  (mem) => mem?.crewId === entry?.crewId
                ) > -1;
              return isForeman || isMember;
            });
            team = crewTeams?.[teamIndex];
          }

          if (team?.crewTeamId) {
            Object.assign(entry, {
              crewTeamId: team?.crewTeamId,
              crewTeamName: team?.crewTeamName,
            });
          }

          tmpAnalytics.push(
            Object.assign(
              entry,
              entry?.companyName ? {} : { companyName: entry?.company }
            )
          );
        }
      }
      if (dateRange && !!tmpAnalytics?.length) {
        message.success({
          key: "zh",
          content: "Analytics fetched successfully!",
          duration: 1.8,
        });
      } else if (dateRange && !tmpAnalytics?.length) {
        message.warning({
          key: "zh",
          content: "There are no Analytics in this date range.",
          duration: 1.8,
        });
      }

      setAnalytics(tmpAnalytics);
      setRemovedAnalytics(tmpRemovedAnalytics);
    } catch (error) {
      console.log("Error getting data: ", error);
      message.error({
        key: "zh",
        content: "There was a problem getting analytics.",
        duration: 1.8,
      });
    }
  }

  useEffect(() => {
    if (accessRight?.children?.length) {
      if (!tabAccess(activeKey)) {
        let newTab = accessRight?.children?.[0]?.["title"];
        navigate(
          `${window.location.pathname}?${queryParams({
            tab: newTab,
          })}`,
          {
            pathname: window.location.pathname,
            state: { tabPosition: newTab },
            search: queryParams({ tab: newTab }),
          }
        );
      }
      API.get("crewTeams", "/crewTeams")
        .then((res) => {
          setCrewTeams(res);
        })
        .catch((error) => console.log("Error getting crew teams: ", error));
      if (allProjects?.length === 0) {
        lazyFetch({
          tableName: "projects",
          listOfKeys: [
            "projectId",
            "accountId",
            "accountName",
            "geoFenceInfo",
            "laborRequirement",
            "projectAddress",
            "projectLatitude",
            "projectLongitude",
          ],
        }).then((res) => {
          setAllProjects(res);
        });
      }
      if (tabAccess("DEG") && !degPayrolls?.length) {
        API.get("deg", "/deg")
          .then((res) => {
            let uncompletedPayrolls = [];
            let completedPayrolls = [];

            for (let i = 0; i < res?.deg?.length; i++) {
              const payroll = res?.deg?.[i];
              if (payroll.degStatus === "Completed") {
                completedPayrolls.push(payroll);
              } else {
                uncompletedPayrolls.push(payroll);
              }
            }

            let latestPayroll = completedPayrolls?.[0];

            for (let i = 1; i < completedPayrolls.length; i++) {
              const payroll = completedPayrolls?.[i];
              if (latestPayroll?.toDate < payroll?.toDate) {
                latestPayroll = payroll;
              }
            }
            if (latestPayroll) {
              form.setFieldValue("analyticsDateRange", [
                dayjsNY(latestPayroll?.fromDate),
                dayjsNY(latestPayroll?.toDate),
              ]);
            }

            const sortedRes = uncompletedPayrolls
              .sort((rowA, rowB) => {
                return rowB?.createdAt - rowA?.createdAt;
              })
              .concat(
                completedPayrolls.sort((rowA, rowB) => {
                  return rowB?.createdAt - rowA?.createdAt;
                })
              );

            setDegPayrolls(sortedRes);
          })
          .catch((err) => {
            setDegPayrolls([]);
            message.error("Could not get data!");
            console.log("Error getting data: ", err);
          });
      }
      if (tabAccess("Employees")) {
        API.get("crews", "/crews")
          .then((res) => {
            setEmployeesList(res);
          })
          .catch((e) => {
            console.error(e);
            message.error("Error while getting crews.");
          });
      }
    }
  }, [accessRight, form]);

  useEffect(() => {
    if (dateRange && !dataFetched) {
      fetchAnalytics();
      setDataFetched(true);
    }
  }, [dateRange, dataFetched]);

  
  return (
    <LoadableComp loading={!userConfiguration}>
      <div
        className={`main-payroll-view ${
          isDarkMode && "main-payroll-view-dark"
        }`}
      >
        {accessRight?.children?.length ? (
          <PayrollContext.Provider
            value={{
              form,
              analytics,
              crewTeams,
              degPayrolls,
              allProjects,
              setCrewTeams,
              setAnalytics,
              employeesList,
              fetchAnalytics,
              removedAnalytics,
              setRemovedAnalytics,
              degAccessRights: tabAccess("DEG"),
            }}
          >
            <Form form={form} style={{ display: "none" }}>
              <InputComponent
                type="rangepicker"
                placeholder="Set Date Range..."
                formItemName={"analyticsDateRange"}
              />
            </Form>
            <Tabs
              {...{
                activeKey,
                onChange(e) {
                  navigate(
                    `${window.location.pathname}?${queryParams({ tab: e })}`,
                    {
                      pathname: window.location.pathname,
                      state: { tabPosition: e },
                      search: queryParams({ tab: e }),
                    }
                  );
                },
                className: "tabComponent",
                items: [
                  tabAccess("Employees") && {
                    key: "Employees",
                    label: "Employees",
                    children: (
                      <EmployeesTab accessRight={tabAccess("Employees")} />
                    ),
                  },
                  tabAccess("Jobsites") && {
                    key: "Jobsites",
                    label: "Jobsites",
                    children: (
                      <JobsiteTab accessRight={tabAccess("Jobsites")} />
                    ),
                  },
                  tabAccess("DEG") && {
                    key: "DEG",
                    label: "DEG",
                    children: <DegTab accessRight={tabAccess("DEG")} />,
                  },
                  tabAccess("Crew Teams") && {
                    key: "Crew Teams",
                    label: "Crew Teams",
                    children: (
                      <CrewsTab accessRight={tabAccess("Crew Teams")} />
                    ),
                  },
                  tabAccess("Activity") && {
                    key: "Activity",
                    label: "Analytics",
                    children: (
                      <AnalyticsTab accessRight={tabAccess("Activity")} />
                    ),
                  },
                  {
                    key: "Reports",
                    label: "Reports",
                    children: <GeneralReports />,
                  },
                  tabAccess("Activity") && {
                    key: "AnalyticsCharts",
                    label: "Analytics Charts",
                    destroyInactiveTabPane: true,
                    children: <AnalyticsCharts />,
                  },
                ].filter(Boolean),
              }}
            />
          </PayrollContext.Provider>
        ) : (
          <NotFound />
        )}
      </div>
    </LoadableComp>
  );
}

export default Payroll;

import { useSelector } from "react-redux";
import { Form, message, Popconfirm } from "antd";
import { SearchOutlined } from "@ant-design/icons";
import { useContext, useMemo, useState } from "react";

import { useResponsive } from "src/hooks";
import { entriesApiCrud } from "../utils";
import EntryModal from "../modals/EntryModal";
import { EntryType } from "../payrollLiveTypes";
import { GroupedReportsCard } from "../components";
import PayrollLiveContext from "../PayrollLiveContext";
import {
  StoreType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import LateClockIn from "../components/LateClockIn/LateClockIn";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { HoverButton, MondayButton } from "src/components/commonComponents";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import UnMatchedEntries from "../components/UnMatchedEntries/UnMatchedEntries";
import { RenderDynamicComponents } from "src/components/Header/forms/Components";
import {
  RightArrow,
  DownloadIcon,
} from "src/components/SidebarPages/BasePage/src";
import UnregisteredEmployees from "../components/UnregisteredEmployees/UnregisteredEmployees";
import { CrewsHeader } from "src/components/pages/Settings/settingsComponents/Crews/Components/CrewsHeader/CrewsHeader";
import GetPayrollReportModal from "../../Payroll/Tabs/DEG/components/modalComponents/GetPayrollReportModal/GetPayrollReportModal";

import "./EmployeeReports.scss";

type NewEmpModalType = {
  employeeId?: string;
  accountName?: string;
  employeeNumber?: string;
  employeeFullName?: string;
};

function EmployeeReports() {
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const { degEntries, accessRight, setDegEntries, setProgramEmployees } =
    useContext(PayrollLiveContext);

  const [payrollReport, setPayrollReport] = useState<boolean>(false);
  const [newEmpModal, setNewEmpModal] = useState<boolean | NewEmpModalType>(
    false
  );
  const [entryModal, setEntryModal] = useState<
    undefined | Partial<EntryType>
  >();

  const [form] = Form.useForm();
  const { width } = useResponsive();

  function clearFilters() {
    form.resetFields();
  }

  async function massUpdateEntries(entriesToUpdate: Array<EntryType>) {
    for (let i = 0; i < entriesToUpdate.length; i += 25) {
      const entriesSlice = entriesToUpdate.slice(i, i + 25);

      for (let j = 0; j < entriesSlice.length; j++) {
        const { entryId, createdAt, userId, duration, ...body } = entriesSlice[
          j
        ] as EntryType & { userId: string };

        entriesApiCrud({ method: "put", id: entryId, body });
      }
      await new Promise((resolve) => setTimeout(resolve, 1000));
    }
  }

  function onNewEmployeeCreation(newEmp: EmployeeType) {
    setProgramEmployees((prev) => prev.concat(newEmp));
    let entriesOfEmployee = degEntries.flatMap((el) =>
      el?.employeeId === newEmp?.employeeId
        ? {
            ...el,
            crewId: newEmp?.crewId,
            crewName: newEmp?.crewName,
            salaryType: newEmp?.salaryType,
            employeeRate: newEmp?.employeeRate,
            employeeRole: newEmp?.crewPosition,
            employeeFullName: newEmp?.crewName,
          }
        : []
    );
    setDegEntries((prev) =>
      prev.flatMap((el) => {
        if (el?.employeeId === newEmp?.employeeId) {
          const updatedEntryObj = {
            ...el,
            crewId: newEmp?.crewId,
            crewName: newEmp?.crewName,
            salaryType: newEmp?.salaryType,
            employeeRate: newEmp?.employeeRate,
            employeeRole: newEmp?.crewPosition,
            employeeFullName: newEmp?.crewName,
          };
          return updatedEntryObj;
        } else {
          return el;
        }
      })
    );

    massUpdateEntries(entriesOfEmployee);
  }

  function onSaveCallback(data: EntryType) {
    if (data?.entryId) {
      try {
        const { userId, entryId, lastFetch, createdAt, duration, ...body } =
          data as EntryType & {
            userId: string;
            lastFetch: number;
          };

        entriesApiCrud({
          method: "put",
          body,
          id: data?.entryId,
        });
        setDegEntries((prev) =>
          prev.map((el) => (el?.entryId === data?.entryId ? data : el))
        );
        message.success({
          key: "entryCrud",
          content: "Entry edited successfully",
          duration: 2,
        });
      } catch (error) {
        message.error({
          key: "entryCrud",
          content: "There was a problem editing this entry",
          duration: 2,
        });
        console.log("Error editing entry: ", error);
      }
    } else {
      try {
        const { duration, ...body } = data as EntryType & {
          userId: string;
          lastFetch: number;
        };

        entriesApiCrud({
          method: "post",
          body: [body],
        });
        setDegEntries((prev) => [data].concat(prev));
        message.success({
          key: "entryCrud",
          content: "Entry created successfully",
          duration: 2,
        });
      } catch (error) {
        message.error({
          key: "entryCrud",
          content: "There was a problem creating this entry",
          duration: 2,
        });
        console.log("Error editing entry: ", error);
      }
    }
  }

  function onDelete(data: EntryType) {
    try {
      entriesApiCrud({
        method: "delete",
        id: data?.entryId,
        body: [data?.entryId],
      });

      setDegEntries((prev) =>
        prev.filter((el) => el.entryId !== data?.entryId)
      );

      message.success({
        key: "entryCrud",
        content: "Entry deleted successfully",
        duration: 2,
      });
    } catch (error) {
      console.log("Error deleting Entry: ", error);
      message.error({
        key: "entryCrud",
        content: "There was a problem deleting this entry",
        duration: 2,
      });
    }
  }

  function resetTimeFilter() {
    form.setFieldValue("punchEnd", null);
    form.setFieldValue("punchStart", null);
  }

  const filterFields = useMemo(() => {
    return [
      {
        type: "input",
        placeholder: "Search...",
        prefix: <SearchOutlined />,
        dataTestid: "employeeName",
        formItemName: "searchField",
      },
    ];
  }, []);

  const timeFilter = useMemo(() => {
    return (
      <Form form={form}>
        <div className="time-range">
          <InputComponent
            secondaryDarkMode
            type="customTimeInput"
            form={form}
            formItemName="punchTimeStart"
          />
          <RightArrow
            fill={darkMode ? "#fff" : "#323338"}
            height={13}
            width={13}
          />
          <InputComponent
            secondaryDarkMode
            type="customTimeInput"
            form={form}
            formItemName="punchTimeEnd"
          />
          <button
            type="button"
            onClick={resetTimeFilter}
            className="clear-time-btn"
          >
            <XIcon height={10} width={10} />
          </button>
        </div>
      </Form>
    );
  }, [form, darkMode]);

  return (
    <section
      className={`employee-reports-page ${
        darkMode ? "employee-reports-page-dark" : ""
      }`}
    >
      <section className="employee-reports-control-panel">
        <Form form={form} className="control-panel-form">
          {RenderDynamicComponents(filterFields, form)}
          {width > 1100 ? (
            <div className="time-range">
              <InputComponent
                secondaryDarkMode
                form={form}
                type="customTimeInput"
                formItemName="punchStart"
              />
              <RightArrow
                fill={darkMode ? "#fff" : "#323338"}
                height={13}
                width={13}
              />
              <InputComponent
                secondaryDarkMode
                form={form}
                type="customTimeInput"
                formItemName="punchEnd"
              />
              <button
                type="button"
                onClick={resetTimeFilter}
                className="clear-time-btn"
              >
                <XIcon height={10} width={10} />
              </button>
            </div>
          ) : (
            <Popconfirm
              title={null}
              placement="bottom"
              description={timeFilter}
              rootClassName={`popup-filter ${
                darkMode ? "popup-filter-dark" : ""
              }`}
            >
              <HoverButton
                type="action"
                text={width > 1400 ? "Time Filter" : "Filter"}
                hasIcon={false}
                onClick={() => {}}
              />
            </Popconfirm>
          )}
        </Form>
        <HoverButton
          hasIcon={false}
          type="decline"
          onClick={clearFilters}
          text="Clear Filters"
        />
        {/* <MondayButton
          Icon={<DownloadIcon />}
          className="mondayButtonBlue"
          onClick={() => setPayrollReport(true)}
        >
          Get Payroll Report
        </MondayButton> */}
      </section>
      <div className="employee-reports-body">
        <GroupedReportsCard form={form} setEntryModal={setEntryModal} />
        <LateClockIn form={form} />
        <UnregisteredEmployees setNewEmpModal={setNewEmpModal} form={form} />
        <UnMatchedEntries form={form} />
      </div>
      {!!newEmpModal ? (
        <CrewsHeader
          isNextStep={false}
          visible={!!newEmpModal}
          defaultData={newEmpModal}
          setVisible={setNewEmpModal}
          defaultEmployeeType={"crews"}
          refreshTable={onNewEmployeeCreation}
        />
      ) : null}
      {entryModal ? (
        <EntryModal
          open={!!entryModal}
          onDeleteCallback={onDelete}
          onSaveCallback={onSaveCallback}
          editDisabled={!accessRight?.write}
          editData={entryModal as EntryType}
          onCancel={() => setEntryModal(undefined)}
        />
      ) : null}
      {payrollReport ? (
        <GetPayrollReportModal
          open={payrollReport}
          onCancel={() => setPayrollReport(false)}
        />
      ) : null}
    </section>
  );
}

export default EmployeeReports;

import { Input } from "antd";
import { useState } from "react";

const KEY_LEFT = "ArrowLeft";
const KEY_UP = "ArrowUp";
const KEY_RIGHT = "ArrowRight";
const KEY_DOWN = "ArrowDown";
const KEY_PAGE_UP = "PageUp";
const KEY_PAGE_DOWN = "PageDown";
const KEY_PAGE_HOME = "Home";
const KEY_PAGE_END = "End";

export const MyCellEditor = ({ value, onValueChange, ...rest }) => {
  const [inputValue, setInputValue] = useState(value || "");

  const onKeyDown = (event) => {
    const key = event.key;

    const isNavigationKey =
      key === KEY_LEFT ||
      key === KEY_RIGHT ||
      key === KEY_UP ||
      key === KEY_DOWN ||
      key === KEY_PAGE_DOWN ||
      key === KEY_PAGE_UP ||
      key === KEY_PAGE_HOME ||
      key === KEY_PAGE_END;

    if (isNavigationKey) {
      event.stopPropagation();
    }
  };

  const handleValueChange = (e) => {
    let myValue = e.target.value;
    setInputValue(myValue);
    console.log("myValue", myValue);
    // Check if the value starts with '=' for formula evaluation
    if (myValue.startsWith("=")) {
      try {
        const result = eval(myValue.slice(1)); // Evaluate the expression after '='
        onValueChange(result); // Pass evaluated result to onValueChange
      } catch (error) {
        console.error("Error evaluating formula:", error);
      }
    } else {
      onValueChange(myValue); // Otherwise, just pass the raw input
    }
  };

  return (
    <Input
      value={inputValue}
      onChange={handleValueChange}
      onKeyDownCapture={onKeyDown}
    />
  );
};

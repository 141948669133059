import { message } from "antd";
import { getCategoriesData } from "./get-categories-data";

export const handleSelectCategory = async (
  value,
  categoriesData,
  setCategoriesData,
  setSelectedCategory,
  categoriesWithGlobalSearch,
  setLoading
) => {
  const categoryExists = categoriesData.some((category) => category[value]);

  // prevents fetching the same category if it's already fetched
  if (categoryExists) {
    setSelectedCategory(value);
    return;
  }

  setLoading(true);
  setSelectedCategory(value);
  const categoryToGetData = categoriesWithGlobalSearch.find(
    (category) => category.categoryName === value
  );
  const categoryArray = [categoryToGetData];

  if (!categoryArray || !categoryArray.length) {
    message.error(
      "This category is not available at this moment. Please select another one."
    );
    return;
  }

  try {
    const categoriesDataResponse = await getCategoriesData(categoryArray);
    message.loading({ content: "Getting data...", duration: 0 });

    if (!categoriesDataResponse || !categoriesDataResponse.length) {
      console.log(
        "Error while getting data of selected category",
        categoryArray
      );
      return;
    }

    setCategoriesData((prev) => {
      return [...prev, ...categoriesDataResponse];
    });
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
    message.destroy();
  }
};

import "./ProfileInputs.scss";
import { useState } from "react";
import { useSelector } from "react-redux";
import { InputComponent } from "../../../../../../../SidebarPages/Fleet/components";
import { useUserProfileContext } from "../../../UserProfileContext";
import Signature from "../../../../../../../commonComponents/Signature/Signature";

const ProfileInputs = () => {
  const { profileChanges, setProfileChanges, tmpSignature, setTmpSignature } =
    useUserProfileContext();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const idleOptions =
    programFields.find((prog) => prog.fieldName === "Idle Options")
      ?.fieldOptions || [];

  return (
    <div className={`profile-configs ${isDarkMode ? "dark-mode" : ""}`}>
      <div data-testid="pin-input">
        <InputComponent
          typePassword={true}
          label="PIN Number"
          autoComplete="new-password"
          defaultValue={userConfiguration.pinNumber}
          placeholder="Input PIN Number"
          rules={[
            {
              pattern: /^[0-9]{4}$/,
              message: "Please enter a valid 4-digit PIN, e.g., 1234.",
            },
          ]}
          onChange={(event) => {
            setProfileChanges((prev) => ({
              ...prev,
              pinNumber: event.target.value,
            }));
          }}
        />
      </div>
      <InputComponent
        label="Idle Time"
        type="select"
        placeholder="Select Idle Time"
        defaultValue={
          profileChanges?.idleTimeOut || userConfiguration.idleTimeOut
        }
        customOptions={idleOptions}
        onChange={(value) => {
          setProfileChanges((prev) => ({ ...prev, idleTimeOut: value }));
        }}
      />
      <Signature
        {...{
          signature: tmpSignature,
          setSignature: (value) => {
            setTmpSignature(value);
            const userSignature = {
              src: value,
              date: new Date().toISOString(),
              cognitoUserId: userConfiguration?.cognitoUserId,
              nameOfUser: userConfiguration?.nameOfUser,
            };
            let result = {};
            if (value) {
              result = userSignature;
            }
            setProfileChanges((prev) => ({
              ...prev,
              userSignature: result,
            }));
          },
          existingSignature: "",
          signDisabled: false,
          removeDisabled: false,
          signButtonName: "Add Signature",
          viewClassName: `${!tmpSignature ? "noSignature" : ""} signatureView`,
        }}
      />
    </div>
  );
};

export default ProfileInputs;

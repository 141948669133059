import { API } from "aws-amplify";
import { v4 as uuid } from "uuid";
import { read, utils } from "xlsx";
import { useSelector } from "react-redux";
import { useState, useContext } from "react";
import { unstable_batchedUpdates } from "react-dom";
import { message, Modal, Upload, Form } from "antd";
import { UploadRequestOption } from "rc-upload/lib/interface";

import { getLatLngFromAddress } from "src/utils";
import { ExcelDataType } from "../../payrollLiveTypes";
import PayrollLiveContext from "../../PayrollLiveContext";
import { MondayButton } from "src/components/commonComponents";
import { extractTeamsFromExcel, getWeekDateRange } from "../../utils";
import { DownloadIcon } from "src/components/SidebarPages/BasePage/src";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { getPayrollLiveReport } from "../../../Payroll/Tabs/DEG/FingerCheckConfig/fingercheckFunctions";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./DateExcelUpload.scss";

interface Props {
  open: boolean;
  onCancel: () => void;
}

function DateExcelUpload(props: Props) {
  const { open, onCancel } = props;
  const {
    jobsites,
    degEntries,
    setCrewTeams,
    clientConfigs,
    clientCompany,
    setDegEntries,
    employeesReport,
    programEmployees,
    controlPanelForm,
    setTodaySchedules,
    setEmployeesReport,
    setSelectedWeekDeg,
    setExcelUploadedData,
  } = useContext(PayrollLiveContext);
  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const [fileName, setFileName] = useState<string | undefined>();
  const [saveDisabled, setSaveDisabled] = useState<boolean>(true);
  const [excelData, setExcelData] = useState<Array<ExcelDataType>>([]);

  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);

  function onFileUpload(event: UploadRequestOption<any> & { file: any }) {
    let fileReader = new FileReader();
    message.loading({
      key: "uplMsg",
      content: "Uploading file...",
      duration: 0,
    });
    fileReader.addEventListener("load", async () => {
      let { Sheets } = read(fileReader.result, {
        sheets: "Sheet1",
      });

      if (Sheets?.["Sheet1"]) {
        const excelData: Array<ExcelDataType> = utils.sheet_to_json(
          Sheets["Sheet1"]
        );

        setSaveDisabled(false);
        setFileName(event?.file?.name);
        setExcelData(excelData as Array<ExcelDataType>);

        message.success({
          key: "uplMsg",
          content: "File Uploaded",
          duration: 1.8,
        });
        return event.onSuccess(Sheets);
      } else {
        message.error({
          key: "uplMsg",
          content: "Sheet1 composition was not found",
          duration: 2.2,
        });
        return event.onError({
          message: "Sheet1 composition was not found",
          name: "Upload Error",
        });
      }
    });
    fileReader.readAsArrayBuffer(event?.file);
  }

  async function onSave() {
    message.loading({
      duration: 0,
      key: "onSave",
      content: "Matching data...",
    });
    setSaveDisabled(true);
    const selectedClient = clientConfigs.find(
      (el) => el.configId === clientCompany
    );
    const weekDateRange = getWeekDateRange(selectedDate);
    // const companyName = selectedClient.clientName;

    try {
      const selectedDateSchedules = await API.get(
        "fetchSchedulesByDay",
        `/fetchSchedulesByDay`,
        {
          queryStringParameters: {
            searchDates: JSON.stringify([
              selectedDate.toISOString(),
              selectedDate.toISOString(),
            ]),
          },
        }
      );

      let modifiedSchedules = [];
      for (let i = 0; i < selectedDateSchedules?.items?.length; i++) {
        const schedule = selectedDateSchedules?.items?.[i];
        const jIndex = jobsites.findIndex(
          (job) => job.projectId === schedule.projectId
        );

        const scheduleObject = {
          ...schedule,
          geoFenceInfo: jobsites?.[jIndex]?.geoFenceInfo,
          radius: jobsites?.[jIndex]?.locationRadius || 300,
          addressPosition: jobsites?.[jIndex]?.addressPosition,
        };

        if (!jobsites?.[jIndex]?.addressPosition?.lat) {
          const addressCoordinates = await getLatLngFromAddress(
            schedule?.scheduleAddress
          );
          Object.assign(scheduleObject, {
            addressPosition: addressCoordinates,
          });
        }
        if (
          modifiedSchedules.findIndex(
            (el) => el?.projectId === scheduleObject?.projectId
          ) === -1
        ) {
          modifiedSchedules.push(scheduleObject);
        }
      }
      let selectedDateEntries;
      const tmpEmployeeReports = await getPayrollLiveReport({
        excludedEmployees: [],
        selectedDate: selectedDate,
        clientKey: selectedClient.clientKey,
        companyName: selectedClient?.clientName,
      });

      const degFilter = [
        {
          conditions: [
            {
              id: uuid(),
              operator: "AND",
              column: "fromDate",
              dataType: "number",
              columnType: "number",
              value: weekDateRange?.[0]?.valueOf?.(),
              formula: "is_greater_than_or_equal_to",
            },
            // {
            //   id: uuid(),
            //   operator: "AND",
            //   column: "toDate",
            //   dataType: "number",
            //   columnType: "number",
            //   formula: "is_less_than",
            //   value: weekDateRange?.[1]?.subtract?.(1, "m")?.valueOf?.(),
            // },
            {
              id: uuid(),
              formula: "is",
              operator: "AND",
              dataType: "string",
              columnType: "string",
              column: "companyName",
              value: selectedClient.clientName,
            },
          ],
          id: uuid(),
          operator: "AND",
        },
      ];

      const degRes = await API.get("deg", "/deg", {
        queryStringParameters: {
          getMaxLimit: "true",
          withPagination: "true",
          ExclusiveStartKey: undefined,
          filters: JSON.stringify(degFilter),
        },
      });

      if (degRes?.deg?.[0]) {
        const deg = degRes?.deg.find(
          (el) => el.toDate <= weekDateRange[1].valueOf()
        );
        if (deg.degStatus === "Completed") {
          message.warning({
            duration: 4,
            content: "This week`s DEG have been completed.",
          });
        }
        setSelectedWeekDeg(deg);
        const appliedFilter = [
          {
            conditions: [
              {
                id: uuid(),
                formula: "is",
                operator: "AND",
                dataType: "string",
                columnType: "string",
                column: "companyName",
                value: selectedClient?.clientName,
              },
              {
                id: uuid(),
                formula: "is",
                operator: "AND",
                column: "degId",
                value: deg?.degId,
                dataType: "string",
                columnType: "string",
              },
              {
                id: uuid(),
                formula: "is_greater_than_or_equal_to",
                operator: "AND",
                column: "punchTimeStamp",
                value: selectedDate.valueOf(),
                dataType: "number",
                columnType: "number",
              },
            ],
            id: uuid(),
            operator: "AND",
          },
        ];

        selectedDateEntries = await API.get("degEntries", "/degEntries", {
          queryStringParameters: {
            getMaxLimit: "true",
            withPagination: "true",
            ExclusiveStartKey: undefined,
            filters: JSON.stringify(appliedFilter),
          },
        });
      }

      // await getEmployeesLiveEntries({
      //   companyName,
      //   date: selectedDate.format("YYYY-MM-DD"),
      // });

      const crewTeams = extractTeamsFromExcel({
        defaultExcelData: excelData,
        employees: programEmployees,
        selectedClient,
      });
      unstable_batchedUpdates(() => {
        setCrewTeams(crewTeams);
        setExcelUploadedData(excelData);
        setDegEntries(
          selectedDateEntries?.degEntries.filter(
            (el) =>
              el.punchTimeStamp <
              selectedDate.add(1, "d").startOf("d").valueOf()
          )
        );
        setTodaySchedules(modifiedSchedules);
        setEmployeesReport(tmpEmployeeReports.data);
      });

      message.success({
        duration: 2,
        key: "onSave",
        content: "Live data updated successfully",
      });
      onCancel();
    } catch (error) {
      console.log("Error getting selected date entries: ", error);
      message.error({
        duration: 2,
        key: "onSave",
        content: `There was a problem getting ${selectedDate.format(
          "MM/DD/YYYY"
        )} data`,
      });
      // maybe reset to today data
      controlPanelForm.setFieldValue(
        "selectedDate",
        parseInTz(employeesReport?.[0]?.punchTime).startOf("d")
      );
    }
  }

  function onCloseModal() {
    onCancel();
    controlPanelForm.setFieldValue(
      "selectedDate",
      parseInTz(
        employeesReport?.[0]?.punchTime || degEntries?.[0]?.punchTime
      ).startOf("d")
    );
  }

  return (
    <Modal
      closable
      centered
      open={open}
      destroyOnClose
      onCancel={onCloseModal}
      closeIcon={<XIcon />}
      title={`Upload Schedule of ${dayjsNY(selectedDate).format("MM/DD/YYYY")}`}
      className={`date-excel-upload ${
        darkMode ? "date-excel-upload-dark" : ""
      }`}
      footer={[
        <MondayButton
          Icon={<XIcon />}
          onClick={onCloseModal}
          className="mondayButtonRed"
        >
          Cancel
        </MondayButton>,
        <MondayButton
          onClick={onSave}
          Icon={<DownloadIcon />}
          disabled={saveDisabled}
          className="mondayButtonBlue"
        >
          Save Data
        </MondayButton>,
      ]}
    >
      <Upload
        multiple={false}
        accept="xlsx, xls"
        showUploadList={false}
        customRequest={onFileUpload}
        disabled={!!excelData?.length}
      >
        <MondayButton
          Icon={<DownloadIcon />}
          disabled={excelData?.length}
          className={`${
            excelData?.length ? "" : "mondayButtonBlue"
          } upload-btn`}
        >
          Upload Today's Schedule
        </MondayButton>
      </Upload>
      <div className="file-name">{fileName}</div>
    </Modal>
  );
}

export default DateExcelUpload;

import { AppstoreOutlined, BarsOutlined } from "@ant-design/icons";
import { Segmented } from "antd";
import { useMemo, useState } from "react";
import { InputComponent } from "../../../../../Fleet/components";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { DragIcon } from "../../../../../../../icons";
import { ReportCheck } from "../../../../../Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";

const defStyle = { display: "flex", flexDirection: "column", gap: 10 };

function SortReportFields({
  modifiedValues,
  filters,
  setFilters,
  initialValues,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [showFields, setShowFields] = useState(false);
  const [category, setCategory] = useState("");

  const categories = useMemo(() => {
    if (!!filters?.categoriesSort) {
      let sortObj = filters?.categoriesSort;
      return (
        (
          initialValues
            ?.filter((el) => !el?.additionalData && !el?.other)
            ?.sort((a, b) => {
              if (!sortObj?.[a?.title] || !sortObj?.[b?.title]) return 0;
              return sortObj[a?.title] - sortObj[b?.title];
            }) || []
        )?.map((el) => el?.title) || []
      );
    } else {
      return (
        initialValues
          ?.filter((el) => !el?.additionalData && !el?.other)
          ?.map((el) => el?.title) || []
      );
    }
  }, [modifiedValues]);

  const fieldsToSort = useMemo(() => {
    if (!category) return [];
    let toReturn =
      initialValues
        ?.filter((el) => !el?.additionalData && !el?.other)
        ?.find((el) => el?.title === category)?.body || [];
    if (!!filters?.fieldsSort?.[category]) {
      let sortObj = filters?.fieldsSort?.[category];
      return (
        toReturn?.sort((a, b) => {
          const aKey = a?.key || a?.title;
          const bKey = b?.key || b?.title;
          if (!sortObj?.[aKey] || !sortObj?.[bKey]) return 0;
          return sortObj[aKey] - sortObj[bKey];
        }) || []
      );
    } else {
      return toReturn;
    }
  }, [modifiedValues, category]);

  function reorderElement(result) {
    if (!result.destination) return;
    const { source, destination } = result;
    const startIndex = source.index;
    const endIndex = destination.index;
    if (startIndex === endIndex) return;
    if (!showFields) {
      let tmp = [...modifiedValues];
      const [removed] = tmp.splice(startIndex, 1);
      tmp.splice(endIndex, 0, removed);
      tmp.forEach((el, i) => {
        el.index = i + 1;
      });
      setFilters((prev) => {
        return {
          ...prev,
          categoriesSort: tmp.reduce((acc, el) => {
            acc[el.title] = el.index;
            return acc;
          }, {}),
        };
      });
    } else {
      let tmp = [...fieldsToSort];
      const [removed] = tmp.splice(startIndex, 1);
      tmp.splice(endIndex, 0, removed);
      tmp.forEach((el, i) => {
        el.index = i + 1;
      });
      setFilters((prev) => ({
        ...prev,
        fieldsSort: {
          ...prev.fieldsSort,
          [category]: tmp.reduce((acc, el) => {
            const key = el.key || el.title;
            acc[key] = el.index;
            return acc;
          }, {}),
        },
      }));
    }
  }

  return (
    <div className="tab-container">
      <Segmented
        style={{ width: "fit-content" }}
        options={[
          {
            label: "Categories",
            value: false,
            icon: <AppstoreOutlined />,
          },
          {
            label: "Fields",
            value: true,
            icon: <BarsOutlined />,
          },
        ]}
        onChange={(e) => {
          setShowFields(e);
          setCategory("");
        }}
      />
      {showFields ? (
        <>
          <InputComponent
            {...{
              label: "Category",
              initialValue: category,
              // value: category,
              onChange: (e) => setCategory(e),
              type: "select",
              customOptions: categories.map((el) => ({ label: el, value: el })),
              dropdownClassName: isDarkMode && "darkDropDown",
            }}
          />
          {fieldsToSort?.length > 1 ? (
            <DragDropContext onDragEnd={reorderElement}>
              <Droppable droppableId="droppable">
                {(provided) => (
                  <div
                    className="droppableDiv"
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                  >
                    {fieldsToSort?.map(({ key = "", title = "" }, i) => {
                      const label = key || title;
                      return (
                        <Draggable
                          key={label}
                          draggableId={label.toString()}
                          index={i}
                        >
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              // {...provided.dragHandleProps}
                              variant={
                                snapshot.isDragging ? "elevation" : "outlined"
                              }
                              elevation={4}
                            >
                              <div className="draggableDiv">
                                <ReportCheck
                                  {...{
                                    //   dataTestId: "",
                                    id: label.toString(),
                                    className: "contact-checkbox",
                                    label,
                                    onChange: (e) => {
                                      setFilters((prev) => {
                                        const removedFields =
                                          prev?.removedFields?.[category] || [];
                                        return {
                                          ...prev,
                                          removedFields: {
                                            ...prev.removedFields,
                                            [category]: e
                                              ? removedFields.filter(
                                                  (el) => el !== label
                                                )
                                              : [...removedFields, label],
                                          },
                                        };
                                      });
                                    },
                                    checked: !(
                                      filters?.removedFields?.[category] || []
                                    )?.includes(label),
                                  }}
                                  key={i}
                                />
                                <span
                                  {...{
                                    ...provided.dragHandleProps,
                                  }}
                                >
                                  <DragIcon />
                                </span>
                              </div>
                            </div>
                          )}
                        </Draggable>
                      );
                    })}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            </DragDropContext>
          ) : (
            fieldsToSort?.map((el, i) => {
              const label = el?.key || el?.title;
              return (
                <div className="draggableDiv">
                  <ReportCheck
                    {...{
                      //   dataTestId: "",
                      id: el?.key || el?.title,
                      className: "contact-checkbox",
                      label,
                      onChange: (e) => {
                        setFilters((prev) => {
                          const removedFields =
                            prev?.removedFields?.[category] || [];
                          return {
                            ...prev,
                            removedFields: {
                              ...prev.removedFields,
                              [category]: e
                                ? removedFields.filter((el) => el !== label)
                                : [...removedFields, label],
                            },
                          };
                        });
                      },
                      checked: !(
                        filters?.removedFields?.[category] || []
                      )?.includes(label),
                    }}
                    key={i}
                  />
                </div>
              );
            })
          )}
        </>
      ) : (
        <>
          <DragDropContext onDragEnd={reorderElement}>
            <Droppable droppableId="droppable">
              {(provided) => (
                <div
                  className="droppableDiv"
                  {...provided.droppableProps}
                  ref={provided.innerRef}
                >
                  {categories.map((title, i) => {
                    return (
                      <Draggable
                        key={title}
                        draggableId={title.toString()}
                        index={i}
                      >
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            // {...provided.dragHandleProps}
                            variant={
                              snapshot.isDragging ? "elevation" : "outlined"
                            }
                            elevation={4}
                          >
                            <div className="draggableDiv">
                              <ReportCheck
                                {...{
                                  dataTestId: title,
                                  id: title,
                                  label: title,
                                  onChange: (e) => {
                                    setFilters((prev) => {
                                      const removedCategories =
                                        prev?.removedCategories || [];
                                      return {
                                        ...prev,
                                        removedCategories: e
                                          ? removedCategories.filter(
                                              (cat) => title !== cat
                                            )
                                          : [...removedCategories, title],
                                      };
                                    });
                                  },
                                  checked: !(
                                    filters?.removedCategories || []
                                  )?.includes(title),
                                }}
                                key={i}
                              />
                              <span
                                {...{
                                  ...provided.dragHandleProps,
                                }}
                              >
                                <DragIcon />
                              </span>
                            </div>
                          </div>
                        )}
                      </Draggable>
                    );
                  })}
                </div>
              )}
            </Droppable>
          </DragDropContext>
        </>
      )}
    </div>
  );
}

export default SortReportFields;

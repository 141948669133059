import { useSelector } from "react-redux";
import { ExportPreview } from "../../../commonComponents";
import HospitalsNearby from "./HospitalsNearby";
import EditSafetyContacts from "./EditSafetyContacts";
import {
  convertSafetyContactsData,
  safetyContactsStyles,
  initialProjectSafetyReportData,
} from "./helpers";

function ProjectSafetyReport({ pdfPreview, setPdfPreview, rowData }) {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { accessToken } = useSelector((state) => state.accessToken);

  return pdfPreview ? (
    <ExportPreview
      {...{
        open: pdfPreview,
        onCancel: () => setPdfPreview(false),
        applyFilters: (filters, data) => data,
        asyncInitialData: true,
        convertData: convertSafetyContactsData,
        customStyles: safetyContactsStyles,
        modalTitle: "Safety Contacts Report",
        defaultHeaderSection: [],
        documentTitle: "Safety Contacts Report",
        hasPagination: true,
        getInitialData: async () => {
          return await initialProjectSafetyReportData({
            rowData,
            userConfiguration,
            accessToken,
          });
        },
        tabs: [
          {
            label: "Urgent Cares Nearby",
            key: "HospitalsNearby",
            component: HospitalsNearby,
          },
          {
            label: "Edit Contacts",
            key: "EditContacts",
            component: EditSafetyContacts,
          },
        ],
        keyName: "ProjectSafetyReport",
      }}
    />
  ) : null;
}

export default ProjectSafetyReport;

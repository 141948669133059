export function convertSecondsToTime(seconds: number): string {
  if (!seconds) {
    return "0 seconds";
  }

  if (seconds < 60) {
    return `${seconds} second${seconds !== 1 ? "s" : ""}`;
  } else if (seconds < 3600) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes !== 1 ? "s" : ""}`;
  } else {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const hourStr = `${hours} hour${hours !== 1 ? "s" : ""}`;
    const minuteStr =
      minutes > 0 ? ` ${minutes} min${minutes !== 1 ? "s" : ""}` : "";
    return `${hourStr},${minuteStr}`;
  }
}

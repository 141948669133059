import { useEffect, useState } from "react";
import { useEmailBox } from "../providers/EmailBoxProvider";
import { filterTables } from "../../../../../../utils";
import { determineCategoryType } from "../../../functions";

const useEmailTemplatesData = (
  invoice,
  proppedTemplateCat,
  exportOrEmailDropdown,
  rowData,
  recordDetails
) => {
  const [emailTemplates, setEmailTemplates] = useState([]);

  // State for the selected email template, initialized with an empty template ID and null custom body
  const [selectedEmailTemplate, setSelectedEmailTemplate] = useState({
    id: "",
    customBody: null,
  });
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    fetchData(); // Fetch email templates data
  }, []);

  const fetchData = async () => {
    try {
      const categoryType = determineCategoryType(
        rowData,
        invoice,
        proppedTemplateCat,
        exportOrEmailDropdown,
        recordDetails
      );

      // Fetch filtered email templates from the 'emailTemplates' table based on the determined category type
      const res = await filterTables(
        "emailTemplates",
        "templateCategory",
        categoryType
      );

      setEmailTemplates(res);

      // Select a default email template based on the template category
      const defaultSelected = res?.find(
        (temp) =>
          proppedTemplateCat === "Tutorial Videos"
            ? temp?.templateName.includes("Tutorial Video") // Select template for tutorial videos
            : temp.templateName.includes("Default") // Select default template
      );

      // Set the selected email template state with the default template's ID
      setSelectedEmailTemplate({
        id: defaultSelected?.templateId,
        customBody: null,
      });
    } catch (error) {
      console.error("Error fetching dynamic fields:", error);
    }
  };

  return {
    emailTemplates,
    setEmailTemplates,
    selectedEmailTemplate,
    setSelectedEmailTemplate,
    loading,
    setLoading,
  };
};

export default useEmailTemplatesData;

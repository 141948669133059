//

import { message } from "antd";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";

//

// const getEventsForCalendar = async ({
//   calendarID,
//   startDate = dayjsNY().startOf("month").format(),
//   endDate = dayjsNY().endOf("month").format(),
//   showDeleted = false,
// }) => {
//   return gapi.client.calendar.events.list({
//     calendarId: calendarID,
//     timeMin: startDate,
//     timeMax: endDate,
//     timeZone: "America/New_York",
//     showDeleted: showDeleted,
//     singleEvents: true,
//     maxResults: 2500,
//   });
// };
// const getEventsForCalendar = async ({
//   calendarID,
//   startDate = dayjsNY().startOf("month").format(),
//   endDate = dayjsNY().endOf("month").format(),
//   showDeleted = false,
// }) => {
//   let allEvents = [];
//   let nextPageToken = null;

//   do {
//     const response = await gapi.client.calendar.events.list({
//       calendarId: calendarID,
//       timeMin: startDate,
//       timeMax: endDate,
//       timeZone: "America/New_York",
//       showDeleted: showDeleted,
//       singleEvents: true,
//       maxResults: 2500,
//       pageToken: nextPageToken, // handle pagination
//     });
//     console.log("response", response);
//     allEvents = allEvents.concat(response); // accumulate the events
//     nextPageToken = response.result.nextPageToken; // get the token for the next page, if any
//   } while (nextPageToken);
//   console.log("allEvents", allEvents);
//   return allEvents; // return all the accumulated events
// };

// export const getEventsForCalendars = async ({
//   arrOfCalendarIDs,
//   startDate = dayjsNY().startOf("month").format(),
//   endDate = dayjsNY().endOf("month").format(),
//   showDeleted = false,
// }) => {
//   try {
//     const promises = arrOfCalendarIDs.map((calendarID) => {
//       return getEventsForCalendar({
//         calendarID,
//         startDate,
//         endDate,
//         showDeleted,
//       });
//     });
//     const results = await Promise.all(promises);

//     let toReturn = results.flatMap((result) => result?.result?.items);
//     console.log("toReturn", toReturn);
//     return toReturn;
//   } catch (error) {
//     console.log("Error in getEventsForCalendars", error);
//     return [];
//   }
// };
// Fetch events for a single calendar, including pagination handling
const getEventsForCalendar = async ({
  calendarID,
  startDate = dayjsNY().startOf("month").format(),
  endDate = dayjsNY().endOf("month").format(),
  showDeleted = false,
  // syncToken = null, // Initialize syncToken
}) => {
  let allEvents = [];
  let nextPageToken = null;
  let staticToken = null;
  let syncToken = null; // Initialize syncToken

  try {
    do {
      const response = await gapi.client.calendar.events.list({
        calendarId: calendarID,
        timeMin: startDate,
        timeMax: endDate,
        timeZone: "America/New_York",
        showDeleted: showDeleted,
        singleEvents: true,
        maxResults: 2500,
        pageToken: nextPageToken, // Use nextPageToken for pagination
        syncToken: syncToken, // Use syncToken for updates
      });

      if (response.error) {
        console.error("API error:", response.error);
        return allEvents; // Return empty if there's an error
      }

      console.log("response", response);
      allEvents = allEvents.concat(response.result.items);
      console.log("allEvents", allEvents);
      // If using pagination, update nextPageToken
      nextPageToken = response?.result?.nextPageToken;

      syncToken = response?.result?.nextSyncToken;

      // Update syncToken for future requests
      if (response.result.items.length === 0) {
        nextPageToken = null;
        syncToken = null;
        return allEvents;
      }
    } while (nextPageToken); // Continue if there's a next page
  } catch (error) {
    console.error("Failed to fetch events:", error);
  }

  console.log("allEvents", allEvents);
  return allEvents; // Return both events and syncToken
};

// Fetch events from multiple calendars and combine results
export const getEventsForCalendars = async ({
  arrOfCalendarIDs,
  startDate = dayjsNY().startOf("month").format(),
  endDate = dayjsNY().endOf("month").format(),
  showDeleted = false,
}) => {
  try {
    message.loading("Loading events...");
    // Get events for all calendars in parallel
    const promises = arrOfCalendarIDs.map((calendarID) => {
      return getEventsForCalendar({
        calendarID,
        startDate,
        endDate,
        showDeleted,
      });
    });

    // Wait for all promises to resolve
    const results = await Promise.all(promises);

    // Flatten the results (since getEventsForCalendar returns arrays)
    let toReturn = results.flat();
    console.log("toReturn", toReturn);

    return toReturn;
  } catch (error) {
    console.log("Error in getEventsForCalendars", error);
    return [];
  } finally {
    message.destroy();
    message.success("Events loaded successfully!");
  }
};

import { Tooltip } from "antd";
import { PrinterFilled } from "@ant-design/icons";

import { useRedux } from "../../../../../../../hooks";
import { generatePDFsForMultipleTables } from "../../utils";
import { ExportPdfIcon } from "../../../../../../../../../assets";
import { MondayButton } from "../../../../../../../../commonComponents";

const HeaderButtons = ({ isDisabled, estimation, selectedKeys }) => {
  const [tableDataArray] = useRedux("CompareTakeOffVersions");

  const title = `${estimation?.jobSiteAddress} - ${
    estimation?.versionServices?.[selectedKeys?.first]?.versionName || ""
  } vs. ${
    estimation?.versionServices?.[selectedKeys?.second]?.versionName || ""
  }`;

  const handleExportPDF = () => {
    generatePDFsForMultipleTables(tableDataArray, "download", title);
  };

  const handlePrintPDF = () => {
    generatePDFsForMultipleTables(tableDataArray, "print", title);
  };

  return (
    <>
      <Tooltip title="Export to PDF">
        <MondayButton
          {...{
            disabled: isDisabled,
            className: `mondayButtonBlue ${isDisabled ? "disabled" : ""}`,
            onClick: handleExportPDF,
            Icon: <ExportPdfIcon />,
          }}
        >
          Export To PDF
        </MondayButton>
      </Tooltip>
      <Tooltip title="Print">
        <div
          onClick={handlePrintPDF}
          className={`filterIcons ${isDisabled ? "disabled" : ""}`}
        >
          <PrinterFilled style={{ fontSize: 21 }} />{" "}
        </div>
      </Tooltip>
    </>
  );
};

export default HeaderButtons;

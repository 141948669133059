export function resetNestedValues(data) {
  if (Array.isArray(data)) {
    return data.map(resetNestedValues);
  }

  if (typeof data === "object" && data !== null) {
    return Object.entries(data).reduce((acc, [key, value]) => {
      if (
        typeof value === "number" &&
        key.toLowerCase() !== "taxRate" &&
        (key.toLowerCase().includes("price") ||
          key.toLowerCase().includes("rent") ||
          key.toLowerCase().includes("value") ||
          key.toLowerCase().includes("tax") ||
          key.toLowerCase().includes("ppu"))
      ) {
        acc[key] = 0;
      } else {
        if (typeof value === "string" && key.toLowerCase().includes("rent")) {
          acc[key] = 0;
        } else {
          acc[key] = resetNestedValues(value);
        }
      }
      return acc;
    }, {});
  }

  return data;
}

import broadcastNotification from "../../../../../../../helpers/controllers/broadcastNotification";

export const sendAutomationNotification = async (
  topic,
  action,
  automationId,
  automationName,
  userConfiguration
) => {
  // return await broadcastNotification(
  return broadcastNotification(
    topic,
    action,
    [
      {
        common: userConfiguration?.nameOfUser,
        commonNext: automationName,
      },
      {
        userName: userConfiguration?.nameOfUser,
        currentUser: userConfiguration?.cognitoUserId,
      },
    ],
    automationId
  );
};

import { Input, Tooltip } from "antd";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import { useState } from "react";

const EditCardTitle = ({ e }) => {
  const selectedCard = e?.rowData?.find((a) => a?.id === e?.id);
  const indexIdx = selectedCard.id;

  const [editedCardTitle, setEditedCardTitle] = useState(
    selectedCard?.title || ""
  );

  const editTitleHandler = (event) => {
    setEditedCardTitle(event.target.value);
  };

  const handleSubmit = () => {
    if (editedCardTitle !== "") {
      e.handleSaveEditedValue(indexIdx, editedCardTitle);
    }
  };

  return (
    <div>
      <label style={{ fontSize: "15px", fontWeight: "bold" }}>
        Edit Card Title
      </label>
      <div style={{ display: "flex", gap: "5px" }}>
        <Input
          value={editedCardTitle}
          style={{ backgroundColor: "#F5F5F7" }}
          onChange={editTitleHandler}
        />

        <Tooltip
          title={editedCardTitle === "" ? "Empty Fields aren't allowed!" : ""}
        >
          <MondayButton
            hasIcon={false}
            className={!editedCardTitle ? "mondayButtonGrey" : ""}
            disabled={
              !editedCardTitle.trim() ||
              editedCardTitle.trim().toLowerCase() ===
                selectedCard?.title.trim().toLowerCase()
            }
            onClick={handleSubmit}
            Icon={<TickIcon />}
          />
        </Tooltip>
      </div>
    </div>
  );
};

export default EditCardTitle;

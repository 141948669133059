import { message } from "antd";
import { useState } from "react";
import { AgGridReact } from "ag-grid-react";

import { useRedux } from "../../../../../hooks";
import { formatCurrency } from "../../../../../utils";
import { defaultColDef, columnDefs } from "./utils/invoiceAgGridColumnDefs";
import { showSwalWarningMessage } from "../../../../../../../utils/swalWindowsMessages";
import { onCellKeyPress } from "../../../Applications/ApplicationView/components/ServiceList/GridData/GridUtils";
import { frameworkComponents } from "../../../Applications/ApplicationView/components/ServiceList/GridData";
import AgGridMathful from "../../../Applications/ApplicationView/components/ServiceList/Service/ServiceContent/AgGridSum/AgGridMathful";

import "./NewCreditMemo.scss";
import "./InvoiceAgGrid.scss";

export const InvoiceAgGrid = ({
  setChargeToSave,
  style = {},
  chargeToSave,
  invoiceId,
  rowData,
  setRowData,
  invoiceNumber,
  subtotal,
  taxAmount,
  currentCharge,
  taxRate,
  chargeToSaveHandler,
  creditAmount,
}) => {
  const [darkMode] = useRedux("chargesDarkMode");
  const [selected, setSelected] = useState(false); // Context state & dispatch
  const [isWritable] = useRedux("creditMemoIsWritable");

  const onDescriptionChange = ({ description, data }) => {
    setRowData((prev) => {
      const changedRow = prev?.find(({ id }) => id === data?.id);
      changedRow.rowDescription = description;
      changedRow.description = description;
      return prev;
    });
  };

  const onCellEditingStarted = (params) => {
    if (!isWritable && currentCharge) {
      params.api.stopEditing(true);
      message.error("Please enable write mode");
    }
  };

  const updateData = (newKeyValues, data, params) => {
    const filteredItems = (chargeToSave?.chargeItems ?? []).filter(
      ({ invoiceId, id }) => invoiceId !== data.invoiceId || id !== data.id
    );

    const otherInvoicesAmount = filteredItems.reduce(
      (acc, item) => acc + item.creditAmount,
      0
    );

    const maxAmountExceeded =
      (newKeyValues?.creditAmount || 0) >
      (creditAmount || 0) - otherInvoicesAmount;

    const totalAllowedAmount =
      data.scopeAmount -
      (newKeyValues?.creditAmount || 0) -
      (data.paidAmount || 0);

    const isMaxAmountExceeded = totalAllowedAmount < 0 || !!maxAmountExceeded;

    const updateRowData = (prev) => {
      return prev?.map((row) => {
        if (
          row.id === data.id &&
          row.invoiceId === data.invoiceId &&
          !isMaxAmountExceeded
        ) {
          return { ...row, ...newKeyValues };
        }
        return row;
      });
    };

    if (isMaxAmountExceeded) {
      if (params) {
        params.node.setDataValue(params.colDef.field, params.oldValue);
      }

      showSwalWarningMessage({
        title: "Warning",
        text: "The total credit amount exceeds the credit amount.",
        confirmButtonText: "Ok",
        timer: 0,
      });

      return;
    }

    setRowData((prev) => {
      const newChargeItems = updateRowData(prev);
      const tempToSave = chargeToSave;
      let temp = {
        ...tempToSave,
        chargeItems: [
          ...(tempToSave?.chargeItems?.filter(
            ({ invoiceId: iD }) => iD !== invoiceId
          ) || []),
          ...(newChargeItems?.filter(
            ({ creditAmount }) => !(creditAmount <= 0)
          ) || []),
        ],
      };
      chargeToSaveHandler(temp);

      return !maxAmountExceeded ? newChargeItems : prev;
    });
  };

  const onCellEditingStopped = (params) => {
    let { newValue, data, colDef } = params;
    if (!isWritable && currentCharge) return;

    const changedKey = colDef.field;

    const totalCreditAmount =
      data?.credits
        ?.filter(({ chargeId }) => chargeId !== currentCharge?.chargeId)
        ?.reduce(
          (total, { creditedAmount }) => total + (creditedAmount || 0),
          0
        ) || 0;

    console.log({ totalCreditAmount, totalCreditAmount, data });

    const totalPaidAmount = data?.paidAmount || 0;

    if (changedKey === "creditAmount") {
      newValue = parseFloat(newValue);
      if (
        newValue + totalCreditAmount >
        data?.scopeAmount + data.creditedAmount
      ) {
        message.error("Value cannot be larger than the total amount!");

        const newCreditAmount =
          data?.scopeAmount - totalCreditAmount - totalPaidAmount;

        const taxAmount = newCreditAmount * (data.tax ? data?.taxRate : 0);

        updateData(
          {
            creditAmount: newCreditAmount,
            creditPercentage: (newCreditAmount / data?.scopeAmount) * 100,
            taxAmount,
            total: newCreditAmount + taxAmount,
          },
          data,
          params
        );
      } else {
        const newCreditAmount = parseFloat(newValue);

        const taxAmount = newCreditAmount * (data.tax ? data?.taxRate : 0);

        updateData(
          {
            creditAmount: newCreditAmount,
            creditPercentage: (newCreditAmount / data?.scopeAmount) * 100,
            taxAmount,
            total: newCreditAmount + taxAmount,
          },
          data,
          params
        );
      }
    } else if (changedKey === "creditPercentage") {
      const newCreditAmount = (parseFloat(newValue) / 100) * data?.scopeAmount;

      if (
        newCreditAmount + totalCreditAmount + totalPaidAmount >
        data?.scopeAmount + data.creditedAmount
      ) {
        message.error("Value cannot be larger than the total amount!");

        const maxPercentageAllowed =
          ((data?.scopeAmount - totalCreditAmount - totalPaidAmount) /
            data?.scopeAmount) *
          100;

        const newCreditAmount =
          (parseFloat(maxPercentageAllowed) / 100) * data?.scopeAmount;

        const taxAmount = newCreditAmount * (data.tax ? data?.taxRate : 0);

        updateData(
          {
            creditPercentage: maxPercentageAllowed,
            creditAmount: newCreditAmount,
            taxAmount,
            total: newCreditAmount + taxAmount,
          },
          data,
          params
        );
      } else {
        const newCreditAmount =
          (parseFloat(newValue) / 100) * data?.scopeAmount;

        const taxAmount = newCreditAmount * (data.tax ? data?.taxRate : 0);

        updateData(
          {
            creditAmount: newCreditAmount,
            creditPercentage: parseFloat(newValue),
            taxAmount,
            total: newCreditAmount + taxAmount,
          },
          data,
          params
        );
      }
    }
  };

  const onPercentageChange = (e) => {
    if (!isWritable && currentCharge) {
      message.error("Please enable write mode");
      return;
    }
    const value = parseFloat(e?.target?.value) || 0;

    if (value > 100) {
      message.error("Percentage is not allowed!");
      e.target.value = 0;
      return;
    }
    console.log({ rowData });
    const allPercentAllowed = rowData?.map((item) => {
      const rowCreditedAmount = item.credits
        ?.filter(({ chargeId }) => chargeId !== currentCharge?.chargeId)
        ?.reduce(
          (total, { creditedAmount }) => total + (creditedAmount || 0),
          0
        );

      const totalAmount = item.scopeAmount;
      const allowedAmount =
        item.scopeAmount - item.paidAmount - rowCreditedAmount;

      const remainingPercentage = (allowedAmount / totalAmount) * 100;

      return remainingPercentage;
    });

    const maxPercentageAllowed = Math.min(...allPercentAllowed);

    if (maxPercentageAllowed < value) {
      message.error("Percentage is not allowed!");
      e.target.value = maxPercentageAllowed
        ? maxPercentageAllowed.toFixed(2)
        : "0";
      return;
    }

    setRowData((prev) => {
      prev?.forEach((lineItem) => {
        const creditAmount = (value * (lineItem?.scopeAmount || 0)) / 100;
        const taxAmount = lineItem?.tax
          ? (value * (lineItem?.scopeAmount * lineItem?.taxRate || 0)) / 100
          : 0;
        lineItem.creditPercentage = value;
        lineItem.creditAmount = creditAmount;
        lineItem.taxAmount = taxAmount;
        lineItem.total = creditAmount + taxAmount;
      });

      setChargeToSave((prevCharge) => ({
        ...(prevCharge || {}),
        chargeItems: [
          ...(prevCharge?.chargeItems?.filter(
            ({ invoiceId: iD }) => iD !== invoiceId
          ) || []),
          ...(prev?.filter(({ creditAmount }) => !(creditAmount <= 0)) || []),
        ],
      }));

      return prev;
    });
  };

  const onRangeSelectionChanged = (event) => {
    let cellRanges = event.api.getCellRanges();
    if (
      cellRanges[0]?.startRow?.rowIndex !== cellRanges[0]?.endRow?.rowIndex ||
      cellRanges.length > 1
    ) {
      setSelected(Object.assign(event, {}));
    } else {
      setSelected(false);
    }
  };

  return (
    <div
      style={{
        marginBottom: 20,
        border: "1px solid black",
        boxShadow: "3px 5px 20px 13px rgb(59 59 73 / 20%)",
        ...style,
      }}
      className={`dark-ag-theme documentationsTable invoiceChargeTable reqTables ag-theme-alpine-dark`}
    >
      <div className="header">
        Invoice {invoiceNumber}
        <div style={{ display: "flex", gap: 15, alignItems: "center" }}>
          %
          <input
            type="number"
            min={0}
            onChange={onPercentageChange}
            style={{ background: "#1F212D", color: "white", border: 0 }}
          />
        </div>
        <div>
          {selected && (
            <div className="mathDataInfoWrapper" style={{ marginRight: 15 }}>
              <AgGridMathful darkMode={darkMode} event={selected} />
            </div>
          )}
        </div>
      </div>
      <AgGridReact
        onRangeSelectionChanged={onRangeSelectionChanged}
        domLayout="autoHeight"
        undoRedoCellEditing={true}
        enableRangeSelection={true}
        immutableData={true}
        enableCellChangeFlash={true}
        stopEditingWhenGridLosesFocus={true}
        suppressDragLeaveHidesColumns={true}
        allowDragFromColumnsToolPanel={true}
        suppressRowClickSelection={true}
        rowSelection="multiple"
        undoRedoCellEditingLimit={50}
        defaultColDef={defaultColDef}
        rowData={rowData}
        columnDefs={columnDefs({
          taxRate,
          invoiceId,
          isWritable,
          updateData,
          currentCharge,
          setChargeToSave,
          onDescriptionChange,
          chargeItems: chargeToSave.chargeItems,
        })}
        getRowNodeId={(data) => data.id}
        onCellKeyPress={onCellKeyPress}
        frameworkComponents={frameworkComponents}
        onCellEditingStopped={onCellEditingStopped}
        getRowHeight={() => 43}
        onCellEditingStarted={onCellEditingStarted}
      />
      <div className="header">
        <div style={{ display: "flex", gap: 15 }}>
          <div>Subtotal: {formatCurrency(subtotal)}</div>
          <div>Tax Amount:{formatCurrency(taxAmount)}</div>
          <div>Total: {formatCurrency(subtotal + taxAmount)}</div>
        </div>
      </div>
    </div>
  );
};

import _ from "lodash";
import { API } from "aws-amplify";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { fetchByList } from "../../../../../utils";

/**
 * Function that updates the data of the dispatch
 * routes and the activities with the actual depart and arrive times
 */
async function auditActivities({
  auditForTruck,
  revert = false,
  newAuditActivities,
  plannedTripsForDate,
}) {
  const tmpActivities = [];
  let activities = [];
  let fleetId = Object.keys(auditForTruck || {})?.[0];

  if (!fleetId) {
    return Promise.resolve();
  }

  const groupedActivities = _.keyBy(
    plannedTripsForDate?.[fleetId] || [],
    "activityId"
  );

  for (const activityId of newAuditActivities) {
    const relatedActivity = groupedActivities?.[activityId] || {};

    if (revert) {
      tmpActivities.push({
        ...relatedActivity,
        actualDepart: "",
        actualArrive: "",
        activityStatus: revert ? "Draft" : "Audited",
      });
      continue;
    }

    const relatedAlerts = [];
    for (const alertId in auditForTruck?.[fleetId]?.["changed"] || {}) {
      let obj = auditForTruck[fleetId]["changed"][alertId];
      if (obj["activityId"] === activityId) {
        relatedAlerts.push(obj);
      }
    }

    if (relatedAlerts?.length) {
      relatedAlerts?.sort((a, b) => a?.alertDateTime - b?.alertDateTime);
      tmpActivities.push({
        ...relatedActivity,
        activityStatus: revert ? "Draft" : "Audited",
        actualDepart: dayjsNY(relatedAlerts?.[0]?.["alertDateTime"]).format(),
        actualArrive: dayjsNY(relatedAlerts?.[1]?.["alertDateTime"]).format(),
      });
    }
  }

  if (fleetId) {
    for (const activityId of newAuditActivities) {
      let relatedActivity = plannedTripsForDate?.[fleetId]?.find(
        ({ activityId: id }) => activityId === id
      );

      if (relatedActivity) {
        if (revert) {
          activities.push({
            activityId,
            dispatchId: relatedActivity?.dispatchId,
            actualDepart: "",
            actualArrive: "",
          });
        } else {
          let relatedAlerts = [];
          for (const alertId in auditForTruck?.[fleetId]?.["changed"] || {}) {
            let obj = auditForTruck[fleetId]["changed"][alertId];
            if (obj["activityId"] === activityId) {
              relatedAlerts.push(obj);
            }
          }

          if (relatedAlerts?.length) {
            relatedAlerts?.sort((a, b) => a?.alertDateTime - b?.alertDateTime);
            activities.push({
              activityId,
              dispatchId: relatedActivity?.dispatchId,
              actualDepart: dayjsNY(
                relatedAlerts?.[0]?.["alertDateTime"]
              ).format(),
              actualArrive: dayjsNY(
                relatedAlerts?.[1]?.["alertDateTime"]
              ).format(),
            });
          }
        }
      }
    }
  }

  if (!activities.length) {
    return Promise.resolve();
  }

  let dispatchesToAudit = activities.reduce(
    (acc, { dispatchId, activityId, actualDepart, actualArrive }) => ({
      ...acc,
      [dispatchId]: {
        ...(acc?.[dispatchId] || []),
        [activityId]: {
          actualDepart,
          actualArrive,
          activityStatus: revert ? "Draft" : "Audited",
        },
      },
    }),
    {}
  );

  const allDispatchesToEdit = await fetchByList(
    "fleetDispatching",
    "dispatchId",
    Object.keys(dispatchesToAudit)
  ).then((res) => {
    return res.reduce(
      (acc, val) => ({
        ...acc,
        [val.dispatchId]: val,
      }),
      {}
    );
  });

  const tmpNewDispatchBodies = [];
  const routesUpdates = {};
  const activityUpdates = {};

  for (const dispatchId in allDispatchesToEdit) {
    const routes = [...(allDispatchesToEdit[dispatchId]["routes"] || [])];
    for (const activityId in dispatchesToAudit[dispatchId]) {
      const routeIndex = routes?.findIndex(
        ({ activityId: id }) => activityId === id
      );

      if (routeIndex === -1) {
        continue;
      }

      if (!dispatchId in routesUpdates) {
        routesUpdates[dispatchId] = {};
      }

      routesUpdates[dispatchId] = {
        ...routesUpdates[dispatchId],
        [`routes.[${routeIndex}].actualDepart`]:
          dispatchesToAudit[dispatchId][activityId]["actualDepart"],
        [`routes.[${routeIndex}].actualArrive`]:
          dispatchesToAudit[dispatchId][activityId]["actualArrive"],
      };

      activityUpdates[activityId] = {
        actualDepart: dispatchesToAudit[dispatchId][activityId]["actualDepart"],
        actualArrive: dispatchesToAudit[dispatchId][activityId]["actualArrive"],
        activityStatus:
          dispatchesToAudit[dispatchId][activityId]["activityStatus"],
      };

      routes[routeIndex]["actualDepart"] =
        dispatchesToAudit[dispatchId][activityId]["actualDepart"];
      routes[routeIndex]["actualArrive"] =
        dispatchesToAudit[dispatchId][activityId]["actualArrive"];
      routes[routeIndex]["activityStatus"] = "Audited";
    }

    const dispatch = allDispatchesToEdit[dispatchId];
    tmpNewDispatchBodies.push({
      ...dispatch,
      routes,
    });
  }

  return Promise.all([
    API.post("batchUpdate", "/batchUpdate", {
      body: {
        entries: tmpActivities,
        tableName: "fleetActivity",
        sortKey: "activityId",
      },
    }),
    API.post("batchUpdate", "/batchUpdate", {
      body: {
        entries: tmpNewDispatchBodies,
        tableName: "fleetDispatching",
        sortKey: "dispatchId",
      },
    }),
  ]);
}

export default auditActivities;

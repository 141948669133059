import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getBills = async () => {
  const bills = await fetchAllData("bills", "bills", "billId");

  return bills.map((bill) => ({
    ...bill,
    billItems: bill.billItems.map((item) => ({
      ...item,
      billNumber: bill.billNumber,
      vendorId: bill.vendorId,
      vendorName: bill.vendorName,
      billCategory: bill.billCategory,
      billDate: bill.billDate,
      billStatus: bill.billStatus,
    })),
  }));
};

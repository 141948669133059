export const compareNewColumns = ({
  getCellChange = false,
  employeeType = "crews",
}) => {
  switch (employeeType) {
    case "engineers":
    case "architects":
      return [
        {
          title: "First Name",
          dataIndex: "firstName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "firstName", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Last Name",
          dataIndex: "lastName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "lastName", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Email",
          dataIndex: "email",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "email", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Pay Rate",
          dataIndex: "payRate",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "payRate", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Salary Type",
          dataIndex: "salaryType",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "salaryType", index, "new");
            } else {
              return value;
            }
          },
        },
      ];
    default:
      return [
        {
          title: "Employee Name",
          dataIndex: "crewName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "crewName", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Role",
          dataIndex: "crewPosition",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "crewPosition", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Employee Id",
          dataIndex: "employeeId",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "employeeId", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Rate",
          dataIndex: "employeeRate",
          width: 100,
          align: "center",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "employeeRate", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Company",
          dataIndex: "accountName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "accountName", index, "new");
            } else {
              return value;
            }
          },
        },
        {
          title: "Foreman",
          dataIndex: "foreman",
          width: 130,
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "foreman", index, "new");
            } else {
              return value ? "Foreman" : "Employee";
            }
          },
        },
      ];
  }
};

export const compareExistingColumns = ({
  getCellChange = false,
  employeeType = "crews",
}) => {
  switch (employeeType) {
    case "engineers":
    case "architects":
      return [
        {
          title: "First Name",
          dataIndex: "firstName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "firstName", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Last Name",
          dataIndex: "lastName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "lastName", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Email",
          dataIndex: "email",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "email", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Pay Rate",
          dataIndex: "payRate",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "payRate", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Salary Type",
          dataIndex: "salaryType",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "salaryType", index, "existing");
            } else {
              return value;
            }
          },
        },
      ];
    default:
      return [
        {
          title: "Employee Name",
          dataIndex: "crewName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "crewName", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Role",
          dataIndex: "crewPosition",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "crewPosition", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Employee Id",
          dataIndex: "employeeId",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "employeeId", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Rate",
          dataIndex: "employeeRate",
          width: 100,
          align: "center",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "employeeRate", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Company",
          dataIndex: "accountName",
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "accountName", index, "existing");
            } else {
              return value;
            }
          },
        },
        {
          title: "Foreman",
          dataIndex: "foreman",
          width: 130,
          render: (value, _, index) => {
            if (getCellChange) {
              return getCellChange(value, "foreman", index, "existing");
            } else {
              return value;
            }
          },
        },
      ];
  }
};

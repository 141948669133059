import {
  UIEvent,
  useMemo,
  useState,
  Dispatch,
  useContext,
  SetStateAction,
} from "react";
import { Dayjs } from "dayjs";
import { Form, FormInstance, Tooltip, message } from "antd";

import PayrollLiveContext from "../../PayrollLiveContext";
import { EntryType, GroupedEntry } from "../../payrollLiveTypes";
import CardComponent from "src/components/CardComponent/CardComponent";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { InputComponent } from "src/components/SidebarPages/Fleet/components";
import { parseInTz } from "src/components/SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";

import "./GroupedReportsCard.scss";

interface Props {
  form: FormInstance<{ searchField: string; reportDate?: Array<Dayjs> }>;
  setEntryModal: Dispatch<SetStateAction<undefined | Partial<EntryType>>>;
}

type GroupStatusType = "noLunch" | "hr" | "incorrect";
type PunchTypeTypes = "ID" | "OL" | "IL" | "OD" | "HR";

const initialIndex = {
  start: 0,
  end: 15,
};
const punchTypes = ["ID", "OL", "IL", "OD", "HR"];
const groupStatuses = [
  { label: "No Lunch", value: "noLunch", key: 0 },
  { label: "Incorrect", value: "incorrect", key: 1 },
  { label: "HR", value: "hr", key: 2 },
  { label: "No Punch", value: "No Punch", key: 3 },
];

function GroupedReportsCard(props: Props) {
  const { form, setEntryModal } = props;
  const {
    degEntries,
    accessRight,
    groupedEntries,
    employeesReport,
    programEmployees,
    controlPanelForm,
  } = useContext(PayrollLiveContext);

  const [groupStatus, setGroupStatus] = useState<Array<GroupStatusType>>([]);
  const [sliceIndex, setSliceIndex] = useState<{ start: number; end: number }>(
    initialIndex
  );

  const searchValue = Form.useWatch("searchField", form);
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);

  function onEditEntry(entryId: string) {
    let entryIndex = degEntries.findIndex((el) => el.entryId === entryId);
    if (entryIndex > -1) {
      let empIndex = programEmployees.findIndex(
        (el) => el?.crewId === degEntries?.[entryIndex]?.crewId
      );
      if (empIndex === -1 && entryIndex > -1) {
        message.warning({
          key: "onNewEntry",
          content: "This employee is not registered in the program.",
          duration: 3.5,
        });
        return;
      }
      setEntryModal(degEntries[entryIndex]);
    }
  }

  function onScroll(e: UIEvent<HTMLElement>): void {
    const scrollStep = 10;
    const maxStartIndex = reportCards.length - scrollStep;
    const maxEndIndex = reportCards.length - 1;
    let indexPosition = e.currentTarget.scrollTop / 130;

    if (indexPosition >= 6.7 && sliceIndex.end === scrollStep) {
      setSliceIndex(() => ({
        start: 0,
        end: 15 + scrollStep,
      }));
    }

    if (indexPosition >= 18 && sliceIndex.end !== maxEndIndex) {
      setSliceIndex((prev) => {
        let startIndex = prev.start + scrollStep;
        let endIndex = prev.end + scrollStep;
        return {
          start: startIndex > maxStartIndex ? maxStartIndex : startIndex,
          end: endIndex > maxEndIndex ? maxEndIndex : endIndex,
        };
      });
    }

    if (indexPosition <= 0.5) {
      setSliceIndex((prev) => {
        return {
          start: prev.start >= scrollStep ? prev.start - scrollStep : 0,
          end: prev.end > scrollStep ? prev.end - scrollStep : scrollStep,
        };
      });
    }
  }

  function onNewEntry({
    crewId,
    punchType,
  }: {
    crewId?: string;
    punchType: PunchTypeTypes;
  }) {
    const entryObj: Partial<EntryType> = {
      crewId,
      punchType,
      punchDate: selectedDate,
      activityStatus: "Draft",
    };
    if (crewId) {
      const empIndex = programEmployees.findIndex((el) => el.crewId === crewId);
      const emp = programEmployees?.[empIndex];

      if (emp?.crewId) {
        Object.assign(entryObj, {
          crewName: emp?.crewName,
          company: emp.accountName,
          employeeId: emp?.employeeId,
          employeeRate: emp.employeeRate,
          employeeRole: emp.crewPosition,
          employeeFullName: emp?.crewName,
        });
      } else {
        message.warning({
          key: "onNewEntry",
          content: "This employee is not registered in the program.",
          duration: 3.5,
        });
        return;
      }
    } else {
      message.warning({
        key: "onNewEntry",
        content: "This employee is not registered in the program.",
        duration: 3.5,
      });
      return;
    }
    setEntryModal(entryObj);
  }

  const noPunchReports = useMemo(() => {
    const npPunchEntries = employeesReport.filter(
      (el) => el.liveStatus === "No Punch"
    );

    return npPunchEntries.map((el) => ({
      ID: [],
      OL: [],
      IL: [],
      OD: [],
      crewId: "",
      employeeId: el?.employeeId,
      employeeName: el?.employeeName,
      date: parseInTz().format("YYYY-MM-DD"),
      status: "No Punch",
    }));
  }, [employeesReport]);

  const reportCards = useMemo(() => {
    const allEntries = groupedEntries.concat(noPunchReports);
    let filteredGroupedEntries: Array<GroupedEntry> = [];

    for (let i = 0; i < allEntries.length; i++) {
      let pass = true;
      const report = allEntries[i];
      // search matches report name
      if (
        !(report?.employeeName || "")
          .toLowerCase()
          .includes((searchValue || "").toLowerCase())
      ) {
        if (sliceIndex?.start) {
          setSliceIndex(initialIndex);
        }
        pass = false;
      }

      if (
        groupStatus?.length &&
        !groupStatus.includes(report?.status as GroupStatusType)
      ) {
        if (sliceIndex.start) {
          setSliceIndex(initialIndex);
        }
        pass = false;
      }

      if (pass) {
        filteredGroupedEntries.push(report);
      }
    }

    return filteredGroupedEntries.map((rep, i) => {
      return (
        <div className={`report-card ${rep?.status}`} key={i}>
          <div className="report-header">
            <div className="header-title">
              <label>{rep?.employeeName}</label>
              <label>{parseInTz(rep.date).format("MM/DD/YYYY")}</label>
            </div>
            <label className={rep?.status}>{rep?.status}</label>
          </div>
          <div className="report-body">
            {punchTypes.map((punchType: PunchTypeTypes, index) => (
              <div className="punch-type-container" key={punchType + index}>
                <span
                  className={`button-inner-text ${
                    !rep?.[punchType]?.length && accessRight?.write
                      ? "button-inner-active"
                      : ""
                  }`}
                  onClick={
                    !rep?.[punchType]?.length && accessRight?.write
                      ? () => onNewEntry({ punchType, crewId: rep?.crewId })
                      : () => {}
                  }
                >
                  {punchType}
                </span>
                <div className="entries-container">
                  {(rep?.[punchType] || []).map(
                    (entry: EntryType, i: number) => {
                      return entry?.punchType === punchType ? (
                        <Tooltip title="Open entry" placement="left">
                          <label
                            key={entry?.entryId}
                            className={i > 0 ? "incorrect-entry" : ""}
                            onClick={() => onEditEntry(entry?.entryId)}
                          >
                            {dayjsNY(entry.punchTimeStamp).format("hh:mm A")}
                          </label>
                        </Tooltip>
                      ) : (
                        []
                      );
                    }
                  )}
                </div>
              </div>
            ))}
          </div>
        </div>
      );
    });
  }, [groupedEntries, searchValue, groupStatus, noPunchReports, accessRight]);

  return (
    <CardComponent
      title={
        <div className="custom-header">
          <label>Employee Cards Report</label>
          <InputComponent
            type="select"
            mode="multiple"
            value={groupStatus}
            formItemName="groupStatus"
            customOptions={groupStatuses}
            placeholder="Filter by status"
            onChange={(e) => setGroupStatus(e)}
          />
        </div>
      }
      className="grouped-reports-card"
    >
      <>
        <div className="reports-container" onScroll={onScroll}>
          {reportCards?.length ? (
            reportCards.slice(sliceIndex.start, sliceIndex.end)
          ) : (
            <div className="no-reports-message">No Report Cards</div>
          )}
        </div>
        <span className="reports-count">
          {reportCards?.length} Total report cards
        </span>
      </>
    </CardComponent>
  );
}

export default GroupedReportsCard;

import { Dropdown, Input } from "antd";
import {
  SearchIcon,
  ThreeDotsIcon,
} from "../../../SidebarPages/Communication/assets";
import { ExportOrEmailDropdown } from "../../../commonComponents";
import { useState } from "react";
import { useSelector } from "react-redux";

function DrawerHeaderActionsMobile({
  setCurrentPage,
  setSearchValue,
  searchValue,
  onGeneratePDF,
  filteredNotifications,
  exportToExcel,
  uploadExcelToDrive,
}) {
  const [open, setOpen] = useState(false); // State to manage dropdown visibility
  const { isDarkMode } = useSelector((state) => state.darkMode); // Select dark mode status from the Redux store

  // Handle opening and closing of the dropdown based on user interaction
  const handleOpenChange = (nextOpen, info) => {
    if (info.source === "trigger" || nextOpen) {
      setOpen(nextOpen); // Update the dropdown open state
    }
  };

  // Define the items for the dropdown menu
  const items = [
    {
      key: "1",
      label: "PDF or XLSX",
      children: ExportOrEmailDropdown({
        onGeneratePDF,
        rowData: filteredNotifications,
        exportGridToExcel: exportToExcel,
        defaultDocDefinition: true,
        uploadExcelToDrive,
        title: "Notifications",
        returnOnlyItems: true,
      }),
      placement: "bottom",
    },
    {
      key: "2",
      label: (
        <Input
          className="searchInput"
          placeholder="Search notifications"
          allowClear
          onChange={(e) => {
            setCurrentPage(1);
            setSearchValue(e.target.value);
          }}
          value={searchValue}
          prefix={
            <SearchIcon width={15} height={15} style={{ marginRight: 5 }} />
          }
          style={{ width: 150, marginLeft: 0 }}
        />
      ),
    },
  ];

  return (
    <Dropdown
      menu={{ items }}
      open={open}
      onOpenChange={handleOpenChange}
      overlayStyle={{ width: "max-content" }}
      placement="bottom"
    >
      <a onClick={(e) => e.preventDefault()}>
        <ThreeDotsIcon style={{ fill: isDarkMode ? "#fff" : "" }} />{" "}
      </a>
    </Dropdown>
  );
}

export default DrawerHeaderActionsMobile;

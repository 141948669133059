import { Image, Modal } from "antd";
import { useNavigate } from "react-router-dom";
import { ExclamationCircleOutlined } from "@ant-design/icons";
import { loginStatusColors } from "../../../../../AllUsers/allUsersData";
import OnlineOfflineStatus from "../../OnlineOfflineStatus/OnlineOfflineStatus";
import { useSelector } from "react-redux";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import { useEffect, useState } from "react";

const { confirm } = Modal;

const UserCard = ({ user }) => {
  const {
    nameOfUser,
    departmentName,
    groupName,
    identityId,
    activeSessions,
    googleDriveFileId,
    isSuspended,
  } = user;

  const { preferences } = useSelector((state) => state.preferences);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [errorImage, setErrorImage] = useState(null);

  const [currentFile, setCurrentFile] = useState({});

  const driveRequest = driveApi({ accessToken });
  const navigate = useNavigate();

  const statusColor = user.isSuspended
    ? loginStatusColors.suspended
    : !!activeSessions?.length
    ? loginStatusColors.loggedIn
    : loginStatusColors.loggedOut;

  const status = isSuspended
    ? "Suspended"
    : !!activeSessions?.length
    ? "Online"
    : "Offline";

  useEffect(() => {
    //setCurrentFile only if we have googleDriveFileId
    if (googleDriveFileId && accessToken)
      driveRequest
        .getImageSrc(googleDriveFileId)
        .then((image) => setCurrentFile({ ...image }));
    else setCurrentFile();
  }, [googleDriveFileId, accessToken]);

  const onUserClickHandler = () => {
    confirm({
      className: "smallWarningModal",
      title:
        "Are you sure you want to change user? The changes you made will be lost.",
      icon: <ExclamationCircleOutlined />,
      onOk: () => {
        navigate(
          `/settings/allUsers/${departmentName}/${groupName}/${identityId}`
        );
      },
    });
  };

  return (
    <div className="userlistBodyElementName">
      {googleDriveFileId && !errorImage && currentFile?.src ? (
        <Image
          preview={false}
          src={currentFile?.src}
          alt="userImage"
          onError={() => {
            setErrorImage(true);
          }}
        />
      ) : (
        <div
          className="userImage"
          style={{
            backgroundColor:
              userConfiguration?.nameOfUser === nameOfUser
                ? preferences.preferences?.profileColor || "#4895ef"
                : "#F8F8FA",
          }}
        >
          <span
            className="letters"
            style={{
              color:
                userConfiguration?.nameOfUser === nameOfUser
                  ? "#fff"
                  : "#323338",
            }}
          >
            {nameOfUser.split(" ").map((userToSplit) => userToSplit[0])}
          </span>
        </div>
      )}
      <span className="name" onClick={onUserClickHandler}>
        {nameOfUser}
      </span>
      <OnlineOfflineStatus {...{ status, color: statusColor }} />
    </div>
  );
};

export default UserCard;

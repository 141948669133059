import { useRef, CSSProperties } from "react";
import { Checkbox, CheckboxRef } from "antd";

import "./ReportCheck.scss";

/**
 * @param {Object} props
 * @param {string} [props.id]
 * @param {string} [props.className]
 * @param {ReactNode} props.label
 * @param {boolean} [props.disabled]
 * @param {null|boolean} [props.checked]
 * @param {boolean} [props.defaultChecked]
 * @param {CSSProperties} [props.style]
 * @param {string} [props.dataTestId="report-check"]
 * @param {(checked: boolean, id?: string) => any} [props.onChange]
 * @param {boolean} [props.indeterminate]
 */
function ReportCheck({
  id,
  label,
  disabled,
  className,
  defaultChecked,
  style = undefined,
  checked = undefined,
  onChange = () => {},
  dataTestId = "report-check",
  indeterminate,
}) {
  /**
   * @type {React.MutableRefObject<CheckboxRef>}
   */
  const checkboxRef = useRef(null);
  /**
   * @type {React.MutableRefObject<HTMLDivElement>}
   */
  const containerRef = useRef(null);

  /**
   * Function that adds/removes a class name from the checkbox
   */
  function handleChange(e = {}) {
    if (disabled) {
      return;
    }

    let isChecked;

    if (checked === undefined) {
      isChecked = e?.target?.checked;

      let box = containerRef.current;

      if (!box) {
        return;
      }

      if (isChecked) {
        box.classList.add("container-checked");
      } else {
        box.classList.remove("container-checked");
      }
    } else {
      isChecked = !checked;
    }

    onChange(isChecked, id);
  }

  function handleClick() {
    checkboxRef.current.input.click();
  }

  return (
    <div
      className={`report-check ${className ?? ""} ${
        disabled ? "check-disabled" : ""
      }`}
      id={id}
      style={style}
    >
      <div
        className={`checkbox-container ${
          checked !== undefined
            ? checked
              ? "container-checked"
              : ""
            : defaultChecked
            ? "container-checked"
            : ""
        }`}
        ref={containerRef}
      >
        <Checkbox
          {...{
            disabled,
            defaultChecked,
            ref: checkboxRef,
            onChange: handleChange,
            "data-testid": dataTestId,
            ...(checked === undefined ? {} : { checked }),
            indeterminate,
          }}
        />
      </div>
      <div className="check-title" onClick={handleClick}>
        {label}
      </div>
    </div>
  );
}

export default ReportCheck;

import { API } from "aws-amplify";
import { v4 as uuidv4 } from "uuid";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { Modal, Form, message } from "antd";
import { HistoryOutlined, InfoCircleFilled } from "@ant-design/icons";

import {
  Stepper,
  MondayButton,
  WarningModal,
  MultiLevelTreeLogs,
} from "../../../../../../commonComponents";
import { TickIcon } from "../../../Roles/src";
import { useEditLogs } from "../../../../../../../hooks";
import EmployeeUploadContext from "./EmployeeUploadContext";
import { fetchAllData, lazyFetch } from "../../../../../../../utils";
import { UploadEmployeeStep, EmployeesTable } from "./stepsComponents";
import { LeftArrow } from "../../../../../../SidebarPages/BasePage/src";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import { WarningTriangle } from "../../../../../../SidebarPages/DynamicView/src";
import { getChangedData } from "../../../../../../SidebarPages/Accounting/components/utilities";

import "./UploadedEmployeesModal.scss";
import React from "react";
import { InputComponent } from "../../../../../../SidebarPages/Fleet/components";
import { downloadExcelTemplate } from "../../../../../../SidebarPages/HrManagement/utils/downloadExcel";

const Steps = { 0: UploadEmployeeStep, 1: EmployeesTable };

function UploadEmployeesModal({
  open,
  onCancel,
  employeesList,
  setEmployeesList,
}) {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { programFields } = useSelector((state) => state.programFields);
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const [teams, setTeams] = useState([]);
  const [roles, setRoles] = useState([]);
  const [logsData, setLogsData] = useState([]);
  const [gridApi, setGridApi] = useState(false);
  const [companies, setCompanies] = useState([]);
  const [uploadInfo, setUploadInfo] = useState([]);
  const [currentStep, setCurrentStep] = useState(0);
  const [uploading, setUploading] = useState(false);
  const [fetchedData, setFetchedData] = useState([]);
  const [logsVisible, setLogsVisible] = useState(false);
  const [cancelWarning, setCancelWarning] = useState(false);
  const [uploadedEmployees, setUploadedEmployees] = useState([]);
  const [addEmployeesWarning, setAddEmployeesWarning] = useState(false);
  const [employeesConflicts, setEmployeesConflicts] = useState({
    existingEmployees: [],
    newEmployees: [],
  });
  const [employeeType, setEmployeeType] = useState(null);

  const modalSteps = [
    { title: "Upload", disabled: true },
    { title: employeeType?.capitalize(), disabled: true },
  ];

  const { saveAddedLogs } = useEditLogs();

  const [form] = Form.useForm();
  const Component = Steps[`${currentStep}`];

  function logsGenerator({ uploadedEmployees = [] }) {
    let logObject = {
      recordId: "",
      recordName: "",
      category: "Crews Upload",
      actionType: "Upload",
      topic: "Employees",
      currentData: {},
      previousData: {},
      label: "Uploaded Employees",
      updatedKeys: [],
    };
    let logsList = [];

    if (!uploadedEmployees?.length) {
      return;
    }

    for (let employee of uploadedEmployees) {
      let existingIndex = employeesList.indexOf(
        ({ crewId }) => crewId === employee?.crewId
      );

      let previousEmployee = employeesList?.[existingIndex];

      let newEditLog = {
        recordId: employee?.crewId,
        recordName: employee.crewName,
        currentData: {},
        previousData: {},
        updatedKeys: [],
      };
      for (let key in employee) {
        let result;
        if (!!employee?.[key] && key !== "crewId" && key !== "foreman") {
          result = getChangedData(employee?.[key], previousEmployee?.[key]);

          if (result !== false) {
            newEditLog.currentData[key] = result.curr;
            newEditLog.previousData[key] = result.prev || "Does not exist";
            newEditLog.updatedKeys.push(key);
          }
        } else {
          continue;
        }
      }
      if (!!newEditLog?.updatedKeys?.length) {
        logsList.push({ ...logObject, ...newEditLog });
      }
    }
    setLogsData((prev) => prev.concat(logsList));
    return logsList;
  }

  function generateCrewTeams({ updatedRows }) {
    const accountName = form.getFieldValue("accountName");
    const crewTeams = _.groupBy(
      updatedRows.filter((row) => !!row?.crewTeam),
      ({ crewTeam }) => crewTeam
    );

    let newTeams = [];
    for (let i = 0; i < Object.keys(crewTeams)?.length; i++) {
      const crewTeamName = Object.keys(crewTeams)[i]; // every key in crewTeams is the crewTeamName
      const teamMembers = crewTeams[crewTeamName];

      if (crewTeamName && crewTeamName !== "Supervisor") {
        // Supervisors is not a team name
        const foreman = teamMembers.filter(({ foreman }) => foreman === true); // in team members find the foremans

        // if there are multiple foremans than we separate the team to multiple teams one per each foreman
        if (foreman.length > 1) {
          let lastIndex = 0;
          foreman.forEach((fEmp, index) => {
            const indexOfForeman =
              teamMembers.findIndex(
                ({ foreman }, i) => foreman === true && i > lastIndex // find next foreman index
              ) || teamMembers.length;

            const crewMembers = teamMembers
              .slice(lastIndex, indexOfForeman)
              .flatMap((member) =>
                member.foreman !== true
                  ? {
                      crewName: member?.crewName,
                      crewId: member?.crewId,
                      employeeId: member?.employeeId,
                    }
                  : []
              );

            const teamObj = {
              crewTeamId: uuidv4(),
              createdAt: new Date().valueOf(),
              createdBy: {
                identityId: userConfiguration?.identityId,
                nameOfUser: userConfiguration?.nameOfUser,
              },
              company: accountName,
              crewMembers,
              crewForeman: { crewId: fEmp?.crewId, crewName: fEmp?.crewName },
              crewTeamName: `${crewTeamName}${!!index ? " " + index : ""}`,
            };
            newTeams.push(teamObj); // add new team to newTeams array

            for (let member of crewMembers) {
              // update each member with the foreman assigned in the team
              let memberIndex = updatedRows.find(
                ({ crewId }) => crewId === member?.crewId
              );
              if (memberIndex) {
                // using Object.assign to change the reference of the object in array
                Object.assign(memberIndex, {
                  foreman: fEmp?.crewId,
                });
              }
            }

            let foremanIndex = updatedRows.find(
              // update members in foreman Object
              ({ crewId }) => crewId === fEmp?.crewId
            );
            if (foremanIndex) {
              // using Object.assign to change the reference of the object in array
              Object.assign(foremanIndex, { members: crewMembers });
            }

            lastIndex = indexOfForeman || teamMembers.length; // change the last index of the foreman we created team to
          });
        } else {
          // create list of members for the crewTeam
          const crewMembers = teamMembers.flatMap((member) =>
            member.foreman !== true
              ? {
                  crewName: member?.crewName,
                  crewId: member?.crewId,
                  employeeId: member?.employeeId,
                }
              : []
          );
          // create crewTeam obj
          const teamObj = {
            crewTeamId: uuidv4(),
            createdAt: new Date().valueOf(),
            createdBy: {
              identityId: userConfiguration?.identityId,
              nameOfUser: userConfiguration?.nameOfUser,
            },
            company: accountName,
            crewMembers,
            crewForeman: {
              crewId: foreman[0]?.crewId,
              crewName: foreman[0]?.crewName,
            },
            crewTeamName,
          };
          newTeams.push(teamObj); // add the teamObj to the newTeams array

          for (let member of crewMembers) {
            // update foreman for each member in the team
            let memberIndex = updatedRows.find(
              ({ crewId }) => crewId === member?.crewId
            );

            if (memberIndex) {
              // using Object.assign to change the reference of the object in array
              Object.assign(memberIndex, {
                foreman: foreman[0]?.crewId,
              });
            }
          }

          let foremanIndex = updatedRows.find(
            // update members in foreman
            ({ crewId }) => crewId === foreman[0]?.crewId
          );
          if (foremanIndex) {
            // using Object.assign to change the reference of the object in array
            Object.assign(foremanIndex, { members: crewMembers });
          }
        }
      }
    }
    return newTeams;
  }

  async function addEmployees() {
    message.loading({
      content: "Uploading Employees...",
      duration: 0,
      key: "uploadEmployees",
    });
    let updatedRows = [];
    gridApi.forEachNode(({ data }) => updatedRows.push(data));

    let employeesToPost = updatedRows.flatMap((emp) => {
      // delete keys without losing the reference value in newCrewTeams
      let bodyObj = { ...emp };
      delete bodyObj.crewTeam;
      delete bodyObj?.newRowIndex;
      if (bodyObj?.userId) {
        delete bodyObj?.userId;
        delete bodyObj?.existingRowIndex;
      }
      return bodyObj;
    });

    // Add Engineers or Architects ToDo: Create the Service
    if (["engineers", "architects"].includes(employeeType)) {
      const newEmployees = employeesToPost.map((employee) => ({
        nameOfUser: `${employee.firstName} ${employee.lastName}`,
        userName: employee.email,
        employeeRate: employee.employeeRate,
        salaryType: employee.salaryType,
        createdBy: {
          nameOfUser: userConfiguration?.nameOfUser,
          cognitoId: userConfiguration?.cognitoUserId,
        },
      }));

      let path = employeeType;

      API.post(path, `/${path}`, {
        body: newEmployees,
      })
        .then(async (res) => {
          employeesList.push(res);

          //Save logs
          const employeesLogs = logsGenerator({
            uploadedEmployees: employeesToPost,
          });
          saveAddedLogs(employeesLogs);

          message.success({
            content: "Employees uploaded successfully!",
            key: "uploadEmployees",
          });
          setAddEmployeesWarning(false);
          onCancel();
        })
        .catch((error) => {
          message.error({
            content: "There was a problem uploading Employees!",
            key: "uploadEmployees",
          });
          setAddEmployeesWarning(false);
        });

      return;
    }

    const newTeams = generateCrewTeams({ updatedRows }).filter(
      (team) => team?.crewTeamName !== "undefined"
    );

    let employeesToUpdate = updatedRows.flatMap((emp) =>
      emp.hasOwnProperty("existingRowIndex") ? emp?.crewId : []
    );

    let membersUpdatedFromTeam = [];
    teams?.fieldOptions?.[
      process.env.NODE_ENV === "production" ? "prod" : "dev"
    ]?.forEach(({ crewTeamName = "", crewMembers = [], crewForeman = {} }) => {
      const teamConflict = newTeams.find(
        (team) => team?.crewTeamName === crewTeamName
      );

      if (teamConflict) {
        let membersToUpdate =
          crewMembers &&
          crewMembers.filter(
            (member) =>
              !teamConflict?.crewMembers &&
              !teamConflict?.crewMembers.some(
                ({ crewId }) => member?.crewId === crewId
              )
          );
        membersUpdatedFromTeam = membersUpdatedFromTeam.concat(membersToUpdate);
        membersUpdatedFromTeam.push({ ...crewForeman, foreman: true });
      }
    });

    if (membersUpdatedFromTeam?.length) {
      const updatedMembers = employeesList.filter(
        ({ crewId }) =>
          membersUpdatedFromTeam.some((member) => member?.crewId === crewId) &&
          !updatedRows.some((member) => member?.crewId === crewId)
      );

      updatedMembers.forEach((member) => {
        const bodyObj = { ...member };

        delete bodyObj.userId;
        delete bodyObj.createdAt;
        delete bodyObj.createdBy;

        if (member.foreman === true) {
          Object.assign(member, { members: [] });
        } else {
          Object.assign(member, { foreman: false });
        }
      });

      employeesToPost = employeesToPost.concat(updatedMembers);

      employeesToUpdate = employeesToUpdate.concat(
        updatedMembers.map(({ crewId }) => crewId)
      );
    }

    try {
      // Delete employees to be updated ( they will be recreated with the updated values)
      await API.del("crews", "/crews/1111", { body: employeesToUpdate });

      await API.post("crews", "/crews", {
        body: employeesToPost,
      }).then((res) => {
        const employeesLogs = logsGenerator({
          uploadedEmployees: employeesToPost,
        });
        const newEmployees = res.map(({ crewId }) => crewId);
        const newEmployeesList = employeesList
          .filter(({ crewId }) => !newEmployees.includes(crewId))
          .concat(res);
        // _.uniqBy(
        //   employeesList.concat(res),
        //   ({ crewId }) => crewId
        // );

        setEmployeesList(newEmployeesList);
        saveAddedLogs(employeesLogs);
      });

      const updatedCrewTeams = (
        teams?.fieldOptions?.[
          process.env.NODE_ENV === "production" ? "prod" : "dev"
        ] || []
      )
        .filter((team) => {
          return !newTeams.some(
            ({ crewTeamName }) => crewTeamName === team.crewTeamName
          );
        })
        .concat(newTeams);

      const newFieldOptions = {
        ...teams.fieldOptions,
        [process.env.NODE_ENV === "production" ? "prod" : "dev"]:
          updatedCrewTeams,
      };

      await API.patch("programFields", `/programFields/${teams.fieldId}`, {
        body: {
          fieldOptions: newFieldOptions,
        },
      });
      message.success({
        content: "Employees uploaded successfully!",
        key: "uploadEmployees",
      });
      setAddEmployeesWarning(false);
      onCancel();
    } catch (err) {
      message.error({
        content: "There was a problem uploading Employees!",
        key: "uploadEmployees",
      });
      setAddEmployeesWarning(false);
    }
  }

  useEffect(() => {
    if (!!programFields) {
      lazyFetch({
        tableName: "accounts",
        listOfKeys: ["accountName", "accountRecordType"],
        filterKey: "accountRecordType",
        filterValue: "Subcontractors",
      })
        .then((res) => {
          setCompanies(res);
        })
        .catch((error) => {
          message.error("There was a problem getting Companies");
          console.log("Error: ", error);
        });

      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "category",
                  value: "Crews Upload",
                  formula: "is",
                },
              ],
            },
          ]),
        },
      }).then((res) => setLogsData(res));
      const listOfTeams = programFields.find(
        ({ fieldName }) => fieldName === "Crew Teams"
      );
      setTeams(listOfTeams);

      const fieldObj = programFields?.reduce(
        (acc, { fieldName, fieldOptions }) => ({
          ...acc,
          [fieldName]: fieldOptions,
        })
      );
      setRoles(fieldObj?.["Crew Position"]);
    }
  }, [programFields]);

  return (
    <Modal
      title={`Upload ${employeeType?.capitalize() || "Employees"} ${
        uploadedEmployees?.[0]?.accountName
          ? " " + uploadedEmployees?.[0]?.accountName
          : ""
      }`}
      open={open}
      onCancel={() => (currentStep !== 0 ? setCancelWarning(true) : onCancel())}
      closable={true}
      centered={true}
      closeIcon={<XIcon />}
      className={`uploadEmployeesModal ${
        isDarkMode && "uploadEmployeesModalDark"
      } ${currentStep === 1 ? "uploadEmployeesModalTable" : ""}`}
      rootClassName={currentStep === 1 ? "uploadEmployeesModalRoot" : ""}
      destroyOnClose={true}
      data-testid="upload-employees-modal"
      footer={[
        <div className="uploadEmployeesFooterDiv">
          <div className="uploadEmployeesFooter">
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={() =>
                currentStep !== 0 ? setCancelWarning(true) : onCancel()
              }
            >
              Cancel
            </MondayButton>
            {!!currentStep && (
              <MondayButton
                className="mondayButtonBlue"
                Icon={<LeftArrow />}
                onClick={() => setCurrentStep(0)}
              >
                Go Back
              </MondayButton>
            )}
          </div>
          <div className="uploadEmployeesFooter">
            <MondayButton
              className="mondayButtonBlue uploadEmployeeLogs"
              Icon={<HistoryOutlined style={{ width: "1.2rem" }} />}
              onClick={() => setLogsVisible(true)}
            />
            {currentStep === 0 ? (
              <MondayButton
                disabled={
                  (uploadedEmployees?.length === 0 &&
                    employeesConflicts?.newEmployees?.length === 0) ||
                  uploading
                }
                onClick={() => setCurrentStep(1)}
                Icon={<TickIcon width={17} height={17} />}
              >
                Next
              </MondayButton>
            ) : (
              <MondayButton
                disabled={!!employeesConflicts?.newEmployees?.length}
                onClick={() => setAddEmployeesWarning(true)}
                Icon={<TickIcon width={17} height={17} />}
              >
                Save Changes
              </MondayButton>
            )}
          </div>
        </div>,
      ]}
    >
      <EmployeeUploadContext.Provider
        value={{
          form,
          roles,
          gridApi,
          companies,
          uploading,
          uploadInfo,
          setGridApi,
          fetchedData,
          setUploading,
          setUploadInfo,
          employeesList,
          setFetchedData,
          setCurrentStep,
          setEmployeesList,
          uploadedEmployees,
          employeesConflicts,
          setUploadedEmployees,
          setEmployeesConflicts,
          employeeType,
          setEmployeeType,
        }}
      >
        {/* ToDo: Create e separate Component */}
        {employeeType === null && (
          <InputComponent
            type="select"
            label="Employee type"
            placeholder="Select employee type..."
            formItemName={"employeeType"}
            dropdownClassName={isDarkMode && "darkDropDown"}
            onChange={(value) => setEmployeeType(value)}
            customOptions={[
              {
                value: "crews",
                label: "Crew",
              },
              {
                value: "engineers",
                label: "Engineer",
              },
              {
                value: "architects",
                label: "Architect",
              },
              // {
              //   value: "drivers",
              //   label: "Drivers",
              // },
            ]}
          />
        )}
        {employeeType && !currentStep && (
          <div className="uploadEmployeeInfo">
            <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
            <p className="uploadEmployeeText">
              Adjust the filters, selecting specific criteria to customize the
              displayed data.
              <br></br>
              {`Get the spreadsheet template for ${employeeType} from `}
              <a onClick={() => downloadExcelTemplate(employeeType)}>here</a>.
            </p>
          </div>
        )}
        {employeeType && (
          <div
            className="uploadEmployeeDiv"
            style={{ width: !!currentStep ? "100%" : "83.5%" }}
          >
            <Stepper
              {...{
                stepperClassName: "employee-upload-stepper",
                stepRenderer: false,
                currentStep,
                setCurrentStep,
                steps: modalSteps,
              }}
            />
            <section style={{ width: "100%" }}>
              <Component />
            </section>
          </div>
        )}
        {addEmployeesWarning && (
          <WarningModal
            visible={addEmployeesWarning}
            setVisible={setAddEmployeesWarning}
            title="Warning Message"
            closable={true}
            className="logout-warning-modal"
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>Are you sure you want to add all the Employees?</p>
              <div className="buttons">
                <MondayButton
                  onClick={() => setAddEmployeesWarning(false)}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={addEmployees}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}

        {cancelWarning && (
          <WarningModal
            visible={cancelWarning}
            setVisible={setCancelWarning}
            title="Warning Message"
            closable={true}
            className="logout-warning-modal"
          >
            <div className="logout-modal-body">
              <span>
                <WarningTriangle />
              </span>
              <p>
                Are you sure you want to Cancel? All the uploads will be lost.
              </p>
              <div className="buttons">
                <MondayButton
                  onClick={() => setCancelWarning(false)}
                  Icon={<XIcon />}
                  className="mondayButtonRed"
                >
                  No
                </MondayButton>
                <MondayButton
                  onClick={onCancel}
                  Icon={<TickIcon width={17} height={17} />}
                >
                  Yes
                </MondayButton>
              </div>
            </div>
          </WarningModal>
        )}
        {logsVisible && (
          <MultiLevelTreeLogs
            {...{
              title: "Uploaded Employees Logs",
              visible: logsVisible,
              setVisible: setLogsVisible,
              logsData,
            }}
          />
        )}
      </EmployeeUploadContext.Provider>
    </Modal>
  );
}

export default UploadEmployeesModal;

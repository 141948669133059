import base64ImageToDrive from "../../../Documentation/View/components/DocumentationModal/components/DocUploader/base64ToDrive";

async function processVisualizationImages(
  rowObject,
  visualizationImages,
  driveRequest,
  folderDriveKeyName,
  driveFolderCondition,
  filesToBeDeleted
) {
  const oldImages =
    rowObject?.safetyApplicationObject?.visualizationImages || [];

  if (!visualizationImages?.length) {
    await handleOldImages([], oldImages, driveRequest, filesToBeDeleted);
    return filteredOldImages([], oldImages, filesToBeDeleted);
  }

  try {
    const newImages = await Promise.all(
      visualizationImages.map((img) =>
        processImage(
          img,
          driveRequest,
          folderDriveKeyName,
          driveFolderCondition
        )
      )
    );
    await handleOldImages(newImages, oldImages, driveRequest, filesToBeDeleted);

    return [
      ...newImages,
      ...filteredOldImages(newImages, oldImages, filesToBeDeleted),
    ];
  } catch (err) {
    console.error("Error saving visualization images: ", err);
    return [];
  }
}

export default processVisualizationImages;

async function processImage(
  image,
  driveRequest,
  folderDriveKeyName,
  driveFolderCondition
) {
  const { src, blob, ...rest } = image || {};
  const newId = await base64ImageToDrive({
    base64: src,
    driveRequest,
    folderId: driveFolderCondition()?.[folderDriveKeyName],
    fileName: rest.name,
    mimeType: "image/png",
  });
  return { ...rest, id: newId, mimeType: "image/png" };
}

async function handleOldImages(
  newImages = [],
  oldImages = [],
  driveRequest = [],
  filesToBeDeleted = []
) {
  const toBeDeleted = oldImages.filter(
    (image) =>
      !newImages.some((newImage) => newImage.parentId === image.parentId) ||
      filesToBeDeleted.includes(image.id)
  );

  if (toBeDeleted.length > 0) {
    await Promise.all(
      toBeDeleted.map((image) => driveRequest.deleteDriveItem(image.id))
    );
  }
}

function filteredOldImages(
  newImages = [],
  oldImages = [],
  filesToBeDeleted = []
) {
  return oldImages.filter(
    (oldImage) =>
      !newImages.some((newImage) => oldImage.parentId === newImage.parentId) &&
      !filesToBeDeleted.includes(oldImage.id)
  );
}

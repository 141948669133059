import { SimplePLI } from "./GeneralModels";

export class TreeProtectionPLI extends SimplePLI {
  serviceId = 85;

  length;
  height;
  width;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}
export class AlternateTreeProtectionPLI extends SimplePLI {
  serviceId = 85;

  length;
  height;
  width;
  isAlternate = true;

  constructor(id, taxRate) {
    super(id, taxRate);
  }
}

import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";

export const getSalesReportApprovedEstimates = async () => {
  try {
    let resTemp = [];
    let tempAcc = await fetchAllData("accounts", "accounts", "accountId");
    let projects = await fetchAllData("projects", "projects", "projectId");
    let estimations = await fetchAllData(
      "estimations",
      "estimations",
      "estimationId"
    ).then((r) => r.estimations.filter((e) => e.estSTATUS === "Approved"));

    projects?.map((proj) => {
      proj?.estimations?.length &&
        resTemp.push(
          ...tempAcc.filter((acc) => acc.accountId === proj.accountId)
        );
    });

    return resTemp?.map((acc) => {
      return {
        ...acc,
        projectDataSet: projects
          ?.filter(
            (proj) =>
              proj?.accountId === acc?.accountId && proj?.estimations.length
          )
          ?.map((proj) => {
            return {
              ...proj,
              estimationDataSet: estimations?.filter(
                (est) => est?.projectId === proj?.projectId
              ),
            };
          }),
      };
    });
  } catch (error) {
    console.log(
      "data source function getSalesReportApprovedEstimates error: ",
      error
    );
  }
};

// communicationEmailsReducer.js

import types from "src/types";

const initialState = {
  emails: [],
  drafts: [],
  recordData: {},
  loading: false,
  error: null,
  currentPage: 1,
  selectedCategory: { key: "1", label: "Inbox" },
  checkedList: [],
};

const communicationReducer = (state = initialState, action) => {
  const { type, payload } = action;

  switch (type) {
    case types.FETCH_EMAILS_START:
      return {
        ...state,
        loading: true,
      };

    case types.FETCH_EMAILS_FINISHED:
      return {
        ...state,
        loading: false,
      };

    case types.FETCH_EMAILS_SUCCESS:
      return {
        ...state,
        emails: payload.emails,
        drafts: payload.drafts,
        recordData: payload.recordData,
        error: null,
      };

    case types.FETCH_EMAILS_FAILURE:
      return {
        ...state,
        loading: false,
        error: payload,
      };

    case types.ADD_EMAILS:
      return {
        ...state,
        emails: [...state.emails, ...payload?.newEmails],
      };

    case types.UPDATE_EMAIL_ATTACHMENTS:
      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === action.payload.emailId
            ? {
                ...email,
                attachments: [
                  ...email.attachments,
                  ...action.payload.newAttachments,
                ],
              }
            : email
        ),
      };

    case types.MARK_AS_IMPORTANT:
      if (payload.isDraft) {
        return {
          ...state,
          drafts: state.drafts.map((draft) =>
            draft.id === payload.itemId
              ? { ...draft, labels: [...draft.labels, "IMPORTANT"] }
              : draft
          ),
        };
      }

      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === payload.itemId
            ? { ...email, labels: [...email.labels, "IMPORTANT"] }
            : email
        ),
      };

    case types.MARK_AS_NOT_IMPORTANT:
      if (payload.isDraft) {
        return {
          ...state,
          drafts: state.drafts.map((draft) =>
            draft.id === payload.itemId
              ? {
                  ...draft,
                  labels: draft.labels.filter((label) => label !== "IMPORTANT"),
                }
              : draft
          ),
        };
      }

      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === payload.itemId
            ? {
                ...email,
                labels: email.labels.filter((label) => label !== "IMPORTANT"),
              }
            : email
        ),
      };

    case types.MARK_AS_READ:
      if (payload.isDraft) {
        return {
          ...state,
          drafts: state.drafts.map((draft) =>
            draft.id === payload.itemId
              ? {
                  ...draft,
                  labels: draft.labels.filter((label) => label !== "UNREAD"),
                }
              : draft
          ),
        };
      }

      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === payload.itemId
            ? {
                ...email,
                labels: email.labels.filter((label) => label !== "UNREAD"),
              }
            : email
        ),
      };

    case types.MARK_AS_UNREAD:
      if (payload.isDraft) {
        return {
          ...state,
          drafts: state.drafts.map((draft) =>
            draft.id === payload.itemId
              ? { ...draft, labels: [...draft.labels, "UNREAD"] }
              : draft
          ),
        };
      }

      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === payload.itemId
            ? { ...email, labels: [...email.labels, "UNREAD"] }
            : email
        ),
      };

    case types.MOVE_EMAIL_TO:
      const { emailId: moveToTrashFromInboxId, removeFrom, moveTo } = payload;
      return {
        ...state,
        emails: state.emails.map((email) =>
          email.id === moveToTrashFromInboxId
            ? {
                ...email,
                labels: [
                  ...email.labels.filter((label) => label !== removeFrom),
                  moveTo,
                ],
              }
            : email
        ),
      };

    case types.ADD_DRAFTS:
      const { newDrafts } = payload;
      return {
        ...state,
        drafts: [newDrafts],
      };

    case types.UPDATE_DRAFTS:
      const { newDraft } = payload;

      return {
        ...state,
        drafts: [...state.drafts, newDraft],
      };

    case types.UPDATE_DRAFT_BY_ID:
      const { updatedDraft } = payload;

      return {
        ...state,
        drafts: state.drafts.map((draft) =>
          draft.draftId === updatedDraft.draftId ? updatedDraft : draft
        ),
      };

    case types.DELETE_DRAFT_BY_ID:
      const { draftIdToDelete } = payload;

      return {
        ...state,
        drafts: state.drafts.filter(
          (draft) => draft.draftId !== draftIdToDelete
        ),
      };

    case types.SET_CURRENT_PAGE:
      return {
        ...state,
        currentPage: payload,
      };

    case types.SET_SELECTED_CATEGORY:
      return {
        ...state,
        selectedCategory: payload,
      };

    case types.SET_CHECKED_LIST:
      return {
        ...state,
        checkedList: payload,
      };

    case types.RESET_COMMUNICATION_STATE:
      return initialState;

    case types.SET_RECORD_DATA:
      return {
        ...state,
        recordData: payload,
      };

    case types.RESET_RECORD_DATA:
      return {
        ...state,
        recordData: {},
      };

    default:
      return state;
  }
};

export default communicationReducer;

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { InfoText } from "../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { TickIcon } from "../../../InternalChat/assets/icons";
import { ReportCheck } from "../../Fleet/fleetsLive/components/LiveReportsView/components/ReportViewController/components";
import "./EditSafetyContacts.scss";
import { ReactComponent as DragIcon } from "src/icons/drag.svg";

function EditSafetyContacts({
  initialValues,
  modifiedValues,
  filters,
  setFilters,
  setInitialValues,
}) {
  const index = initialValues?.findIndex(
    (el) => el?.title === "Safety Contacts"
  );
  const safetyContacts = initialValues?.[index]?.body;

  const checkedUsersNr =
    safetyContacts?.filter((el) => !el?.isHidden)?.length || 0;

  function onDragEnd(result) {
    if (!result.destination) {
      return;
    }
    const startIndex = result.source.index;
    const endIndex = result.destination.index;
    if (startIndex === endIndex) return;
    const items = Array.from(safetyContacts);
    const [reorderedItem] = items.splice(startIndex, 1);
    items.splice(endIndex, 0, reorderedItem);
    setInitialValues((prev) => {
      return [
        ...prev.filter((el) => el?.title !== "Safety Contacts"),
        {
          title: "Safety Contacts",
          body: items,
          other: true,
        },
      ];
    });
  }

  return (
    <div className="editSafetyContacts">
      <InfoText
        {...{
          text: "Add or remove safety contacts. Only 12 contacts can be fit in the first page!",
        }}
      />

      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="droppable">
          {(provided, snapshot) => (
            <div
              className="contacts-container"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {(safetyContacts || [])?.map((contact, i) => {
                const { identityId, nameOfUser, phoneNumber, isHidden } =
                  contact;
                return (
                  <Draggable
                    key={identityId}
                    index={i}
                    draggableId={identityId.toString()}
                  >
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        elevation={4}
                        style={{
                          opacity: snapshot.isDragging ? 0.6 : 1,
                          display: "flex",
                          gap: 10,
                          width: snapshot.isDragging ? "200px" : "fit-content",
                          minWidth: 200,
                          alignItems: "center",
                          ...provided.draggableProps.style,
                          backgroundColor: "#f4f5f6",
                        }}
                      >
                        <ReportCheck
                          {...{
                            //   dataTestId: "",
                            id: identityId,
                            className: "contact-checkbox",
                            label: nameOfUser,
                            onChange: (e) => {
                              setInitialValues((prev) => {
                                const updatedContacts = prev[index]?.body;
                                const updatedContact = updatedContacts.find(
                                  (el) => el.identityId === identityId
                                );
                                updatedContact.isHidden = !e;
                                return [
                                  ...prev.filter(
                                    (el) => el?.title !== "Safety Contacts"
                                  ),
                                  {
                                    title: "Safety Contacts",
                                    body: updatedContacts,
                                    other: true,
                                  },
                                ];
                              });
                            },
                            checked: !isHidden,
                            disabled: checkedUsersNr > 11 && isHidden,
                          }}
                          key={i}
                        />
                        <span
                          {...{
                            ...provided.dragHandleProps,
                            //   ...provided.draggableProps,
                          }}
                        >
                          <DragIcon />
                        </span>
                      </div>
                    )}
                  </Draggable>
                );
              })}
            </div>
          )}
        </Droppable>
      </DragDropContext>
    </div>
  );
}

export default EditSafetyContacts;

import React from "react";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import { useSelector } from "react-redux";
import { Select } from "antd";
import "./select-record-category.scss";

export default function SelectRecordCategory({
  fetchData,
  selectedRecordCategory,
  setSelectedRecordCategory,
}) {
  const { topicCategories } = useSelector((state) => state.topicCategories);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { emailsLoading } = useSelector((state) => state.notifications);

  const categoriesToInclude = [
    "Projects",
    "Leads",
    "Inspections",
    "Scheduling",
    "Opportunities",
    "Estimations",
    "Clients",
    "Hearings",
    "Claims",
    "Contacts",
    "Fleet Drivers",
    "Fleets",
    "Permit Drawings",
    "Vendors",
    "Incidents",
    "Safety",
    "Safety Inspections",
  ];

  // filters topic categories based on categories provided and return the the record options
  const options = topicCategories
    .filter(({ categoryName }) => categoriesToInclude.includes(categoryName))
    .sort(
      (a, b) =>
        categoriesToInclude.indexOf(a.categoryName) -
        categoriesToInclude.indexOf(b.categoryName)
    )
    .map(({ categoryName, apiName }) => ({
      value: apiName,
      label: categoryName,
    }));

  const handleOnChange = (selectedValue) => {
    setSelectedRecordCategory(selectedValue);
    fetchData(selectedValue);
  };

  return (
    <div className="select-record-category-container ">
      <InputComponent
        type="select"
        allowClear={false}
        initialValue={selectedRecordCategory}
        dropdownClassName={isDarkMode && "darkDropDown"}
        placeholder="Select a category to see emails..."
        onChange={handleOnChange}
        options={options?.map((option) => (
          <Select.Option value={option.value}>{option.label}</Select.Option>
        ))}
        disabled={emailsLoading}
      />
    </div>
  );
}

import { getDispatchLocationOptions } from "../../RequestFormModal/requestFormModalData";
import { MaxTagTooltip } from "../../../../commonComponents";

const hearingDynamicInputFields = ({
  dynamicFields,
  nameDisabled,
  projects,
  onLocationChange,
  schedulesForDate,
  category,
  claimDate,
  hearingTeam,
  onTeamSelect,
  onTeamDeselect,
  form,
  isDarkMode,
  modalTitle,
  selectedTeam = [],
}) => {
  let teamOptions = hearingTeam || [];
  if (selectedTeam?.some((team) => team?.value === "Team")) {
    teamOptions.push({
      label: "Team",
      value: "Team",
      members: [],
    });
  }
  const teamField = {
    label: "Team",
    formItemName: "hearingTeam",
    placeholder: "Select team",
    type: "select",
    customOptions: teamOptions,
    mode: "multiple",
    onSelect: onTeamSelect,
    onDeselect: onTeamDeselect,
    // style: { width: "100%", maxWidth: 378.5 },
    required: true,
    allowClear: false,
    maxTagCount: 2,
    maxTagPlaceholder: (e) => {
      console.log("e:", { e });
      return (
        <MaxTagTooltip
          {...{
            array: e,
            onDeselect: onTeamDeselect,
          }}
        />
      );
    },
  };

  const newInputFields = dynamicFields
    ?.filter((el) => el?.claimCategory === category)
    ?.map((el) => {
      if (el?.formItemName === "claimNumber") {
        el.disabled = true;
      } else if (el.formItemName === "representative") {
        el.disabled = nameDisabled;
        el.rules = [
          { min: 3, message: "Name must be at least 3 characters!" },
          {
            validator: (_, value) => {
              const regex = /^[a-zA-Z0-9\s]+$/; // Allow spaces
              if (regex.test(value.trim())) {
                return Promise.resolve();
              } else {
                return Promise.reject(
                  "Hearing Representative must contain only letters, numbers"
                );
              }
            },
          },
        ];
      } else if (el.formItemName === "hearingDate") {
        el = {
          ...el,
          label: `${modalTitle} Date`,
          form,
          validTime: "future",
          validationDate: claimDate,
          isDarkMode,
        };
      } else if (el.formItemName === "depositionAddress") {
        el.formItemName = "hearingAddress";
        el.label = `${modalTitle} Address`;
      } else if (el.formItemName === "time") {
        el = {
          ...el,
          form,
          label: `${modalTitle} Time`,
          validTime: "future",
          isDarkMode,
          dateField: "hearingDate",
        };
      } else if (el.formItemName === "hearingAddress") {
        el.groupedOptions = getDispatchLocationOptions(
          schedulesForDate,
          projects
        );
        el.label = `${modalTitle} Address`;
        el.onSelect = onLocationChange;
        el.showSearch = true;
      }
      return el;
    });

  if (newInputFields?.some((el) => el?.index)) {
    newInputFields.sort((a, b) => a.index - b.index);
  }

  return [...newInputFields, teamField];
};

export default hearingDynamicInputFields;

import { useEffect } from "react";

const useAutoScrollCards = (
  panelRef,
  autoScrollCards,
  scrollStep = 1,
  interval = 20
) => {
  useEffect(() => {
    if (!autoScrollCards) return;

    const panel = panelRef.current;
    let scrollingForward = true;

    const scroll = () => {
      if (scrollingForward) {
        if (panel.scrollLeft >= panel.scrollWidth - panel.clientWidth) {
          scrollingForward = false;
        } else {
          panel.scrollLeft += scrollStep;
        }
      } else {
        if (panel.scrollLeft <= 0) {
          scrollingForward = true;
        } else {
          panel.scrollLeft -= scrollStep;
        }
      }
    };

    const scrollInterval = setInterval(scroll, interval);

    return () => clearInterval(scrollInterval);
  }, [panelRef, autoScrollCards, scrollStep, interval]);
};

export default useAutoScrollCards;

//@ts-check
import moment from "moment";
import {
  getAddressInfo,
  getDobInformation,
  getLatLngFromAddress,
  titleFormatter,
} from ".";
import {
  addTotalitiesToCharges,
  calculateInvoicePaidPercentage,
  calculateSubTotal,
  calculateTotal,
  serviceGeneration,
  validateChargeRentalState,
} from "./utils";
import { filterTables, showErrorMsg } from "../../../../utils";
import { recordName } from "./constants";
import { customProps } from "../forms/NewTask/helpers";
import { generateContactInputFields } from "../forms/Contact/helpers";
import { areRequiredFieldsPresent } from "../forms/NextStepInputs/helpers";
import { getTaskBody } from "../../../SidebarPages/TaskManager/Tasks/TasksModals/NewTask/helpers";
import { forceToNumber } from "../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { convertRequisitionIntoChargeItems } from "../../../SidebarPages/Projects/Accounting/Charges/components/NewChargeItem/utils";
import { amountsCalculator } from "../../../SidebarPages/Projects/Accounting/Applications/ApplicationView/components/ServiceList/Service/ServiceHeader/ServiceHeaderComponents/CompleateAll/compleateAllFunctions";
import { categoriesName } from "../../../SidebarPages/Accounting/components/CreateInvoice/utils/constants";
import {
  firstLetterToUpperCase,
  getCategoryId,
  getRecordNumber,
} from "../../../SidebarPages/Accounting/components/CreateInvoice/components/InvoiceForm/utils/firstLetterToUpperCase";
import { getQuickDescription } from "../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/components/PaymentTable/components/InvoiceOverview/InvoiceOverview";

export const invoiceBodyCreation = async ({
  record,
  inputData,
  category,
  userConfiguration,
  lastStep,
}) => {
  const project = await filterTables(
    "projects",
    "projectId",
    record?.projectId
  );

  if (!project) throw new Error();

  const selectedCategory = !!lastStep ? `${lastStep}s` : category;
  const categoryToSelect = selectedCategory.toLowerCase();
  const invoiceItems = serviceGeneration({
    services: record?.chargeItems ? record.chargeItems : record.services || [],
    groupName: `${firstLetterToUpperCase(categoriesName[categoryToSelect])} ${
      getRecordNumber(record, categoryToSelect) //FIX: add the correct number
    }${categoryToSelect === "applications" ? " SOV no. " + record?.SOVNo : ""}`,
    category: categoryToSelect,
    chargeCategoryFrom: categoryToSelect,
    categoryId: getCategoryId(record, categoryToSelect),
  });

  const bodyToSave = {
    subtotal: calculateSubTotal(invoiceItems),
    invoiceItems,
    totalInvoiceAmount: calculateTotal(invoiceItems),
    invoiceDate: inputData.invoiceDate,
    projectName: project?.[0].projectName,
    projectId: project?.[0].projectId,
    accountId: project?.[0].accountId,
    accountName: project?.[0].accountName,
    fromDate: inputData.invoiceDate,
    toDate: inputData.invoiceDueDate,
    quickBooksNumber: inputData.quickBooksNumber,
    invoiceStatus: "Created",
    invoiceActivity: [
      {
        title: "Created",
        date: inputData.invoiceDate,
        status: true,
        hadData: false,
      },
      {
        title: "Paid",
        date: false,
        status: false,
        hadData: false,
      },
      {
        title: "Deposited",
        date: false,
        status: false,
        hadData: false,
      },
    ],
    editLogs: [],
    lastModifiedBy: {
      date: moment().tz("America/New_York").valueOf(),
      id: userConfiguration.identityId,
      name: userConfiguration.nameOfUser,
    },
    categoriesFrom: [selectedCategory],
  };

  return bodyToSave;
};

export const paymentBodyCreation = async ({ invoice, inputData }) => {
  const account = await filterTables(
    "accounts",
    "accountId",
    invoice?.accountId
  );

  if (!account) throw new Error();

  const currentClientBalance = account?.[0]?.clientBalance || 0;

  const {
    paymentDate,
    paymentMethod,
    paymentAmount: payment,
    qbReferenceNumber,
    wirePostedDate,
    wireNumber,
    checkDate,
    receivedCheckDate,
    recordedCheckDate,
    receivedByCheckDate,
  } = inputData;

  const isCheck = paymentMethod === "Check" || paymentMethod === "e-Check";
  const isWire = paymentMethod === "Wire";

  const paymentAmount = forceToNumber(payment);

  const bodyToSave = {
    accountName: invoice.accountName,
    accountId: invoice.accountId,
    projects: [
      {
        projectName: invoice.projectName,
        projectId: invoice.projectId,
      },
    ],
    paymentMethod,
    paymentDate,
    qbReferenceNumber: qbReferenceNumber || "",
    qbSyncStatus: "",
    qbLastSync: "",
    invoices: [
      {
        invoiceId: invoice.invoiceId,
        invoiceAmount: invoice.totalInvoiceAmount,
        accountName: invoice.accountName,
        invoiceAmountDue: invoice.totalInvoiceAmount,
        invoicePaidPercentage: calculateInvoicePaidPercentage(
          invoice,
          paymentAmount
        ),
        invoiceDescription: getQuickDescription({
          applicableInvoice: invoice,
        }),
        invoicePaidAmount: paymentAmount,
        invoiceNumber: invoice.invoiceNumber,
        invoiceDate: invoice.invoiceDate,
        invoiceChecked: true,
        invoiceTaxRate: invoice.salesTax,
        projectId: invoice.projectId,
        projectAddress: invoice.projectName,
        invoiceCreditedAmount: 0,
      },
    ],
    payments: [
      {
        invoiceNumber: invoice.invoiceNumber,
        invoicePayment: paymentAmount,
      },
    ],
    clientBalance: currentClientBalance,
    paymentMemo: "",
    credits: {},
    paymentAmount: paymentAmount,
    appliedAmount: paymentAmount,
    discounts: {
      discountDate: null,
      discountAmount: 0,
      discountReason: 0,
    },
    currentDueAmount: invoice.totalInvoiceAmount,
    newDueAmount: invoice.totalInvoiceAmount - paymentAmount,
    newCustomerBalance: currentClientBalance - paymentAmount,
    ...(isCheck && {
      checkDates: {
        checkDate,
        receivedBy: receivedByCheckDate,
        receivedDate: receivedCheckDate,
        recordedDate: recordedCheckDate,
      },
    }),
    ...(isWire && {
      wireDetails: {
        wireNumber,
        postedDate: wirePostedDate,
      },
    }),
  };

  return bodyToSave;
};

export const chargeBodyCreation = async ({
  record,
  inputData,
  category,
  lastStep,
  currentRecord,
  applications,
}) => {
  const currentCategory = !!lastStep ? lastStep : category;
  const newCategory = titleFormatter(currentCategory);

  const percentage = forceToNumber(inputData.chargePercentage);

  const allChargeItems =
    currentCategory === "Requisitions"
      ? convertRequisitionIntoChargeItems(currentRecord, applications)
      : record.services.map((service) =>
          validateChargeRentalState(service, percentage, 0)
        );

  const currentData =
    currentCategory === "Requisitions" ? currentRecord : record;

  const newChargeItems =
    currentCategory !== "Requisitions" &&
    addTotalitiesToCharges(allChargeItems);

  const chargeItems =
    currentCategory === "Requisitions" ? allChargeItems : newChargeItems;

  const chargeAmount = chargeItems?.reduce(
    (acc, item) => (acc += item?.price || 0),
    0
  );
  const creditAmount = 0;
  const recordId =
    currentData?.rentalId ||
    currentData?.applicationId ||
    currentData?.estimationId;
  const projectId = currentData?.projectId;
  const projectName =
    currentData?.projectAddress ||
    currentData?.projectName ||
    currentData?.jobSiteAddress;
  const periodTo = record?.periodTo;
  const totalThisPeriod = "";

  const categoryNumber =
    newCategory === "Requisition" ? record.applicationNo : "";

  let bodyToSave = {
    chargeItems,
    chargeAmount,
    appliedAmount: creditAmount,
    creditAmount,
    taxAmount: chargeItems?.reduce(
      (acc, { taxAmount }) => (acc += taxAmount || 0),
      0
    ),
    remainingAmount: 0,
    includedRentals: [],
    chargeType: "Regular",
    categoryFrom: newCategory,
    categoryNumber, //parseInt(selectedCatFrom.split(" ").slice(-1)),
    recordId,
    projectId: projectId,
    projectAddress: projectName,
    ...(newCategory === "Requisition"
      ? {
          periodTo,
          totalThisPeriod,
          creditChargeAmount: 0,
        }
      : {}),
    reqCreateAt: newCategory,
  };

  return bodyToSave;
};

export const requisitionBodyCreation = async ({
  record,
  inputData,
  category,
  projectData,
}) => {
  const creationDate = new Date().getTime();

  const account = await filterTables(
    "accounts",
    "accountId",
    projectData?.accountId
  );

  if (!account) throw new Error();

  const application = {
    projectId: record.projectId,
    applicationStatus: "Under Review",
    applicationNo: 1,
    dueDate: inputData.datePeriod.end,
    projectName: record.projectName || record.projectAddress,
    createdBy: record.createdBy,
    includedRentals: [],
    estimationsSelected: record.estimationsSelected || [record.estimationId],
    notes: [],
    fullyCharged: false,
    accountNameAddress: account?.[0].billingAddress,
    charged: "",
    ...(record.scheduleId && { scheduleId: record.scheduleId }),
    ...(record.rentalId && { rentalId: record.rentalId }),
    totalPrice: record.totalPrice,
    prevTotalLessRetainage: 0,
    accountId: account?.[0].accountId,
    services: record?.services?.map((serviceData) => {
      const { amounts } = serviceData || {};

      return {
        ...serviceData,
        amounts: amountsCalculator({
          checkboxToggle: true,
          amounts,
          service: serviceData,
        }),
      };
    }),
    createdAt: creationDate,
    prevRentalsRetainage: null,
    rentalExcel: {},
    totalities: {
      thisRetainage: 0,
    },
    accountName: account?.[0].accountName,
    accumulatedRentals: {},
    periodTo: {
      start: inputData.datePeriod.start,
      error: false,
      end: inputData.datePeriod.end,
    },
    applicationDateXlsx: creationDate,
  };

  return application;
};

export const safetyInspectionBodyCreation = async ({
  record,
  inputData,
  category,
}) => {
  const {
    accountId,
    createdAt,
    createdBy,
    googleDriveFolderIds,
    googleDriveUpload,
    incidentCategory,
    incidentId,
    incidentName,
    incidentObject,
    lastModifiedBy,
    projectId,
    scheduleId,
    teamsConfiguration,
  } = record;

  const safetyInspection = {
    safetyApplicationObject: incidentObject,
    incidentId,
    safetyName: incidentName,
    safetyApplicationStatus: "Draft",
    projectId,
    accountId,
    scheduleId,
    googleDriveUpload,
    googleDriveFolderIds,
    teamsConfiguration,
    safetyApplicationCategory: incidentCategory,
    createdBy,
    createdAt,
    lastModifiedBy,
  };

  return safetyInspection;
};

export const claimBodyCreation = async ({ record, inputData, category }) => {
  const {
    claimNumber,
    claimType,
    claimant,
    claimantEmployee,
    claimAddress,
    companyName,
    corePMAtTheTime,
    clientAtTimeOfAccident,
    claimDate,
    claimTime,
  } = inputData;

  const isPropertyDamage =
    record?.safetyApplicationCategory === "Property Damage";

  const mandatoryFields = isPropertyDamage
    ? [
        claimNumber,
        claimType,
        claimant,
        claimantEmployee,
        claimAddress,
        companyName,
        corePMAtTheTime,
        clientAtTimeOfAccident,
        claimDate,
        claimTime,
      ]
    : [claimNumber, claimType, claimAddress, claimDate, claimTime];

  const anyFieldEmpty = mandatoryFields.some((field) => !field);

  if (anyFieldEmpty) {
    showErrorMsg({
      content: "Please ensure all mandatory fields are filled out.",
    });
    throw new Error();
  }

  const {
    accountId,
    createdBy,
    googleDriveFolderIds,
    googleDriveUpload,
    safetyApplicationCategory,
    safetyApplicationId,
    safetyApplicationObject,
    safetyName,
    projectId,
    scheduleId,
    teamsConfiguration,
  } = record;

  const latLng = await getLatLngFromAddress(inputData.claimAddress).then(
    (res) => ({ lat: res.lat, lng: res.lng })
  );

  const finalClaim = {
    claimType,
    claimNumber,
    claimCategory: safetyApplicationCategory,
    projectId,
    scheduleId,
    accountId,
    safety: {
      id: safetyApplicationId,
      name: safetyName,
    },
    claimStatus: "Draft",
    claimNote: [{}],
    createdBy,
    claimObject: {
      safetyRecord: safetyName,
      claimant: claimant,
      claimAddress: claimAddress,
      isClaimantAnEmployeeOfCoreOrOfSubcontractor: claimantEmployee,
      assignedTo: safetyApplicationObject?.assignedTo,
      companyName: companyName,
      corePMAtTheTime: corePMAtTheTime,
      typeOfIncident: safetyApplicationCategory,
      clientAtTimeOfAccident: clientAtTimeOfAccident,
      googleDriveUpload,
      claimCoordinates: latLng,
      time: claimTime,
      claimDate: claimDate,
    },
    googleDriveFolderIds,
    teamsConfiguration,
  };

  return finalClaim;
};

export const hearingBodyCreation = async ({ record, inputData }) => {
  const { representative, hearingTime, hearingDate, hearingAddress } =
    inputData;

  if (
    !!!representative ||
    !!!hearingTime ||
    !!!hearingDate ||
    !!!hearingAddress
  ) {
    showErrorMsg({
      content: "Please ensure all mandatory fields are filled out.",
    });
    throw new Error();
  }

  const {
    claimNumber,
    projectId,
    scheduleId,
    accountId,
    createdBy,
    googleDriveFolderIds,
    teamsConfiguration,
    claimId,
    claimCategory,
    claimObject,
    googleDriveUpload,
  } = record;

  const hearingObject = {
    projectId,
    scheduleId,
    accountId,
    hearingStatus: "Draft",
    hearingName: representative,
    claimId,
    hearingCategory: claimCategory,
    createdBy,
    hearingObject: {
      claimNumber,
      hearingAddress,
      datePickerhearingDate: hearingDate,
      assignedTo: claimObject?.assignedTo || [],
      googleDriveUpload,
      time: hearingTime,
      hearingDate,
    },
    googleDriveFolderIds,
    teamsConfiguration,
  };

  return hearingObject;
};

export const contactBodyCreation = async ({ record, inputData }) => {
  // if (true) {
  //   showErrorMsg({
  //     content: "Please ensure all mandatory fields are filled out.",
  //   });
  //   throw new Error();
  // }

  const contactObject = {
    leadId: record?.leadId || "",
    accountId: "" || "",
    contactRecordType: "Lead Contact",
    contactAccountName: record?.leadCompany || "",
    contactTasks: record?.leadTasks || [],
    contactFirstName: record?.leadFirstName || "",
    teamsConfiguration: record?.teamsConfiguration || [],
    contactLastName: record?.leadLastName || "",
    contactEmail: inputData?.contactEmail || record?.leadEmail || "",
    aptNumber: record?.shippingAddApartmentNr || "",
    listOfOpportunitiesIds: [],
    listOfProjectsIds: [],
    contactPhone: record?.leadPhone || "",
    phoneExtension: record?.phoneExtension || "",
    contactMobile: record?.leadMobile || "",
    contactRole: record?.leadRole || "",
    borough: record?.borough || "",
    contactBirthdate: "",
    beveragePreference: "",
    familyDetails: "",
    shippingCoordinates: record?.billingCoordinates || "",
    mailingAddress: record?.leadAddress || "",
    contactNotes: [],
    zipCode: record?.leadZipCode || "",
    createdBy: record?.createdBy || "",
    googleDriveFolderIds: record?.googleDriveFolderIds || {},
    createdAt: new Date().getTime(),
  };

  return contactObject;
};

export const additionalContactBodyCreation = async ({ record, inputData }) => {
  const noAdditionalContact = !areRequiredFieldsPresent(
    inputData,
    generateContactInputFields({ contactRoles: [] })
  );
  if (noAdditionalContact) return;

  const addressInformation = await getAddressInfo(
    inputData.additionalContactShippingAddress
  );

  const { addressInfo, geometry } = addressInformation;

  const { address_components } = addressInfo || {};

  const { location } = geometry || {};

  const additionalContactObject = {
    leadId: record?.leadId || "",
    accountId: "" || "",
    contactRecordType: "Lead Contact",
    contactAccountName: record?.leadCompany || "",
    contactTasks: record?.leadTasks || [],
    contactFirstName: inputData?.additionalContactFirstName || "",
    teamsConfiguration: record?.teamsConfiguration || [],
    contactLastName: inputData?.additionalContactLastName || "",
    contactEmail: inputData?.additionalContactEmail || "",
    aptNumber: record?.shippingAddApartmentNr || "",
    listOfOpportunitiesIds: [],
    listOfProjectsIds: [],
    contactPhone: inputData?.additionalContactPhone || "",
    phoneExtension: inputData?.additionalContactPhoneExtension || "",
    contactMobile: inputData?.additionalContactMobile || "",
    contactRole: inputData?.additionalContactRole || "",
    borough: address_components?.[2]?.long_name || "",
    contactBirthdate: "",
    beveragePreference: "",
    familyDetails: "",
    shippingCoordinates: location || "",
    mailingAddress: inputData?.additionalContactShippingAddress || "",
    contactNotes: [],
    zipCode: address_components?.[7]?.long_name || "",
    createdBy: record?.createdBy || "",
    googleDriveFolderIds: record?.googleDriveFolderIds || {},
    createdAt: new Date().getTime(),
  };

  return additionalContactObject;
};

export const opportunityBodyCreation = async ({
  contact,
  record,
  inputData,
  folders,
  additionalContact,
}) => {
  const { binNumber, dobAddress, dobBlock, dobLot, borough, address } =
    await getDobInformation(inputData.jobSiteAddress);

  const addressInformation = await getAddressInfo(inputData.jobSiteAddress);

  const { addressInfo, geometry, place_id } = addressInformation;

  const { address_components } = addressInfo || {};

  const { location } = geometry || {};

  const mainContact = {
    id: contact?.contactId || "",
    name: `${contact.contactFirstName} ${contact.contactLastName}`,
    email: contact?.contactEmail || "",
    role: [contact?.contactRole || ""],
  };

  const additionalContactInfo = additionalContact
    ? [
        {
          id: additionalContact?.contactId || "",
          name: `${additionalContact.contactFirstName} ${additionalContact.contactLastName}`,
          email: additionalContact?.contactEmail || "",
          role: [additionalContact?.contactRole || ""],
        },
      ]
    : [];

  const opportunityObject = {
    accountId: contact?.accountId || "",
    projectId: contact?.projectId || "",
    leadId: contact?.leadId || "",
    isChangeOrder: false,
    opportunityAddress: inputData.jobSiteAddress,
    teamsConfiguration: contact?.teamsConfiguration || [],
    geoFenceInfo: [],
    opportunityName: inputData.opportunityName,
    dobAddress,
    dobTextArea: "",
    dobBlock,
    dobLot,
    projectManager: [],
    binNumber,
    alternativeAddresses: [],
    totalEstimatesAmount: {},
    opportunityAddCity: borough,
    opportunityAddState: address_components?.[3]?.long_name || "",
    opportunityAddStreet: address,
    estimations: [],
    tasks: [],
    requestReceivedDate: inputData.requestReceivedDate,
    accountName: contact?.contactAccountName,
    bidDueDate: inputData.bidDueDate,
    scopeSheet: "",
    laborRequirement: inputData.laborRequirement,
    insuranceRequirement: inputData.insuranceRequirement,
    opportunityType: "",
    opportunityStage: "New - Not yet started",
    opportunityStatus: "Not Converted",
    primaryContact: mainContact,
    contacts: [mainContact, ...additionalContactInfo],
    contractSignDate: "",
    lossReason: "",
    accountManager: inputData.projectManagers,
    taxExempt: inputData?.opportunityTaxExempt || "Yes",
    taxRate: inputData?.opportunityTaxRate || 0,
    borough,
    proposalSentDate: "",
    proposalStartDate: inputData.proposalStartDate,
    opportunityLatitude: location?.lng,
    opportunityLongitude: location?.lat,
    opportunityPlaceId: place_id,
    neighborRoofProtectionNecessary: "",
    buildingDimensions: "",
    proposedTypeOfWork: [],
    proposedConstructionServices: [],
    workDetail: "",
    opportunitySource: inputData.opportunitySource,
    salesPerson: inputData.salesPerson,
    projectExecutive: inputData.projectExecutive,
    googleDriveFolderIds: folders || {},
    opportunityNotes: [],
    opportunityClient: "",
    googleDriveUploads: [],
    createdBy: contact.createdBy,
    createdAt: new Date().getTime(),
  };

  return opportunityObject;
};

export const documentationBodyCreation = async ({
  record,
  title,
  type,
  folderId,
  inputDocTypes,
}) => {
  const currentTitle = title === "Fleets" ? "Fleet" : title;

  const documentationObject = {
    categoryName: currentTitle,
    recordName: record?.[recordName?.[currentTitle]?.name],
    docType: type,
    docLogs: [
      {
        nameOfUser: record.createdBy,
        updatedAt: new Date().getTime(),
        updatedKeys: [{ label: "Created" }],
      },
    ],
    docObject: inputDocTypes,
    docStatus: "On Hold",
    googleDriveUploads: [],
    expirationDate: "",
    requestedDate: "",
    renewalId: null,
    folderId,
    showThisOn: [],
    teamsConfiguration: [],
    recordId: record?.[recordName?.[currentTitle]?.id],
    relatedDocId: [record?.[recordName?.[currentTitle]?.id]],
  };

  return documentationObject;
};

export const permitDrawingBodyCreation = async ({ record }) => {};

export const taskBodyCreation = async ({
  record,
  formValues,
  category,
  userConfiguration,
  updatedTaskAssignedTo,
}) => {
  if (!formValues?.taskTitle) {
    showErrorMsg({ content: "Please enter a title!" });
    return;
  }
  const customTaskProps = customProps(record, category)?.[category];

  const taskObject = getTaskBody(
    formValues,
    customTaskProps,
    updatedTaskAssignedTo,
    {},
    0,
    [],
    userConfiguration
  );

  return taskObject;
};

export const vehicleIncidentBodyCreation = async ({ record, inputData }) => {
  const {
    incidentAddress,
    incidentReportedBy,
    incidentDate,
    incidentTime,
    incidentDriver,
    incidentInjuredPerson,
    incidentWitnessed,
  } = inputData;

  const driver = await filterTables("drivers", "driverId", incidentDriver);

  const incidentObject = {
    incidentObject: {
      incidentAddress: incidentAddress,
      reportedBy: incidentReportedBy,
      incidentDate: incidentDate,
      incidentTime: incidentTime,
      fleetName: record?.fleetName,
      driverName: driver?.[0].driverName,
      fleetId: record?.fleetId,
      driverId: driver?.[0].driverId,
      incidentReport: [
        {
          category: "Booleans",
          values: [
            {
              type: "radio",
              label: "Was there any injured person",
              id: 2,
              value: incidentInjuredPerson,
            },
            {
              type: "radio",
              label: "Was incident witnessed",
              id: 1,
              value: incidentWitnessed,
            },
          ],
        },
      ],
      incidentNotes: "",
      incidentSignature: "",
      lastModifiedBy: record.lastModifiedBy,
      requestId: "",
    },
    projectId: "",
    scheduleId: "",
    accountId: "",
    incidentName: record?.fleetName,
    googleDriveFolderIds: "",
    googleDriveUpload: [],
    incidentStatus: "New",
    incidentCategory: "Vehicle Damage",
    teamsConfiguration: record.teamsConfiguration,
    createdBy: record.createdBy,
  };

  return incidentObject;
};

export const vehicleViolationBodyCreation = async ({ record, inputData }) => {
  const project = await filterTables(
    "projects",
    "projectName",
    inputData?.projectName
  );

  const amountDue =
    (inputData?.violationLiabilityAmount || 0) +
    (inputData?.violationInterestAmount || 0) +
    (inputData?.violationPenaltyAmount || 0) -
    (inputData?.violationReductionAmount || 0) -
    (inputData?.violationAmountPaid || 0);

  const incidentObject = {
    fleetId: record?.fleetId || "",
    amount_due: amountDue,
    county: "",
    details: "",
    driver: inputData?.violationDriver || "",
    fine_amount: inputData?.violationLiabilityAmount || 0,
    fleetName: record?.fleetName || "",
    interest_amount: inputData?.violationInterestAmount || 0,
    payment_amount: inputData?.violationAmountPaid || 0,
    issue_date: inputData?.violationIssuedDate,
    issuing_agency: "",
    notes: [],
    jobsite: inputData?.violationLocation || "",
    projectId: project?.[0]?.projectId || "",
    license_type: "",
    teamsConfiguration: record?.teamsConfiguration || [],
    violationLocation: inputData?.violationLocation || "",
    isPaid: inputData?.violationPaymentStatus || "No",
    penalty_amount: inputData?.violationPenaltyAmount || 0,
    plate: record?.licensePlate || "",
    precinct: "",
    receivedBy: "",
    receivedDate: inputData?.violationReceivedDate,
    reduction_amount: 100,
    summons_number: inputData?.violationNumber || "",
    summons_image: "",
    violation: inputData?.violation || "",
    violation_status: inputData?.violationStatus || "",
    violation_time: inputData?.violationIssuedDate,
    googleDriveFolderIds: {},
    driveFileId: [],
    createdAt: new Date().getTime(),
  };

  return incidentObject;
};

import { employeeSpreadsheetHeaders } from "./employeeSpreadsheetHeaders";
import * as XLSX from "xlsx";

const capitalize = (s) => s && s[0].toUpperCase() + s.slice(1).toLowerCase();

export const downloadExcelTemplate = (employeeType) => {
  const headers = employeeSpreadsheetHeaders(employeeType);
  const worksheet = XLSX.utils.json_to_sheet([]);
  const workbook = XLSX.utils.book_new();

  XLSX.utils.sheet_add_aoa(worksheet, [headers], { origin: "A1" });
  XLSX.utils.book_append_sheet(workbook, worksheet, "Sheet1");

  const fileName = `${capitalize(employeeType)} Employee Template.xlsx`;
  XLSX.writeFile(workbook, fileName);
};

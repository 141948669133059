/**
 * @typedef LatLngCoords
 * @property {number} latitude
 * @property {longitude} longitude
 */

/**
 * @typedef LatLngPosition
 * @property {number} lat
 * @property {longitude} lng
 */

/**
 * Function that finds an approximated center of a polygon given the points
 * @param {LatLngCoords[]|LatLngPosition[]} points
 */
function findGeofenceCenterCoordinate(points = []) {
  if (!Array.isArray(points)) {
    return null;
  }

  if (points?.length === 1) {
    return {
      lat: points?.[0]?.["lat"] ?? points?.[0]?.["latitude"],
      lng: points?.[0]?.["lng"] ?? points?.[0]?.["longitude"],
    };
  }

  let latMax = null,
    latMin = null,
    lngMax = null,
    lngMin = null;

  let latArr = points
    ?.map(({ latitude = null, lat = null }) => lat || latitude)
    .filter(Boolean);
  let lngArr = points
    ?.map(({ longitude = null, lng = null }) => lng || longitude)
    .filter(Boolean);

  latMax = Math.max(...latArr);
  latMin = Math.min(...latArr);
  lngMax = Math.max(...lngArr);
  lngMin = Math.min(...lngArr);

  return {
    lat: (latMax - latMin) / 2 + (latMin < 0 ? latMax : latMin),
    lng: (lngMax - lngMin) / 2 + (lngMin > 0 ? lngMax : lngMin),
  };
}

export default findGeofenceCenterCoordinate;

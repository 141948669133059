import { useSelector } from "react-redux";
import { Modal, ModalProps, Form, message } from "antd";
import { useRef, useState, useEffect, useContext, useMemo } from "react";

import { entryModalFields } from "../utils";
import PayrollLiveContext from "../PayrollLiveContext";
import {
  XIcon,
  TrashIcon,
} from "src/components/SidebarPages/Communication/assets";
import { EntryType, LocationInfo } from "../payrollLiveTypes";
import { MondayButton } from "src/components/commonComponents";
import {
  StoreType,
  JobsiteType,
  EmployeeType,
} from "src/components/SidebarPages/FleetMaintenanceView/types";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";
import { getCoordinatesAndZip } from "src/components/SidebarPages/Fleet/utils";
import { RenderDynamicComponents } from "src/components/Header/forms/Components";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import { formatDurationDeg } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import PayrollActivityMap from "src/components/pages/Payroll/Tabs/Activity/components/PayrollActivityMap/PayrollActivityMap";

import "./EntryModal.scss";

type Props = ModalProps & {
  editData?: EntryType;
  editDisabled?: boolean;
  onSaveCallback: (data: EntryType) => void;
  onDeleteCallback?: (data: EntryType) => void;
};

function EntryModal(props: Props) {
  const {
    open,
    onCancel,
    editData,
    editDisabled,
    onSaveCallback,
    onDeleteCallback,
  } = props;

  const darkMode = useSelector((store: StoreType) => store.darkMode.isDarkMode);
  const programFields = useSelector(
    (store: StoreType) => store.programFields.programFields
  );
  const allServiceOptions = useSelector(
    (state: StoreType) => state.serviceDefinitions
  );

  const {
    jobsites,
    crewTeams,
    selectedWeekDeg,
    programEmployees,
    controlPanelForm,
  } = useContext(PayrollLiveContext);

  const [distance, setDistance] = useState<number>(0);
  const [duration, setDuration] = useState<number>(0);
  const [changes, setChanges] = useState<boolean>(false);
  const [sowList, setSowList] = useState<Array<string>>([]);
  const [showRoute, setShowRoute] = useState<
    boolean | google.maps.DirectionsResult
  >(false);
  const [position, setPosition] = useState<undefined | LocationInfo>();
  const [selectedJobsite, setSelectedJobsite] = useState<
    undefined | JobsiteType
  >();
  const [selectedEmployee, setSelectedEmployee] = useState<
    undefined | EmployeeType
  >();

  const modalMapRef = useRef(null);

  const [form] = Form.useForm();
  const punchType = Form.useWatch("punchType", form);
  const selectedDate = Form.useWatch("selectedDate", controlPanelForm);

  function onJobsiteSelect(jobsiteId: string) {
    const index = jobsites.findIndex((job) => job.jobsiteId === jobsiteId);
    if (index > -1) {
      setSelectedJobsite(jobsites[index]);
    }

    const empPosition = editData?.punchCoordinates?.lat
      ? editData?.punchCoordinates
      : position?.coordinates;

    const distanceFromJobsite = withinRadius(
      jobsites[index]?.addressPosition,
      empPosition
    )?.distanceInFeet;

    const travelTime = Math.round(distanceFromJobsite / 3.5);
    setDuration(travelTime);
    setDistance(distanceFromJobsite);

    if (
      jobsites?.[index]?.payrollType === "Certified Payroll" ||
      jobsites?.[index]?.payrollType === "Prevailing Wage"
    ) {
      form.setFieldValue(
        "employeeRate",
        jobsites?.[index]?.rates?.[selectedEmployee?.crewPosition] ||
          selectedEmployee?.employeeRate
      );
    }

    if (!position) {
      setPosition({
        coordinates: jobsites?.[index]?.addressPosition,
        formatted_address: jobsites?.[index]?.jobAddress,
      });
      if (!editData?.punchLocation) {
        form.setFieldValue("punchLocation", jobsites?.[index]?.jobAddress);
      }
    }
    setShowRoute(false);
  }

  async function getCoordinates(address: string) {
    const locInfo: LocationInfo = await getCoordinatesAndZip(address);
    setPosition(locInfo);
    if (selectedJobsite && locInfo?.coordinates?.lat) {
      const distanceFromJobsite = withinRadius(
        selectedJobsite?.addressPosition,
        locInfo?.coordinates
      )?.distanceInFeet;

      const travelTime = Math.round(distanceFromJobsite / 3.5);
      setDuration(travelTime);
      setDistance(distanceFromJobsite);
    }
    setShowRoute(false);
  }

  function onClearJobsiteChange(e) {
    if (!e) {
      setSelectedJobsite(null);
      setSowList([]);
      form.setFieldValue("employeeRate", editData?.employeeRate || 0);
    }
  }

  function onClearPositionChange(e) {
    if (!e) {
      setSowList([]);
      setPosition(null);
      setSelectedJobsite(null);
      form.setFieldValue("jobsiteMatch", null);
    }
  }

  function showAllServices() {
    setSowList(allServiceOptions.map(({ serviceName }) => serviceName));
  }

  async function getDirections() {
    if (!!showRoute) {
      setShowRoute(false);
      return;
    }
    const direction = new google.maps.DirectionsService();
    await direction
      .route(
        {
          origin: position?.coordinates?.lat
            ? position.coordinates
            : editData?.punchCoordinates,
          destination: selectedJobsite?.addressPosition,
          travelMode: window.google.maps.TravelMode.DRIVING,
        },
        (directionRes) => {
          message.destroy("directionService");
          setShowRoute(directionRes);
        }
      )
      .catch((err) => {
        message.error({
          content: "Something went wrong while getting directions",
          key: "directionService",
        });
        console.log("Error getting directions: ", err);
      });
  }

  function onEmployeeSelect(id: string) {
    let empIndex = programEmployees?.findIndex((emp) => emp?.crewId === id);
    let selectedEmp = programEmployees?.[empIndex];
    if (!!selectedEmp) {
      const rate = selectedEmp?.employeeRate || 0;
      if (
        selectedJobsite?.payrollType !== "Prevailing Wage" ||
        !selectedJobsite
      ) {
        form.setFieldValue("employeeRate", rate);
      }
      form.setFieldValue("employeeRole", selectedEmp?.crewPosition);

      let company = selectedEmp?.accountName;
      if (company) {
        form.setFieldValue("company", company);
      }

      const team = crewTeams?.find((tm) => {
        const isForeman =
          tm.crewForeman?.crewId === selectedEmp?.crewId ||
          tm?.crewForeman?.employeeId === selectedEmp?.employeeId;
        const isMember =
          (tm?.crewMembers || []).findIndex(
            (mem) =>
              mem?.crewId === selectedEmp?.crewId ||
              mem?.employeeId === selectedEmp?.employeeId
          ) > -1;

        return isForeman || isMember;
      });
      form.setFieldValue("crewTeamName", team?.crewTeamName);
      setSelectedEmployee(selectedEmp);
    }
  }

  function onSave() {
    form.validateFields().then((fieldsData) => {
      let data: EntryType;

      if (editData?.entryId) {
        data = Object.assign(editData, fieldsData);
      } else {
        data = Object.assign({ uploadName: "Manually created" }, fieldsData);
      }

      if (selectedJobsite?.jobsiteId) {
        Object.assign(data, {
          jobsiteId: selectedJobsite?.jobsiteId,
          jobsiteMatch: {
            jobName: selectedJobsite?.jobName,
            jobAddress: selectedJobsite?.jobAddress,
            jobsiteId: selectedJobsite?.jobsiteId,
            services: selectedJobsite?.services || [],
            reimbursement: selectedJobsite?.reimbursement,
          },
        });
      } else if (!!editData?.entryId && !editData.jobsiteId) {
        Object.assign(data, {
          jobsiteId: "",
          jobsiteMatch: {
            jobName: "",
            jobAddress: "",
            jobsiteId: "",
            services: [],
            reimbursement: "",
          },
        });
      }

      if (selectedEmployee) {
        Object.assign(data, {
          employeeFullName: selectedEmployee?.crewName,
          crewId: selectedEmployee?.crewId,
          employeeId: selectedEmployee?.employeeId,
        });
      }

      Object.assign(data, {
        duration,
        degId: selectedWeekDeg?.degId || data?.degId,
        distanceFromJob: distance,
        punchCoordinates: position?.coordinates?.lat
          ? position.coordinates
          : data?.punchCoordinates,
        punchTimeStamp: fieldsData?.punchTime?.valueOf(),
        punchTime: fieldsData.punchTime.format(),
        punchDate: fieldsData?.punchDate?.startOf("d").format(),
      });

      if (onSaveCallback) {
        onSaveCallback(data);
      }
      onCancel(null);
    });
  }

  function onDelete() {
    if (editData) {
      onDeleteCallback(editData);
    }
    onCancel(null);
  }

  const clientConfigs = useMemo(() => {
    if (programFields?.length) {
      let index = programFields.findIndex(
        (field) => field.fieldName === "Payroll Configuration"
      );

      return programFields[index].fieldOptions;
    }
    return [];
  }, [programFields]);

  // #region form fields
  const formFields = useMemo(() => {
    return entryModalFields({
      form,
      sowList,
      editData,
      jobsites,
      punchType,
      clientConfigs,
      getCoordinates,
      position: false,
      onJobsiteSelect,
      showAllServices,
      selectedJobsite,
      selectedEmployee,
      programEmployees,
      onEmployeeSelect,
      onClearJobsiteChange,
      onClearPositionChange,
    });
  }, [
    form,
    sowList,
    editData,
    jobsites,
    punchType,
    clientConfigs,
    selectedJobsite,
    selectedEmployee,
    programEmployees,
  ]);

  const mapComponent = useMemo(() => {
    return (
      <PayrollActivityMap
        ref={modalMapRef}
        directions={showRoute}
        radius={selectedJobsite?.locationRadius}
        defaultAddress={selectedJobsite?.jobAddress}
        geoFenceInfo={selectedJobsite?.geoFenceInfo}
        employeePosition={editData?.punchCoordinates}
        defaultMarker={selectedJobsite?.addressPosition}
      />
    );
  }, [modalMapRef, showRoute, JSON.stringify(selectedJobsite)]);

  // #region useEffects
  useEffect(() => {
    if (typeof editData === "object" && Object.keys(editData)?.length) {
      const jobIndex = jobsites.findIndex(
        (job) => job.jobsiteId === editData?.jobsiteId
      );
      const empIndex = programEmployees.findIndex(
        (emp) => emp?.crewId === editData?.crewId
      );

      setDuration(editData?.duration || 0);
      setSelectedJobsite(jobsites?.[jobIndex]);
      setDistance(editData?.distanceFromJob || 0);
      setSelectedEmployee(programEmployees?.[empIndex]);

      form.setFieldValue("sow", editData?.sow);
      form.setFieldValue("company", editData?.company);
      form.setFieldValue("punchType", editData?.punchType);
      form.setFieldValue("jobsiteMatch", editData?.jobsiteId);
      form.setFieldValue("employeeFullName", editData?.crewId);
      form.setFieldValue("employeeRate", editData?.employeeRate);
      form.setFieldValue("employeeRole", editData?.employeeRole);
      form.setFieldValue("punchLocation", editData?.punchLocation);
      form.setFieldValue("activityStatus", editData?.activityStatus);
      if (!!editData?.punchTime) {
        form.setFieldValue("punchTime", dayjsNY(editData?.punchTime));
      }
      if (editData?.punchDate) {
        form.setFieldValue("punchDate", dayjsNY(editData?.punchDate).startOf());
      } else if (selectedDate) {
        form.setFieldValue("punchDate", selectedDate);
      }
      if (editData?.punchCoordinates?.lat) {
        setPosition({ coordinates: editData?.punchCoordinates });
      }
    } else {
      form.setFieldValue("punchDate", selectedDate);
    }
  }, [editData, jobsites, programEmployees, selectedDate]);

  // #region JSX
  return (
    <Modal
      open={open}
      onCancel={onCancel}
      title={
        editData?.entryId
          ? `${editData?.employeeFullName} Activity`
          : "Add Employee Activity"
      }
      className={`entry-modal ${darkMode ? "entry-modal-dark" : ""}`}
      closable
      centered
      destroyOnClose
      closeIcon={<XIcon />}
      footer={[
        <MondayButton
          onClick={onCancel}
          className="mondayButtonRed"
          Icon={<XIcon />}
        >
          Cancel
        </MondayButton>,
        typeof editData === "object" && onDeleteCallback && !editDisabled ? (
          <MondayButton
            onClick={onDelete}
            className="mondayButtonRed"
            Icon={<TrashIcon />}
          >
            Delete
          </MondayButton>
        ) : null,
        editDisabled ? null : (
          <MondayButton
            onClick={onSave}
            Icon={<TickIcon width={17} height={17} F />}
          >
            Save
          </MondayButton>
        ),
      ]}
    >
      <Form
        form={form}
        className="entry-form-container"
        onFieldsChange={() => !changes && setChanges(true)}
        initialValues={{ activityStatus: "Draft" }}
      >
        {RenderDynamicComponents(formFields, form)}
      </Form>
      <section className="info-map-container">
        <div className="position-details">
          <span onClick={getDirections} className="link-text">
            Show Route
          </span>
          {typeof editData?.distanceFromJob === "number" && (
            <div className="distanceContainer">
              <label className="detail-label">Distance:</label>
              <div>
                {distance > 5280
                  ? (Number(distance / 5280).toFixed(2) || 0) + " miles"
                  : (distance || 0) + " .ft"}
              </div>
              <label className="detail-label">Duration:</label>
              <div>{formatDurationDeg(duration, "seconds").text}</div>
            </div>
          )}
        </div>
        {selectedJobsite?.addressPosition?.lat || position?.coordinates?.lat ? (
          mapComponent
        ) : (
          <div className="modalInfo">Add Jobsite Match or Punch Location.</div>
        )}
      </section>
    </Modal>
  );
}

export default EntryModal;

import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  TABLE_DATA_COLUMN_HOIST_CALCULATOR,
  TABLE_DATA_HOIST_CALCULATOR,
} from "./tableData";
import applyFormulaFromArrayHoist from "./applyFormulaFromArrayHoist";

//SINGLE PRICE SCHEME
const getUnits = ({
  units = 0,
  ppu = 0,
  price = 0,
  rent = 0,
  note = "",
  ...others
}) => {
  return { units, ppu, price, rent, note, ...(others || {}) };
};

export const TABLE_HOIST_REFERENCE_DATA = {
  // First category is the hoist type
  hoistType: {
    options: ["Default"], // to lowercase for comparison
    Default: getUnits({
      units: 1,
      ppu: 70000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinLength: {
    options: [
      `Increase length from 12'9" to 14'6"`,
      `Increase length from 12'9" to 16'4"`,
      `Increase length from 12'9" to 18'1"`,
      `Increase length from 16'4" to 18'1"`,
    ],
    [`Increase length from 12'9" to 14'6"`]: getUnits({
      units: 1,
      ppu: 15000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 12'9" to 16'4"`]: getUnits({
      units: 1,
      ppu: 30000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 12'9" to 18'1"`]: getUnits({
      units: 1,
      ppu: 55000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 16'4" to 18'1"`]: getUnits({
      units: 1,
      ppu: 25000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinHeight: {
    options: [
      `Increase height from 7'6" to 9'2" including taller landing gates at every floor`,
    ],
    [`Increase height from 7'6" to 9'2" including taller landing gates at every floor`]:
      getUnits({
        units: 1,
        ppu: 45000,
        price: 0,
        rent: 0,
        note: "",
      }),
  },
  hoistSpeed: {
    options: [`Increase speed from 175 FPM to 300 FPM`],
    [`Increase speed from 175 FPM to 300 FPM`]: getUnits({
      units: 1,
      ppu: 25000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  liftHeight: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 575,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  location: {
    options: [`Rear`],
    [`Rear`]: getUnits({
      units: 1,
      ppu: 10000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  jumps: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  stops: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 6,
      ppu: 7500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`100'H`]: getUnits({
      units: 6,
      ppu: 8000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`150'H`]: getUnits({
      units: 6,
      ppu: 8500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`200'H`]: getUnits({
      units: 6,
      ppu: 9000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`250'H`]: getUnits({
      units: 6,
      ppu: 9500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`300'H`]: getUnits({
      units: 6,
      ppu: 10000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`350'H`]: getUnits({
      units: 6,
      ppu: 10500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`400'H`]: getUnits({
      units: 6,
      ppu: 11000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
  },
  additionalRentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 1,
      ppu: 7500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`100'H`]: getUnits({
      units: 1,
      ppu: 8000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`150'H`]: getUnits({
      units: 1,
      ppu: 8500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`200'H`]: getUnits({
      units: 1,
      ppu: 9000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`250'H`]: getUnits({
      units: 1,
      ppu: 9500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`300'H`]: getUnits({
      units: 1,
      ppu: 10000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`350'H`]: getUnits({
      units: 1,
      ppu: 10500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`400'H`]: getUnits({
      units: 1,
      ppu: 11000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
  },

  shoringUnderRunback: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6500,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  loadingDock: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 25,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
      // rentRate: 0.05,
      // rentMonths: 6,
      // rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "rentMonths"],
      priceFormula: ["(", "length", "*", "width", "-", 160, ")", "*", "ppu"],
    }),
  },
  winterization: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 3500,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  inserts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 600,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  needleBeamRamp: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rampOnRoof: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  mtaApproval: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  callButtonSystem: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  otherCharges: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  credit: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  discounts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  inclinedRamp: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  steelAngle: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 2500,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  shoring: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6500,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  runbacks: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 625,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
      rentRate: 0.05,
      rentMonths: 6,
      rentRateAfter: 0.5,
      freeMonths: 0,
      rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "rentMonths"],
      priceFormula: [
        "(",
        "ppu",
        "*",
        "units",
        ")",
        "+",
        "(",
        "ppu",
        "*",
        "units",
        "*",
        "rentRate",
        "*",
        "rentMonths",
        ")",
      ],
      rentMonthFormula: ["ppu", "*", "units", "*", "rentRateAfter"],
    }),
  },
  commonPlatform: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 625,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  plywoodEnclosure: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
      // rentRate: 0.5,
      // rentRate: 0.05,
      // rentMonths: 6,
      // rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "rentMonths"],
      // priceFormula: [
      //   "(",
      //   "ppu",
      //   "*",
      //   "units",
      //   ")",
      //   "+",
      //   "(",
      //   "ppu",
      //   "*",
      //   "units",
      //   "*",
      //   "rentRate",
      //   "*",
      //   "rentMonths",
      //   ")",
      // ],
    }),
  },
  steelPlates: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  needleBeamRunback: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  loadingOverParapet: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  // rentalForRunbacks: {
  //   options: [`Default Price`],
  //   [`Default Price`]: getUnits({
  //     units: 6,
  //     ppu: 0,
  //     price: 0,
  //     rent: 0,
  //     note: "",
  //     rentRate: 0.05,
  //   }),
  // },
};
export const TABLE_HOIST_REFERENCE_DATA_DUAL = {
  // First category is the hoist type
  hoistType: {
    options: ["Default"], // to lowercase for comparison
    Default: getUnits({
      units: 1,
      ppu: 100000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinLength: {
    options: [
      `Increase length from 12'9" to 14'6"`,
      `Increase length from 12'9" to 16'4"`,
      `Increase length from 12'9" to 18'1"`,
      `Increase length from 16'4" to 18'1"`,
    ],
    [`Increase length from 12'9" to 14'6"`]: getUnits({
      units: 1,
      ppu: 30000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 12'9" to 16'4"`]: getUnits({
      units: 1,
      ppu: 60000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 12'9" to 18'1"`]: getUnits({
      units: 1,
      ppu: 11000,
      price: 0,
      rent: 0,
      note: "",
    }),
    [`Increase length from 16'4" to 18'1"`]: getUnits({
      units: 1,
      ppu: 50000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  cabinHeight: {
    options: [
      `Increase height from 7'6" to 9'2" including taller landing gates at every floor`,
    ],
    [`Increase height from 7'6" to 9'2" including taller landing gates at every floor`]:
      getUnits({
        units: 1,
        ppu: 90000,
        price: 0,
        rent: 0,
        note: "",
      }),
  },
  hoistSpeed: {
    options: [`Increase speed from 175 FPM to 300 FPM`],
    [`Increase speed from 175 FPM to 300 FPM`]: getUnits({
      units: 1,
      ppu: 50000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  liftHeight: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 575,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  location: {
    options: [`Rear`],
    [`Rear`]: getUnits({
      units: 1,
      ppu: 15000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  jumps: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  stops: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 100,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 6,
      ppu: 13000,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`100'H`]: getUnits({
      units: 6,
      ppu: 13500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`150'H`]: getUnits({
      units: 6,
      ppu: 14500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`200'H`]: getUnits({
      units: 6,
      ppu: 15500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`250'H`]: getUnits({
      units: 6,
      ppu: 16500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`300'H`]: getUnits({
      units: 6,
      ppu: 17500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`350'H`]: getUnits({
      units: 6,
      ppu: 18500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
    [`400'H`]: getUnits({
      units: 6,
      ppu: 19500,
      price: 0,
      rent: 0,
      note: "Standard rental per 4-weeks",
    }),
  },
  additionalRentalForHoist: {
    options: [
      "75'H",
      "100'H",
      "150'H",
      "200'H",
      "250'H",
      "300'H",
      "350'H",
      "400'H",
    ],
    [`75'H`]: getUnits({
      units: 1,
      ppu: 13000,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`100'H`]: getUnits({
      units: 1,
      ppu: 13500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`150'H`]: getUnits({
      units: 1,
      ppu: 14500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`200'H`]: getUnits({
      units: 1,
      ppu: 15500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`250'H`]: getUnits({
      units: 1,
      ppu: 16500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`300'H`]: getUnits({
      units: 1,
      ppu: 17500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`350'H`]: getUnits({
      units: 1,
      ppu: 18500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
    [`400'H`]: getUnits({
      units: 1,
      ppu: 19500,
      price: 0,
      rent: 0,
      note: "Rent After",
    }),
  },
  shoringUnderRunback: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 9000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },

  loadingDock: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 25,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
      // rentRate: 0.05,
      // rentMonths: 6,
      // rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "rentMonths"],
      priceFormula: ["(", "sqft", "-", 160, ")", "*", "ppu"],
    }),
  },

  winterization: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 7000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  inserts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 600,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  needleBeamRamp: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  rampOnRoof: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  mtaApproval: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  callButtonSystem: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  otherCharges: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  credit: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  discounts: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  inclinedRamp: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 12000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  steelAngle: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 2500,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  shoring: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 9000,
      price: 0,
      rent: 0,
      note: "",
    }),
  },
  runbacks: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
      // rentRate: 0.5,
      rentRate: 0.05,
      rentMonths: 6,
      freeMonths: 0,
      rentRateAfter: 0.05,
      rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "freeMonths"],
      priceFormula: [
        "(",
        "ppu",
        "*",
        "units",
        ")",
        "+",
        "(",
        "ppu",
        "*",
        "units",
        "*",
        "rentRate",
        "*",
        "freeMonths",
        ")",
      ],
      rentMonthFormula: ["ppu", "*", "units", "*", "rentRateAfter"],
    }),
  },
  commonPlatform: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 1000,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  plywoodEnclosure: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 6,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
      // rentRate: 0.5,
      // rentRate: 0.05,
      // rentMonths: 6,
      // rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "rentMonths"],
      // priceFormula: [
      //   "(",
      //   "ppu",
      //   "*",
      //   "units",
      //   ")",
      //   "+",
      //   "(",
      //   "ppu",
      //   "*",
      //   "units",
      //   "*",
      //   "rentRate",
      //   "*",
      //   "rentMonths",
      //   ")",
      // ],
    }),
  },
  steelPlates: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  loadingOverParapet: {
    options: [`Default Price`],
    [`Default Price`]: getUnits({
      units: 1,
      ppu: 0,
      price: 0,
      rent: 0,
      note: "",
      isAddon: true,
    }),
  },
  // rentalForRunbacks: {
  //   options: [`Default Price`],
  //   [`Default Price`]: getUnits({
  //     units: 6,
  //     ppu: 0,
  //     price: 0,
  //     rent: 0,
  //     rentMonths: 12,
  //     note: "",
  //     rentRate: 0.05,
  //     rentalFormula: ["ppu", "*", "units", "*", "rentRate", "*", "rentMonths"],
  //   }),
  // },
};

export const getPriceScheme = ({ isDual = false, isAlreadyPicked = false }) => {
  if (isAlreadyPicked) {
    return {};
  }
  return isDual
    ? {
        name: "Default Dual Price Scheme",
        TABLE_HOIST_REFERENCE_DATA: TABLE_HOIST_REFERENCE_DATA_DUAL,
      }
    : { TABLE_HOIST_REFERENCE_DATA, name: "Default Single Price Scheme" };
};

export const mappingOfKeysHoistTable = {
  "Hoist Base Price": "hoistType",
  "Cabin Size (L)": "cabinLength",
  "Cabin Size (H)": "cabinHeight",
  Speed: "hoistSpeed",
  "Lift Height": "liftHeight",
  Location: "location",
  Jumps: "jumps",
  Stops: "stops",
  "Rental for Hoist": "rentalForHoist",
  "Additional Rental for Hoist": "rentalForHoist",
  //////
  Runbacks: "runbacks",
  "Rental for Runbacks": "rentalForRunbacks",
  "Shoring under runbacks": "shoringUnderRunback",
  Shoring: "shoring",
  "Loading Dock": "loadingDock",
  Winterization: "winterization",
  Inserts: "inserts",
  "Needle Beam Ramp": "needleBeamRamp",
  "Ramp on Roof": "rampOnRoof",
  "MTA Approval": "mtaApproval",
  "Call Button System": "callButtonSystem",
  "Other Charges": "otherCharges",
  Credit: "credit",
  Discount: "discounts",
  "Inclined Ramp": "inclinedRamp",
  "Steel Angle": "steelAngle",
  "Plywood Enclosure": "plywoodEnclosure",
  "Common Platform": "commonPlatform",
  "Steel Plates": "steelPlates",
  "Needle Beam Runback": "needleBeamRunback",
  ///
};

// keys to keep in mind, price, totalPrice, taxRate, taxAmount
export const autoFillHoist = ({ hoistData = {}, savedData }) => {
  // we need to check multiple things
  //1. Check type of the hoist first
  let referenceScheme = null;
  let rowData = [];
  let hoistType = hoistData?.type || "";

  console.log("savedData", { savedData });

  if (hoistType === "dual") {
    referenceScheme = TABLE_HOIST_REFERENCE_DATA_DUAL;
  } else {
    referenceScheme = TABLE_HOIST_REFERENCE_DATA;
  }

  let applicableColumns = TABLE_DATA_HOIST_CALCULATOR;

  if (applicableColumns.length > 0) {
    applicableColumns.forEach((column) => {
      let name = column?.name || "";
      switch (name) {
        case "Hoist Base Price": {
          let hoistValueObj = referenceScheme?.hoistType?.Default || {};
          pushHoistValue(rowData, column, hoistValueObj, "Default");
          break;
        }
        case "Cabin Size (L)": {
          let hoistValueObj;
          let entityValue =
            findHoistValue({
              hoistData,
              key: "length",
            })?.replace("/", "") + `"`;
          if (checkIfBetweenIntervalHeight(entityValue, [`14'6"`, `14'6"`])) {
            entityValue = `Increase length from 12'9" to 14'6"`;
          } else if (
            checkIfBetweenIntervalHeight(entityValue, [`16'4"`, `16'4"`])
          ) {
            entityValue = `Increase length from 12'9" to 16'4"`;
          } else if (
            checkIfBetweenIntervalHeight(entityValue, [`16'4"`, `16'4"`])
          ) {
            entityValue = `Increase length from 12'9" to 16'4"`;
          } else if (
            checkIfBetweenIntervalHeight(entityValue, [`18'1"`, `18'1"`])
          ) {
            entityValue = `Increase length from 16'4" to 18'1"`;
          } else {
            pushHoistValue(rowData, column, {});
            break;
          }

          hoistValueObj = referenceScheme?.cabinLength?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        case "Cabin Size (H)": {
          let hoistValueObj;
          let entityValue =
            findHoistValue({
              hoistData,
              key: "height",
            })?.replace("/", "") + `"`;

          if (checkIfBetweenIntervalHeight(entityValue, [`9'2"`, `9'2"`])) {
            entityValue = `Increase height from 7'6" to 9'2" including taller landing gates at every floor`;
          } else {
            pushHoistValue(rowData, column, {});
            break;
          }

          hoistValueObj = referenceScheme?.cabinHeight?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        // case "Cabin Size (H)": {
        //   let hoistValueObj;
        //   let entityValue =
        //     findHoistValue({
        //       hoistData,
        //       key: "length",
        //     })?.replace("/", "") + `"`;
        //   if (checkIfBetweenIntervalHeight(entityValue, [`14'6"`, `14'6"`])) {
        //     entityValue = `Increase length from 12'9" to 14'6"`;
        //   } else if (
        //     checkIfBetweenIntervalHeight(entityValue, [`16'4"`, `16'4"`])
        //   ) {
        //     entityValue = `Increase length from 12'9" to 16'4"`;
        //   } else if (
        //     checkIfBetweenIntervalHeight(entityValue, [`18'1"`, `18'1"`])
        //   ) {
        //     entityValue = `Increase length from 16'4" to 18'1"`;
        //   } else {
        //     pushHoistValue(rowData, column, {});
        //     break;
        //   }

        //   hoistValueObj = referenceScheme?.cabinLength?.[entityValue] || {};
        //   pushHoistValue(rowData, column, hoistValueObj, entityValue);
        //   break;
        // }
        case "Speed": {
          let hoistValueObj;
          let entityValue = findHoistValue({
            hoistData,
            key: "speed",
          });
          if (checkIfBetweenInterval(entityValue, [300, 500])) {
            entityValue = `Increase speed from 175 FPM to 300 FPM`;
          } else {
            pushHoistValue(rowData, column, {});
            break;
          }

          hoistValueObj = referenceScheme?.hoistSpeed?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        case "Lift Height": {
          let hoistValueObj;
          let entityValue = "Default Price";
          console.log("referenceScheme", { hoistData, referenceScheme });
          hoistValueObj = referenceScheme?.liftHeight?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: forceToNumber(
              findHoistValue({
                hoistData,
                key: "lift_height",
              })
            ),
          });
          break;
        }
        case "Location": {
          let hoistValueObj = {};
          let entityValue = findHoistValue({
            hoistData,
            key: "location",
          });
          hoistValueObj = referenceScheme?.location?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        case "Stops": {
          let hoistValueObj = {};
          let entityValue = forceToNumber(
            hoistData?.items?.filter((r) => r?.stop === true)?.length || 0
          );
          console.log("hoist data", { hoistData });
          hoistValueObj = referenceScheme?.location?.[entityValue] || {};
          pushHoistValue(
            rowData,
            column,
            { ...hoistValueObj, units: entityValue },
            `${entityValue} Stops`
          );
          break;
        }
        case "Jumps": {
          let hoistValueObj = {};
          let entityValue = forceToNumber(hoistData?.jumps?.length || 0);
          console.log("hoist data", { hoistData });
          hoistValueObj = referenceScheme?.location?.[entityValue] || {};
          pushHoistValue(
            rowData,
            column,
            { ...hoistValueObj, units: entityValue },
            `${entityValue} Stops`
          );
          break;
        }
        case "Rental for Hoist": {
          let hoistValueObj = {};
          let entityValue =
            findHoistValue({
              hoistData,
              key: "lift_height",
            }) + `'H`;
          hoistValueObj = referenceScheme?.rentalForHoist?.[entityValue] || {};
          pushHoistValue(rowData, column, entityValue, entityValue);
          break;
        }
        case "Additional Rental for Hoist": {
          let hoistValueObj = {};
          let entityValue =
            findHoistValue({
              hoistData,
              key: "lift_height",
            }) + `'H`;
          hoistValueObj =
            referenceScheme?.additionalRentalForHoist?.[entityValue] || {};
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }
        ///TODO: Formula: Unit Price x Number of Floors x Number of Monthly Rental x Unit Price
        case "Call Button System": {
          console.log("rowData", { rowData });
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistValue({
            hoistData,
            key: "noOfFloors",
          });
          hoistValueObj =
            referenceScheme?.callButtonSystem?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          pushHoistValue(rowData, column, hoistValueObj, entityValue);
          break;
        }

        // this is where addons starts
        // case "Common Platform": {
        //   break;
        // }
        case "Shoring under runbacks": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Shoring under runbacks",
          });
          console.log("floors", { floors });
          hoistValueObj =
            referenceScheme?.shoringUnderRunback?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        case "Inclined Ramp": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Inclined Ramp",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.inclinedRamp?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }

        case "Steel Angle": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Steel Angle",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.steelAngle?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        case "Shoring": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Shoring",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.shoring?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        case "Runbacks": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findRunbacksUnits({
            floors: hoistData?.items || [],
            name: "Runbacks",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.runbacks?.[entityValue] || {};
          let price = forceToNumber(hoistValueObj?.ppu * floors);
          let rentPrice = applyFormulaFromArrayHoist(
            hoistValueObj?.rentalFormula || [],
            { ...hoistValueObj, ppu: price, units: floors }
          );
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
            // note: "(Unit Price x F) + (Unit Price x F x 0.05 x rentMonths)",
            // price: price + forceToNumber(rentPrice),
            hasCustomFormula: false,
          });
          break;
        }
        case "Loading Dock": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findSqft({
            floors: hoistData?.items || [],
            name: "Loading Dock",
            dimensions: ["length", "width"],
          });
          console.log("loadingDockFloors", { floors });
          hoistValueObj = referenceScheme?.loadingDock?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            // units: forceToNumber(floors > 160 ? floors - 160 : 0),
            sqft: forceToNumber(floors),
          });
          break;
        }
        case "Plywood Enclosure": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findSqft({
            floors: hoistData?.items || [],
            name: "Plywood Enclosure",
            dimensions: ["length"],
          });
          console.log("loadingDockFloorsplywood", { floors });
          hoistValueObj =
            referenceScheme?.plywoodEnclosure?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: forceToNumber(floors),
          });
          break;
        }
        case "Common Platform": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findSqft({
            floors: hoistData?.items || [],
            name: "Common Platform",
            dimensions: ["length"],
          });
          console.log("loadingDockFloors", { floors });
          hoistValueObj = referenceScheme?.commonPlatform?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: forceToNumber(floors),
          });
          break;
        }
        case "Plywood Enclosure": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Plywood Enclosure",
          });
          console.log("loadingDockFloors", { floors });
          hoistValueObj =
            referenceScheme?.plywoodEnclosure?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: forceToNumber(floors),
          });
          break;
        }
        case "Steel Plates": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Steel Plates",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.steelPlates?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }

        case "Needle Beam Runback": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Needle Beam Runback",
          });
          console.log("floors", { floors });
          hoistValueObj =
            referenceScheme?.needleBeamRunback?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }

        case "Needle Beam Runback": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Needle Beam Runback",
          });
          console.log("floors", { floors });
          hoistValueObj =
            referenceScheme?.needleBeamRunback?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        case "Landing over parapet with a ramp": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Landing over parapet with a ramp",
          });
          console.log("floors", { floors });
          hoistValueObj =
            referenceScheme?.loadingOverParapet?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        case "Winterization": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "Winterization",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.winterization?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        case "MTA Approval": {
          let hoistValueObj = {};
          let entityValue = "Default Price";
          let floors = findHoistAddonsCount({
            floors: hoistData?.items || [],
            name: "MTA Approval",
          });
          console.log("floors", { floors });
          hoistValueObj = referenceScheme?.mtaApproval?.[entityValue] || {};
          hoistValueObj.units = forceToNumber(floors);
          console.log("hoistValueObj", { hoistValueObj });
          pushHoistValue(rowData, column, hoistValueObj, entityValue, {
            units: floors,
          });
          break;
        }
        default: {
          pushHoistValue(rowData, column, {});
          return { ...(column || {}) };
        }
      }
    });
  }
  console.log("rowData", { rowData, hoistData });
  return rowData
    ?.filter((row) => {
      if (row.isAddon) {
        let floors = hoistData?.items || [];
        return checkIfAddonIsPresent({ floors, name: row?.name });
      }
      return true;
    })
    .map((e) => {
      let PRICE = e?.hasCustomFormula
        ? forceToNumber(e?.price)
        : forceToNumber(e?.ppu) * forceToNumber(e?.units);
      let TAX = PRICE * forceToNumber(e?.taxRate);
      let RENT = forceToNumber(e?.rent);
      let additionalRent = forceToNumber(e?.additionalRent);
      let TOTAL_PRICE =
        forceToNumber(PRICE) +
        forceToNumber(TAX) +
        forceToNumber(additionalRent);
      return {
        ...e,
        totalPrice: TOTAL_PRICE,
        tax: TAX,
        additionalRent,
        price: PRICE,
        rent: RENT,
        addonRows: [{ id: 3, name: "Test" }],
      };
    });
};

const findHoistValue = ({ hoistData = {}, key = "" }) => {
  return hoistData?.[key] || "";
};

const findHoistAddonsCount = ({ floors = [], name }) => {
  let count = 0;
  console.log("floors", { floors });
  floors.forEach((floor) => {
    if (Array.isArray(floor?.addons)) {
      count += forceToNumber(
        floor?.addons?.filter(
          (r) => r?.addonname?.toString() === name?.toString()
        )?.length || 0
      );
    }
  });
  return count;
};
const findRunbacksUnits = ({ floors = [], name }) => {
  let count = 0;
  console.log("floors", { floors });
  floors.forEach((floor) => {
    if (Array.isArray(floor?.addons)) {
      floor?.addons
        ?.filter((r) => r?.addonname?.toString() === name?.toString())
        .forEach((e) => {
          console.log("ledjoeee", e);
          count +=
            forceToNumber(e?.depth || 0) < 8 ? 8 : forceToNumber(e?.depth || 0);
        });
    }
  });
  return count;
};
const findSqft = ({ floors = [], name, dimensions = [] }) => {
  let count = 0;
  console.log("floors", { floors });
  floors.forEach((floor) => {
    if (Array.isArray(floor?.addons)) {
      floor?.addons
        ?.filter((r) => r?.addonname?.toString() === name?.toString())
        .forEach((e) => {
          console.log("ledjoeee", e);
          let sqft;
          let arr = [];
          dimensions.forEach((dimension) => {
            arr.push(forceToNumber(e?.[dimension] || 0));
          });
          sqft = arr.reduce((a, b) => a * b, 1);
          count += forceToNumber(sqft);
        });
    }
  });
  return count;
};
const pushHoistValue = (
  rowData,
  original,
  toPush,
  entityValue = "",
  otherValues = {}
) => {
  rowData.push({ ...original, ...toPush, entityValue, ...otherValues });
  return;
};

const convertHeightToInches = (height = "") => {
  try {
    let [feet, inches] = height.split("'");
    return parseInt(feet) * 12 + parseInt(inches);
  } catch (e) {
    return 0;
  }
};

const checkIfBetweenIntervalHeight = (
  height = 0,
  interval = [(start = 0), (end = 0)]
) => {
  let heightInInches = convertHeightToInches(height);
  let intervalInInches = interval.map((e) => convertHeightToInches(e));

  return (
    heightInInches >= intervalInInches[0] &&
    heightInInches <= intervalInInches[1]
  );
};

const checkIfBetweenInterval = (
  value = 0,
  interval = [(start = 0), (end = 0)]
) => {
  let tempVal = forceToNumber(value);
  return tempVal >= interval[0] && tempVal <= interval[1];
};

const checkIfAddonIsPresent = ({ floors = [], name }) => {
  if (Array.isArray(floors)) {
    const hasAddon = floors.some((floor) =>
      floor.addons?.some((r) => r?.addonname?.toString() === name)
    );

    console.log("checkIfAddonIsPresent", { floors, name, hasAddon });
    return hasAddon;
  }
  return false;
};

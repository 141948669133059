import { chartJsBackgroundColor, chartJsBorderColor } from "./constants";

export const getParams = ({
  data,
  chartData,
  chartKey,
  label,
  text,
  type = "Count",
}) => {
  return {
    type: "bar",
    typeOfChart: type,
    data: {
      labels: Array.from(chartData),
      datasets: [
        {
          label,
          data: Array.from(chartData).map(
            (item) => data.filter((d) => d[chartKey] === item).length
          ),
          backgroundColor: chartJsBackgroundColor,
          borderColor: chartJsBorderColor,
          hoverOffset: 4,
        },
      ],
    },
    config: {
      plugins: {
        title: {
          display: true,
          text,
        },
      },
      responsive: true,
    },
  };
};

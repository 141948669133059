import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import customParseFormat from "dayjs/plugin/customParseFormat";

import {
  parsedCell,
  getCellName,
  getNextColumn,
  getIndexFromCell,
  getColumnFromCell,
} from "./cellFunctions";
import { toCamelCase } from "../../../../../../Settings/settingsComponents/ApprovalsDynamicForms/FormFieldModals/newFieldModal";

dayjs.extend(customParseFormat);

export const EMPTY_ROW = {
  entryId: "",
  uploadId: "",
  geofence: "",
  punchDate: "",
  punchTime: "",
  punchType: "",
  employeeId: "",
  uploadName: "",
  companyName: "",
  punchLocation: "",
  employeeFullName: "",
  punchCoordinates: "",
  activityStatus: "Draft",
};

const defaultDegColumns = [
  "employee id",
  "employee full name",
  "companyName",
  "entry id",
  "geofence",
  "punch coordinates",
  "punch date",
  "punch location",
  "punch time",
  "upload id",
  "upload name",
];

/**
 * Function that parses the DEG sheet of an uploaded Excel
 * @param {Object} deg The DEG sheet object
 * @param {File} fileData Additional file data to be put in the row data
 */
function degParser(deg = {}, fileData) {
  /**
   * The idea is to search for the index of the header by finding
   * a cell that has the content of a known header.
   *
   * We find the total number of columns by iterating the header index until
   * we have an empty cell
   *
   * We can find the total number of rows by looking at how many
   * cells have the same column name as the previously found header.
   *
   * Then we simply iterate all the data and turn the header names
   * in camel case to form the new row data
   */
  let headerRowIndex = -1;
  let numOfValidColumns = 0;
  let totalNumOfRows = 0;
  let headerStartColumn = "";
  let headerEndColumn = "";
  let nameCol = "";
  let parsedData = [];

  for (const cell in deg) {
    let cellContent = parsedCell(deg[cell]?.w);
    if (cellContent?.toLowerCase()?.includes("employee id")) {
      headerRowIndex = getIndexFromCell(cell);
      nameCol = getColumnFromCell(cell);
      break;
    }
  }

  //if the data is invalid and there is no header
  if (headerRowIndex === -1) {
    return parsedData;
  }

  //we get the start and end columns to create a range
  let lastCheckedColumn = "";
  while (!headerStartColumn) {
    let col = getNextColumn(lastCheckedColumn);
    let cellName = getCellName(col, headerRowIndex);
    //in order to get the column we can check the default columns or simply write
    //one of the columns that we know is present
    if (
      defaultDegColumns.includes(
        parsedCell(deg[cellName]?.w).toLowerCase()?.trim()
      )
      // parsedCell(deg[cellName]?.w) === "EE Code"
    ) {
      headerStartColumn = col;
      break;
    } else if (parsedCell(deg[cellName]?.w).toLowerCase() === "#value!") {
      headerStartColumn = col;
    } else {
      lastCheckedColumn = col;
    }
  }

  while (!headerEndColumn) {
    let col = getNextColumn(lastCheckedColumn);
    let cellName = getCellName(col, headerRowIndex);

    if (!parsedCell(deg[cellName]?.w)) {
      headerEndColumn = lastCheckedColumn;
      break;
    } else {
      ++numOfValidColumns;
      lastCheckedColumn = col;
    }
  }

  //we get the total number of rows by getting the maximum number of row that have a name
  //we check for the name field since the other ones may not be yet completed
  for (const cell in deg) {
    if (getColumnFromCell(cell) === nameCol) {
      ++totalNumOfRows;
    }
  }

  for (let i = 1; i <= totalNumOfRows; i++) {
    let tmpData = {};
    let rowIndexToCheck = headerRowIndex + i;
    let dataColumn = headerStartColumn;

    for (let j = 0; j < numOfValidColumns; j++) {
      let cellName = getCellName(dataColumn, rowIndexToCheck);
      let key = toCamelCase(
        parsedCell(
          deg[getCellName(dataColumn, headerRowIndex)]?.w
        )?.toLowerCase()
      );

      tmpData[key] = parsedCell(deg[cellName]?.w);
      dataColumn = getNextColumn(dataColumn);
    }

    let entryId = uuidv4();
    parsedData.push({
      ...tmpData,
      entryId,
      uploadId: fileData?.uid,
      uploadName: fileData?.name.replace(".xlsx", "")?.trim(),
    });
  }

  return {
    parsedData,
    // totalHoursPerEmployee,
  };
}

export default degParser;

import { message } from "antd";
import { API } from "aws-amplify";
import axios from "axios";
import * as FileSaver from "file-saver";
import { b64toBlob } from "../../../../../../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";
export async function exportRentalsToXLSX({ projectName, rentalData }) {
  // console.log("name", name, "accountName", accountName, "result", result);
  // let temp = result?.applicationData?.workSheet1DataExcel || [];
  // let services = getServicesName(
  //   result?.applicationData?.workSheet2DataExcel?.map((item) => item?.label)
  // );
  // let fileName = !!temp?.applicationNo
  //   ? name.replace(/\W+/g, " ") +
  //     ` Req#${temp?.applicationNo} ${temp?.periodTo} ` +
  //     ".xlsx"
  //   : name + ".xlsx";
  let fileName = "RENTAL HISTORY FOR " + projectName + ".xlsx";
  const hideLoad = message.loading("Exporting to Excel...", 0);
  await API.post("exportRentals", "/exportRentals", {
    body: {
      projectName,
      rentalData,
    },
  })
    .then((response) => {
      let blob = b64toBlob(
        response.body,
        "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
      );
      FileSaver.saveAs(blob, fileName);
      hideLoad();
      message.success("Export successful.");
    })
    .catch((err) => {
      hideLoad();
      message.error("Something went wrong...");
    });
  return;
  const url =
    "https://leadmanager-express-backend.ue.r.appspot.com/api/export/xlsx";
  // console.log("temp", {
  // 	accountName: accountName,
  // 	name: name,
  // 	apiKey: "5e550158-dc8c-4814-b8d3-db8a8341bf88",
  // 	...result,
  // });
  //   const url = "http://localhost:8080/api/export/xlsx";
  //   const url = "http://10.0.0.85:8080/api/export/xlsx";
  const hideLoading = message.loading(
    "File is being processed. Please wait...",
    0
  );
  axios({
    method: "post",
    url,
    headers: {
      "Content-Type": "application/json",
    },
    responseType: "blob",
    data: JSON.stringify({
      projectName,
      apiKey: "ff9caf9b-bec2-4c52-a17f-5cf2f090e929",
      rentalData,
      // ...result,
    }),
  })
    .then((response) => {
      hideLoading();
      console.log("response", response);
      FileSaver.saveAs(response.data, fileName);
      message.success("Export successful.");
    })
    .catch((err) => {
      console.log("my error", err);
      hideLoading();
      console.error(err);
      message.error("Something went wrong...");
    });
}

import { useState } from "react";
import { Popover } from "antd";
import { MoreOutlined } from "@ant-design/icons";
import { useResponsive } from "../../../hooks";
import "./ResponsiveHeaderButtons.scss";

const ResponsiveHeaderButtons = ({ headerButtons }) => {
  const [open, setOpen] = useState(false);
  const { mobile } = useResponsive();

  const content = () => (
    <div className="mobile-header-buttons">{headerButtons(setOpen)}</div>
  );

  return (
    <>
      {!mobile ? (
        headerButtons()
      ) : (
        <Popover
          content={content}
          trigger="click"
          open={open}
          onOpenChange={(newOpen) => setOpen(newOpen)}
        >
          <MoreOutlined style={{ fontSize: "24px", fontWeight: "700" }} />
        </Popover>
      )}
    </>
  );
};

export default ResponsiveHeaderButtons;

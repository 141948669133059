import { fetchData } from "../../components/SidebarPages/Fleet/utils";
import { formatDate } from "./formatUtils";

export const getFleetWorkOrders = async () => {
  try {
    const workOrders = await fetchData("workOrders");

    return workOrders.map((order) => {
      let items = order.workOrderItems
        .map((item) => item.defectName)
        .join(" :: ");
      let lastOdometerReading =
        order.workOrderItems.length > 0
          ? order.workOrderItems[order.workOrderItems.length - 1]
              .odometerReading
          : "N/A";
      return {
        ...order,
        createdAt: formatDate(order.createdAt),
        dueDate: order.dueDate,
        mechanic: order.mechanicInfo.mechanic,
        fleetName:
          order.fleetName.toLowerCase().includes("truck") ||
          order.fleetName.toLowerCase().includes("trailer")
            ? order.fleetName.replace(/\D/g, "")
            : order.fleetName,
        workOrderItems: items,
        odometer: parseInt(lastOdometerReading),
        schedule: "Corrective",
      };
    });
  } catch (error) {
    console.error("data source function getFleetWorkOrders error: " + error);
  }
};

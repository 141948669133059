import { Badge, Modal, Tooltip, message } from "antd";
import React, { useEffect, useRef, useState } from "react";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { XIcon } from "../../../../../../SidebarPages/Communication/assets";
import "./BreakdownsSelectedDays.scss";
import { AnimatedCheckBox } from "../../../../../../SidebarPages/Fleet/components/InputComponent/components";
import {
  breakInspProgress,
  onlyAppliedBreakdowns,
  progressInPerc,
  sumProgressByDay,
  swalToast,
  typeOfWorkTexts,
  upperCaseFirstLetter,
} from "../../../SchedulingFirstPage/helperData";
import { Button, Input } from "antd";
import {
  NextIcon,
  PercentageIcon,
  PreviousIcon,
} from "../../../../../../SidebarPages/BasePage/src";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";
import { breakdown_dimension } from "./breakdown";
import { InfoIconBlue } from "../../../Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";
import { updateDocumentTitle } from "../../../../../../../utils";
import { SchedulingWhite } from "../../../../../../../icons";
import { useSelector } from "react-redux";
import { CrewsIcon } from "../../../../../../../assets";
import { fetchProgressChangesForemenFiles } from "../../../../../../pages/ScheduleProgress/Components/ForemanProject/ProgressChanges/fetchProgressChangesForemenFiles";
import { driveApi } from "../../../../../../../integrations/DriveRequest";
import { DocUploader } from "../../../../../../SidebarPages/Documentation/View/components/DocumentationModal/components";
const BreakdownsSelectedDays = ({
  onEmptySpecificBreakdown,
  breakVisible,
  setBreakVisible,
  scheduleDays,
  breakdownValue,
  setBreakdownValue,
  specificBreakdown,
  origin,
  typeOfWork,
  totalProgressPli,
  isHoist,
  d1,
  d2,
  pli,
  editMode,
  isDarkMode,
  context,
  updateBreakdownLogs,
  ...rest
}) => {
  const { service, elevation } = context || {};
  const { elevationLabel, elevationId } = elevation || {};
  const availableScheduleDays =
    scheduleDays?.filter(
      (el) =>
        el?.status !== "Canceled" &&
        el?.status !== "Postponed" &&
        el?.status !== "Skipped"
    ) || [];

  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { list } = driveApi({ accessToken });
  const [filesOfDays, setFilesOfDays] = useState({});

  const [selectedValues, setSelectedValues] = useState(
    specificBreakdown?.progressByDay || []
  );

  const [openUploadsModal, setOpenUploadsModal] = useState(false);
  const [lnf, setLnf] = useState({});
  const [step, setStep] = useState(0);

  let objForHoist = {};

  let infoData = {
    Name: specificBreakdown?.name || "",
    Rate: Number(specificBreakdown?.rate || 0) + " %",
    [`Currently ${typeOfWorkTexts[typeOfWork]}`]:
      sumProgressByDay(specificBreakdown?.progressByDay) + "%",
    "To be scheduled":
      100 - sumProgressByDay(specificBreakdown?.progressByDay) + "%",
  };
  Object.entries(infoData)?.map(([key, value]) => {
    objForHoist[key] = value;
    delete objForHoist["Rate"];
  });
  infoData = isHoist ? objForHoist : infoData;

  const selectedDaysOnChange = (e, el) => {
    if (!!e) {
      setSelectedValues((prev) => [
        ...prev,
        {
          day: el?.id,
          breakdown_dimension: 0,
        },
      ]);
    } else {
      setSelectedValues((prev) => [...prev?.filter((a) => a?.day !== el?.id)]);
    }
  };

  const onChangePercentage = (e, el) => {
    if (!editMode) {
      swalToast({
        icon: "error",
        title: "Available only in edit mode!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    }
    const selectedValuesCopy = [...selectedValues];
    let editingBreakdown = selectedValuesCopy?.find((b) => b?.day === el?.day);
    let val = Number(e?.target?.value);
    if (isNaN(val)) {
      message.error("Please enter a valid number!");
      return;
    }
    if (
      sumProgressByDay(
        selectedValues?.map((a, i) => {
          if (a?.day === el?.day) {
            return {
              ...a,
              breakdown_dimension: Number(val),
            };
          }
          return a;
        })
      ) > 100
    ) {
      message.error("Progress cannot be more than 100%!");
      return;
    }

    editingBreakdown.breakdown_dimension = val;
    editingBreakdown.changed =
      specificBreakdown?.progressByDay?.find((a) => a?.day === el?.day)
        ?.breakdown_dimension !== val;
    setLnf({
      ...lnf,
      [el?.day]:
        (!d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2]) *
        (Number(specificBreakdown?.rate) / 100) *
        (editingBreakdown?.breakdown_dimension / 100),
    });

    setSelectedValues(selectedValuesCopy);
  };

  const onChangeLnf = (e, el) => {
    if (!editMode) {
      swalToast({
        icon: "error",
        title: "Available only in edit mode!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    }
    const selectedValuesCopy = [...selectedValues];
    let editingBreakdown = selectedValuesCopy?.find((b) => b?.day === el?.day);
    let val = Number(e?.target?.value);
    if (isNaN(val)) {
      message.error("Please enter a valid number!");
      return;
    }
    const progressByDayInPerc = (lnf, pli, rate) => {
      return (lnf / ((pli || 0) * (rate / 100))) * 100;
    };
    if (
      progressByDayInPerc(
        val,
        !d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2],
        Number(specificBreakdown?.rate)
      ) > 100
    ) {
      message.error("Progress cannot be more than 100%!");
      return;
    }
    editingBreakdown.breakdown_dimension = progressByDayInPerc(
      val,
      !d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2],
      Number(specificBreakdown?.rate)
    );
    editingBreakdown.changed = true;
    setLnf({
      ...lnf,
      [el?.day]: val,
    });
    setSelectedValues(selectedValuesCopy);
  };

  const autoComplete = (el) => {
    if (!editMode) {
      swalToast({
        icon: "error",
        title: "Available only in edit mode!",
        position: "bottom-end",
        showConfirmButton: false,
        timer: 4000,
      });
      return;
    }
    const selectedValuesCopy = [...selectedValues];
    let editingBreakdown = selectedValuesCopy?.find((b) => b?.day === el?.day);
    const sum = sumProgressByDay(selectedValues);
    editingBreakdown.breakdown_dimension = 100 - sum;
    editingBreakdown.changed = true;
    setLnf({
      ...lnf,
      [el?.day]:
        (!d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2]) *
        (Number(specificBreakdown?.rate) / 100) *
        (editingBreakdown?.breakdown_dimension / 100),
    });
    setSelectedValues(selectedValuesCopy);
  };

  const saveBreakdowns = () => {
    let breakdownValueCopy = [...breakdownValue];
    const editingBreakdown = breakdownValue?.find(
      (b) => b?.id === specificBreakdown?.id
    );
    editingBreakdown.days = [
      ...new Set(selectedValues?.map((el) => el?.day)?.filter(Boolean)),
    ];
    editingBreakdown.progressByDay = selectedValues || [];
    setBreakdownValue(breakdownValueCopy);

    updateBreakdownLogs(
      Array.isArray(selectedValues) && selectedValues?.length > 0
        ? selectedValues
            ?.map((day) => {
              if (!!day?.changed) {
                const findDay = scheduleDays?.find((p) => p?.id === day?.day);
                const pliProgress = Math.round(
                  onlyAppliedBreakdowns(breakdownValueCopy || [])
                    ?.map?.(({ progressByDay = [], rate = 0 }) =>
                      breakInspProgress(typeOfWork, progressByDay, rate)
                    )
                    ?.reduce?.((a, b) => a + b, 0) *
                    (d2 === undefined
                      ? pli?.[d1] / 100
                      : (pli?.[d1] * pli?.[d2]) / 100)
                );
                return {
                  dayId: day?.day,
                  dayProgress:
                    editingBreakdown?.name +
                    " - " +
                    day?.breakdown_dimension +
                    "%",
                  user: userConfiguration?.nameOfUser,
                  firstDateOnSchedule: dayjsNY(
                    scheduleDays?.[0]?.startDate
                  ).valueOf(),
                  progressTimeGiven: Date.now(),
                  breakdown: editingBreakdown?.name,
                  pli: pli?.id,
                  pliProgress,
                  elevationId,
                  elevationStatus: elevation?.elevationStatus,
                  pliProgressPercentage:
                    progressInPerc(pliProgress, d1, d2, pli) || 0,
                  serviceId: service?.serviceId,
                  estimationId: service?.estimationId,
                  progressValues: [
                    {
                      breakdownName: editingBreakdown?.name,
                      progress: day?.breakdown_dimension,
                    },
                  ],
                  totalPli: !d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2],
                  length: pli?.length || pli?.originalPli?.length,
                  width: pli?.width || pli?.originalPli?.width,
                  height: pli?.height || pli?.originalPli?.height,
                  surface: pli?.surface || pli?.originalPli?.surface,
                  pliRemainingProgress: !d2
                    ? pli?.[d1] - pli?.totalProgress
                    : pli?.[d1] * pli?.[d2] - pli?.totalProgress,
                  typeOfProgress: "breakdown",
                  estimation:
                    service?.quickbooksNumber ||
                    service?.estimationNumber ||
                    "1",
                  startDate: dayjsNY(findDay?.startDate).valueOf(),
                  endDate: dayjsNY(findDay?.endDate).valueOf(),
                  serviceName: service?.label,
                  elevation: elevationLabel + " - " + elevationId,
                };
              } else {
                return null;
              }
            })
            ?.filter(Boolean)
        : []
    );
    handleCloseModal();
    setStep(0);
    onEmptySpecificBreakdown();
    // setSelectedValues([]);
  };
  const dimensions =
    origin === "pli"
      ? d2 === undefined
        ? `- ${upperCaseFirstLetter(d1)}: ${pli?.[d1] || 0}`
        : `- ${upperCaseFirstLetter(d1)}: ${
            pli?.[d1] || 0
          } - ${upperCaseFirstLetter(d2)}: ${pli?.[d2] || 0}`
      : "";

  let progressByDaysToDisplay = selectedValues?.filter((el) =>
    scheduleDays?.some((a) => a?.id === el?.day)
  );

  progressByDaysToDisplay = progressByDaysToDisplay?.map((el) => {
    const day = scheduleDays?.find((p) => p?.id === el?.day);
    return {
      ...el,
      date: day?.startDate,
    };
  });
  progressByDaysToDisplay = progressByDaysToDisplay?.sort(
    (a, b) => dayjsNY(a?.date) - dayjsNY(b?.date)
  );

  const breakdownHours = (hours, id) => {
    if (isNaN(hours)) {
      message.error("Please enter a valid number!");
      return;
    } else {
      const values = [...selectedValues];
      const index = values?.findIndex((el) => el?.day === id);
      values[index].workingHours = Number(hours);
      setSelectedValues(values);
    }
  };

  const onMatchForemanProgress = (el) => {
    const values = [...selectedValues];
    const index = values?.findIndex((a) => a?.day === el?.day);
    values[index].breakdown_dimension = el?.foreman?.progress || 0;
    setLnf({
      ...lnf,
      [el?.day]:
        (!d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2]) *
        (Number(specificBreakdown?.rate) / 100) *
        (el?.foreman?.progress / 100),
    });
    setSelectedValues(values);
  };

  useEffect(() => {
    if (breakVisible) {
      const data = selectedValues || [];
      let lnfs = {};
      data?.forEach((prg) => {
        lnfs[prg?.day] =
          (!d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2]) *
          (Number(specificBreakdown?.rate) / 100) *
          (prg?.[breakdown_dimension] / 100);
      });
      setLnf(lnfs);
      setSelectedValues(data);
      setStep(0);
    }
  }, [breakVisible]);

  const handleCloseModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setBreakVisible(false);
    onEmptySpecificBreakdown();
  };

  async function getForemanFiles() {
    let obj = {};
    progressByDaysToDisplay.map(async (el) => {
      const scheduleDay = availableScheduleDays?.find((a) => a?.id === el?.day);
      const foremanFiles = el?.files || {};
      const folderName = dayjsNY(scheduleDay?.endDate)?.format("MM/DD/YYYY");
      const folderId = context?.driveIdsByService?.[folderName];
      let uploadedFiles = [];
      if (folderId && Object.keys(foremanFiles).length > 0) {
        uploadedFiles = await fetchProgressChangesForemenFiles({
          list,
          all: true,
          folderId,
          foremanFiles,
          userConfiguration,
        });
      }
      obj[el?.day] = uploadedFiles;
    });
    setFilesOfDays(obj);
  }

  useEffect(() => {
    getForemanFiles();
  }, []);

  return (
    <div>
      <Modal
        className={`breakdown-selectedDays-modal ${
          isDarkMode && "breakdown-selectedDays-modal-dark"
        }`}
        centered
        open={breakVisible}
        destroyOnClose={true}
        onCancel={() => {
          handleCloseModal();
          setSelectedValues([]);
          setBreakdownValue(breakdownValue);
          setStep(0);
        }}
        title={
          step === 0
            ? "Schedule days"
            : `Days selected for ${specificBreakdown?.name} ${dimensions}`
        }
        afterOpenChange={(event) => {
          event &&
            updateDocumentTitle({
              newTitle:
                step === 0
                  ? "Schedule days"
                  : `Days selected for ${specificBreakdown?.name} ${dimensions}`,
            });
        }}
        footer={
          step === 0 ? (
            <div
              style={{
                display: "flex",
                justifyContent: "space-between",
                gap: 10,
              }}
            >
              <MondayButton
                disabled={progressByDaysToDisplay?.length === 0}
                onClick={() => setStep(1)}
                {...{
                  className: "mondayButtonBlue",
                  Icon: <NextIcon />,
                }}
              >
                Next
              </MondayButton>
              <MondayButton
                onClick={saveBreakdowns}
                {...{
                  Icon: <TickIcon width={17} height={17} />,
                }}
              >
                Save Changes
              </MondayButton>
            </div>
          ) : (
            [
              <div className="footerButtons">
                <MondayButton
                  onClick={() => {
                    handleCloseModal();
                    setBreakdownValue(breakdownValue);
                    setStep(0);
                  }}
                  {...{
                    className: "mondayButtonRed",
                    Icon: <XIcon />,
                  }}
                >
                  Cancel
                </MondayButton>
                <MondayButton
                  onClick={() => {
                    setStep(0);
                  }}
                  {...{
                    className: "mondayButtonBlue",
                    Icon: <PreviousIcon />,
                  }}
                >
                  Go back
                </MondayButton>
                <MondayButton
                  onClick={saveBreakdowns}
                  {...{
                    Icon: <TickIcon width={17} height={17} />,
                  }}
                >
                  Save Changes
                </MondayButton>
              </div>,
            ]
          )
        }
        closeIcon={<XIcon />}
        width="fit-content"
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: 12,
            padding: step === 1 ? 20 : 0,
            height: "fit-content",
            maxHeight: 700,
            overflowY: "auto",
          }}
        >
          {step === 1 && (
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: 7,
              }}
            >
              {<InfoIconBlue />}
              {Object.entries(infoData)?.map(([key, value]) => {
                return (
                  <div key={key} className="breakdown-selectedDays-info">
                    <span>{key}:</span>
                    <Tooltip title={value}>
                      <b
                        style={{
                          overflow: "hidden",
                          textOverflow: "ellipsis",
                          whiteSpace: "nowrap",
                          maxWidth: 370,
                          display: "inline-block",
                        }}
                      >
                        {value}
                      </b>
                    </Tooltip>
                  </div>
                );
              })}
            </div>
          )}

          <div className="breakdown-selectedDays-containter">
            {step === 0 && (
              <div
                style={{
                  display: "flex",
                  flexDirection: "column",
                  alignItems: "center",
                  padding: 15,
                }}
              >
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "center",
                    gap: 7,
                    padding: 20,
                  }}
                >
                  {<InfoIconBlue />}
                  <span
                    style={{}}
                  >{`Available schedule days (${availableScheduleDays?.length})`}</span>
                </div>
                <div
                  style={
                    availableScheduleDays?.length > 5
                      ? {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          gap: 7,
                          flexWrap: "wrap",
                          width: 470,
                          height: "fit-content",
                          maxHeight: 600,
                          overflowY: "auto",
                          // paddingLeft: 20,
                        }
                      : {
                          display: "flex",
                          justifyContent: "center",
                          alignItems: "center",
                          flexDirection: "column",
                          gap: 15,
                        }
                  }
                >
                  {availableScheduleDays?.map((el, i) => {
                    const selected = selectedValues?.some(
                      (a) => a?.day === el?.id
                    );
                    return (
                      <div key={i} className="checkAnimSch">
                        <MondayButton
                          onClick={() =>
                            selectedDaysOnChange(selected ? false : true, el)
                          }
                          {...{
                            Icon: <SchedulingWhite width={20} height={20} />,
                            className: selected ? "" : "mondayButtonRed",
                          }}
                        >{`Day ${el?.day} - ${dayjsNY(el?.startDate).format(
                          "MM/DD/YYYY"
                        )}`}</MondayButton>
                      </div>
                    );
                  })}
                </div>
              </div>
            )}
            {step === 1 && (
              <>
                <div
                  style={{
                    display: "flex",
                    flexDirection: "column",
                    gap: 15,
                  }}
                >
                  <span style={{ fontWeight: 600 }}>
                    Selected days ({progressByDaysToDisplay?.length})
                  </span>
                  {progressByDaysToDisplay?.map((el, i) => {
                    const day = availableScheduleDays?.find(
                      (p) => p?.id === el?.day
                    );
                    return (
                      <div key={i} className="checkAnim">
                        <MondayButton
                          {...{
                            hasIcon: false,
                          }}
                        >{`Day ${day?.day} - ${dayjsNY(day?.startDate).format(
                          "MM/DD/YYYY"
                        )}`}</MondayButton>
                      </div>
                    );
                  })}
                </div>
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>
                      Progress by day in %
                    </span>
                    {origin === "pli" && (
                      <span style={{ fontSize: 13 }}>
                        {`Currently pli ${typeOfWorkTexts[typeOfWork]}:
                              ${
                                progressInPerc(totalProgressPli, d1, d2, pli) ||
                                0
                              }%
                            `}
                      </span>
                    )}
                  </div>
                  <div className="progressByDayContainer">
                    {progressByDaysToDisplay?.map((el, i) => {
                      const day = availableScheduleDays?.find(
                        (p) => p?.id === el?.day
                      );
                      const hasProgressFromForeman = "foreman" in el;
                      return (
                        <div key={i} className="progressByDay">
                          <Button
                            onClick={() => autoComplete(el)}
                            style={{ color: day?.color }}
                          >{`D${day?.day}`}</Button>

                          <Input
                            value={el?.[breakdown_dimension] || 0}
                            onChange={(e) => onChangePercentage(e, el)}
                            style={{
                              width: 210,
                              height: 32,
                            }}
                            suffix={<PercentageIcon />}
                          />
                          {hasProgressFromForeman && (
                            <Tooltip
                              title={`Progress is given by ${el?.foreman?.nameOfForeman}: ${el?.foreman?.progress}%`}
                            >
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => onMatchForemanProgress(el)}
                              >
                                {<CrewsIcon fill="green" />}
                              </span>
                            </Tooltip>
                          )}
                          {filesOfDays?.[el?.day] &&
                            filesOfDays?.[el?.day]?.length > 0 && (
                              <Tooltip title="Foreman files uploaded">
                                <Button
                                  className="mondayButtonBlue"
                                  onClick={() => {
                                    setOpenUploadsModal(el?.day);
                                  }}
                                >
                                  {filesOfDays?.[el?.day]?.length}
                                </Button>
                              </Tooltip>
                            )}
                          {!!openUploadsModal && (
                            <Modal
                              open={!!openUploadsModal}
                              onCancel={() => setOpenUploadsModal(false)}
                              title={`Foreman files uploaded - Day ${day?.day}`}
                              closeIcon={<XIcon />}
                              footer={null}
                              centered
                              className="breakdownForemanFilesModal"
                            >
                              <DocUploader
                                {...{
                                  uploadedFiles:
                                    filesOfDays?.[openUploadsModal],
                                  onlyUploadedFiles: true,
                                  responsiveWidth: 10,
                                  disabled: true,
                                }}
                              />
                            </Modal>
                          )}
                        </div>
                      );
                    })}
                  </div>
                </div>
                {origin === "pli" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        Progress by day in lnf
                      </span>
                      <span style={{ fontSize: 13 }}>
                        {`Currently pli ${typeOfWorkTexts?.[typeOfWork]}:
                              ${Math.round(totalProgressPli) || 0}lnf`}
                      </span>
                    </div>
                    <div className="progressByDayContainerlnf">
                      {progressByDaysToDisplay?.map((el, i) => {
                        return (
                          <div key={i} className="progressByDay">
                            <Input
                              style={{
                                width: 150,
                                height: 30,
                                border: "none",
                              }}
                              value={lnf?.[el?.day] || 0}
                              onChange={(e) => onChangeLnf(e, el)}
                              suffix={<b>lnf</b>}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                {origin === "pli" && (
                  <div>
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        gap: 10,
                      }}
                    >
                      <span style={{ fontWeight: 600 }}>
                        Total work progress
                      </span>
                    </div>
                    <div className="progressByDayContainerlnf">
                      {progressByDaysToDisplay?.map((el, i) => {
                        return (
                          <div key={i} className="progressByDay">
                            <Input
                              style={{
                                width: 150,
                                height: 30,
                                border: "none",
                              }}
                              value={(
                                (!d2 ? pli?.[d1] : pli?.[d1] * pli?.[d2]) *
                                (el?.[breakdown_dimension] / 100)
                              ).toFixed(2)}
                              suffix={<b>lnf</b>}
                            />
                          </div>
                        );
                      })}
                    </div>
                  </div>
                )}
                <div>
                  <div
                    style={{
                      display: "flex",
                      justifyContent: "center",
                      alignItems: "center",
                      gap: 10,
                    }}
                  >
                    <span style={{ fontWeight: 600 }}>Working Hours</span>
                  </div>
                  <div className="progressByDayContainerlnf">
                    {progressByDaysToDisplay?.map((el, i) => {
                      return (
                        <div key={i} className="progressByDay">
                          <Input
                            style={{
                              width: 150,
                              height: 30,
                              border: "none",
                            }}
                            value={el?.workingHours || 0}
                            onChange={(e) =>
                              breakdownHours(e.target.value, el?.day)
                            }
                            placeholder="Working Hours"
                            suffix={<b>h</b>}
                          />
                        </div>
                      );
                    })}
                  </div>
                </div>
              </>
            )}
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BreakdownsSelectedDays;

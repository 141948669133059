import "./EditTaskModal.scss";
import "../../../../../commonComponents/detailsModal.scss";
import { useEffect, useState } from "react";
import { Alert, Modal, message } from "antd";
import { useDispatch, useSelector } from "react-redux";
import { API } from "aws-amplify";
import { XIcon } from "../../../../Communication/assets";
import {
  ProgressComponent,
  ToggleSwitch,
} from "../../../../../commonComponents";
import { updateDocumentTitle } from "../../../../../../utils";
import { useGoogleSignIn } from "../../../../../../hooks/useGoogleSignIn";
import { loggedOnTasks as updateTaskRedux } from "../../../../../../actions";
import {
  EditTaskModalFooter,
  SubTaskInEdit,
  TaskCommentsInEdit,
  TaskDatesInEdit,
  TaskLabelsInEdit,
  TaskReminderInEdit,
  TaskTimerInEdit,
  TaskUsersInEdit,
} from "./components";
import {
  createLogObject,
  generateTaskPdf,
  handleEventUpdate,
  sendTaskEditNotification,
} from "./helpers";
import TaskStepper from "../components/TaskStepper";
import {
  useEditLogs,
  useProgressComponent,
  useUploadedFiles,
} from "../../../../../../hooks";
import { DocUploader } from "../../../../Documentation/View/components/DocumentationModal/components";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { useCreateDriveFolders } from "../../../../../../hooks/useCreateDriveFolders";
import { parseInTz } from "../../../../Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { googleDriveUploadsLogs } from "../../../../../../hooks/useUploadedFiles";
import DeleteTaskModal from "./DeleteTaskModal";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { LockFilled, UnlockFilled } from "@ant-design/icons";
////// NOTE
//
//
////// DO NOT CHANGE THIS COMP. WITHOUT CONSULTING BECAUSE IT'S A REUSABLE COMP.
//
//
////// NOTE

const EditTaskModal = ({
  selectedTask,
  setSelectedTask,
  rowData = [],
  updateRowData = () => {},
  setClonedTask = () => {},
  taskStatuses,
}) => {
  const dispatch = useDispatch();

  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { base64 } = useSelector((state) => state.base64);
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { hotCredentials = [] } = useSelector((state) => state.hotCredentials);

  const [updatedTask, setUpdatedTask] = useState({});
  const [deleteConfirmModal, setDeleteConfirmModal] = useState(false);
  const [isPrivate, setIsPrivate] = useState(false);

  const { userDetails } = useGoogleSignIn();
  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Tasks", actionType: "Update" });
  const { saveAddedLogs } = useEditLogs();

  // =================== Upload ===================
  const [uploadHandlers, deleteHandlers] = useUploadedFiles(
    selectedTask?.googleDriveUploads || []
  );
  const [uploadedFiles, setUploadedFiles, formattedFiles, onPickerSuccess] =
    uploadHandlers;
  const [filesToBeDeleted, setFilesToBeDeleted, onDelete] = deleteHandlers;
  const driveFunctions = useCreateDriveFolders("Tasks");
  const taskHotCredential = hotCredentials.find(
    (obj) => obj.credentialId === "21"
  );
  const { deleteDriveItem } = driveApi({ accessToken });
  // =================== Upload ===================

  async function deleteDriveFolder() {
    if (!!selectedTask?.googleDriveFolderIds?.tasksObject) {
      await deleteDriveItem(selectedTask?.googleDriveFolderIds?.tasksObject);
    } else if (driveFunctions?.parentFolderId) {
      await deleteDriveItem(driveFunctions?.parentFolderId);
    }
  }

  const {
    taskId,
    taskTitle,
    taskStatus,
    categoryFrom,
    taskTopic,
    taskRelatedTo,
    taskSubTopic,
    taskSubTopicId,
    taskEndDate,
    taskStartDate,
    taskDeadline,
  } = updatedTask;

  //save current task in state to manipulate with it
  useEffect(() => {
    !!selectedTask && setUpdatedTask(selectedTask);
  }, [selectedTask]);

  //update last seen of users when component mounted
  useEffect(() => {
    (async () => {
      if (!!selectedTask?.taskId) {
        const foldersEmpty = _.isEmpty(selectedTask?.googleDriveFolderIds);
        let folders;
        if (foldersEmpty) {
          const { folders: allFolders } = await driveFunctions.create({
            parentFolderName: `${selectedTask?.taskTitle} ${parseInTz(
              selectedTask?.createdAt
            ).format("DD/MM/YYYY HH:mm:ss")}`,
            parentId: taskHotCredential?.credentialValue,
          });
          folders = allFolders;
        }
        await API.put(
          "tasksManagement",
          `/tasksManagement/${selectedTask.taskId}`,
          {
            body: {
              viewLogs: {
                ...(selectedTask?.viewLogs || {}),
                [userConfiguration?.cognitoUserId]: {
                  lastSeen: Date.now(),
                  name: userConfiguration?.nameOfUser,
                },
              },
              ...(!foldersEmpty ? {} : { googleDriveFolderIds: folders }),
            },
          }
        ).catch((err) => console.log("Error Updating View Logs: ", err));

        if (!!selectedTask.privateOnlyTo) {
          setIsPrivate(true);
        }
      }
    })();
  }, []);

  //Function that updates data of selected task to database
  const updateTask = (taskUpdated = () => {}) => {
    const uploadsLogs = googleDriveUploadsLogs(
      selectedTask?.googleDriveUploads,
      formattedFiles
    );
    //get log object
    const logObject = createLogObject(updatedTask, selectedTask);
    if (uploadsLogs) {
      Object.assign(logObject, {
        currentData: { ...logObject.currentData, ...uploadsLogs.curr },
        previousData: { ...logObject.previousData, ...uploadsLogs.prev },
        updatedKeys: [...logObject.updatedKeys, "googleDriveUploads"],
      });
    }

    message.loading({ content: "Updating...", key: "updateTask" });
    // updateKanbanData(task);

    //save edit logs
    saveAddedLogs(logObject);

    setVisibleCreationProgress(updatedTask);

    const { taskId, userId, taskAssignedTo, ...body } = updatedTask;
    updateProgressStatus({ updatingRecord: "executing" });
    API.put("tasksManagement", `/tasksManagement/${taskId}`, {
      body: {
        ...body,
        privateOnlyTo: isPrivate ? userConfiguration.cognitoUserId : "",
        taskAssignedTo: taskAssignedTo.filter(
          ({ cognitoUserId }) =>
            !isPrivate || cognitoUserId === userConfiguration.cognitoUserId
        ),
        googleDriveUploads: formattedFiles,
      },
    })
      .then(async () => {
        taskUpdated();
        updateProgressStatus({ updatingRecord: "finished" });
        filesToBeDeleted.forEach(async (id) => await deleteDriveItem(id));
        const updatedRowData = rowData.map((task) =>
          task.taskId === selectedTask.taskId ? updatedTask : task
        );
        updateRowData(updatedRowData);
        //Update logged tasks state on redux
        dispatch(updateTaskRedux(updatedRowData));

        if (
          !!updatedTask?.createdByCognito
            ? updatedTask.createdByCognito === userConfiguration.cognitoUserId
            : updatedTask.createdBy === userConfiguration.nameOfUser
        ) {
          await handleEventUpdate(updatedTask, userConfiguration, userDetails);
        } else {
          message.info({
            content: "Task is updated but Event could not be updated",
            key: "updateTask",
          });
        }

        if (!isPrivate) {
          sendTaskEditNotification(
            selectedTask,
            userConfiguration,
            updatedTask,
            updateProgressStatus
          );
        }

        // gridApiParent?.setFilterModel(null);
        message.success({
          content: "Task Updated successfully!",
          key: "updateTask",
        });
        // closeModal();
      })
      .catch((error) => {
        closeModal();
        console.log(error);
        message.error({
          content: "Error while updating task",
          key: "updateTask",
        });
        updateProgressStatus({ updatingRecord: "hasError" });
      });
  };

  const closeModal = ({ cancel = false } = {}) => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setSelectedTask(false);
    if (!selectedTask?.googleDriveFolderIds?.tasksObject) {
      const updatedRowData = rowData.map((task) =>
        task.taskId === selectedTask.taskId
          ? { ...selectedTask, googleDriveFolderIds: driveFunctions.allFolders }
          : task
      );
      updateRowData(updatedRowData);
      dispatch(updateTaskRedux(updatedRowData));
    }

    if (cancel) {
      //delete new files and files to be deleted
      uploadedFiles
        ?.filter(({ newDocFile }) => !!newDocFile)
        ?.map(async ({ id }) => await deleteDriveItem(id));
    }
  };

  return (
    <>
      {selectedTask === 404 ? (
        <Modal
          title="Task Not Found"
          open={true}
          closable={true}
          closeIcon={<XIcon />}
          onCancel={closeModal}
          className={`task_details_wrapper ${
            isDarkMode && "task_details_wrapper_dark"
          }`}
          width={1100}
          centered
          destroyOnClose={true}
          footer={<></>}
        >
          <div className="alert-container">
            <Alert
              style={{ fontSize: "22px", flexDirection: "column" }}
              showIcon={false}
              message="This task has been deleted!"
              type="error"
              banner="true"
            />
          </div>
        </Modal>
      ) : (
        <Modal
          open={!!taskId && selectedTask}
          className={`task_details_wrapper ${
            isDarkMode && "task_details_wrapper_dark"
          }`}
          title={
            <>
              Edit Task
              <ToggleSwitch
                variant="filled"
                checked={isPrivate}
                label={isPrivate ? "Private" : "Public"}
                icon={isPrivate ? <LockFilled /> : <UnlockFilled />}
                onChange={setIsPrivate}
              />
            </>
          }
          afterOpenChange={(event) => {
            event && updateDocumentTitle({ newTitle: "Edit Task" });
          }}
          destroyOnClose={true}
          closeIcon={<XIcon />}
          onCancel={() => closeModal({ cancel: true })}
          width={1826}
          centered
          footer={
            <EditTaskModalFooter
              {...{
                updateTask,
                closeModal,
                setDeleteConfirmModal,
                exportToPdf: () =>
                  generateTaskPdf({ body: selectedTask, base64 }),
                handleCloneTask: () => setClonedTask(updatedTask), //Copy task data for filling all fields when cloning task
                recordId: selectedTask?.taskId,
                recordName: selectedTask?.taskTitle,
                taskId,
                updatedTask,
              }}
            />
          }
        >
          <TaskStepper
            steps={taskStatuses}
            currentStep={taskStatuses?.findIndex(
              (status) => taskStatus === status.title
            )}
            setCurrentStep={(index) => {
              setUpdatedTask((prev) => ({
                ...prev,
                taskStatus: taskStatuses[index].title,
              }));
            }}
          />
          <div className="detailsModal__info__container">
            <DocUploader
              {...{
                folderId: !!selectedTask?.googleDriveFolderIds
                  ? selectedTask.googleDriveFolderIds?.tasksObject
                  : !!driveFunctions.parentFolderId
                  ? driveFunctions.parentFolderId
                  : "",
                uploadedFiles,
                setUploadedFiles,
                onDelete,
                onChange: () => {},
                onPickerSuccess,
                responsiveWidth: 1400,
              }}
            />

            <div className="detailsModal__leftSide">
              <div>
                <span>
                  <h4>Title:</h4> {taskTitle}
                </span>
                <span>
                  <h4>Topic:</h4>
                  {!!categoryFrom?.documentationCategory
                    ? categoryFrom.documentationCategory + " / "
                    : ""}
                  {taskTopic} / {taskRelatedTo}
                </span>

                <span>
                  <h4>Subtopic:</h4>
                  {taskSubTopic || "No subtopic chosen"}
                  {!!taskSubTopicId &&
                    " / " +
                      (typeof taskSubTopicId === "object"
                        ? taskSubTopicId?.recordName
                          ? taskSubTopicId.recordName
                          : ""
                        : taskSubTopicId)}
                </span>
              </div>

              <SubTaskInEdit {...{ updatedTask, setUpdatedTask, rowData }} />
            </div>
            <div className="detailsModal__rightSide">
              {!isPrivate && (
                <TaskUsersInEdit {...{ updatedTask, setUpdatedTask }} />
              )}
              <div className="task-dates-edit">
                {[
                  {
                    title: "Start date:",
                    dateKey: "taskStartDate",
                    compareWith: {
                      dateFrom: dayjsNY(taskStartDate),
                      dateTo: dayjsNY(taskEndDate),
                      comparator: ">",
                      errorMsg: "Start date cannot be after end date",
                    },
                  },
                  {
                    title: "End date:",
                    dateKey: "taskEndDate",
                    compareWith: {
                      dateFrom: dayjsNY(taskStartDate),
                      dateTo: dayjsNY(taskDeadline),
                      comparator: "<",
                      errorMsg: "End date cannot be before start date",
                    },
                  },
                  {
                    title: "Deadline:",
                    dateKey: "taskDeadline",
                    compareWith: {
                      dateFrom: dayjsNY(taskEndDate),
                      dateTo: dayjsNY().add(1, "year"),
                      comparator: "<",
                      errorMsg: "Deadline cannot be before end date",
                    },
                  },
                ].map(({ title, dateKey, compareWith }, i) => (
                  <TaskDatesInEdit
                    key={i}
                    {...{
                      title,
                      dateKey,
                      compareWith,
                      updatedTask,
                      setUpdatedTask,
                      dates: {
                        startDate: taskStartDate,
                        endDate: taskEndDate,
                        deadLine: taskDeadline,
                      },
                    }}
                  />
                ))}
              </div>
              <TaskLabelsInEdit {...{ updatedTask, setUpdatedTask }} />
              <TaskTimerInEdit {...{ updatedTask, setUpdatedTask }} />
              <TaskReminderInEdit {...{ updatedTask, setUpdatedTask }} />
            </div>
          </div>
          <TaskCommentsInEdit
            {...{ selectedTask, updatedTask, setUpdatedTask }}
          />
        </Modal>
      )}

      {deleteConfirmModal && (
        <DeleteTaskModal
          {...{
            updatedTask,
            setDeleteConfirmModal: () => {
              setDeleteConfirmModal(false);
              closeModal();
            },
            rowData,
            updateRowData,
            isDarkMode,
            deleteDriveFolder,
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Tasks",
            actionType: "Update",
            visibleCreationProgress,
            creationProgresses,
            closeModal,
          }}
        />
      )}
    </>
  );
};

export default EditTaskModal;

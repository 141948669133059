import { ConfirmationModal, MondayButton } from "../../../../commonComponents";
import React, { useState, useEffect } from "react";
import "./PageControlPanel.scss";
import FilterModal from "../Modals/FilterModal";
import { StarFilled } from "../../../../commonComponents/StarCheckbox/assets";
import { FilterIcon } from "../../../BasePage/src";
import { ClearFilterIcon } from "../../../DynamicView/src";
import FavoriteCategoriesModal from "../Modals/FavoriteCategoriesModal";
import { useSelector } from "react-redux";
import {
  CheckOutlined,
  ClearOutlined,
  CloseOutlined,
  SearchOutlined,
} from "@ant-design/icons";
import { API } from "aws-amplify";
import { Input, Tooltip, message } from "antd";
import { useDispatch } from "react-redux";
import { preferences as preferencesDispatch } from "../../../../../actions/preferences";
import { useResponsive } from "../../../../../hooks";
import { ReportsPerCategoryModal } from "./ReportsPerCategoryModal";
import { groupBy } from "lodash";
import { debounceSearch } from "../../../../../utils";
import { useLocation, useNavigate } from "react-router-dom";

const PageControlPanel = ({
  activeFilters,
  setActiveFilters,
  configDatas,
  userList,
  filterCategory,
  reportsConfiguration,
  identityId,
  playDrag,
  setPlayDrag,
  setDragItems,
  dragItems,
  reportPrefs,
  setReportPrefs,
  reportsToRender,
  handleClickedCategory,
  categorySelected,
  getSearchTerm = (value) => {},
}) => {
  const dispatch = useDispatch();
  const [searchTerm, setSearchTerm] = useState("");
  const [openConfirmModal, setOpenConfirmModal] = useState(false);
  const { state } = useLocation();
  const navigate = useNavigate();

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { preferences: allPreferences } = useSelector(
    (state) => state.preferences
  );

  const [filterModal, setFilterModal] = useState(false);
  const [favoritesModal, setFavoritesModal] = useState(false);
  let noFilters =
    Object.keys(activeFilters).length === 0 ||
    Object.values(activeFilters).every(
      (value) => value === null || value === undefined
    );

  const handleSaveFavorites = async () => {
    message.loading("Saving...");
    const updatedPreferences = {
      ...(allPreferences?.preferences || {}),
      reportPreferences: reportPrefs,
      ReportsPage: dragItems.map(({ reportId }) => reportId),
    };

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Changes Saved In Your Preferences!");
        setReportPrefs(null);
        setPlayDrag(false);
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => {
        console.error("Error saving to preferences", err);
        setReportPrefs(null);
        setDragItems(reportsToRender);
      });
  };

  const handleClearOrderPreference = async () => {
    if (!allPreferences?.preferences?.ReportsPage) {
      message.info("There is no preference saved before...");
      return;
    }
    setOpenConfirmModal(true);
  };

  const { mobile } = useResponsive();

  const onSearchChangeHandler = (e) => {
    const value = typeof e === "string" ? e : e?.target?.value;

    setSearchTerm(value);
    getSearchTerm?.(value);
  };

  useEffect(() => {
    if (state) {
      onSearchChangeHandler(state?.props);
    } else if (state && searchTerm === "") {
      navigate(location.pathname, { replace: true, state: undefined });
    }
  }, [state]);

  const onSearchHandler = debounceSearch(onSearchChangeHandler, 300);

  const groupReportsByCategory = groupBy(reportsToRender, "categoryName");

  const handleClearSelectedCategory = () => {
    handleClickedCategory?.({ category: undefined });
    setSearchTerm("");
    getSearchTerm?.("");
  };

  const handleOnConfirmModal = () => {
    message.loading("Saving...");
    const { ReportsPage, ...rest } = allPreferences?.preferences;
    const updatedPreferences = rest;

    API.patch("preferences", "/preferences", {
      body: { preferences: updatedPreferences },
    })
      .then(() => {
        message.destroy();
        message.success("Order preference has been deleted!");
        dispatch(
          preferencesDispatch({
            ...allPreferences,
            preferences: updatedPreferences,
          })
        );
      })
      ?.catch((err) => console.error(err));
  };

  return (
    <>
      <div
        style={!mobile ? { width: "auto" } : { overflowX: "auto" }}
        className={`approvalsPageControlPanel ${
          isDarkMode && "approvalsPageControlPanelDark"
        }`}
      >
        <div className="controlSection">
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => setFilterModal(true),
              Icon: <FilterIcon />,
            }}
          >
            Open Filters
          </MondayButton>
          {!noFilters && (
            <MondayButton
              {...{
                Icon: <ClearFilterIcon />,
                className: "mondayButtonRed",
                onClick: () => {
                  setActiveFilters({});
                  setSearchTerm("");
                  getSearchTerm?.("");
                },
                // style: { marginLeft: "20px" },
              }}
            >
              Clear Filters
            </MondayButton>
          )}
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              Icon: <StarFilled />,
              onClick: () => setFavoritesModal(true),
            }}
          >
            Favorite Categories
          </MondayButton>
          <Input
            className="search-comp report-search"
            placeholder={"Search..."}
            allowClear
            autoFocus={true}
            prefix={<SearchOutlined className="SearchLogoIcon" />}
            onChange={onSearchHandler}
            // value={searchTerm}
          />
          <ReportsPerCategoryModal
            reports={groupReportsByCategory}
            handleClickedCategory={handleClickedCategory}
          />
          <Tooltip title="Clear order preference">
            <MondayButton
              data-testid="clear-preference"
              className="mondayButtonBlue"
              Icon={<ClearOutlined />}
              onClick={handleClearOrderPreference}
            />
          </Tooltip>
          {categorySelected && (
            <MondayButton
              {...{
                Icon: <ClearFilterIcon />,
                className: "mondayButtonRed",
                onClick: () => {
                  handleClearSelectedCategory();
                },
                // style: { marginLeft: "20px" },
              }}
            >
              Clear Filters
            </MondayButton>
          )}
        </div>
        {/* <div className="controlSection"></div> */}
        <div className="controlSection-right">
          {(reportPrefs ||
            (playDrag &&
              searchTerm.length === 0 &&
              noFilters &&
              !categorySelected)) && (
            <MondayButton
              {...{
                Icon: <CloseOutlined />,
                className: "mondayButtonRed",
                disabled: !reportPrefs && !playDrag,
                onClick: () => {
                  setReportPrefs(null);
                  setPlayDrag(false);
                  setDragItems(reportsToRender);
                },
                // style: { marginLeft: "20px" },
              }}
            >
              Cancel Changes
            </MondayButton>
          )}
          <Tooltip title="Be aweare of clearing filters first">
            <MondayButton
              data-testid="save-changes"
              {...{
                Icon: <CheckOutlined />,
                className: "mondayButtonGreen",
                onClick: handleSaveFavorites,
                disabled:
                  !reportPrefs &&
                  (!playDrag ||
                    searchTerm.length !== 0 ||
                    !noFilters ||
                    categorySelected),
              }}
            >
              Save Changes
            </MondayButton>
          </Tooltip>
        </div>
      </div>
      <FilterModal
        {...{
          filterModal,
          setFilterModal,
          activeFilters,
          setActiveFilters,
          configDatas,
          userList,
          isDarkMode,
        }}
      />
      {favoritesModal && (
        <FavoriteCategoriesModal
          {...{
            favoritesModal,
            setFavoritesModal,
            onCategoryClick: filterCategory,
            reportsConfiguration,
            identityId,
            isDarkMode,
          }}
        />
      )}
      {openConfirmModal && (
        <ConfirmationModal
          onConfirm={handleOnConfirmModal}
          visible={openConfirmModal}
          setVisible={setOpenConfirmModal}
          title="Confirm"
          text="Are you sure you want to clear order preference"
        />
      )}
    </>
  );
};

export default PageControlPanel;

export const DropdownIconReport = React.memo(() => (
  <svg
    width="12"
    height="8"
    viewBox="0 0 12 8"
    fill="#000"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1.20354 3.10762L5.17386 7.05327C5.31572 7.19487 5.48429 7.30722 5.66988 7.38388C5.85548 7.46054 6.05447 7.5 6.25543 7.5C6.45639 7.5 6.65537 7.46054 6.84097 7.38388C7.02657 7.30722 7.19513 7.19487 7.33699 7.05327L11.3073 3.1037C11.5199 2.88919 11.6641 2.61701 11.7218 2.3213C11.7795 2.0256 11.7482 1.71952 11.6317 1.44145C11.5153 1.16339 11.3189 0.925712 11.0672 0.758231C10.8155 0.59075 10.5197 0.500918 10.2169 0.5H2.27626C1.9731 0.501301 1.67714 0.591996 1.4258 0.760616C1.17445 0.929236 0.979014 1.16821 0.864195 1.44732C0.749376 1.72643 0.720332 2.03314 0.780736 2.32867C0.841139 2.6242 0.988277 2.89527 1.20354 3.10762Z"
      fill="#323338"
    />
  </svg>
));

import { b64toBlob } from "../../../../../../../ProposalBuilder/components/ProposalPages/MergePdfs/MergePdfs";

async function base64ImageToDrive({
  base64,
  fileName,
  mimeType,
  folderId,
  driveRequest,
}) {
  if (!base64) {
    return null;
  }
  const bytes = b64toBlob(base64.split(",")[1], mimeType);

  const file = await driveRequest.uploadExcelFile(bytes, mimeType, {
    name: fileName,
    parents: [folderId],
  });
  const newData = await file.json();
  const newId = newData.id;
  return newId;
}

export default base64ImageToDrive;

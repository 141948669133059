import { Button, Checkbox, Select, Switch, Popover, Tooltip } from "antd";
import React, { useEffect, useMemo, useState } from "react";
import { PriceSchemesType } from "../../../../../../pages/Settings/settingsComponents/Pricing/models/PricingObject";
import { getRawServiceTotals } from "../../../tools/formatters/totals";
import { wrapWithErrorCalculating } from "../../other/ErrorCalculating";
import CurrencyInput from "react-currency-input-field";
import {
  replaceArrayElement,
  updateArrayElement,
} from "../../../../../../../utils";
import { compareIncluding, formatCurrency } from "../../../../../utils";
import PriceTableSchemeModal from "../../PriceTableSchemeModal/PriceTableSchemeModal";
import { getCoordinatesAndZip } from "../../../../../Fleet/utils";
import axios from "axios";
import { MondayButton } from "../../../../../../commonComponents";
import { RentalCalculatorWarningIcon } from "../../../../../../../assets/index";
import {
  otherScopeServiceTaxMapper,
  otherScopeServiceTotalPriceMapper,
} from "./utils";
import PPUInputs from "./PPUInputs";
import { useRedux } from "../../../../../hooks/useRedux";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { pliTaxAmountCalculate } from "../../../tools/controllers/CellEditingEvents";
import { calculateTotalPrice } from "../../../tools/formatters/pricing";
import { checkIfServiceIsHoist } from "../../../models/Service";
import { getSalesTax } from "../../../../../../commonComponents/NextSteps/helpers/getSalesTax";

const { Option } = Select;

const content = (
  <div>
    <p>Content</p>
    <p>Content</p>
  </div>
);

export const getApprovedRentTotal = (service, keyToSave) => {
  let indexToSearch = keyToSave === "alternativeRentalTerms" ? 1 : 0;
  let temp = 0;
  let options =
    service?.serviceOptions?.[indexToSearch]?.flatMap?.(({ items }) =>
      items?.filter(({ approved }) => approved === true)
    ) || [];
  if (options?.length > 0 && service?.isScope !== true) {
    options.forEach((option) => {
      if (!!option?.rent) {
        temp += Number(option.rent);
      }
    });
  }
  if (service?.isScope === true) {
    let tempOpt =
      service?.serviceOptions?.[indexToSearch]?.flatMap?.(
        ({ items }) =>
          (temp += items?.reduce((a, b) => {
            return a + forceToNumber(b?.rent || b?.Rent);
          }, 0))
      ) || [];
  }
  if (Array.isArray(service?.serviceAddons)) {
    service?.serviceAddons.forEach((addon) => {
      let values = addon?.values || [];
      if (values?.length > 0) {
        values.forEach((value) => {
          let tempName = value?.name || "";
          if (tempName?.toLowerCase() === "rent") {
            temp += forceToNumber(value?.defaultValue);
          }
        });
      }
    });
  }
  console.log("getApprovedRentTotal", { service, temp });
  return { approvedRent: temp };
};

export const getElevationAddonsPrice = (elevation) => {
  let tempPrice = 0;
  let taxAmount = 0;
  elevation?.elevationAddons?.forEach((elevationAddon) => {
    tempPrice += forceToNumber(elevationAddon?.totalPrice);
    taxAmount += forceToNumber(elevationAddon?.includedTax?.taxAmount);
  });
  return { elevationAddonPrice: tempPrice, elevationAddonTax: taxAmount };
};

export default function ServicePrices(props) {
  const {
    service,
    stateSetter,
    // gridData,
    handlePricePerUnitChange,
    handleDefaultPricePerUnitChange,
    handleRentPerServiceChange,
    handleAlternateRentPerServiceChange,
    selectedPriceSchemesForService,
    pricingData,
    // isWritable,
    priceEdit,
    priceView,
    updateStateAndSave,
    record,
  } = props;
  //    console.log(selectedPriceSchemesForService[service.serviceId])

  const [pricePerUnit, setPricePerUnit] = useState(service.pricePerUnit);
  const [defaultPPPU, setDefaultPPPU] = useState(
    service?.defaultPricePerUnit || 0
  );
  const [defaultRent, setDefaultRent] = useState(service?.initialRent || 0);
  const [rent, setRent] = useState(service.rent);
  const [alternateRent, setAlternateRent] = useState(service?.alternateRent);

  const [calculateForAllOptions, setCalculateForAllOptions] = useState(false);

  const [priceTableModalVisible, setPriceTableModalVisible] = useState(false);
  const [projectTaxRate, setProjectTaxRate] = useState(null);

  const { taxRate: serviceTaxRate = 0, isTaxable } = service;
  const { taxRate } = record || {};
  const [isWritable] = useRedux("estimationsIsWritable");
  const [gridData] = useRedux("takeOffTableData");

  console.log("records", record);
  let taxExempt = record?.taxExempt || "No";
  // console.log("taxExempt", taxExempt);
  // console.log("serviceTaxRate", serviceTaxRate);
  // console.log("serviceTaxRate", isTaxable);

  const onIsTaxableChange = ({ target: { checked: isTaxable } }) => {
    const { serviceOptions, serviceAddons } = service;
    const pliTaxUpdater = (pli) => {
      // console.log("taxExempt", { taxExempt, pli });
      if (taxExempt === "No" || taxExempt === "Partial") {
        const taxAmount = isTaxable
          ? pliTaxAmountCalculate({ ...pli, taxRate: taxRate })
          : 0;
        const totalPrice = calculateTotalPrice({
          ...pli,
          taxRate: isTaxable ? +taxRate : 0,
          taxAmount,
        });
        return {
          ...pli,
          taxRate: isTaxable ? +taxRate : 0,
          taxAmount,
          totalPrice,
        };
      } else if (taxExempt === "Yes") {
        if (!!isTaxable) {
          const taxAmount = isTaxable
            ? pliTaxAmountCalculate({ ...pli, taxRate: +projectTaxRate })
            : 0;
          const totalPrice = calculateTotalPrice({
            ...pli,
            taxRate: projectTaxRate,
            taxAmount,
          });
          return {
            ...pli,
            taxRate: isTaxable ? +projectTaxRate : 0,
            taxAmount,
            totalPrice,
          };
        }
        if (!isTaxable) {
          const taxAmount = isTaxable
            ? pliTaxAmountCalculate({
                ...pli,
                taxRate: isTaxable ? +taxRate : 0,
              })
            : 0;
          const totalPrice = calculateTotalPrice({
            ...pli,
            taxRate: isTaxable ? +taxRate : 0,
            taxAmount,
          });
          return {
            ...pli,
            taxRate: isTaxable ? +taxRate : 0,
            taxAmount,
            totalPrice,
          };
        }
      }
    };

    const updatedService = {
      ...service,
      isTaxable,
      serviceOptions: serviceOptions.map((alternate) =>
        alternate?.map?.((elevation) => ({
          ...elevation,
          items: elevation?.items?.map?.(pliTaxUpdater),
        }))
      ),
      serviceAddons: serviceAddons.map((el) =>
        serviceAddonPriceMapper(el, isTaxable, taxRate)
      ),
    };

    const newGridData = replaceArrayElement(
      gridData,
      updatedService,
      "serviceId"
    );
    console.log("newGridData", { newGridData, isTaxable });
    updateStateAndSave(newGridData);
  };

  const serviceAddonPriceMapper = (el, isTaxable, taxRate) => {
    if (isTaxable) {
      let tempTax =
        forceToNumber(el?.includedTax?.priceWithoutTax ?? el?.price) *
        forceToNumber(taxRate);
      el.totalPrice =
        forceToNumber(el?.includedTax?.priceWithoutTax ?? el?.price) + tempTax;

      if (el.hasOwnProperty("includedTax")) {
        el.includedTax.taxAmount =
          forceToNumber(el?.includedTax?.priceWithoutTax) *
          forceToNumber(taxRate);
      }
    } else {
      el.totalPrice = forceToNumber(el?.includedTax?.priceWithoutTax);
      if (el.hasOwnProperty("includedTax")) el.includedTax.taxAmount = 0;
    }
    // el.includedTax = {
    //   name: "Included Tax",
    //   taxAmount: isTaxable
    //     ? forceToNumber(el?.totalPrice) * forceToNumber(taxRate)
    //     : 0,
    // };
    return el;
  };

  const totalPriceMapper = (el) =>
    !isNaN(+el?.totalPrice) ? +el?.totalPrice : 0;
  const totalTaxMapper = (el) => (!isNaN(+el?.taxAmount) ? +el?.taxAmount : 0);
  const hoistTotalPriceMapper = ({ totalPrice }) => totalPrice;

  const priceTotal = useMemo(() => {
    let temp = 0;
    let others = 0;
    let taxes = 0;
    let elevationAddonsPrice = 0;
    let serviceAddons = service?.serviceAddons?.reduce((a, b) => {
      return a + forceToNumber(b?.totalPrice);
    }, 0);
    service?.serviceAddons.forEach((el) => {
      taxes += forceToNumber(el?.includedTax?.taxAmount);
    });
    let additionalRentals = service?.additionalRentalTerms?.newPrice || 0;
    let additionalRentalsTax = service?.additionalRentalTerms?.taxAmount || 0;
    console.log("memo service", {
      service,
      gridData,
      serviceAddons,
    });

    const chainedServices = gridData.filter((el) => {
      return (
        el?.isScope === true &&
        Number(el?.chainedService?.serviceId) === Number(service?.serviceId)
      );
    });

    if (chainedServices?.length > 0) {
      chainedServices?.forEach((s) => {
        if (s?.chainedService?.isAutoSum === true)
          others += otherScopeServiceTotalPriceMapper(s);
        taxes += otherScopeServiceTaxMapper(s);
        console.log("memo temp", temp);
      });
    }

    if (service?.isScope === true) {
      temp += otherScopeServiceTotalPriceMapper(service);
      taxes += otherScopeServiceTaxMapper(service);
    } else if (!checkIfServiceIsHoist(service)) {
      temp += [
        ...service?.serviceOptions?.[0]?.flatMap?.(
          ({ items }) =>
            items
              ?.filter?.(({ approved }) => !!approved)
              ?.map?.(totalPriceMapper) || [0]
        ),
        // ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);
      taxes += [
        ...service?.serviceOptions?.[0]?.flatMap?.(
          ({ items }) =>
            items
              ?.filter?.(({ approved }) => !!approved)
              ?.map?.(totalTaxMapper) || [0]
        ),
        ...(service?.serviceAddons?.map?.(totalTaxMapper) || [0]),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);

      service?.serviceOptions?.[0]?.forEach((el) => {
        const { elevationAddonPrice, elevationAddonTax } =
          getElevationAddonsPrice(el);
        temp += forceToNumber(elevationAddonPrice);
        elevationAddonsPrice += forceToNumber(elevationAddonPrice);
        taxes += forceToNumber(elevationAddonTax);
      });
    } else if (checkIfServiceIsHoist(service)) {
      temp += [
        ...service?.serviceOptions?.[0]?.map(hoistTotalPriceMapper),
        // ...service?.serviceAddons.map(totalPriceMapper),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);
      taxes += [
        ...service?.serviceOptions?.[0]?.map((el) => el?.taxAmount),
        ...service?.serviceAddons.map(totalTaxMapper),
      ]
        ?.filter(Boolean)
        ?.reduce((acc, curr) => (acc += curr), 0);
    }
    return {
      others,
      price: temp,
      additionalRentals,
      serviceAddons,
      additionalRentalsTax,
      elevationAddonsPrice,
      total:
        (temp + others + additionalRentals + serviceAddons)?.toFixed(2) ?? 0,
      tax: taxes?.toFixed(2) ?? 0,
    };
  }, [service]);
  // const priceTotal = useMemo(() => {
  //   const myService = gridData.find((e) => e.serviceId === service.serviceId);
  //   const { totalities = {} } = structuredClone(service || {});
  //   console.log("totalities", {
  //     totalities,
  //     service,
  //   });
  //   return {
  //     others: totalities?.includedScope?.scopeAmount || 0,
  //     price: totalities?.servicePrice?.price || 0,
  //     additionalRentals: totalities?.additionalRent || 0,
  //     total: totalities?.totalPrice || 0,
  //     tax: totalities?.includedTaxes ?? 0,
  //   };
  // }, [gridData, service]);

  // const priceTotal = useMemo(() => {
  //   let temp = 0;
  //   let others = 0;
  //   let taxes = 0;
  //   let additionalRentals = service?.additionalRentalTerms?.newPrice || 0;
  //   console.log("memo service", {
  //     service,
  //     gridData,
  //   });
  //   const chainedServices = gridData.filter((el) => {
  //     return (
  //       el?.isScope === true &&
  //       Number(el?.chainedService?.serviceId) === Number(service?.serviceId)
  //     );
  //   });

  //   if (chainedServices?.length > 0) {
  //     chainedServices?.forEach((s) => {
  //       if (s?.chainedService?.isAutoSum === true)
  //         others += otherScopeServiceTotalPriceMapper(s);
  //       taxes += otherScopeServiceTaxMapper(s);
  //       console.log("memo temp", temp);
  //     });
  //   }

  //   if (service?.isScope === true) {
  //     temp += otherScopeServiceTotalPriceMapper(service);
  //     taxes += otherScopeServiceTaxMapper(service);
  //   } else if (service?.label !== "Hoist") {
  //     temp += [
  //       ...service?.serviceOptions?.[0]?.flatMap?.(
  //         ({ items }) =>
  //           items
  //             ?.filter?.(({ approved }) => !!approved)
  //             ?.map?.(totalPriceMapper) || [0]
  //       ),
  //       ...(service?.serviceAddons?.map?.(totalPriceMapper) || [0]),
  //     ]
  //       ?.filter(Boolean)
  //       ?.reduce((acc, curr) => (acc += curr), 0);
  //     taxes += [
  //       ...service?.serviceOptions?.[0]?.flatMap?.(
  //         ({ items }) =>
  //           items
  //             ?.filter?.(({ approved }) => !!approved)
  //             ?.map?.(totalTaxMapper) || [0]
  //       ),
  //       ...(service?.serviceAddons?.map?.(totalTaxMapper) || [0]),
  //     ]
  //       ?.filter(Boolean)
  //       ?.reduce((acc, curr) => (acc += curr), 0);
  //   } else if (service?.label === "Hoist") {
  //     temp += [
  //       ...service?.serviceOptions?.[0]?.map(hoistTotalPriceMapper),
  //       ...service?.serviceAddons.map(totalPriceMapper),
  //     ]
  //       ?.filter(Boolean)
  //       ?.reduce((acc, curr) => (acc += curr), 0);
  //     taxes += [
  //       ...service?.serviceOptions?.[0]?.map((el) => el?.taxAmount),
  //       ...service?.serviceAddons.map(totalTaxMapper),
  //     ]
  //       ?.filter(Boolean)
  //       ?.reduce((acc, curr) => (acc += curr), 0);
  //   }
  //   return {
  //     others,
  //     price: temp,
  //     additionalRentals,
  //     total: (temp + others + additionalRentals)?.toFixed(2) ?? 0,
  //     tax: taxes?.toFixed(2) ?? 0,
  //   };
  // }, [service]);

  const getTaxRateAndCoordinates = async () => {
    const locInfo = await getCoordinatesAndZip(record?.opportunityAddress);
    const taxRate = await getSalesTax({
      zipCode: locInfo.zipCode,
    });
    // !!locInfo?.zipCode &&
    //   (await axios
    //     .post(
    //       "https://leadmanager-express-backend.ue.r.appspot.com/api/salesTax",
    //       {
    //         postalCode: locInfo?.zipCode,
    //       }
    //     )
    //     .then((response) => {
    //       const resTaxRate = parseFloat(response.data?.results[0]?.taxSales);
    //       if (!isNaN(resTaxRate)) {
    //         setProjectTaxRate(resTaxRate);
    //         taxRate = resTaxRate;
    //       } else {
    //         setProjectTaxRate(resTaxRate);
    //       }
    //     }));
    return taxRate;
  };

  const ppuChange = (e) => {
    if (isNaN(e) || e === Infinity || e < 0) e = 0;
    setPricePerUnit(e);
  };

  const defaultPPUChange = (e) => {
    if (isNaN(e) || e === Infinity || e < 0) e = 0;
    setDefaultPPPU(e);
  };

  const defaultRentChange = (e) => {
    if (isNaN(e) || e === Infinity || e < 0) e = 0;
    setDefaultRent(e);
  };

  const rentChange = (e) => {
    if (isNaN(e) || e === Infinity || e < 0) e = 0;
    setRent(e);
  };
  const alternateRentChange = (e) => {
    if (isNaN(e) || e === Infinity || e < 0) e = 0;
    setAlternateRent(e);
  };

  useEffect(() => {
    if (isNaN(service.pricePerUnit) || service.pricePerUnit === Infinity)
      setPricePerUnit(0);
    else setPricePerUnit(service.pricePerUnit);
  }, [service.pricePerUnit]);

  useEffect(() => {
    if (isNaN(service.rent) || service.rent === Infinity) setRent(0);
    else setRent(service.rent);
  }, [service.rent]);

  useEffect(() => {
    if (isNaN(service?.alternateRent) || service?.alternateRent === Infinity)
      setAlternateRent(0);
    else setAlternateRent(service?.alternateRent);
  }, [service?.alternateRent]);

  useEffect(() => {
    if (!!record && projectTaxRate !== null) {
      getTaxRateAndCoordinates();
    }
  }, [record]);

  if (priceView !== true) return ""; //if user is not allowed to view prices

  let PD = pricingData?.find(
    (pricing) => pricing.serviceId === service.serviceId.toString()
  );
  console.log("pricingData", { pricingData, PD });

  let PPU_inputs = "",
    showPricing_Button = "";
  if (PD?.pricingObject?.priceSchemesType === PriceSchemesType.LIST) {
    if (checkIfServiceIsHoist(service)) {
      PPU_inputs = "";
    } else {
      PPU_inputs = (
        <div
          style={{
            display: "flex",
            flexWrap: "wrap",
            paddingTop: "20px",
          }}
        >
          <span className="float-left priceCalculationInput priceCalculationInput-select">
            <Select
              placeholder="Choose a price..."
              style={{ width: "250px" }}
              onChange={(value) => {
                handlePricePerUnitChange(value, service.serviceId, true);
              }}
              value={(() => {
                if (
                  PD.pricingObject.priceSchemes === undefined ||
                  PD.pricingObject.priceSchemes === null
                )
                  PD.pricingObject.priceSchemes = [];
                //if pricingData[0].pricingObject.prices does not have data make it an empty array
                for (const price of selectedPriceSchemesForService[
                  service.serviceId
                ].priceScheme.prices || []) {
                  if (
                    service.pricePerUnit?.toString() === price.value.toString()
                  ) {
                    //if price is same with this pli value return price
                    return price.name;
                  }
                }
                return "custom";
              })()}
            >
              {selectedPriceSchemesForService[
                service.serviceId
              ].priceScheme?.prices?.map?.((price, index) => {
                return (
                  <Option key={index} value={price.value}>
                    {price.name} ({price.value})
                  </Option>
                );
              })}
              <Option value="custom">Custom</Option>
            </Select>
          </span>
          {!checkIfServiceIsHoist(service) && (
            <span className="float-left priceCalculationInput ppu-input">
              <span className="price-input-label">Price Per Unit $</span>
              <CurrencyInput
                disabled={!isWritable}
                name="price per unit"
                value={pricePerUnit}
                onValueChange={ppuChange}
                onBlur={(e) => {
                  handlePricePerUnitChange(
                    pricePerUnit,
                    service.serviceId,
                    true
                  );
                }}
                placeholder="Type in price per unit"
                // prefix={"Rent: "}
                // prefix={"$"}
                step={1}
                decimalScale={2}
                defaultValue={0}
              />
            </span>
          )}

          {service?.serviceId === 8 && (
            <div style={{ display: "flex", marginTop: 20 }}>
              <span className="float-left priceCalculationInput ppu-input">
                <span className="price-input-label">Initial PPU $</span>
                <CurrencyInput
                  disabled={!isWritable}
                  name="price per unit"
                  value={defaultPPPU}
                  onValueChange={defaultPPUChange}
                  onBlur={(e) => {
                    handleDefaultPricePerUnitChange(
                      defaultPPPU,
                      service.serviceId,
                      true,
                      "PPU"
                    );
                  }}
                  placeholder="Type in price per unit"
                  // prefix={"Rent: "}
                  // prefix={"$"}
                  step={1}
                  decimalScale={2}
                  defaultValue={0}
                />{" "}
              </span>{" "}
              <span className="float-left priceCalculationInput ppu-input">
                <span className="price-input-label">Initial Rent %</span>
                <CurrencyInput
                  disabled={!isWritable}
                  name="price per unit"
                  value={defaultRent}
                  onValueChange={defaultRentChange}
                  onBlur={(e) => {
                    handleDefaultPricePerUnitChange(
                      defaultRent,
                      service.serviceId,
                      true,
                      "rent"
                    );
                  }}
                  placeholder="Type in price per unit"
                  // prefix={"Rent: "}
                  // prefix={"$"}
                  step={1}
                  decimalScale={2}
                  defaultValue={0}
                />
              </span>
            </div>
          )}
        </div>
      );
    }
  } else if (PD?.pricingObject?.priceSchemesType === PriceSchemesType.SHEET) {
    PPU_inputs = "";
  }

  if (PD?.pricingObject?.priceSchemesType === PriceSchemesType.SHEET) {
    showPricing_Button = (
      <MondayButton
        className="mondayButtonBlue"
        hasIcon={false}
        // Icon={<RentalCalculatorWarningIcon />}
        style={{ float: "right", background: "#3a3c4e" }}
        onClick={() =>
          stateSetter({ activePriceSheetModal: service.serviceId })
        }
      >
        {" "}
        Show price sheet
      </MondayButton>
    );
  } else if (PD?.pricingObject?.priceSchemesType === PriceSchemesType.LIST) {
    showPricing_Button = (
      <MondayButton
        className="mondayButtonBlue"
        hasIcon={false}
        // Icon={<RentalCalculatorWarningIcon />}
        style={{ float: "right" }}
        onClick={() =>
          stateSetter({ activePriceSheetModal: service.serviceId })
        }
      >
        Show price list
      </MondayButton>
    );
  } else if (PD?.pricingObject?.priceSchemesType === PriceSchemesType.TABLE) {
    showPricing_Button = (
      <MondayButton
        hasIcon={false}
        style={{ float: "right" }}
        className="mondayButtonBlue"
        // Icon={<RentalCalculatorWarningIcon />}
        onClick={() => {
          setPriceTableModalVisible(true);
          // stateSetter({ activePriceSheetModal: service.serviceId });
        }}
      >
        Show price table
      </MondayButton>
    );
  }
  if (checkIfServiceIsHoist(service)) showPricing_Button = "";
  showPricing_Button = <span>{showPricing_Button}</span>;

  const { priceSubtotal, rentTotal } = getRawServiceTotals(
    service,
    calculateForAllOptions
  );
  console.log("priceSubtotal", { priceSubtotal, rentTotal });
  const { approvedRent } = getApprovedRentTotal(service);

  let rent_higher_than_price = "";
  if (rentTotal >= priceSubtotal && rentTotal !== 0)
    rent_higher_than_price = "rent-higher-than-price";

  //     console.log(priceSubtotal)
  let statisticsView = (
    <span className="service-total">
      {priceSubtotal === undefined ? (
        ""
      ) : (
        <Tooltip
          title={
            <div style={{ width: "800px" }}>
              <div>
                <span>Price:</span> {formatCurrency(priceTotal?.price || 0)}
              </div>{" "}
              <div>
                <span>Included Tax:</span>{" "}
                {formatCurrency(priceTotal?.tax || 0)}
              </div>{" "}
              <div>
                <span>Included Scope:</span>{" "}
                {formatCurrency(priceTotal?.others || 0)}
              </div>{" "}
              <div>
                <span>Additional Rent:</span>{" "}
                {formatCurrency(priceTotal?.additionalRentals || 0)}
              </div>{" "}
              <div>
                <span>Additional Rent Tax:</span>{" "}
                {formatCurrency(priceTotal?.additionalRentalsTax || 0)}
              </div>
              <div>
                <span>
                  Included Service Addons:{" "}
                  {formatCurrency(priceTotal?.serviceAddons || 0)}
                </span>{" "}
              </div>
              <div>
                <span>
                  Elevation Addons:{" "}
                  {formatCurrency(priceTotal?.elevationAddonsPrice || 0)}
                </span>{" "}
              </div>
              <div>
                <span>Total :</span> {formatCurrency(priceTotal?.total || 0)}
              </div>{" "}
            </div>
          }
          color={"#1264A3"}
        >
          <span
            className={"service-total-element price " + rent_higher_than_price}
          >
            Price:{" "}
            <span className={"service-total-price"}>
              {formatCurrency(priceTotal.total)}
              {priceTotal.tax > 0
                ? ` (Tax: ${formatCurrency(priceTotal.tax)})`
                : ""}
            </span>
          </span>
        </Tooltip>
      )}
      {rentTotal === undefined ? (
        ""
      ) : (
        <>
          <span
            className={"service-total-element rent " + rent_higher_than_price}
          >
            Rent default:{" "}
            <span className={"service-total-price"}>
              {!checkIfServiceIsHoist(service)
                ? wrapWithErrorCalculating(approvedRent, true, true)
                : wrapWithErrorCalculating(rentTotal, true, true)}
            </span>
          </span>
          <span
            className={"service-total-element rent " + rent_higher_than_price}
            style={{
              display:
                service?.additionalRentalTerms?.newPrice > 0 ? "" : "none",
            }}
          >
            Additional Rental:{" "}
            <Tooltip
              title={
                <div style={{ width: "800px" }}>
                  <div>
                    <span>Months:</span>{" "}
                    {service?.additionalRentalTerms?.months}
                  </div>{" "}
                  <div>
                    <span>Free Months:</span>{" "}
                    {service?.additionalRentalTerms?.freeMonths}
                  </div>{" "}
                  <div>
                    <span>Monthly Price:</span>{" "}
                    {formatCurrency(
                      service?.additionalRentalTerms?.monthlyPrice || 0
                    )}
                  </div>{" "}
                  <div>
                    <span>Recurring Rate:</span>{" "}
                    {service?.additionalRentalTerms?.recurringRate} %
                  </div>{" "}
                  <div>
                    <span>Total Price:</span>{" "}
                    {formatCurrency(
                      service?.additionalRentalTerms?.newPrice || 0
                    )}
                  </div>{" "}
                </div>
              }
              color={"#1264A3"}
            >
              <span className={"service-total-price"}>
                {wrapWithErrorCalculating(
                  priceTotal?.additionalRentals,
                  true,
                  true
                )}
                {/* <Popover content={content} title="Title"> */}

                {/* <span style={{ cursor: "pointer", marginLeft: 10 }}>
                    <RentalCalculatorWarningIcon />
                  </span> */}
                {/* {service?.label !== "Hoist"
                    ? wrapWithErrorCalculating(approvedRent, true, true)
                    : wrapWithErrorCalculating(rentTotal, true, true)} */}
              </span>{" "}
            </Tooltip>
          </span>
        </>
      )}
    </span>
  );

  return (
    <>
      <br />
      {(() => {
        switch (service.serviceId) {
          // case 3:
          // case 13:
          //   return (
          //     <div className="clearfix priceCalculationInputSection">
          //       {statisticsView}
          //       <Checkbox
          //         checked={isTaxable}
          //         onChange={onIsTaxableChange}
          //         disabled={!isWritable || compareIncluding(taxExempt, "yes")}
          //         style={{
          //           color: "#fff",
          //         }}
          //       >
          //         Taxable
          //       </Checkbox>
          //     </div>
          //   );
          // case 16:
          // case 17:
          // case 19:
          // case 20: {
          //   //those with rent and price in elevation level
          //   return (
          //     <div className="clearfix priceCalculationInputSection">
          //       {statisticsView}
          //       <Checkbox
          //         checked={isTaxable}
          //         onChange={onIsTaxableChange}
          //         disabled={!isWritable || compareIncluding(taxExempt, "yes")}
          //         style={{
          //           color: "#fff",
          //         }}
          //       >
          //         Taxable
          //       </Checkbox>
          //     </div>
          //   );
          // }
          // case 11:
          // return (
          //   <div className="clearfix priceCalculationInputSection">
          //     {statisticsView}
          //     <Checkbox
          //       className="takeOff-checkbox"
          //       checked={isTaxable}
          //       onChange={onIsTaxableChange}
          //       disabled={!isWritable || compareIncluding(taxExempt, "yes")}
          //       style={{
          //         color: "#fff",
          //       }}
          //     >
          //       Taxable
          //     </Checkbox>
          //     {showPricing_Button}
          //   </div>
          // );
          default: {
            if (1 === 1) {
              //if user is allowed to edit prices
              return (
                <div className="clearfix priceCalculationInputSection">
                  {/* {PPU_inputs} */}
                  <PPUInputs
                    {...{
                      PD,
                      selectedPriceSchemesForService,
                      handlePricePerUnitChange,
                      service,
                      isWritable,
                      defaultPPPU,
                      defaultPPUChange,
                      handleDefaultPricePerUnitChange,
                      defaultRent,
                      pricePerUnit,
                      ppuChange,
                      PriceSchemesType,
                      defaultRentChange,
                    }}
                  />
                  {!service.isScope && !checkIfServiceIsHoist(service) && (
                    <span className="float-left priceCalculationInput rent-input">
                      <span className="price-input-label">Rent</span>
                      <CurrencyInput
                        disabled={!isWritable}
                        name="rent"
                        value={rent}
                        onValueChange={rentChange}
                        onBlur={(e) => {
                          handleRentPerServiceChange(
                            rent,
                            service.serviceId,
                            true
                          );
                        }}
                        placeholder="Type in price per unit"
                        // prefix={"Rent: "}
                        suffix={" %"}
                        step={1}
                        decimalScale={2}
                        defaultValue={7}
                        style={{
                          width: 70,
                          background: "#20212D",
                        }}
                      />
                    </span>
                  )}
                  {!service.isScope && !checkIfServiceIsHoist(service) && (
                    <span className="float-left priceCalculationInput rent-input">
                      <span className="price-input-label">Alternate Rent</span>
                      <CurrencyInput
                        disabled={!isWritable}
                        name="rent"
                        value={alternateRent}
                        onValueChange={alternateRentChange}
                        onBlur={(e) => {
                          handleAlternateRentPerServiceChange(
                            alternateRent,
                            service.serviceId,
                            true
                          );
                        }}
                        placeholder="Type in price per unit"
                        // prefix={"Rent: "}
                        suffix={" %"}
                        step={1}
                        decimalScale={2}
                        defaultValue={7}
                        style={{
                          width: 70,
                          background: "#20212D",
                        }}
                      />
                    </span>
                  )}
                  {statisticsView}
                  {showPricing_Button}
                  <span
                    style={{ marginRight: 10, marginLeft: 10 }}
                  >{`Tax Rate: ${
                    taxExempt === "No"
                      ? !!isTaxable
                        ? serviceTaxRate * 100
                        : 0
                      : !!isTaxable
                      ? projectTaxRate * 100
                      : serviceTaxRate * 100
                  }%`}</span>
                  <Checkbox
                    className="takeOff-checkbox"
                    // style={{ marginTop: 10 }}
                    style={{ color: "#fff" }}
                    checked={isTaxable}
                    onChange={onIsTaxableChange}
                    disabled={!isWritable || compareIncluding(taxExempt, "yes")}
                  >
                    Taxable
                  </Checkbox>
                  {/* {!service.isScope && (
                    <span>
                      <Switch
                        checkedChildren="All alternatives"
                        unCheckedChildren="Primary alternative"
                        checked={calculateForAllOptions}
                        // style={{ marginTop: 10 }}
                        onChange={(c, e) => {
                          setCalculateForAllOptions(c);
                        }}
                      />
                    </span>
                  )} */}
                </div>
              );
            }
          } //end default case
        }
      })()}
      <PriceTableSchemeModal
        visible={priceTableModalVisible}
        setVisible={setPriceTableModalVisible}
        serviceData={service}
      />
    </>
  ); //end switch (service.serviceId)

  return ""; //if any condition has been skipped
}

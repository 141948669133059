import dayjs from "dayjs";
import { Button, message } from "antd";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import React, { useEffect, useMemo, useState } from "react";

import {
  filterTables,
  getFiltersObject,
  getPanelObject,
} from "../../../../../utils";
import BasePage from "../../../BasePage";
import {
  ConfirmationModal,
  NextSteps,
  ProgressComponent,
} from "../../../../commonComponents/index";
import { fetchData } from "../../../Fleet/utils";
import { LoadableComp } from "../../../XComponents";
import { fetchAllData } from "../../../Fleet/utils/fetchAllData";
import { onDeleteClickHandler } from "../../components/utilities";
import InvoiceModal from "../../components/CreateInvoice/InvoiceModal";
import InvoicePreview from "./components/Invoice_Preview/InvoicePreview";
import InvoicesShower from "../../components/InvoiceDrower/InvoiceShower";
import {
  useNextStep,
  usePaginationControls,
  useProgressComponent,
} from "../../../../../hooks";
import { columnDefs, excelColumnsConfig } from "./AgGridData";
import BalanceReport from "../Payments/components/BalanceReport/BalanceReport";

import "./Invoice.scss";
import styleModule from "./Invoice.module.scss";
import { getExcelColumnKeys } from "../../../../../utils/getExcelColumnKeys";
import { getChartingOptions } from "./utils/getChartingOptions";

export const DataNeededForInvoiceForm = React.createContext();

export const Invoices = ({
  projectData,
  paginationPageSize = 9,
  isEditable,
  activeTab,
}) => {
  const { programFields } = useSelector((state) => state.programFields);
  const panelObject = getPanelObject(programFields, "Invoices");
  const filtersObject = getFiltersObject(programFields, "Invoices");

  const [rowData, setRowData] = useState([]);
  const [data, setData] = useState([]);
  const [loading, setLoading] = useState(false);
  const [filtersData, setFiltersData] = useState({});
  const [accounts, setAccounts] = useState([]);
  const [projects, setProjects] = useState([]);
  const [gridApi, setGridApi] = useState();
  const [selectedData, setSelectedData] = useState();
  const [showCreateInvoiceModal, setShowCreateInvoiceModal] = useState(false);
  const [clickedValue, setClickedValue] = useState();
  const [pdfVisibility, setPdfVisibility] = useState(false);
  const [previewInvoices, setPreviewInvoices] = useState([]);
  const [invoicePreviewNumber, setInvoicePreviewNumber] = useState(false);
  const [confirmModalVisible, setConfirmModalVisible] = useState();
  const [gridRef, setGridRef] = useState(null);

  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const location = useLocation();

  console.log({ rowData });
  const {
    handleStepExit,
    isNextStepOpen,
    nextStepHandler,
    savedRecord,
    setSavedRecord,
  } = useNextStep();

  const {
    rowData: hookData = [],
    statuses,
    fetchAllHandler = () => {},
    onPaginationChanged = () => {},
    reInitializeFetch = () => {},
    filterSearchData = () => {},
  } = usePaginationControls(
    "invoices",
    "invoiceId",
    setLoading,
    9,
    location?.state?.tabPosition ||
      location?.state?.state?.tabPosition ||
      activeTab ||
      "",
    filtersData,
    gridRef
  );

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Invoices", actionType: "Delete" });

  useEffect(() => {
    //we do not touch the already set data, the moment the user
    //wants to get more data, it's simply appended to the end
    if (!projectData?.projectId) {
      let tmpData = data.concat(hookData.slice(data?.length));
      setData(tmpData);
    }
  }, [hookData]);

  const searchTableHandler = async (filters) => {
    if (Object.keys(filters || {}).length > 0) {
      setData([]);
      setRowData([]);
      filterSearchData(filters);
      setFiltersData(filters);
    } else {
      setFiltersData({});
    }
  };

  useEffect(() => {
    if (!!!projectData) {
      Promise.all([
        fetchData("accounts"),
        fetchAllData("projects", "projects", "projectId"),
      ])
        .then(([accounts, projects]) => {
          setAccounts([...(accounts || [])]);
          setProjects([
            ...projects.filter(
              ({ projectStatus }) => projectStatus !== "Canceled"
            ),
          ]);
        })
        .catch((error) => {
          console.log({ error });
        });
    }
    fetchAndSetInvoices(true);
  }, [activeTab]);

  useEffect(() => {
    //Convert data into rowData that is displayed by ag-grid
    let newRowData = [];
    data?.forEach((el, index) => {
      //Calculate STATUS dueDate - current date
      const differenceInDays = parseInt(
        ((el.toDate - new Date().getTime()) / 1000) * 60 * 60 * 24
      );
      //Calculate Invoice Status to be used in the panels
      let status = calculateStatus(
        el.invoiceActivity,
        differenceInDays,
        el.invoiceStatus
      );
      newRowData.push({
        date: dayjs(el.invoiceDate).format("MM/DD/YYYY"),
        id: index + 1,
        customer: el.accountName,
        amount: el.totalInvoiceAmount,
        invoiceStatus: status,
        project: el.projectName,
        ...el,
      });
    });
    setRowData(newRowData);
  }, [data]);

  useEffect(() => onSelectBoxSelect(), [data, previewInvoices]);

  //we add a "first time" parameter to not repeat the same fetch on first page render
  async function fetchAndSetInvoices(firstTime = false) {
    setData([]);
    if (!!projectData?.projectId) {
      await filterTables("invoices", "projectId", projectData.projectId).then(
        (result) => {
          setData([...result]);
        }
      );
    } else {
      !firstTime && reInitializeFetch();
    }
  }

  const columnDefsMemo = useMemo(() => {
    return columnDefs({
      setConfirmModalVisible,
    });
  }, []);

  function calculateStatus(activities, differenceInDays, invoiceStatus) {
    let status = differenceInDays > 0 ? "Due" : "Overdue";
    return invoiceStatus !== "Created" ? invoiceStatus : status;
  }

  const exportGridToExcel = (tableColumns) => {
    gridApi.exportDataAsExcel({
      columnKeys: getExcelColumnKeys(gridApi, tableColumns),
      processCellCallback(params) {
        const value = params?.value;
        const headerName = params?.column?.userProvidedColDef?.headerName;
        if (headerName === "Categories From") {
          return value?.length || "0";
        } else if (headerName === "AMOUNT" || headerName === "OPEN BALANCE") {
          return currencyFormater(value);
        } else {
          return value === undefined ? "" : `${value}`;
        }
      },
    });
  };

  //Event listener to open Info Invoice Drawer
  const rowClickedListener = (event) => {
    setSelectedData({ ...event.data });
  };

  //////////////////////////////////////////////////
  const onSelectionChanged = (e) => {
    var selectedItems = e.api.getSelectedRows();
    if (data.length > 0) {
      var selectedInvoices = [];
      for (let i = 0; i < selectedItems.length; i++) {
        for (let j = 0; j < data.length; j++) {
          if (
            selectedItems[i].invoiceId === data[j].invoiceId &&
            selectedItems[i].invoiceNumber === data[j].invoiceNumber
          ) {
            selectedInvoices.push(data[j]);
          }
        }
      }
      setPreviewInvoices([...selectedInvoices]);
    }
  };
  //////////////////////////////////////////////////
  //ON CLICK PREVIEW ===========================================
  const onSelectBoxSelect = () => {
    if (previewInvoices.length > 0)
      setInvoicePreviewNumber({
        invoceNumber: previewInvoices[0].invoiceNumber,
        invoiceId: previewInvoices[0].invoiceId,
      });
  };
  ////////////////////////////////////////////////////
  const optionButton = (
    <>
      <BalanceReport {...{ viewFrom: "invoice", projectData }} />

      <Button
        color="Primary"
        style={{
          backgroundColor: "#1264A3",
          color: "white",
          borderRadius: 5,
          border: 0,
          cursor: !(previewInvoices?.length > 0) ? "not-allowed" : "pointer",
        }}
        onClick={() => {
          if (!(previewInvoices?.length > 0))
            return message.error("Select an Invoice first!");
          setPdfVisibility(true);
        }}
        // disabled={!(previewInvoices?.length > 0)}
      >
        Preview Invoice
      </Button>
      {/* <Button
        color="Primary"
        style={{
          backgroundColor: "#1264A3",
          color: "white",
          borderRadius: 5,
          border: 0,
          display: "grid",
          placeItems: "center",
        }}
        onClick={() => {
          fetchAndSetInvoices();
        }}
      >
        <RefreshIcon style={{ fill: "white" }} />
      </Button> */}
    </>
  );

  return (
    <>
      {/**
       * WE LEAVE THE LOADING COMPONENT SEPARATE SO THE PAGINATION HOOK
       * WON'T LOSE THE POSITION ON NEXT PAGE FETCH
       */}
      <LoadableComp loading={loading}> </LoadableComp>
      {!pdfVisibility ? (
        <>
          <div className="agGridContainer invoiceAgGrid">
            <BasePage
              {...{
                rowData,
                title: "Invoice",
                videoCategory: "Invoices",
                columnDefs: columnDefsMemo,
                clickedValue: clickedValue,
                setClickedValue: setClickedValue,
                videoLinks: true,
                getSelected: () => {},
                getGridApi: (api) => setGridApi(api),
                agGridDefs: columnDefsMemo,
                isTab: true,
                onRowClicked: rowClickedListener,
                customNew: setShowCreateInvoiceModal,
                hasFilter: true,
                hasNew: !!projectData?.projectId,
                hasStatus: true,
                detailsCell: false,
                sumKey: false,
                panelObject,
                filtersObject,
                rowSelection: "multiple",
                onSelectionChanged,
                optionButton,
                paginationPageSize,
                isEditable,
                fetchAllHandler,
                fetchAllLogsButton: true,
                statuses,
                searchTableHandler,
                allowFetchSearch: true,
                exportGridToExcel,
                defaultExcelExportParams: {
                  columnKeys: excelColumnsConfig,
                  fileName: "Invoice",
                },
                getFiltersData: (data) => {
                  setFiltersData(data);
                },
                getGridRef: (ref) => {
                  setGridRef(ref);
                },
                onPaginationChanged: () => {
                  onPaginationChanged();
                },
                getChartingOptions: (data) => getChartingOptions(data),
                filterSearchData,
                reloadGrid: () => fetchAndSetInvoices(),
              }}
            />
          </div>
          <DataNeededForInvoiceForm.Provider
            value={{
              accounts: accounts,
              projects: projects,
              setAllInvoices: setRowData,
            }}
          >
            {selectedData && !!!confirmModalVisible && (
              <InvoicesShower
                selectedData={selectedData}
                setSelectedData={setSelectedData}
                setData={setData}
                setRowData={setRowData}
                fetchAndSetInvoices={fetchAndSetInvoices}
              />
            )}
            {showCreateInvoiceModal && (
              <InvoiceModal
                projectData={projectData}
                handleExit={() => setShowCreateInvoiceModal(false)}
                fetchAndSetInvoices={fetchAndSetInvoices}
                nextStepHandler={nextStepHandler}
                setSavedRecord={setSavedRecord}
              />
            )}
          </DataNeededForInvoiceForm.Provider>
        </>
      ) : (
        <div className={styleModule.pdfContainer}>
          <InvoicePreview
            {...{
              myProps: {
                previewInvoices,
              },
              invoicePreviewNumber,
              setInvoicePreviewNumber,
              setPdfVisibility,
              fetchAndSetInvoices,
            }}
          />
        </div>
      )}
      <ConfirmationModal
        {...{
          visible: !!confirmModalVisible,
          setVisible: setConfirmModalVisible,
          onConfirm: () =>
            onDeleteClickHandler({
              setData,
              setConfirmModalVisible,
              setAllInvoices: setRowData,
              confirmModalVisible,
              onClose: setSelectedData(),
              selectedData: confirmModalVisible,
              authenticatedUser,
              projectData,
              userConfiguration,
              setVisibleCreationProgress,
              updateProgressStatus,
            }),
          onCancel: () => {
            setConfirmModalVisible(false);
          },
          text: "Are you sure you want to delete this invoice?",
          title: "Delete Invoice",
        }}
      />

      {isNextStepOpen && (
        <NextSteps
          {...{
            isOpen: isNextStepOpen,
            title: "Invoices",
            projectData,
            handleStepExit,
            savedRecord,
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Invoices",
            actionType: "Delete",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              setVisibleCreationProgress(false);
            },
          }}
        />
      )}
    </>
  );
};

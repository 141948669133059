import { message } from "antd";
import { API } from "aws-amplify";

import {
  updateAlreadyCreatedCharges,
  updateAlreadySOVCreatedCharges,
} from "./utils/updateAlreadyCreatedCharges";
import { formatCurrency } from "../../../../../utils/formatCurrency";
import { totalitiesTotalTaxGen } from "../../../Rentals/RentalsView/components/Header/Components/ControlPanel/ControlPanelFunctions";
import { calculateTotalPriceForService } from "../../../../../Accounting/components/utilities";
import { appliedRentPliCalculator } from "../../../Rentals/RentalsView/components/RentalBreakdownList/RentalBreakdown/Components/RentalFooter/RentalBreakdownFooterFunctions";
import { rentalDataExtractor } from "../../../Applications/ApplicationView/components/Header/Components/ControlPanel/ControlPanelComponents/ExportToExcel/exportToExcelFunctions";
import { updateObjectKeys } from "../../../../../../../utils/updateObjectKeys";
import { filterTables } from "../../../../../../../utils/filterTables";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { checkIfServiceIsHoist } from "../../../../../Estimations/DataEntryGrid/models/Service";

export const getTableHeaders = (
  acc,
  curr,
  chargeToSave,
  whereToFind,
  categoryFrom,
  selectedService,
  selectedGroup,
  elevationName,
  item
) => {
  const getItemsOfElevation = (array) => {
    if (
      categoryFrom === "Estimation" &&
      checkIfServiceIsHoist(selectedService)
    ) {
      const hoistSer = array?.find((service) => checkIfServiceIsHoist(service));
      if (checkIfServiceIsHoist(curr))
        return hoistSer?.serviceOptions
          ?.flat(1)
          ?.reduce((prev, curr) => [...prev, ...curr.items], []);
      return hoistSer?.serviceAddons;
    }
    return array
      ?.find(
        ({ label, isChangeOrder }) =>
          label === selectedService.service &&
          isChangeOrder === selectedService.isChangeOrder
      )
      ?.serviceOptions?.flat(1)
      .find(
        ({ elevationLabel }) =>
          elevationLabel === (elevationName || item.elevationLabel)
      )
      ?.items?.filter(Boolean);
  };
  const getCurrentPli = (() => {
    if (checkIfServiceIsHoist(selectedService))
      if (categoryFrom === "Estimation")
        if (checkIfServiceIsHoist(curr))
          return getItemsOfElevation(chargeToSave?.chargeItems)?.find(
            ({ elevationLabel }) => elevationLabel === curr?.elevationLabel
          );
        else
          return getItemsOfElevation(chargeToSave?.chargeItems)?.find(
            ({ id }) => curr?.items?.find(({ id: iID }) => iID === id)
          );
      else if (categoryFrom === "Application")
        return getItemsOfElevation(chargeToSave?.chargeItems)?.find(
          ({ event, items }) => items.find(({ id }) => id === curr?.id)
        );
    return getItemsOfElevation(chargeToSave?.chargeItems)?.find(
      ({ id }) => id === curr.id
    );
  })();
  const getDate = (first, second) =>
    categoryFrom === "Requisition"
      ? whereToFind?.periodTo?.[first]
      : getCurrentPli?.[second]
      ? acc?.[second] < getCurrentPli?.[second]
        ? acc?.[second]
        : getCurrentPli?.[second]
      : acc?.[second];

  acc.price =
    parseFloat(acc.price || 0) +
      parseFloat(
        categoryFrom === "Estimation" &&
          checkIfServiceIsHoist(selectedService) &&
          !curr?.type === "service addon"
          ? chargeToSave?.chargeItems
              ?.find(({ label }) => label === selectedService?.service)
              ?.serviceOptions?.find(
                ({ elevationId }) => elevationId === "Service Addons"
              )
              ?.items?.find(
                ({ elevationId }) => elevationId === curr?.elevationId
              )?.price || 0
          : curr?.type === "service addon"
          ? (() => {
              const currItem = chargeToSave?.chargeItems
                ?.find(({ label }) => label === selectedService?.service)
                ?.serviceOptions?.find((serviceOption) => {
                  const { elevationId } = serviceOption;
                  return (
                    elevationId === "Service Addons" &&
                    !serviceOption?.items?.find((item) => item?.elevationId)
                  );
                })
                ?.items?.find(({ id }) => id === curr?.id);
              return currItem?.price;
            })() || 0
          : parseFloat(
              getItemsOfElevation(chargeToSave?.chargeItems)?.find(
                ({ id }) => id === curr.id
              )?.[categoryFrom === "Rental" ? "price" : "price"] || 0
            )
      ) || 0;

  acc.taxAmount =
    parseFloat(acc.taxAmount || 0) +
    (categoryFrom === "Estimation" &&
    checkIfServiceIsHoist(selectedService) &&
    !curr?.type === "service addon"
      ? (() => {
          const currItem = chargeToSave?.chargeItems
            ?.find(({ label }) => label === selectedService?.service)
            ?.serviceOptions?.find(
              ({ elevationId }) => elevationId === "Service Addons"
            )
            ?.items?.find(
              ({ elevationId }) => elevationId === curr?.elevationId
            );
          return currItem?.price * currItem?.taxRate;
        })() || 0
      : curr?.type === "service addon"
      ? (() => {
          const currItem = chargeToSave?.chargeItems
            ?.find(({ label }) => label === selectedService?.service)
            ?.serviceOptions?.find((serviceOption) => {
              const { elevationId } = serviceOption;
              return (
                elevationId === "Service Addons" &&
                !serviceOption?.items?.find((item) => item?.elevationId)
              );
            })
            ?.items?.find(({ id }) => id === curr?.id);
          return currItem?.price * currItem?.taxRate || 0;
        })() || 0
      : parseFloat(
          (() => {
            const currItem = getItemsOfElevation(
              chargeToSave?.chargeItems
            )?.find(({ id }) => id === curr.id);
            return currItem?.["price"] * currItem?.taxRate;
          })() || 0
        ));

  acc.startDate = getDate("start", "startDate");
  acc.endDate = getDate("end", "endDate");

  return JSON.parse(JSON.stringify(acc));
};

export const lengthOfItems = (array, key, isLength) =>
  array?.reduce(
    (acc, curr) => (acc += isLength ? curr?.[key]?.length : curr?.[key]),
    0
  ) || 0;

export const updateData = (remaining, creditApplied) => {
  let indexToStop = creditApplied.length;
  let accumulated = 0;
  let total = creditApplied.reduce((acc, { applyAmount }) => {
    acc += applyAmount;
    return acc;
  }, 0);
  let newCredit = [];
  let totalOfIndex = 0;
  creditApplied.forEach((element, i) => {
    const { applyAmount = 0 } = element;
    accumulated += applyAmount;
    totalOfIndex += applyAmount;
    if (i < indexToStop) {
      if (total - remaining < totalOfIndex) {
        newCredit.push({
          ...element,
          applyAmount: parseFloat(
            (
              parseFloat(applyAmount) -
              parseFloat(total - remaining) +
              parseFloat(accumulated) -
              parseFloat(applyAmount)
            ).toFixed(2)
          ),
        });
        indexToStop = i;
      }
    } else {
      newCredit.push(element);
    }
  });
  return newCredit;
};
export const fullDataGroup = ({
  itemsGroup,
  chargeType,
  selectedService,
  categoryFrom,
  objectData,
  editChargeId,
}) => {
  // const checkTaxExempt = (tax) => (taxExempt === "Yes" ? 0.0 : tax);
  const checkTaxExempt = (tax) => tax;

  const items = (itemsGroup) => ({
    false: itemsGroup?.serviceOptions?.flat(1)?.reduce((acc, curr) => {
      return (acc = curr.amounts
        ? [
            ...acc,
            {
              ...curr.amounts?.map((el) => ({
                ...el,
                taxAmount: el?.thisPeriod * el?.taxRate,
              })),
              elevationLabel: curr.elevationLabel,
              id: curr.elevationLabel,
            },
          ]
        : acc);
    }, []),
    true: itemsGroup?.amounts?.flat(1)?.[chargeType]?.flat(1),
  });
  const selectedMode = objectData?.selectedMode;
  const hoistElement = itemsGroup?.[chargeType]
    ?.flat(1)
    ?.find(
      (el) => el?.[`${categoryFrom}Id`] === selectedService[`${categoryFrom}Id`]
    );
  const estimations = checkIfServiceIsHoist(itemsGroup)
    ? itemsGroup?.[chargeType]?.flat(1)?.map((el) => {
        return {
          ...el,
          invoicedPercentage:
            itemsGroup?.invoices?.reduce(
              (p, { amountPercent }) => p + (amountPercent || 0),
              0
            ) || 0,
          taxRate: checkTaxExempt(
            itemsGroup?.isTaxable ? itemsGroup?.taxRate : 0
          ),
        };
      })
    : [
        {
          elevationLabel: "Hoist",
          invoicedPercentage:
            itemsGroup?.invoices?.reduce(
              (p, { amountPercent }) => p + (amountPercent || 0),
              0
            ) || 0,
          items: itemsGroup?.[chargeType]?.flat(1)?.map((el) => ({
            ...el,
            taxRate: checkTaxExempt(itemsGroup?.isTaxable ? el?.taxRate : 0),
            taxAmount: checkTaxExempt(
              (itemsGroup?.isTaxable ? el?.taxRate : 0) * el?.price || 0
            ),
          })),
        },
      ];
  const toReturn = {
    Rental: itemsGroup?.[chargeType]?.flat(1)?.map((el) => {
      return {
        ...el,
        description: "",
        items: el?.items?.map((el) => {
          let scopeAmount;

          scopeAmount = !el?.applied
            ? 0.0
            : appliedRentPliCalculator({
                rentalDetails: el?.rentalDetails?.details || [],
              });

          let appliedAmount;
          if (editChargeId) {
            const chargedEl = el?.charges?.find(
              ({ chargeId: cI }) => editChargeId === cI && editChargeId
            );
            appliedAmount = chargedEl?.chargeAmount;
          } else {
            appliedAmount =
              scopeAmount -
              (el?.charges?.reduce(
                (prev, { chargeAmount, taxAmount }) => prev + chargeAmount,
                0
              ) || 0);
          }
          return {
            ...el,
            note: el?.description?.note,
            priceAmount: scopeAmount || 0,
            pricePercent: appliedAmount
              ? (appliedAmount / scopeAmount) * 100
              : 0,
            price: appliedAmount || 0,
            originalPrice: scopeAmount || 0,
            taxAmount: checkTaxExempt(
              appliedAmount * objectData?.whereToFind?.taxRate || 0.0
            ),
            selectedValue: appliedAmount,
            taxRate: checkTaxExempt(objectData?.whereToFind?.taxRate),
          };
        }),
      };
    }),
    Estimation: structuredClone(estimations)?.map((el) => {
      const taxRate = el.taxRate ? el.taxRate : 0;
      const invoicedPercentage = el?.invoicedPercentage || 0;

      el.items = el?.items?.map((el) => {
        const formattedPrice = forceToNumber(updateObjectKeys(el).price);
        const price = (() => {
          if (el?.charges)
            return el?.charges?.reduce(
              (prev, curr) => prev - curr.chargeAmount,
              formattedPrice
            );
          return formattedPrice;
        })();

        let toReturn = (() => {
          return selectedMode === 1
            ? {
                ...el,
                note: "",
                pricePercent: parseFloat(
                  (price / formattedPrice) * 100
                ).toFixed(2),
                totalPrice: price + price * (el?.taxRate ?? taxRate),
                taxAmount: checkTaxExempt(price * (el?.taxRate ?? taxRate)),
                taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
                price: parseFloat(price).toFixed(2),
                priceAmount: formattedPrice,
              }
            : selectedMode === 2
            ? {
                ...el,
                note: "",
                price: parseFloat(
                  (formattedPrice * objectData?.selectedPercentage) / 100
                ),
                selectedValue: parseFloat(
                  (formattedPrice * objectData?.selectedPercentage) / 100
                ),
                totalPrice: (() => {
                  const price =
                    (formattedPrice * objectData?.selectedPercentage) / 100;
                  return parseFloat(price * (el?.taxRate ?? taxRate) + price);
                })(),
                pricePercent: objectData?.selectedPercentage || 100,
                priceAmount: formattedPrice,
                taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
                taxAmount: checkTaxExempt(
                  ((formattedPrice * objectData?.selectedPercentage) / 100) *
                    (el?.taxRate ?? taxRate)
                ),
                taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
              }
            : selectedMode === 3
            ? {
                ...el,
                note: "",
                pricePercent: 0,
                totalPrice: 0,
                taxAmount: 0,
                price: 0,
                priceAmount: formattedPrice,
                taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
              }
            : editChargeId
            ? (() => {
                const prevEl = el?.charges?.find(
                  ({ chargeId: cI }) => editChargeId === cI && editChargeId
                );
                const price = prevEl?.chargeAmount || 0;
                return {
                  ...el,
                  note: prevEl?.note || el?.note,
                  price: parseFloat(price),
                  selectedValue: parseFloat(price),
                  totalPrice: (() =>
                    parseFloat(price * (el?.taxRate ?? taxRate) + price))(),
                  pricePercent: parseFloat((price / formattedPrice) * 100),
                  priceAmount: formattedPrice,
                  taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
                  ...(objectData?.type ? { invoicedPercentage } : {}),
                };
              })()
            : objectData?.type === "Credit Memo"
            ? {
                ...el,
                note: "",
                pricePercent: 0,
                totalPrice: 0,
                taxAmount: 0,
                price: 0,
                taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
                priceAmount: formattedPrice,
                invoicedPercentage,
              }
            : {
                ...el,
                pricePercent: 100,
                taxAmount: checkTaxExempt(
                  formattedPrice * (el?.taxRate ?? taxRate) + formattedPrice
                ),
                priceAmount: formattedPrice,
                taxRate: checkTaxExempt(el?.taxRate ?? taxRate),
              };
        })();
        /*
            Check if it is in Edit Mode and if it is then 
            replace the price and the tax amount with selected price in the PLI of the charge
          */
        const editCharge = objectData?.editCharge;
        if (categoryFrom === "Estimation" && editCharge) {
          editCharge?.chargeItems?.find(
            ({ serviceOptions, label, serviceId }) =>
              itemsGroup?.label === label &&
              serviceId === itemsGroup?.serviceId &&
              serviceOptions?.find(({ items }) =>
                items?.find(({ id, ...e }) => {
                  if (id === toReturn?.id)
                    toReturn = {
                      ...toReturn,
                      ...e,
                      charges: el?.charges?.filter(
                        ({ chargeId }) => chargeId !== editChargeId
                      ),
                    };
                  return id === toReturn?.id;
                })
              )
          );
        }
        return toReturn;
      });
      return el;
    }),
  };
  return toReturn;
};
export const tooltips = ({ price = 0, taxAmount = 0, type }) => [
  {
    title: (type === "Credit Memo" ? "Credit" : "Charge") + " amount: ",
    className: "titleHeaderLabel withTooltipTitle",
    value: formatCurrency(price),
  },
  {
    title: "Total tax: ",
    className: "titleHeaderLabel withTooltipTitle",
    value: formatCurrency(taxAmount),
  },
  {
    title: "Total: ",
    className: "titleHeaderLabel withTooltipTitle",
    value: formatCurrency(taxAmount + price),
  },
];

export const TooltipComp = ({ title, className, value }) => (
  <div
    key={`${title}-${value}`}
    style={{ display: "flex", minWidth: title === "Total: " ? 170 : 200 }}
  >
    <div {...{ className, style: { fontSize: "12px !important" } }}>
      {title}
    </div>
    <div {...{ className, style: { fontSize: "12px !important" } }}>
      {value}
    </div>
  </div>
);
export const calculatePriceForIncludedRentals = (includedRentals) => {
  let total = 0;
  includedRentals?.forEach(({ services }) => {
    for (const el of services) {
      let taxAmount = el?.appliedAmount * el?.taxRate || 0;
      let credit =
        (el?.serviceLevelCredit || 0) +
        (el?.serviceLevelCredit || 0) * (el?.taxRate || 0);
      let totalRentAmount = el?.appliedAmount + taxAmount + credit;
      let retainage =
        el?.retainage || 0 > 0 ? (el?.retainage / 100) * totalRentAmount : 0;
      total += totalRentAmount - retainage;
    }
  });
  return total;
};
export const updateRequisitionChargedPLI = (
  chargeId,
  type,
  services,
  chargeItems,
  ifSetCharge = true
) => {
  return services.map((oldService) => {
    for (let index = 0; index < chargeItems.length; index++) {
      const chargeService = chargeItems[index];
      if (oldService.label === chargeService.label) {
        const updatedServiceOption = (
          serviceOption,
          chargeServiceOption,
          chargeItems
        ) => {
          if (serviceOption.elevationId === chargeServiceOption.elevationId) {
            return {
              ...serviceOption,
              items: serviceOption.items.map((PLI) => {
                chargeItems.find((chargePLI) => {
                  if (PLI.id === chargePLI.id) {
                    const newChargeRec = {
                      chargeId,
                      chargeAmount: parseFloat(chargePLI?.thisPeriod),
                      taxRate: chargePLI.taxRate,
                    };
                    type === "Credit Memo" && (newChargeRec.creditAmount = 0);
                    const charges = PLI?.charges
                      ? [...PLI.charges, newChargeRec]
                      : [newChargeRec];
                    const totalPaidPrice = charges?.reduce(
                      (prev, curr) => prev + curr?.chargeAmount,
                      0
                    );
                    PLI = {
                      ...PLI,
                      charges,
                      ...(ifSetCharge
                        ? {
                            charged: true,
                          }
                        : {}),
                    };
                    return true;
                  }
                  return false;
                });
                return PLI;
              }),
            };
          }
          return serviceOption;
        };

        const updateAmount = (amount) => {
          chargeService.serviceOptions.forEach((chargeServiceOption) =>
            chargeServiceOption.items.forEach((chargePLI) => {
              if (chargePLI?.event === amount?.name) {
                amount.serviceOptions = amount?.serviceOptions?.map((el) =>
                  el?.map
                    ? el?.map((serviceOption) =>
                        updatedServiceOption(
                          serviceOption,
                          chargeServiceOption,
                          chargePLI?.items
                        )
                      )
                    : updatedServiceOption(
                        el,
                        chargeServiceOption,
                        chargePLI?.items
                      )
                );
              }
            })
          );
          return amount;
        };

        if (checkIfServiceIsHoist(oldService)) {
          oldService.serviceOptions.flat(1).forEach((serviceOption) => {
            serviceOption.amounts.forEach((amount) => {
              const chargedPLIs = chargeItems
                .find(({ label }) => label === oldService?.label)
                ?.serviceOptions?.find(
                  ({ elevationId }) => elevationId === serviceOption.elevationId
                )
                ?.items?.reduce(
                  (prev, { items, event }) => [...prev, ...items],
                  []
                );
              const isAmountCharged = chargedPLIs?.find(
                ({ id, name }) => id === amount.id && name === amount.name
              );
              if (isAmountCharged) {
                amount.charged = true;
                const newCharge = {
                  chargeId,
                  chargeAmount: isAmountCharged?.amount,
                  taxRate: oldService.taxRate,
                };
                amount?.charges
                  ? amount.charges.push(newCharge)
                  : (amount.charges = [newCharge]);
              }
            });
          });
        } else {
          return {
            ...oldService,
            amounts: oldService.amounts?.map((el) =>
              el?.map
                ? el?.map((serviceOption) => updateAmount(serviceOption))
                : updateAmount(el)
            ),
          };
        }
      }
    }
    return oldService;
  });
};
export const calCreditAmountForCharge = (chargeItems) =>
  chargeItems?.reduce(
    (creditAmount, { serviceOptions }) =>
      creditAmount +
      (serviceOptions
        ?.find(({ event }) => event === "Credit")
        ?.items?.reduce(
          (creditAmount, { amount }) => creditAmount + amount,
          0
        ) || 0),
    0
  );
export const onCellEditingStopped = ({
  e,
  rowData,
  props,
  charge,
  selectedService,
  setChargeToSave = () => {},
  setFullDataGroup = () => {},
}) => {
  const changedKey = props.colDef.field;
  let { data, newValue, oldValue } = props;
  if (!!!newValue) newValue = oldValue;
  newValue = parseFloat(newValue).toFixed(2);
  oldValue = parseFloat(oldValue).toFixed(2);

  function changeAllKeyRelatedToPriceInItem({
    newValue,
    currChangedItem,
    priceKey = "price",
  }) {
    newValue = parseFloat(newValue || 0);
    const taxAmount = parseFloat(newValue * props.data.taxRate || 0);
    if (currChangedItem) {
      currChangedItem[priceKey] = newValue;
      currChangedItem.taxAmount = taxAmount;
      currChangedItem.totalPrice = parseFloat(newValue + taxAmount);
      currChangedItem.selectedValue = newValue;
      currChangedItem.pricePercent = (
        (newValue / (data?.priceAmount === 0 ? 1 : data?.priceAmount)) *
        100
      ).toFixed(2);
    }
  }
  function changePriceInChargeToSave({ newValue, priceKey = "price" }) {
    setChargeToSave((prev) => {
      if (prev?.chargeItems?.length > 0) {
        const currChangedEle = prev?.chargeItems?.find(
          ({ label }) => label === selectedService.service
        );
        const currChangedItem = (() => {
          if (
            charge.categoryFrom === "Estimation" &&
            checkIfServiceIsHoist(currChangedEle)
          ) {
            let toReturn;
            currChangedEle.serviceOptions.find(({ items }) =>
              items.find((item) => {
                if (item.elevationId === data?.elevationId) {
                  toReturn = item;
                }
                return item.elevationId === data?.elevationId;
              })
            );
            return toReturn;
          } else {
            return currChangedEle?.serviceOptions
              ?.find(({ elevationId }) => elevationId === e.elevationId)
              ?.items?.find(({ id }) => id === data?.id);
          }
        })();
        currChangedItem &&
          changeAllKeyRelatedToPriceInItem({
            newValue,
            currChangedItem,
            priceKey,
          });
        return { ...prev };
      }
      return structuredClone(prev);
    });
  }
  function savePriceInFullDataGroup({
    newValue,
    otherValuesToAdd = {},
    priceKey = "price",
  }) {
    setFullDataGroup((prev) => {
      const currChangedItem = (() => {
        const currEle = prev?.find(
          (service) =>
            (checkIfServiceIsHoist(service) &&
              charge.categoryFrom === "Estimation" &&
              checkIfServiceIsHoist(e)) ||
            elevationId === e.elevationId
        );
        if (checkIfServiceIsHoist(currEle)) {
          let toReturn;
          currEle?.items.find((item) => {
            if (item.elevationId === data?.elevationId) {
              toReturn = item;
            }
            return item.elevationId === data?.elevationId;
          });
          return toReturn;
        }
        return currEle?.items?.find(
          ({ id, elevationId }) =>
            id === data?.id ||
            (elevationId === data?.elevationId && elevationId)
        );
      })();
      changeAllKeyRelatedToPriceInItem({ newValue, currChangedItem, priceKey });
      Object.keys(otherValuesToAdd).forEach(
        (key) => (currChangedItem[key] = otherValuesToAdd[key])
      );
      return structuredClone(prev);
      return [...(prev || [])];
    });
  }
  function priceValidation(newPrice) {
    const chargePrice =
      data?.charges?.reduce((p, c) => p + (c?.chargeAmount || 0), 0) || 0;
    if (
      parseFloat(chargePrice) +
        (data?.priceAmount * data?.invoicedPercentage || 0) / 100 +
        parseFloat(newPrice) >
      data?.priceAmount
    ) {
      message.error("Selected amount is greater scope value.");
      return true;
    }
  }
  const rowDataEl = rowData?.find(({ elevationId, id }) =>
    charge?.categoryFrom === "Estimation" &&
    checkIfServiceIsHoist(selectedService)
      ? data?.elevationId === elevationId
      : data?.id === id
  );
  if (changedKey === "price") {
    if (priceValidation(newValue)) {
      newValue = oldValue;
    }
    const newPercentage = (newValue / data?.priceAmount) * 100;
    changeAllKeyRelatedToPriceInItem({ newValue, currChangedItem: rowDataEl });
    changePriceInChargeToSave({ newValue });
    savePriceInFullDataGroup({
      newValue,
      otherValuesToAdd: { pricePercent: newPercentage },
    });
  } else if (changedKey === "pricePercent") {
    let newPrice = parseFloat((data.priceAmount * newValue) / 100);
    if (priceValidation(newPrice)) {
      newPrice = parseFloat((data.priceAmount * oldValue) / 100);
      newValue = oldValue;
    }

    changeAllKeyRelatedToPriceInItem({
      newValue: newPrice,
      currChangedItem: rowDataEl,
    });
    changePriceInChargeToSave({ newValue: newPrice });
    savePriceInFullDataGroup({
      newValue: newPrice,
      otherValuesToAdd: {
        pricePercent: parseFloat(newValue),
      },
    });
  }
};

export const onAddCredit = ({
  name,
  amount,
  service,
  note = "",
  setChargeToSave = () => {},
}) => {
  setChargeToSave((prev) => {
    const newCredit = {
      name,
      thisPeriod: -amount,
      amount: -amount,
      note,
      charged: false,
      balanceToFinish: 0,
      collectable_amount: 0,
      difference: 0,
      fromPrevious: 0,
      notes: note,
      percentageCompleated: 0,
      rate: 0,
      retainage: 0,
      retainagePercentage: 0,
      serviceAddons: [],
      serviceOptions: [],
      taxAmount: 0,
      totalCompleated: 0,
      isCredit: true,
      fromCharge: true,
    };
    let currService = prev?.chargeItems?.find(
      ({ label }) => label === service?.label
    );
    currService.totalThisPeriod -= amount;
    if (checkIfServiceIsHoist(service)) {
      currService = currService?.serviceOptions?.find(
        ({ elevationId: eI, elevationLabel: eL }) =>
          service?.elevationId === eI && service?.elevationLabel === eL
      );
      currService.totalThisPeriod -= amount;
    }

    currService?.serviceOptions?.push({
      ...newCredit,
      id: currService?.serviceOptions?.length,
    });
    return structuredClone(prev);
  });
};
export const calculateTotalForRowData = (rData, categoryFrom) => {
  if (categoryFrom === "Rental")
    return totalitiesTotalTaxGen({ fakeRentals: rData })?.totalPrice || 0;
  else
    return (
      rData?.services?.reduce((prev, curr) => {
        const { amount, taxAmount } = calculateTotalPriceForService(
          curr,
          convertCategory[categoryFrom]
        );

        return prev + amount || 0 + taxAmount || 0;
      }, 0) + calculatePriceForIncludedRentals(rData?.includedRentals)
    );
};
const convertCategory = {
  Rental: "rentals",
  Requisition: "applications",
  Estimation: "estimations",
};
export const calculateChargeStatusForCategory = ({ data, categoryFrom }) => {
  if (categoryFrom === "Requisition" || categoryFrom === "Invoice") {
    if (data?.charged) return { title: "Fully Charged" };
    return {
      title: "Not Charged",
    };
  }
  let chargedTotal = 0;
  if (categoryFrom === "Estimation") {
    data?.data?.flat(1)?.forEach((service) => {
      const { serviceOptions } = service;
      if (checkIfServiceIsHoist(service))
        serviceOptions?.flat(1)?.forEach(({ charges }) => {
          charges?.forEach(
            ({ chargeAmount, taxRate }) =>
              (chargedTotal += chargeAmount * (1 + taxRate || 0) || 0)
          );
        });
      serviceOptions?.flat(1)?.forEach(({ items }) =>
        items?.find(({ charges, items }) => {
          if (items)
            return items?.forEach(({ charges }) => {
              charges?.forEach(
                ({ chargeAmount, taxRate }) =>
                  (chargedTotal += chargeAmount * (1 + taxRate || 0) || 0)
              );
            });
          charges?.forEach(
            ({ chargeAmount, taxRate }) =>
              (chargedTotal += chargeAmount * (1 + taxRate || 0) || 0)
          );
        })
      );
    });
  }
  const totalAmount = calculateTotalForRowData(data, categoryFrom);
  const title =
    chargedTotal >= totalAmount
      ? "Fully Charged"
      : chargedTotal > 0
      ? "Practically Charged"
      : "Not Charged";
  return {
    title,
    perc: `${parseFloat((chargedTotal / totalAmount) * 100).toFixed(2)} %`,
  };
};
export const convertRequisitionIntoChargeItems = (
  requisitionObj,
  applications
) => {
  const PREV_APPLICATIONS = structuredClone(
    applications?.filter(
      (el) => el?.applicationNo < requisitionObj?.applicationNo
    )
  );
  const BEFORE_APPLICATION =
    PREV_APPLICATIONS?.find(
      (a) => a?.applicationNo === requisitionObj?.applicationNo - 1
    ) || {};

  let prevIncRentals = PREV_APPLICATIONS?.map((el) => {
    return rentalDataExtractor({
      fakeApplication: el,
    })
      ?.filter(Boolean)
      ?.sort((a, b) => (a?.monthStart > b?.monthStart ? 1 : -1))
      ?.map((e) => {
        const BEFORE_RENTAL_RET =
          BEFORE_APPLICATION?.totalities?.prevRentalsRetainage?.[
            `Rental${e?.rentalNumber}`
          ] ?? e?.retainage;
        const CURRENT_RENTAL_RET =
          requisitionObj?.totalities?.prevRentalsRetainage?.[
            `Rental${e?.rentalNumber}`
          ] ?? e?.retainage;
        return {
          ...e,
          isPrevious: true,
          applicableRetainage: BEFORE_RENTAL_RET - CURRENT_RENTAL_RET,
          retainage: CURRENT_RENTAL_RET,
        };
      });
  }).flatMap((e) => e);

  return [
    ...requisitionObj?.services?.reduce((prev, service) => {
      let { label, serviceOptions, amounts, ...el } = service;
      if (checkIfServiceIsHoist(service)) {
        serviceOptions = serviceOptions?.flat(1)?.reduce(
          (prev, { amounts, ...el }) => [
            ...prev,
            {
              ...el,
              serviceOptions: amounts,
              totalThisPeriod:
                amounts?.reduce(
                  (prev, { thisPeriod }) => prev + (thisPeriod || 0),
                  0
                ) || 0,
              totalPaymentDue:
                amounts?.reduce(
                  (prev, { paymentDue }) => prev + (paymentDue || 0),
                  0
                ) || 0,
              creditAmount:
                amounts
                  ?.filter(({ isCredit }) => isCredit)
                  ?.reduce(
                    (prev, { paymentDue }) => prev + (paymentDue || 0),
                    0
                  ) || 0,
            },
          ],
          []
        );
      }

      return checkIfServiceIsHoist(service)
        ? [
            ...prev,
            {
              ...el,
              label,
              // creditAmount: isCredit ? thisPeriod : 0,
              totalThisPeriod:
                serviceOptions?.reduce(
                  (prev, { totalThisPeriod }) => prev + totalThisPeriod,
                  0
                ) || 0,
              price:
                serviceOptions?.reduce(
                  (prev, { totalPaymentDue }) => prev + totalPaymentDue,
                  0
                ) || 0,
              totalPaymentDue:
                serviceOptions
                  ?.flat(1)
                  ?.reduce(
                    (prev, { totalPaymentDue }) => prev + totalPaymentDue,
                    0
                  ) || 0,
              creditAmount:
                serviceOptions?.reduce(
                  (prev, { creditAmount }) => prev + creditAmount,
                  0
                ) || 0,
              serviceOptions,
            },
          ]
        : [
            ...prev,
            {
              ...el,
              label,
              creditAmount:
                amounts
                  ?.filter(({ isCredit }) => isCredit)
                  ?.reduce(
                    (prev, { paymentDue }) => prev + (paymentDue || 0),
                    0
                  ) || 0,
              serviceOptions: amounts,
              totalThisPeriod: amounts?.reduce(
                (prev, { thisPeriod }) => prev + thisPeriod,
                0
              ),
              price:
                amounts?.reduce(
                  (prev, { paymentDue }) => prev + paymentDue,
                  0
                ) || 0,
              totalPaymentDue: amounts?.reduce(
                (prev, { paymentDue }) => prev + paymentDue,
                0
              ),
            },
          ];
    }, []),
    ...requisitionObj?.includedRentals?.reduce(
      (prev, { services, rentalId }) => {
        services?.forEach((el) => {
          let taxAmount = el?.appliedAmount * el?.taxRate || 0;
          let credit =
            (el?.serviceLevelCredit || 0) +
            (el?.serviceLevelCredit || 0) * (el?.taxRate || 0);
          let totalRentAmount =
            (el?.appliedAmount || 0) + (taxAmount || 0) + (credit || 0);
          let retainage =
            (el?.retainage || 0) > 0
              ? (el?.retainage / 100) * totalRentAmount || 0
              : 0;
          const total = (totalRentAmount || 0) - (retainage || 0);
          prev?.push({
            ...el,
            credit,
            retainage,
            taxAmount,
            totalRentAmount,
            totalThisPeriod: totalRentAmount,
            price: total - taxAmount,
            rentalId,
            total: total - taxAmount,
          });
        });
        return prev;
      },
      []
    ),
    ...prevIncRentals?.map((el) => ({
      ...el,
      taxAmount:
        ((el?.amountForThisMonth * el?.applicableRetainage) / 100) *
          el?.taxRate || 0,
      total: (el?.amountForThisMonth * el?.applicableRetainage) / 100,
    })),
  ];
};
export const validateIfPercentageIsAllowed = ({
  percentageToSet,
  items,
  chargeId,
}) =>
  items?.some(({ charges, priceAmount, invoicedPercentage }) => {
    const chargedAmount =
      charges
        ?.filter(({ chargeId: cID }) => chargeId !== cID)
        ?.reduce((p, c) => p + (c?.chargeAmount || 0), 0) || 0;
    return (
      chargedAmount +
        ((priceAmount * invoicedPercentage) / 100 || 0) +
        (priceAmount * percentageToSet) / 100 >
      priceAmount
    );
  });

export const findLargestPercentageAllowed = ({ items, chargeId }) =>
  items?.reduce(
    (maxAllowedPercentage, { charges, priceAmount, invoicedPercentage }) => {
      const chargedAmount =
        charges
          ?.filter(({ chargeId: cID }) => cID !== chargeId)
          ?.reduce((p, c) => p + (c?.chargeAmount || 0), 0) || 0;
      const percentage =
        ((priceAmount -
          chargedAmount -
          ((priceAmount * invoicedPercentage) / 100 || 0)) /
          priceAmount) *
        100;
      return percentage < maxAllowedPercentage
        ? percentage
        : maxAllowedPercentage;
    },
    100
  );

export const onElevationPercentageChange = ({
  e,
  setFullDataGroup,
  item,
  chargeId,
  setChargeToSave = () => {},
  itemsGroup,
}) => {
  let newValue = e?.target?.value;

  if (!!!newValue) return;
  const validationNotPassed = validateIfPercentageIsAllowed({
    percentageToSet: newValue,
    items: item?.items,
    chargeId,
  });
  if (validationNotPassed) {
    const maxAllowedPercentage = findLargestPercentageAllowed({
      items: item?.items,
      chargeId,
    });
    e.target.value = maxAllowedPercentage;
    newValue = maxAllowedPercentage;
    message?.error("Percentage is not allowed");
  }

  const updateItems = (toChangeServiceOption) => {
    toChangeServiceOption?.items?.forEach((item) => {
      item.pricePercent = newValue;
      item.price = (item?.priceAmount * newValue) / 100;
      item.selectedValue = (item?.priceAmount * newValue) / 100;
      item.taxAmount = ((item?.priceAmount * newValue) / 100) * item?.taxRate;
      item.totalPrice =
        ((item?.priceAmount * newValue) / 100) * item?.taxRate +
        (item?.priceAmount * newValue) / 100;
    });
  };

  setFullDataGroup((prev) => {
    const changedElevation = prev?.find(
      ({ elevationId }) => elevationId === item?.elevationId
    );
    updateItems(changedElevation);
    // selectAllRowsForElevation(changedElevation?.items);
    return structuredClone(prev);
  });
  setChargeToSave((prev) => {
    const toChangeServiceOptions = prev?.chargeItems
      ?.find(({ label }) => label === itemsGroup?.label)
      ?.serviceOptions?.flat();
    let toChangeServiceOption;
    if (checkIfServiceIsHoist(itemsGroup))
      toChangeServiceOption = toChangeServiceOptions?.[0] || {};
    else
      toChangeServiceOption = toChangeServiceOptions?.find(
        ({ elevationId }) => elevationId === item?.elevationId
      );

    updateItems(toChangeServiceOption);
    return structuredClone(prev);
  });
};
//Changes state of charge on each PLI that has been effected on creating this charge,
//Sets charged to fully || partially || NuN
//Updates the charges array which stores each charge on each PLI

const transformPLIFromCat = (
  isItemCharged,
  item,
  chargeId,
  chargeType,
  ifSetCharge,
  categoryFrom
) => {
  if (isItemCharged) {
    const newChargeRec = {
      chargeId,
      chargeAmount: isItemCharged.price,
      taxRate: isItemCharged.taxRate,
      taxAmount: isItemCharged?.taxAmount,
      chargeType,
    };
    chargeType === "Credit Memo" && (newChargeRec.creditAmount = 0);
    const charges = item?.charges
      ? [...item.charges, newChargeRec]
      : [newChargeRec];
    const totalPaidPrice = charges?.reduce(
      (prev, curr) => prev + curr?.chargeAmount,
      0
    );
    item.charges = charges;
    if (ifSetCharge) {
      item.charged =
        totalPaidPrice >=
        (categoryFrom === "Rental" ? item.appliedAmount : item.price)
          ? "fully"
          : totalPaidPrice === 0
          ? NaN
          : "partially";
    }
  }
};

export const updateChargedPLI = ({
  chargeId,
  chargeType,
  services,
  categoryFrom,
  chargeItems,
  ifSetCharge = true,
}) =>
  chargeItems.map((chargeItem) =>
    services
      ?.find(({ label }) => label === chargeItem?.label)
      ?.serviceOptions?.flat(1)
      ?.forEach((el) => {
        const { elevationId, items } = el;

        if (
          categoryFrom === "Estimation" &&
          checkIfServiceIsHoist(chargeItem)
        ) {
          chargeItem?.serviceOptions?.flat(1)?.find((option) => {
            if (option?.elevationId === elevationId) {
              transformPLIFromCat(
                option,
                el,
                chargeId,
                chargeType,
                ifSetCharge,
                categoryFrom
              );
            }
            return option?.elevationId === elevationId;
          });
        } else {
          items?.forEach((item) => {
            const isItemCharged = chargeItem?.serviceOptions
              ?.flat(1)
              ?.find((option) => option.elevationId === elevationId)
              ?.items?.find((chargedItem) => chargedItem?.id === item?.id);
            transformPLIFromCat(
              isItemCharged,
              item,
              chargeId,
              chargeType,
              ifSetCharge,
              categoryFrom
            );
          });
        }
      })
  );

export const updateChargedAddons = ({
  chargeId,
  chargeType,
  services,
  categoryFrom,
  chargeItems,
  ifSetCharge = true,
}) =>
  chargeItems.map((chargeItem) =>
    services
      ?.find(({ label }) => label === chargeItem?.label)
      ?.serviceAddons?.forEach((addon) => {
        const { id } = addon;
        const isItemCharged = chargeItem?.serviceAddons?.find(
          (chargedItem) => chargedItem?.id === id
        );
        transformPLIFromCat(
          isItemCharged,
          addon,
          chargeId,
          chargeType,
          ifSetCharge,
          categoryFrom
        );
      })
  );

export const updateChargedRentalTerm = ({
  chargeId,
  chargeType,
  services,
  categoryFrom,
  chargeItems,
  ifSetCharge = true,
}) =>
  chargeItems.map((chargeItem) => {
    const service = services?.find(({ label }) => label === chargeItem?.label);
    const rentalTerm = service?.additionalRentalTerms;
    if (rentalTerm) {
      const isItemCharged = chargeItem?.additionalRentalTerms;

      transformPLIFromCat(
        isItemCharged,
        rentalTerm,
        chargeId,
        chargeType,
        ifSetCharge,
        categoryFrom
      );
    }
  });

export const updateChargedSOVEvents = ({
  chargeId,
  chargeType,
  services,
  categoryFrom,
  chargeItems,
  ifSetCharge = true,
}) => {
  return chargeItems.map((chargeItem) => {
    if (checkIfServiceIsHoist(chargeItem)) {
      return services
        ?.find(({ label }) => label === chargeItem?.label)
        ?.serviceOptions?.flat(1)
        ?.forEach((el) => {
          const { elevationId } = el;

          return chargeItem?.serviceOptions?.flat(1)?.find((option) => {
            if (option?.elevationId === elevationId) {
              transformPLIFromCat(
                option,
                el,
                chargeId,
                chargeType,
                ifSetCharge,
                categoryFrom
              );
            }
            return option?.elevationId === elevationId;
          });
        });
    } else {
      return chargeItem?.amounts?.forEach((item) => {
        const isItemCharged = item.charges?.length > 0;
        transformPLIFromCat(
          isItemCharged,
          item,
          chargeId,
          chargeType,
          ifSetCharge,
          categoryFrom
        );
      });
    }
  });
};

export const updatePLIsFromCategory = async ({
  chargeId,
  recordId,
  chargeType,
  services,
  categoryFrom,
  chargeItems,
  whereToFind,
  tableFrom,
  selectedData,
  selectedGroup,
  chargeNumber,
  chargeAmount,
}) => {
  if (categoryFrom === "Estimation" || categoryFrom === "Rental") {
    updateChargedPLI({
      chargeId,
      chargeType,
      services,
      categoryFrom,
      chargeItems,
    });

    updateChargedAddons({
      chargeId,
      chargeType,
      services,
      categoryFrom,
      chargeItems,
    });

    updateChargedRentalTerm({
      chargeId,
      chargeType,
      services,
      categoryFrom,
      chargeItems,
    });
    const charges = whereToFind?.charges || [];

    const newCharges = [
      ...charges?.filter(({ chargeId: cID }) => chargeId !== cID),
      { chargeId, chargeNumber, chargeType, chargeAmount },
    ];

    if (categoryFrom === "Estimation") {
      const updatedServices = updateAlreadyCreatedCharges(
        services,
        chargeItems,
        chargeId,
        chargeType
      );
      API.put("projects", `/projects/${whereToFind.projectId}`, {
        body: {
          services: {
            ...whereToFind.services,
            [selectedGroup]: updatedServices,
          },
          charges: newCharges,
        },
      }).then(() => {
        filterTables("charges", "projectId", whereToFind.projectId).then(
          (projectCharges) => {
            if (projectCharges.length > 0) {
              const thisCategoryCharges = projectCharges.filter(
                (charge) => charge.categoryFrom === categoryFrom
              );

              return (
                thisCategoryCharges.length > 0 &&
                thisCategoryCharges
                  .filter((charges) => charges.chargeId !== chargeId)
                  .forEach((charge) => {
                    const updatedChargeItems = updateAlreadyCreatedCharges(
                      charge.chargeItems,
                      chargeItems,
                      chargeId,
                      chargeType
                    );
                    API.put("charges", `/charges/${charge.chargeId}`, {
                      body: {
                        chargeItems: updatedChargeItems,
                      },
                    });
                  })
              );
            }
          }
        );
      });
    }

    const isEstimation = categoryFrom === "Estimation";

    const currentEstimation = await filterTables(
      isEstimation ? "estimations" : "rentals",
      isEstimation ? "estimationId" : "rentalId",
      recordId
    );

    const oldCategoryCharges = currentEstimation?.[0]?.charges || [];

    const newCategoryCharges = [
      ...oldCategoryCharges?.filter(({ chargeId: cID }) => chargeId !== cID),
      { chargeId, chargeNumber, chargeType, chargeAmount },
    ];

    API.put(tableFrom, `/${tableFrom}/${recordId}`, {
      body: {
        services,
        charges: newCategoryCharges,
      },
    });
  } else if (categoryFrom === "Requisition") {
    API.put(tableFrom, `/${tableFrom}/${recordId}`, {
      body: {
        charged: chargeId,
      },
    });
    whereToFind?.includedRentals?.forEach(({ rentalId }) => {
      API.put("rentals", `/rentals/${rentalId}`, {
        body: {
          chargedFromReq: recordId,
          charges: [chargeId],
          charged: false,
        },
      });
    });
  } else if (categoryFrom === "Schedule Of Value") {
    const charges = whereToFind?.charges || [];

    const newCharges = [
      ...charges?.filter(({ chargeId: cID }) => chargeId !== cID),
      { chargeId, chargeNumber, chargeType, chargeAmount },
    ];

    const updatedServices = updateAlreadySOVCreatedCharges(
      services,
      chargeItems,
      chargeId,
      chargeType
    );

    filterTables("charges", "projectId", whereToFind.projectId).then(
      (projectCharges) => {
        if (projectCharges.length > 0) {
          const thisCategoryCharges = projectCharges.filter(
            (charge) => charge.categoryFrom === categoryFrom
          );

          return (
            thisCategoryCharges.length > 0 &&
            thisCategoryCharges
              .filter((charges) => charges.chargeId !== chargeId)
              .forEach((charge) => {
                const updatedChargeItems = updateAlreadySOVCreatedCharges(
                  charge.chargeItems,
                  chargeItems,
                  chargeId,
                  chargeType
                );
                API.put("charges", `/charges/${charge.chargeId}`, {
                  body: {
                    chargeItems: updatedChargeItems,
                  },
                });
              })
          );
        }
      }
    );

    const currentScheduleOfValues = await filterTables(
      "scheduleOfValues",
      "scheduleId",
      recordId
    );

    const oldCategoryCharges = currentScheduleOfValues?.[0]?.charges || [];

    const newCategoryCharges = [
      ...oldCategoryCharges?.filter(({ chargeId: cID }) => chargeId !== cID),
      { chargeId, chargeNumber, chargeType, chargeAmount },
    ];

    API.put(tableFrom, `/${tableFrom}/${recordId}`, {
      body: {
        services: updatedServices,
        charges: newCategoryCharges,
      },
    });
  }
  return services;
};

import { message, Popover, Tooltip } from "antd";
import { NotificationConfigIcon } from "../../../../../../assets";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { API } from "aws-amplify";
import { useDispatch } from "react-redux";
import { preferences } from "../../../../../../actions";

function NotificationConfigDropdown() {
  const [outsideAppNotifications, setOutsideAppNotifications] = useState(false);
  const [allowNotifications, setAllowNotifications] = useState(true);
  const { preferences: prefs } = useSelector((state) => state.preferences);

  const dispatch = useDispatch();

  const requestNotificationPermission = () => {
    if (!("Notification" in window)) {
      message.info("This browser does not support desktop notification");
    } else if (Notification?.permission !== "denied") {
      try {
        Notification.requestPermission().then((permission) => {
          if (permission === "granted") {
            setOutsideAppNotifications(true);
            const notification = new Notification(
              "You will get notifications every time!"
            );
            console.log({ notification });
          } else {
            message.warning("Permission for notifications denied.");
          }
        });
      } catch (error) {
        message.info(`error2 ${error}`);
        console.log({ error });
      }
    } else {
      message.info(`Notifications by default are ${Notification?.permission}`);
    }
  };

  const updatePushNotifications = () => {
    const updatedPreferences = {
      preferences: {
        ...prefs.preferences,
        notifications: {
          ...prefs.preferences.notifications,
          allowNotifications: !allowNotifications,
        },
      },
    };

    API.put("preferences", "/preferences", {
      body: updatedPreferences,
    }).then(() => {
      dispatch(
        preferences({ ...prefs, preferences: updatedPreferences.preferences })
      );

      !allowNotifications
        ? message.success("You will get notifications in real time!")
        : message.warning("Notifications are disabled!");
    });
  };

  useEffect(() => {
    const timeout = setTimeout(() => {
      setOutsideAppNotifications(Notification?.permission === "granted");
    }, 1000);

    return () => clearTimeout(timeout);
  }, []);

  useEffect(() => {
    prefs &&
      setAllowNotifications(
        prefs.preferences.notifications.hasOwnProperty("allowNotifications")
          ? prefs.preferences.notifications?.allowNotifications
          : true
      );
  }, [prefs.preferences.notifications?.allowNotifications]);

  const { isDarkMode } = useSelector((state) => state.darkMode);

  return (
    <div className="icon-container">
      <Tooltip title="Notification Config" placement="left">
        <Popover
          content={ConfigPopoverContent({
            requestNotificationPermission,
            updatePushNotifications,
            isDarkMode,
            allowNotifications,
            outsideAppNotifications,
          })}
          placement="bottom"
          // title={label}
          trigger="click"
          overlayClassName={`popoverLinkOverview ${
            isDarkMode && "darkPopoverLinkOverview"
          }`}
        >
          <NotificationConfigIcon className="header-icon" />
        </Popover>
      </Tooltip>
    </div>
  );
}

export default NotificationConfigDropdown;

function ConfigPopoverContent({
  requestNotificationPermission,
  updatePushNotifications,
  isDarkMode,
  allowNotifications,
  outsideAppNotifications,
}) {
  return (
    <div className={`linksContainer ${isDarkMode && "linksContainerDark"}`}>
      {!outsideAppNotifications ? (
        <div
          className="popoverContentItem"
          onClick={requestNotificationPermission}
        >
          Allow Notifications
        </div>
      ) : null}
      <div className="popoverContentItem" onClick={updatePushNotifications}>
        {allowNotifications ? " Disable " : " Enable "}
        in app notifications
      </div>
    </div>
  );
}

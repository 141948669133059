import { useEffect, useState } from "react";
import { Breadcrumb, Form, message } from "antd";
import { useSelector } from "react-redux";
import { PageHeader } from "@ant-design/pro-layout";

import {
  UserList,
  TourGuide,
  CreateNewUser,
  PasswordConfirmation,
  OneTimePasswordConfirmation,
  NewUserTeamSelect,
} from "./components";
import { SignupFormData } from "./SignUpCompData";

import "./SignupComp.scss";
import "../../components/pages/Settings/settingsComponents/Roles/RolesSections.scss";
import EditNewUserAccess from "../../components/pages/Settings/settingsComponents/AllUsers/components/NewUserModal/components/EditNewUserAccess";
import { useWatch } from "antd/es/form/Form";

const Signup = () => {
  const [form] = Form.useForm();
  const [user, setUser] = useState();
  const [roles, setRoles] = useState([]);
  const [openedCards, setOpenedCards] = useState({
    left: false,
    right: false,
    animate: false,
  });

  const { isDarkMode } = useSelector((state) => state.darkMode);
  const { userGroups } = useSelector((state) => state.userGroups);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const [accessModalVisible, setAccessModalVisible] = useState(false);
  const department = useWatch("department", form);
  const role = useWatch("role", form);

  const allUsers = userConfiguration?.allUsers?.Items || [];

  const departments = Array.from(
    new Set(userGroups.map((group) => group.departmentName))
  );

  const departmentFields = departments.map((department) => ({
    departmentName: department,
    roles: userGroups
      .filter((item) => item.departmentName === department)
      .map((item) => item.groupName),
  }));

  const setRoleOptions = (value) => {
    setRoles(
      departmentFields.find(({ departmentName }) => departmentName === value)
        .roles
    );
  };

  const populatedFields = SignupFormData(
    departments,
    roles,
    setRoleOptions,
    form,
    isDarkMode,
    allUsers,
    setAccessModalVisible,
    departmentFields
  );

  const onAccessChange = (value) => {
    console.log("onAccessChange ~ value", value);
  };

  function getRecordsToBePartOf(records) {
    console.log("getRecordsToBePartOf ~ records", records);
  }

  return (
    <>
      <div className={`SettingsBody ${isDarkMode && "SettingsBodyDark"}`}>
        <PageHeader ghost={false} className="settingsPageHeader">
          <Breadcrumb
            items={[{ title: "User Management" }, { title: "New User" }]}
          />
          <TourGuide {...{ setOpenedCards }} />
        </PageHeader>
        <Form form={form}>
          <div className="formContainer">
            <>
              {!openedCards.left ? (
                <CreateNewUser
                  {...{ populatedFields, setOpenedCards, openedCards }}
                />
              ) : (
                <NewUserTeamSelect
                  {...{ user, form, getRecordsToBePartOf, setOpenedCards }}
                />
              )}

              {!!user ? (
                <OneTimePasswordConfirmation {...{ form, setUser }} />
              ) : (
                <PasswordConfirmation {...{ form, populatedFields, setUser }} />
              )}
            </>
            {/* {accessModalVisible && (
              <EditNewUserAccess
                {...{
                  visible: accessModalVisible,
                  onCancel: () => setAccessModalVisible(false),
                  department,
                  currentRole: role,
                  onConfirm: onAccessChange,
                }}
              />
            )} */}
            <UserList />
          </div>
        </Form>
      </div>
    </>
  );
};
export default Signup;

import React from "react";
// import "./ConversationContent.scss";
import "./ChatMessage.scss";
import { Avatar, Popover } from "antd";
import { getInitials } from "../../../../../../utils";
import {
  DeleteIcon,
  EditIcon,
  ForwardIcon,
  ReplyIcon,
  ThreeVerticalMessageIcon,
} from "../../../../assets/icons";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
const ChatMessage = (props) => {
  const {
    id,
    message,
    sender,
    time,
    replyTo,
    edited,
    forwarded,
    userConfiguration,
    setReplyTo,
    setForwardTo,
  } = props;

  const threeDotsActions = () => [
    {
      label: "Reply",
      field: "reply",
      icon: <ReplyIcon />,
      action: () => setReplyTo({ message, sender, id }),
    },
    {
      label: "Forward",
      field: "forward",
      icon: <ForwardIcon />,
      action: () => setForwardTo({ message, sender, id }),
    },
    {
      label: "Edit",
      field: "edit",
      icon: <EditIcon />,
      action: () => {},
    },
    {
      label: "Delete",
      field: "delete",
      icon: <DeleteIcon width={16} height={16} />,
      action: () => {},
    },
  ];

  return (
    <div
      className={`chat-message ${
        userConfiguration.nameOfUser !== sender
          ? "user-message"
          : "other-message"
      }`}
    >
      <Avatar shape="circle" className="avatar-sender">
        {getInitials(sender)}
      </Avatar>
      <div className="message-content">
        {!!forwarded && "Forwarded"}
        <div className="sender-name">
          {sender}{" "}
          <Popover
            trigger="hover"
            placement={"bottom"}
            overlayClassName="threeDotsPopoverChat"
            content={
              <div className="threeDotsPopoverChatContent">
                {threeDotsActions().map((action) => {
                  return (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "space-between",
                      }}
                      className={`threeDotsPopoverChatItem ${
                        action.field === "delete" ? "delete" : ""
                      }`}
                      key={action.field}
                      onClick={action.action}
                    >
                      <span>{action.label}</span>
                      {action.icon}
                    </div>
                  );
                })}
              </div>
            }
          >
            {<ThreeVerticalMessageIcon cursor={"pointer"} />}
          </Popover>
        </div>
        <div className="message-body">
          <div className="message-text">
            {replyTo && (
              <div className="reply-to">
                <p>Replying to: {replyTo.message}</p>{" "}
                {/* Display the original message */}
              </div>
            )}
            <span className="inner-text-container">{message}</span>
            <span className="space-right">
              &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
            </span>
          </div>
          <div className="inline-date">{dayjsNY(time).format("HH:mm")}</div>
        </div>
      </div>
    </div>
  );
};

export default ChatMessage;

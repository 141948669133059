import { fetchData } from "../../components/SidebarPages/Fleet/utils";

export const allUsers = async () => {
  try {
    const data = await fetchData("userConfiguration", {
      ProjectionExpression: [
        "cognitoUserId",
        "departmentName",
        "groupName",
        "is2FaEnabled",
        "isSuspended",
        "nameOfUser",
        "userName",
        "identityId",
      ],
    });

    return data;
  } catch (error) {
    console.error("Data source User Invitations error: ", error);
  }
};

import { useWatch } from "antd/es/form/Form";
import { MondayButton } from "../../../components/commonComponents";
import { InputComponent } from "../../../components/SidebarPages/Fleet/components";
import { useMemo } from "react";
import { RenderDynamicComponents } from "../../../components/Header/forms/Components";

function SelectRole({
  roles,
  form,
  setAccessModalVisible,
  isDarkMode,
  departmentFields,
  disabled,
}) {
  const department = useWatch("department", form);
  const role = useWatch("role", form);

  const field = [
    {
      label: "Role",
      required: true,
      formItemName: "role",
      placeholder: "Select",
      className: "formElement",
      type: "select",
      dropdownClassName: isDarkMode ? "darkDropDown" : "",
      options: roles || [],
      disabled,
    },
  ];
  const buttonVisible = department && role && setAccessModalVisible;
  return (
    <div
      style={{ display: "flex", alignItems: "center" }}
      className={`role-input-container ${buttonVisible && "withButton"}`}
    >
      {RenderDynamicComponents(field, form)}
      {buttonVisible && (
        <MondayButton
          {...{
            style: { marginTop: 5 },
            hasIcon: false,
            className: "mondayButtonBlue",
            onClick: () => setAccessModalVisible(true),
          }}
        >
          Edit Access
        </MondayButton>
      )}
    </div>
  );
}

export default SelectRole;

import getDegTotals from "./getDegTotals";
import getWeeksTotal from "./getWeeksTotal";

export function getRolesCost({ analytics, crews }) {
  const { employeesHoursPerDay } = analytics;
  let roleSet = new Set();
  let groupedEmployees = {};
  let res = [];

  for (const employeeId in employeesHoursPerDay) {
    groupedEmployees[employeeId] = _.groupBy(
      Object.keys(employeesHoursPerDay[employeeId]),
      (key) => employeesHoursPerDay[employeeId][key]["employeeRole"]
    );

    Object.keys(groupedEmployees[employeeId]).forEach((employeeRole) => {
      roleSet.add(employeeRole);
    });
  }

  const roles = roleSet.entries();
  for (const [employeeRole] of roles) {
    let totalEntriesPerRole = {};

    for (const employeeId in groupedEmployees) {
      totalEntriesPerRole[employeeId] = {};
      let daysForRole = groupedEmployees[employeeId][employeeRole] || [];
      for (const day of daysForRole) {
        totalEntriesPerRole[employeeId][day] = {
          jobsitesInfo: [
            ...employeesHoursPerDay[employeeId][day]["jobsitesInfo"],
          ],
        };
      }
    }

    const weeksTotalsForRole = getWeeksTotal({
      workerList: Object.keys(totalEntriesPerRole),
      allEntries: totalEntriesPerRole,
    });

    const totalPerRole = getDegTotals({
      employeeWeekTotals: weeksTotalsForRole,
    });
    let details = [];

    for (const employeeId in totalEntriesPerRole) {
      const employeeActivity = totalEntriesPerRole[employeeId];
      if (Object.keys(employeeActivity)?.length) {
        let weeksTotalEmployee = getWeeksTotal({
          workerList: [employeeId],
          allEntries: totalEntriesPerRole,
        });

        let totalsPerEmployee = getDegTotals({
          employeeWeekTotals: weeksTotalEmployee,
        });

        const employee = crews.find((crew) => employeeId === crew?.employeeId);
        details.push({
          ...employee,
          hoursWorked: totalsPerEmployee?.totalWorkHours,
          regAmount: totalsPerEmployee?.totalReg,
          otAmount: totalsPerEmployee?.totalOt,
          totalAmount: totalsPerEmployee?.total,
          totalOverhead: totalsPerEmployee?.totalOvh,
          totalOvhHours: totalsPerEmployee?.totalOvhHours,
          totalWorkHoursWOvh: totalsPerEmployee?.totalWorkHoursWOvh,
          totalOtHours: totalsPerEmployee?.totalOtHours,
          totalWOvh: totalsPerEmployee?.totalWOvh,
          totalReimbursement: totalsPerEmployee?.totalReimbursement,
        });
      }
    }
    let newEntry = {
      employeeRole,
      total: totalPerRole?.total,
      totalOt: totalPerRole?.totalOt,
      totalOvh: totalPerRole?.totalOvh,
      totalWorkHours: totalPerRole?.totalWorkHours,
      totalOtHours: totalPerRole?.totalOtHours,
      totalOvhHours: totalPerRole?.totalOvhHours,
      totalWOvh: totalPerRole?.totalWOvh,
      totalWorkHoursWOvh: totalPerRole?.totalWorkHoursWOvh,
      totalReimbursement: totalPerRole?.totalReimbursement,
      details,
    };
    res.push(newEntry);
  }
  return res;
}

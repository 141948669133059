import { Space, Table, Tag } from "antd";
import React from "react";

const data2 = [
  {
    key: "1",
    name: "John Brown",
    age: 32,
    address: "New York No. 1 Lake Park",
    tags: ["nice", "developer"],
  },
  {
    key: "2",
    name: "Jim Green",
    age: 42,
    address: "London No. 1 Lake Park",
    tags: ["loser"],
  },
  {
    key: "3",
    name: "Joe Black",
    age: 32,
    address: "Sydney No. 1 Lake Park",
    tags: ["cool", "teacher"],
  },
];
const columns = [
  {
    title: "Name",
    dataIndex: "calendarName",
    key: "calendarName",
    render: (text, record) => (
      <a>
        <Tag color={`#${record.backgroundColor}`}>{text}</Tag>
      </a>
    ),
  },
  {
    title: "Event Name",
    dataIndex: "summary",
    key: "summary",
  },
  {
    title: "Start",
    dataIndex: "formatedStartDate",
    key: "formatedStartDate",
  },
  {
    title: "End",
    dataIndex: "eventEnd",
    key: "eventEnd",
  },
  {
    title: "Scope",
    dataIndex: "scope",
    key: "scope",
  },
  {
    title: "PM",
    dataIndex: "projectManager",
    key: "projectManager",
  },
  {
    title: "Crews",
    dataIndex: "crews",
    key: "crews",
    render: (text) => (
      <Space size="middle">
        <Tag color={text === 0 ? "red" : "blue"} key={text}>
          {text === 0 ? "N/A" : text}
        </Tag>
      </Space>
    ),
  },
  {
    title: "View Event",
    dataIndex: "htmlLink",
    key: "htmlLink",
    render: (text) => (
      <a href={text} target="_blank" rel="noreferrer">
        View Event
      </a>
    ),
  },
];
function AdvancedExportTable({ data = [] }) {
  console.log("rowdaata", data);
  return (
    <div
      style={{
        height: "450px",
      }}
    >
      <Table columns={columns} dataSource={data} scroll={{ y: `450px` }} />
    </div>
  );
}

export default AdvancedExportTable;

import { getParams } from "../../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  let inspType = new Set();
  let inspStatus = new Set();
  let projectExecutive = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const type = item.inspectionType || "No Data";
    const status = item.inspectionStatus || "No Data";
    const pExecutive = item.projectExecutive || "No Data";
    const user = item.createdBy.name || "No Data";

    inspType.add(type);
    inspStatus.add(status);
    projectExecutive.add(pExecutive);
    createdBy.add(user);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total Inspections count based on Inspection Type",
      dataKey: "inspectionType",
      params: getParams({
        data,
        chartData: inspType,
        chartKey: "inspectionType",
        label: "Type",
        text: "Total Projects amount based on Inspection Type",
      }),
    },
    {
      optionTitle: "Show total Inspections count based on Inspection Status",
      dataKey: "inspectionStatus",
      params: getParams({
        data,
        chartData: inspStatus,
        chartKey: "inspectionStatus",
        label: "Status",
        text: "Total Projects amount based on Inspection Status",
      }),
    },
    {
      optionTitle: "Show total Inspections count based on Project Executive",
      dataKey: "projectExecutive",
      params: getParams({
        data,
        chartData: inspStatus,
        chartKey: "inspectionStatus",
        label: "Project Executive",
        text: "Total Projects amount based on Project Executive",
      }),
    },
    {
      optionTitle: "Show total Inspections count based on Created By",
      dataKey: "createdBy.name",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy.name",
        label: "Created By",
        text: "Total Projects amount based on Created By",
      }),
    },
  ];

  return chartingOptions;
};

import { Tooltip } from "antd";
import { XIconWhite } from "../../SidebarPages/Communication/assets";

function MaxTagTooltip({ array = [], onDeselect, hasDeselect = true }) {
  return (
    <Tooltip
      title={() => (
        <div style={{ display: "flex", flexDirection: "column", gap: 10 }}>
          {array.map(({ label = "", value = "" }, i) => (
            <span
              key={i}
              style={{ display: "flex", gap: 10, alignItems: "center" }}
            >
              {label}{" "}
              {hasDeselect ? (
                <XIconWhite
                  width={10}
                  height={10}
                  onClick={() => onDeselect(value, {})}
                />
              ) : null}
            </span>
          ))}
        </div>
      )}
    >
      <span>{`+ ${array?.length} more`}</span>
    </Tooltip>
  );
}

export default MaxTagTooltip;

import { message } from "antd";
import { getBase64 } from "../../Incidents/Modals/utils/getBase64Pdf";
import { createPdfBody } from "../../Incidents/Modals/utils/helpers";
import {
  imageForPdf,
  mapImages,
  docDefinition,
} from "../../Incidents/Modals/utils/incidentsPdf";
import { compareIncluding } from "../../utils";

export async function handleEmailAttachments({
  base64,
  accessToken,
  category = "",
  driveRequest,
  rowObject = {},
  driveFunctions,
  folderDriveKeyName = "",
  createPdfParams = {
    dynamicFields: [],
    selectedTeam: [],
    crews: [],
    userConfiguration: {},
    defaultId: "",
    witnessCount: null,
    witnessSignings: [],
  },
  setAttachments,
  rowObjectKey,
  pdfFileName = "",
}) {
  const images = await imageForPdf({ rowObject, accessToken });
  const imagePdfData = mapImages(images);
  let companyLogo = base64?.find(({ fileName }) =>
    compareIncluding(fileName, "Core Logo Black")
  )?.base64;

  const createDocDef = docDefinition(
    `${rowObject?.incidentName} ${category} Form`,
    createPdfBody({
      ...createPdfParams,
      rowObject,
      rowObjectKey,
    })?.concat(imagePdfData),
    companyLogo
  );
  const pdfDocGenerator = pdfMake.createPdf(createDocDef);

  const existedObject = await getBase64({
    pdfDocGenerator,
    name: rowObject?.projectName,
  });

  let fileList = [];

  fileList.push(existedObject);

  const pdfFiles = await Promise.all(
    fileList?.map(async (el) => {
      const resolvedFileSrc = await (typeof el.fileSrc === "string"
        ? Promise.resolve(el.fileSrc)
        : el.fileSrc);

      return {
        src: resolvedFileSrc,
        id: el.uid,
        name: el.name,
        size: el.size,
      };
    })
  );

  for (const file of pdfFiles) {
    const uploadedPdfFile = await uploadPdfFileToDrive({
      file,
      pdfFileName,
      driveRequest,
      folderDriveKeyName,
      driveFunctions,
      rowObject,
    });

    if (uploadedPdfFile) {
      setAttachments((prevAttachments) => [
        ...prevAttachments,
        uploadedPdfFile,
      ]);
    } else {
      message.error("No uploaded pdf file was found!");
    }
  }
}

export async function uploadPdfFileToDrive({
  file,
  pdfFileName,
  driveRequest,
  folderDriveKeyName,
  driveFunctions,
  rowObject,
}) {
  const blob = base64ToBlob(file.src, "application/pdf");

  try {
    const res = await driveRequest.uploadExcelFile(blob, blob.type, {
      name: pdfFileName,
      writersCanShare: true,
      parents: [
        driveRequest,
        rowObject?.googleDriveFolderIds?.[folderDriveKeyName] ||
          driveFunctions?.parentFolderId,
      ],
    });

    const data = await res.json();
    const { id, mimeType, name } = data;

    if (blob && id && mimeType && name) {
      return {
        id,
        type: mimeType,
        name,
        blob,
        size: blob?.size,
      };
    }
  } catch (error) {
    console.error(["uploadPdfFile_error"], error);
  }
}

export function base64ToBlob(base64, mimeType = "") {
  const byteCharacters = atob(base64.split(",")[1]);
  const byteNumbers = new Array(byteCharacters.length);

  for (let i = 0; i < byteCharacters.length; i++) {
    byteNumbers[i] = byteCharacters.charCodeAt(i);
  }

  const byteArray = new Uint8Array(byteNumbers);
  return new Blob([byteArray], { type: mimeType });
}

export async function handleGoogleDriveUpload(file, driveRequest) {
  try {
    const res = await driveRequest.getImageSrc(file.id);
    const blob = base64ToBlob(res.src, "application/pdf");
    const uploadedFile = await driveRequest.getDriveItem(file.id);
    const data = await uploadedFile.json();
    const { id, name, mimeType } = data;

    if (!id || !data) {
      return;
    }

    return {
      id,
      type: mimeType,
      name,
      blob: blob,
      size: blob?.size,
    };
  } catch (error) {
    console.error("[incidents_helpers_getting_uploaded_files_src]", error);
  }
}

import { MondayButton } from "../../../commonComponents";
import { TickIcon } from "../../../pages/Settings/settingsComponents/Roles/src";
import { XIcon } from "../../Communication/assets";
import { DeleteIcon, LogsIcon } from "../../DynamicView/src";
import IndividualLoader from "src/components/IndividualLoader";
import { getRelatedToRecords, relatedToAccess } from "./helpers";
import { EmailIcon } from "../../BasePage/src";
import ButtonDropdown from "../../../commonComponents/MondayButtonDropDown/ButtonDropdown";
import { PdfIcon } from "../../Documentation/View/assets";
import { TasksIconNew } from "../../../../icons";
import { message } from "antd";
import dayjs from "dayjs";
import SelectWithCustomInputs from "../../../commonComponents/SelectWithCustomInputs/SelectWithCustomInputs";
import { CopyOutlined } from "@ant-design/icons";
import { isDueTimeInPast } from "./helpers/isDueTimeInPast";
import { dayjsUserTimeZone } from "../../../DateComponents/contants/DayjsUserTimeZone";

/**
 *
 * @param {Object} form - the form instance object from antd
 * @param {Array} records - the records that are related to the selected relatedTo option
 * @param {String} recordId - the id of the record we are viewing the to do's for
 * @param {String} category - the category of the to do
 * @param {String} relatedTo - the related to option selected
 * @param {Boolean} disabled - whether the fields are disabled or not
 * @param {Array} programFields - the program fields
 * @param {Boolean} isDarkMode - whether the dark mode is enabled or not
 * @param {Array} topicCategories - the topic categories
 * @param {Function} setRecords - function to set the records for the related to option
 * @param {Boolean} loadingRecords - whether the records are loading or not
 * @param {Object} userConfiguration - the user configuration
 * @param {Function} setRelatedTo - function to set the related to option
 * @param {Function} setLoadingRecords - function to set the loading records
 * @param {Array} toDoSubtopics - the to do subtopics
 * @returns
 */
export const inputFields = ({
  form,
  records = [],
  recordId = "",
  category = "",
  relatedTo = "",
  disabled = false,
  programFields = [],
  isDarkMode = false,
  topicCategories = [],
  setRecords = () => {},
  loadingRecords = false,
  userConfiguration = {},
  setRelatedTo = () => {},
  setLoadingRecords = () => {},
  setChanges = () => {},
  toDoSubtopics = [],
  isCreate = false,
  rowObject = {},
  clonedTodo,
}) => {
  let relatedToOptions = programFields
    ?.find((e) => e?.fieldName === "To Do Subtopics")
    ?.fieldOptions?.find((e) => {
      if (!!category) {
        return e?.topicName === category?.capitalize();
      } else {
        return (
          e?.topicName ===
          window.location.pathname.split("/")?.[1]?.capitalize()
        );
      }
    })
    ?.subTopics?.map(({ subTopicName = "", ...rest }) => {
      return {
        label: subTopicName,
        value: subTopicName,
        filterData: { ...rest },
      };
    });

  const topicObjects = topicCategories?.reduce(
    (acc, curr) => ({
      ...acc,
      [curr?.categoryName]: {
        table: curr?.apiName,
        searchKey: curr?.rowName,
        idKey: curr?.primaryKey,
      },
    }),
    {}
  );

  return [
    {
      label: "Title",
      formItemName: "todoName",
      type: "input",
      required: true,
      disabled: !!clonedTodo ? !clonedTodo : disabled,
    },
    {
      label: "Category",
      formItemName: "toDoCategory",
      type: "select",
      disabled: true,
      className: "related-to",
    },
    {
      label: "Category Related To",
      formItemName: "categoryRelatedTo",
      type: "input",
      disabled: true,
      type: "select",
      className: "related-to",
    },
    {
      label: "SubCategory Related To",
      formItemName: "relatedTo",
      type: "select",
      customComponent: SelectWithCustomInputs,
      componentProps: {
        label: "SubCategory Related To",
        defaultValue: relatedTo,
        getRecords: (value) => {
          getRelatedToRecords({
            value,
            records,
            recordId,
            setRecords,
            topicObjects,
            relatedToOptions,
            setLoadingRecords,
            userConfiguration,
          });
          setRelatedTo(value);
          setChanges(true);
        },
        defaultOptions: relatedToAccess({
          relatedToOptions,
          userConfiguration,
        }).map((item) => item.value),
      },
      onSelect: (value) => {
        setLoadingRecords(true);
        getRelatedToRecords({
          value,
          records,
          recordId,
          setRecords,
          topicObjects,
          relatedToOptions,
          setLoadingRecords,
          userConfiguration,
        });
        setRelatedTo(value);
      },
      disabled: !!clonedTodo ? !clonedTodo : disabled,
      className: "related-to",
      customOptions: relatedToAccess({ relatedToOptions, userConfiguration }),
    },
    !!topicObjects[relatedTo] && {
      // records?.length > 0 &&
      label: "Related Record",
      formItemName: "relatedRecord",
      type: "select",
      disabled: !!clonedTodo ? !clonedTodo : disabled,
      allowSearch: true,
      className: "related-to",
      // notFoundContent: "",
      dropdownRender: (menu) => {
        return (
          <>
            {menu}
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                padding: "0px 10px",
              }}
            >
              {loadingRecords ? (
                <div
                  style={{
                    display: "flex",
                    justifyContent: "center",
                    margin: "20px",
                  }}
                >
                  <IndividualLoader />
                </div>
              ) : null}
            </div>
          </>
        );
      },
      customOptions: records,
    },
    {
      label: "Due Date",
      form,
      isDarkMode,
      formItemName: "dueDate",
      validTime: "future",
      customOnChange: (event) => {
        const today = dayjs().format("MM/DD/YYYY");
        const existedDate = dayjsUserTimeZone(rowObject?.todoDueDate).format(
          "MM/DD/YYYY"
        );

        // Resets the "dueTime" field based on specific conditions related to event and date status.
        if (
          (!isCreate &&
            (existedDate === event ||
              (event === today && existedDate !== today))) ||
          (!!isCreate && event === today)
        ) {
          form.resetFields(["dueTime"]);
        }
      },
      type: "dateinput",
      className: "date-input",
      disabledDate: (current) => {
        const createOrEdit = current < dayjsUserTimeZone().subtract(1, "day");

        return createOrEdit;
      },
      required: true,
    },
    {
      label: "Due Time",
      form,
      isDarkMode,
      validTime: "future",
      formItemName: "dueTime",
      dateField: "dueDate",
      type: "timeinput",
      className: "time-input",
      disabledHours: (current) => {
        const { isDateInPast, isTimeInPast } = isDueTimeInPast(
          current,
          form.getFieldValue("dueDate")
        );

        // Resets "dueDate" and "dueTime" if the date is in the past, shows an error and resets "dueTime" if only the time is in the past.
        if (!!isDateInPast) {
          form.resetFields(["dueDate", "dueTime"]);
        } else if (!!isTimeInPast) {
          message.error("Cannot set time in the past");
          form.resetFields(["dueTime"]);
        }
      },
      required: true,
    },
  ]?.filter(Boolean);
};

/**
 * Renders the footer buttons for the To Do form.
 *
 * @param {Object} form - The form object.
 * @param {string|null} todoId - The ID of the To Do (optional).
 * @param {Function} setWarningModal - The function to set the warning modal.
 * @param {boolean} changes - Indicates if there are changes in the form.
 * @param {boolean} allFieldsDisabled - Indicates if all fields are disabled.
 * @param {Function} setLogsVisible - The function to set the visibility of logs.
 * @param {Function} setVisible - The function to set the visibility of the form.
 * @param {Function} closeModal - The function to close the modal.
 * @param {Function} onCreatePdf - The function to create a PDF.
 * @param {Function} onEmail - The function to send an email.
 * @param {Function} setTasksVisible - The function to set the visibility of tasks.
 * @returns {ReactElement[]} The array of footer buttons.
 */
export function footerButtons({
  form,
  todoId = null,
  setWarningModal = () => {},
  changes = false,
  allFieldsDisabled = false,
  setLogsVisible = () => {},
  setVisible = () => {},
  closeModal = () => {},
  handleCloneTodo = () => {},
  onCreatePdf = () => {},
  onEmail = () => {},
  setTasksVisible = () => {},
  cloneModal,
  setExportPreview = () => {},
}) {
  return [
    <div className="buttonContainer" key="cancel-button">
      <MondayButton
        {...{
          className: "mondayButtonRed",
          Icon: <XIcon />,
          onClick: closeModal,
          tooltipCategory: "To Do",
          tooltipKey: "cancel",
        }}
      >
        Cancel
      </MondayButton>
      <div style={{ display: "flex", gap: "10px" }} key="right-side-buttons">
        {!!todoId && !cloneModal && (
          <>
            <MondayButton
              className="mondayButtonBlue"
              onClick={() => {
                setTasksVisible(true);
              }}
              Icon={<TasksIconNew />}
              tooltipCategory="To Do"
              tooltipKey="showTasks"
            >
              Tasks
            </MondayButton>
            <MondayButton
              Icon={<CopyOutlined />}
              onClick={handleCloneTodo}
              className="mondayButtonBlue"
              tooltipKey="cloneTask"
            >
              Clone
            </MondayButton>
            <ButtonDropdown
              {...{
                customOverlayClassName: null,
                title: "Actions",
                dropDownOptions: [
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "20px",
                          alignContent: "center",
                          width: "70px",
                        }}
                      >
                        <p>Logs</p>
                        <div style={{ width: "20px", alignSelf: "center" }}>
                          <LogsIcon fill={"#000"} />
                        </div>
                      </div>
                    ),
                    onClick: () => {
                      setLogsVisible(true);
                    },
                  },
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "32px",
                        }}
                      >
                        <p>Email</p>
                        <div style={{ width: "20px", alignSelf: "center" }}>
                          <EmailIcon />
                        </div>
                      </div>
                    ),
                    onClick: onEmail,
                  },
                  {
                    label: (
                      <div
                        style={{
                          display: "flex",
                          justifyContent: "space-between",
                          alignItems: "center",
                          height: "32px",
                        }}
                      >
                        <p>PDF</p>
                        <div style={{ width: "20px", alignSelf: "center" }}>
                          <PdfIcon />
                        </div>
                      </div>
                    ),
                    // onClick: onCreatePdf,
                    onClick: () => {
                      setExportPreview(true);
                    },
                  },
                ],
                trigger: ["click"],
                tooltipCategory: "To Do",
                tooltipKey: "exportActions",
              }}
            />
            <MondayButton
              {...{
                className: "mondayButtonRed",
                Icon: <DeleteIcon />,
                id: "deleteToDoButton",
                tooltipCategory: "To Do",
                tooltipKey: "delete",
                onClick: () => {
                  setWarningModal({
                    visible: true,
                    type: "delete",
                    message: "Are you sure you want to delete this To Do?",
                  });
                },
              }}
            >
              Delete
            </MondayButton>
          </>
        )}
        <MondayButton
          {...{
            id: "saveToDoButton",
            className: "mondayButtonGreen",
            Icon: <TickIcon />,
            disabled: !!cloneModal ? false : !changes || allFieldsDisabled,
            tooltipCategory: "To Do",
            tooltipKey: "save",
            onClick: () => {
              // Handles click event to validate date and time, prompts for confirmation based on whether editing or creating a todo.
              const time = form.getFieldValue("dueTime");
              const date = form.getFieldValue("dueDate");
              if (!!todoId) {
                !time || !date
                  ? message.error(`Due ${!date ? "Date" : "Time"} is required!`)
                  : setWarningModal({
                      visible: true,
                      type: "edit",
                      message: "Do you want to save these changes?",
                    });
              } else {
                form.validateFields()?.then(() => {
                  setWarningModal({
                    visible: true,
                    type: "save",
                    message: "Do you want to create this To Do?",
                  });
                });
              }
            },
          }}
        >
          Save
        </MondayButton>
      </div>
    </div>,
  ];
}

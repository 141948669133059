import React, { createContext, useState, useContext } from "react";

const DocumentationTypesContext = createContext(); // Context for managing documentation types and related files

export const useDocumentationTypes = () => {
  return useContext(DocumentationTypesContext);
};

export const DocumentationTypesProvider = ({ children }) => {
  const [documentationTypes, setDocumentationTypes] = useState([]);
  const [documentationTypesFiles, setDocumentationTypesFiles] = useState([]);

  return (
    <DocumentationTypesContext.Provider
      value={{
        documentationTypes,
        setDocumentationTypes,
        documentationTypesFiles,
        setDocumentationTypesFiles,
      }}
    >
      {children}
    </DocumentationTypesContext.Provider>
  );
};

import {
  FieldTimeOutlined,
  LeftOutlined,
  RightOutlined,
  SmileOutlined,
} from "@ant-design/icons";
import { Button, message, Modal, Select, DatePicker, Result } from "antd";
import { API } from "aws-amplify";
import axios from "axios";
import { on } from "events";
import FileSaver from "file-saver";
import { groupBy } from "lodash";
import React, { useEffect } from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import Swal from "sweetalert2";
import {
  CheckIconModal,
  CloseIconModalHeader,
  ExcelIcon,
  ExcelIconWhite,
} from "../../../../../../assets";
import { useProgramFields } from "../../../../../../hooks";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import { MondayButton, Stepper } from "../../../../../commonComponents";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import { forceToNumber } from "../../../../../SidebarPages/Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { DriveIcon } from "../../../../../SidebarPages/Communication/assets";
import { base64ToBlob } from "../../../../../SidebarPages/Projects/utils/email-attachments-helpers";
import { executeCalendarList } from "../../googleClientAPI/execute";
import AdvancedExportTable from "./AdvancedExportTable";
import {
  extractNumberBeforeCrew,
  extractWordAfterDash,
  extractWordAfterScope,
  getColorFromIdEvents,
  htmlToPlainText,
  predictScheduleType,
  removeDashAndWordAfter,
} from "./dataFactory";
import { getEventsForCalendars } from "./utils";
const { RangePicker } = DatePicker;

function AdvancedExportModal({ visible, onClose }) {
  const [current, setCurrent] = useState(0);
  const [selectedCalendars, setSelectedCalendars] = useState([]);
  const [selectedTimeInterval, setSelectedTimeInterval] = useState(null);
  const [allEvents, setAllEvents] = useState([]);
  const [availableCalendars, setAvailableCalendars] = useState([]);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { ["Calendar Events Google Drive"]: driveIds, ...rest } =
    useProgramFields();

  const handleChange = (value) => {
    setSelectedCalendars(value);
  };

  // Time change handler
  const onIntervalChange = (value) => {
    console.log("value", value);
    setSelectedTimeInterval(value);
  };

  const onFinish = async (saveToDrive) => {
    console.log("driveIds", driveIds);
    console.log("onFinish", selectedCalendars, selectedTimeInterval);
    console.log("availableCalendars", availableCalendars);
    let fileName = "GoogleEventsExport" + ".xlsx";
    let allEvents = await getEventsForCalendars({
      arrOfCalendarIDs: selectedCalendars,
      startDate: dayjsNY(selectedTimeInterval?.[0]).format(),
      endDate: dayjsNY(selectedTimeInterval?.[1]).format(),
      showDeleted: false,
    });
    const priorityOrder = {
      7: 1, // Highest priority
      9: 2, // Second priority
      8: 3, // Lowest priority
    };
    const getPriority = (refNumber) => priorityOrder[refNumber] || Infinity;
    allEvents = allEvents
      .map((event) => {
        let currCalendar = availableCalendars.find(
          (item) => item?.id === event?.organizer?.email
        );
        const noOfCrews = extractNumberBeforeCrew(event?.description || "");

        console.log("currCalendar", { currCalendar, event, noOfCrews });
        console.log("event2323", event?.description);
        let plainDesc = htmlToPlainText(event?.description);
        let scopeExtracted = extractWordAfterScope(event?.description || "");
        return {
          ...(event || {}),
          sortVal: dayjsNY(event?.start?.dateTime).valueOf(),
          eventStart: dayjsNY(event?.start?.dateTime).format(
            "MM/DD/YYYY HH:mm"
          ),
          formatedStartDate: dayjsNY(event?.start?.dateTime).format(
            "MM/DD/YYYY"
          ),
          eventTime: dayjsNY(event?.start?.dateTime).format("HH:mm"),
          // eventEnd: dayjsNY(event?.end?.dateTime).format("MM/DD/YYYY HH:mm"),
          eventStatus: event?.status,
          scope: scopeExtracted,
          crews: forceToNumber(extractNumberBeforeCrew(plainDesc || "") || 1),
          projectManager: extractWordAfterDash(event?.summary || ""),
          summary: removeDashAndWordAfter(event?.summary || ""),
          calendarName: currCalendar?.summary,
          backgroundColor: getColorFromIdEvents(event?.colorId),
          colorId: event?.colorId ?? 7,
          foregroundColor: "FFFFFF",
          fullNote: plainDesc || "",
          scheduleType: predictScheduleType(scopeExtracted, event?.description),
          id: event?.id.includes("_") ? event?.id.split("_")[0] : event?.id,
          // backgroundColor: currCalendar?.backgroundColor?.replace("#", ""),
          // foregroundColor: currCalendar?.foregroundColor?.replace("#", ""),
        };
      })
      .sort((a, b) => a.sortVal - b.sortVal);
    setAllEvents(allEvents);
    let toSend = groupBy(allEvents, "formatedStartDate");
    Object.keys(toSend).forEach((key) => {
      let values = toSend[key];
      toSend[key] = values.sort((a, b) => {
        const priorityA = getPriority(a.colorId || 7);
        const priorityB = getPriority(b.colorId || 7);

        // First sort by colorId priority
        if (priorityA !== priorityB) {
          return priorityA - priorityB;
        }

        // If priorities are the same, sort by name
        if (a.projectManager < b.projectManager) {
          return -1;
        } else if (a.projectManager > b.projectManager) {
          return 1;
        } else {
          return 0;
        }
      });
    });

    const hideLoading = message.loading(
      "File is being processed. Please wait...",
      0
    );
    let globalB64 = null;
    API.post("exportEvents", "/exportEvents", {
      body: {
        events: toSend,
        exportedAt: dayjsNY().format("MM/DD/YYYY HH:mm:ss"),
        startDate: dayjsNY(selectedTimeInterval?.[0]).format("MM/DD/YYYY"),
        endDate: dayjsNY(selectedTimeInterval?.[1]).format("MM/DD/YYYY"),
      },
    })
      .then(async (response) => {
        hideLoading();
        console.log("response", response);

        const base64String = response.body;

        // Convert the Base64 string to a Blob
        const base64ToBlob = (base64, type) => {
          const byteCharacters = atob(base64);
          const byteNumbers = new Array(byteCharacters.length);
          for (let i = 0; i < byteCharacters.length; i++) {
            byteNumbers[i] = byteCharacters.charCodeAt(i);
          }
          const byteArray = new Uint8Array(byteNumbers);
          return new Blob([byteArray], { type });
        };

        const base64 = base64String.split(",")[1] || base64String;
        const blob = base64ToBlob(
          base64,
          "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"
        );
        // globalBlob = blob;
        globalB64 = base64;
        if (!saveToDrive) {
          FileSaver.saveAs(blob, "GoogleEventsExport.xlsx");
          message.success("Export successful.");
        }
        // here to attach to google drive
        if (saveToDrive) {
          if (!accessToken) {
            message.error("No access to save to Google Drive!");
            return;
          }
          message.loading("Uploading to Google Drive...", 0);
          var b64 = base64;
          const driveRequest = driveApi({ accessToken });
          try {
            // return;

            const response = await driveRequest.getFilesByFolderId(
              driveIds?.[0]
            );
            const data = await response.json();
            const filesToDelete = data.files.filter(
              (item) =>
                !item?.name?.includes("Pre-schedule raport") &&
                !item?.name?.includes("REFERENCE EVENTS FILE") &&
                !item?.name?.includes("Test")
            );
            console.log("filesToDelete", filesToDelete);
            console.log("filesToDelete", filesToDelete);
            if (filesToDelete.length === 0) {
              console.log("No files found matching the criteria.");
              // return;
            }
            for (const file of filesToDelete) {
              try {
                await driveRequest.deleteDriveItemPromise(file.id);
                console.log(`Deleted file: ${file.name} (ID: ${file.id})`);
              } catch (error) {
                console.error(
                  `Failed to delete file: ${file.name} (ID: ${file.id})`,
                  error
                );
              }
            }
          } catch (error) {
            console.error("Failed to fetch files or process deletion.", error);
          }

          // return;
          await driveRequest
            .uploadFile(
              b64,
              "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;base64,",
              {
                name: "TEMPORARY FILE.xlsx",
                parents: [driveIds?.[0]],
                //   mimeType:
                mimeType: "application/vnd.google-apps.spreadsheet",
              },
              true
            )
            .then((response) => response.json())
            .then(async (data) => {
              // response.json();
              console.log("data", data);
              const queryParams = {
                sourceSpreadsheetId: data.id,
                sourceSheetName: "ExportedEvents", // Name of the sheet to copy data from
                destinationSpreadsheetId:
                  "1mA92oiRkajKahJ5xUHq9_Vcy2CtiS2Nq4dx99y9g6nU",
                destinationSheetName: "ExportedEvents", // Name of the destination sheet
                accessToken: accessToken,
              };

              await driveRequest.replaceSheetData(queryParams);
              console.log("data", data);
              message.destroy();
              Swal.fire({
                title: "Your new updates have been saved to Google Drive!",
                showDenyButton: true,
                showCancelButton: false,
                icon: "info",
                confirmButtonColor: "#3085d6",
                confirmButtonText: "Go to Google Drive",
                denyButtonText: `Don't leave!`,
              }).then((result) => {
                /* Read more about isConfirmed, isDenied below */
                if (result.isConfirmed) {
                  window.open(
                    `https://drive.google.com/drive/folders/${driveIds?.[0]}`
                  );
                }
              });
            });
        }
      })
      .catch((err) => {
        hideLoading();
        console.error(err);
        message.error("Something went wrong...");
        message.destroy();
      });
  };

  const onTimeShortcut = (type) => {
    switch (type) {
      case "today": {
        setSelectedTimeInterval([dayjsNY(), dayjsNY()]);
        break;
      }
      case "thisWeek": {
        setSelectedTimeInterval([
          dayjsNY().startOf("week"),
          dayjsNY().endOf("week"),
        ]);
        break;
      }
      case "theseTwoWeeks": {
        setSelectedTimeInterval([
          dayjsNY().startOf("week"),
          dayjsNY().endOf("week").add(1, "week"),
        ]);
        break;
      }
      case "thisMonth": {
        setSelectedTimeInterval([
          dayjsNY().startOf("month"),
          dayjsNY().endOf("month"),
        ]);
        break;
      }
      case "thisHalfYear": {
        setSelectedTimeInterval([
          dayjsNY().startOf("year"),
          dayjsNY().startOf("year").add(6, "month"),
        ]);
        break;
      }
      case "thisYear": {
        setSelectedTimeInterval([
          dayjsNY().startOf("year"),
          dayjsNY().endOf("year"),
        ]);
        break;
      }
      case "lastYear": {
        setSelectedTimeInterval([
          dayjsNY().subtract(1, "year").startOf("year"),
          dayjsNY().subtract(1, "year").endOf("year"),
        ]);
        break;
      }
      default: {
        setSelectedTimeInterval([
          dayjsNY().startOf("week"),
          dayjsNY().endOf("week"),
        ]);
        break;
      }
    }
  };

  const steps = [
    {
      title: "Pick Calendar/s and Time Interval",
      content: (
        <div
          className="pick_calendar_wrapper"
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "18px",
          }}
        >
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "9px",
            }}
          >
            <h4
              style={{
                lineHeight: "1.5",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "2px",
              }}
            >
              Select Calendar/s
            </h4>
            <Select
              mode="multiple"
              placeholder="Please select calendar/s"
              allowClear
              style={{ width: "100%" }}
              value={selectedCalendars}
              defaultValue={selectedCalendars}
              onChange={handleChange}
              options={availableCalendars.map((item) => ({
                label: item.summary,
                value: item.id,
              }))}
            />{" "}
          </div>
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              gap: "8px",
              width: "1400px",
            }}
          >
            {" "}
            <h4
              style={{
                lineHeight: "1.5",
                display: "flex",
                justifyContent: "flex-start",
                marginLeft: "2px",
              }}
            >
              Select time interval -
              {selectedTimeInterval?.length > 0 &&
                " You have selected " +
                  forceToNumber(
                    dayjsNY(selectedTimeInterval?.[1]).diff(
                      dayjsNY(selectedTimeInterval?.[0]),
                      "days"
                    ) + 1
                  ) +
                  " days."}{" "}
            </h4>
            <div
              style={{
                display: "flex",
                flexDirection: "row",
                gap: "8px",
              }}
            >
              <RangePicker
                width={300}
                onChange={onIntervalChange}
                value={selectedTimeInterval}
                format="MM/DD/YYYY"
              />{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("today")}
                Icon={<FieldTimeOutlined />}
              >
                Today
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisWeek")}
                Icon={<FieldTimeOutlined />}
              >
                This Week
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("theseTwoWeeks")}
                Icon={<FieldTimeOutlined />}
              >
                These Two Weeks
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisMonth")}
                Icon={<FieldTimeOutlined />}
              >
                This Month
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisHalfYear")}
                Icon={<FieldTimeOutlined />}
              >
                This Half Year
              </MondayButton>{" "}
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("thisYear")}
                Icon={<FieldTimeOutlined />}
              >
                This Year
              </MondayButton>
              <MondayButton
                className="mondayButtonBlue"
                onClick={() => onTimeShortcut("lastYear")}
                Icon={<FieldTimeOutlined />}
              >
                Last Year
              </MondayButton>
            </div>
          </div>{" "}
        </div>
      ),
    },
    // {
    //   title: "Review Time Interval Options",
    //   content: (
    //     <div className="pick_time_interval_holder">
    //       {" "}
    //       <RangePicker
    //         onChange={onIntervalChange}
    //         value={selectedTimeInterval}
    //         format="MM/DD/YYYY"
    //       />
    //       <div>
    //         You have selected{" "}
    //         {dayjsNY(selectedTimeInterval?.[1]).diff(
    //           dayjsNY(selectedTimeInterval?.[0]),
    //           "days"
    //         )}{" "}
    //         days
    //       </div>
    //     </div>
    //   ),
    // },
    {
      title: "Successfully Exported",
      content: (
        <div className="choose_source_wrapper">
          <AdvancedExportTable data={allEvents} />
        </div>
      ),
    },
  ];
  const items = steps.map((item) => ({ key: item.title, title: item.title }));
  const next = () => {
    if (current === 0) {
      console.log("selectedCalendars", selectedCalendars);
      console.log("selectedTimeInterval", selectedTimeInterval);
      onFinish(false);
      setCurrent(current + 1);
    } else {
      setCurrent(current + 1);
    }
  };

  const prev = () => {
    setCurrent(current - 1);
  };

  useEffect(() => {
    if (visible) {
      executeCalendarList().then((res) => {
        console.log("calendarList", res);
        setAvailableCalendars(res?.result?.items || []);
      });
    }
  }, [visible]);

  return (
    <Modal
      open={visible}
      onCancel={onClose}
      title={"Advanced Export Events"}
      width={1800}
      closeIcon={<CloseIconModalHeader />}
      footer={null}
      maskClosable={false}
    >
      <div
        style={{
          width: "1750px",
          height: "650px",
        }}
      >
        <Stepper
          current={current}
          items={items}
          stepRenderer={false}
          type="navigation"
          componentContainerClassName="proposalBuilderWrfapper"
        />{" "}
        <div className="antStepperHolder" style={contentStyle}>
          {steps[current].content}
        </div>{" "}
      </div>
      <div
        style={{
          marginTop: 24,
          width: "100%",
          justifyContent: "flex-end",
          display: "flex",
          gap: "8px",
        }}
      >
        {current > 0 && (
          <MondayButton
            className="mondayButtonBlue"
            noTooltip={true}
            onClick={() => prev()}
            Icon={<LeftOutlined />}
          >
            Previous
          </MondayButton>
        )}{" "}
        {current >= 0 && current < steps.length - 1 && (
          <MondayButton
            noTooltip={true}
            className="mondayButtonBlue"
            onClick={() => next()}
            Icon={<RightOutlined />}
          >
            Next
          </MondayButton>
        )}
        {current === steps.length - 1 && (
          <MondayButton
            noTooltip={true}
            className="mondayButtonBlue"
            Icon={<ExcelIconWhite />}
            onClick={() => onFinish(false)}
          >
            Download Excel File
          </MondayButton>
        )}
        {current === steps.length - 1 && (
          <MondayButton
            noTooltip={true}
            className="mondayButtonBlue"
            Icon={<DriveIcon />}
            onClick={() => onFinish(true)}
          >
            SAVE TO GOOGLE DRIVE
          </MondayButton>
        )}
      </div>
    </Modal>
  );
}

export default AdvancedExportModal;
const contentStyle = {
  lineHeight: "260px",
  textAlign: "center",
  // width: "960px",
  minWidth: "1300px",
  // color: token.colorTextTertiary,
  // backgroundColor: token.colorFillAlter,
  // borderRadius: token.borderRadiusLG,
  // border: `1px dashed ${token.colorBorder}`,
  marginTop: 16,
};

const teamPopoverConfirm = ({
  isSelectedIndex,
  team,
  membersToAdd,
  membersToRemove,
  selectedTeamTmp,
  setSelectedTeamTmp,
  setMembersToAdd,
  setMembersToRemove,
}) => {
  const selectedTeamTmpCopy = [...selectedTeamTmp];
  if (isSelectedIndex > -1) {
    if (membersToAdd.length > 0) {
      selectedTeamTmpCopy[isSelectedIndex].members.push(...membersToAdd);
    }
    if (membersToRemove.length > 0) {
      selectedTeamTmpCopy[isSelectedIndex].members = selectedTeamTmpCopy[
        isSelectedIndex
      ].members.filter(
        (m) => !membersToRemove.some((el) => el.identityId === m.identityId)
      );
    }
    selectedTeamTmpCopy.forEach((t, i) => {
      if (i !== isSelectedIndex) {
        t.members = t.members.filter(
          (m) => !membersToAdd.some((el) => el.identityId === m.identityId)
        );
      }
    });
  } else {
    selectedTeamTmpCopy.push({
      value: team.teamName,
      members: membersToAdd,
      ...(team?.teamId ? { teamId: [team?.teamId] } : {}),
    });
    selectedTeamTmpCopy.forEach((t, i) => {
      if (i !== selectedTeamTmpCopy.length - 1) {
        t.members = t.members.filter(
          (m) => !membersToAdd.some((el) => el.identityId === m.identityId)
        );
        if (t.members.length === 0) {
          selectedTeamTmpCopy.splice(i, 1);
        }
      }
    });
  }
  if (
    isSelectedIndex > -1 &&
    selectedTeamTmpCopy[isSelectedIndex]?.members?.length === 0
  ) {
    selectedTeamTmpCopy.splice(isSelectedIndex, 1);
    setSelectedTeamTmp(selectedTeamTmpCopy);
  } else {
    setSelectedTeamTmp(selectedTeamTmpCopy);
  }
  setMembersToAdd([]);
  setMembersToRemove([]);
};

export default teamPopoverConfirm;

import { getParams } from "../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  let toDoStatus = new Set();
  let toDoCategory = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const status = item?.todoStatus || "No Data";
    const category = item?.todoCategory || "No Data";
    const user = item?.todoUser || "No Data";

    toDoStatus.add(status);
    toDoCategory.add(category);
    createdBy.add(user);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total To Do count based on Status",
      dataKey: "todoStatus",
      params: getParams({
        data,
        chartData: toDoStatus,
        chartKey: "todoStatus",
        label: "Status",
        text: "Total To Do count based on Status",
      }),
    },
    {
      optionTitle: "Show total To Do count based on Category",
      dataKey: "todoCategory",
      params: getParams({
        data,
        chartData: toDoCategory,
        chartKey: "todoCategory",
        label: "Category",
        text: "Total To Do count based on Status",
      }),
    },
    {
      optionTitle: "Show total To Do count based on Created By",
      dataKey: "createdBy",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy",
        label: "Created By",
        text: "Total To Do count based on Created By",
      }),
    },
  ];

  return chartingOptions;
};

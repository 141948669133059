import { message } from "antd";
import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { v4 } from "uuid";
import { useEditLogs } from "./useEditLogs";

export const generateNewLog = (
  type,
  label,
  prevVal,
  updatedVal,
  recordId,
  category,
  userConfiguration,
  recordName
) => {
  return {
    actionType: type,
    category,
    recordId,
    nameOfUser: userConfiguration?.nameOfUser,
    cognitoUserId: userConfiguration?.cognitoUserId,
    updatedAt: Date.now(),
    currentData: { [label]: updatedVal },
    previousData: { [label]: prevVal },
    recordName: recordName ?? "Statuses Of Categories",
    label: "",
    updatedKeys: [
      {
        key: v4(),
        label: label,
        previousValue: prevVal,
        updatedValue: updatedVal,
      },
    ],
  };
};

const useLogManagement = () => {
  const { userConfiguration } = useSelector((state) => state.userConfig);

  const { saveAddedLogs } = useEditLogs();

  const handleGenerateNewLog = (
    type,
    label,
    prevVal,
    updatedVal,
    category,
    recordId,
    recordName
  ) => {
    const newLog = generateNewLog(
      type,
      label,
      prevVal,
      updatedVal,
      recordId,
      category,
      userConfiguration,
      recordName
    );

    if (newLog) {
      postLogs(newLog);
    }
  };

  const postLogs = async (newLog) => {
    try {
      await saveAddedLogs(newLog);
    } catch (e) {
      console.error(e);
      message.error("Failed to save Logs");
    } finally {
    }
  };

  return { handleGenerateNewLog };
};

export default useLogManagement;

import { useEffect, useState, useRef } from "react";
import { Form, message, Select, Radio, Tour, Avatar } from "antd";
import { useLocation, useNavigate } from "react-router-dom";
import {
  BorderedTextCard,
  GPickerButton,
  InputComponent,
  NormalSizedModal,
} from "../../../../../../components";
import {
  footerButtons,
  fields,
  pdfBorderedSection,
} from "./maintenanceModalData";
import "./MaintenanceModal.scss";
import { compareIncluding } from "../../../../../../../utils";
import { apiRoutes, fetchData, validateForm } from "../../../../../../utils";
import API from "@aws-amplify/api";
import {
  MondayButton,
  MultiLevelTreeLogs,
  ProgressComponent,
} from "../../../../../../../../commonComponents";
import { RenderDynamicComponents } from "../../../../../../../../Header/forms/Components";
import { useDispatch, useSelector } from "react-redux";
import { assignDisabledDate } from "../../../../../../../../Header/forms/utils";
import dayjs from "dayjs";
import { filterTables } from "../../../../../../../../../utils/filterTables";
import WarningModal from "../../../../../../../../commonComponents/WarningModal/WarningModal";
import { WarningTriangle } from "../../../../../../../DynamicView/src";
import DynamicTeamModal from "../../../../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import {
  fetchAllData,
  updateTeamsConfiguration,
} from "../../../../../../../../../utils";
import { TickIcon } from "../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import {
  showErrorMsg,
  showSuccessMsg,
} from "../../../../../../../../../utils/windowMessages";
import { getChangedData } from "../../../../../../../Accounting/components/utilities";
import broadcastNotification from "../../../../../../../../../helpers/controllers/broadcastNotification";
import { fleetFields as updateFleetFields } from "../../../../../../../../../actions";
import { Editor } from "@tinymce/tinymce-react";
import { useCreateDriveFolders } from "../../../../../../../../../hooks/useCreateDriveFolders";
import { driveApi } from "../../../../../../../../../integrations/DriveRequest";
import createPDF, {
  PREVIEW,
} from "../../../../../../../../../integrations/createPDF";
import CustomModalHeader, {
  PlayVideoTutorial,
  tourTitle,
} from "../../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { PlusOutlined } from "@ant-design/icons";
import { getCognitosForNotification } from "../../../../../../../../../utils";
import { DynamicAvatars } from "../../../../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { XIcon } from "../../../../../../../Communication/assets";
import ToDoModal from "src/components/SidebarPages/ToDos/ToDoModal";
import moment from "moment";
import {
  useEditLogs,
  useProgressComponent,
} from "../../../../../../../../../hooks";
import { deleteAllRelatedToDos } from "../../../../../../../ToDos/forms/helpers";

const MaintenanceModal = (params) => {
  const { visible, setVisible, refreshTable = () => {}, isNextStep } = params;

  const [
    { userConfiguration },
    { accessToken },
    authUser,
    { fleetFields },
    { programFields },
    { isDarkMode },
  ] = useSelector((state) => [
    state.userConfig,
    state.accessToken,
    state.authenticatedUser.authenticatedUser,
    state.fleetFields,
    state.programFields,
    state.darkMode,
  ]);

  const dispatch = useDispatch();
  let location = useLocation();

  const [predefinedData] = useState(
    params?.predefinedData || location?.state?.predefinedData || {}
  );
  const {
    inspectionId,
    fleetId: predefinedFleetId,
    fleetName,
    inspectionReport,
    mechanicInfo,
    maintenanceId,
    maintenanceDate,
  } = predefinedData;

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({
    categoryName: "Fleet Maintenance",
    actionType: !!maintenanceId ? "Update" : "Create",
  });
  const { saveAddedLogs } = useEditLogs();

  const [form] = Form.useForm();
  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Fleet Maintenance")
      ?.children?.find(({ title }) => title === "Overview")
  );
  const [users, setUsers] = useState([]);
  const [fleet, setFleet] = useState([]);
  const [deleteWarningModalVisible, setDeleteWarningModalVisible] =
    useState(false);
  const [formFieldsChanged, setFormFieldsChanged] = useState(false);
  const [maintenanceItems, setMaintenanceItems] = useState([]);
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [logsVisible, setLogsVisible] = useState(false);
  const [existingLogs, setExistingLogs] = useState([]);
  const [maintenanceFields, setMaintenanceFields] = useState([]);
  const [editorBody, setEditorBody] = useState("");
  const [defectList, setDefectList] = useState([]);
  const [fileNames, setFileNames] = useState([]);
  const [selectedFleet, setSelectedFleet] = useState(false);
  const [booleans, setBooleans] = useState([]);
  const [maintenanceDateState, setMaintenanceDate] = useState(
    form.getFieldValue("maintenanceDate")
  );
  const [open, setOpen] = useState(false);
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [gDriveFolderId, setGDriveFolderId] = useState(false);
  const [toDoVisible, setToDoVisible] = useState(false);
  const [allTeams, setAllTeams] = useState([]);
  const oldFolderId = useRef();
  const companyLogo = useRef();
  const driveFunctions = useCreateDriveFolders("Maintenances");
  const driveRequest = driveApi({ accessToken });

  //Gets the fleetId from the URL
  let fleetId = predefinedFleetId ?? "";

  const navigate = useNavigate();

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.find((item) => item.fieldName === "Portal Video Tutorials")
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Maintenance"
    )?.videoLink;

  const formatQuestions = (arr = []) =>
    arr.reduce(
      (acc, { label, type, id }) => [
        ...acc,
        {
          label,
          id,
          value:
            compareIncluding(type, "datepicker") ||
            compareIncluding(type, "timepicker")
              ? dayjs(form.getFieldValue(id)).unix() * 1000
              : form.getFieldValue(id),
          files:
            fileNames.find((file) => file.uploaderId === label)?.files || [],
        },
      ],
      []
    );

  //Resets all input fields except the "Vehicle" field
  const resetFields = () =>
    form.resetFields([
      ...maintenanceFields
        .filter(({ formItemName }) => formItemName !== "vehicle")
        .map(({ formItemName }) => formItemName),
      ...maintenanceItems?.map(({ formItemName }) => formItemName),
    ]);

  let inspStatus = formatQuestions(maintenanceItems);

  const formatExistingInspCategory = (arr = []) =>
    arr?.reduce(
      (acc, { id, type, value }) => ({
        ...acc,
        [id]:
          compareIncluding(type, "datepicker") ||
          compareIncluding(type, "timepicker")
            ? dayjs.unix(dayjs(value).format("X"))
            : value,
      }),
      {}
    );

  function getAccessRights(childTitle) {
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  const onRadioSelect = (e, item) => {
    const selectedVal = e.target.value;
    const defectExists = defectList.some(
      (defect) => defect.label === item.label
    );
    setBooleans((prev) => [
      ...prev.filter((el) => el?.id !== item?.id),
      { id: item.id, label: item.label, value: selectedVal },
    ]);
    if (!defectExists && selectedVal !== "Acceptable") {
      const defectObj = {
        ...item,
        // defectAttachments: [],
      };
      setDefectList(defectList.concat(defectObj));
    } else if (defectExists && selectedVal === "Acceptable") {
      fileNames
        .find((file) => file.uploaderId === item.label)
        ?.files.map(async ({ id }) => await driveRequest.deleteDriveItem(id));

      const filteredFileNames = fileNames.filter(
        (file) => file.uploaderId !== item.label
      );
      const filteredDefectList = defectList.filter(
        (defect) => defect.label !== item.label
      );
      setDefectList(filteredDefectList);
      setFileNames(filteredFileNames);
    }
  };

  function onMassRadioSelect(e) {
    const selectedVal = e.target.value;
    let tmpBooleans = [];
    let tmpDefectList = defectList;
    let tmpFileNames = fileNames;
    maintenanceItems.map((item) => {
      const defectExists = defectList.some(
        (defect) => defect.label === item.label
      );
      tmpBooleans.push({
        id: item.id,
        label: item.label,
        value: selectedVal,
      });
      if (!defectExists && selectedVal !== "Acceptable") {
        const defectObj = {
          ...item,
          // defectAttachments: [],
        };
        if (!tmpDefectList.some((defect) => defect?.label === item.label)) {
          tmpDefectList.push(defectObj);
        }
      } else if (defectExists && selectedVal === "Acceptable") {
        fileNames
          .find((file) => file.uploaderId === item.label)
          ?.files.map(async ({ id }) => await driveRequest.deleteDriveItem(id));

        tmpFileNames = tmpFileNames.filter(
          (file) => file.uploaderId !== item.label
        );
        tmpDefectList = tmpDefectList.filter(
          (defect) => defect.label !== item.label
        );
      }
      form.setFieldValue(`${item?.id}`, selectedVal);
      form.setFieldValue("allItems", selectedVal);
    });
    setDefectList(tmpDefectList);
    setFileNames(tmpFileNames);
    setBooleans(tmpBooleans);
  }

  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    const uploadedFileNames = uploadedFiles.map(({ name, id, mimeType }) => ({
      name,
      mimeType,
      id,
      uploadedAt: dayjs().valueOf(),
    }));

    const fileNamesCopy = [...fileNames];
    const defectListCopy = [...defectList];

    const matchingItem = fileNamesCopy.find((item) =>
      compareIncluding(item.uploaderId, uploaderId)
    );

    if (matchingItem) {
      matchingItem.files.push(...uploadedFileNames);
      setFileNames(fileNamesCopy);
    } else {
      const newItem = {
        uploaderId,
        files: uploadedFileNames,
      };
      const defectObject = defectListCopy.find(
        (defect) => defect.label === newItem.uploaderId
      );
      defectListCopy.filter((defect) => defect.name !== newItem.uploaderId);
      defectObject.defectAttachments = newItem.files;
      defectListCopy.push(defectObject);
      setDefectList(defectListCopy);
      setFileNames(fileNamesCopy.concat(newItem));
    }
  };

  const bodyObject = () => {
    const mechanicInfo = users
      ?.filter(({ department }) => compareIncluding(department, "mechanic"))
      ?.find(({ name }) => name === form.getFieldValue("mechanic"));

    return {
      fleetId: fleet?.find(
        ({ fleetName }) => fleetName === form.getFieldValue("vehicle")
      )?.fleetId,
      fleetName: form.getFieldValue("vehicle"),
      teamsConfiguration: updateTeamsConfiguration(
        userConfiguration,
        selectedTeam
      ),
      maintenanceDate: form.getFieldValue("maintenanceDate").unix() * 1000,
      maintenanceReport: !!inspectionReport
        ? inspectionReport?.find(({ category }) =>
            compareIncluding(category, "Defective Items")
          )?.values
        : !!maintenanceItems?.some(({ value }) => !value)
        ? formatQuestions(maintenanceItems)
        : undefined,
      maintenanceStatus: maintenanceItems?.every(
        ({ formItemName }) => form.getFieldValue(formItemName) === "Acceptable"
      )
        ? "Passed"
        : maintenanceItems?.some(
            ({ formItemName }) =>
              form.getFieldValue(formItemName) === "Defective"
          )
        ? "Failed"
        : "Pending",
      maintenanceDetails: [
        {
          label: "Maintenance Date",
          maintenanceDate: form.getFieldValue("maintenanceDate").unix() * 1000,
        },
      ],
      maintenanceFolderId: gDriveFolderId,
      mechanic: form.getFieldValue("mechanic"),
      mechanicInfo: {
        ...mechanicInfo,
        mechanicNote: editorBody,
      },
      fleetStatus: !!maintenanceItems?.every(
        ({ formItemName }) => form.getFieldValue(formItemName) === "Defective"
      )
        ? "Failed"
        : "Requested" &&
          !!maintenanceItems?.every(
            ({ formItemName }) =>
              form.getFieldValue(formItemName) === "Acceptable"
          )
        ? "Passed"
        : "Requested" && !!inspectionReport
        ? "Requested"
        : "Pending" && !inspectionReport
        ? "Pending"
        : "Requested",
    };
  };

  //Posts the new maintenance request to the database
  const onSave = async () => {
    const key = "onSaveMsg";
    message.loading({ content: "Saving...", key });

    let currentData = bodyObject();

    setVisibleCreationProgress(predefinedData);
    updateProgressStatus({ updatingRecord: "executing" });
    if (!!maintenanceId) {
      let newEditLog = {
        recordId: maintenanceId,
        recordName: predefinedData?.fleetName,
        actionType: "Edit",
        category: "Fleet Maintenances",
        currentData: {},
        previousData: {},
        updatedKeys: [],
      };

      let tmpNewObj = JSON.parse(JSON.stringify(currentData)),
        tmpOldObj = JSON.parse(JSON.stringify(predefinedData));

      let tmpNew = {},
        tmpOld = {};

      tmpNewObj?.maintenanceItems?.forEach((el) => {
        tmpNew[el?.label] = el?.value;
      });

      tmpOldObj?.maintenanceItems?.forEach((el) => {
        tmpOld[el?.label] = el?.value;
      });

      tmpNewObj["maintenanceItems"] = {
        ...tmpNew,
      };
      tmpOldObj["maintenanceItems"] = {
        ...tmpOld,
      };

      for (let key in tmpNewObj) {
        let result;
        if (!!tmpNewObj[key] && !!tmpOldObj[key]) {
          result = getChangedData(tmpNewObj[key], tmpOldObj[key]);
        } else {
          continue;
        }
        if (result !== false) {
          newEditLog.currentData[key] = result.curr;
          newEditLog.previousData[key] = result.prev;
          newEditLog.updatedKeys.push(key);
        }
      }

      saveAddedLogs(newEditLog);

      API.put(
        apiRoutes.fleetMaintenance,
        `/${apiRoutes.fleetMaintenance}/${maintenanceId}`,
        { body: { ...currentData } }
      )
        .then(async (r) => {
          //set progress of updating record as 100%
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });

          Object.keys(predefinedData).forEach(
            (e) => (predefinedData[e] = r?.[e])
          );
          if (
            !!maintenanceDateState &&
            maintenanceDateState?.unix() * 1000 !== maintenanceDate
          ) {
            const files = fileNames
              .flatMap((item) => item.files)
              .map(async (file) => {
                return await driveRequest
                  .getImageSrc(file.id)
                  .then(async (res) => {
                    return {
                      src: res.src,
                      blob: res.blob,
                      mimeType: file?.mimeType,
                      id: file.id,
                      name: file.name,
                    };
                  });
              });

            Promise.all(files).then(async (res) =>
              res.map((file) => {
                return driveRequest.uploadFile(
                  file?.src?.split(",")[1],
                  file.mimeType,
                  {
                    name: file.name,
                    mimeType: file.mimeType,
                    writersCanShare: true,
                    parents: [gDriveFolderId],
                  },
                  true
                );
              })
            );

            driveRequest.deleteDriveItem(oldFolderId.current);
          }

          // await broadcastNotification(
          broadcastNotification(
            "11",
            "onMaintenanceEdit",
            [
              {
                common: userConfiguration?.nameOfUser,
                commonNext: form.getFieldValue("vehicle"),
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "maintenances",
                currentUser: userConfiguration?.cognitoUserId,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  predefinedData?.teamsConfiguration,
                  "fleet administrator"
                ),
              },
            ],
            maintenanceId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          refreshTable({ update: [predefinedData] });
          message.success({ content: "Saved!", key, duration: 3 });
          resetFields();
          if (!!!isNextStep) {
            if (!!predefinedData?.maintenanceId) {
              setTimeout(navigate("/fleetMaintenance"), 2000);
            } else {
              navigate("/fleetMaintenance");

              setVisible(false);
            }
          } else {
            setVisible(false);
          }
        })
        .catch((e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          message.error({ content: "Error saving", key, duration: 3 });
          console.log("Error: ", e);
        });
    } else {
      await API.post(
        apiRoutes.fleetMaintenance,
        `/${apiRoutes.fleetMaintenance}`,
        { body: { ...bodyObject() } }
      )
        .then((r) => {
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          }); //set progress of creating record as 100%
          setVisibleCreationProgress(r); // save datas to generate logs in case of any fail

          saveAddedLogs({
            recordId: r?.maintenanceId,
            recordName: r?.fleetName,
            category: "Fleet Maintenances",
          });

          broadcastNotification(
            "11",
            "onMaintenanceCreation",
            [
              {
                common: userConfiguration?.nameOfUser,
                commonNext: form.getFieldValue("vehicle"),
              },
              {
                userName: userConfiguration?.nameOfUser,
                tab: "maintenances",
                currentUser: userConfiguration?.cognitoUserId,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  r?.teamsConfiguration,
                  "fleet administrator"
                ),
              },
            ],
            r?.maintenanceId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          Object.keys(predefinedData).forEach(
            (e) => (predefinedData[e] = r.data[e])
          );
          refreshTable([r]);
          message.success({ content: "Saved!", key, duration: 3 });
          // if (!!!isNextStep) {
          //   if (!!predefinedData?.maintenanceId) {
          //     setTimeout(navigate("/fleetMaintenance"), 2000);
          //   } else {
          //     navigate("/fleetMaintenance");

          //     setVisible(false);
          //   }
          // } else {
          //   setVisible(false);
          // }
          navigate(`/fleetMaintenance/${r.maintenanceId}`);
        })
        .catch((e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          console.error(e);
        });
    }
  };

  const onCancelModal = () => {
    !!!isNextStep && navigate("/fleetMaintenance");
    setVisible(false);

    driveFunctions.delete();
    driveFunctions.reset();
  };

  const onDelete = () => {
    API.del(
      apiRoutes.fleetMaintenance,
      `/${apiRoutes.fleetMaintenance}/${maintenanceId}`
    )
      .then((r) => {
        const logBody = {
          recordId: maintenanceId,
          recordName: predefinedData?.fleetName,
          actionType: "Delete",
          category: "Fleet Maintenances",
        };
        deleteAllRelatedToDos({ recordId: maintenanceId });

        saveAddedLogs(logBody);

        broadcastNotification(
          "11",
          "onMaintenanceDelete",
          [
            {
              common: userConfiguration?.nameOfUser,
              commonNext: form.getFieldValue("vehicle"),
            },
            {
              userName: userConfiguration?.nameOfUser,
              tab: "maintenances",
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: getCognitosForNotification(
                userConfiguration,
                predefinedData?.teamsConfiguration,
                "fleet administrator"
              ),
            },
          ],
          null
        );
        driveRequest.deleteDriveItem(gDriveFolderId).then((res) => {
          saveAddedLogs({
            ...logBody,
            recordName: predefinedData?.fleetName + " (Drive Folder)",
          });
        });
        showSuccessMsg({ content: "Deleted!", key: "deleteMsg", duration: 3 });
        setDeleteWarningModalVisible(false);
        setTimeout(navigate("/fleetMaintenance"), 300);
      })
      .catch((e) => {
        showErrorMsg({
          content: "Error deleting",
          key: "deleteMsg",
          duration: 3,
        });
        console.error(e);
      });
  };

  //Validates all input fields and then calls the function that we pass as a param
  const validateMaintenanceForm = (onSuccessFunc) => {
    validateForm(form, onSuccessFunc);
  };
  console.log("maintenance Items: ", maintenanceItems);
  ////////////////////////////////////////// PDF //////////////////////////////////////////
  const mainItemsReport = [
    pdfBorderedSection({
      form: form,
      title: "Maintenance Items",
      body: maintenanceItems,
    }),
  ];

  const flexImageSection = (images, imagesPerRow = 4) => {
    const formattedImages = [];
    for (let i = 0; i < images.length; i += imagesPerRow) {
      const rowImages = images.slice(i, i + imagesPerRow).map((image) => ({
        image,
        width: 100,
        height: 100,
        alignment: "center",
      }));
      formattedImages.push(rowImages);
    }

    const tableBody = formattedImages.map((row) => ({
      table: {
        widths: Array(row.length).fill("*"),
        body: [row],
      },
      layout: {
        hLineWidth: () => 0,
        vLineWidth: () => 0,
      },
      style: "innerTable",
    }));

    const outerBorders = {
      hLineWidth: () => 1,
      vLineWidth: () => 1,
      hLineColor: () => "#707070",
      vLineColor: () => "#707070",
    };

    const layout = outerBorders;

    return {
      table: {
        widths: ["*"],
        body: tableBody.map((item) => [item]),
      },
      style: "outerTable",
      layout,
      unbreakable: true,
    };
  };

  const docDefinition = () => {
    const vehicle = form.getFieldValue("vehicle");
    const maintenanceDate = moment(
      form.getFieldValue("maintenanceDate")
    ).format("MM/DD/YYYY");
    const mechanic = form.getFieldValue("mechanic");

    const imagesFromFiles = fileNames
      .filter((obj) => obj.files.length > 0)
      .flatMap((obj) => obj.files)
      .map((file) => file.src);

    const flexImageSectionConfig = imagesFromFiles.length
      ? flexImageSection(imagesFromFiles)
      : {};

    return {
      pageOrientation: "portrait",
      pageMargins: [20, 20, 20, 30],
      footer: (currentPage, pageCount) => {
        return {
          text: `${currentPage} of ${pageCount} | CORE SCAFFOLD SYSTEMS INC`,
          alignment: "center",
          margin: 7,
        };
      },
      content: [
        {
          columns: [
            {
              image: "logo",
              width: 100,
            },
            {
              stack: [
                {
                  text: `Maintenance`,
                  style: ["strong", "large"],
                  margin: [0, 10, 90, 10],
                },
              ],
              width: "*",
              alignment: "center",
            },
          ],
          style: "subheader",
        },
        {
          svg: `<svg xmlns="http://www.w3.org/2000/svg" width="2357" height="1" viewBox="0 0 2357 1">
    <line id="Line_419" data-name="Line 419" x2="2357" transform="translate(0 0.5)" fill="none" stroke="#707070" stroke-width="1"/>
  </svg>`,
          width: 555,
          height: 2,
          style: "header",
        },
        {
          columns: [],
          alignment: "left",
          width: "auto",
          style: "subheader",
        },
        {
          text: `Vehicle: ${vehicle || ""}`,
          style: "subheader",
          margin: [0, 5],
        },
        {
          text: `Maintenance Date: ${maintenanceDate || ""}`,
          style: "subheader",
          margin: [0, 5],
        },
        {
          text: `Mechanic: ${mechanic || ""}`,
          style: "subheader",
          margin: [0, 5],
        },
        mainItemsReport,
        flexImageSectionConfig,
      ],
      images: {
        logo: companyLogo?.current?.base64,
      },
      styles: {
        strong: {
          bold: true,
        },
        large: { fontSize: 20 },
        header: {
          margin: [0, 0, 0, 20],
        },
        subheader: { margin: [0, 0, 0, 10], fontSize: 14 },
        innerTable: { margin: 0 },
        outterTable: { margin: [0, 0, 0, 30] },
        lastColumn: { color: "#4A4A4A" },
        bool: { margin: [0, 5], fontSize: 13 },
        cellItem: { margin: [0, 2] },
      },
    };
  };

  const exportToPdf = () =>
    createPDF({
      action: "download",
      docDefinition: docDefinition(),
      title: "Fleet Maintenance.pdf",
    });
  ////////////////////////////////////////// PDF //////////////////////////////////////////////

  function changeTeamsHandler(truckName, allFleet) {
    let selectedTruck = allFleet?.find(
      ({ fleetName }) => fleetName === truckName
    );
    if (selectedTruck) {
      setSelectedTeam(selectedTruck?.teamsConfiguration || []);
      form.setFieldValue(
        "fleetMaintenanceTeam",
        (selectedTruck?.teamsConfiguration || [])?.map((item) => item?.value)
      );
    }
  }

  useEffect(() => {
    if (users?.length) {
      Promise.allSettled([
        filterTables(
          "dynamicQuestions",
          "categoryId",
          "445df4ds-723646dsfs-45c4"
        ),
        fetchData("teams"),
        fetchData("fleet"),
      ])
        .then(([{ value: res }, { value: teamsRes }, { value: fleetsRes }]) => {
          setFleet(fleetsRes);
          setAllTeams(teamsRes);
          !!fleetId?.length &&
            form.setFieldsValue({
              vehicle: fleetsRes?.find(
                ({ fleetId: arrFleetId }) => arrFleetId === fleetId
              )?.fleetName,
            });

          let defaultFields = fields({
            mechanics: users
              ?.filter(({ department }) => department === "Mechanic")
              ?.map(({ name }) => name),
            fleet: fleetsRes?.map(({ fleetName }) => fleetName),
            fleetId,
            onTruckSelect: changeTeamsHandler,
            allTeams: teamsRes,
            onSelect,
            onDeselect,
            teamsAccess: getAccessRights("Teams")?.write,
            allFleets: fleetsRes,
            selectedFleet,
          });

          let data = res[0].questions?.booleans?.reduce(
            (acc, val) => [...acc, { ...val, formItemName: val?.id }],
            []
          );
          if (data?.some((el) => !!el.index)) {
            data?.sort((a, b) => a.index - b.index);
          }

          let detailsFields = defaultFields[0]?.fields?.concat(
            res[0]?.questions?.details
              .filter((detail) => !detail?.defaultField)
              ?.reduce(
                (acc, val) => [
                  ...acc,
                  {
                    ...val,
                    formItemName:
                      val?.label?.charAt(0).toLowerCase() +
                      val?.label?.slice(1)?.replace(" ", ""),
                  },
                ],
                []
              )
          );
          setMaintenanceItems(data);
          setMaintenanceFields(detailsFields);
        })
        .catch((err) => {
          message.error("Something Went Wrong While Opening Form!");
          console.log("Error getting data: ", err);
        });

      API.get("base64s", "/base64s").then(
        (res) => (companyLogo.current = res[1])
      );
    }
  }, [users]);

  //Fetches data from the API
  useEffect(() => {
    if (!!maintenanceId) {
      fetchAllData({
        endpoint: "editLogs",
        resultPosition: "editLogs",
        resultId: "logId",
        otherStringParams: {
          getMaxLimit: "true",
          filters: JSON.stringify([
            {
              conditions: [
                {
                  column: "recordId",
                  value: maintenanceId,
                  formula: "is",
                },
              ],
            },
          ]),
        },
      })
        .then((res) => {
          setExistingLogs(res);
        })
        .catch((err) => {
          console.log("Could not fetch logs: ", err);
        });
      setSelectedTeam(predefinedData?.teamsConfiguration || []);
      form.setFieldsValue({
        vehicle: predefinedData?.fleetName,
        mechanic: predefinedData?.mechanicInfo?.name || undefined,
        fleetMaintenanceTeam: (predefinedData?.teamsConfiguration || []).map(
          (item) => item?.value
        ),
      });
    }
  }, [users, predefinedData]);

  useEffect(() => {
    setUsers(
      userConfiguration.allUsers.Items.reduce(
        (acc, { groupName, nameOfUser, officialSignature = "" }) => [
          ...acc,
          {
            department: groupName,
            name: `${nameOfUser?.split(" ")[0]} ${nameOfUser?.split(" ")[1]}`,
            officialSignature,
          },
        ],
        []
      )
    );
  }, [userConfiguration]);

  useEffect(() => {
    // const inspectionReportObj = predefinedData?.maintenanceReport?.reduce(
    //   (acc, { category, values }) => ({ ...acc, [category]: values }),
    //   {}
    // );
    if (!!predefinedData?.maintenanceId) {
      form.setFieldsValue({
        mechanic: mechanicInfo?.mechanic || undefined,
        maintenanceDate: dayjs(maintenanceDate),
        ...formatExistingInspCategory(predefinedData.maintenanceReport),
      });
      if (predefinedData?.mechanicInfo?.mechanicNote) {
        setEditorBody(predefinedData?.mechanicInfo?.mechanicNote);
      }
    }
  }, [visible, fleetId]);

  useEffect(() => {
    if (predefinedData?.maintenanceReport) {
      // Get all the files of defects
      const uploadedFiles = predefinedData.maintenanceReport
        .filter((rep) => rep.value !== "Acceptable")
        .map((rep) => ({
          uploaderId: rep.label,
          files: rep.files,
        }));
      let getSrcOfFiles = [];
      uploadedFiles?.forEach(({ uploaderId: title, files }) => {
        files?.forEach((file) => {
          if (
            file?.mimeType === "image/jpeg" ||
            files?.mimeType === "image/png"
          ) {
            getSrcOfFiles.push({
              ...file,
              title,
            });
          }
        });
      });

      Promise.allSettled(
        getSrcOfFiles.map(async (file) => {
          return await driveRequest
            .getImageSrc(file.id)
            .then((r) => ({ ...r, title: file.title, id: file?.id }));
        })
      )
        .then((res) => {
          let newState = res
            ?.filter(({ status }) => status === "fulfilled")
            ?.reduce((acc, val) => [...acc, val?.value], []);
          const uploadedFilesWithSrc = uploadedFiles.map((item) => {
            const addSrc = item.files.map((file) => {
              const src = newState.find(({ id }) => id === file.id).src;
              return { ...file, src: src };
            });
            return { ...item, files: addSrc };
          });
          setFileNames(uploadedFilesWithSrc);
        })
        .catch((err) => {
          console.log("Error getting uploaded files: ", err);
        });

      // Change folder name based on inspection Date
      if (
        !!maintenanceDateState &&
        maintenanceDateState?.unix() * 1000 !== maintenanceDate
      ) {
        driveFunctions.delete();
        const vehicle = fleet.find(
          ({ fleetName }) => fleetName === selectedFleet
        );
        const fleetFolderId = vehicle?.googleDriveFolderIds?.maintenanceObject;
        const maintDate = maintenanceDateState?.unix() * 1000;
        const folderName = dayjs(maintDate).format("MM/DD/YYYY, hh:mm:ss");
        driveFunctions.create({
          parentFolderName: folderName,
          parentId: fleetFolderId,
        });
      }
    } else if (!!selectedFleet && !!maintenanceDateState) {
      // on Change of date or Fleet reCreates the drive folder to match the maintenanceDate
      driveFunctions.delete();
      const vehicle = fleet.find(
        ({ fleetName }) => fleetName === selectedFleet
      );
      const fleetFolderId = vehicle?.googleDriveFolderIds?.maintenanceObject;
      const maintDate = maintenanceDateState?.unix() * 1000;
      const folderName = dayjs(maintDate).format("MM/DD/YYYY, hh:mm:ss");
      driveFunctions.create({
        parentFolderName: folderName,
        parentId: fleetFolderId,
      });
    }
  }, [selectedFleet, maintenanceDateState, predefinedData]);

  useEffect(() => {
    // Get folderId for the selected Maintenance Request
    if (fleetId) {
      if (
        !!maintenanceDateState &&
        maintenanceDateState?.unix() * 1000 !== maintenanceDate
      ) {
        setGDriveFolderId(driveFunctions.parentFolderId);
        oldFolderId.current = predefinedData.maintenanceDetails.find(
          ({ label }) => label === "Maintenance FolderId"
        )?.maintenanceFolderId;
      } else {
        const folderId = predefinedData.maintenanceDetails.find(
          ({ label }) => label === "Maintenance FolderId"
        )?.maintenanceFolderId;
        setGDriveFolderId(folderId);
      }
      // Get folderId for the created Maintenance Request
    } else if (!!driveFunctions?.parentFolderId) {
      setGDriveFolderId(driveFunctions?.parentFolderId);
    }
  }, [driveFunctions?.parentFolderId, predefinedData]);

  // new shortcut from sidebar needs fleetFields to be filled
  useEffect(() => {
    if (!fleetFields) {
      API.get("fleetFields", `/fleetFields`).then((res) =>
        dispatch(updateFleetFields(res))
      );
    }

    if (!params.predefinedData) {
      if (
        window.location.pathname.includes("fleet") &&
        !window.location.pathname.includes("Maintenance")
      ) {
        let fleetId = window.location.pathname.split("/")[2];
        console.log("fleetId: ", fleetId);
        API.get("fleet", `/fleet/${fleetId}`)
          .then((res) => {
            let fleetName = res?.fleetName;
            setSelectedFleet(fleetName);
            form.setFieldsValue({ vehicle: fleetName });
          })
          .catch((err) => {
            console.log("Error getting truck: ", err);
          });
      }
    }
  }, []);

  const onCancel = () => {
    if (!!!isNextStep) {
      if (formFieldsChanged) {
        setCancelModalVisible(true);
      } else {
        if (predefinedData?.maintenanceId) {
          navigate("/fleetMaintenance");
        } else {
          navigate("/fleetMaintenance");
          setVisible(false);
        }
      }
    } else {
      setCancelModalVisible(true);
    }
  };

  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(
        ({ categoryName }) => categoryName === "maintenanceRequestModal"
      )?.steps || [];

  function stepsMapHelper(title) {
    const stepsMap = {
      "Maintenance Details": document.querySelector(".detailsCard"),
      "Maintenance Items": document.querySelector("#maintenanceItemsCard"),
      "Mechanic Note": document.querySelector(".mechanicNoteCard"),
      Save: document.querySelector(".saveButton"),
    };
    return stepsMap[title] || null;
  }

  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps?.map((step) => {
      return {
        title: tourTitle(step?.title, () => {
          setShowVideoTutorial(true);
          setTourOpen(false);
        }),
        description: step?.description,
        target: () => stepsMapHelper(step?.title),
        className: `custom-tour-light`,
      };
    });
    return newSteps;
  }
  const tourSteps = mapRefs(steps);

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        members: data?.members?.map((el) => el),
      },
    ]);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el.value !== teamName));
    if (form.getFieldValue("fleetMaintenanceTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const openMemberModal = () => {
    setOpen(true);
  };

  const ClearOptions = () => {
    setOpen(false);
    true;
  };

  return (
    <>
      <NormalSizedModal
        visible={params?.visible ? visible : true}
        destroyOnClose
        // title="Maintenance Request"
        title={
          <CustomModalHeader
            {...{
              title: "Maintenance Request",
              onClick: () => {
                tourSteps.length > 0
                  ? setTourOpen(true)
                  : message.info("No guide available for this form");
              },
            }}
          />
        }
        className={`maintenanceModalContainer ${
          isDarkMode && "maintenanceModalContainerDark"
        }`}
        onCancel={onCancel}
        newFooterDesign={footerButtons(
          () => {
            if (!!!isNextStep) {
              if (formFieldsChanged) {
                setCancelModalVisible(true);
              } else {
                if (!!predefinedData?.maintenanceId) {
                  navigate("/fleetMaintenance");
                } else {
                  setVisible(false);
                  navigate("/fleetMaintenance");
                }
              }
            } else {
              setVisible(false);
            }
          },
          () => validateMaintenanceForm(onSave),
          () => setDeleteWarningModalVisible(true),
          !!maintenanceId,
          () => setLogsVisible(true),
          exportToPdf,
          getAccessRights("Logs"),
          () => setToDoVisible(true)
        )}
        footer={false}
        centered
        closable={true}
      >
        <Form
          form={form}
          onFieldsChange={() => {
            setFormFieldsChanged(true);
            setSelectedFleet(form.getFieldValue("vehicle"));
            setMaintenanceDate(form.getFieldValue("maintenanceDate"));
          }}
        >
          <div className="contentContainer">
            <BorderedTextCard
              title="Details"
              className="details-text-card detailsCard"
              isDarkMode={isDarkMode}
            >
              {RenderDynamicComponents(
                maintenanceFields.map((input) => {
                  return {
                    ...input,
                    disabledDate: assignDisabledDate(input, "Maintenance Date"),
                  };
                })
              )}
              {!inspectionId && !!maintenanceFields?.length && (
                <InputComponent
                  label={"All Items"}
                  required={false}
                  type={"radio"}
                  form={form}
                  formItemName={"allItems"}
                  disabled={!selectedFleet || fleetId}
                  onChange={(e) => onMassRadioSelect(e)}
                  options={maintenanceItems?.[0]?.options?.map((opt, i) => (
                    <Radio key={i} value={opt}>
                      {opt}
                    </Radio>
                  ))}
                />
              )}
              {!!selectedTeam?.length && getAccessRights("Teams")?.write ? (
                <MondayButton
                  onClick={openMemberModal}
                  Icon={<PlusOutlined />}
                  className="membersButton"
                >
                  Members
                </MondayButton>
              ) : (
                <></>
              )}
              {getAccessRights("Teams") && (
                <DynamicAvatars
                  title={"Maintenance Request Team"}
                  isDarkMode={isDarkMode}
                  members={selectedTeam?.reduce(
                    (acc, val) => [...acc, ...val?.members],
                    []
                  )}
                />
              )}
            </BorderedTextCard>
            {!inspectionId && (
              <BorderedTextCard
                title="Maintenance Items"
                childrenClassName="checkboxItemsContainer"
                isDarkMode={isDarkMode}
                // className="maintenanceItemsCard"
              >
                {maintenanceItems.map((item, i) => {
                  return (
                    <div id={i === 0 ? "maintenanceItemsCard" : null}>
                      <InputComponent
                        label={item.label}
                        required={item.required}
                        type={item.type}
                        form={form}
                        className={form.getFieldValue(item.formItemName)}
                        formItemName={item.formItemName}
                        disabled={!selectedFleet || fleetId}
                        onChange={(e) => onRadioSelect(e, item)}
                        options={item.options.map((opt, i) => (
                          <Radio key={i} value={opt}>
                            {opt}
                          </Radio>
                        ))}
                      />
                      {(predefinedData?.maintenanceReport &&
                        !!predefinedData?.maintenanceReport?.find(
                          ({ label }) => label === item.label
                        )?.value !== undefined) ||
                      booleans.find(({ label }) => label === item.label)
                        ?.value !== undefined ? (
                        <GPickerButton
                          required={
                            defectList.find(({ label }) => label === item.label)
                              ? true
                              : false
                          }
                          parentId={fleetId ? "" : gDriveFolderId}
                          accessToken={accessToken}
                          label={`Upload for ${item.label}`}
                          uploaderId={item.label}
                          fileNames={fileNames}
                          onPickerSuccess={onPickerSuccess}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
                {/* {RenderDynamicComponents(maintenanceItems)} */}
              </BorderedTextCard>
            )}
            <BorderedTextCard
              title="Mechanic Note"
              className="mechanicNoteCard"
              isDarkMode={isDarkMode}
            >
              <Editor
                {...{
                  onEditorChange: (content) => {
                    setEditorBody(content);
                  },
                  value: editorBody,
                  init: {
                    min_height: 150,
                    block_unsupported_drop: false,
                    plugins: "link image code table lists",
                    width: "100%",
                    menubar:
                      "file edit view format tools help fields newFields",
                    lists_indent_on_tab: false,
                    toolbar:
                      "undo redo | " +
                      "bold italic backcolor forecolor fontsizeselect fontselect  | alignleft aligncenter | " +
                      "alignright alignjustify | " +
                      "removeformat | numlist bullist",
                  },
                }}
              />
            </BorderedTextCard>
          </div>
        </Form>
      </NormalSizedModal>
      <WarningModal
        visible={deleteWarningModalVisible}
        setVisible={setDeleteWarningModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to delete?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setDeleteWarningModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onDelete}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions,
            onSave: (data) => {
              form.setFieldValue("fleetMaintenanceTeam", data || []);
            },
            proppedAllTeams: allTeams,
          }}
        />
      )}
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningTriangle />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              Icon={<XIcon />}
              onClick={() => setCancelModalVisible(false)}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancelModal}
              Icon={<TickIcon width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {logsVisible && (
        <MultiLevelTreeLogs
          {...{
            visible: logsVisible,
            setVisible: setLogsVisible,
            logsData: existingLogs || [],
            title: "Maintenance Logs",
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
          mask={{ color: "#2a2b3a71" }}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Maintenance Tutorial",
          }}
        />
      )}
      {toDoVisible && (
        <ToDoModal
          {...{
            toDoVisible,
            setToDoVisible,
            recordId: maintenanceId,
            recordName: predefinedData?.fleetName,
            isDarkMode,
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleet Maintenance",
            actionType: !!maintenanceId ? "Update" : "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              if (!!!isNextStep) {
                if (!!predefinedData?.maintenanceId) {
                  setTimeout(navigate("/fleetMaintenance"), 2000);
                } else {
                  navigate("/fleetMaintenance");
                }
              }
              setVisible(false);
            },
          }}
        />
      )}
    </>
  );
};

export default MaintenanceModal;

import { RentalBody, RentalFooter, RentalHeader } from "./Components";
import { useRedux } from "../../../../../../../hooks";
import "./RentalBreakdown.scss";
import React from "react";
import RentalHelper from "./Components/RentalHelper/RentalHelper";

// import { useStateContext } from "../../../Context/Context"

const RentalBreakdown = ({ elevationData, canRedo }) => {
  const [darkMode] = useRedux("rentalsDarkMode");
  const [color] = useRedux("rentalsColor");
  // const { darkMode, color } = useStateContext()
  console.log("elevationData", elevationData);

  return (
    <div
      className={darkMode ? "RentalBreakdownDark" : "RentalBreakdownLight"}
      style={{ boxShadow: `0px 0px 6px 3px ${color}` }}
    >
      <RentalHeader {...{ elevationData, canRedo }} />
      <RentalBody {...{ elevationData }} />
      <RentalFooter {...{ elevationData }} />
    </div>
  );
};

export default React.memo(RentalBreakdown);

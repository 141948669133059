import { API } from "aws-amplify";
import { useSelector } from "react-redux";
import { Form, message, Modal } from "antd";
import { useEffect, useMemo, useState, useCallback } from "react";

import fleetServiceFields from "./fleetServiceFields";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../settingsComponents/Roles/src";
import { XIcon } from "../../../../../SidebarPages/Communication/assets";
import { RenderDynamicComponents } from "src/components/Header/forms/Components";
import broadcastNotification from "src/helpers/controllers/broadcastNotification";
import { StoreType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { ServiceTaskType } from "src/components/SidebarPages/FleetMaintenanceView/types";

import "./FleetServiceModal.scss";

type FormDataType = {
  laborCost: number | undefined;
  partsCost: number | undefined;
  totalCost: number | undefined;
  serviceTitle: string;
  serviceDescription: string | undefined;
  serviceTaskId: string;
};

type Props = {
  open: boolean;
  onCancel: () => void;
  editData?: ServiceTaskType;
  onSave?: (data: FormDataType) => void;
  setRowData?: React.Dispatch<React.SetStateAction<ServiceTaskType[]>>;
  refreshTable?: (
    data: ServiceTaskType | ServiceTaskType[],
    action: "add" | "edit" | "delete"
  ) => void;
};

const FleetServiceModal = ({
  open,
  onSave,
  onCancel,
  editData,
  setRowData,
  refreshTable,
}: Props) => {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { userConfiguration } = useSelector(
    (state: StoreType) => state.userConfig
  );

  const [saveDisabled, setSaveDisabled] = useState<boolean>(false);

  const [form] = Form.useForm();

  const saveHandler = useCallback(async () => {
    setSaveDisabled(true);
    await form
      .validateFields()
      .then(async (fields: FormDataType) => {
        const dataToSave: ServiceTaskType & { userId?: string } = editData
          ? { ...editData, ...fields }
          : { ...fields, serviceTaskId: "" };

        message.loading({
          content: "Saving service task...",
          key: "taskSave",
          duration: 0,
        });

        let { serviceTaskId, userId, ...newSaveData } = dataToSave;
        const serviceTitle = newSaveData.serviceTitle;
        let notificationAction = "onServiceTaskCreation";

        let call = API.post.bind(API, "serviceTasks", "/serviceTasks", {
          body: newSaveData,
        });

        if (editData) {
          call = API.put.bind(
            API,
            "serviceTasks",
            `/serviceTasks/${editData.serviceTaskId}`,
            {
              body: newSaveData,
            }
          );

          notificationAction = "onServiceTaskEdit";
        }

        await call()
          .then((res: any) => {
            message.success({
              content: "Service task saved!",
              key: "taskSave",
            });
            if (res?.serviceTaskId) {
              serviceTaskId = res.serviceTaskId;
            }

            if (onSave && typeof onSave === "function") {
              onSave({ ...newSaveData, serviceTaskId });
            }

            if (refreshTable && typeof refreshTable === "function") {
              refreshTable(
                { ...newSaveData, serviceTaskId },
                editData?.serviceTaskId ? "edit" : "add"
              );
            }
            if (setRowData && typeof setRowData === "function") {
              setRowData((prev) => {
                if (editData) {
                  return prev.map((el) =>
                    el.serviceTaskId == editData.serviceTaskId
                      ? { ...newSaveData, serviceTaskId }
                      : el
                  );
                } else {
                  return [{ ...newSaveData, serviceTaskId }, ...prev];
                }
              });
            }

            broadcastNotification(
              "121",
              notificationAction,
              [
                {
                  common: userConfiguration.nameOfUser,
                  serviceTitle,
                },
                {
                  userName: userConfiguration.nameOfUser,
                  currentUser: userConfiguration.cognitoUserId,
                },
              ],
              null
            );
          })
          .catch((err: any) => {
            console.log("Error saving service task: ", err);
            message.error({
              content: "Could not save service task",
              key: "taskSave",
            });
          })
          .finally(() => {
            onCancel();
            setSaveDisabled(false);
          });
      })
      .catch(() => setSaveDisabled(false));
  }, []);

  const fields = useMemo(() => {
    return fleetServiceFields({ saveHandler, onCostChange });
  }, [saveHandler]);

  useEffect(() => {
    document.getElementById("serviceTitle").focus();
    if (editData && form) {
      form.setFieldsValue(editData);
    }
  }, [editData, form]);

  function onCostChange(e: number, field: string) {
    const totalCost = form.getFieldValue(field) || 0;
    form.setFieldValue("totalCost", totalCost + e);
  }

  return (
    <Modal
      title="Service Task"
      open={open}
      onCancel={onCancel}
      closeIcon={<XIcon />}
      closable={true}
      centered={true}
      destroyOnClose={true}
      data-testid="service-task-modal"
      className={`fleet-service-modal-modal ${
        isDarkMode ? "fleet-service-modal-modal-dark" : ""
      }`}
      footer={[
        <MondayButton
          Icon={<TickIcon height={17} width={17} />}
          onClick={saveHandler}
          disabled={saveDisabled}
          data-testid="service-task-save"
        >
          Save
        </MondayButton>,
        <MondayButton
          className="mondayButtonRed"
          Icon={<XIcon />}
          data-testid="service-task-close"
          onClick={onCancel}
        >
          Cancel
        </MondayButton>,
      ]}
    >
      <Form form={form}>
        <section className="details">
          {RenderDynamicComponents(fields.details, form)}
        </section>
        <section className="cost">
          {RenderDynamicComponents(fields.cost, form)}
        </section>
      </Form>
    </Modal>
  );
};

export default FleetServiceModal;

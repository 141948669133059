import _ from "lodash";
import { Button, Tooltip, Badge } from "antd";

import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";
import { getAccessRights } from "./dispatchFunctions";
import { RenderDynamicComponents } from "../../../../../../Header/forms/Components";
import { DropdownWrapper } from "../../../../components";
import { TrashIcon } from "../../../../../Communication/assets";
import { LogsIcon } from "../../../../../DynamicView/src";
import { XIcon } from "../../../../../Communication/assets";
import { DownCaretWhite, PdfDocIcon } from "../../../../../../../assets/icons";
import { MondayButton, LinkButton } from "../../../../../../commonComponents";
import { RightArrow } from "../../../../../BasePage/src";
import { parseInTz } from "./dateFunctions";
import { ConnectedWhite } from "../../../../../../../assets/icons";
import { appStore } from "src/store";

export const timeFormat = "hh:mm a";
export const dateFormat = "M/D/YYYY";
export const cargoState = ["Empty", "Loaded"];

/**
 * @returns {string[]}
 */
export function getYards() {
  return (appStore.getState()?.yards?.yards || [])?.map(
    ({ address }) => address
  );
}

export const flashAnimationFrames = {
  dark: [
    { backgroundColor: "#12131b" },
    { backgroundColor: "#2d531e" },
    { backgroundColor: "#12131b" },
    { backgroundColor: "#2d531e" },
    { backgroundColor: "#12131b" },
  ],
  light: [
    { backgroundColor: "#fff" },
    { backgroundColor: "#dafacd" },
    { backgroundColor: "#fff" },
    { backgroundColor: "#dafacd" },
    { backgroundColor: "#fff" },
  ],
};

export const flashAnimationOptions = {
  duration: 900,
  delay: 100,
};

export const simulateClosingFrames = [
  { transform: "scale(0)" },
  { transform: "scale(0)" },
  { transform: "scale(0)" },
  { transform: "scale(1)" },
];

export const paperworkTypes = ["Inbound", "Outbound"];

export function getBaseLabelHtml(title, required = true) {
  return (
    <>
      {required && <span style={{ color: "red" }}>*</span>}
      {title}
    </>
  );
}

export const dropOffBaseHTML = (
  <>
    <span style={{ color: "red" }}>*</span>Drop off Location
  </>
);

function HoistLabel({ children }) {
  return (
    <span
      style={{
        whiteSpace: "nowrap",
        display: "flex",
        justifyContent: "flex-start",
        alignItems: "center",
        flexDirection: "row",
        flexWrap: "nowrap",
        maxWidth: "100%",
      }}
    >
      <span style={{ color: "#fe4c4a" }}>Hoist -&nbsp;</span>
      {children}
    </span>
  );
}

export function testUUID(text) {
  if (!text) {
    return false;
  }
  let rg = new RegExp(
    /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/,
    "i"
  );
  return rg.test(text);
}

/**
 * Gets an edited version of the label for the drop off
 */
export function getLabelHTML(
  type = "",
  customText,
  redLabelAddition,
  required = true
) {
  let inlineCondition = false;
  if (type === "schedule" || type === "yard" || type === "vendor") {
    inlineCondition = true;
  }
  return (
    <div
      style={{
        whiteSpace: "nowrap",
        textOverflow: "ellipsis",
        maxWidth: "100%",
        overflow: "hidden",
      }}
    >
      {getBaseLabelHtml(customText || "Drop off Location", required)}{" "}
      <span
        style={{ color: "#ff4d4f", whiteSpace: "nowrap" }}
        title={`${type.charAt(0).toUpperCase()}${type.slice(1)}${
          redLabelAddition ? " " + redLabelAddition : ""
        }`}
      >{`${inlineCondition ? "(" : ""}${type
        .charAt(0)
        .toUpperCase()}${type.slice(1)}${redLabelAddition ? " " : ""}${
        redLabelAddition || ""
      }${inlineCondition ? ")" : ""}`}</span>
    </div>
  );
}

/**
 * Gets the label for a driver in case there was a driver switch
 */
function getDriverHTML(isSwitched) {
  return (
    <div style={{ whiteSpace: "nowrap" }}>
      {getBaseLabelHtml("Driver")}{" "}
      {isSwitched ? (
        <span style={{ color: "#ff4d4f", whiteSpace: "nowrap" }}>
          {"(Switched)"}
        </span>
      ) : (
        ""
      )}
    </div>
  );
}

function getNextDayLabel(dateField, thisField, label) {
  if (!thisField) {
    return {
      label: "thisDay",
      component: getBaseLabelHtml(label),
      class: "returnRoute-pickTime",
    };
  }

  let thisDateValue = dayjsNY(thisField || undefined)
    .startOf("day")
    ?.valueOf();

  let fieldValue = dayjsNY(dateField).startOf("day")?.valueOf();

  if (thisDateValue === fieldValue) {
    return {
      label: "thisDay",
      component: getBaseLabelHtml(label),
    };
  } else if (thisDateValue > fieldValue) {
    return {
      label: "nextDay",
      component: (
        <div style={{ whiteSpace: "nowrap" }}>
          {getBaseLabelHtml(label)}{" "}
          <span style={{ color: "#ff4d4f", whiteSpace: "nowrap" }}>
            {"(Next Day)"}
          </span>
        </div>
      ),
      class: "next-day-picker",
    };
  } else {
    return {
      label: "prevDay",
      component: (
        <div style={{ whiteSpace: "nowrap" }}>
          {getBaseLabelHtml(label)}{" "}
          <span style={{ color: "#ff4d4f", whiteSpace: "nowrap" }}>
            {"(Previous Day)"}
          </span>
        </div>
      ),
      class: "prev-day-picker",
    };
  }
}

function DropOffPopover({
  confirmHandler = () => {},
  clearHandler = () => {},
  valueCleared,
  isDarkMode,
}) {
  return (
    <div
      style={{
        maxWidth: "400px",
        backgroundColor: isDarkMode ? "#2f2e3d" : "#fff",
      }}
    >
      {!valueCleared ? (
        <>
          <strong style={{ color: isDarkMode ? "#fff" : "#333238" }}>
            Do you want the existing location to be chosen for the switch or do
            you want to choose another one?
          </strong>
          <div
            style={{
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
              width: "100%",
              flexDirection: "row",
              marginTop: "1rem",
              gap: "0.5rem",
            }}
          >
            <Button
              type="primary"
              onClick={confirmHandler}
              data-testid="cancel-use-existing"
            >
              Use existing
            </Button>
            <Button
              onClick={clearHandler}
              data-testid="cancel-select-new"
              style={{
                backgroundColor: "#71cf48",
                color: "white",
                fontWeight: "400",
              }}
            >
              Choose new
            </Button>
          </div>
        </>
      ) : (
        <strong style={{ color: isDarkMode ? "#fff" : "#333238" }}>
          Please choose a location to be used for the driver switch
        </strong>
      )}
    </div>
  );
}

function getOptionsFromRequests() {
  return [
    {
      label: "",
      options: [
        {
          value: "",
          label: (
            <div
              style={{
                width: "100%",
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
              }}
            >
              <Button type="primary" data-testid="drop-off-show-requests">
                Show Requests
              </Button>
            </div>
          ),
        },
      ],
    },
  ].filter(Boolean);
}

export function filterRequests({
  requests = [],
  date = undefined,
  truck = undefined,
}) {
  return requests?.filter(({ requestObject }) => {
    const { vehicle = null, dateOnSite } = requestObject;
    if (
      dayjsNY(dateOnSite).format(dateFormat) ===
      dayjsNY(date).format(dateFormat)
    ) {
      if (vehicle === null) return true;
      if (truck === undefined) return true;
      else if (vehicle === truck) return true;
      else return false;
    } else {
      return false;
    }
  });
}

/**
 * Function that regulates the widths of the location inputs
 * @param {FormInstance} form A pointer to the form
 * @param {Number} index The index of the card
 * @param {Boolean} globalDisabled Used to check for cancelled routes
 * @returns the widths for the pickup and drop off locations
 */
function getWidths(form, index, globalDisabled) {
  //since the default Autocomplete components cannot expand to the text's width
  //we need to calculate that and we edit the widths programmatically
  let pickupWidth = 300,
    dropOffWidth = 300;
  let returnCondition = index === null;
  let dummy = document.querySelector(".dispatchModalControl");
  let p = document.createElement("span");
  p.innerHTML = form.getFieldValue(
    `${
      returnCondition ? "Return" : globalDisabled ? "Ghost" : ""
    }PickupLocation#${returnCondition ? "" : index}`
  );
  let d = document.createElement("span");
  d.innerHTML = form.getFieldValue(
    `${
      returnCondition ? "Return" : globalDisabled ? "Ghost" : ""
    }DropOffLocation#${returnCondition ? "" : index}`
  );
  if (!!dummy) {
    dummy.appendChild(p);
    pickupWidth = p.clientWidth < 100 ? 300 : p.clientWidth + 52;
    dummy.removeChild(p);
    dummy.appendChild(d);
    //takes into account the width of the label
    dropOffWidth = d.clientWidth < 318 ? 360 : d.clientWidth + 52;
    dummy.removeChild(d);
  }
  return {
    pickupWidth,
    dropOffWidth,
  };
}

function ItineraryTooltip({
  itineraryData,
  index,
  routeLength,
  setOpenItinerary,
}) {
  return (
    <Tooltip title="Open Itinerary" className="itineraryButton">
      <Badge
        count={
          itineraryData?.[index === null ? routeLength : index]?.length || 0
        }
      >
        <MondayButton
          {...{
            Icon: <ConnectedWhite />,
            className: "mondayButtonBlue",
            onClick() {
              setOpenItinerary(index);
            },
            "data-testid": `open-itinerary-${index}`,
          }}
        >
          {""}
        </MondayButton>
      </Badge>
    </Tooltip>
  );
}

/**
 * Returns the form fields needed for a route card. All fields have a "baseFormName" key
 * that takes the name of the field (stays concise with the label). All formItemName keys, get
 * the baseFormName with an index at the end, the return card has the same name but takes
 * "Return" at the beginning without an index.
 * When a drop off gets selected it needs to be able to distinguish between yards, schedules,
 * projects and google results. The way this is done is that all schedule values have an empty space at the end
 * (so it doesn't conflict with the validations, putting another thing,
 * like scheduleId, could cause problems with the distance service)
 * When the drop off is blurred, the trimmed value is re-instated.
 */
export function routeFields({
  onPickupLocationSelect = () => {},
  onDropOffSelect = () => {},
  onDepartChange = () => {},
  onArrivalChange = () => {},
  onExitChange = () => {},
  PopoverContent = () => {},
  departPopoverVisible = () => {},
  arrivePopoverVisible = () => {},
  onCargoChange = () => {},
  onDriverSelect = () => {},
  onScheduleSelect = () => {},
  setPrevDriver = () => {},
  setFieldChanged = () => {},
  setPrevTime = () => {},
  onDropOffClear = () => {},
  setOpenItinerary = () => {},
  projects = [],
  schedulesForDate = [],
  vendors = [],
  approvalOptions = [],
  drivers = [],
  hoistRoutes = [],
  form = {},
  itineraryData = {},
  garageAddresses = {},
  addressesField = {},
  routeLength = 1,
  disabledMinutes,
  disabledHours,
  dropOffDisabledCondition,
  dropOffSelectAfterForceCancel,
  driverIsSwitchedCondition,
  onDropOffBlur,
  dropOffLabel,
  index = null,
  staticPassenger = null,
  dropOffInitialValue = undefined,
  fromApprovals = false,
  dropOffAutoFocus = false,
  requestDisabledFields = false,
  returnCondition = false,
  globalDisabled = false,
  lastRouteOnEditBlock = false,
  ghostCondition = false,
  isDarkMode = false,
}) {
  let { pickupWidth, dropOffWidth } = getWidths(form, index, globalDisabled);

  const selectedDate = parseInTz(form.getFieldValue("date"))
    .startOf("day")
    .valueOf();

  if (index === 0 && !!dropOffInitialValue ? dropOffInitialValue : undefined) {
    form.setFieldValue("DropOffLocation#0", dropOffInitialValue);
  }

  const isHoist = hoistRoutes.includes(index);

  const driverGroups = _.groupBy(
    drivers.filter((driver) => {
      if (
        (driver?.inactivityTimeline || []).some(
          ({ start, end }) =>
            dayjsNY(start).startOf("D").valueOf() <= selectedDate &&
            dayjsNY(end || undefined)
              .startOf("D")
              .valueOf() > selectedDate
        )
      ) {
        return false;
      }

      if (
        driver?.driverStatus === "Terminated" ||
        driver?.driverStatus === "Suspended"
      ) {
        return false;
      }
      return true;
    }),
    ({ driverType }) => driverType || "Other"
  );

  const selectedDriver = form.getFieldValue(`Driver#${index}`);

  const groupKeys = Object.keys(driverGroups);
  groupKeys.sort((a, b) => {
    if (a === "Other") {
      return 1;
    }

    if (b === "Other") {
      return -1;
    }

    return ("" + a).localeCompare("" + b);
  });

  return [
    index !== null && {
      type: "input",
      baseFormName: `cancelId#`,
      dataTestid: `cancelId#${index}`,
      className: "ghostElement",
    },
    {
      label: "Pickup Location",
      type: "placesautocomplete",
      allowSearch: true,
      disabled: index !== 0 || globalDisabled,
      placeholder: "Where From",
      baseFormName: "PickupLocation#",
      dataTestid: `PickupLocation#${index}`,
      style: { width: pickupWidth },
      initialValue: !returnCondition && getYards()?.[0],
      allowClear: fromApprovals,
      className: "routePicker",
      groupedOptions: [
        {
          label: "Yards",
          options: getYards()
            ?.map((y) => ({
              label: y,
              value: y,
            }))
            ?.filter(
              (el) =>
                el?.value !==
                form.getFieldValue(
                  `${returnCondition ? "Return" : ""}DropOffLocation#${
                    returnCondition ? "" : index
                  }`
                )
            ),
        },
        form.getFieldValue("truckNumber") &&
          !!garageAddresses?.[form.getFieldValue("truckNumber")]?.[
            "address"
          ] && {
            label: "Garage Address",
            options: [
              {
                value:
                  garageAddresses?.[form.getFieldValue("truckNumber")]?.[
                    "address"
                  ],
              },
            ],
          },
        selectedDriver &&
          !!addressesField?.["fieldOptions"]?.[selectedDriver]?.length && {
            label: "Home Address",
            options: addressesField?.["fieldOptions"]?.[selectedDriver]?.map(
              ({ address }) => ({ value: address })
            ),
          },
      ].filter(Boolean),
      required: true,
      onSelect: (val) => onPickupLocationSelect(val, index),
    },
    {
      label: isHoist ? (
        <HoistLabel>{dropOffLabel}</HoistLabel>
      ) : (
        dropOffLabel || getBaseLabelHtml("Drop off Location")
      ),
      type: "placesautocomplete",
      allowSearch: !fromApprovals,
      popoverVisible: index === dropOffSelectAfterForceCancel,
      popoverClassName: isDarkMode && "dark-popover",
      popoverContent: (
        <DropOffPopover
          {...{
            confirmHandler() {
              onDropOffSelect(
                form.getFieldValue(`DropOffLocation#${index}`),
                index
              );
            },
            clearHandler() {
              form.setFieldValue(`DropOffLocation#${index}`, "");
              setFieldChanged((prev) => !prev);
            },
            index,
            valueCleared: !form.getFieldValue(`DropOffLocation#${index}`),
            isDarkMode,
          }}
        />
      ),
      disabled:
        requestDisabledFields ||
        // returnCondition ||
        index in itineraryData ||
        (index !== dropOffSelectAfterForceCancel &&
          (dropOffDisabledCondition ||
            (globalDisabled && !lastRouteOnEditBlock))),
      placeholder: "Where To",
      baseFormName: "DropOffLocation#",
      dataTestid: `DropOffLocation#${index}`,
      onBlur: () => onDropOffBlur(index),
      style: { width: dropOffWidth },
      isCustomValue: true,
      popupClassName: `${
        index === 0
          ? !!approvalOptions?.length
            ? "dispatchRoutesPopup"
            : "dispatchRoutesRemove"
          : ""
      }`,
      open: index === 0 ? dropOffAutoFocus : false,
      rules: [
        {
          required: true,
          message: !requestDisabledFields ? "*Drop off is required!" : "",
        },
      ],
      onChange(val) {
        if (!val) {
          onDropOffClear(index);
        }
      },
      initialValue: returnCondition && getYards()?.[0],
      className: `routePicker doff-${index}`,
      groupedOptions:
        index === null
          ? [
              {
                label: "Yards",
                options: getYards()
                  ?.map((y) => ({
                    label: y,
                    value: y,
                  }))
                  ?.filter(
                    (el) =>
                      el?.value !==
                      form.getFieldValue(
                        `${returnCondition ? "Return" : ""}DropOffLocation#${
                          returnCondition ? "" : index
                        }`
                      )
                  ),
              },
              form.getFieldValue("truckNumber") &&
                !!garageAddresses?.[form.getFieldValue("truckNumber")]?.[
                  "address"
                ] && {
                  label: "Garage Address",
                  options: [
                    {
                      value:
                        garageAddresses?.[form.getFieldValue("truckNumber")]?.[
                          "address"
                        ],
                    },
                  ],
                },
            ].filter(Boolean)
          : [
              index === 0 &&
                approvalOptions?.length &&
                getOptionsFromRequests(),
              {
                label: "Yards",
                options: getYards()?.map((y) => ({
                  label: y,
                  value: y,
                })),
              },
              {
                label: "Schedules",
                options: schedulesForDate
                  ?.filter(
                    ({ scheduleAddress }) =>
                      isHoist ||
                      hoistRoutes.includes(index - 1) ||
                      (scheduleAddress || "")?.trim() !==
                        (
                          form.getFieldValue(
                            `${returnCondition ? "Return" : ""}PickupLocation#${
                              returnCondition ? "" : index
                            }`
                          ) || ""
                        )?.trim()
                  )
                  ?.reduce(
                    (acc, val) => [
                      ...acc,
                      !acc?.find((e) => e?.label === val?.scheduleAddress)
                        ? {
                            key: val?.scheduleAddress + Math.random(),
                            value: val?.scheduleAddress + " ",
                            label: val?.scheduleAddress,
                          }
                        : null,
                    ],
                    []
                  )
                  ?.filter(Boolean)
                  .sort((a, b) => a?.value.localeCompare(b?.value)),
              },
              {
                label: "Vendors",
                options: vendors
                  ?.filter((el) => !!el?.vendorAddress)
                  ?.map(({ vendorAddress, vendorName, vendorId }) => ({
                    key: vendorId,
                    vendorName,
                    label: (
                      <div style={{ width: "100%" }} title={vendorAddress}>
                        {vendorName}
                      </div>
                    ),
                    value: vendorAddress,
                  }))
                  .sort((a, b) => a?.vendorName.localeCompare(b?.vendorName)),
              },
              {
                label: "Projects",
                options: projects
                  ?.filter(
                    (el) =>
                      isHoist ||
                      hoistRoutes.includes(index - 1) ||
                      (el?.projectName !==
                        form.getFieldValue(
                          `${returnCondition ? "Return" : ""}PickupLocation#${
                            returnCondition ? "" : index
                          }`
                        ) &&
                        !schedulesForDate?.find(
                          ({ scheduleAddress }) =>
                            scheduleAddress === el?.projectName
                        ))
                  )
                  ?.map((e) => ({
                    key: e?.projectName + Math.random(),
                    value: e?.projectName,
                    label: e?.projectName,
                  }))
                  .sort((a, b) => a?.value.localeCompare(b?.value)),
              },
            ]
              .filter(Boolean)
              .flat(),
      onSelect: (val, option) => onDropOffSelect(val, index, option),
    },
    !returnCondition && {
      label: "Schedules",
      baseFormName: "Schedule#",
      dataTestid: `Schedule#${index}`,
      type: "select",
      onSelect: (val) => onScheduleSelect(val, index),
      showSearch: true,
      required: false,
      disabled: globalDisabled,
      customOptions: schedulesForDate?.map((el) => ({
        label: el?.scheduleAddress,
        value: el?.scheduleId,
      })),
      style: { minWidth: "15%", display: "none" },
    },
    {
      customComponent: ItineraryTooltip,
      componentProps: {
        itineraryData,
        index,
        routeLength,
        setOpenItinerary,
      },
      title: "Route Itinerary",
    },
    {
      label: getDriverHTML(driverIsSwitchedCondition),
      placeholder: "Select...",
      baseFormName: "Driver#",
      dataTestid: `Driver#${index}`,
      type: "select",
      onSelect: (val) => onDriverSelect(val, index),
      showSearch: true,
      disabled: globalDisabled,
      allowClear: false,
      rules: [
        {
          required: true,
          message: "*Driver is required",
        },
      ],
      onDropdownVisibleChange(open) {
        let id = form.getFieldValue(
          `${index !== null ? "" : "Return"}Driver#${
            index !== null ? index : ""
          }`
        );
        if (open && !!id) {
          setPrevDriver(id);
        }
      },
      customOptions: groupKeys.map((group) => ({
        label: group,
        options: driverGroups[group].map((driver) => ({
          title: driver?.driverName,
          value: driver?.driverId,
          label: driver?.driverName,
        })),
      })),
      style: { minWidth: "10%" },
    },
    {
      label: getNextDayLabel(
        form.getFieldValue("date"),
        form.getFieldValue(
          `${
            returnCondition ? "Return" : ghostCondition ? "Ghost" : ""
          }DepartAt#${returnCondition ? "" : index}`
        ),
        "Depart At"
      )?.component,
      placeholder: "Select time",
      type: "timePicker",
      // type: "customTimeInput",
      secondaryDarkMode: true,
      showNow: true,
      format: timeFormat,
      disabled: globalDisabled,
      className: `${
        getNextDayLabel(
          form.getFieldValue("date"),
          form.getFieldValue(
            `${
              returnCondition ? "Return" : ghostCondition ? "Ghost" : ""
            }DepartAt#${returnCondition ? "" : index}`
          ),
          "Depart At"
        )?.class
      }`,
      baseFormName: "DepartAt#",
      dataTestid: `DepartAt#${index}`,
      validateTrigger: ["onBlur", "onChange"],
      disabledHours: disabledHours,
      disabledMinutes: disabledMinutes,
      allowClear: false,
      ...{
        [process.env.NODE_ENV === "test" ? "onSelect" : "onChange"]: (val) =>
          onDepartChange(val, index),
      },
      popoverClassName: `dispatchSuggestionPopover ${
        isDarkMode && "dark-popover"
      }`,
      popoverPlacement: "bottom",
      onClick() {
        setPrevTime({
          index,
          depart:
            form.getFieldValue(
              `${returnCondition ? "Return" : ""}DepartAt#${
                returnCondition ? "" : index
              }`
            ) || null,
          arrive:
            form.getFieldValue(
              `${returnCondition ? "Return" : ""}ArriveBy#${
                returnCondition ? "" : index
              }`
            ) || null,
          exit: returnCondition
            ? null
            : form.getFieldValue(`TimeExit#${index}`) || null,
        });
      },
      rules: [
        {
          required: true,
          message: "*Depart is required",
        },
      ],
      popoverContent: PopoverContent,
      popoverVisible:
        departPopoverVisible && !globalDisabled && staticPassenger === null,
    },
    {
      label: "Cargo",
      type: "radio",
      baseFormName: "Cargo#",
      dataTestid: `Cargo#${index}`,
      buttonStyle: "solid",
      required: true,
      onChange: (e) => onCargoChange({ value: e, index }),
      options: cargoState,
      disabled: ghostCondition,
    },
    {
      label: getNextDayLabel(
        form.getFieldValue("date"),
        form.getFieldValue(
          `${
            returnCondition ? "Return" : ghostCondition ? "Ghost" : ""
          }ArriveBy#${returnCondition ? "" : index}`
        ),
        "Arrive By"
      )?.component,
      placeholder: "Select time",
      type: "timePicker",
      // type: "customTimeInput",
      secondaryDarkMode: true,
      showNow: true,
      format: timeFormat,
      baseFormName: "ArriveBy#",
      dataTestid: `ArriveBy#${index}`,
      validateTrigger: ["onBlur", "onChange"],
      disabledHours: disabledHours,
      disabledMinutes: disabledMinutes,
      allowClear: false,
      disabled: globalDisabled,
      ...{
        [process.env.NODE_ENV === "test" ? "onSelect" : "onChange"]: (val) =>
          onArrivalChange(val, index),
      },
      popoverClassName: `dispatchSuggestionPopover ${
        isDarkMode && "dark-popover"
      }`,
      className: `${
        getNextDayLabel(
          form.getFieldValue("date"),
          form.getFieldValue(
            `${
              returnCondition ? "Return" : ghostCondition ? "Ghost" : ""
            }ArriveBy#${returnCondition ? "" : index}`
          ),
          "Arrive By"
        )?.class
      }`,
      popoverPlacement: "bottom",
      popoverContent: PopoverContent,
      onClick() {
        setPrevTime({
          index,
          depart:
            form.getFieldValue(
              `${returnCondition ? "Return" : ""}DepartAt#${
                returnCondition ? "" : index
              }`
            ) || null,
          arrive:
            form.getFieldValue(
              `${returnCondition ? "Return" : ""}ArriveBy#${
                returnCondition ? "" : index
              }`
            ) || null,
          exit: returnCondition
            ? null
            : form.getFieldValue(`TimeExit#${index}`) || null,
        });
      },
      rules: [
        {
          required: true,
          message: "*Arrive is required",
        },
      ],
      popoverVisible: arrivePopoverVisible && staticPassenger === null,
    },
    {
      label: "Duration",
      placeholder: "Duration",
      baseFormName: "Duration#",
      dataTestid: `Duration#${index}`,
      className: "routeDuration",
      disabled: true,
    },
    !returnCondition && {
      label: getNextDayLabel(
        form.getFieldValue("date"),
        form.getFieldValue(`${ghostCondition ? "Ghost" : ""}TimeExit#${index}`),
        "Time Exit"
      )?.component,
      placeholder: "Select time",
      type: "timePicker",
      // type: "customTimeInput",
      secondaryDarkMode: true,
      showNow: true,
      format: timeFormat,
      baseFormName: "TimeExit#",
      dataTestid: `TimeExit#${index}`,
      onClick() {
        setPrevTime({
          index,
          depart:
            form.getFieldValue(
              `${returnCondition ? "Return" : ""}DepartAt#${
                returnCondition ? "" : index
              }`
            ) || null,
          arrive:
            form.getFieldValue(
              `${returnCondition ? "Return" : ""}ArriveBy#${
                returnCondition ? "" : index
              }`
            ) || null,
          exit: returnCondition
            ? null
            : form.getFieldValue(`TimeExit#${index}`) || null,
        });
      },
      className: `${
        getNextDayLabel(
          form.getFieldValue("date"),
          form.getFieldValue(
            `${ghostCondition ? "Ghost" : ""}TimeExit#${index}`
          ),
          "Time Exit"
        )?.class
      }`,
      disabled: globalDisabled,
      validateTrigger: ["onBlur", "onChange"],
      disabledHours: disabledHours,
      disabledMinutes: disabledMinutes,
      allowClear: false,
      rules: [
        {
          required: true,
          message: "*Exit is required",
        },
      ],
      ...{
        [process.env.NODE_ENV === "test" ? "onSelect" : "onChange"]: (val) =>
          onExitChange(val, index),
      },
      popoverClassName: "dispatchSuggestionPopover",
      popoverPlacement: "bottom",
    },
    {
      label: getBaseLabelHtml("Paperwork type"),
      placeholder: "Paperwork type",
      type: "select",
      baseFormName: "PaperworkType#",
      dataTestid: `PaperworkType#${index}`,
      style: { width: 200 },
      onClear() {
        setFieldChanged((prev) => !prev);
      },
      onSelect() {
        setFieldChanged((prev) => !prev);
      },
      showArrow:
        !ghostCondition &&
        !form.getFieldValue(
          `${index === null ? "Return" : ""}PaperworkType#${
            index === null ? "" : index
          }`
        ),
      allowClear: false,
      disabled:
        ghostCondition ||
        !!form.getFieldValue(
          `${index === null ? "Return" : ""}PaperworkType#${
            index === null ? "" : index
          }`
        ),
      customOptions: paperworkTypes?.map((type) => ({
        label: type,
        value: type,
      })),
      rules: [
        {
          required: true,
          message: !form.getFieldValue(
            `${index === null ? "Return" : ""}PaperworkType#${
              index === null ? "" : index
            }`
          )
            ? "*Paperwork Type is required"
            : "",
        },
      ],
    },
    {
      label: getBaseLabelHtml("Paperwork collect status"),
      placeholder: "Paperwork collect status",
      baseFormName: "PaperworkCollectStatus#",
      dataTestid: `PaperworkCollectStatus#${index}`,
      type: "input",
      disabled: true,
      rules: [{ required: true, message: "" }],
    },
    {
      label: getBaseLabelHtml("Paperwork software status"),
      placeholder: "Paperwork software status",
      baseFormName: "PaperworkSoftwareStatus#",
      dataTestid: `PaperworkSoftwareStatus#${index}`,
      type: "input",
      disabled: true,
      style: { width: 300 },
      rules: [{ required: true, message: "" }],
    },
    {
      label: "Notes and Details",
      placeholder: "Notes and Details",
      baseFormName: "NotesAndDetails#",
      dataTestid: `NotesAndDetails#${index}`,
      type: "textArea",
      disabled: globalDisabled,
      className: "notesArea",
    },
    {
      label: "Cargo Weight",
      placeholder: "Cargo Weight",
      baseFormName: "CargoWeight#",
      dataTestid: `CargoWeight#${index}`,
      type: "input",
      typeNumber: true,
      initialValue: 0,
      disabled:
        ghostCondition ||
        form.getFieldValue(
          `${returnCondition ? "Return" : ""}Cargo#${
            returnCondition ? "" : index
          }`
        ) === "Empty",
    },
    {
      label: "Returning Weight",
      placeholder: "Returning Weight",
      baseFormName: "ReturningWeight#",
      dataTestid: `ReturningWeight#${index}`,
      type: "input",
      typeNumber: true,
      initialValue: 0,
      disabled:
        ghostCondition ||
        form.getFieldValue(
          `${returnCondition ? "Return" : ""}Cargo#${
            returnCondition ? "" : index
          }`
        ) === "Empty",
    },
  ];
}

export function footerButtons({
  rowObject = {},
  saveHandler = () => {},
  deleteHandler = () => {},
  openLogs = () => {},
  onCancel = () => {},
  accessRight,
  cancelDisabledCondition = false,
  saveCondition,
  complementaryRoutes = [],
  defaultData = undefined,
  generatePdf = () => {},
  changes = false,
  setVisible = () => {},
}) {
  const tooltipCategory = "Dispatches";

  // set props for Link Component
  const popoverProps = {
    fleetId: rowObject?.fleetId,
    projectId: rowObject?.projectId,
    scheduleId: rowObject?.scheduleId,
  };

  return [
    <MondayButton
      {...{
        className: "mondayButtonRed",
        type: "primary",
        Icon: <XIcon fill="#fff" />,
        disabled: cancelDisabledCondition,
        onClick() {
          changes ? onCancel() : setVisible(false);
        },
        tooltipCategory,
        tooltipKey: "cancel",
        "data-testid": "cancel-button",
      }}
      key="cancel"
    >
      <span>Cancel</span>
    </MondayButton>,
    <div className="otherModalButtons" key="other-actions">
      {!!rowObject?.dispatchId && (
        <>
          <MondayButton
            className="mondayButtonPdf"
            Icon={<PdfDocIcon />}
            onClick={generatePdf}
            tooltipCategory={tooltipCategory}
            tooltipKey="exportPdf"
            data-testid="pdf-button"
          >
            Export To Pdf
          </MondayButton>
          {getAccessRights(accessRight, "Logs") ? (
            <MondayButton
              {...{
                className: "mondayButtonBlue",
                type: "primary",
                Icon: <LogsIcon fill="#fff" />,
                onClick() {
                  openLogs();
                },
                tooltipCategory,
                tooltipKey: "showLogs",
                "data-testid": "logs-button",
              }}
            >
              <span>Show Logs</span>
            </MondayButton>
          ) : (
            <></>
          )}
          <LinkButton popoverProps={popoverProps} title="Go To"></LinkButton>
          <MondayButton
            {...{
              className: "mondayButtonRed",
              type: "primary",
              disabled: cancelDisabledCondition,
              Icon: <TrashIcon fill="#fff" />,
              onClick() {
                deleteHandler();
              },
              tooltipCategory,
              tooltipKey: "delete",
              "data-testid": "delete-button",
            }}
          >
            <span>Delete</span>
          </MondayButton>
        </>
      )}
      <DropdownWrapper
        onClick={() => saveHandler(true)}
        key="save-dropdown"
        dropdownOptions={
          Object.keys(rowObject)?.length > 1
            ? []
            : saveCondition || defaultData
            ? []
            : ["Save & New"]
        }
      >
        <MondayButton
          {...{
            Icon: saveCondition ? <RightArrow /> : <DownCaretWhite />,
            onClick() {
              if (complementaryRoutes?.length === 0) {
                saveHandler(false);
              } else {
                saveHandler(true);
              }
            },
            className: "mondayButtonBlue saveBtn",
            type: "primary",
            tooltipCategory,
            tooltipKey: "save",
          }}
          key="save-button"
          data-testid="save-button"
        >
          <span>{saveCondition ? "Save & Change Next" : "Finish"}</span>
        </MondayButton>
      </DropdownWrapper>
    </div>,
  ];
}

export function driverShowField(drivers, trucks, form, onDriverShowSelect, i) {
  let optionGroups = [
    {
      label: "Drivers",
      options: drivers
        ?.map(
          ({ driverName, driverId }) =>
            driverId !==
              form.getFieldValue(
                `${i !== null ? "" : "Return"}Driver#${i !== null ? i : ""}`
              ) && driverName
        )
        ?.filter(Boolean),
    },
    {
      label: "Trucks",
      options: trucks?.map((el) => el?.fleetName),
    },
  ];

  if (
    !!form.getFieldValue(
      `${i !== null ? "" : "Return"}Driver#${i !== null ? i : ""}`
    ) ||
    !!form.getFieldValue(
      `${i !== null ? "" : "Return"}Passenger#${i !== null ? i : ""}`
    )
  ) {
    let t = [
      {
        label: "Selected",
        options: [
          drivers?.find(
            (el) =>
              el?.driverId ===
              form.getFieldValue(
                `${i !== null ? "" : "Return"}Driver#${i !== null ? i : ""}`
              )
          )?.driverName,
        ].filter(Boolean),
      },
      form.getFieldValue("truckNumber") && {
        label: "Selected Truck",
        options: [
          trucks?.find(
            (el) => el?.fleetId === form.getFieldValue("truckNumber")
          )?.fleetName,
        ],
      },
    ].filter(Boolean);

    optionGroups = t.concat(optionGroups);
  }
  return [
    {
      placeholder: "Show routes for...",
      className: "dispatchSelectDriver",
      type: "select",
      showSearch: true,
      onSelect: (val) => {
        onDriverShowSelect(val, i);
      },
      formItemName: `DriverShow#${i !== null ? i : "Return"}`,
      dataTestid: `DriverShow#${i !== null ? i : "Return"}`,
      optionGroups,
    },
  ];
}

export function GhostRoute({ index, form, cancelObject, drivers }) {
  return (
    <div
      className="dispatchRouteCard nonEditable"
      data-testid={`ghost-route-${index}`}
    >
      <div
        className="cardTitle"
        style={{ backgroundColor: "#ff4d4f" }}
      >{`Route ${index + 1}`}</div>
      {RenderDynamicComponents(
        routeFields({
          globalDisabled: true,
          form,
          dropOffLabel: cancelObject?.label,
          drivers,
          index: index,
          staticPassenger: cancelObject?.defaultPassenger,
          ghostCondition: true,
        })
          ?.filter(Boolean)
          ?.map((el) => ({
            ...el,
            formItemName: `Ghost${el?.baseFormName}${index}`,
          })),
        { form }
      )}
    </div>
  );
}

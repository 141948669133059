import { debounce, uniq, uniqBy } from "lodash";
import {
  filterUsersByAccess,
  getDispatchLocationOptions,
} from "../../../../Header/forms/RequestFormModal/requestFormModalData";
import { searchFetchInput } from "../../../../../utils/searchFetchForInput";
import { userAccessRecords } from "../../../Dashboard/NewDashboardFunctions";
import { filterSchedulesOnDate } from "../utils/filterSchedulesOnDate";
import dayjs from "dayjs";
import { searchSafetyAddress } from "../helpers";
// import { LabeledInput } from "../../../Fleet/components";
// import { RichTextEditor } from "../../../../commonComponents";

/**
 * Separates crew members based on the selected foreman.
 *
 * @param {Array} foremen - The list of foremen.
 * @param {string} selectedForeman - The selected foreman.
 * @param {Array} crewMembers - The list of crew members.
 * @returns {Object} - An object containing custom options, class name, and mode.
 */
function separateCrewMembers(foremen, selectedForeman, crewMembers) {
  if (!!selectedForeman) {
    let crew = foremen.find((el) => el?.value === selectedForeman) || [];
    return {
      customOptions: [
        {
          label: "Under Foreman",
          options:
            crew?.members?.map((e) => ({
              value: e.crewId,
              label: e.crewName,
            })) || [],
        },
        {
          label: "Other Members",
          options:
            crewMembers?.filter(
              (e) => !crew.members?.find((el) => el.crewId === e.value)
            ) || [],
        },
      ],
      className: "multipleSelect",
      mode: "multiple",
    };
  } else {
    return {
      customOptions: crewMembers || [],
      className: "multipleSelect",
      mode: "multiple",
    };
  }
}

/**
 * Adds conditional fields to the given fields array based on the provided parameters.
 *
 * @param {Array} fields - The array of fields.
 * @param {number} fieldCategoryIndex - The index of the field category.
 * @param {string} typeOfEquipment - The type of equipment.
 * @param {Object} field - The field object.
 */
function addConditionalFields({
  fields,
  fieldCategoryIndex,
  typeOfEquipment,
  field,
}) {
  if (
    typeOfEquipment === "Other" &&
    field.formItemName === "typeOfEquipmentInvolved"
  ) {
    fields[fieldCategoryIndex]["fields"].push({
      type: "input",
      formItemName: "otherEquipmentInvolved",
      label: "Other Equipment Involved",
      required: true,
      placeholder: "Other Equipment Involved",
      category: field.category,
    });
  }
}

/**
 * Generates an array of fields for the property damage safety form.
 *
 * @param {Object} form - The form object.
 * @param {Array} [crews=[]] - The array of crews.
 * @param {Array} [projects=[]] - The array of projects.
 * @param {Array} [scheduling=[]] - The array of scheduling.
 * @param {null|number} [defaultId=null] - The default ID.
 * @param {string} [propAddress=""] - The property address.
 * @param {Array} [dynamicFields=[]] - The array of dynamic fields.
 * @param {string} [typeOfEquipment=""] - The type of equipment.
 * @param {Array} [schedulesForDate=[]] - The array of schedules for the date.
 * @param {Object} [userConfiguration={}] - The user configuration object.
 * @param {null|Object} [selectedForeman=null] - The selected foreman object.
 * @param {boolean} [disabledAllFields=false] - Whether all fields are disabled.
 * @param {boolean} [mainDisabledField=false] - Whether the main disabled field is disabled.
 * @param {Function} [onChangeAddress=()=>{}] - The function to handle address change.
 * @param {Function} [changeDateOfIncident=()=>{}] - The function to handle date of incident change.
 * @param {Function} [dispatchSafetyFormData=()=>{}] - The function to dispatch safety form data.
 * @returns {Array} - The array of fields for the property damage safety form.
 */
const propertyDamageSafetyFields = ({
  form,
  crews = [],
  projects = [],
  scheduling = [],
  defaultId = null,
  propAddress = "",
  dynamicFields = [],
  typeOfEquipment = "",
  schedulesForDate = [],
  userConfiguration = {},
  projectExecutives = [],
  selectedForeman = null,
  disabledAllFields = false,
  mainDisabledField = false,
  onChangeAddress = () => {},
  changeDateOfIncident = () => {},
  dispatchSafetyFormData = () => {},
}) => {
  let fields = [];

  const projectExecutivesOpt =
    projectExecutives?.map((executive) => ({
      id: executive.peId,
      label: `${executive.firstName} ${executive.lastName}`,
      value: `${executive.firstName} ${executive.lastName}`,
    })) || [];

  const foremen = crews
    ?.filter((e) => !e.foreman)
    ?.map(({ crewId, crewName, members }) => ({
      label: crewName,
      value: crewId,
      members,
    }));

  const crewMembers = crews
    ?.filter((e) => !!e.foreman)
    ?.map(({ crewId, crewName }) => ({
      label: crewName,
      value: crewId,
    }));

  for (let i = 0; i < dynamicFields?.length; i++) {
    let field = structuredClone(dynamicFields[i]);
    delete field["placeholder"];

    if (field.type === "select") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        ...(field.label.includes("Foreman")
          ? { customOptions: foremen }
          : field.label.includes("Members")
          ? separateCrewMembers(foremen, selectedForeman, crewMembers)
          : {}),
        ...(field.label.includes("Project Executive")
          ? { customOptions: projectExecutivesOpt }
          : {}),
      };
      if (field.label.includes("Members")) {
        delete field["options"];
      }
    }

    if (field.type === "placesautocomplete") {
      field = {
        ...field,
        allowClear: true,
        style: { width: 320 },
      };
    }

    if (field.formItemName === "assignTo") {
      delete field["options"];

      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: filterUsersByAccess(userConfiguration, "Safety"),
        style: { width: 200 },
      };
    }

    if (field.formItemName === "addressOfDamagedProperty") {
      field = {
        ...field,
        onSelect: onChangeAddress,
        disabled: mainDisabledField || disabledAllFields,
        initialValue: propAddress,
        groupedOptions: getDispatchLocationOptions(schedulesForDate, projects),
        showSearch: true,
        customOnSearch: async (searchValue) =>
          await searchSafetyAddress({
            searchValue,
            dispatchSafetyFormData,
            userConfiguration,
            projects,
            scheduling,
            schedulesForDate,
            dateField: "dateOfPropertyDamage",
            form,
          }),
      };
    }

    if (field.formItemName === "reportPreparedBy") {
      field = {
        ...field,
        showSearch: true,
        allowClear: true,
        customOptions: userConfiguration.allUsers.Items.map((e) => ({
          value: e.identityId,
          label: e.nameOfUser,
        })),
        initialValue: !defaultId && userConfiguration?.identityId,
      };
    }

    if (
      field.formItemName === "dateOfPropertyDamage" ||
      field.formItemName === "reportPreparedOn"
    ) {
      field = {
        ...field,
        ...(field.formItemName === "dateOfPropertyDamage"
          ? { customOnChange: changeDateOfIncident }
          : {}),
        validTime: "past",
        form,
        // disabledDate(e) {
        //   return e > dayjsNY().add(1, "day").startOf("D").valueOf();
        // },
      };
    }

    if (field.formItemName === "timeOfPropertyDamage") {
      field = {
        ...field,
        dateField: "dateOfPropertyDamage",
        validTime: "past",
        form,
      };
    }

    let fieldCategoryIndex = fields.findIndex(
      (e) => e?.title === field?.category
    );
    if (fieldCategoryIndex > -1) {
      fields[fieldCategoryIndex]["fields"].push(field);

      addConditionalFields({
        fields,
        fieldCategoryIndex,
        typeOfEquipment,
        field,
      });
    } else {
      fields.push({
        title: field?.category,
        fields: [field],
      });
      addConditionalFields({
        fields,
        fieldCategoryIndex: fields.length - 1 || 0,
        typeOfEquipment,
        field,
      });
    }

    if (disabledAllFields) {
      field.disabled = disabledAllFields;
    }
  }

  let additionalInfoOptions = fields.findIndex(
    (e) => e.title === "Additional Information"
  );
  if (additionalInfoOptions > -1) {
    let tmp = fields.splice(additionalInfoOptions, 1);
    fields.push(tmp[0]);
  }

  return fields;
};

export default propertyDamageSafetyFields;

import axios from "axios";
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import { linxupEndpoints } from "../../components/SidebarPages/Fleet/fleetsLive/utils";
import { isLocationInsideGeofences } from "../Geofence/isLocationInsideGeofences";

export const getGeoFencesActiveActivities = async () => {
  try {
    const [projects, locationsData] = await Promise.all([
      fetchAllData("projects", "projects", "projectId"),
      axios.get(linxupEndpoints.location),
    ]);

    let locations =
      locationsData.status === 200 ? locationsData.data.data.locations : [];

    const activities = [];

    projects.forEach((project) => {
      Array.isArray(project.geoFenceInfo) &&
        locations.forEach((location) => {
          const isInProject = isLocationInsideGeofences(
            location,
            project.geoFenceInfo
          );
          isInProject &&
            activities.push({
              ...location,
              projectName: project.projectName,
              projectAddress: project.projectAddress,
              projectStatus: project.projectStatus,
            });
        });
    });

    return activities;
  } catch (error) {
    console.log(
      "data source function getGeoFencesActiveActivities error: ",
      error
    );
  }
};

import { useEffect, useState } from "react";
import { filterTables } from "../../../../../../../../utils/filterTables";
import { fetchData } from "../../../../../../Fleet/utils/fetchData";
import {
  calculateInvoicedAmountForCategoryDataObj,
  getInfoForRentalServices,
} from "../../../../../../Accounting/components/utilities";
import { checkIfServiceIsHoist } from "../../../../../../Estimations/DataEntryGrid/models/Service";
import { useRedux } from "../../../../../../hooks";
import { useTableContext } from "../../../../../../../commonComponents/DynamicAgGridTable/context/AgGridTableContext";
import { calculateTotalForRowData } from "../utils/calculateTotalForRowData";
import { fetchAllData } from "../../../../../../../../utils";

export const useNewCharge = ({
  setSelectedCategory = () => {},
  updateWhereToFind = () => {},
  project,
  setSelectedData,
  charges,
  closeModal = () => {},
}) => {
  const [requisitions, setRequisitions] = useState(null);
  const [groups, setGroups] = useState([]);
  const [dataToCompare, setDataToCompare] = useState(null);
  const [fromObject, setFromObject] = useState([]);
  const [categoriesResponse, setCategoriesResponse] = useState(null);
  const [whereToFind, setWhereToFind] = useState();
  const [selectedGroup, setSelectedGroup] = useState(false);

  const [visible, setVisible] = useRedux("newChargeVisible");

  const { setTableLoading } = useTableContext();

  const categoryFrom = visible?.categoryFrom;

  // Helper functions
  const getApprovedEstimations = (estimations, projectData) => {
    const approvedEstimations = {};
    Object.keys(projectData.services)
      .filter((estimationId) => {
        const currEst = estimations.find(
          ({ estimationId: eID }) => estimationId === eID
        );
        return currEst?.estSTATUS === "Approved";
      })
      .forEach(
        (estID) => (approvedEstimations[estID] = project.services[estID])
      );

    return approvedEstimations;
  };

  const getIncludedRequisitions = (appliedRequisitions, estimationId) => {
    const includedRequisitions = appliedRequisitions?.filter(
      ({ estimationsSelected }) => estimationsSelected?.includes(estimationId)
    );

    if (!(includedRequisitions?.length > 0)) return false;
    return includedRequisitions?.map((req) => ({
      applicationId: req?.applicationId,
      applicationNo: req?.applicationNo,
    }));
  };

  const mapEstimationGroups = (
    approvedEstimations,
    estimations,
    projectData,
    appliedRequisitions
  ) => {
    return Object.keys(projectData.services)?.map((estimationId, i) => {
      const data = projectData.services[estimationId];
      const estimation = estimations.find(
        ({ estimationId: eID }) => estimationId === eID
      );

      const process = !appliedRequisitions.some((el) =>
        el?.estimationsSelected?.some((r) =>
          estimations?.some(
            (es) => es?.estimationId === r && es.estimationId === estimationId
          )
        )
      );

      const label = `Estimation ${i + 1}`;
      const information = data
        .map((service) => ({
          service: service.label,
          isTaxable: service.isTaxable,
          elevations: service.serviceOptions
            .flat(1)
            ?.map((elevation) => elevation?.elevationLabel)
            .flat(1),
        }))
        .flat(1);

      return {
        ...estimation,
        data,
        projectData,
        estimationData: estimation,
        id: estimationId,
        process,
        label,
        information,
        requisitionsIncluded: getIncludedRequisitions(
          appliedRequisitions,
          estimationId
        ),
        total: calculateTotalForRowData(data, estimation, categoryFrom),
        totalInvoicedAmount: calculateInvoicedAmountForCategoryDataObj({
          categoryDataObj: estimation,
        }),
      };
    });
  };

  const mapRentalGroups = (res) => {
    return res.map((e) => {
      const information = getInfoForRentalServices(e);
      const maxEnd = Object.keys(information)?.reduce((prev, key) => {
        const endDate = information[key]?.reduce(
          (prev, el) => (el?.endDate > prev ? el?.endDate : prev),
          information[key][0]?.endDate
        );
        return endDate > prev ? endDate : prev;
      }, information[Object.keys(information)[0]][0]?.endDate);
      const minStart = Object.keys(information)?.reduce((prev, key) => {
        const startDate = information[key]?.reduce(
          (prev, el) => (el?.startDate < prev ? el?.startDate : prev),
          information[key][0]?.startDate
        );
        return startDate < prev ? startDate : prev;
      }, information[Object.keys(information)[0]][0]?.startDate);
      return {
        ...e,
        data: e?.services,
        includedTo: e?.includedTo,
        id: e.rentalId,
        process: !e.fullyCharged,
        label: `Rental ${e?.rentalNumber}`,
        alreadyJoined: e?.includedTo === "" ? false : true,
        startDate: minStart,
        endDate: maxEnd,
        information,
        totalInvoicedAmount: calculateInvoicedAmountForCategoryDataObj({
          categoryDataObj: e,
        }),
      };
    });
  };

  const mapInvoiceGroups = (res) => {
    return res.map((e) => ({
      ...e,
      data: e.invoiceItems,
      charged: e?.charged,
      id: e.invoiceId,
      label: `Invoice ${e.invoiceNumber}`,
      process: !e.fullyCharged,
      information: {
        start: e.invoiceDate,
        end: e.dueDate,
        description: e.invoiceItems.map((service) => {
          return {
            label: service.name,
            ...service,
          };
        }),
      },
    }));
  };

  const mapScheduleOfValueGroups = (res) => {
    return res.map((e) => ({
      ...e,
      data: e?.services,
      id: e.scheduleId,
      process: !e.fullyCharged,
      label: `Schedule Of Value ${e?.SOVNo}`,
    }));
  };

  const mapRequisitionGroups = (res, scheduleOfValues) => {
    return res.map((e) => {
      return {
        data: e.services,
        charged: e?.charged,
        id: e.applicationId,
        label: `Requisition ${e.applicationNo}`,
        process: !e.fullyCharged,
        includedRentals: e?.includedRentals,
        information: {
          start: e.periodTo.start,
          end: e.periodTo.end,
          description: e.services
            .map((service) => {
              return {
                label: service.label,
                ...service,
                events: !checkIfServiceIsHoist(service)
                  ? service.amounts
                      // Get only the pli that are not charged and thisPeriod > 0
                      ?.filter(
                        ({ charged, paymentDue }) => !charged && paymentDue > 0
                      )
                      .map(({ name }) => name)
                  : service?.serviceOptions[0][0]?.amounts
                      // Get only the pli that are not charged and thisPeriod > 0
                      ?.filter(
                        ({ charged, paymentDue }) => !charged && paymentDue > 0
                      )
                      ?.map(({ name }) => name) || [],
              };
            })
            .filter(({ events }) => events.length > 0),
        },
        SOVNo: scheduleOfValues.find((el) => e.scheduleId === el.scheduleId)
          ?.SOVNo,
        totalInvoicedAmount: calculateInvoicedAmountForCategoryDataObj({
          categoryDataObj: e,
        }),
        ...e,
      };
    });
  };

  const fetchEstimations = async () => {
    try {
      const [estimations, projectData, appliedRequisitions, scheduleOfValues] =
        await Promise.all([
          fetchAllData({
            endpoint: "estimations",
            resultPosition: "estimations",
            resultId: "estimationId",
            otherStringParams: {
              filters: JSON.stringify([
                {
                  conditions: [
                    {
                      column: "projectId",
                      value: project?.projectId,
                      formula: "is",
                    },
                  ],
                },
              ]),
            },
          }),
          fetchData("projects", `projects/${project?.projectId}`),
          filterTables("applications", "projectId", project?.projectId),
          filterTables("scheduleOfValues", "projectId", project?.projectId),
        ]);

      const approvedEstimations = getApprovedEstimations(
        estimations,
        projectData
      );

      setDataToCompare(scheduleOfValues);
      setGroups(
        mapEstimationGroups(
          approvedEstimations,
          estimations,
          projectData,
          appliedRequisitions
        )
      );
      setWhereToFind({ ...projectData });
      updateWhereToFind({ ...projectData });
    } catch (error) {
      console.error("Error fetching estimations:", error);
    }
  };

  const fetchRentalData = async () => {
    const [resRentals, resRequisitions] = await Promise.all([
      filterTables("rentals", "projectId", project.projectId),
      filterTables("applications", "projectId", project.projectId),
    ]);

    setRequisitions(resRequisitions);
    setFromObject(resRentals);
    setGroups(mapRentalGroups(resRentals));
  };

  const fetchInvoiceData = async () => {
    const res = await filterTables("invoices", "projectId", project.projectId);
    setFromObject(res);
    setGroups(mapInvoiceGroups(res));
  };

  const fetchRequisitionData = async () => {
    try {
      const [scheduleOfValues, resRequisitions, includedRentals] =
        await Promise.all([
          filterTables("scheduleOfValues", "projectId", project.projectId),
          filterTables("applications", "projectId", project.projectId),
          filterTables("includedRentals", "projectId", project.projectId),
        ]);

      const newRequisitions = resRequisitions.map((requisition) => ({
        ...requisition,
        includedRentals: includedRentals.filter(
          (rental) => rental.applicationId === requisition.applicationId
        ),
      }));

      setRequisitions(newRequisitions);
      setDataToCompare(scheduleOfValues);
      setFromObject(structuredClone(newRequisitions));
      setGroups(mapRequisitionGroups(newRequisitions, scheduleOfValues));
      setCategoriesResponse(newRequisitions);
    } catch (error) {
      console.error("Error fetching requisition data:", error);
    }
  };

  const fetchScheduleOfValueData = async () => {
    try {
      const [scheduleOfValues, estimations] = await Promise.all([
        filterTables("scheduleOfValues", "projectId", project.projectId),
        fetchAllData({
          endpoint: "estimations",
          resultPosition: "estimations",
          resultId: "estimationId",
          otherStringParams: {
            filters: JSON.stringify([
              {
                conditions: [
                  {
                    column: "projectId",
                    value: project?.projectId,
                    formula: "is",
                  },
                ],
              },
            ]),
          },
        }),
      ]);

      setFromObject(scheduleOfValues);
      setGroups(mapScheduleOfValueGroups(scheduleOfValues));
      setDataToCompare(estimations);
    } catch (error) {
      console.error("Error fetching schedule of value data:", error);
    }
  };

  const fetchRequiredData = async () => {
    setTableLoading(true);
    if (categoryFrom === "Estimation") {
      await fetchEstimations();
    } else if (categoryFrom === "Requisition") {
      await fetchRequisitionData();
    } else if (categoryFrom === "Rental") {
      await fetchRentalData();
    } else if (categoryFrom === "Invoice") {
      await fetchInvoiceData();
    } else if (categoryFrom === "Schedule Of Value") {
      await fetchScheduleOfValueData();
    }
    setTableLoading(false);
  };

  useEffect(() => {
    fetchRequiredData();
  }, [visible, project?.projectId]);

  useEffect(() => {
    if (selectedGroup && categoryFrom !== "Estimation")
      if (categoryFrom === "Requisition")
        setWhereToFind(
          fromObject.find(({ applicationId: id }) => id === selectedGroup)
        );
      else if (categoryFrom === "Rental")
        setWhereToFind(
          fromObject.find(({ rentalId: id }) => id === selectedGroup)
        );
      else if (categoryFrom === "Invoice")
        setWhereToFind(
          fromObject.find(({ invoiceId: id }) => id === selectedGroup)
        );
      else if (categoryFrom === "Schedule Of Value")
        setWhereToFind(
          fromObject.find(({ scheduleId: id }) => id === selectedGroup)
        );
  }, [selectedGroup, fromObject, project?.projectId]);

  useEffect(() => {
    if (fromObject && whereToFind) {
      updateWhereToFind(whereToFind);
    }
  }, [whereToFind, selectedGroup, fromObject, project?.projectId]);

  return {
    requisitions,
    groups,
    dataToCompare,
    fromObject,
    categoriesResponse,
    whereToFind,
    visible,
    setVisible,
    setSelectedCategory,
    updateWhereToFind,
    project,
    setSelectedData,
    charges,
    closeModal,
    selectedGroup,
    setSelectedGroup,
  };
};

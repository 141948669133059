import { API } from "aws-amplify";
import { fetchAllData } from "../components/SidebarPages/Fleet/utils/fetchAllData";
import { setEstimations } from "../actions/estimations";
import {
  programFields,
  proposedTypeOfWork,
  serviceDefinitions,
} from "../actions";

export const onNotificationClickUpdateState = async (dispatch, data) => {
  if (
    data?.topic === "23" ||
    data?.topic === "31" ||
    data?.topic === "41" ||
    data?.topic === "40"
  ) {
    await API.get("serviceDefinitions", "/serviceDefinitions").then((result) =>
      dispatch(serviceDefinitions(result))
    );
  } else if (data?.topic === "29") {
    await Promise.all([
      API.get("proposedTypeOfWork", "/proposedTypeOfWork", {}),
      fetchAllData("estimations", "estimations", "estimationId"),
    ]).then(async ([proposedTypeOfWorkRes, estimationsRes]) => {
      dispatch(proposedTypeOfWork(proposedTypeOfWorkRes));
      dispatch(setEstimations(estimationsRes));
    });
  } else if (data?.topic === "28") {
    await API.get("proposedTypeOfWork", "/proposedTypeOfWork").then((result) =>
      dispatch(proposedTypeOfWork(result))
    );
  } else if (
    data?.topic === "37" ||
    data?.topic === "50" ||
    data?.topic === "51" ||
    data?.topic === "52" ||
    data?.topic === "53" ||
    data?.topic === "54" ||
    data?.topic === "55" ||
    data?.topic === "56" ||
    data?.topic === "57" ||
    data?.topic === "58" ||
    data?.topic === "59" ||
    data?.topic === "60" ||
    data?.topic === "61" ||
    data?.topic === "62" ||
    data?.topic === "64" ||
    data?.topic === "65" ||
    data?.topic === "66" ||
    data?.topic === "80"
  ) {
    await API.get("programFields", "/programFields").then((result) =>
      dispatch(programFields(result))
    );
  }
};

import { Tree } from "antd";
import { useMemo, useState } from "react";
import { useSelector } from "react-redux";
import { UpdateIcon, renderTreeData } from ".";
import "./docWarningsModal.scss";
import { checkIfFailedRecords } from "./helpers/checkFailedRemaining";
import { moveToCorrectFolder } from "./helpers/moveToCorrectFolder";
import fixedAndFailedRecords from "./helpers/fixedAndFailedRecords";
import WarningsInfoMessage from "./components/WarningsInfoMessage";
import { driveApi } from "../../../../../../../../integrations/DriveRequest";
import { RedWarningModal } from "../../../../../../../commonComponents";

function DocWarningsModal({
  visible,
  setVisible,
  docWarnings = [],
  setUploadsWarnings = () => {},
  fromDoc = false,
  callbackFn = () => {},
  proppedCorrectFolderId = "",
  onSave = () => {},
}) {
  const { isDarkMode } = useSelector((state) => state?.darkMode || {});
  const { userConfiguration } = useSelector((state) => state?.userConfig || {});
  const { accessToken } = useSelector((state) => state?.accessToken || {});

  const [saveDisabled, setSaveDisabled] = useState({
    disabled: false,
    hasFailedRecs: null,
  });
  const [rowData, setRowData] = useState(docWarnings);
  const driveRequest = driveApi({ accessToken });

  const hasWarnings = useMemo(
    () => checkIfFailedRecords(rowData, fromDoc),
    [rowData, fromDoc]
  );

  const treeData = useMemo(() => {
    return renderTreeData(rowData, saveDisabled.disabled, fromDoc);
  }, [rowData, saveDisabled.disabled, fromDoc]);

  function closeModal() {
    setVisible(false);
    setSaveDisabled({ disabled: false, hasFailedRecs: null });
  }

  async function onRegulateAll(records) {
    setSaveDisabled({
      disabled: true,
      hasFailedRecs: null,
    });
    let fixedRecords = [];
    let failedRecords = [];
    if (fromDoc) {
      await onSave(records, fixedRecords, failedRecords);
    } else {
      const failedUploads = [];
      const movedFiles = await moveToCorrectFolder(
        records,
        proppedCorrectFolderId,
        driveRequest,
        failedUploads
      );
      failedRecords =
        movedFiles?.filter((file) => file?.statusAfterUpdate === "Failed") ||
        [];
      fixedRecords = movedFiles;
    }

    return fixedAndFailedRecords(fixedRecords, failedRecords, fromDoc);
  }

  return (
    <RedWarningModal
      {...{
        visible,
        onCancel: closeModal,
        footerProps: {
          saveText: saveDisabled.hasFailedRecs ? "Retry" : "Fix All",
          saveClassName: "mondayButtonBlue",
          SaveIcon: UpdateIcon,
          onConfirm: async () => {
            await onRegulateAll(rowData).then(
              ({ fixedRecords, failedRecords }) => {
                console.log({ fixedRecords, failedRecords });
                setRowData(fixedRecords);
                setUploadsWarnings(fixedRecords);
                callbackFn(fixedRecords);
                setSaveDisabled({
                  disabled: false,
                  hasFailedRecs: failedRecords.length > 0 ? true : false,
                });
              }
            );
          },
          saveDisabled:
            saveDisabled.disabled ||
            saveDisabled.hasFailedRecs === false ||
            hasWarnings === false,
        },

        modalClassName: `docWarningsModal ${
          isDarkMode && "docWarningsModalDark"
        } 
       `,
      }}
    >
      <WarningsInfoMessage
        {...{
          proppedCorrectFolderId,
          fromDoc,
          nameOfUser: userConfiguration?.nameOfUser || "",
          isDarkMode,
        }}
      />
      <Tree
        {...{
          multiple: true,
          defaultExpandAll: true,
          treeData,
          blockNode: true,
          selectable: false,
        }}
      />
    </RedWarningModal>
  );
}

export default DocWarningsModal;

import React, { useState } from "react";

import {
  showErrorMsg,
  showLoadingMsg,
  showSuccessMsg,
} from "../../../../../../../../../../utils/windowMessages";
import { WarningIcon } from "../../../../../../../../../../assets";
import { XIcon } from "../../../../../../../../Communication/assets";
import { TickIcon } from "../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import WarningModal from "../../../../../../../../../commonComponents/WarningModal/WarningModal";
import MondayButton from "../../../../../../../../../commonComponents/MondayButton/MondayButton";
import { useCreateChargeContext } from "../../../context/NewChargeItemContext";

const CloseCharge = React.memo(({ removePreviousDataOnDestroy }) => {
  const [warningModalV, setWarningModalV] = useState(false);

  return (
    <>
      <WarningModal
        visible={warningModalV}
        setVisible={setWarningModalV}
        onCancel={() => setWarningModalV(false)}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={removePreviousDataOnDestroy}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setWarningModalV(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={removePreviousDataOnDestroy}
              Icon={<TickIcon />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>

      <XIcon
        fill="white"
        cursor={"pointer"}
        onClick={() => setWarningModalV(true)}
      />
    </>
  );
});

const ChargeSave = ({ currentCharge, isWritable }) => {
  const { saveCharge, removePreviousDataOnDestroy } = useCreateChargeContext();

  return (
    <div>
      <div style={{ display: "flex" }}>
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            gap: 10,
            alignItems: "center",
          }}
        >
          <MondayButton
            className="saveChanges"
            disabled={currentCharge && !isWritable}
            style={{
              color: "black !important",
              fontWeight: "700px !important",
            }}
            onClick={() => {
              showLoadingMsg({ content: "Saving Changes..." });
              saveCharge()
                .then(() => {
                  showSuccessMsg({ content: "Charge saved successfully." });
                })
                .catch((err) => {
                  console.error("Error while saving: ", err);
                  showErrorMsg({ content: "Charge not saved." });
                });
            }}
            Icon={
              <TickIcon
                style={currentCharge && !isWritable ? { fill: "#12131b" } : {}}
              />
            }
          >
            <span
              style={currentCharge && !isWritable ? { color: "#12131b" } : {}}
            >
              Save Changes
            </span>
          </MondayButton>
          <CloseCharge {...{ removePreviousDataOnDestroy }} />
        </div>
      </div>
    </div>
  );
};

export default ChargeSave;

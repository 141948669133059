//@ts-check
import { fetchAllData } from "../../components/SidebarPages/Fleet/utils/fetchAllData";
import {
  calculateServicesTotal,
  countScheduleDays,
  getEveryPaymentInfo,
  getFirstScheduleDay,
  getLastScheduleStatus,
  getPaymentTotal,
} from "./helpers";

const tableConfigs = [
  {
    table: "projects",
    key: "projectId",
    keysToInclude: [
      "projectId",
      "projectName",
      "accountName",
      "projectExecutive",
      "geoFenceInfo",
    ],
  },
  {
    table: "estimations",
    key: "estimationId",
    keysToInclude: [
      "projectId",
      "services",
      "estimationNumber",
      "estSTATUS",
      "projectExecutive",
      "isChangeOrder",
      "createdAt",
      "estimationId",
    ],
  },
  {
    table: "payments",
    key: "paymentId",
    keysToInclude: [
      "projectId",
      "paymentNumber",
      "paymentDate",
      "paymentStatus",
      "invoices",
      "paymentMethod",
      "createdAt",
      "paymentId",
      "receivedFromProjects",
    ],
  },
  {
    table: "scheduling",
    key: "scheduleId",
    keysToInclude: [
      "projectId",
      "createdAt",
      "scheduleDays",
      "typeOfWork",
      "scheduleId",
    ],
  },
  {
    table: "fleetDispatching",
    key: "dispatchId",
    keysToInclude: [
      "routes",
      "dispatchDate",
      "fleetName",
      "fleetId",
      "plannedYardDeparture",
      "plannedYardArrival",
      "driverId",
      "driverName",
      "paperworkType",
      "paperworkCollectStatus",
      "paperworkSoftwareStatus",
      "dispatchId",
    ],
  },
  {
    table: "invoices",
    key: "invoiceId",
    keysToInclude: [
      "totalInvoiceAmount",
      "amountDue",
      "projectId",
      "invoiceId",
    ],
  },
  {
    table: "inspections",
    key: "inspectionId",
    keysToInclude: [
      "projectId",
      "inspectionType",
      "inspectionStatus",
      "inspectionDate",
      "inspectedBy",
      "servicesInspected",
      "createdAt",
      "inspectionId",
    ],
  },
  {
    table: "documentation",
    key: "docId",
    keysToInclude: [
      "projectId",
      "docType",
      "docStatus",
      "expirationDate",
      "createdBy",
      "docId",
    ],
  },
  {
    table: "permitDrawings",
    key: "permitId",
    keysToInclude: [
      "sow",
      "drawingType",
      "permitStatus",
      "servicePPDStatus",
      "actualStartDateTime",
      "actualEndDateTime",
      "projectManager",
      "assignedTo",
      "projectId",
    ],
  },
];

export const projectsOverview = async () => {
  try {
    const fetchPromises = tableConfigs.map(({ table, key, keysToInclude }) =>
      fetchAllData(table, table, key, () => {}, {
        keysToInclude: JSON.stringify(keysToInclude),
      })
    );

    const [
      projects,
      estimations,
      payments,
      schedules,
      fleetDispatching,
      invoices,
      inspections,
      documentation,
      permitDrawings,
    ] = await Promise.all(fetchPromises);

    return projects.map((project) => {
      const projectId = project.projectId;

      const projectEstimations = estimations.filter(
        (estimation) => estimation.projectId === projectId
      );
      const projectPayments = getEveryPaymentInfo(payments, projectId);
      const projectScheduling = schedules.filter(
        (schedule) => schedule.projectId === projectId
      );
      const projectFleetDispatching = fleetDispatching.filter((dispatch) =>
        dispatch.routes?.some((route) => route?.projectId === projectId)
      );
      const projectInvoices = invoices.filter(
        (invoice) => invoice.projectId === projectId
      );
      const projectInspections = inspections.filter(
        (inspection) => inspection.projectId === projectId
      );
      const projectDocumentation = documentation.filter(
        (document) => document.projectId === projectId
      );
      const projectPermitDrawings = permitDrawings.filter(
        (permit) => permit.projectId === projectId
      );

      const firstScheduleDay = getFirstScheduleDay(projectScheduling) || "";
      const scheduleDaysCount = countScheduleDays(projectScheduling) || "";
      const estAmount = projectEstimations.reduce(
        (acc, est) => acc + calculateServicesTotal(est.services || []),
        0
      );
      const totalPayments = getPaymentTotal(projectInvoices) || "";
      const scheduleStatus = getLastScheduleStatus(projectScheduling) || "";

      return {
        accountName: project.accountName,
        projectName: project.projectName,
        projectExecutive: project.projectExecutive,
        scheduledDate: firstScheduleDay,
        daysOnSchedule: scheduleDaysCount,
        firstDateOnSite: firstScheduleDay,
        financialFlow: [
          {
            ttlEstimates: estAmount,
            ttlReceivables: totalPayments,
            progressStatus: scheduleStatus,
          },
        ],
        estimations: projectEstimations.map((est) => ({
          estimateNumber: `${est.estimationNumber} ${est.estSTATUS}`,
          projectExecutive: est.projectExecutive,
          isChangeOrder: est.isChangeOrder ? "Yes" : "No",
          estimationAmount: calculateServicesTotal(est.services || []),
          createdAt: est.createdAt,
        })),
        payments: projectPayments.map((payment) => ({
          paymentNumber: payment.paymentNumber,
          paymentMethod: payment.paymentMethod,
          paymentDate: payment.paymentDate,
          totalAmount: payment.totalAmount,
          paymentStatus: payment.paymentStatus,
          createdAt: payment.createdAt,
        })),
        fleetDispatching: projectFleetDispatching.map((dispatch) => ({
          dispatchDate: dispatch.dispatchDate,
          fleetName: dispatch.fleetName,
          fleetId: dispatch.fleetId,
          plannedYardDeparture: dispatch.plannedYardDeparture,
          plannedYardArrival: dispatch.plannedYardArrival,
          driverId: dispatch.driverId,
          driverName: dispatch.driverName,
          paperworkType: dispatch.paperworkType,
          paperworkCollectStatus: dispatch.paperworkCollectStatus,
          paperworkSoftwareStatus: dispatch.paperworkSoftwareStatus,
        })),
        geoFencesInfo: project.geoFenceInfo.map((geoFence) => ({
          createdAt: geoFence.createdAt,
          createdBy: geoFence.createdBy,
          description: geoFence.description,
          title: geoFence.title,
          type: geoFence.type,
          shapeId: geoFence.shapeId,
          geoFenceInfo: geoFence.geoFenceInfo,
          nodes: `${geoFence.type} with ${geoFence.geoFenceInfo.length} ${
            geoFence.geoFenceInfo.length > 1 ? "nodes" : "node"
          }`,
        })),
        inspections: projectInspections.map((inspection) => ({
          inspectionType: inspection.inspectionType,
          inspectionStatus: inspection.inspectionStatus,
          inspectionDate: inspection.inspectionDate,
          inspectedBy:
            inspection.inspectedBy?.nameOfUser ||
            inspection.inspectedBy?.name ||
            inspection.inspectedBy,
          servicesInspected: inspection.servicesInspected?.map(
            (s) => s.serviceType
          ),
          createdAt: inspection.createdAt,
        })),
        documentation: projectDocumentation.map((doc) => ({
          docType: doc.docType,
          docStatus: doc.docStatus,
          expirationDate: doc.expirationDate,
          createdBy: doc.createdBy,
        })),
        permitDrawings: projectPermitDrawings.map((permit) => ({
          sow: typeof permit.sow === "string" ? permit.sow : permit.sow?.label,
          drawingType: permit.drawingType || "Not Set",
          permitStatus: permit.permitStatus || "Not Set",
          servicePPDStatus: permit.servicePPDStatus,
          actualStartDateTime: permit.actualStartDateTime,
          actualEndDateTime: permit.actualEndDateTime,
          projectManager: permit.projectManager,
          assignedTo: permit.assignedTo,
        })),
      };
    });
  } catch (error) {
    console.error("Error in fetching project overview:", error);
    throw error;
  }
};

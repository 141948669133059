import { message } from "antd";
import { getCategoriesData } from "./get-categories-data";

export const getInitialCategoriesData = async (
  initialCategories,
  categoriesWithGlobalSearch,
  setCategoriesData,
  lastSearches,
  setLoading
) => {
  const categoriesToFetch = categoriesWithGlobalSearch.filter((category) =>
    initialCategories.includes(category.categoryName.toLowerCase())
  );

  try {
    const categoriesDataResponse = await getCategoriesData(categoriesToFetch);
    message.loading({ content: "Getting data...", duration: 0 });

    const newCategoriesData = lastSearches.length
      ? [{ "Last Searches": lastSearches }, ...categoriesDataResponse]
      : [...categoriesDataResponse];

    setCategoriesData(newCategoriesData);
  } catch (error) {
    console.error("Error fetching data:", error);
  } finally {
    setLoading(false);
    message.destroy();
  }
};

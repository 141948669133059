import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { FullScreenModal, BorderedTextCard } from "../../../components";
import { Form, message, Tooltip, Tour } from "antd";
import {
  footerButtons,
  fieldComponents,
  fleetBodyObj,
  documentationBodyObj,
} from "./newFleetModalsData";
import {
  fetchVehicleMakes,
  fetchVehicleModels,
  fetchVehicleModelsByYear,
  fetchVehicleManufacturers,
} from "./dataAPI";
import "./NewFleetModal.scss";
import { compareIncluding } from "../../../../utils";
import { DownOutlined, PlusOutlined } from "@ant-design/icons";
import API from "@aws-amplify/api";
import {
  apiRoutes,
  extractNr,
  fetchData,
  getLoggedUser,
  validateForm,
} from "../../../utils";
import { driveApi } from "../../../../../../integrations/DriveRequest";
import dayjs from "dayjs";
import { useRef } from "react";
import {
  MondayButton,
  ProgressComponent,
  RichTextEditor,
  SortModalFields,
} from "../../../../../commonComponents";
import { useDispatch, useSelector } from "react-redux";
import { RenderDynamicComponents } from "../../../../../Header/forms/Components";
import WarningModal from "../../../../../commonComponents/WarningModal/WarningModal";
import { ReactComponent as Tick } from "../../../../../pages/Settings/settingsComponents/Roles/src/Tick.svg";
import { ReactComponent as WarningIcon } from "../../../../../../icons/warningTriangle.svg";
import {
  getCognitosForNotification,
  lazyFetch,
  updateTeamsConfiguration,
} from "../../../../../../utils";
import broadcastNotification from "../../../../../../helpers/controllers/broadcastNotification";
import { fleetFields as updateFleetFields } from "../../../../../../actions";
import { useCreateDriveFolders } from "../../../../../../hooks/useCreateDriveFolders";
import CustomModalHeader, {
  PlayVideoTutorial,
} from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../Header/components/ModalVideoTutorials/assets/svg";
import DynamicTeamModal from "../../../../../Header/forms/DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { XIcon } from "../../../../Communication/assets";
import { TransferIconNew } from "src/components/SidebarPages/DynamicView/src";
import { useProgressComponent } from "../../../../../../hooks";

const NewFleetModal = ({
  visible,
  setVisible,
  vinObjectData = [],
  refreshTable = () => {},
  dataObject = {},
  licenceObject = [],
  fleetNameObject = [],
  reverseSaveButtons = false,
  isNextStep,
  nextStepHandler = () => {},
  handleSavedRecord = () => {},
}) => {
  const dispatch = useDispatch();

  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const { accessToken } = useSelector((state) => state.accessToken);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const driveRequest = driveApi({ accessToken });
  const driveFunctions = useCreateDriveFolders("Fleets");
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const {
    visibleCreationProgress,
    setVisibleCreationProgress,
    creationProgresses,
    updateProgressStatus,
  } = useProgressComponent({ categoryName: "Fleets", actionType: "Create" });

  const [accessRight] = useState(
    userConfiguration?.routeConfig
      ?.find(({ title }) => title === "Fleets/View")
      ?.children?.find(({ title }) => title === "Overview")
  );
  const [vehicleMakes, setVehicleMakes] = useState([]);
  const [vehicleManufacturers, setVehicleManufacturers] = useState([]);
  const [drivers, setDrivers] = useState([]);
  const [contacts, setContacts] = useState([]);
  const [accounts, setAccounts] = useState([]);
  const [vehicleModels, setVehicleModels] = useState([]);
  const [bodyTypes, setBodyTypes] = useState([]);
  const [fuelTypes, setFuelTypes] = useState([]);
  const [licence, setLicense] = useState(licenceObject);
  const [fleetsNameArr, setFleetsNameArr] = useState(fleetNameObject);
  const [selectedVehicleYear, setSelectedVehicleYear] = useState();
  const [selectedVehicleManufacturer, setSelectedVehicleManufacturer] =
    useState();
  const [linxupgps, setLinxupGps] = useState([]);
  const [filterLinxup, setFilterLinxup] = useState([]);
  const [selectedVehicleMake, setSelectedVehicleMake] = useState(
    dataObject?.fleetMake
  );
  const [fleetNameStr, setFleetNameStr] = useState("");
  const [fileNames, setFileNames] = useState(dataObject?.driveFileId || []);
  const [filesToBeDeleted, setFilesToBeDeleted] = useState([]);
  const [driveFolderId, setDriverFolderId] = useState();
  const [vinObject, setVinObject] = useState(vinObjectData);
  const [companies, setCompanies] = useState([]);
  const [vehicleDocsUploaded, setVehicleDocsUploaded] = useState();
  const [registrationDocsUploaded, setRegistrationDocsUploaded] = useState();
  const [inspectionDocsUploaded, setInspectionDocsUploaded] = useState();
  const [insuranceDocsUploaded, setInsuranceDocsUploaded] = useState();
  const [feDocsUploaded, setFEDocsUploaded] = useState();
  const [cancelModalVisible, setCancelModalVisible] = useState(false);
  const [ownershipOptions, setOwenerShipOptions] = useState([]);
  const [saving, setSaving] = useState();
  const [showOwnership, setShowOwnership] = useState(true);
  const [formChanged, setFormChanged] = useState(false);
  const [sortOpen, setSortOpen] = useState(false);
  const [sortFieldsObj, setSortFieldsObj] = useState(
    programFields?.find(({ fieldName }) => fieldName === "Modal Sorted Fields")
      ?.fieldOptions?.fleetModal || {}
  );
  const [selectedTeam, setSelectedTeam] = useState([]);
  const [allTeams, setAllTeams] = useState([]);
  const loggedUser = useRef();
  const [{ fleetFields }] = useSelector((state) => [state.fleetFields]);
  const [fleetTeamSelect, setFleetTeamSelect] = useState([]);
  const [open, setOpen] = useState(false);
  const [afterSaveAction, setAfterSaveAction] = useState();
  const [createdFleetId, setCreatedFleetId] = useState();

  const vehicleOptions = useMemo(
    () => vehicleMakes?.map?.(({ MakeName }) => MakeName),
    [vehicleMakes]
  );

  const [tourOpen, setTourOpen] = useState(false);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const videoTutorialLink = programFields
    ?.filter((item) => item.fieldName === "Portal Video Tutorials")[0]
    ?.fieldOptions.find((item) => item.categoryName === "Fleet Management")
    ?.subCategories[0].videos.find(
      (item) => item.videoName === "New Fleet"
    )?.videoLink;

  const {
    fleetName,
    fleetYear,
    fleetId,
    registrationDate,
    registrationExpDate,
    inspectionDate,
    inspectionExpDate,
    insuranceDate,
    insuranceExp,
    fEInspectionDate,
    fEExpirationData,
    maxCapacity,
    driveFileId = [],
  } = dataObject;

  function getAccessRights(childTitle) {
    return (accessRight?.children || [])?.find(
      ({ title }) => title === childTitle
    );
  }

  const ClearOptions = () => {
    setOpen(false);
  };

  const openMemberModal = () => {
    setOpen(true);
  };

  //validates the input fields and checks if all documents have been uploaded, then calls onSuccessFunc
  const validateFleetForm = (onSuccessFunc) => {
    // vehicleDocsUploaded
    //   ? // &&
    // registrationDocsUploaded &&
    // inspectionDocsUploaded &&
    // insuranceDocsUploaded &&
    // feDocsUploaded
    validateForm(form, onSuccessFunc);
    // : message.error("Please upload all the necessary documents");
  };

  const selectedLinxupVehicle = (value) => {
    const selectedVehicle = linxupgps?.find(
      (vehicle) => vehicle?.personName === value
    );

    if (selectedVehicle) {
      form.setFieldsValue({
        fleetName: selectedVehicle?.personName,
        fleetMake: selectedVehicle?.make,
        fleetModel: selectedVehicle.model,
        fleetBodyType: selectedVehicle?.bodyType,
        fleetFuelType: selectedVehicle?.fuelType,
        deviceSerialNumber: selectedVehicle?.deviceSerialNumber,
        vinNumber: selectedVehicle?.vin,
        odometerReading: parseInt(selectedVehicle?.estimatedOdo),
        fleetYear: dayjs(selectedVehicle?.year),
        linxupStatus:
          !!selectedVehicle?.status &&
          selectedLinxupVehicle?.status !== "Offline - Unplugged",
      });
      fetchVinNumber();
    }
  };

  const onVehicleMakeSelect = () => {
    // form.setFieldsValue({ fleetModel: "" });
    setSelectedVehicleMake(form.getFieldValue("fleetMake").toLowerCase());
  };

  const onYearSelect = () => {
    // form.setFieldsValue({ fleetModel: "" });
    // form.setFieldsValue({ fleetMake: "" });
    !!form?.getFieldValue("fleetYear")?.year()
      ? setSelectedVehicleYear(form.getFieldValue("fleetYear").year())
      : setSelectedVehicleYear("");
  };

  const onManufacturerSelect = () => {
    // form.setFieldsValue({ fleetMake: "" });
    // form.setFieldsValue({ fleetModel: "" });
    setSelectedVehicleManufacturer(form.getFieldValue("fleetManufacturer"));
  };

  //updates the state of uploaded documents
  const updateDocsUploadStatus = ({ uploaderId, status }) => {
    compareIncluding(uploaderId, "Vehicle Documents") &&
      setVehicleDocsUploaded(status);
    compareIncluding(uploaderId, "Registration Documents") &&
      setRegistrationDocsUploaded(status);
    compareIncluding(uploaderId, "Inspection Documents") &&
      setInspectionDocsUploaded(status);
    compareIncluding(uploaderId, "Insurance Documents") &&
      setInsuranceDocsUploaded(status);
    compareIncluding(uploaderId, "FE Documents") && setFEDocsUploaded(status);
  };

  //gets the names and ids of the uploaded files
  const onPickerSuccess = ({ uploadedFiles, uploaderId }) => {
    let uploadedFileNames = [];

    for (const { name, id, mimeType, url } of uploadedFiles) {
      const fileNameObj = {
        name,
        id,
        mimeType,
        url,
        uploadedAt: dayjs().valueOf(),
      };
      uploadedFileNames.push(
        !fleetName ? fileNameObj : { ...fileNameObj, isNew: true }
      );
    }
    setFileNames(
      !![...fileNames]?.filter((item) =>
        compareIncluding(item.uploaderId, uploaderId)
      ).length
        ? [...fileNames]?.map((item) =>
            !compareIncluding(item?.uploaderId, uploaderId)
              ? item
              : {
                  ...item,
                  files: [...item?.files, ...uploadedFileNames],
                }
          )
        : [...fileNames, { uploaderId, files: [...uploadedFileNames] }]
    );

    updateDocsUploadStatus({ uploaderId, status: true });
  };

  //deletes the array of file ids from the google drive
  const deleteFilesFromGDrive = (arr = []) => {
    for (const { id } of arr) driveRequest.deleteDriveItem(id);
  };

  //handles the deletion of files from the google drive when editing the vehicle
  const onFileDelete = async ({ uploaderId, id }) => {
    const newFileNames = fileNames?.map((item) => {
      const { uploaderId: filesUploaderId, files = [] } = item;

      return compareIncluding(filesUploaderId, uploaderId)
        ? {
            ...item,
            files: files?.filter(({ id: existingId }) => existingId !== id),
          }
        : item;
    });

    const updatedObj = newFileNames?.find(({ uploaderId: filesUploaderId }) =>
      compareIncluding(filesUploaderId, uploaderId)
    );

    const { files } = updatedObj;
    if (!files?.length) {
      updateDocsUploadStatus({ uploaderId, status: false });
    }

    setFileNames(newFileNames);
    setFilesToBeDeleted([...filesToBeDeleted, id]);
  };

  const fetchVinNumber = () => {
    fetch(
      `https://vpic.nhtsa.dot.gov/api/vehicles/DecodeVinExtended/${form.getFieldValue(
        "vinNumber"
      )}?format=json`
    )
      .then((response) => response.json())
      .then((r) => {
        let results = {};
        if (r.Results.length) {
          try {
            results = r?.Results?.reduce(
              (prev, curr) => ({ ...prev, [curr.Variable]: curr.Value }),
              {}
            );
            form.setFieldsValue({ fleetYear: dayjs(results["Model Year"]) });
            form.setFieldsValue({
              fleetManufacturer: results["Manufacturer Name"],
            });
            form.setFieldsValue({ fleetMake: results["Make"] });
            form.setFieldsValue({ fleetModel: results["Model"] });
            form.setFieldsValue({ fuelType: results["Fuel Type - Primary"] });
            form.setFieldsValue({ fleetBodyType: results["Body Class"] });
          } catch (error) {
            form.setFieldsValue({ fleetYear: dayjs(r.Results[9].Value) });
            form.setFieldsValue({ fleetMake: r.Results[7].Value });
            form.setFieldsValue({ fleetManufacturer: r.Results[8].Value });
            form.setFieldsValue({ fleetModel: r.Results[9].Value });
            form.setFieldsValue({ fuelType: r.Results[76].Value });
            form.setFieldsValue({ fleetBodyType: r.Results[22].Value });
          }
        }
      });
  };

  const selectOwnerShip = (data) => {
    if (!!data && data === "Employee owned") {
      form.setFieldsValue({ companyRegistered: "Employee owned" });
      setShowOwnership(false);
    } else {
      form.setFieldsValue({ companyRegistered: "" });
      setShowOwnership(true);
    }
  };
  const onChangeFleetName = (e) => {
    setFleetNameStr(e);
  };

  const onSelectFleetNameGps = (e) => {
    form.setFieldsValue({ fleetName: e });
  };

  //populates the JSON of the input fields
  const components = fieldComponents({
    form,
    onSelectFleetNameGps,
    fleetNameStr,
    accessToken,
    selectedLinxupVehicle,
    onChangeFleetName,
    vinObject,
    licence,
    fleetsNameArr,
    selectOwnerShip,
    // onChange,
    parentId: driveFolderId,
    ownershipOptions: ["Rented", "Financed", "Owned", "Employee owned"],
    fileNames,
    onDelete: !!fleetName && onFileDelete,
    onYearSelect,
    onVehicleMakeSelect,
    vehicleMakes: vehicleOptions,
    vehicleModels: vehicleModels?.map(({ Model_Name }) => Model_Name),
    vehicleManufacturers: vehicleManufacturers?.map(({ Mfr_Name }) => Mfr_Name),
    bodyTypes,
    linxupgps,
    filterLinxup,
    fetchVinNumber,
    onPickerSuccess,
    fuelTypes,
    onManufacturerSelect,
    onTeamSelect,
    onTeamDeselect,
    getAccessRights,
    isDarkMode,
    teamsOptions: allTeams?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    accounts: accounts?.map(({ accountName }) => accountName),
    contacts: contacts?.map(
      ({ contactFirstName, contactLastName }) =>
        `${contactFirstName} ${contactLastName}`
    ),
    drivers: drivers?.map(({ driverName, driverId }) => ({
      label: driverName,
      value: driverId,
    })),
    companies: companies?.map?.(({ companyName }) => companyName),
    fleetTeamSelect: fleetTeamSelect?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => {
          return {
            identityId,
            nameOfUser,
            cognitoUserId,
          };
        }
      ),
    })),
  });

  const vehicleInfoFields = components[0]?.fields;
  vehicleInfoFields.sort((a, b) => {
    if (Object.keys(sortFieldsObj).length === 0) {
      return 0;
    }
    const aIndex = sortFieldsObj[a.formItemName];
    const bIndex = sortFieldsObj[b.formItemName];
    return aIndex - bIndex;
  });

  //posts or updates the vehicle and then its documentation
  const addVehicle = async (body, newFileNames) => {
    setSaving(true);
    const key = "onSuccess";
    message.loading({ content: "Saving...", key });

    const teamsConfiguration = updateTeamsConfiguration(
      userConfiguration,
      selectedTeam
    );
    setSelectedTeam([]);
    setVisibleCreationProgress(dataObject); //show creation progress

    if (!fleetName) {
      let allFolders = null;
      try {
        const { folders, parentFolder } = await driveFunctions?.create({
          parentId: driveFolderId,
          parentFolderName: form.getFieldValue("fleetName").replace(/\W/g, " "),
        });

        await Promise.all(
          fileNames
            .flatMap(({ files = [] }) => files)
            .map(
              async ({ id }) =>
                await driveRequest.updateDriveItem(id, {
                  addParents: parentFolder,
                  removeParents: driveFolderId,
                })
            )
        );
        allFolders = folders;
      } catch (error) {
        console.error(error);
        allFolders = null;
      }

      updateProgressStatus({ updatingRecord: "executing" });
      await API.post(apiRoutes.fleet, `/${apiRoutes.fleet}`, {
        body: {
          ...body,
          teamsConfiguration,
          companyRegistered:
            form.getFieldValue("ownership") === "Employee owned"
              ? "Employee owned"
              : form.getFieldValue("companyRegistered"),
          googleDriveFolderIds: allFolders,
          teamsConfiguration,
          createdBy: {
            id: authenticatedUser?.sub,
            name:
              authenticatedUser?.given_name +
              " " +
              authenticatedUser?.family_name,
          },
        },
      })
        .then(async (r) => {
          handleSavedRecord(r);
          //set progress of creating record as 100%
          updateProgressStatus({
            updatingRecord: "finished",
            sendingNotification: "executing",
          });
          setVisibleCreationProgress(r); // save data to generate logs in case of any fail

          broadcastNotification(
            "11",
            "onFleetCreation",
            [
              { common: userConfiguration?.nameOfUser },
              {
                userName: userConfiguration?.nameOfUser,
                currentUser: userConfiguration?.cognitoUserId,
                cognitos: getCognitosForNotification(
                  userConfiguration,
                  teamsConfiguration
                ),
              },
            ],
            r?.fleetId
          ).then((notificationSent) => {
            updateProgressStatus({
              sendingNotification: !!notificationSent ? "finished" : "hasError",
            });
          });

          refreshTable([r]);
          setSaving(false);
          message.success({ content: "Saved!", key, duration: 2 });
          setVehicleDocsUploaded(false);
          setRegistrationDocsUploaded(false);
          setInspectionDocsUploaded(false);
          setInsuranceDocsUploaded(false);
          setFEDocsUploaded(false);
          setFileNames([]);
          afterSaveAction === "onSaveAndContinue" &&
            setCreatedFleetId(r.fleetId);
        })
        .catch((e) => {
          updateProgressStatus({ updatingRecord: "hasError" });
          message.error({ content: "An error occurred", key, duration: 2 });
          console.error(e);
        });
    } else {
      await API.put(apiRoutes.fleet, `/${apiRoutes.fleet}/${fleetId}`, {
        body: body,
        teamsConfiguration,
      }).then(async (r) => {
        setSaving(false);
        message.success({ content: "Saved!", key, duration: 2 });
        await newFileNames?.forEach(
          async ({ uploaderId, documentId = "", files }) => {
            if (
              uploaderId === "Registration Documents" ||
              uploaderId === "Inspection Documents" ||
              uploaderId === "Insurance Documents"
            ) {
              !!documentId
                ? await API.put(
                    apiRoutes.fleetDocumentation,
                    `/${apiRoutes.fleetDocumentation}/${documentId}`,
                    {
                      body: documentationBodyObj({
                        uploaderId,
                        files,
                        form,
                        fleetId,
                      }),
                      teamsConfiguration,
                    }
                  ).catch((e) => console.error(e))
                : await API.post(
                    apiRoutes.fleetDocumentation,
                    `/${apiRoutes.fleetDocumentation}`,
                    {
                      body: documentationBodyObj({
                        uploaderId,
                        form,
                        files,
                        fleetId,
                      }),
                      teamsConfiguration,
                    }
                  ).catch((e) => console.error(e));
            }
          }
        );
      });
      deleteFilesFromGDrive(filesToBeDeleted);
    }
    message.destroy("projectDriveFolders");
    message.destroy("windowMessages");
  };

  const onSave = async () => {
    await addVehicle(fleetBodyObj({ fileNames, form }), fileNames).then(() => {
      if (!!nextStepHandler) {
        nextStepHandler();
        return;
      }
    });
  };

  //posts the new vehicle and hides the modal
  // const onSaveAndClose = async () => {
  //   await addVehicle(fleetBodyObj({ fileNames, form }), fileNames).then(() => {
  //     setVisible(false);
  //   });
  // };

  //posts the new vehicle and then resets all the input fields
  // const onSaveAndNew = async () => {
  //   await addVehicle(fleetBodyObj({ fileNames, form }), fileNames);
  // };

  //posts the new vehicle and then navigates to that vehicle
  // const onSaveAndContinue = () => {
  //   addVehicle(fleetBodyObj({ fileNames, form }), fileNames).then(
  //     ({ fleetId }) => {
  //       setVisible(false);
  //     }
  //   );
  // };

  //gets the Button that has been pressed and then calls the appropriate function
  const onOptionClick = ({ key }) => {
    setAfterSaveAction(
      key === "Save & New"
        ? "onSaveAndNew"
        : key === "Finish"
        ? "onSaveAndContinue"
        : "onSaveAndClose"
    );
    validateFleetForm(onSave);
    // validateFleetForm();
  };

  //hides the modal, resets the input fields and deletes the uploaded files from the google drive
  const onCancel = () => {
    setVisible(false);
    setCancelModalVisible(false);
    deleteNewUploadedFiles();
    !fleetName && form.resetFields();
    driveFunctions?.reset();
  };

  const onEnterPress = (event) => {
    if (event.charCode === 13) {
      onCancel();
    }
  };

  //deletes uploaded files
  const deleteNewUploadedFiles = () => {
    for (const { files } of fileNames) {
      for (const { id, isNew } of files) {
        if (!fleetName) driveRequest.deleteDriveItem(id);
        else if (!!isNew) driveRequest.deleteDriveItem(id);
      }
    }

    const newFileNames = fileNames?.map((item) => {
      const { files = [] } = item;
      return { ...item, files: files.filter(({ isNew }) => !isNew) };
    });

    setFileNames(!fleetName ? [] : newFileNames);
  };

  //updates the state if we are editing a vehicle
  const updateUploadStatus = () =>
    !!fleetName &&
    driveFileId?.forEach(({ uploaderId }) => {
      updateDocsUploadStatus({ uploaderId, status: true });
    });

  //fetches data from the API
  useEffect(() => {
    !!fleetName &&
      form.setFieldsValue({
        ...dataObject,
        fleetYear: dayjs(fleetYear),
        registrationDate: dayjs(registrationDate),
        registrationExpDate: dayjs(registrationExpDate),
        inspectionDate: dayjs(inspectionDate),
        inspectionExpDate: dayjs(inspectionExpDate),
        insuranceDate: dayjs(insuranceDate),
        insuranceExp: dayjs(insuranceExp),
        fEInspectionDate: dayjs(fEInspectionDate),
        fEExpirationData: dayjs(fEExpirationData),
        maxCapacity: extractNr(maxCapacity),
      });
    updateUploadStatus();

    getLoggedUser().then((r) => (loggedUser.current = r));

    fetchVehicleManufacturers().then((r) => setVehicleManufacturers(r));
    fetchData("drivers").then((r) =>
      setDrivers(() => {
        return r;
      })
    );
    fetchData("contacts").then((r) =>
      setContacts(() => {
        return r;
      })
    );
    fetchData("companies").then((r) => {
      const companiesList = [
        {
          address: "",
          companyId: "personalComp",
          companyName: "Personal",
          logos: {},
          phoneNumber: "",
          userId: userConfiguration.userId,
        },
        ...r,
      ];
      setCompanies(companiesList);
    });
    fetchData("accounts").then((r) =>
      setAccounts(
        r?.filter((item) =>
          compareIncluding(item?.accountRecordType, "subcontractors")
        )
      )
    );

    API.get("teams", "/teams").then((r) => {
      setFleetTeamSelect(r);
    });
  }, []);

  // new shortcut from sidebar needs fleetFields to be filled
  useEffect(() => {
    !fleetFields?.length > 0 &&
      API.get("fleetFields", `/fleetFields`).then((res) =>
        dispatch(updateFleetFields(res))
      );

    API.get("teams", "/teams")
      .then((r) => {
        setAllTeams(r);
      })
      .catch((err) => {
        console.error("Error getting teams: ", err);
      });
  }, []);

  useEffect(() => {
    if (fleetFields) {
      const fieldOptionsObj = fleetFields.reduce(
        (acc, cur) => ({ ...acc, [cur.fieldName]: cur?.fieldOptions }),
        {}
      );
      const listOfKeys = fieldOptionsObj["Google Drive Folder Ids"];

      setBodyTypes(fieldOptionsObj["Body Type"]);
      setFuelTypes(fieldOptionsObj["Fuel Type"]);

      if (process.env.NODE_ENV === "development") {
        const devIdObject = listOfKeys.find(
          (obj) => Object.keys(obj)[0] === "fleet-dev"
        );
        setDriverFolderId(devIdObject["fleet-dev"]);
      } else {
        const devIdObject = listOfKeys.find(
          (obj) => Object.keys(obj)[0] === "fleet"
        );
        setDriverFolderId(devIdObject["fleet"]);
      }
    }
  }, [fleetFields]);

  //fetches all the models of the selected vehicle make
  useEffect(() => {
    if (!!selectedVehicleManufacturer && !!selectedVehicleYear) {
      fetchVehicleMakes({
        manufacturer: selectedVehicleManufacturer,
        year: selectedVehicleYear,
      }).then((r) => setVehicleMakes(r));
    }
  }, [selectedVehicleYear, selectedVehicleManufacturer]);

  useEffect(() => {
    fetchVehicleModels(selectedVehicleMake).then((r) => setVehicleModels(r));
  }, [selectedVehicleMake]);

  //fetches all the models of the selected vehicle by the production year
  useEffect(() => {
    fetchVehicleModelsByYear(selectedVehicleMake, selectedVehicleYear).then(
      (r) => setVehicleModels(r)
    );
  }, [selectedVehicleYear]);

  useEffect(() => {
    Promise.allSettled([
      lazyFetch({
        tableName: apiRoutes.fleet,
        listOfKeys: ["fleetName"],
      }),
      lazyFetch({
        tableName: apiRoutes.fleet,
        listOfKeys: ["licensePlate"],
      }),
      lazyFetch({
        tableName: apiRoutes.fleet,
        listOfKeys: ["vinNumber"],
      }),
    ])
      .then(
        ([
          { value: fleetName },
          { value: licensePlate },
          { value: vinNumber },
        ]) => {
          setFleetsNameArr(fleetName);
          setLicense(licensePlate);
          setVinObject(vinNumber);
        }
      )
      .catch((err) => {
        console.error(err);
      });
  }, []);

  useEffect(() => {
    if (!!vinObject.length) return;
    else if (!!vinObjectData.length) {
      setVinObject(vinObjectData);
      return;
    }
  }, [vinObject, vinObjectData]);

  useEffect(() => {
    if (!!fleetsNameArr.length) return;
    else if (!!fleetNameObject.length) {
      setFleetsNameArr(fleetNameObject);
      return;
    }
  }, [fleetsNameArr, fleetNameObject]);

  useEffect(() => {
    if (!!licence.length) return;
    else if (!!licenceObject.length) {
      setVinObject(licenceObject);
      return;
    }
  }, [licence, licenceObject]);

  useEffect(() => {
    API.get("locations", "/locations")
      .then((res) => {
        setLinxupGps(res.data.locations);
        setFilterLinxup(res.data.locations);
      })
      .catch((err) => {
        console.log("Error getting locations: ", err);
      });
  }, []);

  function onTeamSelect(_, data) {
    setSelectedTeam((prev) => [
      ...prev,
      {
        value: data?.value,
        members: data?.members?.map((e) => e),
      },
    ]);
  }

  function onTeamDeselect(val, data) {
    const teamName = data?.value || val;
    setSelectedTeam((prev) => prev?.filter((el) => el?.value !== teamName));
    if (form.getFieldValue("fleetTeam")?.length < 1) {
      setSelectedTeam([]);
    }
  }

  const tourSteps = [
    {
      title: "Create Fleet Tutorial",
      description: (
        <div>
          <MondayButton
            {...{
              className: "mondayButtonBlue",
              onClick: () => {
                setShowVideoTutorial(true);
                setTourOpen(false);
              },
              style: { display: "flex", justifyContent: " center" },
              Icon: <VideoTutorialIcon />,
            }}
          >
            Watch Video
          </MondayButton>
        </div>
      ),
      className: "custom-tour-light",
    },
  ];

  return (
    <>
      <FullScreenModal
        centered={true}
        visible={visible}
        onCancel={() =>
          formChanged ? setCancelModalVisible(true) : onCancel()
        }
        className="new_fleet_modal"
        isDarkMode={isDarkMode}
        // title={!fleetName ? "New Fleet" : "Edit Fleet"}
        title={
          <CustomModalHeader
            title={!fleetName ? "New Fleet" : "Edit Fleet"}
            onClick={() => {
              setTourOpen(true);
            }}
            headerInfo={() => {
              return (
                userConfiguration.groupName === "Admin" && (
                  <Tooltip title="Sort Fields">
                    <TransferIconNew
                      {...{
                        className: "sortFieldsIcon",
                        onClick: () => {
                          setSortOpen(true);
                        },
                      }}
                    />
                  </Tooltip>
                )
              );
            }}
          />
        }
        // subtitle={subtitle}
        footer={
          !fleetName
            ? footerButtons({
                onCancel: () =>
                  formChanged ? setCancelModalVisible(true) : onCancel(),
                onSaveAndContinue: () => {
                  validateFleetForm(onSave);
                  if (reverseSaveButtons) {
                    // validateFleetForm(onSaveAndClose);
                  } else {
                    // validateFleetForm(onSaveAndContinue);
                  }
                },
                onOptionClick,
                saving,
                suffix: <DownOutlined />,
                reverseSaveButtons,
              })
            : footerButtons({
                onCancel: () =>
                  formChanged ? setCancelModalVisible(true) : onCancel(),
                onSaveAndContinue: () => {
                  validateFleetForm(onSave);
                  // validateFleetForm(onSaveAndContinue);
                },
                saving,
              })?.map(({ text, cancel, disabled, primary, onClick }) => ({
                text,
                cancel,
                disabled,
                primary,
                onClick,
              }))
        }
        footerButtonsStyle={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div
          className={`newFleetFormContainer ${
            isDarkMode && "newFleetFormContainerDark"
          }`}
        >
          <Form
            form={form}
            onFieldsChange={() => {
              setFormChanged(true);
            }}
          >
            <div className="vehicleInfoContainer">
              <BorderedTextCard
                title={components[0]?.category}
                key={components[0]?.category}
              >
                {RenderDynamicComponents(vehicleInfoFields)}
                {!!selectedTeam?.length && getAccessRights("Teams")?.write ? (
                  <div className="members-button-container">
                    <MondayButton
                      onClick={openMemberModal}
                      Icon={<PlusOutlined />}
                      className="membersButton"
                    >
                      Members
                    </MondayButton>
                  </div>
                ) : (
                  <></>
                )}
                {getAccessRights("Teams") ? (
                  <DynamicAvatars
                    title={"New Fleet Team"}
                    isDarkMode={isDarkMode}
                    members={selectedTeam.reduce(
                      (acc, { members }) => [...acc, ...members],
                      []
                    )}
                  />
                ) : (
                  <></>
                )}
              </BorderedTextCard>
            </div>
            <div className="dmv-description-container">
              <BorderedTextCard
                title={components[1]?.category}
                key={components[1]?.category}
                className="dmvContainer"
              >
                {RenderDynamicComponents(components[1]?.fields)}
              </BorderedTextCard>

              <BorderedTextCard
                title="Description"
                className="descriptionContainer"
              >
                <RichTextEditor
                  {...{ name: "description", style: { width: "100%" } }}
                  className="richTextComp"
                />
              </BorderedTextCard>
            </div>
          </Form>
        </div>
      </FullScreenModal>
      <WarningModal
        visible={cancelModalVisible}
        setVisible={setCancelModalVisible}
        title="Warning Message"
        closable={true}
        className="logout-warning-modal"
        onKeyPress={(e) => onEnterPress(e)}
        darkMode={isDarkMode}
      >
        <div className="logout-modal-body">
          <span>
            <WarningIcon />
          </span>
          <p>Are you sure you want to cancel?</p>
          <div className="buttons">
            <MondayButton
              onClick={() => setCancelModalVisible(false)}
              Icon={<XIcon />}
              className="mondayButtonRed"
            >
              No
            </MondayButton>
            <MondayButton
              onClick={onCancel}
              Icon={<Tick width={17} height={17} />}
            >
              Yes
            </MondayButton>
          </div>
        </div>
      </WarningModal>
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            setSelectedTeam,
            ClearOptions,
            onSave: (data) => {
              form.setFieldsValue({ fleetTeam: data || [] });
            },
            proppedAllTeams: allTeams,
          }}
        />
      )}
      {tourOpen && (
        <Tour
          open={tourOpen}
          onClose={() => setTourOpen(false)}
          steps={tourSteps}
        />
      )}
      {showVideoTutorial && (
        <PlayVideoTutorial
          {...{
            visible: showVideoTutorial,
            setVisible: setShowVideoTutorial,
            url: videoTutorialLink,
            title: "Create Fleet Tutorial",
          }}
        />
      )}
      {sortOpen && (
        <SortModalFields
          {...{
            visible: sortOpen,
            setVisible: setSortOpen,
            title: "Fleet",
            rowData: vehicleInfoFields,
            onSave: (newSort) => {
              setSortFieldsObj(newSort);
            },
            saveKey: "fleetModal",
          }}
        />
      )}

      {visibleCreationProgress && creationProgresses && (
        <ProgressComponent
          {...{
            categoryName: "Fleets",
            actionType: "Create",
            visibleCreationProgress,
            creationProgresses,
            closeModal: () => {
              !fleetName && form.resetFields();
              afterSaveAction !== "onSaveAndNew" && setVisible(false);
              afterSaveAction === "onSaveAndContinue" &&
                !!!isNextStep &&
                navigate(`/fleets/${createdFleetId}`);
            },
          }}
        />
      )}
    </>
  );
};

export default NewFleetModal;

import React from "react";
import { useState } from "react";
import { useSelector } from "react-redux";
import { message } from "antd";
import { GPicker } from "../../../../../integrations/GPicker";
import { driveApi } from "../../../../../integrations/DriveRequest";
import SingleFileUploaderPreviewer from "../../cardComponent/Information/components/SingleFileUploaderPreviewer/SingleFileUploaderPreviewer";

const SingleFileUploader = ({
  form,
  folderId,
  onlyTypes,
  initialFileId = "",
  disabled = false,
  folderCredentialKey = "",
  maxFileSize,
}) => {
  const [fileId, setFileId] = useState();
  const { accessToken } = useSelector((state) => state.accessToken);
  const { hotCredentials } = useSelector((state) => state.hotCredentials);

  const driveRequest = driveApi({ accessToken });

  const deleteFiles = (files = []) => {
    files.forEach((file) => {
      driveRequest.deleteDriveItem(file.id).catch((error) => {
        console.error("error deleting item ", error);
      });
    });
  };

  const callBackFunction = async (data) => {
    if (data.action === google.picker.Action.PICKED && !!data.docs.length) {
      let filteredDocs = data.docs;

      if (!!onlyTypes) {
        filteredDocs = data.docs.filter((file) =>
          onlyTypes.some((type) => file.mimeType.includes(type))
        );

        const invalidFiles = data.docs.filter((file) =>
          onlyTypes.some((type) => !file.mimeType.includes(type))
        );

        if (invalidFiles.length) {
          message.error("Detected files with invalid types!");
          deleteFiles(invalidFiles);
        }
      }

      if (maxFileSize) {
        const filesWithSizes = await Promise.all(
          filteredDocs.map(async (file) => {
            const fileSize =
              (await driveRequest.getFileSize(file?.id)) / 1048576;
            return { ...file, fileSize };
          })
        );

        filteredDocs = filesWithSizes.filter(
          ({ fileSize }) => fileSize <= maxFileSize
        );

        const exceededSizeFiles = filesWithSizes.filter(
          ({ fileSize }) => fileSize > maxFileSize
        );

        if (exceededSizeFiles.length) {
          message.error("Detected files with exceeded size!");

          deleteFiles(exceededSizeFiles);
        }
      }

      const { id } = data?.docs[0];
      setFileId(id);
      form.setFieldsValue({
        googleDriveFileId: id,
      });
    }
  };

  return (
    <div className="file-uploader-container">
      <GPicker
        disabled={disabled}
        token={accessToken}
        onAuthenticate={() => {}}
        createPicker={(google, oauthToken) => {
          const uploadView = new google.picker.DocsUploadView()
            .setIncludeFolders(true)
            .setParent(
              folderId ||
                hotCredentials.find(
                  ({ credentialKey }) => credentialKey === folderCredentialKey
                ).credentialValue
            );

          const picker = new window.google.picker.PickerBuilder()
            .enableFeature(google.picker.Feature.SIMPLE_UPLOAD_ENABLED)
            .enableFeature(google.picker.Feature.MULTISELECT_ENABLED)
            .enableFeature(google.picker.Feature.MINE_ONLY)
            .enableFeature(google.picker.Feature.NAV_HIDDEN)
            .addView(uploadView)
            .setOAuthToken(oauthToken)
            .setDeveloperKey("AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo")
            .setCallback(callBackFunction);
          picker.build().setVisible(true);
        }}
      >
        <SingleFileUploaderPreviewer
          fileId={fileId || initialFileId}
          enableUploadPreview={true}
          disabled={disabled}
        />
      </GPicker>
    </div>
  );
};

export default SingleFileUploader;

import parse from "html-react-parser";
import { Select, Tag, Tooltip } from "antd";

import { compareObjects } from "../../utils";

import styles from "./ServiceAddons.module.css";

const ServiceAddons = ({ addons, addonsToCompare }) => {
  const tagRender = (props) => {
    const { label, value } = props;

    return (
      <Tag color={value ? "lightgreen" : ""} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  const selectedAddons = addons.map((addon) => ({
    label: addon.name,
    value: !compareObjects(
      addon,
      addonsToCompare.find((a) => a.id === addon.id)
    ),
  }));

  return (
    <div className={styles.serviceAddonsContainer}>
      {addons.length > 0 ? (
        <div className={styles.serviceAddonsBody}>
          <Select
            mode="multiple"
            value={selectedAddons}
            tagRender={tagRender}
            disabled={true}
          />

          <div className={styles.addonCardContainer}>
            {addons?.map((item, addonIndex) => (
              <div key={item.id} className={styles.addonCardBody}>
                <div className={styles.addonCardHeader}>
                  <h1 className={styles.addonCardTitle}>{item.name}</h1>
                </div>
                <div className={styles.addonCardColumns}>
                  {item?.values?.map(
                    (value, index) =>
                      value.name.toLowerCase() !== "name" && (
                        <p key={index} className={styles.addonCardValues}>
                          <Tooltip
                            overlayInnerStyle={{
                              color: "#fff",
                            }}
                          >
                            <span>{value.name}</span>:
                            <span
                              className={
                                value?.defaultValue !==
                                addonsToCompare?.[addonIndex]?.[index]
                                  ?.defaultValue
                                  ? "highlight-green"
                                  : ""
                              }
                            >
                              {value?.defaultValue || ""}
                            </span>
                          </Tooltip>
                        </p>
                      )
                  )}
                </div>
                <div className={styles.addonCardDescription}>
                  Description:
                  <p className={styles.addonCardDescriptionText}>
                    {item.description ? parse(item.description) : ""}
                  </p>
                </div>
              </div>
            ))}
          </div>
        </div>
      ) : (
        <h1 className="no-data">No data to show</h1>
      )}
    </div>
  );
};

export default ServiceAddons;

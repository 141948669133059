import { useEffect, useState } from "react";
import { message, Modal, Select } from "antd";
import { XIcon } from "../../Communication/assets";
import { MondayButton } from "../../../commonComponents";
import { PlusIcon } from "../../../../assets";
import { useSelector } from "react-redux";
import { getProgramFieldsOptions } from "../../TaskManager/Tasks/utils/getProgramFieldsOptions";
import NewToDo from "../forms/NewToDo";
import { getUpdatedResult } from "../../../commonComponents/InputComponents/FetchedOptions/utils/getUpdatedResult";
import { fetchSearchRecords } from "../../../../utils";
import { hasSettingsAccess } from "../../TaskManager/Tasks/utils/hasSettingsAccess";
import { StopOutlined } from "@ant-design/icons";
import { DropDownArrow } from "../../Fleet/components/InputComponent/assets";
import IndividualLoader from "../../../IndividualLoader";
import { v4 } from "uuid";
import { useProgramFields } from "../../../../hooks";

const categoriesConfig = [
  { name: "Fleet Statuses", value: "Fleet" },
  { name: "Accounting Statuses", value: "Accounting" },
  { name: "Inspection Statuses", value: "Inspection" },
  { name: "Safety Statuses", value: "Safety" },
  { name: "Safety Statuses", value: "Other" },
];

const SettingsToDoModal = ({
  visible,
  setVisible,
  setRowData,
  selectedCategory,
}) => {
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [category, setCategory] = useState(null);
  const [relatedTo, setRelatedTo] = useState();
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [renderToDos, setRenderToDos] = useState(false);
  const [recordOptions, setRecordOptions] = useState([]);
  const [loading, setLoading] = useState(false);

  const { ["To Do Categories"]: todoCategories } = useProgramFields();

  const settingsToDo =
    programFields
      .find(({ fieldName }) => fieldName === "Settings Tasks")
      .fieldOptions.filter((item) => item.included.includes("todo")) || [];

  const hasToDo = (categoryName) => {
    const selectedCategory = todoCategories.find(
      (item) => item.title === categoryName
    );

    if (!selectedCategory) return true;

    return selectedCategory.hasToDo;
  };

  const getCategoryOptions = () => {
    if (selectedCategory) {
      return [
        settingsToDo.find((item) => item.title === selectedCategory),
      ]?.map((el) => ({
        label: el?.title,
        value: el?.title,
      }));
    }

    return settingsToDo
      .filter((task) => hasSettingsAccess(userConfiguration, task.access))
      .filter((item) => hasToDo(item.categoryName))
      ?.map((item) => ({
        label: item?.categoryName,
        value: item?.categoryName,
      }));
  };

  const fetchData = async (selectedTopic) => {
    const { categoryName, apiName, primaryKey, rowName, otherName, keysToUse } =
      selectedTopic;
    try {
      const result = await fetchSearchRecords(
        apiName,
        rowName,
        "",
        [
          primaryKey,
          rowName,
          otherName,
          keysToUse?.includes("teamsConfiguration") && "teamsConfiguration",
        ].filter(Boolean)
      );

      const updatedResult = await getUpdatedResult(categoryName, result);

      const records = Array.isArray(updatedResult)
        ? updatedResult
        : updatedResult[apiName];

      const options = records.map((record) => ({
        customValue: {
          ...record,
          recordId: record[selectedTopic.primaryKey],
          recordName: record[selectedTopic.rowName],
          ...(selectedTopic.hasOwnProperty("path") && {
            path: selectedTopic.path,
          }),
        },
        key: record[primaryKey],
        label: record[rowName],
        value: record[rowName],
      }));

      setRecordOptions(options);
    } catch (error) {
      message.error(
        "Sorry, we couldn't retrieve data at the moment. Please try again later."
      );
      console.error("Error Fetching data", error);
      setLoading(false);
    }
  };

  const onSelectCategory = async (value) => {
    setCategory(value);
    setSelectedRecord(null);
    setRelatedTo();

    const foundedTodo = settingsToDo.find(
      (item) => item.categoryName === value
    );
    const categoriesItem = categoriesConfig.find((item) => item.name === value);

    if (!!categoriesItem) {
      const categories = [];

      const foundedFieldOptions = programFields.find(
        (item) => item.fieldName === "Categories Config"
      )?.fieldOptions[categoriesItem.value];

      foundedFieldOptions.map((option) => {
        const newId = v4();

        categories.push({
          label: option,
          value: option,
          key: newId,
          customValue: {
            key: newId,
            recordId: newId,
            recordName: option,
            value: option,
            ...(foundedTodo.hasOwnProperty("topicCategory") && {
              topicCategory: foundedTodo.topicCategory,
            }),
            ...(foundedTodo.hasOwnProperty("path") && {
              path: foundedTodo.path,
            }),
          },
        });
      });

      setRecordOptions(categories);
    } else if (foundedTodo.hasOwnProperty("fieldId")) {
      const result = getProgramFieldsOptions(programFields, foundedTodo);
      setRecordOptions(result);
    } else {
      setLoading(true);
      await fetchData(foundedTodo);
      setLoading(false);
    }
  };

  useEffect(() => {
    selectedCategory && onSelectCategory(selectedCategory);
  }, [selectedCategory]);

  const onSelectRelated = (value, option) => {
    setRelatedTo(value);
    setSelectedRecord({
      recordId: option?.customValue?.recordId,
      recordName: option?.customValue?.recordName,
      customLinkTo: option?.customValue?.path,
    });
  };

  return (
    <>
      <Modal
        open={visible}
        onCancel={() => setVisible(false)}
        closeIcon={<XIcon />}
        centered={true}
        title="To Do"
        footer={[
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MondayButton
              {...{
                onClick: () => setVisible(false),
                className: "mondayButtonRed",
                Icon: <XIcon />,
              }}
            >
              Cancel
            </MondayButton>
            <MondayButton
              {...{
                onClick: () => setRenderToDos(true),
                Icon: <PlusIcon />,
              }}
              className={
                !selectedRecord ? "mondayButtonGray" : "mondayButtonGreen"
              }
              disabled={!selectedRecord?.recordId}
            >
              Create
            </MondayButton>
          </div>,
        ]}
        className={`preTodoModal ${isDarkMode && "preTodoModalDark"}`}
      >
        <div className="settings">
          {!!selectedCategory ? (
            <div className="modal__default__props default-topicContainer">
              <div className="modal__default__props">
                <h3>
                  <StopOutlined
                    fontSize={"small"}
                    style={{ marginRight: "3px" }}
                  />
                </h3>
                <p>
                  Current category is &nbsp; <b>{selectedCategory}</b>
                </p>
              </div>
              <hr style={{ margin: "0.5rem 0", opacity: "0" }} />
            </div>
          ) : (
            <div className="label-input">
              <label className="fieldLabel">Category</label>
              <Select
                showSearch
                placeholder="Select an option..."
                suffixIcon={<DropDownArrow />}
                options={getCategoryOptions()}
                value={category}
                onChange={(e) => onSelectCategory(e)}
              />
            </div>
          )}
          {!!category && (
            <div className="label-input">
              <label className="fieldLabel">Related To</label>
              <Select
                showSearch
                placeholder="Select a related to..."
                suffixIcon={<DropDownArrow />}
                options={recordOptions}
                value={relatedTo}
                onChange={(value, option) => onSelectRelated(value, option)}
                dropdownRender={(menu) =>
                  loading ? (
                    <div
                      style={{
                        display: "flex",
                        justifyContent: "center",
                        margin: "20px",
                      }}
                    >
                      {!!loading ? (
                        <IndividualLoader />
                      ) : (
                        <span>No data found!</span>
                      )}
                    </div>
                  ) : (
                    menu
                  )
                }
              />
            </div>
          )}
        </div>
      </Modal>
      {!!renderToDos && (
        <NewToDo
          {...{
            visible,
            setVisible,
            recordId: selectedRecord?.recordId,
            recordName: selectedRecord?.recordName,
            userConfiguration,
            isCreate: true,
            category,
            setRowData,
            customLinkTo: selectedRecord.customLinkTo,
          }}
        />
      )}
    </>
  );
};

export default SettingsToDoModal;

import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import LabeledShortcutIcon from "../LabeledShortcutIcon/LabeledShortcutIcon";
import { getAccessRights } from "src/utils";
import { useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { useQuery } from "src/hooks";
import { cloneDeep } from "lodash";
import { useProgramFields } from "../../../../hooks";

const paths = {
  //Sales
  "/leads": "Lead",
  "/opportunities": "Opportunity",
  "/estimations": "Estimation",

  "/contacts": "Contact",

  //Projects
  "/projects": "Project",
  "/scheduling": "Scheduling",
  "/permitdrawings": "Permit Drawing",

  "/accounts": "Client",
  "/subcontractors": "Subcontractor",
  "/inspections": "Inspection",
  "/approvals": "Estimation Request",
  "/tasksManager": "Task",
  "/notes": "Notes",

  //for fleets
  Fleets: "Fleets",
  Violations: "Fleet Violation",
  Dispatch: "Fleet Dispatch",
  Drivers: "Fleet Driver",
  Activities: "Fleet Activity",
  Maintenances: "Fleet Maintenance",
  Incidents: "Fleet Incident",
  Documentation: "Fleet Documentation",
  "Work Order": "Work Order",
  "Schedule Maintenance": "Schedule Maintenance",

  //for accounting tab
  "Schedule Of Values": "Schedule Of Value",
  Rentals: "Rental",
  Requisition: "Application",
  Charges: "Charge",
  Invoice: "Invoice",
  "Receive Payments": "Payment",
  // these accounting in projects has diff name
  Payments: "Payment", //accountingTab
  Applications: "Application", //accountingTab
  Credit: "Charge", //accountingTab
};

export const ShortCuts = ({
  setVisible,
  searchInput = "",
  shortcutButtons,
  setShortcutButtons,
  category,
  modifiedShortcuts,
  setActionHappend,
  showFavourites = false,
  favourites,
  onFavouriteClick,
}) => {
  let query = useQuery();
  const queryTab = query.get("tab");
  const querySubTab = query.get("subTab");

  const { state: locationState } = useLocation();

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const findedElements = shortcutButtons?.sortedSubCategories?.[category]
    .map((el) =>
      modifiedShortcuts?.[category]?.find(({ title }) => title === el)
    )
    .filter(Boolean);

  const { "Approvals Role Access": approvalsRoleAccess } = useProgramFields();

  //check access rights for each topic
  const checkAccessForCategory = (arr) =>
    arr?.filter(({ accessRightsTitle, ...props }) => {
      // accessRightsTitle  // returns string or array of strings
      // props returns object values

      if (category === "Approvals") {
        const { routeConfig } = getAccessRights({
          userConfiguration,
          title: "Approvals",
        });

        if (routeConfig?.write) {
          return (
            approvalsRoleAccess?.[userConfiguration?.departmentName]?.[
              userConfiguration?.groupName
            ]?.includes(props?.title?.split("Request")?.[0]?.trim()) || false
          );
        }

        return false;
      }

      // routeConfig  in line :85  //object with key:value
      // !!routeConfig?.write usage  //returns boolean
      // titles const in line :81 make a check and modify of data wich is used in accesAllowed const
      const titles = Array.isArray(accessRightsTitle)
        ? accessRightsTitle
        : [accessRightsTitle];

      const accessAllowed = titles
        .flatMap((title) => {
          const { routeConfig } = getAccessRights({
            userConfiguration,
            title,
          });
          // don't return modal button for the route that we are located
          // first check for write access to that route
          if (!!routeConfig?.write) {
            //then check if we are located to any TabPane
            if (
              window.location.pathname === "/accounting" ||
              window.location.pathname === "/fleets/overview" ||
              locationState?.tabPosition === "Accounting" ||
              queryTab === "Accounting" //if we are located to the accounting tab of selected projects
            ) {
              // do not return "new modal" of the current position because that modal can be found on top of the grid table (modal can modify directly ag-grid table)

              return (
                paths[
                  locationState?.tabPosition !== "Accounting" ||
                  queryTab !== "Accounting"
                    ? querySubTab
                    : queryTab
                ] !== props.title
              );
            }
            // else we are in dynamic pages
            else {
              return paths[window.location.pathname] !== props.title;
            }
          }

          return false;
        })
        .some(Boolean); // Check if at least one title has access

      return accessAllowed;
    });

  // const clone = cloneDeep(findedElements ?? []); // deep copy
  const favouriteShortcuts = findedElements?.filter((el) =>
    (favourites || [])?.includes(el?.title)
  );
  // clone?.sort((a, b) => {
  //   return favourites?.indexOf(b.title) - favourites?.indexOf(a.title);
  // });

  const filteredShortcuts = checkAccessForCategory(
    showFavourites ? favouriteShortcuts : findedElements
  )?.filter((shortcut) =>
    searchInput?.length
      ? shortcut.title.toLowerCase().includes(searchInput.toLowerCase())
      : true
  );

  const onDragEnd = (result) => {
    if (!result.destination) {
      setActionHappend(false);
      return;
    }
    setActionHappend(true);
    const items = Array.from(filteredShortcuts);
    const [reorderedItem] = items.splice(result.source.index, 1);
    items.splice(result.destination.index, 0, reorderedItem);
    setShortcutButtons((prev) => ({
      ...prev,
      sortedSubCategories: {
        ...prev.sortedSubCategories,
        [category]: items.map(({ title }) => title),
      },
    }));
  };

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <Droppable droppableId="droppable-child">
        {(provided) => (
          <div
            className="categoryItems"
            {...provided?.droppableProps}
            ref={provided?.innerRef}
          >
            {filteredShortcuts?.map((shortcut, key) => {
              const favouriteShortcut = favourites?.find(
                (favourite) => favourite === shortcut?.title
              );

              return (
                <Draggable
                  draggableId={`droppable-${key}`}
                  index={key}
                  key={key}
                >
                  {(provided) => (
                    <div
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      ref={provided.innerRef}
                    >
                      <LabeledShortcutIcon
                        key={key}
                        {...{
                          ...shortcut,
                          favouriteShortcut,
                          onFavouriteClick,
                          setPopoverVisible: setVisible,
                          style: { minWidth: 200 },
                          propTestId: shortcut?.["data-testid"],
                        }}
                      />
                    </div>
                  )}
                </Draggable>
              );
            })}
          </div>
        )}
      </Droppable>
    </DragDropContext>
  );
};

import { useEffect, useState } from "react";
import { Checkbox, Input, InputNumber, Radio, Select, Tag } from "antd";

import "./HoistHeader.scss";
import { DropDownArrow } from "../../../../../../../Fleet/components/InputComponent/assets";

const HoistHeader = ({ elevation, elevationToCompare }) => {
  const [tempCommands, setTempCommands] = useState({
    selectSingleStop: false,
    selectDualStops: false,
  });

  useEffect(() => {
    let temp = elevation?.items || [];
    setTempCommands({
      ...tempCommands,
      selectSingleStop: !temp?.some((item) => item?.stop !== true),
      selectDualStops: !temp?.some((item) => item?.dual !== true),
    });
  }, [elevation]);

  const isDifferent = (fieldName) => {
    return elevationToCompare[fieldName] !== elevation[fieldName];
  };

  const tagRender = (props, key) => {
    const { label, value } = props;

    const compareArray = elevationToCompare[key] || [];

    const isDiff = !compareArray.includes(value);

    return (
      <Tag color={isDiff ? "lightgreen" : ""} style={{ marginRight: 3 }}>
        {label}
      </Tag>
    );
  };

  return (
    <div className="hoistForm-comparison">
      <div className="hoistFormSectionParent">
        <div className="hoistFormSection">
          <div className="hoistFormLabel">Type:</div>
          <Radio.Group
            defaultValue="dual"
            buttonStyle="solid"
            value={elevation.type}
            style={{ border: "none", display: "flex" }}
            disabled={true}
          >
            <Radio.Button
              style={{ border: "none", whiteSpace: "nowrap" }}
              value="single"
              className={isDifferent("type") ? "radioButton" : ""}
            >
              Single Hoist
            </Radio.Button>
            <Radio.Button
              style={{ border: "none", whiteSpace: "nowrap" }}
              value="dual"
              className={isDifferent("type") ? "radioButton" : ""}
            >
              Dual Hoist
            </Radio.Button>
          </Radio.Group>
        </div>
        <div className="hoistFormSection">
          <div className={`hoistFormLabel `}>Select All Floors:</div>
          <div style={{ display: "flex", marginTop: "10px" }}>
            <Checkbox
              className="hoistFormLabel"
              style={
                tempCommands?.selectSingleStop
                  ? {}
                  : {
                      background: "red",
                      padding: "0 5px",
                    }
              }
              checked={tempCommands?.selectSingleStop}
              disabled={true}
            >
              Single
            </Checkbox>
            {elevation?.type === "dual" && (
              <Checkbox
                className="hoistFormLabel"
                style={
                  tempCommands?.selectDualStops
                    ? {}
                    : {
                        background: "red",
                        padding: "0 5px",
                      }
                }
                checked={tempCommands?.selectDualStops}
                disabled={true}
              >
                Dual
              </Checkbox>
            )}
          </div>
        </div>
        <div className="hoistFormSection">
          <div className="hoistFormLabel">Location:</div>
          <Select
            defaultValue="front"
            value={elevation.location}
            className={`hoistFormSelect ${
              isDifferent("location") ? "customSelect" : ""
            }`}
            disabled={true}
            suffixIcon={<DropDownArrow />}
          />
        </div>

        <div className="hoistFormSection">
          <div className="hoistFormLabel">Hoist Info</div>
          <div className="hoistFormSectionChild tagSelect leftPartial hoistInfo">
            <div className="addonBefore">Serial Number:</div>
            <Select
              mode="tags"
              className="selectComponent"
              value={elevation.serialNumbers}
              placeholder="Type it here..."
              tagRender={(props) => tagRender(props, "serialNumbers")}
              disabled={true}
              suffixIcon={<DropDownArrow />}
            />
          </div>
        </div>
      </div>
      <div className="hoistFormSectionParent">
        <div className="hoistFormSection">
          <div className="hoistFormSectionChild">
            <Input
              style={{ width: 200 }}
              addonBefore="Speed:"
              type="number"
              min={1}
              defaultValue={178}
              placeholder="Speed"
              value={!!elevation.speed ? elevation.speed : 178}
              suffix="ft/min"
              className={`speedComp ${isDifferent("speed") ? "highlight" : ""}`}
              disabled={true}
            />
          </div>
        </div>
      </div>
      <div className="hoistFormSectionParent">
        <div className="hoistFormSection">
          <div className="hoistFormLabel">Heights</div>
          <div className="hoistFormSectionChild" style={{ width: "300px" }}>
            <Input
              addonBefore={"Underground Height:"}
              defaultValue={
                elevation.undergroundHeight === "" ||
                elevation.undergroundHeight === undefined
                  ? 0
                  : elevation.undergroundHeight
              }
              type="number"
              value={elevation.undergroundHeight}
              suffix={"ft"}
              min={0}
              className={isDifferent("undergroundHeight") ? "highlight" : ""}
              disabled={true}
            />
          </div>
          <div className="hoistFormSectionChild" style={{ width: "220px" }}>
            <Input
              addonBefore="Building Height:"
              type="number"
              value={elevation.building_height}
              suffix={"ft"}
              min={0}
              className={isDifferent("building_height") ? "highlight" : ""}
              disabled={true}
            />
          </div>
          <div className="hoistFormSectionChild" style={{ width: "220px" }}>
            <Input
              addonBefore="Total Height:"
              type="number"
              value={elevation.totalHeight}
              suffix={"ft"}
              min={0}
              className={isDifferent("totalHeight") ? "highlight" : ""}
              disabled={true}
            />
          </div>

          <div className="hoistFormSectionChild">
            <Input
              addonBefore="Lift Height:"
              type="number"
              value={elevation.lift_height}
              suffix={"ft"}
              style={{ width: "180px", marginTop: "5px" }}
              min={0}
              className={isDifferent("lift_height") ? "highlight" : ""}
              disabled={true}
            />
            <div
              className="hoistFormSectionChild"
              style={{ width: "220px", marginTop: "5px" }}
            >
              <Input
                addonBefore="Mast Height:"
                type="number"
                value={elevation.mastHeight}
                suffix={"ft"}
                min={0}
                className={isDifferent("mastHeight") ? "highlight" : ""}
                disabled={true}
              />
            </div>
          </div>
        </div>
      </div>
      <div className="hoistFormSectionParent">
        <div className="hoistFormSection">
          <div className="hoistFormLabel">Stops:</div>
          <div className="hoistFormSectionChild">
            <InputNumber
              style={{ width: "150px" }}
              addonBefore="From:"
              type="number"
              max={130}
              min={-4}
              value={elevation.from}
              className={isDifferent("from") ? "highlight" : ""}
              disabled={true}
            />
          </div>
          <div className="hoistFormSectionChild">
            <InputNumber
              style={{ width: "150px" }}
              addonBefore="To:"
              type="number"
              max={130}
              value={elevation.to}
              className={isDifferent("to") ? "highlight" : ""}
              disabled={true}
            />
          </div>
        </div>

        <div className="hoistFormSection">
          <div className="hoistFormLabel">Stops Info</div>
          <div className="hoistFormSectionChild tagSelect leftPartial stopsInfo">
            <div className="addonBefore">Including:</div>
            <Select
              mode="tags"
              className="selectComponent"
              value={elevation.stops_including}
              tagRender={(props) => tagRender(props, "stops_including")}
              disabled={true}
            />
            <span className="addonBefore">Excluding:</span>
            <Select
              mode="tags"
              className="selectComponent excludingSelect"
              value={elevation.stops_excluding}
              tagRender={(props) => tagRender(props, "stops_excluding")}
              disabled={true}
            />
          </div>
        </div>

        <div className="hoistFormSection">
          <div className="hoistFormLabel">Dimensions:</div>
          <div className="hoistFormSectionChild">
            <Input
              style={{ width: "150px" }}
              addonBefore="Length:"
              value={elevation.length}
              className={isDifferent("length") ? "highlight" : ""}
              disabled={true}
            />
          </div>
          <div className="hoistFormSectionChild">
            <Input
              style={{ width: "150px" }}
              addonBefore="Width:"
              value={elevation.width}
              className={isDifferent("width") ? "highlight" : ""}
              disabled={true}
            />
          </div>
          <div className="hoistFormSectionChild">
            <Input
              style={{ width: "150px" }}
              addonBefore="Height:"
              value={elevation?.height}
              className={isDifferent("height") ? "highlight" : ""}
              disabled={true}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default HoistHeader;

export const footerButtons = ({
  onCancel,
  cancelRef,
  saveRef,
  onCreateMember,
  suffix,
  employeeType,
  createTeam,
}) => {
  return [
    {
      text: "Cancel",
      cancel: true,
      onClick: onCancel,
      className: "mondayButtonRed",
      dataTestid: "cancel-button",
      tooltipCategory: "Members",
      tooltipKey: "cancel",
      ref: cancelRef,
    },
    employeeType && {
      text: "Create Crew",
      onClick: () => console.log("clicked"),
      // onClick: onCreateCrew,
      type: "checkbox",
    },
    {
      text: createTeam ? "Next" : "Create",
      primary: true,
      onClick: onCreateMember,
      className: "mondayButton",
      suffix: suffix,
      dataTestid: "save-button",
      tooltipCategory: "Members",
      tooltipKey: "save",
      ref: saveRef,
    },
  ].filter(Boolean);
};

export function employeeFields({ isDarkMode, role, accountName }) {
  return [
    // {
    //   type: "input",
    //   placeholder: "Enter here...",
    //   formItemName: "employeeNumber",
    //   className: "employeeNumberInput",
    //   dataTestid: "employeeNumberField",
    //   required: false,
    //   disabled: !accountName,
    // },
    {
      label: "Full Name",
      type: "input",
      placeholder: "Name...",
      formItemName: "crewName",
      className: "crewNameInput",
      dataTestid: "nameField",
      required: true,
    },
    {
      label: "Crew Role",
      type: "select",
      placeholder: "Select role...",
      formItemName: "crewPosition",
      dataTestid: "roleField",
      popupClassName: isDarkMode && "darkDropDown",
      className: "crewPositionInput",
      customOptions: (role || [])?.map(({ statusName }, key) => ({
        key,
        label: statusName,
        value: statusName,
      })),
      required: true,
      getPopUpContainer: document.body,
      dataTestid: "select-input",
    },
    {
      label: "Employee Type",
      type: "select",
      placeholder: "Select employee type...",
      formItemName: "foreman",
      dataTestid: "employeeTypeField",
      disabled: !accountName,
      allowClear: false,
      customOptions: [
        { key: 0, label: "Member", value: false },
        { key: 1, label: "Foreman", value: true },
      ],
      required: true,
      getPopUpContainer: document.body,
    },
    {
      label: "Salary Type",
      type: "select",
      disabled: !accountName,
      placeholder: "Select salary type...",
      formItemName: "salaryType",
      dataTestid: "salaryTypeField",
      popupClassName: isDarkMode && "darkDropDown",
      customOptions: [
        {
          key: 0,
          label: "Hourly",
          value: "Hourly",
        },
        {
          key: 1,
          label: "Salary",
          value: "Salary",
        },
      ],
      required: true,
      getPopUpContainer: document.body,
      dataTestid: "select-input",
    },
    {
      label: "Pay Rate",
      type: "number",
      typeNumber: true,
      placeholder: "Pay rate...",
      formItemName: "employeeRate",
      dataTestid: "payRateField",
      prefix: "$",
      disabled: !accountName,
      min: 0,
      required: true,
    },
    // {
    //   label: "Subcontractor",
    //   type: "select",
    //   placeholder: "Select subcontractor...",
    //   formItemName: "accountName",
    //   popupClassName: isDarkMode && "darkDropDown",
    //   customOptions: (subcontractor || [])?.map(({ accountName }, key) => ({
    //     key,
    //     label: accountName,
    //     value: accountName,
    //   })),
    //   getPopUpContainer: document.body,
    // },
    {
      label: "Employee ID",
      type: "input",
      disabled: !accountName,
      placeholder: "Employee Id...",
      formItemName: "employeeId",
      className: "employeeIdInput",
      dataTestid: "employeeIdField",
      required: true,
    },
    {
      label: "Dynamic Selection",
      type: "select",
      placeholder: "Dynamic Selection...",
      formItemName: "dynamicSelection",
      className: "employeeIdInput",
      customOptions: [
        {
          key: 0,
          label: "Yes",
          value: true,
        },
        {
          key: 1,
          label: "No",
          value: false,
        },
      ],
      dataTestid: "employeeIdField",
      required: true,
    },
  ];
}

export function employeeEmergencyContactFields() {
  return {
    formItemName: "emergencyContact",
    label: "Emergency Contact",
    type: "MultipleInputs",
    disabled: false,
    dynamicFields: [
      {
        formItemName: "fullName",
        label: "Full Name",
        type: "Input",
        disabled: false,
        rules: [{ required: false, message: "Please input the Full Name" }],
      },
      {
        formItemName: "email",
        label: "Email",
        type: "Email",
        disabled: false,
        rules: [
          {
            required: false,
            type: "email",
            message: "The input is not valid e-mail!",
          },
        ],
      },
      {
        formItemName: "phoneNumber",
        label: "Phone Number",
        type: "PhoneNumber",
        rules: [
          {
            required: false,
            message: "Please input your phone number!",
          },
          {
            pattern:
              /^1?[-. ]?\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/,
            message: "Please enter a valid phone number",
          },
        ],
        disabled: false,
      },
      {
        formItemName: "relationship",
        label: "Relationship",
        type: "ComboBox",
        options: ["Parent", "Spouse", "Sibling", "Child", "Friend"],
        disabled: false,
        rules: [
          {
            required: false,
            message: "Please select or enter a Relationship",
          },
        ],
      },
    ],
  };
}

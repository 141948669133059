import React, { useState, useEffect } from "react";
import { Input, Button, Select, Form } from "antd";
import { MondayButton } from "../../../../commonComponents";
import {
  ArrowUpOutlined,
  ArrowDownOutlined,
  CloseOutlined,
} from "@ant-design/icons";
import { PlusIcon } from "../../../../../assets";
import styles from "./MultipleInputs.module.scss";
import PhoneInput from "react-phone-input-2";

const { Option } = Select;

const MultipleInputs = ({
  name: formItemName,
  label,
  value,
  dynamicFields,
  form,
  isDarkMode,
}) => {
  const initialValue = Array.isArray(value) ? value : value ? [value] : [{}];
  const [rows, setRows] = useState(initialValue);

  useEffect(() => {
    if (rows.length === 0) {
      addRow();
    }
  }, [rows]);

  const addRow = () => {
    const newRow = dynamicFields.reduce((acc, field) => {
      acc[field.formItemName] = "";
      return acc;
    }, {});
    setRows((prevRows) => {
      const updatedRows = [...prevRows, newRow];
      handleRowChange(updatedRows);
      return updatedRows;
    });
  };

  const removeRow = (index) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows.splice(index, 1);
      handleRowChange(updatedRows);
      return updatedRows;
    });
  };

  const moveRowUp = (index) => {
    if (index > 0) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        [updatedRows[index], updatedRows[index - 1]] = [
          updatedRows[index - 1],
          updatedRows[index],
        ];
        handleRowChange(updatedRows);
        return updatedRows;
      });
    }
  };

  const moveRowDown = (index) => {
    if (index < rows.length - 1) {
      setRows((prevRows) => {
        const updatedRows = [...prevRows];
        [updatedRows[index], updatedRows[index + 1]] = [
          updatedRows[index + 1],
          updatedRows[index],
        ];
        handleRowChange(updatedRows);
        return updatedRows;
      });
    }
  };

  const handleInputChange = (index, fieldName, value) => {
    setRows((prevRows) => {
      const updatedRows = [...prevRows];
      updatedRows[index][fieldName] = Array.isArray(value)
        ? value[value.length - 1]
        : value;
      handleRowChange(updatedRows);
      return updatedRows;
    });
  };

  const handlePhoneNumberChange = (index, fieldName, value) => {
    handleInputChange(index, fieldName, value.replace(/^\+/, ""));
  };

  const handleRowChange = (updatedRows) => {
    form.setFieldsValue({
      [formItemName]: updatedRows,
    });
  };

  const renderInput = (field, row, index) => {
    const { formItemName, type, disabled, options = [] } = field;

    switch (type) {
      case "Input":
      case "Email":
        return (
          <Input
            value={row[formItemName]}
            type={type === "Email" ? "email" : "text"}
            disabled={disabled}
            className={`${styles.EditInputComponent} ${
              isDarkMode && styles.EditInputComponentDark
            }`}
            onChange={(e) =>
              handleInputChange(index, formItemName, e.target.value)
            }
          />
        );
      case "PhoneNumber":
        return (
          <div
            className={`${styles.EditInputComponent} ${
              isDarkMode && styles.EditInputComponentDark
            }`}
          >
            <PhoneInput
              disableCountryGuess
              {...{
                country: "us",
                onlyCountries: ["us"],
                disableCountryCode: false,
                disabled,
                specialLabel: "",
                copyNumbersOnly: false,
              }}
              value={row[formItemName]}
              onChange={(value) =>
                handlePhoneNumberChange(index, formItemName, value)
              }
            />
          </div>
        );

      case "ComboBox":
        return (
          <Select
            value={row[formItemName]}
            disabled={disabled}
            mode="tags"
            maxTagCount={1}
            className={`${styles.EditDropDownComponent} ${
              isDarkMode && styles.EditDropDownComponentDark
            }`}
            onChange={(value) => handleInputChange(index, formItemName, value)}
            showSearch
            allowClear
            filterOption={(input, option) =>
              option?.children?.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
            placeholder={`Select or enter ${field.label.toLowerCase()}`}
          >
            {options.map((option) => (
              <Option key={option} value={option}>
                {option}
              </Option>
            ))}
          </Select>
        );

      default:
        return null;
    }
  };

  return (
    <div className={styles.container}>
      {rows.map((row, index) => (
        <div key={index} className={styles.rowContainer}>
          <div className={styles.rowButtons}>
            <Button
              onClick={() => moveRowUp(index)}
              disabled={index === 0}
              className={isDarkMode && styles.upBtnDark}
            >
              <ArrowUpOutlined />
            </Button>
            <Button
              onClick={() => moveRowDown(index)}
              disabled={index === rows.length - 1}
              className={isDarkMode && styles.downBtnDark}
            >
              <ArrowDownOutlined />
            </Button>
          </div>
          <div className={styles.inputContainer}>
            {dynamicFields.map((field) => {
              const fieldName = [formItemName, index, field.formItemName];
              return (
                <div key={field.formItemName}>
                  <span className={styles.label}>{field.label}:</span>
                  <Form.Item
                    name={fieldName}
                    rules={field.rules || []}
                    validateTrigger={["onChange", "onBlur"]}
                    initialValue={row[field.formItemName]}
                  >
                    {renderInput(field, row, index)}
                  </Form.Item>
                </div>
              );
            })}
          </div>
          <MondayButton
            Icon={<CloseOutlined />}
            className={"mondayButtonRed"}
            style={{ marginTop: "22px" }}
            onClick={() => removeRow(index)}
          />
        </div>
      ))}
      <MondayButton
        Icon={<PlusIcon />}
        className={"mondayButtonBlue"}
        onClick={addRow}
      >
        {`Add new ${label || "Row"} `}
      </MondayButton>
    </div>
  );
};

export default MultipleInputs;

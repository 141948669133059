import React, { useState } from "react";
import { DeleteIcon } from "../../../../../../../../assets";
import { useDispatch } from "react-redux";
import { ConfirmationModal } from "../../../../ConfirmationModal";
import { useSelector } from "react-redux";
import { onDeleteConfirmation } from "../../../../helpers/notification-item-actions";

function DeleteItem({ setAnimatedClassName, apiName, path, text, type, item }) {
  // Destructure properties from the notification item
  const {
    id,
    notificationBody: { notificationObject, cognitoUserId },
  } = item;

  // Get the authenticated user from Redux state
  const { authenticatedUser } = useSelector((state) => state.authenticatedUser);
  const dispatch = useDispatch(); // Hook to dispatch actions to the Redux store
  const [open, setOpen] = useState(false); // Local state to manage modal visibility

  // Function to handle the confirmation of deletion
  const onOK = () => {
    onDeleteConfirmation(
      setAnimatedClassName, // Function to set animated class names
      id, // Notification ID to delete
      cognitoUserId, // Cognito User ID associated with the notification
      authenticatedUser, // Currently authenticated user
      notificationObject, // The notification object to delete
      apiName, // API name for the delete request
      path, // API path for the delete request
      dispatch, // Dispatch function for Redux
      type // Type of the notification being deleted
    );
  };

  return (
    <div>
      <span
        className="notification-delete-icon" // Icon for deleting the notification
        onClick={() => {
          setOpen(true); // Set modal open state to true when the delete icon is clicked
        }}
      >
        <DeleteIcon /> {/* Render delete icon */}
      </span>
      {open ? (
        <ConfirmationModal
          showModal={open} // Control modal visibility
          setShowModal={() => setOpen(false)} // Function to close the modal
          title={`delete ${text.replace(/<[^>]+>/g, "")}`} // Title of the confirmation modal
          onOK={onOK} // Function to execute on confirmation
        />
      ) : null}
    </div>
  );
}

export default DeleteItem;

import hospital from "./hospital.png";
import laborDep from "./laborDep.png";
import nyAmbulance from "./nyAmbulance.png";
import NYFD from "./NYFD.png";
import NYPD from "./NYPD.png";
import poisonHelp from "./poisonHelp.png";

const emergencyContactsImages = {
  "hospital.png": hospital,
  "laborDep.png": laborDep,
  "nyAmbulance.png": nyAmbulance,
  "NYFD.png": NYFD,
  "NYPD.png": NYPD,
  "poisonHelp.png": poisonHelp,
};

export default emergencyContactsImages;

import { Progress, Result } from "antd";
import "./StreightProgressBar.scss";
import { SmallLoadableComp } from "../../../../Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import { Tooltip } from "antd";
import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";
import { GraphTooltipContent } from "../BarChart/GraphTooltipContent";
import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { roundPercentageTotals } from "../../../../../utils/calculatePercentages";

const StreightProgressBar = ({ params = {}, filterValue }) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const history = useFilteredHistory(params?.table);
  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (params?.isChartDataLoading) return;

    const content = params?.content;

    if (
      !content ||
      content.length === 0 ||
      content.every((a) => a?.percent === "NaN")
    ) {
      setNoData(true);
      setLoading(false);
    } else {
      setNoData(false);
      setLoading(false);
    }
  }, [params?.content, filterValue, params?.isChartDataLoading]);

  if (params.content.length !== 0) {
    const percentValues = params.content.map((item) =>
      parseFloat(item.percent)
    );

    const roundedPercentages = roundPercentageTotals(percentValues);

    params.content.forEach((item, index) => {
      item.percent = roundedPercentages[index];
    });
  }

  return (
    <div
      className={`StreightProgressBarContent ${
        isDarkMode && "StreightProgressBarContentDark"
      }`}
    >
      <div className="StreightProgresBarBodyStyle">
        {!noData ? (
          <SmallLoadableComp loading={loading}>
            <div className="StreightProgressTextContent">
              <div className="StreightProgressTitle">{params?.title}</div>
              <div className="StreightProgressSubtitle">{params?.subtitle}</div>
            </div>
            {params?.content?.map((a, index) => (
              <Tooltip
                key={`tooltip${index}`}
                overlayClassName={
                  !a?.custom ? "dashToolTipStyle" : "dashToolTipStyleCustomm"
                }
                placement="top"
                title={
                  !a?.custom ? (
                    `${a?.status}: ${a?.done || 0}`
                  ) : (
                    <GraphTooltipContent
                      {...{
                        title: `${a?.status}: ${a?.done || 0}`,
                        tooltipData: a?.custom,
                        label: params?.table,
                      }}
                    />
                  )
                }
              >
                <div
                  key={index}
                  className="content"
                  onClick={() => history({ [params?.field]: [a?.status] })}
                >
                  <div className="StreightProgressBarStyle">
                    <Progress
                      size="200px"
                      percent={!!a?.percent ? a?.percent : 0}
                      showInfo={a?.showInfo}
                      strokeColor={a?.color}
                      trailColor={a?.unfilledColor}
                    />
                  </div>

                  <div className="StreightProgressBarStatus">{a?.status}</div>
                </div>
              </Tooltip>
            ))}
          </SmallLoadableComp>
        ) : (
          <Result
            status="warning"
            title={
              params?.noFunction
                ? `There are no matching pattern for this chart `
                : `No data found for ${params?.table}!`
            }
            subTitle={
              params?.noFunction
                ? `Try removing and recreating this chart. If this reoccurs, please contact the Software Department.`
                : `You may not have access to any records!`
            }
          />
        )}
      </div>
    </div>
  );
};
export default StreightProgressBar;

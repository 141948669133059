//@ts-check
import { Flex, Popover } from "antd";
import React from "react";

import styles from "./AttachmentsView.module.css";
import { InformationIcon } from "../../../../../icons";
import Attachment from "../Attachment/Attachment";

const HEADER_TEXT = "You can remove any file as needed.";

const AttachmentsView = ({
  children,
  items = [],
  onDelete = async () => {},
  disabled,
}) => {
  if (disabled) return children;

  return (
    <Popover
      content={
        <Flex className={styles.attachmentsContent}>
          <Flex className={styles.attachmentsHeader}>
            <InformationIcon color="blue" className={styles.infoIcon} />
            <span className={styles.headerText}>{HEADER_TEXT}</span>
          </Flex>
          <Flex className={styles.attachmentsBody}>
            {items.map((item) => (
              <Attachment
                key={item.id}
                file={item}
                onDelete={onDelete}
                color="gray"
              />
            ))}
          </Flex>
        </Flex>
      }
    >
      {children}
    </Popover>
  );
};

export default AttachmentsView;

import { message } from "antd";
import { lazyFetch } from "../../../../../utils";
import { generateLabel, generateLinkTo } from "../helpers";

export const getCategoriesData = async (categoriesToFetch) => {
  if (!categoriesToFetch || !categoriesToFetch.length) {
    message.error("No categories to get data from");
    return;
  }

  try {
    const fetchPromises = categoriesToFetch.map(async (category) => {
      if (!category) {
        message.error(
          "This category is not available at this moment. Please select another one."
        );
        return;
      }

      const {
        apiName,
        rowName,
        primaryKey,
        otherName,
        categoryName,
        lazyFetchKeys,
        filterKey,
        filterValue,
      } = category;

      if (!apiName || apiName === "") {
        console.error("API name is missing");
        return;
      }

      const listOfKeys = [
        rowName,
        primaryKey,
        otherName,
        ...(categoryName ? ["categoryName", "recordId"] : []),
        ...(Array.isArray(lazyFetchKeys)
          ? lazyFetchKeys.filter((key) => key !== "status")
          : []),
        apiName === "scheduling" ? "scheduleName" : "",
      ].filter(Boolean);

      const listOfKeysSets = [...new Set(listOfKeys)];

      const res = await lazyFetch({
        tableName: apiName,
        listOfKeys: listOfKeysSets,
        ...(filterKey && { filterKey }),
        ...(filterValue && { filterValue }),
      });

      const structuredData = {
        [category.categoryName]: res.map((element) => {
          const linkTo = generateLinkTo(category, element);
          const label = generateLabel(category, element);

          return {
            ...element,
            key: element[category.primaryKey],
            label,
            value: element[category.primaryKey],
            linkTo,
            categoryName: category.categoryName,
          };
        }),
      };

      return structuredData;
    });

    const categoriesDataArray = await Promise.all(fetchPromises);

    return categoriesDataArray;
  } catch (error) {
    console.error("Error fetching data:", error);
  }
};

import {
  LiveMapView,
  LiveIdleView,
  LiveAuditView,
  LiveTripsView,
  AfterHoursView,
  LiveReportsView,
  LiveReasonsView,
  PlannedTripsView,
  LiveLocationsView,
  LiveAuditOverview,
} from "../components";

const ACTIVE_VIEW_DATA = {
  MAP: LiveMapView,
  LOCATIONS: LiveLocationsView,
  TRIPS: LiveTripsView,
  PLANNED_TRIPS: PlannedTripsView,
  STOP_REASONS: LiveReasonsView,
  IDLE_EVENTS: LiveIdleView,
  AFTER_HOURS: AfterHoursView,
  AUDIT: LiveAuditView,
  AUDIT_OVERVIEW: LiveAuditOverview,
  REPORTS: LiveReportsView,
};

export default ACTIVE_VIEW_DATA;

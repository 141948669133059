import { message } from "antd";
import {
  getCognitosForNotification,
  showLoadingMsg,
  showSuccessMsg,
  updateTeamsConfiguration,
} from "../../../../../utils";
import broadcastNotification from "../../../../../helpers/controllers/broadcastNotification";
import { API } from "aws-amplify";
import createNewTask from "../../../../SidebarPages/Incidents/Modals/utils/createNewTask";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
import dayjs from "dayjs";
import { v4 as uuidv4 } from "uuid";
import { keys } from "../../../../pages/Settings/settingsComponents/Roles/RolesData";

const getLatLngFromAddress = async (address) => {
  try {
    const response = await fetch(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${encodeURIComponent(
        address
      )}&key=AIzaSyBPFYynCEmAoBWPFm9G1X_ldzbFfI3azIo`
    );
    const data = await response.json();
    if (data.results.length > 0) {
      const { lat, lng } = data.results[0].geometry.location;
      return { lat, lng };
    } else {
      throw new Error("No results found");
    }
  } catch (error) {
    console.error("Error:", error.message);
  }
};

const initials = (nameOfUser) =>
  nameOfUser
    ?.split(" ")
    ?.map((n) => n[0])
    ?.join("")
    ?.toUpperCase() || "AA";

const saveClaimFunction = async ({
  getFieldsValue,
  formattedFiles,
  requestFiles,
  category,
  addressSelectionInfo,
  claimHotCredential,
  driveFunctions,
  userConfiguration,
  selectedTeam,
  handleSavedRecord,
  updateProgressStatus,
  setSaving,
  notes,
  mentions,
  mentionIds,
  selectedSafety,
  incident,
  assignedTo,
  preferences,
  requestId,
  dispatch,
  loggedOnTasks,
  afterSaveRequest,
  hideProgress,
  programFields,
  accessToken,
  setVisibleCreationProgress,
  claimStatus,
}) => {
  const data = { ...getFieldsValue() };
  const {
    claimNumber,
    claimType,
    incidentRecord,
    claimTeam,
    claimDate,
    time,
    ...otherValuesWithoutName
  } = data;

  const filteredOther = Object.fromEntries(
    Object.entries(otherValuesWithoutName).filter(
      ([key]) => !key.includes("datePicker")
    )
  );

  // check if is allowed in automations configuration to create task for
  const postCreationFieldOptions = programFields?.find(
    (prog) => prog.fieldName === "Automated Tasks"
  )?.fieldOptions;
  !postCreationFieldOptions.Claims && hideProgress("creatingTask");
  setVisibleCreationProgress(true);

  const dateAndTime = dayjs
    .tz(`${claimDate} ${time}`, "MM/DD/YYYY hh:mm A", "America/New_York")
    .format();

  const teamsConfiguration = updateTeamsConfiguration(
    userConfiguration,
    selectedTeam
  );

  const latLng = await getLatLngFromAddress(data.claimAddress).then((res) => {
    return { lat: res.lat, lng: res.lng };
  });

  let allFolders = null;
  try {
    if (!driveFunctions.parentFolderId) {
      const { folders } = await driveFunctions.create({
        parentFolderName: `${claimNumber} ${dayjsNY().format(
          "DD/MM/YYYY HH:mm:ss"
        )}`,
        parentId: claimHotCredential?.credentialValue,
      });
      allFolders = folders;
    }
  } catch (e) {
    allFolders = null;
    console.error(e);
  }
  let notesObj = {};
  if (notes !== "" && notes !== "<p><br></p>") {
    notesObj = {
      author: userConfiguration?.nameOfUser,
      cognitoUserId: userConfiguration?.cognitoUserId,
      noteComment: notes,
      createdAt: Date.now(),
      noteId: uuidv4(),
      commentId: uuidv4(),
      category: "General",
      mentions: mentions,
      topicCategory: "Claims",
      logo: initials(userConfiguration?.nameOfUser),
      published: Date.now(),
      fromSettings: false,
      replies: [],
      repliesVisibility: false,
    };
  } else {
    notesObj = {};
  }

  const newClaimObject = {
    ...filteredOther,
    googleDriveUpload: [...formattedFiles, ...(requestFiles || [])],
    claimCoordinates: latLng,
    assignedTo,
    time: dateAndTime,
    claimDate: dateAndTime,
    requestId,
  };

  const claimObject = {
    claimNumber,
    claimType,
    claimCategory: category,
    ...addressSelectionInfo,
    safety: incident, //{id,name}
    claimStatus,
    claimNote: [notesObj],
    createdBy: {
      name: userConfiguration?.nameOfUser,
      id: userConfiguration?.cognitoUserId,
    },
    claimObject: newClaimObject,
    googleDriveFolderIds: driveFunctions?.parentFolderId
      ? driveFunctions?.allFolders
      : allFolders,
    teamsConfiguration,
  };
  showLoadingMsg();
  setSaving(true);
  updateProgressStatus({ updatingRecord: "executing" });
  API.post("claims", "/claims", { body: claimObject })
    .then(async (r) => {
      handleSavedRecord(r);
      setVisibleCreationProgress(r); //save claimId in state to navigate to this id & save logs
      updateProgressStatus({ updatingRecord: "finished" });
      if (
        !!incident &&
        keys(selectedSafety) &&
        !selectedSafety?.safetyApplicationObject?.isDraft
      ) {
        await API.put("safety", `/safety/${incident.id}`, {
          body: { safetyApplicationStatus: "Proceeded to Claim" },
        })
          .then((res) => {
            message.success("Safety Application status updated");
          })
          .catch((e) => console.error(e));
      }

      if (Object.keys(notesObj).length !== 0) {
        await API.post("notes", `/notes`, {
          body: {
            ...notesObj,
            linkTo: `/claims/${r.claimId}`,
            recordId: `${r.claimId}`,
          },
        })
          .then((res) => {})
          .catch((e) => console.error(e));
      }

      if ((r?.claimObject?.assignedTo || [])?.length > 0) {
        // await broadcastNotification(
        broadcastNotification(
          "21",
          "onClaimAssignation",
          [
            {
              common: userConfiguration?.nameOfUser,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos:
                r.claimObject?.assignedTo?.map(
                  ({ cognitoUserId }) => cognitoUserId
                ) || [],
            },
          ],
          r.claimId
        );
      }
      if (mentions?.length !== 0) {
        broadcastNotification(
          "21",
          "onClaimMention",
          [
            {
              user: userConfiguration?.nameOfUser,
              common: r.claimNumber,
            },
            {
              userName: userConfiguration?.nameOfUser,
              currentUser: userConfiguration?.cognitoUserId,
              cognitos: mentionIds || [],
            },
          ],
          r.claimId
        );
      }
      if (!!requestId) {
        await afterSaveRequest({
          newRecordId: r.claimId,
          // requestId,
          path: "claims",
          // cognitoUserId: userConfiguration?.cognitoUserId,
          moveFilesParams: {
            filesToMove: requestFiles || [],
            newParentId: r?.googleDriveFolderIds?.claimObject,
            accessToken,
          },
        });

        // await moveRequestFiles({
        //   requestId,
        // });
      }
      if (postCreationFieldOptions.Claims) {
        updateProgressStatus({ creatingTask: "executing" });
        await createNewTask({
          category: "Claims",
          taskAssignedTo: assignedTo?.map((user) => ({
            identityId: user?.identityId,
            cognitoUserId: user.cognitoUserId,
            nameOfUser: user.label,
            userName: user?.userName,
          })),
          taskRelatedTo: r?.claimNumber,
          taskTopicId: r?.claimId,
          taskSubTopic: category,
          userConfiguration,
          calendarPreference: preferences?.calendarPreference,
          loggedOnTasks,
          dispatch,
        })
          .then(() => {
            updateProgressStatus({ creatingTask: "finished" });
          })
          .catch((err) => {
            console.error("CREATETAASKKKKKK", err);
            updateProgressStatus({ creatingTask: "hasError" });
          });
      }
      showSuccessMsg();
      setSaving(false);

      updateProgressStatus({ sendingNotification: "executing" });
      //await to get the result from createn notification
      broadcastNotification(
        "21",
        "onCreation",
        [
          {
            common: userConfiguration?.nameOfUser,
            claimCategory: category,
          },
          {
            userName: userConfiguration?.nameOfUser,
            currentUser: userConfiguration?.cognitoUserId,
            cognitos: getCognitosForNotification(
              userConfiguration,
              r?.teamsConfiguration
            ),
          },
        ],
        r.claimId
      )
        .then((notificationSent) => {
          updateProgressStatus({
            sendingNotification: !!notificationSent ? "finished" : "hasError",
          });
        })
        .catch((error) => {
          console.error("Error sending notification:", error);
        });
    })
    .catch((error) => {
      console.error("errrrrrrrrr", { error });
      updateProgressStatus({ updatingRecord: "hasError" });
    });
  message.destroy("projectDriveFolders");
  message.destroy("windowMessages");
};

export default saveClaimFunction;

import { useNavigate } from "react-router-dom";
import { createQuery } from "../../../../utils";

const useReportOpener = (route) => {
  const navigate = useNavigate();

  const reportOpener = (category, reportId, state) => {
    const queryParams = {
      category,
      reportId,
    };

    if (state?.tab) {
      queryParams.tab = state.tab;
    }

    navigate(`/${route}?${createQuery(queryParams)}`, { state });
  };

  return reportOpener;
};

export default useReportOpener;

import { callGmailAPI } from "../../../../../SidebarPages/Communication/functions";
import { camelToTitle } from "../../../../Payroll/Tabs/DEG/components/modalComponents/ShiftEditModal/editShiftModalData";
import {
  emailTemplateForPostEventAutomation,
  makeDataOfPostAutomationReadable,
} from "../utils";

export const executeOneTimeAutomation = async (body) => {
  // destruct values from body witch will be used for email template
  const { categoryName, automationName, automationBody, action, href } = body;

  console.log({ body });

  // make the automationBody.dataToShowInAutomationResponse same structure
  const proceedData = Array.isArray(
    automationBody.dataToShowInAutomationResponse
  )
    ? automationBody.dataToShowInAutomationResponse
    : [automationBody.dataToShowInAutomationResponse];

  // prepared email ui its here, and handles all structures of data witch shows dynamically
  const infoTableRows = (data) =>
    Object.entries(data)
      .filter(
        ([key]) =>
          !["id", "key", "primaryk"].some((substring) =>
            key.toLowerCase().includes(substring)
          )
      )
      .map(([key, value]) => {
        if (value === undefined || value === null || !value) {
          value = "";
        } else if (
          typeof value === "number" &&
          value.toString().length !== 13
        ) {
          value = value.toString();
        } else if (!Array.isArray(value) && typeof value === "object") {
          value = "";
        } else {
          value =
            Array.isArray(value) ||
            (typeof value !== "number" && value?.includes("https:"))
              ? makeDataOfPostAutomationReadable(value)
              : value.toString().length === 13
              ? makeDataOfPostAutomationReadable(value)
              : value;
        }

        return `<tr style="border-bottom: 1px solid #ccc;">
          <th style="text-align: left; padding: 8px;">${camelToTitle(key)}:</th>
          <td style="padding: 8px;">${value}</td>
        </tr>`;
      })
      .join("");

  const dynamicList = (dataToShow) => ({
    relatedTo: `Title: ${automationName}`,
    otherValues: `<table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
    ${infoTableRows(dataToShow)}
  </table>`,
    linkTo: `<table style="width: 100%; border-collapse: collapse; margin-top: 10px;">
      <tr style="border-bottom: 1px solid #ccc;">
        <th style="text-align: left; padding: 8px;">Link to</th>
        <td style="padding: 8px;">
          <a href=${href}>
              ${automationName} - post event (${dataToShow.action || action})
          </a>
        </td>
      </tr>
    </table>`,
    filtersDescription: `post event (${dataToShow.action || action})`,
  });

  const emailPromises = proceedData.flatMap((dataToShow) =>
    automationBody.usersList.map((user) =>
      callGmailAPI("sendEmail", {
        to: user.userName,
        body: emailTemplateForPostEventAutomation({
          categoryName,
          usersList: user,
          dynamicList: dynamicList(dataToShow),
          action,
        }),
        attachments: [],
        subject: `${automationName} - post event (${
          dataToShow.action || action
        })`,
        authData: false,
      })
    )
  );

  try {
    const responses = await Promise.all(emailPromises);
    return responses;
  } catch (error) {
    console.error(error);
    throw error;
  }
};

import dayjs from "dayjs";
import { UsernameRenderer } from "../../../../../pages/Settings/settingsComponents/AllUsers/components";
import { Avatar, Tooltip } from "antd";
import { getInitials } from "../../../../../../utils";
const cellStyle = {
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
};
function logsColumnDefs({ allUsersObj, sessionId }) {
  return !sessionId
    ? [
        {
          headerName: "User",
          field: "nameOfUser",
          width: 200,
          enableRowGroup: true,
          valueGetter: ({ data }) => {
            return allUsersObj[data?.sessionInfo?.identityId]?.nameOfUser;
          },
          cellRendererFramework: ({ value, data }) => {
            const currUser = allUsersObj?.[data?.sessionInfo?.identityId] || {
              nameOfUser: value,
            };
            return (
              <div style={{ ...cellStyle, gap: 10 }}>
                <Avatar src={null}>{getInitials(currUser?.nameOfUser)}</Avatar>
                <span>{currUser?.nameOfUser}</span>
              </div>
            );
            // return (
            //   <UsernameRenderer
            //     {...{ value: currUser?.nameOfUser, data: currUser, onlyName: true }}
            //   />
            // );
          },
        },
        {
          headerName: "Status",
          field: "sessionStatus",
          enableRowGroup: true,
          width: 100,
          cellRendererFramework: ({ value }) => {
            const items = {
              active: { color: "#00A464", label: "Active" },
              idle: { color: "#F04F4E", label: "Idle" },
              offline: { color: "#787677", label: "Offline" },
              online: { color: "#00A464", label: "Online" },
              "logged out": { color: "#F04F4E", label: "Logged Out" },
            };
            return (
              <span style={{ color: items?.[value]?.color || "#000" }}>
                {items?.[value]?.label || value}
              </span>
            );
          },
          //   cellStyle,
        },
        {
          headerName: "Department",
          field: "userDepartment",
          enableRowGroup: true,
          width: 170,
          valueGetter: ({ data }) => {
            return allUsersObj[data?.sessionInfo?.identityId]?.departmentName;
          },
          cellRendererFramework: ({ value, data }) => {
            if (value) {
              return <span>{value}</span>;
            } else {
              return (
                <span>
                  {allUsersObj[data?.sessionInfo?.identityId]?.departmentName}
                </span>
              );
            }
          },
          //   cellStyle,
        },
        {
          headerName: "Date and Time",
          field: "logTime",
          enableRowGroup: true,
          width: 200,
          sort: "desc",
          valueGetter: ({ data }) => {
            return data?.sessionInfo?.logTime;
          },
          cellRendererFramework: ({ data }) => {
            const value = data?.sessionInfo?.logTime;
            const date = dayjs(value).format("MM/DD/YYYY");
            const time = dayjs(value).format("hh:mm A");
            return (
              <span>
                {date} - {time}
              </span>
            );
          },
        },
        {
          headerName: "IP Address",
          field: "sessionInfo",
          width: 150,
          enableRowGroup: true,
          valueGetter: ({ data }) => {
            return data?.sessionInfo?.ip;
          },
        },
        {
          headerName: "Device",
          field: "sessionInfo",
          enableRowGroup: true,
          valueGetter: ({ data }) => {
            const osName = data?.sessionInfo?.osName;
            const browserName = data?.sessionInfo?.browserName;
            if (osName && browserName) {
              return `${osName} - ${browserName}`;
            } else {
              return "Unknown";
            }
          },
        },
        {
          headerName: "Role",
          field: "userRole",
          width: 155,
          enableRowGroup: true,
          valueGetter: ({ data }) => {
            return allUsersObj[data?.sessionInfo?.identityId]?.groupName;
          },
          cellRendererFramework: ({ value, data }) => {
            if (value) {
              return <span>{value}</span>;
            } else {
              return (
                <span>
                  {allUsersObj[data?.sessionInfo?.identityId]?.groupName}
                </span>
              );
            }
          },
        },
      ]
    : [
        {
          headerName: "Date and Time",
          field: "logTime",
          enableRowGroup: true,
          width: 200,
          sort: "desc",
          valueGetter: ({ data }) => {
            return data?.sessionInfo?.logTime;
          },
          cellRendererFramework: ({ data }) => {
            const value = data?.sessionInfo?.logTime;
            const date = dayjs(value).format("MM/DD/YYYY");
            const time = dayjs(value).format("hh:mm A");
            return (
              <span>
                {date} - {time}
              </span>
            );
          },
        },
        {
          headerName: "Status",
          field: "sessionStatus",
          enableRowGroup: true,
          width: 100,
          cellRendererFramework: ({ value }) => {
            const items = {
              active: { color: "#00A464", label: "Active" },
              idle: { color: "#F04F4E", label: "Idle" },
              offline: { color: "#787677", label: "Offline" },
              online: { color: "#00A464", label: "Online" },
              "logged out": { color: "#F04F4E", label: "Logged Out" },
            };
            return (
              <span style={{ color: items?.[value]?.color || "#000" }}>
                {items?.[value]?.label || value}
              </span>
            );
          },
          //   cellStyle,
        },
        {
          headerName: "Reason",
          field: "reason",
          cellRenderer: ({ value }) => {
            if (!value) return;
            const showToolTip = value.length >= 10;

            const part1 = value.slice(0, 10);
            const part2 = value.slice(10);

            const tooltipContent = (
              <span>
                {part1}
                <span style={{ backgroundColor: "yellow" }}>{part2}</span>
              </span>
            );

            return showToolTip ? (
              <div style={{ display: "flex", gap: "5px" }}>
                {part1}
                <Tooltip
                  trigger="click"
                  title={tooltipContent}
                  overlayInnerStyle={{ color: "black" }}
                  color="white"
                  placement="right"
                >
                  <div style={{ color: "#aeb2b8", cursor: "pointer" }}>
                    show all...
                  </div>
                </Tooltip>
              </div>
            ) : (
              value
            );
          },
        },
        { headerName: "Ip Address", field: "ipv4" },
        {
          headerName: "Device",
          field: "device",
        },
      ];
}

export default logsColumnDefs;

import React, { useState, useEffect } from "react";
import "./UsersToChat.scss";
import { Input, Avatar, message, Popover, Divider, Badge } from "antd";
import {
  EmailIcon,
  PinnedIcon,
  SearchIcon,
  ThreeVerticalDotsIcon,
} from "../../../assets/icons";
import { getInitials } from "../../../../../utils";
import { dayjsNY } from "../../../../DateComponents/contants/DayjsNY";
const UsersToChat = (props) => {
  const {
    users = [],
    setSelectedUser = () => {},
    messageList,
    cognitoUserId,
  } = props;
  const [usersToChat, setUsersToChat] = useState(users);
  const threeDotsActions = (user) => [
    {
      label: "Mark as Read",
      field: "markAsRead",
      action: () => {},
    },
    {
      label: "Pin",
      field: "pin",
      action: () => {},
    },
    {
      label: "Hide",
      field: "hide",
      action: () => {},
    },
    {
      label: "Delete",
      field: "delete",
      action: () => {},
    },
  ];

  const nonExecutives = usersToChat;

  const executives = [];

  const sortUsers = (users = []) => {
    let toReturn = [];
    const pinned = users.filter((user) => user.pinned);
    const unread = users.filter((user) => user.unread);
    const rest = users.filter((user) => !user.pinned && !user.unread);
    toReturn = [...pinned, ...unread, ...rest];
    return toReturn;
  };

  const searchUsers = (searchValue) => {
    if (!searchValue) {
      setUsersToChat(users);
    } else {
      const filteredUsers = users.filter((user) =>
        user.nameOfUser.toLowerCase().includes(searchValue.toLowerCase())
      );
      setUsersToChat(filteredUsers);
    }
  };

  const roomId = (userCognitoId) => {
    if (!cognitoUserId || !userCognitoId) {
      console.warn("Missing user IDs to generate roomId");
      return null;
    }

    const sortedUuids = [cognitoUserId, userCognitoId].sort();
    return `${sortedUuids[0]}_${sortedUuids[1]}`;
  };

  const lastMessage = (userCognitoId) => {
    const messages = messageList[roomId(userCognitoId)] || [];
    const sortedMessages = [...messages].sort((a, b) => b?.time - a?.time);
    return sortedMessages.length > 0
      ? sortedMessages?.[0]
      : { message: "No messages yet...", time: "" };
  };

  return (
    <div className="usersToChat">
      <div className="usersToChatContent">
        <Input
          placeholder="Search here..."
          prefix={<SearchIcon />}
          className="searchUserInput"
          variant="borderless"
          onChange={(e) => searchUsers(e.target.value)}
        />
      </div>
      <div className="allUsers">
        {nonExecutives.map((user) => (
          <div
            className={`usersData ${user.unread ? "unread" : ""}`}
            key={user.cognitoUserId}
            onClick={() => {
              setSelectedUser(user);
            }}
          >
            <div className="userContent">
              {user.pinned && <PinnedIcon width={8} height={13} />}
              <Badge dot={true} color="#00A464" className="badgeAvatar">
                <Avatar shape="circle" className="avatarInitials">
                  {getInitials(user.nameOfUser)}
                </Avatar>
              </Badge>

              <div className={`userInfo ${user.unread ? "unread" : ""}`}>
                <div className="messageUserName">
                  <span>{user.nameOfUser}</span>
                  {user.unread && <span>(6)</span>}
                  {user.unread && <EmailIcon width={13} height={10} />}
                </div>
                <div className="bottomContent">
                  <span
                    className="lastMessage"
                    style={{ width: user.pinned ? 250 : 260 }}
                  >
                    {lastMessage(user.cognitoUserId).message}
                  </span>
                  <span className="lastMessageDate">
                    {!!lastMessage(user.cognitoUserId).time
                      ? dayjsNY(lastMessage(user.cognitoUserId).time).format(
                          "HH:mm"
                        )
                      : ""}
                  </span>
                </div>
              </div>
            </div>
            <Popover
              trigger="click"
              placement="bottom"
              overlayClassName="threeDotsPopoverChat"
              content={
                <div className="threeDotsPopoverChatContent">
                  {threeDotsActions(user).map((action) => {
                    return (
                      <div
                        className={`threeDotsPopoverChatItem ${
                          action.field === "delete" ? "delete" : ""
                        }`}
                        key={action.field}
                        onClick={action.action}
                      >
                        <span>{action.label}</span>
                      </div>
                    );
                  })}
                </div>
              }
            >
              <ThreeVerticalDotsIcon onClick={(e) => e.stopPropagation()} />
            </Popover>
          </div>
        ))}

        {executives.length > 0 && (
          <>
            <div className="messagesFromLastWeek">
              <hr className="line left" />
              <span>Messages from last week</span>
              <hr className="line right" />
            </div>
            {executives.map((user) => (
              <div className="usersData" key={user.cognitoUserId}>
                <div className="userContent">
                  <Avatar shape="circle" className="avatarInitials">
                    {getInitials(user.nameOfUser)}
                  </Avatar>
                  <div className="userInfo">
                    <span>{user.nameOfUser}</span>
                    <span>{user.departmentName}</span>
                  </div>
                </div>
                <ThreeVerticalDotsIcon />
              </div>
            ))}
          </>
        )}
      </div>
    </div>
  );
};

export default UsersToChat;

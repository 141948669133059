import { useState } from "react";
import { PdfIcon } from "../../SidebarPages/Documentation/View/assets";
import MondayButton from "../MondayButton/MondayButton";
import { XIcon } from "../../SidebarPages/Communication/assets";
import { TickIcon } from "../../InternalChat/assets/icons";
import CustomTransfer from "../CustomTransfer/CustomTransfer";
import { Modal } from "antd";
import { recordInfoToPdf } from "../../../helpers/pdfHelpers";

const RecordInfoToPdf = ({ title = "", data = {} }) => {
  const [open, setOpen] = useState(false);
  const [selectedKeys, setSelectedKeys] = useState(Object.keys(data));

  const onClose = () => {
    setSelectedKeys(Object.keys(data));
    setOpen(false);
  };

  return (
    <>
      <MondayButton
        className="mondayButtonBlue"
        Icon={<PdfIcon />}
        onClick={() => setOpen(true)}
      />

      <Modal
        title={title}
        open={open}
        centered
        closeIcon={<XIcon />}
        onCancel={() => onClose()}
        footer={
          <div style={{ display: "flex", justifyContent: "space-between" }}>
            <MondayButton
              className="mondayButtonRed"
              Icon={<XIcon />}
              onClick={() => onClose()}
            >
              Cancel
            </MondayButton>
            <MondayButton
              Icon={<TickIcon />}
              onClick={() =>
                recordInfoToPdf({
                  title,
                  data: selectedKeys.reduce(
                    (acc, key) => ({ ...acc, [key]: data?.[key] || "-||-" }),
                    {}
                  ),
                }).then(() => onClose())
              }
            >
              Convert PDF
            </MondayButton>
          </div>
        }
      >
        <div style={{ width: "100%", height: "80vh" }}>
          <CustomTransfer
            {...{
              redSectionTitle: "Keys to hide from pdf",
              greenSectionTitle: "Keys to show in pdf",
              dataSource: Object.keys(data).map((key) => ({
                key,
                title: key,
              })),
              value: selectedKeys,
              onChange: (nonHiddenIds) => setSelectedKeys(nonHiddenIds),
            }}
          />
        </div>
      </Modal>
    </>
  );
};

export default RecordInfoToPdf;

import moment from "moment";

export function formatCommunicationTimestamp(createdAt) {
  const isToday = moment(createdAt).isSame(moment(), "day");
  const isThisYear = moment(createdAt).isSame(moment(), "year");

  if (isToday) {
    return moment(createdAt).format("h:mm A");
  } else if (isThisYear) {
    return moment(createdAt).format("MMMM D HH:mm A");
  } else {
    return moment(createdAt).format("MMMM D, YYYY HH:mm A");
  }
}

import _ from "lodash";
import moment from "moment-timezone";
import { dayjsNY } from "../../../../../DateComponents/contants/DayjsNY";
import dayjs from "dayjs";
import { parseInTz } from "../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { logicChangeCrews } from "../../SchedulingFirstPage/helperData";
export const createCrewsArray = (crewsObject) => {
  let selectedCrews = [];
  for (const foreman of Object.values(crewsObject || {})) {
    selectedCrews.push({
      crewId: foreman?.crewId,
      crewName: foreman?.crewName,
      crewPosition: foreman?.crewPosition,
      startTime: !!foreman?.startTime ? dayjs(foreman?.startTime) : "",
      endTime: !!foreman?.endTime ? dayjs(foreman?.endTime) : "",
      crewStatus: foreman?.crewStatus || "",
    });
    for (const member of foreman?.members || []) {
      selectedCrews.push({
        crewId: member?.crewId,
        crewName: member?.crewName,
        crewPosition: foreman?.crewPosition,
        startTime: !!member?.startTime ? dayjs(member?.startTime) : "",
        endTime: !!member?.endTime ? dayjs(member?.endTime) : "",
        crewStatus: member?.crewStatus || "",
      });
    }
  }
  return selectedCrews;
};

export const createCrewsWithHierarchy = (
  crewsFromDatabase,
  crewActivities,
  scheduleCrews,
  schedules,
  listOfTeams,
  scheduleDay,
  createdAt,
  newCrewsUpdate
) => {
  const crewsAsArray = [];
  let currentCrews =
    (newCrewsUpdate ? scheduleCrews : scheduleDay?.crews) || [];
  const crews = _.cloneDeep(crewsFromDatabase);

  for (const crew of crews || []) {
    if (!!crew?.foreman || crew?.crewPosition === "Supervisor") {
      let membersWithObjects = [];
      const crewTeam = listOfTeams?.find(
        (t) => t?.crewForeman?.crewId === crew?.crewId
      );
      let crewMember = crewTeam?.crewMembers || [];

      for (const el of crewMember || []) {
        if (el) {
          setCrewObjectData({
            crewTeam,
            crew: el,
            crewActivities,
            schedules,
            currentCrews,
            scheduleDay,
            createdAt,
            newCrewsUpdate,
          });
          membersWithObjects.push(el);
        }
      }

      crew.dynamicSelection =
        typeof crew?.dynamicSelection === "boolean"
          ? crew?.dynamicSelection
          : true;
      crew.members = membersWithObjects;
      crew.open = membersWithObjects?.some((m) => m?.selected);
      crew.crewTeamName = crewTeam?.crewTeamName || "";

      setCrewObjectData({
        crew,
        crewActivities,
        schedules,
        currentCrews,
        scheduleDay,
        createdAt,
        newCrewsUpdate,
      });

      crewsAsArray.push(crew);
    }
  }

  crewsAsArray.sort(
    (a, b) =>
      (b.selected === true || b?.members?.some((mem) => mem?.selected)
        ? 1
        : 0) -
      (a.selected === true || a?.members?.some((mem) => mem?.selected) ? 1 : 0)
  );

  const crewsAsObject = {};
  for (const crew of crewsAsArray) {
    crewsAsObject[crew.crewId] = crew;
  }

  return crewsAsObject;
};

export const setCrewObjectData = ({
  crewTeam,
  crew,
  crewActivities,
  schedules,
  currentCrews,
  scheduleDay,
  createdAt,
  newCrewsUpdate,
}) => {
  const exists =
    Array.isArray(currentCrews) && currentCrews?.length > 0
      ? !newCrewsUpdate
        ? scheduleDay?.crews?.find(({ crewId = "" }) => crewId === crew?.crewId)
        : currentCrews
            ?.find(({ crewId = "" }) => crewId === crew?.crewId)
            ?.days?.find((d) => d?.dayId === scheduleDay?.id)
      : {};

  crew.startTime =
    !!exists && !!exists?.startTime
      ? parseInTz(exists?.startTime).toISOString()
      : "";

  crew.endTime =
    !!exists && !!exists?.endTime
      ? parseInTz(exists?.endTime).toISOString()
      : "";

  crew.activities = filterActivitiesByCrew(crewActivities, crew);
  crew.selected = Object.keys(exists || {})?.length > 0;

  crew.crewTeamName = crewTeam?.crewTeamName || "";

  crew.workedHere =
    crew?.activities?.filter((ac) =>
      schedules?.find((sch) => sch?.scheduleId === ac?.scheduleId)
    ).length > 0;
};
export const filterActivitiesByCrew = (crewActivities, crew) =>
  crewActivities?.filter((c) => c?.crewId === crew?.crewId);
export const isCrewAvailableInActivity = (
  startDate,
  endDate,
  thisCrewActivities
) => {
  for (const thisCrewActivity of thisCrewActivities) {
    if (
      areIntervalsOverlapping(
        startDate,
        endDate,
        new Date(thisCrewActivity.startDate).getTime(),
        new Date(thisCrewActivity.endDate).getTime()
      )
    )
      return false;
  }
  return true;
};
/**
 * We are supposing that startTime is a smaller number than endTime
 * */
export const areIntervalsOverlapping = (
  startTime1,
  endTime1,
  startTime2,
  endTime2
) => {
  if (endTime2 < startTime1) return false;
  if (endTime1 < startTime2) return false;
  return true;
};
export const isRouteAvailableInDispatches = (route, fleetDispatching) => {
  for (const fleetDispatch of fleetDispatching) {
    for (const fleetDispatchRoute of fleetDispatch.routes) {
      if (
        areIntervalsOverlapping(
          route.departAt,
          route.timeExit,
          fleetDispatchRoute.departAt,
          fleetDispatchRoute.timeExit
        )
      )
        return false;
    }
  }
  return true;
};
/*
crewActivity : []
activityId: "13cb1b00-069e-11ec-b88c-73ee863a7597"
createdAt: 1630003654832
createdBy: {name: 'sdgf', id: '32423'}
crewId: "48235188-b737-4771-a29f-55b8060db674"
endDate: "2021-08-27T20:00:00.040Z"
lastModifiedBy: ""
notes: ""
scheduleId: "13b485c0-069e-11ec-b88c-73ee863a7597"
startDate: "2021-08-27T11:00:00.040Z"
* */
/*
fleet
{
    "fleetId": "029f3eb0-365a-11ec-9512-a3695fa08893",
    "driverId": "0eb84360-ca0b-11eb-909b-9bb945f69803",
    "fleetName": "Alban",
    "driverName": "Jana Hodges Baldwin",
    "dispatchDate": 1635480000000,
    "dispatchedBy": {
        "name": "Jon Shkreli"
    },
    "routes": [
        {
            "pickUpLocation": "2899-2701, Arthur Kill Rd, New York, NY, 10309, USA",
            "pickUpCoordinates": {
                "lat": 40.547668,
                "lng": -74.2270585
            },
            "dropOffLocation": "84-04 Queens Boulevard, Queens, NY, USA",
            "dropOffCoordinates": {
                "lat": 40.73665380000001,
                "lng": -73.87925729999999
            },
            "departAt": 1635488514000,
            "arriveBy": 1635490800000,
            "timeScheduled": "00:00",
            "timeExit": 1635490800000,
            "cargo": "Loaded",
            "dispatchNotes": ""
        },
        {
            "pickUpLocation": "84-04 Queens Boulevard, Queens, NY, USA",
            "pickUpCoordinates": {
                "lat": 40.73665380000001,
                "lng": -73.87925729999999
            },
            "dropOffLocation": "2899-2701, Arthur Kill Rd, New York, NY, 10309, USA",
            "dropOffCoordinates": {
                "lat": 40.547668,
                "lng": -74.2270585
            },
            "departAt": 1635516000000,
            "arriveBy": 1635519929000,
            "timeScheduled": "00:00",
            "timeExit": 1635519929000,
            "cargo": "Loaded",
            "dispatchNotes": ""
        }
    ]
}
fleetDispatching
{
    "dispatchedBy": "Jon Shkreli",
    "userId": "16d08e02a73ab9d99f7160dc07ffd50c9acd1b275899078c58a144cd7f70788bef9dd8409b2e3f1c875bfd9c30cdd59a",
    "dispatchDate": 1635480000000,
    "dispatchId": "577ea5a0-3728-11ec-b1fb-81da7741da50",
    "createdAt": 1635340694778,
    "driverName": "John Doe",
    "routes": [
        {
            "dropOffLocation": "235 West 48th Street, New York, NY, USA",
            "departAt": 1635481333000,
            "arriveBy": 1635483600000,
            "dropOffCoordinates": {
                "lng": -73.98617689999999,
                "lat": 40.7609184
            },
            "timeScheduled": "00:00",
            "pickUpCoordinates": {
                "lng": -74.2270585,
                "lat": 40.547668
            },
            "dispatchNotes": "",
            "timeExit": 1635483600000,
            "cargo": "Loaded",
            "pickUpLocation": "2899-2701, Arthur Kill Rd, New York, NY, 10309, USA"
        },
        {
            "dropOffLocation": "2899-2701, Arthur Kill Rd, New York, NY, 10309, USA",
            "departAt": 1635516000000,
            "arriveBy": 1635518701000,
            "dropOffCoordinates": {
                "lng": -74.2270585,
                "lat": 40.547668
            },
            "timeScheduled": "00:00",
            "pickUpCoordinates": {
                "lng": -73.98617689999999,
                "lat": 40.7609184
            },
            "dispatchNotes": "",
            "timeExit": 1635518701000,
            "cargo": "Loaded",
            "pickUpLocation": "235 West 48th Street, New York, NY, USA"
        }
    ],
    "fleetName": "Alban",
    "driverId": "369bbb50-d2a3-11eb-a7fc-e7c652bae7ce",
    "fleetId": "029f3eb0-365a-11ec-9512-a3695fa08893"
}
* */

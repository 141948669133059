import React, { useState, useCallback, useEffect, useMemo } from "react";
import {
  Button,
  Dropdown,
  Menu,
  Modal,
  Select,
  Switch,
  Tooltip,
  Cascader,
  message,
  Tour,
} from "antd";
import { UndoOutlined, RedoOutlined } from "@ant-design/icons";
import { exportDOCX, exportPDF, exportXLSX } from "../../tools/exporters";
import { gMapsAPI } from "../../../../../../helpers/constants/forProject";
import { options } from "./options";
import "./Header.scss";
import { EstimationNoteModal } from "./EstimationNoteModal/EstimationNoteModal";
import { formatCurrency, getLatLngByAddress } from "../../../../utils";
import { LogsIcon } from "../../../../DynamicView/src";
import { fetchData } from "../../../../Fleet/utils";
import { useProgramFields } from "../../../../../../hooks";
import store from "../../../../../../store";
import {
  MondayButton,
  MultiLevelTreeLogs,
  WithTooltip,
} from "../../../../../commonComponents/index";
import { RefreshIcon } from "../../../../Communication/assets/index";
import { SaveIcon, SwitchSaveIcon } from "../../../../BasePage/src/index";
import {
  CheckIconModal,
  DeleteIcon,
  ExcelIcon,
  ExcelIconWhite,
  GoogleMapsIcon,
  HelpTourIcon,
  TaxIcon,
  WordDocIcon,
  WordIconWhite,
} from "../../../../../../assets/index";
import { useSelector } from "react-redux";
import {
  servicePriceTakeOffCalculator,
  takeOffEstimationPrice,
} from "../ServiceHeader/ServicePrices/utils";
import { useRedux } from "../../../../hooks/useRedux";
import { forceToNumber } from "../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { useEstVersionsContext } from "../../../components/EstVersionsTab/context";
import PreviewTakeOff from "../previewTakeOff/PreviewTakeOff";
import { Buffer } from "buffer";
import { retrieveTakeOffDOCX } from "../../tools/exporters/retrieveDOCX";
const { Option } = Select;
import FileSaver from "file-saver";
import PreviewEngTakeOff from "../previewTakeOff/PreviewEngTakeOff";
import { useMultipleRefs } from "../../../../../../hooks/useMultipleRefs";
import { useModalOnce } from "../../../../../../hooks/useModalOnce";
import useTakeOffAccessRights, {
  ENG_RIGHTS_TAKEOFF,
} from "../../tools/controllers/useTakeOffAccessRights";
import MoreOptions from "./MoreOptions/MoreOptions";
import WordDocxFactory from "./WordFactory/WordDocxFactory";
import { checkIfServiceIsHoist } from "../../models/Service";
import { PlayVideoTutorial } from "../../../../../commonComponents/CustomModalHeader/CustomModalHeader";
import { VideoTutorialIcon } from "../../../../../Header/components/ModalVideoTutorials/assets/svg";
import { useUndoRedoState } from "../../../../../../hooks/useUndoRedoState";
import { getElevationAddonsPrice } from "../ServiceHeader/ServicePrices/ServicePrices";
import LastChange from "./ImportantUpdates/LastChange";
import { fetchAllData } from "../../../../../../utils";
import { removeKeys } from "../../../../../../utils/removeKeysFromObject";
import { API } from "aws-amplify";
import Swal from "sweetalert2";
import { CompareIcon } from "../../../components/EstVersionsTab/assets";
import { CompareTakeOffVersions } from "../../../components/EstVersionsTab/components/CompareTakeOffVersions";

function EstimationSecondaryHeader({
  projectAddress,
  // estimation,
  versionId,
  // gridData,
  serviceDefs,
  accountName,
  estDescription,
  accessRights,
  ServiceMenuContainerShow,
  stateSetter,
  initGrid,
  loading,
  canUndo,
  undoClickHandler,
  canRedo,
  redoClickHandler,
  AutoSave,
  // isWritable,
  handleSave,
  handleSaveProject,
  project_OR_opportunity = "",
  saveEstimationDescription,
  createdBy,
  agGridTheme,
  myMappedData,
}) {
  const [
    totalRef,
    refreshRef,
    descriptionRef,
    logRef,
    undoRef,
    redoRef,
    autoSaveRef,
    saveRef,
    saveProjectRef,
    previewRef,
    mapRef,
    excelRef,
    wordRef,
  ] = useMultipleRefs(13);
  const [editLogsModalVisibility, setEditLogsVisibility] = useState(false);
  const { userConfiguration } = useSelector((state) => state.userConfig);
  const { programFields } = useSelector((state) => state.programFields);
  const [tourOpen, setTourOpen] = useModalOnce("welcomeToTakeOff");
  const steps =
    programFields
      ?.find(({ fieldName }) => fieldName === "Tutorials Steps")
      ?.fieldOptions?.find(({ categoryName }) => categoryName === "takeOff")
      ?.steps || [];
  const {
    canExport = false,
    canSaveInProject = false,
    canViewPrice = false,
  } = useTakeOffAccessRights("Take-Off");
  const [gridData] = useRedux("takeOffTableData");
  const [isWritable] = useRedux("estimationsIsWritable");
  const [editLogs, setEditLogs] = useState([]);
  const [takeOffFullPrice, setTakeOffFullPrice] = useRedux(
    "takeOffFullPrice",
    0,
    false
  );
  // const [printableTakeOffHtml, setPrintableArea] = useRedux(
  //   "printableTakeOffHtml",
  //   null,
  //   false
  // );
  const {
    estimation,
    opportunity,
    updateEstimation,
    isEditable,
    updateOpportunity,
    rentalsAssociated,
  } = useEstVersionsContext();
  const theme = agGridTheme?.includes("dark") ? "dark" : "light";

  //   const [base64string, setBase64string] = useState([]);
  const [base64, proposedTypeOfWork, takeOffExport] = useSelector((state) => [
    state.base64,
    state.proposedTypeOfWork.proposedTypeOfWork,
    state.programFields.programFields.find(
      ({ fieldName }) => fieldName === "TakeOff Export"
    ).fieldOptions,
  ]);
  const exportAccess = accessRights?.children?.find(
    (access) => access.title === "Export"
  )?.write;
  const [videoTutorialLink, setVideoTutorialLink] = useState(null);
  const [showVideoTutorial, setShowVideoTutorial] = useState(false);
  const [compareModalVisible, setCompareModalVisible] = useState(false);

  const links =
    programFields
      ?.find((item) => item.fieldName === "Portal Video Tutorials")
      ?.fieldOptions.find((item) => item?.categoryName === "Project Management")
      ?.subCategories[1]?.videos?.filter((item, i) => i >= 0 && i <= 3)
      ?.map((item) => item) ?? [];

  const maskStyle = {
    color: "#2a2b3a71",
  };

  const selectedServiceColor = useMemo(() => {
    let visibleService = gridData?.find((service) => service?.visible);
    let color =
      myMappedData?.find(
        (e) =>
          e?.serviceId?.toString() === visibleService?.serviceId?.toString()
      )?.borderColor || "#116d6e";
    return color;
  }, [myMappedData, gridData]);

  const onEngMode = () => {
    let engRights = ENG_RIGHTS_TAKEOFF;
    if (localStorage.getItem("takeOffAccessRightsTemp")) {
      localStorage.removeItem("takeOffAccessRightsTemp");
      window.location.reload();
    } else {
      localStorage.setItem(
        "takeOffAccessRightsTemp",
        JSON.stringify(engRights)
      );
      window.location.reload();
    }
  };

  function stepsMapHelper(title) {
    const stepsMap = {
      "View All Amounts": totalRef.current,
      Refresh: refreshRef.current,
      "Add Description": descriptionRef.current,
      "Edit Logs": logRef.current,
      Undo: undoRef.current,
      Redo: redoRef.current,
      "Auto Save": autoSaveRef.current,
      "Preview Take Off": previewRef.current,
      "Show on Google Maps": mapRef.current,
      "Export to Excel": excelRef.current,
      "Quick Save": saveRef.current,
      "Save In Project": saveProjectRef.current,
      "Export to Word": wordRef.current,
    };
    return stepsMap[title] || null;
  }
  function mapRefs(dbSteps = []) {
    let newSteps = dbSteps
      ?.map((step) => {
        if (step?.title === "Welcome to Take Off") {
          return {
            ...step,
            className: `custom-tour-${theme}`,
            mask: {
              ...maskStyle,
            },
            cover: (
              <img src="https://gw.alipayobjects.com/mdn/rms_08e378/afts/img/A*zx7LTI_ECSAAAAAAAAAAAABkARQnAQ" />
            ),
            placement: "center",
          };
        }
        return {
          ...step,
          target: () => stepsMapHelper(step?.title),
          className: `custom-tour-${theme}`,
          mask: {
            ...maskStyle,
          },
        };
      })
      .filter((step) => {
        switch (step?.title) {
          case "View All Amounts":
            return canViewPrice;
          case "Save In Project":
            return canSaveInProject;
          default:
            return true;
        }
      });
    return newSteps;
  }
  const dbSteps = mapRefs(steps);
  const tutorialStep = {
    title: (
      <div style={{ width: 280 }}>
        Watch Take-Off Tutorials or tap "Next" to continue a quick tour
      </div>
    ),
    description: (
      <div style={{ display: "flex", gap: 5, flexDirection: "column" }}>
        {links.map((link) => (
          <MondayButton
            {...{
              className: "mondayButtonBlue guideButton",
              onClick: () => {
                setShowVideoTutorial(true);
                setTourOpen(false);
                setVideoTutorialLink(link.videoLink);
              },
              style: {
                display: "flex",
                justifyContent: " center",
              },
              Icon: <VideoTutorialIcon />,
            }}
          >
            {link.videoName}
          </MondayButton>
        ))}
      </div>
    ),
    className: `custom-tour-${theme}`,
    placement: "center",
    mask: {
      ...maskStyle,
    },
  };
  const tourSteps = [dbSteps[0], tutorialStep, ...dbSteps.slice(1)];
  console.log("this is tourSteps", tourSteps);

  // const takeOffsPrices = useMemo(() => {
  //   return takeOffFullPrice;
  // }, [takeOffFullPrice]);

  console.log("this is takeOffFullPrice", takeOffFullPrice);

  const takeOffsPrices = useCallback(() => {
    const RESULT = {
      TOTAL: 0,
      TAXES: 0,
    };
    if (!canViewPrice) return RESULT;
    RESULT.TOTAL =
      gridData?.reduce((a, b) => {
        return a + (b?.additionalRentalTerms?.newPrice || 0);
      }, 0) || 0;

    try {
      if (Array.isArray(gridData)) {
        let temp =
          gridData
            ?.flatMap((service) => {
              const {
                serviceOptions = [],
                serviceAddons = [],
                label = "",
              } = service;
              console.log("this is service", serviceAddons);
              if (service?.isScope) {
                return [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ items = [] }) =>
                      items?.flatMap((el) => {
                        if (!!el?.price || !!el?.Price) {
                          let taxRate = service?.isTaxable
                            ? service?.taxRate || 0
                            : 0;
                          const res = Number(+el?.price || +el?.Price || 0);
                          const tax = Number(
                            Number(+el?.price || +el?.Price || 0) * taxRate
                          );
                          RESULT.TAXES += forceToNumber(tax);
                          console.log("this is res", res);
                          // Number(+el?.price || +el?.Price || 0) *
                          // (1 + taxRate / 100);
                          return Number(res + tax);
                        }
                      }) || 0
                  ) ?? 0),
                  ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
                    0),
                ];
              } else if (!checkIfServiceIsHoist(service)) {
                console.log("testtt", [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ items = [] }) =>
                      items
                        ?.filter(({ approved = false }) => !!approved)
                        ?.flatMap(({ totalPrice = 0 }) => +totalPrice) || 0
                  ) ?? 0),
                  ...(serviceAddons?.map(({ totalPrice = 0 }) =>
                    forceToNumber(totalPrice)
                  ) ?? 0),
                ]);
                return [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ items = [] }) =>
                      items
                        ?.filter(({ approved = false }) => !!approved)
                        ?.flatMap(({ totalPrice = 0 }) => +totalPrice) || 0
                  ) ?? 0),
                  ...(serviceAddons?.map(({ totalPrice = 0 }) =>
                    forceToNumber(totalPrice)
                  ) ?? 0),
                ];
              } else {
                console.log("this is hoist", service);
                return [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ totalPrice = 0 }) => +totalPrice
                  ) || 0),
                  ...(serviceAddons?.map(({ price = 0 }) => +price) || 0),
                  ...(serviceAddons?.map(({ totalPrice = 0 }) =>
                    forceToNumber(+totalPrice)
                  ) || 0),
                ];
              }
            })
            ?.filter(Boolean)
            ?.reduce((acc, curr) => (acc += curr), 0) ?? 0;
        console.log("test temp", temp);
        let elevationAddonsTax = 0;
        let tempTax =
          gridData
            ?.flatMap((service) => {
              const {
                serviceOptions = [],
                serviceAddons = [],
                label = "",
              } = service;
              if (service?.isScope) {
                return [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ items = [] }) =>
                      items?.flatMap((el) => {
                        if (!!el?.price || !!el?.Price) {
                          let taxRate = service?.isTaxable
                            ? service?.taxRate || 0
                            : 0;
                          const res =
                            (Number(+el?.price || +el?.Price || 0) * taxRate) /
                            100;
                          return res;
                        }
                      }) || 0
                  ) ?? 0),
                  // ,
                  // ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
                  //   0),
                ];
              } else if (!checkIfServiceIsHoist(service)) {
                let price = [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ items = [] }) =>
                      items
                        ?.filter(({ approved = false }) => !!approved)
                        ?.flatMap(({ taxAmount = 0 }) => +taxAmount) || 0
                  ) ?? 0),
                  ...(serviceAddons?.map(({ taxAmount = 0 }) => +taxAmount) ??
                    0),
                ];
                serviceOptions?.[0]?.map((e) => {
                  const { elevationAddonPrice = 0, elevationAddonTax = 0 } =
                    getElevationAddonsPrice(e);
                  temp += forceToNumber(elevationAddonPrice);
                  elevationAddonsTax += forceToNumber(elevationAddonTax);
                });
                return price;
              } else {
                console.log("this is hoist", service);
                return [
                  ...(serviceOptions?.[0]?.flatMap?.(
                    ({ taxAmount = 0 }) => +taxAmount
                  ) || 0),
                  ...(serviceAddons?.map(({ taxAmount = 0 }) => +taxAmount) ||
                    0),
                ];
              }
            })
            ?.filter(Boolean)
            ?.reduce((acc, curr) => (acc += curr), 0) ?? 0;
        console.log("take off tax", tempTax);
        if (!isNaN(temp)) {
          RESULT.TOTAL += temp;
        }
        if (!isNaN(tempTax)) {
          RESULT.TAXES += tempTax;
          RESULT.TAXES += elevationAddonsTax;
        }
        console.log("MY RESULT", RESULT);
        return RESULT;
      } else return RESULT;
    } catch (error) {
      console.log("error", error);
      return RESULT;
    }
  }, [gridData, canViewPrice]);

  const takeOffTotalPrice = useMemo(() => {
    if (!canViewPrice)
      return {
        total: 0,
        taxes: 0,
      };
    const totalities = takeOffsPrices(gridData);

    // let result = 0;
    // let taxes = 0;
    // result =
    //   gridData?.reduce((a, b) => {
    //     return a + (b?.additionalRentalTerms?.newPrice || 0);
    //   }, 0) || 0;

    // try {
    //   if (Array.isArray(gridData)) {
    //     let temp =
    //       gridData
    //         ?.flatMap((service) => {
    //           const {
    //             serviceOptions = [],
    //             serviceAddons = [],
    //             label = "",
    //           } = service;
    //           if (service?.isScope) {
    //             return [
    //               ...(serviceOptions?.[0]?.flatMap?.(
    //                 ({ items = [] }) =>
    //                   items?.flatMap((el) => {
    //                     if (!!el?.price || !!el?.Price) {
    //                       let taxRate = service?.isTaxable
    //                         ? service?.taxRate || 0
    //                         : 0;
    //                       const res =
    //                         Number(+el?.price || +el?.Price || 0) *
    //                         (1 + taxRate / 100);
    //                       return res;
    //                     }
    //                   }) || 0
    //               ) ?? 0),
    //               ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
    //                 0),
    //             ];
    //           } else if (label !== "Hoist") {
    //             return [
    //               ...(serviceOptions?.[0]?.flatMap?.(
    //                 ({ items = [] }) =>
    //                   items
    //                     ?.filter(({ approved = false }) => !!approved)
    //                     ?.flatMap(({ totalPrice = 0 }) => +totalPrice) || 0
    //               ) ?? 0),
    //               ...(serviceAddons?.map(({ totalPrice = 0 }) => +totalPrice) ??
    //                 0),
    //             ];
    //           } else {
    //             console.log("this is hoist", service);
    //             return [
    //               ...(serviceOptions?.[0]?.flatMap?.(
    //                 ({ totalPrice = 0 }) => +totalPrice
    //               ) || 0),
    //               ...(serviceAddons?.map(({ price = 0 }) => +price) || 0),
    //             ];
    //           }
    //         })
    //         ?.filter(Boolean)
    //         ?.reduce((acc, curr) => (acc += curr), 0) ?? 0;

    //     console.log("take off price", temp);
    //     if (!isNaN(temp)) {
    //       result += temp;
    //     }
    //     return { total: temp, taxes };
    //   } else return { total: temp, taxes };
    // } catch (error) {
    //   console.log("error", error);
    //   return { total: temp, taxes };
    // }
    return {
      total: totalities.TOTAL,
      taxes: totalities.TAXES,
    };
  }, [gridData, canViewPrice]);

  const showGMap = () => {
    getLatLngByAddress(projectAddress)
      .then((response) => {
        let { lat, lng } = response?.coordinates;
        window.open(
          `https://www.google.com/maps/search/?api=1&query=${lat},${lng}`,
          "_blank"
        );
      })
      .catch((error) => {
        console.error(error);
      });
  };

  const retrieveFromProject = async () => {
    const project = await getProject(estimation).then(async (re) => {
      let services = re?.project?.services || {};
      let curr = services?.[estimation?.estimationId];
      if (!!services && !!curr) {
        console.log("this is curr", curr);
        Swal.fire({
          title:
            "YOU ARE ABOUT TO OVERWRITE YOUR ESTIMATION WITH PROJECT DATA, THIS ACTION CANNOT BE UNDONE",
          showDenyButton: true,
          showCancelButton: true,
          confirmButtonText: "I understand, overwrite",
          denyButtonText: `Don't save`,
        }).then(async (result) => {
          if (result.isConfirmed) {
            await updateEstimation({
              versionServices: {
                ...estimation?.versionServices,
                [versionId]: {
                  ...(estimation?.versionServices?.[versionId] || {}),
                  services: curr,
                },
              },
            }).then(() => {
              Swal.fire("Saved!", "", "success");
              window.location.reload();
            });
          } else if (result.isDenied) {
            Swal.fire("Changes are not saved!", "", "info");
          }
        });
      }
    });
  };

  // console.log("canUndo", takeOffTotalPrice);

  const onExportHandle = (value) => {
    const base64string = base64?.base64 || [];
    // const { estimation, versionId } = this.props;
    const takeOffObject = {
      ...estimation?.versionServices?.[versionId],
      jobSiteAddress: estimation?.jobSiteAddress,
      logo: base64string?.find((item) => item.fileName === "CORE logo")?.base64,
      // estimation?.logo?.toLowerCase().includes("Central")
      //   ? base64string?.find((item) => item.fileName === "CENTRAL logo")?.base64
      //   : base64string?.find((item) => item.fileName === "CORE logo")?.base64,
    };
    if (value === "excel") {
      exportXLSX({
        takeOffObject,
        proposedTypeOfWork: proposedTypeOfWork,
        withPricing: true,
        takeOffColumns: takeOffExport,
      });
      // Modal.confirm({
      //   title: "Would you like to generate it with pricing or without pricing?",
      //   okText: "With pricing",
      //   cancelText: "Without pricing",
      //   onCancel: () => {
      //     console.log("export without pricing");
      //     exportXLSX({
      //       takeOffObject,
      //       proposedTypeOfWork,
      //       withPricing: false,
      //       takeOffColumns: takeOffExport,
      //     });
      //   },
      //   onOk: () => {
      //     console.log("export with pricing");
      //     exportXLSX({
      //       takeOffObject,
      //       proposedTypeOfWork: proposedTypeOfWork,
      //       withPricing: true,
      //       takeOffColumns: takeOffExport,
      //     });
      //   },
      // });
    } else if (value === "word") {
      Modal.confirm({
        title: "Would you like to generate it with notes or without notes?",
        okText: "",
        cancelText: "Without Notes",
        onCancel: () => {
          exportDOCX(
            gridData,
            projectAddress,
            serviceDefs,
            accountName,
            estimation?.estimationDescription,
            false
            // printableTakeOffHtml
          );
        },

        onOk: () => {
          exportDOCX(
            gridData,
            projectAddress,
            serviceDefs,
            accountName,
            estimation?.estimationDescription,
            true
            // printableTakeOffHtml
          );
        },
      });
    } else {
      exportPDF(gridData, projectAddress);
    }
  };

  const validateClearance = (clearance, callbackFunc) => {
    if (!clearance) {
      message.warning("You have no clearance for this action.");
    } else {
      callbackFunc();
    }
  };

  const openEditLogsHandler = () => {
    const hideLoading = message.loading(
      "Retrieving log data. Please wait...",
      0
    );
    fetchAllData({
      endpoint: "editLogs",
      resultPosition: "editLogs",
      resultId: "logId",
      otherStringParams: {
        getMaxLimit: "true",
        filters: JSON.stringify([
          {
            conditions: [
              {
                column: "recordId",
                value: versionId,
                formula: "is",
              },
            ],
          },
        ]),
      },
    })
      .then((result) => {
        if (canViewPrice) {
          setEditLogs(result);
        } else {
          const keysToRemove = [
            "taxRate",
            "price",
            "rent",
            "value",
            "tax",
            "ppu",
            "rent",
            "taxAmount",
            "totalPrice",
          ];

          const allLogs = result.map((logs) => ({
            ...logs,
            currentData: {
              ...logs.currentData,
              services: removeKeys(logs.currentData.services, keysToRemove),
            },
            previousData: {
              ...logs.previousData,
              services: removeKeys(logs.previousData.services, keysToRemove),
            },
          }));

          setEditLogs(allLogs);
        }
        setEditLogsVisibility(true);
        hideLoading();
      })
      .catch((err) => {
        console.log({ err });
        hideLoading();
        message.error(
          "Sorry, we couldn't retrieve the logs at the moment. Please try again later."
        );
      });
  };

  // useEffect(() => {
  //   let text = `You just made some new changes!`;
  //   let utterance = new SpeechSynthesisUtterance();
  //   utterance.text = text;
  //   utterance.lang = "en-US";
  //   utterance.voice = window.speechSynthesis.getVoices()[2];
  //   window.speechSynthesis.speak(utterance);
  // }, [gridData]);
  // Temporarily disabled
  // useEffect(() => {
  //   const handleUndoRedo = (event) => {
  //     if (event.ctrlKey && event.key === "z") {
  //       // Call your function here
  //       swal({
  //         icon: "info",
  //         title: "Confirm UNDO",
  //         text: "Are you sure you want to undo the last action?",
  //         buttons: ["Cancel", "Confirm"],
  //         dangerMode: true,
  //       }).then((value) => {
  //         console.log("undoval", value);
  //         if (value === true) {
  //           undoClickHandler();
  //           swal("Success", "Your last action was reverted. ", "success");
  //         }
  //       });
  //       // undoClickHandler();
  //     } else if (event.ctrlKey && event.key === "y") {
  //       // Call your function here
  //       swal({
  //         icon: "info",
  //         title: "Confirm REDO",
  //         text: "Are you sure you want to revert to the last action?",
  //         buttons: ["Cancel", "Confirm"],
  //         dangerMode: true,
  //       }).then((value) => {
  //         console.log("undoval", value);
  //         if (value === true) {
  //           redoClickHandler();
  //           swal("Success", "Your last action was reverted. ", "success");
  //         }
  //       });
  //     }
  //   };

  //   window.addEventListener("keydown", handleUndoRedo);

  //   return () => {
  //     window.removeEventListener("keydown", handleUndoRedo);
  //   };
  // }, []);
  return (
    <div
      className={`take-off-secondary-header__${
        agGridTheme?.includes("dark") ? "dark" : "light"
      }`}
      style={{
        boxShadow: `0px 0px 10px 3px ${selectedServiceColor}`,
        // animation: "slide 2s",
      }}
    >
      {/* <Tooltip
        title={
          ServiceMenuContainerShow
            ? "Hide Services Manager"
            : "Show Services Manger"
        }
        className="float-left"
      >
        <button
          className="service-menu-toggle"
          shape="circle"
          onClick={() => {
            stateSetter({
              ServiceMenuContainerShow: !ServiceMenuContainerShow,
            });
          }}
        >
          {ServiceMenuContainerShow ? (
            <span style={{ paddingLeft: "10px" }}>
              <ArrowBackIos />
            </span>
          ) : (
            <ArrowForwardIos />
          )}
        </button>
      </Tooltip> */}
      {/* <h1 className="float-left sansFont dataEntryTitle">
    {this.props.projectAddress}
  </h1> */}
      {canViewPrice && (
        <span
          className="dataEntryTitle"
          ref={totalRef}
          style={{
            // backgroundColor: "rgb(113, 207, 72,0.25)",
            // backgroundColor: "#C6EFCE",
            // textShadow: `-1px -1px 1px rgba(255,255,255,.1), 1px 1px 1px rgba(0,0,0,.5)`,
            padding: "0 10px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            borderRadius: 5,
            marginTop: "-3px",
            marginRight: "20px",
            gap: "5px",
            height: "32px",

            // color: "#00612E",
            // color: "#360103",
          }}
        >
          {canViewPrice ? (
            <>
              <WithTooltip
                tooltipCategory="Take Off"
                tooltipKey="takeOffFullPrice"
              >
                <strong style={{ fontSize: "25px" }}>
                  {formatCurrency(takeOffTotalPrice.total || 0)}
                </strong>
              </WithTooltip>

              <Tooltip
                title={`Tax Amount: ${formatCurrency(
                  takeOffTotalPrice.taxes || 0
                )}`}
              >
                <span
                  style={{
                    // background: theme === "dark" ? "#2a2b3a" : "",
                    color: "white",
                    width: "40px",
                    height: "28px",
                    borderRadius: "5px",
                    display: "flex",
                    fontSize: "15px",
                    marginTop: "1px",
                    justifyContent: "center",
                    alignItems: "center",
                    cursor: "pointer",
                    textAlign: "center",
                    lineHeight: "1.5",
                  }}
                  // onClick={() => toggleTax()}
                >
                  <TaxIcon />
                  {/* TAX */}
                </span>
              </Tooltip>
            </>
          ) : (
            <strong
              style={{
                fontSize: "15px",
                width: "100%",
              }}
            >
              $$$
            </strong>
          )}
        </span>
      )}
      {!canViewPrice && (
        <div
          style={{
            margin: "0 20px",
          }}
        >
          {/* <MondayButton hasIcon={false} className="mondayButtonGrey">
            Auto Save mode!
          </MondayButton> */}
          <LastChange />
        </div>
      )}
      <div className="take-off-secondary-header-btn-cont" ref={refreshRef}>
        <WithTooltip tooltipCategory="Take Off" tooltipKey="refreshPage">
          <button
            className="icon-btn-holder"
            // hasIcon={false}
            onClick={() => {
              stateSetter({ gridData: null });
              initGrid();
            }}
            disabled={loading}
          >
            <span
              style={{
                fill: "white",
                height: "100%",
                display: "flex",
                alignItems: "center",
              }}
            >
              <RefreshIcon />
            </span>
          </button>
        </WithTooltip>
      </div>
      <div className="take-off-secondary-header-btn-cont" ref={descriptionRef}>
        <WithTooltip tooltipCategory="Take Off" tooltipKey="description">
          <EstimationNoteModal
            className="float-right clearfix-primary-btn est-description-btn"
            modalClassName="warningModal-light est-note-modal"
            saveEstimationDescription={saveEstimationDescription}
            estDescription={estDescription}
            disabled={!isWritable}
          />
        </WithTooltip>
      </div>
      <div className="take-off-secondary-header-btn-cont" ref={logRef}>
        <WithTooltip tooltipCategory="Take Off" tooltipKey="showLogs">
          <button
            className="icon-btn-holder"
            onClick={openEditLogsHandler}
            disabled={loading}
          >
            <LogsIcon style={{ color: "white" }} />
          </button>
        </WithTooltip>
      </div>
      <div className="take-off-secondary-header-btn-cont" ref={undoRef}>
        <WithTooltip tooltipCategory="Take Off" tooltipKey="undo">
          <button
            className="icon-btn-holder"
            disabled={!canUndo}
            onClick={undoClickHandler}
          >
            <UndoOutlined style={{ fontSize: "20px" }} />
          </button>
        </WithTooltip>
      </div>
      <div className="take-off-secondary-header-btn-cont" ref={redoRef}>
        <WithTooltip tooltipCategory="Take Off" tooltipKey="redo">
          <button
            className="icon-btn-holder"
            disabled={!canRedo}
            onClick={redoClickHandler}
          >
            <RedoOutlined style={{ fontSize: "20px" }} />
          </button>
        </WithTooltip>
      </div>
      {/* <div className="float-right vDivider" /> */}
      {/* <MondayButton className="monday">AutoSave is on</MondayButton> */}
      {/* <Tooltip title={"Autosave is required for now!"}>
        <Switch
          className="takeoff-auto-save-switch"
          checked={AutoSave}
          ref={autoSaveRef}
          // onChange={(checked) => stateSetter({ AutoSave: checked })} //TODO: Disabled for now
        />
      </Tooltip>{" "} */}
      {/* <Tooltip title={"Redo"}>
        <MondayButton
          className="mondayButtonBlue"
          hasIcon={false}
          // disabled={!canRedo}
          onClick={onEngMode}
        >
          Engineering Mode
        </MondayButton>
      </Tooltip> */}
      {/* <div className="take-off-secondary-header-btn-cont" ref={saveRef}>
        <Tooltip
          title={
            isWritable
              ? "Save your work in estimation"
              : "Enable WRITE mode to save in estimation"
          }
        >
          <MondayButton
            Icon={<CheckIconModal />}
            className="mondayButtonBlue"
            onClick={() => {
              handleSave(true, true);
            }}
            loading={loading}
            disabled={!isWritable}
          >
            Quick Save
          </MondayButton>
        </Tooltip>
      </div>{" "} */}
      {/* <div className="take-off-secondary-header-btn-cont"> */}
      {/* <Tooltip
    title={
      this.props.project_OR_opportunity !== "project"
        ? "This is an opportunity. It has not been converted yet into a project."
        : this.props.isWritable
        ? "Save approved PLI in project"
        : "Enable WRITE mode to save in project"
    }
  > */}
      {/* <PreviewTakeOff /> */}
      {/* </div> */}
      {/* <Button
      type="primary"
      className="float-right clearfix-primary-btn"
      style={{ marginRight: 24 }}
      onClick={this.props.handleSaveProject}
      loading={this.props.loading}
      disabled={
        !this.props.isWritable ||
        this.props.project_OR_opportunity !== "project"
      }
    >
      Save In Project
    </Button> */}
      {/* </Tooltip> */}
      <div
        style={{
          // backgroundColor: "red",
          display: "flex",
          width: "100%",
          gap: "20px",
          justifyContent: "flex-end",
        }}
      >
        <WordDocxFactory
          {...{
            gridData,
            projectAddress,
            serviceDefs,
            accountName,
            versionId,
            wordRef,
          }}
        />{" "}
        <div ref={previewRef}>
          <PreviewEngTakeOff
            {...{
              gridData,
              projectAddress,
              serviceDefs,
              accountName,
              estimationDescription: estimation?.estimationDescription,
              isIt: false,
            }}
          />
        </div>
        {/* <div className="float-right vDivider" /> */}
        <WithTooltip tooltipCategory="Take Off" tooltipKey="viewMap">
          <button
            className="icon-btn-holder"
            ref={mapRef}
            onClick={() => showGMap()}
          >
            <GoogleMapsIcon style={{ transform: "scale(0.8)" }} />
          </button>
        </WithTooltip>
        {/* <div className="float-right vDivider" /> */}
        {canViewPrice && (
          <Tooltip
            title={`Export to Excel${
              !canExport ? " - You have no access." : ""
            }`}
          >
            <button
              className="icon-btn-holder"
              style={{
                backgroundColor: !canExport && "#fe4c4a",
              }}
              ref={excelRef}
              onClick={() => {
                validateClearance(canExport, () => onExportHandle("excel"));
              }}
            >
              <ExcelIconWhite />
            </button>
          </Tooltip>
        )}
        {/* <div className="float-right vDivider" /> */}
        {/* <Tooltip
          title={`Export to Word${!canExport ? " - You have no access." : ""}`}
        >
          <button
            className="icon-btn-holder"
            ref={wordRef}
            style={{
              backgroundColor: !canExport && "#fe4c4a",
            }}
            onClick={() => {
              validateClearance(canExport, onExportHandle("word"));
            }}
          >
            <WordIconWhite />
          </button>
        </Tooltip>{" "} */}
        {/* <div className="float-right vDivider" /> */}
        <WithTooltip tooltipCategory="Take Off" tooltipKey="showGuide">
          <button className="icon-btn-holder" onClick={() => setTourOpen(true)}>
            <HelpTourIcon />
          </button>
        </WithTooltip>{" "}
        <div className="trake-off-secondary-header-btn-cont">
          <Tooltip
            title={
              "Compare your current version with previous versions of this estimation"
            }
          >
            <div>
              {" "}
              <MondayButton
                {...{
                  Icon: <CompareIcon />,
                  className: "comparisonBtn",
                }}
                onClick={() => setCompareModalVisible(true)}
              >
                Compare Versions
              </MondayButton>
            </div>
          </Tooltip>
        </div>
        <div className="trake-off-secondary-header-btn-cont" ref={saveRef}>
          <Tooltip
            title={
              isWritable
                ? "Save your work in estimation"
                : "Enable WRITE mode to save in estimation"
            }
          >
            <div ref={saveRef}>
              <MondayButton
                Icon={<CheckIconModal />}
                className="mondayButtonBlue"
                onClick={() => {
                  handleSave(true, true);
                }}
                loading={loading}
                disabled={!isWritable}
              >
                Quick Save
              </MondayButton>
            </div>
          </Tooltip>
        </div>
        {canSaveInProject && (
          <div ref={saveProjectRef}>
            <MondayButton
              className={"mondayButtonRed"}
              hasIcon={false}
              onClick={retrieveFromProject}
              loading={loading}
              disabled={
                !isWritable ||
                project_OR_opportunity !== "project" ||
                !canSaveInProject
              }
              tooltipCategory="Take Off"
              tooltipKey="revertFromProject"
            >
              {canSaveInProject
                ? "Revert from Project"
                : "No Clearance to Save"}
            </MondayButton>{" "}
          </div>
        )}
        {canSaveInProject && (
          <div ref={saveProjectRef}>
            <MondayButton
              className={
                canSaveInProject ? "mondayButtonBlue" : "mondayButtonRed"
              }
              Icon={<CheckIconModal />}
              onClick={handleSaveProject}
              loading={loading}
              disabled={
                !isWritable ||
                project_OR_opportunity !== "project" ||
                !canSaveInProject
              }
              tooltipCategory="Take Off"
              tooltipKey="saveInProject"
            >
              {canSaveInProject ? "Save In Project" : "No Clearance to Save"}
            </MondayButton>{" "}
          </div>
        )}{" "}
        {/* <MoreOptions
          {...{
            canSaveInProject,
            isWritable,
            project_OR_opportunity,
            handleSaveProject,
            loading,
          }}
        /> */}
        <br />
        {/* </div>{" "} */}
        {/* <Dropdown
        className="show-on-dropdown"
        menu={() => (
          <Menu>
            <Menu.Item onClick={() => showGMap()}>Google Maps</Menu.Item>
            <Menu.Item> Digital Tax Map</Menu.Item>
          </Menu>
        )}
      >
        <Button className="float-right clearfix-secondary-btn">
          Show on...
        </Button>
      </Dropdown> */}
        {/* <Cascader
        options={options(exportAccess)}
        className="show-on-dropdown"
        style={{
          marginRight: 10,
          marginLeft: 20,
          height: "auto",
          background: "#1f212d",
          color: "#fff",
        }}
        dropdownClassName="deg-export-menu"
        // size="small"
        expandTrigger="click"
        placeholder="Export to..."
        onChange={onExportHandle}
      /> */}
        {/* <div className="float-right vDivider" /> */}
        {/* <Select
    className="float-right"
    style={{ marginRight: 24 }}
    onChange={this.setTheme}
    defaultValue={this.props.agGridTheme}
  >
    {themes.map((th) => (
      <Option key={th.value} value={th.value}>
        {th.label}
      </Option>
    ))}
  </Select> */}
        {editLogsModalVisibility && (
          <MultiLevelTreeLogs
            {...{
              visible: editLogsModalVisibility,
              setVisible: setEditLogsVisibility,
              logsData: editLogs || [],
              title: "Take Off Edit Logs",
            }}
          />
        )}
        {tourOpen && (
          <Tour
            open={tourOpen}
            onClose={() => setTourOpen(false)}
            steps={tourSteps}
            // mask={false}
            // colorPrimary="#3387C7"
            // type="primary"
          />
        )}
        {showVideoTutorial && (
          <PlayVideoTutorial
            {...{
              visible: showVideoTutorial,
              setVisible: setShowVideoTutorial,
              url: videoTutorialLink,
              title: "Take-Off Tutorial",
            }}
          />
        )}{" "}
        {compareModalVisible && (
          <CompareTakeOffVersions
            visible={compareModalVisible}
            setVisible={setCompareModalVisible}
            estimation={estimation}
            forceDarkMode={agGridTheme?.includes("dark")}
          />
        )}
      </div>{" "}
    </div>
  );
}

export default React.memo(EstimationSecondaryHeader);
async function getProject(estimation) {
  let project = null;

  await Promise.allSettled([
    !!estimation?.projectId
      ? API.get("projects", `/projects/${estimation?.projectId}`)
      : undefined,
    !!estimation?.opportunityId
      ? API.get("projects", `/projects/${estimation?.projectId}`)
      : undefined,
  ]).then(([{ value: pr }, { value: op }]) => {
    if (!!pr && !!op) {
      project = { project: { ...pr, taxExempt: op?.taxExempt } };
    } else if (!!pr) {
      project = { project: { ...pr } };
    } else if (!!op) {
      project = { opportunity: { ...op } };
    }
  });

  return project;
}

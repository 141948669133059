import axios from "axios";
import { useEffect, useMemo, useState } from "react";
import { StepHeader } from "..";
import {
  aptNumberChangeHandler,
  getAddressComponent,
  getCoordinatesAndZip,
  validateForm,
} from "../../../../../SidebarPages/Fleet/utils";
import {
  compareIncluding,
  getRandomColor,
} from "../../../../../SidebarPages/utils";
import {
  AlternativeAddresses,
  RenderDynamicComponents,
} from "../../../Components";
import { fieldsJSON } from "./overviewStepData";
import "./OverviewStep.scss";
import { Avatar, Button, Form, Popover, Tooltip, message } from "antd";
import isEmpty from "lodash/isEmpty";
import { ClientDropDown, ContactsDropDown } from "./components";
import { ClientModal, ContactModal } from "../../..";
import { API } from "aws-amplify";
import { MondayButton } from "../../../../../commonComponents";
import { TickIcon } from "../../../../../pages/Settings/settingsComponents/Roles/src";
import _ from "lodash";
import { BorderedTextCard } from "../../../../../SidebarPages/Fleet/components";
import { PlusIcon } from "../../../../../../assets";
import DynamicTeamModal from "../../../DynamicTeamModal/DynamicTeamModal";
import { DynamicAvatars } from "../../../../../commonComponents/DynamicAvatars/DynamicAvatars";
import { useSelector } from "react-redux";
import { getSalesTax } from "../../../../../commonComponents/NextSteps/helpers/getSalesTax";
// import ProjectModal from "../../ProjectModal";

const OverviewStep = ({
  form,
  index,
  setValidateStepMethod,
  clients = [],
  contacts = [],
  projectAddress,
  projectAddressInfo = {},
  laborTypes,
  insuranceTypes,
  proppedAccountName,
  accountName,
  setAccountName,
  setProjectAddress,
  setProjectAddressInfo,
  opportunityStage,
  altAddresses = [],
  setAltAddresses = [],
  taxExemptOptions = [],
  salesPerson,
  projectExecutives = [],
  completedSteps = {},
  useExistingData = false,
  existingOpportunity = {},
  onContactCreated,
  onClientCreated,
  selectedTeam,
  projects,
  setSelectedTeam,
  userConfiguration,
}) => {
  const {
    opportunityAddress,
    accountName: oppAccountName,
    projectExecutive,
    primaryContact,
    leadObject: oppContact,
    taxExempt,
    salesPerson: oppSalesPerson,
    alternativeAddresses = [],
    taxRate: oppTaxRate,
    geoFenceInfo,
  } = existingOpportunity;

  const [alternativeAddressesVisible, setAlternativeAddressesVisible] =
    useState(false);
  const [taxRatePopOverVisible, setTaxRatePopOverVisible] = useState(false);
  const [contactModalVisible, setContactModalVisible] = useState(false);
  const [selectedPrimaryContact, setSelectedPrimaryContact] = useState("");
  const [clientModalVisible, setClientModalVisible] = useState(false);
  const [taxRate, setTaxRate] = useState(0);
  const [projectTeamSelect, setProjectTeamSelect] = useState([]);
  const [open, setOpen] = useState(false);
  const projectAddressWatch = Form.useWatch("projectName", form);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const selectedClient = clients.find(
    ({ accountName: arrAccountName }) =>
      accountName === arrAccountName || arrAccountName === proppedAccountName
  );

  const companyNames = useMemo(() => {
    return clients?.map(({ accountName: clientAccName }) => clientAccName);
  }, [clients]);

  const checkClientAccess = useMemo(() => {
    const hasAccess = userConfiguration?.routeConfig?.find(
      ({ title }) => title === "Accounts"
    );
    return hasAccess;
  }, [userConfiguration]);

  const projectExecutivesNames = projectExecutives?.map(
    ({ firstName, lastName }) => `${firstName} ${lastName}`
  );
  const filteredContacts = contacts.filter(
    ({ contactAccountName }) => contactAccountName === accountName
  );

  const onTaxExemptSelect = async (value) => {
    // Get the project address
    const address = form.getFieldValue("projectAddress");

    if (!address) {
      message.error(
        "Please enter a valid project address before selecting tax exempt options."
      );
      return;
    }

    try {
      // Get coordinates and zip based on the address
      const locInfo = await getCoordinatesAndZip(address);

      // Initialize the tax rate
      let taxRate = 0;

      // If tax exemption is 'No' or 'Partial', fetch tax rate based on the location's zip code
      if (value === "No" || value === "Partial") {
        taxRate = await getSalesTax({ zipCode: locInfo?.zipCode });
      }

      // Log the tax rate for debugging purposes
      console.log("Tax Rate:", taxRate);

      // Optionally, handle visibility of popovers based on 'Partial' exemption
      if (compareIncluding(value, "Partial")) {
        setTaxRatePopOverVisible(true);
      } else {
        setTaxRatePopOverVisible(false);
      } // Set form field values
      form.setFieldsValue({
        taxExempt: value,
        taxRate: taxRate * 100, // Multiply taxRate to match the expected format
      });
      setTaxRate(taxRate * 100); // Set the tax rate in the state
    } catch (error) {
      console.error("Error fetching tax rate:", error);
      message.error(
        "There was an error calculating the tax rate. Please try again."
      );
    }
  };

  const onSelect = (_, data) => {
    setSelectedTeam((prev) => [
      ...(prev || []),
      {
        value: data?.value,
        teamId: data?.teamId,
        members: data?.members?.map((el) => el),
      },
    ]);
    form.setFieldValue("projectTeam", [
      {
        value: data.value,
        teamId: data.teamId,
        members: data?.members,
      },
    ]);
  };

  const openProjectTeamModal = () => {
    setOpen(true);
  };

  const onDeselect = (val, data) => {
    const teamName = data?.value || val;
    setSelectedTeam((prev) =>
      prev
        ?.filter((el) => el.value !== teamName)
        ?.map((el) => ({
          ...el,
          teamId: el.teamId?.filter((id) => id !== data?.teamId?.[0]) || [],
        }))
    );
    if (form.getFieldValue("projectTeam").length < 1) {
      setSelectedTeam([]);
    }
  };

  const ClearOptions = () => {
    setOpen(false);
    setSelectedTeam([]); // form.resetFields(["projectTeam"]);
  };
  //region FIELDS
  const inputFields = fieldsJSON({
    form,
    onSelect,
    onDeselect,
    companyNames,
    contacts: filteredContacts,
    accountName,
    projects: projects?.map(({ projectAddress }) => projectAddress),
    projectTeamSelect: projectTeamSelect?.map((team) => ({
      label: team.teamName,
      value: team.teamName,
      teamId: [team.teamId],
      members: team.members?.map(
        ({ identityId, nameOfUser, cognitoUserId = "" }) => ({
          identityId,
          nameOfUser,
          cognitoUserId,
        })
      ),
    })),
    laborTypes,
    insuranceTypes,
    projectExecutivesNames,
    proppedAccountName,
    taxExemptOptions: taxExemptOptions?.map(({ name }) => name),
    taxRatePopOverVisible,
    salesPerson,
    useExistingData,
    onTaxExemptSelect,
    setProjectAddress,
    setAccountName,
    selectedPrimaryContact,
    setSelectedPrimaryContact,
    alternativeAddressesVisible,
    checkClientAccess,
    AlternativeAddressesComp: (
      <AlternativeAddresses
        form={form}
        setVisible={setAlternativeAddressesVisible}
        disabled={!!useExistingData}
        {...{ altAddresses, setAltAddresses }}
      />
    ),
    AlternativeAddressesTitle: (
      <span className="opportunityModalPopoverTitle">
        Alternative Addresses
      </span>
    ),
    contactsDropdown: (menu) => (
      <ContactsDropDown
        {...{
          menu,
          setContactModalVisible,
        }}
      />
    ),
    clientDropdown: (menu) => (
      <ClientDropDown
        {...{
          menu,
          setClientModalVisible,
          checkClientAccess,
        }}
      />
    ),
  });
  const getTaxRateAndCoordinates = async () => {
    const taxExempt = form.getFieldValue("taxExempt");

    const locInfo = await getCoordinatesAndZip(projectAddress);
    if (!compareIncluding(taxExempt, "yes") && !!oppTaxRate) {
      form.setFieldsValue({
        taxRate: compareIncluding(taxExempt, "yes") ? 0 : oppTaxRate * 100,
      });
      setTaxRate(oppTaxRate);
    } else {
      const taxRate = await getSalesTax({ zipCode: locInfo?.zipCode });

      const resTaxRate = parseFloat(taxRate) * 100;
      if (!isNaN(resTaxRate)) {
        form.setFieldsValue({
          taxRate: compareIncluding(taxExempt, "yes") ? 0 : resTaxRate,
        });
        setTaxRate(resTaxRate);
      }
    }
    setProjectAddressInfo(locInfo);
  };

  const validateOverviewStep = async (onSuccessFunc) => {
    // !!projectAddress &&
    //   !isEmpty(projectAddressInfo) &&
    validateForm(form, onSuccessFunc);
  };

  useEffect(() => {
    !completedSteps[index] && setValidateStepMethod(validateOverviewStep);
  }, []);

  useEffect(() => {
    !!projectAddress && !opportunityStage && getTaxRateAndCoordinates();
  }, [projectAddress]);

  useEffect(() => {
    !!oppTaxRate && !!opportunityStage && getTaxRateAndCoordinates();
  }, [oppTaxRate]);

  useEffect(() => {
    if (!!useExistingData && !isEmpty(existingOpportunity)) {
      form.setFieldsValue({
        projectAddress: opportunityAddress,
        projectExecutive,
        taxExempt,
        insuranceRequirement: existingOpportunity.insuranceRequirement,
        accountName: oppAccountName,
        primaryContact:
          primaryContact?.id || primaryContact?.name || primaryContact?.email,
        salesPerson: oppSalesPerson,
        alternativeAddresses,
      });
      onTaxExemptSelect(taxExempt);
      setAltAddresses(alternativeAddresses.map(({ address }) => address));
      if (existingOpportunity?.teamsConfiguration?.length) {
        function getTeamId(teamName) {
          let teamId = projectTeamSelect?.find(
            (e) => e?.teamName === teamName
          )?.teamId;
          return !!teamId ? [teamId] : [];
        }
        let newProjectTeam = existingOpportunity?.teamsConfiguration?.map(
          (el) => ({
            ...el,
            teamId: !!el.teamId ? el.teamId : getTeamId(el.value),
          })
        );

        form.setFieldsValue({
          projectTeam:
            existingOpportunity?.teamsConfiguration?.map((el) => el?.value) ||
            "Team",
        });
        setSelectedTeam(newProjectTeam || []);
      }
    }
  }, [useExistingData, existingOpportunity]);

  useEffect(() => {
    if (
      selectedClient &&
      (!existingOpportunity || isEmpty(existingOpportunity))
    ) {
      const oldValues = form.getFieldsValue();
      const { teamsConfiguration, leadSalePerson, projectExecutive } =
        selectedClient;
      const valuesToAdd = {
        projectTeam: teamsConfiguration || undefined,
        salesPerson: leadSalePerson || undefined,
        projectExecutive: projectExecutive || undefined,
      };
      form.setFieldsValue({
        ...oldValues,
        ...valuesToAdd,
      });
      if (!!valuesToAdd?.projectTeam && valuesToAdd?.projectTeam?.length) {
        function getTeamId(teamName) {
          let teamId = projectTeamSelect?.find(
            (e) => e?.teamName === teamName
          )?.teamId;
          return !!teamId ? [teamId] : [];
        }
        let newProjectTeam = valuesToAdd?.projectTeam?.map((el) => ({
          ...el,
          teamId: !!el.teamId ? el.teamId : getTeamId(el.value),
        }));
        form.setFieldsValue({
          projectTeam: valuesToAdd?.projectTeam?.length
            ? valuesToAdd?.projectTeam?.map((el) => el?.value) || "Team"
            : undefined,
        });
        setSelectedTeam(newProjectTeam || []);
      }
    }
  }, [selectedClient]);

  const handleAddressDecomposition = async (fullAddress) => {
    const addressObj = await getAddressComponent(fullAddress, false, "");
    form.setFieldsValue({
      projectAddStreet: addressObj?.street,
      projectAddCity: addressObj?.city,
      projectAddState: addressObj?.state,
      projectAddPostalCode: addressObj?.zipCode,
    });
  };

  useEffect(() => {
    if (!!projectAddress) {
      form.setFieldValue("projectAddress", projectAddress);
      handleAddressDecomposition(projectAddressWatch);
    }
  }, [projectAddress]);

  useEffect(() => {
    async function fetchTeams() {
      await API.get("teams", "/teams").then((r) => setProjectTeamSelect(r));
    }
    fetchTeams();
  }, []);

  return (
    <>
      <div className="overViewStepContainer">
        {/* <StepHeader title="Overview" /> */}
        <div className="mainContainer">
          <div className="mainGrid">
            <div className="addressGrid">
              <div className="mainAddress">
                <Popover content={<span>Alternative Addresses</span>}>
                  <Button
                    onClick={() => setAlternativeAddressesVisible(true)}
                    // disabled={!!useExistingData}
                    // className={
                    //   !!projectAddress ? "titleVisible" : "addAddressIcon"
                    // }
                    className="addAddressIcon"
                    type="primary"
                  >
                    {altAddresses.length}
                  </Button>
                </Popover>
                {RenderDynamicComponents(
                  inputFields?.find(({ category }) =>
                    compareIncluding(category, "addressGrid")
                  )?.mainFields
                )}
              </div>
              <div className="splitAddress">
                {RenderDynamicComponents(
                  inputFields?.find(({ category }) =>
                    compareIncluding(category, "addressGrid")
                  )?.splitFields
                )}
              </div>
            </div>
            <div className="inputsGrid">
              {RenderDynamicComponents(
                inputFields?.find(({ category }) =>
                  compareIncluding(category, "rightGrid")
                )?.fields
              )}
              <div className="projectAvatarsDiv">
                {!!selectedTeam?.length && (
                  <MondayButton
                    {...{ Icon: <PlusIcon /> }}
                    onClick={openProjectTeamModal}
                  >
                    Members
                  </MondayButton>
                )}
                <div className="projectAvatars">
                  <DynamicAvatars
                    {...{
                      title: "Project Team",
                      isDarkMode: isDarkMode,
                      members: selectedTeam.reduce(
                        (acc, { members }) => [...acc, ...members],
                        []
                      ),
                    }}
                  />
                </div>
              </div>
            </div>
            <div className="inputsGrid">
              {RenderDynamicComponents(
                inputFields?.find(({ category }) =>
                  compareIncluding(category, "leftGrid")
                )?.fields
              )}
            </div>
          </div>
        </div>
      </div>

      {!!contactModalVisible && (
        <ContactModal
          saveOnly
          {...{
            visible: contactModalVisible,
            setVisible: setContactModalVisible,
            refreshTable: onContactCreated,
            preventContinue: true,
            contact: {
              ...selectedClient,
              accountEmail: undefined,
              contactType: "Client Contact",
            },
            propClient: {
              aptNumber: selectedClient?.aptNumber,
              shippingAddress: selectedClient?.shippingAddress,
            },
          }}
        />
      )}

      {!!clientModalVisible && (
        <ClientModal
          saveOnly
          {...{
            visible: clientModalVisible,
            setVisible: setClientModalVisible,
            refreshTable: onClientCreated,
            preventContinue: true,
            onContactCreated,
          }}
        />
      )}
      {open && (
        <DynamicTeamModal
          {...{
            open,
            setOpen,
            selectedTeam,
            ClearOptions,
            setSelectedTeam,
            proppedAllTeams: projectTeamSelect,
            onSave: (newTeams) => {
              form.setFieldValue("projectTeam", newTeams || []);
            },
          }}
        />
      )}
    </>
  );
};
export default OverviewStep;

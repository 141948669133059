import { isEnteredAlert, isYard, getFence } from "./filterAlertsOnTruck";
import { pointsInsideAPolygon } from "../components/Map/utilities/pointsInsideAPolygon";
import { withinRadius } from "../../../../pages/Payroll/Tabs/Activity/components/payrollActivityModalData";

/**
 * Function that takes the actual trips taken by the vehicle
 * and matches them with the activities data
 */
function matchTruckActivities({
  planForTruck,
  trips = [],
  geofences = {},
  forceOrder = false,
}) {
  let lastFoundIndex = -1;

  let matchArray = [];

  for (let i = 0; i < planForTruck?.length; i++) {
    let activity = planForTruck[i];

    for (let j = lastFoundIndex + 1; j < trips?.length - 1; j++) {
      let startActivity = trips[j];
      let endActivity = trips[j + 1];

      if (
        isEnteredAlert(startActivity["alertCode"]) ||
        !isEnteredAlert(endActivity["alertCode"])
      ) {
        continue;
      }

      if (isYard(activity["pickUpLocation"])) {
        if (!isYard(startActivity["fenceName"])) {
          continue;
        }
      } else {
        if (
          getFence(activity["pickUpLocation"]) !==
          getFence(startActivity["fenceName"])
        ) {
          const fence = geofences[getFence(startActivity["fenceName"])];
          const points = fence?.["points"] ?? [];
          let condition = true;

          if (points?.length === 1) {
            condition = !withinRadius(
              points
                .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }))
                .at(0),
              activity?.pickUpCoordinates,
              (fence?.radius ?? 0) * 5280
            ).withinRange;
          } else if (points?.length > 2) {
            condition = !pointsInsideAPolygon(
              [
                activity["pickUpCoordinates"]["lat"],
                activity["pickUpCoordinates"]["lng"],
              ],
              (
                geofences[getFence(startActivity["fenceName"])]?.["points"] ||
                []
              ).map(({ latitude, longitude }) => [latitude, longitude])
            );
          }

          if (condition) {
            continue;
          }
        }
      }

      if (isYard(activity["dropOffLocation"])) {
        if (!isYard(endActivity?.["fenceName"])) {
          continue;
        }
      } else {
        if (
          getFence(activity["dropOffLocation"]) !==
          getFence(endActivity?.["fenceName"])
        ) {
          const fence = geofences[getFence(endActivity["fenceName"])];
          const points = fence?.["points"] ?? [];
          let condition = true;

          if (points?.length === 1) {
            condition = !withinRadius(
              points
                .map(({ latitude: lat, longitude: lng }) => ({ lat, lng }))
                .at(0),
              activity?.dropOffCoordinates,
              (fence?.radius ?? 0) * 5280
            ).withinRange;
          } else if (points?.length > 2) {
            condition = !pointsInsideAPolygon(
              [
                activity["dropOffCoordinates"]["lat"],
                activity["dropOffCoordinates"]["lng"],
              ],
              (
                geofences[getFence(endActivity["fenceName"])]?.["points"] || []
              ).map(({ latitude, longitude }) => [latitude, longitude])
            );
          }

          if (condition) {
            continue;
          }
        }
      }

      /**
       * We need to filter out duplicate matches
       * If the alerts have already matched with an activity,
       * we cannot match them again with another activity
       */
      if (startActivity?.["activityId"] && endActivity?.["activityId"]) {
        if (activity?.["activityId"] !== startActivity?.["activityId"]) {
          continue;
        }
      }

      matchArray.push({
        activityId: activity?.["activityId"],
        alertIds: [startActivity["alertUUID"], endActivity?.["alertUUID"]],
        pickUpLocation: activity?.["pickUpLocation"],
        dropOffLocation: activity?.["dropOffLocation"],
        plannedDepart: activity?.["startingTime"],
        plannedArrive: activity?.["timeAtLocation"],
        actualDepart: startActivity?.["alertDateTime"],
        actualArrive: endActivity?.["alertDateTime"],
        driverName: activity?.["driverName"],
        driverId: activity?.["driverId"],
        fleetName: activity?.["fleetName"],
        cargo: activity?.["cargo"],
        cargoWeight: activity?.["cargoWeight"],
      });

      if (forceOrder) {
        lastFoundIndex = j;
        break;
      }
    }
  }

  return matchArray;
}

export default matchTruckActivities;

export const findProp = (obj, prop) => {
  var result = [];
  function recursivelyFindProp(o, keyToBeFound) {
    if (typeof o !== "object" || o === null) {
      return;
    }
    Object.keys(o).forEach(function (key) {
      if (typeof o[key] === "object") {
        recursivelyFindProp(o[key], keyToBeFound);
      } else {
        if (key === keyToBeFound) result.push(o[key]);
      }
    });
  }
  recursivelyFindProp(obj, prop);
  return result;
};

export const googleDriveFolderIdKeys = {
  accounts: {
    key: "clientObject",
    folderName: "Clients",
    nameKey: "accountName",
    tableName: "accounts",
    idKey: "accountId",
  },
  projects: {
    key: "projectId",
    folderName: "Projects",
    nameKey: "projectName",
    tableName: "projects",
    idKey: "projectId",
  },
  leads: {
    key: "leadObject",
    folderName: "Leads",
    nameKey: "leadCompany",
    tableName: "leads",
    idKey: "leadId",
  },
  opportunities: {
    key: "oppObject",
    folderName: "Opportunities",
    nameKey: "opportunityName",
    tableName: "opportunities",
    idKey: "opportunityId",
  },
  estimations: {
    key: "estimationObject",
    folderName: "Estimations",
    nameKey: "jobSiteAddress",
    tableName: "estimations",
    idKey: "estimationId",
  },
  contacts: {
    key: "contactId",
    folderName: "Contacts",
    nameKey: "contactEmail",
    tableName: "contacts",
    idKey: "contactId",
  },
  scheduling: {
    key: "scheduleObject",
    folderName: "Scheduling",
    nameKey: "scheduleName",
    tableName: "scheduling",
    idKey: "scheduleId",
  },
  permitdrawings: {
    key: "permitDrawings",
    folderName: "Permit Drawings",
    nameKey: "sow",
    tableName: "permitDrawings",
    idKey: "permitId",
  },
  fleets: {
    key: "vehicleObject",
    folderName: "Fleets",
    nameKey: "fleetName",
    tableName: "fleet",
    idKey: "fleetId",
  },
  fleetdrivers: {
    key: "driverObject",
    folderName: "Drivers",
    nameKey: "driverName",
    tableName: "drivers",
    idKey: "driverId",
  },
  fleetViolations: {
    key: "violationObject",
    folderName: "Violations",
    nameKey: "issue_date",
    tableName: "fleetViolations",
    idKey: "violationId",
  },
  claims: {
    key: "claimObject",
    folderName: "Claims",
    nameKey: "claimNumber",
    tableName: "claims",
    idKey: "claimId",
  },
  hearings: {
    key: "hearingId",
    folderName: "Hearings",
    nameKey: "hearingName",
    tableName: "hearings",
    idKey: "hearingId",
  },
  hrManagementEmployee: {
    key: "employee",
    folderName: "Employee",
    nameKey: "employee",
    tableName: "hrManagementEmployee",
    idKey: "employee",
  },
};

export const safetyAndIncidentFolderKeys = (
  type,
  safetyCategory,
  folderDriveKeyName
) => {
  const safetyNameKey = {
    "Property Damage": "addressOfDamagedProperty",
    "Personal Injury": "fullNameOfInjuredIndividual",
    "Vehicle Damage": "addressOfIncident",
    "Other Trade Incident": "fullNameOfInjuredIndividual",
  };
  const folderNameInc = {
    "Property Damage": "Property Damage Incident",
    "Personal Injury": "Personal Injury Incident",
    "Other Trade Incident": "Other Incident",
  };
  const incNameKey = {
    "Property Damage": "addressOfDamagedProperty",
    "Personal Injury": "fullNameOfInjuredIndividual",
    "Other Trade Incident": "fullNameOfInjuredIndividual",
  };
  const safetyFolder = {
    "Property Damage": {
      key: folderDriveKeyName,
      folderName: safetyCategory,
      nameKey: safetyNameKey[safetyCategory],
      tableName: "safety",
      idKey: "safetyApplicationId",
    },
    "Personal Injury": {
      key: folderDriveKeyName,
      folderName: safetyCategory,
      nameKey: safetyNameKey[safetyCategory],
      tableName: "safety",
      idKey: "safetyApplicationId",
    },
    "Vehicle Damage": {
      key: folderDriveKeyName,
      folderName: safetyCategory,
      nameKey: safetyNameKey[safetyCategory],
      tableName: "safety",
      idKey: "safetyApplicationId",
    },
    "Other Trade Incident": {
      key: folderDriveKeyName,
      folderName: safetyCategory,
      nameKey: safetyNameKey[safetyCategory],
      tableName: "safety",
      idKey: "safetyApplicationId",
    },
  };

  const incidentFolder = {
    "Property Damage": {
      key: folderDriveKeyName,
      folderName: folderNameInc[safetyCategory],
      nameKey: incNameKey[safetyCategory],
      tableName: "incidents",
      idKey: "incidentId",
    },
    "Personal Injury": {
      key: folderDriveKeyName,
      folderName: folderNameInc[safetyCategory],
      nameKey: incNameKey[safetyCategory],
      tableName: "incidents",
      idKey: "incidentId",
    },
    "Other Trade Incident": {
      key: folderDriveKeyName,
      folderName: folderNameInc[safetyCategory],
      nameKey: incNameKey[safetyCategory],
      tableName: "incidents",
      idKey: "incidentId",
    },
  };
  return type === "Safety"
    ? safetyFolder[safetyCategory]
    : incidentFolder[safetyCategory];
};

export function findMissingFolderKeys(mainKey, driveObj, folderList) {
  if (
    !folderList?.hasOwnProperty(mainKey) ||
    !driveObj ||
    !Object.keys(driveObj)?.length
  ) {
    return [];
  }
  const missingKeys = [];
  const checkSubFolders = (subFolders, driveObj) => {
    for (const key in subFolders) {
      if (!driveObj?.hasOwnProperty(subFolders[key].folderKeyName)) {
        missingKeys.push({ key: subFolders[key].folderKeyName, label: key });
      }
      if (subFolders[key].subFolders) {
        checkSubFolders(subFolders[key].subFolders, driveObj);
      }
    }
  };

  checkSubFolders(folderList[mainKey].subFolders, driveObj);

  return missingKeys || [];
}

import { DeleteFilled, DiffOutlined, EditOutlined } from "@ant-design/icons";
import { Tooltip } from "antd";
import {
  DeleteElevationIcon,
  DeleteIconModal,
} from "../../../../../../../assets";
import { currencyFormater } from "../../../../../../../utils/currencyFormater";
import { MondayButton } from "../../../../../../commonComponents";
import { AddIcon } from "../../../../../../Header/components/GoogleEvents/assets";
import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import { DeleteIcon } from "../../../../../DynamicView/src";
import textEditor from "./gridComponents/textEditor";
import { MyCellEditor } from "./MyCellEditor";
import { v4 } from "uuid";

import {
  getPriceScheme,
  mappingOfKeysHoistTable,
  TABLE_HOIST_REFERENCE_DATA,
} from "./referenceData";
import { CustomRichSelectEditor } from "./gridComponents/CustomRichSelectEditor";
import applyFormulaFromArrayHoist from "./applyFormulaFromArrayHoist";
const cellEditorSelector = (params) => {
  console.log("cellEditorSelector", params);
  return {
    component: "agSelectCellEditor",
    params: {
      values: [
        ...getValues(
          params?.data?.name,
          params?.context?.hoistData?.type === "dual"
        ),
        "Custom",
      ],
      cellHeight: 55,
    },
  };
};

export const TABLE_DATA_HOIST_CALCULATOR = [
  {
    id: 1,
    name: "Hoist Base Price",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 2,
    name: "Cabin Size (L)",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 3,
    name: "Cabin Size (H)",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 4,
    name: "Speed",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 5,
    name: "Lift Height",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 6,
    name: "Location",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 7,
    name: "Jumps",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 70,
    name: "Stops",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 8,
    name: "Rental for Hoist",
    units: 6,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  // {
  //   id: 80,
  //   name: "Additional Rental for Hoist",
  //   units: 6,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },

  // {
  //   id: 9,
  //   name: "Runbacks",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 10,
  //   name: "Shoring under Runback",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 11,
  //   name: "Shoring",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 12,
  //   name: "Loading Dock",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 13,
  //   name: "Winterization",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  {
    id: 14,
    name: "Inserts",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  // {
  //   id: 15,
  //   name: "Needle Beam Ramp",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 16,
  //   name: "Ramp on Roof",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  // {
  //   id: 17,
  //   name: "MTA Approval",
  //   units: 1,
  //   ppu: 0,
  //   price: 0,
  //   rent: 0,
  //   note: "",
  // },
  {
    id: 19,
    name: "Call Button System",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 20,
    name: "Other Charge #1",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 23,
    name: "Other Charge #2",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 24,
    name: "Other Charge #3",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 21,
    name: "Credit",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 22,
    name: "Discount",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  {
    id: 25,
    name: "Discount with Rental",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
  },
  // / ADDONS
  {
    id: 26,
    name: "Common Platform",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 27,
    name: "Shoring under runbacks",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 28,
    name: "Loading Dock",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 30,
    name: "Inclined Ramp",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 31,
    name: "Plywood Enclosure",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 32,
    name: "Overhead Protection",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 33,
    name: "Steel Angle",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 34,
    name: "Shoring",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 35,
    name: "Runbacks",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 36,
    name: "Rental for Runbacks",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: false,
  },
  {
    id: 366,
    name: "Steel Plates",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 37,
    name: "Needle Beam Runback",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 38,
    name: "Landing over parapet with a ramp",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 39,
    name: "Winterization",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
  {
    id: 40,
    name: "MTA Approval",
    units: 1,
    ppu: 0,
    price: 0,
    rent: 0,
    note: "",
    isAddon: true,
  },
];

export const TABLE_DATA_COLUMN_HOIST_CALCULATOR = [
  {
    headerName: "Entity Name",
    field: "name",
    key: "name",
    cellStyle: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      maxWidth: "250px",
    },
    cellRenderer: "agGroupCellRenderer",
    cellEditor: CustomRichSelectEditor,
    cellEditorParams: {
      values: ["A", "B", "C"], // Initial options
    },
    editable: true,
    autoHeight: true, // This will adjust row height to fit the content  },
    // cellRenderer: ({ data = {} }) => {
    //   return (
    //     <div
    //       style={{
    //         display: "flex",
    //         gap: 10,
    //         alignItems: "center",
    //       }}
    //     >
    //       <div>{data.name}</div>
    //       {data.isAddon && <DiffOutlined />}
    //     </div>
    //   );
    // },
  },
  {
    headerName: "ID",
    field: "id",
    key: "id",
    // width: 200,
    hide: true,
  },
  {
    headerName: "Entity Value",
    field: "entityValue",
    key: "Entity Value",
    cellStyle: (params) => {
      return { color: "#94FFD8" };
    },
    cellHeight: 60,
    // width: 400,
    editable: true,
    cellEditorSelector: cellEditorSelector,
    // valueGetter: (params) => {},
    // cellEditorParams: {
    //   values: getValueFromServer(),
    //   allowTyping: true,
    //   filterList: true,
    //   highlightMatch: true,
    //   valueListMaxHeight: 220,
    // },
  },
  {
    headerName: "Units",
    field: "units",
    // width: 100,
    key: "units",
    valueGetter: (params) => {
      return unitsValueGetter(params);
    },
  },
  {
    headerName: "Unit Category",
    field: "unitCategory",
    // width: 220,
    hide: true,
    key: "unitCategory",
    valueGetter: (params) => {
      return params?.data?.unitCategory || "";
    },
  },
  {
    headerName: "PPU",
    field: "ppu",
    // width: 200,
    editable: true,
    key: "ppu",
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
    valueGetter: pricePerUnitValueGetter,
  },
  {
    headerName: "Price",
    field: "price",
    // width: 200,
    key: "price",
    valueGetter: priceValueGetter,
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
    valueGetter: ({ data }) => {
      if (Array.isArray(data?.priceFormula) && data?.priceFormula.length > 0) {
        let val = applyFormulaFromArrayHoist(data?.priceFormula, data, "price");
        data.price = forceToNumber(data?.price);
        return forceToNumber(data?.price);
      } else {
        data.price = forceToNumber(data?.ppu) * forceToNumber(data?.units);
        return forceToNumber(data?.price);
      }
    },
    cellRenderer: ({ data, value }) => {
      if (data?.priceFormula?.length > 0) {
        return (
          <Tooltip
            title={
              <div>
                {data?.priceFormula?.map((item, idx) => {
                  return <div key={idx}>{item} </div>;
                })}
              </div>
            }
          >
            <MondayButton hasIcon={false} className="mondayButtonBlue">
              {currencyFormater(value)}
            </MondayButton>
          </Tooltip>
        );
      } else {
        return <div>{currencyFormater(value)}</div>;
      }
    },
  },
  {
    headerName: "Free months (Rent)",
    field: "freeMonths",
    // width: 220,
    // cellEditor: MyCellEditor,
    key: "freeMonths",
    valueFormatter: ({ value = "" }) => {
      if (value === 0) return "No";
    },
    valueGetter: ({ data }) => {
      return forceToNumber(data?.freeMonths);
    },
  },
  // {
  //   headerName: "Rent After",
  //   field: "rentAfter",
  //   width: 200,
  //   key: "rentAfter",
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.rentAfter);
  //   },
  //   valueFormatter: ({ value }) => {
  //     return currencyFormater(value);
  //   },
  // },
  // {
  //   headerName: "Tax Rate (%)",
  //   field: "taxRate",
  //   width: 200,
  //   hide: true,
  //   key: "taxRate",
  //   editable: true,
  //   valueFormatter: ({ value = 0 }) => {
  //     return currencyFormater(value);
  //   },
  //   valueGetter: (params) => {
  //     console.log("paramsContext", params.context);
  //     return forceToNumber(params?.context?.hoistData?.taxRate);
  //   },
  // },
  // {
  //   headerName: "Tax",
  //   field: "tax",
  //   width: 200,
  //   key: "tax",
  //   hide: true,
  //   editable: false,
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.taxRate) * forceToNumber(data?.price) * 0.01;
  //   },
  //   valueFormatter: ({ value = 0 }) => {
  //     return currencyFormater(value);
  //   },
  // },
  {
    headerName: "Total Price",
    field: "totalPrice",
    // width: 200,
    key: "totalPrice",
    valueFormatter: ({ value }) => {
      return currencyFormater(value);
    },
    valueGetter: totalPriceValueGetter,
  },
  // {
  //   headerName: "Rent Months",
  //   field: "rentMonths",
  //   // width: 200,
  //   key: "rentMonths",
  //   // valueFormatter: ({ value }) => {
  //   //   if (value === "N/A") return "N/A";
  //   //   return value * 100 + "%";
  //   // },
  //   valueGetter: ({ data }) => {
  //     if (!data?.rentMonths) return "N/A";
  //     data.rentMonths = forceToNumber(data?.rentMonths);
  //     return forceToNumber(data?.rentMonths);
  //   },
  //   // valueGetter: totalPriceValueGetter,
  // },
  {
    headerName: "% Rent Incl.",
    field: "rentRate",
    // width: 200,
    key: "rentRate",
    valueFormatter: ({ value }) => {
      if (value === "N/A") return "N/A";
      return Math.round(value * 100) + "%";
    },
    valueGetter: ({ data }) => {
      if (!data?.rentRate) return 0;
      data.rentRate = forceToNumber(data?.rentRate);
      return forceToNumber(data?.rentRate);
    },
    // valueGetter: totalPriceValueGetter,
  },
  {
    headerName: "Rental Incl.",
    field: "rentalAfter",
    // width: 200,
    key: "rentalAfter",
    // valueFormatter: ({ value }) => {
    //   return currencyFormater(value);
    // },
    cellRenderer: ({ data, value }) => {
      if (data?.rentalFormula?.length > 0) {
        return (
          <Tooltip
            title={
              <div>
                {data?.rentalFormula?.map((item, idx) => {
                  return <div key={idx}>{item} </div>;
                })}
              </div>
            }
          >
            <MondayButton hasIcon={false} className="mondayButtonBlue">
              {currencyFormater(value)}
            </MondayButton>
          </Tooltip>
        );
      } else {
        return <div>{currencyFormater(data?.rentalAfter)}</div>;
      }
    },
    valueGetter: ({ data }) => {
      if (
        Array.isArray(data?.rentalFormula) &&
        data?.rentalFormula.length > 0
      ) {
        let val = applyFormulaFromArrayHoist(
          data?.rentalFormula,
          data,
          "rentalAfter"
        );
        data.rentalAfter = forceToNumber(data?.rentalAfter);
        return forceToNumber(data?.rentalAfter);
      } else {
        let price = forceToNumber(data?.price);
        let freeMonths = forceToNumber(data?.freeMonths);
        let rate = forceToNumber(data?.rentRate);
        let rentalIncl = price * rate;
        data.rentalAfter = forceToNumber(rentalIncl);
        console.log("testttest", {
          price,
          freeMonths,
          rate,
          rentalIncl,
          data,
        });
        return forceToNumber(rentalIncl);
      }
      data.rentalAfter = forceToNumber(data?.rentalAfter);
      return forceToNumber(data?.rentalAfter);
    },
    // valueGetter: totalPriceValueGetter,
  },
  {
    headerName: "% Rent After. [A.R]",
    field: "rentRateAfter",
    // width: 200,
    key: "rentRateAfter",
    valueFormatter: ({ value }) => {
      if (value === "N/A") return "N/A";
      return Math.round(value * 100) + "%";
    },
    valueGetter: ({ data }) => {
      if (!data?.rentRateAfter) return 0;
      data.rentRateAfter = forceToNumber(data?.rentRateAfter);
      return forceToNumber(data?.rentRateAfter);
    },
    // valueGetter: ({ data }) => {
    //   if (!data?.rentRateAfter) return "N/A";
    //   data.rentRateAfter = forceToNumber(data?.rentRateAfter);
    //   return forceToNumber(data?.rentRateAfter);
    // },
    // valueGetter: totalPriceValueGetter,
  },
  {
    headerName: "Rental Monthly [A.R]",
    field: "rentMonthly",
    // width: 200,
    key: "rentMonthly",
    // valueFormatter: ({ value }) => {
    //   return currencyFormater(value);
    // },
    editable: true,
    cellRenderer: ({ data, value }) => {
      if (data?.rentMonthFormula?.length > 0) {
        return (
          <Tooltip
            title={
              <div>
                {data?.rentMonthFormula?.map((item, idx) => {
                  return <div key={idx}>{item} </div>;
                })}
              </div>
            }
          >
            <MondayButton hasIcon={false} className="mondayButtonBlue">
              {currencyFormater(value)}
            </MondayButton>
          </Tooltip>
        );
      } else {
        return <div>{currencyFormater(forceToNumber(data?.rentMonthly))}</div>;
      }
    },
    valueGetter: ({ data }) => {
      if (
        Array.isArray(data?.rentMonthFormula) &&
        data?.rentMonthFormula.length > 0
      ) {
        let val = applyFormulaFromArrayHoist(
          data?.rentMonthFormula,
          data,
          "rentMonthly"
        );
        data.rentMonthly = forceToNumber(val);
        return forceToNumber(val);
      } else {
        let price = forceToNumber(data?.price);
        let rate = forceToNumber(data?.rentRateAfter);
        let rentalIncl = price * rate;
        data.rentMonthly = forceToNumber(rentalIncl);
        return forceToNumber(rentalIncl);
      }
      data.rentMonthly = forceToNumber(data?.rentMonthly);
      return forceToNumber(data?.rentMonthly);
    },
    // valueGetter: totalPriceValueGetter,
  },
  {
    headerName: "Note",
    field: "note",
    // width: 200,
    minWidth: 400,
    cellEditor: "agLargeTextCellEditor",
    cellEditorPopup: true,
    minWidth: 550,
    cellStyle: {
      whiteSpace: "normal",
      wordBreak: "break-word",
      maxWidth: "400px",
    },
    autoHeight: true, // This will adjust row height to fit the content  },
    key: "note",
  },
  // {
  //   headerName: "Additional Rent Months",
  //   field: "additionalRentMonths",
  //   width: 200,
  //   key: "additionalRentMonths",
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.additionalRentMonths);
  //   },
  // },
  // {
  //   headerName: "Additional Rent",
  //   field: "additionalRent",
  //   width: 200,
  //   key: "additionalRent",
  //   valueGetter: ({ data }) => {
  //     return forceToNumber(data?.additionalRent);
  //   },
  //   valueFormatter: ({ value }) => {
  //     return currencyFormater(value);
  //   },
  // },
];

// export const getTableDataColumns = ({ removeRow, addRow }) => {
//   let afterColumns = [
//     {
//       headerName: "Action",
//       field: "action",
//       // minWidth: 140,
//       editable: false,
//       cellRenderer: (params) => {
//         return (
//           <div
//             style={{
//               display: "flex",
//               gap: 10,
//               justifyContent: "space-between",
//             }}
//           >
//             <MondayButton
//               // Icon={EditOutline}
//               onClick={() => {
//                 params.api.applyTransaction({ remove: [params?.data] });
//                 removeRow(params?.data);
//                 // params.api.set
//               }}
//               hasIcon={false}
//               className="mondayButtonRed"
//               style={{ width: "100%" }}
//             >
//               <div
//                 style={{
//                   marginTop: 5,
//                 }}
//               >
//                 <DeleteElevationIcon />
//               </div>
//             </MondayButton>
//             <MondayButton
//               // Icon={EditOutline}
//               hasIcon={false}
//               className="mondayButtonGreen"
//               style={{ width: "100%" }}
//               onClick={() => {
//                 let rowToAdd = addCustomRow(params);
//                 // params.api.applyTransaction({ add: [rowToAdd] });
//                 let newIndex = params.node.rowIndex + 1; // Insert below the current row

//                 addRow(rowToAdd, newIndex);
//                 // let rowToAdd = addCustomRow(params);
//                 // params.api.applyTransaction({
//                 //   add: [rowToAdd],
//                 //   addIndex: 2,
//                 // });
//                 // addRow(rowToAdd);
//               }}
//             >
//               <AddIcon />
//             </MondayButton>
//           </div>
//         );
//       },
//     },
//   ];
//   return [...afterColumns, ...TABLE_DATA_COLUMN_HOIST_CALCULATOR];
// };

function getValues(nameRef, isDual) {
  console.log("getValues", nameRef);
  const { TABLE_HOIST_REFERENCE_DATA } = getPriceScheme({ isDual });
  switch (nameRef) {
    case "Hoist Base Price": {
      return TABLE_HOIST_REFERENCE_DATA.hoistType.options;
    }
    case "Cabin Size (L)": {
      return TABLE_HOIST_REFERENCE_DATA.cabinLength.options;
    }
    case "Cabin Size (H)": {
      return TABLE_HOIST_REFERENCE_DATA.cabinHeight.options;
    }
    case "Speed": {
      return TABLE_HOIST_REFERENCE_DATA.hoistSpeed.options;
    }
    case "Lift Height": {
      return TABLE_HOIST_REFERENCE_DATA.liftHeight.options;
    }
    case "Location": {
      return TABLE_HOIST_REFERENCE_DATA.location.options;
    }
    case "Jumps": {
      return TABLE_HOIST_REFERENCE_DATA.jumps.options;
    }
    case "Rental for Hoist": {
      return TABLE_HOIST_REFERENCE_DATA.rentalForHoist.options;
    }
    case "Additional Rental for Hoist": {
      return TABLE_HOIST_REFERENCE_DATA.additionalRentalForHoist.options;
    }
    case "Runbacks": {
      return TABLE_HOIST_REFERENCE_DATA.runbacks.options;
    }
    case "Rental for Runbacks": {
      return TABLE_HOIST_REFERENCE_DATA.rentalForRunbacks.options;
    }
    case "Shoring under Runback": {
      return TABLE_HOIST_REFERENCE_DATA.shoringUnderRunback.options;
    }
    case "Shoring": {
      return TABLE_HOIST_REFERENCE_DATA.shoring.options;
    }
    case "Loading Dock": {
      return TABLE_HOIST_REFERENCE_DATA.loadingDock.options;
    }
    case "Winterization": {
      return TABLE_HOIST_REFERENCE_DATA.winterization.options;
    }
    case "Inserts": {
      return TABLE_HOIST_REFERENCE_DATA.inserts.options;
    }
    case "Needle Beam Ramp": {
      return TABLE_HOIST_REFERENCE_DATA.needleBeamRamp.options;
    }
    case "Ramp on Roof": {
      return TABLE_HOIST_REFERENCE_DATA.rampOnRoof.options;
    }
    case "MTA Approval": {
      return TABLE_HOIST_REFERENCE_DATA.mtaApproval.options;
    }
    case "Call Button System": {
      return TABLE_HOIST_REFERENCE_DATA.callButtonSystem.options;
    }
    case "Other Charges": {
      return TABLE_HOIST_REFERENCE_DATA.otherCharges.options;
    }
    case "Credit": {
      return TABLE_HOIST_REFERENCE_DATA.credit.options;
    }
    case "Discount": {
      return TABLE_HOIST_REFERENCE_DATA.discounts.options;
    }

    default: {
      return [];
    }
  }
}

function pricePerUnitValueGetter(params) {
  // console.log("pricePerUnitValueGetter", params);
  return forceToNumber(params?.data?.ppu);
  let refName = params?.data.name;
  let refValue = params?.data.entityValue;
  let ppu = 0;

  if (refValue === "Custom") return forceToNumber(params?.data?.ppu);

  let refData =
    TABLE_HOIST_REFERENCE_DATA[mappingOfKeysHoistTable[refName]]?.[refValue] ||
    {};
  if (!!refData?.ppu) {
    ppu = refData?.ppu;
  }
  params.data.ppu = ppu;
  return ppu;
}
function priceValueGetter(params) {
  let PPU = params?.data.ppu;
  let isCustomFormula = params?.data.hasCustomFormula || false;
  let units = params?.data.units;
  let priceWithoutTax = !isCustomFormula
    ? forceToNumber(PPU) * forceToNumber(units)
    : forceToNumber(params.data.price);
  params.data.price = priceWithoutTax;
  return priceWithoutTax;
}
function totalPriceValueGetter(params) {
  let price = params?.data.price;
  let fullPrice = forceToNumber(price);
  params.data.totalPrice = fullPrice;
  return fullPrice;
}
function unitsValueGetter(params) {
  let { name = "", units = 0 } = params?.data;
  if (name === "Call Button System") {
    // access Rental for hoist which is another row
    let noOfMonths = 0;
    params?.api?.forEachNode((node) => {
      if (
        node?.data?.name === "Rental for Hoist" ||
        node?.data?.name === "Additional Rental for Hoist"
      ) {
        noOfMonths += forceToNumber(node?.data?.units);
      }
    });
    let noOfFloors = forceToNumber(
      params?.context?.hoistData?.items?.length || 0
    );
    // console.log("unitsValueGetter", params.context);
    // params.data.note =
    //   `No of Floors ${noOfFloors} * No of Months${noOfMonths}` = noOfMonths * noOfFloors;
    params.data.units = noOfMonths * noOfFloors;
    return forceToNumber(noOfMonths * noOfFloors);
  }
  return forceToNumber(units);
}

import { useSelector } from "react-redux";
import { useEffect, useState } from "react";
import { useProgramFields } from "src/hooks";

import {
  generateReport,
  calculateValue,
  filterRowDataKeys,
  findParentCategoryForProject,
} from "../../utils/helpers";
import {
  BASE_PAGE_DEPARTMENT,
  BASE_PAGE_CATEGORY,
  OTHER_TITLES,
} from "../../utils/constants";
import { fetchData } from "src/components/SidebarPages/Fleet/utils";
import { filterTables, showErrorMsg, showWarningMsg } from "src/utils";

const useRealTimeReport = ({
  title,
  gridApi,
  rowData,
  isUseTemplate,
  isReport = true,
  downloadExcel,
}) => {
  const [dataSet, setDataSet] = useState();
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [templates, setTemplates] = useState([]);
  const [loading, setLoading] = useState(false);

  const reportTemplate = templates.find(
    (template) => template.reportName === title
  );

  const programFields = useProgramFields();
  const reportsCategorization = programFields?.["Reports Categorization"] || {};
  const selectedCategory =
    findParentCategoryForProject(reportsCategorization, title) ||
    BASE_PAGE_DEPARTMENT;

  const isBasePage = selectedCategory === BASE_PAGE_DEPARTMENT || isUseTemplate;

  const newTitle =
    OTHER_TITLES.find((item) => item.name === title)?.currentTitle || title;

  const trimmedTitle = newTitle || title?.replace(/\s/g, "");
  const columnDefs =
    gridApi?.columnModel.displayedColumns
      .filter(
        (column) =>
          !column?.userProvidedColDef?.hasOwnProperty("pdfExportOptions") ||
          !column.userProvidedColDef?.pdfExportOptions?.skipColumn
      )
      .map((column) => column.colId)
      .filter((value) => typeof value === "string") || [];

  const initialTableColumns = gridApi?.columnModel.displayedColumns
    .filter(
      (column) =>
        !column?.userProvidedColDef?.hasOwnProperty("pdfExportOptions") ||
        !column?.userProvidedColDef?.pdfExportOptions?.skipColumn
    )
    .map((column) => column.colId);

  const [tableColumns, setTableColumns] = useState(
    isReport
      ? initialTableColumns.slice(0, 7)
      : downloadExcel
      ? initialTableColumns
      : initialTableColumns.slice(0, 10)
  );

  const [tableParameters, setTableParameters] = useState([]);

  const { userConfiguration } = useSelector((state) => state.userConfig);

  const onCancelReport = () => setIsModalVisible(false);

  const openReport = () => {
    if (!!!dataSet?.Name) {
      showWarningMsg({ content: "There is no dataset for this report." });
      return;
    }

    setIsModalVisible(true);
  };

  const getReportConfig = () => {
    setLoading(true);

    Promise.all([
      fetchData("reportConfiguration"),
      filterTables("reportsAvailable", "categoryName", BASE_PAGE_CATEGORY),
    ])
      .then(([reportConfigResult, templatesResult]) => {
        const filterTitle = !isBasePage ? trimmedTitle : BASE_PAGE_CATEGORY;

        const reportConfigBasePage = reportConfigResult.find(
          (reportConfig) => reportConfig.categoryName === BASE_PAGE_CATEGORY
        );

        const reportConfig = reportConfigResult.find(
          (reportConfig) =>
            reportConfig.department === selectedCategory &&
            reportConfig.categoryName === filterTitle
        );

        const dataset = (reportConfigBasePage &&
          reportConfigBasePage.datasets.find(
            (dataset) => dataset.Name === `${trimmedTitle}DataSet`
          )) ||
          (reportConfig &&
            reportConfig.datasets.find(
              (dataset) => dataset.Name === `${trimmedTitle}DataSet`
            )) || {
            Name: "",
          };

        setDataSet(dataset);
        setTemplates(templatesResult);
      })
      .catch((error) => {
        showErrorMsg({ content: "There was an error while getting Report." });
        console.error("An error occurred:", error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    getReportConfig();
  }, []);

  const finalRowData = filterRowDataKeys(rowData, tableColumns);

  const columnWidth = calculateValue(tableColumns);

  const modifyReport = ({ tableColumns, reportTemplate }) => {
    const { reportObj, ...rest } = reportTemplate;
    return reportTemplate;
  };

  const customTemplate = isUseTemplate
    ? modifyReport({ tableColumns, reportTemplate })
    : generateReport({
        title: trimmedTitle,
        dataSet,
        columnWidth,
        tableColumns,
        tableParameters,
        userConfiguration,
      });

  return {
    loading,
    columnDefs,
    tableColumns,
    finalRowData,
    customTemplate,
    isModalVisible,
    reportTemplate,
    tableParameters,
    openReport,
    onCancelReport,
    setTableColumns,
    setTableParameters,
  };
};

export default useRealTimeReport;

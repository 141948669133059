/**
 * Handles the transfer change of a user between teams.
 *
 * @param {Object} params - The parameters for the function.
 * @param {'remove' | 'add'} params.type - The type of transfer change ("add" or "remove").
 * @param {string} params.userId - The ID of the user being transferred.
 * @param {Array} params.selectedTeamTmp - The temporary list of selected teams.
 * @param {Function} params.setSelectedTeamTmp - The function to update the temporary list of selected teams.
 * @param {Object} params.allUsersObj - An object containing all users.
 * @param {Array} params.allTeams - The list of all teams.
 */
function onTransferChange({
  type,
  userId,
  selectedTeamTmp,
  setSelectedTeamTmp,
  allUsersObj,
  allTeams,
  comparator,
}) {
  // Remove the user from the selected column
  if (type === "remove") {
    const userTeamIndex = selectedTeamTmp.findIndex((team) =>
      team.members.some((member) => member.identityId === userId)
    );
    if (userTeamIndex > -1) {
      const userTeam = selectedTeamTmp[userTeamIndex];
      const newMembers = userTeam.members.filter(
        (member) => member.identityId !== userId
      );
      if (newMembers.length > 0) {
        setSelectedTeamTmp((prev) => [
          ...prev.slice(0, userTeamIndex),
          { ...userTeam, members: newMembers },
          ...prev.slice(userTeamIndex + 1),
        ]);
      } else {
        setSelectedTeamTmp((prev) => [
          ...prev.slice(0, userTeamIndex),
          ...prev.slice(userTeamIndex + 1),
        ]);
      }
    }
    // Add the user to the selected column
  } else if (type === "add") {
    addCase({
      userId,
      allUsersObj,
      selectedTeamTmp,
      setSelectedTeamTmp,
      allTeams,
      comparator,
    });
  }
}

export default onTransferChange;

/**
 * Handles the transfer change event by adding a user to the selected team.
 *
 * @param {Object} params - The parameters object.
 * @param {string} params.userId - The ID of the user to add.
 * @param {Object} params.allUsersObj - An object containing all users, keyed by user ID.
 * @param {Array} params.selectedTeamTmp - The current state of the selected team.
 * @param {Function} params.setSelectedTeamTmp - The state setter function for the selected team.
 * @param {Array} params.allTeams - An array of all teams.
 */
const addCase = ({
  userId,
  allUsersObj,
  selectedTeamTmp,
  setSelectedTeamTmp,
  allTeams,
  comparator,
}) => {
  const userToAdd = allUsersObj[userId];
  const teamsWithUser =
    allTeams.filter((team) =>
      team.members.some((member) => member.identityId === userId)
    ) || [];

  if (teamsWithUser.length === 0) {
    setSelectedTeamTmp((prev) => {
      const teamIndex = prev.findIndex(
        (selectedTeam) => selectedTeam.value === "Team"
      );
      if (teamIndex !== -1) {
        return [
          ...prev.slice(0, teamIndex),
          {
            ...prev[teamIndex],
            members: [...prev[teamIndex].members, userToAdd],
          },
          ...prev.slice(teamIndex + 1),
        ];
      }
      return [...prev, { value: "Team", members: [userToAdd], teamId: [] }];
    });
    return;
  }
  if (teamsWithUser.length === 1) {
    const team = teamsWithUser[0];
    let teamIndex = -1;
    if (comparator === "teamId") {
      teamIndex = selectedTeamTmp.findIndex((selectedTeam) =>
        selectedTeam.teamId.includes(team.teamId)
      );
    } else if (comparator === "teamName") {
      teamIndex = selectedTeamTmp.findIndex(
        (selectedTeam) => selectedTeam.value === team.teamName
      );
    }
    if (teamIndex !== -1) {
      setSelectedTeamTmp((prev) => [
        ...prev.slice(0, teamIndex),
        {
          ...prev[teamIndex],
          members: [...prev[teamIndex].members, userToAdd],
        },
        ...prev.slice(teamIndex + 1),
      ]);
      return;
    } else {
      setSelectedTeamTmp((prev) => {
        const teamIndex = prev.findIndex(
          (selectedTeam) => selectedTeam.value === "Team"
        );
        if (teamIndex !== -1) {
          return [
            ...prev.slice(0, teamIndex),
            {
              ...prev[teamIndex],
              members: [...prev[teamIndex].members, userToAdd],
            },
            ...prev.slice(teamIndex + 1),
          ];
        }
        return [...prev, { value: "Team", members: [userToAdd], teamId: [] }];
      });
      return;
    }
  }
  let found = false;
  for (const team of teamsWithUser) {
    if (found) {
      break;
    }

    let existingTeamIndex = -1;
    if (comparator === "teamId") {
      existingTeamIndex = selectedTeamTmp.findIndex((selectedTeam) =>
        selectedTeam.teamId.includes(team.teamId)
      );
    } else if (comparator === "teamName") {
      existingTeamIndex = selectedTeamTmp.findIndex(
        (selectedTeam) => selectedTeam.value === team.teamName
      );
    }

    if (existingTeamIndex !== -1) {
      found = true;
      setSelectedTeamTmp((prevSelectedTeams) => {
        const updatedTeams = [...prevSelectedTeams];
        const existingTeam = updatedTeams[existingTeamIndex];

        const isUserMember = existingTeam.members.some(
          (member) => member.identityId === userId
        );

        if (!isUserMember) {
          existingTeam.members.push({ nameOfUser: userId, identityId: userId });
        }

        return updatedTeams;
      });
      return;
    } else {
      continue;
    }
  }

  if (!found) {
    setSelectedTeamTmp((prev) => {
      const teamIndex = prev.findIndex(
        (selectedTeam) => selectedTeam.value === "Team"
      );
      if (teamIndex !== -1) {
        return [
          ...prev.slice(0, teamIndex),
          {
            ...prev[teamIndex],
            members: [...prev[teamIndex].members, userToAdd],
          },
          ...prev.slice(teamIndex + 1),
        ];
      }
      return [...prev, { value: "Team", members: [userToAdd], teamId: [] }];
    });
  }
};

import { forceToNumber } from "../../../../../Accounting/Tabs/Payments/components/NewPayment/utils/checkers";
import {
  chartJsBackgroundColor,
  chartJsBorderColor,
} from "../../../../../../ChartButton/utils/constants";
import { Doughnut } from "react-chartjs-2";

function AdditionalRentalChart({ amounts = {} }) {
  const freeMonths =
    forceToNumber(amounts.freeMonths || 0) +
    forceToNumber(amounts?.defaultMonths || 0);
  const additionalMonths = forceToNumber(amounts.months || 0);

  const chartLabels = ["Free Months", "Additional Months"];
  const chartData = [freeMonths, additionalMonths];

  const data = {
    labels: chartLabels,
    datasets: [
      {
        label: "Type",
        data: chartData,
        backgroundColor: chartJsBackgroundColor,
        borderColor: chartJsBorderColor,
        borderWidth: 1,
      },
    ],
  };

  return (
    <Doughnut
      data={data}
      options={{
        responsive: true,
        maintainAspectRatio: false,
        plugins: {
          title: {
            display: true,
            text: "Rental Months For Service",
          },
        },
      }}
    />
  );
}

export default AdditionalRentalChart;

import { useEffect } from "react";
import { useSelector } from "react-redux";
import { SmallLoadableComp } from "src/components/Sidebars/components";
import { formatAutomationResponses } from "../../../../../../Header/components/HeaderAutomations/utils";
import AutomationResponses from "../../../../../../Header/components/HeaderAutomations/components/AutomationResponses/AutomationResponses";
import { useEditLogs } from "../../../../../../../hooks";
import { message } from "antd";
import { automationResponseLogsFilters } from "../../../../../../Header/components/HeaderAutomations/HeaderAutomations";

const UserAutomationExecutions = () => {
  const {
    userConfiguration: { userName },
  } = useSelector((state) => state.userConfig);

  const { fetchedLogs, handleFetchLogs } = useEditLogs();

  useEffect(() => {
    message.loading("Retrieving log data. Please wait...", 0);

    handleFetchLogs({ filters: automationResponseLogsFilters });
  }, []);

  return (
    <SmallLoadableComp loading={!Array.isArray(fetchedLogs)}>
      <AutomationResponses
        {...{
          responses: formatAutomationResponses(fetchedLogs || []).filter(
            ({ toUsers = [] }) => toUsers.includes(userName)
          ),
        }}
      />
    </SmallLoadableComp>
  );
};

export default UserAutomationExecutions;

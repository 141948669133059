import { API } from "aws-amplify";
import axios from "axios";
import moment from "moment";
import { Get } from "../../../utils/functions";
import { message } from "antd";
import EmailBox from "./components/EmailBox/EmailBox";

/**
 *
 * @param {Array} array current list
 * @param {Object} newElement new elemenet to add in current list
 * @returns
 */
export const addToArray = (array, newElement) => [...array, newElement];
/**
 *
 * @param {String} table db table
 * @param {String} recordId uuid of db record
 * @param {String} key key in table to update
 * @param {Array||Object} value value to update selected key
 * @returns
 */
export const updateTable = async (table, recordId, key, value) => {
  try {
    API.patch(table, `/${table}/${recordId}`, {
      body: [{ key, value }],
    });
  } catch (err) {
    console.log("error", err);
    console.error(err);
  }
};

/**
 *
 * @param {String} path path of the function in api to call
 * @param {Object} body object with params to pass
 * @returns
 */
export const callGmailAPI = async (path, body) =>
  axios.post(
    // `http://localhost:8080/api/${path}`,
    `https://leadmanager-express-backend.ue.r.appspot.com/api/${path}`,
    body
  );

/**
 *
 * @param {Array} array array to sort
 * @param {String} key string of key that you want to sort
 * @returns
 */
export const sortBy = (array, key) => array?.sort((a, b) => b[key] - a[key]);

/**
 *
 * @param {Array} array array that you want to get length
 * @returns
 */
export const getLength = (array) => array?.length;

/**
 * @param {Array} array checked emails
 * @returns boolean value status unread labeled emails
 */
export const getIsUnreadStatus = (checkedList) =>
  checkedList?.some((item) => item?.labels?.includes("UNREAD"));

/**
 * @param {Array} array checked emails
 * @returns boolean value status for important labeled emails
 */
export const getIsImportantStatus = (checkedList) =>
  checkedList?.some((item) => item?.labels?.includes("IMPORTANT"));

/**
 * @param {Array} array checked emails
 * @returns boolean value unread & read labeled emails
 */

export const getIsUnreadAndRead = (checkedList) =>
  checkedList?.some((checkedListItem) =>
    checkedListItem?.labels?.includes("UNREAD")
  ) &&
  checkedList?.some(
    (checkedListItem) => !checkedListItem?.labels?.includes("UNREAD")
  );

/**
 * @param {Array} array checked emails
 * @returns boolean value for important & NOT important labeled emails
 */
export const getIsImportantAndNotImportant = (checkedList) =>
  checkedList?.some((checkedListItem) =>
    checkedListItem?.labels?.includes("IMPORTANT")
  ) &&
  checkedList?.some(
    (checkedListItem) => !checkedListItem?.labels?.includes("IMPORTANT")
  );

export const getFilteredEmails = (
  emails,
  drafts,
  category,
  searchValue,
  filterObject
) => {
  // Determine which emails to filter based on the category
  const emailsToFilter =
    category === "IMPORTANT"
      ? [...emails, ...drafts] // Combine emails and drafts for 'IMPORTANT'
      : category === "DRAFT"
      ? drafts // Use only drafts for 'DRAFT'
      : emails?.filter((email) => !drafts?.includes(email)); // Exclude drafts for other categories

  return (
    Array.isArray(emailsToFilter) &&
    emailsToFilter
      ?.filter((email) => email.labels?.includes(category)) // Filter emails by label (category)
      .filter((email) => {
        const createdAt = new Date(email.date);

        if (searchValue) {
          // Check if search value matches email snippet, sender, or creation date
          const snippetIncludesSearch = email?.snippet
            ?.toLowerCase()
            ?.includes(searchValue?.toLowerCase());

          const senderIncludesSearch = email.from
            .toLowerCase()
            .includes(searchValue.toLowerCase());
          const createdAtIncludesSearch = createdAt
            .toLocaleDateString()
            .includes(searchValue.toLowerCase());

          return (
            snippetIncludesSearch ||
            senderIncludesSearch ||
            createdAtIncludesSearch
          );
        }

        if (filterObject) {
          // Apply filters from filterObject: sender, subject, and date range
          const fromFilter =
            !filterObject.from ||
            email.from.toLowerCase().includes(filterObject.from.toLowerCase());
          const subjectFilter =
            !filterObject.subject ||
            email.subject
              .toLowerCase()
              .includes(filterObject.subject.toLowerCase());
          const startDateFilter =
            !filterObject.date ||
            (filterObject.date[0] &&
              createdAt >= new Date(filterObject.date[0].$d));
          const endDateFilter =
            !filterObject.date ||
            (filterObject.date[1] &&
              createdAt <= new Date(filterObject.date[1].$d));

          return (
            fromFilter && subjectFilter && startDateFilter && endDateFilter
          );
        }

        return true; // No search or filters, include all emails
      })
      .sort((a, b) => new Date(b.date) - new Date(a.date)) // Sort emails by date (newest first)
  );
};

/**
 * @param {Array} array emails
 * @returns filtered emails unread labeled
 */
export const getUnreadEmails = (emails) =>
  emails?.filter((email) => email.labels?.includes("UNREAD")) || [];

/**
 * @param {Array} array emails
 * @returns filtered emails read labeled
 */
export const getReadEmails = (emails) =>
  emails?.filter((email) => !email.labels?.includes("UNREAD")) || [];

export function generateUsersEmailsOptions(userConfig, roles) {
  // Create a Set to store unique usernames from the allUsers list in userConfig
  const userNamesSet = new Set(
    userConfig.allUsers.Items.map(({ userName }) => userName).filter(Boolean) // Filter out null/undefined usernames
  );

  // Add emails from roles array to the Set if they exist
  roles.forEach(({ email }) => {
    if (email) {
      userNamesSet.add(email);
    }
  });

  // Convert Set to array and return an array of objects with 'value' and 'label'
  return Array.from(userNamesSet)?.map((email) => ({
    value: email.toLowerCase(), // Use lowercase for 'value'
    label: email, // Use original case for 'label'
  }));
}

export const getTemplateFiles = async (
  docItems,
  tableTemplateData,
  setTableTemplateData
) => {
  if (docItems?.length > 0 && tableTemplateData) {
    const templateDataWithAttachmentsIds = tableTemplateData.map((template) => {
      const updatedTemplate = { ...template };

      if (updatedTemplate.predefinedAttachments.length > 0) {
        updatedTemplate.templateAttachments =
          updatedTemplate.templateAttachments || [];

        const uniqueIds = new Set(updatedTemplate.templateAttachments);

        docItems.forEach((docItem) => {
          if (updatedTemplate.predefinedAttachments.includes(docItem.folder)) {
            if (!uniqueIds.has(docItem.id)) {
              updatedTemplate.templateAttachments.push(docItem.id);
              uniqueIds.add(docItem.id);
            }
          }
        });
      }

      return updatedTemplate;
    });

    setTableTemplateData(templateDataWithAttachmentsIds);
  }
};

// communicationFunctions.js

export const getData = async (
  recordName,
  recordId,
  dispatch,
  fetchEmailsStart,
  fetchEmailsSuccess,
  fetchEmailsFailure,
  fetchEmailsFinished,
  showErrorMsg,
  authData
) => {
  try {
    // Fetch record data based on record name and ID
    const recordData = await Get(recordName, recordId);

    // Handle case when no data is returned
    if (!recordData) {
      message.error("Failed to fetch emails!");
      console.error("recordData is undefined or null");
      return;
    }

    // Handle case when no emails or drafts are found in the record data
    if (!recordData.emails && !recordData.drafts) {
      message.info("No emails were found for this record");
      // Dispatch success action with empty emails and drafts
      dispatch(
        fetchEmailsSuccess({
          emails: [],
          drafts: [],
          recordData: {
            recordName,
            recordId,
            ...recordData,
          },
        })
      );
      return;
    }

    // Function to fetch emails and drafts via API
    const fetchEmails = async (emails, drafts) => {
      try {
        dispatch(fetchEmailsStart()); // Dispatch start action
        // Fetch emails and drafts in parallel using Promise.all
        const [emailsResponse, draftsResponse] = await Promise.all([
          callGmailAPI("getRecordEmails", {
            list: emails || [], // Fallback to empty array if emails is undefined
            authData: authData === "bnVsbA==" ? null : authData, // Use authData or null if base64 'null'
          }),
          callGmailAPI("getRecordDrafts", {
            list: drafts || [], // Fallback to empty array if drafts is undefined
            authData: authData === "bnVsbA==" ? null : authData,
          }),
        ]);

        // Dispatch success action with fetched emails and drafts
        dispatch(
          fetchEmailsSuccess({
            emails: emailsResponse?.data || [], // Fallback to empty array if no data
            drafts: draftsResponse?.data || [], // Fallback to empty array if no data
            recordData: {
              recordName,
              recordId,
              ...recordData,
            },
          })
        );
      } catch (error) {
        console.error("Error in fetching emails/drafts:", error);
        dispatch(fetchEmailsFailure(error)); // Dispatch failure action on error
        showErrorMsg(); // Show error message
      } finally {
        // Dispatch finished action after a short delay
        setTimeout(() => {
          dispatch(fetchEmailsFinished());
        }, 500);
      }
    };

    // Fetch emails and drafts from record data
    fetchEmails(recordData.emailMessagesIds, recordData.drafts);
  } catch (error) {
    // Handle errors in the outer try block
    dispatch(fetchEmailsFinished()); // Ensure finished action is dispatched
    console.error(
      "Error in fetching record information:",
      `recordName:${recordName} recordId:${recordId}`,
      error
    );
    message.error("Failed to fetch emails!"); // Show error message
  }
};

export const openEmailBox = (
  id,
  emailBoxes,
  setSelectedDraft,
  setEmailBoxes,
  mobile
) => {
  // Limit to 2 email boxes on desktop
  if (emailBoxes?.length === 2) {
    message.warning("You cannot open more than two mailboxes");
    return;
  }

  // Limit to 1 email box on mobile
  if (mobile && emailBoxes?.length === 1) {
    message.warning("You cannot open more than one mailbox on mobile");
    return;
  }

  setSelectedDraft(id); // Set the selected draft ID

  // Update the email boxes by adding a new EmailBox component
  setEmailBoxes((prev) =>
    [
      ...prev,
      {
        Component: EmailBox, // Add the EmailBox component
        key: emailBoxes?.length, // Use the current number of boxes as the key
        id: id, // Assign the ID to the new email box
      },
    ].filter((box) => {
      // Filter out undefined boxes when there are more than 1 box
      return emailBoxes?.length > 1 ? box.id !== undefined : true;
    })
  );
};

export const determineCategoryType = (
  rowData,
  invoice,
  proppedTemplateCat,
  exportOrEmailDropdown,
  recordDetails
) => {
  // If the accountRecordType is "Subcontractors", return "Subcontractors"
  if (rowData?.accountRecordType === "Subcontractors") {
    return "Subcontractors";
  }
  // If the accountRecordType is "Client", return "Clients"
  else if (rowData?.accountRecordType === "Client") {
    return "Clients";
  }
  // If invoice is true, return "Invoices"
  else if (invoice) {
    return "Invoices";
  }
  // If proppedTemplateCat is provided, return that value
  else if (proppedTemplateCat) {
    return proppedTemplateCat;
  }
  // If exportOrEmailDropdown is true, return "Settings"
  else if (exportOrEmailDropdown) {
    return "Settings";
  }
  // Otherwise, handle the category type based on recordDetails
  else {
    // If categoryType in recordDetails is "inspectionsview", return "Inspections"
    if (recordDetails?.categoryType.toLowerCase() === "inspectionsview") {
      return "Inspections";
    }

    // Capitalize the first letter of categoryType and return it
    return (
      recordDetails?.categoryType.charAt(0).toUpperCase() +
      recordDetails?.categoryType.slice(1)
    );
  }
};

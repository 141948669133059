import { Modal } from "antd";
import { useState } from "react";

import { HeaderButtons, VersionComparison } from "./components";
import { InputComponent } from "../../../../../Fleet/components";
import { MondayButton } from "../../../../../../commonComponents";
import { TickIcon } from "../../../../../../pages/Settings/settingsComponents/Roles/src";

import { sortByKey } from "./utils";

import "./CompareTakeOffVersions.scss";
import { useRedux } from "../../../../../hooks";
import { updateDocumentTitle } from "../../../../../../../utils";
import { XIcon } from "../../../../../Communication/assets";
import { useSelector } from "react-redux";
import useTakeOffAccessRights from "../../../../DataEntryGrid/tools/controllers/useTakeOffAccessRights";
import { checkPriceAccess } from "./utils/checkPriceAccess";

const CompareTakeOffVersions = ({
  visible,
  setVisible,
  estimation,
  forceDarkMode = false,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const [selectedKeys, setSelectedKeys] = useState({});
  const [, setTableDataArray] = useRedux("CompareTakeOffVersions");
  const { canViewPrice = false } = useTakeOffAccessRights("Take-Off");

  const takeOffVersionsKeys = Object.keys(estimation.versionServices);

  const takeOffWithVersionNames = [];

  const isDisabled = !!!selectedKeys?.first || !!!selectedKeys?.second;

  for (const key of takeOffVersionsKeys) {
    const versionName = estimation.versionServices[key].versionName;
    takeOffWithVersionNames.push({ value: key, label: versionName });
  }

  const handleChange = (key, e) => {
    setSelectedKeys((prev) => ({ ...prev, [key]: e }));
  };

  const sortedTakeOffs = (key) =>
    sortByKey(
      takeOffWithVersionNames.filter(
        (item) => item.value !== selectedKeys?.[key]
      ),
      "value"
    );

  const closeModal = () => {
    updateDocumentTitle(); // reset document title to "Lead Manager";
    setVisible(false);
    setSelectedKeys({});
  };

  return (
    <div
      id={`compareVersionsModal`}
      className={(isDarkMode || forceDarkMode) && "compareVersionsModalDark"}
    >
      <Modal
        {...{
          open: visible,
          title: "Choose versions of Take-Off to compare",
          closeIcon: <XIcon />,
          afterOpenChange: (event) => {
            event &&
              updateDocumentTitle({
                newTitle: "Choose versions of Take-Off to compare",
              });
          },
          onCancel() {
            setTableDataArray([]);
            closeModal();
          },
        }}
        getContainer={() => document.getElementById("compareVersionsModal")}
        footer={
          <div style={{ display: "flex", justifyContent: "flex-end" }}>
            <MondayButton
              Icon={<TickIcon width={17} height={17} />}
              onClick={closeModal}
            >
              Ok
            </MondayButton>
          </div>
        }
      >
        <div className="compareSelectorContainer">
          <div className="compareSelector">
            <InputComponent
              placeholder="Select first TakeOff"
              type="select"
              dropdownClassName={isDarkMode && "darkDropDown"}
              initialValue={
                selectedKeys?.first ? selectedKeys.first : undefined
              }
              customOptions={sortedTakeOffs("second")}
              onChange={(e) => handleChange("first", e)}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
            <InputComponent
              placeholder="Select second TakeOff"
              dropdownClassName={isDarkMode && "darkDropDown"}
              type="select"
              initialValue={
                selectedKeys?.second ? selectedKeys.second : undefined
              }
              customOptions={sortedTakeOffs("first")}
              onChange={(e) => handleChange("second", e)}
              filterOption={(input, option) =>
                (option?.label ?? "")
                  .toLowerCase()
                  .includes(input.toLowerCase())
              }
            />
          </div>
          <div className="compareSelector">
            <HeaderButtons
              {...{
                isDisabled,
                selectedKeys,
                estimation,
              }}
            />
          </div>
        </div>
        {selectedKeys && selectedKeys.first && selectedKeys.second && (
          <VersionComparison
            services={checkPriceAccess(
              estimation.versionServices[selectedKeys.first].services,
              canViewPrice
            )}
            serviceVersion={
              estimation.versionServices[selectedKeys.first].versionName
            }
            serviceVersionToCompare={
              estimation.versionServices[selectedKeys.second].versionName
            }
            serviceToCompare={checkPriceAccess(
              estimation.versionServices[selectedKeys.second].services,
              canViewPrice
            )}
          />
        )}
      </Modal>
    </div>
  );
};

export default CompareTakeOffVersions;

import { Modal } from "antd";
import { XIcon } from "../../Communication/assets";
import { useSelector } from "react-redux";
import "./ChartStyles.scss";
import { MondayButton } from "../../../commonComponents";
import { Bar, Line, Pie, Doughnut } from "react-chartjs-2";
import { formatCurrency } from "../../utils";

const ChartComponent = ({
  open,
  onCancel,
  options,
  title = false,
  children = false,
}) => {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const darkStyle = {
    theme: "ag-default-dark",
    background: { fill: "#1f212d" },
    title: { color: "#fff" },
    subtitle: { color: "#fff" },
    footnote: { stroke: "#fff" },
    legend: { stroke: "#acacca" },
  };

  const getChartOptions = (chartType) => {
    const baseOptions = {
      responsive: true,
      maintainAspectRatio: false,
      plugins: {
        title: {
          display: true,
          text: options.config.plugins.title.text,
        },
        tooltip: {
          callbacks: {
            label: (tooltipItem) => {
              if (options.typeOfChart === "Count") {
                return `${
                  chartType === "pie" || chartType === "doughnut"
                    ? tooltipItem.label
                    : "Count"
                }: ${tooltipItem.raw}`;
              }

              return `Amount: ${formatCurrency(tooltipItem.raw)}`;
            },
          },
        },
        legend: {
          position: "top",
          maxHeight: 300,
        },
      },
      onHover: (event) => {
        event.native.target.style.cursor = "pointer";
      },
    };

    if (chartType === "bar" || chartType === "line") {
      return {
        ...baseOptions,
        scales: {
          x: {
            title: {
              display: false,
            },
          },
          y: {
            title: {
              display: false,
            },
          },
        },
      };
    }

    // No axes for pie and doughnut charts
    return baseOptions;
  };

  return (
    <Modal
      title={title || "Chart"}
      open={open}
      centered
      onCancel={onCancel}
      className={`chartComponent ${isDarkMode && "chartComponentDark"}`}
      data-testid="progress-chart"
      closeIcon={<XIcon />}
      footer={[
        <MondayButton
          key="closeButton"
          className="mondayButtonRed"
          Icon={<XIcon />}
          onClick={onCancel}
        >
          Close
        </MondayButton>,
      ]}
    >
      {children}
      <div className="chartContainer">
        {options && options.chartType === "bar" && (
          <Bar
            data={options.data}
            options={
              isDarkMode
                ? { ...getChartOptions("bar"), ...darkStyle }
                : getChartOptions("bar")
            }
          />
        )}
        {options && options.chartType === "line" && (
          <Line
            data={options.data}
            options={
              isDarkMode
                ? { ...getChartOptions("line"), ...darkStyle }
                : getChartOptions("line")
            }
          />
        )}
        {options && options.chartType === "pie" && (
          <Pie
            data={options.data}
            options={
              isDarkMode
                ? { ...getChartOptions("pie"), ...darkStyle }
                : getChartOptions("pie")
            }
          />
        )}
        {options && options.chartType === "doughnut" && (
          <Doughnut
            data={options.data}
            options={
              isDarkMode
                ? { ...getChartOptions("doughnut"), ...darkStyle }
                : getChartOptions("doughnut")
            }
          />
        )}
      </div>
    </Modal>
  );
};

export default ChartComponent;

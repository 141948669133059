import {
  useState,
  Fragment,
  useEffect,
  forwardRef,
  useContext,
  useImperativeHandle,
} from "react";
import { useSelector } from "react-redux";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import { Tooltip, message, Checkbox, Popover } from "antd";
import { v4 as uuidv4 } from "uuid";

import {
  isYard,
  ONE_DAY,
  getFence,
  isEnteredAlert,
  FleetsLiveContext,
  loadLivePreference,
  getElapseInformation,
} from "../../../../../../utils";
import LiveAuditContext from "../../../../LiveAuditContext";
import { flashFrames, flashOptions } from "../../../../../../data";
import { pointsInsideAPolygon } from "../../../../../Map/utilities/pointsInsideAPolygon";
import { dayjsNY } from "../../../../../../../../../DateComponents/contants/DayjsNY";
import {
  WarningModal,
  MondayButton,
} from "../../../../../../../../../commonComponents";
import LinkStopModal from "../StopModal/LinkStopModal";
import { ActualAddressCard, PopoverStats } from "./components";
import { XIcon } from "../../../../../../../../Communication/assets";
import { LeftArrow, RightArrow } from "../../../../../../../../BasePage/src";
import { TickIcon } from "../../../../../../../../../pages/Settings/settingsComponents/Roles/src";
import { ReactComponent as WarningIcon } from "../../../../../../../../../../icons/warningTriangle.svg";
import { withinRadius } from "../../../../../../../../../pages/Payroll/Tabs/Activity/components/payrollActivityModalData";
import { InfoIconBlue } from "src/components/Header/forms/Scheduling/Subcomponents/SelectView/newModals/TrucksModal/HelpingComponents/icons";

import "./ActualPlanCard.scss";

const ActualPlanCard = forwardRef(
  (
    {
      trips,
      dayStats,
      timeChanges,
      onReasonSelect,
      dateBoundaries,
      discardedStops,
      linkedStopsData,
      selectedReasons,
      checkForOtherDay,
      setDateBoundaries,
      setDiscardedStops,
      createdAlerts = {},
      updateTripsHandler,
      setLinkedStopsData,
      allAuditsForVehicle,
      onLocationHover = () => {},
      onChangeActualTime = () => {},
      onBoundariesChanged = () => {},
      externalDateFilter = {
        start: null,
        end: null,
      },
    },
    ref
  ) => {
    //#region HOOKS
    const { isDarkMode } = useSelector((state) => state.darkMode);
    const { userConfiguration } = useSelector((state) => state.userConfig);

    const {
      allStops,
      geofences,
      showWritableInfo,
      actualTripsForDate,
      getPaginatedAlerts,
      isWritable: globalWriteMode,
    } = useContext(FleetsLiveContext);

    const {
      reasons,
      viewMode,
      addReason,
      auditStatus,
      goToLocation,
      onChangesMade,
      updateGoToLocation,
      selectedVehicle: vehicle,
    } = useContext(LiveAuditContext);

    const [stopData, setStopData] = useState(null);
    const [mode, setMode] = useState("CHANGE_ACTIVITY");
    const [discardWarning, setDiscardWarning] = useState(false);
    const [stopDiscardWarning, setStopDiscardWarning] = useState(false);

    useEffect(() => {
      //#region WRITE EFFECT
      if (!globalWriteMode) {
        setMode("CHANGE_ACTIVITY");
      }
    }, [globalWriteMode]);

    useEffect(() => {
      //#region BOUNDS CHANGE EFFECT
      let call = false;

      if (vehicle) {
        if (!dateBoundaries.vehicle) {
          call = true;
        } else {
          if (vehicle === dateBoundaries.vehicle) {
            call = true;
          }
        }
      }

      if (call) {
        onBoundariesChanged({
          minDate: dateBoundaries.minDate,
          maxDate: dateBoundaries.maxDate,
          fleetId: vehicle,
        });
      }
    }, [
      vehicle,
      dateBoundaries.minDate,
      dateBoundaries.maxDate,
      dateBoundaries.vehicle,
    ]);

    //#region REF HANDLES
    useImperativeHandle(
      ref,
      () => {
        return {
          changeMode() {
            setMode((prev) => {
              if (prev === "CHANGE_ACTIVITY") {
                return "CREATE_DISPATCH";
              }
              return "CHANGE_ACTIVITY";
            });
          },
          updateDateBoundaries(boundaries) {
            let selectedDate = loadLivePreference("liveSelectedDate");

            if (
              vehicle !== dateBoundaries.vehicle ||
              selectedDate !== dateBoundaries.date ||
              boundaries.minDate < dateBoundaries.minDate ||
              boundaries.maxDate > dateBoundaries.maxDate
            ) {
              setDateBoundaries({
                ...boundaries,
                vehicle: vehicle,
                date: selectedDate,
              });
              setTimeout(() => {
                checkForOtherDay(vehicle);
              }, 0);
            }
          },
          resetChanges() {
            setStopDiscardWarning(null);
            setDiscardWarning(null);
            setStopData(null);
            setDiscardedStops([]);
            setLinkedStopsData([]);
          },
          getActualChanges() {
            return {
              discardedStops,
              linkedStops: linkedStopsData,
            };
          },
        };
      },
      [
        vehicle,
        dateBoundaries,
        discardedStops,
        linkedStopsData,
        checkForOtherDay,
        actualTripsForDate,
      ]
    );

    function discardAlertHandler(alertUUID) {
      //#region DISCARD ALERT HANDLER
      let el = document.getElementById(alertUUID);
      if (el) {
        el.classList.add("activity-disregard");
      }

      setTimeout(() => {
        let tmpActivities = structuredClone(trips);
        let alertIndex = tmpActivities?.findIndex(
          ({ alertUUID: id }) => id === alertUUID
        );
        if (alertIndex > -1) {
          tmpActivities.splice(alertIndex, 1);
        }
        updateTripsHandler(tmpActivities, alertUUID);
        onChangesMade();
      }, 400);
    }

    function dragEndHandler({ source, destination }) {
      //#region DRAG END HANDLER
      if (!source || !destination) {
        return;
      }

      if (source?.index === destination?.index) {
        return;
      }

      let isConflicting = trips[destination?.index]["isConflicting"];
      if (!isConflicting) {
        message.error(
          "You cannot place a conflicting activity in between correct activities"
        );
        return;
      }

      /**
       * We need to check if the user has dragged an activity too far
       * we cannot allow a user to put actual activities wherever they want
       * as to not mess with the times of the actual activities. We want to
       * keep the editing to a short group. We know that we can never have one
       * single conflicting activity since it makes two to make it invalid
       */
      let loopIncrementor = 1;
      if (destination?.index < source?.index) {
        loopIncrementor = -1;
      }

      for (
        let i = source?.index;
        i !== destination?.index + loopIncrementor &&
        i >= 0 &&
        i < trips?.length;
        i += loopIncrementor
      ) {
        //if we find a non conflicting activity between the source and the destination
        if (!trips?.[i]?.["isConflicting"]) {
          message.error(
            "You cannot place an activity here as to not change the times of the correct activities"
          );
          return;
        }
      }

      let tmpTrips = structuredClone(trips);

      tmpTrips.splice(
        destination?.index,
        0,
        ...(tmpTrips || []).splice(source?.index, 1)
      );

      for (let i = 0; i < trips?.length; i++) {
        tmpTrips[i]["alertDateTime"] = trips[i]["alertDateTime"];
      }

      updateTripsHandler(tmpTrips);
      onChangesMade();
    }

    function canRenderCheckbox({
      fenceName,
      alertCode,
      audited,
      defaultAudited,
    }) {
      //#region CAN RENDER CHECKBOX
      if (isYard(fenceName)) {
        return false;
      }
      if (!isEnteredAlert(alertCode)) {
        return false;
      }
      if (audited || defaultAudited) {
        return false;
      }
      return true;
    }

    function isStopNotVisible(stop, data = {}) {
      //#region IS STOP HIDDEN
      const { thisTrip = null, nextTrip = null } = data;

      if (thisTrip && nextTrip) {
        if (
          thisTrip.fenceName === nextTrip.fenceName &&
          isEnteredAlert(thisTrip.alertCode) &&
          !isEnteredAlert(nextTrip.alertCode)
        ) {
          let condition = false;
          let relatedGeofence = geofences[getFence(thisTrip.fenceName)];

          const points = (relatedGeofence?.["points"] || [])?.map((e) => [
            e?.latitude,
            e?.longitude,
          ]);

          if (points?.length === 1) {
            const radius = (relatedGeofence?.["radius"] ?? 0) * 5280;
            condition = withinRadius(
              { lat: stop?.position?.lat, lng: stop?.position?.lng },
              points.map((e) => ({ lat: e?.[0], lng: e?.[1] })).at(0),
              radius
            );
          } else if (points?.length > 2) {
            condition = pointsInsideAPolygon(
              [stop?.position?.lat, stop?.position?.lng],
              points
            );
          }

          return condition;
        }
      }

      let d = Boolean(
        discardedStops?.find(
          ({ beginDate, endDate }) =>
            beginDate === stop?.beginDate && endDate === stop?.endDate
        )
      );

      if (d) {
        return d;
      }

      if (!d) {
        d = Boolean(
          linkedStopsData?.find(
            ({ beginAlert, endAlert }) =>
              beginAlert === stop?.beginDate && endAlert === stop?.endDate
          )
        );
      }

      if (!d) {
        d = Boolean(
          (allAuditsForVehicle?.["discardedStops"] || [])?.find(
            ({ beginDate, endDate }) =>
              beginDate === stop?.beginDate && endDate === stop?.endDate
          )
        );
      }

      if (!d) {
        d = Boolean(
          (allAuditsForVehicle?.["linkedStops"] || [])?.find(
            ({ beginAlert, endAlert }) =>
              beginAlert === stop?.beginDate && endAlert === stop?.endDate
          )
        );
      }

      return d;
    }

    function getStopsFromHead() {
      //#region HET STOPS FROM HEAD
      if (!(allStops?.[vehicle] || []).length) {
        return [];
      }

      let lowerBound = trips[0]?.["alertDateTime"];

      if (!trips?.length) {
        lowerBound = dateBoundaries.maxDate + ONE_DAY;
      }

      return allStops?.[vehicle]?.filter(
        ({ beginDate, endDate, geofenceUUID }) =>
          endDate <= lowerBound &&
          endDate >= dateBoundaries.minDate &&
          !isStopNotVisible({ beginDate, endDate, geofenceUUID })
      );
    }

    function getStopsFromTail() {
      //#region GET STOPS FROM TAIL
      if (!(allStops?.[vehicle] || []).length || !trips?.length) {
        return [];
      }

      return allStops?.[vehicle]?.filter(
        ({ beginDate, endDate, geofenceUUID }) =>
          beginDate >= trips[trips?.length - 1]?.["alertDateTime"] &&
          beginDate <= dateBoundaries.maxDate + ONE_DAY &&
          !isStopNotVisible({ beginDate, endDate, geofenceUUID })
      );
    }

    function getIntermediateStops(tripIndex) {
      //#region GET INTERMEDIATE STOPS
      if (tripIndex === trips?.length - 1) {
        return [];
      }

      let thisTrip = trips[tripIndex];
      let nextTrip = trips[tripIndex + 1];

      if (thisTrip?.defaultAudited && nextTrip?.defaultAudited) {
        return [];
      }

      if (thisTrip?.audited && nextTrip?.audited) {
        return [];
      }

      return allStops?.[vehicle]?.filter(
        ({ beginDate, endDate, geofenceUUID, position }) =>
          beginDate >= thisTrip?.["alertDateTime"] &&
          endDate <= nextTrip?.["alertDateTime"] &&
          !isStopNotVisible(
            { beginDate, endDate, geofenceUUID, position },
            {
              thisTrip,
              nextTrip,
            }
          )
      );
    }

    function isIdle(stop) {
      //#region IS IDLE
      return (stop?.stopType || "")?.toLowerCase()?.includes("idling");
    }

    /**
     * Function responsible for creating alerts from the stop data
     * @param {object} stop The selected stop data
     * @param {string} type "ENTER" | "EXIT"
     */
    function getTripsBody(stop, type = "") {
      //#region GET TRIPS BODY
      return {
        alertCode: `GEOFENCE_${type}`,
        activityId: undefined,
        alertUUID: uuidv4(),
        defaultAudited: false,
        fenceName: stop?.linkedFenceName,
        isConflicting: undefined,
        isEnter: `${type.toLowerCase().slice(0, type.length - 2)}-dot`,
        alertDateTime: type === "ENTERED" ? stop?.beginDate : stop?.endDate,
      };
    }

    /**
     * Function that adds animation to a number of cards
     * @param  {...string} ids The list of card ids
     */
    function flashIds(...ids) {
      //#region FLASH IDS
      for (let i = 0; i < ids?.length; i++) {
        let e = document.getElementById(ids[i]);
        if (e) {
          e.animate(flashFrames[isDarkMode ? "dark" : "light"], flashOptions);
        }
      }
    }

    /**
     * Function responsible for creating new alerts in order to make trips
     */
    function stopSubmitHandler(selectedFence) {
      //#region STOP SUBMIT HANDLER
      let newStartTrip = getTripsBody(
        { ...stopData, linkedFenceName: selectedFence?.name },
        "ENTERED"
      );
      let newEndTrip = getTripsBody(
        { ...stopData, linkedFenceName: selectedFence?.name },
        "EXITED"
      );

      let tmp = [...trips];
      let newStartIndex = trips?.findIndex(
        ({ alertDateTime }) => alertDateTime > newStartTrip?.alertDateTime
      );

      if (newStartIndex === -1) {
        newStartIndex = tmp.length;
      }

      tmp.splice(newStartIndex, 0, newStartTrip);

      let newEndIndex = tmp?.findIndex(
        ({ alertDateTime }) => alertDateTime > newEndTrip?.alertDateTime
      );
      if (newEndIndex === -1) {
        newEndIndex = tmp.length;
      }

      tmp.splice(newEndIndex, 0, newEndTrip);

      setStopData(null);
      setLinkedStopsData((prev) => [
        ...prev,
        {
          beginAlert: newStartTrip?.alertDateTime,
          endAlert: newEndTrip?.alertDateTime,
          beginUUID: newStartTrip?.alertUUID,
          endUUID: newEndTrip?.alertUUID,
          fenceName: newStartTrip?.fenceName,
        },
      ]);
      updateTripsHandler(tmp);
      onChangesMade();

      setTimeout(() => {
        flashIds(newStartTrip?.alertUUID, newEndTrip?.alertUUID);
      }, 50);
    }

    function stopDiscardHandler(stop) {
      //#region STOP DISCARD HANDLER
      let wait = 0;
      if (stopData) {
        setStopData(null);
        wait = 100;
      }

      setTimeout(() => {
        let el = document.getElementById(stop?.key);
        if (el) {
          el.classList.add("activity-disregard");
        }

        setTimeout(() => {
          setDiscardedStops((prev) => [
            ...prev,
            { beginDate: stop?.beginDate, endDate: stop?.endDate },
          ]);
          onChangesMade();
          el.classList.remove("activity-disregard");
        }, 400);
      }, wait);
    }

    function actualChangeConfirm({
      date,
      reason,
      alertUUID,
      originalDateTime,
    }) {
      //#region ACTUAL CHANGE CONFIRM
      const changeObject = {
        date,
        reason,
        alertUUID,
        originalDateTime,
        updatedAt: Date.now(),
        updatedBy: userConfiguration?.nameOfUser,
        cognitoUserId: userConfiguration?.cognitoUserId,
      };
      onChangeActualTime(changeObject);
    }

    //#region IS WRITABLE
    const isWritable = globalWriteMode && auditStatus !== "UPLOADED";

    //#region JSX
    return (
      <Fragment>
        <div
          className={`audit-locations-card audit-actual-plan-card${
            isDarkMode ? " actual-plan-dark" : ""
          }`}
          data-testid="audit-actual-plan"
        >
          <div className="locations-card-title card-edit-title">
            {mode !== "CREATE_DISPATCH" && isWritable ? (
              <Tooltip
                title={`Get data from ${dayjsNY(dateBoundaries.minDate)
                  .subtract(1, "d")
                  .format("MMM DD, YYYY")}`}
              >
                {/** 
                  //#region PAGINATE BACK
                */}
                <div
                  className="arrow-container"
                  onClick={() => {
                    let minDate = dayjsNY(dateBoundaries.minDate).subtract(
                      1,
                      "d"
                    );
                    setDateBoundaries({
                      ...dateBoundaries,
                      minDate: minDate.valueOf(),
                    });
                    getPaginatedAlerts({
                      date: dayjsNY(dateBoundaries.minDate).subtract(1, "d"),
                      fleetId: vehicle,
                    });
                  }}
                >
                  <LeftArrow height={16} width={16} fill="currentColor" />
                  <div className="arrow-container-text">
                    {dayjsNY(dateBoundaries.minDate).format("MMM DD")}
                  </div>
                </div>
              </Tooltip>
            ) : (
              <div className="arrow-container-text">
                {dayjsNY(dateBoundaries.minDate).format("MMM DD")}
              </div>
            )}
            <div className="actual-activity-title">
              {/** 
              //#region CARD TITLE
            */}
              <span>ACTUAL ACTIVITIES</span>
              {dayStats && (
                <Popover
                  content={
                    <PopoverStats
                      dayStats={{
                        ...(dayStats || {}),
                        locationsVisited: trips.length,
                      }}
                    />
                  }
                  trigger={"click"}
                  placement="bottom"
                  overlayClassName={`stop-modal-popover-content${
                    isDarkMode ? " popover-dark" : ""
                  }`}
                >
                  <InfoIconBlue
                    height={15}
                    width={15}
                    style={{ cursor: "pointer" }}
                  />
                </Popover>
              )}
              {mode === "CREATE_DISPATCH" && (
                <span style={{ color: "#fe4c4a", fontSize: "14px" }}>
                  &nbsp;(SELECT LOCATIONS)
                </span>
              )}
            </div>
            {mode !== "CREATE_DISPATCH" && isWritable ? (
              <Tooltip
                title={`Get data from ${dayjsNY(dateBoundaries.maxDate)
                  .add(1, "d")
                  .format("MMM DD, YYYY")}`}
              >
                {/** 
                  //#region PAGINATE FORWARDS
                */}
                <div
                  className="arrow-container"
                  onClick={() => {
                    let maxDate = dayjsNY(dateBoundaries.maxDate).add(1, "d");
                    setDateBoundaries({
                      ...dateBoundaries,
                      maxDate: maxDate.valueOf(),
                    });
                    getPaginatedAlerts({
                      date: dayjsNY(dateBoundaries.maxDate).add(1, "d"),
                      fleetId: vehicle,
                    });
                  }}
                >
                  <div className="arrow-container-text">
                    {dayjsNY(dateBoundaries.maxDate).format("MMM DD")}
                  </div>
                  <RightArrow height={16} width={16} fill="currentColor" />
                </div>
              </Tooltip>
            ) : (
              <div className="arrow-container-text">
                {dayjsNY(dateBoundaries.maxDate).format("MMM DD")}
              </div>
            )}
          </div>
          {viewMode === "MAP" && vehicle && (
            <div className="location-option-panel">
              {/** 
                //#region GO TO LOCATION
              */}
              <span
                className={`location-option ${
                  goToLocation === "CLICK" ? "location-option-selected" : ""
                }`}
                onClick={() => {
                  updateGoToLocation("HOVER");
                }}
              >
                Location Hover
              </span>
              <span
                className={`location-option ${
                  goToLocation === "HOVER" ? "location-option-selected" : ""
                }`}
                onClick={() => {
                  updateGoToLocation("CLICK");
                }}
              >
                Location Click
              </span>
            </div>
          )}
          <DragDropContext onDragEnd={dragEndHandler}>
            <Droppable
              droppableId="activities-droppable"
              key="activities-droppable"
            >
              {/** 
                //#region DRAG DROP CONTAINER
              */}
              {(provided) => {
                return (
                  <div
                    className="locations-card-info-container"
                    id="live-audit-actual"
                    key="live-audit-actual"
                    ref={provided?.innerRef}
                    {...provided?.droppableProps}
                  >
                    {getStopsFromHead().flatMap((stop, index) => {
                      if (externalDateFilter.start && externalDateFilter.end) {
                        if (
                          stop.endDate < externalDateFilter.start ||
                          stop.beginDate > externalDateFilter.end
                        ) {
                          return [];
                        }
                      }

                      //#region STOPS FROM HEAD
                      return (
                        <ActualAddressCard
                          {...{
                            type: "STOP",
                            id: `stop-head-${index}`,
                            cardKey: `stop-head-${index}`,
                            address: stop?.address,
                            onChangesMade,
                            onLocationHover,
                            goToLocation,
                            reasons,
                            selectedReasons,
                            onReasonSelect,
                            addReason,
                            stop,
                            isDarkMode,
                            isWritable,
                            onInfoClick() {
                              if (isWritable) {
                                setStopData(stop);
                              } else if (!globalWriteMode) {
                                showWritableInfo();
                              }
                            },
                            discardButtonHide: mode === "CREATE_DISPATCH",
                            classNameAddition: `location-stop${
                              isIdle(stop) ? " idle" : " stop"
                            } ${!isWritable ? "item-non-clickable" : ""}`,
                            dragHandleRender: false,
                            discardHandle() {
                              stopDiscardHandler({
                                ...stop,
                                key: `stop-head-${index}`,
                              });
                              updateTripsHandler();
                            },
                          }}
                          key={`stop-head-${index}`}
                        />
                      );
                    })}
                    {/** 
                      //#region TRIPS MAP
                    */}
                    {trips?.flatMap(
                      (
                        {
                          alertDateTime,
                          fenceName,
                          isEnter,
                          alertUUID,
                          isConflicting,
                          alertCode,
                          audited,
                          defaultAudited,
                        },
                        index
                      ) => {
                        if (
                          externalDateFilter.start &&
                          externalDateFilter.end
                        ) {
                          if (
                            alertDateTime < externalDateFilter.start ||
                            alertDateTime > externalDateFilter.end
                          ) {
                            return [];
                          }
                        }

                        let { distanceInMile, durationText } =
                          getElapseInformation({
                            thisTrip: trips?.[index - 1],
                            nextTrip: trips[index],
                            geofences,
                          });

                        return (
                          <Fragment>
                            <Draggable
                              key={`${alertUUID}-${index}`}
                              draggableId={alertUUID}
                              index={index}
                            >
                              {/** 
                                //#region TRIPS CARDS
                              */}
                              {(dragProvided) => {
                                return (
                                  <ActualAddressCard
                                    {...{
                                      id: alertUUID,
                                      type: "ACTIVITY",
                                      isEnter,
                                      timeChanges,
                                      onLocationHover,
                                      createdAlerts,
                                      dragRef: dragProvided?.innerRef,
                                      cardKey: `${index}`,
                                      address: fenceName,
                                      onChangesMade,
                                      goToLocation,
                                      isDarkMode,
                                      isWritable,
                                      durationText,
                                      alertDateTime,
                                      distanceInMile,
                                      actualChangeConfirm,
                                      dragHandleHide:
                                        !isConflicting || !isWritable,
                                      discardButtonHide:
                                        audited ||
                                        defaultAudited ||
                                        mode === "CREATE_DISPATCH",
                                      dotClassName: isEnter,
                                      draggableProps:
                                        dragProvided?.draggableProps,
                                      dragHandleProps:
                                        dragProvided?.dragHandleProps,
                                      discardHandle() {
                                        discardAlertHandler(alertUUID);
                                        updateTripsHandler();
                                      },
                                    }}
                                    key={`${index}`}
                                  >
                                    {mode === "CREATE_DISPATCH" &&
                                      canRenderCheckbox({
                                        fenceName,
                                        alertCode,
                                        audited,
                                        defaultAudited,
                                      }) && (
                                        <Checkbox
                                          className={`actual-location-check --FENCE:${fenceName}--TIME:${dayjsNY(
                                            alertDateTime
                                          ).format()}--`}
                                        />
                                      )}
                                  </ActualAddressCard>
                                );
                              }}
                            </Draggable>
                            {getIntermediateStops(index).flatMap((stop, i) => {
                              if (
                                externalDateFilter.start &&
                                externalDateFilter.end
                              ) {
                                if (
                                  stop.endDate < externalDateFilter.start ||
                                  stop.beginDate > externalDateFilter.end
                                ) {
                                  return [];
                                }
                              }
                              //#region INTERMEDIATE STOPS
                              return (
                                <ActualAddressCard
                                  {...{
                                    type: "STOP",
                                    id: `stop-intermediate-${i}-${alertUUID}`,
                                    cardKey: `stop-intermediate-${i}-${alertUUID}`,
                                    address: stop?.address,
                                    onChangesMade,
                                    stop,
                                    reasons,
                                    selectedReasons,
                                    onReasonSelect,
                                    addReason,
                                    goToLocation,
                                    isDarkMode,
                                    isWritable,
                                    onLocationHover,
                                    onInfoClick() {
                                      if (isWritable) {
                                        setStopData(stop);
                                      } else if (!globalWriteMode) {
                                        showWritableInfo();
                                      }
                                    },
                                    discardButtonHide:
                                      mode === "CREATE_DISPATCH",
                                    classNameAddition: `location-stop${
                                      isIdle(stop) ? " idle" : " stop"
                                    } ${
                                      !isWritable ? "item-non-clickable" : ""
                                    }`,
                                    dragHandleRender: false,
                                    discardHandle() {
                                      stopDiscardHandler({
                                        ...stop,
                                        key: `stop-intermediate-${i}-${alertUUID}`,
                                      });
                                      updateTripsHandler();
                                    },
                                  }}
                                  key={`stop-intermediate-${i}-${alertUUID}`}
                                />
                              );
                            })}
                          </Fragment>
                        );
                      }
                    )}
                    {getStopsFromTail().flatMap((stop, index) => {
                      if (externalDateFilter.start && externalDateFilter.end) {
                        if (
                          stop.endDate < externalDateFilter.start ||
                          stop.beginDate > externalDateFilter.end
                        ) {
                          return [];
                        }
                      }
                      //#region STOPS FROM TAIL
                      return (
                        <ActualAddressCard
                          {...{
                            type: "STOP",
                            id: `stop-tail-${index}`,
                            cardKey: `stop-tail-${index}`,
                            address: stop?.address,
                            stop,
                            reasons,
                            onChangesMade,
                            selectedReasons,
                            onReasonSelect,
                            addReason,
                            goToLocation,
                            isDarkMode,
                            isWritable,
                            onLocationHover,
                            onInfoClick() {
                              if (isWritable) {
                                setStopData(stop);
                              } else if (!globalWriteMode) {
                                showWritableInfo();
                              }
                            },
                            discardButtonHide: mode === "CREATE_DISPATCH",
                            classNameAddition: `location-stop${
                              isIdle(stop) ? " idle" : " stop"
                            } ${!isWritable ? "item-non-clickable" : ""}`,
                            dragHandleRender: false,
                            discardHandle() {
                              stopDiscardHandler({
                                ...stop,
                                key: `stop-tail-${index}`,
                              });
                              updateTripsHandler();
                            },
                          }}
                          key={`stop-tail-${index}`}
                        />
                      );
                    })}
                    {provided?.placeholder}
                  </div>
                );
              }}
            </Droppable>
          </DragDropContext>
          {/** 
            //#region WARNING MODAL
          */}
          {(discardWarning || stopDiscardWarning) && (
            <WarningModal
              visible={!!discardWarning || !!stopDiscardWarning}
              setVisible={
                discardWarning ? setDiscardWarning : setStopDiscardWarning
              }
              darkMode={isDarkMode}
              title="Warning Message"
              closable={true}
              className="logout-warning-modal"
            >
              <div
                className="logout-modal-body"
                style={{ textAlign: "center" }}
              >
                <span>
                  <WarningIcon />
                </span>
                {!!discardWarning ? (
                  <>
                    <p style={{ textAlign: "center" }}>
                      Are you sure you want to discard the activity?
                    </p>
                    <b style={{ marginBottom: "1rem" }}>{`${discardWarning[
                      "alertCode"
                    ].replace("GEOFENCE_", "")} ${getFence(
                      discardWarning["fenceName"]
                    )} on ${dayjsNY(discardWarning["alertDateTime"]).format(
                      "MMM DD, hh:mm a"
                    )}`}</b>
                  </>
                ) : (
                  <>
                    <p style={{ textAlign: "center" }}>
                      Are you sure you want to discard the stop?
                    </p>
                    <b style={{ marginBottom: "1rem" }}>{`${
                      stopDiscardWarning?.["address"]
                    }, from ${dayjsNY(stopDiscardWarning?.["beginDate"]).format(
                      "MMM DD, hh:mm a"
                    )} to ${dayjsNY(stopDiscardWarning?.["endDate"]).format(
                      "MMM DD, hh:mm a"
                    )}`}</b>
                  </>
                )}
                <div className="buttons">
                  <MondayButton
                    onClick={() => {
                      if (!!discardWarning) {
                        setDiscardWarning(false);
                      } else {
                        setStopDiscardWarning(false);
                      }
                    }}
                    Icon={<XIcon />}
                    className="mondayButtonRed"
                  >
                    No
                  </MondayButton>
                  <MondayButton
                    onClick={() => {
                      if (discardWarning) {
                        discardAlertHandler(discardWarning?.["alertUUID"]);
                        setDiscardWarning(false);
                      } else {
                        stopDiscardHandler(stopDiscardWarning);
                        setStopDiscardWarning(false);
                      }
                    }}
                    Icon={<TickIcon />}
                  >
                    Yes
                  </MondayButton>
                </div>
              </div>
            </WarningModal>
          )}
        </div>
        {/** 
          //#region LINK STOP MODAL
        */}
        {stopData && (
          <LinkStopModal
            {...{
              open: Boolean(stopData),
              onCancel() {
                setStopData(null);
              },
              stopData,
              onSubmit: stopSubmitHandler,
              onDiscard: stopDiscardHandler,
            }}
          />
        )}
      </Fragment>
    );
  }
);

export default ActualPlanCard;

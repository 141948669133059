
import React from 'react';

import {
  LeftArrow,
  RightArrow,
} from "../../../../../SidebarPages/BasePage/src";
import {
  XIcon,
  TrashIcon,
} from "../../../../../SidebarPages/Communication/assets";
import { MondayButton } from "../../../../../commonComponents";
import { LogsIcon } from "../../../../../SidebarPages/DynamicView/src";
import { TickIcon } from "../../../../Settings/settingsComponents/Roles/src";
import { getDispatchLocationOptions } from "../../../../../Header/forms/RequestFormModal/requestFormModalData";

export const OVERHEAD_ENTRY_TYPES = ["CASH", "1099"];
export const OVERHEAD_SHIFT_TYPES = ["CASH Shift", "1099 Shift"];

export function footerButtons({
  onCancel = () => {},
  onSave = () => {},
  onNextStep = () => {},
  onReduceStep = () => {},
  onShowLogs = () => {},
  onDelete = () => {},
  editMode = false,
  isLastStep,
  currentStep,
  degName,
  // emptyData,
  degStatus,
  rowToEdit,
  uploading,
  // accountName,
}) {
  return [
    <>
      <div style={{ display: "flex", gap: "10px" }}>
        {editMode && (
          <MondayButton
            onClick={onDelete}
            Icon={<TrashIcon />}
            className="mondayButtonRed"
            data-testid="delete-deg-btn"
            tooltipKey={" "}
          >
            Delete
          </MondayButton>
        )}
        {editMode && (
          <MondayButton
            onClick={onShowLogs}
            Icon={<LogsIcon />}
            className="mondayButtonBlue"
            data-testid="show-logs-btn"
            tooltipKey={`${degName} Logs`}
          >
            Show Logs
          </MondayButton>
        )}
      </div>
      <div style={{ display: "flex", gap: "10px" }}>
        <MondayButton
          {...{
            Icon: <XIcon />,
            className: "mondayButtonRed",
            onClick: onCancel,
            tooltipKey: " ",
            ["data-testid"]: "close-button",
          }}
        >
          Cancel
        </MondayButton>
        {currentStep > 1 && (
          <MondayButton
            className="mondayButtonBlue"
            Icon={<LeftArrow />}
            onClick={onReduceStep}
            data-testid="previous-step-btn"
          >
            Previous Step
          </MondayButton>
        )}
        <MondayButton
          {...{
            Icon: <RightArrow />,
            className: "mondayButtonBlue",
            onClick: onNextStep,
            ["data-testid"]: "next-button",
            disabled:
              isLastStep && rowToEdit?.degStatus === "Completed" && !uploading,
          }}
        >
          Next Step
        </MondayButton>
        {currentStep ? (
          <MondayButton
            {...{
              Icon: <TickIcon />,
              onClick: onSave,
              ["data-testid"]: "save-button",
              disabled: rowToEdit?.degStatus === "Completed" || uploading,
            }}
          >
            Save as {degStatus}
          </MondayButton>
        ) : null}
        {/* {!!emptyData.length ? (
          <MondayButton
            {...{
              Icon: !isLastStep ? <RightArrow /> : <TickIcon />,
              className: !isLastStep ? "mondayButtonBlue" : undefined,
              onClick: onSave,
              ["data-testid"]: !isLastStep ? "next-button" : "save-deg-btn",
              disabled:
                isLastStep &&
                rowToEdit?.degStatus === "Completed" &&
                !uploading,
            }}
          >
            {!isLastStep ? "Next Step" : `Save as ${degStatus}`}
          </MondayButton>
        ) : (
          <MondayButton
            {...{
              Icon: !isLastStep ? <RightArrow /> : <TickIcon />,
              className: !isLastStep ? "mondayButtonBlue" : undefined,
              ["data-testid"]: !isLastStep ? "next-button" : "save-deg-btn",
              onClick: onSave,
              disabled:
                (isLastStep && degName?.length < 3) ||
                (currentStep === 0 && !accountName) ||
                rowToEdit?.degStatus === "Completed" ||
                uploading,
            }}
          >
            {!isLastStep ? "Next Step" : `Save as ${degStatus}`}
          </MondayButton>
        )} */}
      </div>
    </>,
  ];
}

export function degModalFields({ schedules, projects }) {
  return [
    {
      label: "Full Name",
      type: "input",
      placeholder: "Full Name...",
      formItemName: "fullName",
    },
    {
      label: "Time In",
      type: "timePicker",
      formItemName: "timeIn",
      getPopupContainer: document.body,
      showNow: true,
    },
    {
      label: "Time Out",
      type: "timePicker",
      formItemName: "timeOut",
      getPopupContainer: document.body,
      showNow: true,
    },
    {
      label: "Payroll Type",
      type: "input",
      placeholder: "Payroll Type...",
      formItemName: "payrollType",
    },
    {
      label: "Rate",
      placeholder: "Rate...",
      type: "input",
      typeNumber: true,
      addonAfter: "$",
      formItemName: "rate",
    },
    {
      label: "Reg Amount",
      placeholder: "Reg Amount...",
      type: "input",
      typeNumber: true,
      addonAfter: "$",
      formItemName: "regAmount",
    },
    {
      label: "OT Amount",
      placeholder: "OT Amount...",
      formItemName: "otAmount",
      type: "input",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Total Amount",
      placeholder: "Total Amount...",
      formItemName: "totalAmount",
      type: "input",
      typeNumber: true,
      addonAfter: "$",
    },
    {
      label: "Punch In Location",
      placeholder: "Punch In Location...",
      formItemName: "punchInLocation",
      type: "placesAutocomplete",
      groupedOptions: getDispatchLocationOptions(schedules, projects),
      style: { minWidth: 280 },
      showSearch: true,
    },
    {
      label: "Punch Out Location",
      placeholder: "Punch Out Location...",
      formItemName: "punchOutLocation",
      type: "placesAutocomplete",
      groupedOptions: getDispatchLocationOptions(schedules, projects),
      style: { minWidth: 280 },
      showSearch: true,
    },
    {
      label: "Distance From Jobsite",
      placeholder: "Distance From Jobsite...",
      formItemName: "distanceFromJobsite",
      type: "input",
      typeNumber: true,
    },
    {
      label: "Jobsite Match",
      formItemName: "jobsiteMatch",
      placeholder: "Jobsite Match...",
      type: "placesAutocomplete",
      groupedOptions: getDispatchLocationOptions(schedules, projects),
      style: { minWidth: 280 },
      showSearch: true,
    },
    {
      label: "SOW",
      placeholder: "SOW...",
      type: "input",
      formItemName: "sow",
    },
    {
      label: "Scheduling",
      placeholder: "Scheduling...",
      formItemName: "scheduling",
      type: "input",
    },
  ];
}

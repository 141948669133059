import { v4 as uuid } from "uuid";
import { appStore } from "../../../store";

/**
 * Starts the notification listening session
 *
 * @param {WebSocket} socket
 */
function startNotificationSession(socket) {
  const preferences = appStore.getState()?.preferences?.preferences || {};
  const userConfiguration =
    appStore.getState()?.userConfig?.userConfiguration || {};

  try {
    socket.send(
      JSON.stringify({
        request: "createSession",
        body: {
          userDetails: {
            userName: userConfiguration?.userName,
            cognitoUserId: userConfiguration?.cognitoUserId,
          },
          environment: process.env.NODE_ENV,
          sessionId: uuid(),
          notificationTopics: preferences?.notifications?.topics,
          pushNotifications: preferences?.notifications?.pushNotifications,
          allowNotifications:
            preferences?.notifications?.allowNotifications ?? true,
        },
      })
    );
  } catch (err) {
    console.log("Could not start notification session: ", err);
  }
}

export default startNotificationSession;

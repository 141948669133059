import { message } from "antd";
import axios from "axios";
import { socketsApi } from "../../../../../../../AppData";

/*
@param {Object} automationBody - Body of automation with {automationId,automationAction,automationTime,usersList,cronLogs,etc...}
*/

export const updateAutomation = async (automationBody) => {
  try {
    const res = await axios.post(
      `${socketsApi}/api/automation/update`,
      automationBody
    );
    if (res.data.success) message.success(res.data.message);
    else message.warning(res.data.message);
    return res;
  } catch (err) {
    console.log({ err });
    message.warning("Error while updating automation!");
    throw err;
  }
};

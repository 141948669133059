import { API } from "aws-amplify";
import { message } from "antd";

export async function updateEmployee(
  id,
  employeeType,
  updatedEmployeeData,
  refreshTable = () => {},
  onCancel = () => {}
) {
  message.info("Updating Employee");
  const path = employeeType;

  updatedEmployeeData.nameOfUser =
    updatedEmployeeData.firstName + " " + updatedEmployeeData.lastName;

  //Removing unwanted keys
  delete updatedEmployeeData.firstName;
  delete updatedEmployeeData.lastName;

  API.put(`${path}`, `/${path}/${id}`, {
    body: updatedEmployeeData,
  })
    .then((value) => {
      refreshTable(value, "edit");
      message.success("Updated successfully");
    })
    .catch((e) => {
      console.error(e);
      message.error("Error while updating");
    })
    .finally(() => {
      onCancel();
      return;
    });
}

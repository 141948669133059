import { useMemo } from "react";
import { InfoText } from "../../../../../../../commonComponents/CustomModalHeader/CustomModalHeader";

function CreateUserInfoText({ currentStep, user }) {
  const text = useMemo(() => {
    if (currentStep === 0) {
      return "Fill out the form below to create a new user. The information provided will be used to assign roles, permissions, and access levels within the system.";
    } else if (currentStep === 1) {
      return "Enter the confirmation code sent to your email or phone to verify your identity and continue with the user creation process.";
    } else if (currentStep === 2) {
      return "Assign the new user to a team and configure their permissions, including virtual assistant access and task management capabilities.";
    } else if (currentStep === 3) {
      return `Manage and configure the routes that ${
        user?.nameOfUser || "NO USER"
      } is authorized to access. Ensure each route aligns with the user's role and responsibilities to maintain operational security and efficiency.`;
    } else if (currentStep === 4) {
      return `Review the user's information, permissions, and access rights before completing the creation process.`;
    }
  }, [currentStep, user]);

  return (
    <InfoText
      {...{
        text,
        style: { maxWidth: "unset" },
      }}
    />
  );
}

export default CreateUserInfoText;

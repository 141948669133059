import { getParams } from "../../../../ChartButton/utils/getParams";

export const getChartingOptions = (data) => {
  let topic = new Set();
  let subtopic = new Set();
  let status = new Set();
  let createdBy = new Set();

  data.forEach((item) => {
    const tTopic = item.taskTopic || "No Data";
    const tsubTopic = item.taskSubTopic || "No Data";
    const oStage = item.taskStatus || "No Data";
    const user = item.createdBy || "No Data";

    topic.add(tTopic);
    subtopic.add(tsubTopic);
    status.add(oStage);
    createdBy.add(user);
  });

  const chartingOptions = [
    {
      optionTitle: "Show total Tasks count based on Topic",
      dataKey: "taskTopic",
      params: getParams({
        data,
        chartData: topic,
        chartKey: "taskTopic",
        label: "Topic",
        text: "Total Tasks count based on Topic",
      }),
    },
    {
      optionTitle: "Show total Tasks count based on SubTopic",
      dataKey: "taskSubTopic",
      params: getParams({
        data,
        chartData: subtopic,
        chartKey: "taskSubTopic",
        label: "SubTopic",
        text: "Total Tasks count based on SubTopic",
      }),
    },
    {
      optionTitle: "Show total Tasks count based on Status",
      dataKey: "taskStatus",
      params: getParams({
        data,
        chartData: status,
        chartKey: "taskStatus",
        label: "Status",
        text: "Total Tasks count based on Status",
      }),
    },
    {
      optionTitle: "Show total Tasks count based on Created By",
      dataKey: "createdBy",
      params: getParams({
        data,
        chartData: createdBy,
        chartKey: "createdBy",
        label: "Created By",
        text: "Total Tasks count based on Created By",
      }),
    },
  ];

  return chartingOptions;
};

const safetyContactsStyles = {
  "safety-contacts-main-header": {
    display: "flex",
    "justify-content": "center",
  },

  // Emergency Contacts
  "emergency-contacts-section": {
    display: "flex",
    "justify-content": "center",
    "margin-top": "10px",
  },
  "emergency-contacts": {
    width: "98%",
    height: "fit-content",
    "border-radius": "10px",
    "box-shadow": "0px 1px 4px #00000029",
  },
  "contact-header": {
    height: "45px",
    "background-color": "#F4F5F6",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    padding: "0 10px",
    "font-weight": "600",
    "font-size": "18px",
    "border-radius": "10px 10px 0 0",
  },

  "contact-container": {
    display: "flex",
    "justify-content": "space-between",
    padding: "20px 10px",
  },

  contact: {
    height: "100%",
    display: "flex",
    "max-width": "110px",
    "flex-direction": "column",
    "align-items": "center",
    "margin-bottom": "10px",
    gap: "5px",
    "justify-content": "center",
    "text-align": "center",
  },

  "contact-image": {
    width: "50px",
    height: "60px",
    "object-fit": "contain",
    "margin-right": "5px",
  },

  "contact-text, contact-number": {
    "margin-left": "5px",
  },
  "contact-number": {
    "font-size": "14px",
    "text-decoration": "none",
    color: "#000",
  },
  "contact-text": {
    "font-size": "12px",
    "font-weight": "600",
  },
  // Core Safety Contacts
  "core-safety-contacts": {
    height: "39rem",
  },
  "core-contact-container": {
    display: "grid",
    "justify-content": "space-between",
    "grid-template-columns": "repeat(3, 1fr)",
    padding: "10px",
    "row-gap": "10px",
  },
  "c-contact": {
    "max-width": "unset",
    "flex-wrap": "wrap",
  },
  "c-avatar": {
    height: "50px",
    width: "50px",
    "border-radius": "50%",
    "background-color": "#F4F5F6",
    "font-size": "20px",
    display: "flex",
    "align-items": "center",
    "justify-content": "center",
    "font-weight": "600",
    overflow: "hidden",
  },
  "c-detail": {
    color: "#000",
    "text-decoration": "none",
    cursor: "pointer",
    "font-size": "12px",
  },
  "export-preview-header": {
    height: "57px",
    "margin-bottom": "30px",
  },

  // Hospitals Nearby
  "nearby-title": {
    "margin-bottom": "10px",
  },
  "nearby-title2": {
    "margin-bottom": "20px",
  },

  "hospital-section": {
    height: "99vh",
  },
  "hospital-0": {
    height: "83vh",
  },

  "h-number": {
    color: "#000",
    "text-decoration": "none",
  },
  "hospital-data": {
    padding: "10px 20px",
  },
  "route-image": {
    padding: "10px",
    "object-fit": "contain",
    width: "97%",
  },
  "second-page-container": {
    padding: "10px",
  },
  "avatar-image": {
    width: "100%",
    height: "100%",
    "object-fit": "cover",
  },
};

export default safetyContactsStyles;

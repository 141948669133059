import { message } from "antd";

import {
  formatCurrency,
  formatNumber,
} from "../../../../../../../SidebarPages/utils";
import StatusGridEditor from "../utils/StatusGridEditor";
import ServiceGridSelect from "../utils/ServiceGridSelect";
import { MondayButton } from "../../../../../../../commonComponents";
import { DEG_DATE_FORMAT, DEG_TIME_FORMAT } from "../utils/cellFunctions";
import { dayjsNY } from "../../../../../../../DateComponents/contants/DayjsNY";
import Services from "../../../../../../../SidebarPages/components/AgGridComponents/Services";

export const excelShiftColumnsConfig = [
  "employeeId",
  "employeeFullName",
  "companyName",
  "shiftStatus",
  "foreman",
  "employeeRole",
  "payrollType",
  "jobsiteAddress",
  "salaryType",
  "shiftType",
  "reason",
  "firstClockIn",
  "clockOut",
  "sow",
  "workHours",
  "breakHours",
  "overtimeHours",
  "regAmount",
  "otAmount",
  "total",
  "ovhTotal",
];

export const columnDefs = ({ degAccessRights }) => {
  const columnsState = JSON.parse(sessionStorage.getItem("degShiftColumns"));
  const columnWidths = {};
  const colAccesses = {};

  if (!!columnsState?.length) {
    for (const colState of columnsState) {
      const { colId, width } = colState;
      Object.assign(columnWidths, { [colId]: width });
    }
  }

  for (let i = 0; i < degAccessRights?.children?.length; i++) {
    const accessRight = degAccessRights?.children?.[i];
    Object.assign(colAccesses, { [accessRight?.title]: accessRight?.write });
  }

  return [
    {
      headerName: "Nr",
      field: "nr",
      checkboxSelection: ({ data, context }) => {
        return !context?.notFound?.[data?.employeeId] || !data?.employeeId;
      },
      pinned: "left",
      showDisabledCheckboxes: true,
      headerCheckboxSelection: true,
      headerCheckboxSelectionFilteredOnly: true,
      minWidth: columnWidths?.["nr"] || 50,
      cellClass: "cell-left-aligned",
      cellRenderer: ({ rowIndex }) => {
        return rowIndex + 1;
      },
    },
    {
      headerName: "Full Name",
      field: "employeeFullName",
      minWidth: columnWidths?.["employeeFullName"] || 0,
    },
    {
      headerName: "Employee Id",
      field: "employeeId",
      enableRowGroup: true,
      minWidth: columnWidths?.["employeeId"] || 0,
    },
    {
      headerName: "Edit",
      field: "edit",
      pinned: "left",
      cellClass: "cell-center-aligned",
      minWidth: columnWidths?.["edit"] || 0,
      cellRenderer: ({ data, context }) => {
        const disabledCondition =
          context?.notFound?.[data?.employeeId] || !data?.employeeId;
        return (
          <MondayButton
            tooltipKey={" "}
            hasIcon={false}
            onClick={() => {
              if (disabledCondition) {
                message.error({
                  content: `You can't edit this Shift because this employee is not registered`,
                  key: "shiftDisabled",
                });
              } else {
                context?.toggleEditModal(data);
              }
            }}
            className={
              disabledCondition ? "mondayButtonGrey" : "mondayButtonBlue"
            }
          >
            Edit
          </MondayButton>
        );
      },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
    {
      headerName: "Status",
      field: "shiftStatus",
      pinned: "left",
      minWidth: columnWidths?.["shiftStatus"] || 0,
      enableRowGroup: true,
      editable: true,
      cellEditor: StatusGridEditor,
      singleClickEdit: true,
      cellRenderer({ value }) {
        return value || "";
      },
    },
    {
      headerName: "Role",
      field: "employeeRole",
      minWidth: columnWidths?.["employeeRole"] || 0,
      enableRowGroup: true,
    },
    colAccesses?.["Rate"] && {
      headerName: "Rate per Hour",
      field: "employeeRate",
      minWidth: columnWidths?.["employeeRate"] || 0,
      enableRowGroup: true,
      cellRenderer: ({ value }) => {
        return formatCurrency(value || 0);
      },
    },
    {
      headerName: "Foreman",
      field: "foreman",
      minWidth: columnWidths?.["foreman"] || 0,
      enableRowGroup: true,
      cellRenderer: ({ context, data }) => {
        const teams = context?.crewTeams;
        const foreman = data?.crewTeamId
          ? teams.find((el) => el.crewTeamId === data.crewTeamId)?.crewForeman
          : teams.find(({ crewForeman, crewMembers }) => {
              const isForeman =
                data?.crewId === crewForeman?.crewId ||
                data?.employeeId === crewForeman?.employeeId;
              const isMember = (crewMembers || []).find(
                (memb) =>
                  memb?.crewId === data?.crewId ||
                  memb?.employeeId === data?.employeeId
              );
              return isForeman || isMember;
            })?.crewForeman;

        return foreman?.crewName || "";
      },
      valueGetter: ({ context, data }) => {
        const teams = context?.crewTeams;
        const foreman = data?.crewTeamId
          ? teams.find((el) => el.crewTeamId === data.crewTeamId)?.crewForeman
          : teams.find(({ crewForeman, crewMembers }) => {
              const isForeman =
                data?.crewId === crewForeman?.crewId ||
                data?.employeeId === crewForeman?.employeeId;
              const isMember = (crewMembers || []).find(
                (memb) =>
                  memb?.crewId === data?.crewId ||
                  memb?.employeeId === data?.employeeId
              );
              return isForeman || isMember;
            })?.crewForeman;

        return foreman?.crewName || "";
      },
    },
    {
      headerName: "Team",
      field: "crewTeamName",
      enableRowGroup: true,
      minWidth: columnWidths?.["crewTeamName"] || 0,
      valueGetter: ({ data }) => {
        return data?.crewTeamName || "";
      },
    },
    {
      headerName: "Company",
      field: "companyName",
      enableRowGroup: true,
      minWidth: columnWidths?.["companyName"] || 0,
      valueGetter: ({ data }) => {
        return data?.company || data?.companyName || "";
      },
    },
    {
      headerName: "Salary Type",
      field: "salaryType",
      enableRowGroup: true,
      minWidth: columnWidths?.["salaryType"] || 0,
    },
    {
      headerName: "Shift Type",
      pinned: "right",
      field: "shiftType",
      enableRowGroup: true,
      minWidth: columnWidths?.["shiftType"] || 0,
    },
    {
      headerName: "Clock In",
      field: "firstClockIn",
      minWidth: columnWidths?.["firstClockIn"] || 0,
      enableRowGroup: true,
      cellRenderer({ value }) {
        if (value) {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        }
        return "";
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (value) {
            return dayjsNY(value).format(
              `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
            );
          }
          return "";
        },
      },
    },
    {
      headerName: "Clock Out",
      field: "clockOut",
      minWidth: columnWidths?.["clockOut"] || 0,
      enableRowGroup: true,
      cellRenderer({ value }) {
        if (value) {
          return dayjsNY(value).format(
            `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
          );
        }
        return "";
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          if (value) {
            return dayjsNY(value).format(
              `${DEG_DATE_FORMAT} - ${DEG_TIME_FORMAT}`
            );
          }
          return "";
        },
      },
    },
    {
      headerName: "Jobsite Address",
      field: "jobsiteAddress",
      minWidth: columnWidths?.["jobsiteAddress"] || 0,
      enableRowGroup: true,
    },
    {
      headerName: "Schedule Match",
      field: "scheduleAddress",
      minWidth: columnWidths?.["scheduleAddress"] || 0,
      enableRowGroup: true,
    },
    {
      headerName: "SOW",
      field: "sow",
      cellClass: "cell-center-aligned",
      minWidth: columnWidths?.["sow"] || 0,
      editable: true,
      cellEditor: ServiceGridSelect,
      cellRendererFramework: ({ data }) => {
        return Services({
          value: { [""]: { services: data?.sow || [] } },
          title: "Jobsite Services",
        });
      },
      // valueGetter({ data }) {
      //   return (data?.sow || []).join(", ");
      // },
    },
    // {
    //   headerName: "Google Sheet Link",
    //   field: "jobsiteMatch",
    //   minWidth: columnWidths?.["jobsiteMatch"] || 0,
    //   enableRowGroup: true,
    //   cellRenderer: ({ value }) => {
    //     return (
    //       <a onClick={() => window.open(value?.googleSheetLink)}>
    //         {value?.googleSheetLink}
    //       </a>
    //     );
    //   },
    //   pdfExportOptions: {
    //     createURL: (value) => {
    //       return value?.googleSheetLink;
    //     },
    //     modifyCellValue: (value) => {
    //       if (value?.googleSheetLink) {
    //         return {
    //           text: "Google Sheet Link",
    //           hyperlink: value?.googleSheetLink,
    //         };
    //       }
    //     },
    //   },
    // },
    {
      headerName: "Payroll Type",
      field: "payrollType",
      minWidth: columnWidths?.["payrollType"] || 0,
      enableRowGroup: true,
    },
    {
      headerName: "Work Hours",
      field: "workHours",
      minWidth: columnWidths?.["workHours"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          return formatNumber(value, { unit: "hour" });
        },
      },
    },
    {
      headerName: "Break Hours",
      field: "breakHours",
      minWidth: columnWidths?.["breakHours"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          return formatNumber(value, { unit: "hour" });
        },
      },
    },
    {
      headerName: "Overtime Hours",
      field: "overtimeHours",
      minWidth: columnWidths?.["overtimeHours"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ value }) {
        return formatNumber(value, { unit: "hour" });
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          return formatNumber(value, { unit: "hour" });
        },
      },
    },
    {
      headerName: "HR Hours",
      enableRowGroup: true,
      aggFunc: "sum",
      field: "hrHours",
      minWidth: columnWidths?.["hrHours"] || 0,
      cellRenderer({ data }) {
        return formatNumber(data?.shiftType === "HR Shift" ? 8 : 0, {
          unit: "hour",
        });
      },
    },
    {
      headerName: "Reason ",
      field: "reason",
      enableRowGroup: true,
      minWidth: columnWidths?.["reason"] || 0,
      cellRenderer({ value }) {
        return value || "";
      },
    },
    colAccesses?.["Reg Amount"] && {
      headerName: "Regular Amount",
      field: "regAmount",
      minWidth: columnWidths?.["regAmount"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ data }) {
        let value = data?.regAmount;
        if (data?.ovhRegular) {
          value = data?.ovhRegular;
        }
        return formatCurrency(value || 0);
      },
    },
    colAccesses?.["OT Amount"] && {
      headerName: "OT. Amount",
      field: "otAmount",
      minWidth: columnWidths?.otAmount || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ data }) {
        let value = data?.otAmount;
        if (data?.ovhOvertime) {
          value = data?.ovhOvertime;
        }
        return formatCurrency(value || 0);
      },
    },
    colAccesses?.["Rate"] && {
      headerName: "Reimbursement",
      field: "reimbursement",
      minWidth: columnWidths?.["reimbursement"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer: ({ value }) => {
        return formatCurrency(value || 0);
      },
    },
    colAccesses?.["Total Amount"] && {
      headerName: "Total",
      field: "total",
      minWidth: columnWidths?.["total"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ value }) {
        return formatCurrency(value || 0);
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          return formatCurrency(value || 0);
        },
      },
    },
    colAccesses?.["Total Amount"] && {
      headerName: "Total Overhead",
      field: "totalOvh",
      minWidth: columnWidths?.["totalOvh"] || 0,
      enableRowGroup: true,
      aggFunc: "sum",
      cellRenderer({ value }) {
        return formatCurrency(value || 0);
      },
      pdfExportOptions: {
        modifyCellValue: (value) => {
          return formatCurrency(value || 0);
        },
      },
    },
  ].filter(Boolean);
};

import { Tooltip } from "antd";
import { DepartmentIcon } from "../../../../../../../../../assets";
import { CardTemplate } from "../../../../../Management/EditUser/components";
import { lengthOfRolesUsers } from "../../utils";
import "./DepartmentsInfoCard.scss";
import CloneAccess from "../CloneDepartmentAccess/CloneAccess";
import UsersDetails from "../UserDetailsModal/UserDetails";
import { useState } from "react";

function DepartmentsInfoCard({
  isDarkMode = false,
  departmentName,
  departmentGroups,
  allUsers,
  currentRole,
}) {
  const [selectedRoleName, setSelectedRoleName] = useState("");

  const filteredUsers = allUsers?.filter(
    (user) => user?.groupName?.toLowerCase() === selectedRoleName?.toLowerCase()
  );

  return (
    <CardTemplate
      {...{
        isDarkMode,
        className: `departments-info-card ${
          isDarkMode ? "departments-info-card-dark" : ""
        }`,
      }}
    >
      <div className="departments-info-card-title">
        <DepartmentIcon /> <span>{departmentName + " Department"} </span>
      </div>
      <div className="departments-info-card-body">
        <div className="department-info-card-list-container">
          <div className="department-info-card-list-item header">
            <div>Roles</div>
            <div>Nr. of Users</div>
          </div>
          <ul className="department-info-card-list">
            {departmentGroups.map((department, index) => {
              const activeRole = currentRole in department;
              const roleName = Object.keys(department)[0];

              const length = lengthOfRolesUsers(
                roleName,
                allUsers,
                departmentName
              );
              return (
                <li
                  key={index}
                  className={`department-info-card-list-item child ${
                    activeRole && "active-role"
                  } `}
                >
                  <div>{roleName}s </div>
                  <Tooltip
                    placement="left"
                    title={length !== 0 && !activeRole ? "View Users" : ""}
                  >
                    {" "}
                    <span
                      onClick={() => {
                        if (length !== 0) {
                          setSelectedRoleName(roleName);
                        }
                      }}
                    >
                      {length}
                    </span>
                  </Tooltip>
                </li>
              );
            })}
          </ul>
        </div>
        <CloneAccess {...{ currentRole }} />
      </div>
      {!!selectedRoleName && (
        <UsersDetails
          {...{
            openUsersDetails: !!selectedRoleName,
            setOpenUsersDetails: () => setSelectedRoleName(""),
            selectedRoleName: selectedRoleName + " Role",
            filteredUsers,
            isDarkMode,
          }}
        />
      )}
    </CardTemplate>
  );
}

export default DepartmentsInfoCard;

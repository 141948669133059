//@ts-check
import React, { useState } from "react";

import { Popconfirm } from "antd";

import styles from "./Attachment.module.css";
import {
  ExcelIcon,
  PdfIcon,
  WordIcon,
} from "../../../../SidebarPages/Documentation/View/assets";
import { TrashIcon } from "../../../../SidebarPages/Communication/assets";
import { DownloadIcon } from "../../../../SidebarPages/BasePage/src";
import { useLoading } from "../../../../../hooks";

const fileIcons = {
  pdf: <PdfIcon color="red" />,
  xlsx: <ExcelIcon color="green" />,
  docx: <WordIcon color="blue" />,
};

const Attachment = ({
  file,
  color = "white",
  type = "delete",
  onDelete = async (file) => {},
  onDownload = (file) => {},
}) => {
  const [open, setOpen] = useState(false);

  const { isLoading, startLoading, stopLoading } = useLoading();

  const fileType = file?.originalname?.split(".").pop();

  const confirmDelete = async () => {
    startLoading();
    await onDelete(file);
    stopLoading();
  };

  const showPopConfirm = () => setOpen(true);

  const hidePopConfirm = () => setOpen(false);

  return (
    <div
      className={`${styles.attachmentItem} ${styles[color]} ${styles[type]}`}
      key={file.id}
    >
      <div className={styles.fileInfo}>
        {file?.mimetype && fileIcons[fileType]}
        <span className={styles.fileName}>{file.originalname}</span>
      </div>
      <div className={styles.actions}>
        {type === "delete" ? (
          <Popconfirm
            open={open}
            title="Delete the file"
            description="Are you sure to delete this file?"
            icon={<TrashIcon className={styles.deleteIcon} />}
            onConfirm={confirmDelete}
            okButtonProps={{
              loading: isLoading,
              style: {
                backgroundColor: "#1264a3",
                color: "white",
                border: "none",
              },
            }}
            cancelButtonProps={{
              style: {
                backgroundColor: "#f4f5f6",
                color: "#231f20",
                border: "none",
              },
            }}
            onCancel={hidePopConfirm}
          >
            <TrashIcon onClick={showPopConfirm} className={styles.deleteIcon} />
          </Popconfirm>
        ) : (
          <DownloadIcon
            onClick={() => onDownload(file)}
            className={styles.downloadIcon}
          />
        )}
      </div>
    </div>
  );
};

export default Attachment;

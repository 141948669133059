import dayjs from "dayjs";
import utc from "dayjs/plugin/utc";
import tz from "dayjs/plugin/timezone";
import duration from "dayjs/plugin/duration";
import isSameOrBefore from "dayjs/plugin/isSameOrBefore";
import weekOfYear from "dayjs/plugin/weekOfYear";
import customParseFormat from "dayjs/plugin/customParseFormat";

dayjs.extend(duration);
dayjs.extend(utc);
dayjs.extend(tz);
dayjs.extend(isSameOrBefore);
dayjs.extend(weekOfYear);
dayjs.extend(customParseFormat);

// Converts a UTC date to the user's local timezone.
export const dayjsUserTimeZone = (date, customFormat = undefined) => {
  return dayjs
    .utc(date, customFormat)
    .tz(Intl.DateTimeFormat().resolvedOptions().timeZone);
};

import { Checkbox } from "antd";
import { useState } from "react";

import { elif } from "../../../RolesData";
import ActionButtons from "../../ActionButtons";
import SideDrawer from "./SideDrawer/SideDrawer";
import { hasFalsePermission } from "../utils";
import { useParams } from "react-router-dom";

import AccessCheckboxGroup from "../../../../Management/EditUser/components/AccessCheckboxGroup/AccessCheckboxGroup";
import { useSelector } from "react-redux";

const RouteCard = ({
  route,
  selectedTab,
  accessConfiguration,
  setAccessConfiguration,
  searchRouteConfigs,
  disabled = false,
}) => {
  // const [openActionButtons, setOpenActionButtons] = useState(false);
  // const [openRouteModal, setOpenRouteModal] = useState(false);
  // const [selectedRoute, setSelectedRoute] = useState({});
  const [openRouteTree, setOpenRouteTree] = useState(false);
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const [expandedKeys, setExpandedKeys] = useState({});
  const [autoExpandParent, setAutoExpandParent] = useState(true);

  const { role } = useParams();

  const { title, checked, read, write } = route;

  const isRoute = selectedTab === "Route";

  const onRouteClickHandler = () => {
    // if (!isRoute) {
    //   if (role === "Super Admin") return;
    //   setSelectedRoute(route);
    //   setOpenActionButtons((prev) => !prev);
    //   return;
    // }
    // setSelectedRoute(route);
    // setOpenRouteModal((prev) => !prev);

    setOpenRouteTree((prev) => {
      if (prev === true) {
        setExpandedKeys((prev) => ({
          ...prev,
          [title]: [],
        }));
      }
      return !prev;
    });
  };

  const onSidebarConfigChange = (value, titleKey) => {
    setAccessConfiguration((prev) => ({
      ...prev,
      [selectedTab]: prev[selectedTab].map((route) => {
        if (route.title === title) {
          return {
            ...route,
            [titleKey]: value,
          };
        }
        return route;
      }),
    }));
  };

  const updateRoute = (route, title, titleKey, value) => {
    const updateChildren = (children) =>
      children?.map((child) => updateRoute(child, title, titleKey, value));

    return {
      ...route,
      [titleKey]: value,
      ...(titleKey === "read" && value === false ? { write: false } : {}),
      ...(titleKey !== "description" &&
        route.children && { children: updateChildren(route.children) }),
    };
  };

  const onRouteConfigChange = (value, titleKey) => {
    setAccessConfiguration((prev) => ({
      ...prev,
      [selectedTab]: prev[selectedTab].map((route) => {
        if (route.title !== title) {
          return route;
        }

        return updateRoute({ ...route }, title, titleKey, value);
        // Use spread operator to create a new object and then update it
      }),
    }));
  };

  return (
    <AccessCheckboxGroup
      {...{
        onCollapseClick: onRouteClickHandler,
        collapsedOpen: openRouteTree,
        selectedRoute: route,
        optionsProps: !isRoute
          ? {
              read,
              write,
              onReadClick: () => onSidebarConfigChange(!read, "read"),
              onWriteClick: () => onSidebarConfigChange(!write, "write"),
              showWrite: read && isRoute,
            }
          : {
              read,
              write,
              onReadClick: () => onRouteConfigChange(!read, "read"),
              onWriteClick: () => onRouteConfigChange(!write, "write"),
              showWrite: write !== undefined && read,
            },
        disabled: role === "Super Admin" || disabled,
        setAccessConfiguration,
        accessConfiguration,
        selectedTab,
        isDarkMode,

        ///////////
        setAutoExpandParent,
        setExpandedKeys,
        expandedKeys,
        autoExpandParent,

        searchRouteConfigs,
      }}
    />
  );

  // return (
  //   <div className="configBodyWithOptions">
  //     <div className="configElementContainer" onClick={onRouteClickHandler}>
  //       <div
  //         className={
  //           read && isRoute
  //             ? elif(read, !hasFalsePermission(route)).checkboxSQ
  //             : elif(read).checkboxSQLite
  //         }
  //         style={{ width: checked && 36 }}
  //       >
  //         <div
  //           className="navyBlueContainer"
  //           style={elif(checked).navyBluCStyle}
  //         >
  //           <span style={elif(!checked).checkBoxStyle}>
  //             <Checkbox checked={checked} />
  //           </span>
  //         </div>
  //       </div>
  //       <span className="configElementTitle">{title}</span>
  //     </div>
  //     {openActionButtons && !isRoute && (
  //       <ActionButtons
  //         {...{
  //           read: read,
  //           write: write,
  //           onReadClick: () => onRouteConfigChange(!read, "read"),
  //           onWriteClick: () => onRouteConfigChange(!write, "write"),
  //           readLabel: "Read",
  //           writeLabel: "Write",
  //           showWrite: read && isRoute,
  //         }}
  //       />
  //     )}

  //     {openRouteModal && (
  //       <SideDrawer
  //         {...{
  //           selectedTab,
  //           selectedRoute,
  //           accessConfiguration,
  //           visible: openRouteModal,
  //           setVisible: setOpenRouteModal,
  //           setAccessConfiguration,
  //         }}
  //       />
  //     )}
  //   </div>
  // );
};

export default RouteCard;

import { parseInTz } from "../../../../../../SidebarPages/Fleet/Dispatch/modals/NewDispatchModal/utils/dateFunctions";
import { FormInstance } from "antd";
/**
 * This function returns the list of Form fields
 * @typedef props
 * @property {{ crewId: string, crewName: string, employeeId: string }[]} crews List of employees
 * @property {FormInstance} form Form reference
 * @property {void} onRegularShift Function that updates clockIn (07:00 am) and clockOut (03:00 pm) time to a default value
 *
 * @param {props} props
 */
export function formFieldsList({ crews, form, onRegularShift }) {
  const hrCategories = [
    "Unpaid Time Off",
    "Paid Time Of",
    "Vacation",
    "Sick",
    "Holiday",
    "Bereavement",
    "Personal",
    "Regular",
    "Overtime",
    "NYC Covid Leave",
  ];

  return {
    ["Employees"]: [
      {
        label: "Employees",
        formItemName: "selectedMembers",
        type: "styledmultiselect",
        mode: "multiple",
        placeholder: "Select members...",
        optionFilterProp: "label",
        required: true,
        showSearch: true,
        customOptions: crews?.map(({ crewId, crewName, employeeId }) => ({
          value: crewId,
          label: `${crewName} (${employeeId})`,
        })),
      },
    ],
    ["Shift Details"]: [
      {
        label: "Shift Status",
        required: true,
        allowClear: false,
        type: "select",
        formItemName: "shiftStatus",
        initialValue: "Draft",
        placeholder: "Select status...",
        options: ["Draft", "Pending", "Completed"],
      },
      {
        label: "Selected Dates",
        type: "rangepicker",
        formItemName: "selectedDates",
        required: true,
      },
      {
        form,
        label: "Clock In",
        formItemName: "selectedTime",
        type: "customTimeInput",
        allowClear: false,
        rules: [
          {
            required: true,
            message: "Clock In is required!",
          },
        ],
        onChange(e) {
          form.setFieldValue("selectedTime", parseInTz(e));
        },
      },
      {
        form,
        label: "Clock Out",
        formItemName: "selectedEndTime",
        type: "customTimeInput",
        allowClear: false,
        rules: [
          {
            required: true,
            message: "Clock Out is required!",
          },
        ],
        onChange(e) {
          form.setFieldValue("selectedEndTime", parseInTz(e));
        },
      },
      {
        label: "Leave Reason",
        formItemName: "reason",
        required: true,
        type: "select",
        allowClear: false,
        options: hrCategories,
        placeholder: "Select Reason...",
        getPopupContainer: document.body,
      },
      {
        label: "Regular Shift",
        required: false,
        type: "checkbox",
        className: "regularShiftCheckbox",
        onChange: onRegularShift,
      },
    ],
  };
}

import { Checkbox, Tooltip } from "antd";
import { formatCurrency } from "../../../../../../utils";
import { EditIcon } from "../../../../../../DynamicView/src";
import CreditCheckBox from "../components/CreditCheckBox";
import DescriptionCreditMemo from "../DescriptionCreditMemo";

export const defaultColDef = {
  hide: false,
  filter: true,
  sortable: false,
  resizable: true,
  autoHeight: true,
  enablePivot: true,
  enableRowGroup: false,
  flex: 0.7,
};

export const columnDefs = ({
  taxRate,
  invoiceId,
  updateData,
  isWritable,
  chargeItems,
  currentCharge,
  onDescriptionChange,
}) => [
  {
    headerName: "",
    field: "creditAmount",
    maxWidth: 80,
    resizable: false,
    cellRendererFramework: ({ data }) => (
      <CreditCheckBox
        {...{
          taxRate,
          data: data,
          invoiceId,
          updateData,
          chargeItems,
          currentCharge,
        }}
      />
    ),
  },
  {
    headerName: "#",
    field: "id",
    maxWidth: 65,
    resizable: false,
  },
  {
    headerName: "Service",
    field: "name",
    minWidth: 250,
  },
  {
    headerName: "Group",
    field: "group",
    minWidth: 200,
  },
  {
    headerName: "Scope Amount",
    field: "scopeAmount",
    minWidth: 200,
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: (params) => formatCurrency(params?.value, "USD"),
  },
  {
    headerName: "Credit %",
    field: "creditPercentage",
    minWidth: 150,
    cellEditor: "PeriodCellEditor",
    editable: true,
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value }) => (
      <div
        className="editableCellContainer"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        {(parseFloat(value) || 0).toFixed(2)}% <EditIcon />
      </div>
    ),
  },
  {
    headerName: "Credit Amount",
    field: "creditAmount",
    minWidth: 200,
    cellEditor: "PeriodCellEditor",
    editable: true,
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value }) => (
      <div
        className="editableCellContainer"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        {formatCurrency(value, "USD")} <EditIcon />
      </div>
    ),
  },
  {
    headerName: "Previous Credit",
    field: "credits",
    minWidth: 200,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value: credits }) => (
      <div
        className="editableCellContainer"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        {formatCurrency(
          credits
            ?.filter(({ chargeId }) => chargeId !== currentCharge?.chargeId)
            ?.reduce(
              (total, { creditedAmount }) => total + (creditedAmount || 0),
              0
            ) || 0,
          "USD"
        )}
      </div>
    ),
  },
  {
    headerName: "Received Payment",
    field: "paidAmount",
    minWidth: 200,
    cellEditor: "PeriodCellEditor",
    cellStyle: {
      color: "white",
      backgroundColor: "#68C142",
    },
    cellRendererFramework: ({ value: paidAmount, data }) => (
      <div
        className="editableCellContainer"
        style={{ width: "100%", justifyContent: "space-between" }}
      >
        {formatCurrency(paidAmount, "USD")}
      </div>
    ),
  },
  {
    headerName: "Tax Amount",
    field: "taxAmount",
    valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
    minWidth: 200,
  },
  {
    headerName: "TAX",
    field: "taxAmount",
    minWidth: 70,
    cellRendererFramework: ({ data }) => {
      const isDisabled = (!isWritable && currentCharge) || !data.tax;
      const checked = data?.taxAmount > 0;

      const onCheckBoxChange = () => {
        const newTaxAmount =
          !isDisabled && data?.taxAmount > 0 ? 0 : data?.creditAmount * taxRate;
        const newTotal = data?.creditAmount + (newTaxAmount || 0);
        updateData({ taxAmount: newTaxAmount, total: newTotal }, data);
      };

      return (
        <div className="new-tax-charge-checkbox-item">
          <Tooltip title={isDisabled && "This item is tax-free."}>
            <Checkbox
              checked={checked}
              className={isDisabled ? "ant-checkbox-disabled" : ""}
              disabled={isDisabled}
              onChange={onCheckBoxChange}
            />
          </Tooltip>
        </div>
      );
    },
  },

  {
    headerName: "Total Amount",
    field: "total",
    minWidth: 200,
    valueFormatter: (params) => formatCurrency(params?.value || 0, "USD"),
  },
  {
    headerName: "Description",
    field: "rowDescription",
    minWidth: 400,
    height: 300,
    cellRendererFramework: (params) => (
      <DescriptionCreditMemo
        {...params}
        onDescriptionChange={onDescriptionChange}
      />
    ),
  },
];

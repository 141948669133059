import { Dayjs } from "dayjs";

export function getWeekDateRange(dateOfWeek: Dayjs) {
  if (!dateOfWeek) {
    return;
  }
  const d = dateOfWeek;
  let day = d.day(),
    diff = d.date() - day + (day == 0 ? -6 : 1);
  const monday = d.date(diff);
  const sunday = d.date(monday.date() + 5);

  return [monday.subtract(1, "d").startOf("d"), sunday.startOf("d")];
}

import { useContext } from "react";

import { useMediaQuery } from "../../../../Header/forms/Scheduling/Subcomponents/ServiceDocumentation/utils/use-media-query/use-media-query";
import VirtualAssistantContext from "../../data/VirtualAssistantContext";
import HoverButton from "../../../HoverButton/HoverButton";

import "./AssistantChatFooter.scss";
import { useLoading } from "../../../../../hooks";

function AssistantChatFooter() {
  const { isLoading, startLoading, stopLoading } = useLoading();
  const { status, closeAssistant, createThread } = useContext(
    VirtualAssistantContext
  );

  const onCloseAssistantClickHandler = async () => {
    try {
      startLoading();
      await closeAssistant();
      console.log("here");
    } catch (error) {
      console.error(error);
    } finally {
      console.log("here2");
      stopLoading();
    }
  };

  const mobile = useMediaQuery("(max-width: 767px)");

  let cancelMessage = mobile ? "Close" : "Close session";
  switch (status) {
    case "Connecting":
      cancelMessage = "Cancel and go back";
      break;
    case "Connected":
      cancelMessage = "Disconnect and go back";
      break;
    default:
      break;
  }

  const isError = status === "Error";

  return (
    <div className={`assistant-chat-footer`}>
      <HoverButton
        type="action"
        dataTestId="disconnect-button"
        hasIcon={isLoading}
        text={cancelMessage}
        onClick={onCloseAssistantClickHandler}
        loading={isLoading}
        alwaysShowIcon={isLoading}
      />

      {isError && (
        <HoverButton
          type="decline"
          dataTestId="connect-button"
          onClick={createThread}
          hasIcon={false}
          text="Reconnect"
        />
      )}

      {/* {status === "Online" ? (
        <HoverButton
          type="action"
          dataTestId="connect-button"
          onClick={() => {
            employeeConnectHandler();
          }}
          hasIcon={false}
          text="Connect with one of our employees"
        />
      ) : null} */}
    </div>
  );
}

export default AssistantChatFooter;

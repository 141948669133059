/**
 * Handles the selection of a team in the request form modal.
 *
 * @param {Object} data - The selected team data.
 * @param {string} [action=""] - The action to perform.
 * @param {boolean} [clear=false] - Indicates whether to clear the selected team.
 * @param {Array} [selectedTeam=[]] - The currently selected team.
 * @param {Function} [getFieldValue=() => {}] - The function to get the field value.
 * @param {Function} [dispatchRequestData=() => {}] - The function to dispatch the request data.
 */
const onTeamSelect = ({
  value,
  data,
  action = "",
  clear = false,
  selectedTeam = [],
  getFieldValue = () => {},
  dispatchRequestData = () => {},
}) => {
  if (action === "clearing") {
    return dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: [],
    });
  }

  if (clear === false) {
    const toSet = [
      ...(selectedTeam || []),
      {
        value: data?.value,
        members: data?.members,
        teamId: data?.teamId,
      },
    ];

    dispatchRequestData({
      type: "SELECTED_TEAM",
      payload: toSet,
    });
  } else {
    if (getFieldValue("team").length < 1) {
      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: [],
      });
    } else {
      dispatchRequestData({
        type: "SELECTED_TEAM",
        payload: (selectedTeam || []).filter((el) => {
          const teamName = data?.value || value;
          return el.value !== teamName;
        }),
      });
    }
  }
};

export default onTeamSelect;

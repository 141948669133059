import { useContext } from "react";
import { useSelector } from "react-redux";
import { useLocation, useNavigate } from "react-router-dom";
import { Breadcrumb } from "antd";

import { StoreType } from "../../types";
import { MaintenanceViewContext } from "../../data";
import { queryParams } from "../../../../../utils/queryParams";

import "./MaintenanceTabsContainer.scss";

function MaintenanceTabsContainer() {
  const { isDarkMode } = useSelector((state: StoreType) => state.darkMode);
  const { activeTab } = useContext(MaintenanceViewContext);
  const { topicCategories } = useSelector(
    (state: StoreType) => state.topicCategories
  );

  const location = useLocation();
  const currentPathName = location?.pathname?.split("/")[1];
  const hasReportTab = topicCategories?.find(
    (category) => category?.apiName === currentPathName
  )?.hasReportTab;

  const navigate = useNavigate();

  return (
    <div
      className={`fleetMaintenanceTabs ${
        isDarkMode && "fleetMaintenanceTabsDark"
      }`}
    >
      <Breadcrumb
        items={[
          {
            title: "Overview",
            className: `breadcrumbItem ${
              activeTab === "Overview" ? "active" : ""
            }`,
            onClick() {
              navigate("/fleetMaintenance");
            },
          },
          {
            title: "Maintenance Chart",
            className: `breadcrumbItem ${
              activeTab === "Maintenance Chart" ? "active" : ""
            }`,
            onClick() {
              navigate("/maintenanceChart");
            },
          },
          {
            title: "Inspections",
            className: `breadcrumbItem ${
              activeTab === "Inspections" ? "active" : ""
            }`,
            onClick() {
              navigate("/fleetInspections");
            },
          },
          {
            title: "Daily Inspection",
            className: `breadcrumbItem ${
              activeTab === "Daily Inspection" ? "active" : ""
            }`,
            onClick() {
              navigate(
                `/fleetMaintenance?${queryParams({
                  tab: "fleetDailyInspections",
                })}`,
                {
                  state: {
                    tab: "Daily Inspection",
                  },
                }
              );
            },
          },
          {
            title: "Defects",
            className: `breadcrumbItem ${
              activeTab === "Defects" ? "active" : ""
            }`,
            onClick() {
              navigate("/fleetDefects");
            },
          },
          {
            title: "Schedule Maintenance",
            className: `breadcrumbItem ${
              activeTab === "Schedule Maintenance" ? "active" : ""
            }`,
            onClick() {
              navigate("/scheduleMaintenance");
            },
          },
          {
            title: "Work Orders",
            className: `breadcrumbItem ${
              activeTab === "Work Orders" ? "active" : ""
            }`,
            onClick() {
              navigate("/workOrders");
            },
          },
          {
            title: "Reports",
            className: `breadcrumbItem ${
              activeTab === "Reports" ? "active" : ""
            }`,
            onClick() {
              navigate(`/fleetMaintenance?${queryParams({ tab: "Reports" })}`, {
                state: {
                  tab: "Reports",
                },
              });
            },
          },
        ].filter((tab) => !(tab.title === "Reports" && !hasReportTab))}
      />
    </div>
  );
}

export default MaintenanceTabsContainer;

import { uniqBy } from "lodash";
import { Dispatch, SetStateAction } from "react";
import { unstable_batchedUpdates } from "react-dom";

import {
  EntryType,
  ScheduleType,
  CrewTeamType,
  ExcelDataType,
  EmployeeReportType,
} from "../payrollLiveTypes";
import { coordinatesMatch } from "./coordinatesMatch";
import { matchSchedulesWithExcelData } from "./matchSchedulesWithExcelData";
import { JobsiteType } from "src/components/SidebarPages/FleetMaintenanceView/types";
import { withinRadius } from "../../Payroll/Tabs/Activity/components/payrollActivityModalData";
import { findDistanceFromGeofenceSide } from "../../Payroll/Tabs/DEG/components/modalComponents/utils";

type Props = {
  jobsites: Array<JobsiteType>;
  crewTeams: Array<CrewTeamType>;
  todaySchedules: Array<ScheduleType>;
  defaultExcelData: Array<ExcelDataType>;
  employeesReport: Array<EmployeeReportType>;
  setDegEntries: Dispatch<SetStateAction<Array<EntryType>>>;
  setSchedules: Dispatch<SetStateAction<Array<ScheduleType>>>;
  setEmployeesReport: Dispatch<SetStateAction<Array<EmployeeReportType>>>;
};

export function updateSourceData({
  jobsites,
  crewTeams,
  setSchedules,
  setDegEntries,
  todaySchedules,
  employeesReport,
  defaultExcelData,
  setEmployeesReport,
}: Props): void {
  matchSchedulesWithExcelData(jobsites, todaySchedules, defaultExcelData).then(
    ({ jobsitesIncluded, schedulesIncluded }) => {
      const { jobsitesMatch, matchedEmployees } = coordinatesMatch({
        crewTeams,
        jobs: jobsitesIncluded,
        employees: employeesReport,
        schedules: uniqBy(schedulesIncluded, (e) => e?.projectId),
      });

      const projectsIncluded = uniqBy(
        jobsitesMatch,
        (job: JobsiteType) => job?.projectId
      );

      const schedulesIncludedd = todaySchedules.map((sch) => sch.projectId);
      unstable_batchedUpdates(() => {
        setSchedules(
          todaySchedules.concat(
            projectsIncluded.filter(
              (jb: ScheduleType) => !schedulesIncludedd.includes(jb.projectId)
            ) as Array<ScheduleType>
          )
        );

        setEmployeesReport(
          employeesReport.map((emp) => {
            const empIndex = matchedEmployees.findIndex(
              (el) => el?.employeeNumber == emp?.employeeNumber
            );

            const scheduleMatch = schedulesIncluded.findIndex(
              (el) => el?.projectId === matchedEmployees?.[empIndex]?.projectId
            );
            if (empIndex > -1 && scheduleMatch > -1) {
              return matchedEmployees[empIndex];
            } else if (empIndex > -1 && scheduleMatch === -1) {
              const jobIndex = jobsitesIncluded.findIndex(
                (job) =>
                  job.projectId === matchedEmployees?.[empIndex]?.projectId
              );
              const jobMatch: JobsiteType = jobsitesIncluded?.[jobIndex];
              const distanceCondition =
                matchedEmployees?.[empIndex]?.distance <=
                Number(jobMatch?.locationRadius);
              return {
                ...matchedEmployees?.[empIndex],
                jobsiteId:
                  matchedEmployees?.[empIndex]?.jobsiteId ||
                  jobMatch?.jobsiteId,
                color:
                  emp?.liveStatus === "In" &&
                  (jobIndex === -1 || !distanceCondition)
                    ? "#e9c466"
                    : null,
              };
            } else {
              let foreman = false;
              let teamIndex = crewTeams.findIndex((el) => {
                const isForeman =
                  el?.crewForeman?.employeeId === emp?.employeeId;

                const isMember =
                  el.crewMembers.findIndex(
                    (mem) => mem?.employeeId === emp?.employeeId
                  ) > -1;

                if (isForeman) {
                  foreman = isForeman;
                }
                return isForeman || isMember;
              });
              const scheduleIndex = schedulesIncluded.findIndex(
                (el) => el?.projectId == emp?.projectId
              );
              const jobI = jobsitesIncluded.findIndex(
                (el) => el?.projectId === emp?.projectId
              );

              let jobIncluded = emp?.projectId
                ? scheduleIndex > -1 || jobI > -1
                : false;

              return {
                ...emp,
                isForeman: foreman,
                jobsiteId: jobsites?.[jobI]?.jobsiteId,
                crewTeamId: crewTeams?.[teamIndex]?.crewTeamId,
                projectId:
                  matchedEmployees?.[empIndex]?.projectId || emp?.projectId,
                crewTeamName:
                  crewTeams?.[teamIndex]?.crewTeamName ||
                  emp?.crewTeamName ||
                  "No Team",
                color:
                  emp?.liveStatus === "In" && !jobIncluded ? "#e9c466" : null,
              };
            }
          })
        );

        setDegEntries((prev) => {
          let newState: Array<EntryType> = [];
          prev.forEach((el) => {
            const employeeNumber = Number(
              (el?.employeeId || "").split("-")?.[1]
            );
            const empIndex = matchedEmployees.findIndex(
              (emp) => employeeNumber === Number(emp?.employeeNumber)
            );
            const jobIndex = jobsites.findIndex(
              (el) => el?.projectId === matchedEmployees?.[empIndex]?.projectId
            );

            const jobMatch = jobsites?.[jobIndex];

            if (jobMatch) {
              let distance;
              if (
                jobMatch?.geoFenceInfo?.length ||
                jobMatch?.geofenceInfo?.length
              ) {
                const geoInfo = jobMatch?.geoFenceInfo || [
                  { geoFenceInfo: jobMatch?.geofenceInfo },
                ];
                distance = findDistanceFromGeofenceSide({
                  geofence: geoInfo[0]?.geoFenceInfo,
                  point: el?.punchCoordinates,
                  tolerance: 300,
                })?.distanceInFeet;
              } else {
                distance = withinRadius(
                  jobMatch.addressPosition,
                  el.punchCoordinates
                )?.distanceInFeet;
              }

              const duration = Math.round(distance / 4.7);
              const updatedEntry = {
                ...el,
                jobsiteId: jobMatch?.jobsiteId,
                distanceFromJob: distance || 0,
                duration,
                activityStatus: (distance > 300 || !distance
                  ? "Pending"
                  : "Completed") as "Completed" | "Draft" | "Pending",
                jobsiteMatch: {
                  jobName: jobMatch?.jobName,
                  services: jobMatch?.services,
                  jobsiteId: jobMatch?.jobsiteId,
                  jobAddress: jobMatch.jobAddress,
                  reimbursement: jobMatch?.reimbursement,
                },
              };

              newState.push(updatedEntry);
            } else {
              newState.push(el);
            }
          });

          return newState;
        });
      });
    }
  );
}

import { ExcelIcon, ImageIcon, PdfIcon, VideoIcon, WordIcon } from "./assets";

export const fileIcons = {
  xls: <ExcelIcon />,
  xlsx: <ExcelIcon />,
  pdf: <PdfIcon />,
  doc: <WordIcon />,
  docx: <WordIcon />,
  image: <ImageIcon />,
  mp4: <VideoIcon />,
};

export const docObjectLabels = {
  expirationDate: "Expiration Date",
  notes: "Notes",
  docStatus: "Documentation Status",
};

export const uuidRegex = /\/(\w+-){4}\w+/g;

export const queryRegex = /\?.*$/;

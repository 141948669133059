import {
  useState,
  useEffect,
  useContext,
  forwardRef,
  useImperativeHandle,
} from "react";

import {
  getLiveActivity,
  FleetsLiveContext,
  loadLivePreference,
} from "../../../../utils";
import { LIVE_STATUSES } from "../../../../data";
import LiveMapContext from "../../LiveMapContext";
import { dayjsNY } from "src/components/DateComponents/contants/DayjsNY";

import "./MapFilters.scss";

const MapFilters = forwardRef(({}, ref) => {
  const {
    geofences,
    locations,
    globalAudits,
    auditsForDate,
    actualTripsForTruck,
    plannedTripsForTruck,
  } = useContext(FleetsLiveContext);
  const { onFilterChange = () => {} } = useContext(LiveMapContext);

  const [statuses, setStatuses] = useState([]);
  const [selectedStatus, setSelectedStatus] = useState(null);
  const [liveMatches, setLiveMatches] = useState({});
  const [liveRemarks, setLiveRemarks] = useState({});

  useEffect(() => {
    if (locations?.length) {
      let statusValues = statuses?.reduce(
        (acc, val) => ({ ...acc, [val?.name]: 0 }),
        {}
      );

      for (const location of locations) {
        const { locationStatus } = location;
        statusValues = {
          ...statusValues,
          [locationStatus]: (statusValues?.[locationStatus] || 0) + 1,
        };
      }

      setStatuses(
        Object.keys(statusValues).map((e) => ({
          name: e,
          value: statusValues[e],
        }))
      );
    }
  }, [locations]);

  useEffect(() => {
    let tmp = [];
    for (const vehicle of locations) {
      let todayFormat = dayjsNY(
        loadLivePreference("liveSelectedDate")
      ).format();

      let auditToConsider = {};
      if (globalAudits?.auditDate === todayFormat) {
        auditToConsider = structuredClone(
          globalAudits?.alertChanges?.[vehicle?.fleetId]
        );
      } else {
        auditToConsider = structuredClone(
          auditsForDate?.[todayFormat]?.alertChanges?.[vehicle?.fleetId] || {}
        );
      }

      let activity = getLiveActivity({
        geofences,
        planForTruck: plannedTripsForTruck?.[vehicle?.fleetId] || [],
        actualActivities: actualTripsForTruck?.[vehicle?.fleetId] || {},
        auditsForTruck: auditToConsider,
      });

      activity = {
        ...activity,
        deviceSerialNumber: vehicle?.deviceSerialNumber,
      };

      tmp.push(activity);
    }

    setLiveMatches(
      _.groupBy(tmp, (e) => {
        if (!e.actualStart) {
          return "Not Departed";
        } else {
          if (!e.actualEnd) {
            return "En Route";
          }
        }

        return "In Location";
      })
    );

    let tmpRemarks = _.groupBy(tmp, (e) => {
      return e?.endRemark ? e?.endRemark : e?.startRemark || "noRemark";
    });
    const { noRemark, ...rest } = tmpRemarks;
    setLiveRemarks(rest);
  }, [
    geofences,
    locations,
    globalAudits,
    auditsForDate,
    actualTripsForTruck,
    plannedTripsForTruck,
  ]);

  useImperativeHandle(
    ref,
    () => {
      return {
        getStatusFilter() {
          if (!LIVE_STATUSES.includes(selectedStatus)) {
            return selectedStatus;
          }

          return null;
        },
      };
    },
    [selectedStatus]
  );

  function handleStatusClick(name, vinList) {
    if (name === selectedStatus) {
      setSelectedStatus(null);
      onFilterChange(null);
    } else {
      setSelectedStatus(name);
      onFilterChange(name, vinList);
    }
  }

  let length =
    statuses?.length +
    Object.keys(liveMatches || {}).length +
    Object.keys(liveRemarks || {}).length +
    1;

  return (
    <div className="live-map-filters" id="live-map-filters">
      {statuses?.map(({ name, value }, i) => {
        return (
          <div
            className={`live-status ${name?.replaceAll(" ", "")} ${
              selectedStatus === name ? "live-status-selected" : ""
            }`}
            style={{
              width: `calc(100% / ${length})`,
            }}
            onClick={() => {
              handleStatusClick(name);
            }}
            key={i}
            data-testid={`filter-status-${name.replaceAll(" ", "_")}`}
          >
            <span className="status-value">{value}</span>
            <span className="status-name">{name}</span>
          </div>
        );
      })}
      {Object.keys(liveMatches || {}).map((key, index) => {
        return (
          <div
            className={`live-status ${
              selectedStatus === key ? "live-status-selected" : ""
            }`}
            style={{
              width: `calc(100% / ${length})`,
              backgroundColor:
                key === "Not Departed"
                  ? "#802392"
                  : key === "En Route"
                  ? "#00f2f2"
                  : "#4b6858",
            }}
            data-testid={`filter-match-${key.replaceAll(" ", "_")}`}
            key={`live-status-${index}`}
            onClick={() => {
              handleStatusClick(selectedStatus);
              setTimeout(() => {
                handleStatusClick(
                  key,
                  liveMatches?.[key]?.map(
                    ({ deviceSerialNumber }) => deviceSerialNumber
                  )
                );
              }, 50);
            }}
          >
            <span className="status-value">{liveMatches[key]?.["length"]}</span>
            <span className="status-name">{key}</span>
          </div>
        );
      })}
      {Object.keys(liveRemarks || {}).map((key, index) => {
        return (
          <div
            className={`live-status ${
              selectedStatus === key ? "live-status-selected" : ""
            }`}
            style={{
              width: `calc(100% / ${length})`,
              backgroundColor:
                key === "Delayed"
                  ? "#F18F01"
                  : key === "Late"
                  ? "#7C0B2B"
                  : "#FFEAD0",
            }}
            key={`live-status-${index}`}
            onClick={() => {
              handleStatusClick(selectedStatus);
              setTimeout(() => {
                handleStatusClick(
                  key,
                  liveRemarks?.[key]?.map(
                    ({ deviceSerialNumber }) => deviceSerialNumber
                  )
                );
              }, 50);
            }}
          >
            <span className="status-value">{liveRemarks[key]?.["length"]}</span>
            <span className="status-name">{key}</span>
          </div>
        );
      })}
    </div>
  );
});

export default MapFilters;

import { Modal } from "antd";
import { useSelector } from "react-redux";
import { AlertIcon } from "src/assets";
import { XIcon } from "src/components/SidebarPages/Communication/assets";
import { TickIcon } from "src/components/pages/Settings/settingsComponents/Roles/src";
import MondayButton from "../MondayButton/MondayButton";
import "./RedWarningModal.scss";
import { TrashIcon } from "../../SidebarPages/Communication/assets";
import { useState } from "react";
import "./propTypes";

/**
 *
 * @param {RedWarningModalTypes} props - The props for MyComponent.
 * @returns {JSX.Element} - The rendered component.
 */

function RedWarningModal({
  visible,
  onCancel = () => {},
  setVisible = () => {},
  children,
  TitleIcon = AlertIcon,
  titleText = "Warnings",
  footer = null,
  modalClassName = "",
  footerProps = {},
  confirmModal = false, //grey header -> default header color is red
  deleteModal = false, //add trash icon
  modalProps = {},
  transformIcon = {
    transform: "rotate(-22deg) scale(3.5)",
  },
  disableOnConfirm = true,
}) {
  const [disableSave, setDisableSave] = useState(false);
  const {
    onConfirm = () => {},
    saveText = "Confirm",
    saveDisabled = false,
    SaveIcon = TickIcon,
    saveClassName = "mondayButtonGreen",
    closeText = "Cancel",
  } = footerProps || {};

  function onSaveConfirm() {
    if (disableOnConfirm) {
      setDisableSave(true);
    }
    onConfirm();
  }

  const initialFooter = footer
    ? footer
    : [
        {
          text: closeText,
          onClick: onCancel,
          disabled: false,
          Icon: XIcon,
          className: "mondayButtonRed",
        },
        {
          text: saveText,
          onClick: onSaveConfirm,
          disabled: saveDisabled || disableSave,
          Icon: SaveIcon,
          className: saveClassName,
        },
      ];
  const { isDarkMode } = useSelector((state) => state.darkMode);
  return (
    <Modal
      {...{
        open: visible,
        onCancel: () => {
          setVisible(false);
        },
        "data-testid": "red-warning-modal",
        title: (
          <div className="warningModalTitleContainer">
            <span className="warningModalTitle">
              {deleteModal ? <TrashIcon /> : <TitleIcon />}
              {titleText}
            </span>
            {deleteModal ? (
              <TrashIcon
                fill="#ffffff80"
                style={{
                  transform:
                    "rotate(-32deg) scale(3.5) translateX(2px) translateY(4px) ",
                }}
              />
            ) : (
              <TitleIcon
                fill="#ffffff80"
                style={{
                  ...transformIcon,
                }}
              />
            )}
          </div>
        ),
        footer: (
          <div className="redWarningModalFooter">
            {initialFooter?.map(
              (
                {
                  text = "",
                  onClick = () => {},
                  disabled = false,
                  Icon = null,
                  className = "",
                  ...restProps
                },
                i
              ) => (
                <MondayButton
                  key={i}
                  onClick={onClick}
                  Icon={!Icon ? null : <Icon />}
                  disabled={disabled}
                  className={className}
                  data-testid={"warning-modal-button" + text}
                  hasIcon={!!Icon}
                  {...restProps}
                >
                  {text}
                </MondayButton>
              )
            )}
          </div>
        ),
        centered: true,
        closeIcon: false,
        className: `redWarningsModal ${isDarkMode && "redWarningsModalDark"} 
       ${modalClassName} ${confirmModal && !deleteModal && "confirmModal"}`,
        ...modalProps,
      }}
    >
      {children}
    </Modal>
  );
}

export default RedWarningModal;

const types = {
  SET_ESTIMATIONS: "SET_ESTIMATIONS",
  SET_ESTIMATION_STATUS_TO_START: "SET_ESTIMATION_STATUS_TO_START",
  SET_ESTIMATION_STATUS_TO_HOLDON: "SET_ESTIMATION_STATUS_TO_HOLDON",
  SET_ESTIMATION_STATUS_TO_PAUSED: "SET_ESTIMATION_STATUS_TO_PAUSED",
  SET_ESTIMATION_STATUS_TO_STOPPED: "SET_ESTIMATION_STATUS_TO_STOPPED",
  SET_ESTIMATION_STATUS_TO_FINISHED: "SET_ESTIMATION_STATUS_TO_FINISHED",

  SET_LEADS: "SET_LEADS",

  SET_OPPORTUNITIES: "SET_OPPORTUNITIES",

  SET_ACCOUNTS: "SET_ACCOUNTS",

  ADD_TASK: "ADD_TASK",
  SET_TASK_STATUS: "SET_TASK_STATUS",

  //~~~~~~~~~~~~~~~~~~~~ NOTIFICATIONS ~~~~~~~~~~~~~~~~~~~~~
  SET_NOTIFICATIONS: "SET_NOTIFICATIONS",
  GET_NOTIFICATIONS_REQUEST: "GET_NOTIFICATIONS_REQUEST",
  GET_NOTIFICATIONS_SUCCESS: "GET_NOTIFICATIONS_SUCCESS",
  GET_EMAIL_NOTIFICATIONS_REQUEST: "GET_EMAIL_NOTIFICATIONS_REQUEST",
  GET_EMAIL_NOTIFICATIONS_SUCCESS: "GET_EMAIL_NOTIFICATIONS_SUCCESS",
  GET_EMAIL_NOTIFICATIONS_REQUEST_FINISHED:
    "GET_EMAIL_NOTIFICATIONS_REQUEST_FINISHED",
  GET_EMAIL_NOTIFICATIONS_FAILURE: "GET_EMAIL_NOTIFICATIONS_FAILURE:",
  RESET_EMAIL_NOTIFICATIONS: "RESET_EMAIL_NOTIFICATIONS",
  GET_NOTIFICATION: "GET_NOTIFICATION",
  UPDATE_NOTIFICATIONS: "UPDATE_NOTIFICATIONS",
  ADD_NOTIFICATION: "ADD_NOTIFICATION",
  DELETE_NOTIFICATION: "DELETE_NOTIFICATION",
  SET_NOTIFICATION_SETTINGS: "SET_NOTIFICATION_SETTINGS",
  TOGGLE_NOTIFICATION_PINNED_STATUS: "TOGGLE_NOTIFICATION_PINNED_STATUS",

  SET_FILTERS: "SET_FILTERS",
  MARK_EMAILS_AS_READ: "MARK_EMAILS_AS_READ",

  //~~~~~~~~~~~~~~~~~~~~SCHEDULE OF VALUE~~~~~~~~~~~~~~~~~~~~~~
  LOADING_SCHEDULE_OF_VALUE: "LOADING_SCHEDULE_OF_VALUE",
  GET_SCHEDULE_OF_VALUES: "GET_SCHEDULE_OF_VALUES",
  GET_SCHEDULE_OF_VALUE: "GET_SCHEDULE_OF_VALUE",
  CREATE_SCHEDULE_OF_VALUE: "CREATE_SCHEDULE_OF_VALUE",
  UPDATE_SCHEDULE_OF_VALUE: "UPDATE_SCHEDULE_OF_VALUE",
  DELETE_SCHEDULE_OF_VALUE: "DELETE_SCHEDULE_OF_VALUE",
  ERROR_SCHEDULE_OF_VALUE: "ERROR_SCHEDULE_OF_VALUE",
  CLEAR_SCHEDULE_OF_VALUE: "CLEAR_SCHEDULE_OF_VALUE",
  CLEAR_NEWLY_CREATED_SCHEDULE_OF_VALUE:
    "CLEAR_NEWLY_CREATED_SCHEDULE_OF_VALUE",
  RESET_SCHEDULE_OF_VALUE: "RESET_SCHEDULE_OF_VALUE",
  SET_EDITABLE_MODE: "SET_EDITABLE_MODE",

  //~~~~~~~~~~~~~~~~~~~~APPLICATIONS~~~~~~~~~~~~~~~~~~~~~~
  LOADING_APPLICATION: "LOADING_APPLICATION",
  GET_APPLICATIONS: "GET_APPLICATIONS",
  GET_APPLICATION: "GET_APPLICATION",
  CREATE_APPLICATION: "CREATE_APPLICATION",
  UPDATE_APPLICATION: "UPDATE_APPLICATION",
  DELETE_APPLICATION: "DELETE_APPLICATION",
  ERROR_APPLICATION: "ERROR_APPLICATION",
  CLEAR_APPLICATION: "CLEAR_APPLICATION",
  CLEAR_NEWLY_CREATED_APPLICATION: "CLEAR_NEWLY_CREATED_APPLICATION",
  RESET_APPLICATION: "RESET_APPLICATION",

  //~~~~~~~~~~~~~~~~~~~~RENTALS~~~~~~~~~~~~~~~~~~~~~~
  LOADING_RENTAL: "LOADING_RENTAL",
  GET_RENTALS: "GET_RENTALS",
  GET_RENTAL: "GET_RENTAL",
  CREATE_RENTAL: "CREATE_RENTAL",
  UPDATE_RENTAL: "UPDATE_RENTAL",
  DELETE_RENTAL: "DELETE_RENTAL",
  ERROR_RENTAL: "ERROR_RENTAL",
  CLEAR_RENTAL: "CLEAR_RENTAL",
  CLEAR_NEWLY_CREATED_RENTAL: "CLEAR_NEWLY_CREATED_RENTAL",
  RESET_RENTAL: "RESET_RENTAL",

  // //~~~~~~~~~~~~~~~~~~~~ Templates ~~~~~~~~~~~~~~~~~~~~~~
  // SET_TEMPLATES: "SET_TEMPLATES",

  //~~~~~~~~~~~~~~~~~~~~SERVICE DEFINITIONS~~~~~~~~~~~~~~~~~~~~~~
  SET_SERVICE_DEFINITION: "SET_SERVICE_DEFINITION",
  ADD_SCOPE: "ADD_SCOPE",
  EDIT_SCOPE: "EDIT_SCOPE",
  DELETE_SCOPE: "DELETE_SCOPE",

  //~~~~~~~~~~~~~~~~~~~~SESSION NOTIFICATIONS~~~~~~~~~~~~~~~~~~~~~~
  SET_SESSION_NOTIFICATIONS: "SET_SESSION_NOTIFICATIONS",
  ADD_SESSION_NOTIFICATION: "ADD_SESSION_NOTIFICATION",
  RESET_NEW_SESSION_NOTIFICATIONS: "RESET_NEW_SESSION_NOTIFICATIONS",

  //~~~~~~~~~~~~~~~~~~~~MISCELLANEOUS~~~~~~~~~~~~~~~~~~~~~~
  SET_AUTH_USER: "SET_AUTH_USER",
  SET_USER_CONFIG: "SET_USER_CONFIG",
  UPDATE_OTHER_USER: "UPDATE_OTHER_USER",
  SET_USER_CONFIG_KEY: "SET_USER_CONFIG_KEY",
  UPDATE_SPECIFIC_USER: "UPDATE_SPECIFIC_USER",
  CHANGE_COMPANY: "CHANGE_COMPANY",
  SET_PROGRAM_FIELDS: "SET_PROGRAM_FIELDS",
  SET_PROGRAM_FIELD_BY_NAME: "SET_PROGRAM_FIELD_BY_NAME",
  SET_PROGRAM_FIELD_BY_ID: "SET_PROGRAM_FIELD_BY_ID",
  SET_BASE_64: "SET_BASE_64",
  SET_HOT_CREDINTIALS: "SET_HOT_CREDINTIALS",
  SET_TO_BE_SCHEDULED: "SET_TO_BE_SCHEDULED",
  SET_SCHEDULE_DAYS: "SET_SCHEDULE_DAYS",
  SET_DATA_FOR_SCHEDULE_CALENDAR: "SET_DATA_FOR_SCHEDULE_CALENDAR",
  SET_CREWS_FLEET_INFO: "SET_CREWS_FLEET_INFO",
  SET_SCHEDULE_SETTINGS: "SET_SCHEDULE_SETTINGS",
  SET_PREFERENCES: "SET_PREFERENCES",
  SET_BASE_PAGE_FILTERS: "SET_BASE_PAGE_FILTERS",
  UPDATE_PREFERENCES: "UPDATE_PREFERENCES",
  SET_TYPES_OF_WORK: "SET_TYPES_OF_WORK",
  SET_FLEET_FIELDS: "SET_FLEET_FIELDS",
  SET_ACCESS_TOKEN: "SET_ACCESS_TOKEN",
  SET_CONSTRUCTIONS_SERVICES: "SET_CONSTRUCTIONS_SERVICES",
  SET_YARDS: "SET_YARDS",
  ADD_YARD: "ADD_YARD",
  CHANGE_YARD_CONFIG: "CHANGE_YARD_CONFIG",

  //~~~~~~~~~~~~~~~~~~~~LOGGED ON TASKS~~~~~~~~~~~~~~~~~~~~~~
  LOGGED_ON_TASKS: "LOGGED_ON_TASKS",
  UPDATE_LOGGED_TASKS: "UPDATE_LOGGED_TASKS",
  UPDATE_TASKS: "UPDATE_TASKS",
  NOTIFICATION: "NOTIFICATION",

  //~~~~~~~~~~~~~~~~~~~~NOTE INPUT DRAW ~~~~~~~~~~~~~~~~~~~~~
  SET_STATE_NOTE_INPUT_DRAW: "SET_STATE_NOTE_INPUT_DRAW",

  //~~~~~~~~~~~~~~~~~~~~check ifBreadcrumbContactReducer ~~~~~~~~~~~~~~~~~~~
  SET_BOOLEAN_IF_CONTACT: "SET_BOOLEAN_IF_CONTACT",

  //~~~~~~~~~~~~~~~~~~~~ MAIN TOPIC CATEGORIES ~~~~~~~~~~~~~~~~~~~~~
  SET_TOPIC_CATEGORIES: "SET_TOPIC_CATEGORIES",
  SET_TOPIC_CATEGORIES_BY_ID: "SET_TOPIC_CATEGORIES_BY_ID",

  //~~~~~~~~~~~~~~~~~~~~ USER IMAGES ~~~~~~~~~~~~~~~~~~~~~
  SET_NEW_IMAGE: "SET_NEW_IMAGE",

  //~~~~~~~~~~~~~~~~~~~~ SAMPLE OBJECTS ~~~~~~~~~~~~~~~~~~~~~
  SET_SAMPLE_OBJECTS: "SET_SAMPLE_OBJECTS",

  SET_USER_GROUPS: "SET_USER_GROUPS",
  SET_USER_GROUPS_BY_ID: "SET_USER_GROUPS_BY_ID",

  DARK_MODE: "DARK_MODE",

  ADD_EMAILS: "ADD_EMAILS",
  UPDATE_EMAIL_ATTACHMENTS: "UPDATE_EMAIL_ATTACHMENTS",
  ADD_DRAFTS: "ADD_DRAFTS",
  MARK_AS_IMPORTANT: "MARK_AS_IMPORTANT",
  MARK_AS_NOT_IMPORTANT: "MARK_AS_NOT_IMPORTANT",
  MARK_AS_READ: "MARK_AS_READ",
  MARK_AS_UNREAD: "MARK_AS_UNREAD",
  MOVE_EMAIL_TO: "MOVE_EMAIL_TO",
  UPDATE_DRAFTS: "UPDATE_DRAFTS",
  UPDATE_DRAFT_BY_ID: "UPDATE_DRAFT_BY_ID",
  DELETE_DRAFT_BY_ID: "DELETE_DRAFT_BY_ID",
  FETCH_EMAILS_START: "FETCH_EMAILS_START",
  FETCH_EMAILS_FINISHED: "FETCH_EMAILS_FINISHED",
  FETCH_EMAILS_SUCCESS: "FETCH_EMAILS_SUCCESS",
  FETCH_EMAILS_FAILURE: "FETCH_EMAILS_FAILURE",
  RESET_COMMUNICATION_STATE: "RESET_COMMUNICATION_STATE",
  SET_CURRENT_PAGE: "SET_CURRENT_PAGE",
  SET_SELECTED_CATEGORY: "SET_SELECTED_CATEGORY",
  SET_CHECKED_LIST: "SET_CHECKED_LIST",
  SET_RECORD_DATA: "SET_RECORD_DATA",
  RESET_RECORD_DATA: "RESET_RECORD_DATA",

  // * Google AUTH

  LOGIN: "LOGIN",
  LOGOUT: "LOGOUT",
};

export default types;

import { useSelector } from "react-redux";
import { InfoCircleFilled } from "@ant-design/icons";
import { useCallback, useState, useContext } from "react";
import { AgGridReact } from "ag-grid-react/lib/agGridReact";

import { crewsColumnDefs, engineersColumnDefs } from "../../AgGridData";
import EmployeeUploadContext from "../../EmployeeUploadContext";
import CompareTable from "../../components/CompareConflictTable/CompareTable";
import { SearchIcon } from "../../../../../../../../SidebarPages/Communication/assets";
import { InputComponent } from "../../../../../../../../SidebarPages/Fleet/components";
import { WarningTriangle } from "../../../../../../../../SidebarPages/DynamicView/src";

import "./EmployeesTable.scss";

function EmployeesTable() {
  const { isDarkMode } = useSelector((state) => state.darkMode);

  const {
    gridApi,
    setGridApi,
    uploadedEmployees,
    employeesConflicts,
    employeeType,
  } = useContext(EmployeeUploadContext);

  const [conflictsTableVisible, setConflictsTableVisible] = useState(false);
  const [searchInput, setSearchInput] = useState(null);

  const onGridReady = useCallback(({ api }) => {
    setGridApi(api);
  }, []);

  const getRowId = useCallback((param) => {
    if (employeeType === "crews") {
      return param?.data?.crewId;
    } else if (employeeType === "engineers" || employeeType === "architects") {
      return param?.data?.email;
    }
  }, []);

  function onFilterTextChange(e) {
    if (!gridApi) {
      gridApi?.forEachDetailGridInfo?.((params) => {
        params.api.setQuickFilter(e);
      });
    } else {
      gridApi.setQuickFilter(e);
    }
    setSearchInput(e);
  }

  const columnDefs =
    employeeType === "crews"
      ? crewsColumnDefs
      : employeeType === "engineers" || employeeType === "architects"
      ? engineersColumnDefs
      : null;

  return (
    <main
      className={`employees-table-container ${
        isDarkMode && "employees-table-container-dark"
      }`}
    >
      <section className="table-control-panel">
        <div className="uploadEmployeeInfo">
          <InfoCircleFilled style={{ color: "#0F5C97", fontSize: 20 }} />
          <p className="uploadEmployeeText">
            Manage the list of uploaded crew members. You can remove users,
            double-click on records to edit details, and resolve the conflicts
            if there is any.
          </p>
          {!!employeesConflicts?.newEmployees?.length && (
            <div
              className="conflicts"
              onClick={() => setConflictsTableVisible(true)}
            >
              <WarningTriangle />
              <span className="conflictsCount">
                {employeesConflicts?.newEmployees?.length}
              </span>
              conflicts
            </div>
          )}
        </div>
        <InputComponent
          type="input"
          placeholder="Search employee..."
          value={searchInput}
          prefix={<SearchIcon />}
          onChange={(e) => onFilterTextChange(e.target.value)}
        />
      </section>
      <section
        className={`payroll-grid ${
          isDarkMode
            ? "dark-ag-theme ag-theme-alpine-dark"
            : "light-ag-theme ag-theme-alpine"
        }`}
      >
        <AgGridReact
          {...{
            gridApi,
            rowData: uploadedEmployees,
            columnDefs,
            onGridReady,
            defaultColDef: {
              resizable: true,
              enablePivot: true,
              enableColResize: true,
              enableRowGroup: true,
              editable: false,
              sortable: true,
              flex: 1,
              filter: true,
            },
            rowGroupPanelShow: "always",
            pagination: true,
            animateRows: true,
            enableRangeSelection: true,
            paginationPageSize: Math.floor((window.innerHeight - 370) / 45),
            getRowId,
            sideBar: {
              toolPanels: [
                {
                  id: "columns",
                  labelDefault: "Columns",
                  labelKey: "columns",
                  iconKey: "columns",
                  toolPanel: "agColumnsToolPanel",
                },
                {
                  id: "filters",
                  labelDefault: "Filters",
                  labelKey: "filters",
                  iconKey: "filter",
                  toolPanel: "agFiltersToolPanel",
                },
              ],
            },
          }}
        />
      </section>
      {conflictsTableVisible && (
        <CompareTable
          open={conflictsTableVisible}
          onCancel={() => setConflictsTableVisible(false)}
          employeeType={employeeType}
        />
      )}
    </main>
  );
}

export default EmployeesTable;

import { isEmpty, uniqBy } from "lodash";
import getNearbyPlacesWithDetails from "./getNearbyPlaces";
import { driveApi } from "../../../../../integrations/DriveRequest";

async function initialProjectSafetyReportData({
  rowData,
  userConfiguration,
  accessToken,
}) {
  const driveRequest = driveApi({ accessToken });
  const allUsers = userConfiguration?.allUsers?.Items || [];

  function userMap({
    groupName = "",
    nameOfUser = "",
    phone_number = "",
    userName = "",
    googleDriveFileId,
    identityId,
  }) {
    return {
      groupName,
      nameOfUser,
      phoneNumber: phone_number,
      userName,
      googleDriveFileId,
      identityId,
    };
  }

  const safetyUsers = allUsers
    .filter((user) => user?.departmentName === "Safety" && !user?.isSuspended)
    .map(userMap);

  const pms = isEmpty(rowData?.projectManager)
    ? []
    : allUsers
        .filter((user) =>
          rowData?.projectManager.some(
            (pm) => pm.identityId === user.identityId
          )
        )
        .map(userMap);

  const allTeamMembers = isEmpty(rowData?.teamsConfiguration)
    ? []
    : allUsers
        .filter((user) =>
          rowData?.teamsConfiguration
            .flatMap((el) => el.members)
            .some((member) => member.identityId === user.identityId)
        )
        .map(userMap);

  const projectUsers = uniqBy(
    [...pms, ...allTeamMembers, ...safetyUsers],
    "identityId"
  );

  const usersWithImages = projectUsers.filter((user) => user.googleDriveFileId);

  const usersWithFetchedImages = await Promise.all(
    usersWithImages.map(async (user) => {
      const response = await driveRequest.getImageSrc(user.googleDriveFileId);
      return { ...user, profileImage: response.src };
    })
  );

  const allContactsWithImages = [
    ...projectUsers.filter((user) => !user.googleDriveFileId),
    ...usersWithFetchedImages,
  ].map((user, i) => ({
    ...user,
    isHidden: i > 11,
  }));

  const defaultSafetyContacts = {
    title: "Emergency Contact Information",
    body: [
      { key: "Police Service", value: "911", img: "NYPD.png" },
      { key: "Fire Service", value: "911", img: "NYFD.png" },
      { key: "Ambulance", value: "911", img: "nyAmbulance.png" },
      {
        key: "Poison Control",
        value: "1-800-222-1222",
        img: "poisonHelp.png",
      },
      { key: "Hospital", value: "911", img: "hospital.png" },
      {
        key: "Workplace Safety Authority",
        value: "1-234-567-8910",
        img: "laborDep.png",
      },
    ],
  };

  const coordinates = {
    lat: rowData?.projectLatitude,
    lng: rowData?.projectLongitude,
  };

  const defaultFields = [
    { title: "rowData", body: rowData },
    defaultSafetyContacts,
    { title: "Safety Contacts", body: allContactsWithImages },
  ];
  if (!coordinates.lat || !coordinates.lng) {
    return defaultFields;
  }
  try {
    const nearbyHospitals = await getNearbyPlacesWithDetails(
      coordinates,
      1609,
      "hospital"
    );
    return [
      ...defaultFields,
      {
        title: "Hospitals Nearby",
        body: nearbyHospitals || [],
        showAll: true,
        removedAddresses: [],
      },
    ];
  } catch (e) {
    console.log("initialProjectSafetyReportData ~ e:", e);
    return defaultFields;
  }
}

export default initialProjectSafetyReportData;

//@ts-check
import axios from "axios";
import { VIRTUAL_ASSISTANT_API_URL } from "../data/constants";

export const getCommonQuestions = async () => {
  try {
    const apiUrl = `${VIRTUAL_ASSISTANT_API_URL}/commonWords`;
    const { data } = await axios.get(apiUrl);
    return data?.data;
  } catch (error) {
    console.error("Error creating thread:", error);
  }
};

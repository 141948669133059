import { Button } from "antd";
import dayjs from "dayjs";
import { loginStatusColors } from "../../../allUsersData";

const sessionColDefs = ({
  setLogoutOptionsModal,
  ws,
  sessionLogsObj = {},
  setSessionLogsVisible,
}) => {
  return [
    {
      headerName: "OS",
      field: "os",
      valueGetter: ({ data }) => `${data?.osName} ${data?.osVersion}`,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Browser",
      field: "browser",
      valueGetter: ({ data }) => `${data?.browserName} ${data?.browserVersion}`,
      filter: "agTextColumnFilter",
    },
    {
      headerName: "IPv4",
      field: "ipv4",
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Logged In",
      field: "loggedIn",
      filter: "agTextColumnFilter",
      cellRenderer: ({ value }) => dayjs(value).format("MM/DD/YYYY - hh:mm a"),
      pdfExportOptions: {
        modifyCellValue: (value) => dayjs(value).format("MM/DD/YYYY - hh:mm a"),
      },
    },
    {
      headerName: "Activity",
      field: "sessionId",
      sort: "desc",
      valueGetter: ({ data }) => sessionLogsObj?.[data?.sessionId]?.length || 0,
      cellRendererFramework: ({ data }) => {
        const sessionId = data?.sessionId;
        // console.log({ value });
        if (!sessionLogsObj?.[sessionId]?.length) {
          return (
            <Button className="mondayButtonGrey" disabled>
              0
            </Button>
          );
        } else {
          return (
            <Button
              onClick={() =>
                setSessionLogsVisible({
                  device: `${data?.osName} ${data?.osVersion} ${data?.browserName} ${data?.browserVersion}`,
                  sessionId,
                  ipv4: data?.ipv4 || "",
                })
              }
              className="mondayButtonBlue"
            >
              {sessionLogsObj?.[sessionId]?.length || 0}
            </Button>
          );
        }
      },
      cellStyle: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      },
      filter: "agTextColumnFilter",
    },
    {
      headerName: "Logout Status",
      field: "logout",
      cellRenderer: ({ value }) => (!!value ? "Waiting to logout..." : ""),
    },
    ws && {
      headerName: "Active",
      field: "wsStatus",
      sort: "desc",
      cellRenderer: (params) => {
        const idle = params?.data?.isIdle;
        const status = idle
          ? "Idle"
          : !!params?.data?.wsStatus
          ? "Online"
          : "Offline";
        return (
          <span
            style={{
              color:
                status === "Online"
                  ? loginStatusColors?.loggedIn
                  : loginStatusColors?.loggedOut,
            }}
          >
            {status}
          </span>
        );
      },
    },
    {
      headerName: "Action",
      cellRenderer: "SessionForceLogout",
      cellRendererParams: { onClick: setLogoutOptionsModal },
      pdfExportOptions: {
        skipColumn: true,
      },
    },
  ].filter(Boolean);
};

export default sessionColDefs;

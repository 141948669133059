import { Form } from "antd";
import { Responsive, WidthProvider } from "react-grid-layout";
import React, { useState, useMemo, forwardRef, useContext } from "react";

import PayrollContext from "../../../PayrollContext";
import AnalyticsChartsContext from "../AnalyticsChartsContext";
import { InputComponent } from "../../../../../SidebarPages/Fleet/components";
import {
  DarkHandle,
  LayoutCard,
} from "../../../../../SidebarPages/Fleet/fleetsLive/components/LiveReportsView/components/ReportsGridLayout/components";

import "react-resizable/css/styles.css";
import "react-grid-layout/css/styles.css";

const LayoutBody = WidthProvider(Responsive);

const AnalyticsChartsGridLayout = forwardRef(function () {
  const {
    onHide,
    layout,
    loading,
    changes,
    onRemove,
    setLayout,
    isDarkMode,
    setChanges,
    initialLayout,
    individualFilters,
    setIndividualFilters,
    setIndividualFilterModal,
  } = useContext(AnalyticsChartsContext);
  const { form } = useContext(PayrollContext);

  const [breakpoints] = useState({
    lg: 1200,
    md: 996,
    sm: 768,
    xs: 480,
    xxs: 0,
  });
  const [currentBreakpoint, setCurrentBreakpoint] = useState(calcBreakpoint());

  const analyticsDateRange = Form.useWatch("analyticsDateRange", form);

  function calcBreakpoint() {
    let result = "lg";
    let width = window.innerWidth;

    let br = Object.keys(breakpoints).sort(
      (a, b) => breakpoints[a] - breakpoints[b]
    );

    for (const b of br) {
      if (breakpoints[b] >= width) {
        result = b;
        break;
      }
    }

    return result;
  }

  function updateChartsLayout(e) {
    if (JSON.stringify(layout) !== JSON.stringify(e)) {
      let newLayout = [];
      for (const el of e) {
        let existingIndex = layout?.findIndex(({ i }) => i === el.i);
        if (existingIndex > -1) {
          newLayout.push({
            ...layout[existingIndex],
            ...el,
          });
        } else {
          newLayout.push(el);
        }
      }
      if (!changes) {
        const initialCardsPosition = initialLayout.cards.map((card) => {
          return {
            h: card?.h,
            i: card?.i,
            minH: card?.minH,
            minW: card?.minW,
            moved: card?.moved,
            static: card?.static,
            w: card?.w,
            x: card?.x,
            y: card?.y,
          };
        });

        const eventCardsPosition = e.map((card) => {
          return {
            h: card?.h,
            i: card?.i,
            minH: card?.minH,
            minW: card?.minW,
            moved: card?.moved,
            static: card?.static,
            w: card?.w,
            x: card?.x,
            y: card?.y,
          };
        });

        if (
          JSON.stringify(initialCardsPosition) !==
          JSON.stringify(eventCardsPosition)
        ) {
          setChanges(true);
        }
      }
      setLayout(newLayout);
    }
  }

  function updateCardInLayout(card) {
    setLayout((prev) => {
      const newLayout = prev.map((el) => {
        return el.cardKey === card.cardKey
          ? { ...el, chartData: card.chartData }
          : el;
      });

      return newLayout;
    });
    if (changes === false) {
      setChanges(true);
    }
  }

  function onCardFilter(cardKey) {
    const { chartData = {} } = layout.find((card) => {
      return card?.cardKey === cardKey;
    });

    setIndividualFilterModal({
      cardKey,
      showDataFor: chartData?.showDataFor,
      chartType: chartData?.chartType,
      datasets: chartData?.datasets.map((el) => {
        return {
          label: el?.label,
          selectedDataOption: el?.selectedDataOption,
        };
      }),
    });
  }

  function clearCardFilter(cardTitle) {
    setIndividualFilters((prev) => {
      const filtersObj = { ...prev };
      delete filtersObj?.[cardTitle];

      return filtersObj;
    });
  }
  
  const cards = useMemo(() => {
    return layout?.map((cardData) => {
      const { static: s, i, chartData, ...rest } = cardData;
      const filteredCard = individualFilters[i];

      const updatedChartData = {
        ...chartData,
        ...(filteredCard?.chartData || {}),
      };

      const key = `${
        filteredCard?.dateRange?.[0]?.valueOf?.() ||
        analyticsDateRange?.[0]?.valueOf?.()
      }-${i}`;

      const headerComponents = [
        <InputComponent
          key={key}
          type="rangepicker"
          disabled
          defaultValue={
            filteredCard?.dateRange?.length
              ? filteredCard?.dateRange
              : analyticsDateRange
          }
          // onChange={(e) => {
          //   onDateFilter({ dateRange: e, cardData });
          // }}
        />,
      ];

      return (
        <LayoutCard
          key={i}
          title={i}
          cardKey={i}
          onHide={onHide}
          loading={loading}
          onRemove={onRemove}
          cardRemovable={true}
          filter={onCardFilter}
          chartData={updatedChartData}
          className={s ? "static" : ""}
          headerComponents={headerComponents}
          updateCardInLayout={updateCardInLayout}
          clearFilter={filteredCard ? () => clearCardFilter(i) : null}
          {...rest}
        />
      );
    });
  }, [
    layout,
    JSON.stringify(individualFilters),
    JSON.stringify(analyticsDateRange),
  ]);

  return (
    <section
      className={`analytics-charts-body ${
        isDarkMode && "analytics-charts-body-dark"
      }`}
      style={{ backgroundColor: isDarkMode ? "#20212d" : "#f4f5f6" }}
    >
      <LayoutBody
        {...{
          layouts: {
            [currentBreakpoint]: layout,
          },
          id: "analytics-charts-layout",
          allowOverlap: false,
          breakpoints,
          cols: { lg: 12, md: 12, sm: 8, xs: 4, xxs: 4 },
          rowHeight: 30,
          isBounded: false,
          margin: [10, 10],
          isDraggable: true,
          isResizable: true,
          currentBreakpoint,
          resizeHandles: ["sw", "se"],
          useCSSTransforms: true,
          draggableHandle: ".card-header",
          onBreakpointChange: () => {
            const newBreakpoint = calcBreakpoint();
            setCurrentBreakpoint(newBreakpoint);
          },
          onLayoutChange: updateChartsLayout,
          resizeHandle: isDarkMode
            ? (props, ref) => {
              return <DarkHandle {...props} innerRef={ref} />;
            }
            : undefined,
        }}
      >
        {cards}
      </LayoutBody>
    </section>
  );
});

AnalyticsChartsGridLayout.displayName = "AnalyticsChartsGridLayout";

export default AnalyticsChartsGridLayout;

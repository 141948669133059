import { useRef, useState, useCallback, useEffect } from "react";
import { Result } from "antd";
import { Bar } from "react-chartjs-2";
import { useSelector } from "react-redux";

import CustomTooltip from "src/components/commonComponents/CustomTooltip";
import { formatCardValue } from "src/components/SidebarPages/components/OverviewCards/DynamicCard/utils";
import { SmallLoadableComp } from "src/components/Sidebars/components/SmallLoadableComp/SmallLoadableComp";
import ChartTooltip from "src/components/SidebarPages/components/OverviewCards/DynamicCard/components/ChartTooltip";
import useCustomTooltip from "src/hooks/useCustomTooltip";

import GraphTooltip from "./GraphTooltip";

import "./BarChart.scss";
import { useMediaQuery } from "react-responsive";
import { useFilteredHistory } from "../../../Projects/Accounting/Applications/ApplicationsTab/NewApplication/HistoryData/Hooks/useFilteredHistory";

const BarChart = ({
  params,
  onClick,
  filterValue = [],
  tooltipInformation,
}) => {
  const chartRef = useRef(null);
  const [tooltipVisible, setTooltipVisible] = useState(false);
  const [tooltipData, setTooltipData] = useState(null);
  const [tooltipPos, setTooltipPos] = useState(null);
  const {
    table,
    field,
    labels,
    custom,
    tooltipLabel,
    datasets,
    format, // format type currency or percentage
  } = params;
  const { isDarkMode } = useSelector((state) => state.darkMode);
  const history = useFilteredHistory(table);
  const isMobileView = useMediaQuery({ query: "(max-width: 1024px)" });

  const [noData, setNoData] = useState(false);
  const [loading, setLoading] = useState(true);

  const tooltipApi = useCustomTooltip();

  useEffect(() => {
    if (!datasets?.[0]?.isChartDataLoading) {
      const data = datasets?.[0]?.data;

      if (!data || data.length === 0 || data.every((q) => q === 0)) {
        setLoading(false);
        setNoData(true);
      } else {
        const hasUndefinedData = data.some((e) => e === undefined);
        setLoading(hasUndefinedData);
        setNoData(false);
      }
    } else {
      setLoading(true);
      setNoData(false);
    }
  }, [datasets?.[0]?.data]);

  const customTooltip = useCallback((context) => {
    if (context.tooltip.opacity == 0) {
      // hide tooltip visibilty
      setTooltipVisible(false);
      return;
    }

    const chart = chartRef.current;
    const canvas = chart.canvas;
    if (canvas) {
      // enable tooltip visibilty
      setTooltipVisible(true);

      // set position of tooltip
      const left = context.tooltip.x;
      const top = context.tooltip.y;

      // handle tooltip multiple rerender
      if (tooltipPos?.top != top) {
        setTooltipPos({ top: top, left: left });
        setTooltipData(context.tooltip);
      }
    }
  });

  const options = {
    plugins: {
      tooltip: !!custom
        ? {
            enabled: false,
            external: customTooltip,
          }
        : {
            enabled: true,
            callbacks: {
              label: function (tooltipItem, data) {
                return `${
                  !!tooltipLabel ? tooltipLabel : table
                }: ${formatCardValue(
                  !!format ? tooltipItem.raw : tooltipItem.formattedValue,
                  format
                )}`;
              },
            },
          },
      legend: {
        onHover: (event) => {
          event.native.target.style.cursor = "pointer";
        },
        onLeave: (event) => {
          event.native.target.style.cursor = "default";
        },
        title: {
          color: isDarkMode ? "#f2f2f2" : "#333238",
        },
        labels: {
          color: isDarkMode ? "#f2f2f2" : "#333238",
        },
        display: params?.datasets?.length > 1 ? true : false,
      },
      datalabels: {
        display: false,
      },
    },
    onHover: (event, chartElement) => {
      event.native.target.style.cursor = chartElement[0]
        ? "pointer"
        : "default";
    },
    scales: {
      x: {
        stacked: true,
        ticks: {
          color: isDarkMode ? "#f2f2f2" : "#33332",
          display: !isMobileView, // hide x-axis labels
        },
        grid: {
          color: isDarkMode ? "#b9b9b9" : "#ddd",
        },
      },
      y: {
        stacked: true,
        grid: {
          color: isDarkMode ? "#b9b9b9" : "#ddd",
        },
        ticks: {
          color: isDarkMode ? "#f2f2f2" : "#333238",
          callback: function (tick) {
            return formatCardValue(tick, format);
          },
        },
      },
    },
    animation: true,

    onClick: (_, clickedBarData) => {
      !!onClick
        ? onClick(_, clickedBarData)
        : history({
            [field === "leadAddress" ? "borough" : field]: [
              labels[clickedBarData[0]?.index],
            ],
          });
    },
  };

  const resultText = () => {
    if (params?.noFunction) {
      return "There are no matching pattern for this chart";
    } else if (noData && !!filterValue?.length) {
      return "No data matches the applied filter";
    } else return `No data found for ${table}!`;
  };

  const resultSubText = () => {
    if (params?.noFunction) {
      return "Try removing and recreating this chart. If this reoccurs, please contact the Software Department.";
    } else if (noData && !!filterValue?.length) {
      return "Try another filter.";
    } else return `You may not have access to any records!`;
  };

  return (
    <div className="BarChartBodyStyle">
      {/* <div> */}
      {!noData ? (
        <SmallLoadableComp loading={loading}>
          {window.location.pathname.split("/")[1] !== "dashboard" ? (
            <CustomTooltip
              tooltipApi={tooltipApi}
              style={{ maxHeight: 400, overflowY: "scroll" }}
              content={
                tooltipApi.open &&
                window.location.pathname?.split("/")[1] !== "dashboard" ? (
                  <ChartTooltip
                    data={tooltipApi.open ? tooltipInformation : []}
                  />
                ) : null
              }
            >
              <Bar
                className="BarChart"
                width={300}
                data={params}
                options={options}
                ref={chartRef}
              />
            </CustomTooltip>
          ) : (
            <Bar
              className="BarChart"
              width={300}
              data={params}
              options={options}
              ref={chartRef}
            />
          )}

          {tooltipPos && (
            <GraphTooltip
              data={tooltipData}
              position={tooltipPos}
              visibility={tooltipVisible}
              custom={custom}
              table={params.table}
            />
          )}
        </SmallLoadableComp>
      ) : (
        <Result
          status="warning"
          title={resultText()}
          subTitle={resultSubText()}
        />
      )}
      {/* </div> */}
    </div>
  );
};
export default BarChart;

import "./PliNotes.scss";
import { removeHTMLTags } from "../../../../../../../utils/removeHTMLTags";
import { dayjsNY } from "../../../../../../DateComponents/contants/DayjsNY";

export const PliNotes = ({ toBeScheduled }) => {
  const getNotes = () => {
    let notes = [];
    Object.values(toBeScheduled || {})
      .flat()
      ?.forEach((item) => {
        item?.serviceOptions?.[0]?.forEach((elevation) => {
          elevation?.items?.forEach((pli) => {
            if (Object.keys(pli?.note || {})?.length > 0) {
              notes.push({
                note: removeHTMLTags(pli?.note?.note) || "",
                createdBy: pli?.note?.createdBy || "",
                createdAt: pli?.note?.createdAt || dayjsNY().valueOf(),
                pliId: pli?.id,
                elevation:
                  elevation?.elevationLabel + " " + elevation?.elevationId,
                service: item?.label,
                estimation:
                  item?.quickbooksNumber || item?.estimationNumber || "1",
              });
            }
          });
        });
      });
    return notes;
  };

  return <div className={"pli-notes"}></div>;
};

import HTMLReactParser from "html-react-parser";
import React from "react";
import { useMemo } from "react";
import { useState } from "react";
import "./RentalTermsProject.scss";
function RentalTermsProject({ chosenData = {}, project = "" }) {
  const [content, setContent] = useState(null);

  const services = useMemo(() => {
    let temp = project?.services?.[chosenData?.selectedId]?.map((el) => ({
      rentalPaymentTerms: el?.rentalPaymentTerms,
      serviceId: el?.serviceId,
      label: el?.label,
    }));
    console.log("ledjorentalterms", {
      temp,
    });
    setContent(null);
    return temp;
  }, [chosenData?.selectedId, project]);

  const findContent = (id) => {
    setContent(services.find?.((el) => el?.serviceId === id));
  };

  return services?.length > 0 ? (
    <>
      {" "}
      <div className="RentalDetailsViewLight">
        <div className="ScheduleDetails-container">
          <div className="item-label-contanier">
            {services?.map((el) => (
              <div
                onClick={() => {
                  findContent(el?.serviceId);
                }}
                className={
                  el?.serviceId === content?.serviceId
                    ? "item-label-active"
                    : "item-label"
                }
              >
                {el?.label}
              </div>
            ))}
          </div>
          {!!content && services?.length > 0 ? (
            <div className="notes-content">
              {!!content?.rentalPaymentTerms ? (
                <>
                  <div className="name-div">
                    Name:
                    <br />
                    <div className="name-content">
                      {content?.rentalPaymentTerms?.name ||
                        "No name configured"}
                    </div>
                  </div>
                  <div className="name-div">
                    Number of weeks:
                    <br />
                    <div className="name-content">
                      {content?.rentalPaymentTerms?.range ||
                        "No range configured"}
                    </div>
                  </div>
                  <div className="name-div">
                    Description:
                    <br />
                    <div className="name-content-desc">
                      {HTMLReactParser(
                        content?.rentalPaymentTerms?.description || (
                          <strong>-</strong>
                        )
                      )}
                    </div>
                  </div>
                </>
              ) : (
                <div className="select-service">
                  There is no data saved in project. Contact Acc. Managers
                  department{" "}
                </div>
              )}
            </div>
          ) : (
            <div className="notes-content">
              <div className="select-service">Select one service</div>
            </div>
          )}
        </div>
      </div>
    </>
  ) : (
    ""
  );
}

export default RentalTermsProject;

import { useCallback, useRef, useState } from "react";
import { SmallLoadableComp } from "../../../../../Sidebars/components";
import { ProgressBar } from "../../../../../commonComponents";
import { Empty, Image } from "antd";
import ReactPlayer from "react-player";

const fallback =
  "https://via.placeholder.com/800x500?text=No+Preview+Available";

function FileView({
  src,
  id,
  downloadProgress,
  progressVisible,
  mimeType,
  blob,
  emailBox,
}) {
  const bodyContentRef = useRef(null);
  const [playing, setPlaying] = useState(true);

  const styleDiv = useCallback(() => {
    const height = bodyContentRef?.current?.offsetHeight;
    const width = bodyContentRef?.current?.offsetWidth;

    if (emailBox && height && width) {
      if (height < 500 && width < 1200) {
        return { height: 500, width: 1200 };
      } else if (height < 500) {
        return { height: 500 };
      } else if (width < 1200) {
        return { width: 1200 };
      }
    }
    return {};
  }, [emailBox]);

  function loading() {
    if (progressVisible === undefined) {
      return !src;
    } else if (progressVisible === false && !src) {
      return true;
    }
    return false;
  }

  return (
    <SmallLoadableComp loading={loading()}>
      {progressVisible === true && (
        <ProgressBar
          {...{
            percent: downloadProgress,
            style: { minWidth: 300 },
            loading: true,
          }}
        />
      )}
      {!!src &&
        (progressVisible === false || progressVisible === undefined) && (
          <div className="bodyContent" ref={bodyContentRef} style={styleDiv()}>
            {mimeType === "application/pdf" ? (
              <iframe
                id="previewIFrame"
                className="displayedFileFrame"
                src={
                  !!blob
                    ? blob
                    : !!id
                    ? `https://drive.google.com/file/d/${id}/preview`
                    : src
                }
              />
            ) : mimeType === "video/mp4" ? (
              <div className="video-player-container">
                <ReactPlayer
                  {...{
                    url: [{ src }],
                    controls: true,
                    playbackRate: 1,
                    playsinline: true,
                    height: "100%",
                    width: "100%",
                    onPlay: () => {
                      setPlaying(true);
                    },
                    onPause: () => setPlaying(false),
                    playing,
                    onStart: () => {},
                    onEnded: () => {
                      setPlaying(false);
                    },
                    light: true,
                    stopOnUnmount: true,
                    ref: (player) => {},
                  }}
                />
              </div>
            ) : src === "noSrc" ? (
              <NoImage />
            ) : (
              <Image
                {...{
                  src,
                  fallback,
                  alt: "...",
                  className: "displayedImage",
                  preview: false,
                }}
              />
            )}
          </div>
        )}
    </SmallLoadableComp>
  );
}

export default FileView;

const NoImage = () => {
  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <span style={{ color: "#808080" }}>
          This file doesn't exist or you no longer have permission to access it.
        </span>
      }
    />
  );
};
